import React from 'react'

import Antenna from 'src/assets/svg/device/avatar/Bboard/Antenna'
import Body from 'src/assets/svg/device/avatar/Bboard/Body'

const Bboard = props => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `relative w-full h-full `
  const svgCommon = `absolute inset-x-0 mx-auto scale-125 group-hover:scale-[1.4] duration-700 ease-out`
  const antenna = `${svgCommon} top-6 translate-y-2 group-hover:translate-y-6`
  const body = `${svgCommon} bottom-6 translate-y-0 group-hover:-translate-y-5`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={container}>
      <Body className={body} />
      <Antenna className={antenna} />
    </div>
  )
}
export default Bboard

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Bboard.propTypes = {}
