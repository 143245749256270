import React from 'react'
import { useDispatch } from 'react-redux'

import {
  box,
  subtitle,
} from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/style'

import CommonSwitch from 'src/components/Switch/CommonSwitch'

import { sg_deviceSettings_outputSource10MHz_change_watcher } from 'src/redux/actions/freqConverter/sgAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const OutputRefSource = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const refSource = current.data.settings.device?.refSource
  const outputRefSource = current.data.settings.device?.outputRefSource

  const isExternal = +refSource === 1

  const handleChange = () =>
    dispatch(
      sg_deviceSettings_outputSource10MHz_change_watcher({
        sn,
        value: !outputRefSource,
      })
    )

  if (isExternal) return <></>

  return (
    <div className={box + ' flex justify-between'}>
      <h4 className={subtitle}>Output Reference Source - 10 MHz</h4>
      <CommonSwitch
        type='text'
        onText='ON'
        offText='OFF'
        disabled={false}
        on={outputRefSource}
        onClick={handleChange}
      />
    </div>
  )
}

export default OutputRefSource
