import React from 'react'

import {
  touchContainerSize,
  iconSize,
  touchButtonBox,
  touchButton,
} from 'src/components/Input/InputGroup/style'

import IconButton from 'src/components/Button/IconButton'

import { BiMinus } from 'react-icons/bi'

const TouchLeft = props => {
  const {
    off,
    disabled,
    icon,
    size,
    isFocus,
    isHover,
    isKeydownMin,
    handleMinus,
    handlePointerDownForMinus,
    handlePointerUpForMinus,
  } = props

  const buttonDisabled = isKeydownMin || disabled
  const showButton = !disabled && !off && (isFocus || isHover)

  const handleClick = event => !buttonDisabled && handleMinus(event)
  const handlePointerDown = event =>
    !buttonDisabled && handlePointerDownForMinus(event)
  const handlePointerUp = event =>
    !buttonDisabled && handlePointerUpForMinus(event)

  const containerClass = `
                          ${touchContainerSize[size]} 
                          h-full 
                          flex shrink-0 
                          items-center justify-center 
                          mr-1 select-none
                          `

  const iconClass = `
                    ${iconSize[size] || 'w-4 h-4'} 
                    flex items-center justify-center 
                    shrink-0 fill-white ml-[6px] mr-1
                    `

  return (
    <div className={containerClass}>
      {!showButton && icon && <div className={iconClass}>{icon}</div>}

      {showButton && (
        <div
          className={touchButtonBox + ' border-r-1 touch-none'}
          onClick={handleClick}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}>
          <IconButton
            className={touchButton}
            type='standard'
            size={size}
            tabIndex='-1'
            disabled={buttonDisabled}>
            <BiMinus />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default TouchLeft
