import { call, fork, put, delay, cancel } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'

import { getIsDemoMode } from 'src/redux/sagas/funcs/general'
import { devWarLog } from 'src/funcs/tools'

// import { _vChannelSyncWithH } from 'src/redux/sagas/handlers/event/beamformers/cloverCellEvb/channel'
import { setIsOperatingStatusReading as ui_setIsOperatingStatusReading } from 'src/redux/slices/uiControl/beamformers/cloverCellEvb'
import { modalActions } from 'src/redux/slices/modal'
import { cloverCellEvb2x2CurrentExportApi } from 'src/redux/sagas/services/restfulAPI/localMiddlewareApi'
import { getUiControl } from 'src/redux/sagas/selector/uiControl'
import __export_API_sender from 'src/redux/sagas/services/restfulTools/__export_API_sender'

export function* currentReadMethodChange(payloads) {
  try {
    yield console.log('currentReadMethodChange', payloads)
    const { sn, value } = payloads

    let { lookupID, currentData } = yield call(getCommonArgs, sn)

    currentData.current.readMethod = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // 其實後來 readControl 會直接送 readMethod 下去
    // 所以這隻其實沒什麼特別的用意
    // 但還是先不動
    yield call(
      __socket_API_sender,
      localMiddlewareApi.CLOVERCELL_EVB_OPERATING_STATUS_SET_READ_METHOD,
      {
        sn,
        lookupID,
        value,
      }
    )
  } catch (error) {
    devWarLog('[handler] currentReadMethodChange error:', error)
  }
}

let demoContinuousReadFuncList = {}

export function* currentReadClick(payloads) {
  try {
    const { sn } = payloads
    yield console.log('commonReadClick', payloads)
    let { lookupID, currentData } = yield call(getCommonArgs, sn)

    const { single: s_uiControl } = yield call(getUiControl, sn)
    const isOperatingStatusReading =
      s_uiControl.current.isOperatingStatusReading

    const readMethod = currentData.current.readMethod
    const isDemoMode = yield call(getIsDemoMode)

    // 1. 改 operatingStatus reading (不分 demo or device control)
    if (readMethod === 'continuous') {
      if (isOperatingStatusReading)
        yield put(ui_setIsOperatingStatusReading({ sn, value: false }))
      if (!isOperatingStatusReading)
        yield put(ui_setIsOperatingStatusReading({ sn, value: true }))
    }

    // 2. 如果是 demo 直接 call single 或 continuous 相關 function
    // (demo read power 的行為完完全全在這裡就處理完了，沒過 server Controllers)
    if (isDemoMode) {
      if (readMethod === 'single') yield call(_demoReadCurrentOnce, { sn })

      if (readMethod === 'continuous') {
        if (isOperatingStatusReading)
          yield cancel(demoContinuousReadFuncList[sn])

        if (!isOperatingStatusReading)
          demoContinuousReadFuncList[sn] = yield fork(
            _demoReadOperatingStatus,
            { sn }
          )
      }
    }

    // 如果是 device control 就 call API
    if (!isDemoMode) {
      let action
      if (readMethod === 'single') action = 'start'
      if (readMethod === 'continuous')
        action = isOperatingStatusReading ? 'stop' : 'start'

      yield call(
        __socket_API_sender,
        localMiddlewareApi.CLOVERCELL_EVB_OPERATING_STATUS_ACTIVATE_READ_CONTROL,
        {
          sn,
          lookupID,
          action,
          readMethod,
        }
      )
    }
  } catch (error) {
    devWarLog('[handler] commonReadClick error:', error)
  }
}

const _createFakeData = () => Math.floor(Math.random() * 10) / 10

function* _demoReadCurrentOnce({ sn }) {
  let { currentData } = yield call(getCommonArgs, sn)

  currentData.current.measureDataLength =
    currentData.current.measureDataLength + 1

  currentData.current.temperature.currentValue =
    Math.floor(Math.random() * 245) - 90

  currentData.current.pdt.horizon.currentValue = new Array(4)
    .fill(null)
    .map(() => _createFakeData() - 99)

  currentData.current.pdt.vertical.currentValue = new Array(4)
    .fill(null)
    .map(() => _createFakeData() - 99)

  currentData.current.powerConsumption.vdd1v8.current.currentValue =
    -99 + _createFakeData()
  currentData.current.powerConsumption.vdd1v8.voltage.currentValue =
    -99 + _createFakeData()
  currentData.current.powerConsumption.vdd1v8.power.currentValue =
    -99 + _createFakeData()

  currentData.current.powerConsumption.vddPa.current.currentValue =
    -99 + _createFakeData()
  currentData.current.powerConsumption.vddPa.voltage.currentValue =
    -99 + _createFakeData()
  currentData.current.powerConsumption.vddPa.power.currentValue =
    -99 + _createFakeData()

  yield put(setSingleDeviceData({ sn, data: currentData }))
}

function* _demoReadOperatingStatus({ sn }) {
  while (true) {
    yield call(_demoReadCurrentOnce, { sn })
    yield delay(1000)
  }
}

export function* currentClearClick(payloads) {
  try {
    const { sn } = payloads

    const isDemoMode = yield call(getIsDemoMode)
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.current.measureDataLength = 0
    currentData.current.temperature.currentValue = 0
    currentData.current.pdt.horizon.currentValue = [null, null, null, null]
    currentData.current.pdt.vertical.currentValue = [null, null, null, null]
    currentData.current.powerConsumption.vdd1v8.current.currentValue = null
    currentData.current.powerConsumption.vdd1v8.voltage.currentValue = null
    currentData.current.powerConsumption.vdd1v8.power.currentValue = null
    currentData.current.powerConsumption.vddPa.current.currentValue = null
    currentData.current.powerConsumption.vddPa.voltage.currentValue = null
    currentData.current.powerConsumption.vddPa.power.currentValue = null
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (!isDemoMode)
      yield call(
        __socket_API_sender,
        localMiddlewareApi.CLOVERCELL_EVB_OPERATING_STATUS_CLEAR_DATA,
        {
          sn,
          lookupID,
        }
      )
  } catch (error) {
    devWarLog('[handler] currentExportClick error:', error)
  }
}

export function* currentExportClick(payloads) {
  try {
    const { sn } = payloads

    let { lookupID } = yield call(getCommonArgs, sn)
    const { success } = yield call(__export_API_sender, {
      api: cloverCellEvb2x2CurrentExportApi,
      data: { sn, lookupID },
    })

    if (success) {
      yield put(modalActions.showModalOfFileExportSuccess())
      yield delay(1000)
      yield put(modalActions.clearNormalModal())
    }
  } catch (error) {
    devWarLog('[handler] currentExportClick error:', error)
  }
}
