import { call, put } from 'redux-saga/effects'
import * as R from 'ramda'
import Decimal from 'decimal.js'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

function* _setCurrentControlModeToChannel({ sn }) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)
  currentData.deviceControl.common.currentControlMode[currentRfMode] = 'channel'

  yield put(setSingleDeviceData({ sn, data: currentData }))
}

export function* _vChannelSyncWithH({ sn }) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)

  const currentPolarSynthesis = currentData.deviceControl.common.polarSynthesis

  const currentControlMode = currentData.deviceControl.common.currentControlMode
  const rfMode = currentData.deviceControl.common.rfMode

  if (
    currentControlMode[rfMode] === 'channel' &&
    currentPolarSynthesis !== null
  ) {
    const currentBoardData = R.clone(
      currentData.deviceControl.channel[currentRfMode]
    )

    const mapIndexed = R.addIndex(R.map)

    //* 如果切到有極化，V Channel 的 Phase 照 H Channel 相對應的 Phase 去加 Phase Delay
    //* 而且強制將全部 Power 開啟
    //* 2023/10/20 早上與 Sam Jacky 討論結論
    const getHBoardArgAsSamePath = (key, bI) =>
      R.path(['horizon', bI, key])(currentBoardData)

    const getHChannelArgAsSamePath = (key, bI, cI) =>
      R.path(['horizon', bI, 'lstChannelData', cI, key])(currentBoardData)

    const calcNewVChannelPhase = (bI, cI) =>
      R.pipe(
        R.add(currentPolarSynthesis),
        R.modulo(R.__, 360)
      )(getHChannelArgAsSamePath('phase', bI, cI))

    // V 全部 Channel 的 Phase = H 相對應 Phase + value
    // 開啟 V 全部 Channel 的 Power
    const updateChannelData = mapIndexed((bE, bI) =>
      //--- 這邊是每一個 boardData
      R.modify(
        'lstChannelData', //--- 修改 boardData 的 channelData
        mapIndexed((cE, cI) =>
          R.pipe(
            R.assoc('phase', calcNewVChannelPhase(bI, cI)),
            R.assoc('totalGain', getHChannelArgAsSamePath('totalGain', bI, cI)),
            R.assoc(
              'elementGain',
              getHChannelArgAsSamePath('elementGain', bI, cI)
            ),
            R.assoc('power', true)
          )(cE)
        )
      )(bE)
    )

    const updateCommonGain = mapIndexed((bE, bI) =>
      R.assoc(
        'commonCurrentGain',
        getHBoardArgAsSamePath('commonCurrentGain', bI)
      )(bE)
    )

    const updateVBoardData = R.pipe(updateCommonGain, updateChannelData)

    // 開啟 H 全部 Channel 的 Power
    const updateHBoardData = R.map(
      R.modify('lstChannelData', R.map(R.assoc('power', true)))
    )

    const newCurrentBoardData = R.pipe(
      R.modify(['vertical'], updateVBoardData),
      R.modify(['horizon'], updateHBoardData)
    )(currentBoardData)

    currentData.deviceControl.channel[currentRfMode] = newCurrentBoardData

    yield put(setSingleDeviceData({ sn, data: currentData }))
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    All Power       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelAllPower(payloads) {
  try {
    const { sn, hvMode, value } = payloads
    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    const currentBoardData = R.clone(
      currentData.deviceControl.channel[currentRfMode]
    )

    const newCurrentBoardData = R.pipe(
      // 這邊是每一個 boardData
      // 修改 boardData 的 channelData
      R.modifyPath(
        [hvMode, 'lstBoardData', 0, 'lstChannelData'],
        R.map(R.assoc('power', value))
      ) // 把 channel 裡面所有的 power map 改成 value
    )(currentBoardData)

    currentData.deviceControl.channel[currentRfMode] = newCurrentBoardData

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(_setCurrentControlModeToChannel, { sn })
    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_EVB_CHANNEL_POWER_ALL,
      {
        sn,
        lookupID,
        hvMode,
        value,
      }
    )
  } catch (error) {
    devWarLog('[handler] channelAllPower error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Single Power    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelSinglePower(payloads) {
  try {
    const { sn, hvMode, boardIndex, channelIndex, value } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    currentData.deviceControl.channel[currentRfMode][hvMode]['lstBoardData'][
      boardIndex
    ]['lstChannelData'][channelIndex].power = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(_setCurrentControlModeToChannel, { sn })

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_EVB_CHANNEL_POWER,
      {
        sn,
        lookupID,
        boardIndex,
        channelIndex,
        hvMode,
        value,
      }
    )
  } catch (error) {
    devWarLog('[handler] channelSinglePower error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Common Gain    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _setCommonGainAndSyncToSingleGain({
  sn,
  hvMode,
  boardIndex,
  commonCurrentGain,
}) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)
  const currentBoardData = R.clone(
    currentData.deviceControl.channel[currentRfMode]
  )

  const getNewElementGain = e =>
    isNaN(+commonCurrentGain) || isNaN(+e.elementGain)
      ? 0
      : new Decimal(+commonCurrentGain).add(+e.elementGain).toNumber()

  const updateSingleGain = R.map(
    R.modify(
      'lstChannelData',
      R.map(e => R.assoc('totalGain', getNewElementGain(e))(e))
    )
  )
  const updateCommonGain = R.assoc('commonCurrentGain', commonCurrentGain)

  const newCurrentBoardData = R.pipe(
    R.modifyPath([hvMode, 'lstBoardData', boardIndex], updateCommonGain),
    R.modifyPath([hvMode, 'lstBoardData'], updateSingleGain)
  )(currentBoardData)

  currentData.deviceControl.channel[currentRfMode] = newCurrentBoardData

  yield put(setSingleDeviceData({ sn, data: currentData }))
}

export function* channelCommonGainSliderChange(payloads) {
  try {
    yield console.log('channelCommonGainSliderChange', payloads)
    const { sn, hvMode, boardIndex, value: commonCurrentGain } = payloads

    yield call(_setCommonGainAndSyncToSingleGain, {
      sn,
      hvMode,
      boardIndex,
      commonCurrentGain,
    })

    // yield call(_vChannelSyncWithH, { sn })
  } catch (error) {
    devWarLog('[handler] channelCommonGainSliderChange error:', error)
  }
}

export function* channelCommonGainChange(payloads) {
  try {
    const {
      sn,
      hvMode,
      boardIndex,
      value: commonCurrentGain,
      isValid,
    } = payloads

    yield call(_setCommonGainAndSyncToSingleGain, {
      sn,
      hvMode,
      boardIndex,
      commonCurrentGain,
    })

    // yield call(_vChannelSyncWithH, { sn })

    if (isValid) {
      yield call(_setCurrentControlModeToChannel, { sn })

      let { lookupID, currentData, currentRfMode } = yield call(
        getCommonArgs,
        sn
      )

      const lstChannelData =
        currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[0]
          .lstChannelData

      const lstElementGains = lstChannelData.map(e => e.elementGain)

      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_EVB_CHANNEL_COMMON_GAIN,
        {
          sn,
          lookupID,
          boardIndex,
          hvMode,
          lstElementGains,
          commonGain: +commonCurrentGain,
        }
      )
    }
  } catch (error) {
    devWarLog('[handler] channelCommonGainChange error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Common Phase    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// ! 後來決定放 common phase 的 Polarization 設定為 premium feature
// ! 且還沒開放，所以先註解
// ! 到時候開放這邊要補接 API
// function* _setCommonPhaseAndSyncToSinglePhase({
//   sn,
//   hvMode,
//   boardIndex,
//   commonCurrentPhase,
// }) {
//   let { currentData, currentRfMode } = yield call(getCommonArgs, sn)
//   const currentBoardData = R.clone(
//     currentData.deviceControl.channel[currentRfMode]
//   )

//   const updateSinglePhase = R.map(
//     R.modify(
//       'lstChannelData',
//       R.map(e =>
//         R.assoc(
//           'totalPhase',
//           R.modulo(+commonCurrentPhase + +e.elementPhase, 360)
//         )(e)
//       )
//     )
//   )
//   const updateCommonPhase = R.assoc('commonCurrentPhase', +commonCurrentPhase)

//   const newCurrentBoardData = R.pipe(
//     R.modifyPath([hvMode, 'lstBoardData', boardIndex], updateCommonPhase),
//     R.modifyPath([hvMode, 'lstBoardData'], updateSinglePhase)
//   )(currentBoardData)

//   currentData.deviceControl.channel[currentRfMode] = newCurrentBoardData

//   yield put(setSingleDeviceData({ sn, data: currentData }))
// }

// export function* channelCommonPhaseSliderChange(payloads) {
//   try {
//     const { sn, hvMode, boardIndex, value: commonCurrentPhase } = payloads

//     yield call(_setCommonPhaseAndSyncToSinglePhase, {
//       sn,
//       hvMode,
//       boardIndex,
//       commonCurrentPhase,
//     })

//     // yield call(_vChannelSyncWithH, { sn })
//   } catch (error) {
//     devWarLog('[handler] channelCommonPhaseSliderChange error:', error)
//   }
// }

// export function* channelCommonPhaseChange(payloads) {
//   try {
//     const {
//       sn,
//       hvMode,
//       boardIndex,
//       value: commonCurrentPhase,
//       isValid,
//     } = payloads

//     yield call(_setCommonPhaseAndSyncToSinglePhase, {
//       sn,
//       hvMode,
//       boardIndex,
//       commonCurrentPhase,
//     })

//     // yield call(_vChannelSyncWithH, { sn })

//     if(isValid){

//     }
//   } catch (error) {
//     devWarLog('[handler] channelCommonPhaseChange error:', error)
//   }
// }

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Single Gain    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelSingleGain(payloads) {
  try {
    const { sn, hvMode, boardIndex, channelIndex, value, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    const commonCurrentGain =
      currentData.deviceControl.channel[currentRfMode][hvMode]['lstBoardData'][
        boardIndex
      ].commonCurrentGain

    currentData.deviceControl.channel[currentRfMode][hvMode]['lstBoardData'][
      boardIndex
    ]['lstChannelData'][channelIndex].totalGain = value

    currentData.deviceControl.channel[currentRfMode][hvMode]['lstBoardData'][
      boardIndex
    ]['lstChannelData'][channelIndex].elementGain = value - commonCurrentGain

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // yield call(_vChannelSyncWithH, { sn })

    if (isValid) {
      yield call(_setCurrentControlModeToChannel, { sn })

      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_EVB_CHANNEL_GAIN,
        {
          sn,
          lookupID,
          boardIndex,
          channelIndex,
          hvMode,
          value: +value,
        }
      )
    }
  } catch (error) {
    devWarLog('[handler] channelSingleGain error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Single Phase    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelSinglePhase(payloads) {
  try {
    const { sn, hvMode, boardIndex, channelIndex, value, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    const commonCurrentPhase =
      currentData.deviceControl.channel[currentRfMode][hvMode][
        'lstBoardData'
      ][0].commonCurrentPhase

    currentData.deviceControl.channel[currentRfMode][hvMode]['lstBoardData'][
      boardIndex
    ]['lstChannelData'][channelIndex].totalPhase = value

    const newElementPhase =
      value >= commonCurrentPhase
        ? +value - +commonCurrentPhase
        : +value + 360 - +commonCurrentPhase

    currentData.deviceControl.channel[currentRfMode][hvMode]['lstBoardData'][
      boardIndex
    ]['lstChannelData'][channelIndex].elementPhase = newElementPhase

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // yield call(_vChannelSyncWithH, { sn })

    if (isValid) {
      yield call(_setCurrentControlModeToChannel, { sn })

      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_EVB_CHANNEL_PHASE,
        {
          sn,
          lookupID,
          boardIndex,
          channelIndex,
          hvMode,
          value: +value,
        }
      )
    }
  } catch (error) {
    devWarLog('[handler] channelSinglePhase error:', error)
  }
}
