import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'

import { BiInfoCircle } from 'react-icons/bi'
import CommonButton from 'src/components/Button/CommonButton'
import {
  pd_changeCaliCheckModal_changeClick_watcher,
  pd_changeCaliCheckModal_noClick_watcher,
} from 'src/redux/actions/powerDetector'

const ChangeAnotherCaliConfigFile = props => {
  const dispatch = useDispatch()

  const handleChangeClick = () =>
    dispatch(pd_changeCaliCheckModal_changeClick_watcher())
  const handleNoClick = () =>
    dispatch(pd_changeCaliCheckModal_noClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white ' />
      </Modal.Icon>

      <Modal.Title>Change another calibration config?</Modal.Title>
      <Modal.Description>
        The currently read data will be cleared. <br />
        Do you want to change it?
      </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton type='general' danger onClick={handleChangeClick}>
          Change
        </CommonButton>
        <CommonButton type='general' onClick={handleNoClick}>
          No
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ChangeAnotherCaliConfigFile
