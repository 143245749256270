import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import CommonInput from 'src/components/Input/CommonInput'
import CommonSelect from 'src/components/Select/CommonSelect'

import { descriptionLengthMax } from 'src/constants/beamformers'
import {
  createConfigInitData,
  convertDataOfBSComponentToBSConfig,
  convertDataOfCHComponentToCHConfig,
} from 'src/funcs/device/bbox'

import { modalActions } from 'src/redux/slices/modal'
import { bbox_deviceDataCurrentConfig_update_watcher } from 'src/redux/actions/beamformers/bboxAction'

const SaveToBeamConfig = props => {
  const dispatch = useDispatch()
  const { sn } = props

  const r_currentDeviceData = useSelector(state => state.deviceData[sn])
  const r_numberOfConfigs =
    r_currentDeviceData.beamConfigEditor.currentConfig.numberOfConfigs

  const r_rfMode = r_currentDeviceData.deviceControl.common.rfMode

  const r_userDefinedOnlyTableData =
    r_currentDeviceData.beamConfigEditor.currentConfig.tableData[r_rfMode]

  // 找到 index 最小又沒有值的 beamID
  // 1. 建立沒 user defined 就塞 beamID 欄位的完整 data
  // 2. 找到沒有 beam_config && channel_config (等於未定義) 的最小 index
  const userDefinedBeamData = beamID =>
    r_userDefinedOnlyTableData.find(e => e.beamID === beamID)
  const fillDefaultTableData = new Array(r_numberOfConfigs)
    .fill('')
    .map((e, i) =>
      userDefinedBeamData(i + 1)
        ? { ...userDefinedBeamData(i + 1) }
        : { beamID: i + 1 }
    )
  const defaultBeamID = R.pipe(
    R.findIndex(e => !e.beam_config && !e.channel_config), // 找空的
    R.ifElse(
      R.equals(-1),
      () => 1, // 筆數都設滿了找不到空的就回第一筆 (回傳 -1, 就設成 1)
      R.inc // 如果有找到就 +1 (id 從 1開始)
    )
  )(fillDefaultTableData)

  const [selectedBeamID, setSelectedBeamID] = useState(defaultBeamID)
  const [description, setDescription] = useState('')

  const renderReplaceWarning =
    r_userDefinedOnlyTableData.find(e => +e.beamID === +selectedBeamID) !==
    undefined

  const isEmpty = beamID =>
    r_userDefinedOnlyTableData.find(e => +e.beamID === +beamID) === undefined

  const currentBeamData = r_userDefinedOnlyTableData.find(
    e => +e.beamID === +selectedBeamID
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    setDescription(currentBeamData?.beam_description || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBeamID])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleDescriptionChange = event => setDescription(event.formattedValue)

  const handleCancelClick = () => dispatch(modalActions.clearNormalModal())

  const handleSubmit = event => {
    try {
      event.preventDefault()

      const { currentControlMode, currentAntenna } =
        r_currentDeviceData.deviceControl.common

      const beamType =
        !currentAntenna || currentControlMode[r_rfMode] === 'channel'
          ? 'channel'
          : 'beam'
      const steeringData = r_currentDeviceData.deviceControl.steering[r_rfMode]
      const channelData =
        r_currentDeviceData.deviceControl.channel[r_rfMode].lstBoardData

      const newBeamData = createConfigInitData({
        beamID: selectedBeamID,
        type: beamType,
        description: description,
        rfMode: r_rfMode,
        currentDeviceData: r_currentDeviceData,
        beam_config:
          beamType === 'beam'
            ? convertDataOfBSComponentToBSConfig({
                componentData: steeringData,
              })
            : null,
        channel_config:
          beamType === 'channel'
            ? convertDataOfCHComponentToCHConfig({
                componentData: channelData,
              })
            : null,
      })

      const currentConfig = r_currentDeviceData.beamConfigEditor.currentConfig
      let newCurrentConfig
      if (renderReplaceWarning)
        newCurrentConfig = R.modifyPath(
          ['tableData', r_rfMode],
          R.map(e => (e.beamID === selectedBeamID ? newBeamData : e))
        )(currentConfig)

      if (!renderReplaceWarning)
        newCurrentConfig = R.modifyPath(
          ['tableData', r_rfMode],
          R.insert(selectedBeamID - 1, newBeamData)
        )(currentConfig)

      dispatch(
        bbox_deviceDataCurrentConfig_update_watcher({
          sn,
          currentConfig: newCurrentConfig,
        })
      )
      dispatch(modalActions.clearNormalModal())
    } catch (error) {
      console.warn(error)
    }
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <Modal>
      <Modal.Title>Save to Beam Config</Modal.Title>

      {renderReplaceWarning && (
        <Modal.Description className='text-yellow'>
          Item has content. Save to replace.
        </Modal.Description>
      )}

      <form onSubmit={handleSubmit} className='mt-6'>
        <Modal.FormLabel>Beam ID</Modal.FormLabel>
        <CommonSelect
          optionsContainerClassName='z-20 max-h-[300px]'
          size='md'
          value={selectedBeamID}
          onChange={(event, name) => setSelectedBeamID(name)}>
          {new Array(r_numberOfConfigs).fill(null).map((e, i) => (
            <CommonSelect.Option
              key={`selectedBeamID-${i}`}
              name={i + 1}
              className='relative'>
              <span>{i + 1}</span>{' '}
              {isEmpty(i + 1) && (
                <em className='absolute right-3 text-white/40 italic'>Empty</em>
              )}
            </CommonSelect.Option>
          ))}
        </CommonSelect>

        <Modal.FormLabel className='mt-6'>Description</Modal.FormLabel>
        <CommonInput
          value={description}
          placeholder='Optional'
          danger={description.length > descriptionLengthMax}
          onChange={handleDescriptionChange}
        />

        <div className='flex justify-between text-white/50 text-sm mt-2'>
          <small>Add description for easy identification</small>
          <small>
            {description.length} / {descriptionLengthMax}
          </small>
        </div>

        <Modal.RowButtonBox>
          <CommonButton size='lg' type='general' onClick={handleCancelClick}>
            Cancel
          </CommonButton>
          <CommonButton
            size='lg'
            type='primary'
            buttonType='submit'
            disabled={description.length > descriptionLengthMax}>
            Save
          </CommonButton>
        </Modal.RowButtonBox>
      </form>
    </Modal>
  )
}

export default SaveToBeamConfig
