import {
  getDefaultCurrentFreq,
  getLstFreqOptions,
} from '../../configs/spec.mjs'
import Controllers from '../../Controllers/index.mjs'

const getDT = sn => Controllers._getDeviceType(sn)

export const cloverCell1024InitAppendData = sn => ({
  deviceControl: {
    common: {
      rfMode: 'standby', // tx, rx, standby
      currentSgFreq: 24000, // int
      sgFreqMin: 720,
      sgFreqMax: 24000,
      currentFreq: getDefaultCurrentFreq[getDT(sn)],
      lstFreqOptions: getLstFreqOptions[getDT(sn)],
      currentControlMode: { tx: 'channel', rx: 'channel' }, // 'steering' || 'channel'
    },
    // ! 新增
    steering: {
      tx: {
        horizon: {
          theta: 0,
          thetaMax: 60, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
        vertical: {
          theta: 0,
          thetaMax: 60, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
      },
      rx: {
        horizon: {
          theta: 0,
          thetaMax: 40, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
        vertical: {
          theta: 0,
          thetaMax: 40, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
      },
    },
    channel: {
      tx: {
        horizon: true,
        vertical: true,
      },
      rx: {
        horizon: true,
        vertical: true,
      },
    },
  },
})
