import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiInfoCircle } from 'react-icons/bi'

import {
  pd_customCaliModal_clearIncompleteDataChildModal_clearClick_watcher,
  pd_customCaliModal_clearIncompleteDataChildModal_cancelClick_watcher,
} from 'src/redux/actions/powerDetector'

const ClearIncompleteData = props => {
  const dispatch = useDispatch()

  const handleClearClick = () =>
    dispatch(
      pd_customCaliModal_clearIncompleteDataChildModal_clearClick_watcher()
    )

  const handleCancelClick = () =>
    dispatch(
      pd_customCaliModal_clearIncompleteDataChildModal_cancelClick_watcher()
    )

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white' />
      </Modal.Icon>

      <Modal.Title>Clear incomplete data?</Modal.Title>

      <Modal.Description>
        The frequency points of the yellow outline have not been set. Incomplete
        items will be cleared.
      </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='general'
          danger
          onClick={handleClearClick}>
          Clear
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ClearIncompleteData
