import React from 'react'
import { useDispatch } from 'react-redux'

import { FloatingPortal, FloatingOverlay } from '@floating-ui/react'
import CommonButton from 'src/components/Button/CommonButton'
import { welcomeDemoModal_okClick_watcher } from 'src/redux/actions/facility/init'

const WelcomeDemo = props => {
  const dispatch = useDispatch()

  const handleOKClick = () => dispatch(welcomeDemoModal_okClick_watcher())

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={overlayClass}>
        <h4 className='text-light-4 text-2xl font-medium mb-8'>
          Welcome to our DEMO MODE{' '}
        </h4>

        <p className='text-light-4 text-xl font-medium text-center'>
          Feel free to check out all the products and features! but keep in
          mind:
          <br />{' '}
          <span className='text-yellow'>
            the data on the screen is pure fiction.
          </span>
          <br /> For accurate information, check out the product specs sheet.
        </p>

        <CommonButton
          type='general'
          size='lg'
          className='mt-10'
          autoFocus={true}
          onClick={handleOKClick}>
          OK
        </CommonButton>
      </FloatingOverlay>
    </FloatingPortal>
  )
}

WelcomeDemo.propTypes = {}

export default WelcomeDemo

const overlayClass = `
                    fixed 
                    inset-0 
                    bg-black/80 
                    z-10 
                    flex flex-col 
                    items-center justify-center 
                    duration-[5000ms] 
                    backdrop-blur-sm 
                    px-6
`
