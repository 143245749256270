import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import * as R from 'ramda'
import PropTypes from 'prop-types'

import Avatar from 'src/containers/home/Main/Avatar'
import Notification from 'src/containers/home/Main/Notification'
import Divider from 'src/containers/home/Main/Divider'
import ConnectionInfo from 'src/containers/home/Main/ConnectionInfo'
import Version from 'src/containers/home/Main/Version'
import InitFailed from 'src/containers/home/Main/InitFailed'

import { textBox, title, content } from 'src/containers/home/style'

import { getDeviceInfo, getOfficialFullName } from 'src/funcs/getDeviceInfo'

import LightForDeviceStatus from 'src/components/Light/LightForDeviceStatus'

const Card = ({
  sn,
  deviceType,
  notification,
  initialization,
  connectionInfo,
  versionInfo,
  showInfo,
}) => {
  const { showDeviceInfo } = useSelector(state => state.homeUiControl)
  const { mode } = useParams()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        Func        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const showNotification = data => R.pipe(R.values(), R.includes(true))(data)

  const deviceOfficialFullName = (sn, deviceType) =>
    getOfficialFullName(sn, deviceType) || 'Name Not Found'
  const devNameFirst = deviceType => getDeviceInfo[deviceType]?.devName.first

  const linkUrl = `/operating/${mode}/${devNameFirst(deviceType)}/${sn}`

  const isInitFailed = initialization === 'failed'

  const showInfoCard = mode !== 'demo'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const avatarShow = showInfo ? `${toBack}` : `${toFront} `
  const avatarCard = `${cardCommon} ${avatarShow} `

  const infoShow = showInfo ? `${toFront}` : `${toBack} `
  const infoCard = `${cardCommon} ${infoShow} p-[28px] `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={cardBox} key={'home card' + sn}>
      {/* avatarCard */}
      <Link to={linkUrl}>
        <div className={avatarCard} style={rotateStyle(!showInfo)}>
          {!showDeviceInfo && (
            <>
              <Avatar sn={sn} deviceType={deviceType} />

              <div className={textBox + ' px-7 py-7 mb-0'}>
                <h3 className={title + ' font-bold flex items-center'}>
                  {deviceOfficialFullName(sn, deviceType)}
                  <LightForDeviceStatus sn={sn} className='ml-2' />
                </h3>
                <p className={content}>{sn}</p>
              </div>

              {showNotification(notification) && (
                <Notification sn={sn} data={notification} />
              )}
            </>
          )}
        </div>
      </Link>

      {/* infoCard */}
      {showInfoCard && (
        <div className={infoCard} style={rotateStyle(showInfo)}>
          {showDeviceInfo && (
            <>
              <div className='flex items-center gap-x-2 mb-7 '>
                <div className={iconBox}>
                  {getDeviceInfo[deviceType]?.icon(sn)}
                </div>

                <div className={textBox}>
                  <h3 className={title + ' font-bold'}>
                    {deviceOfficialFullName(deviceType)}
                  </h3>

                  <p className={content}>{sn}</p>
                </div>
              </div>

              {isInitFailed ? (
                <>
                  <InitFailed sn={sn} deviceType={deviceType} />
                </>
              ) : (
                <>
                  {!R.isNil(connectionInfo) && (
                    <ConnectionInfo sn={sn} connectionInfo={connectionInfo} />
                  )}

                  {!R.isNil(connectionInfo) && !R.isNil(versionInfo) && (
                    <Divider />
                  )}

                  {!R.isNil(versionInfo) && (
                    <Version sn={sn} data={versionInfo} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

Card.propTypes = {
  sn: PropTypes.string,
  deviceType: PropTypes.number,
  notification: PropTypes.object,
  connectionInfo: PropTypes.object,
  versionInfo: PropTypes.object,
  showInfo: PropTypes.bool,
}

export default Card

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const cardBox = `
                relative 
                w-[256px] lg:w-[336px] 
                pt-4 
                justify-center 
                group
                `

const cardCommon = `
                    flex flex-col
                    bg-dark-2 
                    rounded-md 
                    will-change-transform
                    `

const toBack = `
                absolute -z-10 top-0
                inset-x-0
                mx-auto
                w-[240px] lg:w-[312px]
                h-[200px] 
                after:content-[''] 
                after:absolute 
                after:inset-0 
                after:bg-dark-2 
                after:backdrop-blur
                after:rounded-md
                after:shadow-[0px_0px_8px_rgba(0,0,0,0.25),_inset_0px_0px_8px_rgba(75,99,161,0.3)]
                `
const toFront = `
                relative z-10 
                shadow-[0px_-4px_20px_rgba(0,0,0,0.25),_inset_0px_0px_8px_rgba(75,99,161,0.25)]
                w-full
                h-full
                `

const rotateStyle = toFront => ({
  backfaceVisibility: 'visible',
  transform: `rotateZ(${toFront ? '0' : '180deg'})`,
})

const iconBox = `w-14 h-14 shrink-0 flex items-center justify-center`
