import React from 'react'
import PropTypes from 'prop-types'
import { RotatingLines } from 'react-loader-spinner'

const LoadingIcon = ({ visible = false }) => (
  <RotatingLines
    strokeColor='black'
    strokeWidth='4'
    animationDuration='1'
    width='10'
    visible={visible}
  />
)

export default LoadingIcon

LoadingIcon.propTypes = {
  visible: PropTypes.bool,
}
