import React from 'react'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import NI from 'src/assets/svg/coBranding/NI'

const CoBrandingBar = props => {
  const { current } = useGetCurrentDeviceData()
  const { coBranding } = current

  const containerClass = `${containerCommon} ${bgColor[coBranding]} `

  if (coBranding)
    return <div className={containerClass}>{icon[coBranding]}</div>

  return <></>
}

CoBrandingBar.propTypes = {}

export default CoBrandingBar

const containerCommon = `w-full p-2 flex justify-end rounded-[6px] backdrop-blur-sm`

const bgColor = { ni: 'bg-[rgba(4,65,35,0.40)]' }

const icon = { ni: <NI /> }
