import React, { useState, useRef, useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { mergeRefs } from 'react-merge-refs'
import {
  useFloating,
  useInteractions,
  FloatingPortal,
  useClick,
  useRole,
  useDismiss,
  offset,
  flip,
  shift,
  autoUpdate,
} from '@floating-ui/react'

import { SelectContext } from './context'

import Option from 'src/components/Select/CommonSelect/Option'
import Divider from 'src/components/Select/CommonSelect/Divider'

import { BiChevronDown } from 'react-icons/bi'

import { tryCatch } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <CommonSelect
//  value={} // str
//  currentClassName='' // str
//  size="" // sm, md
//  icon={} // jsx
//  currentChildren={}  // jsx
//  disabled={} // bool
//  onChange={(event, value, children)=>{}} // func
//  >
//  <CommonSelect.Option name='sm'>sm</CommonSelect.Option>
//  <CommonSelect.Option name='md'>md</CommonSelect.Option>
//  <CommonSelect.Option name='lg'>lg</CommonSelect.Option>
//  <CommonSelect.Divider />
//  <CommonSelect.Option name='xxx'>xxx</CommonSelect.Option>
//  <CommonSelect.Option name=''></CommonSelect.Option>
// </CommonSelect>

const CommonSelect = forwardRef((props, ref) => {
  const {
    className = '',
    currentClassName = '',
    optionsContainerClassName = '',
    size = 'sm',
    icon,
    value: currentValue,
    currentChildren,
    disabled,
    onChange,
    children,
  } = props
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [extend, setExtend] = useState(false)

  const currentRef = useRef(null)

  const { x, y, refs, strategy, context } = useFloating({
    open: extend,
    placement: 'bottom',
    onOpenChange: setExtend,
    strategy: 'absolute',
    middleware: [
      offset({ mainAxis: 4, crossAxis: -14 }),
      flip(),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: !disabled }),
    useRole(context, { role: 'menu' }),
    useDismiss(context, { referencePress: true }),
  ])

  const referenceRef = useMemo(
    () => mergeRefs([refs.setReference, currentRef]),
    [refs.setReference, currentRef]
  )

  const contextProps = {
    containerDisabled: disabled,
    size,
    extend,
    currentValue,
    handleChange,
  }

  const referenceArgs = currentRef?.current?.getBoundingClientRect()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  function handleChange(event, value, children) {
    tryCatch(() => {
      setExtend(false)

      if (!disabled && value !== currentValue) onChange(event, value, children)
    })
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `${className} ${containerCommon}`

  const currentContainerClass = `
                        ${currentClassName}
                        ${currentCommon}
                        ${currentTheme(extend, disabled)} 
                        ${currentSize[size]} 
                        ${cursor(disabled)} 
                        `

  const currentArrowBox = `
                          ${arrowRotate(extend)} 
                          w-4 h-4 
                          ml-1 my-1 
                          text-sm 
                          flex items-center justify-center shrink-0
                          origin-center 
                          duration-500
                          `

  const optionsBoxClass = `
                        ${optionsContainerClassName} 
                        py-3 
                        border-1 border-solid border-dark-5 rounded-[2px] 
                        bg-dark-4 
                        shadow-sm shadow-black/20
                        flex flex-col items-start 
                        will-change-transform 
                        scrollbar
                        `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <SelectContext.Provider value={contextProps}>
      <div className={containerClass} ref={ref}>
        {/* current */}
        <div
          className={currentContainerClass}
          ref={referenceRef}
          {...getReferenceProps()}>
          {icon && <div className={currentIconClass}>{icon}</div>}

          <div className={currentTextClass}>
            {currentChildren || currentValue}
          </div>

          <div className={currentArrowBox}>
            <BiChevronDown className='scale-125' />
          </div>
        </div>

        {/* option container*/}
        {extend && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              className={optionsBoxClass}
              style={{
                width: referenceArgs?.width + 28 + 'px',
                position: strategy,
                top: 0,
                left: 0,
                transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
              }}
              {...getFloatingProps()}>
              {children}
            </div>
          </FloatingPortal>
        )}
      </div>
    </SelectContext.Provider>
  )
})

CommonSelect.Option = Option
CommonSelect.Divider = Divider

export default CommonSelect

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = `
                        relative 
                        cursor-pointer 
                        whitespace-nowrap 
                        select-none 
                        `

const currentCommon = `
                      w-full 
                      flex items-center justify-between 
                      border-1 border-solid rounded-[2px] 
                      duration-500 
                      px-2 
                      text-sm leading-4 font-normal 
                      `
const currentTheme = (extend, disabled) => {
  if (disabled) return `bg-dark-4 border-dark-5 text-white/50`
  if (extend) return `bg-dark-4 border-teal text-white`
  return `bg-dark-1 border-dark-3 hover:border-dark-5 active:border-teal text-white`
}
const currentSize = {
  sm: 'h-6 ',
  md: 'h-8 ',
  lg: 'h-10 ',
}

const arrowRotate = extend => extend && '-rotate-180 '

const currentIconClass =
  'w-4 h-4 mr-2 flex items-center justify-center shrink-0 text-xl'

const currentTextClass = 'w-full -mb-[1px] text-ellipsis overflow-hidden'

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonSelect.propTypes = {
  className: PropTypes.string,
  currentClassName: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  icon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultCurrentChildren: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
