import React from 'react'
import SegmentedButton from 'src/components/Button/SegmentedButton'

const HVModeButtons = ({
  isOffH,
  isOffV,
  dragHVMode,
  handleDragHVModeChange,
}) => (
  <SegmentedButton
    size='sm'
    type='outlined' // 'outlined' : 'solid'
    className='absolute w-[230px] top-6 inset-x-0 mx-auto z-10'
    value={isOffH && isOffV ? null : dragHVMode}
    onChange={(event, value) => handleDragHVModeChange(value)}>
    <SegmentedButton.Option name='horizon' disabled={isOffH}>
      H-Polarization
    </SegmentedButton.Option>
    <SegmentedButton.Option name='vertical' disabled={isOffV}>
      V-Polarization
    </SegmentedButton.Option>
  </SegmentedButton>
)

HVModeButtons.propTypes = {}

export default HVModeButtons
