import React from 'react'

import SignalGeneratorFrequency from 'src/containers/operating/device/beamformers/cloverCell/array1024/Common/SignalGeneratorFrequency'
import RfMode from 'src/containers/operating/device/beamformers/cloverCell/array1024/Common/RfMode'
import CentralFrequency from 'src/containers/operating/device/beamformers/cloverCell/array1024/Common/CentralFrequency'

import {
  divider,
  container,
} from 'src/containers/operating/device/beamformers/style'
import ExternalControl from './ExternalControl'

const Common = props => {
  return (
    <div className={container}>
      <div className='grid grid-cols-2 gap-x-4'>
        <SignalGeneratorFrequency />

        <ExternalControl />
      </div>

      <div className={divider + ' my-4'} />

      <div className='grid grid-cols-2 gap-x-4'>
        <RfMode />

        <CentralFrequency />
      </div>
    </div>
  )
}

Common.propTypes = {}

export default Common
