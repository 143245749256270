export const udmoduleInitAppendData = sn => ({
  current: {
    freq: {
      RF: '', // int
      IF: '', // int
      LO: '', // int
      isHarmonic: false, // bool
    },
    ledIndicators: {
      status: 0, //  0: 正常(綠 - 恆亮), 1: 處理中(黃 - 閃爍), 2: 錯誤(紅 - 恆亮)
      LO: 0, //  0: 鎖定(綠 - 恆亮), 1: 非鎖定(紅 - 恆亮)
      ref: 0, //  0: internal(綠 - 恆亮), 1: external (黃 - 恆亮)
    },
  },

  settings: {
    freq: {
      isSupportFreqUnlock: true,
      bandwidth: { isCustom: false, value: '' }, // int
      unit: 'MHz', // str
      RF: {
        value: '',
        deviceMax: '', // int
        deviceMin: '', // int
        userMax: '', // int
        userMin: '', // int
      },
      IF: {
        value: '',
        deviceMax: '', // int
        deviceMin: '', // int
        userMax: '', // int
        userMin: '', // int
      },
      LO: {
        currentSelection: '', // "LSI" or "HSI"
        deviceMin: '',
        deviceMax: '',
        userMax: '',
        userMin: '',
        LSI: {
          value: '', // int
          disabled: {
            range: true,
            resolution: true,
          },
        },
        HSI: {
          value: '', // int
          disabled: {
            range: true,
            resolution: true,
          },
        },
      },
    },
    device: {
      refSource: 0, // 0: internal, 1: external ,
      internal: {
        isEnable: false,
        lstFreqOptions: [10000, 100000], // kHz
        currentSelection: 10000,
      },
      external: {
        lstFreqOptions: [10000], // kHz
        currentSelection: 10000,
      },
    },
  },
})
