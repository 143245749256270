import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'
import InputGroup from 'src/components/Input/InputGroup'

import { getThetaPhiPhaseWarningText } from 'src/constants/beamformers'

import {
  getBeamIndex,
  getBeamName,
  isIncident,
  isReflector,
} from 'src/funcs/device/ris'

import Theta from 'src/components/composite/InputGroupAppendDialog/Theta'
import Phi from 'src/components/composite/InputGroupAppendDialog/Phi'

import { BiMoveHorizontal } from 'react-icons/bi'

const InputControl = ({
  deviceType,
  targetBeam,
  thetaI,
  phiI,
  thetaMaxI,
  thetaR,
  phiR,
  thetaMaxR,
  distance,
  distanceMin,
  distanceMax,

  handleInputChange,
  handleInputFocus,
  handleDistanceChange,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const _getTheta = targetBeam => {
    if (isIncident(targetBeam)) return thetaI[getBeamIndex(targetBeam)]
    if (isReflector(targetBeam)) return thetaR[getBeamIndex(targetBeam)]
  }
  const _getThetaMax = targetBeam => {
    if (isIncident(targetBeam)) return thetaMaxI[getBeamIndex(targetBeam)]
    if (isReflector(targetBeam)) return thetaMaxR[getBeamIndex(targetBeam)]
  }

  const iClassProps = index => ({
    isActive: isIncident(targetBeam),
  })
  const dClassProps = () => ({
    isActive: isIncident(targetBeam),
  })
  const rClassProps = index => ({
    isActive: targetBeam === `reflector_${index}`,
  })

  const [warning, setWarning] = useState('')

  const distanceInputProps = {
    size: 'lg',
    icon: <BiMoveHorizontal className='text-light-4 text-xl' />,
    unit: 'm',
    step: 0.01,
    loop: false,
    validMin: distanceMin[0],
    validMax: distanceMax[0],
    decimalScale: 2,
    value: distance[0],
  }

  const overwriteElements = (
    <div>
      <InputGroup warning={true} disabled={true} {...distanceInputProps} />
    </div>
  )
  const warningText = getThetaPhiPhaseWarningText({
    min: distanceMin[0],
    step: 0.01,
  })

  const distanceDialogProps = {
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: overwriteElements,
    dialogText: warningText[warning],
    warning: warning,
    setWarning: result => setWarning(result),
    setFocus: () => handleInputFocus({ targetBeam: `incident_0` }),
    changeCallback: ({ value, isValid }) =>
      handleDistanceChange({ value, index: 0, isValid }),
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='flex gap-x-4 '>
        {/* Incident */}
        {/* Incident */}
        {/* Incident */}
        {thetaI.map((e, i, a) => (
          <div
            key={`beam steering input incident ${i}`}
            className='flex flex-col gap-y-3'
            onClick={() => handleInputFocus({ targetBeam: `incident_${i}` })}>
            <h6 className={subtitle(iClassProps(i))}>
              Incident {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
            </h6>

            <div className={itemBox(iClassProps(i))}>
              <Theta
                value={String(_getTheta(`incident_${i}`)) || ''}
                thetaMax={_getThetaMax(`incident_${i}`)}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'theta',
                    targetBeam: `incident_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>

            <div className={itemBox(iClassProps(i))}>
              <Phi
                value={+phiI[i]}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'phi',
                    targetBeam: `incident_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>
          </div>
        ))}

        {/* Distance */}
        {/* Distance */}
        {/* Distance */}
        <div onClick={() => handleInputFocus({ targetBeam: `incident_0` })}>
          <div className={`${itemBox(dClassProps())} gap-y-3`}>
            <h6 className={subtitle(dClassProps())}>INC-REF Distance</h6>

            <InputGroupAppendDialog
              {...distanceInputProps}
              {...distanceDialogProps}
            />
          </div>
        </div>

        {/* Reflector */}
        {/* Reflector */}
        {/* Reflector */}
        {thetaR.map((e, i, a) => (
          <div
            key={`beam steering input reflector ${i}`}
            className='flex flex-col gap-y-3'
            onClick={() => handleInputFocus({ targetBeam: `reflector_${i}` })}>
            <h6 className={subtitle(rClassProps(i))}>
              Reflector {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
            </h6>

            <div className={itemBox(rClassProps(i))}>
              <Theta
                value={String(_getTheta(`reflector_${i}`)) || ''}
                thetaMax={_getThetaMax(`reflector_${i}`)}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'theta',
                    targetBeam: `reflector_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>

            <div className={itemBox(rClassProps(i))}>
              <Phi
                value={+phiR[i]}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'phi',
                    targetBeam: `reflector_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    relative pt-7 flex flex-col items-center
                    `

const subtitle = ({ isActive, isOff }) =>
  `${!isOff && isActive ? 'text-teal' : 'text-light-5'} 
                                font-sm font-bold text-center duration-500`
const itemBox = ({ isActive, isOff }) => `
                                ${
                                  !isOff && isActive
                                    ? 'opacity-100 drop-shadow-md'
                                    : 'opacity-50 drop-shadow-2xl'
                                } 
                                flex flex-col w-full 
                                duration-500
                                `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  phi: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleInputChange: PropTypes.func,
}
