import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const LEDLights = props => {
  const { channel, className } = props

  return (
    <SVGUniqueID>
      <svg
        width='203'
        height='98'
        viewBox='0 0 203 98'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        {/* 右一 */}
        <path
          d='M121.487 24.8511C121.541 26.7207 120.368 28.0846 119.081 28.1216C117.793 28.1587 116.543 26.8645 116.49 24.995C116.436 23.1255 117.609 21.7616 118.896 21.7245C120.184 21.6875 121.434 22.9816 121.487 24.8511Z'
          fill='#4F4F4F'
        />
        <path
          d='M121.487 24.8511C121.541 26.7207 120.368 28.0846 119.081 28.1216C117.793 28.1587 116.543 26.8645 116.49 24.995C116.436 23.1255 117.609 21.7616 118.896 21.7245C120.184 21.6875 121.434 22.9816 121.487 24.8511Z'
          fill='#4C4F60'
        />
        <path
          d='M121.487 24.8511C121.541 26.7207 120.368 28.0846 119.081 28.1216C117.793 28.1587 116.543 26.8645 116.49 24.995C116.436 23.1255 117.609 21.7616 118.896 21.7245C120.184 21.6875 121.434 22.9816 121.487 24.8511Z'
          stroke='#4F4F4F'
        />
        <path
          d='M121.487 24.8511C121.541 26.7207 120.368 28.0846 119.081 28.1216C117.793 28.1587 116.543 26.8645 116.49 24.995C116.436 23.1255 117.609 21.7616 118.896 21.7245C120.184 21.6875 121.434 22.9816 121.487 24.8511Z'
          stroke='#4C4F60'
        />

        {/* 右二 */}
        <path
          d='M112.491 25.1099C112.545 26.9795 111.372 28.3434 110.084 28.3804C108.797 28.4175 107.547 27.1233 107.493 25.2538C107.44 23.3843 108.613 22.0204 109.9 21.9833C111.188 21.9463 112.438 23.2404 112.491 25.1099Z'
          fill='#4F4F4F'
        />
        <path
          d='M112.491 25.1099C112.545 26.9795 111.372 28.3434 110.084 28.3804C108.797 28.4175 107.547 27.1233 107.493 25.2538C107.44 23.3843 108.613 22.0204 109.9 21.9833C111.188 21.9463 112.438 23.2404 112.491 25.1099Z'
          fill='#4C4F60'
        />
        <path
          d='M112.491 25.1099C112.545 26.9795 111.372 28.3434 110.084 28.3804C108.797 28.4175 107.547 27.1233 107.493 25.2538C107.44 23.3843 108.613 22.0204 109.9 21.9833C111.188 21.9463 112.438 23.2404 112.491 25.1099Z'
          stroke='#4F4F4F'
        />
        <path
          d='M112.491 25.1099C112.545 26.9795 111.372 28.3434 110.084 28.3804C108.797 28.4175 107.547 27.1233 107.493 25.2538C107.44 23.3843 108.613 22.0204 109.9 21.9833C111.188 21.9463 112.438 23.2404 112.491 25.1099Z'
          stroke='#4C4F60'
        />

        {/* 右三 */}
        {/* blue lights */}
        <path
          d='M103.495 25.3692C103.549 27.2387 102.375 28.6026 101.088 28.6397C99.8008 28.6768 98.5509 27.3826 98.4971 25.5131C98.4433 23.6435 99.6167 22.2796 100.904 22.2426C102.191 22.2055 103.441 23.4997 103.495 25.3692Z'
          fill='#0064FF'
          className='brightness-200'
        />
        <path
          d='M103.495 25.3692C103.549 27.2387 102.375 28.6026 101.088 28.6397C99.8008 28.6768 98.5509 27.3826 98.4971 25.5131C98.4433 23.6435 99.6167 22.2796 100.904 22.2426C102.191 22.2055 103.441 23.4997 103.495 25.3692Z'
          fill='#0064FF'
          className='brightness-200'
        />
        <path
          d='M103.495 25.3692C103.549 27.2387 102.375 28.6026 101.088 28.6397C99.8008 28.6768 98.5509 27.3826 98.4971 25.5131C98.4433 23.6435 99.6167 22.2796 100.904 22.2426C102.191 22.2055 103.441 23.4997 103.495 25.3692Z'
          stroke='#0064FF'
          className='brightness-200'
        />
        <path
          d='M103.495 25.3692C103.549 27.2387 102.375 28.6026 101.088 28.6397C99.8008 28.6768 98.5509 27.3826 98.4971 25.5131C98.4433 23.6435 99.6167 22.2796 100.904 22.2426C102.191 22.2055 103.441 23.4997 103.495 25.3692Z'
          stroke='#0064FF'
          className='brightness-200'
        />

        {/* 中間 */}
        <path
          d='M94.4987 25.628C94.5525 27.4975 93.3791 28.8614 92.0918 28.8985C90.8045 28.9355 89.5546 27.6414 89.5008 25.7719C89.447 23.9023 90.6203 22.5384 91.9077 22.5014C93.195 22.4643 94.4449 23.7585 94.4987 25.628Z'
          fill='#4F4F4F'
        />
        <path
          d='M94.4987 25.628C94.5525 27.4975 93.3791 28.8614 92.0918 28.8985C90.8045 28.9355 89.5546 27.6414 89.5008 25.7719C89.447 23.9023 90.6203 22.5384 91.9077 22.5014C93.195 22.4643 94.4449 23.7585 94.4987 25.628Z'
          fill='#4C4F60'
        />
        <path
          d='M94.4987 25.628C94.5525 27.4975 93.3791 28.8614 92.0918 28.8985C90.8045 28.9355 89.5546 27.6414 89.5008 25.7719C89.447 23.9023 90.6203 22.5384 91.9077 22.5014C93.195 22.4643 94.4449 23.7585 94.4987 25.628Z'
          stroke='#4F4F4F'
        />
        <path
          d='M94.4987 25.628C94.5525 27.4975 93.3791 28.8614 92.0918 28.8985C90.8045 28.9355 89.5546 27.6414 89.5008 25.7719C89.447 23.9023 90.6203 22.5384 91.9077 22.5014C93.195 22.4643 94.4449 23.7585 94.4987 25.628Z'
          stroke='#4C4F60'
        />

        {/* 左三 */}
        <path
          d='M85.5024 25.8873C85.5562 27.7568 84.3828 29.1207 83.0955 29.1578C81.8081 29.1948 80.5582 27.9007 80.5044 26.0311C80.4506 24.1616 81.624 22.7977 82.9113 22.7606C84.1987 22.7236 85.4486 24.0178 85.5024 25.8873Z'
          fill={channel === 'dual' ? 'white' : 'transparent'}
          stroke={channel === 'dual' ? 'white' : 'transparent'}
        />

        {/* 左二 */}
        <path
          d='M76.5063 26.1461C76.5601 28.0156 75.3867 29.3795 74.0994 29.4166C72.812 29.4536 71.5622 28.1595 71.5083 26.2899C71.4545 24.4204 72.6279 23.0565 73.9152 23.0194C75.2026 22.9824 76.4525 24.2765 76.5063 26.1461Z'
          fill='white'
          stroke='white'
        />

        {/* 左一  */}
        {/* red lights */}
        <path
          d='M65.1174 30.1751C66.7736 30.1275 68.0685 28.433 68.0097 26.3905C67.9509 24.3479 66.5607 22.7308 64.9045 22.7784C63.2484 22.8261 61.9534 24.5206 62.0122 26.5631C62.071 28.6056 63.4613 30.2228 65.1174 30.1751Z'
          fill='#EB5757'
        />
      </svg>
    </SVGUniqueID>
  )
}
export default LEDLights
