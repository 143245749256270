import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import { RotatingLines } from 'react-loader-spinner'

import { modalActions } from 'src/redux/slices/modal'

import { h6 } from 'src/containers/Modal/style'

import { HiRefresh } from 'react-icons/hi'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

const UnlockRequesting = ({ sn, RF, IF, LO }) => {
  const dispatch = useDispatch()

  const { isDemoMode } = useGetCurrentMode()

  const hadValue = LO?.userMin && LO?.userMax

  useEffect(() => {
    if (isDemoMode)
      dispatch(
        modalActions.setModalOfUDUnlockDigest({
          digest:
            'FakeLicenseKeyZIu8PYPBbpSgcjOUW7iwXw6EwadhXYgBheHCdJVoa6QlItXW52dxEwRAdqXqnMfitI',
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal>
      <Modal.Icon>
        <HiRefresh className='text-white ' />
      </Modal.Icon>

      <Modal.Title>Device rebooting...</Modal.Title>

      <Modal.Description>
        Activate new frequency after rebooting device
      </Modal.Description>

      <div>
        {hadValue && (
          <>
            <p className={h6 + ' text-light-4 mt-1 text-center'}>
              RF range {RF?.userMin / 1000} - {RF?.userMax / 1000} MHz
            </p>
            <p className={h6 + ' text-light-4 mt-1 text-center'}>
              IF range {IF?.userMin / 1000} - {IF?.userMax / 1000} MHz
            </p>
            <p className={h6 + ' text-light-4 mt-1 text-center'}>
              LO range {LO?.userMin / 1000} - {LO?.userMax / 1000} MHz
            </p>
          </>
        )}

        <div className='w-full mt-6 flex justify-center'>
          <RotatingLines
            width='56'
            strokeColor='white'
            strokeWidth='3'
            animationDuration='0.7'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      </div>
    </Modal>
  )
}

export default UnlockRequesting
