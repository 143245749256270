import React from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/freqConverter/style'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

const OutputMethod = props => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const isOutputting = current.data.settings.output.isOutputting
  const method = current.data?.settings.output.outputControls.method

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleChange = (event, value) => {
    let newData = R.clone(current.data)
    newData.settings.output.outputControls.method = value

    // spec 只有 sweep 模式才有支援 'dynamic'
    if (value === 'continuous') newData.settings.output.frequency.type = 'fixed'

    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='mb-4'>
      <h6 className={h6 + ' text-light-1 mb-1'}>Output Method</h6>

      <SegmentedButton
        size={isXL || is2XL ? 'sm' : 'md'}
        type='outlined'
        value={method}
        disabled={isOutputting}
        onChange={handleChange}>
        <SegmentedButton.Option name='continuous'>
          Continuous
        </SegmentedButton.Option>
        <SegmentedButton.Option name='sweep'>Sweep</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

export default OutputMethod
