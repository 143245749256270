import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import { SegmentContext } from './context'

import { isType } from 'src/funcs/tools'

const Option = props => {
  const { name, disabled = false, className = '', children } = props

  const { containerDisabled, type, size, value, handleChange } =
    useContext(SegmentContext)

  const isActive = isType('array')(value)
    ? value.includes(name)
    : value === name
  const isDisabled = containerDisabled || disabled

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = event => {
    if (!disabled) handleChange(event, name)
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const btnClass = `
                    ${className} 
                    ${common} 
                    ${paddingSize[size]} 
                    ${theme(type, isActive, isDisabled)} 
                    ${cursor(isDisabled)}
                    ${rounded(isActive)}
                    `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <button className={btnClass} onClick={handleClick}>
      {children}
    </button>
  )
}

export default Option

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const common = `flex items-center justify-center text-sm leading-4 font-normal duration-500`
const paddingSize = { sm: 'min-h-[24px] px-3 ', md: 'min-h-[32px] px-6 ' }

const theme = (type, active, disabled) => {
  if (type === 'outlined') {
    if (active && disabled) return outlinedActiveAndDisabled
    if (disabled) return outlinedDisabled
    if (active) return outlinedActive
    return outlined
  }

  // type === 'solid'
  if (active && disabled) return solidActiveAndDisabled
  if (disabled) return solidDisabled
  if (active) return solidActive
  return solid
}

const rounded = active =>
  !active ? 'first:rounded-l-[2px] last:rounded-r-[2px]' : 'rounded-[2px]'

const typeCommon = `relative border-1 border-solid`

const solidActiveAndDisabled = `${typeCommon} bg-light-4 border-transparent text-dark-4`
const solidDisabled = `${typeCommon} bg-dark-4 border-transparent text-light-5`
const solidActive = `${typeCommon} bg-light-4 border-transparent text-dark-3 text-stroke-dark-3 text-stroke-1`
const solid = `${typeCommon} bg-dark-3 border-transparent text-light-4 hover:text-white`

const outlinedActiveAndDisabled = `${typeCommon} bg-dark-4 border-light-5 text-light-5 z-10`
const outlinedDisabled = `${typeCommon} bg-dark-4 border-transparent text-light-5`
const outlinedActive = `${typeCommon} bg-dark-1 border-teal text-teal text-stroke-teal text-stroke-1 z-10`
const outlined = `${typeCommon} border-transparent text-light-5 hover:text-white`

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Option.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
