export const udboxInitAppendData = sn => ({
  current: {
    freq: {
      RF: '', // int
      IF: '', // int
      LO: '', // int
      isHarmonic: false, //  bool
    },
    ledIndicators: {
      PLO_LOCK: 0,
      CH1: 0,
      CH2: 0,
      OUT_10M: 0,
      OUT_100M: 0, // 這個完全是控制 output ref 的 100m 的, 原則上在這裡不會用到
      SOURCE_100M: 0, // 這個完全是控制 ref source 的, 原則上在這裡不會用到
      LED_100M: 0,
      PWR_5V: 0,
      PWR_9V: 0,
    },
  },

  settings: {
    freq: {
      isSupportFreqUnlock: false,
      bandwidth: { isCustom: false, value: '' }, // int
      unit: 'MHz', // str
      RF: {
        value: null,
        deviceMax: '', // int
        deviceMin: '', // int
        userMax: '', // int
        userMin: '', // int
      },
      IF: {
        value: null,
        deviceMax: '', // int
        deviceMin: '', // int
        userMax: '', // int
        userMin: '', // int
      },
      LO: {
        currentSelection: '', // "LSI" or "HSI"
        deviceMin: '',
        deviceMax: '',
        userMax: '',
        userMin: '',
        LSI: {
          value: '', // int
          disabled: {
            range: true,
            resolution: true,
          },
        },
        HSI: {
          value: '', // int
          disabled: {
            range: true,
            resolution: true,
          },
        },
      },
    },
    device: {
      PLO_LOCK: 0,
      CH1: 0,
      CH2: 0,
      OUT_10M: 0,
      OUT_100M: 0,
      SOURCE_100M: 0,
      LED_100M: 0, // 這個完全是判斷燈號的, 原則上在這裡不會用到
      PWR_5V: 0,
      PWR_9V: 0,
    },
  },
})
