import React, { useState, useRef, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { mergeRefs } from 'react-merge-refs'

import InputCore from 'src/components/Input/Core/'

import { theme, cursor } from '../style'

import { BiX } from 'react-icons/bi'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <CommonInput
//   {...{
//     size, // 'sm', 'md', 'lg'
//     step, // number
//     min, // number
//     max, // number
//     decimalScale, // number (小數點幾位)
//     value, // string
//     inputType, // 'text', 'number', 'ip', 'format' (input 類型)
//     disabled, // bool
//     danger, // bool
//     placeholder, // string
//     icon, // node
//     onChange, // result => console.log(result.formattedValue)
//   }}
// />

const CommonInput = forwardRef((props, ref) => {
  const {
    id = '',
    size = 'md',
    icon,
    inputType = 'text',
    value,
    disabled = false,
    warning = false,
    danger = false,
    className = '',
    inputClassName = '',
    placeholder,
    autoFocus,

    min = 0,
    max,
    decimalScale = 0,
    step = 1,

    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
  } = props

  const inputRef = useRef()

  const mergeRef = useMemo(
    () => (ref ? mergeRefs([inputRef, ref]) : inputRef),
    [inputRef, ref]
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [isActive, setIsActive] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClickForContainer = () => inputRef?.current?.focus()
  const handleClick = () =>
    onChange({ formattedValue: defaultValue?.[inputType] })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                        ${className} 
                        ${containerCommon}
                        ${containerSize[size]}
                        ${theme(isActive, disabled, danger, warning)}
                        ${cursor(disabled)}
                        ${containerPadding(isActive)}
                        `

  const iconClass = icon && `w-6 h-6 mr-1 flex items-center justify-center`

  const clearClass = `${clearIconCommon} ${
    isActive ? showClearIcon : hideClearIcon
  }`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass} onClick={handleClickForContainer}>
      <div className={iconClass}>{icon}</div>

      <InputCore
        {...{
          id,
          inputType,
          value,
          decimalScale,
          step,
          disabled,
          onChange,
          placeholder,
          autoFocus,
        }}
        ref={mergeRef}
        inputMin={min}
        inputMax={max}
        className={`${inputClassName} w-full outline-none`}
        onFocus={e => {
          setIsActive(true)
          onFocus(e)
        }}
        onBlur={e => {
          setIsActive(false)
          onBlur(e)
        }}
      />

      <div className={clearClass} onClick={handleClick}>
        <BiX />
      </div>
    </div>
  )
})
export default CommonInput

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = `
                        relative 
                        flex items-center 
                        border-1 border-solid
                        rounded-[2px]
                        duration-500
                        `
const containerSize = {
  sm: 'h-6 text-sm leading-3',
  md: 'h-8 text-sm leading-3 py-1',
  lg: 'h-10 text-base leading-4 py-2',
}

const containerPadding = isActive => (isActive ? 'pl-2 pr-1' : 'px-2')

const clearIconCommon = `
                    text-white/50 hover:text-white 
                    flex items-center justify-center shrink-0
                    duration-500 cursor-pointer
                    `
const showClearIcon = `w-6 h-6 opacity-1`
const hideClearIcon = `w-0 h-3 opacity-0`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static Func     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const defaultValue = { text: '', number: '0', ip: '...' }

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonInput.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  icon: PropTypes.node,
  inputType: PropTypes.oneOf(['text', 'number', 'ip', 'format']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,

  warning: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,

  min: PropTypes.number,
  max: PropTypes.number,
  decimalScale: PropTypes.number,
  step: PropTypes.number,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}
