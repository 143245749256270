import { createAction } from '@reduxjs/toolkit'

export const home_leaveDeviceInfo_watcher = createAction(
  'facility/home_leaveDeviceInfo_watcher'
)
export const home_leaveCheckModal_cancelClick_watcher = createAction(
  'modalEvent/home_leaveCheckModal_cancelClick_watcher'
)
export const home_leaveCheckModal_dontApplyClick_watcher = createAction(
  'modalEvent/home_leaveCheckModal_dontApplyClick_watcher'
)
export const home_leaveCheckModal_applyClick_watcher = createAction(
  'modalEvent/home_leaveCheckModal_applyClick_watcher'
)

export const home_applyDeviceInfo_watcher = createAction(
  'facility/home_applyDeviceInfo_watcher'
)
export const home_applyCheckModal_NoClick_watcher = createAction(
  'modalEvent/home_applyCheckModal_NoClick_watcher'
)
export const home_applyCheckModal_yesClick_watcher = createAction(
  'modalEvent/home_applyCheckModal_yesClick_watcher'
)

export const home_InitFailedRetryClick_watcher = createAction(
  'facility/home_InitFailedRetryClick_watcher'
)

// home - lottie - scan
export const lottie_scanAnimateModal_complete_watcher = createAction(
  'modalEvent/lottie_scanAnimateModal_complete_watcher'
)
// home - lottie - loading
export const lottie_loadingAnimateModal_complete_watcher = createAction(
  'modalEvent/lottie_loadingAnimateModal_complete_watcher'
)
