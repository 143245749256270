import { put, call } from 'redux-saga/effects'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import { ud_general_freqUnlockClick_watcher } from 'src/redux/actions/freqConverter/generalAction'
import responseToDeviceError from 'src/redux/sagas/handlers/errorResponse/responseToDeviceError'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* freqSettingUnlock({ response, api, time }) {
  try {
    const errorCodeName = response?.codeName

    const licenseErrorList = ['ERROR_LICENSE_LENGTH', 'ERROR_LICENSE_KEY']
    const isLicenseInvalid = licenseErrorList.includes(errorCodeName)
    const { sn } = response?.data

    if (isLicenseInvalid) {
      yield put(modalActions.setModalOfUDUnlockFailed({ isFailed: true }))

      yield put(ud_general_freqUnlockClick_watcher({ sn }))
    }

    if (!isLicenseInvalid) {
      yield put(modalActions.clearNormalModal())
      yield call(responseToDeviceError, sn)
    }
  } catch (error) {
    devWarLog('[res-handler] apply error:', error)
  }
}
