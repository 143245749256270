import React from 'react'

import { h6 } from 'src/containers/operating/device/beamformers/style'
import ValueBox from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/ValueBox'

const ImitationTable = props => {
  const { name, sideLabel = [], col1, col2 } = props

  const { title: col1Title, data: col1Data } = col1
  const { title: col2Title, data: col2Data } = col2

  return (
    <div className='flex'>
      <div className={col}>
        <div className='h-4 ' />

        {sideLabel.map((e, i) => (
          <div className={imitationTd} key={`${name} sideLabel ${i}`}>
            <h6 key={'side label' + i + e} className={h6 + ' text-light-4 '}>
              {e}
            </h6>
          </div>
        ))}
      </div>

      <div className='grid grid-cols-2 gap-x-2 shrink-0'>
        <div className={col}>
          <h6 className={h6 + ' h-4 w-full text-light-4 text-center'}>
            {col1Title}
          </h6>

          {col1Data.map((e, i) => (
            <div className={imitationTd} key={name + col1Title + i}>
              <ValueBox
                key={'cloverCellEvb' + col1Title + i}
                value={e.value}
                unit={e.unit}
              />
            </div>
          ))}
        </div>

        <div className={col}>
          <h6 className={h6 + ' h-4 w-full text-light-4 text-center'}>
            {col2Title}
          </h6>

          {col2Data.map((e, i) => (
            <div className={imitationTd} key={name + col2Title + i}>
              <ValueBox
                key={'cloverCellEvb' + col2Title + i}
                value={e.value}
                unit={e.unit}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ImitationTable.propTypes = {}

export default ImitationTable

const col = `w-full flex flex-col gap-y-1`
const imitationTd = `h-8 flex items-center `
