import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function RF({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 6H6.77246C7.75098 6 8.5918 6.14648 9.29492 6.43945C10.0039 6.73242 10.5488 7.16602 10.9297 7.74023C11.3105 8.31445 11.501 9.02051 11.501 9.8584C11.501 10.5439 11.3838 11.1328 11.1494 11.625C10.9209 12.1113 10.5957 12.5186 10.1738 12.8467C9.75781 13.1689 9.26855 13.4268 8.70605 13.6201L7.87109 14.0596H3.72266L3.70508 12.0029H6.79004C7.25293 12.0029 7.63672 11.9209 7.94141 11.7568C8.24609 11.5928 8.47461 11.3643 8.62695 11.0713C8.78516 10.7783 8.86426 10.4385 8.86426 10.0518C8.86426 9.6416 8.78809 9.28711 8.63574 8.98828C8.4834 8.68945 8.25195 8.46094 7.94141 8.30273C7.63086 8.14453 7.24121 8.06543 6.77246 8.06543H4.63672V18.7969H2V6ZM9.1543 18.7969L6.23633 13.0928L9.02246 13.0752L11.9756 18.6738V18.7969H9.1543Z'
          fill='#C2C2C2'
        />
        <path
          d='M16.168 6V18.7969H13.5312V6H16.168ZM21.2656 11.4668V13.5234H15.4473V11.4668H21.2656ZM21.8809 6V8.06543H15.4473V6H21.8809Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
