import React from 'react'
import { useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'
import Mask from 'src/components/Mask'
import Carousel from 'src/components/Carousel'

import polarGuideImg from 'src/assets/img/polarization_guide.png'
import beamGuideImg1 from 'src/assets/img/beam_guide_1.png'
import beamGuideImg2 from 'src/assets/img/beam_guide_2.png'
import beamGuideImg3 from 'src/assets/img/beam_guide_3.png'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import {
  setMaskDontShowAgain,
  setShowFirst3DInstructionMask,
  setShowManual3DInstructionMask,
} from 'src/redux/slices/uiControl/beamformers/cloverCellEvb'

const InstructionMask = props => {
  const dispatch = useDispatch()
  const {
    sn,
    deviceType,
    showManual3DInstructionMask,
    showFirstInstructionMask,
  } = props

  const isArray1024 =
    getDeviceInfo[deviceType]?.devName.first === 'cloverCellArray1024'

  const handleInstructionOKClick = () => {
    if (showManual3DInstructionMask)
      return dispatch(setShowManual3DInstructionMask({ sn, value: false }))

    return dispatch(setShowFirst3DInstructionMask({ sn, value: false }))
  }

  const handleInstructionDontShowAgainClick = () =>
    dispatch(setMaskDontShowAgain({ key: 'instruction', value: true }))

  return (
    <Mask className='z-20 rounded-lg'>
      <Carousel
        className='h-[460px]
      '>
        {!isArray1024 && (
          <Carousel.Item>
            <div className={instructionBox}>
              <img src={polarGuideImg} alt='polarization guide' />
              <div className={messageContainer}>
                To use H+V polarization make sure both the H port and
                <br /> V port are connected to the required signal source
              </div>
            </div>
          </Carousel.Item>
        )}

        <Carousel.Item>
          <div className={instructionBox}>
            <img src={beamGuideImg1} alt='beam control guide - 1' />
            <div className={messageContainer}>
              Grab the red ball,
              <br /> drag to control the beam Theta value
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={instructionBox}>
            <img src={beamGuideImg2} alt='beam control guide - 2' />
            <div className={messageContainer}>
              Grab the green ball,
              <br /> drag to control the beam Phi value
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={instructionBox}>
            <img src={beamGuideImg3} alt='beam control guide - 3' />
            <div className={messageContainer}>
              3D image can be rotated, zoomed in and out to adjust
              <br /> the angle
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div className='flex flex-col items-center'>
        <CommonButton
          size='md'
          type='general'
          className='my-6'
          onClick={handleInstructionOKClick}>
          OK
        </CommonButton>

        {showFirstInstructionMask && (
          <CommonButton
            size='md'
            type='outlined'
            onClick={handleInstructionDontShowAgainClick}>
            Don't show again
          </CommonButton>
        )}
      </div>
    </Mask>
  )
}

InstructionMask.propTypes = {}

export default InstructionMask

const messageContainer = `w-full mx-auto text-white text-center`

const instructionBox = `w-full pt-[88px] flex flex-col items-center cursor-grab`
