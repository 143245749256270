import { take, fork, call, cancel, select } from 'redux-saga/effects'

import io from 'socket.io-client'

import facilitySocketOn from 'src/redux/sagas/services/socketTools/on/facilitySocketOn'
import localMiddlewareSocketOn from 'src/redux/sagas/services/socketTools/on/localMiddlewareSocketOn'
import beamformersSocketOn from 'src/redux/sagas/services/socketTools/on/beamformersSocketOn'
import freqConverterSocketOn from 'src/redux/sagas/services/socketTools/on/freqConverterSocketOn'
import powerDetectorSocketOn from 'src/redux/sagas/services/socketTools/on/powerDetectorSocketOn'

import emitCreator from 'src/redux/sagas/services/socketTools/socketEmitCreator'

import { path, getHost } from 'src/redux/sagas/services/url'

import { devLog, devWarLog } from 'src/funcs/tools'

import {
  socketDisconnect,
  facilityThrottle,
  facilityNormal,
  facilityEmit,
  facilityEmitCallback,
  localMiddlewareThrottle,
  localMiddlewareNormal,
  localMiddlewareEmit,
  localMiddlewareEmitCallback,
  beamformersThrottle,
  beamformersNormal,
  beamformersEmit,
  beamformersEmitCallback,
  freqConverterThrottle,
  freqConverterNormal,
  freqConverterEmit,
  freqConverterEmitCallback,
  powerDetectorThrottle,
  powerDetectorNormal,
  powerDetectorEmit,
  powerDetectorEmitCallback,
} from 'src/redux/actions/socket'

function _createSocket({ tag, accessJwtToken }) {
  const newSocket = io(getHost('socket', tag), {
    path: `/${path[tag]}/socket.io/`,
    transports: ['websocket'],
    timeout: 3000,
    auth: {
      accessJwtToken,
    },
  })

  return new Promise(resolve => {
    newSocket.on('connect', () => {
      devLog(`[SUCCESS] ${tag} Socket.io is Connected!!`)
      resolve(newSocket)
    })
    newSocket.on('disconnect', () => {
      devWarLog(`[WARNING] ${tag} Socket.io is disconnect!!!`)
    })
    newSocket.on('connect_timeout', timeout => {
      devWarLog(`[WARNING] ${tag} Socket.io is connect timeout: ${timeout}`)
    })
  })
}

function* _getAccessJwtToken() {
  return yield select(state => state.member.accessJwtToken)
}

function* _createSocketService({ lookupID, tag }) {
  const accessJwtToken = yield call(_getAccessJwtToken)
  const socket = yield call(_createSocket, { tag, accessJwtToken })

  const {
    onService,
    emitAction,
    callbackAction,
    throttleAction,
    normalAction,
  } = socketInfo[tag]

  const on = yield fork(onService, socket)
  const emit = yield fork(emitCreator, {
    tag,
    lookupID,
    socket,
    emitAction,
    callbackAction,
    throttleAction,
    normalAction,
  })

  yield take(socketDisconnect.type)
  yield socket.disconnect()
  yield cancel(on)
  yield cancel(emit)
}

const socketInfo = {
  facility: {
    onService: facilitySocketOn,
    emitAction: facilityEmit,
    callbackAction: facilityEmitCallback,
    throttleAction: facilityThrottle,
    normalAction: facilityNormal,
  },
  localMiddleware: {
    onService: localMiddlewareSocketOn,
    emitAction: localMiddlewareEmit,
    callbackAction: localMiddlewareEmitCallback,
    throttleAction: localMiddlewareThrottle,
    normalAction: localMiddlewareNormal,
  },
  freqConverter: {
    onService: freqConverterSocketOn,
    emitAction: freqConverterEmit,
    callbackAction: freqConverterEmitCallback,
    throttleAction: freqConverterThrottle,
    normalAction: freqConverterNormal,
  },
  beamformers: {
    onService: beamformersSocketOn,
    emitAction: beamformersEmit,
    callbackAction: beamformersEmitCallback,
    throttleAction: beamformersThrottle,
    normalAction: beamformersNormal,
  },
  powerDetector: {
    onService: powerDetectorSocketOn,
    emitAction: powerDetectorEmit,
    callbackAction: powerDetectorEmitCallback,
    throttleAction: powerDetectorThrottle,
    normalAction: powerDetectorNormal,
  },
}

export default function* rootSocket(lookupID) {
  yield fork(_createSocketService, { lookupID, tag: 'facility' })
  yield fork(_createSocketService, { lookupID, tag: 'localMiddleware' })
  yield fork(_createSocketService, { lookupID, tag: 'freqConverter' })
  yield fork(_createSocketService, { lookupID, tag: 'beamformers' })
  yield fork(_createSocketService, { lookupID, tag: 'powerDetector' })
}
