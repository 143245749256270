import { take, put, race, call } from 'redux-saga/effects'

import { devWarLog } from 'src/funcs/tools'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import {
  udModule_toExternalErrorModal_tryAgainClick_watcher,
  udModule_toExternalErrorModal_cancelClick_watcher,
} from 'src/redux/actions/freqConverter/udmoduleAction'

import { udmodule_deviceSettings_refSourceChange_watcher } from 'src/redux/actions/freqConverter/udmoduleAction'

import { modalActions } from 'src/redux/slices/modal'
import responseToDeviceError from 'src/redux/sagas/handlers/errorResponse/responseToDeviceError'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* deviceSettingRefSourceChange({ response, api, time }) {
  try {
    const [sn, { refSource, ledIndicators }] = Object.entries(response?.data)[0]

    let { currentData } = yield call(getCommonArgs, sn)
    currentData.current.ledIndicators = ledIndicators

    if (refSource === 1) {
      currentData.settings.device.refSource = 0

      yield put(modalActions.showModalOfUDModuleExternalError())

      const { tryAgain, cancel } = yield race({
        tryAgain: take(
          udModule_toExternalErrorModal_tryAgainClick_watcher.type
        ),
        cancel: take(udModule_toExternalErrorModal_cancelClick_watcher.type),
      })

      if (tryAgain)
        yield put(
          udmodule_deviceSettings_refSourceChange_watcher({ sn, refSource: 1 })
        )

      if (cancel) {
        yield put(modalActions.clearNormalModal())
        yield put(
          udmodule_deviceSettings_refSourceChange_watcher({ sn, refSource: 0 })
        )
      }
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // 避免切回 internal 失敗 be 沒把 initialization 改成 'failed', 這邊強制改
    if (refSource === 0) yield call(responseToDeviceError, sn)
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}
