import React, { useState } from 'react'

import InputControl from 'src/components/composite/beamformers/BeamSteeringModule/ForBBox/InputControl'
import Beam3DButtons from 'src/components/composite/beamformers/BeamSteeringModule/ForBBox/Beam3DButtons'
import Beam3DControlForBBox from 'src/components/composite/beamformers/Beam3DControl/ForBBox'

import ErrorBoundaryOfThree from 'src/containers/ErrorBoundary/Three'

const BeamSteeringModule = props => {
  const {
    sn,
    deviceType,
    // value
    theta,
    phi,
    beamCurrentGain,
    thetaMax,
    beamGainMin,
    beamGainMax,

    // 3d control event
    handleDrag,
    handleDragEnd,
    beam3DCameraArgs,
    handleCameraChange,

    // 3d button event
    deviceDirection,
    handleDirectionChange,
    handleHelpButtonClick,
    handleBoresightClick,

    // input event
    handleInputChange,
  } = props

  // 按 reset 鏡頭的按鈕會設成 true, 100ms 後自動設回 false
  const [isCameraReset, setIsCameraReset] = useState(false)
  const boresightDisabled = +theta === 0 && +phi === 0

  const handleCameraReset = value => setIsCameraReset(value)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='relative w-full h-[400px]'>
        <ErrorBoundaryOfThree>
          <Beam3DControlForBBox
            {...{
              sn,
              deviceType,
              theta,
              phi,
              thetaMax,
              handleDrag,
              handleDragEnd,
              beam3DCameraArgs,
              handleCameraChange,
              isCameraReset,
              handleCameraReset,
              deviceDirection,
            }}
          />

          <Beam3DButtons
            {...{
              deviceDirection,
              handleDirectionChange,
              handleCameraReset,
              handleHelpButtonClick,
              handleBoresightClick,
              boresightDisabled,
            }}
          />
        </ErrorBoundaryOfThree>
      </div>

      <InputControl
        {...{
          deviceType,
          theta,
          phi,
          beamCurrentGain,
          thetaMax,
          beamGainMin,
          beamGainMax,
          handleInputChange,
        }}
      />
    </div>
  )
}

export default BeamSteeringModule

const container = `functionBlockBackground 
                    flex flex-col 
                    gap-y-4 
                    rounded-lg 
                    px-5 md:px-5 xl:px-4
                    pb-10
                    `
