import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     All Power      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepAllPower(payloads) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Power     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepSinglePower(payloads) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Common Att      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepCommonAttenuation(payloads) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Single Att      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepSingleAttenuation(payloads) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Phase     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepSinglePhase(payloads) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}
