import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setHeader } from 'src/redux/slices/uiControl/facility/header'

const useSetHeader = props => {
  const dispatch = useDispatch()

  const newHeaderData = props

  useEffect(() => {
    dispatch(setHeader(newHeaderData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHeaderData])
}

export default useSetHeader
