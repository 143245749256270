import React, { forwardRef } from 'react'
import { RotatingLines } from 'react-loader-spinner'

import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <IconButton
//   {...{
//     type,
//     size,
//     href,
//     className,
//     danger,
//     disabled,
//     loading,
//     tabIndex,
//     onClick,
//     onPointerDown,
//     onPointerUp,
//   }}>
//   <BiCategory />
// </IconButton>

const IconButton = forwardRef((props, ref) => {
  const {
    size = 'md',
    type = 'primary',
    href = '',
    className = '',
    danger = false,
    disabled = false,
    loading = false,
    tabIndex = 0,
    onClick = () => {},
    onPointerDown = () => {},
    onPointerUp = () => {},
    children,
  } = props

  const showLoading = !disabled && loading

  const handleClick = event => {
    if (!disabled && !loading) onClick(event)
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const btnClass = `
                  ${className} 
                  ${shapeSize?.[size]} 
                  ${theme(type, danger, disabled)}
                  ${cursor(disabled)} 
                  ${common} 
                  `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <LinkContainer href={href} disabled={disabled}>
      <button
        className={btnClass}
        aria-label='icon-button'
        onClick={handleClick}
        {...{ ref, tabIndex, disabled, onPointerDown, onPointerUp }}>
        {showLoading ? (
          <Loading color={loadingColor(type, danger)} />
        ) : (
          children
        )}
      </button>
    </LinkContainer>
  )
})

export default IconButton

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const common = `rounded-[2px] border-1 border-solid flex items-center justify-center font-medium duration-500 select-none`

export const shapeSize = {
  sm: 'w-6 h-6 text-md',
  md: 'w-8 h-8 text-lg',
  lg: 'w-10 h-10 text-xl',
}

export const theme = (type, danger, disabled) => {
  if (type === 'general') {
    if (disabled) return basicDisabled
    if (danger) return basicDanger
    return general
  }

  if (type === 'outlined') {
    if (disabled) return outlinedDisabled
    if (danger) return outlinedDanger
    return outlined
  }

  if (type === 'standard') {
    if (disabled) return standardDisabled
    if (danger) return standardDanger
    return standard
  }

  if (disabled) return basicDisabled
  if (danger) return basicDanger
  return primary
}

// normal
export const primary = `border-transparent bg-teal hover:bg-teal/60 text-black fill-black`
export const general = `border-transparent bg-white hover:bg-white/60 text-black fill-black`
export const outlined = `border-white hover:bg-white/20 text-white fill-white`
export const standard = `border-transparent bg-transparent text-white fill-white hover:text-teal hover:fill-teal`

// danger
export const basicDanger = `border-transparent bg-red text-white hover:opacity-60`
export const outlinedDanger = 'border-red hover:bg-red/10 text-red '
export const standardDanger =
  'border-transparent bg-transparent text-red hover:text-red/60 '

// disabled
export const basicDisabled =
  'border-transparent bg-dark-4 grayscale-1 text-white/40 fill-white/40'
export const outlinedDisabled =
  'border-dark-5 bg-transparent grayscale-1 text-dark-5 fill-dark-5'
export const standardDisabled =
  'border-transparent bg-transparent grayscale-1 text-dark-5 fill-dark-5'

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

const loadingColor = (type, danger) => {
  if (type === 'primary') {
    if (danger) return 'white'
    return 'black'
  }

  if (type === 'general') {
    if (danger) return 'white'
    return 'black'
  }

  if (type === 'outlined') {
    if (danger) return 'red'
    return 'white'
  }

  if (type === 'standard') {
    if (danger) return 'red'
    return 'white'
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const LinkContainer = props => {
  const { href, disabled, children } = props
  const internalLink = href.indexOf('http') === -1

  if (disabled) return <>{children}</>

  if (href && internalLink)
    return (
      <Link target='_self' to={href}>
        {children}
      </Link>
    )

  if (href && !internalLink)
    return (
      <a href={href} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    )

  return <>{children}</>
}

const Loading = ({ color }) => (
  <RotatingLines
    width='14'
    strokeColor={color}
    strokeWidth='3'
    animationDuration='0.7'
    wrapperStyle={{}}
    visible={true}
  />
)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
IconButton.propTypes = {
  shape: PropTypes.oneOf(['basic', 'square']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.oneOf(['primary', 'general', 'outlined', 'standard']),
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onPointerDown: PropTypes.func,
  onPointerUp: PropTypes.func,
}
