import React from 'react'
import { useDispatch } from 'react-redux'

import ChannelModule from 'src/components/composite/beamformers/ChannelModule'
import OverlayMask from 'src/containers/operating/device/beamformers/bbox/DeviceController/Channel/OverlayMask'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  bbox_channel_allPowerSwitch_watcher,
  bbox_channel_singlePowerChange_watcher,
  bbox_channel_commonGainSliderChange_watcher,
  bbox_channel_commonGainChange_watcher,
  bbox_channel_singleGainChange_watcher,
  bbox_channel_singlePhaseChange_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

const Channel = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const { deviceControl } = current.data
  const { channel, common } = deviceControl

  const sn = current?.sn
  const boardData = channel[common.rfMode].lstBoardData
  const { channelCount } = channel

  // all power
  const handleAllPowerClick = value =>
    dispatch(
      bbox_channel_allPowerSwitch_watcher({
        sn: current.sn,
        value,
      })
    )

  // common gain
  const handleCommonGainSliderChange = ({ boardIndex, value }) =>
    dispatch(
      bbox_channel_commonGainSliderChange_watcher({
        sn,
        boardIndex,
        value,
      })
    )
  const handleCommonGainChange = ({ boardIndex, value, isValid }) =>
    dispatch(
      bbox_channel_commonGainChange_watcher({
        sn,
        boardIndex,
        value,
        isValid,
      })
    )

  // element power & gain & phase
  const handleSinglePowerClick = ({ boardIndex, channelIndex, value }) =>
    dispatch(
      bbox_channel_singlePowerChange_watcher({
        sn,
        value,
        channelIndex,
        boardIndex,
      })
    )
  const handleSingleGainChange = ({
    boardIndex,
    channelIndex,
    value,
    isValid,
  }) =>
    dispatch(
      bbox_channel_singleGainChange_watcher({
        sn,
        boardIndex,
        channelIndex,
        value,
        isValid,
      })
    )
  const handleSinglePhaseChange = ({
    boardIndex,
    channelIndex,
    value,
    isValid,
  }) =>
    dispatch(
      bbox_channel_singlePhaseChange_watcher({
        sn,
        boardIndex,
        channelIndex,
        value: value,
        isValid: isValid,
      })
    )

  return (
    <div className={container}>
      <OverlayMask sn={sn} />
      <ChannelModule
        {...{
          sn,
          boardData,
          channelCount,
          handleAllPowerClick,
          handleCommonGainSliderChange,
          handleCommonGainChange,
          handleSinglePowerClick,
          handleSingleGainChange,
          handleSinglePhaseChange,
        }}
      />
    </div>
  )
}

export default Channel

const container = `relative`
