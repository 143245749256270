import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { theme, cursor } from '../style'

const TextArea = forwardRef((props, ref) => {
  const {
    id = '',
    size: propsSize = 'md',
    value = '',
    autoFocus = false,
    readOnly = false,
    disabled = false,
    warning = false,
    danger = false,
    className = '',
    placeholder,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
  } = props

  const [isActive, setIsActive] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const textareaClass = `
                        ${className} 
                        ${common}
                        ${size[propsSize]}
                        ${font[propsSize]}
                        ${theme(isActive, disabled, danger, warning)}
                        ${cursor(disabled)}
                        scrollbar
                        `

  return (
    <textarea
      {...{
        ref,
        id,
        value,
        autoFocus,
        readOnly,
        disabled,
        placeholder,
      }}
      className={textareaClass}
      onChange={e => onChange({ formattedValue: e.target.value })}
      onFocus={e => {
        setIsActive(true)
        onFocus(e)
      }}
      onBlur={e => {
        setIsActive(false)
        onBlur(e)
      }}
    />
  )
})

const common = `relative outline-0 rounded-[2px] border-1 border-solid duration-500 font-normal leading-5`
const size = { sm: `py-1 px-2`, md: `p-2`, lg: `p-3` }
const font = { sm: `text-sm`, md: `text-sm`, lg: `text-base` }

TextArea.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,

  className: PropTypes.string,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default TextArea
