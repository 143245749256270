import React from 'react'
import { useDispatch } from 'react-redux'

import BeamSteering from 'src/containers/operating/device/beamformers/cloverCell/array1024/Main/BeamSteering'

import Mask from 'src/components/Mask'

import {
  container,
  divider,
} from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import CommonSwitch from 'src/components/Switch/CommonSwitch'

import { cloverCell1024_channel_allPowerSwitch_watcher } from 'src/redux/actions/beamformers/cloverCellArray1024Action'

const Main = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const rfMode = current.data.deviceControl.common.rfMode

  const isDisabled = rfMode === 'standby'
  const hPower = current.data.deviceControl.channel?.[rfMode]?.['horizon']
  const vPower = current.data.deviceControl.channel?.[rfMode]?.['vertical']

  const isExternalControl = current.data.deviceControl.common?.isExternalControl

  const handleClick = hvMode =>
    dispatch(cloverCell1024_channel_allPowerSwitch_watcher({ sn, hvMode }))

  return (
    <div className={container}>
      <div className='grid grid-cols-2 gap-x-4'>
        <div className={switchContainer}>
          <label>H-Polarization</label>
          <CommonSwitch
            type='text'
            onText='ON'
            offText='OFF'
            size='sm'
            on={hPower}
            disabled={isDisabled}
            loading={false}
            onClick={() => handleClick('horizon')}
          />
        </div>
        <div className={switchContainer}>
          <label>V-Polarization</label>
          <CommonSwitch
            type='text'
            onText='ON'
            offText='OFF'
            size='sm'
            on={vPower}
            disabled={isDisabled}
            loading={false}
            onClick={() => handleClick('vertical')}
          />
        </div>
      </div>

      <div className={divider + ' my-4'} />

      <BeamSteering />

      {!isExternalControl && rfMode === 'standby' && (
        <Mask className={disabledMaskClass}>Enabled in TX or RX mode.</Mask>
      )}

      {isExternalControl && (
        <Mask className={disabledMaskClass}>
          Not available in external control
        </Mask>
      )}
    </div>
  )
}

Main.propTypes = {}

export default Main

const switchContainer = `bg-light-4/10 
flex items-center justify-between
p-4 rounded-md 
text-light-1 text-sm font-bold`

const disabledMaskClass = `flex z-20 
                            items-center justify-center 
                            text-light-4 text-base font-bold 
                            cursor-not-allowed select-none 
                            `
