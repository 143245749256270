import React from 'react'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/beamformers/style'
import CommonSelect from 'src/components/Select/CommonSelect'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCellCTTC_common_frequencyChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const CentralFrequency = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const currentFreq = current.data.deviceControl.common.currentFreq
  const lstFreqOptions = current.data.deviceControl.common.lstFreqOptions

  const handleChange = (event, value) =>
    dispatch(cloverCellCTTC_common_frequencyChange_watcher({ sn, value }))

  return (
    <div>
      <h6 className={subtitle}>Central Frequency</h6>

      <CommonSelect
        size='sm'
        value={currentFreq}
        currentChildren={<>{currentFreq} GHz</>}
        onChange={handleChange}>
        {lstFreqOptions.map(e => (
          <CommonSelect.Option name={e} key={'clover cell freq ' + e}>
            {e} GHz
          </CommonSelect.Option>
        ))}
      </CommonSelect>
    </div>
  )
}

export default CentralFrequency

const subtitle = `${h6} text-light-1 mb-1`
