import { call, put } from 'redux-saga/effects'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'
import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

export function* selectCurrentCaliID({ response }) {
  try {
    const [sn, { currentCaliID }] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.currentCaliID = currentCaliID

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(
      modalActions.show_modal_powerDetector_actionPanel_failedToChangeCaliConfig()
    )
  } catch (error) {
    devWarLog('[res-handler] caliSelect error:', error)
  }
}
