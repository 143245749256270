import React from 'react'
import * as R from 'ramda'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const ItemMark = ({ sn, layout }) => {
  const { deviceData } = useGetCurrentDeviceData()
  const currentData = deviceData?.[sn]
  const { deviceType } = currentData?.info

  const paramPath = getDeviceInfo[deviceType].inventoryMark.paramPath
  const renderParam = R.path(paramPath, currentData)
  const content = getDeviceInfo[deviceType].inventoryMark.render(renderParam)
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const markBoxPosition =
    layout === 'row' ? `top-5 right-3 2xl:right-4` : `top-3 right-3 2xl:right-4`
  const markBox = `${markBoxCommon} ${markBoxPosition}`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // content render
  if (content) return <div className={markBox}>{content}</div>

  return <></>
}

export default ItemMark

const markBoxCommon = `[markBox] 
                        h-[18px] 
                        bg-white/10 
                        px-1 py-[2px] 
                        rounded-[2px] 
                        text-xs font-normal 
                        absolute
                        `
