import { createSlice, current } from '@reduxjs/toolkit'

export const homeUiControlSlice = createSlice({
  name: 'homeUiControl',
  initialState: {
    showDeviceInfo: false,
    isIpChanged: false,
    lstInitFailedRetryRequesting: [], // [...sn]
  },
  reducers: {
    openDeviceInfo(state, action) {
      return { ...current(state), showDeviceInfo: true }
    },
    closeDeviceInfo(state, action) {
      return { ...current(state), showDeviceInfo: false }
    },
    setIsIpChanged(state, action) {
      return { ...current(state), isIpChanged: action.payload }
    },
    addSnToLstInitFailedRetryRequesting(state, action) {
      const { sn } = action.payload
      return {
        ...current(state),
        lstInitFailedRetryRequesting: [
          ...current(state).lstInitFailedRetryRequesting,
          sn,
        ],
      }
    },
    removeSnToLstInitFailedRetryRequesting(state, action) {
      const { sn } = action.payload
      state.lstInitFailedRetryRequesting =
        state.lstInitFailedRetryRequesting.filter(e => e !== sn)
      return state
    },
  },
})

export const temporaryIpDataSlice = createSlice({
  name: 'temporaryIpData',
  initialState: {},
  reducers: {
    tempIpUpdateFromDeviceData(state, action) {
      const deviceData = action.payload

      const newState = Object.entries(deviceData)
        .map(([sn, data]) => ({
          sn,
          staticIP: data?.info?.connectionInfo?.staticIP,
        }))
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr.sn]: curr?.staticIP,
          }),
          {}
        )

      return newState
    },

    tempIpUpdateForChange(state, action) {
      const { sn, value } = action.payload

      return { ...current(state), [sn]: value }
    },
  },
})

// export action
export const {
  openDeviceInfo,
  closeDeviceInfo,
  setIsIpChanged,
  addSnToLstInitFailedRetryRequesting,
  removeSnToLstInitFailedRetryRequesting,
} = homeUiControlSlice.actions

export const { tempIpUpdateFromDeviceData, tempIpUpdateForChange } =
  temporaryIpDataSlice.actions

// export reducer
export const homeUiControl = homeUiControlSlice.reducer

export const temporaryIpData = temporaryIpDataSlice.reducer
