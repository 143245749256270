import React from 'react'
import { FloatingOverlay, FloatingPortal } from '@floating-ui/react'

import DeviceInventory from 'src/containers/operating/DeviceInventory'

const OverlayDeviceInventory = () => (
  <FloatingPortal id='float'>
    <FloatingOverlay lockScroll className={overlayContainer}>
      <DeviceInventory />
    </FloatingOverlay>
  </FloatingPortal>
)

OverlayDeviceInventory.propTypes = {}

export default OverlayDeviceInventory

const overlayContainer = ` [Menu-overlayContainer] 
                            h-screen 
                            bg-gradient-to-l 
                            from-black/90 to-black/20 
                            backdrop-blur-sm 
                            flex justify-start
                            `
