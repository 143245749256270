import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import * as R from 'ramda'

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'src/components/ErrorFallback'

import LeftMenu from 'src/containers/home/LeftMenu'
import RightMenu from 'src/containers/home/RightMenu'
import Main from 'src/containers/home/Main'

import CommonButton from 'src/components/Button/CommonButton'

import { initDemoMode_watcher } from 'src/redux/actions/facility/init'
import {
  home_leaveDeviceInfo_watcher,
  home_applyDeviceInfo_watcher,
} from 'src/redux/actions/facility/home'

import useSetHeader from 'src/hooks/useSetHeader'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import useGetHelmet from 'src/hooks/useGetHelmet'

import { openDeviceInfo } from 'src/redux/slices/uiControl/facility/home'
import {
  ipNoDuplicateCheck,
  ipFormatCheck,
  getIpDataFromDeviceData,
} from 'src/funcs/ip'

const Home = () => {
  const dispatch = useDispatch()

  const helmet = useGetHelmet({ pageName: 'Home' })

  const { showDeviceInfo } = useSelector(state => state.homeUiControl)
  const deviceData = useSelector(state => state.deviceData)
  const { isDeviceControlModeInitialized, isDemoInitialized } = useSelector(
    state => state.facilityInit
  )

  const { isDemoMode, isDeviceControlMode } = useGetCurrentMode()

  useSetHeader(
    showDeviceInfo
      ? { key: 'home_device_info', props: '' }
      : { key: 'home', props: '' }
  )

  const isShowMenu =
    isDeviceControlMode && !showDeviceInfo && !R.isEmpty(deviceData)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    if (isDemoMode && !isDemoInitialized) dispatch(initDemoMode_watcher())

    return () => {
      dispatch(home_leaveDeviceInfo_watcher())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleOpenDeviceInfoClick = () => dispatch(openDeviceInfo())

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='relative w-full max-w-[1440px] mx-auto'>
      {helmet}

      {isDeviceControlMode && !isDeviceControlModeInitialized && (
        <Navigate to='/' replace={true} />
      )}

      {isShowMenu && (
        <>
          <RightMenu />
          <LeftMenu handleOpenDeviceInfoClick={handleOpenDeviceInfoClick} />
        </>
      )}

      {showDeviceInfo && <h2 className='pageTitle'>Device Info & Setting</h2>}

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={error => console.warn(error)}>
        <Main showInfo={showDeviceInfo} />
      </ErrorBoundary>

      {showDeviceInfo && <DeviceInfoApply />}
    </div>
  )
}

export default Home

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const DeviceInfoApply = () => {
  const dispatch = useDispatch()
  const deviceData = useSelector(state => state.deviceData)
  const temporaryIpData = useSelector(state => state.temporaryIpData)
  const { isIpChanged } = useSelector(state => state.homeUiControl)

  //* ip
  // -> {sn: ip, ...}
  const ipDataFromDeviceData = getIpDataFromDeviceData(deviceData)

  const isTempDuplicatePass = (sn, value) =>
    ipNoDuplicateCheck(temporaryIpData, sn, value)

  const isDeviceDataDuplicatePass = (sn, value) =>
    ipNoDuplicateCheck(ipDataFromDeviceData, sn, value)

  const isFormatPass = (sn, value) => ipFormatCheck(value)

  const tempIpTestFullRunPass = R.pipe(
    R.toPairs, // 類似 Object.entries -> [[key, value], ...]
    R.map(([sn, sIp]) =>
      sIp
        ? R.allPass([
            isTempDuplicatePass,
            isDeviceDataDuplicatePass,
            isFormatPass,
          ])(sn, sIp)
        : true
    ),
    R.includes(false),
    R.not
  )(temporaryIpData)

  const handleApplyClick = () => dispatch(home_applyDeviceInfo_watcher())

  return (
    <div className={applyContainerClass}>
      <CommonButton
        className='w-[220px]'
        size='lg'
        disabled={!isIpChanged || !tempIpTestFullRunPass}
        onClick={handleApplyClick}>
        Apply
      </CommonButton>
    </div>
  )
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

const applyContainerClass = `fixed bottom-0 inset-x-0 
                              h-[160px] 
                              bg-gradient-to-t from-black/90 to-black/0 
                              flex justify-center items-start pt-10
                              `
