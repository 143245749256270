import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

//* 用來在 redux 做 router 導向用的
// https://stackoverflow.com/questions/70881320/redirect-to-route-from-saga-using-react-router-v6
// https://stackoverflow.com/questions/68399876/how-to-navigate-outside-of-react-component-using-react-router-6/70002872#70002872
const NavigateSetter = () => {
  let location = useLocation()
  History.navigate = useNavigate()

  useEffect(() => {
    History.pathname = location.pathname
  }, [location.pathname])

  return null
}

export const History = {
  navigate: null,
  push: (page, ...rest) => History.navigate(page, ...rest),
  pathname: '',
}

export default NavigateSetter
