import React from 'react'
import { useDispatch } from 'react-redux'

// import { h4 } from 'src/containers/Modal/style'

import CommonSelect from 'src/components/Select/CommonSelect'
import CommonButton from 'src/components/Button/CommonButton'

import { BiTrash, BiSortDown } from 'react-icons/bi'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  pd_customCaliModal_deleteClick_watcher,
  // bbox_customAntennaModal_exportClick_watcher,
} from 'src/redux/actions/powerDetector'

import { setCustomCalibrationSortRule } from 'src/redux/slices/uiControl/powerDetector'
import useGetUIControl from 'src/hooks/useGetUIControl'

const FunctionBar = ({ selectIdList }) => {
  const dispatch = useDispatch()

  const isDisabled = selectIdList.length === 0

  const { all: all_uiControl } = useGetUIControl()
  const customCalibrationListSortRule =
    all_uiControl.customCalibrationListSortRule

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const handleDeleteClick = () =>
    dispatch(
      pd_customCaliModal_deleteClick_watcher({
        sn,
        selectIdList,
        isEditMode: false,
      })
    )

  // const handleExportClick = () => dispatch(bbox_customAntennaModal_exportClick_watcher())

  const handleSortChange = (event, value) =>
    dispatch(setCustomCalibrationSortRule({ value }))

  //! phase2 沒有 export 所以先註解
  return (
    <div className={functionBar}>
      {/* <div className='w-[197px] py-1 mx-1 flex gap-x-1 shrink-0'> */}
      <div className='py-1 flex items-center'>
        <CommonButton
          className='min-w-[92px]'
          type='text'
          icon={<BiTrash />}
          size='md'
          disabled={isDisabled}
          danger
          onClick={() => !isDisabled && handleDeleteClick()}>
          Delete
        </CommonButton>
        {/* <div
          className={functionItem({
            disabled: isDisabled,
            textColor: 'text-red',
          })}
          onClick={() => !isDisabled && handleDeleteClick()}>
          <BiTrash />
          Delete
        </div> */}

        {/* <div className={divider} />

        <div
          className={functionItem({
            disabled: isDisabled,
            textColor: 'text-light-1',
          })}
          onClick={() => !isDisabled && handleExportClick()}>
          <RiFileUploadLine />
          Export
        </div> */}
      </div>
      {/* </div> */}

      <CommonSelect
        value={customCalibrationListSortRule}
        currentChildren={customCalibrationListSortRule}
        size='md'
        icon={<BiSortDown />}
        className='w-[216px] '
        optionsContainerClassName='z-10'
        onChange={handleSortChange}>
        <CommonSelect.Option name='Latest to oldest'>
          Latest to oldest
        </CommonSelect.Option>
        <CommonSelect.Option name='Oldest to latest'>
          Oldest to latest
        </CommonSelect.Option>
      </CommonSelect>
    </div>
  )
}

FunctionBar.propTypes = {}

export default FunctionBar

const functionBar = `w-full h-8 flex justify-between`

// const functionItem = ({ disabled, textColor }) => `
//                     w-full
//                     flex
//                     justify-center items-center
//                     gap-x-2
//                     duration-300 select-none
//                     ${h4}
//                     ${
//                       disabled
//                         ? ` cursor-not-allowed text-dark-5 `
//                         : ` cursor-pointer ${textColor} `
//                     }
//                     `

// const divider = `h-full w-[1px] bg-dark-5`
