import { isPROD, devServerTarget, isOffline, isQa } from 'src/funcs/getEnv'

const localhost = process.env.REACT_APP_HOST_FOR_LOCAL
const ethanHost = process.env.REACT_APP_HOST_FOR_ETHAN
const leamonHost = process.env.REACT_APP_HOST_FOR_LEAMON
const ivyHost = process.env.REACT_APP_HOST_FOR_IVY
const jamesHost = process.env.REACT_APP_HOST_FOR_JAMES
const offlineHost = process.env.REACT_APP_HOST_FOR_OFFLINE
const otherHost = process.env.REACT_APP_HOST_FOR_OTHER

const facilityPort = process.env.REACT_APP_FACILITY_PORT
const beamformersPort = process.env.REACT_APP_BEAMFORMERS_PORT
const freqConverterPort = process.env.REACT_APP_FREQ_CONVERTER_PORT
const localFwMidPort = process.env.REACT_APP_FW_MIDDLEWARE_PORT
const dbServicePort = process.env.REACT_APP_DB_SERVICE_PORT
const powerDetectorPort = process.env.REACT_APP_POWER_DETECTOR_PORT

const ajaxOfficialProductionURL =
  process.env.REACT_APP_OFFICIAL_AJAX_FOR_PRODUCTION
const ioOfficialProductionURL =
  process.env.REACT_APP_OFFICIAL_SOCKET_FOR_PRODUCTION

const ajaxOfficialQAURL = process.env.REACT_APP_OFFICIAL_AJAX_FOR_QA
const ioOfficialQAURL = process.env.REACT_APP_OFFICIAL_SOCKET_FOR_QA

const head = { ajax: 'http', socket: 'ws' }
const body = {
  local: localhost,
  ethan: ethanHost,
  leamon: leamonHost,
  ivy: ivyHost,
  james: jamesHost,
  offline: offlineHost,
  other: otherHost,
}
export const path = {
  facility: 'facility',
  beamformers: 'beamformers',
  freqConverter: 'freqConverter',
  powerDetector: 'powerDetector',
  localMiddleware: 'tlk-local-middleware',
}
const port = {
  facility: facilityPort,
  beamformers: beamformersPort,
  freqConverter: freqConverterPort,
  dbService: dbServicePort,
  powerDetector: powerDetectorPort,
}

export const getHost = (mode, tag) => {
  if (tag === 'lambda') return getLambdaHost()

  // local middleware 永遠都在使用者電腦內 所以 = localhost
  // 不管任何模式永遠都跟自己 localhost 要
  if (tag === 'localMiddleware')
    return `${head[mode]}://${body.local}` + localFwMidPort

  if (isOffline) {
    const offlineUrl = `${head[mode]}://${body.local}${port[tag]}`
    return offlineUrl
  }

  if (isPROD) {
    if (mode === 'ajax') return ajaxOfficialProductionURL
    if (mode === 'socket') return ioOfficialProductionURL
  }

  if (isQa) {
    if (mode === 'ajax') return ajaxOfficialQAURL
    if (mode === 'socket') return ioOfficialQAURL
  }

  const developUrl = `${head[mode]}://${body[devServerTarget]}${port[tag]}`
  return developUrl
}

export const getLambdaHost = () => {
  if (isPROD) return ajaxOfficialProductionURL

  return ajaxOfficialQAURL
}
