import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import {
  sg_toExternalModal_cancelClick_watcher,
  sg_toExternalModal_useExternalClick_watcher,
} from 'src/redux/actions/freqConverter/sgAction'

import Stepper from 'src/components/Stepper'

import { h3 } from 'src/containers/Modal/style'
import useGetUIControl from 'src/hooks/useGetUIControl'
import RadioButtonGroup from 'src/components/Button/RadioButtonGroup'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const unit = 'MHz'

const ToExternalCheck = ({ sn }) => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData(sn)

  const [inputRefSource, setInputRefSource] = useState('')

  const { single: s_uiControl } = useGetUIControl(sn)
  const { isSuccess, isRequesting } = s_uiControl?.deviceSettings?.refSource

  const titleProcess = value =>
    value.indexOf(unit) > -1 ? 'Sync reference' : 'External frequency'
  const valueProcess = value =>
    value.indexOf(unit) > -1
      ? value.substring(0, value.indexOf(unit) - 1)
      : value
  const unitProcess = value => (value.indexOf(unit) > -1 ? unit : '')

  const options = current.data.settings.device.inputRefSource.lstOptions

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleInputSourceChange = ({ name }) =>
    inputRefSource === name ? setInputRefSource('') : setInputRefSource(name)

  const handleCancelClick = () =>
    dispatch(sg_toExternalModal_cancelClick_watcher())
  const handleUseExternalClick = () =>
    dispatch(
      sg_toExternalModal_useExternalClick_watcher({
        inputRefSource,
      })
    )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const valueClass = `
                      text-2xl font-normal leading-7 mr-3 
                      ${valueColor(isRequesting)} 
                      `

  const unitClass = `text-base font-normal leading-5 ${valueColor(
    isRequesting
  )} `

  return (
    <Modal>
      <Modal.Title>Use External Reference Source</Modal.Title>

      <Stepper
        name='Use External Reference Source'
        className='mt-8'
        gap='gap-y-5'>
        <Stepper.Step>
          <p className={h3 + ' text-light-4'}>
            Connect to an external ref. source
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <p className={h3 + ' text-light-4'}>
            Turn on the external ref. source and confirm transmission
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <p className={h3 + ' text-light-4'}>Select the matching Source</p>
        </Stepper.Step>
      </Stepper>

      <div className='flex flex-col items-center gap-y-2 mt-4'>
        {options.map((e, i) => (
          <RadioButtonGroup
            key={`ud0630 ToExternal modal options ${e} ${i}`}
            className=''
            title={titleProcess(e)}
            content={
              <div className='w-full flex justify-center items-center'>
                <h3 className={valueClass}>{valueProcess(e)}</h3>
                <span className={unitClass}>{unitProcess(e)}</span>
              </div>
            }
            checked={inputRefSource === e} // 是否被選取
            group='ud0630_external_ref_source' // html radio 裡面的 name, 用來判斷是從哪些去單選
            name={e} // onClick 會回傳的值
            disabled={isRequesting || isSuccess}
            onClick={handleInputSourceChange}
          />
        ))}
      </div>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          disabled={isRequesting}
          onClick={handleCancelClick}>
          Cancel
        </CommonButton>
        <CommonButton
          size='lg'
          type='primary'
          loading={isRequesting}
          disabled={!inputRefSource}
          success={isSuccess}
          onClick={handleUseExternalClick}>
          Use External
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ToExternalCheck

const valueColor = isDisabled => (isDisabled ? `text-light-5` : `text-white `)
