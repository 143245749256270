import { createSlice, current } from '@reduxjs/toolkit'
import * as R from 'ramda'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const ud5g = { refSource: { isRequesting: false, isSuccess: false } }
const ud0630 = {
  refSource: { isRequesting: false, isSuccess: false },
  outputSignalSourceEnable: { isRequesting: false },
  outputLOFrequency: { isRequesting: false },
}

const appendData = { ud5g, ud0630 }

// 個別 udbox 控項
// 內容物：
// { [sn]: defaultItemData }
// general 全 ud 共用
// 不同類型的 ud 可以另外 append 不同的 data
const defaultItemData = {
  smallSizeControlMode: 'Frequency Settings',
  general: {
    freqSettings: {
      isChanged: false,
      isRequesting: false,
      isSuccess: false,
      isDisabled: false,
    },
  },
}

export const udboxUIControlForSingleSlice = createSlice({
  name: 'udboxUIControlForSingle',
  initialState: {},
  reducers: {
    // init device 時，會新增一筆
    addUiControl(state, action) {
      const { sn, appendDataKey: key } = action.payload
      return {
        ...current(state),
        [sn]: { ...defaultItemData, [key]: appendData[key] },
      }
    },

    // 小螢幕尺寸時 切換 control mode
    // Frequency Settings || Device Settings
    changeSmallSizeControlMode(state, action) {
      const { sn, value } = action.payload
      state[sn].smallSizeControlMode = value
      return state
    },

    // 設定 general 的 freqSettings 狀態
    setIsFreqChanged(state, action) {
      const { sn, value } = action.payload
      state[sn].general.freqSettings.isChanged = value

      return state
    },
    setIsFreqRequesting(state, action) {
      const { sn } = action.payload
      state[sn].general.freqSettings.isRequesting = true
      state[sn].general.freqSettings.isSuccess = false

      return state
    },
    setIsFreqSuccess(state, action) {
      const { sn } = action.payload
      state[sn].general.freqSettings.isRequesting = false
      state[sn].general.freqSettings.isSuccess = true
      return state
    },
    setIsFreqDisabled(state, action) {
      const { sn, value } = action.payload
      state[sn].general.freqSettings.isDisabled = value
      return state
    },

    clearFreqSuccess(state, action) {
      const { sn } = action.payload
      state[sn].general.freqSettings.isSuccess = false

      return state
    },

    // 設定 udbox 5g(old) 的 deviceSettings 的 refSource 狀態
    setUD5gRefSourceIsRequesting(state, action) {
      const { sn } = action.payload
      state[sn].ud5g.refSource.isRequesting = true
      state[sn].ud5g.refSource.isSuccess = false

      return state
    },
    setUD5gRefSourceIsSuccess(state, action) {
      const { sn } = action.payload
      state[sn].ud5g.refSource.isRequesting = false
      state[sn].ud5g.refSource.isSuccess = true

      return state
    },
    clearUD5gRefSourceStatus(state, action) {
      const { sn } = action.payload
      state[sn].ud5g.refSource = R.map(e => false)(state[sn].ud5g.refSource)

      return state
    },

    // 設定 udbox 0630 的 deviceSettings 的各種狀態
    setUD0630Requesting(state, action) {
      const { sn, key } = action.payload
      state[sn].ud0630[key].isRequesting = true
      return state
    },
    setUD0630Success(state, action) {
      const { sn, key } = action.payload
      state[sn].ud0630[key].isSuccess = true
      return state
    },
    clearUd0630Status(state, action) {
      const { sn } = action.payload
      state[sn].ud0630 = R.map(R.map(e => false))(state[sn].ud0630)
      return state
    },
  },
})

export const udboxUIControlForSingleAction =
  udboxUIControlForSingleSlice.actions

export const udboxUIControlForSingle = udboxUIControlForSingleSlice.reducer
