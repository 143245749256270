import facilityClass from './facility.mjs'
import localMiddlewareClass from './localMiddleware.mjs'
import allDeviceClass from './allDevice.mjs'
import beamformersClass from './beamformers.mjs'
import freqConverterClass from './freqConverter.mjs'
import powerDetectorClass from './powerDetector.mjs'

export class Controllers {
  constructor() {
    this.data = {}

    // beamformers
    this.caliTables = {}
    this.risMockCHIndex = {}
  }
}

function mixin(target, ...sources) {
  Object.assign(target.prototype, ...sources)
}

mixin(
  Controllers,
  facilityClass,
  localMiddlewareClass,
  allDeviceClass,
  beamformersClass,
  freqConverterClass,
  powerDetectorClass
)

var instance = new Controllers()

export default instance
