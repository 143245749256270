import { take, put, race, call, select } from 'redux-saga/effects'
import * as R from 'ramda'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import {
  pd_customCaliModal_clearIncompleteDataChildModal_cancelClick_watcher,
  pd_customCaliModal_clearIncompleteDataChildModal_clearClick_watcher,
  pd_customCaliModal_deleteCheckChildModal_cancelClick_watcher,
  pd_customCaliModal_deleteCheckChildModal_deleteClick_watcher,
  pd_customCaliModal_saveCaliReminderChildModal_continuousClick_watcher,
  pd_customCaliModal_saveCaliReminderChildModal_noClick_watcher,
} from 'src/redux/actions/powerDetector'

import { devWarLog } from 'src/funcs/tools'

import powerDetectorApi from 'src/redux/sagas/services/socketAPI/powerDetector'
import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'

import { modalActions } from 'src/redux/slices/modal'
import { getInitCaliData } from 'src/constants/powerDetector'
import { getUiControl } from 'src/redux/sagas/selector/uiControl'

export function* configCustomClick(payloads) {
  try {
    const { sn } = payloads

    yield put(modalActions.show_modal_of_powerDetector_CC({ sn }))
  } catch (error) {
    devWarLog('[handler] configCustomClick error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Custom Cali     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* customCaliCreate(payloads) {
  try {
    const newCaliData = getInitCaliData()
    yield put(
      modalActions.set_modal_of_powerDetector_CC_to_edit({
        editIsCreate: true,
        temporaryData: newCaliData,
      })
    )
  } catch (error) {
    devWarLog('[handler] customCaliCreate error:', error)
  }
}

export function* customCaliEdit(payloads) {
  try {
    const { temporaryData } = payloads

    yield put(
      modalActions.set_modal_of_powerDetector_CC_to_edit({
        editIsCreate: false,
        temporaryData,
      })
    )
  } catch (error) {
    devWarLog('[handler] customCaliEdit error:', error)
  }
}

export function* customCaliGetVoltage(payloads) {
  try {
    const { sn, freq, power, target } = payloads
    const { lookupID } = yield call(getCommonArgs, sn)

    // 將 targetVoltage 設成 '---'
    const editTemporaryData = yield select(
      state => state.facilityModal.normal.props.editTemporaryData
    )
    let cloneTempData = R.clone(editTemporaryData)

    yield put(
      modalActions.set_temporaryData_for_modal_of_powerDetector_CC({
        editTemporaryData: cloneTempData,
      })
    )

    yield put(
      modalActions.set_isChanged_for_modal_of_powerDetector_CC({
        editIsChanged: true,
      })
    )

    yield put(
      modalActions.add_getVoltageList_for_modal_of_powerDetector_CC({
        freq,
        target,
      })
    )

    yield call(
      __socket_API_sender,
      powerDetectorApi.ACTION_PANEL_GET_CALI_VOLTAGE,
      {
        lookupID,
        sn,
        freq,
        power,
      }
    )
  } catch (error) {
    devWarLog('[handler] customCaliCreate error:', error)
  }
}

export function* customCaliLeaveEdit(payloads) {
  try {
    const { editIsChanged } = yield select(
      state => state.facilityModal.normal.props
    )

    if (editIsChanged) {
      yield put(
        modalActions.show_childModal_of_powerDetector_CC_leaveEditCheck()
      )
    } else {
      yield put(modalActions.set_modal_of_powerDetector_CC_leave_edit())
    }
  } catch (error) {
    devWarLog('[handler] customCaliLeaveEdit error:', error)
  }
}

function* _checkUsingNotIncludesSelectIdList({ sn, selectIdList }) {
  const { currentData } = yield call(getCommonArgs, sn)

  const currentCaliID = currentData.deviceControl.actionPanel.currentCaliID

  return selectIdList.includes(currentCaliID)
}

export function* customCaliDelete(payloads) {
  try {
    const { sn, selectIdList, isEditMode } = payloads

    const isUsingNotIncludesSelectIdList = yield call(
      _checkUsingNotIncludesSelectIdList,
      { sn, selectIdList }
    )

    if (isUsingNotIncludesSelectIdList)
      yield put(
        modalActions.show_childModal_of_powerDetector_CC_usingNotDeleteCheck()
      )

    if (!isUsingNotIncludesSelectIdList) {
      const checkModalAction = isEditMode
        ? modalActions.show_childModal_of_powerDetector_CC_editModeDeleteCheck()
        : modalActions.show_childModal_of_powerDetector_CC_listModeDeleteCheck()

      yield put(checkModalAction)

      const { deleteClick, cancelClick } = yield race({
        deleteClick: take(
          pd_customCaliModal_deleteCheckChildModal_deleteClick_watcher.type
        ),
        cancelClick: take(
          pd_customCaliModal_deleteCheckChildModal_cancelClick_watcher.type
        ),
      })

      if (cancelClick)
        yield put(modalActions.clear_childModal_of_powerDetector_CC())

      if (deleteClick) {
        yield put(
          modalActions.set_isDeleteIsRequesting_for_modal_of_powerDetector_CC({
            isDeleteRequesting: true,
          })
        )

        const { lookupID } = yield call(getCommonArgs, sn)

        yield put(
          modalActions.set_isDeleteIsRequesting_for_modal_of_powerDetector_CC({
            isDeleteRequesting: true,
          })
        )

        yield call(
          __socket_API_sender,
          powerDetectorApi.ACTION_PANEL_DELETE_CALI_DATA,
          {
            lookupID,
            sn,
            deleteIdList: selectIdList,
          }
        )
      }
    }
  } catch (error) {
    devWarLog('[handler] customCaliDelete error:', error)
  }
}

const _findIncompleteTempData = R.tryCatch(
  R.pipe(
    R.values,
    R.head,
    R.prop('value'),
    R.filter(e =>
      R.or(R.equals('---', e.lowVolt), R.equals('---', e.highVolt))
    ),
    R.isEmpty,
    R.not
  ),
  error => console.log('_findIncompleteTempData error: ', error)
)

const _tempDataFilterNotDefaultFreq = data => {
  const cloneData = R.clone(data)
  const id = Object.keys(data)[0]

  cloneData[id].value = R.filter(R.propEq(false, 'isDefault'))(
    cloneData[id].value
  )

  return cloneData
}

function* _checkIsUsingAndChartDataNotEmpty({ sn, editTemporaryData }) {
  const editID = R.pipe(R.keys, R.head)(editTemporaryData)

  const { currentData } = yield call(getCommonArgs, sn)
  const currentCaliID = currentData.deviceControl.actionPanel.currentCaliID

  const { single: s_uiControl } = yield call(getUiControl, sn)
  const chartData = s_uiControl.chartData

  const isChartDataNotEmpty =
    chartData.filter(e => e.power !== null).length !== 0

  return editID === currentCaliID && isChartDataNotEmpty
}

export function* customCaliSave(payloads) {
  try {
    const { sn, editIsCreate, editTemporaryData } = yield select(
      state => state.facilityModal.normal.props
    )

    let isCheckPass = true

    // -----------------------------
    // step 1. check incomplete data
    const isIncompleteTempDataExist = _findIncompleteTempData(editTemporaryData)

    if (isIncompleteTempDataExist) {
      isCheckPass = false

      yield put(
        modalActions.show_childModal_powerDetector_CC_clearIncompleteData()
      )

      const { clear, cancel } = yield race({
        clear: take(
          pd_customCaliModal_clearIncompleteDataChildModal_clearClick_watcher.type
        ),
        cancel: take(
          pd_customCaliModal_clearIncompleteDataChildModal_cancelClick_watcher.type
        ),
      })

      if (cancel) yield put(modalActions.clear_childModal_of_powerDetector_CC())
      if (clear) isCheckPass = true
    }

    // -----------------------------
    // step 2. check isUsing & chart not empty
    const isUsingAndChartDataNotEmpty = yield call(
      _checkIsUsingAndChartDataNotEmpty,
      {
        sn,
        editTemporaryData,
      }
    )

    if (isCheckPass && isUsingAndChartDataNotEmpty) {
      isCheckPass = false

      yield put(
        modalActions.show_childModal_powerDetector_CC_saveCalibrationConfigReminder()
      )

      const { continuous, no } = yield race({
        continuous: take(
          pd_customCaliModal_saveCaliReminderChildModal_continuousClick_watcher.type
        ),
        no: take(
          pd_customCaliModal_saveCaliReminderChildModal_noClick_watcher.type
        ),
      })

      if (no) yield put(modalActions.clear_childModal_of_powerDetector_CC())
      if (continuous) isCheckPass = true
    }

    // -----------------------------
    // step 3. all pass -> call api
    if (isCheckPass) {
      const { lookupID } = yield call(getCommonArgs, sn)
      const sendToBeData = _tempDataFilterNotDefaultFreq(editTemporaryData)

      yield put(
        modalActions.set_editSaveIsRequesting_for_modal_of_powerDetector_CC({
          editSaveIsRequesting: true,
        })
      )

      if (editIsCreate)
        yield call(
          __socket_API_sender,
          powerDetectorApi.ACTION_PANEL_INSERT_CALI_DATA,
          {
            lookupID,
            sn,
            data: sendToBeData,
          }
        )

      if (!editIsCreate) {
        const updateCaliAck = yield call(
          __socket_API_sender,
          powerDetectorApi.ACTION_PANEL_UPDATE_CALI_DATA,
          {
            lookupID,
            sn,
            data: sendToBeData,
          }
        )

        if (updateCaliAck === 0 && isUsingAndChartDataNotEmpty)
          yield call(
            __socket_API_sender,
            localMiddlewareApi.DASHBOARD_CLEAR_POWER_HISTORY,
            {
              lookupID,
              sn,
            }
          )
      }
    }
  } catch (error) {
    devWarLog('[handler] customCaliSave error:', error)
  }
}
