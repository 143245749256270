import React, { memo } from 'react'
// import { useTransition, animated, config } from '@react-spring/web'
import { useTransition, animated } from '@react-spring/web'
import PropTypes from 'prop-types'

const Divider = memo(() => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const animate = useTransition(false, {
    from: { opacity: 0, width: '0%' },
    enter: { opacity: 1, width: '100%' },
    leave: { opacity: 0, width: '0%' },
    delay: 100,
    // config: { ...config.wobbly },
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return animate(styles => <animated.div className={divider} style={styles} />)
})

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const divider = `bg-dark-5 h-[1px] mb-8 mt-4`

export default Divider

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Divider.propTypes = { show: PropTypes.bool }
