import { useParams, useResolvedPath } from 'react-router-dom'

const useGetCurrentMode = props => {
  // 一般頁面，抓 params 就可以直接抓到是不是 demo mode
  // 但在開啟 modal 狀態時，params 永遠都會是空的，所以抓完整 pathname (resolvedPath)來判斷
  const param = useParams()
  const resolvedPath = useResolvedPath()

  // 之所以拆成3個 各別判斷，最主要考量是以後有超過兩種以上的模式比較不會出錯
  const paramFindCurrentMode = param?.mode
  const pathnameFoundCurrentMode = resolvedPath?.pathname
    .split('/')
    .filter(e => e)[1]

  const paramFoundDeviceControl = param?.mode === 'device-control'
  const pathnameFoundDeviceControl =
    resolvedPath?.pathname.split('/')?.find(e => e === 'device-control') ===
    'device-control'

  const paramFoundDemo = param?.mode === 'demo'
  const pathnameFoundDemo =
    resolvedPath?.pathname.split('/')?.find(e => e === 'demo') === 'demo'

  return {
    currentMode: paramFindCurrentMode || pathnameFoundCurrentMode,
    isDemoMode: paramFoundDemo || pathnameFoundDemo,
    isDeviceControlMode: paramFoundDeviceControl || pathnameFoundDeviceControl,
  }
}

export default useGetCurrentMode
