import { all, fork, take, select } from 'redux-saga/effects'

// slices
import { getServiceVersionWatcher } from 'src/redux/slices/uiControl/facility/versions'
import { scanWatcher } from 'src/redux/slices/deviceData'

// actions
import {
  generalErrorWatcher,
  generalErrorSendMailWatcher,
  deviceErrorSendMailWatcher,
} from 'src/redux/actions/facility/generalError'
import { facility_getQuoteButtonClick_watcher } from 'src/redux/actions/facility/getQuote'
import {
  member_updateInfo_watcher,
  member_startSignInSignUpFlow_watcher,
  member_signOut_watcher,
} from 'src/redux/actions/facility/member'
import {
  chooseMode_deviceControlClick_watcher,
  chooseMode_demoModeClick_watcher,
  downloadMid_reConnectClick_watcher,
  initDemoMode_watcher,
  redirectToChooseMode_watcher,
} from 'src/redux/actions/facility/init'
import {
  home_leaveDeviceInfo_watcher,
  home_applyDeviceInfo_watcher,
  home_InitFailedRetryClick_watcher,
} from 'src/redux/actions/facility/home'
import { notification_getMaintenance_watcher } from 'src/redux/actions/facility/notification'

// handler
import { getServiceVersion as handleGetServiceVersion } from 'src/redux/sagas/handlers/event/facility/versions'
import { getQuote as handleGetQuote } from 'src/redux/sagas/handlers/event/facility/getQuote'
import {
  updateMemberInfo as handleUpdateMemberInfo,
  signInSignUp as handleSignInSignUp,
  signOut as handleSignOut,
} from 'src/redux/sagas/handlers/event/facility/member'
import { getMaintenance as handleGetMaintenance } from 'src/redux/sagas/handlers/event/facility/notification'
import { manualScanDevices as handleManualScanDevices } from 'src/redux/sagas/handlers/event/facility/scanDevices'
import {
  initDeviceControlMode as handleInitDeviceControlMode,
  reConnectMiddleware as handleReConnectMiddleware,
  moveToPageHomeAgainForDeviceControl as handleMoveToPageHomeAgainForDeviceControl,
  moveToPageHomeForDemoMode as handleMoveToPageHomeForDemoMode,
  initDemoMode as handleInitDemoMode,
  redirectToChooseMode as handleRedirectToChooseMode,
} from 'src/redux/sagas/handlers/event/facility/chooseMode'
import {
  applyDeviceInfo as handleApplyDeviceInfo,
  leaveDeviceInfo as handleLeaveDeviceInfo,
  initFailedRetry as handleInitFailedRetry,
} from 'src/redux/sagas/handlers/event/facility/deviceInfo'
import {
  generalError as handleGeneralError,
  generalErrorSendMail as handleGeneralErrorSendMail,
  deviceErrorSendMail as handleDeviceErrorSendMail,
} from 'src/redux/sagas/handlers/event/facility/generalError'

import { devLog } from 'src/funcs/tools'

// version
function* getServiceVersion() {
  while (true) {
    yield take(getServiceVersionWatcher.type)
    yield fork(handleGetServiceVersion)
  }
}

// get quote
function* getQuote() {
  while (true) {
    yield take(facility_getQuoteButtonClick_watcher.type)
    devLog('[watcher] getQuote')
    yield fork(handleGetQuote)
  }
}

// member
function* memberUpdateInfo() {
  while (true) {
    yield take(member_updateInfo_watcher.type)
    yield fork(handleUpdateMemberInfo)
  }
}

function* memberSignInSignUp() {
  while (true) {
    yield take(member_startSignInSignUpFlow_watcher.type)
    yield fork(handleSignInSignUp)
  }
}

function* memberSignOut() {
  while (true) {
    yield take(member_signOut_watcher.type)
    yield fork(handleSignOut)
  }
}
function* getMaintenance() {
  while (true) {
    yield take(notification_getMaintenance_watcher.type)
    yield fork(handleGetMaintenance)
  }
}

// scan devices
function* scanDevices() {
  while (true) {
    yield take(scanWatcher.type)
    yield fork(handleManualScanDevices)
  }
}

// mode change
function* modeChooseDeviceControl() {
  while (true) {
    yield take(chooseMode_deviceControlClick_watcher.type)
    devLog('[watcher] modeChooseDeviceControl')

    const { isDeviceControlModeInitialized } = yield select(
      state => state.facilityInit
    )

    if (isDeviceControlModeInitialized)
      yield fork(handleMoveToPageHomeAgainForDeviceControl)

    // socket 永遠會連接 所以這個用 fork 讓 socket 在背景一直監聽
    if (!isDeviceControlModeInitialized) yield fork(handleInitDeviceControlMode)
  }
}

function* downloadMidReConnect() {
  while (true) {
    yield take(downloadMid_reConnectClick_watcher.type)
    devLog('[watcher] downloadMidReConnect')

    // socket 永遠會連接 所以這個用 fork 讓 socket 在背景一直監聽
    yield fork(handleReConnectMiddleware)
  }
}

function* modeChooseDemoMode() {
  while (true) {
    yield take(chooseMode_demoModeClick_watcher.type)
    yield fork(handleMoveToPageHomeForDemoMode)
  }
}
function* initDemoMode() {
  while (true) {
    yield take(initDemoMode_watcher.type)
    yield fork(handleInitDemoMode)
  }
}

function* moveToPageChooseMode() {
  while (true) {
    yield take(redirectToChooseMode_watcher.type)
    yield fork(handleRedirectToChooseMode)
  }
}

// device info
function* leaveDeviceInfo() {
  while (true) {
    yield take(home_leaveDeviceInfo_watcher.type)
    yield fork(handleLeaveDeviceInfo)
  }
}
function* applyDeviceInfo() {
  while (true) {
    yield take(home_applyDeviceInfo_watcher.type)
    yield fork(handleApplyDeviceInfo)
  }
}

function* initFailedRetry() {
  while (true) {
    const action = yield take(home_InitFailedRetryClick_watcher.type)
    yield fork(handleInitFailedRetry, action.payload)
  }
}

// general Error Modal Send Mail
function* generalErrorModal() {
  while (true) {
    const action = yield take(generalErrorWatcher.type)
    yield fork(handleGeneralError, action.payload)
  }
}

function* generalErrorModalSendMail() {
  while (true) {
    yield take(generalErrorSendMailWatcher.type)
    devLog('[watcher] generalErrorModalSendMail')
    yield fork(handleGeneralErrorSendMail)
  }
}
function* deviceErrorModalSendMail() {
  while (true) {
    yield take(deviceErrorSendMailWatcher.type)
    devLog('[watcher] deviceErrorModalSendMail')
    yield fork(handleDeviceErrorSendMail)
  }
}

export default function* facilityWatchers() {
  yield all([
    // version
    getServiceVersion(),

    // get quote
    getQuote(),

    // member
    memberUpdateInfo(),
    memberSignInSignUp(),
    memberSignOut(),

    // maintenance
    getMaintenance(),

    // scan devices
    scanDevices(),

    // mode change
    modeChooseDeviceControl(),
    downloadMidReConnect(),
    modeChooseDemoMode(),
    initDemoMode(),
    moveToPageChooseMode(),

    // device info
    leaveDeviceInfo(),
    applyDeviceInfo(),
    initFailedRetry(),

    // general Error Modal Send Mail
    generalErrorModal(),
    generalErrorModalSendMail(),
    deviceErrorModalSendMail(),
  ])
}
