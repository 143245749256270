import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

import Stepper from 'src/components/Stepper'

import { h4, h6 } from 'src/containers/Modal/style'

import {
  udModule_toExternalErrorModal_tryAgainClick_watcher,
  udModule_toExternalErrorModal_cancelClick_watcher,
} from 'src/redux/actions/freqConverter/udmoduleAction'

const ToExternalError = () => {
  const dispatch = useDispatch()

  const handleCancelClick = () =>
    dispatch(udModule_toExternalErrorModal_cancelClick_watcher())

  const handleTryAgainClick = () =>
    dispatch(udModule_toExternalErrorModal_tryAgainClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Failed to use External reference source</Modal.Title>

      <Modal.Description className='text-yellow'>
        Please check the steps below and try again
      </Modal.Description>

      <Stepper name='Use External Reference Source' className='py-4'>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Connect to an external ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Ensure the source is connected.
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Turn on the external ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Make sure the source has been transmitted.
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Choose the Input Reference Source that matches your connection
          </h4>
        </Stepper.Step>
      </Stepper>

      <Modal.RowButtonBox>
        <CommonButton size='lg' type='outlined' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
        <CommonButton size='lg' type='primary' onClick={handleTryAgainClick}>
          Try again
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ToExternalError
