import React, { useEffect, useRef } from 'react'
import { useThree } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import Light from './Light'

import { degreeToRadian } from 'src/components/composite/beamformers/Beam3DControl/tools'

const getDefaultCameraAndSceneRotation = direction =>
  direction === 'left'
    ? { x: degreeToRadian(90), y: 0, z: degreeToRadian(-90) }
    : { x: 0, y: 0, z: 0 }

const Environment = ({
  sn,
  isDragging,
  defaultCameraPosition,
  deviceDirection,
  isCameraReset,
  beam3DCameraArgs,
  isEnableZoom,
  handleCameraReset,
  handleCameraChange,
}) => {
  const { scene, camera, gl } = useThree()
  const controls = useRef()

  useEffect(() => {
    const { position, rotation } = beam3DCameraArgs
    if (position && rotation) {
      const { x: px, y: py, z: pz } = position
      const { x: rx, y: ry, z: rz } = rotation
      camera.position.set(px, py, pz)
      camera.rotation.set(rx, ry, rz)
    } else {
      const [px, py, pz] = defaultCameraPosition
      const {
        x: rx,
        y: ry,
        z: rz,
      } = getDefaultCameraAndSceneRotation(deviceDirection)
      camera.position.set(px, py, pz)
      camera.rotation.set(rx, ry, rz)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  useEffect(() => {
    if (isCameraReset)
      camera.position.set(
        defaultCameraPosition[0],
        defaultCameraPosition[1],
        defaultCameraPosition[2]
      )
  }, [
    scene,
    camera,
    deviceDirection,
    isCameraReset,
    handleCameraReset,
    defaultCameraPosition,
  ])
  setTimeout(() => void handleCameraReset(false), 100)

  useEffect(() => {
    const {
      x: rx,
      y: ry,
      z: rz,
    } = getDefaultCameraAndSceneRotation(deviceDirection)
    scene.rotation.set(rx, ry, rz)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDirection])

  const handleOrbitControlsChange = () => {
    const lstPositionArray = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    }
    const lstRotationArray = {
      x: camera.rotation._x,
      y: camera.rotation._y,
      z: camera.rotation._z,
    }
    handleCameraChange({
      position: lstPositionArray,
      rotation: lstRotationArray,
    })
  }

  return (
    <>
      <OrbitControls
        onChange={handleOrbitControlsChange}
        ref={controls}
        args={[camera, gl.domElement]}
        enabled={!isDragging}
        enableZoom={isEnableZoom || false}
        enablePan={false}
        enableDamping={false}
        minDistance={18}
        maxDistance={35}
      />

      <Light />
    </>
  )
}

export default Environment
