import React, { useRef } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6, small } from 'src/containers/operating/device/freqConverter/style'

import CommonInput from 'src/components/Input/CommonInput'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { isRepeatRoundsInvalid } from 'src/funcs/device/sg'

const RepeatRounds = props => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const customInputRef = useRef(null)

  const isOutputting = current.data.settings.output.isOutputting
  const { currentValue, min, max } =
    current.data?.settings?.output.outputControls.repeatRounds

  const segmentButtonValue = currentValue === null ? 'infinite' : 'custom'

  const currentValueInvalid = isRepeatRoundsInvalid({
    value: currentValue,
    min,
    max,
  })
  const smallTextColor = currentValueInvalid ? ' text-red' : ' text-white/50'
  const customBoxClass =
    segmentButtonValue === 'custom'
      ? 'h-[52px] duration-500'
      : 'h-0 overflow-hidden'

  const handleSegmentButtonChange = (event, value) => {
    let currentDeviceData = R.clone(current.data)

    if (value === 'infinite')
      currentDeviceData.settings.output.outputControls.repeatRounds.currentValue =
        null

    if (value === 'custom') {
      currentDeviceData.settings.output.outputControls.repeatRounds.currentValue =
        ''
      customInputRef?.current?.focus()
    }

    dispatch(setSingleDeviceData({ sn, data: currentDeviceData }))
  }

  const handleCustomInputChange = result => {
    let currentDeviceData = R.clone(current.data)

    currentDeviceData.settings.output.outputControls.repeatRounds.currentValue =
      result.formattedValue

    dispatch(setSingleDeviceData({ sn, data: currentDeviceData }))
  }
  return (
    <div>
      <h6 className={h6 + ' text-light-1 mb-1'}>Repeat Rounds</h6>

      <div>
        <SegmentedButton
          size={isXL || is2XL ? 'sm' : 'md'}
          type='outlined'
          value={segmentButtonValue}
          disabled={isOutputting}
          onChange={handleSegmentButtonChange}>
          <SegmentedButton.Option name='infinite'>
            Infinite
          </SegmentedButton.Option>
          <SegmentedButton.Option name='custom'>Custom</SegmentedButton.Option>
        </SegmentedButton>

        <div className={customBoxClass}>
          <CommonInput
            ref={customInputRef}
            inputType='number'
            size={isXL || is2XL ? 'sm' : 'md'}
            danger={currentValueInvalid}
            className='mt-1'
            disabled={isOutputting}
            value={currentValue || ''}
            onChange={handleCustomInputChange}
          />
          <small className={small + smallTextColor + ' mt-1'}>
            Range {min} ~ {max}
          </small>
        </div>
      </div>
    </div>
  )
}

export default RepeatRounds
