import React from 'react'

import {
  touchContainerSize,
  unitSize,
  touchButtonBox,
  touchButton,
} from 'src/components/Input/InputGroup/style'

import IconButton from 'src/components/Button/IconButton'

import { BiPlus } from 'react-icons/bi'

const TouchRight = props => {
  const {
    off,
    disabled,
    unit,
    size,
    isFocus,
    isHover,
    isKeydownMax,
    handlePlus,
    handlePointerDownForPlus,
    handlePointerUpForPlus,
  } = props

  const buttonDisabled = isKeydownMax || disabled
  const showButton = !disabled && !off && (isFocus || isHover)

  const handleClick = event => !buttonDisabled && handlePlus(event)
  const handlePointerDown = event =>
    !buttonDisabled && handlePointerDownForPlus(event)
  const handlePointerUp = event =>
    !buttonDisabled && handlePointerUpForPlus(event)

  const containerClass = `
                          ${touchContainerSize[size]} 
                          h-full 
                          flex shrink-0 
                          items-center justify-center 
                          overflow-hidden 
                          ml-1
                          `

  const unitClass = `
                    ${unitSize[size]} 
                    w-full 
                    text-center text-white/40 font-normal 
                    mx-[2px] 
                    `

  return (
    <div className={containerClass}>
      {!showButton && unit && <div className={unitClass}>{!off && unit}</div>}

      {showButton && (
        <div
          className={touchButtonBox + ' border-l-1 touch-none'}
          onClick={handleClick}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}>
          <IconButton
            className={touchButton}
            type='standard'
            size={size}
            tabIndex='-1'
            disabled={buttonDisabled}>
            <BiPlus />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default TouchRight
