import React, { useRef, useState, useLayoutEffect } from 'react'
// import { useDispatch } from 'react-redux'

import Tooltip from 'src/components/Popup/Tooltip'

import {
  // BiCog,
  // BiSlideshow,
  // BiLogOutCircle,
  // BiLinkAlt,
  BiDetail,
} from 'react-icons/bi'

import { iconBox } from 'src/containers/home/style'

const Menu = ({ handleOpenDeviceInfoClick }) => {
  const containerRef = useRef()
  const [containerHeight, setContainerHeight] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setContainerHeight(containerRef.current.clientHeight)
  })

  const data = [
    {
      title: `Device Info & Setting`,
      icon: <BiDetail />,
      type: '',
      onClick: handleOpenDeviceInfoClick,
    },
    // {
    //   title: 'Settings',
    //   icon: <BiCog />,
    //   type: '',
    //   onClick: () => {},
    // },
    // {
    //   title: 'Demo Mode',
    //   icon: <BiSlideshow />,
    //   type: '',
    //   onClick: () => {},
    // },

    //* phase - 2
    // {
    //   title: 'Link Budget',
    //   icon: <BiLinkAlt />,
    //   type: '',
    //   onClick: () => {},
    // },
    // {
    //   title: 'Log out',
    //   icon: <BiLogOutCircle />,
    //   type: '',
    //   onClick: () => {},
    // },
  ]

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerStyle = { top: `calc(50vh - ${containerHeight / 2}px)` }

  const containerFlex = 'flex flex-col justify-center gap-y-10'
  const container = `${containerFlex} 
                      fixed z-10 
                      left-4 md:left-8
                      2xl:left-[calc(20px_+_((100vw_-_1440px)_/_2))]
                      `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <aside className={container} ref={containerRef} style={containerStyle}>
      {data.map(element => (
        <ContentDecide key={'home menu item ' + element.title} {...element} />
      ))}
    </aside>
  )
}

export default Menu

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const ContentDecide = ({ title, icon, type, onClick, link }) => {
  if (type === 'externalLink')
    return (
      <Tooltip
        content={title}
        placement='right'
        contentClassName='break-normal whitespace-nowrap '>
        <a href={link} target='_blank' className={iconBox} rel='noreferrer'>
          {icon}
        </a>
      </Tooltip>
    )

  return (
    <Tooltip
      content={title}
      placement='right'
      contentClassName='break-normal whitespace-nowrap '>
      <div className={iconBox} onClick={onClick}>
        {icon}
      </div>
    </Tooltip>
  )
}
