import React from 'react'

import EditBeam from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam'

import ImportNewSettings from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/ImportNewSettings'
import FailedToImportForNoMatchedAntenna from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/FailedToImportForNoMatchedAntenna'
import FailedToImportForIncorrectFormat from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/FailedToImportForIncorrectFormat'
import FailedToImportForIncorrectSn from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/import/FailedToImportForIncorrectSn'

import UnsavedChangesReminder from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/export/UnsavedChangesReminder'
import CurrentMethodSwitching from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/CurrentMethodSwitching'

import FailedToSaveForNoAntennaAndBeamExist from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/save/FailedToSaveForNoAntennaAndBeamExist'
import FailedToSaveForGeneral from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/save/FailedToSaveForGeneral'

const BeamConfigEditor = props => {
  const { status: modalStatus } = props

  if (modalStatus === 'EditBeam') return <EditBeam {...props} />

  // ----- save
  if (modalStatus === 'FailedToSaveForNoAntennaAndBeamExist')
    return <FailedToSaveForNoAntennaAndBeamExist {...props} />

  if (modalStatus === 'FailedToSaveForGeneral')
    return <FailedToSaveForGeneral {...props} />

  // ----- save
  if (modalStatus === 'UnsavedChangesReminder')
    return <UnsavedChangesReminder {...props} />

  // ----- Current Method Switching
  if (modalStatus === 'CurrentMethodSwitching')
    return <CurrentMethodSwitching {...props} />

  // ----- import
  if (modalStatus === 'FailedToImportForNoMatchedAntenna')
    return <FailedToImportForNoMatchedAntenna {...props} />

  if (modalStatus === 'ImportNewSettings')
    return <ImportNewSettings {...props} />

  if (modalStatus === 'FailedToImportForIncorrectFormat')
    return <FailedToImportForIncorrectFormat {...props} />

  if (modalStatus === 'FailedToImportForIncorrectSn')
    return <FailedToImportForIncorrectSn {...props} />

  // ----- export
  if (modalStatus === 'UnsavedChangesReminder')
    return <UnsavedChangesReminder {...props} />

  return <></>
}

export default BeamConfigEditor
