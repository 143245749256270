import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const LEDLights = props => {
  const { channel, className } = props

  return (
    <SVGUniqueID>
      <svg
        width='210'
        height='104'
        viewBox='0 0 210 104'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        {channel === 'single' ? <SingleLights /> : <DualLights />}
      </svg>
    </SVGUniqueID>
  )
}
export default LEDLights

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const SingleLights = () => {
  return (
    <>
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        fill='white'
      />
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        fill='white'
      />
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        stroke='white'
      />
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        stroke='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        fill='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        fill='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        stroke='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        stroke='white'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        fill='white'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        fill='#0064FF'
        className='brightness-200'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        stroke='#0064FF'
        className='brightness-200'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        stroke='#0064FF'
        className='brightness-200'
      />

      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        fill='white'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        fill='white'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        stroke='white'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        stroke='white'
      />
      <path
        d='M79.1584 29.9971C79.2122 31.8667 78.0388 33.2306 76.7515 33.2676C75.4641 33.3047 74.2143 32.0105 74.1604 30.141C74.1066 28.2715 75.28 26.9076 76.5673 26.8705C77.8547 26.8335 79.1046 28.1276 79.1584 29.9971Z'
        fill='white'
        stroke='white'
      />
      <path
        d='M67.7695 34.0262C69.4257 33.9785 70.7206 32.2841 70.6618 30.2415C70.603 28.199 69.2128 26.5818 67.5566 26.6295C65.9005 26.6772 64.6055 28.3716 64.6643 30.4142C64.7231 32.4567 66.1133 34.0739 67.7695 34.0262Z'
        fill='#EB5757'
      />
    </>
  )
}
const DualLights = () => {
  return (
    <>
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        fill='white'
      />
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        fill='white'
      />
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        stroke='white'
      />
      <path
        d='M124.14 28.7022C124.193 30.5718 123.02 31.9357 121.733 31.9727C120.445 32.0098 119.195 30.7156 119.142 28.8461C119.088 26.9765 120.261 25.6126 121.549 25.5756C122.836 25.5385 124.086 26.8327 124.14 28.7022Z'
        stroke='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        fill='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        fill='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        stroke='white'
      />
      <path
        d='M115.143 28.961C115.197 30.8305 114.024 32.1944 112.737 32.2315C111.449 32.2686 110.199 30.9744 110.146 29.1049C110.092 27.2353 111.265 25.8714 112.552 25.8344C113.84 25.7973 115.09 27.0915 115.143 28.961Z'
        stroke='white'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        fill='white'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        fill='#0064FF'
        className='brightness-200'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        stroke='#0064FF'
        className='brightness-200'
      />
      <path
        d='M106.147 29.2203C106.201 31.0898 105.028 32.4537 103.74 32.4908C102.453 32.5278 101.203 31.2337 101.149 29.3641C101.095 27.4946 102.269 26.1307 103.556 26.0937C104.843 26.0566 106.093 27.3508 106.147 29.2203Z'
        stroke='#0064FF'
        className='brightness-200'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        fill='white'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        fill='white'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        stroke='white'
      />
      <path
        d='M97.1508 29.4791C97.2046 31.3486 96.0312 32.7125 94.7439 32.7496C93.4566 32.7866 92.2067 31.4925 92.1529 29.6229C92.0991 27.7534 93.2724 26.3895 94.5598 26.3524C95.8471 26.3154 97.097 27.6095 97.1508 29.4791Z'
        stroke='white'
      />
      <path
        d='M88.1545 29.7384C88.2083 31.6079 87.0349 32.9718 85.7476 33.0088C84.4602 33.0459 83.2103 31.7517 83.1565 29.8822C83.1027 28.0127 84.2761 26.6488 85.5634 26.6117C86.8508 26.5747 88.1007 27.8688 88.1545 29.7384Z'
        fill='white'
        stroke='white'
      />
      <path
        d='M79.1584 29.9971C79.2122 31.8667 78.0388 33.2306 76.7515 33.2676C75.4641 33.3047 74.2143 32.0105 74.1604 30.141C74.1066 28.2715 75.28 26.9076 76.5673 26.8705C77.8547 26.8335 79.1046 28.1276 79.1584 29.9971Z'
        fill='white'
        stroke='white'
      />
      <path
        d='M67.7695 34.0262C69.4257 33.9785 70.7206 32.2841 70.6618 30.2415C70.603 28.199 69.2128 26.5818 67.5566 26.6295C65.9005 26.6772 64.6055 28.3716 64.6643 30.4142C64.7231 32.4567 66.1133 34.0739 67.7695 34.0262Z'
        fill='#EB5757'
      />
    </>
  )
}
