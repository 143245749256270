/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useMemo } from 'react'
import useMeasure from 'react-use-measure'
import PropTypes from 'prop-types'
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
} from '@floating-ui/react'

import { mergeRefs } from 'react-merge-refs'
import { getOS } from 'src/funcs/tools'

const BlockedDialogCore = props => {
  const {
    open,
    overwriteElements,
    overwriteOffset = { top: 0, left: 0 },
    placement: initPlacement = 'top',
    render,
    overlay = true,
    overlayClassName = '',
    dismiss = false,
    children,
  } = props

  const [triggerRef, bounds] = useMeasure({ scroll: true })
  const arrowRef = useRef()

  const { x, y, refs, strategy, placement, middlewareData, context } =
    useFloating({
      placement: initPlacement,
      strategy: 'fixed',
      middleware: [
        offset(14), // 8 margin + 6 arrow width
        flip(),
        shift({ padding: 5 }),
        arrow({
          element: arrowRef,
          padding: 6,
        }),
      ],
      whileElementsMounted: autoUpdate,
    })

  const referenceRef = useMemo(
    () => mergeRefs([refs.setReference, triggerRef]),
    [refs.setReference, triggerRef]
  )

  // 處理 arrow 反轉用
  const arrowX = middlewareData.arrow?.x
  const arrowY = middlewareData.arrow?.y

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]]

  const isIOS = getOS() === 'ios'

  //! ios 系列 (iphone, ipad) focus 鍵盤彈出時，不加 window.scrollY 會造成 dialog position 位移
  let overWritePositionY = bounds?.y + overwriteOffset.top
  if (isIOS) overWritePositionY += window.scrollY

  let overWritePositionX = bounds?.x + overwriteOffset.left
  if (isIOS) overWritePositionX += window.scrollX

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role: 'Popover' }),
    useDismiss(context, { enabled: dismiss }),
  ])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <>
      {/* 如果去抓 children.ref 會有部分元件不工作，因此在外部包一層 trigger div */}
      {/* 這邊是原本的 reference ，不能動！要拿來抓位置 */}
      <div
        className={open ? 'opacity-0' : ''}
        ref={referenceRef}
        {...getReferenceProps()}>
        {children}
      </div>

      {open && (
        <Overlay overlay={overlay}>
          {/* overwriteElements */}
          <div
            className={`${overlayClassName} fixed `}
            style={{
              top: 0,
              left: 0,
              transform: `translate(${Math.round(overWritePositionX)}px,
              ${Math.round(overWritePositionY)}px)`,
              width: bounds?.width,
            }}
            {...getReferenceProps()}>
            {overwriteElements}
          </div>

          {/* floating elements */}
          <div
            ref={refs.setFloating}
            className={containerClass}
            style={{
              position: strategy,
              top: 0,
              left: 0,
              transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
            }}
            {...getFloatingProps()}>
            <div
              ref={arrowRef}
              className={arrowClass}
              style={{
                position: strategy,
                zIndex: -1,
                left: arrowX ?? '',
                top: arrowY ?? '',
                [staticSide]: '-6px',
              }}
            />
            {render()}
          </div>
        </Overlay>
      )}
    </>
  )
}

export default BlockedDialogCore

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerClass = `bg-black shadow-sm shadow-black/20 p-4 rounded-[4px] text-white text-sm font-normal z-10 will-change-transform`
const arrowClass = `absolute bg-black w-3 h-3 rotate-45`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const Overlay = ({ overlay, children }) => {
  if (overlay)
    return (
      <FloatingPortal>
        <FloatingOverlay
          lockScroll={true}
          className='bg-black/50 fixed h-screen w-screen z-10'>
          {children}
        </FloatingOverlay>
      </FloatingPortal>
    )

  return <>{children}</>
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
BlockedDialogCore.propTypes = {
  open: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  overwriteElements: PropTypes.node,
  overwriteOffset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  placement: PropTypes.string,
  render: PropTypes.func,
  overlay: PropTypes.bool,
  overlayClassName: PropTypes.string,
  dismiss: PropTypes.bool,
}
