import React from 'react'
import * as R from 'ramda'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import pattern_0_svg from 'src/assets/svg/cttcControlPattern/0.svg'
import pattern_1_svg from 'src/assets/svg/cttcControlPattern/1.svg'
import pattern_2_svg from 'src/assets/svg/cttcControlPattern/2.svg'
import pattern_3_svg from 'src/assets/svg/cttcControlPattern/3.svg'
import pattern_4_svg from 'src/assets/svg/cttcControlPattern/4.svg'
import pattern_5_svg from 'src/assets/svg/cttcControlPattern/5.svg'
import pattern_6_svg from 'src/assets/svg/cttcControlPattern/6.svg'
import pattern_7_svg from 'src/assets/svg/cttcControlPattern/7.svg'
import pattern_8_svg from 'src/assets/svg/cttcControlPattern/8.svg'

import { h4 } from 'src/containers/operating/device/beamformers/style'
import { useDispatch } from 'react-redux'
import { cloverCellCTTC_steering_controlPatternChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const svgList = {
  0: pattern_0_svg,
  1: pattern_1_svg,
  2: pattern_2_svg,
  3: pattern_3_svg,
  4: pattern_4_svg,
  5: pattern_5_svg,
  6: pattern_6_svg,
  7: pattern_7_svg,
  8: pattern_8_svg,
}
const patternList = {
  0: [1, 2, 3, 4],
  1: [3],
  2: [1],
  3: [4],
  4: [2],
  5: [3, 4],
  6: [1, 2],
  7: [1, 3],
  8: [2, 4],
}

const ControlPattern = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const controlPattern = current.data?.deviceControl?.steering.controlPattern

  const patternData = new Array(9).fill(null).map((_, i) => ({
    icon: <img src={svgList[i]} alt={`tmytek cttc pattern ${i}`} />,
    isActive: R.equals(patternList[i], controlPattern),
  }))

  const handleClick = event => {
    const index = event.currentTarget.getAttribute('data-index')

    dispatch(
      cloverCellCTTC_steering_controlPatternChange_watcher({
        sn,
        value: patternList[index],
      })
    )
  }

  const itemClass = isActive => `
                w-12 h-12 
                shrink-0 
                flex items-center justify-center 
                border-solid border-1 
                ${isActive ? 'border-teal' : 'border-light-4/20'} 
                rounded-[4px] 
                cursor-pointer 
                hover:opacity-50
                duration-500 
                `

  return (
    <div>
      <h4 className={`${h4} mb-1`}>Control Pattern</h4>
      <div className='flex items-center justify-between gap-x-4'>
        {patternData.map((e, i) => (
          <div
            data-index={i}
            onClick={handleClick}
            className={itemClass(e.isActive)}
            key={`control pattern button ${i}`}>
            {e.icon}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ControlPattern
