import { createAction } from '@reduxjs/toolkit'

export const facility_getQuoteButtonClick_watcher = createAction(
  'facility/facility_getQuoteButtonClick_watcher'
)

// modal
export const facility_chooseDeviceModal_cancelClick_watcher = createAction(
  'modalEvent/facility_chooseDeviceModal_cancelClick_watcher'
)
export const facility_personalInformationModal_submit_watcher = createAction(
  'modalEvent/facility_personalInformationModal_submit_watcher'
)
