const lottie = {
  // scan
  // 顯示 scan loading 動畫
  showModalOfTMYTEKFullAnimationLoading: (state, action) => {
    state.normal = {
      type: 'TMYTEK_FULL_ANIMATION',
      props: { status: 'loading' },
    }
    return state
  },
  // 顯示 scan 完成動畫
  showModalOfTMYTEKFullAnimationComplete: (state, action) => {
    state.normal = {
      type: 'TMYTEK_FULL_ANIMATION',
      props: { status: 'complete' },
    }

    return state
  },

  // loading
  showModalOfTMYTEKShortAnimationLoading: (state, action) => {
    state.normal = {
      type: 'TMYTEK_SHORT_ANIMATION',
      props: { status: 'loading' },
    }
    return state
  },
  showModalOfTMYTEKShortAnimationComplete: (state, action) => {
    state.normal = {
      type: 'TMYTEK_SHORT_ANIMATION',
      props: { status: 'complete' },
    }
    return state
  },

  showModalOfTMYTEKLoginWelcome: (state, action) => {
    state.normal = {
      type: 'TMYTEK_LOGIN_WELCOME',
      props: { status: '' },
    }
    return state
  },
}
export default lottie
