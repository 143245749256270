import { call, delay, select, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import { getInitCaliData } from 'src/constants/powerDetector'

export function* insertCaliData(response) {
  try {
    const [sn, data] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    const initCaliDataValue = Object.values(getInitCaliData())[0].value

    let [responseCaliID, responseCaliData] = Object.entries(data)[0]

    const insertData = data
    insertData[responseCaliID].value = {
      ...initCaliDataValue,
      ...responseCaliData.value,
    }

    currentData.deviceControl.actionPanel.caliData = {
      ...currentData.deviceControl.actionPanel.caliData,
      ...insertData,
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(
      modalActions.show_childModal_of_powerDetector_CC_caliConfigFileSaved()
    )
    yield delay(1000)
    yield put(modalActions.set_modal_of_powerDetector_CC_leave_edit())
  } catch (error) {
    devWarLog('[res-handler] insertCaliData error:', error)
  }
}

export function* updateCaliData(response) {
  try {
    const [sn, data] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    const [id, content] = Object.entries(data)[0]

    // 更新 caliData
    const previousCaliDataValue =
      currentData.deviceControl.actionPanel.caliData[id].value

    currentData.deviceControl.actionPanel.caliData[id] = content

    currentData.deviceControl.actionPanel.caliData[id].value = {
      ...previousCaliDataValue,
      ...content.value,
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))
    yield put(
      modalActions.show_childModal_of_powerDetector_CC_caliConfigFileSaved()
    )
    yield delay(1000)
    yield put(modalActions.set_modal_of_powerDetector_CC_leave_edit())
  } catch (error) {
    devWarLog('[res-handler] updateCaliData error:', error)
  }
}

export function* deleteCaliData(response) {
  try {
    const [sn, { caliData }] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.caliData = caliData

    yield put(setSingleDeviceData({ sn, data: currentData }))

    // 清空 selectIdList
    yield put(
      modalActions.set_selectIdList_for_modal_of_powerDetector_CC({
        selectIdList: [],
      })
    )

    yield put(
      modalActions.set_isDeleteIsRequesting_for_modal_of_powerDetector_CC({
        isDeleteRequesting: false,
      })
    )
    yield put(modalActions.set_modal_of_powerDetector_CC_leave_edit())
  } catch (error) {
    devWarLog('[res-handler] updateCaliData error:', error)
  }
}

export function* getCaliVoltage(response) {
  try {
    const [, freqPowerVolt] = Object.entries(response?.data)[0]
    const [freq, { power, voltage }] = Object.entries(freqPowerVolt)[0]

    // 1. 塞 response voltage 到目標欄位
    const editTemporaryData = yield select(
      state => state.facilityModal.normal.props.editTemporaryData
    )
    let cloneTempData = R.clone(editTemporaryData)

    const [[id, content]] = Object.entries(cloneTempData)

    let targetVoltage
    let oppositeVoltage

    if (+content.value[freq].lowPower === +power) {
      targetVoltage = 'lowVolt'
      oppositeVoltage = 'highVolt'
    } else if (+content.value[freq].highPower === +power) {
      targetVoltage = 'highVolt'
      oppositeVoltage = 'lowVolt'
    } else throw Error('getCaliVoltage: BE return power not matched!')

    cloneTempData[id].value[freq][targetVoltage] = voltage

    // 2. 判斷是否要將 oppositeVoltage 設成 '---'
    const editGetVoltageList = yield select(
      state => state.facilityModal.normal.props.editGetVoltageList
    )
    const isNeedChangeOppositeValueToDash =
      !editGetVoltageList[freq].includes(oppositeVoltage)

    if (isNeedChangeOppositeValueToDash)
      cloneTempData[id].value[freq][oppositeVoltage] = '---'

    // 3. 判斷是否要調整 isDefault (同時滿足 3-1 & 3-2)
    // 3-1 low, high 都 getVolt
    // 3-2 low, high 都不能是 '---'
    const isNeedChangeIsDefaultToFalse =
      editGetVoltageList[freq].length === 2 && !isNeedChangeOppositeValueToDash

    if (isNeedChangeIsDefaultToFalse)
      cloneTempData[id].value[freq]['isDefault'] = false
    if (!isNeedChangeIsDefaultToFalse)
      cloneTempData[id].value[freq]['isDefault'] = true

    yield put(
      modalActions.set_temporaryData_for_modal_of_powerDetector_CC({
        editTemporaryData: cloneTempData,
      })
    )
  } catch (error) {
    devWarLog('[res-handler] caliSelect error:', error)
  }
}
