import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import TextArea from 'src/components/Input/TextArea'

import { modalActions } from 'src/redux/slices/modal'

import { deviceErrorSendMailWatcher } from 'src/redux/actions/facility/generalError'

const DeviceErrorSendMail = props => {
  const {
    userID,
    name,
    email,
    time,
    api,
    code,
    codeName,
    message,
    sn,
    deviceType,
    hwVersion,
    fwVersion,
    comments,
    errorMessage,
    isRequesting,
  } = props
  const dispatch = useDispatch()

  const handleChange = event =>
    dispatch(
      modalActions.setDeviceErrorProps({ comments: event.formattedValue })
    )

  const handleCancelClick = () => dispatch(modalActions.clearEmergencyModal())
  const handleSendClick = event => {
    event.preventDefault()
    dispatch(deviceErrorSendMailWatcher())
  }

  return (
    <Modal>
      <Modal.Title>Report Issue</Modal.Title>

      {errorMessage && (
        <Modal.FormLabel className='text-red'>{errorMessage}</Modal.FormLabel>
      )}

      <div className='max-h-[450px] scrollbar overflow-x-hidden pr-2 mt-2'>
        <Modal.FormLabel>
          We apologize for the inconvenience. Please provide as much detail as
          possible about the issue you are experiencing.
        </Modal.FormLabel>

        <ul className='w-full text-base text-light-1 list-disc list-outside ml-4 my-6'>
          <li>What were you doing?</li>
          <li>What happened?</li>
          <li>What did you expect to happen?</li>
        </ul>

        <Modal.FormLabel htmlFor='comments' className='text-light-1'>
          Problem Description *
        </Modal.FormLabel>
        <TextArea
          className='w-full min-h-[82px]'
          id='comments'
          value={comments}
          onChange={handleChange}
        />

        <Modal.Divide />

        <Modal.FormLabel className='text-light-4'>
          Debug Information
          <br />
          <br />
          Name: {name} <br />
          Email: {email} <br />
          MidID: {userID}
          <br />
          Time: {time} <br />
          API: {api} <br />
          SN: {sn} <br />
          Device Type: {deviceType} <br />
          Hardware Version: {hwVersion} <br />
          Firmware Version: {fwVersion} <br />
          Code: {code} <br />
          Code Name: {codeName} <br />
          Error Message: {message} <br />
        </Modal.FormLabel>
      </div>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          onClick={handleCancelClick}
          disabled={isRequesting}>
          Cancel
        </CommonButton>
        <CommonButton
          size='lg'
          type='primary'
          buttonType='submit'
          disabled={!comments}
          loading={isRequesting}
          onClick={handleSendClick}>
          Send
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default DeviceErrorSendMail
