import React from 'react'
import PropTypes from 'prop-types'

import PortalAndOverlay from 'src/components/Popup/Modal/PortalAndOverlay'

import {
  CloseIcon,
  Icon,
  Title,
  Description,
  FormLabel,
  RowButtonBox,
  ColumnButtonBox,
  Divide,
} from 'src/components/Popup/Modal/ModalComponents'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//  <Modal
//    size={} // sm , lg
//    className={} // string
//  />

const Modal = ({
  size = 'sm',
  functionHead,
  className = '',
  style = {},
  ...props
}) => {
  const modalClass = `${className} ${modalCommon} ${modalSize[size]} scrollbar`

  return (
    <PortalAndOverlay
      open={true}
      render={close => (
        <div className={modalClass} style={style}>
          {props.children}
        </div>
      )}
    />
  )
}

export const modalCommon = `
                    relative 
                    w-full max-h-[90vh] 
                    overflow-y-scroll
                    py-10 px-4 md:px-10 
                    bg-dark-2 
                    flex flex-col 
                    rounded-[8px] 
                    `
const modalSize = {
  sm: 'max-w-[424px]',
  md: 'max-w-[560px]',
  lg: 'max-w-[1016px]',
}

Modal.CloseIcon = CloseIcon
Modal.Icon = Icon
Modal.Title = Title
Modal.Description = Description
Modal.FormLabel = FormLabel
Modal.RowButtonBox = RowButtonBox
Modal.ColumnButtonBox = ColumnButtonBox
Modal.Divide = Divide

export default Modal

Modal.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),

  className: PropTypes.string,
}
