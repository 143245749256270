import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

import { setCameraArgs } from 'src/redux/slices/uiControl/beamformers/cloverCellEvb'

export function* steeringChannelModeMaskClose(payloads) {
  try {
    yield console.log('steeringChannelModeMaskClose', payloads)
  } catch (error) {
    devWarLog('[handler] steeringChannelModeMaskClose error:', error)
  }
}

export function* steeringThetaPhiChange(payloads) {
  try {
    yield console.log('steeringThetaPhiChange', payloads)
    const { sn, hvMode, theta, phi, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering[currentRfMode][hvMode].theta = theta
    currentData.deviceControl.steering[currentRfMode][hvMode].phi = phi
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid)
      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_ARRAY1024_BEAM_STEERING_THETA_PHI,
        { sn, lookupID, hvMode, theta: +theta, phi: +phi }
      )
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* steeringGainChange(payloads) {
  try {
    yield console.log('steeringGainChange', payloads)
    const { sn, hvMode, beamCurrentGain, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering[currentRfMode][hvMode].beamCurrentGain =
      beamCurrentGain
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid)
      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_ARRAY1024_BEAM_STEERING_GAIN,
        {
          sn,
          lookupID,
          hvMode,
          value: +beamCurrentGain,
        }
      )
  } catch (error) {
    devWarLog('[handler] steeringGainChange error:', error)
  }
}
export function* steering3DCameraChange(action) {
  try {
    yield put(setCameraArgs(action.payload))
  } catch (error) {
    devWarLog('[handler] steering3DCameraChange error:', error)
  }
}
