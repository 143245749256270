import fileImport from 'src/redux/slices/modal/normal/fileImport'
import fileExport from 'src/redux/slices/modal/normal/fileExport'
import middleware from 'src/redux/slices/modal/normal/middleware'
import lottie from 'src/redux/slices/modal/normal/lottie'
import home from 'src/redux/slices/modal/normal/home'
import operating from 'src/redux/slices/modal/normal/operating'
import freqConverter from 'src/redux/slices/modal/normal/freqConverter'
import beamformers from 'src/redux/slices/modal/normal/beamformers'
import powerDetector from 'src/redux/slices/modal/normal/powerDetector'
import member from 'src/redux/slices/modal/normal/member'
import getQuote from 'src/redux/slices/modal/normal/getQuote'
import welcomeDemo from 'src/redux/slices/modal/normal/welcomeDemo'

const normal = {
  ...fileImport,
  ...fileExport,
  ...middleware,
  ...lottie,
  ...home,
  ...operating,
  ...freqConverter,
  ...beamformers,
  ...powerDetector,
  ...member,
  ...getQuote,
  ...welcomeDemo,
}

export default normal
