export const unitSize = {
  sm: `text-xs leading-3`,
  md: `text-xs leading-5`,
  lg: `text-sm leading-6`,
}

export const iconSize = {
  sm: 'w-[14px] h-[14px]',
  md: 'w-4 h-4',
  lg: 'w-5 h-5',
}

export const touchContainerSize = {
  sm: 'w-7',
  md: 'w-8',
  lg: 'w-10',
}

export const touchButtonBox = `
                                w-full h-full 
                                flex 
                                border-solid border-dark-3
                                `

export const touchButton = `h-full`
