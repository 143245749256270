import React from 'react'
import { useDispatch } from 'react-redux'

import Stepper from 'src/components/Stepper'
import CommonButton from 'src/components/Button/CommonButton'

import { scanWatcher } from 'src/redux/slices/deviceData'

import { BiScan } from 'react-icons/bi'

import noDeviceImg from 'src/assets/img/no_device.png'

const NoDeviceFound = props => {
  const dispatch = useDispatch()
  return (
    <section className={section}>
      <main className={main}>
        <div className={imageBox}>
          <img src={noDeviceImg} alt='TMXLab-kit no device' />
        </div>
        <div className={info}>
          <h4 className={h4}>No Device Found</h4>
          <Stepper>
            <Stepper.Step>
              <p className={p}>
                Make sure your device is power on and connected to the computer.
              </p>
            </Stepper.Step>
            <Stepper.Step>
              <p className={p}>
                Make sure the computer has an internet connection.
              </p>
            </Stepper.Step>
            <Stepper.Step>
              <p className={p}>
                When you are all ready, click Re-scan to try again.
              </p>
            </Stepper.Step>
          </Stepper>
          <CommonButton
            className='mt-10 w-full'
            type='primary'
            size='lg'
            icon={<BiScan />}
            onClick={() => dispatch(scanWatcher())}>
            Re-scan
          </CommonButton>
        </div>
      </main>
    </section>
  )
}

NoDeviceFound.propTypes = {}

export default NoDeviceFound

const section = `w-full min-h-[calc(100vh_-_84px)] flex items-center justify-center`
const main = `
              w-full md:w-[480px] xl:w-[800px]
              flex flex-col xl:flex-row 
              items-center 
              gap-x-6 gap-y-6
              px-0 py-10 md:px-[60px] xl:px-10 
              bg-black/50 backdrop-blur-sm 
              rounded-[6px]`

const imageBox = `w-full max-w-[360px] h-[360px] `
const info = `w-full max-w-[336px] px-6 py-4`

const h4 = `text-lg font-bold leading-5 text-light-4 text-center mb-6 `
const p = `text-base font-normal leading-5 text-light-4`
