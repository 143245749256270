import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const FunctionalItem = forwardRef((props, ref) => {
  const { className, name, disabled, onClick, children } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                        ${className}
                        ${common}

                        ${background(disabled)}

                        ${fontCommon} 
                        ${fontColor(disabled)} 

                        ${cursor(disabled)}
                        `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div ref={ref} name={name} className={containerClass} onClick={onClick}>
      {children}
    </div>
  )
})
export default FunctionalItem

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const common = `
                py-3 px-2 
                shrink-0 
                select-none duration-500
                `

const background = disabled => (disabled ? `bg-dark-3` : `bg-dark-4`)

const fontCommon = `font-base leading-5 text-white`
const fontColor = disabled =>
  disabled ? 'text-dark-5' : `text-light-4 hover:text-white`

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
FunctionalItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
