import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import {
  sg_turnOffOutputCheckModal_cancelClick_watcher,
  sg_turnOffOutputCheckModal_okClick_watcher,
} from 'src/redux/actions/freqConverter/sgAction'

import { BiInfoCircle } from 'react-icons/bi'

const TurnOffOutputCheck = () => {
  const dispatch = useDispatch()

  const handleCancelClick = () =>
    dispatch(sg_turnOffOutputCheckModal_cancelClick_watcher())

  const handleOKClick = () =>
    dispatch(sg_turnOffOutputCheckModal_okClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white' />
      </Modal.Icon>

      <Modal.Title>
        Using an external reference signal source will turn off the current
        output reference source
      </Modal.Title>

      <Modal.RowButtonBox>
        <CommonButton size='lg' type='general' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
        <CommonButton size='lg' type='primary' onClick={handleOKClick}>
          OK
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default TurnOffOutputCheck
