import React from 'react'

import PortalAndOverlay from 'src/components/Popup/Modal/PortalAndOverlay'

import ListMode from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ListMode'
import EditMode from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/EditMode'

import SaveCalibrationConfigReminder from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/SaveCalibrationConfigReminder'
import ClearIncompleteData from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/ClearIncompleteData'
import ListModeDeleteCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/ListModeDeleteCheck'
import EditModeDeleteCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/EditModeDeleteCheck'
import LeaveEditCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/LeaveEditCheck'
import UsingNotDeleteCheck from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/UsingNotDeleteCheck'
import CaliConfigFileSaved from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/ChildModal/CaliConfigFileSaved'

const CustomCalibration = modalProps => {
  const { editTemporaryData, childModalType } = modalProps
  const isTemporaryDataExist = Object.keys(editTemporaryData).length !== 0

  return (
    <PortalAndOverlay
      open
      render={close => (
        <div className={modalClass}>
          {!isTemporaryDataExist ? (
            <ListMode {...modalProps} />
          ) : (
            <EditMode {...modalProps} />
          )}

          {childModalType === 'saveCalibrationConfigReminder' && (
            <SaveCalibrationConfigReminder />
          )}
          {childModalType === 'clearIncompleteData' && <ClearIncompleteData />}
          {childModalType === 'listModeDeleteCheck' && (
            <ListModeDeleteCheck {...modalProps} />
          )}
          {childModalType === 'editModeDeleteCheck' && (
            <EditModeDeleteCheck {...modalProps} />
          )}
          {childModalType === 'leaveEditCheck' && <LeaveEditCheck />}
          {childModalType === 'usingNotDeleteCheck' && <UsingNotDeleteCheck />}
          {childModalType === 'caliConfigFileSaved' && <CaliConfigFileSaved />}
        </div>
      )}
    />
  )
}

CustomCalibration.propTypes = {}

export default CustomCalibration

const modalClass = `relative 
                    w-full max-w-[800px] 
                    bg-dark-2 
                    flex flex-col 
                    rounded-[8px]   
                    `
