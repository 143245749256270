import { all, fork, take } from 'redux-saga/effects'

// action
import { beamformers_initDevice_watcher } from 'src/redux/actions/beamformers/generalAction'

// handlers
import { init as handleInit } from 'src/redux/sagas/handlers/event/beamformers/general/init'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       General      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* initBbox() {
  while (true) {
    const action = yield take(beamformers_initDevice_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] beamformers_initDevice', action.payload)
    yield fork(handleInit, action.payload)
  }
}

export default function* bboxWatchers() {
  yield all([initBbox()])
}
