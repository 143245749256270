import React from 'react'

import { textBox, subtitle, content } from 'src/containers/home/style'

const Text = ({ title, value }) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={textBox}>
      <label className={subtitle}>{title}</label>
      <p className={content}>{value}</p>
    </div>
  )
}
export default Text
