import { createAction } from '@reduxjs/toolkit'

// device controller - common
export const bboard_common_rfModeSwitch_watcher = createAction(
  'deviceControl/bboard_common_rfModeSwitch_watcher'
)

// device controller - channel
export const bboard_step_allPowerSwitch_watcher = createAction(
  'deviceControl/bboard_step_allPowerSwitch_watcher'
)
export const bboard_step_singlePowerSwitch_watcher = createAction(
  'deviceControl/bboard_step_singlePowerSwitch_watcher'
)
export const bboard_step_commonAttenuationChange_watcher = createAction(
  'deviceControl/bboard_step_commonAttenuationChange_watcher'
)
export const bboard_step_commonAttenuationAfterChange_watcher = createAction(
  'deviceControl/bboard_step_commonAttenuationAfterChange_watcher'
)
export const bboard_step_singleAttenuationChange_watcher = createAction(
  'deviceControl/bboard_step_singleAttenuationChange_watcher'
)
export const bboard_step_singlePhaseChange_watcher = createAction(
  'deviceControl/bboard_step_singlePhaseChange_watcher'
)

// device controller - temperature
export const bboard_RfIcTemperature_updateClick_watcher = createAction(
  'deviceControl/bboard_RfIcTemperature_updateClick_watcher'
)
export const bboard_temperatureCompensationChange_watcher = createAction(
  'deviceControl/bboard_temperatureCompensationChange_watcher'
)
export const bboard_TemperatureCompensation_setupClick_watcher = createAction(
  'deviceControl/bboard_TemperatureCompensation_setupClick_watcher'
)
