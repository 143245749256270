import React from 'react'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

const DemoFooter = props => {
  const { isDemoMode } = useGetCurrentMode()
  return (
    isDemoMode && (
      <div className={container}>
        The data on the screen is pure fiction. For accurate information, check
        out the product specs sheet.
      </div>
    )
  )
}

export default DemoFooter

const container = `
                    fixed z-10 
                    bottom-0 inset-x-0
                    bg-black/70 backdrop-blur 
                    py-3 px-12 
                    sm:text-xs md:text-sm font-medium 
                    text-yellow text-center 
                    `
