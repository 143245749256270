import React from 'react'

import Tooltip from 'src/components/Popup/Tooltip'

import { BiPlus } from 'react-icons/bi'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const CreateItem = ({ disabled, beamID, handleCreateClick }) => {
  const { current } = useGetCurrentDeviceData()

  const currentAntenna = current.data.deviceControl.common.currentAntenna

  return (
    <div className={container}>
      {!currentAntenna && (
        <Tooltip
          childrenContainerClassName='w-full'
          title={
            <div className='text-center'>
              Enabled beam steering simulation <br />
              when the Antenna has an item selected.
            </div>
          }>
          <button
            disabled
            className={button + ' text-light-5/50 cursor-not-allowed'}
            onClick={() => handleCreateClick({ beamID, type: 'beam' })}>
            <BiPlus /> Beam steering control
          </button>
        </Tooltip>
      )}

      {currentAntenna && (
        <button
          className={button + ' bg-dark-3 text-light-5 hover:text-light-1 '}
          onClick={() => handleCreateClick({ beamID, type: 'beam' })}>
          <BiPlus /> Beam steering control
        </button>
      )}

      <div className={divider} />

      <button
        className={button + ' bg-dark-3 text-light-5 hover:text-light-1 '}
        onClick={() => handleCreateClick({ beamID, type: 'channel' })}>
        <BiPlus /> Channel control
      </button>
    </div>
  )
}

CreateItem.propTypes = {}

export default CreateItem

const container = `
                    w-full 
                    flex items-center 
                    gap-x-6 
                    pl-4
`
const button = `w-full h-[32px] 
                rounded-sm 
                flex items-center justify-center 
                gap-x-1 
                duration-500
`
const divider = `w-[1px] h-6 bg-light-5`
