import React from 'react'

import Tooltip from 'src/components/Popup/Tooltip'

import UpRight from 'src/assets/svg/beam3D/UpRight'
import Horizontal from 'src/assets/svg/beam3D/Horizontal'

import { BiReset, BiHelpCircle } from 'react-icons/bi'

import CommonButton from 'src/components/Button/CommonButton'

import useGetScreenSize from 'src/hooks/useGetScreenSize'

const Beam3DButtons = ({
  deviceDirection,
  handleDirectionChange,
  handleCameraReset,
  handleHelpButtonClick,
  handleBoresightClick,
  boresightDisabled,
}) => {
  const { isXL, is2XL } = useGetScreenSize()

  return (
    <>
      <div className={buttonContainer}>
        <div className={groupButtonBox}>
          <Tooltip content='Upright' placement='left' className={tooltipClass}>
            <div
              className={selectIconClass(deviceDirection === 'left')}
              onClick={() => handleDirectionChange('left')}>
              <Horizontal />
            </div>
          </Tooltip>

          <Tooltip
            content='Horizontal'
            placement='left'
            className={tooltipClass}>
            <div
              className={selectIconClass(deviceDirection === 'top')}
              onClick={() => handleDirectionChange('top')}>
              <UpRight />
            </div>
          </Tooltip>
        </div>

        <Tooltip content='Reset' placement='left' className={tooltipClass}>
          <div
            className={otherButtonClass}
            onClick={() => handleCameraReset(true)}>
            <BiReset />
          </div>
        </Tooltip>

        <Tooltip content='Guide' placement='left' className={tooltipClass}>
          <div className={otherButtonClass} onClick={handleHelpButtonClick}>
            <BiHelpCircle />
          </div>
        </Tooltip>
      </div>

      <CommonButton
        size={isXL || is2XL ? 'md' : 'lg'}
        type='outlined'
        onClick={() => {
          if (!boresightDisabled) handleBoresightClick()
        }}
        className={boresightClass}
        disabled={boresightDisabled}>
        Boresight
      </CommonButton>
    </>
  )
}

export default Beam3DButtons

const buttonContainer = `[BeamSteering-Beam3DButtons-buttonContainer]
                        absolute 
                        right-[1px] 
                        top-5 md:top-[100px] 
                        flex flex-col gap-y-2
                        `

const iconBox = `[BeamSteering-Beam3DButtons-iconBox]
                w-11 h-12 px-2
                flex items-center justify-center
                text-4xl text-white hover:text-teal
                cursor-pointer 
                hover:drop-shadow-[0_0_3px_rgba(40,202,206,1)]
                duration-500
                `

const groupButtonBox = `[BeamSteering-Beam3DButtons-groupButtonBox]
                        rounded-lg 
                        bg-white/10
                        `

const border = selected =>
  selected
    ? `border-2 border-solid border-teal`
    : `border-2 border-solid border-transparent`

const dropShadow = selected =>
  selected ? `drop-shadow-[0_0_3px_rgba(40,202,206,1)] text-teal` : ``

const selectIconClass = selected => `
                                    ${iconBox} 
                                    ${border(selected)} 
                                    ${dropShadow(selected)} 
                                    py-3 px-2 
                                    rounded-lg
                                    `

const tooltipClass = `break-normal whitespace-nowrap`

const otherButtonClass = `[BeamSteering-Beam3DButtons-otherButtonClass]
                    flex 
                    items-center justify-center 
                    w-[44px] h-[44px] 
                    rounded-lg 
                    bg-white/10 
                    text-white text-2xl 
                    cursor-pointer
                    `

const boresightClass = `[BeamSteering-Beam3DButtons-boresightClass]
                                    absolute 
                                    right-[1px] 
                                    bottom-0 
                                    `
