import React from 'react'

import CustomCalibration from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration'

import CaliConfigFileChanged from 'src/containers/Modal/Normal/PowerDetector/actionPanel/CaliConfigFileChanged'
import CaliConfigFileChanging from 'src/containers/Modal/Normal/PowerDetector/actionPanel/CaliConfigFileChanging'
import ChangeAnotherCaliConfigFile from 'src/containers/Modal/Normal/PowerDetector/actionPanel/ChangeAnotherCaliConfigFile'
import FailedToChangeCaliConfig from 'src/containers/Modal/Normal/PowerDetector/actionPanel/FailedToChangeCaliConfig'

import ClearTheCurrentlyReadData from 'src/containers/Modal/Normal/PowerDetector/dashboard/ClearTheCurrentlyReadData'

const PowerDetector = modalProps => {
  const { status: modalStatus } = modalProps

  // custom calibration
  if (modalStatus === 'customCalibration')
    return <CustomCalibration {...modalProps} />

  // action panel
  if (modalStatus === 'caliConfigFileChanged')
    return <CaliConfigFileChanged {...modalProps} />

  if (modalStatus === 'caliConfigFileChanging')
    return <CaliConfigFileChanging {...modalProps} />

  if (modalStatus === 'changeAnotherCaliConfigFile')
    return <ChangeAnotherCaliConfigFile {...modalProps} />

  if (modalStatus === 'failedToChangeCaliConfig')
    return <FailedToChangeCaliConfig {...modalProps} />

  // dashboard
  if (modalStatus === 'dashboardClearReadData')
    return <ClearTheCurrentlyReadData {...modalProps} />

  return <></>
}

export default PowerDetector
