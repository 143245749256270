import React from 'react'
import { useDispatch } from 'react-redux'

import CommonSwitch from 'src/components/Switch/CommonSwitch'

import { h4 } from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { bboard_step_allPowerSwitch_watcher } from 'src/redux/actions/beamformers/bboardAction'

const AllPower = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const { rfMode } = current.data.deviceControl.common
  const { channelValue: powerList } =
    current.data.deviceControl.step[rfMode].power

  const isOn = powerList.find(e => e === true)

  const handlePowerClick = () =>
    dispatch(bboard_step_allPowerSwitch_watcher({ sn, value: !isOn }))

  return (
    <div className={container}>
      <h4 className={h4}>All CH Control</h4>
      <CommonSwitch
        type='text'
        onText='ON'
        offText='OFF'
        on={isOn}
        loading={false}
        onClick={handlePowerClick}
      />
    </div>
  )
}

export default AllPower

const container = `py-3 px-[14px] flex gap-x-5`
