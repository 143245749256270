import { createSlice, current } from '@reduxjs/toolkit'
import * as R from 'ramda'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

const defaultItemData = {
  smallSizeControlMode: 'Output Settings',
  outputSettings: {
    isStopped: false, // bool
  },
  deviceSettings: {
    refSource: { isRequesting: false, isSuccess: false },
    outputRefSource: { isRequesting: false },
  },
}

export const sgUIControlForSingleSlice = createSlice({
  name: 'sgUIControlForSingle',
  initialState: {},
  reducers: {
    // init device 時，會新增一筆
    addUiControl(state, action) {
      const { sn } = action.payload
      return {
        ...current(state),
        [sn]: defaultItemData,
      }
    },

    // 小螢幕尺寸時 切換 control mode
    // Frequency Settings || Device Settings
    changeSmallSizeControlMode(state, action) {
      const { sn, value } = action.payload
      state[sn].smallSizeControlMode = value
      return state
    },

    // Output Settings
    // Output Settings
    // Output Settings
    // Output Settings
    // Output Settings
    setOutputIsStopped(state, action) {
      const { sn, value } = action.payload
      state[sn].outputSettings.isStopped = value
      return state
    },

    // Device Settings
    // Device Settings
    // Device Settings
    // Device Settings
    // Device Settings
    setRefSourceIsRequesting(state, action) {
      const { sn } = action.payload
      state[sn].deviceSettings.refSource.isRequesting = true
      state[sn].deviceSettings.refSource.isSuccess = false
      return state
    },
    setRefSourceIsSuccess(state, action) {
      const { sn } = action.payload
      state[sn].deviceSettings.refSource.isRequesting = false
      state[sn].deviceSettings.refSource.isSuccess = true
      return state
    },
    clearRefSourceStatus(state, action) {
      const { sn } = action.payload
      state[sn].deviceSettings.refSource = R.map(e => false)(
        state[sn].deviceSettings.refSource
      )
      return state
    },
  },
})

export const sgUIControlForSingleAction = sgUIControlForSingleSlice.actions

export const sgUIControlForSingle = sgUIControlForSingleSlice.reducer
