import { createAction } from '@reduxjs/toolkit'

export const ud_general_initDevice_watcher = createAction(
  'deviceControl/ud_general_initDevice_watcher'
)

export const ud_general_updateCurrentClick_watcher = createAction(
  'deviceControl/ud_general_updateCurrentClick_watcher'
)

// freq settings - unlock
export const ud_general_freqUnlockClick_watcher = createAction(
  'deviceControl/ud_general_freqUnlockClick_watcher'
)

// freq settings - unlock modal
export const ud_general_freqUnlockModal_cancelClick_watcher = createAction(
  'modalEvent/ud_general_freqUnlockModal_cancelClick_watcher'
)
export const ud_general_freqUnlockModal_enterClick_watcher = createAction(
  'modalEvent/ud_general_freqUnlockModal_enterClick_watcher'
)
export const ud_general_freqUnlockSuccessModal_OKClick_watcher = createAction(
  'modalEvent/udzFreqUnlockSuccessModalOKClick'
)

// freq settings - unit
export const ud_general_freqUnitChange_watcher = createAction(
  'deviceControl/ud_general_freqUnitChange_watcher'
)

// freq settings - RF IF LO
export const ud_general_freqRFChange_watcher = createAction(
  'deviceControl/ud_general_freqRFChange_watcher'
)
export const ud_general_freqIFChange_watcher = createAction(
  'deviceControl/ud_general_freqIFChange_watcher'
)
export const ud_general_freqLOSelect_watcher = createAction(
  'deviceControl/ud_general_freqLOSelect_watcher'
)

// freq settings - bandwidth
export const ud_general_freqBandwidthSelect_watcher = createAction(
  'deviceControl/ud_general_freqBandwidthSelect_watcher'
)
export const ud_general_freqBandwidthInputChange_watcher = createAction(
  'deviceControl/ud_general_freqBandwidthInputChange_watcher'
)

// freq settings - apply clear
export const ud_general_freqApplyClick_watcher = createAction(
  'deviceControl/ud_general_freqApplyClick_watcher'
)
export const ud_general_freqClearClick_watcher = createAction(
  'deviceControl/ud_general_freqClearClick_watcher'
)
