import { take, call, put } from 'redux-saga/effects'
import { devLog, devWarLog } from 'src/funcs/tools'
import { nanoid } from 'nanoid'

import Controllers from 'src/server/Controllers/index.mjs'

import {
  localMiddlewareThrottle,
  localMiddlewareNormal,
  localMiddlewareEmitCallback,
  beamformersThrottle,
  beamformersNormal,
  beamformersEmitCallback,
  freqConverterThrottle,
  freqConverterNormal,
  freqConverterEmitCallback,
  facilityThrottle,
  facilityNormal,
  facilityEmitCallback,
  powerDetectorThrottle,
  powerDetectorNormal,
  powerDetectorEmitCallback,
} from 'src/redux/actions/socket'

import { getIsDemoMode } from 'src/redux/sagas/funcs/general'

const _normalAction = {
  beamformers: beamformersNormal,
  freqConverter: freqConverterNormal,
  facility: facilityNormal,
  powerDetector: powerDetectorNormal,
  localMiddleware: localMiddlewareNormal,
}
const _throttleAction = {
  beamformers: beamformersThrottle,
  freqConverter: freqConverterThrottle,
  facility: facilityThrottle,
  powerDetector: powerDetectorThrottle,
  localMiddleware: localMiddlewareThrottle,
}
const _callbackAction = {
  beamformers: beamformersEmitCallback,
  freqConverter: freqConverterEmitCallback,
  facility: facilityEmitCallback,
  powerDetector: powerDetectorEmitCallback,
  localMiddleware: localMiddlewareEmitCallback,
}

//* ============================================================================
//* ============================================================================
//* ============================================================================

//* ===== Example
// const acknowledgement = yield call(__socket_API_sender, {
//   server: 'beamformers',
//   eventName: 'beam/steering/gain',
//   // demo:steeringGainDemo,
//   data: { sn, lookupID, value: +value },
//   beforeEmitActionType: bbox_steering_socketEmitting_watcher({ sn }),
//   throttle: true,
// })

//* ===== Description
// server : 該 api 所在的 server
// eventName : socket eventName
// demo : demo mode 要跑的 FE server function (src/server/demoAPI/*)
// data : 給 BE 的 data
// beforeEmitActionType : 進 queue 後要 call api 前要做的事情 (需為 action, 且另外開一個 function 用 take 接)
// throttle : 是否需要先 throttle 進 queue (boolean)

//* ============================================================================
//* ============================================================================
//* ============================================================================

export default function* __socket_API_sender(api, data) {
  try {
    const isDemoMode = yield call(getIsDemoMode)

    const {
      server,
      eventName,
      beforeEmitActionType,
      throttle = false,
      timeout,
    } = api.emit

    const { handler } = api.on

    // --------------------
    // --------------------
    //    device control
    // --------------------
    // --------------------
    if (!isDemoMode) {
      const queueID = nanoid()

      if (!throttle)
        yield put(
          _normalAction[server]({
            queueID,
            eventName,
            data,
            beforeEmitActionType,
            timeout,
          })
        )

      if (throttle)
        yield put(
          _throttleAction[server]({
            queueID,
            eventName,
            data,
            beforeEmitActionType,
            timeout,
          })
        )

      const { payload: acknowledgement } = yield take(_callbackAction[server])

      return acknowledgement
    }

    // --------------------
    // --------------------
    //       demo mode
    // --------------------
    // --------------------
    if (isDemoMode) {
      devLog('--------------')
      const key = `s_${eventName}`
      devLog(`%cㅍ_ㅍ Demo Mode - [${key}] `)

      if (beforeEmitActionType)
        yield put({ type: beforeEmitActionType, payload: data })

      if (Controllers[key] === undefined)
        throw Error(`This function key is not defined in demo mode: [${key}]`)
      devLog(`FE - Payloads: `, data)
      const response = Controllers[key]({ payloads: data })
      devLog(`BE - Response: `, response)

      // 真的 BE 回傳會多一層 data 這邊也跟著多包來模擬
      if (handler) yield call(handler, { data: response })

      const ackSuccessCode = 0

      devLog('--------------')
      return ackSuccessCode
    }
  } catch (error) {
    devWarLog('[__socket_API_sender] error: ', error)
  }
}
