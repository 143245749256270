import { select } from 'redux-saga/effects'

import { getCurrentData } from './deviceData'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

//* 根據不同 device 抓各別的 uiControl slice
export function* getUiControl(sn) {
  const deviceData = yield getCurrentData(sn)
  const deviceType = deviceData?.info?.deviceType

  const { single, all } = getDeviceInfo[deviceType].uiControlSliceName

  const single_uiControl = yield select(state => state?.[single]?.[sn])
  const all_uiControl = yield select(state => state?.[all])

  return { all: all_uiControl, single: single_uiControl }
}
