import { call, put } from 'redux-saga/effects'

import {
  getCurrentData,
  getCurrentRfMode,
} from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import {
  changeIsTemperatureCompensationChanged,
  changeTemperatureCompensationButtonStatus,
} from 'src/redux/slices/uiControl/beamformers/bboard'

import { devWarLog } from 'src/funcs/tools'

export function* readTemperature(payloads) {
  // lookupID = device LookupID
  try {
    const { sn } = payloads
    const { lookupID } = yield call(getCommonArgs, sn)

    yield call(__socket_API_sender, beamformersApi.STEP_ADC, {
      sn,
      lookupID,
    })
  } catch (error) {
    devWarLog('[handler] readTemperature error:', error)
  }
}

export function* temperatureCompensationChange(payloads) {
  // lookupID = device LookupID
  try {
    const { sn, trC, trQ } = payloads

    yield put(changeIsTemperatureCompensationChanged({ sn, value: true }))

    let currentData = yield call(getCurrentData, sn)
    const currentRfMode = yield call(getCurrentRfMode, sn)

    if (!isNaN(+trC))
      currentData.deviceControl.step[
        currentRfMode
      ].compensation.trC.currentValue = trC

    if (!isNaN(+trQ))
      currentData.deviceControl.step[
        currentRfMode
      ].compensation.trQ.currentValue = trQ

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] temperatureCompensationChange error:', error)
  }
}

export function* setTemperatureCompensation(payloads) {
  // lookupID = device LookupID
  try {
    const { sn, trC, trQ } = payloads
    const { lookupID } = yield call(getCommonArgs, sn)

    // 不放在 beforeAction 是因為我希望按下去那刻， button 就進入 loading
    // 而不是進 queue 發 api 後才 loading
    yield put(
      changeTemperatureCompensationButtonStatus({ sn, status: 'isRequest' })
    )

    yield call(__socket_API_sender, beamformersApi.STEP_COMPENSATION_CQ_SETUP, {
      sn,
      lookupID,
      trC: +trC,
      trQ: +trQ,
    })
  } catch (error) {
    devWarLog('[handler] setTemperatureCompensation error:', error)
  }
}
