import React from 'react'

import Modal from 'src/components/Popup/Modal'

import { HiRefresh } from 'react-icons/hi'

const CaliConfigFileChanging = props => {
  return (
    <Modal>
      <Modal.Icon>
        <HiRefresh className='text-white ' />
      </Modal.Icon>

      <Modal.Title>Calibration config file changing...</Modal.Title>
      <Modal.Description>Please wait.</Modal.Description>

      <div className='w-full h-[72px]' />
    </Modal>
  )
}

export default CaliConfigFileChanging
