import React from 'react'

import Board from 'src/assets/svg/device/avatar/Ris/Board'
import Antennas from 'src/assets/svg/device/avatar/Ris/Antennas'
import Cover from 'src/assets/svg/device/avatar/Ris/Cover'

const scaleClass = `scale-[0.8] group-hover:scale-[0.93]`

const Ris = props => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `relative w-full h-full overflow-hidden `
  const svgCommon = `absolute 
                    inset-x-0 mx-auto 
                    duration-700 ease-out
                    `

  const board = `
                ${svgCommon} 
                ${scaleClass} 
                bottom-[20px] 
                `

  const antenna = `
                    ${svgCommon} 
                    ${scaleClass} 
                    bottom-[20px] 
                    `

  const cover = `
                ${svgCommon} 
                ${scaleClass} 
                group-hover:translate-y-[100px] 
                bottom-[10px]
                `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <Board className={board} />
      <Antennas className={antenna} />
      <Circle />
      <Cover className={cover} />
    </div>
  )
}

export default Ris

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Ris.propTypes = {}

const Circle = () => {
  const container = `${scaleClass} absolute inset-x-0 bottom-[25px]  m-auto w-[135px] h-[135px] flex items-center justify-center overflow-hidden`

  return (
    <div className={container}>
      {new Array(6).fill('').map((e, i) => (
        <div
          key={`ris circle ${i}`}
          className='absolute rounded-full translate-x-[55px] shadow-[inset_0px_0px_14px_3px_rgba(0,0,0,0.3)] opacity-0 group-hover:opacity-100'
          style={{
            width: `${i * 62}px`,
            height: `${i * 66}px`,
            transitionDuration: `500ms`,
            transitionDelay: `${i * 200}ms`,
          }}
        />
      ))}
    </div>
  )
}
