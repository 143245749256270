import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { HiOutlineSave, HiOutlineUpload } from 'react-icons/hi'

import CommonButton from 'src/components/Button/CommonButton'
import Tooltip from 'src/components/Popup/Tooltip'

import {
  ris_common_afterImport_watcher,
  ris_common_exportClick_watcher,
} from 'src/redux/actions/beamformers/risAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useUploadFiles from 'src/hooks/useUploadFiles'

import { modalActions } from 'src/redux/slices/modal'
import { risCommonImportApi } from 'src/redux/sagas/services/restfulAPI/beamformersApi'

const Common = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const inputFileRef = useRef()

  const freq = current?.data?.deviceControl?.common?.currentFreq
  const sn = current?.sn

  const handleImportClick = () => inputFileRef.current.click()
  const { handleFileInputChange } = useUploadFiles({
    inputFileRef,
    api: risCommonImportApi,
    payload: sn,
    beforeCallback: () => dispatch(modalActions.showModalOfFileImporting()),
    afterCallback: responseData =>
      dispatch(ris_common_afterImport_watcher({ sn, responseData })),
  })

  const handleExportClick = () =>
    dispatch(ris_common_exportClick_watcher({ sn }))

  return (
    <div className='flex justify-between items-center'>
      <h3 className='text-sm text-light-1'>Central Frequency: {freq} GHz</h3>

      <div className='flex items-center'>
        <Tooltip
          title='No support in demo mode'
          // disabled={!isDemoMode}
        >
          <input
            className='hidden'
            ref={inputFileRef}
            type='file'
            accept='.csv'
            onChange={handleFileInputChange}
          />
          <CommonButton
            type='text'
            icon={<HiOutlineSave />}
            disabled
            // disabled={isDemoMode}
            onClick={handleImportClick}>
            Import
          </CommonButton>
        </Tooltip>

        <div className={divider} />

        <Tooltip
          title='No support in demo mode'
          // disabled={!isDemoMode}
        >
          <CommonButton
            type='text'
            icon={<HiOutlineUpload />}
            disabled
            // disabled={isDemoMode}
            onClick={handleExportClick}>
            Export
          </CommonButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default Common

const divider = `h-6 w-[1px] bg-dark-5 mx-4`
