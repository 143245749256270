import { devWarLog } from 'src/funcs/tools'

export function* connect(response) {
  try {
    yield
  } catch (error) {
    devWarLog(`[socket-event-handler] connect error:`, error)
  }
}

export function* disconnect({ tag, response }) {
  try {
    yield
  } catch (error) {
    devWarLog(`[socket-event-handler] disconnect error:`, error)
  }
}

export function* connectError({ tag, response }) {
  try {
    yield
  } catch (error) {
    devWarLog(`[socket-event-handler] connectError error:`, error)
  }
}
