// ud box
export const rfMinKHz = 24000000
export const rfMaxKHz = 44000000

export const ifMinKHz = 10000
export const ifMaxKHz = 14000000

// ud module
export const internalOutputOptions = {
  23: [10000, 100000],
  24: [10000, 100000],
}
export const externalInputOptions = {
  23: [10000, 100000],
  24: [10000, 100000],
}

export const unitDisabled = {
  4: [], // UDBox - 00E
  5: [], // UDBox - 00D
  14: [], // UDBox - 00F
  15: [], // UDBox 5G
  23: ['kHz'], // UD Module - 0620
  24: ['kHz'], // UD Module - 0630 & 1753
}

export const decimalScale = {
  4: { GHz: 6, MHz: 3, kHz: 0 }, // UDBox - 00E
  5: { GHz: 6, MHz: 3, kHz: 0 }, // UDBox - 00D
  14: { GHz: 6, MHz: 3, kHz: 0 }, // UDBox - 00F
  15: { GHz: 6, MHz: 3, kHz: 0 }, // UDBox 5G
  23: { GHz: 3, MHz: 0, kHz: 0 }, // UD Module - 0620
  24: { GHz: 3, MHz: 0, kHz: 0 }, // UD Module - 0630 & 1753
}
