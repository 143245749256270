import React from 'react'

const ChannelItem = ({ channel_config }) => {
  const common_db = channel_config[0].common_db
  const { sw: sw1, db: db1, deg: deg1 } = channel_config[0].ch_group[0]
  const { sw: sw2, db: db2, deg: deg2 } = channel_config[0].ch_group[1]

  //! sw 在 API 打開是 0, 關閉是 1
  return (
    <p className='text-ellipsis overflow-hidden whitespace-nowrap pl-1'>
      CH 01, Gain {+sw1 === 0 ? +common_db + +db1 : '- - -'} dB, Phase{' '}
      {+sw1 === 0 ? deg1 : '- - -'}
      ˚; CH 02, Gain {+sw2 === 0 ? +common_db + +db2 : '- - -'} dB, Phase{' '}
      {+sw2 === 0 ? deg2 : '- - -'}˚; CH 03...
    </p>
  )
}

ChannelItem.propTypes = {}

export default ChannelItem
