import React from 'react'
import ChangeProfilePhoto from 'src/containers/Modal/Normal/Member/ChangeProfilePhoto'
import ChangeName from 'src/containers/Modal/Normal/Member/ChangeName'
import ChangePassword from 'src/containers/Modal/Normal/Member/ChangePassword'

const Member = props => {
  const { status: modalStatus } = props

  if (modalStatus === 'changeProfilePhoto')
    return <ChangeProfilePhoto {...props} />

  if (modalStatus === 'changeName') return <ChangeName {...props} />

  if (modalStatus === 'changePassword') return <ChangePassword {...props} />

  return <></>
}

export default Member
