import { Helmet } from 'react-helmet'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

import { getOfficialFullName } from 'src/funcs/getDeviceInfo'
import useGetCurrentDeviceData from './useGetCurrentDeviceData'

const useGetHelmet = ({ pageName, deviceType }) => {
  const { isDemoMode, isDeviceControlMode } = useGetCurrentMode()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const page = pageName ? pageName : ''

  const mode = isDemoMode
    ? '| Demo Mode'
    : isDeviceControlMode
    ? '| Device Control'
    : ''

  const deviceName = deviceType && getOfficialFullName(sn, deviceType)
  const device = deviceName ? deviceName : ''

  return (
    <Helmet>
      <title>
        {device} {page} {mode} | TMXLAB KIT
      </title>
    </Helmet>
  )
}

export default useGetHelmet
