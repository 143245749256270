import React from 'react'

import CommonAttenuation from 'src/containers/operating/device/beamformers/bboard/Step/Main/CommonAttenuation'
import SingleCH from 'src/containers/operating/device/beamformers/bboard/Step/Main/SingleCH'

const Main = props => {
  return (
    <div className={container}>
      <CommonAttenuation />

      <div className={chRow}>
        {new Array(4).fill(null).map((e, i) => (
          <SingleCH key={'bboard single CH' + i} index={i} />
        ))}
      </div>
    </div>
  )
}

Main.propTypes = {}

export default Main

const container = ``
const chRow = `[Step-Main-chRow] 
              flex flex-row-reverse 
              gap-x-2 
              px-2 py-2
              `
