import React, { useState } from 'react'

import CommonSwitch from 'src/components/Switch/CommonSwitch'
import InputGroup from 'src/components/Input/InputGroup'

import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import { h6, small } from 'src/containers/operating/device/beamformers/style'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// FIXME: example

const VerticalInputGroup = props => {
  const {
    chTitle,
    isSwitchOn,
    isSwitchDisabled,

    topTitle,
    topInputProps,
    topWarningText,
    topRangeText,

    bottomTitle,
    bottomInputProps,
    bottomWarningText,
    bottomRangeText,

    dialogPlacement,
  } = props

  const { handlePowerClick, handleTopChange, handleBottomChange } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [topFocus, setTopFocus] = useState(false)
  const [topWarning, setTopWarning] = useState(false)
  const [bottomFocus, setBottomFocus] = useState(false)
  const [bottomWarning, setBottomWarning] = useState(false)

  const showTopRange = topWarning || topFocus
  const showBottomRange = bottomWarning || bottomFocus

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const overwriteElement = ({ title, inputProps, rangeText }) => (
    <div className='relative flex flex-col'>
      {title && <h6 className={h6 + ' mb-1'}>{title}</h6>}
      <InputGroup
        className='w-[130px] h-[32px] mb-1'
        inputMin={-999} // 讓輸入低於 0 又不合法時，dialog 不會跳回0
        warning={true}
        disabled={true}
        {...inputProps}
      />
      <h6 className={small + ' text-yellow text-left'}>{rangeText}</h6>
    </div>
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className={head}>
        <h6 className={`${h6} ${chTitle === 'CH 1' ? ' !text-yellow' : ''}`}>
          {chTitle}
        </h6>

        <CommonSwitch
          type='text'
          onText='ON'
          on={isSwitchOn}
          offText='OFF'
          disabled={isSwitchDisabled}
          loading={false}
          onClick={handlePowerClick}
        />
      </div>

      {/* Top Control */}
      <div className={body}>
        {topTitle && <h6 className={h6}>{topTitle}</h6>}
        <InputGroupAppendDialog
          className='w-[130px]'
          {...topInputProps}
          // dialog params
          dialogText={topWarningText[topWarning]}
          placement={dialogPlacement}
          overwriteOffset={{ top: topTitle ? -20 : -0, left: 0 }}
          overwriteElements={overwriteElement({
            title: topTitle,
            inputProps: topInputProps,
            rangeText: topRangeText,
          })}
          // event params
          setFocus={result => setTopFocus(result)}
          warning={topWarning}
          setWarning={result => setTopWarning(result)}
          changeCallback={handleTopChange}
        />

        {showTopRange && (
          <small className={smallClass(topWarning)}>{topRangeText}</small>
        )}

        {/* Bottom Control */}
        {bottomTitle && <h6 className={h6}>{bottomTitle}</h6>}
        <InputGroupAppendDialog
          className='w-[130px]'
          {...bottomInputProps}
          // dialog params
          dialogText={bottomWarningText[bottomWarning]}
          placement={dialogPlacement}
          overwriteOffset={{ top: bottomTitle ? -20 : 0, left: 0 }}
          overwriteElements={overwriteElement({
            title: bottomTitle,
            inputProps: bottomInputProps,
            rangeText: bottomRangeText,
          })}
          // event params
          setFocus={result => setBottomFocus(result)}
          warning={bottomWarning}
          setWarning={result => setBottomWarning(result)}
          changeCallback={handleBottomChange}
        />

        {showBottomRange && (
          <small className={smallClass(bottomWarning)}>{bottomRangeText}</small>
        )}
      </div>
    </div>
  )
}

export default VerticalInputGroup

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[Components-VerticalInputGroup-container] 
                  w-full 
                  px-[6px] py-1
                  `
const head = `[Components-VerticalInputGroup-head] 
              flex 
              justify-between 
              mb-2
              `
const body = `[Components-VerticalInputGroup-body] 
              relative 
              flex flex-col 
              gap-y-1
              `

// FIXME: propTypes
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
VerticalInputGroup.propTypes = {}

const smallClass = warning =>
  warning ? 'opacity-0' : small + ' text-light-5 text-left'
