import { init as handleInitResponse } from 'src/redux/sagas/handlers/response/beamformers/general/init'
import { init as handleInitErrorResponse } from 'src/redux/sagas/handlers/errorResponse/beamformers/general/init'

import {
  deviceError as handleDeviceErrorResponse,
  deviceErrorAndClearNormalModal as handleDeviceErrorAndClearNormalModal,
} from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import {
  commonRfModeSwitch as handleCommonRfModeSwitchResponse,
  commonFreqSelect as handleCommonFreqSelectResponse,
  commonFreqAfterImport as handleCommonFreqAfterImportResponse,
} from 'src/redux/sagas/handlers/response/beamformers/general/common'

const generalApi = {
  INIT_DEVICE: {
    emit: {
      server: 'beamformers',
      eventName: 'initDevice',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 15000,
    },
    on: {
      eventName: 'initDevice/response',
      handler: handleInitResponse,
      errorHandler: handleInitErrorResponse,
    },
    Controllers: null,
  },

  COMMON_RF_MODE: {
    emit: {
      server: 'beamformers',
      eventName: 'common/rfMode',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'common/rfMode/response',
      handler: handleCommonRfModeSwitchResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  COMMON_FREQUENCY: {
    emit: {
      server: 'beamformers',
      eventName: 'common/frequency',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 15000,
    },
    on: {
      eventName: 'common/frequency/response',
      handler: handleCommonFreqSelectResponse,
      errorHandler: handleDeviceErrorAndClearNormalModal,
    },
  },

  COMMON_FREQUENCY_GET_MULTI_SN: {
    emit: {
      server: 'beamformers',
      eventName: 'common/frequency/get/multiSN',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'common/frequency/get/multiSN/response',
      handler: handleCommonFreqAfterImportResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default generalApi
