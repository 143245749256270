import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Theta({ className }) {
  return (
    <SVGUniqueID>
      <svg
        viewBox='0 0 14 14'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.5'>
          <path d='M7.22053 1.1665C7.67986 1.1665 8.10135 1.23405 8.48502 1.36915C8.8687 1.49884 9.21184 1.70148 9.51445 1.97708C9.82247 2.24727 10.0819 2.59311 10.2926 3.01461C10.5088 3.4307 10.6709 3.92515 10.7789 4.49796C10.8924 5.06536 10.9492 5.71382 10.9492 6.44333V7.94289C10.9492 8.67241 10.8924 9.32357 10.7789 9.89638C10.6709 10.4692 10.5115 10.969 10.3007 11.3959C10.09 11.8228 9.83328 12.1768 9.53066 12.4578C9.22805 12.7388 8.88491 12.9495 8.50124 13.09C8.11757 13.2251 7.69607 13.2927 7.23674 13.2927C6.78282 13.2927 6.36403 13.2251 5.98035 13.09C5.59668 12.9495 5.25084 12.7388 4.94282 12.4578C4.64021 12.1768 4.38082 11.8228 4.16467 11.3959C3.94852 10.969 3.7837 10.4692 3.67022 9.89638C3.55674 9.32357 3.5 8.67241 3.5 7.94289V6.44333C3.5 5.71382 3.55674 5.06536 3.67022 4.49796C3.7837 3.92515 3.94582 3.4307 4.15656 3.01461C4.37272 2.59311 4.6321 2.24727 4.93471 1.97708C5.23733 1.70148 5.58047 1.49884 5.96414 1.36915C6.34781 1.23405 6.76661 1.1665 7.22053 1.1665ZM7.22053 2.39047C6.86388 2.39047 6.54505 2.47423 6.26405 2.64175C5.98846 2.80927 5.7561 3.05514 5.56696 3.37937C5.38323 3.7036 5.24273 4.10078 5.14546 4.57091C5.04819 5.04104 4.99956 5.58143 4.99956 6.19206V6.63787H9.44961V6.19206C9.44961 5.70571 9.41718 5.2626 9.35234 4.86272C9.2929 4.46283 9.20373 4.10888 9.08485 3.80087C8.96596 3.49285 8.81466 3.23617 8.63093 3.03082C8.4526 2.82007 8.24455 2.66066 8.00679 2.55258C7.77442 2.44451 7.51234 2.39047 7.22053 2.39047ZM7.23674 12.0606C7.52855 12.0606 7.79063 12.0039 8.023 11.8904C8.26077 11.7769 8.46881 11.6121 8.64714 11.3959C8.82547 11.1798 8.97407 10.915 9.09295 10.6016C9.21184 10.2827 9.301 9.92069 9.36044 9.51541C9.41989 9.11012 9.44961 8.6643 9.44961 8.17796V7.86184H4.99956V8.17796C4.99956 8.6643 5.02928 9.11012 5.08872 9.51541C5.15357 9.92069 5.24814 10.2827 5.37242 10.6016C5.49671 10.915 5.64802 11.1798 5.82634 11.3959C6.01007 11.6121 6.21812 11.7769 6.45049 11.8904C6.68825 12.0039 6.95034 12.0606 7.23674 12.0606Z' />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
