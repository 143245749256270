import React, { useState } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import Mask from 'src/components/Mask'
import InputGroup from 'src/components/Input/InputGroup'
import CommonSwitch from 'src/components/Switch/CommonSwitch'
import CommonSlider from 'src/components/Slider/CommonSlider'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'
import GainPhase from 'src/components/composite/beamformers/ChannelModule/GainPhase'

import {
  h4,
  h6,
  small,
} from 'src/containers/operating/device/beamformers/style'

import {
  gainIcon,
  gainUnit,
  gainStep,
  gainDecimalScale,
  commonGainStep,
  getCommonGainWarningText,
} from 'src/constants/beamformers'

import {
  cloverCellEvb_channel_allPowerSwitch_watcher,
  cloverCellEvb_channel_singlePowerSwitch_watcher,
  cloverCellEvb_channel_singleGainChange_watcher,
  cloverCellEvb_channel_singlePhaseChange_watcher,
  cloverCellEvb_channel_commonGainSliderChange_watcher,
  cloverCellEvb_channel_commonGainChange_watcher,
} from 'src/redux/actions/beamformers/cloverCellEvbAction'

import { BiLock } from 'react-icons/bi'

const HVModeChannelItem = ({ hvMode, isShowLockMask }) => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const [commonGainWarning, setCommonGainWarning] = useState(false)
  const [commonGainFocus, setCommonGainFocus] = useState(false)

  const showCommonGainRange = commonGainWarning || commonGainFocus

  const rfMode = current.data.deviceControl.common.rfMode

  const currentBBoardData =
    current.data.deviceControl.channel[rfMode]?.[hvMode]?.lstBoardData

  // evb 只有一個板子所以直接抓 0
  // commonCurrentGain 會輸入負號，這邊不要下 || 0
  const commonCurrentGain = currentBBoardData?.[0]?.commonCurrentGain
  const totalGainMin = currentBBoardData?.[0]?.totalGainMin || 0
  const totalGainMax = currentBBoardData?.[0]?.totalGainMax || 0
  const commonGainMin = currentBBoardData?.[0]?.commonGainMin || 0
  const commonGainMax = currentBBoardData?.[0]?.commonGainMax || 0

  const commonCurrentPhase = currentBBoardData?.[0]?.commonCurrentPhase || 0
  const totalPhaseMin = currentBBoardData?.[0]?.totalPhaseMin || 0
  const totalPhaseMax = currentBBoardData?.[0]?.totalPhaseMax || 0
  const commonPhaseMin = currentBBoardData?.[0]?.commonPhaseMin || 0
  const commonPhaseMax = currentBBoardData?.[0]?.commonPhaseMax || 0

  const lstChannelData = currentBBoardData?.[0]?.lstChannelData || {}

  const isThisHvModeAllPowerOff =
    lstChannelData !== undefined &&
    R.pipe(R.values, R.all(R.propEq(false, 'power')))(lstChannelData)

  const commonGainWarningText = getCommonGainWarningText({
    min: commonGainMin,
    max: commonGainMax,
    step: commonGainStep,
  })

  const commonGainInputProps = {
    // input params
    icon: gainIcon,
    unit: gainUnit,
    off: isThisHvModeAllPowerOff,
    disabled: isThisHvModeAllPowerOff,
    step: gainStep,
    validMin: commonGainMin,
    validMax: commonGainMax,
    decimalScale: gainDecimalScale,
    value: String(commonCurrentGain),
    showProgress: false,
    emptyValue: '',
  }

  const commonGainDialogOverwriteElement = (
    <div className='relative flex flex-col gap-y-1'>
      <InputGroup
        className='w-[130px] h-[32px]'
        warning={true}
        disabled={true}
        {...commonGainInputProps}
      />

      <small className={small + ' text-yellow '}>
        Range {commonGainMin} ~ {commonGainMax}
      </small>
    </div>
  )

  const channelProps = channelIndex => ({
    boardIndex: 1,
    channelIndex,
    channelTitleIndex: channelIndex + 1,
    isChannelDisabled: false,

    totalGainMin,
    totalGainMax,
    commonCurrentGain,
    commonGainMin,
    commonGainMax,

    totalPhaseMin,
    totalPhaseMax,
    commonCurrentPhase,
    commonPhaseMin,
    commonPhaseMax,

    totalGain: lstChannelData?.[channelIndex]?.totalGain,
    elementGain: lstChannelData?.[channelIndex]?.elementGain,
    totalPhase: lstChannelData?.[channelIndex]?.totalPhase,
    elementPhase: lstChannelData?.[channelIndex]?.elementPhase,
    power: lstChannelData?.[channelIndex]?.power,
    isPowerDisabled: false,

    handleSinglePowerClick: ({ value }) =>
      dispatch(
        cloverCellEvb_channel_singlePowerSwitch_watcher({
          sn,
          hvMode,
          boardIndex: 0,
          channelIndex,
          value,
        })
      ),

    handleSingleGainChange: ({ value, isValid }) =>
      dispatch(
        cloverCellEvb_channel_singleGainChange_watcher({
          sn,
          hvMode,
          boardIndex: 0,
          channelIndex,
          value,
          isValid,
        })
      ),

    handleSinglePhaseChange: ({ value, isValid }) =>
      dispatch(
        cloverCellEvb_channel_singlePhaseChange_watcher({
          sn,
          hvMode,
          boardIndex: 0,
          channelIndex,
          value,
          isValid,
        })
      ),
  })

  const titleName = hvMode === 'horizon' ? 'H' : 'V'

  return (
    <div className='relative bg-white/10 rounded-[6px] py-4'>
      {isShowLockMask && (
        <Mask className='flex flex-col justify-center items-center text-white/80 cursor-not-allowed'>
          <span className='text-base font-bold -mt-8 mb-4'>
            Preset items auto-optimize
          </span>
          <BiLock className='text-4xl' />
        </Mask>
      )}

      <div className='flex items-center justify-between px-2 mb-4'>
        <h4 className={title}>{titleName}-Polarization</h4>

        <CommonSwitch
          type='text' // text , block , dot
          onText='ON' // string
          offText='OFF' // string
          size='sm' // sm, md
          on={!isThisHvModeAllPowerOff} // bool
          disabled={false} // bool
          loading={false} // bool
          onClick={() =>
            dispatch(
              cloverCellEvb_channel_allPowerSwitch_watcher({
                sn,
                hvMode,
                value: isThisHvModeAllPowerOff,
              })
            )
          }
        />
      </div>

      <div className='px-2'>
        <h6 className={subtitle + ' mb-1 '}>Common Gain</h6>
        <div className='flex gap-x-3'>
          <CommonSlider
            className='mt-[10px]'
            value={commonCurrentGain} // int
            min={commonGainMin} // int
            max={commonGainMax} // int
            step={commonGainStep} // int
            disabled={isThisHvModeAllPowerOff} // bool
            onChange={value =>
              dispatch(
                cloverCellEvb_channel_commonGainSliderChange_watcher({
                  sn,
                  hvMode,
                  boardIndex: 0,
                  value,
                })
              )
            }
            onAfterChange={value =>
              dispatch(
                cloverCellEvb_channel_commonGainChange_watcher({
                  sn,
                  hvMode,
                  boardIndex: 0,
                  value,
                  isValid: true,
                })
              )
            }
          />

          <div className='flex flex-col gap-y-1'>
            <InputGroupAppendDialog
              {...commonGainInputProps}
              className='w-[130px]'
              // dialog params
              dialogText={commonGainWarningText[commonGainWarning]}
              placement='right'
              overwriteOffset={{ top: 0, left: 0 }}
              overwriteElements={commonGainDialogOverwriteElement}
              // event params
              setFocus={result => setCommonGainFocus(result)}
              warning={commonGainWarning}
              setWarning={result => setCommonGainWarning(result)}
              changeCallback={({ value, isValid }) =>
                dispatch(
                  cloverCellEvb_channel_commonGainChange_watcher({
                    sn,
                    hvMode,
                    boardIndex: 0,
                    value,
                    isValid,
                  })
                )
              }
            />
            {showCommonGainRange && (
              <small className={smallClass(commonGainWarning)}>
                Range {commonGainMin} ~ {commonGainMax}
              </small>
            )}{' '}
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-y-4 pt-6 px-[2px]'>
        <GainPhase {...channelProps(1)} />
        <GainPhase {...channelProps(0)} />
        <GainPhase {...channelProps(2)} />
        <GainPhase {...channelProps(3)} />
      </div>
    </div>
  )
}

const title = `${h4} text-white`
const subtitle = `${h6} text-light-1`
const smallClass = warning =>
  warning ? 'opacity-0' : small + ' text-light-5 text-left'

HVModeChannelItem.propTypes = {}

export default HVModeChannelItem
