import React from 'react'
import { SpotLight } from '@react-three/drei'

const Light = () => {
  return (
    <>
      <MovieLight position={[0, -40, 20]} />
      <MovieLight position={[0, -40, -20]} />
      <MovieLight position={[20, -40, 0]} />
      <MovieLight position={[-20, -40, 0]} />
      <MovieLight position={[0, 100, 0]} color='white' intensity={0.5} />
      <MovieLight position={[0, 60, 0]} color='white' intensity={1} />

      {/* Device 上下左右 光源       */}
      <MovieLight position={[60, -10, 0]} color='white' intensity={1} />
      <MovieLight position={[0, -10, 60]} color='white' intensity={1} />
      <MovieLight position={[-60, -10, 0]} color='white' intensity={1} />
      <MovieLight position={[0, -10, -60]} color='white' intensity={1} />
    </>
  )
}

export default Light

const MovieLight = ({ position, color = 'white', intensity = 3 }) => {
  return (
    <SpotLight
      position={position}
      color={color}
      castShadow
      penumbra={1} // 半影
      distance={100} // 射程
      angle={100} // 擴散角度
      attenuation={100} // 衰減
      anglePower={100}
      intensity={intensity} //亮度
    />
  )
}
