import { delay, call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import __export_API_sender from 'src/redux/sagas/services/restfulTools/__export_API_sender'

import { risCommonExportChannelApi } from 'src/redux/sagas/services/restfulAPI/beamformersApi'
import { modalActions } from 'src/redux/slices/modal'

export function* commonAfterImport(response) {
  try {
    const { sn, responseData } = response
    const { channel } = responseData.data

    const isSuccess = responseData.code === 0

    if (isSuccess) {
      let { currentData } = yield call(getCommonArgs, sn)
      currentData.deviceControl.channel = channel
      yield put(setSingleDeviceData({ sn, data: currentData }))
      yield put(modalActions.showModalOfFileImportSuccess())

      yield delay(1000)
      yield put(modalActions.clearNormalModal())
    }

    if (!isSuccess) {
      yield put(modalActions.showModalOfFileImportFailed())
    }
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* commonExport(payloads) {
  try {
    const { sn } = payloads
    let { lookupID } = yield call(getCommonArgs, sn)
    yield call(__export_API_sender, {
      api: risCommonExportChannelApi,
      data: { sn, lookupID },
    })
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}
