import { call, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { setServiceVersion } from 'src/redux/slices/uiControl/facility/versions'

// const devLookupID = process.env.REACT_APP_DEV_LOOKUP_UD
// import { isDeveloping } from 'src/funcs/getEnv'

import { isDeveloping, deployMode, devServerTarget } from 'src/funcs/getEnv'

import __restful_API_sender from 'src/redux/sagas/services/restfulTools/__restful_API_sender'
import { devWarLog } from 'src/funcs/tools'

import { getServiceVersion as getServiceVersionAPI } from 'src/redux/sagas/services/restfulAPI/facilityApi'
import ServiceVersion from 'src/funcs/versionClass'

//* REACT_APP_VERSION 這個環境變數
//* 會在正式版 github action 部署流程時加上去
//* 內容會是 {tag_name:'', tag_message:'{FE:'v0.0.0.0', BE-FACILITY:'v0.0.0.0', ...}'}
//* tag_name = 當前 FE 版本 (WEB-TLK 的版本)
//* tag_message = 當前 FE 部署時期望配合的 BE & FW & Middleware 版本
const env_allVersion = process.env.REACT_APP_VERSION

//! 開發 version 用的 hard code
//! 吃這邊就會產生一個 env_allVersion
// const env_allVersion =
//  '{"tag_name": "TLKWEB-v0.0.0.7","tag_message": {"FE": "v9.9.9.9","BE-FACILITY": "v8.8.8.1","BE-BEAMFORMERS": "v8.8.8.2","BE-FREQCONVERTER": "v8.8.8.3","BE-DATABASE": "v8.8.8.4", "BE-TLKAPI": "v8.8.8.5", "BE-TLK-MIDDLEWARE": "v7.7.7.7", "FW-COMM-API": "v4.4.4.4"}}'

const tag_name = env_allVersion ? JSON.parse(env_allVersion)?.tag_name : ''

const env_tlkWebVersion = R.pipe(
  R.split('-v'),
  R.last(),
  R.concat('v')
)(tag_name)

const env_beFwVersion = env_allVersion
  ? JSON.parse(env_allVersion)?.tag_message
  : {}

function* _onlyFeVersionUpdate() {
  const onlyFeVersion = Object.entries(env_beFwVersion).reduce(
    (acc, [key, value]) => {
      return [...acc, { key, fe: value, be: undefined }]
    },
    []
  )

  // 先把純 fe env 的 version 寫進 redux (用 Git 管理)
  // 這樣才不會因為 be timeout 晚回又直接按 device control 而無法比對 middleware version
  // 造成不會提示 middleware 過舊
  yield put(setServiceVersion(onlyFeVersion))
  return onlyFeVersion
}

function* _getOnlyBeVersion() {
  const response = yield call(__restful_API_sender, {
    api: getServiceVersionAPI,
  })

  const be_serviceVersion = response?.data?.data

  const onlyBeVersion = yield Object.entries(be_serviceVersion).reduce(
    (acc, [key, value]) => {
      return [...acc, { key, fe: undefined, be: value }]
    },
    []
  )

  return { onlyBeVersion, be_serviceVersion }
}

export function* getServiceVersion() {
  try {
    let allVersion
    if (isDeveloping) {
      console.log('%c%s', 'color: #2f9acf', 'Deploy Mode: ' + deployMode)
      console.log(
        '%c%s',
        'color: #7bb7d5',
        'DEV Server Target: ' + devServerTarget
      )
    }

    // 拿到只有 fe 的 version 清單
    const onlyFeVersion = yield call(_onlyFeVersionUpdate)
    allVersion = yield onlyFeVersion

    yield put(setServiceVersion(allVersion))

    // 拿到只有 be 的 version 清單
    const { onlyBeVersion, be_serviceVersion } = yield call(_getOnlyBeVersion)
    allVersion = yield onlyBeVersion

    // both
    if (env_allVersion !== undefined) {
      allVersion = yield Object.entries(env_beFwVersion).reduce(
        (acc, [key, value]) => {
          return [...acc, { key, fe: value, be: be_serviceVersion[key] }]
        },
        [{ key: 'TLKWEB', fe: env_tlkWebVersion, be: undefined }]
      )
    }

    yield put(setServiceVersion(allVersion))

    if (isDeveloping) {
      const allVersionToClass = yield allVersion.map(
        ({ key, fe, be }) => new ServiceVersion(key, fe, be, undefined)
      )
      console.table(allVersionToClass)
    }
  } catch (error) {
    devWarLog('[handler] getServiceVersion error:', error)

    // 不管怎樣都有部署時 env version
    // 所以遇到錯誤最少顯示這個
    if (isDeveloping) {
      const onlyFeVersion = Object.entries(env_beFwVersion).reduce(
        (acc, [key, value]) => {
          return [...acc, new ServiceVersion(key, value, undefined)]
        },
        []
      )

      console.table(onlyFeVersion)
    }
  }
}
