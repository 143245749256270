const facilityClass = {
  'r_/api/facility/getServiceVersion'({ req, res }) {
    return {
      'BE-FACILITY': 'v8.8.8.1',
      'BE-BEAMFORMERS': 'v8.8.8.2',
      'BE-FREQCONVERTER': 'v8.8.8.3',
      'BE-DATABASE': 'v8.8.8.4',
      'BE-TLKAPI': 'v8.8.8.5',
    }
  },

  'r_/demo/api/facility/device/scanDevices'({ req, res }) {
    return this._demoScan()
  },

  'r_/api/facility/device/scanDevices'({ req, res }) {
    return this._mockServerScan()
  },

  s_modifyStaticIP({ payloads }) {
    return this._setIps(payloads)
  },

  's_device/get/info'({ payloads }) {
    const { sn } = payloads
    const info = this._getCurrentData(sn).info
    return { [sn]: { info } }
  },

  's_device/reboot'({ payloads }) {
    const { sn } = payloads
    const info = this._getCurrentData(sn).info
    return { [sn]: { info } }
  },
}

export default facilityClass
