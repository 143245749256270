import React from 'react'
import PropTypes from 'prop-types'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <RadioButton
//   className=''
//   size={} // 'sm', 'md'
//   group={} // html radio 裡面的 name, 用來判斷是從哪些去單選
//   name={} // onClick 會回傳的值
//   label={} // 後方挾帶的文字
//   checked={} // 是否被選取
//   disabled={}
//   onClick={()=>{})}
// />

const Radio = props => {
  const { className, size, group, name, label, checked, disabled, onClick } =
    props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = () => !disabled && onClick(name)
  const handleKeyDown = e => !disabled && e.key === 'Enter' && onClick(name)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                          ${className} 
                          ${cursor(disabled)} 
                          group 
                          flex items-center 
                          gap-x-[10px] 
                          select-none 
                          `

  const radioClass = `
                      ${radioCommon}
                      ${radioSize[size]}
                      ${radioTheme(checked, disabled)} 
                      ${radioAfter(size, checked, disabled)} 
                      ${cursor(disabled)} 
                      `

  const labelClass = `
                      ${textColor(disabled)} 
                      ${cursor(disabled)} 
                      text-base font-normal leading-5 
                      mt-[1px]
                      `
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={containerClass} onClick={handleClick}>
      <input
        type='radio'
        name={group}
        className={radioClass}
        onKeyDown={handleKeyDown}
      />
      {label && <label className={labelClass}>{label}</label>}
    </div>
  )
}
export default Radio

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const radioCommon = `
                    group 
                    appearance-none 
                    relative 
                    rounded-full 
                    bg-transparent 
                    border-1 border-solid 
                    duration-500 
                    `
const radioSize = { sm: 'w-4 h-4', md: 'w-[18px] h-[18px]' }
const radioTheme = (checked, disabled) => {
  if (disabled)
    return `
            bg-dark-3
            border-light-4
            `

  if (checked)
    return `
            bg-dark-1
            border-teal 
            group-hover:shadow-[inset_0_0_6px_rgba(40,202,206,0.7)]
            `

  if (!checked)
    return `
            bg-dark-1
            border-light-4 group-hover:border-light-1 
            group-hover:shadow-[inset_0_0_6px_rgba(255,255,255,0.7)]
            `
}

const afterCommon = `
                    after:block 
                    after:content-[''] 
                    after:absolute 
                    after:inset-0 after:m-auto 
                    after:rounded-full
                    after:duration-500
                    `
const afterSize = { sm: 'after:w-2 after:h-2', md: 'after:w-3 after:h-3' }
const afterTheme = disabled => (disabled ? 'after:bg-light-4' : 'after:bg-teal')
const afterHidden = checked => (checked ? '' : 'after:opacity-0')
const radioAfter = (size, checked, disabled) => `
                                                ${afterCommon} 
                                                ${afterHidden(checked)}
                                                ${afterSize[size]} 
                                                ${afterTheme(disabled)}
                                                `

const textColor = disabled => (disabled ? 'text-white/50' : 'text-white')

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Radio.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
