import React from 'react'
import PropTypes from 'prop-types'

import { small } from 'src/containers/operating/device/freqConverter/style'

import { valueConversionFromUnit } from 'src/funcs/device/udbox'

import { appendComma } from 'src/funcs/tools'
import RadioButtonGroup from 'src/components/Button/RadioButtonGroup'

const LoItem = props => {
  const { subtitle, label, unit, value, isSelected, disabled, handleClick } =
    props

  const processedValue = valueConversionFromUnit(value, unit)

  const { range: rangeDisabled, resolution: resolutionDisabled } = disabled

  const isDisabled = rangeDisabled || resolutionDisabled

  const valueClass = `
                    text-2xl font-normal leading-7 mr-3 
                    ${valueColor(isDisabled)} 
                    `

  const unitClass = `text-base font-normal leading-5 ${valueColor(isDisabled)} `

  return (
    <>
      <RadioButtonGroup
        className='mt-4'
        title={subtitle} // '' 右邊標題文字
        content={
          <div className='flex justify-center items-center'>
            <h3 className={valueClass}>{appendComma(processedValue)}</h3>
            <span className={unitClass}>{unit}</span>
          </div>
        } // '' 右邊內容文字
        checked={isSelected} // 是否被選取
        group='udFreqLo' // html radio 裡面的 name, 用來判斷是從哪些去單選
        name={label} // onClick 會回傳的值
        disabled={isDisabled}
        onClick={handleClick}
      />

      {rangeDisabled && (
        <small className={small + ' text-white/50 mt-2'}>
          Out of the LO frequency range
        </small>
      )}
      {!rangeDisabled && resolutionDisabled && (
        <small className={small + ' text-white/50 mt-2'}>
          Does not meet LO frequency resolution (
          {valueConversionFromUnit(10, unit)} {unit})
        </small>
      )}
    </>
  )
}

LoItem.propTypes = {
  subtitle: PropTypes.string,
  label: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
}

export default LoItem

const valueColor = isDisabled => (isDisabled ? `text-light-5` : `text-white `)
