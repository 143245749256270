import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const LEDLights = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='203'
        height='98'
        viewBox='0 0 203 98'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        {/* 右一 */}
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M120 66C120 67.6569 118.657 69 117 69C115.343 69 114 67.6569 114 66C114 64.3431 115.343 63 117 63C118.657 63 120 64.3431 120 66ZM132 66C132 67.6569 130.657 69 129 69C127.343 69 126 67.6569 126 66C126 64.3431 127.343 63 129 63C130.657 63 132 64.3431 132 66ZM141 69C142.657 69 144 67.6569 144 66C144 64.3431 142.657 63 141 63C139.343 63 138 64.3431 138 66C138 67.6569 139.343 69 141 69Z'
          fill='white'
        />
        <path
          d='M117 70C119.209 70 121 68.2091 121 66H119C119 67.1046 118.105 68 117 68V70ZM113 66C113 68.2091 114.791 70 117 70V68C115.895 68 115 67.1046 115 66H113ZM117 62C114.791 62 113 63.7909 113 66H115C115 64.8954 115.895 64 117 64V62ZM121 66C121 63.7909 119.209 62 117 62V64C118.105 64 119 64.8954 119 66H121ZM129 70C131.209 70 133 68.2091 133 66H131C131 67.1046 130.105 68 129 68V70ZM125 66C125 68.2091 126.791 70 129 70V68C127.895 68 127 67.1046 127 66H125ZM129 62C126.791 62 125 63.7909 125 66H127C127 64.8954 127.895 64 129 64V62ZM133 66C133 63.7909 131.209 62 129 62V64C130.105 64 131 64.8954 131 66H133ZM143 66C143 67.1046 142.105 68 141 68V70C143.209 70 145 68.2091 145 66H143ZM141 64C142.105 64 143 64.8954 143 66H145C145 63.7909 143.209 62 141 62V64ZM139 66C139 64.8954 139.895 64 141 64V62C138.791 62 137 63.7909 137 66H139ZM141 68C139.895 68 139 67.1046 139 66H137C137 68.2091 138.791 70 141 70V68Z'
          fill='white'
          mask='url(#path-4-inside-1_6596_13187)'
        />
      </svg>
    </SVGUniqueID>
  )
}
export default LEDLights
