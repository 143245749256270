import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Board = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='145'
        height='148'
        viewBox='0 0 145 148'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}>
        <path
          d='M5.17898 1.86033C5.46208 1.48057 5.90792 1.25684 6.38159 1.25684H137.938C138.411 1.25684 138.857 1.48057 139.14 1.86032L139.541 1.56149L139.14 1.86033L141.377 4.86033C142.114 5.84972 141.408 7.25684 140.174 7.25684H4.14519C2.91113 7.25684 2.20501 5.84972 2.94257 4.86033L5.17898 1.86033Z'
          fill='#121212'
          stroke='white'
        />
        <rect
          x='0.959473'
          y='4.98242'
          width='142.4'
          height='142.4'
          rx='1.5'
          fill='#222222'
        />
        <rect
          x='0.959473'
          y='4.98242'
          width='142.4'
          height='142.4'
          rx='1.5'
          stroke='white'
        />

        <rect
          x='71.6597'
          y='75.457'
          width='1'
          height='1'
          rx='0.5'
          stroke='#F2F2F2'
          strokeWidth='2'
        />
        <rect
          x='60.1597'
          y='63.957'
          width='2'
          height='2'
          rx='1'
          fill='#F2F2F2'
        />
        <rect
          x='82.1597'
          y='63.957'
          width='2'
          height='2'
          rx='1'
          fill='#F2F2F2'
        />
        <rect
          x='60.1597'
          y='85.957'
          width='2'
          height='2'
          rx='1'
          fill='#F2F2F2'
        />
        <rect
          x='82.1597'
          y='85.957'
          width='2'
          height='2'
          rx='1'
          fill='#F2F2F2'
        />
        <defs>
          <clipPath id='clip0_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 9.48242)'
            />
          </clipPath>
          <clipPath id='clip1_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 13.6826)'
            />
          </clipPath>
          <clipPath id='clip2_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 17.8828)'
            />
          </clipPath>
          <clipPath id='clip3_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 22.082)'
            />
          </clipPath>
          <clipPath id='clip4_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 26.2822)'
            />
          </clipPath>
          <clipPath id='clip5_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 30.4824)'
            />
          </clipPath>
          <clipPath id='clip6_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 34.6826)'
            />
          </clipPath>
          <clipPath id='clip7_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 38.8828)'
            />
          </clipPath>
          <clipPath id='clip8_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 43.082)'
            />
          </clipPath>
          <clipPath id='clip9_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 47.2822)'
            />
          </clipPath>
          <clipPath id='clip10_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 51.4824)'
            />
          </clipPath>
          <clipPath id='clip11_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 55.6826)'
            />
          </clipPath>
          <clipPath id='clip12_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 59.8828)'
            />
          </clipPath>
          <clipPath id='clip13_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 64.082)'
            />
          </clipPath>
          <clipPath id='clip14_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 68.2822)'
            />
          </clipPath>
          <clipPath id='clip15_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 72.4824)'
            />
          </clipPath>
          <clipPath id='clip16_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 76.6826)'
            />
          </clipPath>
          <clipPath id='clip17_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 80.8828)'
            />
          </clipPath>
          <clipPath id='clip18_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 85.082)'
            />
          </clipPath>
          <clipPath id='clip19_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 89.2822)'
            />
          </clipPath>
          <clipPath id='clip20_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 93.4824)'
            />
          </clipPath>
          <clipPath id='clip21_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 97.6826)'
            />
          </clipPath>
          <clipPath id='clip22_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 101.883)'
            />
          </clipPath>
          <clipPath id='clip23_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 106.082)'
            />
          </clipPath>
          <clipPath id='clip24_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 110.282)'
            />
          </clipPath>
          <clipPath id='clip25_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 114.482)'
            />
          </clipPath>
          <clipPath id='clip26_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 118.683)'
            />
          </clipPath>
          <clipPath id='clip27_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 122.883)'
            />
          </clipPath>
          <clipPath id='clip28_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 127.082)'
            />
          </clipPath>
          <clipPath id='clip29_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 131.282)'
            />
          </clipPath>
          <clipPath id='clip30_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 135.482)'
            />
          </clipPath>
          <clipPath id='clip31_16269_25637'>
            <rect
              width='133.4'
              height='3.2'
              fill='white'
              transform='translate(5.45947 139.683)'
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}

export default Board
