import React from 'react'
import ToExternalError from './ToExternalError'
import ToExternalCheck from './ToExternalCheck'

const CTTC = props => {
  const { status: modalStatus } = props

  if (modalStatus === 'toExternalCheck') return <ToExternalCheck {...props} />
  if (modalStatus === 'toExternalError') return <ToExternalError {...props} />
  return <></>
}

export default CTTC
