import React from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import { generalErrorWatcher } from 'src/redux/actions/facility/generalError'

const ErrorBoundaryOfGeneralError = props => {
  const dispatch = useDispatch()
  const userID = useSelector(state => state.member.userID)
  const { isReportIssueDisabled } = props

  const handleError = (info, error) => {
    const time = moment().format('YYYY-MM-DD  HH:mm:ss')
    dispatch(
      generalErrorWatcher({
        userID,
        time,
        code: 1,
        codeName: 'FE ERROR',
        message: String(info) + '\n' + String(error.componentStack),
        isReportIssueDisabled: isReportIssueDisabled ?? false,
      })
    )
  }

  return (
    <ErrorBoundary fallback={<></>} onError={handleError}>
      {props.children}
    </ErrorBoundary>
  )
}

ErrorBoundaryOfGeneralError.propTypes = {}

export default ErrorBoundaryOfGeneralError
