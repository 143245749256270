import React from 'react'

import Antenna from 'src/assets/svg/device/avatar/BboxOne/Antenna'
import Body from 'src/assets/svg/device/avatar/BboxOne/Body'

const BboxOne = props => {
  const { color } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `relative w-full h-full `
  const svgCommon = `absolute inset-0 m-auto duration-1000 scale-100 group-hover:scale-125 `
  const antenna = `${svgCommon} scale-[112%] -translate-x-11 translate-y-3 group-hover:-translate-x-5 group-hover:translate-y-[1px]`
  const body = `${svgCommon} scale-[110%] translate-x-11 -translate-y-2 group-hover:translate-x-4 group-hover:-translate-y-0`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={container}>
      <Body className={body} color={color} />
      <Antenna className={antenna} />
    </div>
  )
}
export default BboxOne
