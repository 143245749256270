import { call, take, fork } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

import socketOnDistributor from 'src/redux/sagas/services/socketTools/socketOnDistributor'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

const beamformersApiList = Object.entries(beamformersApi).map(
  ([apiName, apiContent]) => apiContent.on
)

function subscribe(socket) {
  return eventChannel(channelEmit => {
    beamformersApiList.forEach(({ type, eventName, handler, errorHandler }) =>
      socket.on(eventName, response =>
        channelEmit({ type, eventName, handler, errorHandler, response })
      )
    )

    return () => {}
  })
}

export default function* socketOn(socket) {
  const channel = yield call(subscribe, socket)

  while (true) {
    let { type, eventName, handler, response, errorHandler } = yield take(
      channel
    )

    yield fork(socketOnDistributor, {
      tag: 'beamformers',
      type,
      eventName,
      handler,
      errorHandler,
      response,
    })
  }
}
