import { createSlice, createAction, current } from '@reduxjs/toolkit'

export const versionSlice = createSlice({
  name: 'versions',
  initialState: { serviceVersion: [], midVersion: [] },
  reducers: {
    setServiceVersion(state, action) {
      return { ...current(state), serviceVersion: action.payload }
    },
    setMidVersion(state, action) {
      // action.payload =
      // {
      //   key: 'BE-TLK-MIDDLEWARE',
      //   mid: mid_beTlkMiddlewareVersion,
      // },
      // {
      //   key: 'FW-COMM-API',
      //   mid: mid_fwCommApiVersion,
      // }
      return { ...current(state), midVersion: action.payload }
    },
  },
})

// export action
export const { setServiceVersion, setMidVersion } = versionSlice.actions

export const getServiceVersionWatcher = createAction(
  'version/getServiceVersionWatcher'
)

// export reducer
export const versions = versionSlice.reducer
