import * as R from 'ramda'
import Decimal from 'decimal.js'

import { udboxInitAppendData } from '../configs/initAppendData/udbox.mjs'
import { udbox0630InitAppendData } from '../configs/initAppendData/udbox0630.mjs'
import { udmoduleInitAppendData } from '../configs/initAppendData/udmodule.mjs'
import { sgInitAppendData } from '../configs/initAppendData/sg.mjs'

import { udIfDeviceRange, udRfLoUserRange } from '../configs/spec.mjs'

const freqConverterClass = {
  //* ------------------ ------------------- -------------------
  //* ------------------ ------------------- -------------------
  //* ------------------    freqConverter    -------------------
  //* ------------------ ------------------- -------------------
  //* ------------------ ------------------- -------------------
  _isSG(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'sg')
    } catch (error) {
      console.warn(error)
    }
  },
  _isUDBox5G(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'udbox_5g')
    } catch (error) {
      console.warn(error)
    }
  },
  _isUDBox0630(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'udbox_0630')
    } catch (error) {
      console.warn(error)
    }
  },
  _isUDModule(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'ud_module')
    } catch (error) {
      console.warn(error)
    }
  },

  _getChannelType(sn) {
    try {
      return sn[4] === 'S' ? 'single' : 'dual'
    } catch (error) {
      console.warn(error)
    }
  },

  _getUDDeviceFreqRange(sn) {
    const deviceType = this._getDeviceType(sn)
    const snFreq = R.pipe(R.split('-'), R.last)(sn)

    //! 如果哪天 UD sn 結尾不是頻率就會出事
    // 用 sn 判斷 freq 上下限多少
    // ex:0620
    const low = R.slice(0, 2)(snFreq) // '06'
    const high = R.slice(2, 4)(snFreq) // '20'

    const ifDeviceRange = udIfDeviceRange[deviceType]

    return {
      rfMin: +low * 1000000,
      rfMax: +high * 1000000,
      ifMin: ifDeviceRange.min,
      ifMax: ifDeviceRange.max,
      loMin: +low * 1000000,
      loMax: +high * 1000000,
    }
  },

  _setFreq(sn, newDeviceData, range) {
    let newFreq = newDeviceData.settings?.freq

    newFreq.isSupportFreqUnlock = false
    newFreq.bandwidth.value = 0
    newFreq.unit = 'MHz'

    const { rfMin, rfMax, ifMin, ifMax, loMin, loMax } = range

    newFreq.RF.userMin = rfMin
    newFreq.RF.deviceMin = rfMin
    newFreq.RF.userMax = rfMax
    newFreq.RF.deviceMax = rfMax

    newFreq.IF.userMin = ifMin
    newFreq.IF.deviceMin = ifMin
    newFreq.IF.userMax = ifMax
    newFreq.IF.deviceMax = ifMax

    newFreq.LO.userMin = loMin
    newFreq.LO.deviceMin = loMin
    newFreq.LO.userMax = loMax
    newFreq.LO.deviceMax = loMax

    return newDeviceData
  },

  _getHarmonic({ sn, RF, IF, LO, bandwidth, isLSI }) {
    try {
      let currentData = this._getCurrentData(sn)

      //! 換公式了  https://tmytek.atlassian.net/browse/API-155
      const diff = bandwidth.value + 20000
      const checkList = [8, 4, 2, 1]

      const isHarmonic = checkList.reduce((prev, curr) => {
        if (prev === true) return true

        const upper = LO / curr + diff
        const lower = LO / curr - diff

        return IF > lower && IF < upper
      }, false)

      currentData.current.freq.isHarmonic = isHarmonic
      this._updateSingleDeviceData(sn, currentData)

      return { isHarmonic }
    } catch (error) {
      console.warn(error)
    }
  },

  _setFreqToSupportLockFeature({ sn, newDeviceData }) {
    const deviceType = newDeviceData.info.deviceType
    let newFreq = newDeviceData.settings?.freq

    newFreq.isSupportFreqUnlock = true
    newFreq.bandwidth.value = 0
    newFreq.unit = 'MHz'

    const {
      rfMin: d_rfMid,
      rfMax: d_rfMax,
      ifMin: d_ifMin,
      ifMax: d_ifMax,
      loMin: d_loMin,
      loMax: d_loMax,
    } = this._getUDDeviceFreqRange(sn)

    const { min: u_rfLoMin, max: u_rfLoMax } = udRfLoUserRange[deviceType]

    newFreq.RF.userMin = newFreq?.RF?.userMin || u_rfLoMin
    newFreq.RF.userMax = newFreq?.RF?.userMax || u_rfLoMax
    newFreq.RF.deviceMin = d_rfMid
    newFreq.RF.deviceMax = d_rfMax

    newFreq.IF.userMin = newFreq?.IF?.userMin || d_ifMin
    newFreq.IF.userMax = newFreq?.IF?.userMax || d_ifMax
    newFreq.IF.deviceMin = d_ifMin
    newFreq.IF.deviceMax = d_ifMax

    newFreq.LO.userMin = newFreq?.LO?.userMin || u_rfLoMin
    newFreq.LO.userMax = newFreq?.LO?.userMax || u_rfLoMax
    newFreq.LO.deviceMin = d_loMin
    newFreq.LO.deviceMax = d_loMax

    return newDeviceData
  },

  _unlockInit({ newDeviceData }) {
    // unlock 要清掉 RF IF LO
    newDeviceData.settings.freq.RF.value = null
    newDeviceData.settings.freq.IF.value = null
    newDeviceData.settings.freq.LO.currentSelection = ''
    newDeviceData.settings.freq.LO.LSI.value = ''
    newDeviceData.settings.freq.LO.HSI.value = ''

    return newDeviceData
  },

  _udBox5GInit({ sn, newDeviceData }) {
    const chType = this._getChannelType(sn)

    newDeviceData = { ...newDeviceData, ...udboxInitAppendData(sn) }

    const rfMin = 24000000
    const rfMax = 44000000
    const ifMin = 10000
    const ifMax = 14000000
    const loMin = 24000000
    const loMax = 24000000
    newDeviceData = this._setFreq(sn, newDeviceData, {
      rfMin,
      rfMax,
      ifMin,
      ifMax,
      loMin,
      loMax,
    })

    newDeviceData.current.ledIndicators.PLO_LOCK = 1
    newDeviceData.current.ledIndicators.CH1 = 1
    newDeviceData.current.freq.LO = 28000000
    newDeviceData.settings.device.PLO_LOCK = 1
    newDeviceData.settings.device.CH1 = 1
    if (chType === 'dual') {
      newDeviceData.current.ledIndicators.CH2 = 1
      newDeviceData.settings.device.CH2 = 1
    }

    return newDeviceData
  },
  _udModuleInit({ sn, newDeviceData }) {
    newDeviceData = { ...newDeviceData, ...udmoduleInitAppendData(sn) }
    newDeviceData = this._setFreqToSupportLockFeature({ sn, newDeviceData })

    newDeviceData.current.ledIndicators.status = 0 // -1: 錯誤(紅 - 恆亮), 0: 正常(綠 - 恆亮)
    newDeviceData.current.ledIndicators.LO = 0 // -1: 非鎖定(紅 - 恆亮), 0: 鎖定(綠 - 恆亮)
    newDeviceData.current.ledIndicators.ref = 0 // -1: 非鎖定(紅 - 恆亮), 0: internal (綠 - 恆亮), 1: external (黃 - 恆亮)
    newDeviceData.current.freq.LO = 6000000

    newDeviceData.settings.device.refSource = 0

    return newDeviceData
  },

  _ud0630Init({ sn, newDeviceData }) {
    newDeviceData = { ...newDeviceData, ...udbox0630InitAppendData(sn) }
    newDeviceData.current.freq.isSupportUpdateCurrent = true
    newDeviceData.current.freq.LO = 6000000
    newDeviceData = this._setFreqToSupportLockFeature({ sn, newDeviceData })

    return newDeviceData
  },

  _sgInit({ sn, newDeviceData }) {
    newDeviceData = { ...newDeviceData, ...sgInitAppendData(sn) }

    return newDeviceData
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------      General       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  _udInit(sn) {
    try {
      const currentData = this._getCurrentData(sn)

      const isSG = this._isSG(sn)
      const isUDBox5G = this._isUDBox5G(sn)
      const isUDBox0630 = this._isUDBox0630(sn)
      const isUDModule = this._isUDModule(sn)

      let newDeviceData = { ...currentData }

      // 如果已經 init 過了要保留資料，就特別處理
      // 不然 unlock 在 demo mode 時會拿不到新的 range
      const isInitialed = currentData.info.initialization === 'finished'
      if (isInitialed)
        return { [sn]: { deviceData: this._unlockInit({ newDeviceData }) } }

      // 沒 init 過就跑首次 init 流程
      newDeviceData.info.initialization = 'finished'
      if (isUDBox5G) newDeviceData = this._udBox5GInit({ sn, newDeviceData })
      if (isUDModule) newDeviceData = this._udModuleInit({ sn, newDeviceData })
      if (isUDBox0630) newDeviceData = this._ud0630Init({ sn, newDeviceData })
      if (isSG) newDeviceData = this._sgInit({ sn, newDeviceData })

      this._updateSingleDeviceData(sn, newDeviceData)

      return { [sn]: { deviceData: newDeviceData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_update/current'({ payloads }) {
    try {
      const { sn } = payloads
      const isUDBox0630 = this._isUDBox0630(sn)

      if (isUDBox0630) {
        const currentData = this._getCurrentData(sn)

        const RFValue = currentData.settings.freq.RF.value
        const IFValue = currentData.settings.freq.IF.value

        const LOCurrentSelection = currentData.settings.freq.LO.currentSelection
        const LOValue = currentData.settings.freq.LO[LOCurrentSelection]?.value

        const RFMax = currentData.settings.freq.RF.userMax
        const IFMax = currentData.settings.freq.IF.userMax
        const LOMax = currentData.settings.freq.LO.userMax

        const isHarmonic = currentData.current.freq.isHarmonic

        let freq = {
          RF: RFValue || RFMax, // int
          IF: IFValue || IFMax, // int
          LO: LOValue || LOMax, // int
          isHarmonic, //  bool
          isSupportUpdateCurrent: true,
        }

        let ledIndicators = {
          status: 0, //  0: 正常(綠), 1: (黃), 2: 錯誤(紅)
          LO: 0, //  0: 正常(綠), 1: (黃), 2: 錯誤(紅)
          ref: 0, //  0: 正常(綠), 1: (黃), 2: 錯誤(紅)
        }

        return { [sn]: { freq, ledIndicators } }
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_freqSetting/getLO'({ payloads }) {
    try {
      const { sn, RF, IF } = payloads
      const isUDBox5G = this._isUDBox5G(sn)
      const isUDModule = this._isUDModule(sn)
      const _isUDBox0630 = this._isUDBox0630(sn)

      let min
      let max

      if (isUDBox5G) {
        min = 24000000
        max = 44000000
      }

      if (isUDModule || _isUDBox0630) {
        const { loMin: udModuleLoMin, loMax: udModuleLoMax } =
          this._getUDDeviceFreqRange(sn)
        min = udModuleLoMin
        max = udModuleLoMax
      }

      const isRangeDisabled = value => +value < min || +value > max
      // lo 一定要 10kHz 結尾，所以多做一個 resolution 驗證
      const isResolutionDisabled = value => +value % 10 !== 0

      const LSIValue = new Decimal(RF).sub(IF).toNumber()
      const HSIValue = new Decimal(RF).add(IF).toNumber()

      let LSI = {
        value: LSIValue,
        disabled: {
          range: isRangeDisabled(LSIValue),
          resolution: isResolutionDisabled(LSIValue),
        },
      }
      let HSI = {
        value: HSIValue,
        disabled: {
          range: isRangeDisabled(HSIValue),
          resolution: isResolutionDisabled(HSIValue),
        },
      }
      const currentSelection = ''

      let currentData = this._getCurrentData(sn)
      currentData.settings.freq.LO = {
        ...currentData.settings.freq.LO,
        ...{ currentSelection, LSI, HSI },
      }

      this._updateSingleDeviceData(sn, currentData)
      const LO = currentData.settings.freq.LO

      return { [sn]: { LO } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_freqSetting/apply'({ payloads }) {
    try {
      const { sn, RF, IF, LO, bandwidth } = payloads
      let currentData = this._getCurrentData(sn)

      currentData.settings.freq.RF.value = RF
      currentData.settings.freq.IF.value = IF
      currentData.settings.freq.bandwidth = bandwidth

      currentData.current.freq.RF = RF
      currentData.current.freq.IF = IF
      currentData.current.freq.LO = LO

      const isLSI = currentData.settings.freq.LO.LSI.value === LO
      if (isLSI) currentData.settings.freq.LO.currentSelection = 'LSI'
      if (!isLSI) currentData.settings.freq.LO.currentSelection = 'HSI'

      this._updateSingleDeviceData(sn, currentData)

      // { isLSI } 給下一步判斷 Harmonic 用
      const { isHarmonic } = this._getHarmonic({
        sn,
        RF,
        IF,
        LO,
        bandwidth,
        isLSI,
      })

      const isSupportUpdateCurrent =
        currentData.current.freq.isSupportUpdateCurrent

      return {
        [sn]: {
          currentFreq: { RF, IF, LO, isHarmonic, isSupportUpdateCurrent },
        },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_freqSetting/unlock/range'({ payloads }) {
    const { sn, digest } = payloads

    // demo mode 只要有給就當它合法
    if (digest) {
      const currentData = this._getCurrentData(sn)
      const newRFUserMax = currentData.settings.freq.RF.deviceMax
      const newRFUserMin = currentData.settings.freq.RF.deviceMin
      const newIFUserMax = currentData.settings.freq.IF.deviceMax
      const newIFUserMin = currentData.settings.freq.IF.deviceMin
      const newLOUserMax = currentData.settings.freq.LO.deviceMax
      const newLOUserMin = currentData.settings.freq.LO.deviceMin

      currentData.settings.freq.RF.userMax = newRFUserMax
      currentData.settings.freq.RF.userMin = newRFUserMin
      currentData.settings.freq.IF.userMax = newIFUserMax
      currentData.settings.freq.IF.userMin = newIFUserMin
      currentData.settings.freq.LO.userMax = newLOUserMax
      currentData.settings.freq.LO.userMin = newLOUserMin

      this._updateSingleDeviceData(sn, currentData)

      return {
        [sn]: {
          RF: { userMax: newRFUserMax, userMin: newRFUserMin },
          IF: { userMax: newIFUserMax, userMin: newIFUserMin },
          LO: { userMax: newLOUserMax, userMin: newLOUserMin },
        },
      }
    }
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       UD Box       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  s_deviceSetting({ payloads }) {
    try {
      const { sn, label, value } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device[label] = value
      currentData.current.ledIndicators[label] = value

      if (label === 'SOURCE_100M' || label === 'OUT_100M') {
        if (label === 'OUT_100M' && value === 0)
          currentData.current.ledIndicators.LED_100M = 0

        if (label === 'OUT_100M' && value === 1)
          currentData.current.ledIndicators.LED_100M = 1

        if (label === 'SOURCE_100M' && value === 1)
          currentData.current.ledIndicators.LED_100M = 2

        // 切回 internal out 100 會吃之前的狀態
        if (label === 'SOURCE_100M' && value === 0)
          currentData.current.ledIndicators.LED_100M =
            currentData.settings.device.OUT_100M
      }

      this._updateSingleDeviceData(sn, currentData)

      return {
        [sn]: {
          label,
          value,
          ledIndicators: currentData.current.ledIndicators,
        },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_device/set/refSource'({ payloads }) {
    try {
      const { sn, refSource, inputFreq } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.current.ledIndicators.ref = refSource // -1: 非鎖定(紅 - 恆亮),  0: internal(綠 - 恆亮), 1: external (黃 - 恆亮)

      currentData.settings.device.refSource = refSource // -1: 非鎖定(紅 - 恆亮),  0: internal(綠 - 恆亮), 1: external (黃 - 恆亮)

      if (refSource === 1) {
        currentData.settings.device.external.currentSelection = inputFreq
      }

      this._updateSingleDeviceData(sn, currentData)
      return {
        [sn]: {
          refSource,
          inputFreq,
          ledIndicators: currentData.current.ledIndicators,
        },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_device/external/set/input/freq'({ payloads }) {
    try {
      const { sn, inputFreq } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device.external.currentSelection = inputFreq

      this._updateSingleDeviceData(sn, currentData)

      return {
        [sn]: { inputFreq, ledIndicators: currentData.current.ledIndicators },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_device/internal/set/output/freq'({ payloads }) {
    try {
      const { sn, isEnable, outputFreq } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device.internal.isEnable = isEnable
      currentData.settings.device.internal.currentSelection = outputFreq

      this._updateSingleDeviceData(sn, currentData)

      return {
        [sn]: {
          isEnable,
          outputFreq,
          ledIndicators: currentData.current.ledIndicators,
        },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     UDBox 0630     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  's_udbox0630/set/refSource'({ payloads }) {
    try {
      const { sn, refSource, inputRefSource } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device.refSource = refSource

      let ledIndicators = currentData.current.ledIndicators

      if (refSource === 1) {
        ledIndicators.ref = 1
        currentData.settings.device.inputRefSource.currentSelection =
          inputRefSource
      }
      if (refSource === 0) ledIndicators.ref = 0

      if (inputRefSource === 'LO') ledIndicators.LO = 1
      if (inputRefSource !== 'LO') ledIndicators.LO = 0

      return { [sn]: { refSource, inputRefSource, ledIndicators } }
    } catch (error) {
      console.warn(error)
    }
  },
  's_udbox0630/output/refSource'({ payloads }) {
    try {
      const { sn, isEnable, currentSelection } = payloads

      const currentData = this._getCurrentData(sn)

      currentData.settings.device.outputRefSource.isEnable = isEnable

      if (currentSelection)
        currentData.settings.device.outputRefSource.currentSelection =
          currentSelection

      return { [sn]: { isEnable, currentSelection } }
    } catch (error) {
      console.warn(error)
    }
  },
  's_udbox0630/output/LO/frequency'({ payloads }) {
    try {
      const { sn, isOutputLoEnable } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device.isOutputLoEnable = isOutputLoEnable

      return { [sn]: { isOutputLoEnable } }
    } catch (error) {
      console.warn(error)
    }
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------         SG         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  's_sg/set/refSource'({ payloads }) {
    try {
      const { sn, refSource, inputRefSource } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device.refSource = refSource
      currentData.settings.device.inputRefSource.currentSelection =
        inputRefSource.currentSelection

      const ledIndicators = currentData.current.ledIndicators
      return { [sn]: { refSource, inputRefSource, ledIndicators } }
    } catch (error) {
      console.warn(error)
    }
  },
  's_sg/output/refSource'({ payloads }) {
    try {
      const { sn, outputRefSource } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.settings.device.outputRefSource = outputRefSource
      currentData.current.ledIndicators.output10M = outputRefSource

      return {
        [sn]: {
          outputRefSource,
          ledIndicators: currentData.current.ledIndicators,
        },
      }
    } catch (error) {
      console.warn(error)
    }
  },
}

export default freqConverterClass
