import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function LO({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.2266 15.916V17.9727H3.78418V15.916H10.2266ZM4.63672 5.17578V17.9727H2V5.17578H4.63672Z'
          fill='#C2C2C2'
        />
        <path
          d='M21.6699 11.2754V11.8818C21.6699 12.8545 21.5381 13.7275 21.2744 14.501C21.0107 15.2744 20.6387 15.9336 20.1582 16.4785C19.6777 17.0176 19.1035 17.4307 18.4355 17.7178C17.7734 18.0049 17.0381 18.1484 16.2295 18.1484C15.4268 18.1484 14.6914 18.0049 14.0234 17.7178C13.3613 17.4307 12.7871 17.0176 12.3008 16.4785C11.8145 15.9336 11.4365 15.2744 11.167 14.501C10.9033 13.7275 10.7715 12.8545 10.7715 11.8818V11.2754C10.7715 10.2969 10.9033 9.42383 11.167 8.65625C11.4307 7.88281 11.8027 7.22363 12.2832 6.67871C12.7695 6.13379 13.3438 5.71777 14.0059 5.43066C14.6738 5.14355 15.4092 5 16.2119 5C17.0205 5 17.7559 5.14355 18.418 5.43066C19.0859 5.71777 19.6602 6.13379 20.1406 6.67871C20.627 7.22363 21.002 7.88281 21.2656 8.65625C21.5352 9.42383 21.6699 10.2969 21.6699 11.2754ZM19.0068 11.8818V11.2578C19.0068 10.5781 18.9453 9.98047 18.8223 9.46484C18.6992 8.94922 18.5176 8.51562 18.2773 8.16406C18.0371 7.8125 17.7441 7.54883 17.3984 7.37305C17.0527 7.19141 16.6572 7.10059 16.2119 7.10059C15.7666 7.10059 15.3711 7.19141 15.0254 7.37305C14.6855 7.54883 14.3955 7.8125 14.1553 8.16406C13.9209 8.51562 13.7422 8.94922 13.6191 9.46484C13.4961 9.98047 13.4346 10.5781 13.4346 11.2578V11.8818C13.4346 12.5557 13.4961 13.1533 13.6191 13.6748C13.7422 14.1904 13.9238 14.627 14.1641 14.9844C14.4043 15.3359 14.6973 15.6025 15.043 15.7842C15.3887 15.9658 15.7842 16.0566 16.2295 16.0566C16.6748 16.0566 17.0703 15.9658 17.416 15.7842C17.7617 15.6025 18.0518 15.3359 18.2861 14.9844C18.5205 14.627 18.6992 14.1904 18.8223 13.6748C18.9453 13.1533 19.0068 12.5557 19.0068 11.8818Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
