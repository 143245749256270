import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'
import { modalActions } from 'src/redux/slices/modal'

const FailedToChangeCaliConfig = props => {
  const dispatch = useDispatch()

  const handleOKClick = () => dispatch(modalActions.clearNormalModal())

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Failed to change calibration config</Modal.Title>

      <Modal.Description>Please try again later.</Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton type='general' size='lg' onClick={handleOKClick}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default FailedToChangeCaliConfig
