import React from 'react'
import { useDispatch } from 'react-redux'

import TMXLabKitLogo from 'src/assets/svg/TMXLabKitLogo'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { redirectToChooseMode_watcher } from 'src/redux/actions/facility/init'

const HeadLogo = props => {
  const dispatch = useDispatch()
  const { isSM } = useGetScreenSize()
  const { isDemoMode } = useGetCurrentMode()

  const handleClick = () => dispatch(redirectToChooseMode_watcher())

  const hoverColor = `group-hover:fill-teal group-hover:text-teal duration-500`
  const logoClass = ` ${hoverColor} fill-light-4 `

  return (
    <div className='cursor-pointer group' onClick={handleClick}>
      {!isDemoMode && <TMXLabKitLogo className={logoClass} />}

      {isDemoMode && !isSM && (
        <div className='flex items-center gap-x-4'>
          <TMXLabKitLogo className={logoClass} />
          <div
            className={`${hoverColor} h-[22px] w-[1px] bg-light-4 group-hover:bg-teal sm:hidden md:block`}
          />
          <h2 className={`${hoverColor} text-light-4  text-2xl font-bold`}>
            DEMO MODE
          </h2>
        </div>
      )}

      {isDemoMode && isSM && (
        <div className='flex flex-col items-center'>
          <TMXLabKitLogo className={`${logoClass} h-[10px]`} />
          <h2 className={`${hoverColor} text-light-4 text-2xl font-bold`}>
            DEMO MODE
          </h2>
        </div>
      )}
    </div>
  )
}

HeadLogo.propTypes = {}

export default HeadLogo
