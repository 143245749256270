import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ErrorBoundaryOfDevice from 'src/containers/ErrorBoundary/Device'

import DeviceInitializing from 'src/containers/operating/DeviceInitializing'
import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'
import NoCalibrationTable from 'src/containers/operating/NoCalibrationTable'
import NotSupportScreenSize from 'src/containers/operating/NotSupportScreenSize'

import Common from 'src/containers/operating/device/beamformers/cloverCell/array1024/Common'
import Main from 'src/containers/operating/device/beamformers/cloverCell/array1024/Main'

import { beamformers_initDevice_watcher } from 'src/redux/actions/beamformers/generalAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'

const CloverCellArray1024 = () => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()

  const { current } = useGetCurrentDeviceData()

  const sn = current.sn
  const info = current.data?.info

  useEffect(() => {
    if (info?.initialization === 'unfinished')
      dispatch(beamformers_initDevice_watcher({ sn }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  if (info?.initialization === 'unfinished') return <DeviceInitializing />

  if (info?.initialization === 'failed')
    return (
      <DeviceSomethingWereWrong
        handleRetry={() => dispatch(beamformers_initDevice_watcher({ sn }))}
      />
    )

  if (info?.initialization === 'no-table') return <NoCalibrationTable sn={sn} />

  if (isXL || is2XL)
    return (
      <div className={lgWidthBox}>
        <Common />
        <Main />
      </div>
    )

  return <NotSupportScreenSize supportWidthSize='1280' />
}

const CloverCellArray1024WithErrorBoundary = () => {
  const { current } = useGetCurrentDeviceData()
  const deviceType = current.deviceType
  const helmet = useGetHelmet({ deviceType })

  return (
    // TODO: retry 補 call re-init api
    <ErrorBoundaryOfDevice
      handleRetry={() => console.log('Error Boundary Retry Click')}>
      {helmet}
      <CloverCellArray1024 />
    </ErrorBoundaryOfDevice>
  )
}
export default CloverCellArray1024WithErrorBoundary

const lgWidthBox = `max-w-[632px] w-full flex flex-col gap-y-6 xl:gap-y-4`
