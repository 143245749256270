import React from 'react'
import PropTypes from 'prop-types'
import ReactSlider from 'react-slider'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <CommonSlider
//  value={} // int
//  min={} // int
//  max={} // int
//  step={} // int
//  disabled={} // bool
//  onChange={()=>{}} // func
//  onAfterChange={()=>{}} // func
// />

const CommonSlider = ({
  value,
  min,
  max,
  step = 1,
  disabled = false,
  invert = false,
  onChange,
  onAfterChange,

  className = '',
  type = 'primary',
  size = 'full',
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                        ${className} 
                        ${inputSize[size]} 
                        ${cursor(disabled)} 
                        group 
                        flex items-center 
                        border-0 select-none
                        `

  const thumbClass = `
                      h-3 w-3 
                      ${thumbTheme(disabled)}
                      shadow-sm shadow-black/20
                      rounded-full 
                      outline-0 
                      `
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <ReactSlider
      className={containerClass} // 外層
      thumbClassName={thumbClass} // 點擊圓球
      value={value || 0}
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      invert={invert}
      renderTrack={(props, state) => (
        <Track {...props} {...state} {...{ type, disabled }} />
      )}
      onChange={onChange}
      onAfterChange={onAfterChange}
    />
  )
}
export default CommonSlider

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static Func     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const inputSize = {
  full: 'w-full h-3',
}

const thumbTheme = disabled =>
  disabled
    ? 'bg-dark-5'
    : 'bg-light-4 group-hover:bg-light-1 group-active:bg-light-1'

const trackTheme = {
  0: {
    primary: 'bg-white/50 group-hover:bg-white group-active:bg-white',
    disabled: 'bg-dark-5',
  },
  1: {
    primary: 'bg-dark-4',
    disabled: 'bg-dark-4',
  },
}

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const Track = props => {
  const themeString = props.disabled ? 'disabled' : props.type

  const trackClass = `
                        ${trackTheme[props.index][themeString]} 
                        h-[3px] rounded-sm
                        `

  return <div {...props} className={trackClass} />
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonSlider.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['full']),
  type: PropTypes.oneOf(['primary']),

  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
