import { all, fork, take } from 'redux-saga/effects'

// action

import {
  pd_general_initDevice_watcher,
  pd_freqChange_watcher,
  pd_readMethodChange_watcher,
  pd_updateRateChange_watcher,
  pd_readClick_watcher,
  pd_averageChange_watcher,
  pd_caliSelect_watcher,
  pd_configCustomClick_watcher,
  pd_clearPowerHistoryModal_clearClick_watcher,
  pd_exportPowerHistoryClick_watcher,
} from 'src/redux/actions/powerDetector'

// handlers
import { init as handleInit } from 'src/redux/sagas/handlers/event/powerDetector/init'
import {
  freqChange as handleFreqChange,
  readMethodChange as handleReadMethodChange,
  updateRateChange as handleUpdateRateChange,
  readClick as handleReadClick,
  averageChange as handleAverageChange,
  caliSelect as handleCaliSelect,
} from 'src/redux/sagas/handlers/event/powerDetector/actionPanel'
import {
  configCustomClick as handleConfigCustomClick,
  customCaliCreate as handleCustomCaliCreate,
  customCaliEdit as handleCustomCaliEdit,
  customCaliGetVoltage as handleCustomCaliGetVoltage,
  customCaliLeaveEdit as handleCustomCaliLeaveEdit,
  customCaliDelete as handleCustomCaliDelete,
  customCaliSave as handleCustomCaliSave,
} from 'src/redux/sagas/handlers/event/powerDetector/customCali'
import {
  powerHistoryClear as handlePowerHistoryClear,
  powerHistoryExport as handlePowerHistoryExport,
} from 'src/redux/sagas/handlers/event/powerDetector/dashboard'

import {
  pd_customCaliModal_createClick_watcher,
  pd_customCaliModal_editClick_watcher,
  pd_customCaliModal_getVoltClick_watcher,
  pd_customCaliModal_leaveEditClick_watcher,
  pd_customCaliModal_deleteClick_watcher,
  pd_customCaliModal_saveClick_watcher,
} from 'src/redux/actions/powerDetector'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       General      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* initPowerDetector() {
  while (true) {
    const action = yield take(pd_general_initDevice_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] initPowerDetector', action.payload)
    yield fork(handleInit, action.payload)
  }
}
function* freqChange() {
  while (true) {
    const action = yield take(pd_freqChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqChange', action.payload)
    yield fork(handleFreqChange, action.payload)
  }
}
function* readMethodChange() {
  while (true) {
    const action = yield take(pd_readMethodChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] readMethodChange', action.payload)
    yield fork(handleReadMethodChange, action.payload)
  }
}
function* updateRateChange() {
  while (true) {
    const action = yield take(pd_updateRateChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] updateRateChange', action.payload)
    yield fork(handleUpdateRateChange, action.payload)
  }
}
function* readClick() {
  while (true) {
    const action = yield take(pd_readClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] readClick', action.payload)
    yield fork(handleReadClick, action.payload)
  }
}
function* averageChange() {
  while (true) {
    const action = yield take(pd_averageChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] averageChange', action.payload)
    yield fork(handleAverageChange, action.payload)
  }
}
function* caliSelect() {
  while (true) {
    const action = yield take(pd_caliSelect_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] caliSelect', action.payload)
    yield fork(handleCaliSelect, action.payload)
  }
}
function* configCustomClick() {
  while (true) {
    const action = yield take(pd_configCustomClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] configCustomClick', action.payload)
    yield fork(handleConfigCustomClick, action.payload)
  }
}

// custom calibration
function* customCaliCreate() {
  while (true) {
    const action = yield take(pd_customCaliModal_createClick_watcher.type)
    devLog('[watcher] customCaliCreate', action.payload)
    yield fork(handleCustomCaliCreate, action.payload)
  }
}
function* customCaliEdit() {
  while (true) {
    const action = yield take(pd_customCaliModal_editClick_watcher.type)
    devLog('[watcher] customCaliCreate', action.payload)
    yield fork(handleCustomCaliEdit, action.payload)
  }
}
function* customCaliGetVoltage() {
  while (true) {
    const action = yield take(pd_customCaliModal_getVoltClick_watcher.type)
    devLog('[watcher] customCaliGetVoltage', action.payload)
    yield fork(handleCustomCaliGetVoltage, action.payload)
  }
}
function* customCaliLeaveEdit() {
  while (true) {
    const action = yield take(pd_customCaliModal_leaveEditClick_watcher.type)
    devLog('[watcher] customCaliLeaveEdit', action.payload)
    yield fork(handleCustomCaliLeaveEdit, action.payload)
  }
}
function* customCaliDelete() {
  while (true) {
    const action = yield take(pd_customCaliModal_deleteClick_watcher.type)
    devLog('[watcher] customCaliDelete', action.payload)
    yield fork(handleCustomCaliDelete, action.payload)
  }
}
function* customCaliSave() {
  while (true) {
    const action = yield take(pd_customCaliModal_saveClick_watcher.type)
    devLog('[watcher] customCaliSave', action.payload)
    yield fork(handleCustomCaliSave, action.payload)
  }
}

function* powerHistoryClear() {
  while (true) {
    const action = yield take(pd_clearPowerHistoryModal_clearClick_watcher.type)
    devLog('[watcher] customCaliExport', action.payload)
    yield fork(handlePowerHistoryClear, action.payload)
  }
}
function* powerHistoryExport() {
  while (true) {
    const action = yield take(pd_exportPowerHistoryClick_watcher.type)
    devLog('[watcher] customCaliExport', action.payload)
    yield fork(handlePowerHistoryExport, action.payload)
  }
}

export default function* powerDetectorWatchers() {
  yield all([
    initPowerDetector(),
    freqChange(),
    readMethodChange(),
    updateRateChange(),
    readClick(),
    averageChange(),
    caliSelect(),
    configCustomClick(),

    customCaliCreate(),
    customCaliEdit(),
    customCaliGetVoltage(),
    customCaliLeaveEdit(),
    customCaliDelete(),
    customCaliSave(),

    powerHistoryClear(),
    powerHistoryExport(),
  ])
}
