import moment from 'moment'
import { isDeveloping } from 'src/funcs/getEnv'

export const tryCatch = func => {
  try {
    const result = func()
    return result
  } catch (error) {
    if (isDeveloping)
      console.error(
        `%c Error from try-catch: ${moment().format('hh:mm:ss')} : `,
        'color: red',
        error
      )
    return new Error({ message: error })
  }
}

export const IsTrue = value =>
  tryCatch(() => {
    if (typeof value === 'string') if (value && value !== 'false') return true

    if (typeof value === 'number')
      if (+value !== 0 && !isNaN(+value)) return true

    if (typeof value === 'boolean') return value

    return false
  })

//* 避免被非數字的值弄爆
export const safeNumber = value => {
  if (isNaN(+value))
    console.warn('Oops! safeNumber got an error, value is invalid: ', value)

  return isNaN(+value) ? 0 : +value
}

export const appendComma = value =>
  tryCatch(() => {
    if (value) {
      var parts = value.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    }

    return value
  })

export const isType = tag => value => {
  //* key 設計參考 react propTypes
  const typeList = {
    number: '[object Number]',
    string: '[object String]',
    bool: '[object Boolean]',
    array: '[object Array]',
    object: '[object Object]',
    func: '[object Function]',
    null: '[object Null]',
    undefined: '[object Undefined]',
    date: '[object Date]',
    reg: '[object RegExp]',
  }

  if (tag === 'NaN') return isNaN(value)

  // NaN 掃出來也會等於 number 這邊另外處理
  if (tag === 'number')
    return (
      !isNaN(value) &&
      Object.prototype.toString.call(value) === typeList['number']
    )

  return Object.prototype.toString.call(value) === typeList[tag]
}

export const devLog = (message1, message2) => {
  let randomColor = Math.floor(Math.random() * 16777215).toString(16)

  if (isDeveloping)
    console.log(
      '%c༼ つ◕_◕ ༽つ ',
      `color: #${randomColor}`,
      message1,
      message2 || ''
    )
}

export const devWarLog = (message1, message2) => {
  let randomColor = Math.floor(Math.random() * 16777215).toString(16)

  if (isDeveloping)
    console.warn(
      '%c(ノ▼Д▼)ノ ',
      `color: #${randomColor}`,
      message1,
      message2 || ''
    )
}
export const getOS = () => {
  var u = navigator.userAgent
  if (!!u.match(/compatible/i) || u.match(/Windows/i)) return 'windows'

  if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) return 'macOS'

  if (!!u.match(/iphone/i) || u.match(/Ipad/i)) return 'ios'

  if (!!u.match(/android/i)) return 'android'

  if (!!u.match(/linux/i)) return 'linux'

  return 'other'
}
