import React from 'react'

import PortalAndOverlay from 'src/components/Popup/Modal/PortalAndOverlay'

import ListMode from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode'
import EditMode from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode'
import ListModeDeleteCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/ListModeDeleteCheck'
import EditModeDeleteCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/EditModeDeleteCheck'
import LeaveEditCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/LeaveEditCheck'
import UsingNotDeleteCheck from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ChildModal/UsingNotDeleteCheck'

const CustomAntenna = modalProps => {
  const { editTemporaryData, childModalType } = modalProps

  const hasTemporaryData = Object.keys(editTemporaryData).length !== 0

  return (
    <PortalAndOverlay
      open
      render={close => (
        <div className={modalClass}>
          {!hasTemporaryData ? (
            <ListMode {...modalProps} />
          ) : (
            <EditMode {...modalProps} />
          )}

          {childModalType === 'listModeDeleteCheck' && <ListModeDeleteCheck />}
          {childModalType === 'editModeDeleteCheck' && (
            <EditModeDeleteCheck {...modalProps} />
          )}
          {childModalType === 'leaveEditCheck' && <LeaveEditCheck />}
          {childModalType === 'usingNotDeleteCheck' && <UsingNotDeleteCheck />}
        </div>
      )}
    />
  )
}

export default CustomAntenna

const modalClass = `relative 
                    w-full max-w-[800px] 
                    bg-dark-2 
                    flex flex-col 
                    rounded-[8px]   
                    `
