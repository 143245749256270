import React from 'react'
import { useDispatch } from 'react-redux'

import InputGroup from 'src/components/Input/InputGroup'
import CommonButton from 'src/components/Button/CommonButton'

import { h4, h6 } from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { bboard_RfIcTemperature_updateClick_watcher } from 'src/redux/actions/beamformers/bboardAction'

import { RiTempHotLine } from 'react-icons/ri'

const ReadTemperature = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const { rfMode } = current.data.deviceControl.common
  const { currentValue } = current.data?.deviceControl.step[rfMode].adc

  const handleUpdateClick = () =>
    dispatch(bboard_RfIcTemperature_updateClick_watcher({ sn }))

  const inputValue = currentValue ? String(currentValue) : ''

  return (
    <div className={container}>
      <h4 className={h4}>RF IC Temperature</h4>
      <h6 className={h6 + ' mt-4 mb-1'}>ADC Value</h6>
      <div className='flex'>
        <InputGroup
          icon={<RiTempHotLine className='text-white/50' />}
          className='w-[128px] mr-4 '
          value={inputValue}
          inputType='text'
          size='md'
          onChange={() => {}}
          disabled
          readOnly
        />

        <CommonButton
          className='w-[128px]'
          type='general'
          size='md'
          onClick={handleUpdateClick}>
          Update
        </CommonButton>
      </div>
    </div>
  )
}

export default ReadTemperature

const container = `w-full py-4 px-3 flex flex-col`
