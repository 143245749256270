import React from 'react'

import Board from 'src/assets/svg/device/avatar/CloverCellEvb/Board'
import Array from 'src/assets/svg/device/avatar/CloverCellEvb/Array'

const CloverCellEvb = props => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `relative w-full h-full overflow-hidden `
  const svgCommon = `absolute 
                    inset-x-0 mx-auto 
                    duration-700 ease-out
                    `

  const board = `
                ${svgCommon} 
                bottom-4 scale-[1] group-hover:scale-[1.15] 
                `

  const array = `
                    ${svgCommon} 
                    bottom-4 scale-[1] group-hover:scale-[1.15] 
                    group-hover:animate-breathBrightness
                    `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <Board className={board} />
      <Array className={array} />
    </div>
  )
}
export default CloverCellEvb

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CloverCellEvb.propTypes = {}
