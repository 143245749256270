import React from 'react'
import { useDispatch } from 'react-redux'

import {
  box,
  subtitle,
} from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/style'

import CommonSwitch from 'src/components/Switch/CommonSwitch'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import {
  ud0630_deviceSettings_outputSignalSource_enable_change_watcher,
  ud0630_deviceSettings_outputSignalSource_selection_change_watcher,
} from 'src/redux/actions/freqConverter/udboxAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetUIControl from 'src/hooks/useGetUIControl'

const OutputSignalSource = () => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const { refSource, outputRefSource } = current.data.settings.device
  const { isEnable, lstOptions, currentSelection } = outputRefSource

  const isExternal = +refSource === 1

  const { single: s_uiControl } = useGetUIControl(sn)
  const isEnableLoading =
    s_uiControl?.ud0630?.outputSignalSourceEnable?.isRequesting

  const handleEnableChange = () =>
    dispatch(
      ud0630_deviceSettings_outputSignalSource_enable_change_watcher({
        sn,
        value: !isEnable,
      })
    )
  const handleSelectionChange = (event, value) =>
    dispatch(
      ud0630_deviceSettings_outputSignalSource_selection_change_watcher({
        sn,
        value,
      })
    )

  //! 請 tina 幫我看 切回 LO 後的 device 燈號
  //! 是不是維持在上一動的狀態，
  //! 如果 GUI 跟 燈號狀態不一致，就開 bug 給我
  if (isExternal) return <></>

  return (
    <div className={box}>
      <div className='flex justify-between'>
        <h4 className={subtitle}>Output Reference Source</h4>

        <CommonSwitch
          type='text'
          onText='ON'
          offText='OFF'
          on={isEnable}
          loading={isEnableLoading}
          onClick={handleEnableChange}
        />
      </div>

      {isEnable && (
        <SegmentedButton
          size={isSM || isMD ? 'md' : 'sm'}
          type='outlined'
          value={currentSelection}
          onChange={handleSelectionChange}>
          {lstOptions.map((e, i) => (
            <SegmentedButton.Option
              key={`OutputSignalSource ${e} ${i}`}
              name={e}>
              {e}
            </SegmentedButton.Option>
          ))}
        </SegmentedButton>
      )}
    </div>
  )
}
export default OutputSignalSource
