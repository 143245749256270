import Decimal from 'decimal.js'
import { call, put } from 'redux-saga/effects'

import {
  getCurrentData,
  getCurrentRfMode,
} from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

function* _setCurrentControlModeToChannel({ sn }) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)
  currentData.deviceControl.common.currentControlMode[currentRfMode] = 'channel'

  yield put(setSingleDeviceData({ sn, data: currentData }))
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     All Power      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelAllPower(payloads) {
  try {
    const { sn, value } = payloads
    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    currentData.deviceControl.channel[currentRfMode].lstBoardData =
      currentData.deviceControl.channel[currentRfMode].lstBoardData.map(
        boardData => ({
          ...boardData,
          lstChannelData: boardData.lstChannelData.map(channelData => ({
            ...channelData,
            power: value,
          })),
        })
      )

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(_setCurrentControlModeToChannel, { sn })

    yield call(__socket_API_sender, beamformersApi.CHANNEL_POWER_ALL, {
      sn,
      value,
      lookupID,
    })
  } catch (error) {
    devWarLog('[handler] _setCurrentControlModeToChannel error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Power     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelSinglePower(payloads) {
  try {
    const { sn, boardIndex, channelIndex, value } = payloads
    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    currentData.deviceControl.channel[currentRfMode].lstBoardData[
      boardIndex
    ].lstChannelData[channelIndex].power = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(_setCurrentControlModeToChannel, { sn })

    yield call(__socket_API_sender, beamformersApi.CHANNEL_POWER, {
      sn,
      boardIndex,
      channelIndex,
      value,
      lookupID,
    })
  } catch (error) {
    devWarLog('[handler] channelSinglePower error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Common Gain     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _commonGainUpdate({ sn, boardIndex, value: newCommonGain, isValid }) {
  try {
    let currentData = yield call(getCurrentData, sn)
    const currentRfMode = yield call(getCurrentRfMode, sn)

    const boardData =
      currentData.deviceControl.channel[currentRfMode].lstBoardData[boardIndex]

    const lstElementGains = boardData.lstChannelData.map(
      chData => chData.elementGain
    )

    if (isValid)
      boardData.lstChannelData = lstElementGains.map((elementGain, index) => ({
        ...boardData.lstChannelData[index],
        totalGain: +elementGain + +newCommonGain,
      }))

    //! 這裡不能轉數字, 小數點會掛掉
    boardData.commonCurrentGain = newCommonGain

    yield put(setSingleDeviceData({ sn, data: currentData }))

    return { lstElementGains }
  } catch (error) {
    devWarLog('[handler] _commonGainUpdate error:', error)
  }
}

export function* channelCommonGainSliderChange(payloads) {
  try {
    yield call(_commonGainUpdate, payloads)
  } catch (error) {
    devWarLog('[handler] channelCommonGainSliderChange error:', error)
  }
}

export function* channelCommonGainChange(payloads) {
  try {
    const { sn, boardIndex, value, isValid } = payloads

    const { lstElementGains } = yield call(_commonGainUpdate, payloads)

    if (isValid) {
      yield call(_setCurrentControlModeToChannel, { sn })

      let { lookupID } = yield call(getCommonArgs, sn)

      yield call(__socket_API_sender, beamformersApi.CHANNEL_COMMON_GAIN, {
        sn,
        lookupID,
        boardIndex,
        lstElementGains,
        commonGain: +value,
      })
    }
  } catch (error) {
    devWarLog('[handler] channelCommonGainAfterChange error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Single Gain     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* _channelSingleGainUpdate({
  sn,
  boardIndex,
  channelIndex,
  value: totalGain,
}) {
  try {
    let currentData = yield call(getCurrentData, sn)
    const currentRfMode = yield call(getCurrentRfMode, sn)

    let boardData =
      currentData.deviceControl.channel[currentRfMode].lstBoardData[boardIndex]

    //! 這裡不能轉數字, 小數點會掛掉
    boardData.lstChannelData[channelIndex].totalGain = totalGain

    const commonGain = boardData.commonCurrentGain
    const newElementGain = new Decimal(+totalGain).minus(+commonGain).toNumber()

    boardData.lstChannelData[channelIndex].elementGain = newElementGain

    yield put(setSingleDeviceData({ sn, data: currentData }))

    return newElementGain
  } catch (error) {
    devWarLog('[handler] _channelSingleGainUpdate error:', error)
  }
}

export function* channelSingleGain(payloads) {
  try {
    const { sn, boardIndex, channelIndex, isValid } = payloads

    const feElementGain = yield call(_channelSingleGainUpdate, payloads)

    if (isValid) {
      yield call(_setCurrentControlModeToChannel, { sn })

      let { lookupID } = yield call(getCommonArgs, sn)

      //! 注意： event 傳來的是 total gain (因為 input 是顯示且操作 totalGain)
      //! 注意： 這邊 BE 要的是 elementGain

      yield call(__socket_API_sender, beamformersApi.CHANNEL_GAIN, {
        sn,
        lookupID,
        boardIndex,
        channelIndex,
        value: +feElementGain,
      })
    }
  } catch (error) {
    devWarLog('[handler] channelSingleGain error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Phase     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

function* _channelSinglePhaseUpdate({ sn, boardIndex, channelIndex, value }) {
  try {
    let currentData = yield call(getCurrentData, sn)
    const currentRfMode = yield call(getCurrentRfMode, sn)

    currentData.deviceControl.channel[currentRfMode].lstBoardData[
      boardIndex
    ].lstChannelData[channelIndex].phase = value

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] _channelSinglePhaseUpdate error:', error)
  }
}

export function* channelSinglePhase(payloads) {
  try {
    const { sn, boardIndex, channelIndex, value, isValid } = payloads

    yield call(_channelSinglePhaseUpdate, payloads)

    if (isValid) {
      yield call(_setCurrentControlModeToChannel, { sn })

      let { lookupID } = yield call(getCommonArgs, sn)
      yield call(__socket_API_sender, beamformersApi.CHANNEL_PHASE, {
        sn,
        lookupID,
        boardIndex,
        channelIndex,
        value: +value,
      })
    }
  } catch (error) {
    devWarLog('[handler] channelSinglePhase error:', error)
  }
}
