import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'react-router-dom'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { VscTools } from 'react-icons/vsc'

import { modalActions } from 'src/redux/slices/modal'

import { downloadNewMidModal_backDoorClick_Watcher } from 'src/redux/actions/facility/init'

const DownloadNewMiddleware = props => {
  const dispatch = useDispatch()

  const mid_midVersion = useSelector(
    state =>
      state.versions.midVersion.find(e => e.key === 'BE-TLK-MIDDLEWARE')?.mid
  )
  const fe_midVersion = useSelector(
    state =>
      state.versions.serviceVersion.find(e => e.key === 'BE-TLK-MIDDLEWARE')?.fe
  )

  const backDoorTimeoutId = useRef(null)

  const handleVersionBackDoorStart = () => {
    backDoorTimeoutId.current = setTimeout(() => {
      dispatch(downloadNewMidModal_backDoorClick_Watcher())
    }, 3000)
  }
  const handleVersionBackDoorClear = () => {
    clearTimeout(backDoorTimeoutId?.current)
    backDoorTimeoutId.current = null
  }

  const handleButtonClick = () => {
    dispatch(modalActions.clearNormalModal())
    redirect('/download/middleware')
  }

  return (
    <Modal>
      <Modal.Icon>
        <VscTools className='text-yellow' />
      </Modal.Icon>

      <Modal.Title>
        <span
          onPointerDown={handleVersionBackDoorStart}
          onPointerUp={handleVersionBackDoorClear}>
          Please
        </span>{' '}
        Update your WEB-TLK Middleware
      </Modal.Title>

      <Modal.Description>
        <span className='text-yellow'>Latest version: {fe_midVersion}</span>
        <br />
        Installed version: {mid_midVersion}
        <br />
        Upgrade now for improved features and bug fixes to enhance your user
        experience.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton size='lg' type='general' onClick={handleButtonClick}>
          Download Latest Middleware
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default DownloadNewMiddleware
