import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { RotatingLines } from 'react-loader-spinner'
import { Link } from 'react-router-dom'

import { BiCheck } from 'react-icons/bi'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Button
//   {...{
//     icon, // node
//     type, // 'primary', 'general', 'outlined', 'text'
//     size,  // sm, md, lg
//     disabled, // bool
//     danger, // bool
//     loading, // bool
//     success, // bool
//     tabIndex, // -1, 0, 1
//     buttonType = 'button'
//     style = {}
//     href = '' // string
//   }}
//  >
//   content
// </Button>

const CommonButton = forwardRef((props, ref) => {
  const {
    size = 'md',
    type = 'primary',
    danger = false,
    disabled = false,
    href = '',
    loading = false,
    success = false,
    autoFocus = false,
    tabIndex = 0,
    className = '',
    buttonType = 'button',
    style = {},
    onClick = () => {},
    onPointerDown = () => {},
    onPointerUp = () => {},
    icon,
    children,
  } = props

  const handleClick = e => !disabled && !loading && !success && onClick(e)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const btnClass = `
                  ${className} 
                  ${
                    type === 'text' ? textShapeSize?.[size] : shapeSize?.[size]
                  } 
                  ${theme(type, danger, disabled, loading, success)}
                  ${cursor(disabled, loading, success)} 
                  ${common} 
                  `
  const iconBoxClass = `
                        ${iconSize(loading || success || icon)} 
                        flex items-center justify-center
                        `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <LinkContainer href={href} disabled={disabled}>
      <button
        autoFocus={autoFocus}
        type={buttonType}
        className={btnClass}
        aria-label='common-button'
        onClick={handleClick}
        {...{ ref, tabIndex, disabled, onPointerDown, onPointerUp, style }}>
        <div className={iconBoxClass}>
          {!disabled && loading && (
            <Loading color={loadingColor(type, danger)} />
          )}

          {success && <BiCheck className='w-4 text-lg ' />}

          {!loading && !success && icon}
        </div>

        {children}
      </button>
    </LinkContainer>
  )
})

export default CommonButton

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const common = `rounded-[2px] 
                border-1 border-solid 
                flex items-center justify-center 
                font-medium 
                duration-500 
                select-none 
                group
                `

export const shapeSize = {
  sm: 'min-w-[80px] px-4 py-[3px] text-sm leading-4',
  md: 'min-w-[96px] px-4 py-[7px] text-sm leading-4',
  lg: 'min-w-[108px] px-4 py-[9px] text-base leading-5',
}
const textShapeSize = {
  sm: 'text-sm leading-4',
  md: 'text-sm leading-4',
  lg: 'text-base leading-5',
}

export const theme = (type, danger, disabled, loading, success) => {
  const noHover = loading || success

  if (type === 'outlined') {
    if (disabled) return outlinedDisabled
    if (danger)
      return noHover ? outlinedDanger : outlinedDanger + outlinedDangerHover

    return noHover ? outlined : outlined + outlinedHover
  }

  if (type === 'text') {
    if (disabled) return textDisabled
    if (danger) return noHover ? textDanger : textDanger + textDangerHover

    return noHover ? text : text + textHover
  }

  if (type === 'general') {
    if (disabled) return basicDisabled
    if (danger) return noHover ? basicDanger : basicDanger + basicDangerHover

    return noHover ? general : general + generalHover
  }

  if (type === 'primary') {
    if (disabled) return basicDisabled
    if (danger) return noHover ? basicDanger : basicDanger + basicDangerHover

    return noHover ? primary : primary + primaryHover
  }
}

// normal
const primary = `border-transparent bg-teal text-black fill-black`
const general = `border-transparent bg-white text-black fill-black`
const outlined = `border-white bg-transparent text-white fill-white`
const text = `border-transparent bg-transparent text-white fill-white`
const primaryHover = ` hover:bg-teal/60`
const generalHover = ` hover:bg-white/60`
const outlinedHover = ` hover:bg-white/20`
const textHover = ` hover:text-teal hover:fill-teal`

// danger
const basicDanger = `border-transparent bg-red text-white `
const outlinedDanger = 'border-red text-red '
const textDanger = 'border-transparent bg-transparent text-red '
const basicDangerHover = ` hover:opacity-60`
const outlinedDangerHover = ` hover:bg-red/10`
const textDangerHover = ` hover:text-red/60`

// disabled
const basicDisabled =
  'border-transparent bg-dark-4 grayscale-1 text-white/40 fill-white/40'
const outlinedDisabled =
  'border-dark-5 bg-transparent grayscale-1 text-dark-5 fill-dark-5'
const textDisabled =
  'border-transparent bg-transparent grayscale-1 text-dark-5 fill-dark-5'

const cursor = (disabled, loading, success) =>
  disabled || loading || success ? 'cursor-not-allowed' : 'cursor-pointer'

const iconSize = show => (show ? 'w-4 h-4 mr-[6px]' : '')

const loadingColor = (type, danger) => {
  if (type === 'primary') {
    if (danger) return 'white'
    return 'black'
  }

  if (type === 'general') {
    if (danger) return 'white'
    return 'black'
  }

  if (type === 'outlined') {
    if (danger) return 'red'
    return 'white'
  }

  if (type === 'text') {
    if (danger) return 'red'
    return 'white'
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const LinkContainer = props => {
  const { href, disabled, children } = props
  const internalLink = href.indexOf('http') === -1

  if (disabled) return <>{children}</>

  if (href && internalLink)
    return (
      <Link target='_self' to={href}>
        {children}
      </Link>
    )

  if (href && !internalLink)
    return (
      <a href={href} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    )

  return <>{children}</>
}

const Loading = ({ color }) => (
  <RotatingLines
    width='14'
    strokeColor={color}
    strokeWidth='3'
    animationDuration='0.7'
    wrapperStyle={{}}
    visible={true}
  />
)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonButton.propTypes = {
  shape: PropTypes.oneOf(['basic', 'square']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.oneOf(['primary', 'general', 'outlined', 'text']),
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onPointerDown: PropTypes.func,
  onPointerUp: PropTypes.func,
}
