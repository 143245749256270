import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiInfoCircle } from 'react-icons/bi'

import {
  pd_customCaliModal_saveCaliReminderChildModal_continuousClick_watcher,
  pd_customCaliModal_saveCaliReminderChildModal_noClick_watcher,
} from 'src/redux/actions/powerDetector'

const SaveCalibrationConfigReminder = props => {
  const dispatch = useDispatch()

  const handleContinuousClick = () =>
    dispatch(
      pd_customCaliModal_saveCaliReminderChildModal_continuousClick_watcher()
    )

  const handleNoClick = () =>
    dispatch(pd_customCaliModal_saveCaliReminderChildModal_noClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white ' />
      </Modal.Icon>

      <Modal.Title>Saving will clear chart data. Continue?</Modal.Title>

      <Modal.RowButtonBox>
        <CommonButton size='lg' type='general' onClick={handleContinuousClick}>
          Continuous
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleNoClick}>
          No
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default SaveCalibrationConfigReminder
