import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { SelectContext } from './context'

import { BiCheck } from 'react-icons/bi'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Parent.Option
//  name={string}
//  disabled={bool}>
//    content
// </Parent.Option>

const Option = props => {
  const {
    name,
    disabled = false,
    className = '',
    childrenContainerClass,
    children,
  } = props

  const { containerDisabled, currentValue, handleChange } =
    useContext(SelectContext)

  const isActive = currentValue === name
  const isDisabled = containerDisabled || disabled

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = event => {
    if (!isDisabled) handleChange(event, name, children)
    if (isDisabled) event.stopPropagation()
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                      ${className} 
                      ${containerCommon} 
                      ${cursor(isDisabled)}
                      ${containerTextColor(disabled)}
                      `

  const check = `${!isActive && 'opacity-0'} w-4 h-4 mr-3 shrink-0 text-lg`
  const text = `
    ${childrenContainerClass} 
    shrink-1 w-[calc(100%_-_28px)] text-justify break-words duration-500
    `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass} onClick={handleClick}>
      <div className={check}>
        <BiCheck />
      </div>

      <div className={text}>{children}</div>
    </div>
  )
}

export default Option

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//! leading-4 是為了文字超出換行不至於變太高用的
const containerTextColor = disabled =>
  disabled ? 'text-white/30' : 'text-white hover:text-black'
const containerText = `text-sm leading-4 font-normal hover:text-stroke-black hover:text-stroke-1`
const containerCommon = `${containerText} 
                          w-full 
                          py-2 pl-2 
                          flex items-center justify-start 
                          shrink-0 
                          hover:bg-teal 
                          overflow-hidden 
                          `

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Option.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
