import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Box = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='152'
        height='158'
        viewBox='0 0 152 158'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M40.8646 1.22466C41.6957 0.749785 42.6362 0.5 43.5934 0.5H108.407C109.364 0.5 110.304 0.749784 111.135 1.22466L148.483 22.5659C148.928 22.8203 148.747 23.5 148.234 23.5H3.76556C3.25284 23.5 3.07231 22.8203 3.5175 22.5659L40.8646 1.22466Z'
          fill='#3D3D3D'
          stroke='white'
        />
        <path
          d='M0.560337 24.0441C0.535474 23.1988 1.21399 22.5 2.05969 22.5H149.94C150.786 22.5 151.465 23.1988 151.44 24.0441L147.557 156.044C147.533 156.855 146.869 157.5 146.058 157.5H5.94205C5.13079 157.5 4.46654 156.855 4.44269 156.044L0.560337 24.0441Z'
          fill='#121212'
          stroke='white'
        />
        <path
          d='M43.5201 59.015C43.5118 58.1807 44.1858 57.5 45.0201 57.5H106.98C107.814 57.5 108.488 58.1807 108.48 59.015L107.861 121.015C107.853 121.838 107.184 122.5 106.361 122.5H45.6389C44.8164 122.5 44.1472 121.838 44.139 121.015L43.5201 59.015Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M47.5201 63.015C47.5118 62.1807 48.1858 61.5 49.0201 61.5H102.98C103.814 61.5 104.488 62.1807 104.48 63.015L103.941 117.015C103.933 117.838 103.264 118.5 102.441 118.5H49.5591C48.7365 118.5 48.0674 117.838 48.0592 117.015L47.5201 63.015Z'
          fill='#121212'
          stroke='white'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default Box
