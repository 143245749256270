import { put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* init({ response }) {
  try {
    yield console.log('error init:', response)

    // 如果是 no table, be 會把 initialization 改 'no-table'
    // 如果是其他 error, be 會把 initialization 改 'failed'
    // 然後再回傳整台 deviceData
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}
