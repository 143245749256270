import React from 'react'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiCheck } from 'react-icons/bi'

const GeneralErrorMailSended = props => {
  return (
    <Modal>
      <Modal.Icon>
        <BiCheck className='text-white' />
      </Modal.Icon>

      <Modal.Title>We got your report</Modal.Title>

      <Modal.Description>
        Thanks for using the feedback system to help us make our product even
        better.
        <br /> Please click "Refresh" to reload and continue.
      </Modal.Description>

      <form onSubmit={() => window.location.reload()}>
        <Modal.ColumnButtonBox>
          <CommonButton size='lg' type='primary' buttonType='submit'>
            Refresh
          </CommonButton>
        </Modal.ColumnButtonBox>
      </form>
    </Modal>
  )
}

export default GeneralErrorMailSended
