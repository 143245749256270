import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import useMeasure from 'react-use-measure'

import CommonSelect from 'src/components/Select/CommonSelect'

import Option from 'src/components/Select/CommonSelect/Option'
import Divider from 'src/components/Select/CommonSelect/Divider'

import { BiPlus } from 'react-icons/bi'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//  <SelectWithAction
//    value={} // string
//    icon={} // jsx
//    size={} // sm , md
//    selectDisabled={} // bool
//    actionDisabled={} // bool
//    selectClassName='' // string
//    buttonChildren={} // jsx
//    onChange={(e, v) => {}}
//    onClickForAction={() => {}}>
//    <SelectWithAction.Option name='' disabled={}></SelectWithAction.Option>
//    <SelectWithAction.Option name='' disabled={}></SelectWithAction.Option>
//    <SelectWithAction.Option name='' disabled={}></SelectWithAction.Option>
//  </SelectWithAction>

const SelectWithAction = props => {
  const {
    value,
    currentChildren,
    icon,
    size,

    selectDisabled,
    actionDisabled,

    buttonChildren,

    onChange,
    onClickForAction,

    className = '',
    selectClassName = '',
    children,
  } = props

  const [actionButtonRef, actionsButtonBounds] = useMeasure({ scroll: true })
  const [containerRef, containerBounds] = useMeasure({ scroll: true })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `${className} w-full flex items-center justify-start`

  const selectWidth = containerBounds?.width - actionsButtonBounds?.width

  const selectClass = `${selectClassName}`

  const actionButtonClass = ``

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass} ref={containerRef}>
      <div style={{ width: selectWidth + 'px' }}>
        <CommonSelect
          {...{ value, currentChildren, size, icon, onChange }}
          className={selectClass}
          currentClassName={selectCurrentClass}
          disabled={selectDisabled}>
          {children}
        </CommonSelect>
      </div>

      <ActionButton
        ref={actionButtonRef}
        className={actionButtonClass}
        size={size}
        disabled={actionDisabled}
        onClick={onClickForAction}>
        {buttonChildren || <BiPlus />}
      </ActionButton>
    </div>
  )
}

SelectWithAction.Option = Option
SelectWithAction.Divider = Divider

export default SelectWithAction

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const selectCurrentClass = 'rounded-r-none'

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const ActionButton = forwardRef((props, ref) => {
  const { className, size, disabled, onClick, children } = props
  const buttonClass = `
                      ${className} 
                      ${actionSize[size]}
                      ${buttonTheme(disabled)}
                      ${cursor(disabled)}
                      rounded-r-[2px] 
                      px-2 py-1
                      border-[1px] border-solid 
                      border-dark-3 
                      text-sm leading-3 font-normal
                      shrink-0 duration-500
                      `

  const handleClick = () => !disabled && onClick()

  return (
    <button className={buttonClass} ref={ref} onClick={handleClick}>
      {children}
    </button>
  )
})

// ActionButton - css
const buttonTheme = disabled =>
  disabled
    ? 'bg-dark-4 text-white/50'
    : 'bg-dark-1 hover:border-dark-5 active:border-teal text-white'

const actionSize = { sm: 'h-6', md: 'h-8' }

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
SelectWithAction.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  defaultCurrentChildren: PropTypes.node,
  icon: PropTypes.node,
  size: PropTypes.string,
  selectDisabled: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  buttonChildren: PropTypes.node,
  onChange: PropTypes.func,
  onClickForAction: PropTypes.func,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
}
