import React from 'react'
import { h4 } from 'src/containers/operating/device/beamformers/style'
import CommonSwitch from 'src/components/Switch/CommonSwitch'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { useDispatch } from 'react-redux'
import { cloverCellCTTC_steering_powerChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const RowPower = ({ beamIndex }) => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const isBeamSynthesis = current.data?.deviceControl?.steering.isBeamSynthesis
  const controlPattern = current.data?.deviceControl?.steering.controlPattern
  const isOn = beamIndex === null || controlPattern.includes(beamIndex)

  const beamData = isBeamSynthesis
    ? current.data?.deviceControl?.steering.synthesizedBeamData
    : current.data?.deviceControl?.steering.separateBeamsData.find(
        e => e.beamIndex === beamIndex
      )

  const rfMode = beamData?.rfMode
  const h_power = isOn && beamData?.[rfMode]?.horizon.power
  const v_power = isOn && beamData?.[rfMode]?.vertical.power
  const isDisabled = rfMode === 'standby'

  const chunk = `bg-white/10 flex justify-between px-3 py-2 rounded-[4px]`

  const handleChange = ({ hvMode }) =>
    dispatch(
      cloverCellCTTC_steering_powerChange_watcher({ sn, beamIndex, hvMode })
    )

  return (
    <div className='grid grid-cols-2 gap-x-4'>
      <div className={chunk}>
        <h4 className={h4}>H</h4>
        <CommonSwitch
          onText='ON'
          offText='OFF'
          disabled={isDisabled}
          on={h_power}
          onClick={() => handleChange({ hvMode: 'horizon' })}
        />
      </div>
      <div className={chunk}>
        <h4 className={h4}>V</h4>
        <CommonSwitch
          onText='ON'
          offText='OFF'
          disabled={isDisabled}
          on={v_power}
          onClick={() => handleChange({ hvMode: 'vertical' })}
        />
      </div>
    </div>
  )
}

export default RowPower
