import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Antenna = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='78'
        height='64'
        viewBox='0 0 78 64'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.21669 1.26696C4.83729 0.770483 5.60837 0.5 6.40312 0.5H71.5969C72.3916 0.5 73.1627 0.770483 73.7833 1.26696L77.5 4.24031V49.5H0.5V4.24031L4.21669 1.26696Z'
          fill='#323232'
          stroke='white'
        />
        <path
          d='M0.5 5C0.5 4.17157 1.17157 3.5 2 3.5H76C76.8284 3.5 77.5 4.17157 77.5 5V57C77.5 57.8284 76.8284 58.5 76 58.5H2C1.17157 58.5 0.5 57.8284 0.5 57V5Z'
          fill='#554927'
          stroke='white'
        />
        <g filter='url(#filter0_i_769_102914)'>
          <path
            d='M13 18C13 16.8954 13.8954 16 15 16H63C64.1046 16 65 16.8954 65 18V48C65 49.1046 64.1046 50 63 50H15C13.8954 50 13 49.1046 13 48V18Z'
            fill='#F2C94C'
            fillOpacity='0.35'
          />
        </g>
        <path
          d='M13.5 18C13.5 17.1716 14.1716 16.5 15 16.5H63C63.8284 16.5 64.5 17.1716 64.5 18V48C64.5 48.8284 63.8284 49.5 63 49.5H15C14.1716 49.5 13.5 48.8284 13.5 48V18Z'
          stroke='white'
        />
        <rect x='29' y='23' width='4' height='3' rx='1' fill='white' />
        <rect x='40.0283' y='23' width='4' height='3' rx='1' fill='white' />
        <rect
          x='40.0283'
          y='34.0283'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect x='29' y='34.0283' width='4' height='3' rx='1' fill='white' />
        <rect x='34.5142' y='23' width='4' height='3' rx='1' fill='white' />
        <rect x='45.5459' y='23' width='4' height='3' rx='1' fill='white' />
        <rect
          x='45.5459'
          y='34.0283'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect
          x='34.5142'
          y='34.0283'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect x='29' y='28.5142' width='4' height='3' rx='1' fill='white' />
        <rect
          x='40.0283'
          y='28.5142'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect
          x='40.0283'
          y='39.5459'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect x='29' y='39.5459' width='4' height='3' rx='1' fill='white' />
        <rect
          x='34.5142'
          y='28.5142'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect
          x='45.5459'
          y='28.5142'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect
          x='45.5459'
          y='39.5459'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <rect
          x='34.5142'
          y='39.5459'
          width='4'
          height='3'
          rx='1'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.5 54C14.2239 54 14 54.2239 14 54.5V58.5C14 58.7761 14.2239 59 14.5 59H16V63C16 63.5523 16.4477 64 17 64H19C19.5523 64 20 63.5523 20 63V59H21.5C21.7761 59 22 58.7761 22 58.5V54.5C22 54.2239 21.7761 54 21.5 54H14.5Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M28.5 54C28.2239 54 28 54.2239 28 54.5V58.5C28 58.7761 28.2239 59 28.5 59H30V63C30 63.5523 30.4477 64 31 64H33C33.5523 64 34 63.5523 34 63V59H35.5C35.7761 59 36 58.7761 36 58.5V54.5C36 54.2239 35.7761 54 35.5 54H28.5Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M42.5 54C42.2239 54 42 54.2239 42 54.5V58.5C42 58.7761 42.2239 59 42.5 59H44V63C44 63.5523 44.4477 64 45 64H47C47.5523 64 48 63.5523 48 63V59H49.5C49.7761 59 50 58.7761 50 58.5V54.5C50 54.2239 49.7761 54 49.5 54H42.5Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.5 54C56.2239 54 56 54.2239 56 54.5V58.5C56 58.7761 56.2239 59 56.5 59H58V63C58 63.5523 58.4477 64 59 64H61C61.5523 64 62 63.5523 62 63V59H63.5C63.7761 59 64 58.7761 64 58.5V54.5C64 54.2239 63.7761 54 63.5 54H56.5Z'
          fill='white'
        />
        <circle cx='9.5' cy='12.5' r='1.5' fill='white' />
        <circle cx='9.5' cy='51.5' r='1.5' fill='white' />
        <circle cx='68.5' cy='12.5' r='1.5' fill='white' />
        <circle cx='68.5' cy='51.5' r='1.5' fill='white' />
        <defs>
          <filter
            id='filter0_i_769_102914'
            x='13'
            y='16'
            width='52'
            height='34'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='BackgroundImageFix'
              result='shape'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='-4' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
            />
            <feBlend
              mode='normal'
              in2='shape'
              result='effect1_innerShadow_769_102914'
            />
          </filter>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
export default Antenna
