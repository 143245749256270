import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import FocusLock from 'react-focus-lock'
import Decimal from 'decimal.js'

import CommonBlockedDialog from 'src/components/Popup/BlockedDialog/CommonDialog'
import CommonButton from 'src/components/Button/CommonButton'
import InputGroup from 'src/components/Input/InputGroup'

import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'
import { inputAccessibleExceptionList } from 'src/components/Input/func'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <InputGroupAppendDialog
//   className = ''
//   // input params
//   icon={} // jsx
//   unit=''
//   off={}
//   step={}
//   validMin={}
//   validMax={}
//   decimalScale={}
//   value={}
//   emptyValue={}
//   showProgress={}
//   // dialog params
//   dialogText=''
//   placement='right'
//   overwriteOffset={{ top: 0, left: 0 }}
//   overwriteElements={} // jsx
//   // event params
//   setFocus={(result => setFocus(result))}
//   warning={warning}
//   setWarning={result => setWarning(result)}
//   changeCallback={({ value, isValid }) =>{}}
// />

const getMaxValid = (value, max) =>
  new Decimal(value).lessThanOrEqualTo(new Decimal(max))
const getMinValid = (value, min) =>
  new Decimal(value).greaterThanOrEqualTo(new Decimal(min))
const getStepValid = (value, step) =>
  new Decimal(value).modulo(step).equals(new Decimal(0))

const InputGroupAppendDialog = props => {
  // input params
  const {
    className: inputGroupClassName = '',
    off,
    size,
    icon,
    value,
    emptyValue,
    unit,
    step,
    loop,
    disabled,
    inputMin = -99999999,
    inputMax = 99999999,
    keydownMin = -99999999,
    keydownMax = 99999999,
    validMin = -99999999,
    validMax = 99999999,
    decimalScale,
  } = props

  // progress bar params
  const {
    showProgress,
    progressValue,
    progressColor,
    progressMin,
    progressMax,
  } = props

  // dialog params
  const {
    dialogText,
    placement,
    overwriteElements,
    overwriteOffset,
    overlayClassName,
  } = props

  // event params
  const { setFocus = () => {}, warning, setWarning, changeCallback } = props

  const inputRef = useRef()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // 按 ok 後就會去改變 value 變成合法的，這時這個生命週期就會關掉 block dialog
  // 並且 focus 回 input
  useEffect(() => {
    const verifyFailureList = [
      value !== 0 && !getStepValid(value, step),
      !getMinValid(value, validMin),
      !getMaxValid(value, validMax),
    ]

    const pass = verifyFailureList.every(element => element === false)

    if (pass && warning) {
      setWarning('')

      // dialog 有掛 focus lock 搶不贏, 放 timeout 讓元件晚點 focus 回去
      setTimeout(() => {
        inputRef.current.focus()
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleChange = result => {
    const maxValid = getMaxValid(result.formattedValue, validMax)
    const minValid = getMinValid(result.formattedValue, validMin)
    const stepValid = getStepValid(result.formattedValue, step)

    if (!warning)
      changeCallback({
        value: result.formattedValue,
        isValid: maxValid && minValid && stepValid,
      })
  }

  const handleWarningOkClick = useBindErrorBoundary(() => {
    let newValue

    if (isNaN(+value)) newValue = validMin

    if (warning === 'WARNING_MIN') newValue = validMin

    if (warning === 'WARNING_MAX') newValue = validMax

    if (warning === 'WARNING_STEP') {
      // modulo = %
      let remainder = new Decimal(+value).modulo(step).toNumber()

      // div = /
      let halfStep = new Decimal(step).div(2).toNumber()

      if (value >= 0) {
        if (remainder < halfStep)
          newValue = new Decimal(+value).sub(+remainder).toNumber()
        // ex:
        // input: value(4.1), step(0.5)
        // output: 4.1 - 0.1 = 4

        if (remainder >= halfStep)
          newValue = new Decimal(+value).add(+step).sub(+remainder).toNumber()
        // ex:
        // input: value(4.3), step(0.5)
        // output: 4.3 + 0.5 - 0.3 = 4.5
      }
      if (value < 0) {
        if (remainder > -halfStep)
          newValue = new Decimal(+value).sub(+remainder).toNumber()
        // ex:
        // input: value(-4.1), step(0.5)
        // output: -4.1 - -0.1 = -4

        if (remainder <= -halfStep)
          newValue = new Decimal(+value).sub(+step).sub(+remainder).toNumber()
        // ex:
        // input: value(-4.3), step(0.5)
        // output: -4.3 - 0.5 - -0.3 = -4.5
      }
    }

    changeCallback({ value: newValue, isValid: true })
  })

  const validation = () => {
    if (!warning) {
      if (value !== 0 && !getStepValid(value, step)) setWarning('WARNING_STEP')

      //! 例外清單是可以接受被輸入 (因為某些值輸入過程需要)
      //! 但不能作為結果，所以這邊要提示 warning
      if (inputAccessibleExceptionList.includes(String(value)))
        setWarning('WARNING_STEP')

      if (!getMinValid(value, validMin)) setWarning('WARNING_MIN')
      if (!getMaxValid(value, validMax)) setWarning('WARNING_MAX')
    }
  }

  const handleBlur = () => {
    setFocus(false)
    validation()
  }
  const handleKeyDown = e => {
    // dialog 有掛 focus lock, dialog 裡面 button 會吃到這裡的 keyDown = Enter ,
    // 放 timeout 讓 dialog 不會隨著這裡的 Enter 被關閉
    if (e.key === 'Enter')
      setTimeout(() => {
        inputRef.current.blur()
      }, 100)
  }

  const handleFocus = () => setFocus(true)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const inputProps = {
    className: inputGroupClassName,
    size,
    value,
    emptyValue,
    icon,
    unit,
    off,
    step,
    loop,
    disabled,
    inputMin: inputMin,
    inputMax: inputMax,
    keydownMin: keydownMin || validMin,
    keydownMax: keydownMax || validMax,
    decimalScale,
    showProgress,
    progressValue,
    progressColor,
    progressMin,
    progressMax,
    onChange: handleChange,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    onFocus: handleFocus,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <CommonBlockedDialog
      open={warning}
      content={dialogText}
      contentClassName='w-[184px]'
      buttonContent={() => [
        <FocusLock disabled={!warning}>
          <CommonButton
            size='sm'
            className='w-[184px]'
            onClick={handleWarningOkClick}>
            OK
          </CommonButton>
        </FocusLock>,
      ]}
      overwriteElements={overwriteElements}
      overwriteOffset={overwriteOffset}
      overlayClassName={overlayClassName}
      placement={placement}>
      <InputGroup ref={inputRef} {...inputProps} />
    </CommonBlockedDialog>
  )
}

export default InputGroupAppendDialog

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputGroupAppendDialog.propTypes = {
  className: PropTypes.string,
  off: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  step: PropTypes.number,
  inputMin: PropTypes.number,
  inputMax: PropTypes.number,
  validMin: PropTypes.number,
  validMax: PropTypes.number,
  decimalScale: PropTypes.number,

  showProgress: PropTypes.bool,
  progressValue: PropTypes.arrayOf(PropTypes.string),
  progressColor: PropTypes.arrayOf(PropTypes.string),
  progressMin: PropTypes.arrayOf(PropTypes.number),
  progressMax: PropTypes.arrayOf(PropTypes.number),

  dialogText: PropTypes.node,
  placement: PropTypes.string,
  overwriteElements: PropTypes.node,
  overwriteOffset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  overlayClassName: PropTypes.string,
}
