import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'

import CommonButton from 'src/components/Button/CommonButton'

import { AiOutlineClear } from 'react-icons/ai'

import { pd_clearPowerHistoryModal_clearClick_watcher } from 'src/redux/actions/powerDetector'
import { modalActions } from 'src/redux/slices/modal'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const ClearTheCurrentlyReadData = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const handleClearClick = () =>
    dispatch(pd_clearPowerHistoryModal_clearClick_watcher({ sn }))
  const handleCancelClick = () => dispatch(modalActions.clearNormalModal())

  return (
    <Modal>
      <Modal.Icon>
        <AiOutlineClear className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Clear the currently read data?</Modal.Title>
      <Modal.Description>You cannot undo this action. </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='primary'
          danger
          onClick={handleClearClick}>
          Clear
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ClearTheCurrentlyReadData
