import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     All Power      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelAllPower(response) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Power     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelSinglePower(response) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Common Gain     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

export function* channelCommonGain(response) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Single Gain     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelSingleGain(response) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Phase     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

export function* channelSinglePhase(payloads) {
  try {
    yield console.log()
  } catch (error) {
    devWarLog(error)
  }
}
