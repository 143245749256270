import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const UDInterface = props => {
  const { channel, className } = props

  return (
    <SVGUniqueID>
      <svg
        width='210'
        height='104'
        viewBox='0 0 210 104'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        {channel === 'single' ? <SingleInterface /> : <DualInterface />}
      </svg>
    </SVGUniqueID>
  )
}
export default UDInterface

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

const SingleInterface = () => (
  <>
    <path
      d='M81.0476 51.5056L86.8291 51.5156C88.2081 51.518 89.3247 52.6366 89.3247 54.0156V63.0246C89.3247 64.4611 88.117 65.6021 86.6828 65.5206L81.5144 65.2268C80.2581 65.1554 79.2507 64.1613 79.1625 62.906L78.5494 54.1808C78.4477 52.733 79.5962 51.5031 81.0476 51.5056Z'
      stroke='white'
    />
    <path
      d='M76.3672 56.2708L82.2231 55.7585C83.7591 55.6241 85.1133 56.7603 85.2477 58.2964C85.4084 60.1342 84.0489 61.7545 82.211 61.9153L76.9016 62.3798C75.2147 62.5274 73.7275 61.2795 73.5799 59.5925C73.4323 57.9056 74.6802 56.4184 76.3672 56.2708Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M78.4887 58.5837L78.5893 59.7336C78.7091 61.103 77.6961 62.3103 76.3267 62.4301C74.9573 62.5499 73.75 61.5369 73.6302 60.1675L73.5296 59.0175C73.4098 57.6481 74.4228 56.4409 75.7922 56.3211C77.1616 56.2013 78.3688 57.2143 78.4887 58.5837Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M50.8355 52.5052L56.3172 52.5141C57.6963 52.5163 58.8131 53.6349 58.8131 55.0141V62.6304C58.8131 64.0629 57.6118 65.2025 56.1812 65.1269L51.2564 64.8667C50.001 64.8004 48.9902 63.8122 48.8954 62.5587L48.3386 55.1937C48.2288 53.7413 49.3789 52.5029 50.8355 52.5052Z'
      stroke='white'
    />
    <path
      d='M46.1382 56.2708L51.9941 55.7585C53.5301 55.6241 54.8843 56.7603 55.0187 58.2964C55.1794 60.1342 53.8199 61.7545 51.982 61.9153L46.6726 62.3798C44.9857 62.5274 43.4985 61.2795 43.3509 59.5925C43.2033 57.9056 44.4512 56.4184 46.1382 56.2708Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M48.2597 58.5837L48.3603 59.7336C48.4801 61.103 47.4671 62.3103 46.0977 62.4301C44.7283 62.5499 43.521 61.5369 43.4012 60.1675L43.3006 59.0175C43.1808 57.6481 44.1938 56.4409 45.5632 56.3211C46.9326 56.2013 48.1398 57.2143 48.2597 58.5837Z'
      fill='#434343'
      stroke='white'
    />
  </>
)
const DualInterface = () => (
  <>
    <path
      d='M91.6777 55.0453C91.5679 53.5928 92.718 52.3544 94.1746 52.3568L99.6563 52.3656C101.035 52.3679 102.152 53.4865 102.152 54.8656V62.4819C102.152 63.9145 100.951 65.054 99.5203 64.9785L94.5955 64.7183C93.3402 64.652 92.3293 63.6638 92.2345 62.4102L91.6777 55.0453Z'
      stroke='white'
    />
    <path
      d='M86.69 59.4441C86.5424 57.7571 87.7903 56.2699 89.4773 56.1223L95.3332 55.61C96.8692 55.4756 98.2234 56.6119 98.3578 58.1479C98.5186 59.9858 97.159 61.606 95.3211 61.7668L90.0117 62.2313C88.3248 62.3789 86.8376 61.131 86.69 59.4441Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M86.6397 58.8691C86.5199 57.4997 87.5329 56.2925 88.9023 56.1726C90.2717 56.0528 91.479 57.0658 91.5988 58.4352L91.6994 59.5852C91.8192 60.9546 90.8062 62.1618 89.4368 62.2817C88.0674 62.4015 86.8601 61.3885 86.7403 60.0191L86.6397 58.8691Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M71.4487 55.0453C71.3389 53.5928 72.489 52.3544 73.9456 52.3568L79.4273 52.3656C80.8064 52.3679 81.9232 53.4865 81.9232 54.8656V62.4819C81.9232 63.9145 80.7219 65.054 79.2913 64.9785L74.3665 64.7183C73.1112 64.652 72.1003 63.6638 72.0055 62.4102L71.4487 55.0453Z'
      stroke='white'
    />
    <path
      d='M66.461 59.4441C66.3134 57.7571 67.5613 56.2699 69.2483 56.1223L75.1042 55.61C76.6402 55.4756 77.9944 56.6119 78.1288 58.1479C78.2896 59.9858 76.93 61.606 75.0921 61.7668L69.7827 62.2313C68.0958 62.3789 66.6086 61.131 66.461 59.4441Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M66.4107 58.8691C66.2909 57.4997 67.3039 56.2925 68.6733 56.1726C70.0427 56.0528 71.25 57.0658 71.3698 58.4352L71.4704 59.5852C71.5902 60.9546 70.5772 62.1618 69.2078 62.2817C67.8384 62.4015 66.6311 61.3885 66.5113 60.0191L66.4107 58.8691Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M52.2197 55.0453C52.1099 53.5928 53.26 52.3544 54.7166 52.3568L60.1983 52.3656C61.5774 52.3679 62.6942 53.4865 62.6942 54.8656V62.4819C62.6942 63.9145 61.4929 65.054 60.0623 64.9785L55.1375 64.7183C53.8821 64.652 52.8713 63.6638 52.7765 62.4102L52.2197 55.0453Z'
      stroke='white'
    />
    <path
      d='M47.232 59.4441C47.0844 57.7571 48.3323 56.2699 50.0193 56.1223L55.8752 55.61C57.4112 55.4756 58.7654 56.6119 58.8998 58.1479C59.0605 59.9858 57.701 61.606 55.8631 61.7668L50.5537 62.2313C48.8668 62.3789 47.3796 61.131 47.232 59.4441Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M47.1817 58.8691C47.0619 57.4997 48.0749 56.2925 49.4443 56.1726C50.8137 56.0528 52.0209 57.0658 52.1408 58.4352L52.2414 59.5852C52.3612 60.9546 51.3482 62.1618 49.9788 62.2817C48.6094 62.4015 47.4021 61.3885 47.2823 60.0191L47.1817 58.8691Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M32.9907 55.0453C32.8809 53.5928 34.031 52.3544 35.4876 52.3568L40.9693 52.3656C42.3484 52.3679 43.4652 53.4865 43.4652 54.8656V62.4819C43.4652 63.9145 42.2639 65.054 40.8333 64.9785L35.9085 64.7183C34.6531 64.652 33.6423 63.6638 33.5475 62.4102L32.9907 55.0453Z'
      stroke='white'
    />
    <path
      d='M28.003 59.4441C27.8554 57.7571 29.1033 56.2699 30.7903 56.1223L36.6462 55.61C38.1822 55.4756 39.5364 56.6119 39.6708 58.1479C39.8315 59.9858 38.472 61.606 36.6341 61.7668L31.3247 62.2313C29.6378 62.3789 28.1506 61.131 28.003 59.4441Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M27.9527 58.8691C27.8329 57.4997 28.8459 56.2925 30.2153 56.1726C31.5847 56.0528 32.7919 57.0658 32.9118 58.4352L33.0124 59.5852C33.1322 60.9546 32.1192 62.1618 30.7498 62.2817C29.3804 62.4015 28.1731 61.3885 28.0533 60.0191L27.9527 58.8691Z'
      fill='#434343'
      stroke='white'
    />
  </>
)
