import React from 'react'

import Timeout from 'src/containers/Modal/High/Timeout'
import SignInSignUp from 'src/containers/Modal/High/member/SignInSignUp'

import SignUpVerification from 'src/containers/Modal/High/member/SignUpVerification'
import SignUpSuccess from 'src/containers/Modal/High/member/SignUpSuccess'

import ForgotPasswordSendMail from 'src/containers/Modal/High/member/ForgotPasswordSendMail'
import ForgotPasswordReset from 'src/containers/Modal/High/member/ForgotPasswordReset'
import ForgotPasswordSuccess from 'src/containers/Modal/High/member/ForgotPasswordSuccess'

import ClearCheck from 'src/containers/Modal/High/beamformers/bbox/beamConfigEditor/ClearCheck'
import DiscardUnsavedChanges from 'src/containers/Modal/High/beamformers/bbox/beamConfigEditor/DiscardUnsavedChanges'

const HighModal = props => {
  const { type: modalType, props: modalProps } = props
  const { status: modalStatus } = modalProps

  if (modalType === 'ERROR' && modalStatus === 'timeout')
    return <Timeout {...modalProps} />

  if (modalType === 'MEMBER' && modalStatus === 'signInAndSingUp')
    return <SignInSignUp {...modalProps} />

  if (modalType === 'MEMBER' && modalStatus === 'forgotPasswordSendEMail')
    return <ForgotPasswordSendMail {...modalProps} />
  if (modalType === 'MEMBER' && modalStatus === 'forgotPasswordReset')
    return <ForgotPasswordReset {...modalProps} />
  if (modalType === 'MEMBER' && modalStatus === 'forgotPasswordSuccess')
    return <ForgotPasswordSuccess {...modalProps} />

  if (modalType === 'MEMBER' && modalStatus === 'signUpVerification')
    return <SignUpVerification {...modalProps} />
  if (modalType === 'MEMBER' && modalStatus === 'signUpSuccess')
    return <SignUpSuccess {...modalProps} />

  if (modalType === 'BEAM_CONFIG_EDITOR' && modalStatus === 'clearCheck')
    return <ClearCheck {...modalProps} />

  if (
    modalType === 'BEAM_CONFIG_EDITOR' &&
    modalStatus === 'discardUnsavedChanges'
  )
    return <DiscardUnsavedChanges {...modalProps} />

  return <></>
}

export default HighModal
