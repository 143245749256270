import { call, put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* updateCurrent(response) {
  try {
    const [sn, { freq, ledIndicators }] = Object.entries(response?.data)[0]
    let { currentData } = yield call(getCommonArgs, sn)
    currentData.current.freq = freq
    currentData.current.ledIndicators = ledIndicators

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}
