import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const ConnectAwsFailed = props => {
  const dispatch = useDispatch()

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red' />
      </Modal.Icon>

      <Modal.Title>Failed to Connect WEB-TLK Middleware</Modal.Title>

      <Modal.Description>
        1. Confirm the Internet connection status
        <br />
        2. Ask your IT department if the domain and <br />
        port are blocked or protected by a firewall
        <br />
        <br />
        If the problem persists, please contact TMYTEK.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          size='lg'
          type='general'
          onClick={() => dispatch(modalActions.clearNormalModal())}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default ConnectAwsFailed
