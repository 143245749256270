import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import IconButton from 'src/components/Button/IconButton'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

import { BiArrowBack, BiHomeAlt } from 'react-icons/bi'

import { redirectToChooseMode_watcher } from 'src/redux/actions/facility/init'
import { home_leaveDeviceInfo_watcher } from 'src/redux/actions/facility/home'

const MainLeft = () => {
  const { currentMode } = useGetCurrentMode()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const headerData = useSelector(state => state.header)
  const { key } = headerData

  const leftButtons = {
    home: [
      {
        content: <BiHomeAlt />,
        buttonType: '',
        buttonClassName: '',
        onClick: () => dispatch(redirectToChooseMode_watcher()),
      },
    ],
    home_device_info: [
      {
        content: <BiArrowBack />,
        buttonType: '',
        buttonClassName: '',
        onClick: () => dispatch(home_leaveDeviceInfo_watcher()),
      },
    ],
    download_middleware: [
      {
        content: <BiArrowBack />,
        buttonType: '',
        buttonClassName: '',
        onClick: () => navigate(`/`),
      },
    ],
    settings: [
      {
        content: <BiArrowBack />,
        buttonType: '',
        buttonClassName: '',
        onClick: () => navigate(-1),
      },
    ],
    team: [
      {
        content: <BiArrowBack />,
        buttonType: '',
        buttonClassName: '',
        onClick: () => navigate(-1),
      },
    ],
    operating: [
      {
        content: <BiArrowBack />,
        buttonType: '',
        buttonClassName: '',
        onClick: () => navigate(`/home/${currentMode}`),
      },
    ],
  }

  return (
    <div className='absolute left-4 md:left-8 flex gap-x-1'>
      {leftButtons[key]?.map((e, i) => (
        <IconButton
          key={`header left button` + i}
          type={e.buttonType || 'standard'}
          size='lg'
          className={e.buttonClassName}
          onClick={e.onClick}>
          {e.content}
        </IconButton>
      ))}
    </div>
  )
}

MainLeft.propTypes = {}

export default MainLeft
