import { createSlice, current } from '@reduxjs/toolkit'

// [{ type: '', title: '', description: '', read: false }]

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: [],
  reducers: {
    addNotification(state, action) {
      const lstAddData = action.payload
      return [...current(state), ...lstAddData]
    },
    removeNotification(state, action) {
      return []
    },
  },
})

export const notificationActions = notificationSlice.actions

export default notificationSlice.reducer
