import moment from 'moment'

import { powerDetectorInitAppendData } from '../configs/initAppendData/powerDetector.mjs'

const powerDetectorClass = {
  _isPd(sn) {
    const deviceType = this._getDeviceType(sn)
    return this._isIncludesCategory(deviceType, 'pd')
  },

  _pdInit(sn) {
    try {
      const currentData = this._getCurrentData(sn)

      const appendData = powerDetectorInitAppendData(sn)

      const newDeviceData = { ...currentData, ...appendData }

      newDeviceData.info.initialization = 'finished'

      this._updateSingleDeviceData(sn, newDeviceData)

      return { [sn]: { deviceData: newDeviceData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/select/currentCaliID'({ payloads }) {
    try {
      const { sn, value } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.deviceControl.actionPanel.currentCaliID = value

      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { currentCaliID: value } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/insert/cali/data'({ payloads }) {
    try {
      const { sn, data } = payloads
      const currentData = this._getCurrentData(sn)

      const id = Object.keys(data)[0]

      let newCaliData = data
      newCaliData[id].createdAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSS')

      currentData.deviceControl.actionPanel.caliData = {
        ...newCaliData,
        ...currentData.deviceControl.actionPanel.caliData,
      }

      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { ...newCaliData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/update/cali/data'({ payloads }) {
    try {
      const { sn, data } = payloads
      const currentData = this._getCurrentData(sn)

      const [[id, value]] = Object.entries(data)

      currentData.deviceControl.actionPanel.caliData[id] = value

      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { ...data } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/delete/cali/data'({ payloads }) {
    try {
      const { sn, deleteIdList } = payloads
      const currentData = this._getCurrentData(sn)

      deleteIdList.forEach(id => {
        delete currentData.deviceControl.actionPanel.caliData[id]
      })

      this._updateSingleDeviceData(sn, currentData)

      return {
        [sn]: { caliData: currentData.deviceControl.actionPanel.caliData },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/get/cali/voltage'({ payloads }) {
    const { sn, freq, power } = payloads
    return {
      [sn]: {
        [freq]: { power, voltage: Math.floor(Math.random() * 100000) / 100 },
      },
    }
  },
  exportPowerHistory({ payloads }) {
    try {
      const { sn } = payloads
      const currentData = this._getCurrentData(sn)

      const measureData = currentData.deviceControl.dashboard.measureData

      return { measureData }
    } catch (error) {
      console.warn(error)
    }
  },
}

export default powerDetectorClass
