import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiInfoCircle } from 'react-icons/bi'

import {
  deviceResetReminderModal_cancelClick_watcher,
  deviceResetReminderModal_yesClick_watcher,
} from 'src/redux/actions/facility/operating'

const DeviceResetReminder = props => {
  const dispatch = useDispatch()
  const { isLoading, isSuccess } = props

  const handleCancelClick = () =>
    dispatch(deviceResetReminderModal_cancelClick_watcher())

  const handleYesClick = () =>
    dispatch(deviceResetReminderModal_yesClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white' />
      </Modal.Icon>
      <Modal.Title>
        This change will reset the device. <br />
        Apply?
      </Modal.Title>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='primary'
          danger
          loading={isLoading}
          success={isSuccess}
          onClick={handleYesClick}>
          Apply
        </CommonButton>
        <CommonButton
          size='lg'
          type='general'
          disabled={isLoading || isSuccess}
          onClick={handleCancelClick}>
          Cancel
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default DeviceResetReminder
