const fileExport = {
  // file import
  showModalOfFileExporting: (state, action) => {
    state.normal = {
      type: 'FILE_EXPORT',
      props: { status: 'exporting' },
    }
    return state
  },
  showModalOfFileExportSuccess: (state, action) => {
    state.normal = {
      type: 'FILE_EXPORT',
      props: { status: 'success' },
    }
    return state
  },
  showModalOfFileImportFailed: (state, action) => {
    state.normal = {
      type: 'FILE_EXPORT',
      props: {
        status: 'failed',
      },
    }
    return state
  },
}
export default fileExport
