import React, { cloneElement } from 'react'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'

import Core from 'src/components/Popup/BlockedDialog/Core'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//  <CommonDialog
//    open={}
//    content={} // jsx
//    contentClassName='' // string
//    buttonContent={} // () => []
//    overwriteElements={} // jsx
//    overwriteOffset={{top:'',left:''}} // {top,left}
//    overlayClassName='' // string
//    placement={} // top , bottom , left , right
//   >
//    ...children
//  </CommonDialog>

const CommonDialog = props => {
  const {
    open,
    title,
    content,
    buttonContent = () => [],
    overwriteElements,
    overwriteOffset,
    placement,
    dismiss,
    overlay,
    overlayClassName = '',
    titleClassName = '',
    contentClassName = 'w-[184px]',
    buttonContainerClassName = 'justify-end',
    children,
  } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const titleClass = `${titleClassName} text-base font-medium text-white `
  const divider = `w-full h-[1px] bg-dark-5 my-1`
  const contentClass = `
                      ${contentClassName} 
                      text-sm font-normal text-white whitespace-pre-line
                      `
  const buttonContainerClass = `${buttonContainerClassName} flex mt-5`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <Core
      {...{
        open,
        placement,
        dismiss,
        overlay,
        overlayClassName,
        overwriteElements,
        overwriteOffset,
      }}
      render={() => (
        <>
          {title && <h3 className={titleClass}>{title}</h3>}
          {title && content && <div className={divider} />}
          {content && <div className={contentClass}>{content}</div>}
          <div className={buttonContainerClass}>
            <ButtonWithIndex buttons={buttonContent()} />
          </div>
        </>
      )}>
      {children}
    </Core>
  )
}

export default CommonDialog

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 把傳進來的 children array 給予 key
const ButtonWithIndex = props =>
  props.buttons.map(e =>
    cloneElement(e, {
      ...props,
      key: nanoid(),
    })
  )

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonDialog.propTypes = {
  open: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  title: PropTypes.node,
  content: PropTypes.node,
  buttonContent: PropTypes.func,
  overwriteElements: PropTypes.node,
  overwriteOffset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  placement: PropTypes.string,
  dismiss: PropTypes.bool,
  overlay: PropTypes.bool,
  overlayClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  buttonContainerClassName: PropTypes.string,
}
