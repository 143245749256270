import React from 'react'
import * as R from 'ramda'

import CommonButton from 'src/components/Button/CommonButton'
import SegmentedButton from 'src/components/Button/SegmentedButton'
import Tooltip from 'src/components/Popup/Tooltip'

import Description from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/Description'
import BeamSteering from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/BeamSteering'
import Channel from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/Channel'

import { descriptionLengthMax } from 'src/constants/beamformers'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { AiOutlineClear } from 'react-icons/ai'

const Main = props => {
  const {
    sn,
    rfMode,
    currentBeamConfigData: currentData,
    handleClearClick,
    handleDataChange,
  } = props

  const { current } = useGetCurrentDeviceData(sn)
  const { common } = current.data.deviceControl
  const r_currentAntenna = common.currentAntenna
  const { beam_config, channel_config } = currentData

  const handleDescriptionChange = e => {
    const newDescription = e.formattedValue
    const newCurrentBeamConfigData = R.assoc(
      'beam_description',
      newDescription
    )(currentData)

    handleDataChange({ newCurrentBeamConfigData })
  }

  const handleBeamTypeChange = (event, value) => {
    const newCurrentBeamConfigData = R.assoc('beam_type', value)(currentData)

    handleDataChange({ newCurrentBeamConfigData })
  }

  const handleSteeringChange = newBeamConfigData => {
    const newCurrentBeamConfigData = R.assoc(
      'beam_config',
      newBeamConfigData
    )(currentData)

    handleDataChange({ newCurrentBeamConfigData })
  }

  const handleChannelChange = newChannelConfigData => {
    const newCurrentBeamConfigData = R.assoc(
      'channel_config',
      newChannelConfigData
    )(currentData)

    handleDataChange({ newCurrentBeamConfigData })
  }

  return (
    <div
      className={`${mainHeight} max-w-[640px] w-full py-4 pr-2 mx-auto translate-x-2 scrollbar`}>
      <div className='relative'>
        <Description
          value={currentData.beam_description}
          danger={currentData.beam_description.length > descriptionLengthMax}
          placeholder='Optional'
          onChange={handleDescriptionChange}
        />

        <CommonButton
          className='absolute right-0 top-0'
          icon={<AiOutlineClear />}
          danger
          type='text'
          size='sm'
          onClick={handleClearClick}>
          Clear
        </CommonButton>
      </div>

      <SegmentedButton
        size='md'
        type='solid'
        className='mt-8 mb-4'
        value={currentData.beam_type}
        onChange={handleBeamTypeChange}>
        <Tooltip
          disabled={r_currentAntenna}
          placement='bottom'
          title={
            <div className='text-center'>
              Enabled beam steering simulation
              <br /> when the Antenna has an item selected.
            </div>
          }>
          <SegmentedButton.Option
            name={0}
            className='w-full'
            disabled={!r_currentAntenna}>
            Beam Steering
          </SegmentedButton.Option>
        </Tooltip>

        <SegmentedButton.Option name={1}>
          Channel Control
        </SegmentedButton.Option>
      </SegmentedButton>

      <div className=''>
        {currentData.beam_type === 0 ? (
          <BeamSteering
            {...{
              sn,
              rfMode,
              beam_config,
              handleSteeringChange,
            }}
          />
        ) : (
          <Channel {...{ sn, rfMode, channel_config, handleChannelChange }} />
        )}
      </div>
    </div>
  )
}

export default Main

//! 設計師堅持 main 一定要高度撐滿中間
// 90vh = modal 的最大高度
// 116px = title 的高度
// 104px = 104 的高度
// 所以中間的高度就會是 下面那樣
export const mainHeight = `h-[calc(90vh-116px-104px)]`
