import React from 'react'
import { useDispatch } from 'react-redux'

import CommonSwitch from 'src/components/Switch/CommonSwitch'
import {
  divider,
  h4,
  container,
} from 'src/containers/operating/device/beamformers/style'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCellCTTC_steering_beamSynthesisChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const BeamSynthesis = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const isBeamSynthesis = current.data?.deviceControl?.steering.isBeamSynthesis

  const handleClick = () =>
    dispatch(cloverCellCTTC_steering_beamSynthesisChange_watcher({ sn }))

  return (
    <>
      <div className={`${container} flex justify-between`}>
        <h4 className={h4}>Beam Synthesis</h4>
        <CommonSwitch
          onText='ON'
          offText='OFF'
          on={isBeamSynthesis}
          onClick={handleClick}
        />
      </div>

      <div className={divider} />
    </>
  )
}

export default BeamSynthesis
