import { all, fork, take } from 'redux-saga/effects'

// watcher
import {
  bboard_common_rfModeSwitch_watcher,
  bboard_step_allPowerSwitch_watcher,
  bboard_step_singlePowerSwitch_watcher,
  bboard_step_commonAttenuationChange_watcher,
  bboard_step_commonAttenuationAfterChange_watcher,
  bboard_step_singleAttenuationChange_watcher,
  bboard_step_singlePhaseChange_watcher,
  bboard_RfIcTemperature_updateClick_watcher,
  bboard_temperatureCompensationChange_watcher,
  bboard_TemperatureCompensation_setupClick_watcher,
} from 'src/redux/actions/beamformers/bboardAction'

// handler
import { commonRfModeSwitch as handleCommonRfModeSwitch } from 'src/redux/sagas/handlers/event/beamformers/general/common'

import {
  stepAllPower as handleStepAllPower,
  stepSinglePower as handleStepSinglePower,
  stepCommonAttenuationChange as handleStepCommonAttenuationChange,
  stepCommonAttenuationAfterChange as handleStepCommonAttenuationAfterChange,
  stepSingleAttenuation as handleStepSingleAttenuation,
  stepSinglePhase as handleStepSinglePhase,
} from 'src/redux/sagas/handlers/event/beamformers/bboard/step'

import {
  readTemperature as handleReadTemperature,
  temperatureCompensationChange as handleTemperatureCompensationChange,
  setTemperatureCompensation as handleSetTemperatureCompensation,
} from 'src/redux/sagas/handlers/event/beamformers/bboard/temperature'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

function* commonRfModeSwitch() {
  while (true) {
    const action = yield take(bboard_common_rfModeSwitch_watcher.type)
    devLog('[watcher] commonRfModeSwitch', action.payload)
    yield fork(handleCommonRfModeSwitch, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Step      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* stepAllPower() {
  while (true) {
    const action = yield take(bboard_step_allPowerSwitch_watcher.type)
    devLog('[watcher] stepAllPower', action.payload)
    yield fork(handleStepAllPower, action.payload)
  }
}

function* stepSinglePower() {
  while (true) {
    const action = yield take(bboard_step_singlePowerSwitch_watcher.type)
    devLog('[watcher] stepSinglePower', action.payload)
    yield fork(handleStepSinglePower, action.payload)
  }
}

function* stepCommonAttenuationChange() {
  while (true) {
    const action = yield take(bboard_step_commonAttenuationChange_watcher.type)
    devLog('[watcher] stepCommonAttenuationChange', action.payload)
    yield fork(handleStepCommonAttenuationChange, action.payload)
  }
}
function* stepCommonAttenuationAfterChange() {
  while (true) {
    const action = yield take(
      bboard_step_commonAttenuationAfterChange_watcher.type
    )
    devLog('[watcher] stepCommonAttenuationAfterChange', action.payload)
    yield fork(handleStepCommonAttenuationAfterChange, action.payload)
  }
}

function* stepSingleAttenuation() {
  while (true) {
    const action = yield take(bboard_step_singleAttenuationChange_watcher.type)
    devLog('[watcher] stepSingleAttenuation', action.payload)
    yield fork(handleStepSingleAttenuation, action.payload)
  }
}

function* stepSinglePhase() {
  while (true) {
    const action = yield take(bboard_step_singlePhaseChange_watcher.type)
    devLog('[watcher] stepSinglePhase', action.payload)
    yield fork(handleStepSinglePhase, action.payload)
  }
}

function* readTemperature() {
  while (true) {
    const action = yield take(bboard_RfIcTemperature_updateClick_watcher.type)
    devLog('[watcher] readTemperature', action.payload)
    yield fork(handleReadTemperature, action.payload)
  }
}

function* temperatureCompensationChange() {
  while (true) {
    const action = yield take(bboard_temperatureCompensationChange_watcher.type)
    devLog('[watcher] temperatureCompensationChange', action.payload)
    yield fork(handleTemperatureCompensationChange, action.payload)
  }
}

function* setTemperatureCompensation() {
  while (true) {
    const action = yield take(
      bboard_TemperatureCompensation_setupClick_watcher.type
    )
    devLog('[watcher] setTemperatureCompensation', action.payload)
    yield fork(handleSetTemperatureCompensation, action.payload)
  }
}

export default function* bboardWatchers() {
  yield all([
    // common
    commonRfModeSwitch(),

    // step
    stepAllPower(),
    stepSinglePower(),
    stepCommonAttenuationChange(),
    stepCommonAttenuationAfterChange(),
    stepSingleAttenuation(),
    stepSinglePhase(),

    // temperature
    readTemperature(),
    temperatureCompensationChange(),
    setTemperatureCompensation(),
  ])
}
