import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'
import CommonSelect from 'src/components/Select/CommonSelect'

import downloadImg from 'src/assets/img/download.png'
import installImg from 'src/assets/img/install.png'
import connectImg from 'src/assets/img/connect.png'

import { BiRightArrowAlt, BiDownArrowAlt } from 'react-icons/bi'

import useSetHeader from 'src/hooks/useSetHeader'

import { downloadMid_reConnectClick_watcher } from 'src/redux/actions/facility/init'

import { isProduction } from 'src/funcs/getEnv'

import { getOS } from 'src/funcs/tools'

const DownloadMid = () => {
  const dispatch = useDispatch()

  useSetHeader({ key: 'download_middleware', props: '' })

  const handleReConnectClick = () =>
    dispatch(downloadMid_reConnectClick_watcher())

  const [linuxSelect, setLinuxSelect] = useState('Ubuntu 18.04')

  const userOS = getOS()

  const versionPath = `/${env_midVersion}`
  const packageDownloadLink = key =>
    domain + rootPath(isProduction) + versionPath + fileName[key]

  const windowsLink = packageDownloadLink('windows')
  const macLink = packageDownloadLink('macOS')

  const linuxLinkList = {
    'Ubuntu 18.04': packageDownloadLink('ubuntu1804'),
    'Ubuntu 20.04': packageDownloadLink('ubuntu2004'),
    'Ubuntu 22.04': packageDownloadLink('ubuntu2204'),
    'CentOS 7 or Later': packageDownloadLink('centOS7'),
  }

  return (
    <>
      <section className={mainBgContainer}>
        <main className={main}>
          <h3 className={h3}>3 Steps to Control your Device</h3>

          <div className='flex flex-col xl:flex-row'>
            <div className={infoBox}>
              <div className={imageBox}>
                <img src={downloadImg} alt='TMXLab-Kit download' />
              </div>

              <h4 className={h4}>➊ Download</h4>

              <p className={p}>
                We use this tool as a bridge to communicate with your device.
                <br />
                Please download and install WEB-TLK Middleware.
              </p>

              {userOS === 'windows' && (
                <CommonButton
                  className='w-full'
                  type='primary'
                  size='lg'
                  onClick={() => window.open(windowsLink, '_blank')}>
                  Windows 10 or Later
                </CommonButton>
              )}

              {userOS === 'macOS' && (
                <CommonButton
                  className='w-full '
                  type='primary'
                  size='lg'
                  onClick={() => window.open(macLink, '_blank')}>
                  MacOS 11 or Later
                </CommonButton>
              )}

              {userOS === 'linux' && (
                <>
                  <CommonSelect
                    size='lg'
                    className='w-full mb-3'
                    value={linuxSelect}
                    onChange={(event, name) => setLinuxSelect(name)}>
                    <CommonSelect.Option name='Ubuntu 18.04'>
                      Ubuntu 18.04
                    </CommonSelect.Option>
                    <CommonSelect.Option name='Ubuntu 20.04'>
                      Ubuntu 20.04
                    </CommonSelect.Option>
                    <CommonSelect.Option name='Ubuntu 22.04'>
                      Ubuntu 22.04
                    </CommonSelect.Option>

                    <CommonSelect.Divider />

                    <CommonSelect.Option name='CentOS 7 or Later'>
                      CentOS 7 or Later
                    </CommonSelect.Option>
                  </CommonSelect>

                  <CommonButton
                    className='w-full'
                    type='primary'
                    size='lg'
                    onClick={() => {
                      window.open(linuxLinkList[linuxSelect], '_blank')
                    }}>
                    Download
                  </CommonButton>
                </>
              )}

              <CommonButton
                className='w-full mt-3'
                type='outlined'
                size='lg'
                href={corpWebDownloadMidLink}>
                Show all available download
              </CommonButton>
            </div>

            <BiRightArrowAlt className={arrow + 'hidden xl:block mx-8'} />
            <BiDownArrowAlt className={arrow + 'block xl:hidden'} />

            <div className={infoBox}>
              <div className={imageBox}>
                <img src={installImg} alt='TMXLab-Kit install middleware' />
              </div>
              <h4 className={h4}>➋ Install</h4>
              <p className={p}>
                Complete the installation.
                <br /> Keep it turned ON automatically to keep the connection
                smooth.
              </p>
            </div>

            <BiRightArrowAlt className={arrow + 'hidden xl:block mx-8'} />
            <BiDownArrowAlt className={arrow + 'block xl:hidden'} />

            <div className={infoBox}>
              <div className={imageBox}>
                <img src={connectImg} alt='TMXLab-Kit connect' />
              </div>
              <h4 className={h4}>➌ Connect</h4>
              <p className={p}>
                Make sure WEB-TLK Middleware is turned on. <br />
                If the system does not automatically connect, click "Connect" to
                start controlling your device.
              </p>
              <CommonButton
                className='w-full'
                type='general'
                size='lg'
                onClick={handleReConnectClick}>
                Connect
              </CommonButton>
            </div>
          </div>
        </main>
      </section>
    </>
  )
}

export default DownloadMid

// sm:pl-2 是為了補手機被 scrollbar 往左推造成左右不對稱
const mainBgContainer = `[DownloadMid-mainBgContainer] 
                      sectionHeight 
                      w-full 
                      pr-4 md:pl-4 sm:pl-6
                      flex items-start xl:items-center justify-center 
                      pt-[136px] xl:pt-0
                      `

const main = `[DownloadMid-main] 
              w-full md:w-[480px] xl:w-[1040px]
              flex flex-col 
              items-center 
              px-6 py-10 md:px-[60px] xl:px-10 
              bg-black/50 backdrop-blur-sm 
              rounded-[6px]
              `

const imageBox = `[DownloadMid-imageBox] w-full max-w-[360px] h-[120px] flex items-center justify-center mb-6`
const infoBox = `[DownloadMid-infoBox] w-[240px] flex flex-col items-center py-6`

const h3 = `text-2xl font-bold leading-7 text-light-4 text-center mb-8`
const h4 = `text-lg font-medium leading-5 text-light-4`
const p = `text-base font-normal leading-5 text-light-4 text-center mt-2 mb-6`

const arrow = `relative inset-0 m-auto text-5xl text-light-5 shrink-0 `

const corpWebDownloadMidLink = process.env.REACT_APP_MID_CORP_WEB_DOWNLOAD_LINK
const env_allVersion = process.env.REACT_APP_VERSION
export const env_midVersion = env_allVersion
  ? JSON.parse(env_allVersion)?.tag_message?.['BE-TLK-MIDDLEWARE']
  : ''

const domain = `https://tmytek.com`
const rootPath = isProduction => (isProduction ? `/TLK` : `/TLK_QA`)
const fileName = {
  windows: `/TLK_Middleware.exe`,
  macOS: `/TLK_Middleware_macOS.zip`,
  ubuntu1804: `/TLK_Middleware_ubuntu1804.tar.gz`,
  ubuntu2004: `/TLK_Middleware_ubuntu2004.tar.gz`,
  ubuntu2204: `/TLK_Middleware_ubuntu2204.tar.gz`,
  centOS7: `/TLK_Middleware_centos7.tar.gz`,
}
