import React from 'react'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import SegmentedButton from 'src/components/Button/SegmentedButton'

import { sg_deviceSettings_refSignalSource_change_watcher } from 'src/redux/actions/freqConverter/sgAction'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const RefSource = ({ value, handleChange }) => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const refSource = current.data.settings.device?.refSource
  const isOutputting = current.data.settings.output.isOutputting

  const isDisabled = isOutputting

  const handleCHange = (event, value) =>
    dispatch(sg_deviceSettings_refSignalSource_change_watcher({ sn, value }))

  return (
    <div className='px-2'>
      <h6 className={h6 + ' text-light-1 mb-2'}>Reference Signal Source</h6>

      <SegmentedButton
        size={isSM || isMD ? 'md' : 'sm'}
        type='outlined'
        value={+refSource}
        disabled={isDisabled}
        onChange={handleCHange}>
        <SegmentedButton.Option name={0}>Internal</SegmentedButton.Option>
        <SegmentedButton.Option name={1}>External</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

export default RefSource
