import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

import {
  setCameraArgs,
  setMaskDontShowAgain,
} from 'src/redux/slices/uiControl/beamformers/cloverCellEvb'

function* _setCurrentControlModeToSteering({ sn }) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)
  currentData.deviceControl.common.currentControlMode[currentRfMode] =
    'steering'

  yield put(setSingleDeviceData({ sn, data: currentData }))
}

export function* steeringChannelModeMaskClose(payloads) {
  try {
    const { sn, dontShowAgain } = payloads

    let { currentData, currentRfMode, lookupID } = yield call(getCommonArgs, sn)

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield put(
      setMaskDontShowAgain({ key: 'channelMode', value: dontShowAgain })
    )

    yield call(_setCurrentControlModeToSteering, { sn })

    const h_theta =
      currentData.deviceControl.steering[currentRfMode]['horizon'].theta
    const h_phi =
      currentData.deviceControl.steering[currentRfMode]['horizon'].phi
    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_EVB_BEAM_STEERING_THETA_PHI,
      { sn, lookupID, hvMode: 'horizon', theta: +h_theta, phi: +h_phi }
    )

    const v_theta =
      currentData.deviceControl.steering[currentRfMode]['vertical'].theta
    const v_phi =
      currentData.deviceControl.steering[currentRfMode]['vertical'].phi
    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_EVB_BEAM_STEERING_THETA_PHI,
      { sn, lookupID, hvMode: 'vertical', theta: +v_theta, phi: +v_phi }
    )
  } catch (error) {
    devWarLog('[handler] steeringChannelModeMaskClose error:', error)
  }
}

export function* steeringThetaPhiChange(payloads) {
  try {
    const { sn, hvMode, theta, phi, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering[currentRfMode][hvMode].theta = theta
    currentData.deviceControl.steering[currentRfMode][hvMode].phi = phi
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid) {
      yield call(_setCurrentControlModeToSteering, { sn })

      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_EVB_BEAM_STEERING_THETA_PHI,
        { sn, lookupID, hvMode, theta: +theta, phi: +phi }
      )
    }
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* steeringGainChange(payloads) {
  try {
    const { sn, hvMode, beamCurrentGain, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering[currentRfMode][hvMode].beamCurrentGain =
      beamCurrentGain
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid) {
      yield call(_setCurrentControlModeToSteering, { sn })

      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_EVB_BEAM_STEERING_GAIN,
        {
          sn,
          lookupID,
          hvMode,
          value: +beamCurrentGain,
        }
      )
    }
  } catch (error) {
    devWarLog('[handler] steeringGainChange error:', error)
  }
}
export function* steering3DCameraChange(action) {
  try {
    yield put(setCameraArgs(action.payload))
  } catch (error) {
    devWarLog('[handler] steering3DCameraChange error:', error)
  }
}
