import { all, take, fork, cancel, race } from 'redux-saga/effects'

// action
import {
  udmodule_deviceSettings_refSourceChange_watcher,
  udmodule_deviceSettings_InternalEnableClick_watcher,
  udmodule_deviceSettings_OutputChange_watcher,
  udmodule_deviceSettings_InputChange_watcher,
  udModule_toExternalCheckModal_cancelClick_watcher,
  udModule_toExternalCheckModal_useExternalClick_watcher,
} from 'src/redux/actions/freqConverter/udmoduleAction'

// handlers
import {
  deviceSettingRefSourceChange as handleDeviceSettingRefSourceChange,
  deviceSettingInternalEnableClick as handleDeviceSettingInternalEnableClick,
  deviceSettingOutputChange as handleDeviceSettingOutputChange,
  deviceSettingInputChange as handleDeviceSettingInputChange,
} from 'src/redux/sagas/handlers/event/freqConverter/udmodule/device'

import { devLog } from 'src/funcs/tools'

function* deviceSettingRefSourceChange() {
  while (true) {
    const action = yield take(
      udmodule_deviceSettings_refSourceChange_watcher.type
    )
    // const { sn } = action.payload

    devLog('[watcher] deviceSettingRefSourceChange', action.payload)

    const { refSource } = action.payload

    const refSourceChangeTask = yield fork(
      handleDeviceSettingRefSourceChange,
      action.payload
    )

    const toExternal = refSource === 1
    if (toExternal) {
      const { cancelTask } = yield race({
        sendExternalApi: take(
          udModule_toExternalCheckModal_useExternalClick_watcher.type
        ),
        cancelTask: take(
          udModule_toExternalCheckModal_cancelClick_watcher.type
        ),
      })

      if (cancelTask) yield cancel(refSourceChangeTask)
    }
  }
}
function* deviceSettingInternalEnableClick() {
  while (true) {
    const action = yield take(
      udmodule_deviceSettings_InternalEnableClick_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] deviceSettingInternalEnableClick', action.payload)
    yield fork(handleDeviceSettingInternalEnableClick, action.payload)
  }
}
function* deviceSettingOutputChange() {
  while (true) {
    const action = yield take(udmodule_deviceSettings_OutputChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] deviceSettingOutputChange', action.payload)
    yield fork(handleDeviceSettingOutputChange, action.payload)
  }
}
function* deviceSettingInputChange() {
  while (true) {
    const action = yield take(udmodule_deviceSettings_InputChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] deviceSettingInputChange', action.payload)
    yield fork(handleDeviceSettingInputChange, action.payload)
  }
}

export default function* udmoduleWatchers() {
  yield all([
    deviceSettingRefSourceChange(),
    deviceSettingInternalEnableClick(),
    deviceSettingOutputChange(),
    deviceSettingInputChange(),
  ])
}
