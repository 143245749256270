import React from 'react'
import PropTypes from 'prop-types'
import { SVGUniqueID } from 'react-svg-unique-id'

const DragCircles = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='none'
        className={className}>
        <path d='M0 6.85C0 5.96634 0.716345 5.25 1.6 5.25C2.48366 5.25 3.2 5.96634 3.2 6.85V7.15C3.2 8.03366 2.48366 8.75 1.6 8.75C0.716345 8.75 0 8.03366 0 7.15V6.85ZM0 1.6C0 0.716344 0.716345 0 1.6 0C2.48366 0 3.2 0.716344 3.2 1.6V1.9C3.2 2.78366 2.48366 3.5 1.6 3.5C0.716345 3.5 0 2.78366 0 1.9V1.6ZM0 12.1C0 11.2163 0.716345 10.5 1.6 10.5C2.48366 10.5 3.2 11.2163 3.2 12.1V12.4C3.2 13.2837 2.48366 14 1.6 14C0.716345 14 0 13.2837 0 12.4V12.1ZM4.8 6.85C4.8 5.96634 5.51634 5.25 6.4 5.25C7.28366 5.25 8 5.96634 8 6.85V7.15C8 8.03366 7.28366 8.75 6.4 8.75C5.51634 8.75 4.8 8.03366 4.8 7.15V6.85ZM4.8 1.6C4.8 0.716344 5.51634 0 6.4 0C7.28366 0 8 0.716344 8 1.6V1.9C8 2.78366 7.28366 3.5 6.4 3.5C5.51634 3.5 4.8 2.78366 4.8 1.9V1.6ZM4.8 12.1C4.8 11.2163 5.51634 10.5 6.4 10.5C7.28366 10.5 8 11.2163 8 12.1V12.4C8 13.2837 7.28366 14 6.4 14C5.51634 14 4.8 13.2837 4.8 12.4V12.1Z' />
      </svg>
    </SVGUniqueID>
  )
}

DragCircles.propTypes = { className: PropTypes.string }

export default DragCircles
