import React from 'react'

import Pd01Device from 'src/assets/svg/device/avatar/PowerDetector/Pd01Device'
import Pd02Device from 'src/assets/svg/device/avatar/PowerDetector/Pd02Device'
import Cable from 'src/assets/svg/device/avatar/PowerDetector/Cable'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const PowerDetector = props => {
  const sn = props?.sn

  // 這邊有塞 sn 進去撈指定 current
  const { current } = useGetCurrentDeviceData(sn)

  const hardwareVersion = current?.data?.info?.versionInfo?.hardware

  return hardwareVersion === '01' ? <Pd01 /> : <Pd02 />
}
export default PowerDetector

const containerClass = `
                          relative 
                          w-full h-full 
                          flex items-center justify-center 
                          overflow-hidden 
                          group
                          `
const deviceClass = `
                          absolute inset-x-0 top-9 m-auto 
                          translate-y-3 group-hover:translate-y-0 
                          scale-[1.15] group-hover:scale-[1]
                          duration-1000 
                          `
const cableCommonClass = ` absolute inset-x-0 bottom-0 m-auto z-10 
                          opacity-0  group-hover:opacity-100
                          duration-1000
                          `

const cableLightClass = `group-hover:animate-breathOpacity`

const Pd01 = () => {
  const cableClass = `
  ${cableCommonClass} 
  translate-x-[1px]
  translate-y-[110px] group-hover:translate-y-[11px] 
  `

  return (
    <div className={containerClass}>
      <Pd01Device className={deviceClass} />
      <Cable className={cableClass} cableLightClassName={cableLightClass} />
    </div>
  )
}

const Pd02 = () => {
  const cableClass = `
  ${cableCommonClass} 
  translate-x-[0.5px]
  translate-y-[110px] group-hover:translate-y-[16px] 
  `

  return (
    <div className={containerClass}>
      <Pd02Device className={deviceClass} />
      <Cable className={cableClass} cableLightClassName={cableLightClass} />
    </div>
  )
}
