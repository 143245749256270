import React from 'react'
import { useDispatch } from 'react-redux'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import { h6 } from 'src/containers/operating/device/beamformers/style'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCellCTTC_common_refSignalSourceChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const RefSignalSource = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const refSignalSource = current.data.deviceControl.common.refSource

  const handleClick = (event, value) =>
    dispatch(
      cloverCellCTTC_common_refSignalSourceChange_watcher({
        sn,
        value,
        showCheckModal: true,
      })
    )

  return (
    <div>
      <h6 className={subtitle}>Reference Signal Source - 100 MHz</h6>

      <SegmentedButton
        type='outlined'
        size='sm'
        value={refSignalSource}
        onChange={handleClick}>
        <SegmentedButton.Option name={0}>Internal</SegmentedButton.Option>
        <SegmentedButton.Option name={1}>External</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

export default RefSignalSource

const subtitle = `${h6} text-light-1 mb-1`
