import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Y({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.8595 15.6399L14.7795 3H19L13.0585 19.3924C12.9311 19.7522 12.758 20.1385 12.5395 20.551C12.3301 20.9636 12.0433 21.3542 11.679 21.7228C11.3239 22.1003 10.8732 22.4075 10.3268 22.6445C9.78959 22.8815 9.12943 23 8.34634 23C7.97301 23 7.66797 22.9781 7.43122 22.9342C7.19447 22.8903 6.9122 22.8288 6.58439 22.7498V19.9717C6.68455 19.9717 6.78927 19.9717 6.89854 19.9717C7.0078 19.9805 7.11252 19.9849 7.21268 19.9849C7.73171 19.9849 8.15512 19.9278 8.48293 19.8137C8.81073 19.6996 9.0748 19.524 9.27512 19.287C9.47545 19.0588 9.6348 18.7604 9.75317 18.3917L10.8595 15.6399ZM9.22049 3L12.4302 13.3226L12.9902 17.3384L10.3132 17.6149L5 3H9.22049Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
