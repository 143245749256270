import { call, put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import { _setUiControlOutputToIsStopped } from 'src/redux/sagas/handlers/event/freqConverter/sg/outputSettings'

export function* sgOutputSettingsApply(response) {
  try {
    yield console.log('!!!! sgOutputSettingsApply:', response)
  } catch (error) {
    devWarLog('[handler] deviceSetting error:', error)
  }
}

export function* sgOutputSettingsUpdateRepeatRounds(response) {
  try {
    const [sn, { repeatRounds: responseRepeatRounds }] = Object.entries(
      response?.data
    )[0]

    let { currentData } = yield call(getCommonArgs, sn)

    if (responseRepeatRounds.currentValue !== null) {
      currentData.settings.output.outputControls.repeatRounds.currentValue =
        responseRepeatRounds.currentValue

      if (responseRepeatRounds.currentValue === 0) {
        currentData.settings.output.isOutputting = false
        yield call(_setUiControlOutputToIsStopped, { sn })
      }

      yield put(setSingleDeviceData({ sn, data: currentData }))
    }
  } catch (error) {
    devWarLog('[handler] deviceSetting error:', error)
  }
}
