import { call } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'

import { devWarLog } from 'src/funcs/tools'

export function* updateCurrentClick({ sn }) {
  const { lookupID } = yield call(getCommonArgs, sn)
  yield call(__socket_API_sender, freqConverterApi.UPDATE_CURRENT, {
    sn,
    lookupID,
  })
  try {
  } catch (error) {
    devWarLog('[handler] getLO error:', error)
  }
}
