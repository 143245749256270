import {
  applyDeviceInfo as handleApplyDeviceInfoResponse,
  deviceInitGetInfo as handleDeviceInitGetInfoResponse,
  deviceReboot as handleDeviceReboot,
} from 'src/redux/sagas/handlers/response/facility/deviceInfo'

import {
  connect as handleConnect,
  disconnect as handleDisconnect,
  // connectError as handleConnectError,
} from 'src/redux/sagas/handlers/event/socket/facility'

const facilityApi = {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------      General       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  MODIFY_STATIC_IP: {
    emit: {
      server: 'facility',
      eventName: 'modifyStaticIP', //TODO: 有空回來重構 eventName:new event name = 'device/set/staticIP'
      beforeEmitActionType: null,
      throttle: false,
      timeout: 15000,
    },
    on: {
      eventName: 'modifyStaticIP/response',
      handler: handleApplyDeviceInfoResponse,
    },
  },

  DEVICE_GET_INFO: {
    emit: {
      server: 'facility',
      eventName: 'device/get/info',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'device/get/info/response',
      handler: handleDeviceInitGetInfoResponse,
    },
  },

  DEVICE_REBOOT: {
    emit: {
      server: 'facility',
      eventName: 'device/reboot',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'device/reboot/response',
      handler: handleDeviceReboot,
    },
  },

  // socket listener
  SOCKET_CONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'connect',
      handler: handleConnect,
    },
  },
  SOCKET_DISCONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'disconnect',
      handler: handleDisconnect,
    },
  },
  // SOCKET_CONNECT_ERROR: {
  //   on: {
  //     type: 'SOCKET_EVENT',
  //     eventName: 'connect_error',
  //     handler: handleConnectError,
  //   },
  // },
}

export default facilityApi
