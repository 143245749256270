import React from 'react'

const MergeSvg = props => {
  return (
    <svg
      width='149'
      height='95'
      viewBox='0 0 149 95'
      fill='none'
      className='absolute inset-x-0 top-2 mx-auto -z-10'
      xmlns='http://www.w3.org/2000/svg'>
      <line
        x1='-4.37114e-08'
        y1='19.5'
        x2='149'
        y2='19.5'
        stroke='white'
        strokeOpacity='0.5'
      />
      <path
        d='M77 40L74.1132 45L79.8868 45L77 40ZM76.5 44.5L76.5 95L77.5 95L77.5 44.5L76.5 44.5Z'
        fill='white'
        fillOpacity='0.5'
      />
      <circle cx='76' cy='20' r='20' fill='#222222' />
      <circle cx='76' cy='20' r='19.5' stroke='white' strokeOpacity='0.5' />
      <line
        x1='90.6393'
        y1='6.06791'
        x2='62.1291'
        y2='34.5781'
        stroke='white'
        strokeOpacity='0.5'
      />
      <line
        x1='62.0678'
        y1='5.3608'
        x2='90.578'
        y2='33.871'
        stroke='white'
        strokeOpacity='0.5'
      />
    </svg>
  )
}

export default MergeSvg
