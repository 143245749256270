// global
export const checkLocalMidAWSConnection = data => ({
  server: 'localMiddleware',
  method: 'GET',
  url: '/api/checkAWSConnection',
  timeout: 5000,
  isAutoDemo: true,
})
export const getLocalMidInfo = data => ({
  server: 'localMiddleware',
  method: 'GET',
  url: '/api/middlewareInfo',
  timeout: 5000,
  isAutoDemo: true,
})
// power detector
export const dashboardExportPowerHistoryApi = () => ({
  server: 'localMiddleware',
  method: 'POST',
  url: '/api/powerDetector/dashboard/power/export/history',
  timeout: 5000,
})

// cc - evb 2x2
export const cloverCellEvb2x2CurrentExportApi = () => ({
  server: 'localMiddleware',
  method: 'POST',
  url: '/api/clovercell/evb/operatingStatus/export/history',
  timeout: 5000,
})
