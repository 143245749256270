import * as R from 'ramda'

export const ipNoDuplicateCheck = (data, sn, value) =>
  R.pipe(
    R.dissoc(sn),
    R.values,
    R.intersection([value]),
    R.length,
    R.equals(0)
  )(data)

export const ipFormatCheck = value =>
  R.pipe(
    R.split('.'),
    R.filter(e => !e),
    R.length,
    R.equals(0)
  )(value)

export const getIpDataFromDeviceData = deviceData =>
  R.map(data => R.path(['info', 'connectionInfo', 'ip'], data))(deviceData)
