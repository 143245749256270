import React from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/freqConverter/style'
import CommonSelect from 'src/components/Select/CommonSelect'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

const OutputPowerStep = props => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const isOutputting = current.data.settings.output.isOutputting

  const { currentSelection, lstOptions } =
    current.data?.settings.output.powerStep

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleChange = (event, value, children) => {
    let newData = R.clone(current.data)
    newData.settings.output.powerStep.currentSelection = value
    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div>
      <h6 className={h6 + ' text-light-1 mb-1'}>
        Output Power Step ({R.head(lstOptions)}~{R.last(lstOptions)})
      </h6>
      <CommonSelect
        value={currentSelection}
        currentClassName=''
        size={isXL || is2XL ? 'sm' : 'md'}
        disabled={isOutputting}
        onChange={handleChange}>
        {lstOptions.map((e, i) => (
          <CommonSelect.Option key={`sg power step ${e}${i}`} name={i}>
            {i}
          </CommonSelect.Option>
        ))}
      </CommonSelect>
    </div>
  )
}

export default OutputPowerStep
