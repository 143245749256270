import React from 'react'

import HVModeChannelItem from 'src/containers/operating/device/beamformers/cloverCell/evb/Main/HVModeChannelItem'

const Channel = props => {
  // TODO: 有極化的時候要加 vertical 的 isShowLockMask 邏輯
  return (
    <div className='grid grid-cols-2 gap-x-4'>
      <HVModeChannelItem hvMode='horizon' />
      <HVModeChannelItem hvMode='vertical' isShowLockMask={false} />
    </div>
  )
}

Channel.propTypes = {}

export default Channel
