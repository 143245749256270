import React from 'react'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/beamformers/style'

import CommonButton from 'src/components/Button/CommonButton'
import Tooltip from 'src/components/Popup/Tooltip'

import { FiUpload } from 'react-icons/fi'
import { BiInfoCircle } from 'react-icons/bi'
import { AiOutlineClear } from 'react-icons/ai'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

import {
  cloverCellEvb_current_clearClick_watcher,
  cloverCellEvb_current_exportClick_watcher,
} from 'src/redux/actions/beamformers/cloverCellEvbAction'
import useGetUIControl from 'src/hooks/useGetUIControl'

const ExportOutputData = props => {
  const dispatch = useDispatch()
  const { isDemoMode } = useGetCurrentMode()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const { single: s_uiControl } = useGetUIControl(sn)

  const measureDataLength = current.data.current.measureDataLength

  const isNotRead = measureDataLength === 0

  const isOperatingStatusReading = s_uiControl.current.isOperatingStatusReading

  const isButtonDisabled = isNotRead || isOperatingStatusReading

  const handleClearClick = () =>
    dispatch(cloverCellEvb_current_clearClick_watcher({ sn }))
  const handleExportClick = () =>
    dispatch(cloverCellEvb_current_exportClick_watcher({ sn }))

  return (
    <>
      <h6 className={h6 + ' flex  justify-center gap-x-1 opacity-80 mt-9'}>
        Current {measureDataLength} records. Maximum 960 records
        <Tooltip
          placement='bottom'
          title='The system keeps 960 records in total. Overwrite the oldest one'>
          <BiInfoCircle className='cursor-pointer' />
        </Tooltip>
      </h6>

      <div className='grid grid-cols-2 gap-x-2 mt-2'>
        <ClearButton
          {...{
            isButtonDisabled,
            handleClearClick,
          }}
        />
        <ExportButton
          {...{
            isDemoMode,
            isButtonDisabled,
            handleExportClick,
          }}
        />
      </div>
    </>
  )
}

ExportOutputData.propTypes = {}

export default ExportOutputData

const ClearButton = ({ isButtonDisabled, handleClearClick }) => (
  <CommonButton
    size='md'
    type='outlined'
    danger
    className='w-full'
    icon={<AiOutlineClear />}
    disabled={isButtonDisabled}
    onClick={handleClearClick}>
    Clear
  </CommonButton>
)

const ExportButton = ({ isDemoMode, isButtonDisabled, handleExportClick }) =>
  isDemoMode ? (
    <Tooltip title='No support in demo mode'>
      <CommonButton
        size='md'
        className='w-full'
        type='outlined'
        icon={<FiUpload />}
        disabled={true}>
        Export output data
      </CommonButton>
    </Tooltip>
  ) : (
    <CommonButton
      size='md'
      type='outlined'
      className='w-full'
      icon={<FiUpload />}
      disabled={isButtonDisabled}
      onClick={handleExportClick}>
      Export output data
    </CommonButton>
  )
