import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import Frequency from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common/Frequency'
import Antenna from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common/Antenna'

import CommonButton from 'src/components/Button/CommonButton'
import CommonSwitch from 'src/components/Switch/CommonSwitch'
import Tooltip from 'src/components/Popup/Tooltip'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { BiSave } from 'react-icons/bi'

import { HiOutlineSave, HiOutlineUpload } from 'react-icons/hi'

import { modalActions } from 'src/redux/slices/modal'

import {
  bbox_spi_configSave_watcher,
  bbox_spi_switchMethod_watcher,
  bbox_spi_configImport_watcher,
  bbox_spi_configExport_watcher,
} from 'src/redux/actions/beamformers/bboxAction'
import useGetUIControl from 'src/hooks/useGetUIControl'

const Common = props => {
  const dispatch = useDispatch()

  const { isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const { isDemoMode } = useGetCurrentMode()

  const sn = current.sn

  const { single: s_uiControl } = useGetUIControl(sn)
  const s_spiUiControl = s_uiControl.beamConfigEditor

  const { controlMethod } = current.data.beamConfigEditor

  const { saveStatus, switchMethodStatus, isChanged } = s_spiUiControl

  const inputFileRef = useRef()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const handleSaveClick = () => dispatch(bbox_spi_configSave_watcher({ sn }))

  const handleSPIControlClick = () =>
    dispatch(bbox_spi_switchMethod_watcher({ sn }))

  const handleExportClick = () =>
    dispatch(bbox_spi_configExport_watcher({ sn }))

  const handleImportFileChange = async event => {
    try {
      if (event.target.files.length > 0) {
        //* import flow:
        //* handle function -> 讀檔案整成 data
        //* -> spiImportConfig(saga) -> 判斷是否合法, 跳 modal
        //* -> callImportApi(saga) -> 判斷 call 哪隻 API / call API + 處理 response
        //! 在這邊開 modal 而不是 saga  開的原因是因為
        //! 如果 spi config 檔案太大 FileReader 會 read 很久 (尤其是 windows)
        //! 這樣使用者就會看到一段空窗期，所以在這邊就直接開 importing modal
        dispatch(modalActions.showModalOfFileImporting())

        const fileData = event.target.files[0]

        const blob = new Blob([fileData], { type: fileData.type })
        const formData = new FormData()
        const fileName = fileData.name
        formData.append('file', blob, fileName)

        const { jsonFileData } = await readFileData(fileData)

        inputFileRef.current.value = await null

        await dispatch(
          bbox_spi_configImport_watcher({ sn, fileName, jsonFileData })
        )
      }
    } catch (error) {
      console.warn(error)
      dispatch(modalActions.showModalOfBBoxFailedToImportForIncorrectFormat())
    }
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='flex justify-between'>
        {/* level 1 */}
        <div className={row}>
          <CommonButton
            type='text'
            size={isMD ? 'lg' : 'sm'}
            icon={<BiSave />}
            onClick={handleSaveClick}
            disabled={!isChanged}
            loading={saveStatus === 'loading'}
            success={saveStatus === 'success'}>
            Save
          </CommonButton>

          <Tooltip
            title='Save before switching to SPI'
            disabled={!isChanged}
            placement='bottom'>
            <CommonSwitch
              type='text'
              onText='SPI Control'
              offText='SPI Control'
              size='md'
              on={controlMethod === 'SPI'}
              disabled={isChanged}
              loading={switchMethodStatus === 'loading'}
              onClick={handleSPIControlClick}
            />
          </Tooltip>
        </div>

        <div className={row}>
          <input
            type='file'
            className='hidden'
            accept='.json'
            ref={inputFileRef}
            onChange={handleImportFileChange}
          />
          <Tooltip title='No support in demo mode' disabled={!isDemoMode}>
            <CommonButton
              type='text'
              size={isMD ? 'lg' : 'sm'}
              icon={<HiOutlineSave />}
              disabled={controlMethod === 'SPI' || isDemoMode}
              onClick={() => inputFileRef.current.click()}>
              Import
            </CommonButton>
          </Tooltip>

          <div className={colDivider} />

          <Tooltip title='No support in demo mode' disabled={!isDemoMode}>
            <CommonButton
              type='text'
              size={isMD ? 'lg' : 'sm'}
              icon={<HiOutlineUpload />}
              disabled={controlMethod === 'SPI' || isDemoMode}
              onClick={handleExportClick}>
              Export
            </CommonButton>{' '}
          </Tooltip>
        </div>
      </div>

      <div className={rowDivider} />

      {/* level 2 */}
      <div className='grid grid-cols-2 gap-x-4'>
        <div>
          <h6 className={subtitle}>Central Frequency</h6>
          <Frequency disabled={controlMethod === 'SPI'} />
        </div>

        <div>
          <Antenna disabled={controlMethod === 'SPI'} />
        </div>
      </div>
    </div>
  )
}

export default Common

const container = `[BeamConfigEditor-Common-container] functionBlockBackground w-[632px] px-3 py-2 rounded-md`
const row = `[BeamConfigEditor-Common-row] flex items-center gap-x-4 p-2`
const subtitle = `[BeamConfigEditor-Common-subtitle] text-light-1 text-base lg:text-sm font-normal leading-5 mb-2 lg:mb-1`
const rowDivider = `[BeamConfigEditor-Common-rowDivider] w-full h-[1px] bg-dark-5 my-2`
const colDivider = `[BeamConfigEditor-Common-colDivider] h-full w-[1px] bg-dark-5`

const readFileData = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = e => {
      try {
        const fileContent = e.target.result
        const data = JSON.parse(fileContent)
        resolve({ jsonFileData: data })
      } catch (error) {
        reject(error) // 使用 reject 傳遞 JSON 解析錯誤
      }
    }

    reader.readAsText(file)
  })
