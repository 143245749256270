import React, { useEffect } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import TextArea from 'src/components/Input/TextArea'

import { modalActions } from 'src/redux/slices/modal'

import {
  ud_general_freqUnlockModal_cancelClick_watcher,
  ud_general_freqUnlockModal_enterClick_watcher,
} from 'src/redux/actions/freqConverter/generalAction'

import { BiKey } from 'react-icons/bi'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

const UnlockInput = ({ sn, digest, isRequesting, isFailed }) => {
  const dispatch = useDispatch()

  const { isDemoMode } = useGetCurrentMode()

  const handleCancelClick = () =>
    dispatch(ud_general_freqUnlockModal_cancelClick_watcher())

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(ud_general_freqUnlockModal_enterClick_watcher())
  }

  const handleDigestChange = result =>
    dispatch(
      modalActions.setModalOfUDUnlockDigest({
        digest: R.trim(result.formattedValue),
      })
    )

  useEffect(() => {
    if (isDemoMode)
      dispatch(
        modalActions.setModalOfUDUnlockDigest({
          digest:
            'FakeLicenseKeyZIu8PYPBbpSgcjOUW7iwXw6EwadhXYgBheHCdJVoa6QlItXW52dxEwRAdqXqnMfitI',
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal>
      <Modal.Icon>
        <BiKey className='text-white ' />
      </Modal.Icon>

      <Modal.Title>Unlock Frequency</Modal.Title>

      <Modal.Description>
        Enter the license key to unlock frequency
      </Modal.Description>

      <form className='mt-7' onSubmit={handleSubmit}>
        <Modal.FormLabel htmlFor='digest'>License Key</Modal.FormLabel>
        <TextArea
          id='digest'
          className='w-full'
          autoFocus
          value={digest}
          disabled={isRequesting}
          onChange={handleDigestChange}
        />
        <small className='text-sm text-white/50 block'>
          The system will reboot the device to activate.
        </small>

        {isFailed && (
          <small className={errorTextClass}>Invalid license key</small>
        )}

        <Modal.RowButtonBox>
          <CommonButton
            size='lg'
            type='general'
            disabled={isRequesting}
            onClick={handleCancelClick}>
            Cancel
          </CommonButton>
          <CommonButton
            buttonType='submit'
            size='lg'
            type='primary'
            loading={isRequesting}
            disabled={!digest}>
            Enter
          </CommonButton>
        </Modal.RowButtonBox>
      </form>
    </Modal>
  )
}

export default UnlockInput

const errorTextClass = `text-sm text-red`
