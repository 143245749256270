import { call, put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { deviceSetting as handleDeviceSettingResponse } from 'src/redux/sagas/handlers/response/freqConverter/udbox/device'

import { devWarLog } from 'src/funcs/tools'

import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'

export function* deviceSetting(payloads) {
  try {
    const { sn, label, value } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    currentData.settings.device[label] = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (label === 'SOURCE_100M' && value === 1)
      yield put(uiControlAction.setUD5gRefSourceIsRequesting({ sn }))

    const acknowledgement = yield call(
      __socket_API_sender,
      freqConverterApi.DEVICE_SETTINGS,
      {
        sn,
        lookupID,
        label,
        value,
      }
    )

    if (acknowledgement?.isDemoMode !== undefined)
      yield call(handleDeviceSettingResponse, acknowledgement)
  } catch (error) {
    devWarLog('[handler] deviceSetting error:', error)
  }
}
