import { getDefaultCurrentFreq, getLstFreqOptions } from '../spec.mjs'
import * as R from 'ramda'
import Controllers from '../../Controllers/index.mjs'

const getDT = sn => Controllers._getDeviceType(sn)

const beamData = {
  horizon: {
    power: true,
    theta: 0,
    thetaMax: 60, // 沒天線所以要先定義
    phi: 0,
    beamCurrentGain: 0,
    beamGainMin: 0,
    beamGainMax: 15,
  },
  vertical: {
    power: true,
    theta: 0,
    thetaMax: 60, // 沒天線所以要先定義
    phi: 0,
    beamCurrentGain: 0,
    beamGainMin: 0,
    beamGainMax: 15,
  },
}

export const cloverCellCTTCInitAppendData = (sn, aipCount) => ({
  deviceControl: {
    common: {
      currentFreq: getDefaultCurrentFreq[getDT(sn)],
      lstFreqOptions: getLstFreqOptions[getDT(sn)],
      refSource: 0, // 0, 1
    },
    steering: {
      controlPattern: [1, 2, 3, 4], // 1, 2, 3, 4 (beamIndex)
      isBeamSynthesis: false,
      synthesizedBeamData: {
        rfMode: 'tx',
        tx: R.clone(beamData),
        rx: R.clone(beamData),
      },
      separateBeamsData: new Array(aipCount).fill(null).map((_, i) => ({
        beamIndex: +aipCount === 1 ? 3 : i + 1,
        rfMode: 'tx',
        tx: R.clone(beamData),
        rx: R.clone(beamData),
      })),
    },
  },
})
