import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import CommonButton from 'src/components/Button/CommonButton'
import Mask from 'src/components/Mask'

import InstructionMask from 'src/components/composite/beamformers/BeamSteeringModule/ForBBox/InstructionMask'

import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { bbox_steering_channelModeMaskClose_watcher } from 'src/redux/actions/beamformers/bboxAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { RotatingLines } from 'react-loader-spinner'
import useGetUIControl from 'src/hooks/useGetUIControl'

const OverlayMask = ({ sn, currentControlMode }) => {
  const { current } = useGetCurrentDeviceData()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const { all: all_uiControl, single: s_uiControl } = useGetUIControl(sn)

  const { showFirst3DInstructionMask, showManual3DInstructionMask } =
    s_uiControl.deviceControl
  const { dontShowAgainForSteeringMask } = all_uiControl
  const {
    channelMode: dontShowCHModeMask,
    instruction: dontShowInstructionMask,
  } = dontShowAgainForSteeringMask

  const currentAntenna = current?.data?.deviceControl?.common?.currentAntenna
  const rfMode = current?.data?.deviceControl?.common?.rfMode

  const showLoadingMask = s_uiControl.deviceControl.showLoadingMask.steering

  const showChannelModeMask =
    currentControlMode[rfMode] === 'channel' && !dontShowCHModeMask

  const showManualInstructionMask =
    currentAntenna && showManual3DInstructionMask
  const showFirstInstructionMask =
    currentAntenna && !dontShowInstructionMask && showFirst3DInstructionMask

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  if (showLoadingMask) return <LoadingMask />

  if (showChannelModeMask)
    return (
      <ChannelModeMask
        {...{
          sn,
          dontShowCHModeMask,
        }}
      />
    )

  if (showManualInstructionMask || showFirstInstructionMask)
    return (
      <InstructionMask
        {...{
          sn,
          showFirstInstructionMask,
          showManual3DInstructionMask,
        }}
      />
    )

  return <></>
}

OverlayMask.propTypes = { sn: PropTypes.string }

export default OverlayMask

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `
                  w-full h-full
                  p-8
                  flex flex-col
                  items-center justify-center
                  text-base font-normal leading-5
                  text-white text-center
                  `

const messageContainer = `max-w-[345px] mx-auto text-white text-center`

const h6 = `text-light-4 text-base font-bold text-center`
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const LoadingMask = () => (
  <Mask>
    <div className='w-full h-full flex flex-col items-center justify-center gap-y-10'>
      <RotatingLines
        strokeColor='white'
        strokeWidth='3'
        animationDuration='0.75'
        width='56'
        visible={true}
      />
      <h6 className={h6}>Synchronizing changes</h6>
    </div>
  </Mask>
)

const ChannelModeMask = ({ sn, dontShowCHModeMask }) => {
  const dispatch = useDispatch()
  const { isDesktop } = useGetScreenSize()
  const [isCHModePointerEnter, setIsCHModePointerEnter] = useState(false)
  const [isCHModeClicked, setIsCHModeClicked] = useState(false)

  const isPC = isDesktop

  const isPCRenderContent1 = isPC && !isCHModePointerEnter && !isCHModeClicked

  const _isEnterOrClicked = isCHModePointerEnter || isCHModeClicked
  const isPCRenderContent2 = isPC && _isEnterOrClicked

  const _mobileAndPadRenderBoth = !isPC
  const renderContent1 = _mobileAndPadRenderBoth || isPCRenderContent1
  const renderContent2 = _mobileAndPadRenderBoth || isPCRenderContent2

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleChannelModePointerEnter = () => {
    if (!dontShowCHModeMask) setIsCHModePointerEnter(true)

    if (dontShowCHModeMask) {
      dispatch(
        bbox_steering_channelModeMaskClose_watcher({
          sn,
          dontShowAgain: true,
        })
      )
      setIsCHModePointerEnter(false)
    }
  }
  const handleChannelModePointerLeave = () => setIsCHModePointerEnter(false)
  const handleChannelModeClick = () => setIsCHModeClicked(prev => !prev)

  const handleChannelModeButtonClick = dontShowAgain => {
    dispatch(bbox_steering_channelModeMaskClose_watcher({ sn, dontShowAgain }))

    setIsCHModePointerEnter(false)
  }

  return (
    <Mask
      onClick={handleChannelModeClick}
      onPointerEnter={handleChannelModePointerEnter}
      onPointerLeave={handleChannelModePointerLeave}>
      <div className={container}>
        {renderContent1 && (
          <div className={messageContainer + ' font-bold lg:font-normal'}>
            Manually adjusted CH values will not be simulated
          </div>
        )}

        {_mobileAndPadRenderBoth && <br />}

        {renderContent2 && (
          <>
            <div className={messageContainer}>
              Using beam steering simulation, the system will calculate the
              value of each CH.
            </div>

            <br />

            <div>Your edits will be overwritten.</div>

            <CommonButton
              size='md'
              type='general'
              className='mt-10 mb-[130px]'
              onClick={() => handleChannelModeButtonClick(false)}>
              OK
            </CommonButton>

            <CommonButton
              size='md'
              type='outlined'
              onClick={() => handleChannelModeButtonClick(true)}>
              Don't show again
            </CommonButton>
          </>
        )}
      </div>
    </Mask>
  )
}
