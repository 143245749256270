import { current } from '@reduxjs/toolkit'
import * as R from 'ramda'

const powerDetector = {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------   Action Panel     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  show_modal_powerDetector_actionPanel_caliConfigFileChanged: (
    state,
    action
  ) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'caliConfigFileChanged',
      },
    }
    return state
  },

  show_modal_powerDetector_actionPanel_caliConfigFileChanging: (
    state,
    action
  ) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'caliConfigFileChanging',
      },
    }
    return state
  },

  show_modal_powerDetector_actionPanel_changeAnotherCaliConfigFile: (
    state,
    action
  ) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'changeAnotherCaliConfigFile',
      },
    }
    return state
  },

  show_modal_powerDetector_actionPanel_failedToChangeCaliConfig: (
    state,
    action
  ) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'failedToChangeCaliConfig',
      },
    }
    return state
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Dashboard      -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  show_modal_powerDetector_dashboard_clearReadData: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'dashboardClearReadData',
      },
    }
    return state
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------    Custom Cali     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // CC = (custom calibration)
  show_modal_of_powerDetector_CC: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        sn,
        selectIdList: [],
        editIsChanged: false,
        editIsCreate: false,
        editSaveIsRequesting: false,
        editTemporaryData: {},
        childModalType: '',
        editGetVoltageList: [],
        editIsGetVolError: false,
      },
    }
    return state
  },

  // 進入編輯模式
  // 如果是按 create 過來的，editIsCreate = true
  set_modal_of_powerDetector_CC_to_edit: (state, action) => {
    const { temporaryData, editIsCreate } = action.payload
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        editIsCreate,
        editTemporaryData: temporaryData,
      },
    }
    return state
  },

  // 離開編輯模式用這個
  set_modal_of_powerDetector_CC_leave_edit: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        selectIdList: [],
        editIsChanged: false,
        editIsCreate: false,
        editSaveIsRequesting: false,
        isDeleteRequesting: false,
        editTemporaryData: {},
        childModalType: '',
        editGetVoltageList: {}, // editGetVoltageList = {'100MHz': ['lowVolt', 'highVolt']}
        editIsGetVolError: false,
      },
    }
    return state
  },

  // 編輯模式時，畫面上輸入後更新 temporaryData 用這個
  set_temporaryData_for_modal_of_powerDetector_CC: (state, action) => {
    const editTemporaryData = action.payload?.editTemporaryData
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        editIsChanged: true,
        editTemporaryData,
      },
    }
    return state
  },

  add_getVoltageList_for_modal_of_powerDetector_CC: (state, action) => {
    // ex: action.payload = {freq: 'xxxMHz', target: 'lowVolt'}
    const { freq, target } = action.payload

    const notHaveAction =
      current(state).normal.props.editGetVoltageList?.[freq]?.includes(target)

    if (notHaveAction) return state

    const isAddNewFreq =
      current(state).normal.props.editGetVoltageList?.[freq] === undefined ||
      current(state).normal.props.editGetVoltageList?.[freq].length === 0

    if (isAddNewFreq)
      state = R.modifyPath(
        ['normal', 'props', 'editGetVoltageList'],
        R.assoc(freq, [target])
      )(current(state))
    else
      state = R.assocPath(
        ['normal', 'props', 'editGetVoltageList', freq],
        ['lowVolt', 'highVolt']
      )(current(state))

    return state
  },
  remove_getVoltageList_for_modal_of_powerDetector_CC: (state, action) => {
    // ex: action.payload = {freq: 'xxxMHz', target: 'lowVolt'}
    const { freq, target } = action.payload

    state = R.modifyPath(
      ['normal', 'props', 'editGetVoltageList', freq],
      R.filter(e => e !== target)
    )(current(state))

    return state
  },

  set_selectIdList_for_modal_of_powerDetector_CC: (state, action) => {
    const selectIdList = action.payload?.selectIdList
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        selectIdList,
      },
    }
    return state
  },

  set_isChanged_for_modal_of_powerDetector_CC: (state, action) => {
    const editIsChanged = action.payload?.editIsChanged
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        editIsChanged,
      },
    }
    return state
  },

  set_editSaveIsRequesting_for_modal_of_powerDetector_CC: (state, action) => {
    const editSaveIsRequesting = action.payload?.editSaveIsRequesting
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        editSaveIsRequesting,
      },
    }
    return state
  },

  set_editIsGetVolError_of_powerDetector_CC: (state, action) => {
    const { value } = action.payload
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        editIsGetVolError: value,
      },
    }
    return state
  },

  set_isDeleteIsRequesting_for_modal_of_powerDetector_CC: (state, action) => {
    const isDeleteRequesting = action.payload?.isDeleteRequesting
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        isDeleteRequesting,
      },
    }
    return state
  },

  show_childModal_powerDetector_CC_clearIncompleteData: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'clearIncompleteData',
      },
    }
    return state
  },

  show_childModal_powerDetector_CC_saveCalibrationConfigReminder: (
    state,
    action
  ) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'saveCalibrationConfigReminder',
      },
    }
    return state
  },

  show_childModal_of_powerDetector_CC_listModeDeleteCheck: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'listModeDeleteCheck',
      },
    }
    return state
  },

  show_childModal_of_powerDetector_CC_editModeDeleteCheck: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'editModeDeleteCheck',
      },
    }
    return state
  },

  show_childModal_of_powerDetector_CC_leaveEditCheck: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'leaveEditCheck',
      },
    }
    return state
  },

  show_childModal_of_powerDetector_CC_usingNotDeleteCheck: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'usingNotDeleteCheck',
      },
    }
    return state
  },

  show_childModal_of_powerDetector_CC_caliConfigFileSaved: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: 'caliConfigFileSaved',
      },
    }
    return state
  },

  clear_childModal_of_powerDetector_CC: (state, action) => {
    state.normal = {
      type: 'POWER_DETECTOR',
      props: {
        status: 'customCalibration',
        ...current(state).normal.props,
        childModalType: '',
      },
    }
    return state
  },
}
export default powerDetector
