import React from 'react'
import { useDispatch } from 'react-redux'

import CommonSelect from 'src/components/Select/CommonSelect'
import CommonButton from 'src/components/Button/CommonButton'

import { BiTrash, BiSortDown } from 'react-icons/bi'

import { bbox_customAntennaModal_deleteClick_watcher } from 'src/redux/actions/beamformers/bboxAction'

import { setCustomAntennaSortRule } from 'src/redux/slices/uiControl/beamformers/bbox'
import useGetUIControl from 'src/hooks/useGetUIControl'

const FunctionBar = ({ selectIdList }) => {
  const dispatch = useDispatch()

  const isDisabled = selectIdList.length === 0

  const { all: all_uiControl } = useGetUIControl()
  const { customAntennaListSortRule } = all_uiControl

  const handleDeleteClick = () =>
    dispatch(bbox_customAntennaModal_deleteClick_watcher(selectIdList))

  const handleSortChange = (event, value) =>
    dispatch(setCustomAntennaSortRule({ value }))

  //! phase2 沒有 export 所以先註解
  return (
    <div className={functionBar}>
      <div className='py-1 flex items-center'>
        <CommonButton
          className='min-w-[92px]'
          type='text'
          icon={<BiTrash />}
          size='md'
          disabled={isDisabled}
          danger
          onClick={() => !isDisabled && handleDeleteClick()}>
          Delete
        </CommonButton>
      </div>

      <CommonSelect
        value={customAntennaListSortRule}
        currentChildren={customAntennaListSortRule}
        size='md'
        icon={<BiSortDown />}
        className='w-[216px] '
        optionsContainerClassName='z-10'
        onChange={handleSortChange}>
        <CommonSelect.Option name='Latest to oldest'>
          Latest to oldest
        </CommonSelect.Option>
        <CommonSelect.Option name='Oldest to latest'>
          Oldest to latest
        </CommonSelect.Option>
      </CommonSelect>
    </div>
  )
}

FunctionBar.propTypes = {}

export default FunctionBar

const functionBar = `w-full h-8 flex justify-between`

// const functionItem = ({ disabled, textColor }) => `
//                     w-full
//                     flex
//                     justify-center items-center
//                     gap-x-2
//                     duration-300 select-none
//                     ${h4}
//                     ${
//                       disabled
//                         ? ` cursor-not-allowed text-dark-5 `
//                         : ` cursor-pointer ${textColor} `
//                     }
//                     `

// const divider = `h-full w-[1px] bg-dark-5`
