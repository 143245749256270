import React from 'react'
import { useDispatch } from 'react-redux'

import {
  box,
  subtitle,
} from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/style'

import { ud0630_deviceSettings_inputSource_change_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import CommonButton from 'src/components/Button/CommonButton'

const InputSource = () => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const refSource = current.data.settings.device?.refSource
  const currentSelection =
    current.data.settings.device?.inputRefSource?.currentSelection

  const isInternal = +refSource === 0

  const handleClick = () =>
    dispatch(ud0630_deviceSettings_inputSource_change_watcher({ sn }))

  if (isInternal) return <></>

  return (
    <div className={box}>
      <h4 className={subtitle}>Input Source</h4>

      <CommonButton
        size={isSM || isMD ? 'md' : 'sm'}
        type='outlined'
        className='w-full'
        onClick={handleClick}>
        {currentSelection}
      </CommonButton>
    </div>
  )
}
export default InputSource
