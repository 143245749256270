import axios from 'axios'
import { getHost } from 'src/redux/sagas/services/url'
import { call } from 'redux-saga/effects'
import { isDeveloping } from 'src/funcs/getEnv'

import { _demoSuccessRes, getIsDemoMode } from 'src/redux/sagas/funcs/general'
import Controllers from 'src/server/Controllers/index.mjs'
//* [AJAX] MIDDLEWARE ---------------------
export default function* __restful_API_sender({ api, data }) {
  try {
    const isDemoMode = yield call(getIsDemoMode)

    const { url, isAutoDemo } = api(data, isDemoMode)

    const demoKey = `r_${url}`

    if (!isDemoMode || !isAutoDemo) {
      yield call(feAjaxDevLog, { url, data })

      const response = yield call(_apiPkg, api({ data, ...api }))

      yield call(beAjaxDevLog, {
        url,
        response: response?.data?.data,
      })
      return response
    } else if (isDemoMode && Controllers?.[demoKey]) {
      const response = Controllers?.[demoKey]({ req: data })

      return _demoSuccessRes(response)
    } else {
      throw Error('Send "API" failed or "Demo Controller" not found')
    }
  } catch (errorMsg) {
    console.log(`Error: `, errorMsg)
  }
}

export const feAjaxDevLog = ({ url, data }) => {
  if (isDeveloping) {
    console.groupCollapsed(
      '%c༼ つ◕_◕ ༽つ  ' + url + ' [FE][Restful]',
      'color: rgba(255,150,150,0.7); font-weight: 900'
    )
    console.log('[FE] Request: ')
    console.dir(data || '-- No Data --')
    console.count('[FE] Request count')
    console.groupEnd()

    console.time('[BE] Timer')
  }
}
export const beAjaxDevLog = ({ url, response }) => {
  if (isDeveloping) {
    console.groupCollapsed(
      '%cლ(・´ｪ`・ლ)  ' + url + ' [BE][Restful]',
      'color: rgba(150,150,255,0.7); font-weight: 900'
    )
    console.log('[BE] Response:')
    console.dir(_demoSuccessRes(response))
    console.count('[BE] Response count')
    console.timeEnd('[BE] Timer')
    console.groupEnd()
  }
}

export const _apiPkg = ({
  server = '',
  method = 'GET',
  url = '/',
  data = null,
  timeout = 5000,
  config = {},
  tool = 'axios',
}) => {
  const baseURL = getHost('ajax', server)
  if (tool === 'axios') {
    const instance = axios.create({ baseURL, timeout })
    const l_method = method.toLocaleLowerCase()
    if (method === 'GET') return instance[l_method](url, config)
    if (method !== 'GET') return instance[l_method](url, data, config)
  }

  if (tool === 'fetch') {
    return fetch(baseURL + url, {
      method,
      body: JSON.stringify(data),
      ...config,
    })
      .then(response => response)
      .catch(error => error.response)
  }
}

// ngf = not generator function
export const __ngf_restful_API_sender = ({ api, data }) => {
  const {
    server = '',
    method = 'GET',
    url = '/',
    timeout = 5000,
    config = {},
  } = api()
  const baseURL = getHost('ajax', server)
  const instance = axios.create({ baseURL, timeout })
  const l_method = method.toLocaleLowerCase()
  if (method === 'GET') return instance[l_method](url, config)
  if (method !== 'GET') return instance[l_method](url, data, config)
}
