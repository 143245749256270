import React from 'react'

import CommonInput from 'src/components/Input/CommonInput'

import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import { appendComma } from 'src/funcs/tools'

const OFR = ({ OFRMinValue, OFRMaxValue }) => {
  const { isSM, isMD } = useGetScreenSize()

  return (
    <div className='w-full'>
      <h6 className={label + ' mb-2'}>Occupied Frequency Range</h6>
      <CommonInput
        type='text'
        size={isSM || isMD ? 'md' : 'sm'}
        inputClassName='bg-transparent'
        value={`${appendComma(OFRMinValue)} - ${appendComma(OFRMaxValue)} MHz`}
        onChange={() => {}}
        disabled
      />
    </div>
  )
}

export default OFR

const label = `${h6} text-light-1`
