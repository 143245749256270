import React from 'react'
import { useDispatch } from 'react-redux'
import Gain from 'src/components/composite/InputGroupAppendDialog/Gain'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCellCTTC_steering_thetaPhiGainChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const RowGain = ({ beamIndex }) => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const isBeamSynthesis = current.data?.deviceControl?.steering.isBeamSynthesis
  const controlPattern = current.data?.deviceControl?.steering.controlPattern
  const isOn = beamIndex === null || controlPattern.includes(beamIndex)

  const beamData = isBeamSynthesis
    ? current.data?.deviceControl?.steering.synthesizedBeamData
    : current.data?.deviceControl?.steering.separateBeamsData.find(
        e => e.beamIndex === beamIndex
      )

  const rfMode = beamData?.rfMode

  const standbySafe = value => (rfMode === 'standby' ? null : value)

  const h_value = standbySafe(beamData[rfMode]?.['horizon'].beamCurrentGain)
  const h_min = standbySafe(beamData[rfMode]?.['horizon'].beamGainMin)
  const h_max = standbySafe(beamData[rfMode]?.['horizon'].beamGainMax)
  const h_power = standbySafe(beamData[rfMode]?.['horizon'].power)
  const h_isOff = !isOn || !h_power || rfMode === 'standby'

  const v_value = standbySafe(beamData[rfMode]?.['vertical'].beamCurrentGain)
  const v_min = standbySafe(beamData[rfMode]?.['vertical'].beamGainMin)
  const v_max = standbySafe(beamData[rfMode]?.['vertical'].beamGainMax)
  const v_power = standbySafe(beamData[rfMode]?.['vertical'].power)
  const v_isOff = !isOn || !v_power || rfMode === 'standby'

  const handleChange = ({ value, isValid, hvMode }) =>
    dispatch(
      cloverCellCTTC_steering_thetaPhiGainChange_watcher({
        sn,
        beamIndex: beamIndex || null,
        label: 'beamCurrentGain',
        hvMode,
        value,
        isValid,
      })
    )
  return (
    <div className='grid grid-cols-2 gap-x-4'>
      <Gain
        value={h_value}
        gainMin={h_min}
        gainMax={h_max}
        size='md'
        off={h_isOff}
        onChange={({ value, isValid }) =>
          handleChange({ value, isValid, hvMode: 'horizon' })
        }
      />
      <Gain
        value={v_value}
        gainMin={v_min}
        gainMax={v_max}
        size='md'
        off={v_isOff}
        onChange={({ value, isValid }) =>
          handleChange({ value, isValid, hvMode: 'vertical' })
        }
      />
    </div>
  )
}

export default RowGain
