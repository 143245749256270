const fileImport = {
  // file import
  showModalOfFileImporting: (state, action) => {
    state.normal = {
      type: 'FILE_IMPORT',
      props: { status: 'importing', filesCountMax: 0, failedList: [] },
    }
    return state
  },
  showModalOfFileImportSuccess: (state, action) => {
    state.normal = {
      type: 'FILE_IMPORT',
      props: { status: 'success', filesCountMax: 0, failedList: [] },
    }
    return state
  },
  showModalOfFileImportFailedCheck: (state, action) => {
    const failedList = action.payload?.failedList

    state.normal = {
      type: 'FILE_IMPORT',
      props: {
        status: 'failed',
        filesCountMax: 0,
        failedList: failedList ?? [],
      },
    }
    return state
  },
  showModalOfFileImportExceededMax: (state, action) => {
    const filesCountMax = action.payload?.filesCountMax
    state.normal = {
      type: 'FILE_IMPORT',
      props: {
        status: 'exceededMax',
        filesCountMax: filesCountMax ?? 0,
        failedList: [],
      },
    }
    return state
  },
}
export default fileImport
