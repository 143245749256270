const middlewareClass = {
  _getRandomInt(max) {
    return Math.floor(Math.random() * max)
  },

  _powerDetectorReadPowerOnce(sn) {
    try {
      const currentData = this._getCurrentData(sn)

      const newPower = (-30 + Math.random()).toFixed(2)

      currentData.deviceControl.dashboard.measureData = [
        { point: 0, power: newPower },
        ...currentData.deviceControl.dashboard.measureData,
      ].map((e, i) => ({ point: i + 1, ...e }))

      if (currentData.deviceControl.dashboard.measureData.length > 960)
        currentData.deviceControl.dashboard.measureData.pop()

      this._updateSingleDeviceData(sn, currentData)

      return {
        power: newPower,
        measureDataLength:
          currentData.deviceControl.dashboard.measureData.length,
      }
    } catch (error) {
      console.warn(error)
    }
  },

  _ccEVBOperaStatusReadPowerOnce(sn) {
    try {
      evbMeasureDataLength = evbMeasureDataLength + 1

      const response = {
        measureDataLength: evbMeasureDataLength,
        temperature: {
          currentValue: this._getRandomInt(77),
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
        pdt: {
          horizon: {
            currentValue: [
              this._getRandomInt(77),
              this._getRandomInt(77),
              this._getRandomInt(77),
              this._getRandomInt(77),
            ],
            history: [[], [], [], []], // evb: BE Only , 之後畫圖 FE BE 都會用
          },
          vertical: {
            currentValue: [
              this._getRandomInt(77),
              this._getRandomInt(77),
              this._getRandomInt(77),
              this._getRandomInt(77),
            ],
            history: [[], [], []], // evb: BE Only , 之後畫圖 FE BE 都會用
          },
        },
        powerConsumption: {
          vdd1v8: {
            current: {
              currentValue: this._getRandomInt(77),
              history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
            },
            voltage: {
              currentValue: this._getRandomInt(77),
              history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
            },
            power: {
              currentValue: this._getRandomInt(77),
              history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
            },
          },
          vddPa: {
            current: {
              currentValue: this._getRandomInt(77),
              history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
            },
            voltage: {
              currentValue: this._getRandomInt(77),
              history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
            },
            power: {
              currentValue: this._getRandomInt(77),
              history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
            },
          },
        },
      }

      return { [sn]: response }
    } catch (error) {
      console.warn(error)
    }
  },

  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* -----------------------         Global          -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  'r_/api/checkAWSConnection'({ req, res }) {
    return { code: 0 }
  },

  'r_/api/middlewareInfo'({ req, res }) {
    return {
      lookupID: 'e92c1eee-138f-4021-8bcc-a84e9bb3655a_Leamon_Lee-NB',
      clientHostname: 'Leamon_Lee-NB',
      clientDescription: "Leamon's Computer",
      sid: 'BJvcigbW5jr8qG4OAAAB',
      'BE-TLK-MIDDLEWARE': 'v7.7.7.1',
      'FW-COMM-API': 'v4.4.4.4',
    }
  },

  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* -----------------------           PD            -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  s_initPDLocalThread({ payloads }) {
    // const { sn } = payloads
    return null
  },

  's_actionPanel/set/currentFreq'({ payloads }) {
    try {
      const { sn, value } = payloads
      const currentData = this._getCurrentData(sn)
      currentData.deviceControl.actionPanel.currentFreq = value
      this._updateSingleDeviceData(sn, currentData)
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/set/readMethod'({ payloads }) {
    try {
      const { sn, value } = payloads
      const currentData = this._getCurrentData(sn)
      currentData.deviceControl.actionPanel.readMethod = value
      this._updateSingleDeviceData(sn, currentData)
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/set/updateRate'({ payloads }) {
    try {
      const { sn, value } = payloads
      const currentData = this._getCurrentData(sn)
      currentData.deviceControl.actionPanel.updateRate = value
      this._updateSingleDeviceData(sn, currentData)
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/set/average'({ payloads }) {
    try {
      const { sn, value } = payloads
      const currentData = this._getCurrentData(sn)
      currentData.deviceControl.actionPanel.average = value
      this._updateSingleDeviceData(sn, currentData)
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_actionPanel/activate/readControl'({ payloads, emit, acknowledgement }) {
    try {
      // 先回這隻 success
      emit('actionPanel/activate/readControl/response', null)
      acknowledgement(0)

      // 後續由 BE 主動發 dashboard/render/point 更新 chart
      const { sn, readMethod, action } = payloads
      if (readMethod === 'single') {
        const { power, measureDataLength } =
          this._powerDetectorReadPowerOnce(sn)
        emit('dashboard/render/point/response', {
          [sn]: { power, measureDataLength },
        })
      }

      if (readMethod === 'continuous' && action === 'start') {
        pdPowerReadInterval[sn] = setInterval(() => {
          const { power, measureDataLength } =
            this._powerDetectorReadPowerOnce(sn)
          emit('dashboard/render/point/response', {
            [sn]: { power, measureDataLength },
          })
        }, mockUpdateRate)
      }

      if (readMethod === 'continuous' && action === 'stop') {
        clearInterval(pdPowerReadInterval[sn])
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_dashboard/clear/power/history'({ payloads }) {
    try {
      const { sn } = payloads
      const currentData = this._getCurrentData(sn)

      currentData.deviceControl.dashboard.measureData = []

      this._updateSingleDeviceData(sn, currentData)
      return { sn }
    } catch (error) {
      console.warn(error)
    }
  },

  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* -----------------------  clover cell - evb 2x2  -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  s_initCloverCellEVBLocalThread({ payloads }) {
    return null
  },
  's_clovercell/evb/operatingStatus/set/readMethod'({ payloads }) {
    // const { sn, value } = payloads
    return null
  },
  's_clovercell/evb/operatingStatus/activate/readControl'({
    payloads,
    emit,
    acknowledgement,
  }) {
    try {
      // 先回這隻 success
      emit('clovercell/evb/operatingStatus/activate/readControl/response', null)
      acknowledgement(0)

      // 後續由 BE 主動發 clovercell/evb/operatingStatus/update/data/response 更新 chart
      const { sn, readMethod, action } = payloads
      if (readMethod === 'single')
        emit(
          'clovercell/evb/operatingStatus/update/data/response',
          this._ccEVBOperaStatusReadPowerOnce(sn)
        )

      if (readMethod === 'continuous' && action === 'start') {
        evbOperatingStatusReadInterval[sn] = setInterval(
          () =>
            emit(
              'clovercell/evb/operatingStatus/update/data/response',
              this._ccEVBOperaStatusReadPowerOnce(sn)
            ),
          mockUpdateRate
        )
      }
      if (readMethod === 'continuous' && action === 'stop') {
        clearInterval(evbOperatingStatusReadInterval[sn])
      }
    } catch (error) {
      console.warn(error)
    }
  },
  's_clovercell/evb/operatingStatus/clear/data'({ payloads }) {
    try {
      evbMeasureDataLength = 0
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* -----------------------           SG            -----------------------
  //* ----------------------- ----------------------- -----------------------
  //* ----------------------- ----------------------- -----------------------
  's_sg/outputSettings/activate/output'({ payloads, emit, acknowledgement }) {
    try {
      const { sn, output } = payloads
      const currentData = this._getCurrentData(sn)

      emit('sg/outputSettings/apply/response', null)
      acknowledgement(0)

      // 更新 BE data
      currentData.settings.output = output

      const isOutputting = currentData.settings.output.isOutputting

      const repeatRounds =
        currentData.settings.output.outputControls.repeatRounds.currentValue

      const sweepTimeStep =
        currentData.settings.output.outputControls.sweepTimeStep.currentValue

      // stop
      // stop
      // stop
      // stop
      // stop
      if (isOutputting === false) {
        clearInterval(sgOutputInterval[sn])

        emit('sg/outputSettings/update/repeatRounds/response', {
          [sn]: {
            repeatRounds: {
              currentValue: repeatRounds === null ? null : 0,
            },
          },
        })
      }

      // start
      // start
      // start
      // start
      // start
      if (isOutputting === true) {
        sgOutputInterval[sn] = setInterval(() => {
          if (repeatRounds === null)
            emit('sg/outputSettings/update/repeatRounds/response', {
              [sn]: {
                repeatRounds: { currentValue: null },
              },
            })

          if (repeatRounds !== null) {
            const newRepeatRounds =
              +currentData.settings.output.outputControls.repeatRounds
                .currentValue - 1

            currentData.settings.output.outputControls.repeatRounds.currentValue =
              newRepeatRounds

            emit('sg/outputSettings/update/repeatRounds/response', {
              [sn]: {
                repeatRounds: { currentValue: newRepeatRounds },
              },
            })

            if (newRepeatRounds === 0) clearInterval(sgOutputInterval[sn])
          }
        }, sweepTimeStep || 30)
      }
    } catch (error) {
      console.warn(error)
    }
  },
}

export default middlewareClass

let pdPowerReadInterval = {}
let mockUpdateRate = 1000

let evbOperatingStatusReadInterval = {}
let evbMeasureDataLength = 0

let sgOutputInterval = {}
