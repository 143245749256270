import React from 'react'

import Main from 'src/containers/Header/Main'
import CurrentDevice from 'src/containers/Header/CurrentDevice'

const Header = () => (
  <header className={headerClass}>
    <Main />

    <CurrentDevice />
  </header>
)

Header.propTypes = {}

export default Header

const headerClass = `[Facility-header]
                    fixed 
                    z-10
                    inset-x-0 
                    flex flex-col
                    `
