import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { textBox, subtitle } from 'src/containers/home/style'

import IpInput from 'src/components/Input/IpInput'
import Tooltip from 'src/components/Popup/Tooltip'

import { BiInfoCircle } from 'react-icons/bi'

import {
  setIsIpChanged,
  tempIpUpdateForChange,
} from 'src/redux/slices/uiControl/facility/home'

import {
  ipNoDuplicateCheck,
  ipFormatCheck,
  getIpDataFromDeviceData,
} from 'src/funcs/ip'

const StaticIp = ({ title, sn }) => {
  const dispatch = useDispatch()
  const inputRef = useRef([])
  const temporaryIpValue = useSelector(state => state.temporaryIpData[sn])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const temporaryIpData = useSelector(state => state.temporaryIpData)
  const deviceData = useSelector(state => state.deviceData)

  // -> {sn: ip, ...}
  const ipDataFromDeviceData = getIpDataFromDeviceData(deviceData)

  // 判斷當前 "staticIP" 是否跟 temporaryIpData 裡其他 "staticIP" 重複
  const isTempDuplicatePass = ipNoDuplicateCheck(
    temporaryIpData,
    sn,
    temporaryIpValue
  )

  // 判斷當前 "staticIP" 是否跟 deviceData 裡其他 "ip" 重複
  const isDeviceDataDuplicatePass = ipNoDuplicateCheck(
    ipDataFromDeviceData,
    sn,
    temporaryIpValue
  )

  // 判斷當前 ip 是否四組中有哪一組格式錯誤 (是空的)
  const isFormatPass = ipFormatCheck(temporaryIpValue)

  const handleInputChange = result => {
    dispatch(tempIpUpdateForChange({ sn, value: result.formattedValue }))
    dispatch(setIsIpChanged(true))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={textBox + ' relative'}>
      <div className='flex justify-start'>
        <Tooltip
          className='w-[200px] pb-5'
          title={
            <label className='text-base leading-6 text-yellow'>
              Network environment
            </label>
          }
          content={
            <span className='text-sm leading-5 text-light-4'>
              If the IP Address of the device and PC is assigned by DHCP, this
              step can be skipped.
              <br />
              <br />
              If the IP Address cannot be obtained through DHCP, the domain must
              be set to 192.168.100.xxx.
            </span>
          }
          placement='right'
          dismiss={true}>
          <span className={subtitle}>{title}</span>
          <BiInfoCircle className='inline ml-1 text-white' />
        </Tooltip>
      </div>

      <IpInput
        ref={inputRef}
        className='mt-2'
        size='sm'
        value={temporaryIpValue}
        disabled={false}
        danger={false}
        onChange={handleInputChange}
      />

      {/* 不可使用的警示（紅字） 已有其他裝置使用該 IP： */}
      {!isTempDuplicatePass || !isDeviceDataDuplicatePass ? (
        <span className={`${suggestTextClass} text-yellow`}>
          Another device on the network is using this IP Address.
        </span>
      ) : (
        <></>
      )}

      {/* IP 位址格式不正確： */}
      {!isFormatPass && (
        <span className={`${suggestTextClass} text-red`}>
          Incorrect format.
        </span>
      )}
    </div>
  )
}
export default StaticIp

// TODO: PropTypes !!
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
StaticIp.propTypes = {}

const suggestTextClass = `text-xs font-normal leading-4  mt-1`
