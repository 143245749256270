import React from 'react'

import CommonButton from 'src/components/Button/CommonButton'

const Footer = props => {
  const { isSaveDisabled, handleCloseClick, handleSaveClick } = props
  return (
    <div className='py-8 px-20 flex justify-end gap-x-8 shadow-[0px_-4px_8px_0px_rgba(0,_0,_0,_0.25)]'>
      <CommonButton size='lg' type='outlined' onClick={handleCloseClick}>
        Close
      </CommonButton>

      <CommonButton
        size='lg'
        onClick={handleSaveClick}
        disabled={isSaveDisabled}>
        Save
      </CommonButton>
    </div>
  )
}

export default Footer
