import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import {
  home_leaveCheckModal_cancelClick_watcher,
  home_leaveCheckModal_dontApplyClick_watcher,
  home_leaveCheckModal_applyClick_watcher,
} from 'src/redux/actions/facility/home'

const LeaveDeviceInfoCheck = props => {
  const dispatch = useDispatch()

  return (
    <Modal size='md'>
      <Modal.Title>Do you want to apply the changes?</Modal.Title>
      <Modal.Description>
        If "Don't Apply" the changes will be cleared.
      </Modal.Description>

      <div className='w-full flex justify-between mt-14'>
        <CommonButton
          size='lg'
          type='outlined'
          onClick={() => dispatch(home_leaveCheckModal_cancelClick_watcher())}>
          Cancel
        </CommonButton>

        <div className='flex gap-x-5'>
          <CommonButton
            size='lg'
            type='general'
            onClick={() =>
              dispatch(home_leaveCheckModal_dontApplyClick_watcher())
            }>
            Don't Apply
          </CommonButton>
          <CommonButton
            size='lg'
            type='primary'
            onClick={() => dispatch(home_leaveCheckModal_applyClick_watcher())}>
            Apply
          </CommonButton>
        </div>
      </div>
    </Modal>
  )
}

export default LeaveDeviceInfoCheck
