/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useFloating,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  offset,
  flip,
  shift,
  autoUpdate,
} from '@floating-ui/react'
import { mergeRefs } from 'react-merge-refs'

const Dropdown = props => {
  const {
    placement: initPlacement = 'top',
    render,
    dismiss = false,
    children,
  } = props

  const [open, setOpen] = useState(false)

  const triggerRef = useRef()

  const { x, y, refs, strategy, context } = useFloating({
    open,
    placement: initPlacement,
    onOpenChange: setOpen,
    strategy: 'absolute',
    middleware: [
      offset(14), // 8 margin + 6 arrow width
      flip(),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const referenceRef = useMemo(
    () => mergeRefs([refs.setReference, triggerRef]),
    [refs.setReference, triggerRef]
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'Popover' }),
    useDismiss(context, { enable: dismiss }),
  ])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <>
      {/* 如果去抓 children.ref 會有部分元件不工作，因此在外部包一層 trigger div */}
      <div ref={referenceRef} {...getReferenceProps()}>
        {children}
      </div>

      {open && (
        <div
          ref={refs.setFloating}
          className={containerClass}
          style={{
            position: strategy,
            top: 0,
            left: 0,
            transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
          }}
          {...getFloatingProps()}>
          {render({ close: () => setOpen(false) })}
        </div>
      )}
    </>
  )
}

export default Dropdown

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerClass = `
                        z-10 
                        will-change-transform
                        `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Dropdown.propTypes = {
  placement: PropTypes.string,
  render: PropTypes.func,
  dismiss: PropTypes.bool,
}
