// 3D
// degree to radius
export const degreeToRadian = degree => degree * (Math.PI / 180)
export const radianToDegree = radius => radius * (180 / Math.PI)

// cartesianXyzToViewXyz: 畫面上 y軸跟 z軸 對調 -> result : [viewX:cx , viewY:cz , viewZ:cy ]
export const cartesianXyzToViewXyz = (cx, cy, cz) => [cx, cz, cy]

export const topViewXyzToCartesianXyz = (tx, ty, tz) => [tz, tx, ty]
export const leftViewXyzToCartesianXyz = (lx, ly, lz) => [lz, ly, lx]

// spherical = radius, theta, phi
// cartesian = x, y, z
export const getCartesianBySpherical = ([radius, theta, phi]) => {
  const x = radius * Math.sin(theta) * Math.cos(phi)
  const y = radius * -Math.sin(theta) * Math.sin(phi)
  const z = radius * Math.cos(theta)
  return [x, y, z]
}

export const getSphericalByCartesian = ([x, y, z]) => {
  const radius = Math.sqrt(x ** 2, y ** 2, z ** 2)
  const theta = Math.atan(Math.sqrt(x ** 2 + y ** 2) / z)
  const phi = Math.atan2(y, x)

  return [radius, theta, phi] // is radian
}
