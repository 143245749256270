import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import moment from 'moment'

import Checkbox from 'src/components/Button/Checkbox'

import { h3, h6 } from 'src/containers/Modal/style'

import { BiChevronRight } from 'react-icons/bi'

import { getAntennaName, getAntennaTimestamp } from 'src/funcs/device/bbox'
import { antennaNameTimestampFormat as timeFormat } from 'src/constants/beamformers'

import { modalActions } from 'src/redux/slices/modal'
import useGetUIControl from 'src/hooks/useGetUIControl'

const TBody = ({ customOnlyAntennaList, selectIdList }) => {
  const dispatch = useDispatch()

  const deviceData = useSelector(state => state.deviceData)
  const currentAntennaList = Object.entries(deviceData).map(([sn, data]) => ({
    sn,
    currentAntenna: data?.deviceControl?.common?.currentAntenna,
  }))
  const getUsingSn = name =>
    currentAntennaList.find(e => e.currentAntenna === name)?.sn

  const { all: all_uiControl } = useGetUIControl()
  const { customAntennaListSortRule } = all_uiControl

  const isChecked = id => selectIdList === 'all' || selectIdList.includes(id)

  const getMomentValue = timeStr => moment(timeStr, timeFormat).valueOf()
  const nameTimestamp = data =>
    R.pipe(R.prop('name'), getAntennaTimestamp, getMomentValue)(data)

  const sortedAntennaList =
    customAntennaListSortRule === 'Latest to oldest'
      ? R.sort(R.descend(nameTimestamp))(customOnlyAntennaList)
      : R.sort(R.ascend(nameTimestamp))(customOnlyAntennaList)

  const handleSingleSelectClick = id => {
    const isAll = selectIdList.includes('all')
    const isExist = selectIdList.includes(id)
    let result

    if (isAll) result = sortedAntennaList.map(e => e.id).filter(e => e !== id)

    if (!isAll && isExist) result = selectIdList.filter(e => e !== id)
    if (!isAll && !isExist) result = [...selectIdList, id]

    dispatch(
      modalActions.setSelectIdListForModalOfBBoxCA({ selectIdList: result })
    )
  }

  const handleSingleMainClick = data =>
    dispatch(
      modalActions.setModalOfBBoxCAToEdit({
        editIsCreate: false,
        temporaryData: data,
      })
    )

  return (
    <div className={tBody}>
      {sortedAntennaList.map((e, i) => (
        <div key={e.id} className={tRow({ checked: isChecked(e.id) })}>
          <div className={tBodyCheckBoxContainer}>
            <Checkbox
              disabled={false}
              status={isChecked(e.id) ? 'checked' : 'uncheck'}
              onClick={() => handleSingleSelectClick(e.id)}
            />
          </div>

          <div
            className={tBodyMainContainer}
            onClick={() => handleSingleMainClick(e)}>
            <div className='w-full min-h-10 '>
              <div className='w-[532px] flex pr-[2px]'>
                <p
                  className={
                    h3 +
                    '  text-light-1 text-ellipsis overflow-hidden break-keep'
                  }>
                  {getAntennaName(e.name)}
                </p>

                <p className='text-yellow italic shrink-0 '>
                  {getUsingSn(e.name) && ` --- ${getUsingSn(e.name)} In Use`}
                </p>
              </div>

              <h6 className={h6 + ' text-light-4 shrink-0'}>
                X - {e.spacingX} mm / Y - {e.spacingY} mm / θ - {e.thetaMax}˚
              </h6>
            </div>

            <div className={arrowContainer}>
              <BiChevronRight />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

TBody.propTypes = {}

export default TBody

const tBody = `w-full  max-h-[522px] overflow-y-scroll scrollbar`
const tRow = ({ checked }) => `
              w-full 
              px-4 py-2
              first:border-t-1 border-b-1 
              border-solid border-dark-5 
              flex 
              duration-300 
              ${!checked ? `hover:bg-black/30` : `bg-white/10`}
              `

const tBodyCheckBoxContainer = `
                          w-6 min-h-10 shrink-0
                          flex items-center
                          `

const tBodyMainContainer = `w-full flex items-center justify-between gap-x-2 pl-2 cursor-pointer `

const arrowContainer = `
                        w-6 h-6 shrink-0
                        text-white text-xl 
                        flex items-center justify-center 
                        cursor-pointer 
                        `
