import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import CommonInput from 'src/components/Input/CommonInput'

import { modalActions } from 'src/redux/slices/modal'
import { setMemberAttribute } from 'src/redux/slices/uiControl/facility/member'

const ChangeName = props => {
  const dispatch = useDispatch()
  const preferredUsername = useSelector(state => state.member.preferredUsername)
  const [newPreferredUsername, setNewPreferredUsername] =
    useState(preferredUsername)

  const handleNameChange = e => setNewPreferredUsername(e.formattedValue)
  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, {
        preferred_username: newPreferredUsername,
      })

      dispatch(
        setMemberAttribute({
          preferredUsername: newPreferredUsername,
        })
      )
      dispatch(modalActions.clearNormalModal())
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal>
      <Modal.Title>Change Name</Modal.Title>

      <form onSubmit={handleSubmit}>
        <Modal.FormLabel htmlFor='name'>Name</Modal.FormLabel>
        <CommonInput
          id='name'
          value={newPreferredUsername}
          placeholder='Name'
          autoFocus
          onChange={handleNameChange}
        />

        <Modal.RowButtonBox>
          <CommonButton
            size='lg'
            type='outlined'
            onClick={() => dispatch(modalActions.clearNormalModal())}>
            Cancel
          </CommonButton>
          <CommonButton size='lg' type='primary' buttonType='submit'>
            Save
          </CommonButton>
        </Modal.RowButtonBox>
      </form>
    </Modal>
  )
}

export default ChangeName
