import { put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { udboxUIControlForSingleAction as udUIControl } from 'src/redux/slices/uiControl/freqConverter/udbox'
import { sgUIControlForSingleAction as sgUIControl } from 'src/redux/slices/uiControl/freqConverter/sg'

import { devWarLog } from 'src/funcs/tools'
import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* init(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]

    const deviceType = deviceData.info.deviceType
    const { devName } = getDeviceInfo[deviceType]

    const isUDBox = devName?.first === 'udbox'
    const isUDModule = devName?.first === 'udModule'
    const isSg = devName?.first === 'sg'

    if (isSg) yield put(sgUIControl.addUiControl({ sn }))

    if (isUDBox || isUDModule) {
      let appendDataKey
      if (devName?.first === 'udModule') appendDataKey = 'ud5g'
      if (devName?.third === '5g') appendDataKey = 'ud5g'
      if (devName?.third === '0630') appendDataKey = 'ud0630'

      yield put(udUIControl.addUiControl({ sn, appendDataKey }))
    }

    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}
