import React from 'react'

import { small } from 'src/containers/operating/device/freqConverter/style'

import InputGroup from 'src/components/Input/InputGroup'

import { appendComma } from 'src/funcs/tools'
import { decimalScale } from 'src/constants/freqConverter'

const FreqInput = props => {
  const { value, min, max, icon, unit, deviceType, handleChange } = props

  const step = 1

  const isEmptyValue = value === '' || value === null
  const inputDanger = isEmptyValue ? false : value < min || value > max

  const inputProps = {
    // input params
    icon,
    size: 'lg',
    className: 'w-full',
    unit,
    off: false,
    step,
    inputMin: 0,
    inputMax: 99999999,
    keydownMin: 0,
    keydownMax: 99999999,
    danger: inputDanger,
    placeholder: '0',
    decimalScale: decimalScale[deviceType][unit],
    value: value === null ? '' : value,
    emptyValue: '',
    // progress bar params
    showProgress: false,
    onChange: handleChange,
  }

  const smallColor = inputDanger ? ' text-red' : ' text-white/50'

  return (
    <div className='w-full'>
      <InputGroup {...inputProps} />

      <small className={small + smallColor + ' mt-1'}>
        Range {appendComma(min)} - {appendComma(max)} {unit}
      </small>
    </div>
  )
}

export default FreqInput
