import React from 'react'
import MultiAiP from './MultiAiP'
import SingleAiP from './SingleAiP'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const Main = props => {
  const { current } = useGetCurrentDeviceData()
  const separateBeamsDataLength =
    current.data?.deviceControl?.steering.separateBeamsData.length

  if (separateBeamsDataLength === 1) return <SingleAiP />

  return <MultiAiP />
}

export default Main
