import React from 'react'

import Modal from 'src/components/Popup/Modal'

import { BiCheckCircle } from 'react-icons/bi'
import { appendComma } from 'src/funcs/tools'

const UnlockSuccess = ({ sn, RF, IF, LO }) => {
  const toMHz = value => appendComma(value / 1000)

  return (
    <Modal>
      <Modal.Icon>
        <BiCheckCircle className='text-green' />
      </Modal.Icon>

      <Modal.Title>Verification successful</Modal.Title>

      <Modal.Description>
        RF range {toMHz(RF?.userMin)} - {toMHz(RF?.userMax)} MHz
        <br />
        IF range {toMHz(IF?.userMin)} - {toMHz(IF?.userMax)} MHz
        <br />
        LO range {toMHz(LO?.userMin)} - {toMHz(LO?.userMax)} MHz
      </Modal.Description>
    </Modal>
  )
}

export default UnlockSuccess
