import React from 'react'

import LEDIndicators from 'src/containers/operating/device/freqConverter/sg/DeviceSettings/LEDIndicators'
import RefSource from 'src/containers/operating/device/freqConverter/sg/DeviceSettings/RefSource'
import OutputRefSource from 'src/containers/operating/device/freqConverter/sg/DeviceSettings/OutputRefSource'
import InputRefSource from 'src/containers/operating/device/freqConverter/sg/DeviceSettings/InputRefSource'

import { hDivider } from 'src/containers/operating/device/freqConverter/style'

const SGDeviceSettings = () => {
  return (
    <div className={container}>
      <LEDIndicators />

      <div className={hDivider} />
      <RefSource />
      {/* 切換 ref source */}
      <div className={hDivider} />
      <OutputRefSource />
      <InputRefSource />
      {/* 根據 ref source 決定顯示 output ref source or input ref source */}
    </div>
  )
}

export default SGDeviceSettings

const container = `[SGDeviceSettings-container] 
                  functionBlockBackground 
                  w-full
                  rounded-md
                  px-4 pt-4 pb-10 
                  flex flex-col 
                  gap-y-4 
                  `
