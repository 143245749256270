import { createSlice } from '@reduxjs/toolkit'

// device = {
//   [sn]: {
//     time: '',
//     api: '',
//     code: 1,
//     codeName: 'str',
//     data: '',
//     errorInfo: {
//       sn: '',
//       deviceType: 0,
//       versionInfo: { caliTable: '', firmware: '', hardware: '' },
//     },
//     message: '',
//     module: '',
//     subModule: '',
//     status: '',
//   },
// }
const initState = {
  facility: {},
  device: {},
}

export const errorSlice = createSlice({
  name: 'error',
  initialState: initState,
  reducers: {
    setFacilityError(state, action) {
      // { sn, errorData } = action.payload
      const { errorData } = action.payload
      state.facility = errorData
      return state
    },
    setDeviceError(state, action) {
      const { sn, errorData } = action.payload

      state.device[sn] = errorData
      return state
    },
    clearAllError(state, action) {
      return initState
    },
  },
})

export const errorActions = errorSlice.actions

export default errorSlice.reducer
