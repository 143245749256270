import { call, put } from 'redux-saga/effects'
import * as R from 'ramda'
import moment from 'moment'

import { isDeveloping } from 'src/funcs/getEnv'
import { devLog, devWarLog } from 'src/funcs/tools'
import { getIsDemoMode } from 'src/redux/sagas/funcs/general'

import { errorActions } from 'src/redux/slices/uiControl/facility/error'
import responseToGeneralError from 'src/redux/sagas/handlers/errorResponse/responseToGeneralError'

export default function* socketOnDistributor({
  tag,
  type,
  eventName,
  handler,
  errorHandler,
  response,
}) {
  try {
    if (type === 'SOCKET_EVENT') {
      yield console.log(
        `%c ------ ${tag} IO ${eventName} ------`,
        'color: #ff3e3e',
        response
      )
      yield call(handler, response)
    }

    if (type !== 'SOCKET_EVENT') {
      const api = R.pipe(R.split('/'), R.dropLast(1), R.join('/'))(eventName)

      const isDemoMode = yield call(getIsDemoMode)
      // --------------------
      // --------------------
      //       Success
      // --------------------
      // --------------------
      if (+response?.code === 0 && !isDemoMode) {
        yield call(printDevSuccessLog, { tag, api, response })

        yield call(handler, response)
      }

      // --------------------
      // --------------------
      //        Error
      // --------------------
      // --------------------
      if (+response?.code !== 0 && !isDemoMode) {
        const time = moment().format('YYYY-MM-DD  HH:mm:ss')

        yield call(printDevErrorLog, response)

        const sliceErrorData = {
          time,
          api,
          ...response,
        }

        // 更新 error 訊息到 redux, 外部有需要用到就可以直接撈
        yield call(updateErrorToSlice, sliceErrorData)

        // 有設定 errorHandler 就跑 errorHandler
        if (errorHandler) yield call(errorHandler, { response, api, time })

        // 沒有設定 errorHandler 就跳 Global Error Modal
        if (!errorHandler)
          yield call(responseToGeneralError, { response, api, time })
      }
    }
  } catch (error) {
    devWarLog('[socketOnDistributor] error: ', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------  Success Function  -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* printDevSuccessLog({ tag, api, response }) {
  if (isDeveloping) {
    yield console.groupCollapsed(
      // yield console.group(
      '%cლ(・´ｪ`・ლ) ' + tag + ': ' + api + '/response [BE][Socket]',
      'color: rgba(150,150,255,0.7); font-weight: 900'
    )

    yield console.log('[BE] Socket Response:')
    yield console.table(response)
    yield console.count('[BE] Response count')
    yield console.timeEnd(`[BE] ${tag} Timer`)
    yield console.groupEnd()
    yield devLog('--------------')
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Error Function   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* printDevErrorLog(response) {
  const code = response?.code
  const codeName = response?.codeName
  const message = response?.message
  const errorInfo = response?.errorInfo

  yield devWarLog(' ⇩ ⇩ ⇩ Total Response ⇩ ⇩ ⇩')
  yield devWarLog(response)

  const versionInfo = errorInfo?.versionInfo

  if (isDeveloping) yield console.group('⛔ Oops!! We got a BE or API Error')
  yield devWarLog(' ⇩ ⇩ ⇩ Code ⇩ ⇩ ⇩')
  yield devWarLog(code)
  yield devWarLog(' ⇩ ⇩ ⇩ CodeName ⇩ ⇩ ⇩')
  yield devWarLog(codeName)
  yield devWarLog(' ⇩ ⇩ ⇩ Message ⇩ ⇩ ⇩')
  yield devWarLog(message)
  yield devWarLog(' ⇩ ⇩ ⇩ versionInfo ⇩ ⇩ ⇩')
  yield devWarLog(versionInfo)
  if (isDeveloping) yield console.groupEnd('⛔ Oops!! We got a BE or API Error')
}

function* updateErrorToSlice(sliceErrorData) {
  const sn = sliceErrorData?.errorInfo?.sn

  if (sn)
    yield put(errorActions.setDeviceError({ sn, errorData: sliceErrorData }))

  if (!sn)
    yield put(errorActions.setFacilityError({ errorData: sliceErrorData }))
}
