import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { useParams, useResolvedPath } from 'react-router-dom'
import { isDeveloping } from 'src/funcs/getEnv'

const useGetCurrentDeviceData = propsSn => {
  const params = useParams()
  const resolvedPath = useResolvedPath()

  const pathnameSn = R.pipe(
    R.split('/'),
    R.filter(e => e),
    R.last()
  )(resolvedPath?.pathname)

  const deviceData = useSelector(state => state?.deviceData)
  const sn = propsSn || params?.sn || pathnameSn
  const currentData = deviceData?.[sn]
  const coBranding = getCoBranding(sn, currentData)

  try {
    return {
      deviceData: deviceData,
      current: {
        sn: currentData ? sn : undefined,
        deviceType: currentData?.info.deviceType,
        data: currentData,
        coBranding,
      },
    }
  } catch (error) {
    if (isDeveloping) console.warn(error)
  }
}

export default useGetCurrentDeviceData

const niDeviceType = [20, 21]

const getCoBranding = (sn, data) => {
  const deviceType = data?.info.deviceType
  const hardwareVersion = data?.info?.versionInfo?.hardware

  // ni 的 bbox 有獨立 deviceType
  if (niDeviceType.includes(data?.info.deviceType)) return 'ni'

  // ni 的 udbox 5g 沒有獨立 deviceType 要靠 hardwareVersion 判斷
  if (deviceType === 15 && hardwareVersion === '0014') return 'ni'

  return ''
}
