import { bbox_steering_socketEmitting_watcher } from 'src/redux/actions/beamformers/bboxAction'

import {
  deviceError as handleDeviceErrorResponse,
  deviceErrorAndClearNormalModal as handleDeviceErrorAndClearNormalModal,
} from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import {
  commonAntennaSelect as handleCommonAntennaSelectResponse,
  customAntennaInsert as handleCustomAntennaInsertResponse,
  customAntennaUpdate as handleCustomAntennaUpdateResponse,
  customAntennaDelete as handleCustomAntennaDeleteResponse,
} from 'src/redux/sagas/handlers/response/beamformers/bbox/common'

import {
  channelAllPower as handleChannelAllPowerResponse,
  channelSinglePower as handleChannelSinglePowerResponse,
  channelCommonGain as handleChannelCommonGainResponse,
  channelSingleGain as handleChannelSingleGainResponse,
  channelSinglePhase as handleChannelSinglePhaseResponse,
} from 'src/redux/sagas/handlers/response/beamformers/bbox/channel'

import {
  steeringThetaPhi as handleSteeringThetaPhiResponse,
  steeringGain as handleSteeringGainResponse,
} from 'src/redux/sagas/handlers/response/beamformers/bbox/steering'

import { switchMethod as handleSwitchMethodResponse } from 'src/redux/sagas/handlers/response/beamformers/bbox/beamConfigEditor'

const bboxApi = {
  AAKIT_SELECT: {
    emit: {
      server: 'beamformers',
      eventName: 'aakit/select',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 10000,
    },
    on: {
      eventName: 'aakit/select/response',
      handler: handleCommonAntennaSelectResponse,
      errorHandler: handleDeviceErrorAndClearNormalModal,
    },
  },

  AAKIT_INSERT: {
    emit: {
      server: 'beamformers',
      eventName: 'aakit/insert',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'aakit/insert/response',
      handler: handleCustomAntennaInsertResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  AAKIT_UPDATE: {
    emit: {
      server: 'beamformers',
      eventName: 'aakit/update',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'aakit/update/response',
      handler: handleCustomAntennaUpdateResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  AAKIT_DELETE: {
    emit: {
      server: 'beamformers',
      eventName: 'aakit/delete',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'aakit/delete/response',
      handler: handleCustomAntennaDeleteResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CHANNEL_POWER_ALL: {
    emit: {
      server: 'beamformers',
      eventName: 'channel/power/all',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'channel/power/all/response',
      handler: handleChannelAllPowerResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CHANNEL_POWER: {
    emit: {
      server: 'beamformers',
      eventName: 'channel/power',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'channel/power/response',
      handler: handleChannelSinglePowerResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CHANNEL_COMMON_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'channel/commonGain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'channel/commonGain/response',
      handler: handleChannelCommonGainResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CHANNEL_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'channel/gain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'channel/gain/response',
      handler: handleChannelSingleGainResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CHANNEL_PHASE: {
    emit: {
      server: 'beamformers',
      eventName: 'channel/phase',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'channel/phase/response',
      handler: handleChannelSinglePhaseResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  BEAM_STEERING_THETA_PHI: {
    emit: {
      server: 'beamformers',
      eventName: 'beam/steering/thetaPhi',
      beforeEmitActionType: bbox_steering_socketEmitting_watcher.type,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'beam/steering/thetaPhi/response',
      handler: handleSteeringThetaPhiResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  BEAM_STEERING_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'beam/steering/gain',
      beforeEmitActionType: bbox_steering_socketEmitting_watcher.type,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'beam/steering/gain/response',
      handler: handleSteeringGainResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  SPI_SWITCH_METHOD: {
    emit: {
      server: 'beamformers',
      eventName: 'spi/switch/method',
      beforeEmitActionType: null,
      throttle: true,
      timeout: 15000,
    },
    on: {
      eventName: 'spi/switch/method/response',
      handler: handleSwitchMethodResponse,
      errorHandler: handleDeviceErrorAndClearNormalModal,
    },
  },
}
export default bboxApi
