import React from 'react'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/beamformers/style'
import SegmentedButton from 'src/components/Button/SegmentedButton'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCellCTTC_steering_rfModeChange_watcher } from 'src/redux/actions/beamformers/cloverCellCTTCAction'

const RfMode = ({ beamIndex }) => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const isBeamSynthesis = current.data?.deviceControl?.steering.isBeamSynthesis
  const controlPattern = current.data?.deviceControl?.steering.controlPattern
  const isOn = beamIndex === null || controlPattern.includes(beamIndex)

  const beamData = isBeamSynthesis
    ? current.data?.deviceControl?.steering.synthesizedBeamData
    : current.data?.deviceControl?.steering.separateBeamsData.find(
        e => e.beamIndex === beamIndex
      )

  const rfMode = beamData?.rfMode

  const isDisabled = !isOn

  const handleChange = (e, value) =>
    dispatch(
      cloverCellCTTC_steering_rfModeChange_watcher({
        sn,
        beamIndex: beamIndex || null,
        value,
      })
    )

  return (
    <div>
      <h4 className={`${h6} mb-1`}>Current Mode</h4>
      <SegmentedButton
        type='outlined'
        size='sm'
        value={rfMode}
        disabled={isDisabled}
        onChange={handleChange}>
        <SegmentedButton.Option name='standby'>Standby</SegmentedButton.Option>
        <SegmentedButton.Option name='tx'>TX</SegmentedButton.Option>
        <SegmentedButton.Option name='rx'>RX</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

export default RfMode
