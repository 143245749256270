import { createSlice, current } from '@reduxjs/toolkit'

export const facilityInitSlice = createSlice({
  name: 'facilityInit',
  initialState: {
    isDeviceControlModeInitialized: false,
    isDemoInitialized: false,
    temporaryDeviceData: {},
    currentPageMode: '', // 'deviceControl' , 'demo'
  },
  reducers: {
    setDeviceControlInitIsTrue(state, action) {
      return { ...current(state), isDeviceControlModeInitialized: true }
    },
    setDeviceControlInitIsFalse(state, action) {
      return { ...current(state), isDeviceControlModeInitialized: false }
    },
    setDemoInitIsTrue(state, action) {
      return { ...current(state), isDemoInitialized: true }
    },
    setDemoInitIsFalse(state, action) {
      return { ...current(state), isDemoInitialized: false }
    },
    setTemporaryDeviceData(state, action) {
      return { ...current(state), temporaryDeviceData: action.payload }
    },
    setCurrentPageModeIsDeviceControl(state, action) {
      return { ...current(state), currentPageMode: 'deviceControl' }
    },
    setCurrentPageModeIsDemo(state, action) {
      return { ...current(state), currentPageMode: 'demo' }
    },
    clearCurrentPageMode(state, action) {
      return { ...current(state), currentPageMode: '' }
    },
  },
})

export const {
  setDeviceControlInitIsTrue,
  setDeviceControlInitIsFalse,
  setDemoInitIsTrue,
  setDemoInitIsFalse,
  setTemporaryDeviceData,
  setCurrentPageModeIsDeviceControl,
  setCurrentPageModeIsDemo,
  clearCurrentPageMode,
} = facilityInitSlice.actions

export default facilityInitSlice.reducer
