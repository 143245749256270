import { put, call } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

export default function* responseToDeviceError(sn) {
  const currentData = yield call(getCurrentData, sn)
  currentData.info.initialization = 'failed'
  yield put(setSingleDeviceData({ sn, data: currentData }))
}
