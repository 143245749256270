import React from 'react'
import { useDispatch } from 'react-redux'
import { RotatingLines } from 'react-loader-spinner'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiImport, BiCheckCircle, BiXCircle, BiError } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

const FileImport = modalProps => {
  const dispatch = useDispatch()
  const { status, filesCountMax, failedList } = modalProps

  const handleFailedOKClick = () => dispatch(modalActions.clearNormalModal())
  const handleExceededMaxOKClick = () =>
    dispatch(modalActions.clearNormalModal())

  if (status === 'importing')
    return (
      <Modal>
        <Modal.Icon>
          <BiImport className='text-white' />
        </Modal.Icon>

        <Modal.Title>File importing...</Modal.Title>

        <Modal.Description>
          The waiting time depends on your internet speed.
        </Modal.Description>

        <div className='flex justify-center mt-6'>
          <RotatingLines
            width='56'
            strokeColor='white'
            strokeWidth='3'
            animationDuration='0.7'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      </Modal>
    )

  if (status === 'success')
    return (
      <Modal>
        <Modal.Icon>
          <BiCheckCircle className='text-green' />
        </Modal.Icon>
        <Modal.Title>Import successful</Modal.Title>
      </Modal>
    )

  if (status === 'failed')
    return (
      <Modal>
        <Modal.Icon>
          <BiXCircle className='text-red ' />
        </Modal.Icon>

        <Modal.Title>Failed to import {failedList.length} file</Modal.Title>

        <Modal.Description>
          <>
            {failedList.map((e, i) => (
              <div className='text-center' key={'modal failed list ' + i}>
                {e}
              </div>
            ))}
            <br />
            <div className='text-center'>
              Please make sure the selected file matches the system format and
              try again.
            </div>
          </>
        </Modal.Description>

        <Modal.ColumnButtonBox>
          <CommonButton type='general' onClick={handleFailedOKClick}>
            OK
          </CommonButton>
        </Modal.ColumnButtonBox>
      </Modal>
    )

  if (status === 'exceededMax')
    return (
      <Modal>
        <Modal.Icon>
          <BiError className='text-yellow ' />
        </Modal.Icon>

        <Modal.Title>
          Limit import to {filesCountMax} files at a time
        </Modal.Title>

        <Modal.Description>Please import in batches.</Modal.Description>

        <Modal.ColumnButtonBox>
          <CommonButton type='general' onClick={handleExceededMaxOKClick}>
            OK
          </CommonButton>
        </Modal.ColumnButtonBox>
      </Modal>
    )
}

export default FileImport
