import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ErrorBoundaryOfDevice from 'src/containers/ErrorBoundary/Device'

import FunctionTabs from 'src/containers/operating/device/beamformers/bbox/FunctionTabs'
import DeviceController from 'src/containers/operating/device/beamformers/bbox/DeviceController'
import BeamConfigEditor from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor'

import DeviceInitializing from 'src/containers/operating/DeviceInitializing'
import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'
import NoCalibrationTable from 'src/containers/operating/NoCalibrationTable'

import { beamformers_initDevice_watcher } from 'src/redux/actions/beamformers/generalAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'
import useGetUIControl from 'src/hooks/useGetUIControl'

const Bbox = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current.sn
  const info = current.data?.info

  const { isSM, isMD, isLG, isXL, is2XL, isMobile, isTablet, isDesktop } =
    useGetScreenSize()

  // padControlContent 跟 deviceData 裡面的 currentControlMode 分開，
  // 因為如果切換到 steering 就直接 設定 theta phi 洗掉 channel
  // UX 上可能會被痛恨(畢竟這樣 steering 的警語在 pad 上就白設計了)
  const { single: s_uiControl } = useGetUIControl(sn)
  const functionTabs = s_uiControl?.functionTabs

  // pc 可以視窗化後拖大拖小，而且使用者蠻常用的
  const isPcSM = isDesktop && isSM
  const isPcMid = isDesktop && (isMD || isLG)
  const isPcBig = isDesktop && (isXL || is2XL)

  useEffect(() => {
    if (info?.initialization === 'unfinished')
      dispatch(beamformers_initDevice_watcher({ sn }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ==================================
  // 第一步 攔截 init 中或 init 失敗
  // ==================================
  if (info?.initialization === 'unfinished') return <DeviceInitializing />

  if (info?.initialization === 'failed')
    return (
      <DeviceSomethingWereWrong
        handleRetry={() => dispatch(beamformers_initDevice_watcher({ sn }))}
      />
    )

  if (info?.initialization === 'no-table') return <NoCalibrationTable sn={sn} />

  // ==================================
  // Render
  // ==================================
  if (isMobile || isPcSM) return <DeviceController />

  if (isTablet || isPcMid || isPcBig)
    return (
      <>
        <FunctionTabs />

        {functionTabs === 'Device Controller' && <DeviceController />}
        {functionTabs === 'Beam Config Editor' && <BeamConfigEditor />}
      </>
    )
}

const BboxWithErrorBoundary = () => {
  const { current } = useGetCurrentDeviceData()
  const deviceType = current.deviceType
  const helmet = useGetHelmet({ deviceType })

  return (
    // TODO: retry 補 call re-init api
    <ErrorBoundaryOfDevice
      handleRetry={() => console.log('Error Boundary Retry Click')}>
      {helmet}
      <Bbox />
    </ErrorBoundaryOfDevice>
  )
}

export default BboxWithErrorBoundary
