import { call, put } from 'redux-saga/effects'

import { getMaintenance as getMaintenanceAPI } from 'src/redux/sagas/services/restfulAPI/lambdaApi'

import { devWarLog } from 'src/funcs/tools'

import { notificationActions } from 'src/redux/slices/uiControl/facility/notification'

export function* getMaintenance() {
  try {
    const response = yield call(getMaintenanceAPI)
    const maintenanceData = response?.data?.data

    if (maintenanceData)
      yield put(notificationActions.addNotification([maintenanceData]))
  } catch (error) {
    devWarLog('[handler] applyDeviceInfo error:', error)
  }
}
