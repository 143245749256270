import { call, put } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* beamSteeringSyncChannel(response) {
  try {
    const [sn, { channel }] = Object.entries(response?.data)[0]

    let newCurrentData = yield call(getCurrentData, sn)

    newCurrentData.deviceControl.channel = yield channel

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
  } catch (error) {
    devWarLog(error)
  }
}

export function* steeringThetaPhi(response) {
  try {
    const [sn, { rfMode, hvMode, lstBoardData }] = Object.entries(
      response?.data
    )[0]

    let newCurrentData = yield call(getCurrentData, sn)

    newCurrentData.deviceControl.channel[rfMode][hvMode].lstBoardData =
      yield lstBoardData

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
    yield console.log('steeringGain')
    yield console.log(response)
  } catch (error) {
    devWarLog(error)
  }
}

export function* steeringGain(response) {
  try {
    const [sn, { rfMode, hvMode, lstBoardData }] = Object.entries(
      response?.data
    )[0]

    let newCurrentData = yield call(getCurrentData, sn)

    newCurrentData.deviceControl.channel[rfMode][hvMode].lstBoardData =
      yield lstBoardData

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
    yield console.log('steeringGain')
    yield console.log(response)
  } catch (error) {
    devWarLog(error)
  }
}
