import React, { useState, useRef, useEffect } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import CommonInput from 'src/components/Input/CommonInput'
import Tooltip from 'src/components/Popup/Tooltip'

import { head } from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/style'
import { h2, h6 } from 'src/containers/Modal/style'

import { BiArrowBack } from 'react-icons/bi'

import { modalActions } from 'src/redux/slices/modal'

import { pd_customCaliModal_leaveEditClick_watcher } from 'src/redux/actions/powerDetector'

export const nameRegex = /[?”“/\\.;<>*|:]+/
const isNameEmpty = name => R.isEmpty(name)
const isNameTooLong = name => name?.length > 60
const isNameIllegal = name => name.match(nameRegex)?.[0] !== undefined
export const isNameInvalid = name =>
  isNameTooLong(name) || isNameIllegal(name) || isNameEmpty(name)

const Head = ({ editIsCreate, editTemporaryData }) => {
  const dispatch = useDispatch()

  const nameRef = useRef(null)
  const [nameIsFocus, setNameIsFocus] = useState(false)

  const id = Object.keys(editTemporaryData)[0]
  const name = editTemporaryData[id].name

  const nameBorderBottomBg = isNameInvalid(name)
    ? `bg-red`
    : nameIsFocus
    ? `bg-teal-5`
    : `bg-transparent`

  const nameBorderBottom = `${nameBorderBottomBg} w-full h-[2px] duration-500`

  const nameInvalidHint = `${h6} block w-full text-red text-center first:mt-2 mt-1`
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    if (editIsCreate && nameRef?.current) nameRef?.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const handleBackClick = () =>
    dispatch(pd_customCaliModal_leaveEditClick_watcher())

  const handleNameChange = e => {
    dispatch(
      modalActions.set_isChanged_for_modal_of_powerDetector_CC({
        editIsChanged: true,
      })
    )
    dispatch(
      modalActions.set_temporaryData_for_modal_of_powerDetector_CC({
        editTemporaryData: R.assocPath(
          [id, 'name'],
          e.formattedValue
        )(editTemporaryData),
      })
    )
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <header
      className={`${head} ${h2} relative text-light-1 pt-6 pb-6 px-[84px]`}>
      <div
        className='absolute left-[44px] top-[36px] cursor-pointer'
        onClick={handleBackClick}>
        <BiArrowBack />
      </div>

      <Tooltip content='Rename' placement='bottom' enableDismiss={true}>
        <CommonInput
          ref={nameRef}
          inputType='text'
          value={name}
          size='lg'
          autoFocus={editIsCreate}
          placeholder='Custom calibration config name'
          className='w-full shrink-0 bg-transparent hover:bg-black/30 border-none '
          inputClassName='text-center bg-transparent'
          onFocus={() => setNameIsFocus(true)}
          onBlur={() => setNameIsFocus(false)}
          onChange={handleNameChange}
        />
      </Tooltip>
      <div className={nameBorderBottom} />

      {isNameIllegal(name) && (
        <small
          className={
            nameInvalidHint
            // eslint-disable-next-line no-useless-escape
          }>{`File name can’t contain any of the following characters: ? ” “/ \ . ; < > * | : `}</small>
      )}

      {isNameTooLong(name) && (
        <small className={nameInvalidHint}>
          Maximum 60 characters. {name?.length} / 60
        </small>
      )}

      {isNameEmpty(name) && (
        <small className={nameInvalidHint}>Required field</small>
      )}
    </header>
  )
}

export default Head
