import { current } from '@reduxjs/toolkit'

// const {
//     name,
//     email,
//     time,
//     userID,
//     api,
//     code,
//     codeName,
//     message,
//     sn,
//     deviceType,
//     hwVersion,
//     caliTableVersion,
//     fwVersion,

//     comments,
//     errorMessage,
//     isRequesting,
//     isReportIssueDisabled,
// } = action.payload

const error = {
  // general
  showModalOfGeneralError: (state, action) => {
    const prevProps = current(state).emergency.props

    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        comments: prevProps.comments ?? '',
        status: 'generalError',
        ...action.payload,
      },
    }
    return state
  },
  showModalOfGeneralErrorSendMail: (state, action) => {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        status: 'generalErrorSendMail',
      },
    }
    return state
  },
  showModalOfGeneralErrorMailSended: (state, action) => {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        status: 'generalErrorMailSended',
      },
    }
    return state
  },
  setGeneralErrorProps: (state, action) => {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        ...action.payload,
      },
    }
    return state
  },

  // device error send mail
  showModalOfDeviceErrorSendMail(state, action) {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        ...action.payload,
        status: 'deviceErrorSendMail',
      },
    }
    return state
  },
  setDeviceErrorProps: (state, action) => {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        ...action.payload,
        status: 'deviceErrorSendMail',
      },
    }
    return state
  },
  showModalOfDeviceErrorMailSended(state, action) {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        ...action.payload,
        status: 'deviceErrorMailSended',
      },
    }
    return state
  },
  showModalOfLocalMidSocketDisconnect(state, action) {
    const prevProps = current(state).emergency.props
    state.emergency = {
      type: 'ERROR',
      props: {
        ...prevProps,
        ...action.payload,
        status: 'localMidSocketDisconnect',
      },
    }
    return state
  },
}
export default error
