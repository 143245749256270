import { call, put, delay, cancel, fork } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { sgUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/sg'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'
import { getIsDemoMode } from 'src/redux/sagas/funcs/general'

export function* _setUiControlOutputToIsStopped({ sn }) {
  yield put(
    uiControlAction.setOutputIsStopped({
      sn,
      value: true,
    })
  )

  yield delay(1000)
  yield put(
    uiControlAction.setOutputIsStopped({
      sn,
      value: false,
    })
  )
}

let outputApplyMockBeList = {}
let repeatRoundsMockList = {}
let timeStepMockList = {}

function* _outputStoppedDemo({ sn }) {
  let { currentData } = yield call(getCommonArgs, sn)
  currentData.settings.output.isOutputting = false
  yield put(setSingleDeviceData({ sn, data: currentData }))
  yield call(_setUiControlOutputToIsStopped, { sn })
}

function* _outputApplyMockBe({ sn }) {
  let { currentData: outside_currentData } = yield call(getCommonArgs, sn)

  repeatRoundsMockList[sn] =
    outside_currentData.settings.output.outputControls.repeatRounds.currentValue
  timeStepMockList[sn] =
    outside_currentData.settings.output.outputControls.sweepTimeStep.currentValue

  while (true) {
    let { currentData: inside_currentData } = yield call(getCommonArgs, sn)

    if (repeatRoundsMockList[sn] === 0) {
      yield call(_outputStoppedDemo, { sn })

      yield cancel(outputApplyMockBeList[sn])
    }

    if (repeatRoundsMockList[sn] > 0) {
      repeatRoundsMockList[sn] = repeatRoundsMockList[sn] - 1

      inside_currentData.settings.output.outputControls.repeatRounds.currentValue =
        repeatRoundsMockList[sn]

      yield put(setSingleDeviceData({ sn, data: inside_currentData }))
    }

    yield delay(timeStepMockList[sn] || 30)
  }
}

function* _outputApplyDemo({ sn }) {
  let { currentData } = yield call(getCommonArgs, sn)
  const isOutputting = currentData.settings.output.isOutputting

  // stop
  if (isOutputting) {
    yield call(_outputStoppedDemo, { sn })
    yield cancel(outputApplyMockBeList[sn])
  }

  // start
  if (!isOutputting) {
    currentData.settings.output.isOutputting = true

    yield put(setSingleDeviceData({ sn, data: currentData }))

    outputApplyMockBeList[sn] = yield fork(_outputApplyMockBe, { sn })
  }
}

function* _outputApplyReal({ sn }) {
  let { currentData, lookupID } = yield call(getCommonArgs, sn)
  const isOutputting = currentData.settings.output.isOutputting

  // stop
  if (isOutputting) {
    currentData.settings.output.isOutputting = false
    yield call(_setUiControlOutputToIsStopped, { sn })
  }

  // start
  if (!isOutputting) currentData.settings.output.isOutputting = true

  yield put(setSingleDeviceData({ sn, data: currentData }))

  const output = currentData.settings.output

  yield call(
    __socket_API_sender,
    localMiddlewareApi.SG_OUTPUT_SETTINGS_ACTIVATE_OUTPUT,
    {
      sn,
      lookupID,
      output,
    }
  )
}

export function* sgOutputSettingsApply(payloads) {
  try {
    const { sn } = payloads

    const isDemoMode = yield call(getIsDemoMode)

    if (isDemoMode) yield call(_outputApplyDemo, { sn })
    if (!isDemoMode) yield call(_outputApplyReal, { sn })
  } catch (error) {
    devWarLog('[handler] deviceSetting error:', error)
  }
}
