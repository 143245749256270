import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function C({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='15'
        height='18'
        viewBox='0 0 15 18'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.6774 11.9519H14.2725C14.2004 13.1303 13.8758 14.1764 13.2986 15.0902C12.7295 16.004 11.9319 16.7174 10.9058 17.2305C9.88778 17.7435 8.66132 18 7.22645 18C6.10421 18 5.0982 17.8076 4.20842 17.4228C3.31864 17.0301 2.55711 16.4689 1.92385 15.7395C1.2986 15.01 0.821643 14.1283 0.492986 13.0942C0.164329 12.0601 0 10.9018 0 9.61924V8.40481C0 7.12224 0.168337 5.96393 0.50501 4.92986C0.849699 3.88778 1.33868 3.002 1.97194 2.27255C2.61323 1.54309 3.37876 0.981964 4.26854 0.589178C5.15832 0.196393 6.1523 0 7.2505 0C8.70942 0 9.93988 0.264529 10.9419 0.793587C11.9519 1.32265 12.7335 2.0521 13.2866 2.98196C13.8477 3.91182 14.1844 4.96994 14.2966 6.15631H10.6894C10.6493 5.4509 10.509 4.85371 10.2685 4.36473C10.0281 3.86774 9.66333 3.49499 9.17435 3.24649C8.69339 2.98998 8.0521 2.86172 7.2505 2.86172C6.6493 2.86172 6.12425 2.97395 5.67535 3.1984C5.22645 3.42285 4.8497 3.76353 4.54509 4.22044C4.24048 4.67735 4.01202 5.25451 3.85972 5.9519C3.71543 6.64128 3.64329 7.4509 3.64329 8.38076V9.61924C3.64329 10.5251 3.71142 11.3226 3.8477 12.012C3.98397 12.6934 4.19238 13.2705 4.47295 13.7435C4.76152 14.2084 5.13026 14.5611 5.57916 14.8016C6.03607 15.0341 6.58517 15.1503 7.22645 15.1503C7.97996 15.1503 8.6012 15.0301 9.09018 14.7896C9.57916 14.5491 9.9519 14.1924 10.2084 13.7194C10.4729 13.2465 10.6293 12.6573 10.6774 11.9519Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
