import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import { devWarLog } from 'src/funcs/tools'

const ChangeProfilePhotoUpload = props => {
  const dispatch = useDispatch()
  const { uploadFileState } = props
  const [uploadFile, setUploadFile] = uploadFileState
  const [errorMessage, setErrorMessage] = useState('')

  const maxFiles = 1
  const acceptedFileTypes = ['image/jpeg', 'image/png']

  const onDrop = useCallback(acceptedFiles => {
    try {
      if (!acceptedFileTypes.includes(acceptedFiles[0].type))
        throw Error('Only image files (png or jpg) are supported.')

      if (acceptedFiles.length > maxFiles)
        throw Error('Only one file upload is supported.')

      setUploadFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      )
    } catch (error) {
      devWarLog('ChangeProfilePhotoEdit error:', error)
      setErrorMessage(error?.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <Modal>
      <Modal.Title>Change Profile Photo</Modal.Title>

      {errorMessage && <div className='text-red my-6'>{errorMessage}</div>}

      <div className={dropAreaClass(isDragActive)} {...getRootProps()}>
        <input {...getInputProps()} />

        <div className={dropImageBoxClass}>
          {uploadFile && (
            <img
              className='w-full h-full object-cover'
              src={uploadFile?.preview}
              alt='avatar preview'
              onLoad={() => {
                URL.revokeObjectURL(uploadFile.preview)
              }}
            />
          )}
        </div>

        {isDragActive ? (
          <p className={dropTextClass + ' text-light-4 '}>
            Drop your file here
          </p>
        ) : (
          <p className={dropTextClass + ' text-light-4 '}>
            Click to upload
            <br />
            or
            <br />
            Drag and drop your image here
          </p>
        )}
      </div>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          onClick={() => dispatch(modalActions.clearNormalModal())}>
          Cancel
        </CommonButton>
        <CommonButton size='lg' type='primary' disabled>
          Save
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ChangeProfilePhotoUpload

const dropAreaCommon = `max-w-[346px] h-[310px] 
                        py-10 mt-6 
                        flex flex-col items-center 
                        border-1 border-dashed border-white/50 
                        rounded-lg duration-500
`
const dropAreaBgColor = isDragActive =>
  isDragActive ? `bg-black/30` : `bg-transparent hover:bg-black/30`
const dropAreaCursor = isDragActive =>
  isDragActive ? `cursor-zoom-in` : `cursor-default hover:cursor-pointer `
const dropAreaClass = isDragActive =>
  `${dropAreaCommon} 
  ${dropAreaBgColor(isDragActive)} 
  ${dropAreaCursor(isDragActive)} 
  `

const dropTextClass = `text-base font-normal leading-5 text-center duration-500`

const dropImageBoxClass = `w-[144px] h-[144px] 
                            rounded-full 
                            bg-white/10 
                            mb-6 
                            overflow-hidden duration-500 
                            shrink-0
`
