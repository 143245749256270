import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Board = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='110'
        height='165'
        viewBox='0 0 110 165'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M107.5 145.402V20.3987C107.5 19.5896 106.858 18.9263 106.05 18.8995L4.16947 15.5262C3.78419 15.5135 3.40878 15.6495 3.12115 15.9062L2.78825 15.5331L3.12115 15.9062L1.66973 17.2013C0.925529 17.8654 0.5 18.8154 0.5 19.8128V154.254C0.5 154.649 0.655258 155.027 0.932175 155.308L2.56808 156.966C2.88752 157.29 3.33663 157.451 3.78911 157.405L106.153 146.895C106.918 146.816 107.5 146.172 107.5 145.402Z'
          fill='#121212'
          stroke='white'
        />
        <path
          d='M102.5 149.992V123.317C102.5 123.044 102.282 122.822 102.009 122.817L86.2398 122.519C85.9601 122.514 85.7304 122.739 85.7304 123.019V152.422L102.5 149.992Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M81.5 149.752V123.271C81.5 123.008 81.7043 122.79 81.9671 122.772L85.0441 122.569C85.3326 122.55 85.577 122.779 85.577 123.068V151.353C85.577 151.728 85.1791 151.969 84.8463 151.796L81.7693 150.196C81.6038 150.11 81.5 149.938 81.5 149.752Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M52.4473 158.399V133.337C52.4473 133.063 52.226 132.84 51.9516 132.837L13.9516 132.509C13.6738 132.506 13.4473 132.731 13.4473 133.009V163.846C13.4473 164.151 13.7169 164.385 14.0182 164.341L52.0182 158.894C52.2644 158.859 52.4473 158.648 52.4473 158.399Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M8.5 160.745V133.29C8.5 133.025 8.70667 132.806 8.97118 132.791L12.9712 132.56C13.2582 132.544 13.5 132.772 13.5 133.059V164.105L8.73775 161.171C8.58999 161.08 8.5 160.919 8.5 160.745Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M109.5 145.33V20.4971C109.5 19.6879 108.858 19.0246 108.049 18.9979L4.04945 15.5679C3.2021 15.5399 2.5 16.2192 2.5 17.067V156.768C2.5 157.661 3.27599 158.357 4.16399 158.259L108.164 146.821C108.924 146.737 109.5 146.095 109.5 145.33Z'
          fill='#121212'
          stroke='white'
        />
        <mask id='path-7-inside-1_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M28 0C29.6569 0 31 1.34315 31 3V10.2259C31 10.5829 31.1942 10.9075 31.4847 11.1151C33.0074 12.2032 34 13.9857 34 16V21C34 21.5523 33.5523 22 33 22H29C28.4477 22 28 21.5523 28 21V17.5C28 16.9477 27.5523 16.5 27 16.5C26.4477 16.5 26 16.9477 26 17.5V21C26 21.5523 25.5523 22 25 22H21C20.4477 22 20 21.5523 20 21V17.5C20 16.9477 19.5523 16.5 19 16.5H17C16.4477 16.5 16 16.0523 16 15.5V13.057C16 11.4647 16.9296 10.0922 18.2728 9.44804C18.6842 9.25075 19 8.86399 19 8.40773V3C19 1.34315 20.3431 0 22 0H28Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M28 0C29.6569 0 31 1.34315 31 3V10.2259C31 10.5829 31.1942 10.9075 31.4847 11.1151C33.0074 12.2032 34 13.9857 34 16V21C34 21.5523 33.5523 22 33 22H29C28.4477 22 28 21.5523 28 21V17.5C28 16.9477 27.5523 16.5 27 16.5C26.4477 16.5 26 16.9477 26 17.5V21C26 21.5523 25.5523 22 25 22H21C20.4477 22 20 21.5523 20 21V17.5C20 16.9477 19.5523 16.5 19 16.5H17C16.4477 16.5 16 16.0523 16 15.5V13.057C16 11.4647 16.9296 10.0922 18.2728 9.44804C18.6842 9.25075 19 8.86399 19 8.40773V3C19 1.34315 20.3431 0 22 0H28Z'
          fill='#222222'
        />
        <path
          d='M31.4847 11.1151L30.9032 11.9287L31.4847 11.1151ZM30 3C30 1.89543 29.1046 1 28 1V-1C30.2091 -1 32 0.790861 32 3H30ZM30 10.2259V3H32V10.2259H30ZM32.0661 10.3014C33.8402 11.5693 35 13.6492 35 16H33C33 14.3222 32.1746 12.8372 30.9032 11.9287L32.0661 10.3014ZM35 16V21H33V16H35ZM33 23H29V21H33V23ZM27 21V17.5H29V21H27ZM27 17.5V21H25V17.5H27ZM25 23H21V21H25V23ZM19 21V17.5H21V21H19ZM19 17.5H17V15.5H19V17.5ZM15 15.5V13.057H17V15.5H15ZM15 13.057C15 11.0651 16.1639 9.35032 17.8404 8.54636L18.7052 10.3497C17.6953 10.834 17 11.8643 17 13.057H15ZM20 3V8.40773H18V3H20ZM22 1C20.8954 1 20 1.89543 20 3H18C18 0.790861 19.7909 -1 22 -1V1ZM28 1H22V-1H28V1ZM17 17.5C15.8954 17.5 15 16.6046 15 15.5H17V17.5ZM19 17.5V15.5C20.1046 15.5 21 16.3954 21 17.5H19ZM21 23C19.8954 23 19 22.1046 19 21H21V23ZM27 21C27 22.1046 26.1046 23 25 23V21H27ZM27 17.5V15.5C28.1046 15.5 29 16.3954 29 17.5H27ZM29 23C27.8954 23 27 22.1046 27 21H29V23ZM35 21C35 22.1046 34.1046 23 33 23V21H35ZM27 17.5H25C25 16.3954 25.8954 15.5 27 15.5V17.5ZM17.8404 8.54636C17.9107 8.51263 17.9587 8.46858 17.9833 8.43493C18.0051 8.40513 18 8.39724 18 8.40773H20C20 9.35185 19.3597 10.0359 18.7052 10.3497L17.8404 8.54636ZM32 10.2259C32 10.2202 31.998 10.2237 32.0062 10.2384C32.0153 10.2546 32.0342 10.2787 32.0661 10.3014L30.9032 11.9287C30.394 11.5648 30 10.9562 30 10.2259H32Z'
          fill='#C2C2C2'
          mask='url(#path-7-inside-1_12808_144350)'
        />
        <mask id='path-9-inside-2_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M34 13.5C34 11.2909 32.2091 9.5 30 9.5H26C23.7909 9.5 22 11.2909 22 13.5V14.7273V15.5V21C22 21.5523 22.4477 22 23 22H25C25.5523 22 26 21.5523 26 21V18.5C26 17.9477 26.4477 17.5 27 17.5H29C29.5523 17.5 30 17.9477 30 18.5V21C30 21.5523 30.4477 22 31 22H33C33.5523 22 34 21.5523 34 21V15.5V15V13.5Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M34 13.5C34 11.2909 32.2091 9.5 30 9.5H26C23.7909 9.5 22 11.2909 22 13.5V14.7273V15.5V21C22 21.5523 22.4477 22 23 22H25C25.5523 22 26 21.5523 26 21V18.5C26 17.9477 26.4477 17.5 27 17.5H29C29.5523 17.5 30 17.9477 30 18.5V21C30 21.5523 30.4477 22 31 22H33C33.5523 22 34 21.5523 34 21V15.5V15V13.5Z'
          fill='#222222'
        />
        <path
          d='M30 8.5C32.7614 8.5 35 10.7386 35 13.5H33C33 11.8431 31.6569 10.5 30 10.5V8.5ZM26 8.5H30V10.5H26V8.5ZM21 13.5C21 10.7386 23.2386 8.5 26 8.5V10.5C24.3431 10.5 23 11.8431 23 13.5H21ZM21 14.7273V13.5H23V14.7273H21ZM23 14.7273V15.5H21V14.7273H23ZM23 15.5V21H21V15.5H23ZM23 21H25V23H23V21ZM25 21V18.5H27V21H25ZM29 18.5H27V16.5H29V18.5ZM29 21V18.5H31V21H29ZM33 23H31V21H33V23ZM35 15.5V21H33V15.5H35ZM35 15V15.5H33V15H35ZM35 13.5V15H33V13.5H35ZM33 21H35C35 22.1046 34.1046 23 33 23V21ZM31 21V23C29.8954 23 29 22.1046 29 21H31ZM29 16.5C30.1046 16.5 31 17.3954 31 18.5H29V16.5ZM25 18.5C25 17.3954 25.8954 16.5 27 16.5V18.5H25ZM25 21H27C27 22.1046 26.1046 23 25 23V21ZM23 21V23C21.8954 23 21 22.1046 21 21H23Z'
          fill='#C2C2C2'
          mask='url(#path-9-inside-2_12808_144350)'
        />
        <path d='M19.5 9C22.5 10 27.5 10 30.5 9' stroke='#C2C2C2' />
        <path d='M19.5 6C22.5 7.5 28 7.5 30.5 6' stroke='#C2C2C2' />
        <path d='M19.5 3C22.5 4.5 28 4.5 30.5 3' stroke='#C2C2C2' />
        <mask id='path-14-inside-3_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52 1C53.6569 1 55 2.34315 55 4V11.2259C55 11.5829 55.1942 11.9075 55.4847 12.1151C57.0074 13.2032 58 14.9857 58 17V22C58 22.5523 57.5523 23 57 23H53C52.4477 23 52 22.5523 52 22V18.5C52 17.9477 51.5523 17.5 51 17.5C50.4477 17.5 50 17.9477 50 18.5V22C50 22.5523 49.5523 23 49 23H45C44.4477 23 44 22.5523 44 22V18.5C44 17.9477 43.5523 17.5 43 17.5H41C40.4477 17.5 40 17.0523 40 16.5V14.057C40 12.4647 40.9296 11.0922 42.2728 10.448C42.6842 10.2508 43 9.86399 43 9.40773V4C43 2.34315 44.3431 1 46 1H52Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M52 1C53.6569 1 55 2.34315 55 4V11.2259C55 11.5829 55.1942 11.9075 55.4847 12.1151C57.0074 13.2032 58 14.9857 58 17V22C58 22.5523 57.5523 23 57 23H53C52.4477 23 52 22.5523 52 22V18.5C52 17.9477 51.5523 17.5 51 17.5C50.4477 17.5 50 17.9477 50 18.5V22C50 22.5523 49.5523 23 49 23H45C44.4477 23 44 22.5523 44 22V18.5C44 17.9477 43.5523 17.5 43 17.5H41C40.4477 17.5 40 17.0523 40 16.5V14.057C40 12.4647 40.9296 11.0922 42.2728 10.448C42.6842 10.2508 43 9.86399 43 9.40773V4C43 2.34315 44.3431 1 46 1H52Z'
          fill='#222222'
        />
        <path
          d='M55.4847 12.1151L54.9032 12.9287L55.4847 12.1151ZM54 4C54 2.89543 53.1046 2 52 2V0C54.2091 0 56 1.79086 56 4H54ZM54 11.2259V4H56V11.2259H54ZM56.0661 11.3014C57.8402 12.5693 59 14.6492 59 17H57C57 15.3222 56.1746 13.8372 54.9032 12.9287L56.0661 11.3014ZM59 17V22H57V17H59ZM57 24H53V22H57V24ZM51 22V18.5H53V22H51ZM51 18.5V22H49V18.5H51ZM49 24H45V22H49V24ZM43 22V18.5H45V22H43ZM43 18.5H41V16.5H43V18.5ZM39 16.5V14.057H41V16.5H39ZM39 14.057C39 12.0651 40.1639 10.3503 41.8404 9.54636L42.7052 11.3497C41.6953 11.834 41 12.8643 41 14.057H39ZM44 4V9.40773H42V4H44ZM46 2C44.8954 2 44 2.89543 44 4H42C42 1.79086 43.7909 0 46 0V2ZM52 2H46V0H52V2ZM41 18.5C39.8954 18.5 39 17.6046 39 16.5H41V18.5ZM43 18.5V16.5C44.1046 16.5 45 17.3954 45 18.5H43ZM45 24C43.8954 24 43 23.1046 43 22H45V24ZM51 22C51 23.1046 50.1046 24 49 24V22H51ZM51 18.5V16.5C52.1046 16.5 53 17.3954 53 18.5H51ZM53 24C51.8954 24 51 23.1046 51 22H53V24ZM59 22C59 23.1046 58.1046 24 57 24V22H59ZM51 18.5H49C49 17.3954 49.8954 16.5 51 16.5V18.5ZM41.8404 9.54636C41.9107 9.51263 41.9587 9.46858 41.9833 9.43493C42.0051 9.40513 42 9.39724 42 9.40773H44C44 10.3518 43.3597 11.0359 42.7052 11.3497L41.8404 9.54636ZM56 11.2259C56 11.2202 55.998 11.2237 56.0062 11.2384C56.0153 11.2546 56.0342 11.2787 56.0661 11.3014L54.9032 12.9287C54.394 12.5648 54 11.9562 54 11.2259H56Z'
          fill='#C2C2C2'
          mask='url(#path-14-inside-3_12808_144350)'
        />
        <mask id='path-16-inside-4_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M58 14.5C58 12.2909 56.2091 10.5 54 10.5H50C47.7909 10.5 46 12.2909 46 14.5V15.7273V16.5V22C46 22.5523 46.4477 23 47 23H49C49.5523 23 50 22.5523 50 22V19.5C50 18.9477 50.4477 18.5 51 18.5H53C53.5523 18.5 54 18.9477 54 19.5V22C54 22.5523 54.4477 23 55 23H57C57.5523 23 58 22.5523 58 22V16.5V16V14.5Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M58 14.5C58 12.2909 56.2091 10.5 54 10.5H50C47.7909 10.5 46 12.2909 46 14.5V15.7273V16.5V22C46 22.5523 46.4477 23 47 23H49C49.5523 23 50 22.5523 50 22V19.5C50 18.9477 50.4477 18.5 51 18.5H53C53.5523 18.5 54 18.9477 54 19.5V22C54 22.5523 54.4477 23 55 23H57C57.5523 23 58 22.5523 58 22V16.5V16V14.5Z'
          fill='#222222'
        />
        <path
          d='M54 9.5C56.7614 9.5 59 11.7386 59 14.5H57C57 12.8431 55.6569 11.5 54 11.5V9.5ZM50 9.5H54V11.5H50V9.5ZM45 14.5C45 11.7386 47.2386 9.5 50 9.5V11.5C48.3431 11.5 47 12.8431 47 14.5H45ZM45 15.7273V14.5H47V15.7273H45ZM47 15.7273V16.5H45V15.7273H47ZM47 16.5V22H45V16.5H47ZM47 22H49V24H47V22ZM49 22V19.5H51V22H49ZM53 19.5H51V17.5H53V19.5ZM53 22V19.5H55V22H53ZM57 24H55V22H57V24ZM59 16.5V22H57V16.5H59ZM59 16V16.5H57V16H59ZM59 14.5V16H57V14.5H59ZM57 22H59C59 23.1046 58.1046 24 57 24V22ZM55 22V24C53.8954 24 53 23.1046 53 22H55ZM53 17.5C54.1046 17.5 55 18.3954 55 19.5H53V17.5ZM49 19.5C49 18.3954 49.8954 17.5 51 17.5V19.5H49ZM49 22H51C51 23.1046 50.1046 24 49 24V22ZM47 22V24C45.8954 24 45 23.1046 45 22H47Z'
          fill='#C2C2C2'
          mask='url(#path-16-inside-4_12808_144350)'
        />
        <path d='M43.5 10C46.5 11 51.5 11 54.5 10' stroke='#C2C2C2' />
        <path d='M43.5 7C46.5 8.5 52 8.5 54.5 7' stroke='#C2C2C2' />
        <path d='M43.5 4C46.5 5.5 52 5.5 54.5 4' stroke='#C2C2C2' />
        <mask id='path-21-inside-5_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M69 2C70.6569 2 72 3.34315 72 5V12.2259C72 12.5829 72.1942 12.9075 72.4847 13.1151C74.0074 14.2032 75 15.9857 75 18V23C75 23.5523 74.5523 24 74 24H70C69.4477 24 69 23.5523 69 23V19C69 18.4477 68.5523 18 68 18C67.4477 18 67 18.4477 67 19V23C67 23.5523 66.5523 24 66 24H62C61.4477 24 61 23.5523 61 23V19C61 18.4477 60.5523 18 60 18H58C57.4477 18 57 17.5523 57 17V15.057C57 13.4647 57.9296 12.0922 59.2728 11.448C59.6842 11.2508 60 10.864 60 10.4077V5C60 3.34315 61.3431 2 63 2H69Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M69 2C70.6569 2 72 3.34315 72 5V12.2259C72 12.5829 72.1942 12.9075 72.4847 13.1151C74.0074 14.2032 75 15.9857 75 18V23C75 23.5523 74.5523 24 74 24H70C69.4477 24 69 23.5523 69 23V19C69 18.4477 68.5523 18 68 18C67.4477 18 67 18.4477 67 19V23C67 23.5523 66.5523 24 66 24H62C61.4477 24 61 23.5523 61 23V19C61 18.4477 60.5523 18 60 18H58C57.4477 18 57 17.5523 57 17V15.057C57 13.4647 57.9296 12.0922 59.2728 11.448C59.6842 11.2508 60 10.864 60 10.4077V5C60 3.34315 61.3431 2 63 2H69Z'
          fill='#222222'
        />
        <path
          d='M72.4847 13.1151L71.9032 13.9287L72.4847 13.1151ZM71 5C71 3.89543 70.1046 3 69 3V1C71.2091 1 73 2.79086 73 5H71ZM71 12.2259V5H73V12.2259H71ZM73.0661 12.3014C74.8402 13.5693 76 15.6492 76 18H74C74 16.3222 73.1746 14.8372 71.9032 13.9287L73.0661 12.3014ZM76 18V23H74V18H76ZM74 25H70V23H74V25ZM68 23V19H70V23H68ZM68 19V23H66V19H68ZM66 25H62V23H66V25ZM60 23V19H62V23H60ZM60 19H58V17H60V19ZM56 17V15.057H58V17H56ZM56 15.057C56 13.0651 57.1639 11.3503 58.8404 10.5464L59.7052 12.3497C58.6953 12.834 58 13.8643 58 15.057H56ZM61 5V10.4077H59V5H61ZM63 3C61.8954 3 61 3.89543 61 5H59C59 2.79086 60.7909 1 63 1V3ZM69 3H63V1H69V3ZM58 19C56.8954 19 56 18.1046 56 17H58V19ZM60 19V17C61.1046 17 62 17.8954 62 19H60ZM62 25C60.8954 25 60 24.1046 60 23H62V25ZM68 23C68 24.1046 67.1046 25 66 25V23H68ZM68 19V17C69.1046 17 70 17.8954 70 19H68ZM70 25C68.8954 25 68 24.1046 68 23H70V25ZM76 23C76 24.1046 75.1046 25 74 25V23H76ZM68 19H66C66 17.8954 66.8954 17 68 17V19ZM58.8404 10.5464C58.9107 10.5126 58.9587 10.4686 58.9833 10.4349C59.0051 10.4051 59 10.3972 59 10.4077H61C61 11.3518 60.3597 12.0359 59.7052 12.3497L58.8404 10.5464ZM73 12.2259C73 12.2202 72.998 12.2237 73.0062 12.2384C73.0153 12.2546 73.0342 12.2787 73.0661 12.3014L71.9032 13.9287C71.394 13.5648 71 12.9562 71 12.2259H73Z'
          fill='#C2C2C2'
          mask='url(#path-21-inside-5_12808_144350)'
        />
        <mask id='path-23-inside-6_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M75 15.5C75 13.2909 73.2091 11.5 71 11.5H67C64.7909 11.5 63 13.2909 63 15.5V16.7273V17.5V23C63 23.5523 63.4477 24 64 24H66C66.5523 24 67 23.5523 67 23V20.5C67 19.9477 67.4477 19.5 68 19.5H70C70.5523 19.5 71 19.9477 71 20.5V23C71 23.5523 71.4477 24 72 24H74C74.5523 24 75 23.5523 75 23V17.5V17V15.5Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M75 15.5C75 13.2909 73.2091 11.5 71 11.5H67C64.7909 11.5 63 13.2909 63 15.5V16.7273V17.5V23C63 23.5523 63.4477 24 64 24H66C66.5523 24 67 23.5523 67 23V20.5C67 19.9477 67.4477 19.5 68 19.5H70C70.5523 19.5 71 19.9477 71 20.5V23C71 23.5523 71.4477 24 72 24H74C74.5523 24 75 23.5523 75 23V17.5V17V15.5Z'
          fill='#222222'
        />
        <path
          d='M71 10.5C73.7614 10.5 76 12.7386 76 15.5H74C74 13.8431 72.6569 12.5 71 12.5V10.5ZM67 10.5H71V12.5H67V10.5ZM62 15.5C62 12.7386 64.2386 10.5 67 10.5V12.5C65.3431 12.5 64 13.8431 64 15.5H62ZM62 16.7273V15.5H64V16.7273H62ZM64 16.7273V17.5H62V16.7273H64ZM64 17.5V23H62V17.5H64ZM64 23H66V25H64V23ZM66 23V20.5H68V23H66ZM70 20.5H68V18.5H70V20.5ZM70 23V20.5H72V23H70ZM74 25H72V23H74V25ZM76 17.5V23H74V17.5H76ZM76 17V17.5H74V17H76ZM76 15.5V17H74V15.5H76ZM74 23H76C76 24.1046 75.1046 25 74 25V23ZM72 23V25C70.8954 25 70 24.1046 70 23H72ZM70 18.5C71.1046 18.5 72 19.3954 72 20.5H70V18.5ZM66 20.5C66 19.3954 66.8954 18.5 68 18.5V20.5H66ZM66 23H68C68 24.1046 67.1046 25 66 25V23ZM64 23V25C62.8954 25 62 24.1046 62 23H64Z'
          fill='#C2C2C2'
          mask='url(#path-23-inside-6_12808_144350)'
        />
        <path d='M60.5 11C63.5 12 68.5 12 71.5 11' stroke='#C2C2C2' />
        <path d='M60.5 8C63.5 9.5 69 9.5 71.5 8' stroke='#C2C2C2' />
        <path d='M60.5 5C63.5 6.5 69 6.5 71.5 5' stroke='#C2C2C2' />
        <mask id='path-28-inside-7_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M90 3C91.6569 3 93 4.34315 93 6V13.2259C93 13.5829 93.1942 13.9075 93.4847 14.1151C95.0074 15.2032 96 16.9857 96 19V24C96 24.5523 95.5523 25 95 25H91C90.4477 25 90 24.5523 90 24V19.5C90 18.9477 89.5523 18.5 89 18.5C88.4477 18.5 88 18.9477 88 19.5V24C88 24.5523 87.5523 25 87 25H83C82.4477 25 82 24.5523 82 24V19.5C82 18.9477 81.5523 18.5 81 18.5H79C78.4477 18.5 78 18.0523 78 17.5V16.0488C78 14.4568 78.9294 13.0841 80.2728 12.4399C80.6842 12.2426 81 11.8558 81 11.3995V6C81 4.34315 82.3431 3 84 3H90Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M90 3C91.6569 3 93 4.34315 93 6V13.2259C93 13.5829 93.1942 13.9075 93.4847 14.1151C95.0074 15.2032 96 16.9857 96 19V24C96 24.5523 95.5523 25 95 25H91C90.4477 25 90 24.5523 90 24V19.5C90 18.9477 89.5523 18.5 89 18.5C88.4477 18.5 88 18.9477 88 19.5V24C88 24.5523 87.5523 25 87 25H83C82.4477 25 82 24.5523 82 24V19.5C82 18.9477 81.5523 18.5 81 18.5H79C78.4477 18.5 78 18.0523 78 17.5V16.0488C78 14.4568 78.9294 13.0841 80.2728 12.4399C80.6842 12.2426 81 11.8558 81 11.3995V6C81 4.34315 82.3431 3 84 3H90Z'
          fill='#222222'
        />
        <path
          d='M93.4847 14.1151L94.0661 13.3014L93.4847 14.1151ZM92 6C92 4.89543 91.1046 4 90 4V2C92.2091 2 94 3.79086 94 6H92ZM92 13.2259V6H94V13.2259H92ZM94.0661 13.3014C95.8402 14.5693 97 16.6492 97 19H95C95 17.3222 94.1746 15.8372 92.9032 14.9287L94.0661 13.3014ZM97 19V24H95V19H97ZM95 26H91V24H95V26ZM89 24V19.5H91V24H89ZM89 19.5V24H87V19.5H89ZM87 26H83V24H87V26ZM81 24V19.5H83V24H81ZM81 19.5H79V17.5H81V19.5ZM77 17.5V16.0488H79V17.5H77ZM77 16.0488C77 14.0573 78.1637 12.3422 79.8404 11.5382L80.7052 13.3415C79.6952 13.8259 79 14.8563 79 16.0488H77ZM82 6V11.3995H80V6H82ZM84 4C82.8954 4 82 4.89543 82 6H80C80 3.79086 81.7909 2 84 2V4ZM90 4H84V2H90V4ZM79 19.5C77.8954 19.5 77 18.6046 77 17.5H79V19.5ZM81 19.5V17.5C82.1046 17.5 83 18.3954 83 19.5H81ZM83 26C81.8954 26 81 25.1046 81 24H83V26ZM89 24C89 25.1046 88.1046 26 87 26V24H89ZM89 19.5V17.5C90.1046 17.5 91 18.3954 91 19.5H89ZM91 26C89.8954 26 89 25.1046 89 24H91V26ZM97 24C97 25.1046 96.1046 26 95 26V24H97ZM89 19.5H87C87 18.3954 87.8954 17.5 89 17.5V19.5ZM79.8404 11.5382C79.9107 11.5044 79.9587 11.4604 79.9833 11.4267C80.0051 11.397 80 11.3891 80 11.3995H82C82 12.3437 81.3596 13.0277 80.7052 13.3415L79.8404 11.5382ZM94 13.2259C94 13.2202 93.998 13.2237 94.0062 13.2384C94.0153 13.2546 94.0342 13.2787 94.0661 13.3014L92.9032 14.9287C92.394 14.5648 92 13.9562 92 13.2259H94Z'
          fill='#C2C2C2'
          mask='url(#path-28-inside-7_12808_144350)'
        />
        <mask id='path-30-inside-8_12808_144350' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M96 16.5C96 14.2909 94.2091 12.5 92 12.5H88C85.7909 12.5 84 14.2909 84 16.5V17.7273V18.5V24C84 24.5523 84.4477 25 85 25H87C87.5523 25 88 24.5523 88 24V21.5C88 20.9477 88.4477 20.5 89 20.5H91C91.5523 20.5 92 20.9477 92 21.5V24C92 24.5523 92.4477 25 93 25H95C95.5523 25 96 24.5523 96 24V18.5V18V16.5Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M96 16.5C96 14.2909 94.2091 12.5 92 12.5H88C85.7909 12.5 84 14.2909 84 16.5V17.7273V18.5V24C84 24.5523 84.4477 25 85 25H87C87.5523 25 88 24.5523 88 24V21.5C88 20.9477 88.4477 20.5 89 20.5H91C91.5523 20.5 92 20.9477 92 21.5V24C92 24.5523 92.4477 25 93 25H95C95.5523 25 96 24.5523 96 24V18.5V18V16.5Z'
          fill='#222222'
        />
        <path
          d='M92 11.5C94.7614 11.5 97 13.7386 97 16.5H95C95 14.8431 93.6569 13.5 92 13.5V11.5ZM88 11.5H92V13.5H88V11.5ZM83 16.5C83 13.7386 85.2386 11.5 88 11.5V13.5C86.3431 13.5 85 14.8431 85 16.5H83ZM83 17.7273V16.5H85V17.7273H83ZM85 17.7273V18.5H83V17.7273H85ZM85 18.5V24H83V18.5H85ZM85 24H87V26H85V24ZM87 24V21.5H89V24H87ZM91 21.5H89V19.5H91V21.5ZM91 24V21.5H93V24H91ZM95 26H93V24H95V26ZM97 18.5V24H95V18.5H97ZM97 18V18.5H95V18H97ZM97 16.5V18H95V16.5H97ZM95 24H97C97 25.1046 96.1046 26 95 26V24ZM93 24V26C91.8954 26 91 25.1046 91 24H93ZM91 19.5C92.1046 19.5 93 20.3954 93 21.5H91V19.5ZM87 21.5C87 20.3954 87.8954 19.5 89 19.5V21.5H87ZM87 24H89C89 25.1046 88.1046 26 87 26V24ZM85 24V26C83.8954 26 83 25.1046 83 24H85Z'
          fill='#C2C2C2'
          mask='url(#path-30-inside-8_12808_144350)'
        />
        <path d='M81.5 12C84.5 13 89.5 13 92.5 12' stroke='#C2C2C2' />
        <path d='M81.5 9C84.5 10.5 90 10.5 92.5 9' stroke='#C2C2C2' />
        <path d='M81.5 6C84.5 7.5 90 7.5 92.5 6' stroke='#C2C2C2' />
        <path
          d='M67.5 90.1569V77C67.5 76.7239 67.2761 76.5 67 76.5H55C54.7239 76.5 54.5 76.7239 54.5 77V90.9332C54.5 91.2221 54.744 91.4508 55.0323 91.4322L67.0323 90.6559C67.2953 90.6389 67.5 90.4206 67.5 90.1569Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M52.3872 76.9895L54.5 76.5V90.2844C54.5 90.5504 54.1794 90.6848 53.9897 90.4983L52 88.5424V77.4766C52 77.2439 52.1605 77.042 52.3872 76.9895Z'
          stroke='white'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default Board
