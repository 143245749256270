import React from 'react'

import IconButton from 'src/components/Button/IconButton'

import { BiX } from 'react-icons/bi'

export const CloseIcon = ({ className = '', style, disabled, onClick }) => (
  <IconButton
    style={style}
    className={`${className} absolute top-2 right-4`}
    type='standard'
    size='md'
    disabled={disabled}
    onClick={onClick}>
    <BiX className='text-2xl' />
  </IconButton>
)

export const Icon = ({ className = '', style, children }) => (
  <div style={style} className='mx-auto mb-6 text-6xl'>
    {children}
  </div>
)

export const Title = ({ className = '', style, children }) => (
  <h4
    style={style}
    className={`${className} block text-light-4 text-lg font-bold text-center mb-2`}>
    {children}
  </h4>
)

export const Description = ({ className = '', style, children }) => (
  <p
    style={style}
    className={`${className} block text-light-4 text-base font-normal text-center`}>
    {children}
  </p>
)

export const FormLabel = ({ htmlFor, className = '', style, children }) => (
  <label
    style={style}
    htmlFor={htmlFor}
    className={`${className} block text-light-1 text-base mb-2`}>
    {children}
  </label>
)

export const RowButtonBox = ({ className = '', style, children }) => (
  <div
    style={style}
    className={`${className} flex justify-center gap-x-6 mt-14`}>
    {children}
  </div>
)

export const ColumnButtonBox = ({ className = '', style, children }) => (
  <div style={style} className={`${className} flex flex-col gap-y-4 mt-14`}>
    {children}
  </div>
)

export const Divide = ({ className = '', style }) => (
  <div style={style} className={`${className} w-full h-[1px] bg-dark-5 my-4`} />
)
