import Ud00EFAvatar from 'src/assets/svg/device/avatar/Ud00ef'
import Ud5gAvatar from 'src/assets/svg/device/avatar/Ud5g'
import UdModuleAvatar from 'src/assets/svg/device/avatar/UdModule'
import Ud0630Avatar from 'src/assets/svg/device/avatar/Ud0630'
import SgAvatar from 'src/assets/svg/device/avatar/Sg'

import PowerDetectorAvatar from 'src/assets/svg/device/avatar/PowerDetector'

import BboardAvatar from 'src/assets/svg/device/avatar/Bboard'
import BboxLiteAvatar from 'src/assets/svg/device/avatar/BboxLite'
import BboxOneAvatar from 'src/assets/svg/device/avatar/BboxOne'
import CloverCellEvbAvatar from 'src/assets/svg/device/avatar/CloverCellEvb'
import CloverCell1024Avatar from 'src/assets/svg/device/avatar/CloverCell1024'
import RisAvatar from 'src/assets/svg/device/avatar/Ris'
import CloverCellCttc1Avatar from 'src/assets/svg/device/avatar/CloverCellCttc1'
import CloverCellCttc4Avatar from 'src/assets/svg/device/avatar/CloverCellCttc4'

import UdboxIcon from 'src/assets/svg/device/icon/Udbox'
import Udbox5GIcon from 'src/assets/svg/device/icon/Udbox5G'
import UdModuleIcon from 'src/assets/svg/device/icon/UdModule'
import Ud0630Icon from 'src/assets/svg/device/icon/Ud0630'
import SgIcon from 'src/assets/svg/device/icon/Sg'

import PowerDetectorIcon from 'src/assets/svg/device/icon/PowerDetector'

import BboxLite5GIcon from 'src/assets/svg/device/icon/BboxLite5G'
import BboxOne5GIcon from 'src/assets/svg/device/icon/BboxOne5G'
import BboardIcon from 'src/assets/svg/device/icon/Bboard'
import CloverCellEvbIcon from 'src/assets/svg/device/icon/CloverCellEvb'
import CloverCell1024Icon from 'src/assets/svg/device/icon/CloverCell1024'
import RisIcon from 'src/assets/svg/device/icon/Ris'
import CloverCellCttc1Icon from 'src/assets/svg/device/icon/CloverCellCttc1'
import CloverCellCTTC4Icon from 'src/assets/svg/device/icon/CloverCellCttc4'

export const getDeviceInfo = {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------  Standard Product  -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // UDBox - 00E
  4: {
    officialName: sn => ({ first: 'UD Box', second: '', third: '00E' }),
    devName: { first: 'udbox', second: '', third: '00E' },
    class: 'freqConverter',
    avatar: sn => <Ud00EFAvatar channel={getUdChannelMode(sn)} />,
    icon: (sn, hardwareVersion) => <UdboxIcon sn={sn} />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'SOURCE_100M'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'udboxUIControlForSingle',
      all: '',
    },
  },

  // UDBox - 00D
  5: {
    officialName: sn => ({ first: 'UD Box', second: '', third: '00D' }),
    devName: { first: 'udbox', second: '', third: '00D' },
    class: 'freqConverter',
    avatar: sn => <Ud00EFAvatar channel={getUdChannelMode(sn)} />,
    icon: (sn, hardwareVersion) => <UdboxIcon sn={sn} />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'SOURCE_100M'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'udboxUIControlForSingle',
      all: '',
    },
  },

  // BBox Lite 5G - 28
  7: {
    officialName: sn => ({ first: 'BBox', second: 'Lite', third: '5G' }),
    devName: { first: 'bbox', second: 'lite', third: '28' },
    class: 'beamformers',
    avatar: sn => <BboxLiteAvatar />,
    icon: (sn, hardwareVersion) => <BboxLite5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBox Lite 5G - 39
  8: {
    officialName: sn => ({ first: 'BBox', second: 'Lite', third: '5G' }),
    devName: { first: 'bbox', second: 'lite', third: '39' },
    class: 'beamformers',
    avatar: sn => <BboxLiteAvatar />,
    icon: (sn, hardwareVersion) => <BboxLite5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBox One 5G - 28
  9: {
    officialName: sn => ({ first: 'BBox', second: 'One', third: '5G' }),
    devName: { first: 'bbox', second: 'one', third: '28' },
    class: 'beamformers',
    avatar: sn => <BboxOneAvatar />,
    icon: (sn, hardwareVersion) => <BboxOne5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBox One 5G - 39
  10: {
    officialName: sn => ({ first: 'BBox', second: 'One', third: '5G' }),
    devName: { first: 'bbox', second: 'one', third: '39' },
    class: 'beamformers',
    avatar: sn => <BboxOneAvatar />,
    icon: (sn, hardwareVersion) => <BboxOne5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBoard - 28
  12: {
    officialName: sn => ({ first: 'BBoard', second: '', third: '' }),
    devName: { first: 'bboard', second: '', third: '28' },
    class: 'beamformers',
    avatar: sn => <BboardAvatar />,
    icon: (sn, hardwareVersion) => <BboardIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboardUIControlForSingle',
      all: '',
    },
  },

  // BBoard - 39
  13: {
    officialName: sn => ({ first: 'BBoard', second: '', third: '' }),
    devName: { first: 'bboard', second: '', third: '39' },
    class: 'beamformers',
    avatar: sn => <BboardAvatar />,
    icon: (sn, hardwareVersion) => <BboardIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboardUIControlForSingle',
      all: '',
    },
  },

  // UDBox - 00F
  14: {
    officialName: sn => ({ first: 'UD Box', second: '', third: '00F' }),
    devName: { first: 'udbox', second: '', third: '00F' },
    class: 'freqConverter',
    avatar: sn => <Ud00EFAvatar channel={getUdChannelMode(sn)} />,
    icon: (sn, hardwareVersion) => <UdboxIcon sn={sn} />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'SOURCE_100M'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'udboxUIControlForSingle',
      all: '',
    },
  },

  // UDBox 5G
  15: {
    officialName: sn => ({ first: 'UD Box', second: '', third: '5G' }),
    devName: { first: 'udbox', second: '', third: '5g' },
    class: 'freqConverter',
    avatar: sn => <Ud5gAvatar channel={getUdChannelMode(sn)} />,
    icon: (sn, hardwareVersion) => <Udbox5GIcon sn={sn} />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'SOURCE_100M'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'udboxUIControlForSingle',
      all: '',
    },
  },

  // UD Demo AD (已廢棄)
  // 16: {
  //   officialName:sn=> ({ first: 'UD Module', second: '', third: '' }),
  //   devName: { first: 'udmodule', second: '', third: '' },
  //   class: 'freqConverter',
  //   avatar: (sn) => {},
  //   icon: (sn) => {},
  //   inventoryMark: {
  //     paramPath: ['settings', 'device', 'SOURCE_100M'],
  //     render: param => UDRefSourceString[param] || '',
  //   },
  //   uiControlSliceName: {
  //     single: 'udboxUIControlForSingle',
  //     all: '',
  //   },
  // },

  // BBoard - 26
  17: {
    officialName: sn => ({ first: 'BBoard', second: '', third: '' }),
    devName: { first: 'bboard', second: '', third: '26' },
    class: 'beamformers',
    avatar: sn => <BboardAvatar />,
    icon: (sn, hardwareVersion) => <BboardIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboardUIControlForSingle',
      all: '',
    },
  },

  // BBox Lite 5G - 26
  18: {
    officialName: sn => ({ first: 'BBox', second: 'Lite', third: '5G' }),
    devName: { first: 'bbox', second: 'lite', third: '26' },
    class: 'beamformers',
    avatar: sn => <BboxLiteAvatar />,
    icon: (sn, hardwareVersion) => <BboxLite5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBox One 5G - 26
  19: {
    officialName: sn => ({ first: 'BBox', second: 'One', third: '5G' }),
    devName: { first: 'bbox', second: 'one', third: '26' },
    class: 'beamformers',
    avatar: sn => <BboxOneAvatar />,
    icon: (sn, hardwareVersion) => <BboxOne5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBox Lite 5G - 28 (NI)
  20: {
    officialName: sn => ({ first: 'BBox', second: 'Lite', third: '5G' }),
    devName: { first: 'bbox', second: 'lite', third: '28' },
    class: 'beamformers',
    avatar: sn => <BboxLiteAvatar />,
    icon: (sn, hardwareVersion) => <BboxLite5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // BBox One 5G - 39  (NI)
  21: {
    officialName: sn => ({ first: 'BBox', second: 'One', third: '5G' }),
    devName: { first: 'bbox', second: 'one', third: '28' },
    class: 'beamformers',
    avatar: sn => <BboxOneAvatar />,
    icon: (sn, hardwareVersion) => <BboxOne5GIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => param?.toUpperCase(),
    },
    uiControlSliceName: {
      single: 'bboxUIControlForSingle',
      all: 'bboxUIControlForAll',
    },
  },

  // PD
  22: {
    officialName: sn => ({ first: 'Power Detector', second: '', third: '' }),
    devName: { first: 'pd', second: '', third: '' },
    class: '',
    avatar: sn => <PowerDetectorAvatar sn={sn} />,
    icon: (sn, hardwareVersion) => <PowerDetectorIcon />,
    inventoryMark: {
      paramPath: [],
      render: param => {},
    },
    uiControlSliceName: {
      single: 'powerDetectorUIControlForSingle',
      all: 'powerDetectorUIControlForAll',
    },
  },

  // UD Module - 0620
  23: {
    officialName: sn => ({
      first: 'UD Module',
      second: '',
      third: '',
    }),
    devName: { first: 'udModule', second: '', third: '' },
    class: 'freqConverter',
    avatar: sn => <UdModuleAvatar />,
    icon: (sn, hardwareVersion) => <UdModuleIcon />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'refSource'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'udboxUIControlForSingle',
      all: '',
    },
  },

  // UD Module - 0630 & 1753
  24: {
    officialName: sn => ({
      first: 'UD Box',
      second: '',
      third: '0630',
    }),
    devName: { first: 'udbox', second: '', third: '0630' },
    class: 'freqConverter',
    avatar: sn => <Ud0630Avatar />,
    icon: (sn, hardwareVersion) => <Ud0630Icon />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'refSource'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'udboxUIControlForSingle',
      all: '',
    },
  },

  // Clover Cell - Evb
  25: {
    officialName: sn => ({
      first: 'CloverCell™',
      second: 'EVB',
      third: '',
    }),
    devName: { first: 'cloverCellEvb', second: '', third: '' },
    class: 'beamformers',
    avatar: sn => <CloverCellEvbAvatar />,
    icon: () => <CloverCellEvbIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => {
        if (param === 'tx') return 'TX'
        if (param === 'rx') return 'RX'
        if (param === 'standby') return 'STBY'
      },
    },
    uiControlSliceName: {
      single: 'cclEvbUIControlForSingle',
      all: 'cclEvbUIControlForAll',
    },
  },
  // Clover Cell - (保留)
  26: {
    officialName: sn => ({
      first: 'CloverCell™',
      second: 'EVB',
      third: '',
    }),
    devName: { first: 'cloverCellEvb', second: '', third: '' },
    class: 'beamformers',
    avatar: sn => <CloverCellEvbAvatar />,
    icon: () => <CloverCellEvbIcon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => {
        if (param === 'tx') return 'TX'
        if (param === 'rx') return 'RX'
        if (param === 'standby') return 'STBY'
      },
    },
    uiControlSliceName: {
      single: 'cclEvbUIControlForSingle',
      all: 'cclEvbUIControlForAll',
    },
  },

  // SG
  27: {
    officialName: sn => ({
      first: 'Signal Generator',
      second: '',
      third: '',
    }),
    devName: { first: 'sg', second: '', third: '' },
    class: 'freqConverter',
    avatar: sn => <SgAvatar />,
    icon: () => <SgIcon />,
    inventoryMark: {
      paramPath: ['settings', 'device', 'refSource'],
      render: param => UDRefSourceString[param] || '',
    },
    uiControlSliceName: {
      single: 'sgUIControlForSingle',
      all: '',
    },
  },
  // RIS
  28: {
    officialName: sn => ({
      first: 'RIS',
      second: '',
      third: '',
    }),
    devName: { first: 'ris', second: '', third: '' },
    class: 'beamformers',
    avatar: sn => <RisAvatar />,
    icon: () => <RisIcon />,
    inventoryMark: {
      paramPath: [],
      render: () => {},
    },
    uiControlSliceName: {
      single: 'risUIControlForSingle',
      all: 'risUIControlForAll',
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------   Custom Product   -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // Clover Cell - 1024
  255: {
    officialName: sn => ({
      first: 'CloverCell™',
      second: ' 1024 Array',
      third: '',
    }),
    devName: { first: 'cloverCellArray1024', second: '', third: '' },
    class: 'beamformers',
    avatar: sn => <CloverCell1024Avatar />,
    icon: () => <CloverCell1024Icon />,
    inventoryMark: {
      paramPath: ['deviceControl', 'common', 'rfMode'],
      render: param => {
        if (param === 'tx') return 'TX'
        if (param === 'rx') return 'RX'
        if (param === 'standby') return 'STBY'
      },
    },
    uiControlSliceName: {
      single: 'cclEvbUIControlForSingle',
      all: 'cclEvbUIControlForAll',
    },
  },
  // Clover Cell - CTTC
  254: {
    officialName: sn =>
      +sn[sn.length - 1] === 1
        ? {
            first: 'CloverCell™',
            second: 'AiP 8x8',
            third: '',
          }
        : {
            first: 'CloverCell™',
            second: 'AiP 16x16',
            third: '',
          },
    devName: { first: 'cloverCellCTTC', second: '', third: '' },
    class: 'beamformers',
    avatar: sn =>
      +sn[sn.length - 1] === 1 ? (
        <CloverCellCttc1Avatar />
      ) : (
        <CloverCellCttc4Avatar />
      ),
    icon: sn =>
      +sn[sn.length - 1] === 1 ? (
        <CloverCellCttc1Icon />
      ) : (
        <CloverCellCTTC4Icon />
      ),
    inventoryMark: {
      paramPath: [],
      render: param => null,
    },
    uiControlSliceName: {
      single: 'cclCttcUIControlForSingle',
      all: '',
    },
  },
}

export const getOfficialFullName = (sn, deviceType) => {
  if (getDeviceInfo[deviceType]) {
    const { first, second, third } = getDeviceInfo[deviceType]?.officialName(sn)
    const name = first
    const type = second ? ` ${second}` : ''
    const version = third ? ` ${third}` : ''
    return name + type + version
  }

  return ''
}

export const getUdChannelMode = sn =>
  sn ? (sn[4] === 'S' ? 'single' : 'dual') : undefined

const UDRefSourceString = {
  0: 'Internal',
  1: 'External',
}
