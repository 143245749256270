import { call, put, delay } from 'redux-saga/effects'

import { modalActions } from 'src/redux/slices/modal'
import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* selectCurrentCaliID(response) {
  try {
    const [sn, { currentCaliID }] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    currentData.deviceControl.actionPanel.currentCaliID = currentCaliID

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield put(
      modalActions.show_modal_powerDetector_actionPanel_caliConfigFileChanged()
    )

    yield delay(1000)

    yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog('[res-handler] dashboardRenderPoint error:', error)
  }
}
