import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import { commonSignalGeneratorChange as handleCommonSignalGeneratorChangeResponse } from 'src/redux/sagas/handlers/response/beamformers/cloverCell1024/common'

const cloverCellArray1024Api = {
  CLOVERCELL_ARRAY1024_COMMON_SIGNAL_GENERATOR_FREQUENCY: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/array1024/common/signalGeneratorFrequency',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName:
        'clovercell/array1024/common/signalGeneratorFrequency/response',
      handler: handleCommonSignalGeneratorChangeResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_ARRAY1024_CHANNEL_POWER_ALL: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/array1024/channel/power/all',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/array1024/channel/power/all/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_ARRAY1024_BEAM_STEERING_THETA_PHI: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/array1024/beam/steering/thetaPhi',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/array1024/beam/steering/thetaPhi/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_ARRAY1024_BEAM_STEERING_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/array1024/beam/steering/gain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/array1024/beam/steering/gain/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_ARRAY1024_SPI_SWITCH_METHOD: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/array1024/spi/switch/method',
      beforeEmitActionType: null,
      throttle: true,
      timeout: 15000,
    },
    on: {
      eventName: 'clovercell/array1024/spi/switch/method/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default cloverCellArray1024Api
