import React, { useState, useRef, useEffect } from 'react'

import lottie from 'lottie-web'
import * as R from 'ramda'
import PropTypes from 'prop-types'

import { tryCatch } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <LottieWeb
//  width={} // ex: '100px'
//  height={} // ex: '100px'
//  className={''}
//  play={true}
//  loop={true}
//  animationData={} // json
//  segments={[]}
//  forceSegments={false}
//  onLoopComplete={()=>{}}
// />

const Lottie = props => {
  const {
    className = '',
    width,
    height,
    loop = true,
    play = true,
    segments = null,
    forceSegments = true,
    animationData,

    onComplete = () => {},
    onLoopComplete = () => {},
    onEnterFrame = () => {},
    onSegmentStart = () => {},
    // onLoad = () => {},

    onClick = () => {},
    onPointerDown = () => {},
    onPointerEnter = () => {},
    onPointerLeave = () => {},
    onPointerMove = () => {},
    onPointerOut = () => {},
    onPointerOver = () => {},
    onPointerUp = () => {},
  } = props

  const element = useRef(null)
  const lottieInstance = useRef()
  const wasPlayingSegmentsRef = React.useRef(false)

  const mouseEvent = {
    onClick,
    onPointerDown,
    onPointerEnter,
    onPointerLeave,
    onPointerMove,
    onPointerOut,
    onPointerOver,
    onPointerUp,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [isFirstRunForce, setIsFirstRunForce] = useState(true)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // load
  useEffect(() => {
    tryCatch(() => {
      lottieInstance.current = lottie.loadAnimation({
        container: element.current,
        animationData: R.clone(animationData),
        loop: false,
        autoplay: false,
      })
    })

    return () => {
      lottieInstance.current?.destroy()
      lottieInstance.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData])

  // 更新 play, segments, forceSegments, loop
  useEffect(() => {
    tryCatch(() => {
      lottieInstance.current.loop = loop

      if (play) {
        if (segments) {
          if (isFirstRunForce) {
            lottieInstance.current.playSegments(segments, true)
            setIsFirstRunForce(false)
          }

          if (!isFirstRunForce)
            lottieInstance.current.playSegments(segments, forceSegments)

          wasPlayingSegmentsRef.current = true
        }

        if (!segments) {
          if (wasPlayingSegmentsRef.current)
            lottieInstance.current?.resetSegments(forceSegments)

          wasPlayingSegmentsRef.current = false
        }
      }

      if (!play) return lottieInstance.current?.pause()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play, segments, forceSegments, loop, animationData])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    tryCatch(() => {
      lottieInstance.current?.addEventListener('complete', onComplete)
      lottieInstance.current?.addEventListener('loopComplete', onLoopComplete)
      lottieInstance.current?.addEventListener('enterFrame', onEnterFrame)
      lottieInstance.current?.addEventListener('segmentStart', onSegmentStart)
      return () => {
        lottieInstance.current?.removeEventListener('complete', onComplete)
        lottieInstance.current?.removeEventListener(
          'loopComplete',
          onLoopComplete
        )
        lottieInstance.current?.removeEventListener('enterFrame', onEnterFrame)
        lottieInstance.current?.removeEventListener(
          'segmentStart',
          onSegmentStart
        )
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div
      className={className}
      style={{ width: width || '100%', height: height || 'auto' }}
      ref={element}
      {...mouseEvent}
    />
  )
}

export default Lottie

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Lottie.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  loop: PropTypes.bool,
  play: PropTypes.bool,
  segments: PropTypes.array,
  forceSegments: PropTypes.bool,
  animationData: PropTypes.any,

  onComplete: PropTypes.func,
  onLoopComplete: PropTypes.func,
  onEnterFrame: PropTypes.func,
  onSegmentStart: PropTypes.func,
  onLoad: PropTypes.func,

  onClick: PropTypes.func,
  onPointerDown: PropTypes.func,
  onPointerEnter: PropTypes.func,
  onPointerLeave: PropTypes.func,
  onPointerMove: PropTypes.func,
  onPointerOut: PropTypes.func,
  onPointerOver: PropTypes.func,
  onPointerUp: PropTypes.func,
}
