import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import BeamSteeringModuleForBBox from 'src/components/composite/beamformers/BeamSteeringModule/ForBBox'

import InstructionMask from 'src/components/composite/beamformers/BeamSteeringModule/ForBBox/InstructionMask'

import { setShowManual3DInstructionMask } from 'src/redux/slices/uiControl/beamformers/bbox'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { bbox_steering_3DCameraChange_watcher } from 'src/redux/actions/beamformers/bboxAction'

import {
  convertDataOfBSConfigToBSComponent,
  convertDataOfBSComponentToBSConfig,
} from 'src/funcs/device/bbox'
import useGetUIControl from 'src/hooks/useGetUIControl'

const BeamSteering = ({ sn, rfMode, beam_config, handleSteeringChange }) => {
  const dispatch = useDispatch()

  const steeringData = convertDataOfBSConfigToBSComponent({
    configData: beam_config,
  })

  const [deviceDirection, setBeam3DDirection] = useState('left')
  const { current } = useGetCurrentDeviceData(sn)
  const currentDeviceData = current.data
  const deviceType = current.data.info.deviceType

  const { common } = current.data.deviceControl
  const r_currentAntenna = common.currentAntenna
  const r_lstAntennaData = common.lstAntennaData
  const thetaMax = r_lstAntennaData.filter(e => e.name === r_currentAntenna)[0]
    .thetaMax

  //* instruction mask 跟 device control 吃同一套邏輯（只有一點點差異 - 這邊不判斷天線）
  //* 這層不用判斷是否有天線,沒天線基本上不會進到這層，前面有其他行為會擋掉
  //* 而且最後 save 下去如果沒選天線而 config 卻有 steering 值會自動報錯，我們到時候跳 modal 就好
  const { all: all_uiControl, single: s_uiControl } = useGetUIControl(sn)

  const { beamGainMin, beamGainMax } =
    currentDeviceData.deviceControl.steering[rfMode]

  const { instruction: dontShowInstructionMask } =
    all_uiControl.dontShowAgainForSteeringMask
  const {
    beam3DCameraArgs,
    showFirst3DInstructionMask,
    showManual3DInstructionMask,
  } = s_uiControl.deviceControl

  const showManualInstructionMask = showManual3DInstructionMask
  const showFirstInstructionMask =
    !dontShowInstructionMask && showFirst3DInstructionMask
  const showInstructionMask =
    showManualInstructionMask || showFirstInstructionMask

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleUpdateConfigData = componentData =>
    handleSteeringChange(convertDataOfBSComponentToBSConfig({ componentData }))

  const handleDrag = ({ resultTheta, resultPhi }) =>
    handleUpdateConfigData({
      ...steeringData,
      theta: resultTheta,
      phi: resultPhi,
    })

  const handleDragEnd = () => {} // 不需接
  const handleCameraChange = ({ position, rotation }) =>
    dispatch(
      bbox_steering_3DCameraChange_watcher({
        sn,
        position,
        rotation,
      })
    )

  const handleDirectionChange = value => setBeam3DDirection(value)
  const handleHelpButtonClick = () =>
    dispatch(setShowManual3DInstructionMask({ sn, value: true }))
  const handleBoresightClick = () =>
    handleUpdateConfigData({ ...steeringData, theta: 0, phi: 0 })

  const handleInputChange = ({ label, value, isValid }) => {
    if (label === 'theta')
      handleUpdateConfigData({ ...steeringData, theta: value })
    if (label === 'phi') handleUpdateConfigData({ ...steeringData, phi: value })
    if (label === 'gain')
      handleUpdateConfigData({
        ...steeringData,
        beamCurrentGain: value,
      })
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='relative'>
      <BeamSteeringModuleForBBox
        {...{
          sn,
          deviceType,
          ...steeringData,
          thetaMax,
          beamGainMin,
          beamGainMax,

          handleDrag,
          handleDragEnd,
          beam3DCameraArgs,
          handleCameraChange,

          deviceDirection,
          handleDirectionChange,
          handleHelpButtonClick,
          handleBoresightClick,

          handleInputChange,
        }}
      />

      {showInstructionMask && (
        <InstructionMask
          {...{
            sn,
            showFirstInstructionMask,
            showManual3DInstructionMask,
          }}
        />
      )}
    </div>
  )
}

BeamSteering.propTypes = {}

export default BeamSteering
