import React from 'react'

import CommonSwitch from 'src/components/Switch/CommonSwitch'

const AllPower = props => {
  const boardData = props?.boardData
  const { handleAllPowerClick } = props

  const isAllPowerOn = boardData
    ?.map(bItem => bItem.lstChannelData.map(cItem => cItem.power))
    .flat()
    .includes(true)

  return (
    <div className={container}>
      <div className={main}>
        <h6 className={title}>All CH Control</h6>
        <CommonSwitch
          type='text'
          onText='ON'
          offText='OFF'
          on={isAllPowerOn}
          loading={false}
          onClick={() => handleAllPowerClick(isAllPowerOn ? false : true)}
        />
      </div>
    </div>
  )
}

export default AllPower

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static  Func    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[Components-composite-beamformers-ChannelModule-AllPower-container] p-3`

const main = `[Components-composite-beamformers-ChannelModule-AllPower-main] 
                flex 
                gap-x-5 
                items-center 
                pb-3 pl-[14px] 
                border-solid border-b-1 border-dark-5
                `

const title = `[Components-composite-beamformers-ChannelModule-AllPower-title] text-white text-sm font-medium leading-4`
