import React from 'react'

import VerticalInputGroup from 'src/components/composite/beamformers/VerticalInputGroup'

import {
  gainIcon,
  gainStep,
  gainUnit,
  gainDecimalScale,
  phaseIcon,
  phaseUnit,
  phaseMin,
  phaseMax,
  phaseStep,
  phaseDecimalScale,
  getCHGainWarningText,
  getThetaPhiPhaseWarningText,
} from 'src/constants/beamformers'

const GainPhase = props => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // mapItem
  const { boardIndex, channelIndex, channelTitleIndex, isChannelDisabled } =
    props

  // boardItem
  const {
    totalGainMin,
    totalGainMax,
    commonCurrentGain,
    commonGainMin,
    commonGainMax,
    // totalPhaseMin,
    // totalPhaseMax,
    commonCurrentPhase,
    commonPhaseMin,
    // commonPhaseMax,
  } = props

  // chItem
  const {
    totalGain,
    elementGain,
    totalPhase,
    elementPhase,
    phase, // 沒有 total phase & element phase 吃這個
    power,
    isPowerDisabled,
  } = props

  // event
  const {
    handleSinglePowerClick,
    handleSingleGainChange,
    handleSinglePhaseChange,
  } = props

  const supportCommonPhase = totalPhase !== undefined

  const decidePlacement =
    channelIndex === 0 || channelIndex === 1 ? 'left' : 'right'

  // ---- gain
  // totalGain = elementGain + commonCurrentGain
  // 拿到 elementGainMin & Max
  const elementGainMin = +totalGainMin - +commonGainMin
  const elementGainMax = +totalGainMax - +commonGainMax
  // 用 elements Min & Max 去加 common gain 算出當前 ch 的 min & max
  // 加 common gain 是因為當前 min & max 會隨著 common gain 變動
  const currentGainMin = +commonCurrentGain + +elementGainMin
  const currentGainMax = +commonCurrentGain + +elementGainMax

  const realPhaseMax =
    +commonCurrentPhase + +elementPhase <= 355
      ? 360
      : +commonCurrentPhase + +elementPhase

  // warning text
  const gainWarningText = getCHGainWarningText({
    min: currentGainMin,
    max: currentGainMax,
    step: gainStep,
  })

  const phaseWarningText = getThetaPhiPhaseWarningText({
    min: phaseMin,
    max: phaseMax,
    step: phaseStep,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handlePowerClick = () =>
    handleSinglePowerClick({
      boardIndex,
      channelIndex,
      value: !power,
    })

  const handleTopChange = ({ value, isValid }) =>
    handleSingleGainChange({
      boardIndex,
      channelIndex,
      value,
      isValid,
    })

  const handleBottomChange = ({ value, isValid }) =>
    handleSinglePhaseChange({ boardIndex, channelIndex, value, isValid })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const gainInputProps = {
    // input params
    icon: gainIcon,
    unit: gainUnit,
    step: gainStep,
    off: !power,
    disabled: isChannelDisabled,
    validMin: currentGainMin,
    validMax: currentGainMax,
    keydownMin: currentGainMin,
    keydownMax: currentGainMax,
    value: totalGain,
    decimalScale: gainDecimalScale,
    emptyValue: '',
    // progress bar params
    showProgress: true,
    progressValue: [String(commonCurrentGain), String(elementGain)],
    progressColor: ['white', '#F2994A'],
    progressMin: [commonGainMin, elementGainMin],
    progressMax: [commonGainMax, elementGainMax],
  }

  const phaseInputProps = {
    // input params
    icon: phaseIcon,
    unit: phaseUnit,
    step: phaseStep,
    off: !power,
    disabled: isChannelDisabled,
    loop: true,
    validMin: phaseMin,
    validMax: phaseMax,
    keydownMin: phaseMin,
    keydownMax: phaseMax,
    value: supportCommonPhase ? String(totalPhase) : String(phase),
    decimalScale: phaseDecimalScale,
    emptyValue: '',
    // progress bar params
    showProgress: supportCommonPhase ? true : false,
    progressValue: [String(commonCurrentPhase), String(elementPhase)],
    progressColor: ['white', '#B284FC'],
    progressMin: [commonPhaseMin, commonCurrentPhase],
    progressMax: [commonCurrentPhase, realPhaseMax],
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <VerticalInputGroup
      chTitle={'CH ' + channelTitleIndex}
      isSwitchOn={power}
      isSwitchDisabled={isPowerDisabled}
      topTitle=''
      topInputProps={gainInputProps}
      topWarningText={gainWarningText}
      topRangeText={`Range ${currentGainMin} ~ ${currentGainMax}`}
      bottomTitle=''
      bottomInputProps={phaseInputProps}
      bottomWarningText={phaseWarningText}
      bottomRangeText={`${phaseMin} ~ ${phaseMax}, multiples of 5`}
      dialogPlacement={decidePlacement}
      {...{ handlePowerClick, handleTopChange, handleBottomChange }}
    />
  )
}

export default GainPhase
