// import React from 'react'
// import { useSelector } from 'react-redux'

const LowModal = props => {
  // const { type } = props
}

LowModal.propTypes = {}

export default LowModal
