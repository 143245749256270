import React from 'react'
// import { RotatingLines } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <LightForDeviceStatus
//  className=''
//  sn='' //
// />

const greenList = ['finished', 'no-table']

const LightForDeviceStatus = ({ sn, className }) => {
  const initialization = useSelector(
    state => state.deviceData[sn].info.initialization
  )

  // const isLoading = false
  const isGreen = greenList.includes(initialization)
  const isYellow = initialization === 'unfinished'
  const isRed = initialization === 'failed'

  const lightTheme = () => {
    // isLoading 這個還沒實作
    // if (isLoading)
    //   return ` w-3 h-3  ring-[2px] ring-solid ring-dark-2/80 bg-dark-2/80`

    if (isGreen)
      return ` w-[10px] h-[10px]  ring-[3px] ring-solid ring-dark-2/80 bg-green`

    if (isYellow)
      return `w-[10px] h-[10px] ring-[3px] ring-solid ring-dark-2 bg-yellow`

    if (isRed)
      return ` w-[10px] h-[10px]  ring-[3px] ring-solid ring-dark-2/80 bg-red`
  }

  // const content = isLoading ? (
  //   <RotatingLines
  //     strokeColor='white'
  //     strokeWidth='3'
  //     animationDuration='0.5'
  //     width='12'
  //   />
  // ) : (
  //   <></>
  // )
  const content = <></>

  const lightClass = `
                      ${className} 
                      ${lightTheme()} 
                      flex items-center justify-center 
                      shadow-sm shadow-black/20 
                      rounded-full 
                      shrink-0 
                      select-none 
                      `

  return <div className={lightClass}>{content}</div>
}

LightForDeviceStatus.propTypes = {
  sn: PropTypes.string,
  className: PropTypes.string,
}

export default LightForDeviceStatus
