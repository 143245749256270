import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import CommonInput from 'src/components/Input/CommonInput'
import TextArea from 'src/components/Input/TextArea'
import Checkbox from 'src/components/Button/Checkbox'

import { modalActions } from 'src/redux/slices/modal'
import { facility_personalInformationModal_submit_watcher } from 'src/redux/actions/facility/getQuote'

const PersonalInformation = props => {
  const dispatch = useDispatch()

  const { firstName, lastName, email, comments, privacyPolicy, errorMessage } =
    props

  const submitDisabled = !firstName || !lastName || !email || !comments

  const handleChange = (label, value) =>
    dispatch(modalActions.setModalOfGetQuoteContent({ [label]: value }))

  const handlePrivacyPolicyClick = () =>
    dispatch(
      modalActions.setModalOfGetQuoteContent({ privacyPolicy: !privacyPolicy })
    )

  const handleBackClick = () =>
    dispatch(modalActions.showModalOfGetQuoteChooseDeviceAndPurpose())

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(facility_personalInformationModal_submit_watcher())
  }

  return (
    <Modal>
      <Modal.Title className='mb-6'>Get Quote</Modal.Title>

      {errorMessage && (
        <Modal.FormLabel className='text-red'>{errorMessage}</Modal.FormLabel>
      )}

      <Modal.FormLabel>Your contact information</Modal.FormLabel>

      <form onSubmit={handleSubmit}>
        <div className='max-h-[400px] overflow-y-scroll scrollbar pr-2 translate-x-2'>
          <Modal.FormLabel htmlFor='firstName' className='mt-4'>
            First name *
          </Modal.FormLabel>
          <CommonInput
            id='firstName'
            value={firstName}
            placeholder='First name'
            autoFocus
            onChange={e => handleChange('firstName', e.formattedValue)}
          />

          <Modal.FormLabel htmlFor='lastName' className='mt-4'>
            Last name *
          </Modal.FormLabel>
          <CommonInput
            id='lastName'
            value={lastName}
            placeholder='Last name'
            onChange={e => handleChange('lastName', e.formattedValue)}
          />

          <Modal.FormLabel htmlFor='email' className='mt-4'>
            Email *
          </Modal.FormLabel>
          <CommonInput
            id='email'
            value={email}
            placeholder='Email'
            onChange={e => handleChange('email', e.formattedValue)}
          />

          <Modal.FormLabel htmlFor='comments' className='mt-4'>
            Comments *
          </Modal.FormLabel>
          <TextArea
            className='w-full min-h-[82px]'
            id='comments'
            value={comments}
            placeholder='Leave your comments here'
            onChange={e => handleChange('comments', e.formattedValue)}
          />

          <Modal.Divide />

          <div
            className='flex items-center gap-x-3 cursor-pointer select-none shrink-0'
            onClick={handlePrivacyPolicyClick}>
            <Checkbox
              className='shrink-0'
              status={privacyPolicy ? 'checked' : 'uncheck'}
            />
            <p className='text-light-1'>
              Keep me updated on the latest products, resources, and events with
              personalized email updates.
            </p>
          </div>

          <p className='text-white/50 text-sm leading-4 mt-4'>
            By submitting, you are giving us your consent to share your personal
            data provided with our sales partners. Please read TMYTEK's{' '}
            <a
              href='https://www.tmytek.com/legal/privacy'
              target='_blank'
              rel='noreferrer'
              className='underline underline-offset-1 text-white'
              onClick={e => e.stopPropagation()}>
              Privacy Policy
            </a>{' '}
            for more information on how your personal data will be used and the
            choices you have.
          </p>
        </div>

        <Modal.RowButtonBox>
          <CommonButton size='lg' type='outlined' onClick={handleBackClick}>
            Back
          </CommonButton>
          <CommonButton
            size='lg'
            type='primary'
            buttonType='submit'
            disabled={submitDisabled}>
            Get Quote
          </CommonButton>
        </Modal.RowButtonBox>
      </form>
    </Modal>
  )
}

export default PersonalInformation
