import { all, take, fork } from 'redux-saga/effects'

// action
import {
  ud_general_initDevice_watcher,
  ud_general_freqUnlockClick_watcher,
  ud_general_freqUnitChange_watcher,
  ud_general_freqRFChange_watcher,
  ud_general_freqIFChange_watcher,
  ud_general_freqLOSelect_watcher,
  ud_general_freqBandwidthSelect_watcher,
  ud_general_freqBandwidthInputChange_watcher,
  ud_general_freqApplyClick_watcher,
  ud_general_freqClearClick_watcher,
  ud_general_updateCurrentClick_watcher,
} from 'src/redux/actions/freqConverter/generalAction'

// handlers
import { init as handleInit } from 'src/redux/sagas/handlers/event/freqConverter/general/init'
import { updateCurrentClick as handleUpdateCurrentClick } from 'src/redux/sagas/handlers/event/freqConverter/general/current'
import {
  unlockClick as handleUnlockClick,
  unitChange as handleUnitChange,
  RFChange as handleRFChange,
  IFChange as handleIFChange,
  LOSelect as handleLOSelect,
  bandwidthSelectChange as handleBandwidthSelectChange,
  bandwidthInputChange as handleBandwidthInputChange,
  applyClick as handleApplyClick,
  clearClick as handleClearClick,
} from 'src/redux/sagas/handlers/event/freqConverter/general/freq'

import { devLog } from 'src/funcs/tools'

function* initDevice() {
  while (true) {
    const action = yield take(ud_general_initDevice_watcher.type)

    // const { sn } = action.payload
    devLog('[watcher] initDevice(freqConverter)', action.payload)
    yield fork(handleInit, action.payload)
  }
}
function* updateCurrentClick() {
  while (true) {
    const action = yield take(ud_general_updateCurrentClick_watcher.type)

    // const { sn } = action.payload
    devLog('[watcher] updateCurrentClick', action.payload)
    yield fork(handleUpdateCurrentClick, action.payload)
  }
}
function* freqUnlockClick() {
  while (true) {
    const action = yield take(ud_general_freqUnlockClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqUnlockClick', action.payload)
    yield fork(handleUnlockClick, action.payload)
  }
}
function* freqUnitChange() {
  while (true) {
    const action = yield take(ud_general_freqUnitChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqUnitChange', action.payload)
    yield fork(handleUnitChange, action.payload)
  }
}
function* freqRFChange() {
  while (true) {
    const action = yield take(ud_general_freqRFChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqRFChange', action.payload)
    yield fork(handleRFChange, action.payload)
  }
}
function* freqIFChange() {
  while (true) {
    const action = yield take(ud_general_freqIFChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqIFChange', action.payload)
    yield fork(handleIFChange, action.payload)
  }
}
function* freqLOSelect() {
  while (true) {
    const action = yield take(ud_general_freqLOSelect_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqLOSelect', action.payload)
    yield fork(handleLOSelect, action.payload)
  }
}

function* freqBandwidthSelectChange() {
  while (true) {
    const action = yield take(ud_general_freqBandwidthSelect_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqBandwidthSelectChange', action.payload)
    yield fork(handleBandwidthSelectChange, action.payload)
  }
}
function* freqBandwidthInputChange() {
  while (true) {
    const action = yield take(ud_general_freqBandwidthInputChange_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqBandwidthInputChange', action.payload)
    yield fork(handleBandwidthInputChange, action.payload)
  }
}
function* freqApplyClick() {
  while (true) {
    const action = yield take(ud_general_freqApplyClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqApplyClick', action.payload)
    yield fork(handleApplyClick, action.payload)
  }
}
function* freqClearClick() {
  while (true) {
    const action = yield take(ud_general_freqClearClick_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] freqClearClick', action.payload)
    yield fork(handleClearClick, action.payload)
  }
}

export default function* freqConverterWatchers() {
  yield all([
    initDevice(),

    updateCurrentClick(),

    freqUnlockClick(),

    freqUnitChange(),
    freqRFChange(),
    freqIFChange(),
    freqLOSelect(),
    freqBandwidthSelectChange(),
    freqBandwidthInputChange(),
    freqApplyClick(),
    freqClearClick(),
  ])
}
