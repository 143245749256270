import { createAction } from '@reduxjs/toolkit'

export const member_updateInfo_watcher = createAction(
  'facility/member_updateInfo_watcher'
)
export const member_startSignInSignUpFlow_watcher = createAction(
  'facility/member_startSignInSignUpFlow_watcher'
)
export const member_signOut_watcher = createAction(
  'facility/member_signOut_watcher'
)

// modal - main
export const member_modalClose_watcher = createAction(
  'modalEvent/member_modalClose_watcher'
)
export const member_signInSignUpModal_signInClick_watcher = createAction(
  'modalEvent/member_signInSignUpModal_signInClick_watcher'
)
export const member_signInSignUpModal_createAccountClick_watcher = createAction(
  'modalEvent/member_signInSignUpModal_createAccountClick_watcher'
)
export const member_signInSignUpModal_forgotPasswordClick_watcher =
  createAction(
    'modalEvent/member_signInSignUpModal_forgotPasswordClick_watcher'
  )

// modal - sign up
export const member_signUpVerificationModal_submit_watcher = createAction(
  'modalEvent/member_signUpVerificationModal_submit_watcher'
)
export const member_signUpVerificationModal_resend_watcher = createAction(
  'modalEvent/member_signUpVerificationModal_resend_watcher'
)
export const member_signUpVerificationSuccessModal_okClick_watcher =
  createAction(
    'modalEvent/member_signUpVerificationSuccessModal_okClick_watcher'
  )

// modal - forgot password
export const member_forgotPasswordSendMailModal_submit_watcher = createAction(
  'modalEvent/member_forgotPasswordSendMailModal_submit_watcher'
)
export const member_forgotPasswordSendMailModal_backToSignIn_watcher =
  createAction(
    'modalEvent/member_forgotPasswordSendMailModal_backToSignIn_watcher'
  )
export const member_forgotPasswordResetModal_submit_watcher = createAction(
  'modalEvent/member_forgotPasswordResetModal_submit_watcher'
)
export const member_forgotPasswordResetModal_resendCode_watcher = createAction(
  'modalEvent/member_forgotPasswordResetModal_resendCode_watcher'
)
