import BeamItem from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList/BeamItem'
import ChannelItem from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList/ChannelItem'
import CreateItem from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList/CreateItem'

import Tooltip from 'src/components/Popup/Tooltip'
import IconButton from 'src/components/Button/IconButton'
import Checkbox from 'src/components/Button/Checkbox'

import { BiPencil, BiCopy } from 'react-icons/bi'
import { AiOutlineClear } from 'react-icons/ai'

import DragCircles from 'src/assets/svg/sundry/DragCircles'

import useGetScreenSize from 'src/hooks/useGetScreenSize'

const getRenderTrItem =
  ({
    rfMode,
    isSelectMode,
    selectLists,
    fillDefaultTableData,
    sort,
    currentAntenna,
    controlMethod,
    isUserDefinedFulled,
    handleCreateClick,
    handleEditClick,
    handleCopyClick,
    handleClearClick,
    handleSteeringChange,
    handleSelectChange,
  }) =>
  (provided, snapshot, rubric) => {
    // provided: {
    //   innerRef: 套件的機制所需, 直接去取用 dom 的 ref, 就是套用的例行公事,
    //   draggableProps: 套件的機制所需, 掛在可拖動元素裡面,
    //   dragHandleProps: 放在點擊後「會觸發拖動事件」的元素裡面,
    // }

    // snapshot: {
    //   isDragging: 可以取得當前是否正在被抓取拖曳中,
    // }

    // rubric: {
    //   source: {
    //      index: 當前 index (包含抓取中即時更新的情況),
    //    }
    // }

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------       Props       ------------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    const { isMD } = useGetScreenSize()

    const index =
      sort === 'asc'
        ? rubric.source.index
        : fillDefaultTableData.length - 1 - rubric.source.index
    const data = fillDefaultTableData[index]

    const selectList = selectLists[rfMode]
    const selectIsChecked = selectList.includes(data.beamID)

    const isBeamConfigWithNoAntenna = data.beam_type === 0 && !currentAntenna

    const editButtonDisabled = isBeamConfigWithNoAntenna
    const copyButtonDisabled = isUserDefinedFulled || isBeamConfigWithNoAntenna

    // th
    const isRenderThEmpty =
      controlMethod === 'TLK' && isSelectMode && data.beam_type === undefined

    const isRenderThBeamIDOnly = controlMethod === 'SPI'

    const isRenderThDraggable = controlMethod === 'TLK' && !isSelectMode

    const isRenderThCheckbox =
      controlMethod === 'TLK' && isSelectMode && data.beam_type !== undefined

    // td
    const isRenderCreateItem =
      controlMethod === 'TLK' && data.beam_type === undefined && !isSelectMode

    const isRenderBeamOrChannelItem = data.beam_type !== undefined

    const isTlkRenderEmptyTd =
      controlMethod === 'TLK' &&
      !isRenderCreateItem &&
      !isRenderBeamOrChannelItem
    const isSpiRenderEmptyTd =
      controlMethod === 'SPI' && data.beam_type === undefined
    const isRenderEmptyTd = isSpiRenderEmptyTd || isTlkRenderEmptyTd

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------       Event        -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    const handleSelectClick = () => {
      if (isRenderThCheckbox) {
        if (!selectIsChecked)
          handleSelectChange({
            newSelectLists: {
              ...selectLists,
              [rfMode]: [...selectLists[rfMode], data.beamID],
            },
          })

        if (selectIsChecked)
          handleSelectChange({
            newSelectLists: {
              ...selectLists,
              [rfMode]: selectLists[rfMode].filter(e => e !== data.beamID),
            },
          })
      }
    }

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------        JSX         -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //! 這裡的 table 全部使用 <div> 的原因是，如果用 <table> + <tr> 會跳下面的錯誤
    //! Warning: validateDOMNesting(...): <tr> cannot appear as a child of <body>.,
    //! 主因是我讓它在拖移時的狀態掛上了 portal 因此會處於 tr 掛載 body 裡面的臨時狀態
    //! 只用全 <div> 雖然語意不是很好，但至少不會跳警告
    //! 而如果不掛 portal 再拖拉時的位置會是錯的，因此最後使用 <div>
    //* Reparenting a <Draggable />
    //* https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
    return (
      <div
        className={tr(snapshot.isDragging, selectIsChecked, isSelectMode)}
        ref={provided.innerRef}
        onClick={handleSelectClick}
        {...provided.draggableProps}>
        {/* ------ th ------ */}
        {/* ------ th ------ */}
        {/* ------ th ------ */}
        <div className={th} {...provided.dragHandleProps}>
          {isRenderThEmpty && <></>}

          {isRenderThBeamIDOnly && data.beamID}

          {isRenderThCheckbox && (
            <Checkbox
              disabled={false}
              status={selectIsChecked ? 'checked' : 'uncheck'} // oneOf(['checked', 'uncheck', 'indeterminate'])
            />
          )}

          {isRenderThDraggable && (
            <>
              <DragCircles className={thDragCircles(snapshot.isDragging)} />
              {data.beamID}
            </>
          )}
        </div>

        {/* ------ td ------ */}
        {/* ------ td ------ */}
        {/* ------ td ------ */}
        {isRenderEmptyTd && <div className={td} />}

        {isRenderCreateItem && (
          <div className={td + ' group '}>
            <div className='w-full pr-10 opacity-0 group-hover:opacity-100 duration-500'>
              <CreateItem
                beamID={data.beamID}
                handleCreateClick={handleCreateClick}
              />
            </div>
          </div>
        )}

        {isRenderBeamOrChannelItem && (
          <div className={td}>
            <div className='w-[409px] flex flex-col gap-y-1 pl-3 py-2'>
              <h6 className='text-sm leading-[18px]'>
                {data.beam_description}
              </h6>

              {data.beam_type === 0 && (
                <BeamItem
                  disabled={
                    controlMethod === 'SPI' || isSelectMode || !currentAntenna
                  }
                  beamID={data.beamID}
                  beam_config={data.beam_config}
                  rfMode={rfMode}
                  handleSteeringChange={handleSteeringChange}
                />
              )}

              {data.beam_type === 1 && (
                <ChannelItem channel_config={data.channel_config} />
              )}
            </div>

            <div className='w-[123px] flex gap-x-0 lg:gap-x-4 ml-4'>
              {!isSelectMode && controlMethod === 'TLK' && (
                <>
                  <Tooltip title='Edit'>
                    <IconButton
                      type='standard'
                      size={isMD ? 'lg' : 'sm'}
                      disabled={editButtonDisabled}
                      onClick={() => handleEditClick({ beamID: data.beamID })}>
                      <BiPencil />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Duplicate'>
                    <IconButton
                      type='standard'
                      size={isMD ? 'lg' : 'sm'}
                      disabled={copyButtonDisabled}
                      onClick={() => handleCopyClick({ beamID: data.beamID })}>
                      <BiCopy />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Clear'>
                    <IconButton
                      type='standard'
                      size={isMD ? 'lg' : 'sm'}
                      onClick={() => handleClearClick({ beamID: data.beamID })}>
                      <AiOutlineClear />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

export default getRenderTrItem

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const trBg = (isDragging, selectIsChecked) => {
  if (isDragging) return `bg-dark-5`
  if (selectIsChecked) return `bg-white/10`

  return `bg-transparent hover:bg-black/30`
}
const trCursor = isSelectMode =>
  isSelectMode ? `cursor-pointer` : `cursor-auto`
const tr = (isDragging, selectIsChecked, isSelectMode) =>
  `${trBg(isDragging, selectIsChecked)} 
  ${trCursor(isSelectMode)} 
  group 
  flex items-center
  border-y-[0.5px] border-solid border-dark-5 
  text-light-1 text-sm font-normal leading-5 
  `

const td = `w-full min-h-[72px] flex items-center border-l-1 border-solid border-white/10 `

const th = `relative
            w-[40px] h-[72px] 
            shrink-0 
            flex items-center justify-center
            text-sm 
            select-none
`
const thDragCirclesBg = isDragging =>
  isDragging ? `fill-light-4` : `fill-transparent group-hover:fill-dark-5`
const thDragCircles = isDragging =>
  `${thDragCirclesBg(isDragging)} 
  absolute 
  left-[2px] inset-y-0 
  my-auto 
  `
