import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Pagination, Autoplay } from 'swiper'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//  <Carousel
//      className = 'w-[320px] h-full',
//      onSlideChange = () => console.log('slide change'),
//      onSwiper = swiper => console.log(swiper)
//      delay = 2000
//      >
//   <Carousel.Item>
//       <img src={image1} alt='' />
//   </Carousel.Item>
//   <Carousel.Item>
//       <img src={image5} alt='' />
//   </Carousel.Item>
// </Carousel >

const Carousel = props => {
  const {
    className = 'w-[320px] h-full',
    onSlideChange = () => {},
    onSwiper = () => {},
    delay = 3000,
  } = props

  return (
    <Swiper
      spaceBetween={0}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay,
        disableOnInteraction: false,
      }}
      modules={[Pagination, Autoplay]}
      className={className}
      onSlideChange={onSlideChange}
      onSwiper={onSwiper}>
      {props.children}
    </Swiper>
  )
}

Carousel.Item = SwiperSlide

export default Carousel
