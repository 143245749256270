import React from 'react'
import CommonButton from 'src/components/Button/CommonButton'

import noDeviceFound from 'src/assets/img/no_device_found.png'

const PageNotFound = props => {
  return (
    <div className='sectionHeight w-full flex flex-col justify-center items-center px-4'>
      <img src={noDeviceFound} alt='tmytek page not found' className='mb-8' />
      <h2 className='text-5xl font-bold text-light-1 mb-3'>Page not found</h2>
      <h6 className='text-lg font-normal text-light-4 text-center'>
        The page you are looking for doesn't exist or the page has been removed.
      </h6>
      <CommonButton href='/' type='general' size='lg' className='mt-8'>
        Back to Home
      </CommonButton>
    </div>
  )
}

PageNotFound.propTypes = {}

export default PageNotFound
