import React, { useRef } from 'react'
import { useThree } from '@react-three/fiber'
import { Box, Torus, Cone, Text, Line } from '@react-three/drei'

import { degreeToRadian } from 'src/components/composite/beamformers/Beam3DControl/tools'

import {
  red,
  green,
  blue,
} from 'src/components/composite/beamformers/Beam3DControl/color'

const lineLength = 35

const Coordinates = ({ deviceDirection }) => {
  const { camera } = useThree()

  const zTextRef = useRef()
  const yTextRef = useRef()
  const xTextRef = useRef()

  const textRotation =
    deviceDirection === 'top'
      ? [camera.rotation._x, camera.rotation._y, camera.rotation._z]
      : [degreeToRadian(-90), camera.rotation._z + degreeToRadian(-120), 0]

  return (
    <>
      <Label {...{ zTextRef, yTextRef, xTextRef, textRotation }} />
      <ThetaSuggest textRotation={textRotation} />
      <PhiSuggest textRotation={textRotation} />

      <Axis />
    </>
  )
}

// will re-render
const Label = ({ zTextRef, yTextRef, xTextRef, textRotation }) => {
  const textBasePosition = lineLength / 2 + 1

  return (
    <>
      {/* z */}
      <Text
        ref={zTextRef}
        rotation={textRotation}
        position={[0, textBasePosition, 0]}
        color={blue}
        {...textCommonProps}>
        Z
      </Text>

      {/* Y */}
      <Text
        ref={yTextRef}
        rotation={textRotation}
        position={[0, 0, -textBasePosition]}
        color={green}
        {...textCommonProps}>
        Y
      </Text>

      {/* X*/}
      <Text
        ref={xTextRef}
        rotation={textRotation}
        position={[textBasePosition, 0, 0]}
        color={red}
        {...textCommonProps}>
        X
      </Text>
    </>
  )
}
const ThetaSuggest = ({ textRotation }) => {
  return (
    <>
      <Torus
        args={[11.5, 0.05, 10, 50, 0.5]}
        rotation={[0, degreeToRadian(180), degreeToRadian(90)]}>
        <meshMatcapMaterial color='white' />
      </Torus>
      <Cone
        args={[0.2, 0.3, 64, 15, false, 0, 6.3]}
        position={[5.9, 9.9, 0]}
        rotation={[0, 0, degreeToRadian(-120)]}>
        <meshMatcapMaterial color='white' />
      </Cone>
      <Text
        rotation={textRotation}
        position={[6.8, 9.7, 0]}
        color='white'
        {...textCommonProps}>
        Theta
      </Text>
    </>
  )
}
const PhiSuggest = ({ textRotation }) => {
  return (
    <>
      <Torus
        args={[11.5, 0.05, 10, 50, 0.5]}
        rotation={[degreeToRadian(-90), 0, degreeToRadian(0)]}>
        <meshMatcapMaterial color='white' />
      </Torus>
      <Cone
        args={[0.2, 0.3, 64, 15, false, 0, 6.3]}
        position={[9.9, 0, -5.9]}
        rotation={[0, degreeToRadian(120), degreeToRadian(-90)]}>
        <meshMatcapMaterial color='white' />
      </Cone>
      <Text rotation={textRotation} position={[9.5, 0, -6.5]} color='white'>
        Phi
      </Text>
    </>
  )
}

// no re-render
const Axis = React.memo(() => {
  return (
    <>
      {/* z */}
      <AxisLine color={blue} args={[0.1, lineLength, 0.1]} />
      <Arrow type='z' color={blue} />
      {/* Y */}
      <AxisLine color={green} args={[0.1, 0.1, lineLength]} />
      <Arrow type='y' color={green} />
      {/* X*/}
      <AxisLine color={red} args={[lineLength, 0.1, 0.1]} />
      <Arrow type='x' color={red} />
    </>
  )
})
const AxisLine = props => {
  return (
    <Box args={props.args}>
      <meshBasicMaterial color={props.color} transparent opacity={0.3} />
    </Box>
  )
}

const Arrow = ({ type, color }) => {
  const arrowBasePosition = lineLength / 2
  const arrowOffset = 0.3

  const points = {
    z: [
      [-0.3, arrowBasePosition - arrowOffset, 0],
      [0, arrowBasePosition, 0],
      [0.3, arrowBasePosition - arrowOffset, 0],
    ],
    x: [
      [arrowBasePosition - arrowOffset, 0, -0.3],
      [arrowBasePosition, 0, 0],
      [arrowBasePosition - arrowOffset, 0, 0.3],
    ],
    y: [
      [-0.3, 0, -arrowBasePosition + arrowOffset],
      [0, 0, -arrowBasePosition],
      [0.3, 0, -arrowBasePosition + arrowOffset],
    ],
  }
  return (
    <Line
      points={points[type]}
      color={color}
      transparent
      opacity={0.3}
      lineWidth='2'
    />
  )
}

export default Coordinates

const textCommonProps = {
  scale: [1.1, 1.1, 1.1],
  anchorX: 'center',
  anchorY: 'middle',
}
