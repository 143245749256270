import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiInfoCircle } from 'react-icons/bi'

import {
  home_applyCheckModal_NoClick_watcher,
  home_applyCheckModal_yesClick_watcher,
} from 'src/redux/actions/facility/home'

const ApplyDeviceInfoCheck = props => {
  const dispatch = useDispatch()

  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white' />
      </Modal.Icon>

      <Modal.Title>
        The device will automatically reboot to activate the new settings
      </Modal.Title>

      <Modal.Description>
        If the settings are not correct, changing the IP may lose network
        connectivity.
        <br />
        Are you sure you want to continue?
      </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='general'
          onClick={() => dispatch(home_applyCheckModal_NoClick_watcher())}>
          No
        </CommonButton>
        <CommonButton
          size='lg'
          type='primary'
          onClick={() => dispatch(home_applyCheckModal_yesClick_watcher())}>
          Yes
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ApplyDeviceInfoCheck
