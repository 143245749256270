import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <SwitchGroup
//  className='' // string
//  size='lg' // sm , md , lg
//  value={} // string || number
//  icon={} // jsx
//  text1={} // node
//  text2={} // node
//  unit='' // node
//  onClick={() => {}} // func
// />

const SwitchGroup = props => {
  const {
    size = 'md',
    value,

    icon,
    off = false,
    disabled = false,

    text1 = '',
    text2 = '',
    unit = '',

    className = '',

    onClick = () => {},
  } = props
  const inputRef = useRef()

  const isEnabled = !off && !disabled

  const [isFocus, setIsFocus] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = () => {
    inputRef?.current?.focus()
    isEnabled && onClick()
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                    ${className} 
                    ${containerSize[size]}
                    ${containerPadding[size]}
                    ${containerBg(isEnabled)} 
                    ${containerBorder(isEnabled, isFocus)} 
                    ${cursor(!isEnabled)}
                    border-solid border-1 
                    flex items-center gap-x-2
                    select-none 
                    px-3
                    rounded-md 
                    duration-200 
                    `

  const iconClass = `
                    ${iconSize[size] || 'w-4 h-4'} 
                    flex items-center justify-center shrink-0 fill-white 
                    `

  const contentBoxClass = `w-full h-full flex justify-around items-center `

  // -mb-[1px] 是為了讓字視覺上不要偏上
  const contentClass = active => `
                                ${contentColor(active)} 
                                ${contentSize[size]} 
                                duration-500 -mb-[1px]
                                `

  const dividerClass = `h-full w-[1px] bg-dark-4 mx-[6px]`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass} onClick={handleClick}>
      {icon && <div className={iconClass}>{icon}</div>}

      <div className={contentBoxClass}>
        <div className={contentClass(value === text1)}>{text1 + unit}</div>
        <div className={dividerClass} />
        <div className={contentClass(value === text2)}>{text2 + unit}</div>
      </div>

      <input
        ref={inputRef}
        className={hiddenInput}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onKeyDown={e => e.key === 'Enter' && handleClick()}
      />
    </div>
  )
}
export default SwitchGroup

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerSize = {
  sm: 'h-6 ',
  md: 'h-8 ',
  lg: 'h-14 ',
}
const containerPadding = {
  sm: 'px-2 py-1 ',
  md: 'p-2 ',
  lg: 'px-2 py-[14px] ',
}

const containerBg = isEnabled => (isEnabled ? 'bg-dark-1 ' : 'bg-dark-4 ')
const containerBorder = (isEnabled, isFocus) => {
  if (isEnabled) {
    if (isFocus) return `border-teal-5`
    else return `border-dark-3 hover:border-dark-5 `
  }
  return `border-transparent`
}

const iconSize = { sm: 'w-[14px] h-[14px]', md: 'w-4 h-4', lg: 'w-5 h-5' }

const contentColor = active => (active ? 'text-white' : 'text-white/20')

const contentSize = {
  sm: 'text-sm leading-4',
  md: 'text-lg leading-5',
  lg: 'text-2xl leading-7',
}

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

const hiddenInput = `absolute w-0 h-0 -z-1`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
SwitchGroup.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  icon: PropTypes.node,
  off: PropTypes.bool,
  disabled: PropTypes.bool,

  text1: PropTypes.node,
  text2: PropTypes.node,
  unit: PropTypes.node,

  className: PropTypes.string,

  onClick: PropTypes.func,
}
