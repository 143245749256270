import { call, put, select } from 'redux-saga/effects'
import * as R from 'ramda'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { setConfigEditorIsChanged } from 'src/redux/slices/uiControl/beamformers/bbox'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       RfMode       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* commonRfModeSwitch(response) {
  try {
    const [sn, data] = Object.entries(response?.data)[0]

    let currentData = yield call(getCurrentData, sn)

    // 如果有 return adc 代表是 bboard, 這時候去更新該 trMode 裡面的 adc
    const rfMode = data?.rfMode
    const adc = data?.adc
    if (adc) currentData.deviceControl.step[rfMode].adc.currentValue = adc

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[res-handler] commonRfModeSwitch error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Freq        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* commonFreqSelect(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))

    if (deviceData?.beamConfigEditor)
      yield put(setConfigEditorIsChanged({ sn, value: false }))
  } catch (error) {
    devWarLog('[res-handler] commonFreqSelect error:', error)
  }
}
export function* commonFreqAfterImport(response) {
  try {
    const freqListArray = Object.entries(response?.data).map(
      ([sn, freqList]) => ({ sn, freqList })
    )

    const deviceData = yield select(state => state.deviceData)
    let cloneDeviceData = R.clone(deviceData)

    for (let i = 0; i < freqListArray.length; i++) {
      cloneDeviceData[freqListArray[i].sn].deviceControl.common.lstFreqOptions =
        freqListArray[i].freqList

      yield put(
        setSingleDeviceData({
          sn: freqListArray[i].sn,
          data: cloneDeviceData[freqListArray[i].sn],
        })
      )
    }
  } catch (error) {
    devWarLog('[res-handler] commonFreqAfterImport error:', error)
  }
}
