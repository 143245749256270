import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'

import {
  pd_customCaliModal_deleteClick_watcher,
  pd_customCaliModal_saveClick_watcher,
} from 'src/redux/actions/powerDetector'

import { footer } from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { isNameInvalid } from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/EditMode/Head'

const Footer = ({ editIsCreate, editSaveIsRequesting }) => {
  const dispatch = useDispatch()

  const editTemporaryData = useSelector(
    state => state.facilityModal.normal.props.editTemporaryData
  )

  const id = Object.keys(editTemporaryData)[0]
  const { name } = Object.values(editTemporaryData)[0]

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const saveIsDisabled = isNameInvalid(name)

  const handleDeleteClick = () =>
    dispatch(
      pd_customCaliModal_deleteClick_watcher({
        sn,
        selectIdList: [id],
        isEditMode: true,
      })
    )
  const handleSaveClick = () => dispatch(pd_customCaliModal_saveClick_watcher())

  if (editIsCreate)
    return (
      <footer className={footer + ' flex justify-end'}>
        <CommonButton
          type='primary'
          size='lg'
          loading={editSaveIsRequesting}
          onClick={handleSaveClick}
          disabled={saveIsDisabled}>
          Save
        </CommonButton>
      </footer>
    )

  return (
    <footer className={footer + ' flex justify-between gap-x-8'}>
      <CommonButton
        type='outlined'
        size='lg'
        danger
        className='px-4'
        onClick={handleDeleteClick}>
        Delete
      </CommonButton>

      <CommonButton
        type='primary'
        size='lg'
        disabled={saveIsDisabled}
        loading={editSaveIsRequesting}
        onClick={handleSaveClick}>
        Save
      </CommonButton>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
