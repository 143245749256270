import {
  deviceError as handleDeviceErrorResponse,
  // deviceErrorAndClearNormalModal as handleDeviceErrorAndClearNormalModal,
} from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import {
  stepAllPower as handleStepAllPowerResponse,
  stepSinglePower as handleStepSinglePowerResponse,
  stepCommonAttenuation as handleStepCommonAttenuationResponse,
  stepSingleAttenuation as handleStepSingleAttenuationResponse,
  stepSinglePhase as handleStepSinglePhaseResponse,
} from 'src/redux/sagas/handlers/response/beamformers/bboard/step'

import {
  readTemperature as handleReadTemperatureResponse,
  setTemperatureCompensation as handleSetTemperatureCompensationResponse,
} from 'src/redux/sagas/handlers/response/beamformers/bboard/temperature'

const bboardApi = {
  STEP_POWER_ALL: {
    emit: {
      server: 'beamformers',
      eventName: 'step/power/all',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'step/power/all/response',
      handler: handleStepAllPowerResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  STEP_POWER: {
    emit: {
      server: 'beamformers',
      eventName: 'step/power',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'step/power/response',
      handler: handleStepSinglePowerResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  STEP_ATTENUATION_COMMON: {
    emit: {
      server: 'beamformers',
      eventName: 'step/attenuation/common',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'step/attenuation/common/response',
      handler: handleStepCommonAttenuationResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  STEP_ATTENUATION: {
    emit: {
      server: 'beamformers',
      eventName: 'step/attenuation',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'step/attenuation/response',
      handler: handleStepSingleAttenuationResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  STEP_PHASE: {
    emit: {
      server: 'beamformers',
      eventName: 'step/phase',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'step/phase/response',
      handler: handleStepSinglePhaseResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  STEP_ADC: {
    emit: {
      server: 'beamformers',
      eventName: 'step/adc',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'step/adc/response',
      handler: handleReadTemperatureResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  STEP_COMPENSATION_CQ_SETUP: {
    emit: {
      server: 'beamformers',
      eventName: 'step/compensation/CQ/setup',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'step/compensation/CQ/setup/response',
      handler: handleSetTemperatureCompensationResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default bboardApi
