const error = {
  showModalOfTimeout: (state, action) => {
    // const { eventName } = action.payload

    state.high = {
      type: 'ERROR',
      props: { status: 'timeout', ...action.payload },
    }
    return state
  },
}
export default error
