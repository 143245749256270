import React from 'react'
import { useDispatch } from 'react-redux'

import { FloatingPortal, FloatingOverlay } from '@floating-ui/react'

import { lottie_loadingAnimateModal_complete_watcher } from 'src/redux/actions/facility/home'

import TMXLABShortAnimation from 'src/assets/json/TMXLABShortAnimation.json'

import LottieWeb from 'src/components/Lottie'

const TMXLABShortAnimationModal = modalProps => {
  const dispatch = useDispatch()
  const { status: modalStatus } = modalProps

  const handleAnimateComplete = () =>
    dispatch(lottie_loadingAnimateModal_complete_watcher())

  const overlayClass = `
    fixed 
    inset-0 
    bg-black/80 
    z-10 
    flex 
    items-center justify-center 
    duration-[5000ms] 
    backdrop-blur-sm 
    ${modalStatus === 'loading' ? 'opacity-100' : 'opacity-0'}
    `

  const lottieClass = `max-w-[400px] `

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={overlayClass}>
        <LottieWeb
          className={lottieClass}
          play={true}
          loop={true}
          animationData={TMXLABShortAnimation}
          segments={modalStatus === 'loading' ? loadingSegment : endSegment}
          forceSegments={false}
          onLoopComplete={handleAnimateComplete}
        />
      </FloatingOverlay>
    </FloatingPortal>
  )
}

TMXLABShortAnimationModal.propTypes = {}

const loadingSegment = [100, 130]
const endSegment = [260, 347]

export default TMXLABShortAnimationModal
