import { IoResizeSharp } from 'react-icons/io5'
import { GrRotateRight, GrRotateLeft } from 'react-icons/gr'
import { MdDoNotDisturb } from 'react-icons/md'

export const polarSynthesisIcon = {
  'Not using': <MdDoNotDisturb />,
  'Forward slash linear': <IoResizeSharp />,
  'Backward slash linear': <IoResizeSharp className='rotate-90' />,
  'Left-hand circular': <GrRotateRight />,
  'Right-hand circular': <GrRotateLeft />,
}
