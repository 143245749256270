import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Q({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='15'
        height='20'
        viewBox='0 0 15 20'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.64286 14.5161L14.182 18.1106L12.0046 20L7.53456 16.4055L9.64286 14.5161ZM14.2972 8.22581V9.02074C14.2972 10.2957 14.1244 11.4401 13.7788 12.4539C13.4409 13.4677 12.9531 14.3318 12.3157 15.0461C11.6782 15.7527 10.9255 16.2942 10.0576 16.6705C9.18971 17.0469 8.22581 17.235 7.1659 17.235C6.11367 17.235 5.14977 17.0469 4.27419 16.6705C3.39862 16.2942 2.64209 15.7527 2.00461 15.0461C1.36713 14.3318 0.871736 13.4677 0.518433 12.4539C0.172811 11.4401 0 10.2957 0 9.02074V8.22581C0 6.94316 0.172811 5.79877 0.518433 4.79263C0.871736 3.7788 1.36329 2.91475 1.99309 2.20046C2.62289 1.48618 3.37558 0.94086 4.25115 0.564516C5.12673 0.188172 6.09063 0 7.14286 0C8.20276 0 9.16667 0.188172 10.0346 0.564516C10.9101 0.94086 11.6667 1.48618 12.3041 2.20046C12.9416 2.91475 13.4332 3.7788 13.7788 4.79263C14.1244 5.79877 14.2972 6.94316 14.2972 8.22581ZM10.8065 9.02074V8.20276C10.8065 7.31183 10.722 6.52842 10.553 5.85253C10.3917 5.17665 10.1536 4.60829 9.83871 4.14747C9.53149 3.68664 9.15131 3.34101 8.69816 3.1106C8.24501 2.8725 7.72657 2.75346 7.14286 2.75346C6.55914 2.75346 6.04071 2.8725 5.58756 3.1106C5.13441 3.34101 4.75422 3.68664 4.447 4.14747C4.13978 4.60829 3.90553 5.17665 3.74424 5.85253C3.58295 6.52842 3.5023 7.31183 3.5023 8.20276V9.02074C3.5023 9.90399 3.58295 10.6874 3.74424 11.371C3.90553 12.0469 4.13978 12.619 4.447 13.0876C4.7619 13.5484 5.14593 13.8978 5.59908 14.1359C6.05991 14.374 6.58218 14.4931 7.1659 14.4931C7.74962 14.4931 8.26421 14.374 8.70968 14.1359C9.16283 13.8978 9.54301 13.5484 9.85023 13.0876C10.1651 12.619 10.4032 12.0469 10.5645 11.371C10.7258 10.6874 10.8065 9.90399 10.8065 9.02074Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
