import { call, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

export function* _vChannelSyncWithH({ sn }) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)

  const currentPolarSynthesis = currentData.deviceControl.common.polarSynthesis

  const currentControlMode = currentData.deviceControl.common.currentControlMode
  const rfMode = currentData.deviceControl.common.rfMode

  if (
    currentControlMode[rfMode] === 'channel' &&
    currentPolarSynthesis !== null
  ) {
    const currentBoardData = R.clone(
      currentData.deviceControl.channel[currentRfMode]
    )

    const mapIndexed = R.addIndex(R.map)

    //* 如果切到有極化，V Channel 的 Phase 照 H Channel 相對應的 Phase 去加 Phase Delay
    //* 而且強制將全部 Power 開啟
    //* 2023/10/20 早上與 Sam Jacky 討論結論
    const getHBoardArgAsSamePath = (key, bI) =>
      R.path(['horizon', bI, key])(currentBoardData)

    const getHChannelArgAsSamePath = (key, bI, cI) =>
      R.path(['horizon', bI, 'channelData', cI, key])(currentBoardData)

    const calcNewVChannelPhase = (bI, cI) =>
      R.pipe(
        R.add(currentPolarSynthesis),
        R.modulo(R.__, 360)
      )(getHChannelArgAsSamePath('phase', bI, cI))

    // V 全部 Channel 的 Phase = H 相對應 Phase + value
    // 開啟 V 全部 Channel 的 Power
    const updateChannelData = mapIndexed((bE, bI) =>
      //--- 這邊是每一個 boardData
      R.modify(
        'channelData', //--- 修改 boardData 的 channelData
        mapIndexed((cE, cI) =>
          R.pipe(
            R.assoc('phase', calcNewVChannelPhase(bI, cI)),
            R.assoc('totalGain', getHChannelArgAsSamePath('totalGain', bI, cI)),
            R.assoc(
              'elementGain',
              getHChannelArgAsSamePath('elementGain', bI, cI)
            ),
            R.assoc('power', true)
          )(cE)
        )
      )(bE)
    )

    const updateCommonGain = mapIndexed((bE, bI) =>
      R.assoc(
        'commonCurrentGain',
        getHBoardArgAsSamePath('commonCurrentGain', bI)
      )(bE)
    )

    const updateVBoardData = R.pipe(updateCommonGain, updateChannelData)

    // 開啟 H 全部 Channel 的 Power
    const updateHBoardData = R.map(
      R.modify('channelData', R.map(R.assoc('power', true)))
    )

    const newCurrentBoardData = R.pipe(
      R.modify(['vertical'], updateVBoardData),
      R.modify(['horizon'], updateHBoardData)
    )(currentBoardData)

    currentData.deviceControl.channel[currentRfMode] = newCurrentBoardData

    yield put(setSingleDeviceData({ sn, data: currentData }))
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    All Power       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* channelAllPower(payloads) {
  try {
    const { sn, hvMode } = payloads
    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    const prevValue = currentData.deviceControl.channel[currentRfMode][hvMode]
    currentData.deviceControl.channel[currentRfMode][hvMode] = !prevValue

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_ARRAY1024_CHANNEL_POWER_ALL,
      {
        sn,
        lookupID,
        rfMode: currentRfMode,
        hvMode,
        value: !prevValue,
      }
    )
  } catch (error) {
    devWarLog('[handler] channelAllPower error:', error)
  }
}
