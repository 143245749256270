import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Phase({ className }) {
  return (
    <SVGUniqueID>
      <svg
        viewBox='0 0 16 16'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.5'>
          <path d='M8.69984 12.9023H7.35773C6.33142 12.9023 5.41475 12.7073 4.60773 12.3171C3.8051 11.9229 3.17133 11.375 2.70641 10.6736C2.24589 9.96802 2.01562 9.15039 2.01562 8.2207C2.01562 7.29102 2.25027 6.47339 2.71957 5.76782C3.18887 5.0581 3.82484 4.5061 4.62747 4.11182C5.4301 3.71338 6.33141 3.51416 7.33141 3.51416H8.72615C9.72615 3.51416 10.6253 3.7113 11.4235 4.10559C12.2218 4.49573 12.8533 5.04358 13.3183 5.74915C13.7832 6.45056 14.0156 7.26611 14.0156 8.1958C14.0156 9.13379 13.7854 9.95557 13.3248 10.6611C12.8687 11.3667 12.2393 11.9166 11.4367 12.3109C10.6384 12.7052 9.72615 12.9023 8.69984 12.9023ZM7.35773 11.4829H8.69984C9.46299 11.4829 10.1231 11.348 10.6801 11.0782C11.2415 10.8043 11.6735 10.4225 11.9762 9.93274C12.2832 9.43884 12.4367 8.85986 12.4367 8.1958C12.4367 7.53174 12.281 6.95691 11.9696 6.47131C11.6582 5.98157 11.2262 5.60388 10.6735 5.33826C10.1209 5.06848 9.48054 4.93359 8.75247 4.93359H7.33141C6.59457 4.93359 5.94545 5.07056 5.38405 5.34448C4.82264 5.61426 4.38405 5.99609 4.06826 6.48999C3.75247 6.97974 3.59457 7.55664 3.59457 8.2207C3.59457 8.88477 3.75027 9.46167 4.06168 9.95142C4.37747 10.437 4.81606 10.8147 5.37747 11.0845C5.94326 11.3501 6.60334 11.4829 7.35773 11.4829ZM8.83141 1.83325V14.5833H7.25247V1.83325H8.83141Z' />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
