import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { IoHelpSharp } from 'react-icons/io5'

import {
  bbox_configImportNewSettingsModal_noClick_watcher,
  bbox_configImportNewSettingsModal_importClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

const ImportNewSettings = props => {
  const dispatch = useDispatch()
  const { targetFreq, targetAntenna } = props

  return (
    <Modal>
      <Modal.Icon>
        <IoHelpSharp className='text-white' />
      </Modal.Icon>

      <Modal.Title>Import new settings?</Modal.Title>

      <Modal.Description>
        The matching frequency / antenna in this file is:
        {targetFreq !== null && (
          <>
            <br />
            {targetFreq}GHz
          </>
        )}
        {targetAntenna !== null && (
          <>
            {' '}
            / <br />
            {targetAntenna || 'No antenna'}
          </>
        )}
        <br />
        Do you want to import it?
      </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          type='general'
          size='lg'
          onClick={() =>
            dispatch(bbox_configImportNewSettingsModal_noClick_watcher())
          }>
          NO
        </CommonButton>
        <CommonButton
          type='primary'
          size='lg'
          onClick={() =>
            dispatch(bbox_configImportNewSettingsModal_importClick_watcher())
          }>
          Import
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

ImportNewSettings.propTypes = {}

export default ImportNewSettings
