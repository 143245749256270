import { useSelector } from 'react-redux'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

//* 根據不同 device 抓各別的 uiControl slice
const useGetUIControl = sn => {
  const { current } = useGetCurrentDeviceData(sn)
  const deviceType = current?.deviceType

  const { single, all } = getDeviceInfo[deviceType]?.uiControlSliceName

  const single_uiControl = useSelector(state => state?.[single]?.[sn])
  const all_uiControl = useSelector(state => state?.[all])

  return { all: all_uiControl, single: single_uiControl }
}

export default useGetUIControl
