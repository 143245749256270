import React from 'react'
import PropTypes from 'prop-types'

import Mask from 'src/components/Mask'

import { RotatingLines } from 'react-loader-spinner'
import useGetUIControl from 'src/hooks/useGetUIControl'

const OverlayForBeamRequesting = ({ sn }) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { single: s_uiControl } = useGetUIControl(sn)

  const showLoadingMask = s_uiControl.deviceControl.showLoadingMask?.channel

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  if (showLoadingMask)
    return (
      <Mask>
        <div className='w-full h-full flex flex-col items-center justify-center gap-y-10'>
          <RotatingLines
            strokeColor='white'
            strokeWidth='3'
            animationDuration='0.75'
            width='56'
            visible={true}
          />
          <h6 className={h6}>Synchronizing changes</h6>
        </div>
      </Mask>
    )

  return <></>
}

OverlayForBeamRequesting.propTypes = { sn: PropTypes.string }

export default OverlayForBeamRequesting

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

const h6 = `text-light-4 text-base font-bold text-center`
