import { put, call } from 'redux-saga/effects'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

import powerDetectorApi from 'src/redux/sagas/services/socketAPI/powerDetector'
import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* init(payloads) {
  try {
    const { sn } = payloads
    const { currentData, lookupID } = yield call(getCommonArgs, sn)

    // initialization 在 init device 前不先寫成 unfinished,

    // initialization 在等於 'failed' 時
    // 在 operating 裡面會顯示 init failed 且有 retry 可以按
    currentData.info.initialization = 'unfinished'

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(__socket_API_sender, localMiddlewareApi.INIT_PD_LOCAL_TREAD, {
      sn,
      lookupID,
    })

    yield call(__socket_API_sender, powerDetectorApi.INIT_DEVICE, {
      sn,
      lookupID,
    })
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}
