import React, { useLayoutEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import InputGroup from 'src/components/Input/InputGroup'

import RF from 'src/assets/svg/unit/RF'
import IF from 'src/assets/svg/unit/IF'
import LO from 'src/assets/svg/unit/LO'

import { BiError } from 'react-icons/bi'
import { HiRefresh } from 'react-icons/hi'

import { h4, h6 } from 'src/containers/operating/device/freqConverter/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useScrollIntoView from 'src/hooks/useScrollIntoView'

import { valueConversionFromUnit } from 'src/funcs/device/udbox'
import CommonButton from 'src/components/Button/CommonButton'
import useGetUIControl from 'src/hooks/useGetUIControl'
import { useDispatch } from 'react-redux'
import { ud_general_updateCurrentClick_watcher } from 'src/redux/actions/freqConverter/generalAction'

const Current = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const scrollTo = useScrollIntoView

  const sn = current?.sn

  const { single: s_uiControl } = useGetUIControl(sn)

  const isFreqSuccess = s_uiControl?.general?.freqSettings?.isSuccess

  const {
    RF: RFValue,
    IF: IFValue,
    LO: LOValue,
    isHarmonic,
    isSupportUpdateCurrent,
  } = current?.data.current.freq

  const { unit } = current.data.settings.freq

  const harmonicColor = isHarmonic ? ' text-yellow' : ' text-white/30'

  const processedRF = valueConversionFromUnit(RFValue, unit)
  const processedIF = valueConversionFromUnit(IFValue, unit)
  const processedLO = valueConversionFromUnit(LOValue, unit)

  const { ref, entry } = useInView()

  useLayoutEffect(() => {
    if (isFreqSuccess) scrollTo({ ref: entry?.target, block: 'center' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreqSuccess])

  const handleUpdateClick = () =>
    dispatch(ud_general_updateCurrentClick_watcher({ sn }))

  return (
    <div className={container} ref={ref}>
      {/* phase 1 沒會員所以沒有 save */}
      {/* <div className={head}></div> */}

      <div className={body}>
        <h4 className={h4 + ' text-white'}>Current Value</h4>

        <div className='flex gap-x-3'>
          <h6 className={h6 + harmonicColor + ' flex items-center gap-x-1 '}>
            {isHarmonic && (
              <>
                <BiError />
                Harmonic
              </>
            )}

            {!isHarmonic && 'No Harmonic'}
          </h6>

          {isSupportUpdateCurrent && (
            <CommonButton
              type='outlined'
              size='sm'
              icon={
                <HiRefresh className='group-hover:rotate-180 duration-300' />
              }
              onClick={handleUpdateClick}>
              Update
            </CommonButton>
          )}
        </div>
      </div>

      <div className={footer}>
        <InputGroup
          value={processedRF}
          icon={<RF />}
          size='md'
          unit={unit}
          className={inputGroupClass}
          off={!RFValue}
          warning={isHarmonic}
          readOnly
        />
        <InputGroup
          value={processedIF}
          icon={<IF />}
          size='md'
          unit={unit}
          className={inputGroupClass}
          off={!IFValue}
          warning={isHarmonic}
          readOnly
        />
        <InputGroup
          value={processedLO}
          icon={<LO />}
          size='md'
          unit={unit}
          className={inputGroupClass}
          off={!LOValue}
          warning={isHarmonic}
          readOnly
        />
      </div>
    </div>
  )
}

Current.propTypes = {}

export default Current

const container = `[Current-container] 
                  functionBlockBackground 
                  w-full
                  rounded-md
                  p-3 
                  `

// const head = `[Current-head]
//               w-full
//               flex
//               justify-between
//               px-2
//               `

const body = `[Current-body] 
              w-full 
              flex 
              items-center 
              justify-between 
              px-3 
              mb-2
              `

const footer = `[Current-footer] 
              w-full 
              flex 
              flex-col md:flex-row 
              md:justify-between
              gap-y-2 md:gap-x-3 
              px-3 
              pb-1 
              `

const inputGroupClass = `[Current-inputGroupClass] w-full `
