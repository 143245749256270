import React from 'react'
import { useDispatch } from 'react-redux'

import SegmentedButton from 'src/components/Button/SegmentedButton'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { bbox_common_rfModeSwitch_watcher } from 'src/redux/actions/beamformers/bboxAction'

const RfMode = props => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const { rfMode } = current.data.deviceControl.common

  const isMDSize = isSM || isMD

  const handleRfModeChange = (event, value) =>
    dispatch(bbox_common_rfModeSwitch_watcher({ sn, value }))

  return (
    <SegmentedButton
      size={isMDSize ? 'md' : 'sm'}
      type='outlined'
      value={rfMode}
      disabled={false}
      onChange={handleRfModeChange}>
      <SegmentedButton.Option name='tx'>TX</SegmentedButton.Option>
      <SegmentedButton.Option name='rx'>RX</SegmentedButton.Option>
    </SegmentedButton>
  )
}

export default RfMode
