import { createSlice } from '@reduxjs/toolkit'

export const headerSlice = createSlice({
  name: 'header',
  initialState: {
    key: '',
    props: '',
  },
  reducers: {
    setHeader(state, action) {
      //   const {key, props} = action.payload
      return action.payload
    },
  },
})

export const { setHeader } = headerSlice.actions

export default headerSlice.reducer
