import React, { useRef, useEffect } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6, small } from 'src/containers/operating/device/freqConverter/style'
import CommonInput from 'src/components/Input/CommonInput'
import Tooltip from 'src/components/Popup/Tooltip'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { isDynamicStartEndInvalid } from 'src/funcs/device/sg'

const StartEndFrequency = props => {
  const startInputRef = useRef(null)
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const { min, max, dynamic } = current.data?.settings.output.frequency
  const { start, end } = dynamic

  const isUseDynamicFreq =
    current.data?.settings?.output.frequency.type === 'dynamic'

  useEffect(() => {
    if (isUseDynamicFreq) startInputRef.current.focus()
  }, [isUseDynamicFreq])

  const isOutputting = current.data.settings.output.isOutputting
  const isInputDisabled = !isUseDynamicFreq || isOutputting
  const isTooltipDisabled = isUseDynamicFreq || isOutputting

  const { isStartInvalid, isEndInvalid } = isDynamicStartEndInvalid({
    start,
    end,
    min,
    max,
  })

  const startSmallColor =
    isStartInvalid && !isInputDisabled ? ' text-red' : ' text-white/50'
  const endSmallColor =
    isEndInvalid && !isInputDisabled ? ' text-red' : ' text-white/50'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleStartInputChange = result => {
    let newData = R.clone(current.data)
    newData.settings.output.frequency.dynamic.start = String(
      result.formattedValue
    )
    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  const handleEndInputChange = result => {
    let newData = R.clone(current.data)
    newData.settings.output.frequency.dynamic.end = String(
      result.formattedValue
    )
    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='grid grid-cols-2 gap-2 mb-8'>
      <Tooltip
        title='Click "Set Frequency Range" to enable'
        placement='bottom'
        disabled={isTooltipDisabled}>
        <h6 className={h6 + ' text-light-1'}>Start Frequency (MHz)</h6>
        <CommonInput
          ref={startInputRef}
          size={isXL || is2XL ? 'sm' : 'md'}
          className='my-1'
          inputType='number'
          value={start || ''}
          disabled={isInputDisabled}
          danger={isStartInvalid}
          onChange={handleStartInputChange}
        />
        <small className={small + startSmallColor}>
          {min} ~ {max} MHz
        </small>
      </Tooltip>

      <Tooltip
        title='Click "Set Frequency Range" to enable'
        placement='bottom'
        disabled={isTooltipDisabled}>
        <h6 className={h6 + ' text-light-1'}>End Frequency (MHz)</h6>
        <CommonInput
          size={isXL || is2XL ? 'sm' : 'md'}
          className='my-1'
          inputType='number'
          value={end || ''}
          danger={isEndInvalid}
          disabled={isInputDisabled}
          onChange={handleEndInputChange}
        />
        <small className={small + endSmallColor}>
          {min} ~ {max} MHz (Must be &gt; Start)
        </small>
      </Tooltip>
    </div>
  )
}

export default StartEndFrequency
