import * as R from 'ramda'
import moment from 'moment'
import Decimal from 'decimal.js'
import { antennaNameTimestampFormat as timeFormat } from 'src/constants/beamformers'

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------       Device Control - Antenna       -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const getCustomAntennaIdList = antennaData =>
  antennaData.filter(e => e.type === 'customized').map(e => e.id)

// antenna name (fullName) 的命名規則為 [name]_[timestamp]
// valid timestamp ex: 2023021705255454+0800
// moment(timestamp, format, isStrictMode).isValid()
const timestampVerify = timestamp =>
  moment(timestamp, timeFormat, true).isValid()

export const getAntennaTimestamp = fullName => {
  const timestamp = R.pipe(R.split('_'), R.last())(fullName)

  const timestampIsValid = timestampVerify(timestamp)
  return timestampIsValid ? timestamp : null
}
export const getAntennaName = fullName => {
  const name = R.pipe(R.split('_'), R.dropLast(1), R.join('_'))(fullName)

  const timestampIsValid = timestampVerify(getAntennaTimestamp(fullName))

  return timestampIsValid ? name : fullName
}

// antenna verify
export const nameIsTooLong = name => name?.length > 60
export const nameIsEmpty = name => R.isEmpty(name)

const antennaNameRegex = /[?”“/\\.;<>*|:]+/
export const nameIsIllegal = name =>
  name.match(antennaNameRegex)?.[0] !== undefined

export const antennaNameIsInvalid = name =>
  nameIsTooLong(name) || nameIsIllegal(name) || nameIsEmpty(name)

export const spacingIsInvalid = spacing => isNaN(+spacing)

export const thetaMaxIsInvalid = thetaMax =>
  thetaMax < 0 || thetaMax > 90 || isNaN(+thetaMax)

export const offsetIsInvalid = (value, min, max) =>
  R.isEmpty(value) || value > max || value < min || value % 5 !== 0

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------           Beam Config Editor         -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const convertDataOfBSConfigToBSComponent = ({ configData }) => ({
  ...configData,
  beamCurrentGain: configData.db,
})
export const convertDataOfBSComponentToBSConfig = ({ componentData }) => ({
  ...componentData,
  db: componentData.beamCurrentGain,
})

const totalGainProcess = ({ commonGain, elementGain }) => {
  try {
    // 為了讓使用者可在 element gain 順利輸入例外情況 這邊就直接回傳
    //! WEBTLK-1338: https://tmytek.atlassian.net/browse/WEBTLK-1338
    //! 這裡的例外清單 不可以與下面的 「通用 input」 的例外清單共用
    //! import { inputAccessibleExceptionList } from 'src/components/Input/func'
    if (['', '-', '-0'].includes(elementGain)) return elementGain

    // 為了讓使用者可在 common gain 單獨輸入 '-'也不出錯，這邊就直接回傳 element gain
    if (commonGain === '-') return elementGain

    if (elementGain[elementGain.length - 1] === '.') {
      // 有可能會收到 "2.5." 這種狀況，
      // 所以都統一把最後的小數點拔掉
      // 轉數字下去計算後再把小數點加回去
      const removeDotElementGain = R.dropLast(1)(elementGain)

      // 小數點相加會有可能出現非常多神奇的尾數，所以用套件來處理
      return new Decimal(+commonGain).add(+removeDotElementGain).valueOf() + '.'
    }

    // 小數點相加會有可能出現非常多神奇的尾數，所以用套件來處理
    return new Decimal(+commonGain).add(+elementGain).toNumber()
  } catch (error) {
    console.warn(error)
  }
}

export const convertDataOfCHConfigToCHComponent = ({
  rfMode,
  currentDeviceData,
  configData,
}) => {
  // config 的 rfMode 跟 deviceControl 的 rfMode 是斷開的
  // 直接撈會不準，因此還是要由外部傳進來
  const deviceControlLstBoardData =
    currentDeviceData.deviceControl.channel[rfMode].lstBoardData

  return R.clone(configData).map((be, bi) => ({
    ...deviceControlLstBoardData[bi],
    commonCurrentGain: be.common_db,
    lstChannelData: be.ch_group.map(ce => ({
      totalGain: totalGainProcess({
        commonGain: be.common_db,
        elementGain: ce.db,
      }),
      elementGain: ce.db,
      phase: ce.deg,
      power: +ce.sw === 0 ? true : false, //! sw 在 API 打開是 0, 關閉是 1
    })),
  }))
}
export const convertDataOfCHComponentToCHConfig = ({ componentData }) =>
  R.clone(componentData).map((be, bi) => ({
    board: bi + 1,
    common_db: be.commonCurrentGain,
    ch_group: be.lstChannelData.map((ce, ci) => ({
      board_ch: ci + 1,
      db: ce.elementGain,
      deg: ce.phase,
      sw: ce.power ? 0 : 1, //! sw 在 API 打開是 0, 關閉是 1
    })),
  }))

export const createConfigInitData = ({
  beamID,
  description = '',
  type,
  rfMode,
  currentDeviceData: deviceData,
  beam_config = null,
  channel_config = null,
}) => ({
  beamID,
  beam_description: description,
  beam_type: type === 'beam' ? 0 : 1,
  beam_config: beam_config || {
    db: getBeamGainMax({ deviceData, rfMode }),
    theta: 0,
    phi: 0,
  },
  channel_config:
    channel_config ||
    new Array(getBoardCount({ deviceData })).fill(null).map((be, bi) => ({
      board: bi + 1,
      common_db: getCommonGainMax({
        deviceData,
        rfMode,
        boardIndex: bi,
      }),
      ch_group: new Array(getChannelCount({ deviceData }))
        .fill(null)
        .map((ce, ci) => ({
          board_ch: ci + 1,
          sw: 0, //! sw 在 API 打開是 0, 關閉是 1
          db: getElementGainMax({
            deviceData,
            rfMode,
            boardIndex: bi,
          }), // element gain
          deg: 0,
        })),
    })),
})

const getBeamGainMax = ({ deviceData, rfMode }) =>
  deviceData.deviceControl.steering[rfMode].beamGainMax

const getBoardCount = ({ deviceData }) =>
  deviceData.deviceControl.channel.boardCount

const getChannelCount = ({ deviceData }) =>
  deviceData.deviceControl.channel.channelCount

const getCommonGainMax = ({ deviceData, rfMode, boardIndex }) =>
  deviceData.deviceControl.channel[rfMode].lstBoardData[boardIndex]
    .commonGainMax

const getElementGainMax = ({ deviceData, rfMode, boardIndex }) => {
  const commonGainMax = getCommonGainMax({ deviceData, rfMode, boardIndex })
  const totalGainMax =
    deviceData.deviceControl.channel[rfMode].lstBoardData[boardIndex]
      .totalGainMax

  return totalGainMax - commonGainMax
}
