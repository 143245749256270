import { put } from 'redux-saga/effects'

import { devWarLog } from 'src/funcs/tools'
import { modalActions } from 'src/redux/slices/modal'
import { cclCttcUIControlForSingleActions as uiControlAction } from 'src/redux/slices/uiControl/beamformers/cloverCellCttc'

export function* common_refSignalSourceChange({ response }) {
  try {
    const [sn] = Object.entries(response?.data)[0]

    // 清除 requesting 狀態
    yield put(uiControlAction.clearRefSourceStatus({ sn }))

    // 顯示 error modal
    yield put(modalActions.showModal_cclCTTC_toExternalError({ sn }))
  } catch (error) {
    devWarLog(error)
  }
}
