import { isProduction } from 'src/funcs/getEnv'

const POOL_ID_DEV = process.env.REACT_APP_COGNITO_POOL_ID_DEV
const CLIENT_ID_DEV = process.env.REACT_APP_COGNITO_CLIENT_ID_DEV

const POOL_ID_PRODUCTION = process.env.REACT_APP_COGNITO_POOL_ID_PRODUCTION
const CLIENT_ID_PRODUCTION = process.env.REACT_APP_COGNITO_CLIENT_ID_PRODUCTION

const userPoolId = isProduction ? POOL_ID_PRODUCTION : POOL_ID_DEV
const userPoolWebClientId = isProduction ? CLIENT_ID_PRODUCTION : CLIENT_ID_DEV

export const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId,
    userPoolWebClientId,
  },
}
