import React from 'react'

import LottieWeb from 'src/components/Lottie'

import TMXLABShortAnimation from 'src/assets/json/TMXLABShortAnimation.json'

const DeviceInitializing = props => {
  return (
    <section className={initSection}>
      <div className='w-[300px]'>
        <LottieWeb
          className='scale-125'
          play={true}
          loop={true}
          animationData={TMXLABShortAnimation}
          segments={[100, 130]}
          forceSegments={false}
        />
      </div>
      <div className='text-lg text-white/90 font-bold leading-5'>
        Device initializing...Please wait.
      </div>
    </section>
  )
}

DeviceInitializing.propTypes = {}

export default DeviceInitializing

// h-[calc(100vh_-_64px_-_24px_-_84px)]
// 64px = 上方空白處
// 24px = operating 下方 padding
// 84px - footer 的 height
const initSection = `[DeviceInitializing-initSection] 
                functionBlockBackground 
                relative 
                w-full 
                h-[calc(100vh_-_64px_-_24px_-_84px)] 
                flex flex-col 
                items-center justify-center 
                text-light-4 text-3xl font-bold text-center 
                `
