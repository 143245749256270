import React, { forwardRef } from 'react'
import { useFBX, shaderMaterial } from '@react-three/drei'
import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import glsl from 'babel-plugin-glsl/macro'

import beamFbx from 'src/assets/fbx/beam.fbx'

const BeamMaterial = shaderMaterial(
  // uniform
  {
    vColor1: new THREE.Color('lightgreen'),
    vColor2: new THREE.Color('red'),
    vBeamMin: { x: 0, y: 0 },
    vBeamMax: { x: 14, y: 14 },
  },
  // vertex shader
  glsl`
      uniform vec3 vBeamMin;
      uniform vec3 vBeamMax;
      varying vec2 vUv;
  
      void main() {
        vUv = uv;
  
        vUv.y = (position.y - vBeamMin.y) / (vBeamMax.y - vBeamMin.y);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  // fragment shader
  glsl`
      uniform vec3 vColor1;
      uniform vec3 vColor2;
  
      varying vec2 vUv;
  
      void main() {
        gl_FragColor = vec4(mix(vColor1 ,vColor2, vUv.y), 1.0);
      }
    `
)

extend({ BeamMaterial })

const Beam = forwardRef((props, ref) => {
  const fbx = useFBX(beamFbx)
  const beamModel = fbx.children[0].children[0]

  return (
    <group ref={ref} onClick={props.onClick}>
      <mesh {...beamModel}>
        {props.isActive ? (
          <beamMaterial wireframe />
        ) : (
          <meshPhongMaterial
            wireframe
            transparent
            opacity={0.2}
            color='#ffffff'
          />
        )}
      </mesh>
    </group>
  )
})

export default Beam
