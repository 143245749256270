import * as ReactDOMClient from 'react-dom/client'
import ReactGA from 'react-ga4'

import 'reset-css'
import 'src/stylesheets/index.css'
import 'swiper/css'
import 'swiper/css/pagination'

import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import reportWebVitals from './reportWebVitals'

import React from 'react'
import { Provider } from 'react-redux'
import { store } from './redux/configureStore'

import { isPROD, isQa, isDeveloping, isOffline } from 'src/funcs/getEnv'

const rootElement = document.getElementById('root')

const root = ReactDOMClient.createRoot(rootElement)

const getMeasurementId = () => {
  if (isPROD) return 'G-WNEQRYR465'
  if (isQa) return 'G-HMV1T0ZRYG'
  if (isDeveloping) return 'G-XVRGRB5331'

  return ''
}

// 離線版不用掛 GA
if (!isOffline) ReactGA.initialize(getMeasurementId())

//! 不用嚴格模式是因為 react-beautiful-dnd 在嚴格模式下會出錯
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register()
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
