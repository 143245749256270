import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

import { _vChannelSyncWithH } from 'src/redux/sagas/handlers/event/beamformers/cloverCell/evb/channel'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       RfMode       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// const { sn, value } = payloads
// const { lookupID } = yield call(getCommonArgs, sn)

// let currentData = yield call(getCurrentData, sn)
// currentData.deviceControl.common.rfMode = value
// yield put(setSingleDeviceData({ sn, data: currentData }))

// yield call(__socket_API_sender, beamformersApi.COMMON_RF_MODE, {
//   sn,
//   lookupID,
//   value,
// })

export function* commonAntennaPolarizationChange(payloads) {
  try {
    yield console.log('commonAntennaPolarizationChange', payloads)
    const { sn, value } = payloads

    let { currentData } = yield call(getCommonArgs, sn)

    currentData.deviceControl.common.hvMode = value

    if (value !== 'dual') currentData.deviceControl.common.polarSynthesis = null

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] commonAntennaPolarizationChange error:', error)
  }
}

export function* commonPolarSynthesisChange(payloads) {
  try {
    yield console.log('commonPolarSynthesisChange', payloads)

    const { sn, value } = payloads

    let { currentData } = yield call(getCommonArgs, sn)

    const currentPolarSynthesis =
      currentData.deviceControl.common.polarSynthesis
    const hvMode = currentData.deviceControl.common.hvMode

    currentData.deviceControl.common.polarSynthesis = value

    // 如果是 dual 模式的話，啟用點擊自己就取消極化的功能
    if (hvMode === 'dual' && currentPolarSynthesis === value)
      currentData.deviceControl.common.polarSynthesis = null

    // 先改 redux state 後續 _vChannelSyncWithH 抓值才會是新的
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(_vChannelSyncWithH, { sn })
  } catch (error) {
    devWarLog('[handler] commonPolarSynthesisChange error:', error)
  }
}
