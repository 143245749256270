import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function X({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.48111 5L11.9722 9.561L14.5328 5H18.8052L14.5467 12.3336L19 20H14.7137L12 15.1756L9.28628 20H5L9.45328 12.3336L5.19483 5H9.48111Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
