import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  useFloating,
  FloatingPortal,
  FloatingOverlay,
} from '@floating-ui/react'

const PortalAndOverlay = ({ render, open: passedOpen = false }) => {
  const [open, setOpen] = useState(passedOpen)

  useFloating({
    open,
    onOpenChange: setOpen,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------         CSS        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const overlayClass = `
                        fixed 
                        inset-0 
                        bg-black/80 
                        z-10 
                        px-4 
                        flex 
                        items-center 
                        justify-center
                        `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <FloatingPortal>
      {open && (
        <FloatingOverlay lockScroll className={overlayClass}>
          {render({
            close: () => setOpen(false),
          })}
        </FloatingOverlay>
      )}
    </FloatingPortal>
  )
}
export default PortalAndOverlay

PortalAndOverlay.propTypes = {
  render: PropTypes.func,
  open: PropTypes.bool,
}
