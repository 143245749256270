import { createSlice, createAction, current } from '@reduxjs/toolkit'

const initState = {
  userID: '',
  email: '',
  preferredUsername: '',
  picture: '',
  avatarDefaultColor: '',
  accessJwtToken: '',
}

export const memberSlice = createSlice({
  name: 'member',
  initialState: initState,
  reducers: {
    clearMember() {
      return initState
    },
    addMember(state, action) {
      // const { userID, email, preferredUsername, picture, avatarDefaultColor, accessJwtToken } = action.payload
      return action.payload
    },
    setMemberAttribute(state, action) {
      const { picture, preferredUsername } = action.payload
      return {
        ...current(state),
        picture: picture ?? current(state).picture,
        preferredUsername:
          preferredUsername ?? current(state).preferredUsername,
      }
    },
  },
})

export const { clearMember, addMember, setMemberAttribute } =
  memberSlice.actions

export const scanWatcher = createAction('member/')

export default memberSlice.reducer
