import { put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* steering_controlPatternChange(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog(error)
  }
}

export function* steering_beamSynthesisChange(response) {
  try {
    yield console.log(`response`, response)
    // api 回來有操作寫這
  } catch (error) {
    devWarLog(error)
  }
}

export function* steering_rfModeChange(response) {
  try {
    yield console.log(`response`, response)
    // api 回來有操作寫這
  } catch (error) {
    devWarLog(error)
  }
}
export function* steering_powerChange(response) {
  try {
    yield console.log(`response`, response)
    // api 回來有操作寫這
  } catch (error) {
    devWarLog(error)
  }
}
export function* steering_thetaPhiGainChange(response) {
  try {
    yield console.log(`response`, response)
    // api 回來有操作寫這
  } catch (error) {
    devWarLog(error)
  }
}
