import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { textBox, subtitle, content } from 'src/containers/home/style'

const Version = memo(({ sn, data = {} }) =>
  Object.entries(data)
    .map(([key, value]) => ({ ...dataInfo[key], value }))
    .sort((a, b) => a.index - b.index)
    .map(
      ({ title, index, value }) =>
        value && (
          <div className={textBox} key={sn + index + value}>
            <label className={subtitle}>{title}</label>
            <p className={content}>{vHeadAddition(value)}</p>
          </div>
        )
    )
)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static Func      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const dataInfo = {
  caliTable: { title: 'Calibration Table Version', index: 0 },
  firmware: { title: 'Firmware Version', index: 1 },
  hardware: { title: 'Hardware Version', index: 2 },
}
const vHeadAddition = string =>
  string && (string[0] !== 'v' ? 'v' + string : string)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Version.propTypes = { data: PropTypes.object }

export default Version
