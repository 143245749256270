import React from 'react'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/freqConverter/style'
import CommonButton from 'src/components/Button/CommonButton'

import { CgInfinity } from 'react-icons/cg'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetUIControl from 'src/hooks/useGetUIControl'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { sg_outputSettingsApply_click_watcher } from 'src/redux/actions/freqConverter/sgAction'
import { isOutputSettingsInvalid } from 'src/funcs/device/sg'

const ApplyButton = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const { isXL, is2XL } = useGetScreenSize()
  const { single: s_uiControl } = useGetUIControl(sn)

  const isOutputting = current.data.settings.output.isOutputting
  const isStopped = s_uiControl.outputSettings.isStopped

  const isDisabled = isOutputSettingsInvalid({ deviceData: current.data })

  const handleClick = () =>
    dispatch(sg_outputSettingsApply_click_watcher({ sn }))

  return (
    <div className='w-full flex flex-col'>
      <h6 className={h6 + ' text-light-1 mb-1 '}>Output Control</h6>

      <CommonButton
        size={isXL || is2XL ? 'sm' : 'md'}
        className='w-full'
        danger={isOutputting}
        disabled={isDisabled}
        onClick={handleClick}>
        {isOutputting ? 'STOP' : 'START'}
      </CommonButton>

      {isOutputting && (
        <span className='self-center flex items-center mt-2 text-light-1/50 animate-breathOpacity'>
          <CgInfinity className='text-xl mr-2' />
          Signal Outputting...
        </span>
      )}

      {!isOutputting && isStopped && (
        <span className='self-center text-light-1 mt-2'>Signal Stopped</span>
      )}
    </div>
  )
}

ApplyButton.propTypes = {}

export default ApplyButton
