import React from 'react'

import HeadLogo from 'src/containers/Header/HeadLogo'
import MainLeft from 'src/containers/Header/MainLeft'
import MainRight from 'src/containers/Header/MainRight'

import useGetScrollInfo from 'src/hooks/useGetScrollInfo'

const Main = () => {
  const { pageYOffset } = useGetScrollInfo()

  const containerBackground = pageYOffset ? 'bg-dark-1' : 'bg-transparent'
  const containerClass = `${containerCommon} ${containerBackground}`

  // header Main 的三個部分可以根據 slice.header 的 key & props 調整內容
  return (
    <div className={containerClass}>
      <div className={widthLimit}>
        <MainLeft />

        <HeadLogo />

        <MainRight />
      </div>
    </div>
  )
}

Main.propTypes = {}

export default Main

const containerCommon = ` 
                        h-[72px] w-full 
                        duration-500 
                        `

const widthLimit = `relative
                    w-full h-full max-w-[1440px] 
                    mx-auto 
                    flex items-center justify-center
                    `
