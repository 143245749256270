import BboxOne5GIcon from 'src/assets/svg/device/icon/BboxOne5G'
import BboxLite5GIcon from 'src/assets/svg/device/icon/BboxLite5G'
import BboardIcon from 'src/assets/svg/device/icon/Bboard'

import Udbox5GIcon from 'src/assets/svg/device/icon/Udbox5G'
import UdModuleIcon from 'src/assets/svg/device/icon/UdModule'
import Ud0630Icon from 'src/assets/svg/device/icon/Ud0630'
import PowerDetectorIcon from 'src/assets/svg/device/icon/PowerDetector'
import RisIcon from 'src/assets/svg/device/icon/Ris'

import { isProduction } from 'src/funcs/getEnv'

export const productList = [
  { name: 'BBox One', category: 'Beamformers', icon: <BboxOne5GIcon /> },
  { name: 'BBox Lite', category: 'Beamformers', icon: <BboxLite5GIcon /> },
  { name: 'BBoard', category: 'Beamformers', icon: <BboardIcon /> },
  {
    name: 'UD Box 5G',
    category: 'Frequency Converter',
    icon: <Udbox5GIcon />,
  },
  {
    name: 'UD Module',
    category: 'Frequency Converter',
    icon: <UdModuleIcon />,
  },
  {
    name: 'Power Detector',
    category: '',
    icon: <PowerDetectorIcon />,
  },
  {
    name: 'UD Box 0630',
    category: 'Frequency Converter',
    icon: <Ud0630Icon />,
  },
  {
    name: 'RIS',
    category: 'Beamformers',
    icon: <RisIcon />,
  },
]

export const purposeList = [
  'Antenna design',
  'Beam tracking algorithm development',
  'Signal processing',
  'Beam steering validation',
  'Others',
]

// ----- recipient
const prodRecipient = [
  'operation@tmytek.com',
  'sales@tmytek.com',
  'customersuccess@tmytek.com',
]
const devRecipient = ['tmymail@tmytek.com']
export const createRecipient = () =>
  isProduction ? prodRecipient : devRecipient

export const createCcRecipient = () => []
export const createBccRecipient = () => [
  'tmymail@tmytek.com',
  'ivy_siao@tmytek.com',
  'qianwei_huang@tmytek.com',
  'ethan_liao@tmytek.com',
  'tina_huang@tmytek.com',
  'james_hoo@tmytek.com',
]

// ---- subject

export const createSubject = ({ interestedProduct, firstName, lastName }) =>
  `[${interestedProduct}] New quote from - [ ${firstName} ${lastName} ] ${
    !isProduction ? '「DEVELOPMENT」' : ''
  }`

export const createHtmlTemplate = ({
  interestedProduct,
  purpose,
  purposeOthers,
  firstName,
  lastName,
  email,
  comments,
  privacyPolicy,
}) => `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1" name="viewport">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="telephone=no" name="format-detection">
</head>
<body>
    <div style="border:1px solid #ccc; border-radius:6px; padding: 0 16px; text-align:center">
        <h3 style="font-weight: bold;">Name:</h3>
        <p style="margin: 16px 0 0 0;">${firstName} ${lastName}</p>

        <h3 style="font-weight: bold;">E-mail:</h3>
        <p style="margin: 16px 0 0 0;">${email}</p>

        <h3 style="font-weight: bold;">Comment:</h3>
        <p style="margin: 16px 0 0 0;">${comments}</p>

        <h3 style="font-weight: bold;">Function:</h3>
        <p style="margin: 16px 0 0 0;">${
          purpose === 'others' ? purposeOthers : purpose
        }</p>

        <h3 style="font-weight: bold;">Privacy Policy:</h3>
        <p style="margin: 16px 0 0 0;">${privacyPolicy}</p>

        <h3 style="font-weight: bold;">From WEB-TLK</h3>
    </div>
</body>
</html>`

export const createTextTemplate = ({
  interestedProduct,
  purpose,
  purposeOthers,
  firstName,
  lastName,
  email,
  comments,
  privacyPolicy,
}) =>
  `
  Name: ${firstName} ${lastName}, 
  E-mail: ${email},
  Comment: ${comments},
  Function: ${purpose === 'others' ? purposeOthers : purpose},
  Privacy Policy: ${privacyPolicy}
  Interested Product: ${interestedProduct}, 
  `
