import * as R from 'ramda'
import { isDeveloping } from 'src/funcs/getEnv'

import { devLog } from 'src/funcs/tools'

export const RLog = mark => value => {
  devLog(`${mark || ''}:`, value)
  return value
}

export const RSafe = func => value =>
  R.tryCatch(func, error => isDeveloping && console.warn(error))(value)

export const RMapIndexed = R.addIndex(R.map)
