// import {
//   deviceError as handleDeviceErrorResponse,
//   deviceErrorAndClearNormalModal as handleDeviceErrorAndClearNormalModal,
// } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import {
  steeringThetaPhi as handleSteeringThetaPhiResponse,
  steeringGain as handleSteeringGainResponse,
} from 'src/redux/sagas/handlers/response/beamformers/cloverCellEvb/steering'

const cloverCellEvbApi = {
  CLOVERCELL_EVB_CHANNEL_POWER_ALL: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/channel/power/all',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/channel/power/all/response',
      handler: () => {},
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_CHANNEL_POWER: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/channel/power',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/channel/power/response',
      handler: () => {},
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_CHANNEL_COMMON_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/channel/commonGain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/channel/commonGain/response',
      handler: () => {},
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_CHANNEL_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/channel/gain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/channel/gain/response',
      handler: () => {},
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_CHANNEL_PHASE: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/channel/phase',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/channel/phase/response',
      handler: () => {},
      // errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_EVB_BEAM_STEERING_THETA_PHI: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/beam/steering/thetaPhi',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/beam/steering/thetaPhi/response',
      handler: handleSteeringThetaPhiResponse,
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_BEAM_STEERING_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/evb/beam/steering/gain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/beam/steering/gain/response',
      handler: handleSteeringGainResponse,
      // errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default cloverCellEvbApi
