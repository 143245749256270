import React from 'react'
import { useDispatch } from 'react-redux'

import CommonInput from 'src/components/Input/CommonInput'

import { h6, small } from 'src/containers/operating/device/beamformers/style'

import { cloverCell1024_common_sgFreqChange_watcher } from 'src/redux/actions/beamformers/cloverCellArray1024Action'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const SignalGeneratorFrequency = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  // const currentFreq = current.data.deviceControl.common.currentFreq
  // const lstFreqOptions = current.data.deviceControl.common.lstFreqOptions

  const currentSgFreq = current.data.deviceControl.common?.currentSgFreq
  const sgInvalidMin = current.data.deviceControl.common?.sgFreqMin
  const sgInvalidMax = current.data.deviceControl.common?.sgFreqMax
  const isInvalidSgFreq =
    currentSgFreq < sgInvalidMin || currentSgFreq > sgInvalidMax
  const isExternalControl = current.data.deviceControl.common?.isExternalControl

  const handleChange = result =>
    dispatch(
      cloverCell1024_common_sgFreqChange_watcher({
        sn,
        value: result.formattedValue,
      })
    )

  return (
    <div>
      <h6 className={subtitle}>Signal Generator Frequency (MHz)</h6>
      <CommonInput
        size='sm'
        inputType='number'
        value={currentSgFreq}
        danger={isInvalidSgFreq}
        disabled={isExternalControl}
        onChange={handleChange}
      />
      <small className={small + ' text-white/50'}>
        Value between {sgInvalidMin} ~ {sgInvalidMax}
      </small>
    </div>
  )
}

SignalGeneratorFrequency.propTypes = {}

export default SignalGeneratorFrequency

const subtitle = `${h6} text-light-1 mb-1`
