import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import PasswordInput from 'src/components/Input/PasswordInput'

import { modalActions } from 'src/redux/slices/modal'
import { devWarLog } from 'src/funcs/tools'

const ChangePassword = props => {
  const dispatch = useDispatch()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleCurrentPasswordChange = e => setCurrentPassword(e.formattedValue)
  const handleNewPasswordChange = e => setNewPassword(e.formattedValue)
  const handleConfirmPasswordChange = e => setConfirmPassword(e.formattedValue)

  const handleSubmit = async event => {
    try {
      event.preventDefault()

      if (newPassword !== confirmPassword)
        throw Error('Password and the confirmed password do not match')

      if (newPassword.length < 8)
        throw Error('The password must be at least 8 characters long.')

      const user = await Auth.currentAuthenticatedUser()

      await Auth.changePassword(user, currentPassword, newPassword)

      dispatch(modalActions.clearNormalModal())
    } catch (error) {
      devWarLog('ChangePassword error:', error)
      setErrorMessage(error?.message)
    }
  }

  return (
    <Modal>
      <Modal.Title>Change Password</Modal.Title>

      {errorMessage && <div className='text-red my-6'>{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <Modal.FormLabel htmlFor='currentPassword'>
          Current Password
        </Modal.FormLabel>
        <PasswordInput
          id='currentPassword'
          className='mb-8'
          value={currentPassword}
          placeholder=' Current Password'
          autoFocus
          onChange={handleCurrentPasswordChange}
        />

        <Modal.FormLabel htmlFor='newPassword'>New Password</Modal.FormLabel>
        <PasswordInput
          id='newPassword'
          value={newPassword}
          placeholder='New Password'
          onChange={handleNewPasswordChange}
        />
        <small className='block mt-2 mb-8 text-white/50 text-sm font-normal'>
          At least 8 characters
        </small>

        <Modal.FormLabel htmlFor='confirmPassword'>
          Confirm Password
        </Modal.FormLabel>
        <PasswordInput
          id='confirmPassword'
          value={confirmPassword}
          placeholder='Confirm Password'
          onChange={handleConfirmPasswordChange}
        />

        <Modal.RowButtonBox>
          <CommonButton
            size='lg'
            type='outlined'
            onClick={() => dispatch(modalActions.clearNormalModal())}>
            Cancel
          </CommonButton>
          <CommonButton size='lg' type='primary' buttonType='submit'>
            Save
          </CommonButton>
        </Modal.RowButtonBox>
      </form>
    </Modal>
  )
}

export default ChangePassword
