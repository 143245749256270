import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Pd01Device = props => {
  return (
    <SVGUniqueID>
      <svg
        width='145'
        height='140'
        viewBox='0 0 145 140'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}>
        <path
          d='M42.2322 1.30723L142.232 6.11492C143.032 6.15336 143.66 6.81276 143.66 7.61319V111.958C143.66 112.709 143.105 113.345 142.36 113.445L42.3603 126.906C41.4605 127.027 40.6602 126.328 40.6602 125.42V2.8055C40.6602 1.94879 41.3765 1.26609 42.2322 1.30723Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M132.66 27.207C132.66 28.939 131.471 30.207 130.16 30.207C128.849 30.207 127.66 28.939 127.66 27.207C127.66 25.4751 128.849 24.207 130.16 24.207C131.471 24.207 132.66 25.4751 132.66 27.207Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M132.66 63.207C132.66 64.939 131.471 66.207 130.16 66.207C128.849 66.207 127.66 64.939 127.66 63.207C127.66 61.4751 128.849 60.207 130.16 60.207C131.471 60.207 132.66 61.4751 132.66 63.207Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M2.05329 4.07308L40.0533 1.3588C40.9216 1.29677 41.6602 1.98447 41.6602 2.85498V125.508C41.6602 126.392 40.8986 127.085 40.0179 127.001L2.01794 123.382C1.24809 123.308 0.660156 122.662 0.660156 121.888V5.56926C0.660156 4.78231 1.26833 4.12915 2.05329 4.07308Z'
          fill='#121212'
          stroke='white'
        />
        <path d='M20.4102 4.54785V126.048' stroke='white' strokeOpacity='0.5' />
        <path
          d='M12.4925 46.0377C12.0705 41.2952 15.8103 37.2134 20.5715 37.2199C25.0405 37.2261 28.6602 40.8506 28.6602 45.3197V45.7318C28.6602 50.3373 24.8172 54.0116 20.2164 53.8052C16.1733 53.6237 12.8878 50.4795 12.5291 46.4482L12.4925 46.0377Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M5.46411 45.3297C5.56239 42.5153 7.93061 40.3175 10.7445 40.4292L18.0044 40.7176C20.5621 40.8191 22.5569 42.9686 22.4676 45.5268C22.3611 48.5777 19.7938 50.9603 16.7433 50.8391L10.3412 50.5849C7.54626 50.4739 5.3665 48.1251 5.46411 45.3297Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M5.66016 44.707C5.66016 42.2217 7.67487 40.207 10.1602 40.207C12.6454 40.207 14.6602 42.2217 14.6602 44.707V45.957C14.6602 48.4423 12.6454 50.457 10.1602 50.457C7.67487 50.457 5.66016 48.4423 5.66016 45.957V44.707Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M12.4925 84.0377C12.0705 79.2952 15.8103 75.2134 20.5715 75.2199C25.0405 75.2261 28.6602 78.8506 28.6602 83.3197V83.7318C28.6602 88.3373 24.8172 92.0116 20.2164 91.8052C16.1733 91.6237 12.8878 88.4795 12.5291 84.4482L12.4925 84.0377Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M5.66016 83.3157C5.66016 80.4995 7.95023 78.2204 10.7663 78.2339L18.0319 78.2687C20.5916 78.2809 22.6602 80.3595 22.6602 82.9192C22.6602 85.972 20.1776 88.4427 17.1248 88.4281L10.7177 88.3974C7.92057 88.384 5.66016 86.1128 5.66016 83.3157Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M4.66016 82.707C4.66016 80.2217 6.67487 78.207 9.16016 78.207C11.6454 78.207 13.6602 80.2217 13.6602 82.707V83.957C13.6602 86.4423 11.6454 88.457 9.16016 88.457C6.67487 88.457 4.66016 86.4423 4.66016 83.957V82.707Z'
          fill='#434343'
          stroke='white'
        />
        <rect
          x='47.4102'
          y='58.707'
          width='81'
          height='81'
          fill='url(#pattern0_16273_23092)'
          fillOpacity='0.5'
        />
      </svg>
    </SVGUniqueID>
  )
}

Pd01Device.propTypes = {}

export default Pd01Device
