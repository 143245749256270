import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import InputGroup from 'src/components/Input/InputGroup'
import CommonSlider from 'src/components/Slider/CommonSlider'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import Attenuation from 'src/assets/svg/unit/Attenuation'

import { h4, small } from 'src/containers/operating/device/beamformers/style'

import {
  bboard_step_commonAttenuationChange_watcher,
  bboard_step_commonAttenuationAfterChange_watcher,
} from 'src/redux/actions/beamformers/bboardAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const CommonAttenuation = () => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const { rfMode } = current.data.deviceControl.common
  const { attenuation, power } = current.data.deviceControl.step[rfMode]

  const { comValue, valueMax: attMax } = attenuation

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const boardChPowerAllOff = !power.channelValue.find(item => item)

  const attMin = 0
  const commonAttStep = 1

  const [commonGainFocus, setCommonGainFocus] = useState(false)
  const [commonGainWarning, setCommonGainWarning] = useState(false)

  const commonGainWarningText = {
    WARNING_MIN: `The minimum value is ${attMin} 
      The system will use the minimum value.`,

    WARNING_MAX: `The maximum value is ${attMax}. 
      The system will use the maximum value.`,

    WARNING_STEP: `Value not allowed. 
    Each gain step is ${commonAttStep}.`,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleSliderChange = value =>
    dispatch(
      bboard_step_commonAttenuationChange_watcher({
        sn,
        value: value,
      })
    )

  const handleSliderAfterChange = value =>
    dispatch(
      bboard_step_commonAttenuationAfterChange_watcher({
        sn,
        value: value,
        isValid: true,
      })
    )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `[Channel-CommonGain-container] 
                      flex 
                      gap-x-7 
                      pt-3 px-[14px]
                      `
  const leftSide = `[Channel-CommonGain-leftSide] 
                      w-full 
                      flex flex-col 
                      items-start 
                      gap-y-1
                      `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const commonGainInputProps = {
    // input params
    icon: <Attenuation />,
    unit: '',
    off: boardChPowerAllOff,
    step: commonAttStep,
    validMin: 0,
    validMax: attMax,
    decimalScale: 0,
    value: String(comValue),
    // progress bar params
    showProgress: false,
  }

  const commonGainDialogOverwriteElement = (
    <div className='relative flex flex-col'>
      <InputGroup
        className='w-[130px] h-[32px] mb-1'
        warning={true}
        disabled={true}
        {...commonGainInputProps}
      />
      <small className={small + ' text-yellow '}>
        Range {attMin} ~ {attMax}
      </small>
    </div>
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className={leftSide}>
        <h6 className={h4}>RF Board Common Attenuation</h6>
        <CommonSlider
          value={+comValue}
          min={attMin}
          max={attMax}
          step={commonAttStep}
          disabled={boardChPowerAllOff}
          onChange={handleSliderChange}
          onAfterChange={handleSliderAfterChange}
        />
      </div>

      <div className='flex flex-col justify-center h-[46px]'>
        <InputGroupAppendDialog
          {...commonGainInputProps}
          className='w-[130px]'
          // dialog params
          dialogText={commonGainWarningText[commonGainWarning]}
          placement='right'
          overwriteOffset={{ top: 0, left: 0 }}
          overwriteElements={commonGainDialogOverwriteElement}
          // event params
          setFocus={result => setCommonGainFocus(result)}
          warning={commonGainWarning}
          setWarning={result => setCommonGainWarning(result)}
          changeCallback={({ value, isValid }) =>
            dispatch(
              bboard_step_commonAttenuationAfterChange_watcher({
                sn,
                value: value,
                isValid: isValid,
              })
            )
          }
        />

        {commonGainFocus && (
          <small className={small + ' text-light-5  mt-1'}>
            Range {attMin} ~ {attMax}
          </small>
        )}
      </div>
    </div>
  )
}

export default CommonAttenuation
