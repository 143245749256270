import React from 'react'

import Read from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/Read'
import JunctionTemperature from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/JunctionTemperature'
import PowerDetectorOutput from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/PowerDetectorOutput'
import PowerConsumption from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/PowerConsumption'
import ExportOutputData from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/ExportOutputData'

import {
  container,
  h4,
  divider,
} from 'src/containers/operating/device/beamformers/style'

const Current = props => {
  return (
    <div className={container}>
      <h4 className={`${h4} text-white mb-6`}>Data Output</h4>

      <Read />

      <div className={divider + ' my-6'} />

      <JunctionTemperature />

      <div className={divider + ' my-6'} />

      <PowerDetectorOutput />

      <div className={divider + ' my-6'} />

      <PowerConsumption />

      <ExportOutputData />
    </div>
  )
}

Current.propTypes = {}

export default Current
