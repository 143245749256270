import { call, put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     All Power      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepAllPower(payloads) {
  try {
    const { sn, value } = payloads
    let { currentData, currentRfMode, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.step[currentRfMode].power.channelValue =
      currentData.deviceControl.step[currentRfMode].power.channelValue.fill(
        value
      )

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(__socket_API_sender, beamformersApi.STEP_POWER_ALL, {
      sn,
      lookupID,
      value,
    })
  } catch (error) {
    devWarLog('[handler] stepAllPower error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Power     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepSinglePower(payloads) {
  try {
    const { sn, channelIndex, value } = payloads
    let { currentData, currentRfMode, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.step[currentRfMode].power.channelValue[
      channelIndex
    ] = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(__socket_API_sender, beamformersApi.STEP_POWER, {
      sn,
      lookupID,
      channelIndex,
      value,
    })
  } catch (error) {
    devWarLog('[handler] stepSinglePower error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Common Gain     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

function* _commonAttenuationUpdate({ sn, value }) {
  try {
    let { currentData, currentRfMode } = yield call(getCommonArgs, sn)

    currentData.deviceControl.step[currentRfMode].attenuation.comValue = value

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[handler] _commonAttenuationUpdate error:', error)
  }
}

export function* stepCommonAttenuationChange(payloads) {
  try {
    yield call(_commonAttenuationUpdate, payloads)
  } catch (error) {
    devWarLog('[handler] stepCommonAttenuationChange error:', error)
  }
}

export function* stepCommonAttenuationAfterChange(payloads) {
  try {
    const { sn, value, isValid } = payloads

    yield call(_commonAttenuationUpdate, payloads)

    if (isValid) {
      let { lookupID } = yield call(getCommonArgs, sn)

      yield call(__socket_API_sender, beamformersApi.STEP_ATTENUATION_COMMON, {
        sn,
        lookupID,
        value: +value,
      })
    }
  } catch (error) {
    devWarLog('[handler] stepCommonAttenuationAfterChange error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Single Gain     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepSingleAttenuation(payloads) {
  try {
    const { sn, channelIndex, value, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)
    currentData.deviceControl.step[currentRfMode].attenuation.channelValue[
      channelIndex
    ] = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid)
      yield call(__socket_API_sender, beamformersApi.STEP_ATTENUATION, {
        sn,
        lookupID,
        channelIndex,
        value: +value,
      })
  } catch (error) {
    devWarLog('[handler] stepSingleAttenuation error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Single Phase     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* stepSinglePhase(payloads) {
  try {
    const { sn, channelIndex, value, isValid } = payloads

    let { lookupID, currentData, currentRfMode } = yield call(getCommonArgs, sn)

    currentData.deviceControl.step[currentRfMode].phase.channelValue[
      channelIndex
    ] = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid)
      yield call(__socket_API_sender, beamformersApi.STEP_PHASE, {
        sn,
        lookupID,
        channelIndex,
        value: +value,
      })
  } catch (error) {
    devWarLog('[handler] stepSinglePhase error:', error)
  }
}
