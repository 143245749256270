const useScrollIntoView = props => {
  const { ref, block = 'nearest', inline = 'start' } = props
  ref?.scrollIntoView({
    behavior: 'smooth',
    block: block,
    inline: inline,
  })
}

export default useScrollIntoView
