import React from 'react'
import Decimal from 'decimal.js'
import { useDispatch } from 'react-redux'

import FreqInput from 'src/containers/operating/device/freqConverter/general/FreqSettings/FreqInput'
import LO from 'src/containers/operating/device/freqConverter/general/FreqSettings/Lo'
import MergeSvg from 'src/containers/operating/device/freqConverter/general/FreqSettings/MergeSvg'
import HarmonicCheck from 'src/containers/operating/device/freqConverter/general/FreqSettings/HarmonicCheck'
import OFR from 'src/containers/operating/device/freqConverter/general/FreqSettings/OFR'

import Mask from 'src/components/Mask'
import CommonButton from 'src/components/Button/CommonButton'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import {
  h4,
  hDivider,
} from 'src/containers/operating/device/freqConverter/style'

import RFIcon from 'src/assets/svg/unit/RF'
import IFIcon from 'src/assets/svg/unit/IF'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  ud_general_freqUnlockClick_watcher,
  ud_general_freqUnitChange_watcher,
  ud_general_freqRFChange_watcher,
  ud_general_freqIFChange_watcher,
  ud_general_freqLOSelect_watcher,
  ud_general_freqApplyClick_watcher,
  ud_general_freqClearClick_watcher,
  ud_general_freqBandwidthSelect_watcher,
  ud_general_freqBandwidthInputChange_watcher,
} from 'src/redux/actions/freqConverter/generalAction'

import {
  valueConversionFromUnit,
  reverseValueUnitTokHz,
} from 'src/funcs/device/udbox'

import { BiKey } from 'react-icons/bi'

import { unitDisabled } from 'src/constants/freqConverter'
import useGetUIControl from 'src/hooks/useGetUIControl'

const FreqSettings = props => {
  const dispatch = useDispatch()

  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const sn = current?.sn
  const deviceType = current?.deviceType

  const { single: s_uiControl } = useGetUIControl(sn)
  const { isChanged, isRequesting, isSuccess, isDisabled } =
    s_uiControl?.general?.freqSettings

  const { bandwidth, unit, isSupportFreqUnlock } = current.data.settings.freq
  const {
    RF: settingRF,
    IF: settingIF,
    LO: settingLO,
  } = current.data.settings.freq

  const { currentSelection } = settingLO
  const { value: RFValue, userMax: RFUserMax, userMin: RFUserMin } = settingRF
  const { value: IFValue, userMax: IFUserNax, userMin: IFUserMin } = settingIF

  // 轉換基準單位為kHz
  const rfMin = valueConversionFromUnit(RFUserMin, unit)
  const rfMax = valueConversionFromUnit(RFUserMax, unit)

  const ifMin = valueConversionFromUnit(IFUserMin, unit)
  const ifMax = valueConversionFromUnit(IFUserNax, unit)

  // Occupied Frequency Range
  const RFMHz = +valueConversionFromUnit(
    +reverseValueUnitTokHz(RFValue, unit),
    'MHz'
  )
  const bandwidthValueMHz =
    +valueConversionFromUnit(bandwidth.value / 2, 'MHz') || 0

  // 會有小數點，所以這邊的加減要掛 Decimal
  const OFRMinValue = Math.max(
    0,
    new Decimal(RFMHz).minus(bandwidthValueMHz).toNumber()
  )
  const OFRMaxValue = new Decimal(RFMHz).plus(bandwidthValueMHz).toNumber()

  const bandwidthMin = 0

  // bandwidth.value 本身是 kHz, 所以 bandwidthMax 要轉 kHz 才能比較
  const bandwidthMax = reverseValueUnitTokHz(rfMax, unit) * 2

  // processedBandwidthValue input 顯示用, 因為 bandwidth.value 本身是 kHz
  const processedBandwidthValue = valueConversionFromUnit(
    bandwidth.value,
    'MHz'
  )
  const bandwidthList = [50000, 100000, 200000, 400000, 800000].map(e =>
    valueConversionFromUnit(e, 'MHz')
  )

  const bandwidthIsEmptyString = bandwidth.value === ''
  // bandwidth.value 本身是 kHz
  const bandwidthIsOutOfRange =
    +bandwidth.value > bandwidthMax || +bandwidth.value < bandwidthMin

  const isBandwidthInvalid = bandwidthIsEmptyString || bandwidthIsOutOfRange
  const bandwidthSmallColor = isBandwidthInvalid
    ? ' text-red'
    : ' text-white/50'

  const clearDisabledList = [isRequesting, isSuccess, !RFValue && !IFValue]
  const applyDisabledList = [
    isRequesting,
    isSuccess,
    isBandwidthInvalid,
    !currentSelection,
    !isChanged,
  ]

  const isClearDisabled = clearDisabledList.some(e => e === true)
  const isApplyDisabled = applyDisabledList.some(e => e === true)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleUnlockClick = () =>
    dispatch(ud_general_freqUnlockClick_watcher({ sn }))

  const handleBandwidthSelectChange = (event, value) =>
    dispatch(ud_general_freqBandwidthSelect_watcher({ sn, value }))

  const handleBandwidthCustomInputChange = result =>
    dispatch(
      ud_general_freqBandwidthInputChange_watcher({
        sn,
        value: result.formattedValue,
      })
    )

  const handleUnitChange = (event, value) =>
    dispatch(ud_general_freqUnitChange_watcher({ sn, value }))

  const handleInputChange = ({ label, value }) => {
    if (label === 'RF') dispatch(ud_general_freqRFChange_watcher({ sn, value }))
    if (label === 'IF') dispatch(ud_general_freqIFChange_watcher({ sn, value }))
  }

  const handleLOClick = ({ name }) =>
    dispatch(ud_general_freqLOSelect_watcher({ sn, value: name }))

  const handleClearClick = () =>
    dispatch(ud_general_freqClearClick_watcher({ sn }))
  const handleApplyClick = () =>
    dispatch(ud_general_freqApplyClick_watcher({ sn }))

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <MaskHOC isDisabled={isDisabled}>
        <div className='flex justify-between items-center h-[26px]'>
          <h4 className={h4 + ' text-white'}>Frequency Settings</h4>

          {isSupportFreqUnlock && (
            <CommonButton
              icon={<BiKey />}
              type='outlined'
              size='sm'
              onClick={handleUnlockClick}>
              Unlock Frequency
            </CommonButton>
          )}
        </div>

        <div className={unitContainer}>
          <SegmentedButton
            className='sm:w-full md:w-[350px]'
            size={isSM || isMD ? 'md' : 'sm'}
            type='outlined'
            value={unit}
            onChange={handleUnitChange}>
            {['GHz', 'MHz', 'kHz'].map(e => (
              <SegmentedButton.Option
                key={'device control ud unit ' + sn + e}
                name={e}
                disabled={unitDisabled[deviceType].includes(e)}>
                {e}
              </SegmentedButton.Option>
            ))}
          </SegmentedButton>
        </div>

        <div className={freqInputContainer}>
          <FreqInput
            value={RFValue}
            min={rfMin}
            max={rfMax}
            unit={unit}
            icon={<RFIcon />}
            deviceType={deviceType}
            handleChange={result =>
              handleInputChange({ label: 'RF', value: result.formattedValue })
            }
          />

          <FreqInput
            value={IFValue}
            min={ifMin}
            max={ifMax}
            unit={unit}
            icon={<IFIcon />}
            deviceType={deviceType}
            handleChange={result =>
              handleInputChange({ label: 'IF', value: result.formattedValue })
            }
          />

          {!isSM && <MergeSvg />}
        </div>

        <LO handleLOClick={handleLOClick} />

        <div className={hDivider} />

        <div className={bandwidthContainer}>
          <HarmonicCheck
            {...{
              bandwidth: { ...bandwidth, value: processedBandwidthValue },
              bandwidthList,

              isBandwidthInvalid,
              bandwidthIsOutOfRange,

              bandwidthSmallColor,

              handleBandwidthSelectChange,
              handleBandwidthCustomInputChange,
            }}
          />

          <OFR OFRMinValue={OFRMinValue} OFRMaxValue={OFRMaxValue} />
        </div>

        <div className='w-full flex justify-between gap-x-2 mt-1'>
          <CommonButton
            type='outlined'
            className='w-full md:w-[106px] sm:w-[108px]'
            size={isSM ? 'lg' : 'md'}
            disabled={isClearDisabled}
            onClick={handleClearClick}>
            Clear
          </CommonButton>
          <CommonButton
            className='w-full md:w-[106px]'
            size={isSM ? 'lg' : 'md'}
            disabled={isApplyDisabled}
            loading={isRequesting}
            success={isSuccess}
            onClick={handleApplyClick}>
            Apply
          </CommonButton>
        </div>
      </MaskHOC>
    </div>
  )
}

export default FreqSettings

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[FreqSettings-container] 
                  functionBlockBackground 
                  relative 
                  w-full 
                  rounded-md 
                  px-6 pt-4 pb-10 
                  flex flex-col 
                  gap-y-4 
                  `

const unitContainer = `[FreqSettings-unitContainer] w-full flex md:justify-center`
const freqInputContainer = `[FreqSettings-freqInputContainer] relative w-full flex flex-col md:flex-row gap-x-[88px] gap-y-5`
const bandwidthContainer = `flex flex-col md:flex-row md:gap-x-4 gap-y-4`

const MaskHOC = props =>
  props.isDisabled ? (
    <>
      <Mask className='grid place-content-center text-center text-light-1 z-50'>
        Receiving External LO Frequency
        <br />
        Additional setting are currently disabled.
      </Mask>
      {props.children}
    </>
  ) : (
    props.children
  )
