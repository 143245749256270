import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function UpRight({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12.6367 2.44255C12.5249 2.39231 12.4044 2.36471 12.2821 2.36133C12.1597 2.35795 12.038 2.37886 11.9239 2.42286L4.96905 5.09257C4.60913 5.23073 4.372 5.57701 4.37174 5.9635L4.37889 17.2344C4.37898 17.4151 4.43142 17.592 4.52983 17.7437C4.62823 17.8953 4.76838 18.0152 4.93323 18.0887L10.9545 21.7823C11.0663 21.8325 11.1868 21.8601 11.3091 21.8635C11.4314 21.8669 11.5531 21.846 11.6673 21.802L18.6221 18.1323C18.982 17.9941 19.2192 17.6478 19.2194 17.2613L19.2123 5.99045C19.2122 5.80972 19.1597 5.63279 19.0613 5.48114C18.9629 5.32949 18.8228 5.20965 18.6579 5.13618L12.6367 2.44255ZM15.8457 5.92241L11.3502 7.64805L7.73747 6.03187L12.2338 4.3059L15.8457 5.92241ZM6.23631 7.4039L10.3765 9.25604L10.4071 19.2138L6.24258 16.6301L6.23631 7.4039ZM17.3551 16.6166L12.252 19.2159L12.2391 9.30883L17.3492 7.34726L17.3551 16.6166Z'
          fill='white'
        />
      </svg>
    </SVGUniqueID>
  )
}
