import React from 'react'

import UnlockInput from 'src/containers/Modal/Normal/freqConverter/general/freqSetting/UnlockInput'
import UnlockRequesting from 'src/containers/Modal/Normal/freqConverter/general/freqSetting/UnlockRequesting'
import UnlockSuccess from 'src/containers/Modal/Normal/freqConverter/general/freqSetting/UnlockSuccess'

import UDBoxRefSourceCheck from 'src/containers/Modal/Normal/freqConverter/udbox/RefSourceCheck'
import UDBoxToExternalError from 'src/containers/Modal/Normal/freqConverter/udbox/ToExternalError'

import UDModuleTurnOffOutputCheck from 'src/containers/Modal/Normal/freqConverter/udModule/TurnOffOutputCheck'
import UDModuleToExternalCheck from 'src/containers/Modal/Normal/freqConverter/udModule/ToExternalCheck'
import UDModuleToExternalError from 'src/containers/Modal/Normal/freqConverter/udModule/ToExternalError'

import UD0630ToExternalCheck from 'src/containers/Modal/Normal/freqConverter/udbox0630/ToExternalCheck'
import UD0630TurnOffOutputCheck from 'src/containers/Modal/Normal/freqConverter/udbox0630/TurnOffOutputCheck'

import SGToExternalCheck from 'src/containers/Modal/Normal/freqConverter/sg/ToExternalCheck'
import SGTurnOffOutputCheck from 'src/containers/Modal/Normal/freqConverter/sg/TurnOffOutputCheck'

const FreqConverter = modalProps => {
  const { status: modalStatus } = modalProps

  // general
  // general
  // general
  // general
  // general
  if (modalStatus === 'udUnlockInput') return <UnlockInput {...modalProps} />
  if (modalStatus === 'udUnlockRequesting')
    return <UnlockRequesting {...modalProps} />
  if (modalStatus === 'udUnlockSuccess')
    return <UnlockSuccess {...modalProps} />

  // udbox
  // udbox
  // udbox
  // udbox
  // udbox
  if (modalStatus === 'udboxToExternalCheck')
    return <UDBoxRefSourceCheck {...modalProps} />

  if (modalStatus === 'udboxToExternalError')
    return <UDBoxToExternalError {...modalProps} />

  // ud module
  // ud module
  // ud module
  // ud module
  // ud module
  if (modalStatus === 'udmoduleToExternalTurnOffOutputCheck')
    return <UDModuleTurnOffOutputCheck {...modalProps} />

  if (modalStatus === 'udmoduleToExternalCheck')
    return <UDModuleToExternalCheck {...modalProps} />

  if (modalStatus === 'udmoduleToExternalError')
    return <UDModuleToExternalError {...modalProps} />

  // udbox 0630
  // udbox 0630
  // udbox 0630
  // udbox 0630
  // udbox 0630
  if (modalStatus === 'udbox0630ToExternalCheck')
    return <UD0630ToExternalCheck {...modalProps} />

  if (modalStatus === 'ud0630ToExternalTurnOffOutputCheck')
    return <UD0630TurnOffOutputCheck {...modalProps} />

  // sg
  // sg
  // sg
  // sg
  // sg
  if (modalStatus === 'sgToExternalCheck')
    return <SGToExternalCheck {...modalProps} />

  if (modalStatus === 'sgToExternalCheckTurnOffOutputCheck')
    return <SGTurnOffOutputCheck {...modalProps} />

  return <></>
}

export default FreqConverter
