export const sgInitAppendData = sn => ({
  current: {
    ledIndicators: {
      output10M: false,
      lock: true,
    },
  },

  settings: {
    output: {
      isOutputting: true,
      rf: { a: true, b: true },
      frequency: {
        type: 'fixed', // fixed, dynamic
        max: 18000,
        min: 180,
        fixed: 6000,
        dynamic: { start: null, end: null, step: 1000 },
      },
      powerStep: {
        currentSelection: 3,
        lstOptions: new Array(13).fill('').map((_, i) => i),
      },
      outputControls: {
        method: 'continuous', // continuous, sweep
        sweepTimeStep: { currentValue: null, min: 100, max: 60000 }, // currentValue === null 代表用最快的速度跑
        repeatRounds: { currentValue: null, min: 1, max: 10000 }, // currentValue === null 代表無限循環
      },
    },

    device: {
      refSource: 0, // 0: internal, 1: external
      inputRefSource: {
        lstOptions: ['10 MHz', '100 MHz'],
        currentSelection: '10 MHz',
      },
      outputRefSource: false, // bool
    },
  },
})
