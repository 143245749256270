export const udbox0630InitAppendData = sn => ({
  current: {
    freq: {
      RF: '', // int
      IF: '', // int
      LO: '', // int
      isHarmonic: false, //  bool
      isSupportUpdateCurrent: true,
    },
    ledIndicators: {
      status: 0, //  0: 正常(綠), 1: (黃), 2: 錯誤(紅)
      LO: 0, //  0: 正常(綠), 1: (黃), 2: 錯誤(紅)
      ref: 0, //  0: 正常(綠), 1: (黃), 2: 錯誤(紅)
    },
  },

  settings: {
    freq: {
      isSupportFreqUnlock: false,
      bandwidth: { isCustom: false, value: '' }, // int
      unit: 'MHz', // str
      RF: {
        value: null,
        deviceMax: '', // int
        deviceMin: '', // int
        userMax: '', // int
        userMin: '', // int
      },
      IF: {
        value: null,
        deviceMax: '', // int
        deviceMin: '', // int
        userMax: '', // int
        userMin: '', // int
      },
      LO: {
        currentSelection: '', // "LSI" or "HSI"
        deviceMin: '',
        deviceMax: '',
        userMax: '',
        userMin: '',
        LSI: {
          value: '', // int
          disabled: {
            range: true,
            resolution: true,
          },
        },
        HSI: {
          value: '', // int
          disabled: {
            range: true,
            resolution: true,
          },
        },
      },
    },
    device: {
      refSource: 0, // 0: internal, 1: external
      inputRefSource: {
        lstOptions: ['10 MHz', '100 MHz', 'LO'],
        currentSelection: '10 MHz',
      },
      outputRefSource: {
        isEnable: false,
        lstOptions: ['10 MHz', '100 MHz'],
        currentSelection: '10 MHz',
      },
      isOutputLoEnable: false,
    },
  },
})
