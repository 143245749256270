import React, { useState, useRef, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { mergeRefs } from 'react-merge-refs'

import InputCore from 'src/components/Input/Core'

import { BiShow, BiHide } from 'react-icons/bi'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <PasswordInput
//   {...{
//     id,
//     size, // 'sm', 'md', 'lg'
//     icon,
//     value, // string
//     disabled, // bool
//     danger, // bool
//     placeholder, // string
//     icon, // node
//     className,
//     inputClassName,
//     onChange, // result => console.log(result.formattedValue)
//   }}
// />

const PasswordInput = forwardRef((props, ref) => {
  const {
    id = '',
    size = 'md',
    icon,
    value,
    disabled = false,
    // warning = false,
    danger = false,
    placeholder,
    autoFocus,

    className = '',
    inputClassName = '',

    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
  } = props

  const inputRef = useRef()

  const mergeRef = useMemo(
    () => (ref ? mergeRefs([inputRef, ref]) : inputRef),
    [inputRef, ref]
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [isActive, setIsActive] = useState(false)
  const [readPassword, setReadPassword] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClickForContainer = () => inputRef?.current?.focus()
  const handleEyesClick = () => setReadPassword(prev => !prev)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                        ${className} 
                        ${containerCommon}
                        ${containerSize[size]}
                        ${theme(isActive, danger, disabled)}
                        ${containerCursor(disabled)}
                        `

  const iconClass = icon && `w-6 h-6 mr-1 flex items-center justify-center`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass} onClick={handleClickForContainer}>
      <div className={iconClass}>{icon}</div>

      <InputCore
        {...{
          id,
          value,
          disabled,
          onChange,
          placeholder,
          autoFocus,
        }}
        inputType={readPassword ? 'text' : 'password'}
        ref={mergeRef}
        className={`${inputClassName} w-full outline-none`}
        onFocus={() => {
          setIsActive(true)
          onFocus()
        }}
        onBlur={() => {
          setIsActive(false)
          onBlur()
        }}
      />

      <div className={eyesIconClass} onClick={handleEyesClick}>
        {readPassword ? <BiShow /> : <BiHide />}
      </div>
    </div>
  )
})
export default PasswordInput

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = `
                        relative 
                        flex items-center 
                        border-1 border-solid
                        rounded-[2px]
                        duration-500 
                        px-2
                        `
const containerSize = {
  sm: 'h-6 text-sm leading-3',
  md: 'h-8 text-sm leading-3 py-1',
  lg: 'h-10 text-base leading-4 py-2',
}
const containerCursor = disabled =>
  disabled ? 'cursor-not-allowed' : 'cursor-default'

const theme = (isActive, danger, disabled) => {
  if (disabled) return `bg-dark-4 border-transparent text-white/50`
  if (danger) return `bg-dark-1 border-red text-white`
  if (isActive) return `bg-dark-1 border-teal text-white`
  return `bg-dark-1 border-dark-3 hover:border-dark-5 text-white`
}

const eyesIconClass = `
                    text-white/50 hover:text-white 
                    flex items-center justify-center shrink-0
                    duration-500 cursor-pointer 
                    w-6 h-6 opacity-1
                    `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
PasswordInput.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  icon: PropTypes.node,
  inputType: PropTypes.oneOf(['password']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,

  danger: PropTypes.bool,
  placeholder: PropTypes.string,

  className: PropTypes.string,
  inputClassName: PropTypes.string,

  onChange: PropTypes.func,
}
