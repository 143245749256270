export const _successRes =
  ({ isDemoMode }) =>
  data => ({
    code: 0,
    codeName: '',
    status: 'OK',
    message: 'Successful',
    data,
    errorInfo: null,
    module: '',
    subModule: '',
    isDemoMode,
  })

export const _failedRes =
  ({ isDemoMode, codeName }) =>
  (data = null) => ({
    code: 34,
    codeName: codeName || 'ERROR_SEND_CMD',
    status: 'ERROR',
    message: 'Wrong ACK, please USB connection and your device is powered on.',
    // message:
    //   '啊啊啊啊啊啊靠腰啊～～～爆掉拉～～～～可以下班拉～～～～～～～～ＲＲＲＲＲＲＲＲＲＲ',
    data,
    errorInfo: {
      sn: null,
      deviceType: 0,
      versionInfo: {
        hardware: 'v1.1.1.1',
        caliTable: '',
        firmware: 'v1.1.1.1',
      },
    },
    module: 'fw',
    subModule: 'TLK-Controllers',
    isDemoMode,
  })

export const _resPkg = error => response => {
  // demo mode 那邊會改成 true
  const _notDemoSuccessRes = _successRes({ isDemoMode: false })
  const _notDemoFailedRes = _failedRes({ isDemoMode: false })

  if (!error.isEnable) return _notDemoSuccessRes(response)
  if (error.isEnable) return _notDemoFailedRes(response)
}

export const _notError = { isEnable: false, message: '' }
