import React from 'react'

import CaliReminder from 'src/containers/Modal/Normal/home/CaliReminder'
import LeaveDeviceInfoCheck from 'src/containers/Modal/Normal/home/LeaveDeviceInfoCheck'
import ApplyDeviceInfoCheck from 'src/containers/Modal/Normal/home/ApplyDeviceInfoCheck'

const Home = props => {
  const { status: modalStatus } = props

  if (modalStatus === 'settings') return <></>

  if (modalStatus === 'reminderNotify') return <CaliReminder />

  if (modalStatus === 'firmwareNotify') return <></>

  if (modalStatus === 'leaveDeviceInfoCheck') return <LeaveDeviceInfoCheck />

  if (modalStatus === 'applyDeviceInfoCheck') return <ApplyDeviceInfoCheck />
  return <></>
}

export default Home
