import React from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6, small } from 'src/containers/operating/device/freqConverter/style'
import CommonInput from 'src/components/Input/CommonInput'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { isFixedFreqInvalid } from 'src/funcs/device/sg'

const FixedFrequency = props => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const { fixed, min, max, type } = current.data?.settings?.output.frequency
  const isOutputting = current.data.settings.output.isOutputting
  const isUseDynamicFreq = type === 'dynamic'

  const isDisabled = isUseDynamicFreq || isOutputting

  const isInvalid = isFixedFreqInvalid({
    value: fixed,
    min,
    max,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleChange = result => {
    let newData = R.clone(current.data)
    newData.settings.output.frequency.fixed = String(result.formattedValue)
    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const smallColor = isInvalid ? ' text-red' : ' text-white/50'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div>
      <h6 className={h6 + ' text-light-1'}>Frequency (MHz)</h6>
      <CommonInput
        size={isXL || is2XL ? 'sm' : 'md'}
        className='my-1'
        inputType='number'
        value={fixed}
        danger={isInvalid}
        disabled={isDisabled}
        onChange={handleChange}
      />
      <small className={small + smallColor + ' mt-1'}>
        Range {min} ~ {max} MHz
      </small>
    </div>
  )
}

export default FixedFrequency
