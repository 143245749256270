export const powerDetectorInitAppendData = sn => ({
  deviceControl: {
    actionPanel: {
      currentFreq: 28,
      readMethod: 'single',
      updateRate: 500,
      average: 1,
      currentCaliID: 'default', // 選擇的 caliData 的 key (預設吃 default)
      caliData: {},
    },
    dashboard: {
      currentPowerValue: 0,
      measureData: [],
      bufferData: [],
    },
  },
})
