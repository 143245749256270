import { createSlice, current } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { chartLengthMax } from 'src/constants/powerDetector'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        All         -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 全部 powerDetector 共用控項，也就是動一台 powerDetector, 全部 powerDetector 都受影響
const powerDetectorUIControlForAllInitData = {
  // customCalibrationListSortRule
  // 客製化 cali config 清單的排序，動一次全 powerDetector 都會共用
  // 且這個值不能隨著 modal 消失而被改變
  // 'Latest to oldest' || 'Oldest to latest'
  customCalibrationListSortRule: 'Latest to oldest',
}
export const powerDetectorUIControlForAllSlice = createSlice({
  name: 'powerDetectorUIControlForAll',
  initialState: powerDetectorUIControlForAllInitData,
  reducers: {
    setCustomCalibrationSortRule(state, action) {
      const { value } = action.payload
      return {
        ...current(state),
        customCalibrationListSortRule: value,
      }
    },
  },
})

export const { setCustomCalibrationSortRule } =
  powerDetectorUIControlForAllSlice.actions

export const powerDetectorUIControlForAll =
  powerDetectorUIControlForAllSlice.reducer

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 個別 powerDetector 控項
// 內容物：
// { [sn]: defaultItemData }
// defaultItemData 如下：
const defaultItemData = {
  isPowerReading: false,
  chartData: new Array(chartLengthMax).fill('').map((e, i) => ({
    point: 0,
    power: null,
  })),
  measureDataLength: 0,
}

export const powerDetectorUIControlForSingleSlice = createSlice({
  name: 'powerDetectorUIControlForSingle',
  initialState: {},
  reducers: {
    addUiControl(state, action) {
      const { sn } = action.payload
      return { ...current(state), [sn]: defaultItemData }
    },

    setIsPowerReading(state, action) {
      const { sn, value } = action.payload
      state[sn].isPowerReading = value

      return state
    },

    addSinglePowerPoint(state, action) {
      const { sn, power, measureDataLength } = action.payload
      const oldChartData = current(state)[sn].chartData

      const _getPoint = index =>
        measureDataLength - index <= 0 ? 0 : measureDataLength - index

      const _mapIndexed = R.addIndex(R.map)

      const newChartData = R.pipe(
        R.take(chartLengthMax - 1),
        R.insert(0, { point: null, power }),
        _mapIndexed((e, i) => ({ ...e, point: _getPoint(i) }))
      )(oldChartData)

      state[sn].chartData = newChartData

      return state
    },

    updateMeasureDataLength(state, action) {
      const { sn, measureDataLength } = action.payload
      state[sn].measureDataLength = measureDataLength

      return state
    },

    clearChartData(state, action) {
      const { sn } = action.payload
      state[sn].chartData = new Array(chartLengthMax).fill('').map((e, i) => ({
        point: 0,
        power: null,
      }))
      return state
    },
  },
})

export const {
  addUiControl,
  setIsPowerReading,
  addSinglePowerPoint,
  updateMeasureDataLength,
  clearChartData,
} = powerDetectorUIControlForSingleSlice.actions

export const powerDetectorUIControlForSingle =
  powerDetectorUIControlForSingleSlice.reducer
