import { call, put, select, take } from 'redux-saga/effects'
import axios from 'axios'

import {
  getLocalMidInfo as getLocalMidInfoAPI,
  checkLocalMidAWSConnection as checkLocalMidAWSConnectionAPI,
  // getCompanyFwInfo as getCompanyFwInfoAPI,
} from 'src/redux/sagas/services/restfulAPI/localMiddlewareApi'
import { modalActions } from 'src/redux/slices/modal'

import { downloadNewMidModal_backDoorClick_Watcher } from 'src/redux/actions/facility/init'

import ServiceVersion from 'src/funcs/versionClass'

import __restful_API_sender from 'src/redux/sagas/services/restfulTools/__restful_API_sender'

import { devLog, devWarLog } from 'src/funcs/tools'
import { isDeveloping, isProduction, isQa } from 'src/funcs/getEnv'
import { History } from 'src/containers/NavigateSetter'
import { setMidVersion } from 'src/redux/slices/uiControl/facility/versions'

function* _midVersionVerify(midInfoRes) {
  const mid_beTlkMiddlewareVersion = yield midInfoRes?.data?.data?.[
    'BE-TLK-MIDDLEWARE'
  ]

  const mid_fwCommApiVersion = yield midInfoRes?.data?.data?.['FW-COMM-API']

  const env_Version = yield select(state => state.versions.serviceVersion)
  const env_midBeVersion = env_Version.filter(
    e => e.key === 'BE-TLK-MIDDLEWARE'
  )[0]?.fe
  const env_midFwVersion = env_Version.filter(e => e.key === 'FW-COMM-API')[0]
    ?.fe

  yield put(
    setMidVersion([
      {
        key: 'BE-TLK-MIDDLEWARE',
        mid: mid_beTlkMiddlewareVersion,
      },
      {
        key: 'FW-COMM-API',
        mid: mid_fwCommApiVersion,
      },
    ])
  )

  if (mid_beTlkMiddlewareVersion || mid_fwCommApiVersion) {
    const logVersion = [
      new ServiceVersion(
        'BE-TLK-MIDDLEWARE',
        env_midBeVersion,
        undefined,
        mid_beTlkMiddlewareVersion
      ),
      new ServiceVersion(
        'FW-COMM-API',
        env_midFwVersion,
        undefined,
        mid_fwCommApiVersion
      ),
    ]
    console.table(logVersion)
  }

  const isMidBeVersionTooOld = mid_beTlkMiddlewareVersion !== env_midBeVersion
  const isMidFwVersionTooOld = mid_fwCommApiVersion !== env_midFwVersion

  const midNeedUpdate = isMidBeVersionTooOld || isMidFwVersionTooOld

  if (midNeedUpdate) {
    devLog('更新一下你那冰河時期下載的 WEB-TLK Middleware吧')

    // 正式版 跟 QA 版如果版本過舊就要跳錯誤處理
    if (isProduction || isQa) throw Error('middleware 版本不符或過舊')
  }

  return midNeedUpdate
}

// 首次 initDeviceControlMode 跑這
export function* midConnection() {
  try {
    // 跟 middleware 要它的版本資訊
    const midInfoRes = yield call(__restful_API_sender, {
      api: getLocalMidInfoAPI,
    })
    if (midInfoRes?.data?.code !== 0) throw Error('Middleware 啟動異常')

    const midNeedUpdate = yield call(_midVersionVerify, midInfoRes)

    // 請 middleware 確認它與 AWS 的連線狀態
    const checkAWSRes = yield call(__restful_API_sender, {
      api: checkLocalMidAWSConnectionAPI,
    })
    if (checkAWSRes?.data?.code !== 0) throw Error('Aws 連線異常')

    if (isDeveloping || !midNeedUpdate) {
      const lookupID = yield midInfoRes?.data?.data?.lookupID
      if (lookupID === undefined) throw Error('本地身份驗證異常')

      //! phase1 只支援 local, 所以 lookupID 絕對 只有一個，這邊先套，之後寫 remote 在重構
      axios.defaults.params = yield { lookupID }

      return yield { lookupID }
    }
  } catch (error) {
    // 沒連到 local middleware 或有任何問題的後續處理在這
    yield History.push('/download/middleware')

    // 關閉 init 用的 大T 動畫
    yield put(modalActions.clearNormalModal())

    devWarLog('[handler] midConnection:', error)

    if (String(error) === 'Error: middleware 版本不符或過舊') {
      yield put(modalActions.showModalOfDownloadNewMid())

      //! ------ backdoor ------ !
      yield take(downloadNewMidModal_backDoorClick_Watcher.type)
      const midInfoRes = yield call(__restful_API_sender, {
        api: getLocalMidInfoAPI,
      })
      axios.defaults.params = yield {
        lookupID: midInfoRes?.data?.data?.lookupID,
      }
      return { lookupID: midInfoRes?.data?.data?.lookupID }
      //! ------ backdoor ------ !
    }
  }
}

// 在下載 Middleware 頁面點擊 connect 跑這
export function* reMidConnection() {
  try {
    const midInfoRes = yield call(__restful_API_sender, {
      api: getLocalMidInfoAPI,
    })
    if (midInfoRes?.data?.code !== 0) throw Error()

    const midNeedUpdate = yield call(_midVersionVerify, midInfoRes)

    const checkAWSRes = yield call(__restful_API_sender, {
      api: checkLocalMidAWSConnectionAPI,
    })
    if (checkAWSRes?.data?.code !== 0) throw Error('Aws 連線異常')

    if (isDeveloping || !midNeedUpdate) {
      const lookupID = yield midInfoRes?.data?.data?.lookupID
      if (lookupID === undefined) throw Error('本地身份驗證異常')

      //! phase1 只支援 local, 所以 lookupID 絕對 只有一個，這邊先套，之後寫 remote 在重構
      axios.defaults.params = yield { lookupID }

      return yield { lookupID }
    }
  } catch (error) {
    if (String(error) === 'Error: Aws 連線異常')
      yield put(modalActions.showModalOfMidConnectAwsFailed())
    else if (String(error) === 'Error: 本地身份驗證異常')
      yield put(modalActions.showModalOfMidGetInfoFailed())
    else if (String(error) === 'Error: middleware 版本不符或過舊')
      yield put(modalActions.showModalOfDownloadNewMid())
    else yield put(modalActions.showModalOfMidNotRunning())

    devWarLog('[handler] reMidConnection:', error)
  }
}
