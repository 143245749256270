import { call, put } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* clovercellEvbOperatingStatusUpdateData(response) {
  try {
    yield console.log('clovercellEvbOperatingStatusUpdateData')
    yield console.log(response)

    const [sn, { measureDataLength, temperature, pdt, powerConsumption }] =
      Object.entries(response?.data)[0]

    let newCurrentData = yield call(getCurrentData, sn)

    newCurrentData.current.measureDataLength = yield measureDataLength
    newCurrentData.current.temperature = yield temperature
    newCurrentData.current.pdt = yield pdt
    newCurrentData.current.powerConsumption = yield powerConsumption

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
  } catch (error) {
    devWarLog(error)
  }
}
