import React from 'react'
import PropTypes from 'prop-types'

import { AiOutlineWarning } from 'react-icons/ai'

// TODO: 顯示內容待定

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.log('%c⧭', 'color: #cc7033', error)
  return (
    <section className={container}>
      <AiOutlineWarning className={errorIcon} />
    </section>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
}

const containerFlex = ' flex flex-col items-center justify-center '
const containerBgBorder =
  'bg-red/10 border-red/50 border-4 border-solid rounded-md '
const container = `w-full h-hull ${containerBgBorder} ${containerFlex}`

const errorIcon = 'text-red text-8xl mr-2 mt-1 mb-2'

export default ErrorFallback
