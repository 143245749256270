import * as R from 'ramda'

import { demoDeviceList, mockServerDeviceList } from '../configs/deviceList.mjs'
import {
  infoContentList,
  supportConnectionInfo,
  supportVersionInfo,
} from '../configs/spec.mjs'
import { deviceList } from '../configs/deviceList.mjs'

const infoContent = (name, deviceType, value) =>
  infoContentList[deviceType]?.includes(name) ? { [name]: value } : null

const allDeviceClass = {
  // func
  _getAllData() {
    try {
      return R.clone(this.data)
    } catch (error) {
      console.warn(error)
    }
  },

  _getCurrentData(sn) {
    try {
      return R.clone(this.data[sn])
    } catch (error) {
      console.warn(error)
    }
  },

  _getDeviceType(sn) {
    try {
      return R.clone(this._getCurrentData(sn)?.info.deviceType)
    } catch (error) {
      console.warn(error)
    }
  },

  _isIncludesCategory(deviceType, tag) {
    try {
      return Object.values(deviceList)
        .find(e => +e.deviceType === +deviceType)
        .category.includes(tag)
    } catch (error) {
      console.warn(error)
      return null
    }
  },

  _isBeamformers(sn) {
    const deviceType = this._getDeviceType(sn)

    return this._isIncludesCategory(deviceType, 'beamformers')
  },
  _isFreqConverter(sn) {
    const deviceType = this._getDeviceType(sn)
    return this._isIncludesCategory(deviceType, 'freqConverter')
  },

  _updateSingleDeviceData(sn, newData) {
    try {
      let newThisData = R.clone(this.data)
      newThisData[sn] = newData
      this.data = newThisData
    } catch (error) {
      console.warn(error)
    }
  },

  _scan(deviceList) {
    try {
      const snBody = (index, length) =>
        '0'.repeat(length - String(index).length) + index

      this.data = deviceList.reduce(
        (
          prev,
          { snHead, deviceType, snBodyLength, snFoot, hardwareVersion },
          curIndex
        ) => ({
          ...prev,

          [`${snHead}${snBody(curIndex, snBodyLength)}${
            snFoot ? `-${snFoot}` : ''
          }`]: {
            info: {
              lookupID: 'test ' + deviceType + curIndex,
              deviceType: deviceType,
              initialization: 'unfinished', // unfinished(string) / finished(string) / failed(string)
              isSupportChangeIP: true,
              connectionInfo: supportConnectionInfo.includes(deviceType)
                ? {
                    ...infoContent('ip', deviceType, '192.168.100.' + curIndex),
                    ...infoContent(
                      'mac',
                      deviceType,
                      `01:00:5e:de:mo:${curIndex}`
                    ),
                    ...infoContent(
                      'staticIP',
                      deviceType,
                      '192.168.100.' + curIndex
                    ),
                  }
                : null,
              versionInfo: supportVersionInfo.includes(deviceType)
                ? {
                    ...infoContent('hardware', deviceType, hardwareVersion),
                    ...infoContent('caliTable', deviceType, 'v' + curIndex),
                    ...infoContent('firmware', deviceType, 'v' + curIndex),
                  }
                : null,
              notification: {
                isFirmwareUpdate: false,
                isRecaliReminder: false,
              },
            },
          },
        }),
        {}
      )

      return this.data
    } catch (error) {
      console.warn(error)
    }
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------      General       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  _demoScan() {
    try {
      return this._scan(demoDeviceList)
    } catch (error) {
      console.warn(error)
    }
  },

  _mockServerScan() {
    try {
      return this._scan(mockServerDeviceList)
    } catch (error) {
      console.warn(error)
    }
  },

  _setIps({ data }) {
    try {
      let newAllData = this._getAllData()

      Object.entries(data).forEach(([sn, value]) => {
        newAllData[sn].info.connectionInfo.staticIP = value
      })

      this.data = newAllData
      return this.data
    } catch (error) {
      console.warn(error)
    }
  },

  // 因為多個 server 都有 initDevice
  // 所以直接放在 allDevice 做分發
  // 實際上真的後端是會拆開各自接收處理的
  s_initDevice({ payloads }) {
    const { sn } = payloads
    if (this._isBeamformers(sn)) return this._beamformersInit(sn)
    if (this._isFreqConverter(sn)) return this._udInit(sn)
    if (this._isPd(sn)) return this._pdInit(sn)
  },
}

export default allDeviceClass
