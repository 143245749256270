import { fork, take, call, put, select, delay } from 'redux-saga/effects'

import { scanDevices as handleScanDevices } from 'src/redux/sagas/handlers/event/facility/scanDevices'
import {
  midConnection as handleMidConnection,
  reMidConnection as handleReGetMidConnection,
} from 'src/redux/sagas/handlers/event/facility/midConnection'

import rootSocket from 'src/redux/sagas/services/socketTools/rootSocket'

import { setScanDevicesData } from 'src/redux/slices/deviceData'
import {
  setDeviceControlInitIsTrue,
  setDemoInitIsTrue,
  setDemoInitIsFalse,
  setTemporaryDeviceData,
  setCurrentPageModeIsDeviceControl,
  setCurrentPageModeIsDemo,
  clearCurrentPageMode,
} from 'src/redux/slices/uiControl/facility/init'
import { modalActions } from 'src/redux/slices/modal'

import { tempIpUpdateFromDeviceData } from 'src/redux/slices/uiControl/facility/home'
import { lottie_scanAnimateModal_complete_watcher } from 'src/redux/actions/facility/home'
import { welcomeDemoModal_okClick_watcher } from 'src/redux/actions/facility/init'
import { socketDisconnect } from 'src/redux/actions/socket'
import { loginCheck as handleLoginCheck } from 'src/redux/sagas/handlers/event/facility/member'

import { setLookupID } from 'src/redux/slices/uiControl/facility/user'

import { History } from 'src/containers/NavigateSetter'

import { devWarLog } from 'src/funcs/tools'

function* _kickOffDeviceControlInit({ lookupID }) {
  try {
    // 每次 socket 連線時都先斷線，這樣絕對不會重複連線
    yield put(socketDisconnect())
    yield fork(rootSocket, lookupID)

    yield call(handleScanDevices)
    yield put(setDeviceControlInitIsTrue())
    yield put(setCurrentPageModeIsDeviceControl())

    yield History.push('/home/device-control')

    yield put(modalActions.showModalOfTMYTEKFullAnimationComplete())
    yield take(lottie_scanAnimateModal_complete_watcher.type)
    yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog('[handler] _kickOffDeviceControlInit error:', error)
  }
}

export function* initDeviceControlMode() {
  try {
    const isLogin = yield call(handleLoginCheck, initDeviceControlMode)

    if (isLogin) {
      yield put(modalActions.showModalOfTMYTEKFullAnimationLoading())
      const midData = yield call(handleMidConnection)
      const middlewareConnectSuccess = midData?.lookupID !== undefined

      if (middlewareConnectSuccess) {
        yield put(setLookupID({ lookupID: midData?.lookupID }))

        yield fork(_kickOffDeviceControlInit, { lookupID: midData?.lookupID })
      }
    }
  } catch (error) {
    devWarLog('[handler] initDeviceControlMode error:', error)
  }
}

export function* reConnectMiddleware() {
  try {
    yield put(modalActions.showModalOfTMYTEKFullAnimationLoading())
    const midData = yield call(handleReGetMidConnection)
    const middlewareConnectSuccess = midData?.lookupID !== undefined

    if (middlewareConnectSuccess)
      yield fork(_kickOffDeviceControlInit, { lookupID: midData?.lookupID })
  } catch (error) {
    devWarLog('[handler] reConnectMiddleware error:', error)
  }
}

export function* moveToPageHomeAgainForDeviceControl() {
  try {
    // init 過 device control mode 就不重新 scan 直接把暫存的撈出來用
    let { temporaryDeviceData } = yield select(state => state.facilityInit)

    yield put(setScanDevicesData(temporaryDeviceData))
    yield put(tempIpUpdateFromDeviceData(temporaryDeviceData))
    yield put(setCurrentPageModeIsDeviceControl())
    yield History.push('/home/device-control')
  } catch (error) {
    devWarLog('[handler] moveToPageHomeAgainForDeviceControl error:', error)
  }
}

export function* moveToPageHomeForDemoMode() {
  try {
    yield History.push('/home/demo')
  } catch (error) {
    devWarLog('[handler] moveToPageHomeForDemoMode error:', error)
  }
}

export function* initDemoMode() {
  try {
    yield put(modalActions.showModalOfWelcomeDemo())

    yield take(welcomeDemoModal_okClick_watcher.type)
    yield put(modalActions.showModalOfTMYTEKFullAnimationLoading())

    // 設 delay 200 讓動畫跑一下
    yield delay(200)
    yield call(handleScanDevices)
    yield put(setDemoInitIsTrue())
    yield put(setCurrentPageModeIsDemo())

    yield put(modalActions.showModalOfTMYTEKFullAnimationComplete())
    yield take(lottie_scanAnimateModal_complete_watcher.type)
    yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog('[handler] initDemoMode error:', error)
  }
}

export function* redirectToChooseMode() {
  try {
    const currentPageMode = yield select(
      state => state.facilityInit.currentPageMode
    )

    if (currentPageMode === 'deviceControl') {
      // 先把當下檔案存在暫存裡面，下次回 device control mode撈出來用
      let deviceData = yield select(state => state.deviceData)
      yield put(setTemporaryDeviceData(deviceData))
    }

    if (currentPageMode === 'demo') yield put(setDemoInitIsFalse())

    yield put(clearCurrentPageMode())
    yield History.push('/')
  } catch (error) {
    devWarLog('[handler] moveToPageChooseMode error:', error)
  }
}
