import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import { udbox_deviceSettings_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import Stepper from 'src/components/Stepper'

import { h4, h6 } from 'src/containers/Modal/style'
import useGetUIControl from 'src/hooks/useGetUIControl'

const RefSourceCheck = ({ sn }) => {
  const dispatch = useDispatch()

  const { single: s_uiControl } = useGetUIControl(sn)

  const { isRequesting, isSuccess } = s_uiControl?.ud5g?.refSource

  const handleUseExternalClick = () =>
    dispatch(
      udbox_deviceSettings_watcher({ sn, label: 'SOURCE_100M', value: 1 })
    )

  return (
    <Modal>
      <Modal.Title>Use External Reference Source</Modal.Title>

      <Stepper name='Use External Reference Source' className='mt-8'>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Connect to an external 100-MHz ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Ensure the source is connected.
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Turn on the external 100-MHz ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Make sure the source has been transmitted.
          </p>
        </Stepper.Step>
      </Stepper>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          disabled={isRequesting || isSuccess}
          onClick={() => dispatch(modalActions.clearNormalModal())}>
          Cancel
        </CommonButton>
        <CommonButton
          size='lg'
          type='primary'
          loading={isRequesting}
          success={isSuccess}
          onClick={handleUseExternalClick}>
          Use External
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default RefSourceCheck
