export const iconBox = `
                        w-12 h-12 px-2
                        flex items-center justify-center
                        text-4xl text-white hover:text-teal
                        cursor-pointer 
                        hover:drop-shadow-[0_0_3px_rgba(40,202,206,1)]
                        duration-500
                        `

export const textBox = 'flex flex-col mb-4 last:mb-0'

export const title = 'text-white'

const smText = 'text-sm font-normal leading-4'
export const subtitle = `${smText} text-light-1`
export const content = `${smText} text-light-4`
