import { History } from 'src/containers/NavigateSetter'
import { _successRes } from 'src/server/configs/responseFormatter.mjs'

export function* getIsDemoMode() {
  return yield History.pathname.indexOf('/demo') >= 0
}

export const _demoSuccessRes = response => ({
  data: _successRes({ isDemoMode: true })(response),
})
