import React from 'react'

import PropTypes from 'prop-types'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'
import LightForDeviceStatus from 'src/components/Light/LightForDeviceStatus'

const DeviceIcon = ({ sn, deviceType, hardwareVersion }) => {
  return (
    <>
      {getDeviceInfo[deviceType]?.icon(sn, hardwareVersion)}

      <LightForDeviceStatus sn={sn} className={lightClass} />
    </>
  )
}

const lightClass = `
    absolute 
    right-[2px] 
    bottom-[2px] 
    `

DeviceIcon.propTypes = {
  sn: PropTypes.string,
  deviceType: PropTypes.number,
}

export default DeviceIcon
