import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Body = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='258'
        height='86'
        viewBox='0 0 258 86'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M86.2747 1.60444L94.431 4.05132C95.0655 4.24166 95.5 4.82565 95.5 5.48806V78.6812C95.5 79.2812 95.1424 79.8236 94.5909 80.0599L82.4047 85.2826C82.1115 85.4082 81.7859 85.4371 81.4752 85.3652L1.66141 66.8717C0.981439 66.7142 0.5 66.1084 0.5 65.4104V14.7403C0.5 13.992 1.05156 13.3581 1.79271 13.2547L85.6364 1.55557C85.8502 1.52574 86.068 1.54241 86.2747 1.60444Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M80.5002 10.486C80.5002 5.06243 85.0332 0.739515 90.4507 0.99672L246.053 8.38425C252.374 8.68431 257.264 14.0337 256.998 20.3555L254.582 77.6847C254.549 78.462 253.928 79.0852 253.15 79.12L234.548 79.9521C233.856 79.9831 233.278 79.4302 233.278 78.7374C233.278 77.805 232.503 77.0592 231.572 77.0946L116.413 81.4672C115.317 81.5088 114.449 82.4099 114.449 83.5074C114.449 84.0711 114.001 84.5326 113.437 84.5486L82.0427 85.4417C81.1979 85.4657 80.5 84.7874 80.5 83.9423L80.5002 10.486Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M91.3681 3.91156L245.018 11.4576C250.247 11.7144 254.283 16.1531 254.042 21.3829L251.59 74.681C251.554 75.4597 250.927 76.0812 250.148 76.111L85.0573 82.421C84.207 82.4535 83.5 81.773 83.5 80.9221L83.5002 11.4025C83.5002 7.11592 87.0866 3.70129 91.3681 3.91156Z'
          fill='#121212'
          stroke='white'
        />
        <mask id='path-4-inside-1_6596_13187' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M120 66C120 67.6569 118.657 69 117 69C115.343 69 114 67.6569 114 66C114 64.3431 115.343 63 117 63C118.657 63 120 64.3431 120 66ZM132 66C132 67.6569 130.657 69 129 69C127.343 69 126 67.6569 126 66C126 64.3431 127.343 63 129 63C130.657 63 132 64.3431 132 66ZM141 69C142.657 69 144 67.6569 144 66C144 64.3431 142.657 63 141 63C139.343 63 138 64.3431 138 66C138 67.6569 139.343 69 141 69Z'
          />
        </mask>

        <path
          d='M139.908 27.5373C139.715 29.738 141.343 31.6781 143.544 31.8707L148.87 32.3366C150.88 32.5125 152.652 31.0254 152.828 29.0151C153.037 26.6239 151.269 24.5159 148.877 24.3067L144.241 23.9011C142.041 23.7086 140.1 25.3365 139.908 27.5373Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M145.842 28.55C145.673 30.4757 147.098 32.1733 149.023 32.3417C150.949 32.5102 152.647 31.0857 152.815 29.1601L152.902 28.1639C153.071 26.2383 151.646 24.5407 149.721 24.3722C147.795 24.2037 146.097 25.6282 145.929 27.5538L145.842 28.55Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M158.905 27.8683C158.712 30.0691 160.34 32.0092 162.541 32.2017L167.867 32.6677C169.877 32.8435 171.649 31.3565 171.825 29.3462C172.035 26.955 170.266 24.847 167.874 24.6378L163.238 24.2322C161.038 24.0396 159.097 25.6676 158.905 27.8683Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M164.839 28.8811C164.67 30.8067 166.095 32.5043 168.021 32.6728C169.946 32.8413 171.644 31.4168 171.812 29.4912L171.899 28.495C172.068 26.5693 170.643 24.8717 168.718 24.7032C166.792 24.5348 165.095 25.9592 164.926 27.8849L164.839 28.8811Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M177.902 28.2003C177.709 30.4011 179.337 32.3412 181.538 32.5338L186.864 32.9997C188.874 33.1756 190.647 31.6885 190.822 29.6782C191.032 27.287 189.263 25.179 186.872 24.9698L182.235 24.5642C180.035 24.3717 178.095 25.9996 177.902 28.2003Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M183.836 29.2131C183.668 31.1387 185.092 32.8364 187.018 33.0048C188.943 33.1733 190.641 31.7488 190.809 29.8232L190.896 28.827C191.065 26.9014 189.641 25.2037 187.715 25.0353C185.789 24.8668 184.092 26.2913 183.923 28.2169L183.836 29.2131Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M196.899 28.5314C196.707 30.7321 198.335 32.6723 200.535 32.8648L205.861 33.3308C207.871 33.5066 209.644 32.0196 209.819 30.0093C210.029 27.6181 208.26 25.5101 205.869 25.3009L201.232 24.8952C199.032 24.7027 197.092 26.3307 196.899 28.5314Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M202.833 29.5442C202.665 31.4698 204.089 33.1674 206.015 33.3359C207.94 33.5043 209.638 32.0799 209.806 30.1542L209.894 29.158C210.062 27.2324 208.638 25.5348 206.712 25.3663C204.786 25.1978 203.089 26.6223 202.92 28.548L202.833 29.5442Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M47.5 44C47.5 48.0859 46.7261 51.7606 45.4967 54.3952C44.2483 57.0703 42.6233 58.5 41 58.5C39.3767 58.5 37.7517 57.0703 36.5033 54.3952C35.2739 51.7606 34.5 48.0859 34.5 44C34.5 39.9141 35.2739 36.2394 36.5033 33.6048C37.7517 30.9297 39.3767 29.5 41 29.5C42.6233 29.5 44.2483 30.9297 45.4967 33.6048C46.7261 36.2394 47.5 39.9141 47.5 44Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M25.5 44C25.5 47.5343 24.8378 50.7097 23.7887 52.9829C22.7199 55.2984 21.3443 56.5 20 56.5C18.6557 56.5 17.2801 55.2984 16.2113 52.9829C15.1622 50.7097 14.5 47.5343 14.5 44C14.5 40.4657 15.1622 37.2903 16.2113 35.0171C17.2801 32.7016 18.6557 31.5 20 31.5C21.3443 31.5 22.7199 32.7016 23.7887 35.0171C24.8378 37.2903 25.5 40.4657 25.5 44Z'
          fill='#434343'
          stroke='white'
        />
      </svg>
    </SVGUniqueID>
  )
}
export default Body
