import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'
import {
  connect as handleConnect,
  disconnect as handleDisconnect,
  // connectError as handleConnectError,
} from 'src/redux/sagas/handlers/event/socket/localMiddleware'

import {
  dashboardRenderPoint as handleDashboardRenderPoint,
  clearPowerHistory as handleClearPowerHistory,
} from 'src/redux/sagas/handlers/response/powerDetector/dashboard'
import { clovercellEvbOperatingStatusUpdateData as handleClovercellEvbOperatingStatusUpdateData } from 'src/redux/sagas/handlers/response/beamformers/cloverCellEvb/current'

import { sgOutputSettingsUpdateRepeatRounds as handle_sgOutputSettingsUpdateRepeatRounds } from 'src/redux/sagas/handlers/response/freqConverter/sg/outputSettings'

const localMiddlewareApi = {
  // ********************* PD ********************* //
  // ********************* PD ********************* //
  // ********************* PD ********************* //
  // ********************* PD ********************* //
  // ********************* PD ********************* //
  INIT_PD_LOCAL_TREAD: {
    emit: {
      server: 'localMiddleware',
      eventName: 'initPDLocalThread',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'initPDLocalThread/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_SET_CURRENT_FREQ: {
    emit: {
      server: 'localMiddleware',
      eventName: 'actionPanel/set/currentFreq',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/set/currentFreq/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_SET_READ_METHOD: {
    emit: {
      server: 'localMiddleware',
      eventName: 'actionPanel/set/readMethod',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/set/readMethod/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_SET_UPDATE_RATE: {
    emit: {
      server: 'localMiddleware',
      eventName: 'actionPanel/set/updateRate',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/set/updateRate/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_ACTIVATE_READ_CONTROL: {
    emit: {
      server: 'localMiddleware',
      eventName: 'actionPanel/activate/readControl',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/activate/readControl/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_SET_AVERAGE: {
    emit: {
      server: 'localMiddleware',
      eventName: 'actionPanel/set/average',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/set/average/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  DASHBOARD_RENDER_POINT: {
    emit: {
      server: 'localMiddleware',
      eventName: '',
      // // demo:initDeviceDemo,
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'dashboard/render/point/response',
      handler: handleDashboardRenderPoint,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  DASHBOARD_CLEAR_POWER_HISTORY: {
    emit: {
      server: 'localMiddleware',
      eventName: 'dashboard/clear/power/history',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'dashboard/clear/power/history/response',
      handler: handleClearPowerHistory,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  // ********************* EVB ********************* //
  // ********************* EVB ********************* //
  // ********************* EVB ********************* //
  // ********************* EVB ********************* //
  // ********************* EVB ********************* //
  INIT_CLOVERCELL_EVB_LOCAL_TREAD: {
    emit: {
      server: 'localMiddleware',
      eventName: 'initCloverCellEVBLocalThread',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'initCloverCellEVBLocalThread/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_OPERATING_STATUS_ACTIVATE_READ_CONTROL: {
    emit: {
      server: 'localMiddleware',
      eventName: 'clovercell/evb/operatingStatus/activate/readControl',
      //   code: 0,
      // }),
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/operatingStatus/activate/readControl/response',
      handler: a => {
        console.log(a)
      },
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_OPERATING_STATUS_SET_READ_METHOD: {
    emit: {
      server: 'localMiddleware',
      eventName: 'clovercell/evb/operatingStatus/set/readMethod',
      //   code: 0,
      // }),
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/operatingStatus/set/readMethod/response',
      handler: a => {
        console.log(a)
      },
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_OPERATING_STATUS_UPDATE_DATA_RESPONSE: {
    emit: {
      server: 'localMiddleware',
      eventName: '',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/operatingStatus/update/data/response',
      handler: handleClovercellEvbOperatingStatusUpdateData,
      // errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_EVB_OPERATING_STATUS_CLEAR_DATA: {
    emit: {
      server: 'localMiddleware',
      eventName: 'clovercell/evb/operatingStatus/clear/data',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/evb/operatingStatus/clear/data/response',
      handler: a => {
        console.log(a)
      },
      // errorHandler: handleDeviceErrorResponse,
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------         SG         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  SG_OUTPUT_SETTINGS_ACTIVATE_OUTPUT: {
    emit: {
      server: 'localMiddleware',
      eventName: 'sg/outputSettings/activate/output',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'sg/outputSettings/activate/output/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },
  SG_OUTPUT_SETTINGS_UPDATE_REPEAT_ROUNDS: {
    emit: {
      server: 'localMiddleware',
      eventName: '',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'sg/outputSettings/update/repeatRounds/response',
      handler: handle_sgOutputSettingsUpdateRepeatRounds,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  // socket listener
  SOCKET_CONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'connect',
      handler: handleConnect,
    },
  },
  SOCKET_DISCONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'disconnect',
      handler: handleDisconnect,
    },
  },
  // SOCKET_CONNECT_ERROR: {
  //   on: {
  //     type: 'SOCKET_EVENT',
  //     eventName: 'connect_error',
  //     handler: handleConnectError,
  //   },
  // },
}

export default localMiddlewareApi
