import React, { forwardRef, useRef, useEffect } from 'react'

import {
  useFBX,
  shaderMaterial,
  Box,
  Text,
  GradientTexture,
  Cone,
  SpotLight,
} from '@react-three/drei'
import { useSpring, animated } from '@react-spring/three'

import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import glsl from 'babel-plugin-glsl/macro'

import beamFbx from 'src/assets/fbx/beam.fbx'
import {
  cartesianXyzToViewXyz,
  degreeToRadian,
  getCartesianBySpherical,
} from '../tools'

const BeamMaterial = shaderMaterial(
  // uniform
  {
    vColor1: new THREE.Color('lightgreen'),
    vColor2: new THREE.Color('red'),
    vBeamMin: { x: 0, y: 0 },
    vBeamMax: { x: 14, y: 14 },
  },
  // vertex shader
  glsl`
      uniform vec3 vBeamMin;
      uniform vec3 vBeamMax;
      varying vec2 vUv;
  
      void main() {
        vUv = uv;
  
        vUv.y = (position.y - vBeamMin.y) / (vBeamMax.y - vBeamMin.y);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  // fragment shader
  glsl`
      uniform vec3 vColor1;
      uniform vec3 vColor2;
  
      varying vec2 vUv;
  
      void main() {
        gl_FragColor = vec4(mix(vColor1 ,vColor2, vUv.y), 1.0);
      }
    `
)

extend({ BeamMaterial })

const ArrowBeam = forwardRef((props, ref) => {
  const beam_mesh_ref = useRef(null)
  const fbx = useFBX(beamFbx)
  const beamModel = fbx.children[0].children[0]

  const { lookAt, text, theta, phi, distance, isActive, onClick } = props

  useEffect(() => {
    if (beam_mesh_ref.current && lookAt === 'inside') {
      beam_mesh_ref.current.rotation.set(0, 0, Math.PI)
      beam_mesh_ref.current.position.set(0, distance, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookAt, distance])

  const [{ scale }] = useSpring(
    () => ({
      from: {
        scale: isActive ? 0.9 : 0.9,
      },
      to: {
        scale: isActive ? 1 : 0.9,
      },
      loop: true,
      config: {
        duration: 1000,
      },
    }),
    [isActive]
  )

  return (
    <>
      <group ref={ref} onClick={onClick}>
        {/* <animated.mesh ref={beam_mesh_ref} {...beamModel} scale={scale}>
          {isActive ? (
            <beamMaterial wireframe={true} />
          ) : (
            <meshPhongMaterial
              wireframe
              transparent
              opacity={0.1}
              color='#ffffff'
            />
          )}
        </animated.mesh> */}

        <Line {...{ theta, phi, distance, lookAt, isActive }} />
      </group>
    </>
  )
})

export default ArrowBeam

const Line = React.memo(({ theta, phi, distance, lookAt, isActive }) => {
  const _beamLength = isActive ? 10 : 10
  const _startOffset = 1
  // const _shortPercent = 0.2
  const _lineWidth = 0.3

  // line
  // line
  // line
  // _startOffset 是不要從 0 出發，會跟座標重疊導致看不清楚
  const _lineLength =
    lookAt === 'inside' ? distance - _startOffset : _beamLength

  // _startOffset 是不要從 0 出發，會跟座標重疊導致看不清楚
  // x -1 是為了反過來射往中間
  const i_start_point = (_lineLength / 2) * -1
  const o_start_point = _lineLength / 2 - _startOffset

  //_shortPercent 是動畫要縮短的比例
  // const i_short_position = [0, i_start_point * _shortPercent, 0]
  const i_long_position = [0, i_start_point, 0]

  // const o_short_position = [0, o_start_point * _shortPercent, 0]
  const o_long_position = [0, o_start_point, 0]

  // const _short = lookAt === 'inside' ? i_short_position : o_short_position
  const _long = lookAt === 'inside' ? i_long_position : o_long_position

  // group
  // group
  // group
  const groupPosition =
    lookAt === 'inside' ? [0, _lineLength + 1, 0] : [0, _startOffset + 1, 0]
  const groupYRotation = [degreeToRadian(180), 0, 0, degreeToRadian(180)]
  const groupYRotationIndex =
    phi <= 90 ? 0 : phi <= 180 ? 1 : phi <= 270 ? 2 : 3
  const groupRotation = [0, groupYRotation[groupYRotationIndex], 0]

  const arrowPosition =
    lookAt === 'inside' ? [0, -_lineLength, 0] : [0, _lineLength - 1, 0]
  const arrowRotation =
    lookAt === 'inside'
      ? [degreeToRadian(180), 0, 0]
      : [0, degreeToRadian(70), 0]

  const activeColor =
    lookAt === 'inside' ? ['#ed7f10', 'white'] : ['white', '#ed7f10']
  const color = isActive ? activeColor : ['#777777', '#777777']

  const textPosition =
    lookAt === 'inside' ? [0, 0 + 1.5, 0] : [0, _lineLength + 1.5, 0]
  const textColor = isActive ? '#ed7f10' : '#777777'

  // spring
  // const [{ opacity, lineScale, linePosition }] = useSpring(
  //   () => ({
  //     from: {
  //       opacity: 0.7,
  //       lineScale: 0.8,
  //       linePosition: _short,
  //     },
  //     to: {
  //       opacity: 1,
  //       lineScale: 1,
  //       linePosition: _long,
  //     },
  //     loop: true,
  //     config: {
  //       duration: 2000,
  //     },
  //   }),
  //   [isActive]
  // )

  return (
    <group
      rotation={groupRotation}
      position={groupPosition}
      scale={isActive ? 1 : 0.9}>
      {/* Line */}
      <Box
        scale={1}
        position={_long}
        args={[_lineWidth, _lineLength, _lineWidth]}>
        <meshStandardMaterial transparent opacity={0.9}>
          <GradientTexture
            stops={[0, 1]} // As many stops as you want
            colors={color} // Colors need to match the number of stops
            size={32} // Size is optional, default = 1024
          />
        </meshStandardMaterial>
      </Box>

      <Cone
        args={[1, 1, 3, 15, false, 0, 6.3]}
        position={arrowPosition}
        rotation={arrowRotation}>
        <meshStandardMaterial color='white' />
      </Cone>

      <SuggestText
        {...{
          text: lookAt === 'inside' ? 'Incident' : 'Reflector',
          position: textPosition,
          color: textColor,
          isActive,
        }}
      />
    </group>
  )
})

const SuggestText = props => {
  const { text, position, color, isActive } = props
  const rotation = [degreeToRadian(180), 0, 0]

  return (
    <Text
      color={color}
      scale={isActive ? 1 : 0.7}
      position={position}
      rotation={rotation}>
      {text}
    </Text>
  )
}
