import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'

import Step from 'src/components/Stepper/Step'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Stepper
//    name = ''
//    className = 'py-4'
//    gap = 'gap-y-4'
//>
//  <Stepper.Step></Stepper.Step>
//  <Stepper.Step></Stepper.Step>
// </Stepper>

const Stepper = props => {
  const { name, className, gap = 'gap-y-4' } = props

  const container = `${className} ${gap} relative flex flex-col `

  return (
    <div className={container}>
      {Children.map(props.children, (child, index) =>
        cloneElement(child, {
          key: 'step' + name + index,
          index: index,
          totalCount: Children.count(props.children),
        })
      )}

      <div className='absolute inset-y-8 left-4 w-[1px] bg-white' />
    </div>
  )
}

Stepper.Step = Step

Stepper.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

export default Stepper
