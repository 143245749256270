import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { initDemoMode_watcher } from 'src/redux/actions/facility/init'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useSetHeader from 'src/hooks/useSetHeader'

import DeviceInventory from 'src/containers/operating/DeviceInventory'

const Operating = () => {
  const dispatch = useDispatch()
  const { isLG, isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const { isDeviceControlModeInitialized, isDemoInitialized } = useSelector(
    state => state.facilityInit
  )

  useSetHeader({ key: 'operating', props: '' })

  const showDeviceInventory = isLG || isXL || is2XL

  const { isDemoMode, isDeviceControlMode } = useGetCurrentMode()

  useEffect(() => {
    if (isDemoMode && !isDemoInitialized) dispatch(initDemoMode_watcher())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isDeviceControlMode && !isDeviceControlModeInitialized && (
        <Navigate to='/' replace={true} />
      )}

      <section className={section}>
        {current.sn && (
          <div className={operatingArea}>
            {showDeviceInventory && (
              <div className={menuContainer}>
                <DeviceInventory />
              </div>
            )}

            <main className={mainClass}>
              <Outlet />
            </main>
          </div>
        )}
      </section>
    </>
  )
}

export default Operating

// sm:pl-2 是為了補手機被 scrollbar 往左推造成左右不對稱
const section = `[Operating-section] w-full flex flex-col gap-y-8 `

const operatingArea = `[Operating-operatingArea] 
                        relative z-0 
                        sm:w-[calc(100vw-32px)] 
                        md:w-[632px] 
                        lg:w-[832px] 
                        xl:w-[1240px] 
                        2xl:w-[1328px] 
                        flex 
                        flex-col lg:flex-row 
                        justify-center lg:justify-between 
                        items-center lg:items-start 
                        mx-auto 
                        `

const mainClass = `[Operating-mainClass] 
                    lg:ml-[196px] 
                    2xl:ml-[264px] 
                    w-full 
                    pt-[40px] lg:pt-0
                    max-w-[632px] xl:max-w-[1064px] 
                    `

const menuContainer = `[Menu-bigSizeContainer] fixed top-[88px] h-[80%] `
