import React from 'react'

import Modal from 'src/components/Popup/Modal'
import CommonInput from 'src/components/Input/CommonInput'

const Description = ({
  value = '',
  placeholder = '',
  disabled = false,
  danger = false,
  onChange = () => {},
}) => {
  return (
    <div className='w-full'>
      <Modal.FormLabel>Beam Description</Modal.FormLabel>
      <CommonInput {...{ value, placeholder, disabled, danger, onChange }} />
      <small className='text-sm text-white/50 mt-2'>
        Beam Description Optional Add description for easy identification{' '}
        {value.length} / 50
      </small>
    </div>
  )
}

export default Description
