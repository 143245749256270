import React from 'react'
import * as R from 'ramda'
import Decimal from 'decimal.js'

import ChannelModule from 'src/components/composite/beamformers/ChannelModule'

import {
  convertDataOfCHConfigToCHComponent,
  convertDataOfCHComponentToCHConfig,
} from 'src/funcs/device/bbox'

import { inputAccessibleExceptionList } from 'src/components/Input/func'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const Channel = props => {
  const { sn, rfMode, channel_config, handleChannelChange } = props

  const { current } = useGetCurrentDeviceData(sn)
  const currentDeviceData = current.data
  const boardData = convertDataOfCHConfigToCHComponent({
    rfMode,
    currentDeviceData,
    configData: channel_config,
  })

  const { channelCount } = currentDeviceData.deviceControl.channel

  const handleUpdateConfigData = componentData =>
    handleChannelChange(convertDataOfCHComponentToCHConfig({ componentData }))

  const handleAllPowerClick = value =>
    handleUpdateConfigData(
      boardData.map(bE => ({
        ...bE,
        lstChannelData: bE.lstChannelData.map(cE => ({
          ...cE,
          power: value,
        })),
      }))
    )

  const handleCommonGainSliderChange = ({ boardIndex, value }) =>
    handleUpdateConfigData(
      R.assocPath([boardIndex, 'commonCurrentGain'], value)(boardData)
    )

  const handleCommonGainChange = ({ boardIndex, value }) =>
    handleUpdateConfigData(
      R.assocPath([boardIndex, 'commonCurrentGain'], value)(boardData)
    )

  const handleSinglePowerClick = ({ boardIndex, channelIndex, value }) =>
    handleUpdateConfigData(
      R.assocPath(
        [boardIndex, 'lstChannelData', channelIndex, 'power'],
        value
      )(boardData)
    )
  const handleSingleGainChange = ({
    boardIndex,
    channelIndex,
    value: totalGain,
  }) => {
    const commonGain = R.view(R.lensPath([boardIndex, 'commonCurrentGain']))(
      boardData
    )

    // 處理 element gain 系列 (elementGain 在 config 格式時會使用)
    let processedElementGain = new Decimal(+totalGain)
      .minus(+commonGain)
      .toNumber()
    // 這邊結尾要保留小數點, 使用者輸入小數點才會正常
    if (totalGain[totalGain.length - 1] === '.')
      processedElementGain =
        new Decimal(+totalGain).minus(+commonGain).toNumber() + '.'
    // 輸入過程中也需要保留例外狀況, 不然無法順利輸入某些值 (以下情況的結果在運算過程中會不見)
    if (inputAccessibleExceptionList.includes(totalGain))
      processedElementGain = totalGain

    const newBoardData = R.pipe(
      R.assocPath(
        [boardIndex, 'lstChannelData', channelIndex, 'totalGain'],
        totalGain
      ),

      // 為了讓使用者可以單獨輸入 '-' 這邊就直接回傳 '-'
      R.assocPath(
        [boardIndex, 'lstChannelData', channelIndex, 'elementGain'],
        processedElementGain
      )
    )(boardData)

    handleUpdateConfigData(newBoardData)
  }

  const handleSinglePhaseChange = ({ boardIndex, channelIndex, value }) =>
    handleUpdateConfigData(
      R.assocPath(
        [boardIndex, 'lstChannelData', channelIndex, 'phase'],
        value
      )(boardData)
    )

  return (
    <ChannelModule
      {...{ sn, boardData, channelCount }}
      {...{
        handleAllPowerClick,
        handleCommonGainSliderChange,
        handleCommonGainChange,
        handleSinglePowerClick,
        handleSingleGainChange,
        handleSinglePhaseChange,
      }}
    />
  )
}

export default Channel
