import { createAction } from '@reduxjs/toolkit'

export const socketDisconnect = createAction('socket/disconnect')

export const facilityThrottle = createAction('facilityAPI/throttleEmit')
export const facilityNormal = createAction('facilityAPI/normalEmit')
export const facilityEmit = createAction('facilityAPI/emit')
export const facilityEmitCallback = createAction('facilityAPI/callback')

export const localMiddlewareThrottle = createAction(
  'localMiddlewareAPI/throttleEmit'
)
export const localMiddlewareNormal = createAction(
  'localMiddlewareAPI/normalEmit'
)
export const localMiddlewareEmit = createAction('localMiddlewareAPI/emit')
export const localMiddlewareEmitCallback = createAction(
  'localMiddlewareAPI/callback'
)

export const beamformersThrottle = createAction('beamformersAPI/throttleEmit')
export const beamformersNormal = createAction('beamformersAPI/normalEmit')
export const beamformersEmit = createAction('beamformersAPI/emit')
export const beamformersEmitCallback = createAction('beamformersAPI/callback')

export const freqConverterThrottle = createAction(
  'freqConverterAPI/throttleEmit'
)
export const freqConverterNormal = createAction('freqConverterAPI/normalEmit')
export const freqConverterEmit = createAction('freqConverterAPI/emit')
export const freqConverterEmitCallback = createAction(
  'freqConverterAPI/callback'
)

export const powerDetectorThrottle = createAction(
  'powerDetectorAPI/throttleEmit'
)
export const powerDetectorNormal = createAction('powerDetectorAPI/normalEmit')
export const powerDetectorEmit = createAction('powerDetectorAPI/emit')
export const powerDetectorEmitCallback = createAction(
  'powerDetectorAPI/callback'
)
