import React from 'react'

import CommonButton from 'src/components/Button/CommonButton'
import CommonSelect from 'src/components/Select/CommonSelect'
import Checkbox from 'src/components/Button/Checkbox'

import { BiSortDown, BiSortUp } from 'react-icons/bi'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const FunctionBar = props => {
  const {
    rfMode,
    isSelectMode,
    selectLists,
    userDefinedOnlyTableData,
    numberOfConfigs,
    sort,
    handleSelectClearClick,
    handleSortChange,
    handleSelectChange,
    handleIsSelectModeChange,
  } = props

  return (
    <div className='flex items-center justify-between px-1'>
      {isSelectMode && (
        <LeftForSelectOn
          {...{
            userDefinedOnlyTableData,
            rfMode,
            selectLists,
            handleSelectChange,
          }}
        />
      )}
      {!isSelectMode && (
        <LeftForSelectOff {...{ userDefinedOnlyTableData, numberOfConfigs }} />
      )}

      {isSelectMode && (
        <RightForSelectOn
          {...{
            rfMode,
            selectLists,
            handleSelectClearClick,
            handleIsSelectModeChange,
          }}
        />
      )}
      {!isSelectMode && (
        <RightForSelectOff
          {...{ sort, handleSortChange, handleIsSelectModeChange }}
        />
      )}
    </div>
  )
}

FunctionBar.propTypes = {}

export default FunctionBar

const sortItem = `flex items-center gap-x-2`

const LeftForSelectOn = ({
  rfMode,
  selectLists,
  userDefinedOnlyTableData,
  handleSelectChange,
}) => {
  const selectList = selectLists[rfMode]

  // FIXME: 改寫成「乙浪」
  const allSelectIsChecked =
    selectList.length &&
    selectList.length === userDefinedOnlyTableData.length &&
    'checked'

  const allSelectIsIndeterminate =
    !allSelectIsChecked && selectList.length !== 0 && 'indeterminate'

  const allSelectIsUncheck = selectList.length === 0 && 'uncheck'

  const handleCheckboxClick = () => {
    if (allSelectIsIndeterminate || allSelectIsUncheck)
      handleSelectChange({
        newSelectLists: {
          ...selectLists,
          [rfMode]: userDefinedOnlyTableData.map(e => e.beamID),
        },
      })

    if (allSelectIsChecked)
      handleSelectChange({
        newSelectLists: {
          ...selectLists,
          [rfMode]: [],
        },
      })
  }

  return (
    <div
      className='group flex items-center gap-x-3 text-base text-light-1 cursor-pointer pl-1 select-none'
      onClick={handleCheckboxClick}>
      <Checkbox
        disabled={!userDefinedOnlyTableData.length}
        status={
          // oneOf(['checked', 'uncheck', 'indeterminate'])
          allSelectIsChecked || allSelectIsIndeterminate || allSelectIsUncheck
        }
      />
      Select {selectList.length} item
    </div>
  )
}
const LeftForSelectOff = props => {
  const { userDefinedOnlyTableData, numberOfConfigs } = props
  return (
    <div className='text-base text-light-1'>
      Beam ({userDefinedOnlyTableData.length} / {numberOfConfigs})
    </div>
  )
}

const RightForSelectOn = props => {
  const {
    rfMode,
    selectLists,
    handleSelectClearClick,
    handleIsSelectModeChange,
  } = props

  return (
    <div className='flex gap-x-4'>
      <CommonButton
        type='primary'
        danger
        size='sm'
        disabled={selectLists[rfMode].length === 0}
        onClick={handleSelectClearClick}>
        Clear
      </CommonButton>
      <CommonButton
        type='outlined'
        size='sm'
        onClick={() => handleIsSelectModeChange(false)}>
        Done
      </CommonButton>
    </div>
  )
}
const RightForSelectOff = props => {
  const { sort, handleSortChange, handleIsSelectModeChange } = props
  const { current } = useGetCurrentDeviceData()
  const controlMethod = current.data.beamConfigEditor.controlMethod

  return (
    <div className='flex gap-x-4'>
      <CommonSelect
        className='w-[156px]'
        value={sort}
        size='sm'
        disabled={controlMethod === 'SPI'}
        selectClassName={`z-10 `}
        currentChildren={
          <div className={sortItem}>
            {sort === 'asc' ? <Ascending /> : <Descending />}
          </div>
        }
        onChange={handleSortChange}>
        <CommonSelect.Option name='asc'>
          <div className={sortItem}>
            <Ascending />
          </div>
        </CommonSelect.Option>
        <CommonSelect.Option name='desc'>
          <div className={sortItem}>
            <Descending />
          </div>
        </CommonSelect.Option>
      </CommonSelect>

      <CommonButton
        type='outlined'
        size='sm'
        disabled={controlMethod === 'SPI'}
        onClick={() => handleIsSelectModeChange(true)}>
        Select
      </CommonButton>
    </div>
  )
}

const Ascending = () => (
  <>
    <BiSortDown />
    Ascending
  </>
)

const Descending = () => (
  <>
    <BiSortUp />
    Descending
  </>
)
