import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'

import NoData from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/NoData'
import Footer from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/Footer'
import Main from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/Main'

import Modal from 'src/components/Popup/Modal'
import Tooltip from 'src/components/Popup/Tooltip'
import IconButton from 'src/components/Button/IconButton'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { modalActions } from 'src/redux/slices/modal'

import { descriptionLengthMax } from 'src/constants/beamformers'
import { createConfigInitData } from 'src/funcs/device/bbox'

import { bbox_deviceDataCurrentConfig_update_watcher } from 'src/redux/actions/beamformers/bboxAction'

import { BiChevronLeftCircle, BiChevronRightCircle } from 'react-icons/bi'

const EditBeam = modalProps => {
  const dispatch = useDispatch()
  const { sn, rfMode, beamID: currentBeamID, isChanged } = modalProps
  const { current } = useGetCurrentDeviceData(sn)
  const { beamConfigEditor } = current.data

  const r_tableData = beamConfigEditor.currentConfig.tableData[rfMode]

  const r_numberOfConfigs = beamConfigEditor.currentConfig.numberOfConfigs

  const [editBeamData, setEditBeamData] = useState(r_tableData)

  const currentBeamConfigData = editBeamData.find(
    e => e?.beamID === currentBeamID
  )

  const [didMountAnimate, setDidMountAnimate] = useState('')

  const descriptionIsInvalid =
    currentBeamConfigData?.beam_description.length > descriptionLengthMax

  useEffect(() => {
    setDidMountAnimate('brightness-[0.95] scale-[0.99]')

    setTimeout(() => setDidMountAnimate(''), 200)
  }, [currentBeamID])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const setIsChanged = value =>
    dispatch(modalActions.setIsChangeFromEditBeam({ isChanged: value }))

  const handleCreateClick = ({ type }) => {
    const initData = createConfigInitData({
      beamID: currentBeamID,
      type,
      rfMode,
      currentDeviceData: current.data,
    })

    setEditBeamData(prev => R.insert(currentBeamID - 1, initData)(prev))
    setIsChanged(true)
  }

  const handleClearClick = () => {
    setEditBeamData(prev => R.reject(e => e.beamID === currentBeamID)(prev))
    setIsChanged(true)
  }

  const handleCloseClick = () => {
    if (isChanged)
      dispatch(modalActions.showModalOfBBoxBeamConfigDiscardUnsavedChanges())
    if (!isChanged) dispatch(modalActions.clearNormalModal())
  }

  const handleSaveClick = () => {
    const newCurrentConfig = R.clone(
      current.data.beamConfigEditor.currentConfig
    )

    newCurrentConfig.tableData[rfMode] = editBeamData

    dispatch(modalActions.clearNormalModal())
    dispatch(
      bbox_deviceDataCurrentConfig_update_watcher({
        sn,
        currentConfig: newCurrentConfig,
      })
    )
  }

  const handleArrowClick = type =>
    dispatch(
      modalActions.setBeamIDFromEditBeam({
        beamID: type === 'left' ? currentBeamID - 1 : currentBeamID + 1,
      })
    )

  const handleDataChange = ({ newCurrentBeamConfigData }) => {
    setEditBeamData(prev =>
      prev.map(e => (e.beamID === currentBeamID ? newCurrentBeamConfigData : e))
    )
    setIsChanged(true)
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <Modal
      className={`${didMountAnimate} max-w-[800px] w-full duration-200 select-none`}
      style={{ padding: 0 }}>
      <div className='pt-10 pb-4 shadow-[0px_2px_8px_0px_rgba(0,_0,_0,_0.25)]'>
        <Modal.Title>Edit Beam</Modal.Title>
        <Modal.Description>ID - {currentBeamID}</Modal.Description>
      </div>

      <div className='flex items-center justify-between px-6'>
        <Tooltip title='Previous beam' placement='right'>
          <IconButton
            className='w-8 h-8'
            type='standard'
            disabled={currentBeamID === 1}
            size='lg'
            onClick={() => handleArrowClick('left')}>
            <BiChevronLeftCircle />
          </IconButton>
        </Tooltip>

        {!currentBeamConfigData && <NoData {...{ handleCreateClick }} />}

        {currentBeamConfigData && (
          <Main
            {...{
              sn,
              rfMode,
              currentBeamConfigData,
              handleClearClick,
              handleDataChange,
            }}
          />
        )}

        <Tooltip title='Next beam' placement='left'>
          <IconButton
            className='w-8 h-8'
            type='standard'
            disabled={currentBeamID === r_numberOfConfigs}
            size='lg'
            onClick={() => handleArrowClick('right')}>
            {<BiChevronRightCircle />}
          </IconButton>
        </Tooltip>
      </div>

      <Footer
        isSaveDisabled={!isChanged || descriptionIsInvalid}
        {...{ handleCloseClick, handleSaveClick }}
      />
    </Modal>
  )
}

export default EditBeam
