import React from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import {
  vDivider,
  h6,
} from 'src/containers/operating/device/freqConverter/style'
import CommonSwitch from 'src/components/Switch/CommonSwitch'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

const RfPortControl = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const rfA = current.data?.settings?.output.rf.a
  const rfB = current.data?.settings?.output.rf.b
  const isOutputting = current.data.settings.output.isOutputting

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = type => {
    let newData = R.clone(current.data)
    newData.settings.output.rf[type] = !newData.settings.output.rf[type]
    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div>
      <h6 className={h6 + ' text-light-1 mb-2'}>RF Port Control</h6>
      <div className='flex justify-between items-center'>
        <div className='w-full flex justify-between'>
          <h6 className={h6 + ' text-light-4'}>RF A</h6>
          <CommonSwitch
            type='text'
            onText='ON'
            offText='OFF'
            size='sm'
            on={rfA}
            disabled={isOutputting}
            onClick={() => handleClick('a')}
          />
        </div>

        <div className={vDivider + ' mx-2'} />

        <div className='w-full flex justify-between'>
          <h6 className={h6 + ' text-light-4'}>RF B</h6>
          <CommonSwitch
            type='text'
            onText='ON'
            offText='OFF'
            size='sm'
            on={rfB}
            disabled={isOutputting}
            onClick={() => handleClick('b')}
          />
        </div>
      </div>
    </div>
  )
}

export default RfPortControl
