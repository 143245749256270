import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'

import {
  chooseMode_deviceControlClick_watcher,
  chooseMode_demoModeClick_watcher,
} from 'src/redux/actions/facility/init'

import useSetHeader from 'src/hooks/useSetHeader'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'

import { BiGridAlt, BiSlideshow } from 'react-icons/bi'

import coverImg from 'src/assets/img/cover.png'

// 維護通知在 notification 裡面的 data 結構
//   {
//        "category": "Maintenance",
//        "title": "Notification",
//        "message": "",
//        "start_time": {
//            "date": "2020-07-15",
//            "time": "23:00:00",
//        },
//        "end_time": {
//            "date": "2020-07-16",
//            "time": "00:00:00",
//        }
//   }

const ChooseMode = props => {
  const dispatch = useDispatch()
  const notification = useSelector(state => state.notification)
  const maintenance = notification?.find(e => e.category === 'Maintenance')

  const helmet = useGetHelmet({ pageName: 'Choose Mode' })

  const { isDesktop } = useGetScreenSize()

  const TLKWEBversion = useSelector(
    state => state.versions.serviceVersion?.filter(e => e.key === 'TLKWEB')[0]
  )

  useSetHeader({ key: 'choose_mode', props: '' })

  const handleDeviceControlClick = () =>
    dispatch(chooseMode_deviceControlClick_watcher())

  const handleDemoModeClick = () => dispatch(chooseMode_demoModeClick_watcher())

  return (
    <section className={mainOutsideContainer}>
      {helmet}

      <main className={mainContainer}>
        {maintenance && <MaintenanceComponent data={maintenance} />}

        <div className={main}>
          <div className='w-[350px] shrink-0'>
            <img src={coverImg} alt='TMXLAB-KIT Cover' />
          </div>
          <div className={info}>
            <h3 className={h3}>Welcome to TMXLAB KIT</h3>

            {isDesktop && (
              <CommonButton
                type='primary'
                size='lg'
                className='w-full mb-4'
                icon={<BiGridAlt />}
                onClick={handleDeviceControlClick}>
                Device Control
              </CommonButton>
            )}

            <CommonButton
              type='general'
              size='lg'
              className='w-full'
              icon={<BiSlideshow />}
              onClick={handleDemoModeClick}>
              Demo Mode
            </CommonButton>
          </div>{' '}
        </div>
      </main>

      <footer className={copyright}>
        COPYRIGHT © TMY Technology Inc. All rights reserved.
        {TLKWEBversion?.fe && `Version: ${TLKWEBversion?.fe}`}
      </footer>
    </section>
  )
}

ChooseMode.propTypes = {}

export default ChooseMode

const copyright =
  'fixed bottom-0 inset-x-0 pb-[60px] -z-20 text-light/25 text-center select-none'

const mainOutsideContainer = `[ChooseMode-mainOutsideContainer] 
                            absolute inset-0 m-auto w-full  
                            pr-4 md:pl-4 sm:pl-6 
                            flex items-center justify-center 
                            `

const mainContainer = `[ChooseMode-mainContainer] 
                        w-full 
                        max-w-[800px] 
                        px-0 py-6 md:px-[60px] xl:px-10 
                        bg-black/50 backdrop-blur-sm shadow-sm 
                        rounded-[6px] `

const notificationClass = `w-[320px] md:w-[400px] xl:w-full
                          flex flex-col gap-y-2 
                          text-center pb-6 mx-auto
                          `

const divide = `w-[300px] md:w-[480px] 
                border-1 border-dashed border-dark-4
                mx-auto my-2
                `

const main = `[ChooseMode-main] 
              flex flex-col xl:flex-row 
              items-center 
              gap-x-6 gap-y-0
              `

const info = `[ChooseMode-info] w-full max-w-[336px] px-6 py-4 `

const h3 = `[ChooseMode-h3] text-lg font-bold leading-5 text-light-4 text-center mb-8 select-none`

const MaintenanceComponent = ({ data }) => {
  const { date: startDate, time: startTime } = data?.start_time
  const { date: endDate, time: endTime } = data?.end_time

  const userStartTime = moment
    .parseZone(`${startDate}T${startTime}+08:00`)
    .local()
    .format('YYYY-MM-DD HH:mm')
  const userEndTime = moment
    .parseZone(`${endDate}T${endTime}+08:00`)
    .local()
    .format('YYYY-MM-DD HH:mm')

  return (
    <>
      <div className={notificationClass}>
        <h4 className='text-yellow text-lg font-bold'>
          System Maintenance Notice
        </h4>
        <p className='text-light-4 text-base font-normal'>
          Our website will be temporarily unavailable for routine maintenance in
          your local time from <br />
          <b>{userStartTime}</b> to <b>{userEndTime}</b>
          <br />
          We apologize for any inconvenience caused.
        </p>
      </div>
      <div className={divide} />
    </>
  )
}

// I visited the theater where there had been Mozart shows operas many times
