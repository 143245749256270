import React from 'react'

import RfMode from 'src/containers/operating/device/beamformers/cloverCell/evb/Common/RfMode'
import CentralFrequency from 'src/containers/operating/device/beamformers/cloverCell/evb/Common/CentralFrequency'

import { container } from 'src/containers/operating/device/beamformers/style'

const Common = props => {
  return (
    <div className={container + ' grid grid-cols-2 gap-x-4'}>
      <RfMode />

      <CentralFrequency />
    </div>
  )
}

Common.propTypes = {}

export default Common
