import { useMemo } from 'react'
import { useThree } from '@react-three/fiber'
import { useGesture } from 'react-use-gesture'
import * as THREE from 'three'

const useDrag = props => {
  const { name, onHover, onDragStart, onDrag, onDragEnd } = props

  const { raycaster, size, camera } = useThree()

  const { mouse2D, mouse3D, normal, plane } = useMemo(() => {
    return {
      mouse2D: new THREE.Vector2(), // Normalized 2D screen space mouse coords
      mouse3D: new THREE.Vector3(), // 3D world space mouse coords
      normal: new THREE.Vector3(), // Normal of the drag plane
      plane: new THREE.Plane(), // Drag plane
    }
  }, [])

  const bind = useGesture({
    onHover: args => onHover(args),
    onDrag: ({ event }) => {
      // 因為 canvas 沒有塞滿整個 window, 所以抓 offset 而不是 client
      const x = event.offsetX
      const y = event.offsetY

      // 抓到滑鼠在 canvas 區塊的 2d 位置，x 左到右 = 0~1, y 上到下 = 0~1
      const nx = (x / size.width) * 2 - 1
      const ny = -(y / size.height) * 2 + 1

      // 把上面的值塞回 vector2
      mouse2D.set(nx, ny)

      // 讓 raycaster 以我們畫面為 origin, 往滑鼠方向射
      raycaster.setFromCamera(mouse2D, camera)

      // The drag plane is normal to the camera view
      camera.getWorldDirection(normal).negate()

      // Find the plane that's normal to the camera and contains our drag point
      plane.setFromNormalAndCoplanarPoint(normal, mouse3D)

      // Find the point of intersection
      raycaster.ray.intersectPlane(plane, mouse3D)

      // Update the object position with the original offset
      onDrag(name, mouse3D.toArray())
    },
    onDragStart: ({ event }) => {
      const { point } = event

      // event.currentTarget.stopPropagation()
      // Set initial 3D cursor position (needed for onDrag plane calculation)
      mouse3D.copy(point)

      // Run user callback
      onDragStart(event)
    },
    onDragEnd: ({ event }) => onDragEnd(event),
  })

  return bind
}

export default useDrag
