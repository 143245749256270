import React from 'react'
import { useSelector } from 'react-redux'

import Emergency from 'src/containers/Modal/Emergency'
import High from 'src/containers/Modal/High'
import Normal from 'src/containers/Modal/Normal'
import Low from 'src/containers/Modal/Low'

import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryOfGeneralError from 'src/containers/ErrorBoundary/GeneralError'

import GeneralError from 'src/containers/Modal/Emergency/GeneralError'

const Modal = () => {
  const facilityModal = useSelector(state => state.facilityModal)
  const { emergency, high, normal, low } = facilityModal

  const { type: emergencyType } = emergency
  const { type: highType } = high
  const { type: normalType } = normal
  const { type: lowType } = low

  // ! Modal 的位置在 Global 錯誤邊界外，需獨立處理
  // ! (為了讓 Global 任何一個出問題，Emergency 都可以正常運作)

  // ! Emergency 內含處理全部手段(包含 Modal 錯誤)的 GeneralErrorModal
  // ! 不獨立隔離錯誤邊界會導致 其他 Modal 掛掉跳出的 GeneralErrorModal 的送信功能也跟著不能用
  // ! 因此這邊就將 Emergency Level 的 Modal 錯誤邊界獨立，其中 Emergency 拔掉送信功能
  return (
    <>
      {emergencyType && (
        <ErrorBoundary fallback={<GeneralError isReportIssueDisabled />}>
          <Emergency {...emergency} />
        </ErrorBoundary>
      )}

      <ErrorBoundaryOfGeneralError>
        {highType && <High {...high} />}
        {normalType && <Normal {...normal} />}
        {lowType && <Low {...low} />}
      </ErrorBoundaryOfGeneralError>
    </>
  )
}

Modal.propTypes = {}

export default Modal
