import { getDefaultCurrentFreq } from '../../configs/spec.mjs'
import Controllers from '../../Controllers/index.mjs'

const getDT = sn => Controllers._getDeviceType(sn)

export const bboardInitAppendData = sn => ({
  deviceControl: {
    common: {
      rfMode: 'tx',
      currentFreq: getDefaultCurrentFreq[getDT(sn)],
    },
    step: {
      tx: {
        power: {
          channelValue: [true, true, true, true],
        },
        phase: {
          valueMax: 63,
          channelValue: [0, 0, 0, 0],
        },
        attenuation: {
          comValue: 0,
          valueMax: 15,
          channelValue: [0, 0, 0, 0],
        },
        compensation: {
          isDisabled: false,
          trC: {
            valueMax: 31,
            currentValue: 8, //! 2023/11/7 rd2_app 結論：demo mode 只用 28 的示意即可
          },
          trQ: {
            valueMax: 31,
            currentValue: 6, //! 2023/11/7 rd2_app 結論：demo mode 只用 28 的示意即可
          },
        },
        adc: {
          currentValue: 24, // 24 這個值是參考 2023/09/20 實際 bboard init 後回傳的數字
        },
      },
      rx: {
        power: {
          channelValue: [true, true, true, true],
        },
        phase: {
          valueMax: 63,
          channelValue: [0, 0, 0, 0],
        },
        attenuation: {
          comValue: 0,
          valueMax: 15,
          channelValue: [0, 0, 0, 0],
        },
        compensation: {
          isDisabled: false,
          trC: {
            valueMax: 31,
            currentValue: 2, //! 2023/11/7 rd2_app 結論：demo mode 只用 28 的示意即可
          },
          trQ: {
            valueMax: 31,
            currentValue: 11, //! 2023/11/7 rd2_app 結論：demo mode 只用 28 的示意即可
          },
        },
        adc: {
          currentValue: 24, // 24 這個值是參考 2023/09/20 實際 bboard init 後回傳的數字
        },
      },
    },
  },
})
