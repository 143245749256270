import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import {
  bbox_configClearCheckModal_clearClick_watcher,
  bbox_configClearCheckModal_cancelClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import { AiOutlineClear } from 'react-icons/ai'

const ClearCheck = props => {
  const dispatch = useDispatch()

  const handleClearClick = () =>
    dispatch(bbox_configClearCheckModal_clearClick_watcher())
  const handleCancelClick = () =>
    dispatch(bbox_configClearCheckModal_cancelClick_watcher())

  return (
    <Modal>
      <Modal.Icon>
        <AiOutlineClear className='text-red' />
      </Modal.Icon>

      <Modal.Title>Clear selected items? </Modal.Title>

      <Modal.Description>You cannot undo this action. </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='primary'
          danger
          onClick={handleClearClick}>
          Clear
        </CommonButton>
        <CommonButton size='lg' type='general' onClick={handleCancelClick}>
          Cancel
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ClearCheck
