import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonInput from 'src/components/Input/CommonInput'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import {
  member_modalClose_watcher,
  member_forgotPasswordSendMailModal_submit_watcher,
  member_forgotPasswordSendMailModal_backToSignIn_watcher,
} from 'src/redux/actions/facility/member'

const ForgotPasswordSendMail = props => {
  const dispatch = useDispatch()

  const { email, errorMessage } = props

  const handleInputChange = event =>
    dispatch(
      modalActions.setMemberForgotPasswordValue({ email: event.formattedValue })
    )

  const handleCloseIconClick = () => dispatch(member_modalClose_watcher())
  const handleSubmitClick = event => {
    event.preventDefault()
    dispatch(member_forgotPasswordSendMailModal_submit_watcher())
  }
  const handleBackClick = () =>
    dispatch(member_forgotPasswordSendMailModal_backToSignIn_watcher())

  return (
    <Modal>
      <Modal.CloseIcon onClick={handleCloseIconClick} />

      <Modal.Title>Forgot password</Modal.Title>

      <div className='flex flex-col'>
        {errorMessage && (
          <div className='text-red mt-6 mb-8'>{errorMessage}</div>
        )}

        <form className='flex flex-col' onSubmit={handleSubmitClick}>
          <Modal.FormLabel htmlFor='email' className='mt-6 '>
            Enter your email
          </Modal.FormLabel>
          <CommonInput
            autoFocus
            id='email'
            size='lg'
            value={email}
            placeholder='Enter your email'
            onChange={handleInputChange}
          />

          <Modal.ColumnButtonBox>
            <CommonButton buttonType='submit' size='lg'>
              Send code
            </CommonButton>
            <CommonButton size='lg' type='text' onClick={handleBackClick}>
              Back to Sign In
            </CommonButton>
          </Modal.ColumnButtonBox>
        </form>
      </div>
    </Modal>
  )
}

export default ForgotPasswordSendMail
