import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const UDInterface = props => {
  const { className, channel } = props

  return (
    <SVGUniqueID>
      <svg
        width='203'
        height='98'
        viewBox='0 0 203 98'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        {channel === 'single' ? <SingleInterface /> : <DualInterface />}
      </svg>
    </SVGUniqueID>
  )
}
export default UDInterface

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const SingleInterface = () => (
  <>
    <path
      d='M59.5494 50.1808C59.4477 48.733 60.5962 47.5031 62.0476 47.5056L67.8291 47.5156C69.2081 47.518 70.3247 48.6366 70.3247 50.0156V59.0246C70.3247 60.4611 69.117 61.6021 67.6828 61.5206L62.5144 61.2268C61.2581 61.1554 60.2507 60.1613 60.1625 58.906L59.5494 50.1808Z'
      stroke='white'
    />
    <path
      d='M54.5799 55.5925C54.4323 53.9056 55.6802 52.4184 57.3672 52.2708L63.2231 51.7585C64.7591 51.6241 66.1133 52.7603 66.2477 54.2964C66.4084 56.1342 65.0489 57.7545 63.211 57.9153L57.9016 58.3798C56.2147 58.5274 54.7275 57.2795 54.5799 55.5925Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M54.5296 55.0175C54.4098 53.6481 55.4228 52.4409 56.7922 52.3211C58.1616 52.2013 59.3688 53.2143 59.4887 54.5837L59.5893 55.7336C59.7091 57.103 58.6961 58.3103 57.3267 58.4301C55.9573 58.5499 54.75 57.5369 54.6302 56.1675L54.5296 55.0175Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M32.3386 51.1937C32.2288 49.7413 33.3789 48.5029 34.8355 48.5052L40.3172 48.5141C41.6963 48.5163 42.8131 49.6349 42.8131 51.0141V58.6304C42.8131 60.0629 41.6118 61.2025 40.1812 61.1269L35.2564 60.8667C34.001 60.8004 32.9902 59.8122 32.8954 58.5587L32.3386 51.1937Z'
      stroke='white'
    />
    <path
      d='M27.3509 55.5925C27.2033 53.9056 28.4512 52.4184 30.1382 52.2708L35.9941 51.7585C37.5301 51.6241 38.8843 52.7603 39.0187 54.2964C39.1794 56.1342 37.8199 57.7545 35.982 57.9153L30.6726 58.3798C28.9857 58.5274 27.4985 57.2795 27.3509 55.5925Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M27.3006 55.0175C27.1808 53.6481 28.1938 52.4409 29.5632 52.3211C30.9326 52.2013 32.1398 53.2143 32.2597 54.5837L32.3603 55.7336C32.4801 57.103 31.4671 58.3103 30.0977 58.4301C28.7283 58.5499 27.521 57.5369 27.4012 56.1675L27.3006 55.0175Z'
      fill='#434343'
      stroke='white'
    />
  </>
)

const DualInterface = () => (
  <>
    <path
      d='M89.0256 51.1937C88.9158 49.7413 90.0659 48.5029 91.5225 48.5052L97.0042 48.5141C98.3833 48.5163 99.5001 49.6349 99.5001 51.0141V58.6304C99.5001 60.0629 98.2988 61.2025 96.8682 61.1269L91.9434 60.8667C90.6881 60.8004 89.6772 59.8122 89.5824 58.5587L89.0256 51.1937Z'
      stroke='white'
    />
    <path
      d='M84.0379 55.5925C83.8903 53.9056 85.1382 52.4184 86.8252 52.2708L92.6811 51.7585C94.2171 51.6241 95.5713 52.7603 95.7057 54.2964C95.8665 56.1342 94.5069 57.7545 92.669 57.9153L87.3596 58.3798C85.6727 58.5274 84.1855 57.2795 84.0379 55.5925Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M83.9876 55.0175C83.8678 53.6481 84.8808 52.4409 86.2502 52.3211C87.6196 52.2013 88.8269 53.2143 88.9467 54.5837L89.0473 55.7336C89.1671 57.103 88.1541 58.3103 86.7847 58.4301C85.4153 58.5499 84.208 57.5369 84.0882 56.1675L83.9876 55.0175Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M68.7966 51.1937C68.6868 49.7413 69.8369 48.5029 71.2935 48.5052L76.7752 48.5141C78.1543 48.5163 79.2711 49.6349 79.2711 51.0141V58.6304C79.2711 60.0629 78.0698 61.2025 76.6392 61.1269L71.7144 60.8667C70.4591 60.8004 69.4482 59.8122 69.3534 58.5587L68.7966 51.1937Z'
      stroke='white'
    />
    <path
      d='M63.8089 55.5925C63.6613 53.9056 64.9092 52.4184 66.5962 52.2708L72.4521 51.7585C73.9881 51.6241 75.3423 52.7603 75.4767 54.2964C75.6375 56.1342 74.2779 57.7545 72.44 57.9153L67.1306 58.3798C65.4437 58.5274 63.9565 57.2795 63.8089 55.5925Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M63.7586 55.0175C63.6388 53.6481 64.6518 52.4409 66.0212 52.3211C67.3906 52.2013 68.5979 53.2143 68.7177 54.5837L68.8183 55.7336C68.9381 57.103 67.9251 58.3103 66.5557 58.4301C65.1863 58.5499 63.979 57.5369 63.8592 56.1675L63.7586 55.0175Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M49.5676 51.1937C49.4578 49.7413 50.6079 48.5029 52.0645 48.5052L57.5462 48.5141C58.9253 48.5163 60.0421 49.6349 60.0421 51.0141V58.6304C60.0421 60.0629 58.8408 61.2025 57.4102 61.1269L52.4854 60.8667C51.2301 60.8004 50.2192 59.8122 50.1244 58.5587L49.5676 51.1937Z'
      stroke='white'
    />
    <path
      d='M44.5799 55.5925C44.4323 53.9056 45.6802 52.4184 47.3672 52.2708L53.2231 51.7585C54.7591 51.6241 56.1133 52.7603 56.2477 54.2964C56.4084 56.1342 55.0489 57.7545 53.211 57.9153L47.9016 58.3798C46.2147 58.5274 44.7275 57.2795 44.5799 55.5925Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M44.5296 55.0175C44.4098 53.6481 45.4228 52.4409 46.7922 52.3211C48.1616 52.2013 49.3688 53.2143 49.4887 54.5837L49.5893 55.7336C49.7091 57.103 48.6961 58.3103 47.3267 58.4301C45.9573 58.5499 44.75 57.5369 44.6302 56.1675L44.5296 55.0175Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M30.3386 51.1937C30.2288 49.7413 31.3789 48.5029 32.8355 48.5052L38.3172 48.5141C39.6963 48.5163 40.8131 49.6349 40.8131 51.0141V58.6304C40.8131 60.0629 39.6118 61.2025 38.1812 61.1269L33.2564 60.8667C32.001 60.8004 30.9902 59.8122 30.8954 58.5587L30.3386 51.1937Z'
      stroke='white'
    />
    <path
      d='M25.3509 55.5925C25.2033 53.9056 26.4512 52.4184 28.1382 52.2708L33.9941 51.7585C35.5301 51.6241 36.8843 52.7603 37.0187 54.2964C37.1794 56.1342 35.8199 57.7545 33.982 57.9153L28.6726 58.3798C26.9857 58.5274 25.4985 57.2795 25.3509 55.5925Z'
      fill='#434343'
      stroke='white'
    />
    <path
      d='M25.3006 55.0175C25.1808 53.6481 26.1938 52.4409 27.5632 52.3211C28.9326 52.2013 30.1398 53.2143 30.2597 54.5837L30.3603 55.7336C30.4801 57.103 29.4671 58.3103 28.0977 58.4301C26.7283 58.5499 25.521 57.5369 25.4012 56.1675L25.3006 55.0175Z'
      fill='#434343'
      stroke='white'
    />
  </>
)
