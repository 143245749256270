// import { current } from '@reduxjs/toolkit'

const beamformers = {
  showModalOfBBoxBeamConfigClearCheck: (state, action) => {
    state.high = {
      type: 'BEAM_CONFIG_EDITOR',
      props: { status: 'clearCheck' },
    }
    return state
  },
  showModalOfBBoxBeamConfigDiscardUnsavedChanges: (state, action) => {
    state.high = {
      type: 'BEAM_CONFIG_EDITOR',
      props: { status: 'discardUnsavedChanges' },
    }
    return state
  },
}
export default beamformers
