import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const UdModule = props => {
  return (
    <SVGUniqueID>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}>
        <mask id='path-1-inside-1_13831_13060' fill='white'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3 38C3 39.1046 3.89543 40 5 40L39 40C40.1046 40 41 39.1046 41 38L41 29L42.5 29C42.7761 29 43 28.7761 43 28.5L43 27L45 27C45.5523 27 46 26.5523 46 26C46 25.4477 45.5523 25 45 25L43 25L43 23.5C43 23.2239 42.7761 23 42.5 23L41 23L41 12C41 10.8954 40.1046 10 39 10L5 10C3.89543 10 3 10.8954 3 12L3 38Z'
          />
        </mask>
        <path
          d='M41 38L40 38L41 38ZM41 29L41 28L40 28L40 29L41 29ZM42.5 29L42.5 28L42.5 29ZM43 27L43 26L42 26L42 27L43 27ZM45 27L45 26L45 27ZM45 25L45 24L45 25ZM43 25L42 25L42 26L43 26L43 25ZM42.5 23L42.5 22L42.5 23ZM41 23L40 23L40 24L41 24L41 23ZM5 39C4.44772 39 4 38.5523 4 38L2 38C2 39.6569 3.34314 41 5 41L5 39ZM39 39L5 39L5 41L39 41L39 39ZM40 38C40 38.5523 39.5523 39 39 39L39 41C40.6569 41 42 39.6569 42 38L40 38ZM40 29L40 38L42 38L42 29L40 29ZM41 30L42.5 30L42.5 28L41 28L41 30ZM42.5 30C43.3284 30 44 29.3284 44 28.5L42 28.5C42 28.2239 42.2239 28 42.5 28L42.5 30ZM44 28.5L44 27L42 27L42 28.5L44 28.5ZM43 28L45 28L45 26L43 26L43 28ZM45 28C46.1046 28 47 27.1046 47 26L45 26L45 28ZM47 26C47 24.8954 46.1046 24 45 24L45 26L47 26ZM45 24L43 24L43 26L45 26L45 24ZM44 25L44 23.5L42 23.5L42 25L44 25ZM44 23.5C44 22.6716 43.3284 22 42.5 22L42.5 24C42.2239 24 42 23.7761 42 23.5L44 23.5ZM42.5 22L41 22L41 24L42.5 24L42.5 22ZM40 12L40 23L42 23L42 12L40 12ZM39 11C39.5523 11 40 11.4477 40 12L42 12C42 10.3431 40.6569 9 39 9L39 11ZM5 11L39 11L39 9L5 9L5 11ZM4 12C4 11.4477 4.44771 11 5 11L5 9C3.34314 9 2 10.3431 2 12L4 12ZM4 38L4 12L2 12L2 38L4 38Z'
          fill='#C2C2C2'
          mask='url(#path-1-inside-1_13831_13060)'
        />
        <rect
          x='6.5'
          y='36.5'
          width='23'
          height='31'
          transform='rotate(-90 6.5 36.5)'
          stroke='#C2C2C2'
        />
        <path
          d='M6 25.5H17.5V19.5H22.5V31.5H26.5V25.5H30.5V22.5H34.5V26.5H37.5'
          stroke='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default UdModule
