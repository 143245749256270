import React from 'react'

import { h6 } from 'src/containers/operating/device/beamformers/style'
import ValueBox from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/ValueBox'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { RiTempHotLine } from 'react-icons/ri'

const JunctionTemperature = props => {
  const { current } = useGetCurrentDeviceData()

  const { temperature } = current.data.current

  const tempMax = 105
  const tempMin = -40
  const tempOverMax = temperature?.currentValue >= tempMax
  const tempOverMin = temperature?.currentValue <= tempMin
  const tempWarning = tempOverMax || tempOverMin

  return (
    <div className='flex items-center justify-between h-10'>
      <div className='flex flex-col justify-center'>
        <h6 className={h6 + ' text-white'}>Junction Temperature</h6>
        {tempOverMax && (
          <span className={warningText}>
            Max temperature limit reached :{tempMax}˚C
          </span>
        )}
        {tempOverMin && (
          <span className={warningText}>
            Min temperature limit reached :{tempMin}˚C
          </span>
        )}
      </div>

      <ValueBox
        icon={<RiTempHotLine />}
        value={temperature?.currentValue}
        warning={tempWarning}
        unit='˚C'
      />
    </div>
  )
}

JunctionTemperature.propTypes = {}

export default JunctionTemperature

const warningText = `text-xs text-yellow `
