import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

export function* steering_controlPatternChange(payloads) {
  try {
    const { sn, value } = payloads

    let { lookupID, currentData } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering.controlPattern = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_CTTC_STEERING_SET_CONTROL_PATTERN,
      { sn, lookupID, value }
    )
  } catch (error) {
    devWarLog('[handler] steering_controlPatternChange error:', error)
  }
}

export function* steering_beamSynthesisChange(payloads) {
  try {
    const { sn } = payloads

    let { lookupID, currentData } = yield call(getCommonArgs, sn)
    const newValue = !currentData.deviceControl.steering.isBeamSynthesis

    currentData.deviceControl.steering.isBeamSynthesis = newValue
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_CTTC_STEERING_SET_BEAM_SYNTHESIS,
      { sn, lookupID, value: newValue }
    )
  } catch (error) {
    devWarLog('[handler] steering_beamSynthesisChange error:', error)
  }
}
export function* steering_rfModeChange(payloads) {
  try {
    const { sn, beamIndex, value } = payloads

    let { lookupID, currentData } = yield call(getCommonArgs, sn)

    const isBeamSynthesis = currentData.deviceControl.steering.isBeamSynthesis

    if (isBeamSynthesis) {
      currentData.deviceControl.steering.synthesizedBeamData.rfMode = value
    } else {
      const targetData =
        currentData.deviceControl.steering.separateBeamsData.find(
          e => e.beamIndex === beamIndex
        )
      targetData.rfMode = value
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_CTTC_STEERING_SET_RF_MODE,
      { sn, lookupID, beamIndex: +beamIndex, value }
    )
  } catch (error) {
    devWarLog('[handler] steering_rfModeChange error:', error)
  }
}
export function* steering_powerChange(payloads) {
  try {
    const { sn, hvMode, beamIndex } = payloads

    let { lookupID, currentData } = yield call(getCommonArgs, sn)

    const isBeamSynthesis = currentData.deviceControl.steering.isBeamSynthesis
    let newValue

    if (isBeamSynthesis) {
      const rfMode =
        currentData.deviceControl.steering.synthesizedBeamData.rfMode
      newValue =
        !currentData.deviceControl.steering.synthesizedBeamData[rfMode][hvMode]
          .power
      currentData.deviceControl.steering.synthesizedBeamData[rfMode][
        hvMode
      ].power = newValue
    } else {
      const targetData =
        currentData.deviceControl.steering.separateBeamsData.find(
          e => e.beamIndex === beamIndex
        )
      const targetIndexRfMode = targetData.rfMode
      newValue = !targetData[targetIndexRfMode][hvMode].power
      targetData[targetIndexRfMode][hvMode].power = newValue
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(
      __socket_API_sender,
      beamformersApi.CLOVERCELL_CTTC_STEERING_SET_POWER,
      { sn, lookupID, hvMode, beamIndex, value: newValue }
    )
  } catch (error) {
    devWarLog('[handler] steering_powerChange error:', error)
  }
}

export function* steering_thetaPhiGainChange(payloads) {
  try {
    const { sn, beamIndex, label, hvMode, value, isValid } = payloads

    let { lookupID, currentData } = yield call(getCommonArgs, sn)

    const isBeamSynthesis = currentData.deviceControl.steering.isBeamSynthesis

    const targetBeamData = isBeamSynthesis
      ? currentData.deviceControl.steering.synthesizedBeamData
      : currentData.deviceControl.steering.separateBeamsData.find(
          e => e.beamIndex === beamIndex
        )

    const rfMode = targetBeamData.rfMode
    targetBeamData[rfMode][hvMode][label] = value

    yield put(setSingleDeviceData({ sn, data: currentData }))
    if (isValid) {
      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_CTTC_STEERING_SET_THETA_PHI_GAIN,
        { sn, lookupID, beamIndex: +beamIndex, label, hvMode, value: +value }
      )
    }
  } catch (error) {
    devWarLog('[handler] steering_thetaPhiGainChange error:', error)
  }
}
