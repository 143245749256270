import React from 'react'
import { container } from 'src/containers/operating/device/beamformers/style'
import CentralFrequency from 'src/containers/operating/device/beamformers/cloverCell/cttc/Common/CentralFrequency'
import RefSignalSource from 'src/containers/operating/device/beamformers/cloverCell/cttc/Common/RefSignalSource'

const Common = props => {
  return (
    <div className={container}>
      <div className='grid grid-cols-2 gap-x-4'>
        <CentralFrequency />
        <RefSignalSource />
      </div>
    </div>
  )
}

Common.propTypes = {}

export default Common
