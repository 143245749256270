import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import BeamSteeringModuleForCloverCell from 'src/components/composite/beamformers/BeamSteeringModule/ForCloverCell'
import OverlayMask from 'src/containers/operating/device/beamformers/cloverCell/evb/Main/BeamSteering/OverlayMask'
// import Mask from 'src/components/Mask'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  cloverCellEvb_steering_thetaPhiChange_watcher,
  cloverCellEvb_steering_gainChange_watcher,
  cloverCellEvb_steering_3DCameraChange_watcher,
} from 'src/redux/actions/beamformers/cloverCellEvbAction'

import {
  change3DDirection,
  setShowManual3DInstructionMask,
} from 'src/redux/slices/uiControl/beamformers/cloverCellEvb'
import useGetUIControl from 'src/hooks/useGetUIControl'

const BeamSteering = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const deviceType = current.data.info.deviceType

  const rfMode = current.data.deviceControl.common.rfMode
  const currentControlMode =
    current.data.deviceControl?.common.currentControlMode

  const [dragHVMode, setDragHVMode] = useState('horizon')

  const pickValue = hvMode => key =>
    current.data.deviceControl.steering[rfMode]?.[hvMode]?.[key]

  const pickH = pickValue('horizon')
  const pickV = pickValue('vertical')

  const [thetaH, setThetaH] = useState(pickH('theta') || 0)
  const [phiH, setPhiH] = useState(pickH('phi') || 0)

  const [thetaV, setThetaV] = useState(pickV('theta') || 0)
  const [phiV, setPhiV] = useState(pickV('phi') || 0)

  const { single: s_uiControl } = useGetUIControl(sn)

  const deviceDirection = s_uiControl?.deviceControl.deviceDirection
  const beam3DCameraArgs = s_uiControl?.deviceControl.beam3DCameraArgs

  const getCHData = hvMode =>
    current.data.deviceControl.channel[rfMode]?.[hvMode]?.lstBoardData?.[0]
      ?.lstChannelData

  const isHAllPowerOff =
    getCHData('horizon') &&
    R.pipe(R.values, R.all(R.propEq(false, 'power')))(getCHData('horizon'))
  const isVAllPowerOff =
    getCHData('vertical') &&
    R.pipe(R.values, R.all(R.propEq(false, 'power')))(getCHData('vertical'))

  const isOffH = isHAllPowerOff
  const isOffV = isVAllPowerOff

  useEffect(() => {
    if (isOffH && dragHVMode === 'horizon') setDragHVMode('vertical')
    if (isOffV && dragHVMode === 'vertical') setDragHVMode('horizon')
  }, [dragHVMode, isOffH, isOffV])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    setThetaH(pickH('theta'))
    setPhiH(pickH('phi'))
    setThetaV(pickV('theta'))
    setPhiV(pickV('phi'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickH('theta'), pickH('phi'), pickV('theta'), pickV('phi')])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleDrag = ({ resultTheta, resultPhi }) => {
    if (dragHVMode === 'horizon') {
      setThetaH(resultTheta)
      setPhiH(resultPhi)
    }
    if (dragHVMode === 'vertical') {
      setThetaV(resultTheta)
      setPhiV(resultPhi)
    }
  }

  const handleDragEnd = e =>
    dispatch(
      cloverCellEvb_steering_thetaPhiChange_watcher({
        sn,
        hvMode: dragHVMode,
        theta: dragHVMode === 'horizon' ? thetaH : thetaV,
        phi: dragHVMode === 'horizon' ? phiH : phiV,
        isValid: true,
      })
    )

  const handleCameraChange = ({ position, rotation }) => {
    dispatch(
      cloverCellEvb_steering_3DCameraChange_watcher({
        sn,
        position,
        rotation,
      })
    )
  }

  const handleDirectionChange = value =>
    dispatch(change3DDirection({ sn, value }))

  const handleInputFocus = ({ hvMode }) => setDragHVMode(hvMode)

  const handleInputChange = ({ hvMode, label, value, isValid }) => {
    // 按 +- 也要切換 hvMode
    setDragHVMode(hvMode)

    if (label === 'theta' || label === 'phi') {
      let theta = label === 'theta' ? value : pickValue(hvMode)('theta')
      let phi = label === 'phi' ? value : pickValue(hvMode)('phi')

      dispatch(
        cloverCellEvb_steering_thetaPhiChange_watcher({
          sn,
          hvMode,
          theta,
          phi,
          [label]: value,
          isValid,
        })
      )
    }

    if (label === 'gain')
      dispatch(
        cloverCellEvb_steering_gainChange_watcher({
          sn,
          hvMode,
          beamCurrentGain: value,
          isValid,
        })
      )
  }

  const handleHelpButtonClick = () =>
    dispatch(setShowManual3DInstructionMask({ sn, value: true }))

  const handleBoresightClick = () =>
    dispatch(
      cloverCellEvb_steering_thetaPhiChange_watcher({
        sn,
        hvMode: dragHVMode,
        theta: 0,
        phi: 0,
        isValid: true,
      })
    )

  const handleDragHVModeChange = value => setDragHVMode(value)

  const standbySetZero = value => (rfMode === 'standby' ? 0 : value)

  const beamSteeringProps = {
    sn,
    isDontRender3D: rfMode === 'standby',
    deviceType,
    dragHVMode,
    handleDragHVModeChange,

    // value
    thetaH: standbySetZero(thetaH),
    thetaV: standbySetZero(thetaV),
    phiH: standbySetZero(phiH),
    phiV: standbySetZero(phiV),
    beamCurrentGainH: standbySetZero(pickH('beamCurrentGain')),
    beamCurrentGainV: standbySetZero(pickV('beamCurrentGain')),
    thetaMaxH: standbySetZero(pickH('thetaMax')),
    thetaMaxV: standbySetZero(pickV('thetaMax')),
    beamGainMinH: standbySetZero(pickH('beamGainMin')),
    beamGainMinV: standbySetZero(pickV('beamGainMin')),
    beamGainMaxH: standbySetZero(pickH('beamGainMax')),
    beamGainMaxV: standbySetZero(pickV('beamGainMax')),
    isOffH,
    isOffV,

    // 3d control event
    handleDrag,
    handleDragEnd,
    beam3DCameraArgs,
    handleCameraChange,

    // 3d button event
    deviceDirection,
    handleDirectionChange,
    handleHelpButtonClick,
    handleBoresightClick,

    // input event
    handleInputChange,
    handleInputFocus,
  }

  return (
    <div className='relative rounded-md'>
      <OverlayMask sn={sn} currentControlMode={currentControlMode} />

      <BeamSteeringModuleForCloverCell {...beamSteeringProps} />
    </div>
  )
}

BeamSteering.propTypes = {}

export default BeamSteering

// const disabledMaskClass = `flex rounded-md
//                           items-center justify-center
//                           text-light-4 text-base font-medium
//                           cursor-not-allowed select-none
//                           `
