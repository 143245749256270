import React from 'react'
import PropTypes from 'prop-types'

import { BiCheck, BiMinus } from 'react-icons/bi'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Checkbox
//   disabled={bool}
//   status={oneOf(['checked', 'uncheck', 'indeterminate'])}
//   onClick = () => {},
// />

const Checkbox = props => {
  const {
    className = '',
    status = 'uncheck', // uncheck, checked, indeterminate
    disabled = false,
    onClick = () => {},
  } = props

  const themeStatus = disabled ? 'disabled' : status

  const handleClick = () => !disabled && onClick()

  const container = `
                    ${className} 
                    ${containerCommon} 
                    ${containerTheme[themeStatus]} 
                    ${cursor(disabled)}
                    `

  return (
    <button
      type='button'
      className={container}
      disabled={disabled}
      onClick={handleClick}
      aria-label='checkbox'>
      {content[status]}
    </button>
  )
}
export default Checkbox

const containerCommon = `
                        w-[14px] h-[14px] 
                        border-1 border-solid 
                        flex items-center justify-center 
                        rounded-[2px] 
                        text-black 
                        select-none 
                        `
const containerTheme = {
  uncheck: `bg-dark-1 border-light-4 hover:border-light-1 group-hover:border-light-1`,
  checked: `bg-teal border-transparent hover-bg-teal/80 group-hover:border-teal/80`,
  indeterminate: `bg-teal border-transparent hover-bg-teal/80 group-hover:border-teal/80`,
  disabled: `bg-light-4/50 border-transparent`,
}

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

const content = {
  uncheck: <></>,
  checked: <BiCheck data-testid='checked-icon' />,
  indeterminate: <BiMinus />,
}

Checkbox.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(['uncheck', 'checked', 'indeterminate']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
