import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Gain({ className }) {
  return (
    <SVGUniqueID>
      <svg
        viewBox='0 0 16 16'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.5'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.30975 2.09983C4.21641 2.15075 4.13824 2.22719 4.08367 2.32088C4.02909 2.41458 4.00019 2.52197 4.00008 2.63148L4.00008 7.36467H0.666748V8.698H4.00008L4.00008 13.4325C4.00052 13.5418 4.02963 13.6489 4.0843 13.7423C4.13896 13.8357 4.2171 13.9119 4.31032 13.9626C4.40355 14.0133 4.50832 14.0367 4.61337 14.0302C4.71842 14.0237 4.81979 13.9876 4.90655 13.9257L12.1769 8.698H15.3334V7.36467H12.1751L4.90655 2.13823C4.81996 2.07577 4.71854 2.03917 4.61332 2.0324C4.5081 2.02563 4.4031 2.04895 4.30975 2.09983ZM11.0733 8.03198L5.33342 12.0313V4.03133L11.0733 8.03198Z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
