import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Mask from 'src/components/Mask'

import InstructionMask from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS/InstructionMask'

// import { ris_steering_channelModeMaskClose_watcher } from 'src/redux/actions/beamformers/risAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { RotatingLines } from 'react-loader-spinner'
import useGetUIControl from 'src/hooks/useGetUIControl'

const OverlayMask = ({ sn }) => {
  const { current } = useGetCurrentDeviceData()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const { all: all_uiControl, single: s_uiControl } = useGetUIControl(sn)

  const { showFirst3DInstructionMask, showManual3DInstructionMask } =
    s_uiControl.deviceControl
  const { dontShowAgainForSteeringMask } = all_uiControl
  const { instruction: dontShowInstructionMask } = dontShowAgainForSteeringMask

  const showManualInstructionMask = showManual3DInstructionMask
  const showFirstInstructionMask =
    !dontShowInstructionMask && showFirst3DInstructionMask

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  if (showManualInstructionMask || showFirstInstructionMask)
    return (
      <InstructionMask
        {...{
          sn,
          showFirstInstructionMask,
          showManual3DInstructionMask,
        }}
      />
    )

  return <></>
}

OverlayMask.propTypes = { sn: PropTypes.string }

export default OverlayMask

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `
                  w-full h-full
                  p-8
                  flex flex-col
                  items-center justify-center
                  text-base font-normal leading-5
                  text-white text-center
                  `

const messageContainer = `max-w-[345px] mx-auto text-white text-center`

const h6 = `text-light-4 text-base font-bold text-center`
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const LoadingMask = () => (
  <Mask className='z-20 rounded-lg'>
    <div className='w-full h-full flex flex-col items-center justify-center gap-y-10'>
      <RotatingLines
        strokeColor='white'
        strokeWidth='3'
        animationDuration='0.75'
        width='56'
        visible={true}
      />
      <h6 className={h6}>Synchronizing changes</h6>
    </div>
  </Mask>
)

// const ChannelModeMask = ({ sn, dontShowCHModeMask }) => {
//   const dispatch = useDispatch()
//   const { isLG, isXL, is2XL } = useGetScreenSize()
//   const [isCHModePointerEnter, setIsCHModePointerEnter] = useState(false)

//   const isPC = isLG || isXL || is2XL

//   const pcShowView1 = isPC && !isCHModePointerEnter
//   const pcShowView2 = isPC && isCHModePointerEnter

//   const touchShowBoth = !isPC

//   const showView1 = touchShowBoth || pcShowView1
//   const showView2 = touchShowBoth || pcShowView2

//   //* ----------------- ------------------ -----------------
//   //* ----------------- ------------------ -----------------
//   //* -----------------       Event        -----------------
//   //* ----------------- ------------------ -----------------
//   //* ----------------- ------------------ -----------------
//   const handleChannelModePointerEnter = () => {
//     if (!dontShowCHModeMask) setIsCHModePointerEnter(true)

//     // if (dontShowCHModeMask) {
//     //   dispatch(
//     //     cloverCellEvb_steering_channelModeMaskClose_watcher({
//     //       sn,
//     //       dontShowAgain: true,
//     //     })
//     //   )
//     //   setIsCHModePointerEnter(false)
//     // }
//   }
//   const handleChannelModePointerLeave = () => setIsCHModePointerEnter(false)

//   const handleChannelModeClick = dontShowAgain => {
//     // dispatch(
//     //   cloverCellEvb_steering_channelModeMaskClose_watcher({ sn, dontShowAgain })
//     // )

//     setIsCHModePointerEnter(false)
//   }

//   return (
//     <Mask
//       className='z-20 rounded-lg'
//       onPointerEnter={handleChannelModePointerEnter}
//       onPointerLeave={handleChannelModePointerLeave}>
//       <div className={container}>
//         {showView1 && (
//           <div className={messageContainer + ' font-bold lg:font-normal'}>
//             Manually adjusted CH values will not be simulated
//           </div>
//         )}

//         {touchShowBoth && <br />}

//         {showView2 && (
//           <>
//             <div className={messageContainer}>
//               Using beam steering simulation, the system will calculate the
//               value of each CH.
//             </div>

//             <br />

//             <div>Your edits will be overwritten.</div>

//             <CommonButton
//               size='md'
//               type='general'
//               className='mt-10 mb-[130px]'
//               onClick={() => handleChannelModeClick(false)}>
//               OK
//             </CommonButton>

//             <CommonButton
//               size='md'
//               type='outlined'
//               onClick={() => handleChannelModeClick(true)}>
//               Don't show again
//             </CommonButton>
//           </>
//         )}
//       </div>
//     </Mask>
//   )
// }
