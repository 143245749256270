import React from 'react'

import PropTypes from 'prop-types'

const Divider = props => {
  const { className = '' } = props

  const dividerClass = `
                    ${className} 
                    w-full h-[1px] bg-dark-5 my-1
                    `

  return <div className={dividerClass} />
}

export default Divider

Divider.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
