import { current } from '@reduxjs/toolkit'

const member = {
  showModalOfMemberSignInSignUp: (state, action) => {
    // tabs = (string) "signIn", "createAccount"
    // email = (string)
    // password = (string)
    // confirmPassword = (string)
    // preferredUsername = (string)
    const prevProps = current(state).high.props

    state.high = {
      type: 'MEMBER',
      props: {
        ...current(state).high.props,
        status: 'signInAndSingUp',
        tabs: prevProps.tabs ?? 'signIn',
        email: prevProps.email ?? '',
        password: prevProps.password ?? '',
        confirmPassword: prevProps.confirmPassword ?? '',
        preferredUsername: prevProps.preferredUsername ?? '',
        errorMessage: prevProps.errorMessage ?? '',
      },
    }
    return state
  },
  setMemberSignInSignUpValue: (state, action) => {
    const {
      tabs,
      email,
      password,
      confirmPassword,
      preferredUsername,
      errorMessage,
    } = action.payload

    const prevProps = current(state).high.props

    state.high = {
      type: 'MEMBER',
      props: {
        ...current(state).high.props,
        status: 'signInAndSingUp',
        tabs: tabs ?? prevProps.tabs,
        email: email ?? prevProps.email,
        password: password ?? prevProps.password,
        confirmPassword: confirmPassword ?? prevProps.confirmPassword,
        preferredUsername: preferredUsername ?? prevProps.preferredUsername,
        errorMessage: errorMessage ?? prevProps.errorMessage,
      },
    }
    return state
  },

  showModalOfMemberSignUpVerification: (state, action) => {
    const prevProps = current(state).high.props

    state.high = {
      type: 'MEMBER',
      props: {
        ...current(state).high.props,
        status: 'signUpVerification',
        email: current(state).high.props.email,
        verificationCode: prevProps.verificationCode ?? '',
        errorMessage: prevProps.errorMessage ?? '',
        resendDelay: prevProps.resendDelay ?? 30,
      },
    }
    return state
  },
  setMemberSignUpVerificationValue: (state, action) => {
    const { verificationCode, errorMessage } = action.payload

    const prevProps = current(state).high.props

    state.high = {
      type: 'MEMBER',
      props: {
        ...current(state).high.props,
        status: 'signUpVerification',
        email: prevProps.email,
        verificationCode: verificationCode ?? prevProps.verificationCode,
        errorMessage: errorMessage ?? prevProps.errorMessage,
        resendDelay: prevProps.resendDelay,
      },
    }
    return state
  },
  showModalOfMemberSignUpSuccess: (state, action) => {
    state.high = {
      type: 'MEMBER',
      props: {
        ...current(state).high.props,
        status: 'signUpSuccess',
      },
    }
    return state
  },

  showModalOfMemberForgotPasswordSendEMail: (state, action) => {
    const prevProps = current(state).high.props
    state.high = {
      type: 'MEMBER',
      props: {
        status: 'forgotPasswordSendEMail',
        email: current(state).high.props.email,
        verificationCode: prevProps.verificationCode ?? '',
        newPassword: prevProps.newPassword ?? '',
        resendDelay: prevProps.resendDelay ?? 30,
        errorMessage: prevProps.errorMessage ?? '',
      },
    }
    return state
  },

  showModalOfMemberForgotPasswordReset: (state, action) => {
    const prevProps = current(state).high.props
    state.high = {
      type: 'MEMBER',
      props: {
        status: 'forgotPasswordReset',
        email: current(state).high.props.email,
        verificationCode: prevProps.verificationCode ?? '',
        newPassword: prevProps.newPassword ?? '',
        confirmNewPassword: prevProps.confirmNewPassword ?? '',
        resendDelay: prevProps.resendDelay ?? 30,
        errorMessage: prevProps.errorMessage ?? '',
      },
    }
    return state
  },

  showModalOfMemberForgotPasswordSuccess: (state, action) => {
    state.high = {
      type: 'MEMBER',
      props: {
        status: 'forgotPasswordSuccess',
      },
    }
    return state
  },
  setMemberForgotPasswordValue: (state, action) => {
    const {
      email,
      verificationCode,
      errorMessage,
      newPassword,
      confirmNewPassword,
    } = action.payload

    const prevProps = current(state).high.props

    state.high = {
      type: 'MEMBER',
      props: {
        status: prevProps.status,
        email: email ?? prevProps.email,
        verificationCode: verificationCode ?? prevProps.verificationCode,
        resendDelay: prevProps.resendDelay,
        newPassword: newPassword ?? prevProps.newPassword,
        confirmNewPassword: confirmNewPassword ?? prevProps.confirmNewPassword,
        errorMessage: errorMessage ?? prevProps.errorMessage,
      },
    }
    return state
  },
  setMemberResendDelayValue: (state, action) => {
    const { resendDelay } = action.payload

    const prevProps = current(state).high.props

    state.high = {
      type: 'MEMBER',
      props: {
        ...prevProps,
        resendDelay: resendDelay ?? prevProps.resendDelay,
      },
    }
    return state
  },
}
export default member
