import { createAction } from '@reduxjs/toolkit'

// udbox - device settings
export const udbox_deviceSettings_watcher = createAction(
  'deviceControl/udbox_deviceSettings_watcher'
)

// udbox 0630 - device settings
export const ud0630_deviceSettings_refSignalSource_change_watcher =
  createAction(
    'deviceControl/ud0630_deviceSettings_refSignalSource_change_watcher'
  )
export const ud0630_deviceSettings_inputSource_change_watcher = createAction(
  'deviceControl/ud0630_deviceSettings_inputSource_change_watcher'
)
export const ud0630_deviceSettings_outputSignalSource_enable_change_watcher =
  createAction(
    'deviceControl/ud0630_deviceSettings_outputSignalSource_enable_change_watcher'
  )
export const ud0630_deviceSettings_outputSignalSource_selection_change_watcher =
  createAction(
    'deviceControl/ud0630_deviceSettings_outputSignalSource_selection_change_watcher'
  )
export const ud0630_deviceSettings_outputLOFrequency_change_watcher =
  createAction(
    'deviceControl/ud0630_deviceSettings_outputLOFrequency_change_watcher'
  )

// udbox 0630 - modal event
export const ud0630_turnOffOutputCheckModal_cancelClick_watcher = createAction(
  'modalEvent/ud0630_turnOffOutputCheckModal_cancelClick_watcher'
)
export const ud0630_turnOffOutputCheckModal_okClick_watcher = createAction(
  'modalEvent/ud0630_turnOffOutputCheckModal_okClick_watcher'
)
export const ud0630_toExternalModal_useExternalClick_watcher = createAction(
  'modalEvent/ud0630_toExternalModal_useExternalClick_watcher'
)
export const ud0630_toExternalModal_cancelClick_watcher = createAction(
  'modalEvent/ud0630_toExternalModal_cancelClick_watcher'
)
