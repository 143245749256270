import React from 'react'

const ValueBox = ({ className, warning, icon, value, unit }) => {
  const isOff = value === null || value === undefined

  const valueColor = warning ? ' text-yellow' : ' text-white'

  if (isOff)
    return (
      <div className={`${className} ${containerClass} text-white text-lg`}>
        - - -
      </div>
    )

  return (
    <div className={`${className} ${containerClass}`}>
      <div className={iconUnitClass + ' text-lg'}>{icon}</div>
      <div className={'w-full text-lg' + valueColor}>{value}</div>
      <div className={iconUnitClass + ' text-xs -translate-x-[2px]'}>
        {unit}
      </div>
    </div>
  )
}

ValueBox.propTypes = {}

export default ValueBox

const containerClass = `w-[148px] h-8 
                        bg-dark-4 
                        rounded-[2px] 
                        border-1 border-solid border-dark-3 
                        text-center 
                        flex justify-center items-center 
                        px-[2px]
                    `

const iconUnitClass = `w-6 h-full 
                      flex justify-center items-center 
                      shrink-0 
                      text-white/30 
                      translate-y-[1px]
                      `
