import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import RefSourceFreq from 'src/containers/operating/device/freqConverter/udModule/DeviceSettings/RefSourceFreq'

import { modalActions } from 'src/redux/slices/modal'

import Stepper from 'src/components/Stepper'

import { h4, h6 } from 'src/containers/Modal/style'

import { BiDownArrowAlt } from 'react-icons/bi'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import {
  udModule_toExternalCheckModal_cancelClick_watcher,
  udModule_toExternalCheckModal_useExternalClick_watcher,
} from 'src/redux/actions/freqConverter/udmoduleAction'

const ToExternalCheck = ({ sn, inputFreq, isLoading, isSuccess }) => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData(sn)

  const handleInputFreqChange = (event, value) =>
    dispatch(
      modalActions.setModalOfUDModuleExternalCheckInputFreq({
        inputFreq: +value,
      })
    )

  const handleCancelClick = () =>
    dispatch(udModule_toExternalCheckModal_cancelClick_watcher())

  const handleUseExternalClick = () =>
    dispatch(udModule_toExternalCheckModal_useExternalClick_watcher())

  if (current.data)
    return (
      <Modal>
        <Modal.Title>Use External Reference Source</Modal.Title>

        <Stepper
          name='Use External Reference Source'
          className='mt-8'
          gap='gap-y-5'>
          <Stepper.Step>
            <h4 className={h4 + ' text-white'}>
              Connect to an external ref. source
            </h4>
            <p className={h6 + ' text-light-4 mt-1'}>
              Ensure the source is connected.
            </p>
          </Stepper.Step>
          <Stepper.Step>
            <h4 className={h4 + ' text-white'}>
              Turn on the external ref. source
            </h4>
            <p className={h6 + ' text-light-4 mt-1'}>
              Make sure the source has been transmitted.
            </p>
          </Stepper.Step>
          <Stepper.Step>
            <h4 className={h4 + ' text-white'}>
              Choose the Input Reference Source that matches your connection
            </h4>
          </Stepper.Step>
        </Stepper>

        <BiDownArrowAlt className='mx-auto text-light-4 text-4xl my-2' />

        <RefSourceFreq
          size='md'
          source='external'
          value={inputFreq}
          onChange={handleInputFreqChange}
        />

        <Modal.RowButtonBox>
          <CommonButton
            size='lg'
            type='outlined'
            disabled={isLoading || isSuccess}
            onClick={handleCancelClick}>
            Cancel
          </CommonButton>
          <CommonButton
            size='lg'
            type='primary'
            loading={isLoading}
            success={isSuccess}
            onClick={handleUseExternalClick}>
            Use External
          </CommonButton>
        </Modal.RowButtonBox>
      </Modal>
    )

  return <></>
}

export default ToExternalCheck
