import { createSlice, current } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    acceptPrivacyPolicy: true,
    isAutoSave: false,
    language: {
      currentLanguage: 'en',
      lstLanguageOptions: ['en'],
    },
    isDarkMode: true,
  },
  reducers: {
    setAcceptPrivacyPolicy(state, action) {
      const { value } = action.payload
      return { ...current(state), acceptPrivacyPolicy: value }
    },
  },
})

export const { setAcceptPrivacyPolicy } = settingsSlice.actions

export const settings = settingsSlice.reducer
