import { current } from '@reduxjs/toolkit'

const freqConverter = {
  // freqConverter
  // -- general
  // -- general
  // -- general
  // -- general
  // -- general
  showModalOfUDUnlockInput(state, action) {
    const { sn, digest, isFailed } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: { status: 'udUnlockInput', sn, digest, isFailed },
    }
    return state
  },
  setModalOfUDUnlockDigest(state, action) {
    const { digest } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        ...current(state).normal.props,
        digest,
        isFailed: false,
      },
    }
    return state
  },
  setModalOfUDUnlockFailed(state, action) {
    const { isFailed } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        ...current(state).normal.props,
        status: 'udUnlockInput',
        isFailed,
      },
    }
    return state
  },
  showModalOfUDUnlockRequesting(state, action) {
    const { sn, RF, IF, LO } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        ...current(state).normal.props,
        status: 'udUnlockRequesting',
        sn,
        RF,
        IF,
        LO,
      },
    }
    return state
  },
  showModalOfUDUnlockSuccess(state, action) {
    const { sn } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        ...current(state).normal.props,
        status: 'udUnlockSuccess',
        sn,
      },
    }
    return state
  },
  // -- udbox
  // -- udbox
  // -- udbox
  // -- udbox
  // -- udbox
  // 顯示 udbox 準備切到 external 的提示 modal
  showModalOfUDBoxExternalCheck: (state, action) => {
    // const { sn, isLoading, isSuccess } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: { status: 'udboxToExternalCheck', ...action.payload },
    }
    return state
  },
  // 顯示 udbox external 切換失敗的 modal
  showModalOfUDBoxExternalError: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: { status: 'udboxToExternalError', sn },
    }
    return state
  },

  // -- ud module
  // -- ud module
  // -- ud module
  // -- ud module
  // -- ud module
  // 顯示 ud module 準備切到 external 時 發現 output 是開的會關掉的提示 modal
  showModalOfUDModuleExternalTurnOffOutputCheck: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        status: 'udmoduleToExternalTurnOffOutputCheck',
        sn,
      },
    }
    return state
  },
  showModalOfUDModuleExternalCheck: (state, action) => {
    const { sn, inputFreq, isLoading, isSuccess } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        status: 'udmoduleToExternalCheck',
        inputFreq: inputFreq || current(state).normal.props.inputFreq,
        sn,
        isLoading,
        isSuccess,
      },
    }
    return state
  },
  setModalOfUDModuleExternalCheckInputFreq: (state, action) => {
    const inputFreq = action.payload?.inputFreq
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        ...current(state).normal.props,
        status: 'udmoduleToExternalCheck',
        inputFreq,
      },
    }
    return state
  },
  // -----  顯示 ud module external 切換失敗的 modal
  showModalOfUDModuleExternalError: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: { status: 'udmoduleToExternalError', sn },
    }
    return state
  },

  // ----- udbox 0630
  // ----- udbox 0630
  // ----- udbox 0630
  // ----- udbox 0630
  // ----- udbox 0630
  // ----- 顯示 udbox 0630 切 external 的確認 modal
  showModalOfUDBox0630ToExternalCheck(state, action) {
    const { sn } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: { status: 'udbox0630ToExternalCheck', sn },
    }
    return state
  },
  showModalOfUD0630ExternalTurnOffOutputCheck: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        status: 'ud0630ToExternalTurnOffOutputCheck',
        sn,
      },
    }
    return state
  },

  // ----- sg
  // ----- sg
  // ----- sg
  // ----- sg
  // ----- sg
  showModalOfSGToExternalCheck(state, action) {
    const { sn } = action.payload
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: { status: 'sgToExternalCheck', sn },
    }
    return state
  },
  showModalOfSGExternalTurnOffOutputCheck: (state, action) => {
    const sn = action.payload?.sn
    state.normal = {
      type: 'FREQ_CONVERTER',
      props: {
        status: 'sgToExternalCheckTurnOffOutputCheck',
        sn,
      },
    }
    return state
  },
}
export default freqConverter
