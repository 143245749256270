import React from 'react'

import BeamList from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList'
import Common from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/Common'

const BeamConfigEditor = props => {
  return (
    <div className='flex flex-col gap-y-4'>
      <Common />

      <BeamList />
    </div>
  )
}

BeamConfigEditor.propTypes = {}

export default BeamConfigEditor
