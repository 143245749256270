import React from 'react'

import Modal from 'src/components/Popup/Modal'

import { BiCheckCircle } from 'react-icons/bi'

const CaliConfigFileSaved = props => {
  return (
    <Modal>
      <Modal.Icon>
        <BiCheckCircle className='text-green ' />
      </Modal.Icon>

      <Modal.Title>Calibration config file saved</Modal.Title>

      <div className='w-full h-[72px]' />
    </Modal>
  )
}

export default CaliConfigFileSaved
