import React from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <ProgressBar
//  className=''
//  value={[]}
//  min={[]}
//  max={[]}
//  color={[]}
// />

const ProgressBar = ({ value, min, max, color, className }) => {
  const colorArray = color.length ? color : defaultColor

  const totalScale = R.subtract(R.sum(max), R.sum(min))

  const negativeValue = index => {
    const caliValue = +value[index] + Math.abs(min[index])
    return (caliValue / totalScale) * 100
  }
  const plusValue = index => ((value[index] - min[index]) / totalScale) * 100

  // 算出這個值佔該值的百分比多少 （支援負數）
  const valuePercent = index => {
    if (min[index] >= 0) return +plusValue(index)
    if (min[index] < 0) return +negativeValue(index)
  }

  // 得到所以 value 百分比組合成 array
  const valuePercentArray = value.map((e, i) => valuePercent(i))

  // 把個別 value 封裝成最後要塞進 style 的 gradient text
  //         [         起始線段         ] [       中間線段       ] [              沒有值線段              ]
  // 目標是： `起始顏色 0% 起始顏色 起始百分比 第二組顏色 第二組百分比... 沒有值的顏色 沒有值的百分比 沒有值的顏色 100%`
  function itemGradientCreate(
    currColor,
    nextColor,
    lastValue,
    currValue,
    nextValue
  ) {
    // 如果 value 只有一筆 直接處理該筆並補上 gray
    if (value.length === 1)
      return [
        `${currColor} ${currValue}%`,
        `${bufferColor} ${currValue}%`,
        `${bufferColor} 100%`,
      ]

    // 如果沒有前一筆（如果是第一筆）最前面補上 0%
    if (!lastValue && lastValue !== 0)
      return [
        `${colorArray[0]} 0%`,
        `${currColor} ${currValue}%`,
        `${nextColor} ${currValue}%`,
      ]

    // 如果沒有下一筆 （如果是最後一筆） 最尾端補上 100%
    if (!nextValue && nextValue !== 0)
      return [
        `${currColor} ${currValue}%`,
        `${bufferColor} ${currValue}%`,
        `${bufferColor} 100%`,
      ]

    // 如果有上一筆也有下一筆 則回傳一個區間
    return [`${currColor} ${currValue}%`, `${nextColor} ${currValue}%`]
  }

  // 這個變數是 每一個 item 會是之前所有 value 加總後，再加當前的 value
  // 這邊會用到的只有 result, totalValue 單純 reduce 內部計算用
  const valueForCurrentSumLastTotal = valuePercentArray.reduce(
    (prev, curr, i, a) => ({
      totalValue: +prev.totalValue + +curr,
      result: [...prev.result, +prev.totalValue + +curr],
    }),
    { totalValue: 0, result: [] }
  )

  // 先拿到各 value 的百分比階乘後 進而封裝成各 value 各自的 string
  const gradientCreate = valueForCurrentSumLastTotal.result.reduce(
    (prev, curr, index, arr) => [
      ...prev,
      ...itemGradientCreate(
        colorArray[index], // currColor
        colorArray[index + 1], // nextColor
        arr[index - 1], // lastValue
        curr, // currValue
        arr[index + 1] // nextValue
      ),
    ],
    []
  )

  // 沒有任何一筆 value 是 0, 用 percent 來判斷是因為有可能為負數
  const allValueIsNotZero = valuePercentArray.filter(e => e > 0).length > 0

  // 如果全部的值都是 0, 直接輸出整條是 bufferColor 的，反之進去 gradientCreate 建立漸變字串
  const gradient = allValueIsNotZero
    ? gradientCreate.join(',')
    : `${bufferColor} 0%, ${bufferColor} 100%`

  const gradientStyle = {
    background: `linear-gradient(90deg, ${gradient})`,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return <div className={`${className} ${commonClass}`} style={gradientStyle} />
}

export default ProgressBar

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const commonClass = 'h-[1px] w-full duration-500 rounded-lg select-none '

const defaultColor = ['white', 'yellow', 'orange', 'red', 'pink', 'blue']
const bufferColor = 'rgba(255, 255, 255, 0.2)'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
ProgressBar.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  min: PropTypes.arrayOf(PropTypes.number),
  max: PropTypes.arrayOf(PropTypes.number),
  color: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
}
