import React, { useEffect, useContext, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { TabsContext } from 'src/components/Tabs/context'

const CommonItem = forwardRef((props, ref) => {
  const { className, index, name, disabled, children } = props

  const {
    disabled: parentDisabled,
    activeName,
    activeIndex,
    handleChange,
  } = useContext(TabsContext)

  const isActive = activeName === name || activeIndex === index
  const isDisabled = (!isActive && parentDisabled) || (!isActive && disabled)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  // 若 active CommonItem 更新，會自動觸發父層 change 來更新狀態
  useEffect(() => {
    if (isActive) handleChange(null, name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = event => {
    if (!isDisabled) handleChange(event, name)
    if (isDisabled) event.stopPropagation()
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                        ${className} 
                        ${common} 
                        ${fontCommon} 
                        ${fontStroke(isActive)} 
                        ${fontColor(isDisabled, isActive)} 
                        ${borderCommon} 
                        ${borderColor(isActive)} 
                        ${cursor(isDisabled)} 
                        `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div ref={ref} name={name} className={containerClass} onClick={handleClick}>
      {children}
    </div>
  )
})
export default CommonItem

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const common = `pt-3 pb-[10px] px-2 select-none bg-transparent duration-500`

const fontCommon = `font-base leading-5 `
const fontStroke = active => (active ? `text-stroke-white text-stroke-1` : ``)
const fontColor = (disabled, active) => {
  if (disabled) return 'text-dark-5'

  if (active) return `text-white`

  return `text-light-4 hover:text-white`
}

// 為了視覺上美觀 margin bottom 設 1px 抬起來一點
const borderCommon = `border-b-2 border-solid mb-[1px]`
const borderColor = active => (active ? 'border-teal' : 'border-transparent')

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonItem.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
}
