const home = {
  showModalOfHomeSetting: (state, action) => {
    state.normal = { type: 'HOME', props: { status: 'settings' } }
    return state
  },
  showModalOfHomeCaliReminder: (state, action) => {
    state.normal = { type: 'HOME', props: { status: 'reminderNotify' } }
    return state
  },
  showModalOfHomeFirmwareUpdate: (state, action) => {
    state.normal = { type: 'HOME', props: { status: 'firmwareNotify' } }
    return state
  },
  showModalOfLeaveDeviceInfoCheck: (state, action) => {
    state.normal = { type: 'HOME', props: { status: 'leaveDeviceInfoCheck' } }
    return state
  },
  showModalOfApplyDeviceInfoCheck: (state, action) => {
    state.normal = { type: 'HOME', props: { status: 'applyDeviceInfoCheck' } }
    return state
  },
}
export default home
