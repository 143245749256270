import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Sg = props => {
  return (
    <SVGUniqueID>
      <svg
        width='48'
        height='49'
        viewBox='0 0 48 49'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}>
        <mask id='path-1-inside-1_16146_729' fill='white'>
          <path d='M11 39.5C9.89543 39.5 9 38.6046 9 37.5L9 11.5C9 10.3954 9.89543 9.5 11 9.5L37 9.5C38.1046 9.5 39 10.3954 39 11.5L39 37.5C39 38.6046 38.1046 39.5 37 39.5L11 39.5Z' />
        </mask>
        <path
          d='M39 37.5L38 37.5L39 37.5ZM37 39.5L37 38.5L37 39.5ZM39 11.5L40 11.5L39 11.5ZM11 39.5L11 40.5L11 39.5ZM10 37.5L10 11.5L8 11.5L8 37.5L10 37.5ZM11 10.5L37 10.5L37 8.5L11 8.5L11 10.5ZM38 11.5L38 37.5L40 37.5L40 11.5L38 11.5ZM37 38.5L11 38.5L11 40.5L37 40.5L37 38.5ZM38 37.5C38 38.0523 37.5523 38.5 37 38.5L37 40.5C38.6569 40.5 40 39.1569 40 37.5L38 37.5ZM37 10.5C37.5523 10.5 38 10.9477 38 11.5L40 11.5C40 9.84314 38.6569 8.5 37 8.5L37 10.5ZM10 11.5C10 10.9477 10.4477 10.5 11 10.5L11 8.5C9.34315 8.5 8 9.84314 8 11.5L10 11.5ZM8 37.5C8 39.1569 9.34315 40.5 11 40.5L11 38.5C10.4477 38.5 10 38.0523 10 37.5L8 37.5Z'
          fill='#C2C2C2'
          mask='url(#path-1-inside-1_16146_729)'
        />
        <rect
          x='6'
          y='21.5'
          width='3'
          height='4'
          rx='0.5'
          transform='rotate(-90 6 21.5)'
          fill='#C4C4C4'
        />
        <rect
          x='6'
          y='30.5'
          width='3'
          height='4'
          rx='0.5'
          transform='rotate(-90 6 30.5)'
          fill='#C4C4C4'
        />
        <rect
          x='38'
          y='20.5'
          width='3'
          height='4'
          rx='0.5'
          transform='rotate(-90 38 20.5)'
          fill='#C4C4C4'
        />
        <rect
          x='38'
          y='26.5'
          width='3'
          height='4'
          rx='0.5'
          transform='rotate(-90 38 26.5)'
          fill='#C4C4C4'
        />
        <rect
          x='32'
          y='14.5001'
          width='2.95455'
          height='3'
          rx='1.47727'
          fill='#D9D9D9'
          fillOpacity='0.1'
          stroke='#C2C2C2'
        />
        <rect
          x='32'
          y='25.5001'
          width='2.95455'
          height='3'
          rx='1.47727'
          fill='#D9D9D9'
          fillOpacity='0.1'
          stroke='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default Sg
