import React from 'react'

import AllPower from 'src/components/composite/beamformers/ChannelModule/AllPower'
import CommonGain from 'src/components/composite/beamformers/ChannelModule/CommonGain'
import GainPhase from 'src/components/composite/beamformers/ChannelModule/GainPhase'

const Channel = props => {
  // param
  const { sn, boardData, channelCount } = props

  // event
  const {
    handleAllPowerClick,
    handleCommonGainSliderChange,
    handleCommonGainChange,
    handleSinglePowerClick,
    handleSingleGainChange,
    handleSinglePhaseChange,
  } = props

  const getChTitleIdx = (boardIndex, chIndex) =>
    boardIndex * channelCount + chIndex + 1

  return (
    <div className={container}>
      <AllPower {...{ boardData, handleAllPowerClick }} />

      {boardData?.map((boardItem, boardIndex) => (
        <div key={'bboxBoardIndex: ' + boardIndex} className={RFBoard}>
          <CommonGain
            {...boardItem}
            {...{
              sn,
              boardIndex,
              handleCommonGainSliderChange,
              handleCommonGainChange,
            }}
          />

          <div className={chRow}>
            {boardItem.lstChannelData.map((chItem, channelIndex) => (
              <GainPhase
                key={'bboxCHIndex: ' + getChTitleIdx(boardIndex, channelIndex)}
                {...boardItem}
                {...chItem}
                {...{
                  sn,
                  boardIndex,
                  channelIndex,
                  channelTitleIndex: getChTitleIdx(boardIndex, channelIndex),
                  handleSinglePowerClick,
                  handleSingleGainChange,
                  handleSinglePhaseChange,
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Channel

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[Components-composite-beamformers-ChannelModule-container] 
                    functionBlockBackground 
                    relative 
                    w-full
                    pb-3
                    rounded-md 
                    `

const RFBoard = `[Components-composite-beamformers-ChannelModule-RFboard] 
                  w-full 
                  odd:bg-light-4/10
                  `

const chRow = `[Components-composite-beamformers-ChannelModule-chRow] 
                flex flex-row-reverse 
                gap-x-2 
                px-5 py-2
                `
