import {
  connect as handleConnect,
  disconnect as handleDisconnect,
  // connectError as handleConnectError,
} from 'src/redux/sagas/handlers/event/socket/freqConvertor'

import { init as handleInitResponse } from 'src/redux/sagas/handlers/response/freqConverter/general/init'
import { updateCurrent as handle_updateCurrent_response } from 'src/redux/sagas/handlers/response/freqConverter/general/current'
import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import {
  getLO as handleGetLOResponse,
  apply as handleApplyResponse,
  freqSettingUnlock as handleFreqSettingUnlock,
} from 'src/redux/sagas/handlers/response/freqConverter/general/freq'
import { freqSettingUnlock as handleFreqSettingUnlockErrorResponse } from 'src/redux/sagas/handlers/errorResponse/freqConverter/general/freq'

import { deviceSetting as handleDeviceSettingResponse } from 'src/redux/sagas/handlers/response/freqConverter/udbox/device'
import { deviceSetting as handleDeviceSettingErrorResponse } from 'src/redux/sagas/handlers/errorResponse/freqConverter/udbox/device'

import {
  deviceSettingRefSourceChange as handleDeviceSettingRefSourceChangeResponse,
  deviceSettingOutputChange as handleDeviceSettingOutputChangeResponse,
  deviceSettingInputChange as handleDeviceSettingInputChangeResponse,
} from 'src/redux/sagas/handlers/response/freqConverter/udmodule/device'
import { deviceSettingRefSourceChange as handleDeviceSettingRefSourceChangeErrorResponse } from 'src/redux/sagas/handlers/errorResponse/freqConverter/udmodule/device'

import {
  setRefSource as handle_setRefSource_response,
  outputRefSource as handle_outputRefSource_response,
  outputLOFrequency as handle_outputLOFrequency_response,
} from 'src/redux/sagas/handlers/response/freqConverter/udbox0630/device'

import {
  sgDeviceSettingsRefSourceChange as handle_sgDeviceSettingsRefSourceChange,
  sgDeviceSettingsOutputSource10MHzChange as handle_sgDeviceSettingsOutputSource10MHzChange,
} from 'src/redux/sagas/handlers/response/freqConverter/sg/deviceSettings'

const freqConverterApi = {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------      General       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  INIT_DEVICE: {
    emit: {
      server: 'freqConverter',
      eventName: 'initDevice',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 10000,
    },
    on: {
      eventName: 'initDevice/response',
      handler: handleInitResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  UPDATE_CURRENT: {
    emit: {
      server: 'freqConverter',
      eventName: 'update/current',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 10000,
    },
    on: {
      eventName: 'update/current/response',
      handler: handle_updateCurrent_response,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  GET_LO: {
    emit: {
      server: 'freqConverter',
      eventName: 'freqSetting/getLO',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'freqSetting/getLO/response',
      handler: handleGetLOResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  FREQ_SETTING_APPLY: {
    emit: {
      server: 'freqConverter',
      eventName: 'freqSetting/apply',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'freqSetting/apply/response',
      handler: handleApplyResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  FREQ_SETTING_UNLOCK_RANGE: {
    emit: {
      server: 'freqConverter',
      eventName: 'freqSetting/unlock/range',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'freqSetting/unlock/range/response',
      handler: handleFreqSettingUnlock,
      errorHandler: handleFreqSettingUnlockErrorResponse,
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       UD Box       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  DEVICE_SETTINGS: {
    emit: {
      server: 'freqConverter',
      eventName: 'deviceSetting',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 8000,
    },

    on: {
      eventName: 'deviceSetting/response',
      handler: handleDeviceSettingResponse,
      errorHandler: handleDeviceSettingErrorResponse,
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     UD Module      -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  DEVICE_SET_REF_SOURCE: {
    emit: {
      server: 'freqConverter',
      eventName: 'device/set/refSource',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 6000,
    },

    on: {
      eventName: 'device/set/refSource/response',
      handler: handleDeviceSettingRefSourceChangeResponse,
      errorHandler: handleDeviceSettingRefSourceChangeErrorResponse,
    },
  },

  DEVICE_INTERNAL_SET_OUTPUT_FREQ: {
    emit: {
      server: 'freqConverter',
      eventName: 'device/internal/set/output/freq',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'device/internal/set/output/freq/response',
      handler: handleDeviceSettingOutputChangeResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  DEVICE_EXTERNAL_SET_INPUT_FREQ: {
    emit: {
      server: 'freqConverter',
      eventName: 'device/external/set/input/freq',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'device/external/set/input/freq/response',
      handler: handleDeviceSettingInputChangeResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     UDBox 0630     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  UD0630_SET_REF_SOURCE: {
    emit: {
      server: 'freqConverter',
      eventName: 'udbox0630/set/refSource',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'udbox0630/set/refSource/response',
      handler: handle_setRefSource_response,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  UD0630_OUTPUT_REF_SOURCE: {
    emit: {
      server: 'freqConverter',
      eventName: 'udbox0630/output/refSource',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'udbox0630/output/refSource/response',
      handler: handle_outputRefSource_response,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  UD0630_OUTPUT_LO_FREQUENCY: {
    emit: {
      server: 'freqConverter',
      eventName: 'udbox0630/output/LO/frequency',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'udbox0630/output/LO/frequency/response',
      handler: handle_outputLOFrequency_response,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------         SG         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  SG_SET_REF_SOURCE: {
    emit: {
      server: 'freqConverter',
      eventName: 'sg/set/refSource',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'sg/set/refSource/response',
      handler: handle_sgDeviceSettingsRefSourceChange,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  SG_OUTPUT_REF_SOURCE: {
    emit: {
      server: 'freqConverter',
      eventName: 'sg/output/refSource',
      beforeEmitActionType: null,
      throttle: false,
      timeout: null,
    },

    on: {
      eventName: 'sg/output/refSource/response',
      handler: handle_sgDeviceSettingsOutputSource10MHzChange,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------   Socket Listener   -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // socket listener
  SOCKET_CONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'connect',
      handler: handleConnect,
    },
  },
  SOCKET_DISCONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'disconnect',
      handler: handleDisconnect,
    },
  },
  // SOCKET_CONNECT_ERROR: {
  //   on: {
  //     type: 'SOCKET_EVENT',
  //     eventName: 'connect_error',
  //     handler: handleConnectError,
  //   },
  // },
}

export default freqConverterApi
