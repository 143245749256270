import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Phi({ className }) {
  return (
    <SVGUniqueID>
      <svg
        viewBox='0 0 14 14'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.5' clipPath='url(#clip0_689_23273)'>
          <path d='M6.46712 14.4333V4.09418C6.46712 3.73222 6.54727 3.42196 6.70757 3.16342C6.87303 2.89971 7.10055 2.69546 7.39012 2.55067C7.68486 2.40589 8.02614 2.3335 8.41395 2.3335C8.92587 2.3335 9.40676 2.43691 9.85663 2.64375C10.3117 2.85058 10.7124 3.14274 11.0589 3.52021C11.4053 3.89251 11.6768 4.33462 11.8733 4.84654C12.0698 5.35329 12.168 5.90657 12.168 6.50639C12.168 7.08036 12.0775 7.63106 11.8965 8.15848C11.7156 8.68074 11.426 9.14871 11.0278 9.56238C10.6348 9.97088 10.1203 10.2941 9.48433 10.5319C8.85348 10.7698 8.0856 10.8887 7.1807 10.8887C6.29648 10.8887 5.54671 10.7672 4.93137 10.5242C4.31604 10.276 3.81705 9.93985 3.4344 9.51584C3.05176 9.09183 2.77253 8.60835 2.59672 8.06541C2.42091 7.5173 2.33301 6.94591 2.33301 6.35126C2.33301 5.77213 2.40799 5.23436 2.55794 4.73795C2.7079 4.24155 2.93024 3.79168 3.22498 3.38835C3.51972 2.97986 3.88427 2.62824 4.31862 2.3335L5.09425 3.35733C4.78917 3.62621 4.54097 3.92612 4.34965 4.25706C4.15833 4.588 4.01613 4.93186 3.92305 5.28865C3.83515 5.64544 3.78344 5.99964 3.76793 6.35126C3.76793 6.75459 3.82739 7.15534 3.94632 7.55349C4.06525 7.94648 4.25657 8.30585 4.52029 8.63162C4.784 8.95738 5.13304 9.21851 5.56739 9.41501C6.00691 9.6115 6.54468 9.70975 7.1807 9.70975C8.05458 9.70975 8.75006 9.54945 9.26715 9.22886C9.78424 8.90309 10.1565 8.49459 10.3841 8.00336C10.6167 7.51213 10.7331 7.01314 10.7331 6.50639C10.7279 6.12892 10.6736 5.76178 10.5702 5.40499C10.4668 5.0482 10.3168 4.72761 10.1203 4.44321C9.92902 4.15881 9.69116 3.93388 9.40676 3.76841C9.12236 3.59777 8.79143 3.51246 8.41395 3.51246C8.24849 3.51246 8.1218 3.56675 8.0339 3.67534C7.94599 3.78393 7.90204 3.90544 7.90204 4.03988V14.4333H6.46712Z' />
        </g>
        <defs>
          <clipPath id='clip0_689_23273'>
            <rect width='14' height='14' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
