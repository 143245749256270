import React from 'react'
import { Box, Line } from '@react-three/drei'

import { degreeToRadian } from 'src/components/composite/beamformers/Beam3DControl/tools'

const DragHVModeReference = ({ dragHVMode }) => {
  const color = `#666666`

  return (
    <group
      rotation={
        dragHVMode === 'horizon' ? [0, 0, 0] : [0, degreeToRadian(90), 0]
      }>
      <Arrow
        points={[
          [3.8, 0.1, -0.3],
          [4.1, 0.1, 0],
          [3.8, 0.1, 0.3],
        ]}
        color={color}
      />
      <AxisLine color={color} args={[8.1, 0.1, 0.1]} />
      <Arrow
        points={[
          [-3.8, 0.1, -0.3],
          [-4.1, 0.1, 0],
          [-3.8, 0.1, 0.3],
        ]}
        color={color}
      />
    </group>
  )
}

const AxisLine = props => {
  return (
    <Box args={props.args} position={[0, 0.1, 0]}>
      <meshBasicMaterial color={props.color} transparent opacity={1} />
    </Box>
  )
}
const Arrow = ({ points, color }) => (
  <Line points={points} color={color} transparent opacity={1} lineWidth='3' />
)

export default DragHVModeReference
