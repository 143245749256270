import React from 'react'

import { h6 } from 'src/containers/operating/device/beamformers/style'

import ImitationTable from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/ImitationTable'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const PowerDetectorOutput = props => {
  const sideLabel = ['RF_1', 'RF_2', 'RF_3', 'RF_4']

  const { current } = useGetCurrentDeviceData()

  const { pdt } = current.data.current

  const col1 = {
    title: 'H-Polarization',
    data: pdt.horizon.currentValue.map(e => ({ unit: 'dBm', value: e })),
  }
  const col2 = {
    title: 'V-Polarization',
    data: pdt.vertical.currentValue.map(e => ({ unit: 'dBm', value: e })),
  }

  return (
    <div>
      <h6 className={h6 + ' text-white mb-4'}>Power Detector Output</h6>

      <ImitationTable
        {...{ name: 'power detector output', sideLabel, col1, col2 }}
      />
    </div>
  )
}

PowerDetectorOutput.propTypes = {}

export default PowerDetectorOutput
