import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

import { bbox_configFailedToImportNoMatchedAntennaModal_okClick_watcher } from 'src/redux/actions/beamformers/bboxAction'

const FailedToImportForNoMatchedAntenna = props => {
  const dispatch = useDispatch()
  const { bindAntenna } = props
  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red' />
      </Modal.Icon>

      <Modal.Title>Failed to import</Modal.Title>

      <Modal.Description>
        Unable to locate antenna file: <br />
        {bindAntenna} <br />
        Please import or try another config file.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          type='general'
          size='lg'
          onClick={() =>
            dispatch(
              bbox_configFailedToImportNoMatchedAntennaModal_okClick_watcher()
            )
          }>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

FailedToImportForNoMatchedAntenna.propTypes = {}

export default FailedToImportForNoMatchedAntenna
