import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './sagas/rootSaga'
import rootReducers from './slices'

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    store.dispatch({ type: 'ERROR', payload: error })
  },
})

export const store = configureStore({
  reducer: rootReducers,
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
})
sagaMiddleware.run(rootSaga)

export default store
