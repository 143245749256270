import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function IF({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={`${className} w-full h-full `}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.62793 6V18.7969H5V6H7.62793Z' fill='#C2C2C2' />
        <path
          d='M12.7344 6V18.7969H10.0977V6H12.7344ZM17.832 11.4668V13.5234H12.0137V11.4668H17.832ZM18.4473 6V8.06543H12.0137V6H18.4473Z'
          fill='#C2C2C2'
        />
      </svg>
    </SVGUniqueID>
  )
}
