import React from 'react'
import { useDispatch } from 'react-redux'

import {
  box,
  subtitle,
} from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/style'

import SegmentedButton from 'src/components/Button/SegmentedButton'

import { ud0630_deviceSettings_refSignalSource_change_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

const RefSignalSource = () => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const { refSource } = current.data.settings.device

  const handleChange = (event, value) =>
    dispatch(
      ud0630_deviceSettings_refSignalSource_change_watcher({ sn, value })
    )

  return (
    <div className={box}>
      <h4 className={subtitle}>Reference Signal Source</h4>

      <SegmentedButton
        size={isSM || isMD ? 'md' : 'sm'}
        type='outlined'
        value={refSource}
        onChange={handleChange}>
        <SegmentedButton.Option name={0}>Internal</SegmentedButton.Option>
        <SegmentedButton.Option name={1}>External</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

export default RefSignalSource
