import React from 'react'
import { useDispatch } from 'react-redux'

import CommonTabs from 'src/components/Tabs/CommonTabs'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetUIControl from 'src/hooks/useGetUIControl'

import { setFunctionTabs } from 'src/redux/slices/uiControl/beamformers/bbox'

const FunctionTabs = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const controlMethod = current.data.beamConfigEditor.controlMethod

  const { single: s_uiControl } = useGetUIControl(sn)
  const functionTabs = s_uiControl.functionTabs

  return (
    <CommonTabs
      value={functionTabs}
      className='mb-6'
      onChange={(event, value) => dispatch(setFunctionTabs({ sn, value }))}>
      <CommonTabs.Item
        name='Device Controller'
        disabled={controlMethod === 'SPI'}>
        Device Controller
      </CommonTabs.Item>

      <CommonTabs.Item name='Beam Config Editor'>
        Beam Config Editor
      </CommonTabs.Item>
    </CommonTabs>
  )
}

FunctionTabs.propTypes = {}

export default FunctionTabs
