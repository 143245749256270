import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Board = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='164'
        height='166'
        viewBox='0 0 164 166'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M25.8404 13.0614C26.6892 12.5584 27.6576 12.293 28.6443 12.293H135.356C136.342 12.293 137.311 12.5584 138.16 13.0614L160.606 26.3628C161.043 26.6221 160.86 27.293 160.351 27.293H3.64905C3.14049 27.293 2.95665 26.6221 3.39414 26.3628L25.8404 13.0614Z'
          fill='#3D3D3D'
          stroke='white'
        />
        <path
          d='M0.563317 27.8392C0.537233 26.9931 1.21607 26.293 2.0626 26.293H161.937C162.784 26.293 163.463 26.9931 163.437 27.8392L159.244 163.839C159.219 164.649 158.555 165.293 157.745 165.293H6.25509C5.44465 165.293 4.78077 164.649 4.7558 163.839L0.563317 27.8392Z'
          fill='#222222'
          stroke='white'
        />
        <rect
          x='48.5'
          y='56.293'
          width='67'
          height='67'
          rx='15.5'
          fill='#121212'
          stroke='white'
        />
        <rect
          x='62.5'
          y='70.293'
          width='39'
          height='39'
          rx='1.5'
          stroke='white'
        />
        <path
          d='M28 33.793C28 34.8975 27.1046 35.793 26 35.793C24.8954 35.793 24 34.8975 24 33.793C24 32.6884 24.8954 31.793 26 31.793C27.1046 31.793 28 32.6884 28 33.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M56 33.793C56 34.8975 55.1046 35.793 54 35.793C52.8954 35.793 52 34.8975 52 33.793C52 32.6884 52.8954 31.793 54 31.793C55.1046 31.793 56 32.6884 56 33.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M84 33.793C84 34.8975 83.1046 35.793 82 35.793C80.8954 35.793 80 34.8975 80 33.793C80 32.6884 80.8954 31.793 82 31.793C83.1046 31.793 84 32.6884 84 33.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M112 33.793C112 34.8975 111.105 35.793 110 35.793C108.895 35.793 108 34.8975 108 33.793C108 32.6884 108.895 31.793 110 31.793C111.105 31.793 112 32.6884 112 33.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M140 33.793C140 34.8975 139.105 35.793 138 35.793C136.895 35.793 136 34.8975 136 33.793C136 32.6884 136.895 31.793 138 31.793C139.105 31.793 140 32.6884 140 33.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M28 157.793C28 158.898 27.1046 159.793 26 159.793C24.8954 159.793 24 158.898 24 157.793C24 156.688 24.8954 155.793 26 155.793C27.1046 155.793 28 156.688 28 157.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M56 157.793C56 158.898 55.1046 159.793 54 159.793C52.8954 159.793 52 158.898 52 157.793C52 156.688 52.8954 155.793 54 155.793C55.1046 155.793 56 156.688 56 157.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M84 157.793C84 158.898 83.1046 159.793 82 159.793C80.8954 159.793 80 158.898 80 157.793C80 156.688 80.8954 155.793 82 155.793C83.1046 155.793 84 156.688 84 157.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M112 157.793C112 158.898 111.105 159.793 110 159.793C108.895 159.793 108 158.898 108 157.793C108 156.688 108.895 155.793 110 155.793C111.105 155.793 112 156.688 112 157.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M140 157.793C140 158.898 139.105 159.793 138 159.793C136.895 159.793 136 158.898 136 157.793C136 156.688 136.895 155.793 138 155.793C139.105 155.793 140 156.688 140 157.793Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M154.396 55.8174C153.292 55.7789 152.428 54.8527 152.467 53.7488C152.505 52.6449 153.432 51.7813 154.535 51.8198C155.639 51.8584 156.503 52.7845 156.464 53.8884C156.426 54.9923 155.5 55.856 154.396 55.8174Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M153.419 83.8008C152.315 83.7623 151.451 82.8361 151.49 81.7322C151.528 80.6283 152.454 79.7647 153.558 79.8032C154.662 79.8418 155.526 80.7679 155.487 81.8718C155.449 82.9757 154.523 83.8394 153.419 83.8008Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M152.441 111.783C151.337 111.745 150.474 110.819 150.512 109.715C150.551 108.611 151.477 107.747 152.581 107.786C153.685 107.824 154.548 108.75 154.51 109.854C154.471 110.958 153.545 111.822 152.441 111.783Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M151.464 139.767C150.36 139.728 149.497 138.802 149.535 137.698C149.574 136.594 150.5 135.731 151.604 135.769C152.708 135.808 153.571 136.734 153.533 137.838C153.494 138.942 152.568 139.805 151.464 139.767Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M9.72871 55.8545C8.62481 55.893 7.69868 55.0294 7.66013 53.9255C7.62158 52.8216 8.48521 51.8955 9.58911 51.8569C10.693 51.8184 11.6191 52.682 11.6577 53.7859C11.6962 54.8898 10.8326 55.8159 9.72871 55.8545Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M10.7062 83.8369C9.60235 83.8754 8.67621 83.0118 8.63767 81.9079C8.59912 80.804 9.46275 79.8779 10.5666 79.8393C11.6705 79.8008 12.5967 80.6644 12.6352 81.7683C12.6738 82.8722 11.8101 83.7983 10.7062 83.8369Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M11.6833 111.82C10.5794 111.859 9.65327 110.995 9.61472 109.891C9.57617 108.787 10.4398 107.861 11.5437 107.823C12.6476 107.784 13.5737 108.648 13.6123 109.752C13.6508 110.856 12.7872 111.782 11.6833 111.82Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M12.6603 139.803C11.5565 139.841 10.6303 138.978 10.5918 137.874C10.5532 136.77 11.4169 135.844 12.5207 135.805C13.6246 135.767 14.5508 136.63 14.5893 137.734C14.6279 138.838 13.7642 139.764 12.6603 139.803Z'
          fill='white'
          fillOpacity='0.3'
        />
        <path
          d='M22 17.293H21.5V17.793V21.793C21.5 22.0691 21.2761 22.293 21 22.293H11.0554C10.7687 22.293 10.5407 22.0523 10.5562 21.766L11.4224 5.71968C11.4818 4.61834 11.9437 3.57719 12.7202 2.79395L12.888 2.62467C13.733 1.77239 14.8835 1.29297 16.0836 1.29297H25.1565C27.0261 1.29297 28.5656 2.76238 28.6527 4.62997L28.9872 11.8052L28.9957 15.7898C28.9975 16.6194 28.3254 17.293 27.4957 17.293H22Z'
          fill='#3D3D3D'
          stroke='white'
        />
        <path
          d='M21.5 17.793V8.11277C21.5 7.63609 21.8365 7.22568 22.3039 7.13219L25.3039 6.53219C25.9227 6.40843 26.5 6.88173 26.5 7.51277V17.293'
          stroke='white'
        />
        <path
          d='M142.5 17.293H143V17.793V21.793C143 22.0691 143.224 22.293 143.5 22.293H153.445C153.731 22.293 153.959 22.0523 153.944 21.766L153.078 5.71968C153.018 4.61834 152.556 3.57719 151.78 2.79395L151.612 2.62467C150.767 1.77239 149.617 1.29297 148.416 1.29297H139.344C137.474 1.29297 135.934 2.76238 135.847 4.62997L135.513 11.8052L135.504 15.7898C135.503 16.6194 136.175 17.293 137.004 17.293H142.5Z'
          fill='#3D3D3D'
          stroke='white'
        />
        <path
          d='M143 17.793V7.6493C143 7.17575 142.668 6.76713 142.204 6.67039L139.204 6.0443C138.583 5.91468 138 6.38875 138 7.02321V17.2712'
          stroke='white'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default Board
