import { init as handleInitResponse } from 'src/redux/sagas/handlers/response/powerDetector/init'
import { selectCurrentCaliID as handleSelectCurrentCaliIDResponse } from 'src/redux/sagas/handlers/response/powerDetector/actionPanel'
import {
  insertCaliData as handleInsertCaliData,
  updateCaliData as handleUpdateCaliData,
  deleteCaliData as handleDeleteCaliData,
  getCaliVoltage as handleGetCaliVoltage,
} from 'src/redux/sagas/handlers/response/powerDetector/customCali'

import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'
import { getCaliVoltage as handleGetCaliVoltageErrorResponse } from 'src/redux/sagas/handlers/errorResponse/powerDetector/customCali'
import { selectCurrentCaliID as handleSelectCurrentCaliIDErrorResponse } from 'src/redux/sagas/handlers/errorResponse/powerDetector/actionPanel'

import {
  connect as handleConnect,
  disconnect as handleDisconnect,
  // connectError as handleConnectError,
} from 'src/redux/sagas/handlers/event/socket/powerDetector'

const freqConverterApi = {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------      General       -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  INIT_DEVICE: {
    emit: {
      server: 'powerDetector',
      eventName: 'initDevice',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 10000,
    },
    on: {
      eventName: 'initDevice/response',
      handler: handleInitResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_SELECT_CURRENT_CALI_ID: {
    emit: {
      server: 'powerDetector',
      eventName: 'actionPanel/select/currentCaliID',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/select/currentCaliID/response',
      handler: handleSelectCurrentCaliIDResponse,
      errorHandler: handleSelectCurrentCaliIDErrorResponse,
    },
  },

  ACTION_PANEL_INSERT_CALI_DATA: {
    emit: {
      server: 'powerDetector',
      eventName: 'actionPanel/insert/cali/data',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/insert/cali/data/response',
      handler: handleInsertCaliData,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_UPDATE_CALI_DATA: {
    emit: {
      server: 'powerDetector',
      eventName: 'actionPanel/update/cali/data',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/update/cali/data/response',
      handler: handleUpdateCaliData,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_DELETE_CALI_DATA: {
    emit: {
      server: 'powerDetector',
      eventName: 'actionPanel/delete/cali/data',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/delete/cali/data/response',
      handler: handleDeleteCaliData,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  ACTION_PANEL_GET_CALI_VOLTAGE: {
    emit: {
      server: 'powerDetector',
      eventName: 'actionPanel/get/cali/voltage',
      beforeEmitActionType: null,
      throttle: false,
      timeout: 3000,
    },
    on: {
      eventName: 'actionPanel/get/cali/voltage/response',
      handler: handleGetCaliVoltage,
      errorHandler: handleGetCaliVoltageErrorResponse,
    },
  },

  // socket listener
  SOCKET_CONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'connect',
      handler: handleConnect,
    },
  },
  SOCKET_DISCONNECT: {
    on: {
      type: 'SOCKET_EVENT',
      eventName: 'disconnect',
      handler: handleDisconnect,
    },
  },
  // SOCKET_CONNECT_ERROR: {
  //   on: {
  //     type: 'SOCKET_EVENT',
  //     eventName: 'connect_error',
  //     handler: handleConnectError,
  //   },
  // },
}

export default freqConverterApi
