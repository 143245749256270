import React from 'react'

import MiddlewareNotRunning from 'src/containers/Modal/Normal/DownloadMid/MiddlewareNotRunning'
import ConnectAwsFailed from 'src/containers/Modal/Normal/DownloadMid/ConnectAwsFailed'
import GetInfoFailed from 'src/containers/Modal/Normal/DownloadMid/GetInfoFailed'
import DownloadNewMiddleware from 'src/containers/Modal/Normal/DownloadMid/DownloadNewMiddleware'

const DownloadMid = modalProps => {
  const { status: modalStatus } = modalProps

  if (modalStatus === 'notRunning') return <MiddlewareNotRunning />
  if (modalStatus === 'connectAwsFailed') return <ConnectAwsFailed />
  if (modalStatus === 'getInfoFailed') return <GetInfoFailed />
  if (modalStatus === 'downloadNewMid') return <DownloadNewMiddleware />
  return <></>
}

export default DownloadMid
