import React, { useRef, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Tooltip from 'src/components/Popup/Tooltip'

import { BiScan } from 'react-icons/bi'
import { iconBox } from 'src/containers/home/style'

import { scanWatcher } from 'src/redux/slices/deviceData'

const Sidebar = () => {
  const dispatch = useDispatch()
  const containerRef = useRef()
  const [containerHeight, setContainerHeight] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setContainerHeight(containerRef.current.clientHeight)
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleScanClick = () => dispatch(scanWatcher())

  const tooltipClass = `break-normal whitespace-nowrap`

  const containerStyle = { top: `calc(50vh - ${containerHeight / 2}px)` }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <aside className={container} ref={containerRef} style={containerStyle}>
      <Tooltip content='Re-scan' placement='left' className={tooltipClass}>
        <div className={iconBox} onClick={handleScanClick}>
          <BiScan />
        </div>
      </Tooltip>
    </aside>
  )
}

Sidebar.propTypes = {
  setShowInfo: PropTypes.func,
}

const containerFlex = 'flex flex-col justify-center gap-y-10'
const container = `${containerFlex} 
                    fixed z-10 
                    right-4 md:right-8 
                    2xl:right-[calc(20px_+_((100vw_-_1440px)_/_2))]
                    `

export default Sidebar
