import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import {
  common_refSignalSourceChange as handle_common_refSignalSourceChange_response,
  common_frequencyChange as handle_common_frequencyChange_response,
} from 'src/redux/sagas/handlers/response/beamformers/cloverCellCttc/common'
import { steering_controlPatternChange as handle_steering_controlPatternChange_response } from 'src/redux/sagas/handlers/response/beamformers/cloverCellCttc/steering'
import { common_refSignalSourceChange as handle_common_refSignalSourceChange_error_response } from 'src/redux/sagas/handlers/errorResponse/beamformers/cloverCellCttc/common'

const cloverCellCttcApi = {
  CLOVERCELL_CTTC_COMMON_FREQUENCY: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/common/frequency',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/common/frequency/response',
      handler: handle_common_frequencyChange_response,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  CLOVERCELL_CTTC_COMMON_SET_REF_SOURCE: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/common/set/refSource',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/common/set/refSource/response',
      handler: handle_common_refSignalSourceChange_response,
      errorHandler: handle_common_refSignalSourceChange_error_response,
    },
  },

  CLOVERCELL_CTTC_STEERING_SET_CONTROL_PATTERN: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/steering/set/controlPattern',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/steering/set/controlPattern/response',
      handler: handle_steering_controlPatternChange_response,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_CTTC_STEERING_SET_BEAM_SYNTHESIS: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/steering/set/beamSynthesis',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/steering/set/beamSynthesis/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_CTTC_STEERING_SET_RF_MODE: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/steering/set/rfMode',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/steering/set/rfMode/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_CTTC_STEERING_SET_POWER: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/steering/set/power',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/steering/set/power/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },

  CLOVERCELL_CTTC_STEERING_SET_THETA_PHI_GAIN: {
    emit: {
      server: 'beamformers',
      eventName: 'clovercell/cttc/steering/set/thetaPhiGain',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'clovercell/cttc/steering/set/thetaPhiGain/response',
      handler: () => {},
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default cloverCellCttcApi
