import React, { forwardRef } from 'react'
import InputNumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

import InputForNumber from './Number'

const Input = forwardRef((props, ref) => {
  // both args
  const {
    id,
    value,
    emptyValue,
    className = '',
    inputType = 'text',
    disabled,
    placeholder,
    decimalScale,
    autoFocus,
    onChange = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
    onBlur = () => {},
  } = props

  // format args
  const { format, prefix, thousandSeparator } = props

  // non-format args
  const {
    inputMin,
    inputMax,
    keydownMin,
    keydownMax,
    step = 1,
    loop = false,
  } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const cursor = disabled ? 'cursor-not-allowed' : 'cursor-auto'
  const inputClass = `${className} ${cursor} outline-0 bg-[transparent] `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const contentDecide = () => {
    // 數字格式化輸入框
    if (inputType === 'format')
      return (
        <InputNumberFormat
          id={id}
          ref={ref}
          value={value}
          disabled={disabled}
          className={inputClass}
          displayType={'input'} // text or input (string)
          decimalScale={+decimalScale} // (number)
          allowNegative={false} // (boolean)
          placeholder={placeholder}
          thousandSeparator={thousandSeparator}
          format={format} // '#### #### #### ####' (string)
          prefix={prefix} // '$' (string)
          onValueChange={onChange} // (func)
        />
      )

    // 普通數字輸入框
    if (inputType === 'number')
      return (
        <InputForNumber
          className={inputClass}
          {...{
            id,
            ref,
            value,
            emptyValue,
            disabled,
            inputMin,
            inputMax,
            keydownMin,
            keydownMax,
            step,
            loop,
            placeholder,
            decimalScale,
            autoFocus,
            onChange,
            onFocus,
            onKeyDown,
            onBlur,
          }}
        />
      )

    if (inputType === 'text')
      return (
        <input
          className={inputClass}
          type='text'
          {...{
            id,
            ref,
            value,
            disabled,
            placeholder,
            autoFocus,
            onChange: e => onChange({ formattedValue: e.target.value }),
            onFocus,
            onBlur,
          }}
        />
      )

    if (inputType === 'password')
      return (
        <input
          className={inputClass}
          type='password'
          {...{
            id,
            ref,
            value,
            disabled,
            placeholder,
            autoFocus,
            onChange: e => onChange({ formattedValue: e.target.value }),
            onFocus,
            onBlur,
          }}
        />
      )
  }

  return contentDecide()
})

export default Input

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,

  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputType: PropTypes.oneOf(['text', 'number', 'ip', 'format', 'password']),
  onChange: PropTypes.func.isRequired,

  decimalScale: PropTypes.number,
  format: PropTypes.string,
  prefix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
}
