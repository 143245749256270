import React, { useRef } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6, small } from 'src/containers/operating/device/freqConverter/style'

import CommonInput from 'src/components/Input/CommonInput'
import SegmentedButton from 'src/components/Button/SegmentedButton'
import Tooltip from 'src/components/Popup/Tooltip'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import {
  lstOutputFreqStepOptions,
  outputFreqStepMax,
  outputFreqStepMin,
} from 'src/constants/sg'

import { isOutputFreqStepInvalid } from 'src/funcs/device/sg'

const FrequencyStepSize = props => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const customInputRef = useRef(null)

  const step = current.data?.settings?.output.frequency.dynamic.step

  const isOutputting = current.data.settings.output.isOutputting

  const isUseDynamicFreq =
    current.data?.settings?.output.frequency.type === 'dynamic'

  const segmentButtonValue = lstOutputFreqStepOptions.includes(step)
    ? step
    : 'custom'

  const isInputDisabled = !isUseDynamicFreq || isOutputting
  const isTooltipDisabled = isUseDynamicFreq || isOutputting

  const currentValueInvalid = isOutputFreqStepInvalid({
    value: +step,
    min: outputFreqStepMin,
    max: outputFreqStepMax,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleSegmentButtonChange = (event, value) => {
    let newData = R.clone(current.data)

    // 如果是 1000, 100, 10, 1, 0.1 segmentButton 選項選的 會是 number
    if (value !== 'custom')
      newData.settings.output.frequency.dynamic.step = +value

    // 如果是 custom 輸入, step 會是 string
    if (value === 'custom') {
      newData.settings.output.frequency.dynamic.step = ''
      customInputRef?.current?.focus()
    }

    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  const handleCustomInputChange = result => {
    let newData = R.clone(current.data)

    // 如果是 custom 輸入, step 會是 string
    newData.settings.output.frequency.dynamic.step = String(
      result.formattedValue
    )

    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const smallTextColor =
    !isInputDisabled && currentValueInvalid ? ' text-red' : ' text-white/50'
  const customBoxClass =
    segmentButtonValue === 'custom'
      ? 'h-[52px] duration-500'
      : 'h-0 overflow-hidden'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //! 這裡用 string 跟 number 來區分是不是 custom
  //! 如果是 custom 輸入, step 會是 string,
  //! 如果是 1000, 100, 10, 1, 0.1 segmentButton 選項選的會是 number
  return (
    <Tooltip
      title='Click "Set Frequency Range" to enable'
      disabled={isTooltipDisabled}>
      <h6 className={h6 + ' text-light-1 mb-1'}>Frequency Step Size (MHz)</h6>
      <SegmentedButton
        size={isXL || is2XL ? 'sm' : 'md'}
        type='outlined'
        value={segmentButtonValue}
        disabled={isInputDisabled}
        onChange={handleSegmentButtonChange}>
        {lstOutputFreqStepOptions.map((e, i) => (
          <SegmentedButton.Option key={`outputFreqStep-${e}${i}`} name={e}>
            {e}
          </SegmentedButton.Option>
        ))}

        <SegmentedButton.Option name='custom'>Custom</SegmentedButton.Option>
      </SegmentedButton>

      <div className={customBoxClass}>
        <CommonInput
          ref={customInputRef}
          inputType='number'
          size={isXL || is2XL ? 'sm' : 'md'}
          danger={currentValueInvalid}
          className='mt-1'
          disabled={isInputDisabled}
          value={step}
          onChange={handleCustomInputChange}
        />
        <small className={small + smallTextColor + ' mt-1 '}>
          Range {outputFreqStepMin} ~ {outputFreqStepMax} MHz
        </small>
      </div>
    </Tooltip>
  )
}

export default FrequencyStepSize
