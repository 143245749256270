import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Cable = ({ className, cableLightClassName }) => {
  return (
    <SVGUniqueID>
      <svg
        width='33'
        height='115'
        viewBox='0 0 33 115'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}>
        <path
          d='M10.2526 5.76366C10.3791 2.82074 12.8018 0.5 15.7475 0.5H16.2525C19.1982 0.5 21.6209 2.82073 21.7474 5.76366L22.4556 22.2285C22.4678 22.5128 22.2406 22.75 21.9561 22.75H10.0439C9.75936 22.75 9.53217 22.5128 9.5444 22.2285L10.2526 5.76366Z'
          fill='#3D3D3D'
          stroke='white'
        />
        <path
          d='M21 7.27328C21.1703 7.27328 21.3289 7.35996 21.4209 7.5033L26.7286 15.7772C26.7841 15.8636 26.8115 15.9651 26.8073 16.0678L26.5405 22.5207C26.5294 22.7885 26.309 23 26.0409 23L5.4656 23C5.19634 23 4.97544 22.7868 4.96591 22.5177L4.7374 16.0676L4.23772 16.0853L4.7374 16.0676C4.7338 15.9659 4.7613 15.8655 4.81624 15.7799L10.1257 7.5033C10.2176 7.35996 10.3762 7.27328 10.5465 7.27328L10.5465 6.80069L10.5465 7.27328L21 7.27328Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M20.9692 13.2733C21.1478 13.2733 21.3129 13.3686 21.4022 13.5233L26.5982 22.523C26.6875 22.6777 26.6875 22.8683 26.5982 23.023L21.4022 32.0227C21.3129 32.1774 21.1478 32.2727 20.9692 32.2727L10.5773 32.2727C10.3986 32.2727 10.2336 32.1774 10.1443 32.0227L4.94829 23.023C4.85898 22.8683 4.85897 22.6777 4.94829 22.523L10.1443 13.5233C10.2336 13.3686 10.3986 13.2733 10.5773 13.2733L20.9692 13.2733Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M12.2868 26.0209C12.3918 24.0468 14.0231 22.5 16 22.5C17.9769 22.5 19.6082 24.0468 19.7132 26.0209L24.4447 114.973C24.4599 115.26 24.232 115.5 23.9454 115.5H8.05461C7.76803 115.5 7.54009 115.26 7.55531 114.973L12.2868 26.0209Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M10.0039 35.938L10.0026 35.9482L10.0017 35.9585L9.79858 38.3962C9.79369 38.4549 9.74466 38.5 9.6858 38.5C8.53754 38.5 7.63715 37.514 7.74111 36.3705L8.8642 24.0165C9.19971 20.3259 12.2941 17.5 16 17.5C19.7059 17.5 22.8003 20.3259 23.1358 24.0165L24.2753 36.5514C24.3705 37.5978 23.5466 38.5 22.4958 38.5C22.4602 38.5 22.4299 38.4738 22.4249 38.4384L21.995 35.4293C21.6729 33.1747 19.742 31.5 17.4645 31.5H15.0311C12.476 31.5 10.3208 33.4026 10.0039 35.938Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          opacity='0.5'
          d='M16 47L16 115'
          stroke='#F2C94C'
          strokeWidth='4'
          strokeLinecap='round'
          className={cableLightClassName}
        />
        <path
          d='M10.0712 35.0204C10.2934 31.9099 12.8816 29.5 16 29.5C19.1184 29.5 21.7066 31.9099 21.9288 35.0204L23.3234 54.5459C23.3989 55.6018 22.5626 56.5 21.5039 56.5C21.4636 56.5 21.4294 56.4703 21.4237 56.4304L21.0003 53.4667L20.5053 53.5374L21.0003 53.4667C20.9906 53.3984 20.9719 53.3317 20.9447 53.2683L20.4851 53.4652L20.9447 53.2683L20.612 52.492C19.8341 50.6769 18.0493 49.5 16.0745 49.5C14.0168 49.5 12.1748 50.7765 11.4523 52.7032L11.0467 53.7848C11.0207 53.8542 11.0044 53.9268 10.9982 54.0007L10.7993 56.3871C10.794 56.4509 10.7407 56.5 10.6767 56.5C9.52046 56.5 8.60706 55.519 8.68944 54.3657L10.0712 35.0204Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path d='M10.5 7.5V13.5' stroke='white' />
        <path d='M21 7.5V13.5' stroke='white' />
        <path
          d='M8 28L9.31055 25.3193C10.3329 23.2281 12.2745 21.7353 14.5582 21.2846V21.2846C15.5102 21.0967 16.4898 21.0967 17.4418 21.2846V21.2846C19.7255 21.7353 21.6671 23.2281 22.6895 25.3193L24 28'
          stroke='white'
        />
      </svg>
    </SVGUniqueID>
  )
}

Cable.propTypes = {}

export default Cable
