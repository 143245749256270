import { nanoid } from 'nanoid'

export const chartLengthMax = 241

export const getInitCaliData = () => ({
  [nanoid()]: {
    name: 'Unnamed calibration config',
    createdAt: '',
    value: {
      '100MHz': {
        lowPower: -35,
        lowVolt: 34.68,
        highPower: -5,
        highVolt: 901.68,
        isDefault: true,
      },
      '300MHz': {
        lowPower: -36,
        lowVolt: 34.68,
        highPower: -5,
        highVolt: 901.68,
        isDefault: true,
      },
      '500MHz': {
        lowPower: -36,
        lowVolt: 109.98,
        highPower: -5,
        highVolt: 984.18,
        isDefault: true,
      },
      '1GHz': {
        lowPower: -36,
        lowVolt: 109.98,
        highPower: -5,
        highVolt: 984.18,
        isDefault: true,
      },
      '10GHz': {
        lowPower: -36,
        lowVolt: 57.6,
        highPower: -5,
        highVolt: 950.4,
        isDefault: true,
      },
      '20GHz': {
        lowPower: -36,
        lowVolt: 40.46,
        highPower: -5,
        highVolt: 936.36,
        isDefault: true,
      },
      '30GHz': {
        lowPower: -36,
        lowVolt: 83.81,
        highPower: -5,
        highVolt: 979.71,
        isDefault: true,
      },
      '40GHz': {
        lowPower: -30,
        lowVolt: 20.65,
        highPower: -5,
        highVolt: 787.65,
        isDefault: true,
      },
      '43GHz': {
        lowPower: -26,
        lowVolt: 20.65,
        highPower: -5,
        highVolt: 787.65,
        isDefault: true,
      },
    },
  },
})
