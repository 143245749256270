import React from 'react'
import { useDispatch } from 'react-redux'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import CommonSwitch from 'src/components/Switch/CommonSwitch'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import RefSourceFreq from 'src/containers/operating/device/freqConverter/udModule/DeviceSettings/RefSourceFreq'

import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  udmodule_deviceSettings_refSourceChange_watcher,
  udmodule_deviceSettings_InternalEnableClick_watcher,
  udmodule_deviceSettings_OutputChange_watcher,
  udmodule_deviceSettings_InputChange_watcher,
} from 'src/redux/actions/freqConverter/udmoduleAction'

const RefSource = () => {
  const dispatch = useDispatch()

  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()

  const sn = current.sn
  const { refSource, internal, external } = current.data.settings.device

  const isInternal = +refSource === 0
  const isExternal = +refSource === 1

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleRefSourceChange = (event, value) =>
    dispatch(
      udmodule_deviceSettings_refSourceChange_watcher({ sn, refSource: value })
    )

  const handleInternalEnableClick = () =>
    dispatch(
      udmodule_deviceSettings_InternalEnableClick_watcher({
        sn,
        isEnable: !internal.isEnable,
      })
    )

  const handleOutputChange = (event, value) =>
    dispatch(
      udmodule_deviceSettings_OutputChange_watcher({ sn, outputFreq: value })
    )

  const handleInputChange = (event, value) =>
    dispatch(
      udmodule_deviceSettings_InputChange_watcher({ sn, inputFreq: value })
    )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='px-2'>
      <h6 className={h6 + ' text-light-1 mb-2'}>Reference Signal Source</h6>

      <SegmentedButton
        size={isSM || isMD ? 'md' : 'sm'}
        type='outlined'
        value={+refSource}
        onChange={handleRefSourceChange}>
        <SegmentedButton.Option name={0}>Internal</SegmentedButton.Option>
        <SegmentedButton.Option name={1}>External</SegmentedButton.Option>
      </SegmentedButton>

      <div className='flex justify-between items-center mt-4 mb-2'>
        <h6 className={h6 + ' text-light-1'}>
          {isInternal ? 'Output' : 'Input'} Reference Source
        </h6>

        {isInternal && (
          <CommonSwitch
            type='text'
            onText='ON'
            offText='OFF'
            on={internal.isEnable}
            onClick={handleInternalEnableClick}
          />
        )}
      </div>

      {isInternal && internal.isEnable && (
        <RefSourceFreq
          size={isSM || isMD ? 'md' : 'sm'}
          source='internal'
          value={+internal.currentSelection}
          onChange={handleOutputChange}
        />
      )}

      {isExternal && (
        <RefSourceFreq
          size={isSM || isMD ? 'md' : 'sm'}
          source='external'
          value={+external.currentSelection}
          onChange={handleInputChange}
        />
      )}
    </div>
  )
}

export default RefSource
