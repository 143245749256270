import React from 'react'
import { useDispatch } from 'react-redux'

import {
  box,
  subtitle,
} from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/style'

import CommonSwitch from 'src/components/Switch/CommonSwitch'

import { ud0630_deviceSettings_outputLOFrequency_change_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const OutputLOFrequency = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const isOutputLoDisabled =
    current.data.settings.device?.inputRefSource?.currentSelection === 'LO'
  const isOutputLoEnable = current.data.settings.device?.isOutputLoEnable

  const handleChange = () =>
    dispatch(
      ud0630_deviceSettings_outputLOFrequency_change_watcher({
        sn,
        value: !isOutputLoEnable,
      })
    )

  return (
    <div className={box + ' flex justify-between'}>
      <h4 className={subtitle}>Output LO Frequency</h4>
      <CommonSwitch
        type='text'
        onText='ON'
        offText='OFF'
        disabled={isOutputLoDisabled}
        on={isOutputLoEnable}
        onClick={handleChange}
      />
    </div>
  )
}

export default OutputLOFrequency
