/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'

import ItemMark from 'src/containers/operating/DeviceInventory/ItemMark'

import DeviceIcon from 'src/containers/operating/DeviceInventory/DeviceIcon'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useScrollIntoView from 'src/hooks/useScrollIntoView'

const Item = ({ layout, deviceType, hardwareVersion, name, sn, isSelect }) => {
  const scrollTo = useScrollIntoView

  const { current } = useGetCurrentDeviceData()
  const { ref, inView, entry } = useInView({
    // * threshold 就是 element 如果不是完全顯示 inView 就是 false
    threshold: 1,
  })

  useLayoutEffect(() => {
    if (sn === current.sn && !inView)
      setTimeout(() => scrollTo({ ref: entry?.target, block: 'center' }), 500)
  }, [current.sn, entry?.target])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                          ${containerCommon} 
                          ${containerLayout(layout)} 
                          ${isSelect && 'bg-black/30'}
                          `

  const infoBox = `${infoBoxCommon} ${infoBoxLayout(layout)}`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  if (sn)
    return (
      <div className={containerClass} ref={ref}>
        <div className={iconBox}>
          <DeviceIcon
            sn={sn}
            deviceType={deviceType}
            hardwareVersion={hardwareVersion}
          />
        </div>

        <div className={infoBox}>
          <h4 className='text-sm '>{name || ''}</h4>
          <h6 className='text-xs '>{sn}</h6>
        </div>

        <ItemMark sn={sn} layout={layout} />

        {isSelect && <div className={selectedLine} />}
      </div>
    )

  // 沒有 sn render 空的區塊
  return <div className={containerClass} />
}

Item.propTypes = {
  deviceType: PropTypes.number,
  name: PropTypes.string,
  sn: PropTypes.string,
  isSelect: PropTypes.bool,
}

export default Item

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = `[Menu-Item-containerClass]
                          relative
                          w-full
                          max-w-[230px] 
                          flex 
                          items-center justify-center
                          gap-x-2 
                          px-3 py-5 
                          text-light-4 
                          shrink-0 
                          hover:bg-black/25 
                          duration-200
                          `

//! 駐一
const containerLayout = layout => (layout === 'row' ? 'flex-row' : 'flex-col')

const iconBox = `[Menu-Item-iconBox]
                  relative 
                  w-12 h-12 
                  flex 
                  items-center justify-center 
                  shrink-0 
                  text-2xl 
                  `

const infoBoxCommon = `[Menu-Item-infoBoxCommon]
                  flex flex-col 
                  w-full 
                  font-normal text-center
                  `

//! 駐一
const infoBoxLayout = layout =>
  layout === 'row' ? 'items-start mt-0' : 'items-center mt-2'

const selectedLine = `[Menu-Item-selectedLineCommon]
                      absolute 
                      inset-y-0 
                      w-[4px] 
                      bg-teal 
                      left-auto -right-[2px]
                      `

//! 註一
//! 舊版設計有 row 跟 col 兩種設計, 因此會有這個參數
//! 在開發 clover cell - 1024 array 時, 因文字放不下而統一使用 col
//! 但為了畢竟未來還會改回 有 row 的版本，因此這邊不做更動
