import React from 'react'

import Member from 'src/containers/Header/Member'
import { isOffline } from 'src/funcs/getEnv'

const MainRight = props => {
  return (
    <div className='absolute right-4 md:right-8'>
      {!isOffline && <Member />}
    </div>
  )
}

MainRight.propTypes = {}

export default MainRight
