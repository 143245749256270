import { put, call } from 'redux-saga/effects'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'
import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import { commonFreqAfterImport } from 'src/redux/sagas/handlers/event/beamformers/general/common'
import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* init(payloads) {
  try {
    const { sn } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    // 先設為 unfinished 是因為有可能是 failed 後的 retry
    // 所以要先設為 unfinished 來改變 init 畫面
    currentData.info.initialization = 'unfinished'

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(__socket_API_sender, beamformersApi.INIT_DEVICE, {
      sn,
      lookupID,
    })

    const deviceType = currentData.info.deviceType

    // clover cell evb 要多 call 這個 API - init local tread (middleware)
    // 這樣才能正常跑後續的 read current
    const isCloverCellEvb =
      getDeviceInfo[deviceType].devName.first === 'cloverCellEvb'

    if (isCloverCellEvb)
      yield call(
        __socket_API_sender,
        localMiddlewareApi.INIT_CLOVERCELL_EVB_LOCAL_TREAD,
        { sn, lookupID }
      )
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}

export function* initNoTableAfterImport(payloads) {
  try {
    // responseData.data = { lstFailed, lstSuccess, lstSN }
    const { sn, responseData } = payloads

    const importResult = yield call(commonFreqAfterImport, { sn, responseData })
    // importResult = 'allSuccess' || 'partiallyFailed' || 'allFailed'

    if (importResult === 'allSuccess' || importResult === 'partiallyFailed')
      yield call(init, { sn })
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}
