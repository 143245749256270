import React from 'react'
import PropTypes from 'prop-types'

import TextSwitch from './TextSwitch'
import BlockSwitch from './BlockSwitch'
import DotSwitch from './DotSwitch'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <CommonSwitch
//  type='text' // text , block , dot
//  onText='' // string
//  offText='' // string
//  size='' // sm, md
//  on={} // bool
//  disabled={} // bool
//  loading={false} // bool
//  onClick={()=>{}}
// />

const CommonSwitch = props => {
  const { type = 'text' } = props

  if (type === 'text') return <TextSwitch {...props} />
  if (type === 'block') return <BlockSwitch {...props} />
  if (type === 'dot') return <DotSwitch {...props} />
  return <></>
}
export default CommonSwitch

CommonSwitch.propTypes = { type: PropTypes.string }
