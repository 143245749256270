import React from 'react'

import Channel from 'src/containers/operating/device/beamformers/cloverCell/evb/Main/Channel'
import BeamSteering from 'src/containers/operating/device/beamformers/cloverCell/evb/Main/BeamSteering'

import Mask from 'src/components/Mask'

import {
  container,
  divider,
} from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const Main = props => {
  const { current } = useGetCurrentDeviceData()

  const rfMode = current.data.deviceControl.common.rfMode

  return (
    <div className={container}>
      {rfMode === 'standby' && (
        <Mask className={disabledMaskClass}>Enabled in TX or RX mode.</Mask>
      )}

      <Channel />

      <div className={divider + ' my-4'} />

      <BeamSteering />
    </div>
  )
}

Main.propTypes = {}

export default Main

const disabledMaskClass = `flex z-20 
                            items-center justify-center 
                            text-light-4 text-base font-bold 
                            cursor-not-allowed select-none 
                            `
