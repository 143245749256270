import React from 'react'

const index = props => {
  const {
    type = 'default',
    className = '',
    children,
    onClick,
    onPointerEnter,
    onPointerLeave,
  } = props

  return (
    <div
      className={container + ' ' + className}
      style={{ background: background[type] }}
      onClick={onClick}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}>
      {children}
    </div>
  )
}

export default index

const background = {
  default:
    'radial-gradient(50% 50% at 50% 50%, #343434 17%, rgba(35, 35, 35, 0.75) 100%)',
}

const container = `
                  absolute 
                  inset-0 z-10 
                  opacity-95
                  `
