import Controllers from '../../Controllers/index.mjs'
import {
  getBeamGainMin,
  getBeamGainMax,
  getCommonGainMin,
  getCommonGainMax,
  boardCount,
  chCount,
  elementGainMin,
  elementGainMax,
  getLstAntennaData,
  getDefaultCurrentFreq,
  getLstFreqOptions,
} from '../spec.mjs'

// DT = deviceType
const getDT = sn => Controllers._getDeviceType(sn)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        BBox        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const getTotalGainMin = (sn, rfMode) =>
  getCommonGainMin[rfMode][getDT(sn)] + elementGainMin
const getTotalGainMax = (sn, rfMode) =>
  getCommonGainMax[rfMode][getDT(sn)] + elementGainMax

const boardDataPackage = (sn, rfMode) =>
  new Array(boardCount[getDT(sn)]).fill(null).map(() => ({
    totalGainMin: getTotalGainMin(sn, rfMode),
    totalGainMax: getTotalGainMax(sn, rfMode),
    commonCurrentGain: getCommonGainMax[rfMode][getDT(sn)],
    commonGainMin: getCommonGainMin[rfMode][getDT(sn)],
    commonGainMax: getCommonGainMax[rfMode][getDT(sn)],
    lstChannelData: new Array(chCount[getDT(sn)]).fill(null).map(() => ({
      totalGain: getTotalGainMax(sn, rfMode),
      elementGain: elementGainMax,
      phase: 0,
      power: true,
    })),
  }))

// const configBeamDataPackage = ({
//   beamID: 1, // 1 - 64
//   beam_description: '',
//   beam_type: 0, // 0 = beam , 1 = channel
//   beam_config: {
//     db: 0,
//     theta: 0,
//     phi: 0,
//   },
//   channel_config: new Array(4).fill(null).map((be, bi) => ({
//     board: bi + 1,
//     common_db: 0,
//     ch_group: new Array(4).fill(null).map((ce, ci) => ({
//       board_ch: ci + 1,
//       sw: 0,
//       db: 0, // element gain
//       deg: 0,
//     })),
//   }))
// })

export const bboxInitAppendData = sn => ({
  deviceControl: {
    common: {
      currentAntenna: '',
      lstAntennaData: getLstAntennaData(getDT(sn)),
      rfMode: 'tx',
      currentFreq: getDefaultCurrentFreq[getDT(sn)],
      lstFreqOptions: getLstFreqOptions[getDT(sn)],
      currentControlMode: { tx: 'channel', rx: 'channel' }, // 'steering' || 'channel'
    },
    steering: {
      tx: {
        theta: 0,
        phi: 0,
        beamCurrentGain: getBeamGainMax['tx'][getDT(sn)], // spec 14 會有問題
        beamGainMin: getBeamGainMin['tx'][getDT(sn)],
        beamGainMax: getBeamGainMax['tx'][getDT(sn)], // spec 14 會有問題
      },
      rx: {
        theta: 0,
        phi: 0,
        beamCurrentGain: getBeamGainMax['rx'][getDT(sn)],
        beamGainMin: getBeamGainMin['rx'][getDT(sn)],
        beamGainMax: getBeamGainMax['rx'][getDT(sn)],
      },
    },
    channel: {
      boardCount: boardCount[getDT(sn)],
      channelCount: chCount[getDT(sn)],
      tx: {
        lstBoardData: boardDataPackage(sn, 'tx'),
      },
      rx: {
        lstBoardData: boardDataPackage(sn, 'rx'),
      },
    },
  },
  beamConfigEditor: {
    controlMethod: 'TLK', // TLK or SPI
    currentConfig: {
      beamConfigID: '', //
      beamConfigName: '', // 遠端才會用到
      bindAntennaID: '',
      numberOfConfigs: 64, // config 數量
      sort: 'asc', // asc or desc
      tableData: {
        tx: [],
        rx: [],
      },
      lstConfigs: {
        // [config_id1]: {
        //   name: '',
        // },
        // [config_id2]: {
        //   name: '',
        // },
      },
    },
  },
})
