import React from 'react'

import { h6, small } from 'src/containers/operating/device/powerDetector/style'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetUIControl from 'src/hooks/useGetUIControl'

const Chart = props => {
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const { single: s_uiControl } = useGetUIControl(sn)

  const chartData = s_uiControl?.chartData

  return (
    <div className='w-full h-[300px] md:h-[542px] bg-red/0 pr-3'>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          width='100%'
          height='100%'
          data={chartData}
          margin={{
            top: 0,
            right: 15,
            left: -17,
            bottom: -10,
          }}>
          <CartesianGrid strokeDasharray='2 2' style={{ opacity: 0.2 }} />
          <XAxis dataKey='point' interval={29} className='text-xs' />
          <YAxis type='number' domain={['auto', 0]} className='text-xs' />

          <Tooltip content={<ChartTooltip />} />

          <Line
            dataKey='power'
            dot={false}
            stroke='#28CACE'
            fill='#28CACE'
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart

const ChartTooltip = ({ active, payload, label }) =>
  active && payload && payload.length ? (
    <div className='p-4 rounded-md bg-black'>
      <h6 className={h6 + ' text-light-4'}>{label}</h6>

      <div className='w-full h-[1px] bg-light-4 mt-2 mb-1' />

      <span className={small + ' text-light-4'}>
        Power: {payload[0].value} dBm
      </span>
    </div>
  ) : (
    <></>
  )
