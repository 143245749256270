import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function Horizontal({ className }) {
  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2826_168383)'>
          <path
            d='M3.01392 13.5205C3.01299 13.6431 3.03632 13.7647 3.08256 13.8782C3.1288 13.9917 3.19704 14.095 3.28335 14.1821L8.53283 19.4926C8.80449 19.7674 9.2172 19.8464 9.57121 19.692L20.772 14.2301C20.9374 14.1577 21.0783 14.0388 21.1774 13.8879C21.2766 13.7369 21.3298 13.5605 21.3306 13.3799L21.3688 7.77989C21.3697 7.6573 21.3464 7.53573 21.3001 7.42219C21.2539 7.30865 21.1857 7.20537 21.0993 7.11831L17.0092 3.56223C16.7376 3.28741 16.3249 3.20846 15.9709 3.36284L3.61074 6.07033C3.44529 6.14272 3.30443 6.2616 3.20525 6.41252C3.10608 6.56344 3.05286 6.73992 3.05207 6.92051L3.01392 13.5205ZM4.90618 9.1852L8.27643 11.8003L8.27643 16.5778L4.88264 13.1445L4.90618 9.1852ZM10.1382 17.4056L10.0334 12.3628L19.2376 9.30954L19.2376 12.8782L10.1382 17.4056ZM15.9709 5.05034L18.3459 7.42219L9.47085 10.1128L5.60453 7.23623L15.9709 5.05034Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_2826_168383'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
