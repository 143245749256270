import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'
import { modalActions } from 'src/redux/slices/modal'

const FailedToImportForIncorrectSn = props => {
  const dispatch = useDispatch()
  const { currentSn, bindSn } = props
  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red' />
      </Modal.Icon>

      <Modal.Title>Failed to Import</Modal.Title>

      <Modal.Description>
        Incompatible files can't be imported.
        <br />
        The current device SN is: {currentSn} <br />
        The matching device SN is: {bindSn}
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          type='general'
          size='lg'
          onClick={() => dispatch(modalActions.clearNormalModal())}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

FailedToImportForIncorrectSn.propTypes = {}

export default FailedToImportForIncorrectSn
