import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const LEDLights = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='259'
        height='108'
        viewBox='0 0 259 108'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M72.6538 76.3323C72.5982 78.2645 70.9867 79.7858 69.0545 79.7301C67.1223 79.6745 65.6011 78.0631 65.6567 76.1309C65.7123 74.1987 67.3237 72.6774 69.2559 72.733C71.1881 72.7887 72.7094 74.4001 72.6538 76.3323Z'
          fill='#479A5F'
          stroke='#479A5F'
        />
        <path
          d='M60.6587 75.9866C60.6031 77.9188 58.9916 79.4401 57.0594 79.3844C55.1272 79.3288 53.606 77.7174 53.6616 75.7852C53.7172 73.853 55.3286 72.3317 57.2608 72.3873C59.193 72.443 60.7143 74.0544 60.6587 75.9866Z'
          fill='#479A5F'
          stroke='#479A5F'
        />
        <path
          d='M45.0499 79.5385C47.2581 79.6021 49.0998 77.8635 49.1633 75.6553C49.2269 73.447 47.4883 71.6054 45.2801 71.5418C43.0719 71.4783 41.2302 73.2169 41.1667 75.4251C41.1031 77.6333 42.8417 79.475 45.0499 79.5385Z'
          fill='#479A5F'
        />
      </svg>
    </SVGUniqueID>
  )
}
export default LEDLights
