import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  head,
  main,
  footer,
} from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import { h2, h3 } from 'src/containers/Modal/style'

import CommonButton from 'src/components/Button/CommonButton'

import FunctionBar from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode/FunctionBar'
import THead from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode/THead'
import TBody from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/ListMode/TBody'

import noCustomAntennaImg from 'src/assets/img/no_custom_antenna.png'

import { modalActions } from 'src/redux/slices/modal'

import { bbox_customAntennaModal_createClick_watcher } from 'src/redux/actions/beamformers/bboxAction'

const ListMode = ({ sn, selectIdList }) => {
  const dispatch = useDispatch()
  const lstCurrentAntennaData = useSelector(
    state => state.deviceData[sn].deviceControl.common.lstAntennaData
  )
  const customOnlyAntennaList = lstCurrentAntennaData.filter(
    e => e.type === 'customized'
  )

  const handleCreateClick = () =>
    dispatch(bbox_customAntennaModal_createClick_watcher({ sn }))
  const handleCloseClick = () => dispatch(modalActions.clearNormalModal())

  return (
    <>
      <header className={`${head} ${h2} text-light-1 pt-10 pb-6 text-center`}>
        Custom Antenna
      </header>

      {customOnlyAntennaList.length === 0 && <NoData />}

      {customOnlyAntennaList.length > 0 && (
        <main className={main + ' flex flex-col items-start px-[84px]'}>
          <FunctionBar selectIdList={selectIdList} />
          <THead {...{ customOnlyAntennaList, selectIdList }} />
          <TBody {...{ customOnlyAntennaList, selectIdList }} />
        </main>
      )}

      <footer className={footer + ' flex justify-end gap-x-8'}>
        <CommonButton
          type='general'
          size='lg'
          className='px-4'
          disabled={customOnlyAntennaList.length >= 50}
          onClick={handleCreateClick}>
          Create Custom Antenna
        </CommonButton>

        <CommonButton type='outlined' size='lg' onClick={handleCloseClick}>
          Close
        </CommonButton>
      </footer>
    </>
  )
}

ListMode.propTypes = {}

export default ListMode

const NoData = () => (
  <main className={main + ' flex flex-col items-center justify-center'}>
    <div className='w-[240px] h-[160px] mb-4'>
      <img src={noCustomAntennaImg} alt='TMXLab-kit bbox - no custom antenna' />
    </div>
    <h2 className={`${h2} text-light-4`}>No custom antenna</h2>
    <h3 className={`${h3} text-light-4 text-center`}>
      Click the button below to create new one.
      <br /> Up to 50 custom antenna.
    </h3>
  </main>
)
