import { useErrorBoundary } from 'react-error-boundary'

// ErrorBoundary 只能抓到 React render 生命週期中的錯誤
// 因此 jsx 裡面的 異步處理跟 event handler 都抓不到
// 這個 hook 就是為了處理抓不到的部分，抓到錯誤並送到 ErrorBoundary

// 一般只送 dispatch 的 event handler 基本上不會有錯誤
// 因此建議只掛在內含邏輯的 event handler 或 function

const useBindErrorBoundary = callback => {
  const { showBoundary } = useErrorBoundary()

  return (...args) => {
    try {
      callback(...args)
    } catch (error) {
      showBoundary(error)
    }
  }
}

export default useBindErrorBoundary
