import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { setCameraArgs } from 'src/redux/slices/uiControl/beamformers/ris'

import { devWarLog } from 'src/funcs/tools'
import { getBeamIndex, getBeamName } from 'src/funcs/device/ris'

function* _steeringThetaPhiChangePreRender({ payloads }) {
  const { sn, targetBeam, theta, phi } = payloads
  let { currentData } = yield call(getCommonArgs, sn)

  const beamName = getBeamName(targetBeam)
  const beamIndex = getBeamIndex(targetBeam)

  currentData.deviceControl.steering[beamName][beamIndex].theta = theta
  currentData.deviceControl.steering[beamName][beamIndex].phi = phi

  yield put(setSingleDeviceData({ sn, data: currentData }))

  return {
    incident: currentData.deviceControl.steering['incident'],
    reflector: currentData.deviceControl.steering['reflector'],
  }
}

export function* steeringThetaPhiChange(payloads) {
  try {
    // payloads format: { sn, targetBeam, theta, phi, isValid }
    const { sn, isValid } = payloads
    const { incident, reflector } = yield call(
      _steeringThetaPhiChangePreRender,
      { payloads }
    )

    if (isValid) {
      let { lookupID } = yield call(getCommonArgs, sn)
      yield call(__socket_API_sender, beamformersApi.RIS_THETA_PHI_CHANGE, {
        sn,
        lookupID,
        incident,
        reflector,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* steeringDistanceChange(payloads) {
  try {
    const { sn, value, index, isValid } = payloads

    let { currentData } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering.incident[index].distance = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid) {
      let { lookupID } = yield call(getCommonArgs, sn)
      yield call(__socket_API_sender, beamformersApi.RIS_DISTANCE_CHANGE, {
        sn,
        lookupID,
        index,
        distance: +value,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringDistanceChange error:', error)
  }
}

export function* steering3DCameraChange(action) {
  yield put(setCameraArgs(action.payload))
}
