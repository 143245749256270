/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from '@floating-ui/react'

import { mergeRefs } from 'react-merge-refs'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// {
/* <Tooltip
      title=''
      content=""
      className = ''
      titleClassName = ''
      contentClassName = ''
      childrenContainerClassName = ''
      placement='bottom'
      enableDismiss={true}
      disabled={false}
      >
      <div>reference jsx...</div>
  </Tooltip> */
// }

const Tooltip = props => {
  const {
    title,
    content,
    className = '',
    titleClassName = '',
    contentClassName = '',
    childrenContainerClassName = '',
    placement: initPlacement = 'top',
    enableDismiss = false,
    disabled = false,
    children,
  } = props

  const triggerRef = useRef()
  const arrowRef = useRef()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [open, setOpen] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        Func        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { x, y, refs, strategy, placement, middlewareData, context } =
    useFloating({
      open,
      placement: initPlacement,
      onOpenChange: setOpen,
      strategy: 'absolute',
      middleware: [
        offset(13), // 8 margin + 5 arrow width
        flip(),
        shift({ padding: 5 }),
        arrow({
          element: arrowRef,
          padding: 6,
        }),
      ],
      whileElementsMounted: autoUpdate,
    })

  const referenceRef = useMemo(
    () => mergeRefs([refs.setReference, triggerRef]),
    [refs.setReference, triggerRef]
  )

  // 處理 arrow 反轉用
  const arrowX = middlewareData.arrow?.x
  const arrowY = middlewareData.arrow?.y
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]]

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, { enabled: enableDismiss }),
  ])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                          ${className} 
                          bg-black 
                          shadow-sm shadow-black/20 
                          px-4 py-2 
                          rounded-[2px] 
                          text-light-4 font-normal 
                          z-10 
                          will-change-transform
                          `

  const titleClass = `${titleClassName} text-base `
  const contentClass = `${contentClassName} text-sm `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <>
      {/* 如果去抓 children.ref 會有部分元件不工作，因此在外部包一層 trigger div */}
      {
        <div
          className={childrenContainerClassName}
          ref={referenceRef}
          {...getReferenceProps()}>
          {children}
        </div>
      }
      {open && !disabled && (
        <div
          ref={refs.setFloating}
          className={containerClass}
          style={{
            position: strategy,
            top: 0,
            left: 0,
            transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
          }}
          {...getFloatingProps()}>
          <div
            ref={arrowRef}
            className={arrowClass}
            style={{
              position: strategy,
              zIndex: -1,
              left: arrowX ?? '',
              top: arrowY ?? '',
              [staticSide]: '-6px',
            }}
          />
          {title && <span className={titleClass}>{title}</span>}
          {title && content && <div className={divider} />}
          {content && <span className={contentClass}>{content}</span>}
        </div>
      )}{' '}
    </>
  )
}

export default Tooltip

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const arrowClass = `absolute bg-black w-[10px] h-[10px] rotate-45`
const divider = `w-full h-[1px] bg-dark-5 my-[6px]`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Tooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.node,
  enableDismiss: PropTypes.bool,
}
