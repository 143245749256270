import React from 'react'

import PremiumContactTMYTEK from 'src/containers/Modal/Normal/beamformers/cloverCell/Polarization/PremiumContactTMYTEK'

const BeamConfigEditor = props => {
  const { status: modalStatus } = props

  if (modalStatus === 'premiumContactTMYTEK')
    return <PremiumContactTMYTEK {...props} />

  return <></>
}

export default BeamConfigEditor
