import { createAction } from '@reduxjs/toolkit'

export const pd_general_initDevice_watcher = createAction(
  'deviceControl/pd_general_initDevice_watcher'
)

// actionPanel
export const pd_freqChange_watcher = createAction(
  'deviceControl/pd_freqChange_watcher'
)
export const pd_readMethodChange_watcher = createAction(
  'deviceControl/pd_readMethodChange_watcher'
)
export const pd_updateRateChange_watcher = createAction(
  'deviceControl/pd_updateRateChange_watcher'
)
export const pd_readClick_watcher = createAction(
  'deviceControl/pd_readClick_watcher'
)
export const pd_averageChange_watcher = createAction(
  'deviceControl/pd_averageChange_watcher'
)
export const pd_caliSelect_watcher = createAction(
  'deviceControl/pd_caliSelect_watcher'
)
export const pd_configCustomClick_watcher = createAction(
  'deviceControl/pd_configCustomClick_watcher'
)

export const pd_changeCaliCheckModal_changeClick_watcher = createAction(
  'modalEvent/pd_changeCaliCheckModal_changeClick_watcher'
)
export const pd_changeCaliCheckModal_noClick_watcher = createAction(
  'modalEvent/pd_changeCaliCheckModal_noClick_watcher'
)

export const pd_customCaliModal_createClick_watcher = createAction(
  'modalEvent/pd_customCaliModal_createClick_watcher'
)
export const pd_customCaliModal_editClick_watcher = createAction(
  'modalEvent/pd_customCaliModal_editClick_watcher'
)
export const pd_customCaliModal_leaveEditClick_watcher = createAction(
  'modalEvent/pd_customCaliModal_leaveEditClick_watcher'
)
export const pd_customCaliModal_getVoltClick_watcher = createAction(
  'modalEvent/pd_customCaliModal_getVoltClick_watcher'
)
export const pd_customCaliModal_deleteClick_watcher = createAction(
  'modalEvent/pd_customCaliModal_deleteClick_watcher'
)
export const pd_customCaliModal_saveClick_watcher = createAction(
  'modalEvent/pd_customCaliModal_saveClick_watcher'
)

export const pd_customCaliModal_clearIncompleteDataChildModal_clearClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_clearIncompleteDataChildModal_clearClick_watcher'
  )

export const pd_customCaliModal_clearIncompleteDataChildModal_cancelClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_clearIncompleteDataChildModal_cancelClick_watcher'
  )

export const pd_customCaliModal_saveCaliReminderChildModal_continuousClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_saveCaliReminderChildModal_continuousClick_watcher'
  )

export const pd_customCaliModal_saveCaliReminderChildModal_noClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_saveCaliReminderChildModal_noClick_watcher'
  )

export const pd_customCaliModal_deleteCheckChildModal_deleteClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_deleteCheckChildModal_deleteClick_watcher'
  )
export const pd_customCaliModal_deleteCheckChildModal_cancelClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_deleteCheckChildModal_cancelClick_watcher'
  )
export const pd_customCaliModal_usingWillNotBeDeleteChildModal_okClick_watcher =
  createAction(
    'modalEvent/pd_customCaliModal_usingWillNotBeDeleteChildModal_okClick_watcher'
  )

// dashboard
export const pd_exportPowerHistoryClick_watcher = createAction(
  'deviceControl/pd_exportPowerHistoryClick_watcher'
)
// dashboard - modal
export const pd_clearPowerHistoryModal_clearClick_watcher = createAction(
  'modalEvent/pd_clearPowerHistoryModal_clearClick_watcher'
)
