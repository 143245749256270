import React from 'react'
import DeviceResetReminder from 'src/containers/Modal/Normal/Operating/DeviceResetReminder'

const Operating = modalProps => {
  const { status: modalStatus } = modalProps

  // device reset reminder
  if (modalStatus === 'deviceResetReminder')
    return <DeviceResetReminder {...modalProps} />

  return <></>
}

Operating.propTypes = {}

export default Operating
