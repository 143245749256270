export const getCompanyFwInfo = clientID => ({
  server: 'facility',
  method: 'GET',
  url: '/api/facility/middlewareInfo',
  config: { params: { clientID } },
  isAutoDemo: true,
})

export const getServiceVersion = () => ({
  server: 'facility',
  method: 'GET',
  url: '/api/facility/getServiceVersion',
})

export const sendEmailAPI = data => ({
  server: 'facility',
  method: 'POST',
  url: '/api/facility/email/send',
  data,
  isAutoDemo: false,
})

// scan
// lookupID
//* 會多開一隻掃全部的 這只是掃單一 middleware 的
//* 全部要給 ClientID
export const scanDevicesAPI = (data, isDemoMode) => {
  const prefix = isDemoMode ? '/demo' : ''

  return {
    server: 'facility',
    method: 'GET',
    url: `${prefix}/api/facility/device/scanDevices`,
    timeout: 20000,
    isAutoDemo: true,
  }
}

// member
export const memberAvatarUploadAPI = () => ({
  server: 'facility',
  method: 'POST',
  url: '/api/facility/user/avatar/upload',
  timeout: 20000,
  isAutoDemo: false,
})
