import { createAction } from '@reduxjs/toolkit'

// device settings
export const udmodule_deviceSettings_refSourceChange_watcher = createAction(
  'deviceControl/udmodule_deviceSettings_refSourceChange_watcher'
)
export const udmodule_deviceSettings_InternalEnableClick_watcher = createAction(
  'deviceControl/udmodule_deviceSettings_InternalEnableClick_watcher'
)
export const udmodule_deviceSettings_OutputChange_watcher = createAction(
  'deviceControl/udmodule_deviceSettings_OutputChange_watcher'
)
export const udmodule_deviceSettings_InputChange_watcher = createAction(
  'deviceControl/udmodule_deviceSettings_InputChange_watcher'
)

// freqConverter - ud module
export const udModule_toExternalCheckModal_cancelClick_watcher = createAction(
  'modalEvent/udModule_toExternalCheckModal_cancelClick_watcher'
)
export const udModule_toExternalCheckModal_useExternalClick_watcher =
  createAction(
    'modalEvent/udModule_toExternalCheckModal_useExternalClick_watcher'
  )
export const udModule_turnOffOutputCheckModal_okClick_watcher = createAction(
  'modalEvent/udModule_turnOffOutputCheckModal_okClick_watcher'
)

export const udModule_toExternalErrorModal_tryAgainClick_watcher = createAction(
  'modalEvent/udModule_toExternalErrorModal_tryAgainClick_watcher'
)
export const udModule_toExternalErrorModal_cancelClick_watcher = createAction(
  'modalEvent/udModule_toExternalErrorModal_cancelClick_watcher'
)
