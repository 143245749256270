import React, { useState } from 'react'

import InputGroup from 'src/components/Input/InputGroup'
import CommonSlider from 'src/components/Slider/CommonSlider'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import Gain from 'src/assets/svg/unit/Gain'

import { h6, small } from 'src/containers/operating/device/beamformers/style'

import {
  commonGainStep,
  getCommonGainWarningText,
} from 'src/constants/beamformers'

const CommonGain = ({ boardIndex, ...props }) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const { commonCurrentGain, commonGainMin, commonGainMax, lstChannelData } =
    props

  const { handleCommonGainSliderChange, handleCommonGainChange } = props

  const boardChPowerAllOff =
    lstChannelData.filter(chData => chData.power).length === 0

  const [commonGainFocus, setCommonGainFocus] = useState(false)
  const [commonGainWarning, setCommonGainWarning] = useState(false)

  const commonGainWarningText = getCommonGainWarningText({
    min: commonGainMin,
    max: commonGainMax,
    step: commonGainStep,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleSliderChange = value =>
    handleCommonGainSliderChange({ boardIndex, value })

  const handleSliderAfterChange = value =>
    handleCommonGainChange({ boardIndex, value, isValid: true })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const commonGainInputProps = {
    // input params
    icon: <Gain />,
    unit: 'dB',
    off: boardChPowerAllOff,
    step: commonGainStep,
    validMin: commonGainMin,
    validMax: commonGainMax,
    decimalScale: 1,
    value: String(commonCurrentGain),
    // progress bar params
    showProgress: false,
    emptyValue: '',
  }

  const commonGainDialogOverwriteElement = (
    <div className='relative flex flex-col'>
      <InputGroup
        className='w-[130px] h-[32px] mb-1'
        warning={true}
        disabled={true}
        {...commonGainInputProps}
      />
      <h6 className={small + ' text-yellow '}>
        Range {commonGainMin} ~ {commonGainMax}
      </h6>
    </div>
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className={leftSide}>
        <h6 className={h6}>RF Board {boardIndex + 1} Common Gain</h6>
        <CommonSlider
          value={+commonCurrentGain}
          min={commonGainMin ?? 0}
          max={commonGainMax ?? 0}
          step={0.5}
          disabled={boardChPowerAllOff}
          onChange={handleSliderChange}
          onAfterChange={handleSliderAfterChange}
        />
      </div>

      <div className='flex flex-col justify-center h-[46px]'>
        <InputGroupAppendDialog
          {...commonGainInputProps}
          className='w-[130px]'
          // dialog params
          dialogText={commonGainWarningText[commonGainWarning]}
          placement='right'
          overwriteOffset={{ top: 0, left: 0 }}
          overwriteElements={commonGainDialogOverwriteElement}
          // event params
          setFocus={result => setCommonGainFocus(result)}
          warning={commonGainWarning}
          setWarning={result => setCommonGainWarning(result)}
          changeCallback={({ value, isValid }) =>
            handleCommonGainChange({
              boardIndex,
              value: value,
              isValid,
            })
          }
        />

        {commonGainFocus && (
          <h6 className={small + ' text-light-5  mt-1'}>
            Range {commonGainMin} ~ {commonGainMax}
          </h6>
        )}
      </div>
    </div>
  )
}

export default CommonGain

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static  Func    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[Components-composite-beamformers-ChannelModule-CommonGain-container] 
                      flex 
                      gap-x-7 
                      pt-3 px-[26px]
                      `
const leftSide = `[Components-composite-beamformers-ChannelModule-CommonGain-leftSide] 
                      w-full 
                      flex flex-col 
                      items-start 
                      gap-y-1
                      `
