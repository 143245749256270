import { current } from '@reduxjs/toolkit'

// props: {
//   status: 'chooseDeviceAndPurpose',
//   interestedProduct,
//   purpose,
//   purposeOther,
//   firstName,
//   lastName,
//   email,
//   comments,
//   privacyPolicy,
//   errorMessage,
// },
const getQuote = {
  showModalOfGetQuoteChooseDeviceAndPurpose: (state, action) => {
    const prevProps = current(state).normal.props

    state.normal = {
      type: 'GET_QUOTE',
      props: {
        ...current(state).normal.props,
        status: 'chooseDeviceAndPurpose',
        interestedProduct: prevProps.interestedProduct ?? [],
        purpose: prevProps.purpose ?? '',
        firstName: prevProps.firstName ?? '',
        lastName: prevProps.lastName ?? '',
        email: prevProps.email ?? '',
        comments: prevProps.comments ?? '',
        privacyPolicy: prevProps.privacyPolicy ?? false,
        errorMessage: prevProps.errorMessage ?? '',
      },
    }
    return state
  },
  showModalOfGetQuotePersonalInformation: (state, action) => {
    state.normal = {
      type: 'GET_QUOTE',
      props: { ...current(state).normal.props, status: 'personalInformation' },
    }
    return state
  },
  setModalOfGetQuoteContent(state, action) {
    // --- action.payload example
    // const {
    //   interestedProduct,
    //   purpose,
    //   purposeOther,
    //   firstName,
    //   lastName,
    //   email,
    //   comments,
    //   privacyPolicy,
    //   errorMessage,
    // } = action.payload

    state.normal = {
      type: 'GET_QUOTE',
      props: { ...current(state).normal.props, ...action.payload },
    }
    return state
  },
  showModalOfGetQuoteSuccess: (state, action) => {
    state.normal = {
      type: 'GET_QUOTE',
      props: { ...current(state).normal.props, status: 'success' },
    }
    return state
  },
}
export default getQuote
