import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Array = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='152'
        height='158'
        viewBox='0 0 152 158'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 65.2998H51.7753V65.7752L51.2998 65.7752V65.2998ZM51.2998 66.0129V66.4881H51.7753V66.0129H51.2998ZM52.013 66.0129V66.4881H52.4881V66.0129H52.013ZM52.4881 65.7752V65.2998H52.013V65.7752L52.4881 65.7752ZM51.8348 65.4632H51.9536V65.79H51.9236V65.9982H51.9536V66.325H51.8348V65.9982H51.8642V65.79H51.8348V65.4632ZM51.4629 65.8348V65.9536H51.7897V65.8348H51.4629ZM51.9981 65.9536V65.8348H52.3249V65.9536H51.9981ZM51.2998 66.7113H51.7753V67.1868H51.2998V66.7113ZM51.2998 67.4245V67.8996H51.7753V67.4245H51.2998ZM52.013 67.4245V67.8996H52.4881V67.4245H52.013ZM52.4881 67.1868V66.7113H52.013V67.1868H52.4881ZM51.8348 66.8747H51.9536V67.2015H51.9236V67.4097H51.9536V67.7364H51.8348V67.4097H51.8642V67.2015H51.8348V66.8747ZM51.4629 67.2463V67.3651H51.7897V67.2463H51.4629ZM51.9981 67.3651V67.2463H52.3249V67.3651H51.9981ZM53.1869 65.2998H52.7115V65.7753L53.1869 65.7753V65.2998ZM52.7115 66.4881V66.013H53.1869V66.4881H52.7115ZM53.4246 66.4881V66.013H53.8998V66.4881H53.4246ZM53.8998 65.2998V65.7753L53.4246 65.7753V65.2998H53.8998ZM53.3653 65.4632H53.2465V65.79H53.2759V65.9982H53.2465V66.325H53.3653V65.9982H53.3353V65.79H53.3653V65.4632ZM52.875 65.9536V65.8348H53.2018V65.9536H52.875ZM53.4095 65.8348V65.9536H53.7363V65.8348H53.4095ZM52.7115 66.7114H53.1869V67.1868H52.7115V66.7114ZM52.7115 67.4245V67.8997H53.1869V67.4245H52.7115ZM53.4246 67.4245V67.8997H53.8998V67.4245H53.4246ZM53.8998 67.1868V66.7114H53.4246V67.1868H53.8998ZM53.2465 66.8748H53.3653V67.2016H53.3353V67.4097H53.3653V67.7365H53.2465V67.4097H53.2759V67.2016H53.2465V66.8748ZM52.875 67.2463V67.3651H53.2018V67.2463H52.875ZM53.4095 67.3651V67.2463H53.7363V67.3651H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 65.2998H54.8754V65.7752L54.3999 65.7752V65.2998ZM54.3999 66.0129V66.4881H54.8754V66.0129H54.3999ZM55.1131 66.0129V66.4881H55.5882V66.0129H55.1131ZM55.5882 65.7752V65.2998H55.1131V65.7752L55.5882 65.7752ZM54.9349 65.4632H55.0537V65.79H55.0237V65.9982H55.0537V66.325H54.9349V65.9982H54.9643V65.79H54.9349V65.4632ZM54.563 65.8348V65.9536H54.8898V65.8348H54.563ZM55.0982 65.9536V65.8348H55.425V65.9536H55.0982ZM54.3999 66.7113H54.8753V67.1868H54.3999V66.7113ZM54.3999 67.4245V67.8996H54.8753V67.4245H54.3999ZM55.1131 67.4245V67.8996H55.5882V67.4245H55.1131ZM55.5882 67.1868V66.7113H55.1131V67.1868L55.5882 67.1868ZM54.9349 66.8747H55.0537V67.2015H55.0237V67.4097H55.0537V67.7364H54.9349V67.4097H54.9643V67.2015H54.9349V66.8747ZM54.563 67.2463V67.3651H54.8898V67.2463H54.563ZM55.0982 67.3651V67.2463H55.425V67.3651H55.0982ZM56.287 65.2998H55.8116V65.7753L56.287 65.7753V65.2998ZM55.8116 66.4881V66.013H56.287V66.4881H55.8116ZM56.5247 66.4881V66.013H56.9999V66.4881H56.5247ZM56.9999 65.2998V65.7753L56.5247 65.7753V65.2998H56.9999ZM56.4654 65.4632H56.3466V65.79H56.376V65.9982H56.3466V66.325H56.4654V65.9982H56.4354V65.79H56.4654V65.4632ZM55.9751 65.9536V65.8348H56.3019V65.9536H55.9751ZM56.5096 65.8348V65.9536H56.8364V65.8348H56.5096ZM55.8116 66.7114H56.287V67.1868L55.8116 67.1868V66.7114ZM55.8116 67.4245V67.8997H56.287V67.4245H55.8116ZM56.5247 67.4245V67.8997H56.9999V67.4245H56.5247ZM56.9999 67.1868V66.7114H56.5247V67.1868H56.9999ZM56.3466 66.8748H56.4654V67.2016H56.4354V67.4097H56.4654V67.7365H56.3466V67.4097H56.376V67.2016H56.3466V66.8748ZM55.9751 67.2463V67.3651H56.3019V67.2463H55.9751ZM56.5096 67.3651V67.2463H56.8364V67.3651H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 65.2998H57.9755V65.7752L57.5 65.7752V65.2998ZM57.5 66.0129V66.4881H57.9755V66.0129H57.5ZM58.2132 66.0129V66.4881H58.6883V66.0129H58.2132ZM58.6883 65.7752V65.2998H58.2132V65.7752L58.6883 65.7752ZM58.035 65.4632H58.1538V65.79H58.1238V65.9982H58.1538V66.325H58.035V65.9982H58.0644V65.79H58.035V65.4632ZM57.6631 65.8348V65.9536H57.9899V65.8348H57.6631ZM58.1983 65.9536V65.8348H58.5251V65.9536H58.1983ZM57.5 66.7113H57.9754V67.1868H57.5V66.7113ZM57.5 67.4245V67.8996H57.9754V67.4245H57.5ZM58.2132 67.4245V67.8996H58.6883V67.4245H58.2132ZM58.6883 67.1868V66.7113H58.2132V67.1868L58.6883 67.1868ZM58.035 66.8747H58.1538V67.2015H58.1238V67.4097H58.1538V67.7364H58.035V67.4097H58.0644V67.2015H58.035V66.8747ZM57.6631 67.2463V67.3651H57.9899V67.2463H57.6631ZM58.1983 67.3651V67.2463H58.5251V67.3651H58.1983ZM59.3871 65.2998H58.9117V65.7753L59.3871 65.7753V65.2998ZM58.9117 66.4881V66.013H59.3871V66.4881H58.9117ZM59.6248 66.4881V66.013H60.1V66.4881H59.6248ZM60.1 65.2998V65.7753L59.6248 65.7753V65.2998H60.1ZM59.5655 65.4632H59.4467V65.79H59.4761V65.9982H59.4467V66.325H59.5655V65.9982H59.5355V65.79H59.5655V65.4632ZM59.0752 65.9536V65.8348H59.402V65.9536H59.0752ZM59.6097 65.8348V65.9536H59.9365V65.8348H59.6097ZM58.9117 66.7114H59.3871V67.1868L58.9117 67.1868V66.7114ZM58.9117 67.4245V67.8997H59.3871V67.4245H58.9117ZM59.6248 67.4245V67.8997H60.1V67.4245H59.6248ZM60.1 67.1868V66.7114H59.6248V67.1868H60.1ZM59.4467 66.8748H59.5655V67.2016H59.5355V67.4097H59.5655V67.7365H59.4467V67.4097H59.4761V67.2016H59.4467V66.8748ZM59.0752 67.2463V67.3651H59.402V67.2463H59.0752ZM59.6097 67.3651V67.2463H59.9365V67.3651H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 65.2998H61.0756V65.7752L60.6001 65.7752V65.2998ZM60.6001 66.0129V66.4881H61.0756V66.0129H60.6001ZM61.3133 66.0129V66.4881H61.7884V66.0129H61.3133ZM61.7884 65.7752V65.2998H61.3133V65.7752L61.7884 65.7752ZM61.1351 65.4632H61.2539V65.79H61.2239V65.9982H61.2539V66.325H61.1351V65.9982H61.1645V65.79H61.1351V65.4632ZM60.7632 65.8348V65.9536H61.09V65.8348H60.7632ZM61.2984 65.9536V65.8348H61.6252V65.9536H61.2984ZM60.6001 66.7113H61.0755V67.1868H60.6001V66.7113ZM60.6001 67.4245V67.8996H61.0755V67.4245H60.6001ZM61.3133 67.4245V67.8996H61.7884V67.4245H61.3133ZM61.7884 67.1868V66.7113H61.3133V67.1868L61.7884 67.1868ZM61.1351 66.8747H61.2539V67.2015H61.2239V67.4097H61.2539V67.7364H61.1351V67.4097H61.1645V67.2015H61.1351V66.8747ZM60.7632 67.2463V67.3651H61.09V67.2463H60.7632ZM61.2984 67.3651V67.2463H61.6252V67.3651H61.2984ZM62.4872 65.2998H62.0118V65.7753L62.4872 65.7753V65.2998ZM62.0118 66.4881V66.013H62.4872V66.4881H62.0118ZM62.7249 66.4881V66.013H63.2001V66.4881H62.7249ZM63.2001 65.2998V65.7753L62.7249 65.7753V65.2998H63.2001ZM62.6656 65.4632H62.5468V65.79H62.5762V65.9982H62.5468V66.325H62.6656V65.9982H62.6356V65.79H62.6656V65.4632ZM62.1753 65.9536V65.8348H62.5021V65.9536H62.1753ZM62.7098 65.8348V65.9536H63.0366V65.8348H62.7098ZM62.0118 66.7114H62.4872V67.1868L62.0118 67.1868V66.7114ZM62.0118 67.4245V67.8997H62.4872V67.4245H62.0118ZM62.7249 67.4245V67.8997H63.2001V67.4245H62.7249ZM63.2001 67.1868V66.7114H62.7249V67.1868H63.2001ZM62.5468 66.8748H62.6656V67.2016H62.6356V67.4097H62.6656V67.7365H62.5468V67.4097H62.5762V67.2016H62.5468V66.8748ZM62.1753 67.2463V67.3651H62.5021V67.2463H62.1753ZM62.7098 67.3651V67.2463H63.0366V67.3651H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 68.4004H51.7753V68.8758L51.2998 68.8758V68.4004ZM51.2998 69.1135V69.5887H51.7753V69.1135H51.2998ZM52.013 69.1135V69.5887H52.4881V69.1135H52.013ZM52.4881 68.8758V68.4004H52.013V68.8758L52.4881 68.8758ZM51.8348 68.5638H51.9536V68.8906H51.9236V69.0988H51.9536V69.4255H51.8348V69.0988H51.8642V68.8906H51.8348V68.5638ZM51.4629 68.9353V69.0542H51.7897V68.9353H51.4629ZM51.9981 69.0542V68.9354H52.3249V69.0542H51.9981ZM51.2998 69.8119H51.7753V70.2874H51.2998V69.8119ZM51.2998 70.5251V71.0002H51.7753V70.5251H51.2998ZM52.013 70.5251V71.0002H52.4881V70.5251H52.013ZM52.4881 70.2874V69.8119H52.013V70.2874L52.4881 70.2874ZM51.8348 69.9753H51.9536V70.3021H51.9236V70.5103H51.9536V70.837H51.8348V70.5103H51.8642V70.3021H51.8348V69.9753ZM51.4629 70.3468V70.4657H51.7897V70.3468H51.4629ZM51.9981 70.4657V70.3469H52.3249V70.4657H51.9981ZM53.1869 68.4004H52.7115V68.8759L53.1869 68.8759V68.4004ZM52.7115 69.5887V69.1136H53.1869V69.5887H52.7115ZM53.4246 69.5887V69.1136H53.8998V69.5887H53.4246ZM53.8998 68.4004V68.8759L53.4246 68.8759V68.4004H53.8998ZM53.3653 68.5638H53.2465V68.8906H53.2759V69.0988H53.2465V69.4256H53.3653V69.0988H53.3353V68.8906H53.3653V68.5638ZM52.875 69.0542V68.9354H53.2018V69.0542H52.875ZM53.4095 68.9354V69.0542H53.7363V68.9354H53.4095ZM52.7115 69.812H53.1869V70.2874L52.7115 70.2874V69.812ZM52.7115 70.5251V71.0003H53.1869V70.5251H52.7115ZM53.4246 70.5251V71.0003H53.8998V70.5251H53.4246ZM53.8998 70.2874V69.812H53.4246V70.2874H53.8998ZM53.2465 69.9754H53.3653V70.3021H53.3353V70.5103H53.3653V70.8371H53.2465V70.5103H53.2759V70.3021H53.2465V69.9754ZM52.875 70.3469V70.4657H53.2018V70.3469H52.875ZM53.4095 70.4657V70.3469H53.7363V70.4657H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 68.4004H54.8754V68.8758L54.3999 68.8758V68.4004ZM54.3999 69.1135V69.5887H54.8754V69.1135H54.3999ZM55.1131 69.1135V69.5887H55.5882V69.1135H55.1131ZM55.5882 68.8758V68.4004H55.1131V68.8758L55.5882 68.8758ZM54.9349 68.5638H55.0537V68.8906H55.0237V69.0988H55.0537V69.4255H54.9349V69.0988H54.9643V68.8906H54.9349V68.5638ZM54.563 68.9353V69.0542H54.8898V68.9353H54.563ZM55.0982 69.0542V68.9354H55.425V69.0542H55.0982ZM54.3999 69.8119H54.8753V70.2874H54.3999V69.8119ZM54.3999 70.5251V71.0002H54.8753V70.5251H54.3999ZM55.1131 70.5251V71.0002H55.5882V70.5251H55.1131ZM55.5882 70.2874V69.8119H55.1131V70.2874L55.5882 70.2874ZM54.9349 69.9753H55.0537V70.3021H55.0237V70.5103H55.0537V70.837H54.9349V70.5103H54.9643V70.3021H54.9349V69.9753ZM54.563 70.3468V70.4657H54.8898V70.3468H54.563ZM55.0982 70.4657V70.3469H55.425V70.4657H55.0982ZM56.287 68.4004H55.8116V68.8759L56.287 68.8759V68.4004ZM55.8116 69.5887V69.1136H56.287V69.5887H55.8116ZM56.5247 69.5887V69.1136H56.9999V69.5887H56.5247ZM56.9999 68.4004V68.8759L56.5247 68.8759V68.4004H56.9999ZM56.4654 68.5638H56.3466V68.8906H56.376V69.0988H56.3466V69.4256H56.4654V69.0988H56.4354V68.8906H56.4654V68.5638ZM55.9751 69.0542V68.9354H56.3019V69.0542H55.9751ZM56.5096 68.9354V69.0542H56.8364V68.9354H56.5096ZM55.8116 69.812H56.287V70.2874L55.8116 70.2874V69.812ZM55.8116 70.5251V71.0003H56.287V70.5251H55.8116ZM56.5247 70.5251V71.0003H56.9999V70.5251H56.5247ZM56.9999 70.2874V69.812H56.5247V70.2874H56.9999ZM56.3466 69.9754H56.4654V70.3021H56.4354V70.5103H56.4654V70.8371H56.3466V70.5103H56.376V70.3021H56.3466V69.9754ZM55.9751 70.3469V70.4657H56.3019V70.3469H55.9751ZM56.5096 70.4657V70.3469H56.8364V70.4657H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 68.4004H57.9755V68.8758L57.5 68.8758V68.4004ZM57.5 69.1135V69.5887H57.9755V69.1135H57.5ZM58.2132 69.1135V69.5887H58.6883V69.1135H58.2132ZM58.6883 68.8758V68.4004H58.2132V68.8758L58.6883 68.8758ZM58.035 68.5638H58.1538V68.8906H58.1238V69.0988H58.1538V69.4255H58.035V69.0988H58.0644V68.8906H58.035V68.5638ZM57.6631 68.9353V69.0542H57.9899V68.9353H57.6631ZM58.1983 69.0542V68.9354H58.5251V69.0542H58.1983ZM57.5 69.8119H57.9754V70.2874H57.5V69.8119ZM57.5 70.5251V71.0002H57.9754V70.5251H57.5ZM58.2132 70.5251V71.0002H58.6883V70.5251H58.2132ZM58.6883 70.2874V69.8119H58.2132V70.2874L58.6883 70.2874ZM58.035 69.9753H58.1538V70.3021H58.1238V70.5103H58.1538V70.837H58.035V70.5103H58.0644V70.3021H58.035V69.9753ZM57.6631 70.3468V70.4657H57.9899V70.3468H57.6631ZM58.1983 70.4657V70.3469H58.5251V70.4657H58.1983ZM59.3871 68.4004H58.9117V68.8759L59.3871 68.8759V68.4004ZM58.9117 69.5887V69.1136H59.3871V69.5887H58.9117ZM59.6248 69.5887V69.1136H60.1V69.5887H59.6248ZM60.1 68.4004V68.8759L59.6248 68.8759V68.4004H60.1ZM59.5655 68.5638H59.4467V68.8906H59.4761V69.0988H59.4467V69.4256H59.5655V69.0988H59.5355V68.8906H59.5655V68.5638ZM59.0752 69.0542V68.9354H59.402V69.0542H59.0752ZM59.6097 68.9354V69.0542H59.9365V68.9354H59.6097ZM58.9117 69.812H59.3871V70.2874L58.9117 70.2874V69.812ZM58.9117 70.5251V71.0003H59.3871V70.5251H58.9117ZM59.6248 70.5251V71.0003H60.1V70.5251H59.6248ZM60.1 70.2874V69.812H59.6248V70.2874H60.1ZM59.4467 69.9754H59.5655V70.3021H59.5355V70.5103H59.5655V70.8371H59.4467V70.5103H59.4761V70.3021H59.4467V69.9754ZM59.0752 70.3469V70.4657H59.402V70.3469H59.0752ZM59.6097 70.4657V70.3469H59.9365V70.4657H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 68.4004H61.0756V68.8758L60.6001 68.8758V68.4004ZM60.6001 69.1135V69.5887H61.0756V69.1135H60.6001ZM61.3133 69.1135V69.5887H61.7884V69.1135H61.3133ZM61.7884 68.8758V68.4004H61.3133V68.8758L61.7884 68.8758ZM61.1351 68.5638H61.2539V68.8906H61.2239V69.0988H61.2539V69.4255H61.1351V69.0988H61.1645V68.8906H61.1351V68.5638ZM60.7632 68.9353V69.0542H61.09V68.9353H60.7632ZM61.2984 69.0542V68.9354H61.6252V69.0542H61.2984ZM60.6001 69.8119H61.0755V70.2874H60.6001V69.8119ZM60.6001 70.5251V71.0002H61.0755V70.5251H60.6001ZM61.3133 70.5251V71.0002H61.7884V70.5251H61.3133ZM61.7884 70.2874V69.8119H61.3133V70.2874L61.7884 70.2874ZM61.1351 69.9753H61.2539V70.3021H61.2239V70.5103H61.2539V70.837H61.1351V70.5103H61.1645V70.3021H61.1351V69.9753ZM60.7632 70.3468V70.4657H61.09V70.3468H60.7632ZM61.2984 70.4657V70.3469H61.6252V70.4657H61.2984ZM62.4872 68.4004H62.0118V68.8759L62.4872 68.8759V68.4004ZM62.0118 69.5887V69.1136H62.4872V69.5887H62.0118ZM62.7249 69.5887V69.1136H63.2001V69.5887H62.7249ZM63.2001 68.4004V68.8759L62.7249 68.8759V68.4004H63.2001ZM62.6656 68.5638H62.5468V68.8906H62.5762V69.0988H62.5468V69.4256H62.6656V69.0988H62.6356V68.8906H62.6656V68.5638ZM62.1753 69.0542V68.9354H62.5021V69.0542H62.1753ZM62.7098 68.9354V69.0542H63.0366V68.9354H62.7098ZM62.0118 69.812H62.4872V70.2874L62.0118 70.2874V69.812ZM62.0118 70.5251V71.0003H62.4872V70.5251H62.0118ZM62.7249 70.5251V71.0003H63.2001V70.5251H62.7249ZM63.2001 70.2874V69.812H62.7249V70.2874H63.2001ZM62.5468 69.9754H62.6656V70.3021H62.6356V70.5103H62.6656V70.8371H62.5468V70.5103H62.5762V70.3021H62.5468V69.9754ZM62.1753 70.3469V70.4657H62.5021V70.3469H62.1753ZM62.7098 70.4657V70.3469H63.0366V70.4657H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 71.5H51.7753V71.9754L51.2998 71.9754V71.5ZM51.2998 72.2131V72.6883H51.7753V72.2131H51.2998ZM52.013 72.2131V72.6883H52.4881V72.2131H52.013ZM52.4881 71.9754V71.5H52.013V71.9754L52.4881 71.9754ZM51.8348 71.6634H51.9536V71.9902H51.9236V72.1984H51.9536V72.5251H51.8348V72.1984H51.8642V71.9902H51.8348V71.6634ZM51.4629 72.035V72.1538H51.7897V72.035H51.4629ZM51.9981 72.1538V72.035H52.3249V72.1538H51.9981ZM51.2998 72.9115H51.7753V73.387H51.2998V72.9115ZM51.2998 73.6247V74.0998H51.7753V73.6247H51.2998ZM52.013 73.6247V74.0998H52.4881V73.6247H52.013ZM52.4881 73.387V72.9115H52.013V73.387L52.4881 73.387ZM51.8348 73.0749H51.9536V73.4017H51.9236V73.6099H51.9536V73.9366H51.8348V73.6099H51.8642V73.4017H51.8348V73.0749ZM51.4629 73.4465V73.5653H51.7897V73.4465H51.4629ZM51.9981 73.5653V73.4465H52.3249V73.5653H51.9981ZM53.1869 71.5H52.7115V71.9755L53.1869 71.9755V71.5ZM52.7115 72.6883V72.2132H53.1869V72.6883H52.7115ZM53.4246 72.6883V72.2132H53.8998V72.6883H53.4246ZM53.8998 71.5V71.9755L53.4246 71.9755V71.5H53.8998ZM53.3653 71.6634H53.2465V71.9902H53.2759V72.1984H53.2465V72.5252H53.3653V72.1984H53.3353V71.9902H53.3653V71.6634ZM52.875 72.1538V72.035H53.2018V72.1538H52.875ZM53.4095 72.035V72.1538H53.7363V72.035H53.4095ZM52.7115 72.9116H53.1869V73.387L52.7115 73.387V72.9116ZM52.7115 73.6247V74.0999H53.1869V73.6247H52.7115ZM53.4246 73.6247V74.0999H53.8998V73.6247H53.4246ZM53.8998 73.387V72.9116H53.4246V73.387H53.8998ZM53.2465 73.075H53.3653V73.4018H53.3353V73.6099H53.3653V73.9367H53.2465V73.6099H53.2759V73.4018H53.2465V73.075ZM52.875 73.4465V73.5653H53.2018V73.4465H52.875ZM53.4095 73.5653V73.4465H53.7363V73.5653H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 71.5H54.8754V71.9754L54.3999 71.9754V71.5ZM54.3999 72.2131V72.6883H54.8754V72.2131H54.3999ZM55.1131 72.2131V72.6883H55.5882V72.2131H55.1131ZM55.5882 71.9754V71.5H55.1131V71.9754L55.5882 71.9754ZM54.9349 71.6634H55.0537V71.9902H55.0237V72.1984H55.0537V72.5251H54.9349V72.1984H54.9643V71.9902H54.9349V71.6634ZM54.563 72.035V72.1538H54.8898V72.035H54.563ZM55.0982 72.1538V72.035H55.425V72.1538H55.0982ZM54.3999 72.9115H54.8753V73.387H54.3999V72.9115ZM54.3999 73.6247V74.0998H54.8753V73.6247H54.3999ZM55.1131 73.6247V74.0998H55.5882V73.6247H55.1131ZM55.5882 73.387V72.9115H55.1131V73.387L55.5882 73.387ZM54.9349 73.0749H55.0537V73.4017H55.0237V73.6099H55.0537V73.9366H54.9349V73.6099H54.9643V73.4017H54.9349V73.0749ZM54.563 73.4465V73.5653H54.8898V73.4465H54.563ZM55.0982 73.5653V73.4465H55.425V73.5653H55.0982ZM56.287 71.5H55.8116V71.9755L56.287 71.9755V71.5ZM55.8116 72.6883V72.2132H56.287V72.6883H55.8116ZM56.5247 72.6883V72.2132H56.9999V72.6883H56.5247ZM56.9999 71.5V71.9755L56.5247 71.9755V71.5H56.9999ZM56.4654 71.6634H56.3466V71.9902H56.376V72.1984H56.3466V72.5252H56.4654V72.1984H56.4354V71.9902H56.4654V71.6634ZM55.9751 72.1538V72.035H56.3019V72.1538H55.9751ZM56.5096 72.035V72.1538H56.8364V72.035H56.5096ZM55.8116 72.9116H56.287V73.387L55.8116 73.387V72.9116ZM55.8116 73.6247V74.0999H56.287V73.6247H55.8116ZM56.5247 73.6247V74.0999H56.9999V73.6247H56.5247ZM56.9999 73.387V72.9116H56.5247V73.387H56.9999ZM56.3466 73.075H56.4654V73.4018H56.4354V73.6099H56.4654V73.9367H56.3466V73.6099H56.376V73.4018H56.3466V73.075ZM55.9751 73.4465V73.5653H56.3019V73.4465H55.9751ZM56.5096 73.5653V73.4465H56.8364V73.5653H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 71.5H57.9755V71.9754L57.5 71.9754V71.5ZM57.5 72.2131V72.6883H57.9755V72.2131H57.5ZM58.2132 72.2131V72.6883H58.6883V72.2131H58.2132ZM58.6883 71.9754V71.5H58.2132V71.9754L58.6883 71.9754ZM58.035 71.6634H58.1538V71.9902H58.1238V72.1984H58.1538V72.5251H58.035V72.1984H58.0644V71.9902H58.035V71.6634ZM57.6631 72.035V72.1538H57.9899V72.035H57.6631ZM58.1983 72.1538V72.035H58.5251V72.1538H58.1983ZM57.5 72.9115H57.9754V73.387H57.5V72.9115ZM57.5 73.6247V74.0998H57.9754V73.6247H57.5ZM58.2132 73.6247V74.0998H58.6883V73.6247H58.2132ZM58.6883 73.387V72.9115H58.2132V73.387L58.6883 73.387ZM58.035 73.0749H58.1538V73.4017H58.1238V73.6099H58.1538V73.9366H58.035V73.6099H58.0644V73.4017H58.035V73.0749ZM57.6631 73.4465V73.5653H57.9899V73.4465H57.6631ZM58.1983 73.5653V73.4465H58.5251V73.5653H58.1983ZM59.3871 71.5H58.9117V71.9755L59.3871 71.9755V71.5ZM58.9117 72.6883V72.2132H59.3871V72.6883H58.9117ZM59.6248 72.6883V72.2132H60.1V72.6883H59.6248ZM60.1 71.5V71.9755L59.6248 71.9755V71.5H60.1ZM59.5655 71.6634H59.4467V71.9902H59.4761V72.1984H59.4467V72.5252H59.5655V72.1984H59.5355V71.9902H59.5655V71.6634ZM59.0752 72.1538V72.035H59.402V72.1538H59.0752ZM59.6097 72.035V72.1538H59.9365V72.035H59.6097ZM58.9117 72.9116H59.3871V73.387L58.9117 73.387V72.9116ZM58.9117 73.6247V74.0999H59.3871V73.6247H58.9117ZM59.6248 73.6247V74.0999H60.1V73.6247H59.6248ZM60.1 73.387V72.9116H59.6248V73.387H60.1ZM59.4467 73.075H59.5655V73.4018H59.5355V73.6099H59.5655V73.9367H59.4467V73.6099H59.4761V73.4018H59.4467V73.075ZM59.0752 73.4465V73.5653H59.402V73.4465H59.0752ZM59.6097 73.5653V73.4465H59.9365V73.5653H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 71.5H61.0756V71.9754L60.6001 71.9754V71.5ZM60.6001 72.2131V72.6883H61.0756V72.2131H60.6001ZM61.3133 72.2131V72.6883H61.7884V72.2131H61.3133ZM61.7884 71.9754V71.5H61.3133V71.9754L61.7884 71.9754ZM61.1351 71.6634H61.2539V71.9902H61.2239V72.1984H61.2539V72.5251H61.1351V72.1984H61.1645V71.9902H61.1351V71.6634ZM60.7632 72.035V72.1538H61.09V72.035H60.7632ZM61.2984 72.1538V72.035H61.6252V72.1538H61.2984ZM60.6001 72.9115H61.0755V73.387H60.6001V72.9115ZM60.6001 73.6247V74.0998H61.0755V73.6247H60.6001ZM61.3133 73.6247V74.0998H61.7884V73.6247H61.3133ZM61.7884 73.387V72.9115H61.3133V73.387L61.7884 73.387ZM61.1351 73.0749H61.2539V73.4017H61.2239V73.6099H61.2539V73.9366H61.1351V73.6099H61.1645V73.4017H61.1351V73.0749ZM60.7632 73.4465V73.5653H61.09V73.4465H60.7632ZM61.2984 73.5653V73.4465H61.6252V73.5653H61.2984ZM62.4872 71.5H62.0118V71.9755L62.4872 71.9755V71.5ZM62.0118 72.6883V72.2132H62.4872V72.6883H62.0118ZM62.7249 72.6883V72.2132H63.2001V72.6883H62.7249ZM63.2001 71.5V71.9755L62.7249 71.9755V71.5H63.2001ZM62.6656 71.6634H62.5468V71.9902H62.5762V72.1984H62.5468V72.5252H62.6656V72.1984H62.6356V71.9902H62.6656V71.6634ZM62.1753 72.1538V72.035H62.5021V72.1538H62.1753ZM62.7098 72.035V72.1538H63.0366V72.035H62.7098ZM62.0118 72.9116H62.4872V73.387L62.0118 73.387V72.9116ZM62.0118 73.6247V74.0999H62.4872V73.6247H62.0118ZM62.7249 73.6247V74.0999H63.2001V73.6247H62.7249ZM63.2001 73.387V72.9116H62.7249V73.387H63.2001ZM62.5468 73.075H62.6656V73.4018H62.6356V73.6099H62.6656V73.9367H62.5468V73.6099H62.5762V73.4018H62.5468V73.075ZM62.1753 73.4465V73.5653H62.5021V73.4465H62.1753ZM62.7098 73.5653V73.4465H63.0366V73.5653H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 74.5996H51.7753V75.075L51.2998 75.075V74.5996ZM51.2998 75.3127V75.7879H51.7753V75.3127H51.2998ZM52.013 75.3127V75.7879H52.4881V75.3127H52.013ZM52.4881 75.075V74.5996H52.013V75.075L52.4881 75.075ZM51.8348 74.763H51.9536V75.0898H51.9236V75.298H51.9536V75.6248H51.8348V75.298H51.8642V75.0898H51.8348V74.763ZM51.4629 75.1346V75.2534H51.7897V75.1346H51.4629ZM51.9981 75.2534V75.1346H52.3249V75.2534H51.9981ZM51.2998 76.0112H51.7753V76.4866H51.2998V76.0112ZM51.2998 76.7243V77.1994H51.7753V76.7243H51.2998ZM52.013 76.7243V77.1994H52.4881V76.7243H52.013ZM52.4881 76.4866V76.0112H52.013V76.4866L52.4881 76.4866ZM51.8348 76.1746H51.9536V76.5013H51.9236V76.7095H51.9536V77.0363H51.8348V76.7095H51.8642V76.5013H51.8348V76.1746ZM51.4629 76.5461V76.6649H51.7897V76.5461H51.4629ZM51.9981 76.6649V76.5461H52.3249V76.6649H51.9981ZM53.1869 74.5996H52.7115V75.0751L53.1869 75.0751V74.5996ZM52.7115 75.7879V75.3128H53.1869V75.7879H52.7115ZM53.4246 75.7879V75.3128H53.8998V75.7879H53.4246ZM53.8998 74.5996V75.0751L53.4246 75.0751V74.5996H53.8998ZM53.3653 74.763H53.2465V75.0898H53.2759V75.298H53.2465V75.6248H53.3653V75.298H53.3353V75.0898H53.3653V74.763ZM52.875 75.2534V75.1346H53.2018V75.2534H52.875ZM53.4095 75.1346V75.2534H53.7363V75.1346H53.4095ZM52.7115 76.0112H53.1869V76.4866L52.7115 76.4866V76.0112ZM52.7115 76.7243V77.1995H53.1869V76.7243H52.7115ZM53.4246 76.7243V77.1995H53.8998V76.7243H53.4246ZM53.8998 76.4866V76.0112H53.4246V76.4866H53.8998ZM53.2465 76.1746H53.3653V76.5014H53.3353V76.7095H53.3653V77.0363H53.2465V76.7095H53.2759V76.5014H53.2465V76.1746ZM52.875 76.5461V76.6649H53.2018V76.5461H52.875ZM53.4095 76.6649V76.5461H53.7363V76.6649H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 74.5996H54.8754V75.075L54.3999 75.075V74.5996ZM54.3999 75.3127V75.7879H54.8754V75.3127H54.3999ZM55.1131 75.3127V75.7879H55.5882V75.3127H55.1131ZM55.5882 75.075V74.5996H55.1131V75.075L55.5882 75.075ZM54.9349 74.763H55.0537V75.0898H55.0237V75.298H55.0537V75.6248H54.9349V75.298H54.9643V75.0898H54.9349V74.763ZM54.563 75.1346V75.2534H54.8898V75.1346H54.563ZM55.0982 75.2534V75.1346H55.425V75.2534H55.0982ZM54.3999 76.0112H54.8753V76.4866H54.3999V76.0112ZM54.3999 76.7243V77.1994H54.8753V76.7243H54.3999ZM55.1131 76.7243V77.1994H55.5882V76.7243H55.1131ZM55.5882 76.4866V76.0112H55.1131V76.4866L55.5882 76.4866ZM54.9349 76.1746H55.0537V76.5013H55.0237V76.7095H55.0537V77.0363H54.9349V76.7095H54.9643V76.5013H54.9349V76.1746ZM54.563 76.5461V76.6649H54.8898V76.5461H54.563ZM55.0982 76.6649V76.5461H55.425V76.6649H55.0982ZM56.287 74.5996H55.8116V75.0751L56.287 75.0751V74.5996ZM55.8116 75.7879V75.3128H56.287V75.7879H55.8116ZM56.5247 75.7879V75.3128H56.9999V75.7879H56.5247ZM56.9999 74.5996V75.0751L56.5247 75.0751V74.5996H56.9999ZM56.4654 74.763H56.3466V75.0898H56.376V75.298H56.3466V75.6248H56.4654V75.298H56.4354V75.0898H56.4654V74.763ZM55.9751 75.2534V75.1346H56.3019V75.2534H55.9751ZM56.5096 75.1346V75.2534H56.8364V75.1346H56.5096ZM55.8116 76.0112H56.287V76.4866L55.8116 76.4866V76.0112ZM55.8116 76.7243V77.1995H56.287V76.7243H55.8116ZM56.5247 76.7243V77.1995H56.9999V76.7243H56.5247ZM56.9999 76.4866V76.0112H56.5247V76.4866H56.9999ZM56.3466 76.1746H56.4654V76.5014H56.4354V76.7095H56.4654V77.0363H56.3466V76.7095H56.376V76.5014H56.3466V76.1746ZM55.9751 76.5461V76.6649H56.3019V76.5461H55.9751ZM56.5096 76.6649V76.5461H56.8364V76.6649H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 74.5996H57.9755V75.075L57.5 75.075V74.5996ZM57.5 75.3127V75.7879H57.9755V75.3127H57.5ZM58.2132 75.3127V75.7879H58.6883V75.3127H58.2132ZM58.6883 75.075V74.5996H58.2132V75.075L58.6883 75.075ZM58.035 74.763H58.1538V75.0898H58.1238V75.298H58.1538V75.6248H58.035V75.298H58.0644V75.0898H58.035V74.763ZM57.6631 75.1346V75.2534H57.9899V75.1346H57.6631ZM58.1983 75.2534V75.1346H58.5251V75.2534H58.1983ZM57.5 76.0112H57.9754V76.4866H57.5V76.0112ZM57.5 76.7243V77.1994H57.9754V76.7243H57.5ZM58.2132 76.7243V77.1994H58.6883V76.7243H58.2132ZM58.6883 76.4866V76.0112H58.2132V76.4866L58.6883 76.4866ZM58.035 76.1746H58.1538V76.5013H58.1238V76.7095H58.1538V77.0363H58.035V76.7095H58.0644V76.5013H58.035V76.1746ZM57.6631 76.5461V76.6649H57.9899V76.5461H57.6631ZM58.1983 76.6649V76.5461H58.5251V76.6649H58.1983ZM59.3871 74.5996H58.9117V75.0751L59.3871 75.0751V74.5996ZM58.9117 75.7879V75.3128H59.3871V75.7879H58.9117ZM59.6248 75.7879V75.3128H60.1V75.7879H59.6248ZM60.1 74.5996V75.0751L59.6248 75.0751V74.5996H60.1ZM59.5655 74.763H59.4467V75.0898H59.4761V75.298H59.4467V75.6248H59.5655V75.298H59.5355V75.0898H59.5655V74.763ZM59.0752 75.2534V75.1346H59.402V75.2534H59.0752ZM59.6097 75.1346V75.2534H59.9365V75.1346H59.6097ZM58.9117 76.0112H59.3871V76.4866L58.9117 76.4866V76.0112ZM58.9117 76.7243V77.1995H59.3871V76.7243H58.9117ZM59.6248 76.7243V77.1995H60.1V76.7243H59.6248ZM60.1 76.4866V76.0112H59.6248V76.4866H60.1ZM59.4467 76.1746H59.5655V76.5014H59.5355V76.7095H59.5655V77.0363H59.4467V76.7095H59.4761V76.5014H59.4467V76.1746ZM59.0752 76.5461V76.6649H59.402V76.5461H59.0752ZM59.6097 76.6649V76.5461H59.9365V76.6649H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 74.5996H61.0756V75.075L60.6001 75.075V74.5996ZM60.6001 75.3127V75.7879H61.0756V75.3127H60.6001ZM61.3133 75.3127V75.7879H61.7884V75.3127H61.3133ZM61.7884 75.075V74.5996H61.3133V75.075L61.7884 75.075ZM61.1351 74.763H61.2539V75.0898H61.2239V75.298H61.2539V75.6248H61.1351V75.298H61.1645V75.0898H61.1351V74.763ZM60.7632 75.1346V75.2534H61.09V75.1346H60.7632ZM61.2984 75.2534V75.1346H61.6252V75.2534H61.2984ZM60.6001 76.0112H61.0755V76.4866H60.6001V76.0112ZM60.6001 76.7243V77.1994H61.0755V76.7243H60.6001ZM61.3133 76.7243V77.1994H61.7884V76.7243H61.3133ZM61.7884 76.4866V76.0112H61.3133V76.4866L61.7884 76.4866ZM61.1351 76.1746H61.2539V76.5013H61.2239V76.7095H61.2539V77.0363H61.1351V76.7095H61.1645V76.5013H61.1351V76.1746ZM60.7632 76.5461V76.6649H61.09V76.5461H60.7632ZM61.2984 76.6649V76.5461H61.6252V76.6649H61.2984ZM62.4872 74.5996H62.0118V75.0751L62.4872 75.0751V74.5996ZM62.0118 75.7879V75.3128H62.4872V75.7879H62.0118ZM62.7249 75.7879V75.3128H63.2001V75.7879H62.7249ZM63.2001 74.5996V75.0751L62.7249 75.0751V74.5996H63.2001ZM62.6656 74.763H62.5468V75.0898H62.5762V75.298H62.5468V75.6248H62.6656V75.298H62.6356V75.0898H62.6656V74.763ZM62.1753 75.2534V75.1346H62.5021V75.2534H62.1753ZM62.7098 75.1346V75.2534H63.0366V75.1346H62.7098ZM62.0118 76.0112H62.4872V76.4866L62.0118 76.4866V76.0112ZM62.0118 76.7243V77.1995H62.4872V76.7243H62.0118ZM62.7249 76.7243V77.1995H63.2001V76.7243H62.7249ZM63.2001 76.4866V76.0112H62.7249V76.4866H63.2001ZM62.5468 76.1746H62.6656V76.5014H62.6356V76.7095H62.6656V77.0363H62.5468V76.7095H62.5762V76.5014H62.5468V76.1746ZM62.1753 76.5461V76.6649H62.5021V76.5461H62.1753ZM62.7098 76.6649V76.5461H63.0366V76.6649H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 65.2998H64.2753V65.7752L63.7998 65.7752V65.2998ZM63.7998 66.0129V66.4881H64.2753V66.0129H63.7998ZM64.513 66.0129V66.4881H64.9881V66.0129H64.513ZM64.9881 65.7752V65.2998H64.513V65.7752L64.9881 65.7752ZM64.3348 65.4632H64.4536V65.79H64.4236V65.9982H64.4536V66.325H64.3348V65.9982H64.3642V65.79H64.3348V65.4632ZM63.9629 65.8348V65.9536H64.2897V65.8348H63.9629ZM64.4981 65.9536V65.8348H64.8249V65.9536H64.4981ZM63.7998 66.7113H64.2753V67.1868H63.7998V66.7113ZM63.7998 67.4245V67.8996H64.2753V67.4245H63.7998ZM64.513 67.4245V67.8996H64.9881V67.4245H64.513ZM64.9881 67.1868V66.7113H64.513V67.1868H64.9881ZM64.3348 66.8747H64.4536V67.2015H64.4236V67.4097H64.4536V67.7364H64.3348V67.4097H64.3642V67.2015H64.3348V66.8747ZM63.9629 67.2463V67.3651H64.2897V67.2463H63.9629ZM64.4981 67.3651V67.2463H64.8249V67.3651H64.4981ZM65.6869 65.2998H65.2115V65.7753L65.6869 65.7753V65.2998ZM65.2115 66.4881V66.013H65.6869V66.4881H65.2115ZM65.9246 66.4881V66.013H66.3998V66.4881H65.9246ZM66.3998 65.2998V65.7753L65.9246 65.7753V65.2998H66.3998ZM65.8653 65.4632H65.7465V65.79H65.7759V65.9982H65.7465V66.325H65.8653V65.9982H65.8353V65.79H65.8653V65.4632ZM65.375 65.9536V65.8348H65.7018V65.9536H65.375ZM65.9095 65.8348V65.9536H66.2363V65.8348H65.9095ZM65.2115 66.7114H65.6869V67.1868H65.2115V66.7114ZM65.2115 67.4245V67.8997H65.6869V67.4245H65.2115ZM65.9246 67.4245V67.8997H66.3998V67.4245H65.9246ZM66.3998 67.1868V66.7114H65.9246V67.1868H66.3998ZM65.7465 66.8748H65.8653V67.2016H65.8353V67.4097H65.8653V67.7365H65.7465V67.4097H65.7759V67.2016H65.7465V66.8748ZM65.375 67.2463V67.3651H65.7018V67.2463H65.375ZM65.9095 67.3651V67.2463H66.2363V67.3651H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 65.2998H67.3754V65.7752L66.8999 65.7752V65.2998ZM66.8999 66.0129V66.4881H67.3754V66.0129H66.8999ZM67.6131 66.0129V66.4881H68.0882V66.0129H67.6131ZM68.0882 65.7752V65.2998H67.6131V65.7752L68.0882 65.7752ZM67.4349 65.4632H67.5537V65.79H67.5237V65.9982H67.5537V66.325H67.4349V65.9982H67.4643V65.79H67.4349V65.4632ZM67.063 65.8348V65.9536H67.3898V65.8348H67.063ZM67.5982 65.9536V65.8348H67.925V65.9536H67.5982ZM66.8999 66.7113H67.3753V67.1868H66.8999V66.7113ZM66.8999 67.4245V67.8996H67.3753V67.4245H66.8999ZM67.6131 67.4245V67.8996H68.0882V67.4245H67.6131ZM68.0882 67.1868V66.7113H67.6131V67.1868L68.0882 67.1868ZM67.4349 66.8747H67.5537V67.2015H67.5237V67.4097H67.5537V67.7364H67.4349V67.4097H67.4643V67.2015H67.4349V66.8747ZM67.063 67.2463V67.3651H67.3898V67.2463H67.063ZM67.5982 67.3651V67.2463H67.925V67.3651H67.5982ZM68.787 65.2998H68.3116V65.7753L68.787 65.7753V65.2998ZM68.3116 66.4881V66.013H68.787V66.4881H68.3116ZM69.0247 66.4881V66.013H69.4999V66.4881H69.0247ZM69.4999 65.2998V65.7753L69.0247 65.7753V65.2998H69.4999ZM68.9654 65.4632H68.8466V65.79H68.876V65.9982H68.8466V66.325H68.9654V65.9982H68.9354V65.79H68.9654V65.4632ZM68.4751 65.9536V65.8348H68.8019V65.9536H68.4751ZM69.0096 65.8348V65.9536H69.3364V65.8348H69.0096ZM68.3116 66.7114H68.787V67.1868L68.3116 67.1868V66.7114ZM68.3116 67.4245V67.8997H68.787V67.4245H68.3116ZM69.0247 67.4245V67.8997H69.4999V67.4245H69.0247ZM69.4999 67.1868V66.7114H69.0247V67.1868H69.4999ZM68.8466 66.8748H68.9654V67.2016H68.9354V67.4097H68.9654V67.7365H68.8466V67.4097H68.876V67.2016H68.8466V66.8748ZM68.4751 67.2463V67.3651H68.8019V67.2463H68.4751ZM69.0096 67.3651V67.2463H69.3364V67.3651H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 65.2998H70.4755V65.7752L70 65.7752V65.2998ZM70 66.0129V66.4881H70.4755V66.0129H70ZM70.7132 66.0129V66.4881H71.1883V66.0129H70.7132ZM71.1883 65.7752V65.2998H70.7132V65.7752L71.1883 65.7752ZM70.535 65.4632H70.6538V65.79H70.6238V65.9982H70.6538V66.325H70.535V65.9982H70.5644V65.79H70.535V65.4632ZM70.1631 65.8348V65.9536H70.4899V65.8348H70.1631ZM70.6983 65.9536V65.8348H71.0251V65.9536H70.6983ZM70 66.7113H70.4754V67.1868H70V66.7113ZM70 67.4245V67.8996H70.4754V67.4245H70ZM70.7132 67.4245V67.8996H71.1883V67.4245H70.7132ZM71.1883 67.1868V66.7113H70.7132V67.1868L71.1883 67.1868ZM70.535 66.8747H70.6538V67.2015H70.6238V67.4097H70.6538V67.7364H70.535V67.4097H70.5644V67.2015H70.535V66.8747ZM70.1631 67.2463V67.3651H70.4899V67.2463H70.1631ZM70.6983 67.3651V67.2463H71.0251V67.3651H70.6983ZM71.8871 65.2998H71.4117V65.7753L71.8871 65.7753V65.2998ZM71.4117 66.4881V66.013H71.8871V66.4881H71.4117ZM72.1248 66.4881V66.013H72.6V66.4881H72.1248ZM72.6 65.2998V65.7753L72.1248 65.7753V65.2998H72.6ZM72.0655 65.4632H71.9467V65.79H71.9761V65.9982H71.9467V66.325H72.0655V65.9982H72.0355V65.79H72.0655V65.4632ZM71.5752 65.9536V65.8348H71.902V65.9536H71.5752ZM72.1097 65.8348V65.9536H72.4365V65.8348H72.1097ZM71.4117 66.7114H71.8871V67.1868L71.4117 67.1868V66.7114ZM71.4117 67.4245V67.8997H71.8871V67.4245H71.4117ZM72.1248 67.4245V67.8997H72.6V67.4245H72.1248ZM72.6 67.1868V66.7114H72.1248V67.1868H72.6ZM71.9467 66.8748H72.0655V67.2016H72.0355V67.4097H72.0655V67.7365H71.9467V67.4097H71.9761V67.2016H71.9467V66.8748ZM71.5752 67.2463V67.3651H71.902V67.2463H71.5752ZM72.1097 67.3651V67.2463H72.4365V67.3651H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 65.2998H73.5756V65.7752L73.1001 65.7752V65.2998ZM73.1001 66.0129V66.4881H73.5756V66.0129H73.1001ZM73.8133 66.0129V66.4881H74.2884V66.0129H73.8133ZM74.2884 65.7752V65.2998H73.8133V65.7752L74.2884 65.7752ZM73.6351 65.4632H73.7539V65.79H73.7239V65.9982H73.7539V66.325H73.6351V65.9982H73.6645V65.79H73.6351V65.4632ZM73.2632 65.8348V65.9536H73.59V65.8348H73.2632ZM73.7984 65.9536V65.8348H74.1252V65.9536H73.7984ZM73.1001 66.7113H73.5755V67.1868H73.1001V66.7113ZM73.1001 67.4245V67.8996H73.5755V67.4245H73.1001ZM73.8133 67.4245V67.8996H74.2884V67.4245H73.8133ZM74.2884 67.1868V66.7113H73.8133V67.1868L74.2884 67.1868ZM73.6351 66.8747H73.7539V67.2015H73.7239V67.4097H73.7539V67.7364H73.6351V67.4097H73.6645V67.2015H73.6351V66.8747ZM73.2632 67.2463V67.3651H73.59V67.2463H73.2632ZM73.7984 67.3651V67.2463H74.1252V67.3651H73.7984ZM74.9872 65.2998H74.5118V65.7753L74.9872 65.7753V65.2998ZM74.5118 66.4881V66.013H74.9872V66.4881H74.5118ZM75.2249 66.4881V66.013H75.7001V66.4881H75.2249ZM75.7001 65.2998V65.7753L75.2249 65.7753V65.2998H75.7001ZM75.1656 65.4632H75.0468V65.79H75.0762V65.9982H75.0468V66.325H75.1656V65.9982H75.1356V65.79H75.1656V65.4632ZM74.6753 65.9536V65.8348H75.0021V65.9536H74.6753ZM75.2098 65.8348V65.9536H75.5366V65.8348H75.2098ZM74.5118 66.7114H74.9872V67.1868L74.5118 67.1868V66.7114ZM74.5118 67.4245V67.8997H74.9872V67.4245H74.5118ZM75.2249 67.4245V67.8997H75.7001V67.4245H75.2249ZM75.7001 67.1868V66.7114H75.2249V67.1868H75.7001ZM75.0468 66.8748H75.1656V67.2016H75.1356V67.4097H75.1656V67.7365H75.0468V67.4097H75.0762V67.2016H75.0468V66.8748ZM74.6753 67.2463V67.3651H75.0021V67.2463H74.6753ZM75.2098 67.3651V67.2463H75.5366V67.3651H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 68.4004H64.2753V68.8758L63.7998 68.8758V68.4004ZM63.7998 69.1135V69.5887H64.2753V69.1135H63.7998ZM64.513 69.1135V69.5887H64.9881V69.1135H64.513ZM64.9881 68.8758V68.4004H64.513V68.8758L64.9881 68.8758ZM64.3348 68.5638H64.4536V68.8906H64.4236V69.0988H64.4536V69.4255H64.3348V69.0988H64.3642V68.8906H64.3348V68.5638ZM63.9629 68.9353V69.0542H64.2897V68.9353H63.9629ZM64.4981 69.0542V68.9354H64.8249V69.0542H64.4981ZM63.7998 69.8119H64.2753V70.2874H63.7998V69.8119ZM63.7998 70.5251V71.0002H64.2753V70.5251H63.7998ZM64.513 70.5251V71.0002H64.9881V70.5251H64.513ZM64.9881 70.2874V69.8119H64.513V70.2874L64.9881 70.2874ZM64.3348 69.9753H64.4536V70.3021H64.4236V70.5103H64.4536V70.837H64.3348V70.5103H64.3642V70.3021H64.3348V69.9753ZM63.9629 70.3468V70.4657H64.2897V70.3468H63.9629ZM64.4981 70.4657V70.3469H64.8249V70.4657H64.4981ZM65.6869 68.4004H65.2115V68.8759L65.6869 68.8759V68.4004ZM65.2115 69.5887V69.1136H65.6869V69.5887H65.2115ZM65.9246 69.5887V69.1136H66.3998V69.5887H65.9246ZM66.3998 68.4004V68.8759L65.9246 68.8759V68.4004H66.3998ZM65.8653 68.5638H65.7465V68.8906H65.7759V69.0988H65.7465V69.4256H65.8653V69.0988H65.8353V68.8906H65.8653V68.5638ZM65.375 69.0542V68.9354H65.7018V69.0542H65.375ZM65.9095 68.9354V69.0542H66.2363V68.9354H65.9095ZM65.2115 69.812H65.6869V70.2874L65.2115 70.2874V69.812ZM65.2115 70.5251V71.0003H65.6869V70.5251H65.2115ZM65.9246 70.5251V71.0003H66.3998V70.5251H65.9246ZM66.3998 70.2874V69.812H65.9246V70.2874H66.3998ZM65.7465 69.9754H65.8653V70.3021H65.8353V70.5103H65.8653V70.8371H65.7465V70.5103H65.7759V70.3021H65.7465V69.9754ZM65.375 70.3469V70.4657H65.7018V70.3469H65.375ZM65.9095 70.4657V70.3469H66.2363V70.4657H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 68.4004H67.3754V68.8758L66.8999 68.8758V68.4004ZM66.8999 69.1135V69.5887H67.3754V69.1135H66.8999ZM67.6131 69.1135V69.5887H68.0882V69.1135H67.6131ZM68.0882 68.8758V68.4004H67.6131V68.8758L68.0882 68.8758ZM67.4349 68.5638H67.5537V68.8906H67.5237V69.0988H67.5537V69.4255H67.4349V69.0988H67.4643V68.8906H67.4349V68.5638ZM67.063 68.9353V69.0542H67.3898V68.9353H67.063ZM67.5982 69.0542V68.9354H67.925V69.0542H67.5982ZM66.8999 69.8119H67.3753V70.2874H66.8999V69.8119ZM66.8999 70.5251V71.0002H67.3753V70.5251H66.8999ZM67.6131 70.5251V71.0002H68.0882V70.5251H67.6131ZM68.0882 70.2874V69.8119H67.6131V70.2874L68.0882 70.2874ZM67.4349 69.9753H67.5537V70.3021H67.5237V70.5103H67.5537V70.837H67.4349V70.5103H67.4643V70.3021H67.4349V69.9753ZM67.063 70.3468V70.4657H67.3898V70.3468H67.063ZM67.5982 70.4657V70.3469H67.925V70.4657H67.5982ZM68.787 68.4004H68.3116V68.8759L68.787 68.8759V68.4004ZM68.3116 69.5887V69.1136H68.787V69.5887H68.3116ZM69.0247 69.5887V69.1136H69.4999V69.5887H69.0247ZM69.4999 68.4004V68.8759L69.0247 68.8759V68.4004H69.4999ZM68.9654 68.5638H68.8466V68.8906H68.876V69.0988H68.8466V69.4256H68.9654V69.0988H68.9354V68.8906H68.9654V68.5638ZM68.4751 69.0542V68.9354H68.8019V69.0542H68.4751ZM69.0096 68.9354V69.0542H69.3364V68.9354H69.0096ZM68.3116 69.812H68.787V70.2874L68.3116 70.2874V69.812ZM68.3116 70.5251V71.0003H68.787V70.5251H68.3116ZM69.0247 70.5251V71.0003H69.4999V70.5251H69.0247ZM69.4999 70.2874V69.812H69.0247V70.2874H69.4999ZM68.8466 69.9754H68.9654V70.3021H68.9354V70.5103H68.9654V70.8371H68.8466V70.5103H68.876V70.3021H68.8466V69.9754ZM68.4751 70.3469V70.4657H68.8019V70.3469H68.4751ZM69.0096 70.4657V70.3469H69.3364V70.4657H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 68.4004H70.4755V68.8758L70 68.8758V68.4004ZM70 69.1135V69.5887H70.4755V69.1135H70ZM70.7132 69.1135V69.5887H71.1883V69.1135H70.7132ZM71.1883 68.8758V68.4004H70.7132V68.8758L71.1883 68.8758ZM70.535 68.5638H70.6538V68.8906H70.6238V69.0988H70.6538V69.4255H70.535V69.0988H70.5644V68.8906H70.535V68.5638ZM70.1631 68.9353V69.0542H70.4899V68.9353H70.1631ZM70.6983 69.0542V68.9354H71.0251V69.0542H70.6983ZM70 69.8119H70.4754V70.2874H70V69.8119ZM70 70.5251V71.0002H70.4754V70.5251H70ZM70.7132 70.5251V71.0002H71.1883V70.5251H70.7132ZM71.1883 70.2874V69.8119H70.7132V70.2874L71.1883 70.2874ZM70.535 69.9753H70.6538V70.3021H70.6238V70.5103H70.6538V70.837H70.535V70.5103H70.5644V70.3021H70.535V69.9753ZM70.1631 70.3468V70.4657H70.4899V70.3468H70.1631ZM70.6983 70.4657V70.3469H71.0251V70.4657H70.6983ZM71.8871 68.4004H71.4117V68.8759L71.8871 68.8759V68.4004ZM71.4117 69.5887V69.1136H71.8871V69.5887H71.4117ZM72.1248 69.5887V69.1136H72.6V69.5887H72.1248ZM72.6 68.4004V68.8759L72.1248 68.8759V68.4004H72.6ZM72.0655 68.5638H71.9467V68.8906H71.9761V69.0988H71.9467V69.4256H72.0655V69.0988H72.0355V68.8906H72.0655V68.5638ZM71.5752 69.0542V68.9354H71.902V69.0542H71.5752ZM72.1097 68.9354V69.0542H72.4365V68.9354H72.1097ZM71.4117 69.812H71.8871V70.2874L71.4117 70.2874V69.812ZM71.4117 70.5251V71.0003H71.8871V70.5251H71.4117ZM72.1248 70.5251V71.0003H72.6V70.5251H72.1248ZM72.6 70.2874V69.812H72.1248V70.2874H72.6ZM71.9467 69.9754H72.0655V70.3021H72.0355V70.5103H72.0655V70.8371H71.9467V70.5103H71.9761V70.3021H71.9467V69.9754ZM71.5752 70.3469V70.4657H71.902V70.3469H71.5752ZM72.1097 70.4657V70.3469H72.4365V70.4657H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 68.4004H73.5756V68.8758L73.1001 68.8758V68.4004ZM73.1001 69.1135V69.5887H73.5756V69.1135H73.1001ZM73.8133 69.1135V69.5887H74.2884V69.1135H73.8133ZM74.2884 68.8758V68.4004H73.8133V68.8758L74.2884 68.8758ZM73.6351 68.5638H73.7539V68.8906H73.7239V69.0988H73.7539V69.4255H73.6351V69.0988H73.6645V68.8906H73.6351V68.5638ZM73.2632 68.9353V69.0542H73.59V68.9353H73.2632ZM73.7984 69.0542V68.9354H74.1252V69.0542H73.7984ZM73.1001 69.8119H73.5755V70.2874H73.1001V69.8119ZM73.1001 70.5251V71.0002H73.5755V70.5251H73.1001ZM73.8133 70.5251V71.0002H74.2884V70.5251H73.8133ZM74.2884 70.2874V69.8119H73.8133V70.2874L74.2884 70.2874ZM73.6351 69.9753H73.7539V70.3021H73.7239V70.5103H73.7539V70.837H73.6351V70.5103H73.6645V70.3021H73.6351V69.9753ZM73.2632 70.3468V70.4657H73.59V70.3468H73.2632ZM73.7984 70.4657V70.3469H74.1252V70.4657H73.7984ZM74.9872 68.4004H74.5118V68.8759L74.9872 68.8759V68.4004ZM74.5118 69.5887V69.1136H74.9872V69.5887H74.5118ZM75.2249 69.5887V69.1136H75.7001V69.5887H75.2249ZM75.7001 68.4004V68.8759L75.2249 68.8759V68.4004H75.7001ZM75.1656 68.5638H75.0468V68.8906H75.0762V69.0988H75.0468V69.4256H75.1656V69.0988H75.1356V68.8906H75.1656V68.5638ZM74.6753 69.0542V68.9354H75.0021V69.0542H74.6753ZM75.2098 68.9354V69.0542H75.5366V68.9354H75.2098ZM74.5118 69.812H74.9872V70.2874L74.5118 70.2874V69.812ZM74.5118 70.5251V71.0003H74.9872V70.5251H74.5118ZM75.2249 70.5251V71.0003H75.7001V70.5251H75.2249ZM75.7001 70.2874V69.812H75.2249V70.2874H75.7001ZM75.0468 69.9754H75.1656V70.3021H75.1356V70.5103H75.1656V70.8371H75.0468V70.5103H75.0762V70.3021H75.0468V69.9754ZM74.6753 70.3469V70.4657H75.0021V70.3469H74.6753ZM75.2098 70.4657V70.3469H75.5366V70.4657H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 71.5H64.2753V71.9754L63.7998 71.9754V71.5ZM63.7998 72.2131V72.6883H64.2753V72.2131H63.7998ZM64.513 72.2131V72.6883H64.9881V72.2131H64.513ZM64.9881 71.9754V71.5H64.513V71.9754L64.9881 71.9754ZM64.3348 71.6634H64.4536V71.9902H64.4236V72.1984H64.4536V72.5251H64.3348V72.1984H64.3642V71.9902H64.3348V71.6634ZM63.9629 72.035V72.1538H64.2897V72.035H63.9629ZM64.4981 72.1538V72.035H64.8249V72.1538H64.4981ZM63.7998 72.9115H64.2753V73.387H63.7998V72.9115ZM63.7998 73.6247V74.0998H64.2753V73.6247H63.7998ZM64.513 73.6247V74.0998H64.9881V73.6247H64.513ZM64.9881 73.387V72.9115H64.513V73.387L64.9881 73.387ZM64.3348 73.0749H64.4536V73.4017H64.4236V73.6099H64.4536V73.9366H64.3348V73.6099H64.3642V73.4017H64.3348V73.0749ZM63.9629 73.4465V73.5653H64.2897V73.4465H63.9629ZM64.4981 73.5653V73.4465H64.8249V73.5653H64.4981ZM65.6869 71.5H65.2115V71.9755L65.6869 71.9755V71.5ZM65.2115 72.6883V72.2132H65.6869V72.6883H65.2115ZM65.9246 72.6883V72.2132H66.3998V72.6883H65.9246ZM66.3998 71.5V71.9755L65.9246 71.9755V71.5H66.3998ZM65.8653 71.6634H65.7465V71.9902H65.7759V72.1984H65.7465V72.5252H65.8653V72.1984H65.8353V71.9902H65.8653V71.6634ZM65.375 72.1538V72.035H65.7018V72.1538H65.375ZM65.9095 72.035V72.1538H66.2363V72.035H65.9095ZM65.2115 72.9116H65.6869V73.387L65.2115 73.387V72.9116ZM65.2115 73.6247V74.0999H65.6869V73.6247H65.2115ZM65.9246 73.6247V74.0999H66.3998V73.6247H65.9246ZM66.3998 73.387V72.9116H65.9246V73.387H66.3998ZM65.7465 73.075H65.8653V73.4018H65.8353V73.6099H65.8653V73.9367H65.7465V73.6099H65.7759V73.4018H65.7465V73.075ZM65.375 73.4465V73.5653H65.7018V73.4465H65.375ZM65.9095 73.5653V73.4465H66.2363V73.5653H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 71.5H67.3754V71.9754L66.8999 71.9754V71.5ZM66.8999 72.2131V72.6883H67.3754V72.2131H66.8999ZM67.6131 72.2131V72.6883H68.0882V72.2131H67.6131ZM68.0882 71.9754V71.5H67.6131V71.9754L68.0882 71.9754ZM67.4349 71.6634H67.5537V71.9902H67.5237V72.1984H67.5537V72.5251H67.4349V72.1984H67.4643V71.9902H67.4349V71.6634ZM67.063 72.035V72.1538H67.3898V72.035H67.063ZM67.5982 72.1538V72.035H67.925V72.1538H67.5982ZM66.8999 72.9115H67.3753V73.387H66.8999V72.9115ZM66.8999 73.6247V74.0998H67.3753V73.6247H66.8999ZM67.6131 73.6247V74.0998H68.0882V73.6247H67.6131ZM68.0882 73.387V72.9115H67.6131V73.387L68.0882 73.387ZM67.4349 73.0749H67.5537V73.4017H67.5237V73.6099H67.5537V73.9366H67.4349V73.6099H67.4643V73.4017H67.4349V73.0749ZM67.063 73.4465V73.5653H67.3898V73.4465H67.063ZM67.5982 73.5653V73.4465H67.925V73.5653H67.5982ZM68.787 71.5H68.3116V71.9755L68.787 71.9755V71.5ZM68.3116 72.6883V72.2132H68.787V72.6883H68.3116ZM69.0247 72.6883V72.2132H69.4999V72.6883H69.0247ZM69.4999 71.5V71.9755L69.0247 71.9755V71.5H69.4999ZM68.9654 71.6634H68.8466V71.9902H68.876V72.1984H68.8466V72.5252H68.9654V72.1984H68.9354V71.9902H68.9654V71.6634ZM68.4751 72.1538V72.035H68.8019V72.1538H68.4751ZM69.0096 72.035V72.1538H69.3364V72.035H69.0096ZM68.3116 72.9116H68.787V73.387L68.3116 73.387V72.9116ZM68.3116 73.6247V74.0999H68.787V73.6247H68.3116ZM69.0247 73.6247V74.0999H69.4999V73.6247H69.0247ZM69.4999 73.387V72.9116H69.0247V73.387H69.4999ZM68.8466 73.075H68.9654V73.4018H68.9354V73.6099H68.9654V73.9367H68.8466V73.6099H68.876V73.4018H68.8466V73.075ZM68.4751 73.4465V73.5653H68.8019V73.4465H68.4751ZM69.0096 73.5653V73.4465H69.3364V73.5653H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 71.5H70.4755V71.9754L70 71.9754V71.5ZM70 72.2131V72.6883H70.4755V72.2131H70ZM70.7132 72.2131V72.6883H71.1883V72.2131H70.7132ZM71.1883 71.9754V71.5H70.7132V71.9754L71.1883 71.9754ZM70.535 71.6634H70.6538V71.9902H70.6238V72.1984H70.6538V72.5251H70.535V72.1984H70.5644V71.9902H70.535V71.6634ZM70.1631 72.035V72.1538H70.4899V72.035H70.1631ZM70.6983 72.1538V72.035H71.0251V72.1538H70.6983ZM70 72.9115H70.4754V73.387H70V72.9115ZM70 73.6247V74.0998H70.4754V73.6247H70ZM70.7132 73.6247V74.0998H71.1883V73.6247H70.7132ZM71.1883 73.387V72.9115H70.7132V73.387L71.1883 73.387ZM70.535 73.0749H70.6538V73.4017H70.6238V73.6099H70.6538V73.9366H70.535V73.6099H70.5644V73.4017H70.535V73.0749ZM70.1631 73.4465V73.5653H70.4899V73.4465H70.1631ZM70.6983 73.5653V73.4465H71.0251V73.5653H70.6983ZM71.8871 71.5H71.4117V71.9755L71.8871 71.9755V71.5ZM71.4117 72.6883V72.2132H71.8871V72.6883H71.4117ZM72.1248 72.6883V72.2132H72.6V72.6883H72.1248ZM72.6 71.5V71.9755L72.1248 71.9755V71.5H72.6ZM72.0655 71.6634H71.9467V71.9902H71.9761V72.1984H71.9467V72.5252H72.0655V72.1984H72.0355V71.9902H72.0655V71.6634ZM71.5752 72.1538V72.035H71.902V72.1538H71.5752ZM72.1097 72.035V72.1538H72.4365V72.035H72.1097ZM71.4117 72.9116H71.8871V73.387L71.4117 73.387V72.9116ZM71.4117 73.6247V74.0999H71.8871V73.6247H71.4117ZM72.1248 73.6247V74.0999H72.6V73.6247H72.1248ZM72.6 73.387V72.9116H72.1248V73.387H72.6ZM71.9467 73.075H72.0655V73.4018H72.0355V73.6099H72.0655V73.9367H71.9467V73.6099H71.9761V73.4018H71.9467V73.075ZM71.5752 73.4465V73.5653H71.902V73.4465H71.5752ZM72.1097 73.5653V73.4465H72.4365V73.5653H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 71.5H73.5756V71.9754L73.1001 71.9754V71.5ZM73.1001 72.2131V72.6883H73.5756V72.2131H73.1001ZM73.8133 72.2131V72.6883H74.2884V72.2131H73.8133ZM74.2884 71.9754V71.5H73.8133V71.9754L74.2884 71.9754ZM73.6351 71.6634H73.7539V71.9902H73.7239V72.1984H73.7539V72.5251H73.6351V72.1984H73.6645V71.9902H73.6351V71.6634ZM73.2632 72.035V72.1538H73.59V72.035H73.2632ZM73.7984 72.1538V72.035H74.1252V72.1538H73.7984ZM73.1001 72.9115H73.5755V73.387H73.1001V72.9115ZM73.1001 73.6247V74.0998H73.5755V73.6247H73.1001ZM73.8133 73.6247V74.0998H74.2884V73.6247H73.8133ZM74.2884 73.387V72.9115H73.8133V73.387L74.2884 73.387ZM73.6351 73.0749H73.7539V73.4017H73.7239V73.6099H73.7539V73.9366H73.6351V73.6099H73.6645V73.4017H73.6351V73.0749ZM73.2632 73.4465V73.5653H73.59V73.4465H73.2632ZM73.7984 73.5653V73.4465H74.1252V73.5653H73.7984ZM74.9872 71.5H74.5118V71.9755L74.9872 71.9755V71.5ZM74.5118 72.6883V72.2132H74.9872V72.6883H74.5118ZM75.2249 72.6883V72.2132H75.7001V72.6883H75.2249ZM75.7001 71.5V71.9755L75.2249 71.9755V71.5H75.7001ZM75.1656 71.6634H75.0468V71.9902H75.0762V72.1984H75.0468V72.5252H75.1656V72.1984H75.1356V71.9902H75.1656V71.6634ZM74.6753 72.1538V72.035H75.0021V72.1538H74.6753ZM75.2098 72.035V72.1538H75.5366V72.035H75.2098ZM74.5118 72.9116H74.9872V73.387L74.5118 73.387V72.9116ZM74.5118 73.6247V74.0999H74.9872V73.6247H74.5118ZM75.2249 73.6247V74.0999H75.7001V73.6247H75.2249ZM75.7001 73.387V72.9116H75.2249V73.387H75.7001ZM75.0468 73.075H75.1656V73.4018H75.1356V73.6099H75.1656V73.9367H75.0468V73.6099H75.0762V73.4018H75.0468V73.075ZM74.6753 73.4465V73.5653H75.0021V73.4465H74.6753ZM75.2098 73.5653V73.4465H75.5366V73.5653H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 74.5996H64.2753V75.075L63.7998 75.075V74.5996ZM63.7998 75.3127V75.7879H64.2753V75.3127H63.7998ZM64.513 75.3127V75.7879H64.9881V75.3127H64.513ZM64.9881 75.075V74.5996H64.513V75.075L64.9881 75.075ZM64.3348 74.763H64.4536V75.0898H64.4236V75.298H64.4536V75.6248H64.3348V75.298H64.3642V75.0898H64.3348V74.763ZM63.9629 75.1346V75.2534H64.2897V75.1346H63.9629ZM64.4981 75.2534V75.1346H64.8249V75.2534H64.4981ZM63.7998 76.0112H64.2753V76.4866H63.7998V76.0112ZM63.7998 76.7243V77.1994H64.2753V76.7243H63.7998ZM64.513 76.7243V77.1994H64.9881V76.7243H64.513ZM64.9881 76.4866V76.0112H64.513V76.4866L64.9881 76.4866ZM64.3348 76.1746H64.4536V76.5013H64.4236V76.7095H64.4536V77.0363H64.3348V76.7095H64.3642V76.5013H64.3348V76.1746ZM63.9629 76.5461V76.6649H64.2897V76.5461H63.9629ZM64.4981 76.6649V76.5461H64.8249V76.6649H64.4981ZM65.6869 74.5996H65.2115V75.0751L65.6869 75.0751V74.5996ZM65.2115 75.7879V75.3128H65.6869V75.7879H65.2115ZM65.9246 75.7879V75.3128H66.3998V75.7879H65.9246ZM66.3998 74.5996V75.0751L65.9246 75.0751V74.5996H66.3998ZM65.8653 74.763H65.7465V75.0898H65.7759V75.298H65.7465V75.6248H65.8653V75.298H65.8353V75.0898H65.8653V74.763ZM65.375 75.2534V75.1346H65.7018V75.2534H65.375ZM65.9095 75.1346V75.2534H66.2363V75.1346H65.9095ZM65.2115 76.0112H65.6869V76.4866L65.2115 76.4866V76.0112ZM65.2115 76.7243V77.1995H65.6869V76.7243H65.2115ZM65.9246 76.7243V77.1995H66.3998V76.7243H65.9246ZM66.3998 76.4866V76.0112H65.9246V76.4866H66.3998ZM65.7465 76.1746H65.8653V76.5014H65.8353V76.7095H65.8653V77.0363H65.7465V76.7095H65.7759V76.5014H65.7465V76.1746ZM65.375 76.5461V76.6649H65.7018V76.5461H65.375ZM65.9095 76.6649V76.5461H66.2363V76.6649H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 74.5996H67.3754V75.075L66.8999 75.075V74.5996ZM66.8999 75.3127V75.7879H67.3754V75.3127H66.8999ZM67.6131 75.3127V75.7879H68.0882V75.3127H67.6131ZM68.0882 75.075V74.5996H67.6131V75.075L68.0882 75.075ZM67.4349 74.763H67.5537V75.0898H67.5237V75.298H67.5537V75.6248H67.4349V75.298H67.4643V75.0898H67.4349V74.763ZM67.063 75.1346V75.2534H67.3898V75.1346H67.063ZM67.5982 75.2534V75.1346H67.925V75.2534H67.5982ZM66.8999 76.0112H67.3753V76.4866H66.8999V76.0112ZM66.8999 76.7243V77.1994H67.3753V76.7243H66.8999ZM67.6131 76.7243V77.1994H68.0882V76.7243H67.6131ZM68.0882 76.4866V76.0112H67.6131V76.4866L68.0882 76.4866ZM67.4349 76.1746H67.5537V76.5013H67.5237V76.7095H67.5537V77.0363H67.4349V76.7095H67.4643V76.5013H67.4349V76.1746ZM67.063 76.5461V76.6649H67.3898V76.5461H67.063ZM67.5982 76.6649V76.5461H67.925V76.6649H67.5982ZM68.787 74.5996H68.3116V75.0751L68.787 75.0751V74.5996ZM68.3116 75.7879V75.3128H68.787V75.7879H68.3116ZM69.0247 75.7879V75.3128H69.4999V75.7879H69.0247ZM69.4999 74.5996V75.0751L69.0247 75.0751V74.5996H69.4999ZM68.9654 74.763H68.8466V75.0898H68.876V75.298H68.8466V75.6248H68.9654V75.298H68.9354V75.0898H68.9654V74.763ZM68.4751 75.2534V75.1346H68.8019V75.2534H68.4751ZM69.0096 75.1346V75.2534H69.3364V75.1346H69.0096ZM68.3116 76.0112H68.787V76.4866L68.3116 76.4866V76.0112ZM68.3116 76.7243V77.1995H68.787V76.7243H68.3116ZM69.0247 76.7243V77.1995H69.4999V76.7243H69.0247ZM69.4999 76.4866V76.0112H69.0247V76.4866H69.4999ZM68.8466 76.1746H68.9654V76.5014H68.9354V76.7095H68.9654V77.0363H68.8466V76.7095H68.876V76.5014H68.8466V76.1746ZM68.4751 76.5461V76.6649H68.8019V76.5461H68.4751ZM69.0096 76.6649V76.5461H69.3364V76.6649H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 74.5996H70.4755V75.075L70 75.075V74.5996ZM70 75.3127V75.7879H70.4755V75.3127H70ZM70.7132 75.3127V75.7879H71.1883V75.3127H70.7132ZM71.1883 75.075V74.5996H70.7132V75.075L71.1883 75.075ZM70.535 74.763H70.6538V75.0898H70.6238V75.298H70.6538V75.6248H70.535V75.298H70.5644V75.0898H70.535V74.763ZM70.1631 75.1346V75.2534H70.4899V75.1346H70.1631ZM70.6983 75.2534V75.1346H71.0251V75.2534H70.6983ZM70 76.0112H70.4754V76.4866H70V76.0112ZM70 76.7243V77.1994H70.4754V76.7243H70ZM70.7132 76.7243V77.1994H71.1883V76.7243H70.7132ZM71.1883 76.4866V76.0112H70.7132V76.4866L71.1883 76.4866ZM70.535 76.1746H70.6538V76.5013H70.6238V76.7095H70.6538V77.0363H70.535V76.7095H70.5644V76.5013H70.535V76.1746ZM70.1631 76.5461V76.6649H70.4899V76.5461H70.1631ZM70.6983 76.6649V76.5461H71.0251V76.6649H70.6983ZM71.8871 74.5996H71.4117V75.0751L71.8871 75.0751V74.5996ZM71.4117 75.7879V75.3128H71.8871V75.7879H71.4117ZM72.1248 75.7879V75.3128H72.6V75.7879H72.1248ZM72.6 74.5996V75.0751L72.1248 75.0751V74.5996H72.6ZM72.0655 74.763H71.9467V75.0898H71.9761V75.298H71.9467V75.6248H72.0655V75.298H72.0355V75.0898H72.0655V74.763ZM71.5752 75.2534V75.1346H71.902V75.2534H71.5752ZM72.1097 75.1346V75.2534H72.4365V75.1346H72.1097ZM71.4117 76.0112H71.8871V76.4866L71.4117 76.4866V76.0112ZM71.4117 76.7243V77.1995H71.8871V76.7243H71.4117ZM72.1248 76.7243V77.1995H72.6V76.7243H72.1248ZM72.6 76.4866V76.0112H72.1248V76.4866H72.6ZM71.9467 76.1746H72.0655V76.5014H72.0355V76.7095H72.0655V77.0363H71.9467V76.7095H71.9761V76.5014H71.9467V76.1746ZM71.5752 76.5461V76.6649H71.902V76.5461H71.5752ZM72.1097 76.6649V76.5461H72.4365V76.6649H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 74.5996H73.5756V75.075L73.1001 75.075V74.5996ZM73.1001 75.3127V75.7879H73.5756V75.3127H73.1001ZM73.8133 75.3127V75.7879H74.2884V75.3127H73.8133ZM74.2884 75.075V74.5996H73.8133V75.075L74.2884 75.075ZM73.6351 74.763H73.7539V75.0898H73.7239V75.298H73.7539V75.6248H73.6351V75.298H73.6645V75.0898H73.6351V74.763ZM73.2632 75.1346V75.2534H73.59V75.1346H73.2632ZM73.7984 75.2534V75.1346H74.1252V75.2534H73.7984ZM73.1001 76.0112H73.5755V76.4866H73.1001V76.0112ZM73.1001 76.7243V77.1994H73.5755V76.7243H73.1001ZM73.8133 76.7243V77.1994H74.2884V76.7243H73.8133ZM74.2884 76.4866V76.0112H73.8133V76.4866L74.2884 76.4866ZM73.6351 76.1746H73.7539V76.5013H73.7239V76.7095H73.7539V77.0363H73.6351V76.7095H73.6645V76.5013H73.6351V76.1746ZM73.2632 76.5461V76.6649H73.59V76.5461H73.2632ZM73.7984 76.6649V76.5461H74.1252V76.6649H73.7984ZM74.9872 74.5996H74.5118V75.0751L74.9872 75.0751V74.5996ZM74.5118 75.7879V75.3128H74.9872V75.7879H74.5118ZM75.2249 75.7879V75.3128H75.7001V75.7879H75.2249ZM75.7001 74.5996V75.0751L75.2249 75.0751V74.5996H75.7001ZM75.1656 74.763H75.0468V75.0898H75.0762V75.298H75.0468V75.6248H75.1656V75.298H75.1356V75.0898H75.1656V74.763ZM74.6753 75.2534V75.1346H75.0021V75.2534H74.6753ZM75.2098 75.1346V75.2534H75.5366V75.1346H75.2098ZM74.5118 76.0112H74.9872V76.4866L74.5118 76.4866V76.0112ZM74.5118 76.7243V77.1995H74.9872V76.7243H74.5118ZM75.2249 76.7243V77.1995H75.7001V76.7243H75.2249ZM75.7001 76.4866V76.0112H75.2249V76.4866H75.7001ZM75.0468 76.1746H75.1656V76.5014H75.1356V76.7095H75.1656V77.0363H75.0468V76.7095H75.0762V76.5014H75.0468V76.1746ZM74.6753 76.5461V76.6649H75.0021V76.5461H74.6753ZM75.2098 76.6649V76.5461H75.5366V76.6649H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 65.2998H76.7753V65.7752L76.2998 65.7752V65.2998ZM76.2998 66.0129V66.4881H76.7753V66.0129H76.2998ZM77.013 66.0129V66.4881H77.4881V66.0129H77.013ZM77.4881 65.7752V65.2998H77.013V65.7752L77.4881 65.7752ZM76.8348 65.4632H76.9536V65.79H76.9236V65.9982H76.9536V66.325H76.8348V65.9982H76.8642V65.79H76.8348V65.4632ZM76.4629 65.8348V65.9536H76.7897V65.8348H76.4629ZM76.9981 65.9536V65.8348H77.3249V65.9536H76.9981ZM76.2998 66.7113H76.7753V67.1868H76.2998V66.7113ZM76.2998 67.4245V67.8996H76.7753V67.4245H76.2998ZM77.013 67.4245V67.8996H77.4881V67.4245H77.013ZM77.4881 67.1868V66.7113H77.013V67.1868H77.4881ZM76.8348 66.8747H76.9536V67.2015H76.9236V67.4097H76.9536V67.7364H76.8348V67.4097H76.8642V67.2015H76.8348V66.8747ZM76.4629 67.2463V67.3651H76.7897V67.2463H76.4629ZM76.9981 67.3651V67.2463H77.3249V67.3651H76.9981ZM78.1869 65.2998H77.7115V65.7753L78.1869 65.7753V65.2998ZM77.7115 66.4881V66.013H78.1869V66.4881H77.7115ZM78.4246 66.4881V66.013H78.8998V66.4881H78.4246ZM78.8998 65.2998V65.7753L78.4246 65.7753V65.2998H78.8998ZM78.3653 65.4632H78.2465V65.79H78.2759V65.9982H78.2465V66.325H78.3653V65.9982H78.3353V65.79H78.3653V65.4632ZM77.875 65.9536V65.8348H78.2018V65.9536H77.875ZM78.4095 65.8348V65.9536H78.7363V65.8348H78.4095ZM77.7115 66.7114H78.1869V67.1868H77.7115V66.7114ZM77.7115 67.4245V67.8997H78.1869V67.4245H77.7115ZM78.4246 67.4245V67.8997H78.8998V67.4245H78.4246ZM78.8998 67.1868V66.7114H78.4246V67.1868H78.8998ZM78.2465 66.8748H78.3653V67.2016H78.3353V67.4097H78.3653V67.7365H78.2465V67.4097H78.2759V67.2016H78.2465V66.8748ZM77.875 67.2463V67.3651H78.2018V67.2463H77.875ZM78.4095 67.3651V67.2463H78.7363V67.3651H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 65.2998H79.8754V65.7752L79.3999 65.7752V65.2998ZM79.3999 66.0129V66.4881H79.8754V66.0129H79.3999ZM80.1131 66.0129V66.4881H80.5882V66.0129H80.1131ZM80.5882 65.7752V65.2998H80.1131V65.7752L80.5882 65.7752ZM79.9349 65.4632H80.0537V65.79H80.0237V65.9982H80.0537V66.325H79.9349V65.9982H79.9643V65.79H79.9349V65.4632ZM79.563 65.8348V65.9536H79.8898V65.8348H79.563ZM80.0982 65.9536V65.8348H80.425V65.9536H80.0982ZM79.3999 66.7113H79.8753V67.1868H79.3999V66.7113ZM79.3999 67.4245V67.8996H79.8753V67.4245H79.3999ZM80.1131 67.4245V67.8996H80.5882V67.4245H80.1131ZM80.5882 67.1868V66.7113H80.1131V67.1868L80.5882 67.1868ZM79.9349 66.8747H80.0537V67.2015H80.0237V67.4097H80.0537V67.7364H79.9349V67.4097H79.9643V67.2015H79.9349V66.8747ZM79.563 67.2463V67.3651H79.8898V67.2463H79.563ZM80.0982 67.3651V67.2463H80.425V67.3651H80.0982ZM81.287 65.2998H80.8116V65.7753L81.287 65.7753V65.2998ZM80.8116 66.4881V66.013H81.287V66.4881H80.8116ZM81.5247 66.4881V66.013H81.9999V66.4881H81.5247ZM81.9999 65.2998V65.7753L81.5247 65.7753V65.2998H81.9999ZM81.4654 65.4632H81.3466V65.79H81.376V65.9982H81.3466V66.325H81.4654V65.9982H81.4354V65.79H81.4654V65.4632ZM80.9751 65.9536V65.8348H81.3019V65.9536H80.9751ZM81.5096 65.8348V65.9536H81.8364V65.8348H81.5096ZM80.8116 66.7114H81.287V67.1868L80.8116 67.1868V66.7114ZM80.8116 67.4245V67.8997H81.287V67.4245H80.8116ZM81.5247 67.4245V67.8997H81.9999V67.4245H81.5247ZM81.9999 67.1868V66.7114H81.5247V67.1868H81.9999ZM81.3466 66.8748H81.4654V67.2016H81.4354V67.4097H81.4654V67.7365H81.3466V67.4097H81.376V67.2016H81.3466V66.8748ZM80.9751 67.2463V67.3651H81.3019V67.2463H80.9751ZM81.5096 67.3651V67.2463H81.8364V67.3651H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 65.2998H82.9755V65.7752L82.5 65.7752V65.2998ZM82.5 66.0129V66.4881H82.9755V66.0129H82.5ZM83.2132 66.0129V66.4881H83.6883V66.0129H83.2132ZM83.6883 65.7752V65.2998H83.2132V65.7752L83.6883 65.7752ZM83.035 65.4632H83.1538V65.79H83.1238V65.9982H83.1538V66.325H83.035V65.9982H83.0644V65.79H83.035V65.4632ZM82.6631 65.8348V65.9536H82.9899V65.8348H82.6631ZM83.1983 65.9536V65.8348H83.5251V65.9536H83.1983ZM82.5 66.7113H82.9754V67.1868H82.5V66.7113ZM82.5 67.4245V67.8996H82.9754V67.4245H82.5ZM83.2132 67.4245V67.8996H83.6883V67.4245H83.2132ZM83.6883 67.1868V66.7113H83.2132V67.1868L83.6883 67.1868ZM83.035 66.8747H83.1538V67.2015H83.1238V67.4097H83.1538V67.7364H83.035V67.4097H83.0644V67.2015H83.035V66.8747ZM82.6631 67.2463V67.3651H82.9899V67.2463H82.6631ZM83.1983 67.3651V67.2463H83.5251V67.3651H83.1983ZM84.3871 65.2998H83.9117V65.7753L84.3871 65.7753V65.2998ZM83.9117 66.4881V66.013H84.3871V66.4881H83.9117ZM84.6248 66.4881V66.013H85.1V66.4881H84.6248ZM85.1 65.2998V65.7753L84.6248 65.7753V65.2998H85.1ZM84.5655 65.4632H84.4467V65.79H84.4761V65.9982H84.4467V66.325H84.5655V65.9982H84.5355V65.79H84.5655V65.4632ZM84.0752 65.9536V65.8348H84.402V65.9536H84.0752ZM84.6097 65.8348V65.9536H84.9365V65.8348H84.6097ZM83.9117 66.7114H84.3871V67.1868L83.9117 67.1868V66.7114ZM83.9117 67.4245V67.8997H84.3871V67.4245H83.9117ZM84.6248 67.4245V67.8997H85.1V67.4245H84.6248ZM85.1 67.1868V66.7114H84.6248V67.1868H85.1ZM84.4467 66.8748H84.5655V67.2016H84.5355V67.4097H84.5655V67.7365H84.4467V67.4097H84.4761V67.2016H84.4467V66.8748ZM84.0752 67.2463V67.3651H84.402V67.2463H84.0752ZM84.6097 67.3651V67.2463H84.9365V67.3651H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 65.2998H86.0756V65.7752L85.6001 65.7752V65.2998ZM85.6001 66.0129V66.4881H86.0756V66.0129H85.6001ZM86.3133 66.0129V66.4881H86.7884V66.0129H86.3133ZM86.7884 65.7752V65.2998H86.3133V65.7752L86.7884 65.7752ZM86.1351 65.4632H86.2539V65.79H86.2239V65.9982H86.2539V66.325H86.1351V65.9982H86.1645V65.79H86.1351V65.4632ZM85.7632 65.8348V65.9536H86.09V65.8348H85.7632ZM86.2984 65.9536V65.8348H86.6252V65.9536H86.2984ZM85.6001 66.7113H86.0755V67.1868H85.6001V66.7113ZM85.6001 67.4245V67.8996H86.0755V67.4245H85.6001ZM86.3133 67.4245V67.8996H86.7884V67.4245H86.3133ZM86.7884 67.1868V66.7113H86.3133V67.1868L86.7884 67.1868ZM86.1351 66.8747H86.2539V67.2015H86.2239V67.4097H86.2539V67.7364H86.1351V67.4097H86.1645V67.2015H86.1351V66.8747ZM85.7632 67.2463V67.3651H86.09V67.2463H85.7632ZM86.2984 67.3651V67.2463H86.6252V67.3651H86.2984ZM87.4872 65.2998H87.0118V65.7753L87.4872 65.7753V65.2998ZM87.0118 66.4881V66.013H87.4872V66.4881H87.0118ZM87.7249 66.4881V66.013H88.2001V66.4881H87.7249ZM88.2001 65.2998V65.7753L87.7249 65.7753V65.2998H88.2001ZM87.6656 65.4632H87.5468V65.79H87.5762V65.9982H87.5468V66.325H87.6656V65.9982H87.6356V65.79H87.6656V65.4632ZM87.1753 65.9536V65.8348H87.5021V65.9536H87.1753ZM87.7098 65.8348V65.9536H88.0366V65.8348H87.7098ZM87.0118 66.7114H87.4872V67.1868L87.0118 67.1868V66.7114ZM87.0118 67.4245V67.8997H87.4872V67.4245H87.0118ZM87.7249 67.4245V67.8997H88.2001V67.4245H87.7249ZM88.2001 67.1868V66.7114H87.7249V67.1868H88.2001ZM87.5468 66.8748H87.6656V67.2016H87.6356V67.4097H87.6656V67.7365H87.5468V67.4097H87.5762V67.2016H87.5468V66.8748ZM87.1753 67.2463V67.3651H87.5021V67.2463H87.1753ZM87.7098 67.3651V67.2463H88.0366V67.3651H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 68.4004H76.7753V68.8758L76.2998 68.8758V68.4004ZM76.2998 69.1135V69.5887H76.7753V69.1135H76.2998ZM77.013 69.1135V69.5887H77.4881V69.1135H77.013ZM77.4881 68.8758V68.4004H77.013V68.8758L77.4881 68.8758ZM76.8348 68.5638H76.9536V68.8906H76.9236V69.0988H76.9536V69.4255H76.8348V69.0988H76.8642V68.8906H76.8348V68.5638ZM76.4629 68.9353V69.0542H76.7897V68.9353H76.4629ZM76.9981 69.0542V68.9354H77.3249V69.0542H76.9981ZM76.2998 69.8119H76.7753V70.2874H76.2998V69.8119ZM76.2998 70.5251V71.0002H76.7753V70.5251H76.2998ZM77.013 70.5251V71.0002H77.4881V70.5251H77.013ZM77.4881 70.2874V69.8119H77.013V70.2874L77.4881 70.2874ZM76.8348 69.9753H76.9536V70.3021H76.9236V70.5103H76.9536V70.837H76.8348V70.5103H76.8642V70.3021H76.8348V69.9753ZM76.4629 70.3468V70.4657H76.7897V70.3468H76.4629ZM76.9981 70.4657V70.3469H77.3249V70.4657H76.9981ZM78.1869 68.4004H77.7115V68.8759L78.1869 68.8759V68.4004ZM77.7115 69.5887V69.1136H78.1869V69.5887H77.7115ZM78.4246 69.5887V69.1136H78.8998V69.5887H78.4246ZM78.8998 68.4004V68.8759L78.4246 68.8759V68.4004H78.8998ZM78.3653 68.5638H78.2465V68.8906H78.2759V69.0988H78.2465V69.4256H78.3653V69.0988H78.3353V68.8906H78.3653V68.5638ZM77.875 69.0542V68.9354H78.2018V69.0542H77.875ZM78.4095 68.9354V69.0542H78.7363V68.9354H78.4095ZM77.7115 69.812H78.1869V70.2874L77.7115 70.2874V69.812ZM77.7115 70.5251V71.0003H78.1869V70.5251H77.7115ZM78.4246 70.5251V71.0003H78.8998V70.5251H78.4246ZM78.8998 70.2874V69.812H78.4246V70.2874H78.8998ZM78.2465 69.9754H78.3653V70.3021H78.3353V70.5103H78.3653V70.8371H78.2465V70.5103H78.2759V70.3021H78.2465V69.9754ZM77.875 70.3469V70.4657H78.2018V70.3469H77.875ZM78.4095 70.4657V70.3469H78.7363V70.4657H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 68.4004H79.8754V68.8758L79.3999 68.8758V68.4004ZM79.3999 69.1135V69.5887H79.8754V69.1135H79.3999ZM80.1131 69.1135V69.5887H80.5882V69.1135H80.1131ZM80.5882 68.8758V68.4004H80.1131V68.8758L80.5882 68.8758ZM79.9349 68.5638H80.0537V68.8906H80.0237V69.0988H80.0537V69.4255H79.9349V69.0988H79.9643V68.8906H79.9349V68.5638ZM79.563 68.9353V69.0542H79.8898V68.9353H79.563ZM80.0982 69.0542V68.9354H80.425V69.0542H80.0982ZM79.3999 69.8119H79.8753V70.2874H79.3999V69.8119ZM79.3999 70.5251V71.0002H79.8753V70.5251H79.3999ZM80.1131 70.5251V71.0002H80.5882V70.5251H80.1131ZM80.5882 70.2874V69.8119H80.1131V70.2874L80.5882 70.2874ZM79.9349 69.9753H80.0537V70.3021H80.0237V70.5103H80.0537V70.837H79.9349V70.5103H79.9643V70.3021H79.9349V69.9753ZM79.563 70.3468V70.4657H79.8898V70.3468H79.563ZM80.0982 70.4657V70.3469H80.425V70.4657H80.0982ZM81.287 68.4004H80.8116V68.8759L81.287 68.8759V68.4004ZM80.8116 69.5887V69.1136H81.287V69.5887H80.8116ZM81.5247 69.5887V69.1136H81.9999V69.5887H81.5247ZM81.9999 68.4004V68.8759L81.5247 68.8759V68.4004H81.9999ZM81.4654 68.5638H81.3466V68.8906H81.376V69.0988H81.3466V69.4256H81.4654V69.0988H81.4354V68.8906H81.4654V68.5638ZM80.9751 69.0542V68.9354H81.3019V69.0542H80.9751ZM81.5096 68.9354V69.0542H81.8364V68.9354H81.5096ZM80.8116 69.812H81.287V70.2874L80.8116 70.2874V69.812ZM80.8116 70.5251V71.0003H81.287V70.5251H80.8116ZM81.5247 70.5251V71.0003H81.9999V70.5251H81.5247ZM81.9999 70.2874V69.812H81.5247V70.2874H81.9999ZM81.3466 69.9754H81.4654V70.3021H81.4354V70.5103H81.4654V70.8371H81.3466V70.5103H81.376V70.3021H81.3466V69.9754ZM80.9751 70.3469V70.4657H81.3019V70.3469H80.9751ZM81.5096 70.4657V70.3469H81.8364V70.4657H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 68.4004H82.9755V68.8758L82.5 68.8758V68.4004ZM82.5 69.1135V69.5887H82.9755V69.1135H82.5ZM83.2132 69.1135V69.5887H83.6883V69.1135H83.2132ZM83.6883 68.8758V68.4004H83.2132V68.8758L83.6883 68.8758ZM83.035 68.5638H83.1538V68.8906H83.1238V69.0988H83.1538V69.4255H83.035V69.0988H83.0644V68.8906H83.035V68.5638ZM82.6631 68.9353V69.0542H82.9899V68.9353H82.6631ZM83.1983 69.0542V68.9354H83.5251V69.0542H83.1983ZM82.5 69.8119H82.9754V70.2874H82.5V69.8119ZM82.5 70.5251V71.0002H82.9754V70.5251H82.5ZM83.2132 70.5251V71.0002H83.6883V70.5251H83.2132ZM83.6883 70.2874V69.8119H83.2132V70.2874L83.6883 70.2874ZM83.035 69.9753H83.1538V70.3021H83.1238V70.5103H83.1538V70.837H83.035V70.5103H83.0644V70.3021H83.035V69.9753ZM82.6631 70.3468V70.4657H82.9899V70.3468H82.6631ZM83.1983 70.4657V70.3469H83.5251V70.4657H83.1983ZM84.3871 68.4004H83.9117V68.8759L84.3871 68.8759V68.4004ZM83.9117 69.5887V69.1136H84.3871V69.5887H83.9117ZM84.6248 69.5887V69.1136H85.1V69.5887H84.6248ZM85.1 68.4004V68.8759L84.6248 68.8759V68.4004H85.1ZM84.5655 68.5638H84.4467V68.8906H84.4761V69.0988H84.4467V69.4256H84.5655V69.0988H84.5355V68.8906H84.5655V68.5638ZM84.0752 69.0542V68.9354H84.402V69.0542H84.0752ZM84.6097 68.9354V69.0542H84.9365V68.9354H84.6097ZM83.9117 69.812H84.3871V70.2874L83.9117 70.2874V69.812ZM83.9117 70.5251V71.0003H84.3871V70.5251H83.9117ZM84.6248 70.5251V71.0003H85.1V70.5251H84.6248ZM85.1 70.2874V69.812H84.6248V70.2874H85.1ZM84.4467 69.9754H84.5655V70.3021H84.5355V70.5103H84.5655V70.8371H84.4467V70.5103H84.4761V70.3021H84.4467V69.9754ZM84.0752 70.3469V70.4657H84.402V70.3469H84.0752ZM84.6097 70.4657V70.3469H84.9365V70.4657H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 68.4004H86.0756V68.8758L85.6001 68.8758V68.4004ZM85.6001 69.1135V69.5887H86.0756V69.1135H85.6001ZM86.3133 69.1135V69.5887H86.7884V69.1135H86.3133ZM86.7884 68.8758V68.4004H86.3133V68.8758L86.7884 68.8758ZM86.1351 68.5638H86.2539V68.8906H86.2239V69.0988H86.2539V69.4255H86.1351V69.0988H86.1645V68.8906H86.1351V68.5638ZM85.7632 68.9353V69.0542H86.09V68.9353H85.7632ZM86.2984 69.0542V68.9354H86.6252V69.0542H86.2984ZM85.6001 69.8119H86.0755V70.2874H85.6001V69.8119ZM85.6001 70.5251V71.0002H86.0755V70.5251H85.6001ZM86.3133 70.5251V71.0002H86.7884V70.5251H86.3133ZM86.7884 70.2874V69.8119H86.3133V70.2874L86.7884 70.2874ZM86.1351 69.9753H86.2539V70.3021H86.2239V70.5103H86.2539V70.837H86.1351V70.5103H86.1645V70.3021H86.1351V69.9753ZM85.7632 70.3468V70.4657H86.09V70.3468H85.7632ZM86.2984 70.4657V70.3469H86.6252V70.4657H86.2984ZM87.4872 68.4004H87.0118V68.8759L87.4872 68.8759V68.4004ZM87.0118 69.5887V69.1136H87.4872V69.5887H87.0118ZM87.7249 69.5887V69.1136H88.2001V69.5887H87.7249ZM88.2001 68.4004V68.8759L87.7249 68.8759V68.4004H88.2001ZM87.6656 68.5638H87.5468V68.8906H87.5762V69.0988H87.5468V69.4256H87.6656V69.0988H87.6356V68.8906H87.6656V68.5638ZM87.1753 69.0542V68.9354H87.5021V69.0542H87.1753ZM87.7098 68.9354V69.0542H88.0366V68.9354H87.7098ZM87.0118 69.812H87.4872V70.2874L87.0118 70.2874V69.812ZM87.0118 70.5251V71.0003H87.4872V70.5251H87.0118ZM87.7249 70.5251V71.0003H88.2001V70.5251H87.7249ZM88.2001 70.2874V69.812H87.7249V70.2874H88.2001ZM87.5468 69.9754H87.6656V70.3021H87.6356V70.5103H87.6656V70.8371H87.5468V70.5103H87.5762V70.3021H87.5468V69.9754ZM87.1753 70.3469V70.4657H87.5021V70.3469H87.1753ZM87.7098 70.4657V70.3469H88.0366V70.4657H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 71.5H76.7753V71.9754L76.2998 71.9754V71.5ZM76.2998 72.2131V72.6883H76.7753V72.2131H76.2998ZM77.013 72.2131V72.6883H77.4881V72.2131H77.013ZM77.4881 71.9754V71.5H77.013V71.9754L77.4881 71.9754ZM76.8348 71.6634H76.9536V71.9902H76.9236V72.1984H76.9536V72.5251H76.8348V72.1984H76.8642V71.9902H76.8348V71.6634ZM76.4629 72.035V72.1538H76.7897V72.035H76.4629ZM76.9981 72.1538V72.035H77.3249V72.1538H76.9981ZM76.2998 72.9115H76.7753V73.387H76.2998V72.9115ZM76.2998 73.6247V74.0998H76.7753V73.6247H76.2998ZM77.013 73.6247V74.0998H77.4881V73.6247H77.013ZM77.4881 73.387V72.9115H77.013V73.387L77.4881 73.387ZM76.8348 73.0749H76.9536V73.4017H76.9236V73.6099H76.9536V73.9366H76.8348V73.6099H76.8642V73.4017H76.8348V73.0749ZM76.4629 73.4465V73.5653H76.7897V73.4465H76.4629ZM76.9981 73.5653V73.4465H77.3249V73.5653H76.9981ZM78.1869 71.5H77.7115V71.9755L78.1869 71.9755V71.5ZM77.7115 72.6883V72.2132H78.1869V72.6883H77.7115ZM78.4246 72.6883V72.2132H78.8998V72.6883H78.4246ZM78.8998 71.5V71.9755L78.4246 71.9755V71.5H78.8998ZM78.3653 71.6634H78.2465V71.9902H78.2759V72.1984H78.2465V72.5252H78.3653V72.1984H78.3353V71.9902H78.3653V71.6634ZM77.875 72.1538V72.035H78.2018V72.1538H77.875ZM78.4095 72.035V72.1538H78.7363V72.035H78.4095ZM77.7115 72.9116H78.1869V73.387L77.7115 73.387V72.9116ZM77.7115 73.6247V74.0999H78.1869V73.6247H77.7115ZM78.4246 73.6247V74.0999H78.8998V73.6247H78.4246ZM78.8998 73.387V72.9116H78.4246V73.387H78.8998ZM78.2465 73.075H78.3653V73.4018H78.3353V73.6099H78.3653V73.9367H78.2465V73.6099H78.2759V73.4018H78.2465V73.075ZM77.875 73.4465V73.5653H78.2018V73.4465H77.875ZM78.4095 73.5653V73.4465H78.7363V73.5653H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 71.5H79.8754V71.9754L79.3999 71.9754V71.5ZM79.3999 72.2131V72.6883H79.8754V72.2131H79.3999ZM80.1131 72.2131V72.6883H80.5882V72.2131H80.1131ZM80.5882 71.9754V71.5H80.1131V71.9754L80.5882 71.9754ZM79.9349 71.6634H80.0537V71.9902H80.0237V72.1984H80.0537V72.5251H79.9349V72.1984H79.9643V71.9902H79.9349V71.6634ZM79.563 72.035V72.1538H79.8898V72.035H79.563ZM80.0982 72.1538V72.035H80.425V72.1538H80.0982ZM79.3999 72.9115H79.8753V73.387H79.3999V72.9115ZM79.3999 73.6247V74.0998H79.8753V73.6247H79.3999ZM80.1131 73.6247V74.0998H80.5882V73.6247H80.1131ZM80.5882 73.387V72.9115H80.1131V73.387L80.5882 73.387ZM79.9349 73.0749H80.0537V73.4017H80.0237V73.6099H80.0537V73.9366H79.9349V73.6099H79.9643V73.4017H79.9349V73.0749ZM79.563 73.4465V73.5653H79.8898V73.4465H79.563ZM80.0982 73.5653V73.4465H80.425V73.5653H80.0982ZM81.287 71.5H80.8116V71.9755L81.287 71.9755V71.5ZM80.8116 72.6883V72.2132H81.287V72.6883H80.8116ZM81.5247 72.6883V72.2132H81.9999V72.6883H81.5247ZM81.9999 71.5V71.9755L81.5247 71.9755V71.5H81.9999ZM81.4654 71.6634H81.3466V71.9902H81.376V72.1984H81.3466V72.5252H81.4654V72.1984H81.4354V71.9902H81.4654V71.6634ZM80.9751 72.1538V72.035H81.3019V72.1538H80.9751ZM81.5096 72.035V72.1538H81.8364V72.035H81.5096ZM80.8116 72.9116H81.287V73.387L80.8116 73.387V72.9116ZM80.8116 73.6247V74.0999H81.287V73.6247H80.8116ZM81.5247 73.6247V74.0999H81.9999V73.6247H81.5247ZM81.9999 73.387V72.9116H81.5247V73.387H81.9999ZM81.3466 73.075H81.4654V73.4018H81.4354V73.6099H81.4654V73.9367H81.3466V73.6099H81.376V73.4018H81.3466V73.075ZM80.9751 73.4465V73.5653H81.3019V73.4465H80.9751ZM81.5096 73.5653V73.4465H81.8364V73.5653H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 71.5H82.9755V71.9754L82.5 71.9754V71.5ZM82.5 72.2131V72.6883H82.9755V72.2131H82.5ZM83.2132 72.2131V72.6883H83.6883V72.2131H83.2132ZM83.6883 71.9754V71.5H83.2132V71.9754L83.6883 71.9754ZM83.035 71.6634H83.1538V71.9902H83.1238V72.1984H83.1538V72.5251H83.035V72.1984H83.0644V71.9902H83.035V71.6634ZM82.6631 72.035V72.1538H82.9899V72.035H82.6631ZM83.1983 72.1538V72.035H83.5251V72.1538H83.1983ZM82.5 72.9115H82.9754V73.387H82.5V72.9115ZM82.5 73.6247V74.0998H82.9754V73.6247H82.5ZM83.2132 73.6247V74.0998H83.6883V73.6247H83.2132ZM83.6883 73.387V72.9115H83.2132V73.387L83.6883 73.387ZM83.035 73.0749H83.1538V73.4017H83.1238V73.6099H83.1538V73.9366H83.035V73.6099H83.0644V73.4017H83.035V73.0749ZM82.6631 73.4465V73.5653H82.9899V73.4465H82.6631ZM83.1983 73.5653V73.4465H83.5251V73.5653H83.1983ZM84.3871 71.5H83.9117V71.9755L84.3871 71.9755V71.5ZM83.9117 72.6883V72.2132H84.3871V72.6883H83.9117ZM84.6248 72.6883V72.2132H85.1V72.6883H84.6248ZM85.1 71.5V71.9755L84.6248 71.9755V71.5H85.1ZM84.5655 71.6634H84.4467V71.9902H84.4761V72.1984H84.4467V72.5252H84.5655V72.1984H84.5355V71.9902H84.5655V71.6634ZM84.0752 72.1538V72.035H84.402V72.1538H84.0752ZM84.6097 72.035V72.1538H84.9365V72.035H84.6097ZM83.9117 72.9116H84.3871V73.387L83.9117 73.387V72.9116ZM83.9117 73.6247V74.0999H84.3871V73.6247H83.9117ZM84.6248 73.6247V74.0999H85.1V73.6247H84.6248ZM85.1 73.387V72.9116H84.6248V73.387H85.1ZM84.4467 73.075H84.5655V73.4018H84.5355V73.6099H84.5655V73.9367H84.4467V73.6099H84.4761V73.4018H84.4467V73.075ZM84.0752 73.4465V73.5653H84.402V73.4465H84.0752ZM84.6097 73.5653V73.4465H84.9365V73.5653H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 71.5H86.0756V71.9754L85.6001 71.9754V71.5ZM85.6001 72.2131V72.6883H86.0756V72.2131H85.6001ZM86.3133 72.2131V72.6883H86.7884V72.2131H86.3133ZM86.7884 71.9754V71.5H86.3133V71.9754L86.7884 71.9754ZM86.1351 71.6634H86.2539V71.9902H86.2239V72.1984H86.2539V72.5251H86.1351V72.1984H86.1645V71.9902H86.1351V71.6634ZM85.7632 72.035V72.1538H86.09V72.035H85.7632ZM86.2984 72.1538V72.035H86.6252V72.1538H86.2984ZM85.6001 72.9115H86.0755V73.387H85.6001V72.9115ZM85.6001 73.6247V74.0998H86.0755V73.6247H85.6001ZM86.3133 73.6247V74.0998H86.7884V73.6247H86.3133ZM86.7884 73.387V72.9115H86.3133V73.387L86.7884 73.387ZM86.1351 73.0749H86.2539V73.4017H86.2239V73.6099H86.2539V73.9366H86.1351V73.6099H86.1645V73.4017H86.1351V73.0749ZM85.7632 73.4465V73.5653H86.09V73.4465H85.7632ZM86.2984 73.5653V73.4465H86.6252V73.5653H86.2984ZM87.4872 71.5H87.0118V71.9755L87.4872 71.9755V71.5ZM87.0118 72.6883V72.2132H87.4872V72.6883H87.0118ZM87.7249 72.6883V72.2132H88.2001V72.6883H87.7249ZM88.2001 71.5V71.9755L87.7249 71.9755V71.5H88.2001ZM87.6656 71.6634H87.5468V71.9902H87.5762V72.1984H87.5468V72.5252H87.6656V72.1984H87.6356V71.9902H87.6656V71.6634ZM87.1753 72.1538V72.035H87.5021V72.1538H87.1753ZM87.7098 72.035V72.1538H88.0366V72.035H87.7098ZM87.0118 72.9116H87.4872V73.387L87.0118 73.387V72.9116ZM87.0118 73.6247V74.0999H87.4872V73.6247H87.0118ZM87.7249 73.6247V74.0999H88.2001V73.6247H87.7249ZM88.2001 73.387V72.9116H87.7249V73.387H88.2001ZM87.5468 73.075H87.6656V73.4018H87.6356V73.6099H87.6656V73.9367H87.5468V73.6099H87.5762V73.4018H87.5468V73.075ZM87.1753 73.4465V73.5653H87.5021V73.4465H87.1753ZM87.7098 73.5653V73.4465H88.0366V73.5653H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 74.5996H76.7753V75.075L76.2998 75.075V74.5996ZM76.2998 75.3127V75.7879H76.7753V75.3127H76.2998ZM77.013 75.3127V75.7879H77.4881V75.3127H77.013ZM77.4881 75.075V74.5996H77.013V75.075L77.4881 75.075ZM76.8348 74.763H76.9536V75.0898H76.9236V75.298H76.9536V75.6248H76.8348V75.298H76.8642V75.0898H76.8348V74.763ZM76.4629 75.1346V75.2534H76.7897V75.1346H76.4629ZM76.9981 75.2534V75.1346H77.3249V75.2534H76.9981ZM76.2998 76.0112H76.7753V76.4866H76.2998V76.0112ZM76.2998 76.7243V77.1994H76.7753V76.7243H76.2998ZM77.013 76.7243V77.1994H77.4881V76.7243H77.013ZM77.4881 76.4866V76.0112H77.013V76.4866L77.4881 76.4866ZM76.8348 76.1746H76.9536V76.5013H76.9236V76.7095H76.9536V77.0363H76.8348V76.7095H76.8642V76.5013H76.8348V76.1746ZM76.4629 76.5461V76.6649H76.7897V76.5461H76.4629ZM76.9981 76.6649V76.5461H77.3249V76.6649H76.9981ZM78.1869 74.5996H77.7115V75.0751L78.1869 75.0751V74.5996ZM77.7115 75.7879V75.3128H78.1869V75.7879H77.7115ZM78.4246 75.7879V75.3128H78.8998V75.7879H78.4246ZM78.8998 74.5996V75.0751L78.4246 75.0751V74.5996H78.8998ZM78.3653 74.763H78.2465V75.0898H78.2759V75.298H78.2465V75.6248H78.3653V75.298H78.3353V75.0898H78.3653V74.763ZM77.875 75.2534V75.1346H78.2018V75.2534H77.875ZM78.4095 75.1346V75.2534H78.7363V75.1346H78.4095ZM77.7115 76.0112H78.1869V76.4866L77.7115 76.4866V76.0112ZM77.7115 76.7243V77.1995H78.1869V76.7243H77.7115ZM78.4246 76.7243V77.1995H78.8998V76.7243H78.4246ZM78.8998 76.4866V76.0112H78.4246V76.4866H78.8998ZM78.2465 76.1746H78.3653V76.5014H78.3353V76.7095H78.3653V77.0363H78.2465V76.7095H78.2759V76.5014H78.2465V76.1746ZM77.875 76.5461V76.6649H78.2018V76.5461H77.875ZM78.4095 76.6649V76.5461H78.7363V76.6649H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 74.5996H79.8754V75.075L79.3999 75.075V74.5996ZM79.3999 75.3127V75.7879H79.8754V75.3127H79.3999ZM80.1131 75.3127V75.7879H80.5882V75.3127H80.1131ZM80.5882 75.075V74.5996H80.1131V75.075L80.5882 75.075ZM79.9349 74.763H80.0537V75.0898H80.0237V75.298H80.0537V75.6248H79.9349V75.298H79.9643V75.0898H79.9349V74.763ZM79.563 75.1346V75.2534H79.8898V75.1346H79.563ZM80.0982 75.2534V75.1346H80.425V75.2534H80.0982ZM79.3999 76.0112H79.8753V76.4866H79.3999V76.0112ZM79.3999 76.7243V77.1994H79.8753V76.7243H79.3999ZM80.1131 76.7243V77.1994H80.5882V76.7243H80.1131ZM80.5882 76.4866V76.0112H80.1131V76.4866L80.5882 76.4866ZM79.9349 76.1746H80.0537V76.5013H80.0237V76.7095H80.0537V77.0363H79.9349V76.7095H79.9643V76.5013H79.9349V76.1746ZM79.563 76.5461V76.6649H79.8898V76.5461H79.563ZM80.0982 76.6649V76.5461H80.425V76.6649H80.0982ZM81.287 74.5996H80.8116V75.0751L81.287 75.0751V74.5996ZM80.8116 75.7879V75.3128H81.287V75.7879H80.8116ZM81.5247 75.7879V75.3128H81.9999V75.7879H81.5247ZM81.9999 74.5996V75.0751L81.5247 75.0751V74.5996H81.9999ZM81.4654 74.763H81.3466V75.0898H81.376V75.298H81.3466V75.6248H81.4654V75.298H81.4354V75.0898H81.4654V74.763ZM80.9751 75.2534V75.1346H81.3019V75.2534H80.9751ZM81.5096 75.1346V75.2534H81.8364V75.1346H81.5096ZM80.8116 76.0112H81.287V76.4866L80.8116 76.4866V76.0112ZM80.8116 76.7243V77.1995H81.287V76.7243H80.8116ZM81.5247 76.7243V77.1995H81.9999V76.7243H81.5247ZM81.9999 76.4866V76.0112H81.5247V76.4866H81.9999ZM81.3466 76.1746H81.4654V76.5014H81.4354V76.7095H81.4654V77.0363H81.3466V76.7095H81.376V76.5014H81.3466V76.1746ZM80.9751 76.5461V76.6649H81.3019V76.5461H80.9751ZM81.5096 76.6649V76.5461H81.8364V76.6649H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 74.5996H82.9755V75.075L82.5 75.075V74.5996ZM82.5 75.3127V75.7879H82.9755V75.3127H82.5ZM83.2132 75.3127V75.7879H83.6883V75.3127H83.2132ZM83.6883 75.075V74.5996H83.2132V75.075L83.6883 75.075ZM83.035 74.763H83.1538V75.0898H83.1238V75.298H83.1538V75.6248H83.035V75.298H83.0644V75.0898H83.035V74.763ZM82.6631 75.1346V75.2534H82.9899V75.1346H82.6631ZM83.1983 75.2534V75.1346H83.5251V75.2534H83.1983ZM82.5 76.0112H82.9754V76.4866H82.5V76.0112ZM82.5 76.7243V77.1994H82.9754V76.7243H82.5ZM83.2132 76.7243V77.1994H83.6883V76.7243H83.2132ZM83.6883 76.4866V76.0112H83.2132V76.4866L83.6883 76.4866ZM83.035 76.1746H83.1538V76.5013H83.1238V76.7095H83.1538V77.0363H83.035V76.7095H83.0644V76.5013H83.035V76.1746ZM82.6631 76.5461V76.6649H82.9899V76.5461H82.6631ZM83.1983 76.6649V76.5461H83.5251V76.6649H83.1983ZM84.3871 74.5996H83.9117V75.0751L84.3871 75.0751V74.5996ZM83.9117 75.7879V75.3128H84.3871V75.7879H83.9117ZM84.6248 75.7879V75.3128H85.1V75.7879H84.6248ZM85.1 74.5996V75.0751L84.6248 75.0751V74.5996H85.1ZM84.5655 74.763H84.4467V75.0898H84.4761V75.298H84.4467V75.6248H84.5655V75.298H84.5355V75.0898H84.5655V74.763ZM84.0752 75.2534V75.1346H84.402V75.2534H84.0752ZM84.6097 75.1346V75.2534H84.9365V75.1346H84.6097ZM83.9117 76.0112H84.3871V76.4866L83.9117 76.4866V76.0112ZM83.9117 76.7243V77.1995H84.3871V76.7243H83.9117ZM84.6248 76.7243V77.1995H85.1V76.7243H84.6248ZM85.1 76.4866V76.0112H84.6248V76.4866H85.1ZM84.4467 76.1746H84.5655V76.5014H84.5355V76.7095H84.5655V77.0363H84.4467V76.7095H84.4761V76.5014H84.4467V76.1746ZM84.0752 76.5461V76.6649H84.402V76.5461H84.0752ZM84.6097 76.6649V76.5461H84.9365V76.6649H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 74.5996H86.0756V75.075L85.6001 75.075V74.5996ZM85.6001 75.3127V75.7879H86.0756V75.3127H85.6001ZM86.3133 75.3127V75.7879H86.7884V75.3127H86.3133ZM86.7884 75.075V74.5996H86.3133V75.075L86.7884 75.075ZM86.1351 74.763H86.2539V75.0898H86.2239V75.298H86.2539V75.6248H86.1351V75.298H86.1645V75.0898H86.1351V74.763ZM85.7632 75.1346V75.2534H86.09V75.1346H85.7632ZM86.2984 75.2534V75.1346H86.6252V75.2534H86.2984ZM85.6001 76.0112H86.0755V76.4866H85.6001V76.0112ZM85.6001 76.7243V77.1994H86.0755V76.7243H85.6001ZM86.3133 76.7243V77.1994H86.7884V76.7243H86.3133ZM86.7884 76.4866V76.0112H86.3133V76.4866L86.7884 76.4866ZM86.1351 76.1746H86.2539V76.5013H86.2239V76.7095H86.2539V77.0363H86.1351V76.7095H86.1645V76.5013H86.1351V76.1746ZM85.7632 76.5461V76.6649H86.09V76.5461H85.7632ZM86.2984 76.6649V76.5461H86.6252V76.6649H86.2984ZM87.4872 74.5996H87.0118V75.0751L87.4872 75.0751V74.5996ZM87.0118 75.7879V75.3128H87.4872V75.7879H87.0118ZM87.7249 75.7879V75.3128H88.2001V75.7879H87.7249ZM88.2001 74.5996V75.0751L87.7249 75.0751V74.5996H88.2001ZM87.6656 74.763H87.5468V75.0898H87.5762V75.298H87.5468V75.6248H87.6656V75.298H87.6356V75.0898H87.6656V74.763ZM87.1753 75.2534V75.1346H87.5021V75.2534H87.1753ZM87.7098 75.1346V75.2534H88.0366V75.1346H87.7098ZM87.0118 76.0112H87.4872V76.4866L87.0118 76.4866V76.0112ZM87.0118 76.7243V77.1995H87.4872V76.7243H87.0118ZM87.7249 76.7243V77.1995H88.2001V76.7243H87.7249ZM88.2001 76.4866V76.0112H87.7249V76.4866H88.2001ZM87.5468 76.1746H87.6656V76.5014H87.6356V76.7095H87.6656V77.0363H87.5468V76.7095H87.5762V76.5014H87.5468V76.1746ZM87.1753 76.5461V76.6649H87.5021V76.5461H87.1753ZM87.7098 76.6649V76.5461H88.0366V76.6649H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 65.2998H89.2753V65.7752L88.7998 65.7752V65.2998ZM88.7998 66.0129V66.4881H89.2753V66.0129H88.7998ZM89.513 66.0129V66.4881H89.9881V66.0129H89.513ZM89.9881 65.7752V65.2998H89.513V65.7752L89.9881 65.7752ZM89.3348 65.4632H89.4536V65.79H89.4236V65.9982H89.4536V66.325H89.3348V65.9982H89.3642V65.79H89.3348V65.4632ZM88.9629 65.8348V65.9536H89.2897V65.8348H88.9629ZM89.4981 65.9536V65.8348H89.8249V65.9536H89.4981ZM88.7998 66.7113H89.2753V67.1868H88.7998V66.7113ZM88.7998 67.4245V67.8996H89.2753V67.4245H88.7998ZM89.513 67.4245V67.8996H89.9881V67.4245H89.513ZM89.9881 67.1868V66.7113H89.513V67.1868H89.9881ZM89.3348 66.8747H89.4536V67.2015H89.4236V67.4097H89.4536V67.7364H89.3348V67.4097H89.3642V67.2015H89.3348V66.8747ZM88.9629 67.2463V67.3651H89.2897V67.2463H88.9629ZM89.4981 67.3651V67.2463H89.8249V67.3651H89.4981ZM90.6869 65.2998H90.2115V65.7753L90.6869 65.7753V65.2998ZM90.2115 66.4881V66.013H90.6869V66.4881H90.2115ZM90.9246 66.4881V66.013H91.3998V66.4881H90.9246ZM91.3998 65.2998V65.7753L90.9246 65.7753V65.2998H91.3998ZM90.8653 65.4632H90.7465V65.79H90.7759V65.9982H90.7465V66.325H90.8653V65.9982H90.8353V65.79H90.8653V65.4632ZM90.375 65.9536V65.8348H90.7018V65.9536H90.375ZM90.9095 65.8348V65.9536H91.2363V65.8348H90.9095ZM90.2115 66.7114H90.6869V67.1868H90.2115V66.7114ZM90.2115 67.4245V67.8997H90.6869V67.4245H90.2115ZM90.9246 67.4245V67.8997H91.3998V67.4245H90.9246ZM91.3998 67.1868V66.7114H90.9246V67.1868H91.3998ZM90.7465 66.8748H90.8653V67.2016H90.8353V67.4097H90.8653V67.7365H90.7465V67.4097H90.7759V67.2016H90.7465V66.8748ZM90.375 67.2463V67.3651H90.7018V67.2463H90.375ZM90.9095 67.3651V67.2463H91.2363V67.3651H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 65.2998H92.3754V65.7752L91.8999 65.7752V65.2998ZM91.8999 66.0129V66.4881H92.3754V66.0129H91.8999ZM92.6131 66.0129V66.4881H93.0882V66.0129H92.6131ZM93.0882 65.7752V65.2998H92.6131V65.7752L93.0882 65.7752ZM92.4349 65.4632H92.5537V65.79H92.5237V65.9982H92.5537V66.325H92.4349V65.9982H92.4643V65.79H92.4349V65.4632ZM92.063 65.8348V65.9536H92.3898V65.8348H92.063ZM92.5982 65.9536V65.8348H92.925V65.9536H92.5982ZM91.8999 66.7113H92.3753V67.1868H91.8999V66.7113ZM91.8999 67.4245V67.8996H92.3753V67.4245H91.8999ZM92.6131 67.4245V67.8996H93.0882V67.4245H92.6131ZM93.0882 67.1868V66.7113H92.6131V67.1868L93.0882 67.1868ZM92.4349 66.8747H92.5537V67.2015H92.5237V67.4097H92.5537V67.7364H92.4349V67.4097H92.4643V67.2015H92.4349V66.8747ZM92.063 67.2463V67.3651H92.3898V67.2463H92.063ZM92.5982 67.3651V67.2463H92.925V67.3651H92.5982ZM93.787 65.2998H93.3116V65.7753L93.787 65.7753V65.2998ZM93.3116 66.4881V66.013H93.787V66.4881H93.3116ZM94.0247 66.4881V66.013H94.4999V66.4881H94.0247ZM94.4999 65.2998V65.7753L94.0247 65.7753V65.2998H94.4999ZM93.9654 65.4632H93.8466V65.79H93.876V65.9982H93.8466V66.325H93.9654V65.9982H93.9354V65.79H93.9654V65.4632ZM93.4751 65.9536V65.8348H93.8019V65.9536H93.4751ZM94.0096 65.8348V65.9536H94.3364V65.8348H94.0096ZM93.3116 66.7114H93.787V67.1868L93.3116 67.1868V66.7114ZM93.3116 67.4245V67.8997H93.787V67.4245H93.3116ZM94.0247 67.4245V67.8997H94.4999V67.4245H94.0247ZM94.4999 67.1868V66.7114H94.0247V67.1868H94.4999ZM93.8466 66.8748H93.9654V67.2016H93.9354V67.4097H93.9654V67.7365H93.8466V67.4097H93.876V67.2016H93.8466V66.8748ZM93.4751 67.2463V67.3651H93.8019V67.2463H93.4751ZM94.0096 67.3651V67.2463H94.3364V67.3651H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 65.2998H95.4755V65.7752L95 65.7752V65.2998ZM95 66.0129V66.4881H95.4755V66.0129H95ZM95.7132 66.0129V66.4881H96.1883V66.0129H95.7132ZM96.1883 65.7752V65.2998H95.7132V65.7752L96.1883 65.7752ZM95.535 65.4632H95.6538V65.79H95.6238V65.9982H95.6538V66.325H95.535V65.9982H95.5644V65.79H95.535V65.4632ZM95.1631 65.8348V65.9536H95.4899V65.8348H95.1631ZM95.6983 65.9536V65.8348H96.0251V65.9536H95.6983ZM95 66.7113H95.4754V67.1868H95V66.7113ZM95 67.4245V67.8996H95.4754V67.4245H95ZM95.7132 67.4245V67.8996H96.1883V67.4245H95.7132ZM96.1883 67.1868V66.7113H95.7132V67.1868L96.1883 67.1868ZM95.535 66.8747H95.6538V67.2015H95.6238V67.4097H95.6538V67.7364H95.535V67.4097H95.5644V67.2015H95.535V66.8747ZM95.1631 67.2463V67.3651H95.4899V67.2463H95.1631ZM95.6983 67.3651V67.2463H96.0251V67.3651H95.6983ZM96.8871 65.2998H96.4117V65.7753L96.8871 65.7753V65.2998ZM96.4117 66.4881V66.013H96.8871V66.4881H96.4117ZM97.1248 66.4881V66.013H97.6V66.4881H97.1248ZM97.6 65.2998V65.7753L97.1248 65.7753V65.2998H97.6ZM97.0655 65.4632H96.9467V65.79H96.9761V65.9982H96.9467V66.325H97.0655V65.9982H97.0355V65.79H97.0655V65.4632ZM96.5752 65.9536V65.8348H96.902V65.9536H96.5752ZM97.1097 65.8348V65.9536H97.4365V65.8348H97.1097ZM96.4117 66.7114H96.8871V67.1868L96.4117 67.1868V66.7114ZM96.4117 67.4245V67.8997H96.8871V67.4245H96.4117ZM97.1248 67.4245V67.8997H97.6V67.4245H97.1248ZM97.6 67.1868V66.7114H97.1248V67.1868H97.6ZM96.9467 66.8748H97.0655V67.2016H97.0355V67.4097H97.0655V67.7365H96.9467V67.4097H96.9761V67.2016H96.9467V66.8748ZM96.5752 67.2463V67.3651H96.902V67.2463H96.5752ZM97.1097 67.3651V67.2463H97.4365V67.3651H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 65.2998H98.5756V65.7752L98.1001 65.7752V65.2998ZM98.1001 66.0129V66.4881H98.5756V66.0129H98.1001ZM98.8133 66.0129V66.4881H99.2884V66.0129H98.8133ZM99.2884 65.7752V65.2998H98.8133V65.7752L99.2884 65.7752ZM98.6351 65.4632H98.7539V65.79H98.7239V65.9982H98.7539V66.325H98.6351V65.9982H98.6645V65.79H98.6351V65.4632ZM98.2632 65.8348V65.9536H98.59V65.8348H98.2632ZM98.7984 65.9536V65.8348H99.1252V65.9536H98.7984ZM98.1001 66.7113H98.5755V67.1868H98.1001V66.7113ZM98.1001 67.4245V67.8996H98.5755V67.4245H98.1001ZM98.8133 67.4245V67.8996H99.2884V67.4245H98.8133ZM99.2884 67.1868V66.7113H98.8133V67.1868L99.2884 67.1868ZM98.6351 66.8747H98.7539V67.2015H98.7239V67.4097H98.7539V67.7364H98.6351V67.4097H98.6645V67.2015H98.6351V66.8747ZM98.2632 67.2463V67.3651H98.59V67.2463H98.2632ZM98.7984 67.3651V67.2463H99.1252V67.3651H98.7984ZM99.9872 65.2998H99.5118V65.7753L99.9872 65.7753V65.2998ZM99.5118 66.4881V66.013H99.9872V66.4881H99.5118ZM100.225 66.4881V66.013H100.7V66.4881H100.225ZM100.7 65.2998V65.7753L100.225 65.7753V65.2998H100.7ZM100.166 65.4632H100.047V65.79H100.076V65.9982H100.047V66.325H100.166V65.9982H100.136V65.79H100.166V65.4632ZM99.6753 65.9536V65.8348H100.002V65.9536H99.6753ZM100.21 65.8348V65.9536H100.537V65.8348H100.21ZM99.5118 66.7114H99.9872V67.1868L99.5118 67.1868V66.7114ZM99.5118 67.4245V67.8997H99.9872V67.4245H99.5118ZM100.225 67.4245V67.8997H100.7V67.4245H100.225ZM100.7 67.1868V66.7114H100.225V67.1868H100.7ZM100.047 66.8748H100.166V67.2016H100.136V67.4097H100.166V67.7365H100.047V67.4097H100.076V67.2016H100.047V66.8748ZM99.6753 67.2463V67.3651H100.002V67.2463H99.6753ZM100.21 67.3651V67.2463H100.537V67.3651H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 68.4004H89.2753V68.8758L88.7998 68.8758V68.4004ZM88.7998 69.1135V69.5887H89.2753V69.1135H88.7998ZM89.513 69.1135V69.5887H89.9881V69.1135H89.513ZM89.9881 68.8758V68.4004H89.513V68.8758L89.9881 68.8758ZM89.3348 68.5638H89.4536V68.8906H89.4236V69.0988H89.4536V69.4255H89.3348V69.0988H89.3642V68.8906H89.3348V68.5638ZM88.9629 68.9353V69.0542H89.2897V68.9353H88.9629ZM89.4981 69.0542V68.9354H89.8249V69.0542H89.4981ZM88.7998 69.8119H89.2753V70.2874H88.7998V69.8119ZM88.7998 70.5251V71.0002H89.2753V70.5251H88.7998ZM89.513 70.5251V71.0002H89.9881V70.5251H89.513ZM89.9881 70.2874V69.8119H89.513V70.2874L89.9881 70.2874ZM89.3348 69.9753H89.4536V70.3021H89.4236V70.5103H89.4536V70.837H89.3348V70.5103H89.3642V70.3021H89.3348V69.9753ZM88.9629 70.3468V70.4657H89.2897V70.3468H88.9629ZM89.4981 70.4657V70.3469H89.8249V70.4657H89.4981ZM90.6869 68.4004H90.2115V68.8759L90.6869 68.8759V68.4004ZM90.2115 69.5887V69.1136H90.6869V69.5887H90.2115ZM90.9246 69.5887V69.1136H91.3998V69.5887H90.9246ZM91.3998 68.4004V68.8759L90.9246 68.8759V68.4004H91.3998ZM90.8653 68.5638H90.7465V68.8906H90.7759V69.0988H90.7465V69.4256H90.8653V69.0988H90.8353V68.8906H90.8653V68.5638ZM90.375 69.0542V68.9354H90.7018V69.0542H90.375ZM90.9095 68.9354V69.0542H91.2363V68.9354H90.9095ZM90.2115 69.812H90.6869V70.2874L90.2115 70.2874V69.812ZM90.2115 70.5251V71.0003H90.6869V70.5251H90.2115ZM90.9246 70.5251V71.0003H91.3998V70.5251H90.9246ZM91.3998 70.2874V69.812H90.9246V70.2874H91.3998ZM90.7465 69.9754H90.8653V70.3021H90.8353V70.5103H90.8653V70.8371H90.7465V70.5103H90.7759V70.3021H90.7465V69.9754ZM90.375 70.3469V70.4657H90.7018V70.3469H90.375ZM90.9095 70.4657V70.3469H91.2363V70.4657H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 68.4004H92.3754V68.8758L91.8999 68.8758V68.4004ZM91.8999 69.1135V69.5887H92.3754V69.1135H91.8999ZM92.6131 69.1135V69.5887H93.0882V69.1135H92.6131ZM93.0882 68.8758V68.4004H92.6131V68.8758L93.0882 68.8758ZM92.4349 68.5638H92.5537V68.8906H92.5237V69.0988H92.5537V69.4255H92.4349V69.0988H92.4643V68.8906H92.4349V68.5638ZM92.063 68.9353V69.0542H92.3898V68.9353H92.063ZM92.5982 69.0542V68.9354H92.925V69.0542H92.5982ZM91.8999 69.8119H92.3753V70.2874H91.8999V69.8119ZM91.8999 70.5251V71.0002H92.3753V70.5251H91.8999ZM92.6131 70.5251V71.0002H93.0882V70.5251H92.6131ZM93.0882 70.2874V69.8119H92.6131V70.2874L93.0882 70.2874ZM92.4349 69.9753H92.5537V70.3021H92.5237V70.5103H92.5537V70.837H92.4349V70.5103H92.4643V70.3021H92.4349V69.9753ZM92.063 70.3468V70.4657H92.3898V70.3468H92.063ZM92.5982 70.4657V70.3469H92.925V70.4657H92.5982ZM93.787 68.4004H93.3116V68.8759L93.787 68.8759V68.4004ZM93.3116 69.5887V69.1136H93.787V69.5887H93.3116ZM94.0247 69.5887V69.1136H94.4999V69.5887H94.0247ZM94.4999 68.4004V68.8759L94.0247 68.8759V68.4004H94.4999ZM93.9654 68.5638H93.8466V68.8906H93.876V69.0988H93.8466V69.4256H93.9654V69.0988H93.9354V68.8906H93.9654V68.5638ZM93.4751 69.0542V68.9354H93.8019V69.0542H93.4751ZM94.0096 68.9354V69.0542H94.3364V68.9354H94.0096ZM93.3116 69.812H93.787V70.2874L93.3116 70.2874V69.812ZM93.3116 70.5251V71.0003H93.787V70.5251H93.3116ZM94.0247 70.5251V71.0003H94.4999V70.5251H94.0247ZM94.4999 70.2874V69.812H94.0247V70.2874H94.4999ZM93.8466 69.9754H93.9654V70.3021H93.9354V70.5103H93.9654V70.8371H93.8466V70.5103H93.876V70.3021H93.8466V69.9754ZM93.4751 70.3469V70.4657H93.8019V70.3469H93.4751ZM94.0096 70.4657V70.3469H94.3364V70.4657H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 68.4004H95.4755V68.8758L95 68.8758V68.4004ZM95 69.1135V69.5887H95.4755V69.1135H95ZM95.7132 69.1135V69.5887H96.1883V69.1135H95.7132ZM96.1883 68.8758V68.4004H95.7132V68.8758L96.1883 68.8758ZM95.535 68.5638H95.6538V68.8906H95.6238V69.0988H95.6538V69.4255H95.535V69.0988H95.5644V68.8906H95.535V68.5638ZM95.1631 68.9353V69.0542H95.4899V68.9353H95.1631ZM95.6983 69.0542V68.9354H96.0251V69.0542H95.6983ZM95 69.8119H95.4754V70.2874H95V69.8119ZM95 70.5251V71.0002H95.4754V70.5251H95ZM95.7132 70.5251V71.0002H96.1883V70.5251H95.7132ZM96.1883 70.2874V69.8119H95.7132V70.2874L96.1883 70.2874ZM95.535 69.9753H95.6538V70.3021H95.6238V70.5103H95.6538V70.837H95.535V70.5103H95.5644V70.3021H95.535V69.9753ZM95.1631 70.3468V70.4657H95.4899V70.3468H95.1631ZM95.6983 70.4657V70.3469H96.0251V70.4657H95.6983ZM96.8871 68.4004H96.4117V68.8759L96.8871 68.8759V68.4004ZM96.4117 69.5887V69.1136H96.8871V69.5887H96.4117ZM97.1248 69.5887V69.1136H97.6V69.5887H97.1248ZM97.6 68.4004V68.8759L97.1248 68.8759V68.4004H97.6ZM97.0655 68.5638H96.9467V68.8906H96.9761V69.0988H96.9467V69.4256H97.0655V69.0988H97.0355V68.8906H97.0655V68.5638ZM96.5752 69.0542V68.9354H96.902V69.0542H96.5752ZM97.1097 68.9354V69.0542H97.4365V68.9354H97.1097ZM96.4117 69.812H96.8871V70.2874L96.4117 70.2874V69.812ZM96.4117 70.5251V71.0003H96.8871V70.5251H96.4117ZM97.1248 70.5251V71.0003H97.6V70.5251H97.1248ZM97.6 70.2874V69.812H97.1248V70.2874H97.6ZM96.9467 69.9754H97.0655V70.3021H97.0355V70.5103H97.0655V70.8371H96.9467V70.5103H96.9761V70.3021H96.9467V69.9754ZM96.5752 70.3469V70.4657H96.902V70.3469H96.5752ZM97.1097 70.4657V70.3469H97.4365V70.4657H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 68.4004H98.5756V68.8758L98.1001 68.8758V68.4004ZM98.1001 69.1135V69.5887H98.5756V69.1135H98.1001ZM98.8133 69.1135V69.5887H99.2884V69.1135H98.8133ZM99.2884 68.8758V68.4004H98.8133V68.8758L99.2884 68.8758ZM98.6351 68.5638H98.7539V68.8906H98.7239V69.0988H98.7539V69.4255H98.6351V69.0988H98.6645V68.8906H98.6351V68.5638ZM98.2632 68.9353V69.0542H98.59V68.9353H98.2632ZM98.7984 69.0542V68.9354H99.1252V69.0542H98.7984ZM98.1001 69.8119H98.5755V70.2874H98.1001V69.8119ZM98.1001 70.5251V71.0002H98.5755V70.5251H98.1001ZM98.8133 70.5251V71.0002H99.2884V70.5251H98.8133ZM99.2884 70.2874V69.8119H98.8133V70.2874L99.2884 70.2874ZM98.6351 69.9753H98.7539V70.3021H98.7239V70.5103H98.7539V70.837H98.6351V70.5103H98.6645V70.3021H98.6351V69.9753ZM98.2632 70.3468V70.4657H98.59V70.3468H98.2632ZM98.7984 70.4657V70.3469H99.1252V70.4657H98.7984ZM99.9872 68.4004H99.5118V68.8759L99.9872 68.8759V68.4004ZM99.5118 69.5887V69.1136H99.9872V69.5887H99.5118ZM100.225 69.5887V69.1136H100.7V69.5887H100.225ZM100.7 68.4004V68.8759L100.225 68.8759V68.4004H100.7ZM100.166 68.5638H100.047V68.8906H100.076V69.0988H100.047V69.4256H100.166V69.0988H100.136V68.8906H100.166V68.5638ZM99.6753 69.0542V68.9354H100.002V69.0542H99.6753ZM100.21 68.9354V69.0542H100.537V68.9354H100.21ZM99.5118 69.812H99.9872V70.2874L99.5118 70.2874V69.812ZM99.5118 70.5251V71.0003H99.9872V70.5251H99.5118ZM100.225 70.5251V71.0003H100.7V70.5251H100.225ZM100.7 70.2874V69.812H100.225V70.2874H100.7ZM100.047 69.9754H100.166V70.3021H100.136V70.5103H100.166V70.8371H100.047V70.5103H100.076V70.3021H100.047V69.9754ZM99.6753 70.3469V70.4657H100.002V70.3469H99.6753ZM100.21 70.4657V70.3469H100.537V70.4657H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 71.5H89.2753V71.9754L88.7998 71.9754V71.5ZM88.7998 72.2131V72.6883H89.2753V72.2131H88.7998ZM89.513 72.2131V72.6883H89.9881V72.2131H89.513ZM89.9881 71.9754V71.5H89.513V71.9754L89.9881 71.9754ZM89.3348 71.6634H89.4536V71.9902H89.4236V72.1984H89.4536V72.5251H89.3348V72.1984H89.3642V71.9902H89.3348V71.6634ZM88.9629 72.035V72.1538H89.2897V72.035H88.9629ZM89.4981 72.1538V72.035H89.8249V72.1538H89.4981ZM88.7998 72.9115H89.2753V73.387H88.7998V72.9115ZM88.7998 73.6247V74.0998H89.2753V73.6247H88.7998ZM89.513 73.6247V74.0998H89.9881V73.6247H89.513ZM89.9881 73.387V72.9115H89.513V73.387L89.9881 73.387ZM89.3348 73.0749H89.4536V73.4017H89.4236V73.6099H89.4536V73.9366H89.3348V73.6099H89.3642V73.4017H89.3348V73.0749ZM88.9629 73.4465V73.5653H89.2897V73.4465H88.9629ZM89.4981 73.5653V73.4465H89.8249V73.5653H89.4981ZM90.6869 71.5H90.2115V71.9755L90.6869 71.9755V71.5ZM90.2115 72.6883V72.2132H90.6869V72.6883H90.2115ZM90.9246 72.6883V72.2132H91.3998V72.6883H90.9246ZM91.3998 71.5V71.9755L90.9246 71.9755V71.5H91.3998ZM90.8653 71.6634H90.7465V71.9902H90.7759V72.1984H90.7465V72.5252H90.8653V72.1984H90.8353V71.9902H90.8653V71.6634ZM90.375 72.1538V72.035H90.7018V72.1538H90.375ZM90.9095 72.035V72.1538H91.2363V72.035H90.9095ZM90.2115 72.9116H90.6869V73.387L90.2115 73.387V72.9116ZM90.2115 73.6247V74.0999H90.6869V73.6247H90.2115ZM90.9246 73.6247V74.0999H91.3998V73.6247H90.9246ZM91.3998 73.387V72.9116H90.9246V73.387H91.3998ZM90.7465 73.075H90.8653V73.4018H90.8353V73.6099H90.8653V73.9367H90.7465V73.6099H90.7759V73.4018H90.7465V73.075ZM90.375 73.4465V73.5653H90.7018V73.4465H90.375ZM90.9095 73.5653V73.4465H91.2363V73.5653H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 71.5H92.3754V71.9754L91.8999 71.9754V71.5ZM91.8999 72.2131V72.6883H92.3754V72.2131H91.8999ZM92.6131 72.2131V72.6883H93.0882V72.2131H92.6131ZM93.0882 71.9754V71.5H92.6131V71.9754L93.0882 71.9754ZM92.4349 71.6634H92.5537V71.9902H92.5237V72.1984H92.5537V72.5251H92.4349V72.1984H92.4643V71.9902H92.4349V71.6634ZM92.063 72.035V72.1538H92.3898V72.035H92.063ZM92.5982 72.1538V72.035H92.925V72.1538H92.5982ZM91.8999 72.9115H92.3753V73.387H91.8999V72.9115ZM91.8999 73.6247V74.0998H92.3753V73.6247H91.8999ZM92.6131 73.6247V74.0998H93.0882V73.6247H92.6131ZM93.0882 73.387V72.9115H92.6131V73.387L93.0882 73.387ZM92.4349 73.0749H92.5537V73.4017H92.5237V73.6099H92.5537V73.9366H92.4349V73.6099H92.4643V73.4017H92.4349V73.0749ZM92.063 73.4465V73.5653H92.3898V73.4465H92.063ZM92.5982 73.5653V73.4465H92.925V73.5653H92.5982ZM93.787 71.5H93.3116V71.9755L93.787 71.9755V71.5ZM93.3116 72.6883V72.2132H93.787V72.6883H93.3116ZM94.0247 72.6883V72.2132H94.4999V72.6883H94.0247ZM94.4999 71.5V71.9755L94.0247 71.9755V71.5H94.4999ZM93.9654 71.6634H93.8466V71.9902H93.876V72.1984H93.8466V72.5252H93.9654V72.1984H93.9354V71.9902H93.9654V71.6634ZM93.4751 72.1538V72.035H93.8019V72.1538H93.4751ZM94.0096 72.035V72.1538H94.3364V72.035H94.0096ZM93.3116 72.9116H93.787V73.387L93.3116 73.387V72.9116ZM93.3116 73.6247V74.0999H93.787V73.6247H93.3116ZM94.0247 73.6247V74.0999H94.4999V73.6247H94.0247ZM94.4999 73.387V72.9116H94.0247V73.387H94.4999ZM93.8466 73.075H93.9654V73.4018H93.9354V73.6099H93.9654V73.9367H93.8466V73.6099H93.876V73.4018H93.8466V73.075ZM93.4751 73.4465V73.5653H93.8019V73.4465H93.4751ZM94.0096 73.5653V73.4465H94.3364V73.5653H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 71.5H95.4755V71.9754L95 71.9754V71.5ZM95 72.2131V72.6883H95.4755V72.2131H95ZM95.7132 72.2131V72.6883H96.1883V72.2131H95.7132ZM96.1883 71.9754V71.5H95.7132V71.9754L96.1883 71.9754ZM95.535 71.6634H95.6538V71.9902H95.6238V72.1984H95.6538V72.5251H95.535V72.1984H95.5644V71.9902H95.535V71.6634ZM95.1631 72.035V72.1538H95.4899V72.035H95.1631ZM95.6983 72.1538V72.035H96.0251V72.1538H95.6983ZM95 72.9115H95.4754V73.387H95V72.9115ZM95 73.6247V74.0998H95.4754V73.6247H95ZM95.7132 73.6247V74.0998H96.1883V73.6247H95.7132ZM96.1883 73.387V72.9115H95.7132V73.387L96.1883 73.387ZM95.535 73.0749H95.6538V73.4017H95.6238V73.6099H95.6538V73.9366H95.535V73.6099H95.5644V73.4017H95.535V73.0749ZM95.1631 73.4465V73.5653H95.4899V73.4465H95.1631ZM95.6983 73.5653V73.4465H96.0251V73.5653H95.6983ZM96.8871 71.5H96.4117V71.9755L96.8871 71.9755V71.5ZM96.4117 72.6883V72.2132H96.8871V72.6883H96.4117ZM97.1248 72.6883V72.2132H97.6V72.6883H97.1248ZM97.6 71.5V71.9755L97.1248 71.9755V71.5H97.6ZM97.0655 71.6634H96.9467V71.9902H96.9761V72.1984H96.9467V72.5252H97.0655V72.1984H97.0355V71.9902H97.0655V71.6634ZM96.5752 72.1538V72.035H96.902V72.1538H96.5752ZM97.1097 72.035V72.1538H97.4365V72.035H97.1097ZM96.4117 72.9116H96.8871V73.387L96.4117 73.387V72.9116ZM96.4117 73.6247V74.0999H96.8871V73.6247H96.4117ZM97.1248 73.6247V74.0999H97.6V73.6247H97.1248ZM97.6 73.387V72.9116H97.1248V73.387H97.6ZM96.9467 73.075H97.0655V73.4018H97.0355V73.6099H97.0655V73.9367H96.9467V73.6099H96.9761V73.4018H96.9467V73.075ZM96.5752 73.4465V73.5653H96.902V73.4465H96.5752ZM97.1097 73.5653V73.4465H97.4365V73.5653H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 71.5H98.5756V71.9754L98.1001 71.9754V71.5ZM98.1001 72.2131V72.6883H98.5756V72.2131H98.1001ZM98.8133 72.2131V72.6883H99.2884V72.2131H98.8133ZM99.2884 71.9754V71.5H98.8133V71.9754L99.2884 71.9754ZM98.6351 71.6634H98.7539V71.9902H98.7239V72.1984H98.7539V72.5251H98.6351V72.1984H98.6645V71.9902H98.6351V71.6634ZM98.2632 72.035V72.1538H98.59V72.035H98.2632ZM98.7984 72.1538V72.035H99.1252V72.1538H98.7984ZM98.1001 72.9115H98.5755V73.387H98.1001V72.9115ZM98.1001 73.6247V74.0998H98.5755V73.6247H98.1001ZM98.8133 73.6247V74.0998H99.2884V73.6247H98.8133ZM99.2884 73.387V72.9115H98.8133V73.387L99.2884 73.387ZM98.6351 73.0749H98.7539V73.4017H98.7239V73.6099H98.7539V73.9366H98.6351V73.6099H98.6645V73.4017H98.6351V73.0749ZM98.2632 73.4465V73.5653H98.59V73.4465H98.2632ZM98.7984 73.5653V73.4465H99.1252V73.5653H98.7984ZM99.9872 71.5H99.5118V71.9755L99.9872 71.9755V71.5ZM99.5118 72.6883V72.2132H99.9872V72.6883H99.5118ZM100.225 72.6883V72.2132H100.7V72.6883H100.225ZM100.7 71.5V71.9755L100.225 71.9755V71.5H100.7ZM100.166 71.6634H100.047V71.9902H100.076V72.1984H100.047V72.5252H100.166V72.1984H100.136V71.9902H100.166V71.6634ZM99.6753 72.1538V72.035H100.002V72.1538H99.6753ZM100.21 72.035V72.1538H100.537V72.035H100.21ZM99.5118 72.9116H99.9872V73.387L99.5118 73.387V72.9116ZM99.5118 73.6247V74.0999H99.9872V73.6247H99.5118ZM100.225 73.6247V74.0999H100.7V73.6247H100.225ZM100.7 73.387V72.9116H100.225V73.387H100.7ZM100.047 73.075H100.166V73.4018H100.136V73.6099H100.166V73.9367H100.047V73.6099H100.076V73.4018H100.047V73.075ZM99.6753 73.4465V73.5653H100.002V73.4465H99.6753ZM100.21 73.5653V73.4465H100.537V73.5653H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 74.5996H89.2753V75.075L88.7998 75.075V74.5996ZM88.7998 75.3127V75.7879H89.2753V75.3127H88.7998ZM89.513 75.3127V75.7879H89.9881V75.3127H89.513ZM89.9881 75.075V74.5996H89.513V75.075L89.9881 75.075ZM89.3348 74.763H89.4536V75.0898H89.4236V75.298H89.4536V75.6248H89.3348V75.298H89.3642V75.0898H89.3348V74.763ZM88.9629 75.1346V75.2534H89.2897V75.1346H88.9629ZM89.4981 75.2534V75.1346H89.8249V75.2534H89.4981ZM88.7998 76.0112H89.2753V76.4866H88.7998V76.0112ZM88.7998 76.7243V77.1994H89.2753V76.7243H88.7998ZM89.513 76.7243V77.1994H89.9881V76.7243H89.513ZM89.9881 76.4866V76.0112H89.513V76.4866L89.9881 76.4866ZM89.3348 76.1746H89.4536V76.5013H89.4236V76.7095H89.4536V77.0363H89.3348V76.7095H89.3642V76.5013H89.3348V76.1746ZM88.9629 76.5461V76.6649H89.2897V76.5461H88.9629ZM89.4981 76.6649V76.5461H89.8249V76.6649H89.4981ZM90.6869 74.5996H90.2115V75.0751L90.6869 75.0751V74.5996ZM90.2115 75.7879V75.3128H90.6869V75.7879H90.2115ZM90.9246 75.7879V75.3128H91.3998V75.7879H90.9246ZM91.3998 74.5996V75.0751L90.9246 75.0751V74.5996H91.3998ZM90.8653 74.763H90.7465V75.0898H90.7759V75.298H90.7465V75.6248H90.8653V75.298H90.8353V75.0898H90.8653V74.763ZM90.375 75.2534V75.1346H90.7018V75.2534H90.375ZM90.9095 75.1346V75.2534H91.2363V75.1346H90.9095ZM90.2115 76.0112H90.6869V76.4866L90.2115 76.4866V76.0112ZM90.2115 76.7243V77.1995H90.6869V76.7243H90.2115ZM90.9246 76.7243V77.1995H91.3998V76.7243H90.9246ZM91.3998 76.4866V76.0112H90.9246V76.4866H91.3998ZM90.7465 76.1746H90.8653V76.5014H90.8353V76.7095H90.8653V77.0363H90.7465V76.7095H90.7759V76.5014H90.7465V76.1746ZM90.375 76.5461V76.6649H90.7018V76.5461H90.375ZM90.9095 76.6649V76.5461H91.2363V76.6649H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 74.5996H92.3754V75.075L91.8999 75.075V74.5996ZM91.8999 75.3127V75.7879H92.3754V75.3127H91.8999ZM92.6131 75.3127V75.7879H93.0882V75.3127H92.6131ZM93.0882 75.075V74.5996H92.6131V75.075L93.0882 75.075ZM92.4349 74.763H92.5537V75.0898H92.5237V75.298H92.5537V75.6248H92.4349V75.298H92.4643V75.0898H92.4349V74.763ZM92.063 75.1346V75.2534H92.3898V75.1346H92.063ZM92.5982 75.2534V75.1346H92.925V75.2534H92.5982ZM91.8999 76.0112H92.3753V76.4866H91.8999V76.0112ZM91.8999 76.7243V77.1994H92.3753V76.7243H91.8999ZM92.6131 76.7243V77.1994H93.0882V76.7243H92.6131ZM93.0882 76.4866V76.0112H92.6131V76.4866L93.0882 76.4866ZM92.4349 76.1746H92.5537V76.5013H92.5237V76.7095H92.5537V77.0363H92.4349V76.7095H92.4643V76.5013H92.4349V76.1746ZM92.063 76.5461V76.6649H92.3898V76.5461H92.063ZM92.5982 76.6649V76.5461H92.925V76.6649H92.5982ZM93.787 74.5996H93.3116V75.0751L93.787 75.0751V74.5996ZM93.3116 75.7879V75.3128H93.787V75.7879H93.3116ZM94.0247 75.7879V75.3128H94.4999V75.7879H94.0247ZM94.4999 74.5996V75.0751L94.0247 75.0751V74.5996H94.4999ZM93.9654 74.763H93.8466V75.0898H93.876V75.298H93.8466V75.6248H93.9654V75.298H93.9354V75.0898H93.9654V74.763ZM93.4751 75.2534V75.1346H93.8019V75.2534H93.4751ZM94.0096 75.1346V75.2534H94.3364V75.1346H94.0096ZM93.3116 76.0112H93.787V76.4866L93.3116 76.4866V76.0112ZM93.3116 76.7243V77.1995H93.787V76.7243H93.3116ZM94.0247 76.7243V77.1995H94.4999V76.7243H94.0247ZM94.4999 76.4866V76.0112H94.0247V76.4866H94.4999ZM93.8466 76.1746H93.9654V76.5014H93.9354V76.7095H93.9654V77.0363H93.8466V76.7095H93.876V76.5014H93.8466V76.1746ZM93.4751 76.5461V76.6649H93.8019V76.5461H93.4751ZM94.0096 76.6649V76.5461H94.3364V76.6649H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 74.5996H95.4755V75.075L95 75.075V74.5996ZM95 75.3127V75.7879H95.4755V75.3127H95ZM95.7132 75.3127V75.7879H96.1883V75.3127H95.7132ZM96.1883 75.075V74.5996H95.7132V75.075L96.1883 75.075ZM95.535 74.763H95.6538V75.0898H95.6238V75.298H95.6538V75.6248H95.535V75.298H95.5644V75.0898H95.535V74.763ZM95.1631 75.1346V75.2534H95.4899V75.1346H95.1631ZM95.6983 75.2534V75.1346H96.0251V75.2534H95.6983ZM95 76.0112H95.4754V76.4866H95V76.0112ZM95 76.7243V77.1994H95.4754V76.7243H95ZM95.7132 76.7243V77.1994H96.1883V76.7243H95.7132ZM96.1883 76.4866V76.0112H95.7132V76.4866L96.1883 76.4866ZM95.535 76.1746H95.6538V76.5013H95.6238V76.7095H95.6538V77.0363H95.535V76.7095H95.5644V76.5013H95.535V76.1746ZM95.1631 76.5461V76.6649H95.4899V76.5461H95.1631ZM95.6983 76.6649V76.5461H96.0251V76.6649H95.6983ZM96.8871 74.5996H96.4117V75.0751L96.8871 75.0751V74.5996ZM96.4117 75.7879V75.3128H96.8871V75.7879H96.4117ZM97.1248 75.7879V75.3128H97.6V75.7879H97.1248ZM97.6 74.5996V75.0751L97.1248 75.0751V74.5996H97.6ZM97.0655 74.763H96.9467V75.0898H96.9761V75.298H96.9467V75.6248H97.0655V75.298H97.0355V75.0898H97.0655V74.763ZM96.5752 75.2534V75.1346H96.902V75.2534H96.5752ZM97.1097 75.1346V75.2534H97.4365V75.1346H97.1097ZM96.4117 76.0112H96.8871V76.4866L96.4117 76.4866V76.0112ZM96.4117 76.7243V77.1995H96.8871V76.7243H96.4117ZM97.1248 76.7243V77.1995H97.6V76.7243H97.1248ZM97.6 76.4866V76.0112H97.1248V76.4866H97.6ZM96.9467 76.1746H97.0655V76.5014H97.0355V76.7095H97.0655V77.0363H96.9467V76.7095H96.9761V76.5014H96.9467V76.1746ZM96.5752 76.5461V76.6649H96.902V76.5461H96.5752ZM97.1097 76.6649V76.5461H97.4365V76.6649H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 74.5996H98.5756V75.075L98.1001 75.075V74.5996ZM98.1001 75.3127V75.7879H98.5756V75.3127H98.1001ZM98.8133 75.3127V75.7879H99.2884V75.3127H98.8133ZM99.2884 75.075V74.5996H98.8133V75.075L99.2884 75.075ZM98.6351 74.763H98.7539V75.0898H98.7239V75.298H98.7539V75.6248H98.6351V75.298H98.6645V75.0898H98.6351V74.763ZM98.2632 75.1346V75.2534H98.59V75.1346H98.2632ZM98.7984 75.2534V75.1346H99.1252V75.2534H98.7984ZM98.1001 76.0112H98.5755V76.4866H98.1001V76.0112ZM98.1001 76.7243V77.1994H98.5755V76.7243H98.1001ZM98.8133 76.7243V77.1994H99.2884V76.7243H98.8133ZM99.2884 76.4866V76.0112H98.8133V76.4866L99.2884 76.4866ZM98.6351 76.1746H98.7539V76.5013H98.7239V76.7095H98.7539V77.0363H98.6351V76.7095H98.6645V76.5013H98.6351V76.1746ZM98.2632 76.5461V76.6649H98.59V76.5461H98.2632ZM98.7984 76.6649V76.5461H99.1252V76.6649H98.7984ZM99.9872 74.5996H99.5118V75.0751L99.9872 75.0751V74.5996ZM99.5118 75.7879V75.3128H99.9872V75.7879H99.5118ZM100.225 75.7879V75.3128H100.7V75.7879H100.225ZM100.7 74.5996V75.0751L100.225 75.0751V74.5996H100.7ZM100.166 74.763H100.047V75.0898H100.076V75.298H100.047V75.6248H100.166V75.298H100.136V75.0898H100.166V74.763ZM99.6753 75.2534V75.1346H100.002V75.2534H99.6753ZM100.21 75.1346V75.2534H100.537V75.1346H100.21ZM99.5118 76.0112H99.9872V76.4866L99.5118 76.4866V76.0112ZM99.5118 76.7243V77.1995H99.9872V76.7243H99.5118ZM100.225 76.7243V77.1995H100.7V76.7243H100.225ZM100.7 76.4866V76.0112H100.225V76.4866H100.7ZM100.047 76.1746H100.166V76.5014H100.136V76.7095H100.166V77.0363H100.047V76.7095H100.076V76.5014H100.047V76.1746ZM99.6753 76.5461V76.6649H100.002V76.5461H99.6753ZM100.21 76.6649V76.5461H100.537V76.6649H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 77.7998H51.7753V78.2752L51.2998 78.2752V77.7998ZM51.2998 78.5129V78.9881H51.7753V78.5129H51.2998ZM52.013 78.5129V78.9881H52.4881V78.5129H52.013ZM52.4881 78.2752V77.7998H52.013V78.2752L52.4881 78.2752ZM51.8348 77.9632H51.9536V78.29H51.9236V78.4982H51.9536V78.825H51.8348V78.4982H51.8642V78.29H51.8348V77.9632ZM51.4629 78.3348V78.4536H51.7897V78.3348H51.4629ZM51.9981 78.4536V78.3348H52.3249V78.4536H51.9981ZM51.2998 79.2113H51.7753V79.6868H51.2998V79.2113ZM51.2998 79.9245V80.3996H51.7753V79.9245H51.2998ZM52.013 79.9245V80.3996H52.4881V79.9245H52.013ZM52.4881 79.6868V79.2113H52.013V79.6868H52.4881ZM51.8348 79.3747H51.9536V79.7015H51.9236V79.9097H51.9536V80.2364H51.8348V79.9097H51.8642V79.7015H51.8348V79.3747ZM51.4629 79.7463V79.8651H51.7897V79.7463H51.4629ZM51.9981 79.8651V79.7463H52.3249V79.8651H51.9981ZM53.1869 77.7998H52.7115V78.2753L53.1869 78.2753V77.7998ZM52.7115 78.9881V78.513H53.1869V78.9881H52.7115ZM53.4246 78.9881V78.513H53.8998V78.9881H53.4246ZM53.8998 77.7998V78.2753L53.4246 78.2753V77.7998H53.8998ZM53.3653 77.9632H53.2465V78.29H53.2759V78.4982H53.2465V78.825H53.3653V78.4982H53.3353V78.29H53.3653V77.9632ZM52.875 78.4536V78.3348H53.2018V78.4536H52.875ZM53.4095 78.3348V78.4536H53.7363V78.3348H53.4095ZM52.7115 79.2114H53.1869V79.6868H52.7115V79.2114ZM52.7115 79.9245V80.3997H53.1869V79.9245H52.7115ZM53.4246 79.9245V80.3997H53.8998V79.9245H53.4246ZM53.8998 79.6868V79.2114H53.4246V79.6868H53.8998ZM53.2465 79.3748H53.3653V79.7016H53.3353V79.9097H53.3653V80.2365H53.2465V79.9097H53.2759V79.7016H53.2465V79.3748ZM52.875 79.7463V79.8651H53.2018V79.7463H52.875ZM53.4095 79.8651V79.7463H53.7363V79.8651H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 77.7998H54.8754V78.2752L54.3999 78.2752V77.7998ZM54.3999 78.5129V78.9881H54.8754V78.5129H54.3999ZM55.1131 78.5129V78.9881H55.5882V78.5129H55.1131ZM55.5882 78.2752V77.7998H55.1131V78.2752L55.5882 78.2752ZM54.9349 77.9632H55.0537V78.29H55.0237V78.4982H55.0537V78.825H54.9349V78.4982H54.9643V78.29H54.9349V77.9632ZM54.563 78.3348V78.4536H54.8898V78.3348H54.563ZM55.0982 78.4536V78.3348H55.425V78.4536H55.0982ZM54.3999 79.2113H54.8753V79.6868H54.3999V79.2113ZM54.3999 79.9245V80.3996H54.8753V79.9245H54.3999ZM55.1131 79.9245V80.3996H55.5882V79.9245H55.1131ZM55.5882 79.6868V79.2113H55.1131V79.6868L55.5882 79.6868ZM54.9349 79.3747H55.0537V79.7015H55.0237V79.9097H55.0537V80.2364H54.9349V79.9097H54.9643V79.7015H54.9349V79.3747ZM54.563 79.7463V79.8651H54.8898V79.7463H54.563ZM55.0982 79.8651V79.7463H55.425V79.8651H55.0982ZM56.287 77.7998H55.8116V78.2753L56.287 78.2753V77.7998ZM55.8116 78.9881V78.513H56.287V78.9881H55.8116ZM56.5247 78.9881V78.513H56.9999V78.9881H56.5247ZM56.9999 77.7998V78.2753L56.5247 78.2753V77.7998H56.9999ZM56.4654 77.9632H56.3466V78.29H56.376V78.4982H56.3466V78.825H56.4654V78.4982H56.4354V78.29H56.4654V77.9632ZM55.9751 78.4536V78.3348H56.3019V78.4536H55.9751ZM56.5096 78.3348V78.4536H56.8364V78.3348H56.5096ZM55.8116 79.2114H56.287V79.6868L55.8116 79.6868V79.2114ZM55.8116 79.9245V80.3997H56.287V79.9245H55.8116ZM56.5247 79.9245V80.3997H56.9999V79.9245H56.5247ZM56.9999 79.6868V79.2114H56.5247V79.6868H56.9999ZM56.3466 79.3748H56.4654V79.7016H56.4354V79.9097H56.4654V80.2365H56.3466V79.9097H56.376V79.7016H56.3466V79.3748ZM55.9751 79.7463V79.8651H56.3019V79.7463H55.9751ZM56.5096 79.8651V79.7463H56.8364V79.8651H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 77.7998H57.9755V78.2752L57.5 78.2752V77.7998ZM57.5 78.5129V78.9881H57.9755V78.5129H57.5ZM58.2132 78.5129V78.9881H58.6883V78.5129H58.2132ZM58.6883 78.2752V77.7998H58.2132V78.2752L58.6883 78.2752ZM58.035 77.9632H58.1538V78.29H58.1238V78.4982H58.1538V78.825H58.035V78.4982H58.0644V78.29H58.035V77.9632ZM57.6631 78.3348V78.4536H57.9899V78.3348H57.6631ZM58.1983 78.4536V78.3348H58.5251V78.4536H58.1983ZM57.5 79.2113H57.9754V79.6868H57.5V79.2113ZM57.5 79.9245V80.3996H57.9754V79.9245H57.5ZM58.2132 79.9245V80.3996H58.6883V79.9245H58.2132ZM58.6883 79.6868V79.2113H58.2132V79.6868L58.6883 79.6868ZM58.035 79.3747H58.1538V79.7015H58.1238V79.9097H58.1538V80.2364H58.035V79.9097H58.0644V79.7015H58.035V79.3747ZM57.6631 79.7463V79.8651H57.9899V79.7463H57.6631ZM58.1983 79.8651V79.7463H58.5251V79.8651H58.1983ZM59.3871 77.7998H58.9117V78.2753L59.3871 78.2753V77.7998ZM58.9117 78.9881V78.513H59.3871V78.9881H58.9117ZM59.6248 78.9881V78.513H60.1V78.9881H59.6248ZM60.1 77.7998V78.2753L59.6248 78.2753V77.7998H60.1ZM59.5655 77.9632H59.4467V78.29H59.4761V78.4982H59.4467V78.825H59.5655V78.4982H59.5355V78.29H59.5655V77.9632ZM59.0752 78.4536V78.3348H59.402V78.4536H59.0752ZM59.6097 78.3348V78.4536H59.9365V78.3348H59.6097ZM58.9117 79.2114H59.3871V79.6868L58.9117 79.6868V79.2114ZM58.9117 79.9245V80.3997H59.3871V79.9245H58.9117ZM59.6248 79.9245V80.3997H60.1V79.9245H59.6248ZM60.1 79.6868V79.2114H59.6248V79.6868H60.1ZM59.4467 79.3748H59.5655V79.7016H59.5355V79.9097H59.5655V80.2365H59.4467V79.9097H59.4761V79.7016H59.4467V79.3748ZM59.0752 79.7463V79.8651H59.402V79.7463H59.0752ZM59.6097 79.8651V79.7463H59.9365V79.8651H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 77.7998H61.0756V78.2752L60.6001 78.2752V77.7998ZM60.6001 78.5129V78.9881H61.0756V78.5129H60.6001ZM61.3133 78.5129V78.9881H61.7884V78.5129H61.3133ZM61.7884 78.2752V77.7998H61.3133V78.2752L61.7884 78.2752ZM61.1351 77.9632H61.2539V78.29H61.2239V78.4982H61.2539V78.825H61.1351V78.4982H61.1645V78.29H61.1351V77.9632ZM60.7632 78.3348V78.4536H61.09V78.3348H60.7632ZM61.2984 78.4536V78.3348H61.6252V78.4536H61.2984ZM60.6001 79.2113H61.0755V79.6868H60.6001V79.2113ZM60.6001 79.9245V80.3996H61.0755V79.9245H60.6001ZM61.3133 79.9245V80.3996H61.7884V79.9245H61.3133ZM61.7884 79.6868V79.2113H61.3133V79.6868L61.7884 79.6868ZM61.1351 79.3747H61.2539V79.7015H61.2239V79.9097H61.2539V80.2364H61.1351V79.9097H61.1645V79.7015H61.1351V79.3747ZM60.7632 79.7463V79.8651H61.09V79.7463H60.7632ZM61.2984 79.8651V79.7463H61.6252V79.8651H61.2984ZM62.4872 77.7998H62.0118V78.2753L62.4872 78.2753V77.7998ZM62.0118 78.9881V78.513H62.4872V78.9881H62.0118ZM62.7249 78.9881V78.513H63.2001V78.9881H62.7249ZM63.2001 77.7998V78.2753L62.7249 78.2753V77.7998H63.2001ZM62.6656 77.9632H62.5468V78.29H62.5762V78.4982H62.5468V78.825H62.6656V78.4982H62.6356V78.29H62.6656V77.9632ZM62.1753 78.4536V78.3348H62.5021V78.4536H62.1753ZM62.7098 78.3348V78.4536H63.0366V78.3348H62.7098ZM62.0118 79.2114H62.4872V79.6868L62.0118 79.6868V79.2114ZM62.0118 79.9245V80.3997H62.4872V79.9245H62.0118ZM62.7249 79.9245V80.3997H63.2001V79.9245H62.7249ZM63.2001 79.6868V79.2114H62.7249V79.6868H63.2001ZM62.5468 79.3748H62.6656V79.7016H62.6356V79.9097H62.6656V80.2365H62.5468V79.9097H62.5762V79.7016H62.5468V79.3748ZM62.1753 79.7463V79.8651H62.5021V79.7463H62.1753ZM62.7098 79.8651V79.7463H63.0366V79.8651H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 80.9004H51.7753V81.3758L51.2998 81.3758V80.9004ZM51.2998 81.6135V82.0887H51.7753V81.6135H51.2998ZM52.013 81.6135V82.0887H52.4881V81.6135H52.013ZM52.4881 81.3758V80.9004H52.013V81.3758L52.4881 81.3758ZM51.8348 81.0638H51.9536V81.3906H51.9236V81.5988H51.9536V81.9255H51.8348V81.5988H51.8642V81.3906H51.8348V81.0638ZM51.4629 81.4353V81.5542H51.7897V81.4353H51.4629ZM51.9981 81.5542V81.4354H52.3249V81.5542H51.9981ZM51.2998 82.3119H51.7753V82.7874H51.2998V82.3119ZM51.2998 83.0251V83.5002H51.7753V83.0251H51.2998ZM52.013 83.0251V83.5002H52.4881V83.0251H52.013ZM52.4881 82.7874V82.3119H52.013V82.7874L52.4881 82.7874ZM51.8348 82.4753H51.9536V82.8021H51.9236V83.0103H51.9536V83.337H51.8348V83.0103H51.8642V82.8021H51.8348V82.4753ZM51.4629 82.8468V82.9657H51.7897V82.8468H51.4629ZM51.9981 82.9657V82.8469H52.3249V82.9657H51.9981ZM53.1869 80.9004H52.7115V81.3759L53.1869 81.3759V80.9004ZM52.7115 82.0887V81.6136H53.1869V82.0887H52.7115ZM53.4246 82.0887V81.6136H53.8998V82.0887H53.4246ZM53.8998 80.9004V81.3759L53.4246 81.3759V80.9004H53.8998ZM53.3653 81.0638H53.2465V81.3906H53.2759V81.5988H53.2465V81.9256H53.3653V81.5988H53.3353V81.3906H53.3653V81.0638ZM52.875 81.5542V81.4354H53.2018V81.5542H52.875ZM53.4095 81.4354V81.5542H53.7363V81.4354H53.4095ZM52.7115 82.312H53.1869V82.7874L52.7115 82.7874V82.312ZM52.7115 83.0251V83.5003H53.1869V83.0251H52.7115ZM53.4246 83.0251V83.5003H53.8998V83.0251H53.4246ZM53.8998 82.7874V82.312H53.4246V82.7874H53.8998ZM53.2465 82.4754H53.3653V82.8021H53.3353V83.0103H53.3653V83.3371H53.2465V83.0103H53.2759V82.8021H53.2465V82.4754ZM52.875 82.8469V82.9657H53.2018V82.8469H52.875ZM53.4095 82.9657V82.8469H53.7363V82.9657H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 80.9004H54.8754V81.3758L54.3999 81.3758V80.9004ZM54.3999 81.6135V82.0887H54.8754V81.6135H54.3999ZM55.1131 81.6135V82.0887H55.5882V81.6135H55.1131ZM55.5882 81.3758V80.9004H55.1131V81.3758L55.5882 81.3758ZM54.9349 81.0638H55.0537V81.3906H55.0237V81.5988H55.0537V81.9255H54.9349V81.5988H54.9643V81.3906H54.9349V81.0638ZM54.563 81.4353V81.5542H54.8898V81.4353H54.563ZM55.0982 81.5542V81.4354H55.425V81.5542H55.0982ZM54.3999 82.3119H54.8753V82.7874H54.3999V82.3119ZM54.3999 83.0251V83.5002H54.8753V83.0251H54.3999ZM55.1131 83.0251V83.5002H55.5882V83.0251H55.1131ZM55.5882 82.7874V82.3119H55.1131V82.7874L55.5882 82.7874ZM54.9349 82.4753H55.0537V82.8021H55.0237V83.0103H55.0537V83.337H54.9349V83.0103H54.9643V82.8021H54.9349V82.4753ZM54.563 82.8468V82.9657H54.8898V82.8468H54.563ZM55.0982 82.9657V82.8469H55.425V82.9657H55.0982ZM56.287 80.9004H55.8116V81.3759L56.287 81.3759V80.9004ZM55.8116 82.0887V81.6136H56.287V82.0887H55.8116ZM56.5247 82.0887V81.6136H56.9999V82.0887H56.5247ZM56.9999 80.9004V81.3759L56.5247 81.3759V80.9004H56.9999ZM56.4654 81.0638H56.3466V81.3906H56.376V81.5988H56.3466V81.9256H56.4654V81.5988H56.4354V81.3906H56.4654V81.0638ZM55.9751 81.5542V81.4354H56.3019V81.5542H55.9751ZM56.5096 81.4354V81.5542H56.8364V81.4354H56.5096ZM55.8116 82.312H56.287V82.7874L55.8116 82.7874V82.312ZM55.8116 83.0251V83.5003H56.287V83.0251H55.8116ZM56.5247 83.0251V83.5003H56.9999V83.0251H56.5247ZM56.9999 82.7874V82.312H56.5247V82.7874H56.9999ZM56.3466 82.4754H56.4654V82.8021H56.4354V83.0103H56.4654V83.3371H56.3466V83.0103H56.376V82.8021H56.3466V82.4754ZM55.9751 82.8469V82.9657H56.3019V82.8469H55.9751ZM56.5096 82.9657V82.8469H56.8364V82.9657H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 80.9004H57.9755V81.3758L57.5 81.3758V80.9004ZM57.5 81.6135V82.0887H57.9755V81.6135H57.5ZM58.2132 81.6135V82.0887H58.6883V81.6135H58.2132ZM58.6883 81.3758V80.9004H58.2132V81.3758L58.6883 81.3758ZM58.035 81.0638H58.1538V81.3906H58.1238V81.5988H58.1538V81.9255H58.035V81.5988H58.0644V81.3906H58.035V81.0638ZM57.6631 81.4353V81.5542H57.9899V81.4353H57.6631ZM58.1983 81.5542V81.4354H58.5251V81.5542H58.1983ZM57.5 82.3119H57.9754V82.7874H57.5V82.3119ZM57.5 83.0251V83.5002H57.9754V83.0251H57.5ZM58.2132 83.0251V83.5002H58.6883V83.0251H58.2132ZM58.6883 82.7874V82.3119H58.2132V82.7874L58.6883 82.7874ZM58.035 82.4753H58.1538V82.8021H58.1238V83.0103H58.1538V83.337H58.035V83.0103H58.0644V82.8021H58.035V82.4753ZM57.6631 82.8468V82.9657H57.9899V82.8468H57.6631ZM58.1983 82.9657V82.8469H58.5251V82.9657H58.1983ZM59.3871 80.9004H58.9117V81.3759L59.3871 81.3759V80.9004ZM58.9117 82.0887V81.6136H59.3871V82.0887H58.9117ZM59.6248 82.0887V81.6136H60.1V82.0887H59.6248ZM60.1 80.9004V81.3759L59.6248 81.3759V80.9004H60.1ZM59.5655 81.0638H59.4467V81.3906H59.4761V81.5988H59.4467V81.9256H59.5655V81.5988H59.5355V81.3906H59.5655V81.0638ZM59.0752 81.5542V81.4354H59.402V81.5542H59.0752ZM59.6097 81.4354V81.5542H59.9365V81.4354H59.6097ZM58.9117 82.312H59.3871V82.7874L58.9117 82.7874V82.312ZM58.9117 83.0251V83.5003H59.3871V83.0251H58.9117ZM59.6248 83.0251V83.5003H60.1V83.0251H59.6248ZM60.1 82.7874V82.312H59.6248V82.7874H60.1ZM59.4467 82.4754H59.5655V82.8021H59.5355V83.0103H59.5655V83.3371H59.4467V83.0103H59.4761V82.8021H59.4467V82.4754ZM59.0752 82.8469V82.9657H59.402V82.8469H59.0752ZM59.6097 82.9657V82.8469H59.9365V82.9657H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 80.9004H61.0756V81.3758L60.6001 81.3758V80.9004ZM60.6001 81.6135V82.0887H61.0756V81.6135H60.6001ZM61.3133 81.6135V82.0887H61.7884V81.6135H61.3133ZM61.7884 81.3758V80.9004H61.3133V81.3758L61.7884 81.3758ZM61.1351 81.0638H61.2539V81.3906H61.2239V81.5988H61.2539V81.9255H61.1351V81.5988H61.1645V81.3906H61.1351V81.0638ZM60.7632 81.4353V81.5542H61.09V81.4353H60.7632ZM61.2984 81.5542V81.4354H61.6252V81.5542H61.2984ZM60.6001 82.3119H61.0755V82.7874H60.6001V82.3119ZM60.6001 83.0251V83.5002H61.0755V83.0251H60.6001ZM61.3133 83.0251V83.5002H61.7884V83.0251H61.3133ZM61.7884 82.7874V82.3119H61.3133V82.7874L61.7884 82.7874ZM61.1351 82.4753H61.2539V82.8021H61.2239V83.0103H61.2539V83.337H61.1351V83.0103H61.1645V82.8021H61.1351V82.4753ZM60.7632 82.8468V82.9657H61.09V82.8468H60.7632ZM61.2984 82.9657V82.8469H61.6252V82.9657H61.2984ZM62.4872 80.9004H62.0118V81.3759L62.4872 81.3759V80.9004ZM62.0118 82.0887V81.6136H62.4872V82.0887H62.0118ZM62.7249 82.0887V81.6136H63.2001V82.0887H62.7249ZM63.2001 80.9004V81.3759L62.7249 81.3759V80.9004H63.2001ZM62.6656 81.0638H62.5468V81.3906H62.5762V81.5988H62.5468V81.9256H62.6656V81.5988H62.6356V81.3906H62.6656V81.0638ZM62.1753 81.5542V81.4354H62.5021V81.5542H62.1753ZM62.7098 81.4354V81.5542H63.0366V81.4354H62.7098ZM62.0118 82.312H62.4872V82.7874L62.0118 82.7874V82.312ZM62.0118 83.0251V83.5003H62.4872V83.0251H62.0118ZM62.7249 83.0251V83.5003H63.2001V83.0251H62.7249ZM63.2001 82.7874V82.312H62.7249V82.7874H63.2001ZM62.5468 82.4754H62.6656V82.8021H62.6356V83.0103H62.6656V83.3371H62.5468V83.0103H62.5762V82.8021H62.5468V82.4754ZM62.1753 82.8469V82.9657H62.5021V82.8469H62.1753ZM62.7098 82.9657V82.8469H63.0366V82.9657H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 84H51.7753V84.4754L51.2998 84.4754V84ZM51.2998 84.7131V85.1883H51.7753V84.7131H51.2998ZM52.013 84.7131V85.1883H52.4881V84.7131H52.013ZM52.4881 84.4754V84H52.013V84.4754L52.4881 84.4754ZM51.8348 84.1634H51.9536V84.4902H51.9236V84.6984H51.9536V85.0251H51.8348V84.6984H51.8642V84.4902H51.8348V84.1634ZM51.4629 84.535V84.6538H51.7897V84.535H51.4629ZM51.9981 84.6538V84.535H52.3249V84.6538H51.9981ZM51.2998 85.4115H51.7753V85.887H51.2998V85.4115ZM51.2998 86.1247V86.5998H51.7753V86.1247H51.2998ZM52.013 86.1247V86.5998H52.4881V86.1247H52.013ZM52.4881 85.887V85.4115H52.013V85.887L52.4881 85.887ZM51.8348 85.5749H51.9536V85.9017H51.9236V86.1099H51.9536V86.4366H51.8348V86.1099H51.8642V85.9017H51.8348V85.5749ZM51.4629 85.9465V86.0653H51.7897V85.9465H51.4629ZM51.9981 86.0653V85.9465H52.3249V86.0653H51.9981ZM53.1869 84H52.7115V84.4755L53.1869 84.4755V84ZM52.7115 85.1883V84.7132H53.1869V85.1883H52.7115ZM53.4246 85.1883V84.7132H53.8998V85.1883H53.4246ZM53.8998 84V84.4755L53.4246 84.4755V84H53.8998ZM53.3653 84.1634H53.2465V84.4902H53.2759V84.6984H53.2465V85.0252H53.3653V84.6984H53.3353V84.4902H53.3653V84.1634ZM52.875 84.6538V84.535H53.2018V84.6538H52.875ZM53.4095 84.535V84.6538H53.7363V84.535H53.4095ZM52.7115 85.4116H53.1869V85.887L52.7115 85.887V85.4116ZM52.7115 86.1247V86.5999H53.1869V86.1247H52.7115ZM53.4246 86.1247V86.5999H53.8998V86.1247H53.4246ZM53.8998 85.887V85.4116H53.4246V85.887H53.8998ZM53.2465 85.575H53.3653V85.9018H53.3353V86.1099H53.3653V86.4367H53.2465V86.1099H53.2759V85.9018H53.2465V85.575ZM52.875 85.9465V86.0653H53.2018V85.9465H52.875ZM53.4095 86.0653V85.9465H53.7363V86.0653H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 84H54.8754V84.4754L54.3999 84.4754V84ZM54.3999 84.7131V85.1883H54.8754V84.7131H54.3999ZM55.1131 84.7131V85.1883H55.5882V84.7131H55.1131ZM55.5882 84.4754V84H55.1131V84.4754L55.5882 84.4754ZM54.9349 84.1634H55.0537V84.4902H55.0237V84.6984H55.0537V85.0251H54.9349V84.6984H54.9643V84.4902H54.9349V84.1634ZM54.563 84.535V84.6538H54.8898V84.535H54.563ZM55.0982 84.6538V84.535H55.425V84.6538H55.0982ZM54.3999 85.4115H54.8753V85.887H54.3999V85.4115ZM54.3999 86.1247V86.5998H54.8753V86.1247H54.3999ZM55.1131 86.1247V86.5998H55.5882V86.1247H55.1131ZM55.5882 85.887V85.4115H55.1131V85.887L55.5882 85.887ZM54.9349 85.5749H55.0537V85.9017H55.0237V86.1099H55.0537V86.4366H54.9349V86.1099H54.9643V85.9017H54.9349V85.5749ZM54.563 85.9465V86.0653H54.8898V85.9465H54.563ZM55.0982 86.0653V85.9465H55.425V86.0653H55.0982ZM56.287 84H55.8116V84.4755L56.287 84.4755V84ZM55.8116 85.1883V84.7132H56.287V85.1883H55.8116ZM56.5247 85.1883V84.7132H56.9999V85.1883H56.5247ZM56.9999 84V84.4755L56.5247 84.4755V84H56.9999ZM56.4654 84.1634H56.3466V84.4902H56.376V84.6984H56.3466V85.0252H56.4654V84.6984H56.4354V84.4902H56.4654V84.1634ZM55.9751 84.6538V84.535H56.3019V84.6538H55.9751ZM56.5096 84.535V84.6538H56.8364V84.535H56.5096ZM55.8116 85.4116H56.287V85.887L55.8116 85.887V85.4116ZM55.8116 86.1247V86.5999H56.287V86.1247H55.8116ZM56.5247 86.1247V86.5999H56.9999V86.1247H56.5247ZM56.9999 85.887V85.4116H56.5247V85.887H56.9999ZM56.3466 85.575H56.4654V85.9018H56.4354V86.1099H56.4654V86.4367H56.3466V86.1099H56.376V85.9018H56.3466V85.575ZM55.9751 85.9465V86.0653H56.3019V85.9465H55.9751ZM56.5096 86.0653V85.9465H56.8364V86.0653H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 84H57.9755V84.4754L57.5 84.4754V84ZM57.5 84.7131V85.1883H57.9755V84.7131H57.5ZM58.2132 84.7131V85.1883H58.6883V84.7131H58.2132ZM58.6883 84.4754V84H58.2132V84.4754L58.6883 84.4754ZM58.035 84.1634H58.1538V84.4902H58.1238V84.6984H58.1538V85.0251H58.035V84.6984H58.0644V84.4902H58.035V84.1634ZM57.6631 84.535V84.6538H57.9899V84.535H57.6631ZM58.1983 84.6538V84.535H58.5251V84.6538H58.1983ZM57.5 85.4115H57.9754V85.887H57.5V85.4115ZM57.5 86.1247V86.5998H57.9754V86.1247H57.5ZM58.2132 86.1247V86.5998H58.6883V86.1247H58.2132ZM58.6883 85.887V85.4115H58.2132V85.887L58.6883 85.887ZM58.035 85.5749H58.1538V85.9017H58.1238V86.1099H58.1538V86.4366H58.035V86.1099H58.0644V85.9017H58.035V85.5749ZM57.6631 85.9465V86.0653H57.9899V85.9465H57.6631ZM58.1983 86.0653V85.9465H58.5251V86.0653H58.1983ZM59.3871 84H58.9117V84.4755L59.3871 84.4755V84ZM58.9117 85.1883V84.7132H59.3871V85.1883H58.9117ZM59.6248 85.1883V84.7132H60.1V85.1883H59.6248ZM60.1 84V84.4755L59.6248 84.4755V84H60.1ZM59.5655 84.1634H59.4467V84.4902H59.4761V84.6984H59.4467V85.0252H59.5655V84.6984H59.5355V84.4902H59.5655V84.1634ZM59.0752 84.6538V84.535H59.402V84.6538H59.0752ZM59.6097 84.535V84.6538H59.9365V84.535H59.6097ZM58.9117 85.4116H59.3871V85.887L58.9117 85.887V85.4116ZM58.9117 86.1247V86.5999H59.3871V86.1247H58.9117ZM59.6248 86.1247V86.5999H60.1V86.1247H59.6248ZM60.1 85.887V85.4116H59.6248V85.887H60.1ZM59.4467 85.575H59.5655V85.9018H59.5355V86.1099H59.5655V86.4367H59.4467V86.1099H59.4761V85.9018H59.4467V85.575ZM59.0752 85.9465V86.0653H59.402V85.9465H59.0752ZM59.6097 86.0653V85.9465H59.9365V86.0653H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 84H61.0756V84.4754L60.6001 84.4754V84ZM60.6001 84.7131V85.1883H61.0756V84.7131H60.6001ZM61.3133 84.7131V85.1883H61.7884V84.7131H61.3133ZM61.7884 84.4754V84H61.3133V84.4754L61.7884 84.4754ZM61.1351 84.1634H61.2539V84.4902H61.2239V84.6984H61.2539V85.0251H61.1351V84.6984H61.1645V84.4902H61.1351V84.1634ZM60.7632 84.535V84.6538H61.09V84.535H60.7632ZM61.2984 84.6538V84.535H61.6252V84.6538H61.2984ZM60.6001 85.4115H61.0755V85.887H60.6001V85.4115ZM60.6001 86.1247V86.5998H61.0755V86.1247H60.6001ZM61.3133 86.1247V86.5998H61.7884V86.1247H61.3133ZM61.7884 85.887V85.4115H61.3133V85.887L61.7884 85.887ZM61.1351 85.5749H61.2539V85.9017H61.2239V86.1099H61.2539V86.4366H61.1351V86.1099H61.1645V85.9017H61.1351V85.5749ZM60.7632 85.9465V86.0653H61.09V85.9465H60.7632ZM61.2984 86.0653V85.9465H61.6252V86.0653H61.2984ZM62.4872 84H62.0118V84.4755L62.4872 84.4755V84ZM62.0118 85.1883V84.7132H62.4872V85.1883H62.0118ZM62.7249 85.1883V84.7132H63.2001V85.1883H62.7249ZM63.2001 84V84.4755L62.7249 84.4755V84H63.2001ZM62.6656 84.1634H62.5468V84.4902H62.5762V84.6984H62.5468V85.0252H62.6656V84.6984H62.6356V84.4902H62.6656V84.1634ZM62.1753 84.6538V84.535H62.5021V84.6538H62.1753ZM62.7098 84.535V84.6538H63.0366V84.535H62.7098ZM62.0118 85.4116H62.4872V85.887L62.0118 85.887V85.4116ZM62.0118 86.1247V86.5999H62.4872V86.1247H62.0118ZM62.7249 86.1247V86.5999H63.2001V86.1247H62.7249ZM63.2001 85.887V85.4116H62.7249V85.887H63.2001ZM62.5468 85.575H62.6656V85.9018H62.6356V86.1099H62.6656V86.4367H62.5468V86.1099H62.5762V85.9018H62.5468V85.575ZM62.1753 85.9465V86.0653H62.5021V85.9465H62.1753ZM62.7098 86.0653V85.9465H63.0366V86.0653H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 87.0996H51.7753V87.575L51.2998 87.575V87.0996ZM51.2998 87.8127V88.2879H51.7753V87.8127H51.2998ZM52.013 87.8127V88.2879H52.4881V87.8127H52.013ZM52.4881 87.575V87.0996H52.013V87.575L52.4881 87.575ZM51.8348 87.263H51.9536V87.5898H51.9236V87.798H51.9536V88.1248H51.8348V87.798H51.8642V87.5898H51.8348V87.263ZM51.4629 87.6346V87.7534H51.7897V87.6346H51.4629ZM51.9981 87.7534V87.6346H52.3249V87.7534H51.9981ZM51.2998 88.5112H51.7753V88.9866H51.2998V88.5112ZM51.2998 89.2243V89.6994H51.7753V89.2243H51.2998ZM52.013 89.2243V89.6994H52.4881V89.2243H52.013ZM52.4881 88.9866V88.5112H52.013V88.9866L52.4881 88.9866ZM51.8348 88.6746H51.9536V89.0013H51.9236V89.2095H51.9536V89.5363H51.8348V89.2095H51.8642V89.0013H51.8348V88.6746ZM51.4629 89.0461V89.1649H51.7897V89.0461H51.4629ZM51.9981 89.1649V89.0461H52.3249V89.1649H51.9981ZM53.1869 87.0996H52.7115V87.5751L53.1869 87.5751V87.0996ZM52.7115 88.2879V87.8128H53.1869V88.2879H52.7115ZM53.4246 88.2879V87.8128H53.8998V88.2879H53.4246ZM53.8998 87.0996V87.5751L53.4246 87.5751V87.0996H53.8998ZM53.3653 87.263H53.2465V87.5898H53.2759V87.798H53.2465V88.1248H53.3653V87.798H53.3353V87.5898H53.3653V87.263ZM52.875 87.7534V87.6346H53.2018V87.7534H52.875ZM53.4095 87.6346V87.7534H53.7363V87.6346H53.4095ZM52.7115 88.5112H53.1869V88.9866L52.7115 88.9866V88.5112ZM52.7115 89.2243V89.6995H53.1869V89.2243H52.7115ZM53.4246 89.2243V89.6995H53.8998V89.2243H53.4246ZM53.8998 88.9866V88.5112H53.4246V88.9866H53.8998ZM53.2465 88.6746H53.3653V89.0014H53.3353V89.2095H53.3653V89.5363H53.2465V89.2095H53.2759V89.0014H53.2465V88.6746ZM52.875 89.0461V89.1649H53.2018V89.0461H52.875ZM53.4095 89.1649V89.0461H53.7363V89.1649H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 87.0996H54.8754V87.575L54.3999 87.575V87.0996ZM54.3999 87.8127V88.2879H54.8754V87.8127H54.3999ZM55.1131 87.8127V88.2879H55.5882V87.8127H55.1131ZM55.5882 87.575V87.0996H55.1131V87.575L55.5882 87.575ZM54.9349 87.263H55.0537V87.5898H55.0237V87.798H55.0537V88.1248H54.9349V87.798H54.9643V87.5898H54.9349V87.263ZM54.563 87.6346V87.7534H54.8898V87.6346H54.563ZM55.0982 87.7534V87.6346H55.425V87.7534H55.0982ZM54.3999 88.5112H54.8753V88.9866H54.3999V88.5112ZM54.3999 89.2243V89.6994H54.8753V89.2243H54.3999ZM55.1131 89.2243V89.6994H55.5882V89.2243H55.1131ZM55.5882 88.9866V88.5112H55.1131V88.9866L55.5882 88.9866ZM54.9349 88.6746H55.0537V89.0013H55.0237V89.2095H55.0537V89.5363H54.9349V89.2095H54.9643V89.0013H54.9349V88.6746ZM54.563 89.0461V89.1649H54.8898V89.0461H54.563ZM55.0982 89.1649V89.0461H55.425V89.1649H55.0982ZM56.287 87.0996H55.8116V87.5751L56.287 87.5751V87.0996ZM55.8116 88.2879V87.8128H56.287V88.2879H55.8116ZM56.5247 88.2879V87.8128H56.9999V88.2879H56.5247ZM56.9999 87.0996V87.5751L56.5247 87.5751V87.0996H56.9999ZM56.4654 87.263H56.3466V87.5898H56.376V87.798H56.3466V88.1248H56.4654V87.798H56.4354V87.5898H56.4654V87.263ZM55.9751 87.7534V87.6346H56.3019V87.7534H55.9751ZM56.5096 87.6346V87.7534H56.8364V87.6346H56.5096ZM55.8116 88.5112H56.287V88.9866L55.8116 88.9866V88.5112ZM55.8116 89.2243V89.6995H56.287V89.2243H55.8116ZM56.5247 89.2243V89.6995H56.9999V89.2243H56.5247ZM56.9999 88.9866V88.5112H56.5247V88.9866H56.9999ZM56.3466 88.6746H56.4654V89.0014H56.4354V89.2095H56.4654V89.5363H56.3466V89.2095H56.376V89.0014H56.3466V88.6746ZM55.9751 89.0461V89.1649H56.3019V89.0461H55.9751ZM56.5096 89.1649V89.0461H56.8364V89.1649H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 87.0996H57.9755V87.575L57.5 87.575V87.0996ZM57.5 87.8127V88.2879H57.9755V87.8127H57.5ZM58.2132 87.8127V88.2879H58.6883V87.8127H58.2132ZM58.6883 87.575V87.0996H58.2132V87.575L58.6883 87.575ZM58.035 87.263H58.1538V87.5898H58.1238V87.798H58.1538V88.1248H58.035V87.798H58.0644V87.5898H58.035V87.263ZM57.6631 87.6346V87.7534H57.9899V87.6346H57.6631ZM58.1983 87.7534V87.6346H58.5251V87.7534H58.1983ZM57.5 88.5112H57.9754V88.9866H57.5V88.5112ZM57.5 89.2243V89.6994H57.9754V89.2243H57.5ZM58.2132 89.2243V89.6994H58.6883V89.2243H58.2132ZM58.6883 88.9866V88.5112H58.2132V88.9866L58.6883 88.9866ZM58.035 88.6746H58.1538V89.0013H58.1238V89.2095H58.1538V89.5363H58.035V89.2095H58.0644V89.0013H58.035V88.6746ZM57.6631 89.0461V89.1649H57.9899V89.0461H57.6631ZM58.1983 89.1649V89.0461H58.5251V89.1649H58.1983ZM59.3871 87.0996H58.9117V87.5751L59.3871 87.5751V87.0996ZM58.9117 88.2879V87.8128H59.3871V88.2879H58.9117ZM59.6248 88.2879V87.8128H60.1V88.2879H59.6248ZM60.1 87.0996V87.5751L59.6248 87.5751V87.0996H60.1ZM59.5655 87.263H59.4467V87.5898H59.4761V87.798H59.4467V88.1248H59.5655V87.798H59.5355V87.5898H59.5655V87.263ZM59.0752 87.7534V87.6346H59.402V87.7534H59.0752ZM59.6097 87.6346V87.7534H59.9365V87.6346H59.6097ZM58.9117 88.5112H59.3871V88.9866L58.9117 88.9866V88.5112ZM58.9117 89.2243V89.6995H59.3871V89.2243H58.9117ZM59.6248 89.2243V89.6995H60.1V89.2243H59.6248ZM60.1 88.9866V88.5112H59.6248V88.9866H60.1ZM59.4467 88.6746H59.5655V89.0014H59.5355V89.2095H59.5655V89.5363H59.4467V89.2095H59.4761V89.0014H59.4467V88.6746ZM59.0752 89.0461V89.1649H59.402V89.0461H59.0752ZM59.6097 89.1649V89.0461H59.9365V89.1649H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 87.0996H61.0756V87.575L60.6001 87.575V87.0996ZM60.6001 87.8127V88.2879H61.0756V87.8127H60.6001ZM61.3133 87.8127V88.2879H61.7884V87.8127H61.3133ZM61.7884 87.575V87.0996H61.3133V87.575L61.7884 87.575ZM61.1351 87.263H61.2539V87.5898H61.2239V87.798H61.2539V88.1248H61.1351V87.798H61.1645V87.5898H61.1351V87.263ZM60.7632 87.6346V87.7534H61.09V87.6346H60.7632ZM61.2984 87.7534V87.6346H61.6252V87.7534H61.2984ZM60.6001 88.5112H61.0755V88.9866H60.6001V88.5112ZM60.6001 89.2243V89.6994H61.0755V89.2243H60.6001ZM61.3133 89.2243V89.6994H61.7884V89.2243H61.3133ZM61.7884 88.9866V88.5112H61.3133V88.9866L61.7884 88.9866ZM61.1351 88.6746H61.2539V89.0013H61.2239V89.2095H61.2539V89.5363H61.1351V89.2095H61.1645V89.0013H61.1351V88.6746ZM60.7632 89.0461V89.1649H61.09V89.0461H60.7632ZM61.2984 89.1649V89.0461H61.6252V89.1649H61.2984ZM62.4872 87.0996H62.0118V87.5751L62.4872 87.5751V87.0996ZM62.0118 88.2879V87.8128H62.4872V88.2879H62.0118ZM62.7249 88.2879V87.8128H63.2001V88.2879H62.7249ZM63.2001 87.0996V87.5751L62.7249 87.5751V87.0996H63.2001ZM62.6656 87.263H62.5468V87.5898H62.5762V87.798H62.5468V88.1248H62.6656V87.798H62.6356V87.5898H62.6656V87.263ZM62.1753 87.7534V87.6346H62.5021V87.7534H62.1753ZM62.7098 87.6346V87.7534H63.0366V87.6346H62.7098ZM62.0118 88.5112H62.4872V88.9866L62.0118 88.9866V88.5112ZM62.0118 89.2243V89.6995H62.4872V89.2243H62.0118ZM62.7249 89.2243V89.6995H63.2001V89.2243H62.7249ZM63.2001 88.9866V88.5112H62.7249V88.9866H63.2001ZM62.5468 88.6746H62.6656V89.0014H62.6356V89.2095H62.6656V89.5363H62.5468V89.2095H62.5762V89.0014H62.5468V88.6746ZM62.1753 89.0461V89.1649H62.5021V89.0461H62.1753ZM62.7098 89.1649V89.0461H63.0366V89.1649H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 77.7998H64.2753V78.2752L63.7998 78.2752V77.7998ZM63.7998 78.5129V78.9881H64.2753V78.5129H63.7998ZM64.513 78.5129V78.9881H64.9881V78.5129H64.513ZM64.9881 78.2752V77.7998H64.513V78.2752L64.9881 78.2752ZM64.3348 77.9632H64.4536V78.29H64.4236V78.4982H64.4536V78.825H64.3348V78.4982H64.3642V78.29H64.3348V77.9632ZM63.9629 78.3348V78.4536H64.2897V78.3348H63.9629ZM64.4981 78.4536V78.3348H64.8249V78.4536H64.4981ZM63.7998 79.2113H64.2753V79.6868H63.7998V79.2113ZM63.7998 79.9245V80.3996H64.2753V79.9245H63.7998ZM64.513 79.9245V80.3996H64.9881V79.9245H64.513ZM64.9881 79.6868V79.2113H64.513V79.6868H64.9881ZM64.3348 79.3747H64.4536V79.7015H64.4236V79.9097H64.4536V80.2364H64.3348V79.9097H64.3642V79.7015H64.3348V79.3747ZM63.9629 79.7463V79.8651H64.2897V79.7463H63.9629ZM64.4981 79.8651V79.7463H64.8249V79.8651H64.4981ZM65.6869 77.7998H65.2115V78.2753L65.6869 78.2753V77.7998ZM65.2115 78.9881V78.513H65.6869V78.9881H65.2115ZM65.9246 78.9881V78.513H66.3998V78.9881H65.9246ZM66.3998 77.7998V78.2753L65.9246 78.2753V77.7998H66.3998ZM65.8653 77.9632H65.7465V78.29H65.7759V78.4982H65.7465V78.825H65.8653V78.4982H65.8353V78.29H65.8653V77.9632ZM65.375 78.4536V78.3348H65.7018V78.4536H65.375ZM65.9095 78.3348V78.4536H66.2363V78.3348H65.9095ZM65.2115 79.2114H65.6869V79.6868H65.2115V79.2114ZM65.2115 79.9245V80.3997H65.6869V79.9245H65.2115ZM65.9246 79.9245V80.3997H66.3998V79.9245H65.9246ZM66.3998 79.6868V79.2114H65.9246V79.6868H66.3998ZM65.7465 79.3748H65.8653V79.7016H65.8353V79.9097H65.8653V80.2365H65.7465V79.9097H65.7759V79.7016H65.7465V79.3748ZM65.375 79.7463V79.8651H65.7018V79.7463H65.375ZM65.9095 79.8651V79.7463H66.2363V79.8651H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 77.7998H67.3754V78.2752L66.8999 78.2752V77.7998ZM66.8999 78.5129V78.9881H67.3754V78.5129H66.8999ZM67.6131 78.5129V78.9881H68.0882V78.5129H67.6131ZM68.0882 78.2752V77.7998H67.6131V78.2752L68.0882 78.2752ZM67.4349 77.9632H67.5537V78.29H67.5237V78.4982H67.5537V78.825H67.4349V78.4982H67.4643V78.29H67.4349V77.9632ZM67.063 78.3348V78.4536H67.3898V78.3348H67.063ZM67.5982 78.4536V78.3348H67.925V78.4536H67.5982ZM66.8999 79.2113H67.3753V79.6868H66.8999V79.2113ZM66.8999 79.9245V80.3996H67.3753V79.9245H66.8999ZM67.6131 79.9245V80.3996H68.0882V79.9245H67.6131ZM68.0882 79.6868V79.2113H67.6131V79.6868L68.0882 79.6868ZM67.4349 79.3747H67.5537V79.7015H67.5237V79.9097H67.5537V80.2364H67.4349V79.9097H67.4643V79.7015H67.4349V79.3747ZM67.063 79.7463V79.8651H67.3898V79.7463H67.063ZM67.5982 79.8651V79.7463H67.925V79.8651H67.5982ZM68.787 77.7998H68.3116V78.2753L68.787 78.2753V77.7998ZM68.3116 78.9881V78.513H68.787V78.9881H68.3116ZM69.0247 78.9881V78.513H69.4999V78.9881H69.0247ZM69.4999 77.7998V78.2753L69.0247 78.2753V77.7998H69.4999ZM68.9654 77.9632H68.8466V78.29H68.876V78.4982H68.8466V78.825H68.9654V78.4982H68.9354V78.29H68.9654V77.9632ZM68.4751 78.4536V78.3348H68.8019V78.4536H68.4751ZM69.0096 78.3348V78.4536H69.3364V78.3348H69.0096ZM68.3116 79.2114H68.787V79.6868L68.3116 79.6868V79.2114ZM68.3116 79.9245V80.3997H68.787V79.9245H68.3116ZM69.0247 79.9245V80.3997H69.4999V79.9245H69.0247ZM69.4999 79.6868V79.2114H69.0247V79.6868H69.4999ZM68.8466 79.3748H68.9654V79.7016H68.9354V79.9097H68.9654V80.2365H68.8466V79.9097H68.876V79.7016H68.8466V79.3748ZM68.4751 79.7463V79.8651H68.8019V79.7463H68.4751ZM69.0096 79.8651V79.7463H69.3364V79.8651H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 77.7998H70.4755V78.2752L70 78.2752V77.7998ZM70 78.5129V78.9881H70.4755V78.5129H70ZM70.7132 78.5129V78.9881H71.1883V78.5129H70.7132ZM71.1883 78.2752V77.7998H70.7132V78.2752L71.1883 78.2752ZM70.535 77.9632H70.6538V78.29H70.6238V78.4982H70.6538V78.825H70.535V78.4982H70.5644V78.29H70.535V77.9632ZM70.1631 78.3348V78.4536H70.4899V78.3348H70.1631ZM70.6983 78.4536V78.3348H71.0251V78.4536H70.6983ZM70 79.2113H70.4754V79.6868H70V79.2113ZM70 79.9245V80.3996H70.4754V79.9245H70ZM70.7132 79.9245V80.3996H71.1883V79.9245H70.7132ZM71.1883 79.6868V79.2113H70.7132V79.6868L71.1883 79.6868ZM70.535 79.3747H70.6538V79.7015H70.6238V79.9097H70.6538V80.2364H70.535V79.9097H70.5644V79.7015H70.535V79.3747ZM70.1631 79.7463V79.8651H70.4899V79.7463H70.1631ZM70.6983 79.8651V79.7463H71.0251V79.8651H70.6983ZM71.8871 77.7998H71.4117V78.2753L71.8871 78.2753V77.7998ZM71.4117 78.9881V78.513H71.8871V78.9881H71.4117ZM72.1248 78.9881V78.513H72.6V78.9881H72.1248ZM72.6 77.7998V78.2753L72.1248 78.2753V77.7998H72.6ZM72.0655 77.9632H71.9467V78.29H71.9761V78.4982H71.9467V78.825H72.0655V78.4982H72.0355V78.29H72.0655V77.9632ZM71.5752 78.4536V78.3348H71.902V78.4536H71.5752ZM72.1097 78.3348V78.4536H72.4365V78.3348H72.1097ZM71.4117 79.2114H71.8871V79.6868L71.4117 79.6868V79.2114ZM71.4117 79.9245V80.3997H71.8871V79.9245H71.4117ZM72.1248 79.9245V80.3997H72.6V79.9245H72.1248ZM72.6 79.6868V79.2114H72.1248V79.6868H72.6ZM71.9467 79.3748H72.0655V79.7016H72.0355V79.9097H72.0655V80.2365H71.9467V79.9097H71.9761V79.7016H71.9467V79.3748ZM71.5752 79.7463V79.8651H71.902V79.7463H71.5752ZM72.1097 79.8651V79.7463H72.4365V79.8651H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 77.7998H73.5756V78.2752L73.1001 78.2752V77.7998ZM73.1001 78.5129V78.9881H73.5756V78.5129H73.1001ZM73.8133 78.5129V78.9881H74.2884V78.5129H73.8133ZM74.2884 78.2752V77.7998H73.8133V78.2752L74.2884 78.2752ZM73.6351 77.9632H73.7539V78.29H73.7239V78.4982H73.7539V78.825H73.6351V78.4982H73.6645V78.29H73.6351V77.9632ZM73.2632 78.3348V78.4536H73.59V78.3348H73.2632ZM73.7984 78.4536V78.3348H74.1252V78.4536H73.7984ZM73.1001 79.2113H73.5755V79.6868H73.1001V79.2113ZM73.1001 79.9245V80.3996H73.5755V79.9245H73.1001ZM73.8133 79.9245V80.3996H74.2884V79.9245H73.8133ZM74.2884 79.6868V79.2113H73.8133V79.6868L74.2884 79.6868ZM73.6351 79.3747H73.7539V79.7015H73.7239V79.9097H73.7539V80.2364H73.6351V79.9097H73.6645V79.7015H73.6351V79.3747ZM73.2632 79.7463V79.8651H73.59V79.7463H73.2632ZM73.7984 79.8651V79.7463H74.1252V79.8651H73.7984ZM74.9872 77.7998H74.5118V78.2753L74.9872 78.2753V77.7998ZM74.5118 78.9881V78.513H74.9872V78.9881H74.5118ZM75.2249 78.9881V78.513H75.7001V78.9881H75.2249ZM75.7001 77.7998V78.2753L75.2249 78.2753V77.7998H75.7001ZM75.1656 77.9632H75.0468V78.29H75.0762V78.4982H75.0468V78.825H75.1656V78.4982H75.1356V78.29H75.1656V77.9632ZM74.6753 78.4536V78.3348H75.0021V78.4536H74.6753ZM75.2098 78.3348V78.4536H75.5366V78.3348H75.2098ZM74.5118 79.2114H74.9872V79.6868L74.5118 79.6868V79.2114ZM74.5118 79.9245V80.3997H74.9872V79.9245H74.5118ZM75.2249 79.9245V80.3997H75.7001V79.9245H75.2249ZM75.7001 79.6868V79.2114H75.2249V79.6868H75.7001ZM75.0468 79.3748H75.1656V79.7016H75.1356V79.9097H75.1656V80.2365H75.0468V79.9097H75.0762V79.7016H75.0468V79.3748ZM74.6753 79.7463V79.8651H75.0021V79.7463H74.6753ZM75.2098 79.8651V79.7463H75.5366V79.8651H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 80.9004H64.2753V81.3758L63.7998 81.3758V80.9004ZM63.7998 81.6135V82.0887H64.2753V81.6135H63.7998ZM64.513 81.6135V82.0887H64.9881V81.6135H64.513ZM64.9881 81.3758V80.9004H64.513V81.3758L64.9881 81.3758ZM64.3348 81.0638H64.4536V81.3906H64.4236V81.5988H64.4536V81.9255H64.3348V81.5988H64.3642V81.3906H64.3348V81.0638ZM63.9629 81.4353V81.5542H64.2897V81.4353H63.9629ZM64.4981 81.5542V81.4354H64.8249V81.5542H64.4981ZM63.7998 82.3119H64.2753V82.7874H63.7998V82.3119ZM63.7998 83.0251V83.5002H64.2753V83.0251H63.7998ZM64.513 83.0251V83.5002H64.9881V83.0251H64.513ZM64.9881 82.7874V82.3119H64.513V82.7874L64.9881 82.7874ZM64.3348 82.4753H64.4536V82.8021H64.4236V83.0103H64.4536V83.337H64.3348V83.0103H64.3642V82.8021H64.3348V82.4753ZM63.9629 82.8468V82.9657H64.2897V82.8468H63.9629ZM64.4981 82.9657V82.8469H64.8249V82.9657H64.4981ZM65.6869 80.9004H65.2115V81.3759L65.6869 81.3759V80.9004ZM65.2115 82.0887V81.6136H65.6869V82.0887H65.2115ZM65.9246 82.0887V81.6136H66.3998V82.0887H65.9246ZM66.3998 80.9004V81.3759L65.9246 81.3759V80.9004H66.3998ZM65.8653 81.0638H65.7465V81.3906H65.7759V81.5988H65.7465V81.9256H65.8653V81.5988H65.8353V81.3906H65.8653V81.0638ZM65.375 81.5542V81.4354H65.7018V81.5542H65.375ZM65.9095 81.4354V81.5542H66.2363V81.4354H65.9095ZM65.2115 82.312H65.6869V82.7874L65.2115 82.7874V82.312ZM65.2115 83.0251V83.5003H65.6869V83.0251H65.2115ZM65.9246 83.0251V83.5003H66.3998V83.0251H65.9246ZM66.3998 82.7874V82.312H65.9246V82.7874H66.3998ZM65.7465 82.4754H65.8653V82.8021H65.8353V83.0103H65.8653V83.3371H65.7465V83.0103H65.7759V82.8021H65.7465V82.4754ZM65.375 82.8469V82.9657H65.7018V82.8469H65.375ZM65.9095 82.9657V82.8469H66.2363V82.9657H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 80.9004H67.3754V81.3758L66.8999 81.3758V80.9004ZM66.8999 81.6135V82.0887H67.3754V81.6135H66.8999ZM67.6131 81.6135V82.0887H68.0882V81.6135H67.6131ZM68.0882 81.3758V80.9004H67.6131V81.3758L68.0882 81.3758ZM67.4349 81.0638H67.5537V81.3906H67.5237V81.5988H67.5537V81.9255H67.4349V81.5988H67.4643V81.3906H67.4349V81.0638ZM67.063 81.4353V81.5542H67.3898V81.4353H67.063ZM67.5982 81.5542V81.4354H67.925V81.5542H67.5982ZM66.8999 82.3119H67.3753V82.7874H66.8999V82.3119ZM66.8999 83.0251V83.5002H67.3753V83.0251H66.8999ZM67.6131 83.0251V83.5002H68.0882V83.0251H67.6131ZM68.0882 82.7874V82.3119H67.6131V82.7874L68.0882 82.7874ZM67.4349 82.4753H67.5537V82.8021H67.5237V83.0103H67.5537V83.337H67.4349V83.0103H67.4643V82.8021H67.4349V82.4753ZM67.063 82.8468V82.9657H67.3898V82.8468H67.063ZM67.5982 82.9657V82.8469H67.925V82.9657H67.5982ZM68.787 80.9004H68.3116V81.3759L68.787 81.3759V80.9004ZM68.3116 82.0887V81.6136H68.787V82.0887H68.3116ZM69.0247 82.0887V81.6136H69.4999V82.0887H69.0247ZM69.4999 80.9004V81.3759L69.0247 81.3759V80.9004H69.4999ZM68.9654 81.0638H68.8466V81.3906H68.876V81.5988H68.8466V81.9256H68.9654V81.5988H68.9354V81.3906H68.9654V81.0638ZM68.4751 81.5542V81.4354H68.8019V81.5542H68.4751ZM69.0096 81.4354V81.5542H69.3364V81.4354H69.0096ZM68.3116 82.312H68.787V82.7874L68.3116 82.7874V82.312ZM68.3116 83.0251V83.5003H68.787V83.0251H68.3116ZM69.0247 83.0251V83.5003H69.4999V83.0251H69.0247ZM69.4999 82.7874V82.312H69.0247V82.7874H69.4999ZM68.8466 82.4754H68.9654V82.8021H68.9354V83.0103H68.9654V83.3371H68.8466V83.0103H68.876V82.8021H68.8466V82.4754ZM68.4751 82.8469V82.9657H68.8019V82.8469H68.4751ZM69.0096 82.9657V82.8469H69.3364V82.9657H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 80.9004H70.4755V81.3758L70 81.3758V80.9004ZM70 81.6135V82.0887H70.4755V81.6135H70ZM70.7132 81.6135V82.0887H71.1883V81.6135H70.7132ZM71.1883 81.3758V80.9004H70.7132V81.3758L71.1883 81.3758ZM70.535 81.0638H70.6538V81.3906H70.6238V81.5988H70.6538V81.9255H70.535V81.5988H70.5644V81.3906H70.535V81.0638ZM70.1631 81.4353V81.5542H70.4899V81.4353H70.1631ZM70.6983 81.5542V81.4354H71.0251V81.5542H70.6983ZM70 82.3119H70.4754V82.7874H70V82.3119ZM70 83.0251V83.5002H70.4754V83.0251H70ZM70.7132 83.0251V83.5002H71.1883V83.0251H70.7132ZM71.1883 82.7874V82.3119H70.7132V82.7874L71.1883 82.7874ZM70.535 82.4753H70.6538V82.8021H70.6238V83.0103H70.6538V83.337H70.535V83.0103H70.5644V82.8021H70.535V82.4753ZM70.1631 82.8468V82.9657H70.4899V82.8468H70.1631ZM70.6983 82.9657V82.8469H71.0251V82.9657H70.6983ZM71.8871 80.9004H71.4117V81.3759L71.8871 81.3759V80.9004ZM71.4117 82.0887V81.6136H71.8871V82.0887H71.4117ZM72.1248 82.0887V81.6136H72.6V82.0887H72.1248ZM72.6 80.9004V81.3759L72.1248 81.3759V80.9004H72.6ZM72.0655 81.0638H71.9467V81.3906H71.9761V81.5988H71.9467V81.9256H72.0655V81.5988H72.0355V81.3906H72.0655V81.0638ZM71.5752 81.5542V81.4354H71.902V81.5542H71.5752ZM72.1097 81.4354V81.5542H72.4365V81.4354H72.1097ZM71.4117 82.312H71.8871V82.7874L71.4117 82.7874V82.312ZM71.4117 83.0251V83.5003H71.8871V83.0251H71.4117ZM72.1248 83.0251V83.5003H72.6V83.0251H72.1248ZM72.6 82.7874V82.312H72.1248V82.7874H72.6ZM71.9467 82.4754H72.0655V82.8021H72.0355V83.0103H72.0655V83.3371H71.9467V83.0103H71.9761V82.8021H71.9467V82.4754ZM71.5752 82.8469V82.9657H71.902V82.8469H71.5752ZM72.1097 82.9657V82.8469H72.4365V82.9657H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 80.9004H73.5756V81.3758L73.1001 81.3758V80.9004ZM73.1001 81.6135V82.0887H73.5756V81.6135H73.1001ZM73.8133 81.6135V82.0887H74.2884V81.6135H73.8133ZM74.2884 81.3758V80.9004H73.8133V81.3758L74.2884 81.3758ZM73.6351 81.0638H73.7539V81.3906H73.7239V81.5988H73.7539V81.9255H73.6351V81.5988H73.6645V81.3906H73.6351V81.0638ZM73.2632 81.4353V81.5542H73.59V81.4353H73.2632ZM73.7984 81.5542V81.4354H74.1252V81.5542H73.7984ZM73.1001 82.3119H73.5755V82.7874H73.1001V82.3119ZM73.1001 83.0251V83.5002H73.5755V83.0251H73.1001ZM73.8133 83.0251V83.5002H74.2884V83.0251H73.8133ZM74.2884 82.7874V82.3119H73.8133V82.7874L74.2884 82.7874ZM73.6351 82.4753H73.7539V82.8021H73.7239V83.0103H73.7539V83.337H73.6351V83.0103H73.6645V82.8021H73.6351V82.4753ZM73.2632 82.8468V82.9657H73.59V82.8468H73.2632ZM73.7984 82.9657V82.8469H74.1252V82.9657H73.7984ZM74.9872 80.9004H74.5118V81.3759L74.9872 81.3759V80.9004ZM74.5118 82.0887V81.6136H74.9872V82.0887H74.5118ZM75.2249 82.0887V81.6136H75.7001V82.0887H75.2249ZM75.7001 80.9004V81.3759L75.2249 81.3759V80.9004H75.7001ZM75.1656 81.0638H75.0468V81.3906H75.0762V81.5988H75.0468V81.9256H75.1656V81.5988H75.1356V81.3906H75.1656V81.0638ZM74.6753 81.5542V81.4354H75.0021V81.5542H74.6753ZM75.2098 81.4354V81.5542H75.5366V81.4354H75.2098ZM74.5118 82.312H74.9872V82.7874L74.5118 82.7874V82.312ZM74.5118 83.0251V83.5003H74.9872V83.0251H74.5118ZM75.2249 83.0251V83.5003H75.7001V83.0251H75.2249ZM75.7001 82.7874V82.312H75.2249V82.7874H75.7001ZM75.0468 82.4754H75.1656V82.8021H75.1356V83.0103H75.1656V83.3371H75.0468V83.0103H75.0762V82.8021H75.0468V82.4754ZM74.6753 82.8469V82.9657H75.0021V82.8469H74.6753ZM75.2098 82.9657V82.8469H75.5366V82.9657H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 84H64.2753V84.4754L63.7998 84.4754V84ZM63.7998 84.7131V85.1883H64.2753V84.7131H63.7998ZM64.513 84.7131V85.1883H64.9881V84.7131H64.513ZM64.9881 84.4754V84H64.513V84.4754L64.9881 84.4754ZM64.3348 84.1634H64.4536V84.4902H64.4236V84.6984H64.4536V85.0251H64.3348V84.6984H64.3642V84.4902H64.3348V84.1634ZM63.9629 84.535V84.6538H64.2897V84.535H63.9629ZM64.4981 84.6538V84.535H64.8249V84.6538H64.4981ZM63.7998 85.4115H64.2753V85.887H63.7998V85.4115ZM63.7998 86.1247V86.5998H64.2753V86.1247H63.7998ZM64.513 86.1247V86.5998H64.9881V86.1247H64.513ZM64.9881 85.887V85.4115H64.513V85.887L64.9881 85.887ZM64.3348 85.5749H64.4536V85.9017H64.4236V86.1099H64.4536V86.4366H64.3348V86.1099H64.3642V85.9017H64.3348V85.5749ZM63.9629 85.9465V86.0653H64.2897V85.9465H63.9629ZM64.4981 86.0653V85.9465H64.8249V86.0653H64.4981ZM65.6869 84H65.2115V84.4755L65.6869 84.4755V84ZM65.2115 85.1883V84.7132H65.6869V85.1883H65.2115ZM65.9246 85.1883V84.7132H66.3998V85.1883H65.9246ZM66.3998 84V84.4755L65.9246 84.4755V84H66.3998ZM65.8653 84.1634H65.7465V84.4902H65.7759V84.6984H65.7465V85.0252H65.8653V84.6984H65.8353V84.4902H65.8653V84.1634ZM65.375 84.6538V84.535H65.7018V84.6538H65.375ZM65.9095 84.535V84.6538H66.2363V84.535H65.9095ZM65.2115 85.4116H65.6869V85.887L65.2115 85.887V85.4116ZM65.2115 86.1247V86.5999H65.6869V86.1247H65.2115ZM65.9246 86.1247V86.5999H66.3998V86.1247H65.9246ZM66.3998 85.887V85.4116H65.9246V85.887H66.3998ZM65.7465 85.575H65.8653V85.9018H65.8353V86.1099H65.8653V86.4367H65.7465V86.1099H65.7759V85.9018H65.7465V85.575ZM65.375 85.9465V86.0653H65.7018V85.9465H65.375ZM65.9095 86.0653V85.9465H66.2363V86.0653H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 84H67.3754V84.4754L66.8999 84.4754V84ZM66.8999 84.7131V85.1883H67.3754V84.7131H66.8999ZM67.6131 84.7131V85.1883H68.0882V84.7131H67.6131ZM68.0882 84.4754V84H67.6131V84.4754L68.0882 84.4754ZM67.4349 84.1634H67.5537V84.4902H67.5237V84.6984H67.5537V85.0251H67.4349V84.6984H67.4643V84.4902H67.4349V84.1634ZM67.063 84.535V84.6538H67.3898V84.535H67.063ZM67.5982 84.6538V84.535H67.925V84.6538H67.5982ZM66.8999 85.4115H67.3753V85.887H66.8999V85.4115ZM66.8999 86.1247V86.5998H67.3753V86.1247H66.8999ZM67.6131 86.1247V86.5998H68.0882V86.1247H67.6131ZM68.0882 85.887V85.4115H67.6131V85.887L68.0882 85.887ZM67.4349 85.5749H67.5537V85.9017H67.5237V86.1099H67.5537V86.4366H67.4349V86.1099H67.4643V85.9017H67.4349V85.5749ZM67.063 85.9465V86.0653H67.3898V85.9465H67.063ZM67.5982 86.0653V85.9465H67.925V86.0653H67.5982ZM68.787 84H68.3116V84.4755L68.787 84.4755V84ZM68.3116 85.1883V84.7132H68.787V85.1883H68.3116ZM69.0247 85.1883V84.7132H69.4999V85.1883H69.0247ZM69.4999 84V84.4755L69.0247 84.4755V84H69.4999ZM68.9654 84.1634H68.8466V84.4902H68.876V84.6984H68.8466V85.0252H68.9654V84.6984H68.9354V84.4902H68.9654V84.1634ZM68.4751 84.6538V84.535H68.8019V84.6538H68.4751ZM69.0096 84.535V84.6538H69.3364V84.535H69.0096ZM68.3116 85.4116H68.787V85.887L68.3116 85.887V85.4116ZM68.3116 86.1247V86.5999H68.787V86.1247H68.3116ZM69.0247 86.1247V86.5999H69.4999V86.1247H69.0247ZM69.4999 85.887V85.4116H69.0247V85.887H69.4999ZM68.8466 85.575H68.9654V85.9018H68.9354V86.1099H68.9654V86.4367H68.8466V86.1099H68.876V85.9018H68.8466V85.575ZM68.4751 85.9465V86.0653H68.8019V85.9465H68.4751ZM69.0096 86.0653V85.9465H69.3364V86.0653H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 84H70.4755V84.4754L70 84.4754V84ZM70 84.7131V85.1883H70.4755V84.7131H70ZM70.7132 84.7131V85.1883H71.1883V84.7131H70.7132ZM71.1883 84.4754V84H70.7132V84.4754L71.1883 84.4754ZM70.535 84.1634H70.6538V84.4902H70.6238V84.6984H70.6538V85.0251H70.535V84.6984H70.5644V84.4902H70.535V84.1634ZM70.1631 84.535V84.6538H70.4899V84.535H70.1631ZM70.6983 84.6538V84.535H71.0251V84.6538H70.6983ZM70 85.4115H70.4754V85.887H70V85.4115ZM70 86.1247V86.5998H70.4754V86.1247H70ZM70.7132 86.1247V86.5998H71.1883V86.1247H70.7132ZM71.1883 85.887V85.4115H70.7132V85.887L71.1883 85.887ZM70.535 85.5749H70.6538V85.9017H70.6238V86.1099H70.6538V86.4366H70.535V86.1099H70.5644V85.9017H70.535V85.5749ZM70.1631 85.9465V86.0653H70.4899V85.9465H70.1631ZM70.6983 86.0653V85.9465H71.0251V86.0653H70.6983ZM71.8871 84H71.4117V84.4755L71.8871 84.4755V84ZM71.4117 85.1883V84.7132H71.8871V85.1883H71.4117ZM72.1248 85.1883V84.7132H72.6V85.1883H72.1248ZM72.6 84V84.4755L72.1248 84.4755V84H72.6ZM72.0655 84.1634H71.9467V84.4902H71.9761V84.6984H71.9467V85.0252H72.0655V84.6984H72.0355V84.4902H72.0655V84.1634ZM71.5752 84.6538V84.535H71.902V84.6538H71.5752ZM72.1097 84.535V84.6538H72.4365V84.535H72.1097ZM71.4117 85.4116H71.8871V85.887L71.4117 85.887V85.4116ZM71.4117 86.1247V86.5999H71.8871V86.1247H71.4117ZM72.1248 86.1247V86.5999H72.6V86.1247H72.1248ZM72.6 85.887V85.4116H72.1248V85.887H72.6ZM71.9467 85.575H72.0655V85.9018H72.0355V86.1099H72.0655V86.4367H71.9467V86.1099H71.9761V85.9018H71.9467V85.575ZM71.5752 85.9465V86.0653H71.902V85.9465H71.5752ZM72.1097 86.0653V85.9465H72.4365V86.0653H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 84H73.5756V84.4754L73.1001 84.4754V84ZM73.1001 84.7131V85.1883H73.5756V84.7131H73.1001ZM73.8133 84.7131V85.1883H74.2884V84.7131H73.8133ZM74.2884 84.4754V84H73.8133V84.4754L74.2884 84.4754ZM73.6351 84.1634H73.7539V84.4902H73.7239V84.6984H73.7539V85.0251H73.6351V84.6984H73.6645V84.4902H73.6351V84.1634ZM73.2632 84.535V84.6538H73.59V84.535H73.2632ZM73.7984 84.6538V84.535H74.1252V84.6538H73.7984ZM73.1001 85.4115H73.5755V85.887H73.1001V85.4115ZM73.1001 86.1247V86.5998H73.5755V86.1247H73.1001ZM73.8133 86.1247V86.5998H74.2884V86.1247H73.8133ZM74.2884 85.887V85.4115H73.8133V85.887L74.2884 85.887ZM73.6351 85.5749H73.7539V85.9017H73.7239V86.1099H73.7539V86.4366H73.6351V86.1099H73.6645V85.9017H73.6351V85.5749ZM73.2632 85.9465V86.0653H73.59V85.9465H73.2632ZM73.7984 86.0653V85.9465H74.1252V86.0653H73.7984ZM74.9872 84H74.5118V84.4755L74.9872 84.4755V84ZM74.5118 85.1883V84.7132H74.9872V85.1883H74.5118ZM75.2249 85.1883V84.7132H75.7001V85.1883H75.2249ZM75.7001 84V84.4755L75.2249 84.4755V84H75.7001ZM75.1656 84.1634H75.0468V84.4902H75.0762V84.6984H75.0468V85.0252H75.1656V84.6984H75.1356V84.4902H75.1656V84.1634ZM74.6753 84.6538V84.535H75.0021V84.6538H74.6753ZM75.2098 84.535V84.6538H75.5366V84.535H75.2098ZM74.5118 85.4116H74.9872V85.887L74.5118 85.887V85.4116ZM74.5118 86.1247V86.5999H74.9872V86.1247H74.5118ZM75.2249 86.1247V86.5999H75.7001V86.1247H75.2249ZM75.7001 85.887V85.4116H75.2249V85.887H75.7001ZM75.0468 85.575H75.1656V85.9018H75.1356V86.1099H75.1656V86.4367H75.0468V86.1099H75.0762V85.9018H75.0468V85.575ZM74.6753 85.9465V86.0653H75.0021V85.9465H74.6753ZM75.2098 86.0653V85.9465H75.5366V86.0653H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 87.0996H64.2753V87.575L63.7998 87.575V87.0996ZM63.7998 87.8127V88.2879H64.2753V87.8127H63.7998ZM64.513 87.8127V88.2879H64.9881V87.8127H64.513ZM64.9881 87.575V87.0996H64.513V87.575L64.9881 87.575ZM64.3348 87.263H64.4536V87.5898H64.4236V87.798H64.4536V88.1248H64.3348V87.798H64.3642V87.5898H64.3348V87.263ZM63.9629 87.6346V87.7534H64.2897V87.6346H63.9629ZM64.4981 87.7534V87.6346H64.8249V87.7534H64.4981ZM63.7998 88.5112H64.2753V88.9866H63.7998V88.5112ZM63.7998 89.2243V89.6994H64.2753V89.2243H63.7998ZM64.513 89.2243V89.6994H64.9881V89.2243H64.513ZM64.9881 88.9866V88.5112H64.513V88.9866L64.9881 88.9866ZM64.3348 88.6746H64.4536V89.0013H64.4236V89.2095H64.4536V89.5363H64.3348V89.2095H64.3642V89.0013H64.3348V88.6746ZM63.9629 89.0461V89.1649H64.2897V89.0461H63.9629ZM64.4981 89.1649V89.0461H64.8249V89.1649H64.4981ZM65.6869 87.0996H65.2115V87.5751L65.6869 87.5751V87.0996ZM65.2115 88.2879V87.8128H65.6869V88.2879H65.2115ZM65.9246 88.2879V87.8128H66.3998V88.2879H65.9246ZM66.3998 87.0996V87.5751L65.9246 87.5751V87.0996H66.3998ZM65.8653 87.263H65.7465V87.5898H65.7759V87.798H65.7465V88.1248H65.8653V87.798H65.8353V87.5898H65.8653V87.263ZM65.375 87.7534V87.6346H65.7018V87.7534H65.375ZM65.9095 87.6346V87.7534H66.2363V87.6346H65.9095ZM65.2115 88.5112H65.6869V88.9866L65.2115 88.9866V88.5112ZM65.2115 89.2243V89.6995H65.6869V89.2243H65.2115ZM65.9246 89.2243V89.6995H66.3998V89.2243H65.9246ZM66.3998 88.9866V88.5112H65.9246V88.9866H66.3998ZM65.7465 88.6746H65.8653V89.0014H65.8353V89.2095H65.8653V89.5363H65.7465V89.2095H65.7759V89.0014H65.7465V88.6746ZM65.375 89.0461V89.1649H65.7018V89.0461H65.375ZM65.9095 89.1649V89.0461H66.2363V89.1649H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 87.0996H67.3754V87.575L66.8999 87.575V87.0996ZM66.8999 87.8127V88.2879H67.3754V87.8127H66.8999ZM67.6131 87.8127V88.2879H68.0882V87.8127H67.6131ZM68.0882 87.575V87.0996H67.6131V87.575L68.0882 87.575ZM67.4349 87.263H67.5537V87.5898H67.5237V87.798H67.5537V88.1248H67.4349V87.798H67.4643V87.5898H67.4349V87.263ZM67.063 87.6346V87.7534H67.3898V87.6346H67.063ZM67.5982 87.7534V87.6346H67.925V87.7534H67.5982ZM66.8999 88.5112H67.3753V88.9866H66.8999V88.5112ZM66.8999 89.2243V89.6994H67.3753V89.2243H66.8999ZM67.6131 89.2243V89.6994H68.0882V89.2243H67.6131ZM68.0882 88.9866V88.5112H67.6131V88.9866L68.0882 88.9866ZM67.4349 88.6746H67.5537V89.0013H67.5237V89.2095H67.5537V89.5363H67.4349V89.2095H67.4643V89.0013H67.4349V88.6746ZM67.063 89.0461V89.1649H67.3898V89.0461H67.063ZM67.5982 89.1649V89.0461H67.925V89.1649H67.5982ZM68.787 87.0996H68.3116V87.5751L68.787 87.5751V87.0996ZM68.3116 88.2879V87.8128H68.787V88.2879H68.3116ZM69.0247 88.2879V87.8128H69.4999V88.2879H69.0247ZM69.4999 87.0996V87.5751L69.0247 87.5751V87.0996H69.4999ZM68.9654 87.263H68.8466V87.5898H68.876V87.798H68.8466V88.1248H68.9654V87.798H68.9354V87.5898H68.9654V87.263ZM68.4751 87.7534V87.6346H68.8019V87.7534H68.4751ZM69.0096 87.6346V87.7534H69.3364V87.6346H69.0096ZM68.3116 88.5112H68.787V88.9866L68.3116 88.9866V88.5112ZM68.3116 89.2243V89.6995H68.787V89.2243H68.3116ZM69.0247 89.2243V89.6995H69.4999V89.2243H69.0247ZM69.4999 88.9866V88.5112H69.0247V88.9866H69.4999ZM68.8466 88.6746H68.9654V89.0014H68.9354V89.2095H68.9654V89.5363H68.8466V89.2095H68.876V89.0014H68.8466V88.6746ZM68.4751 89.0461V89.1649H68.8019V89.0461H68.4751ZM69.0096 89.1649V89.0461H69.3364V89.1649H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 87.0996H70.4755V87.575L70 87.575V87.0996ZM70 87.8127V88.2879H70.4755V87.8127H70ZM70.7132 87.8127V88.2879H71.1883V87.8127H70.7132ZM71.1883 87.575V87.0996H70.7132V87.575L71.1883 87.575ZM70.535 87.263H70.6538V87.5898H70.6238V87.798H70.6538V88.1248H70.535V87.798H70.5644V87.5898H70.535V87.263ZM70.1631 87.6346V87.7534H70.4899V87.6346H70.1631ZM70.6983 87.7534V87.6346H71.0251V87.7534H70.6983ZM70 88.5112H70.4754V88.9866H70V88.5112ZM70 89.2243V89.6994H70.4754V89.2243H70ZM70.7132 89.2243V89.6994H71.1883V89.2243H70.7132ZM71.1883 88.9866V88.5112H70.7132V88.9866L71.1883 88.9866ZM70.535 88.6746H70.6538V89.0013H70.6238V89.2095H70.6538V89.5363H70.535V89.2095H70.5644V89.0013H70.535V88.6746ZM70.1631 89.0461V89.1649H70.4899V89.0461H70.1631ZM70.6983 89.1649V89.0461H71.0251V89.1649H70.6983ZM71.8871 87.0996H71.4117V87.5751L71.8871 87.5751V87.0996ZM71.4117 88.2879V87.8128H71.8871V88.2879H71.4117ZM72.1248 88.2879V87.8128H72.6V88.2879H72.1248ZM72.6 87.0996V87.5751L72.1248 87.5751V87.0996H72.6ZM72.0655 87.263H71.9467V87.5898H71.9761V87.798H71.9467V88.1248H72.0655V87.798H72.0355V87.5898H72.0655V87.263ZM71.5752 87.7534V87.6346H71.902V87.7534H71.5752ZM72.1097 87.6346V87.7534H72.4365V87.6346H72.1097ZM71.4117 88.5112H71.8871V88.9866L71.4117 88.9866V88.5112ZM71.4117 89.2243V89.6995H71.8871V89.2243H71.4117ZM72.1248 89.2243V89.6995H72.6V89.2243H72.1248ZM72.6 88.9866V88.5112H72.1248V88.9866H72.6ZM71.9467 88.6746H72.0655V89.0014H72.0355V89.2095H72.0655V89.5363H71.9467V89.2095H71.9761V89.0014H71.9467V88.6746ZM71.5752 89.0461V89.1649H71.902V89.0461H71.5752ZM72.1097 89.1649V89.0461H72.4365V89.1649H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 87.0996H73.5756V87.575L73.1001 87.575V87.0996ZM73.1001 87.8127V88.2879H73.5756V87.8127H73.1001ZM73.8133 87.8127V88.2879H74.2884V87.8127H73.8133ZM74.2884 87.575V87.0996H73.8133V87.575L74.2884 87.575ZM73.6351 87.263H73.7539V87.5898H73.7239V87.798H73.7539V88.1248H73.6351V87.798H73.6645V87.5898H73.6351V87.263ZM73.2632 87.6346V87.7534H73.59V87.6346H73.2632ZM73.7984 87.7534V87.6346H74.1252V87.7534H73.7984ZM73.1001 88.5112H73.5755V88.9866H73.1001V88.5112ZM73.1001 89.2243V89.6994H73.5755V89.2243H73.1001ZM73.8133 89.2243V89.6994H74.2884V89.2243H73.8133ZM74.2884 88.9866V88.5112H73.8133V88.9866L74.2884 88.9866ZM73.6351 88.6746H73.7539V89.0013H73.7239V89.2095H73.7539V89.5363H73.6351V89.2095H73.6645V89.0013H73.6351V88.6746ZM73.2632 89.0461V89.1649H73.59V89.0461H73.2632ZM73.7984 89.1649V89.0461H74.1252V89.1649H73.7984ZM74.9872 87.0996H74.5118V87.5751L74.9872 87.5751V87.0996ZM74.5118 88.2879V87.8128H74.9872V88.2879H74.5118ZM75.2249 88.2879V87.8128H75.7001V88.2879H75.2249ZM75.7001 87.0996V87.5751L75.2249 87.5751V87.0996H75.7001ZM75.1656 87.263H75.0468V87.5898H75.0762V87.798H75.0468V88.1248H75.1656V87.798H75.1356V87.5898H75.1656V87.263ZM74.6753 87.7534V87.6346H75.0021V87.7534H74.6753ZM75.2098 87.6346V87.7534H75.5366V87.6346H75.2098ZM74.5118 88.5112H74.9872V88.9866L74.5118 88.9866V88.5112ZM74.5118 89.2243V89.6995H74.9872V89.2243H74.5118ZM75.2249 89.2243V89.6995H75.7001V89.2243H75.2249ZM75.7001 88.9866V88.5112H75.2249V88.9866H75.7001ZM75.0468 88.6746H75.1656V89.0014H75.1356V89.2095H75.1656V89.5363H75.0468V89.2095H75.0762V89.0014H75.0468V88.6746ZM74.6753 89.0461V89.1649H75.0021V89.0461H74.6753ZM75.2098 89.1649V89.0461H75.5366V89.1649H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 77.7998H76.7753V78.2752L76.2998 78.2752V77.7998ZM76.2998 78.5129V78.9881H76.7753V78.5129H76.2998ZM77.013 78.5129V78.9881H77.4881V78.5129H77.013ZM77.4881 78.2752V77.7998H77.013V78.2752L77.4881 78.2752ZM76.8348 77.9632H76.9536V78.29H76.9236V78.4982H76.9536V78.825H76.8348V78.4982H76.8642V78.29H76.8348V77.9632ZM76.4629 78.3348V78.4536H76.7897V78.3348H76.4629ZM76.9981 78.4536V78.3348H77.3249V78.4536H76.9981ZM76.2998 79.2113H76.7753V79.6868H76.2998V79.2113ZM76.2998 79.9245V80.3996H76.7753V79.9245H76.2998ZM77.013 79.9245V80.3996H77.4881V79.9245H77.013ZM77.4881 79.6868V79.2113H77.013V79.6868H77.4881ZM76.8348 79.3747H76.9536V79.7015H76.9236V79.9097H76.9536V80.2364H76.8348V79.9097H76.8642V79.7015H76.8348V79.3747ZM76.4629 79.7463V79.8651H76.7897V79.7463H76.4629ZM76.9981 79.8651V79.7463H77.3249V79.8651H76.9981ZM78.1869 77.7998H77.7115V78.2753L78.1869 78.2753V77.7998ZM77.7115 78.9881V78.513H78.1869V78.9881H77.7115ZM78.4246 78.9881V78.513H78.8998V78.9881H78.4246ZM78.8998 77.7998V78.2753L78.4246 78.2753V77.7998H78.8998ZM78.3653 77.9632H78.2465V78.29H78.2759V78.4982H78.2465V78.825H78.3653V78.4982H78.3353V78.29H78.3653V77.9632ZM77.875 78.4536V78.3348H78.2018V78.4536H77.875ZM78.4095 78.3348V78.4536H78.7363V78.3348H78.4095ZM77.7115 79.2114H78.1869V79.6868H77.7115V79.2114ZM77.7115 79.9245V80.3997H78.1869V79.9245H77.7115ZM78.4246 79.9245V80.3997H78.8998V79.9245H78.4246ZM78.8998 79.6868V79.2114H78.4246V79.6868H78.8998ZM78.2465 79.3748H78.3653V79.7016H78.3353V79.9097H78.3653V80.2365H78.2465V79.9097H78.2759V79.7016H78.2465V79.3748ZM77.875 79.7463V79.8651H78.2018V79.7463H77.875ZM78.4095 79.8651V79.7463H78.7363V79.8651H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 77.7998H79.8754V78.2752L79.3999 78.2752V77.7998ZM79.3999 78.5129V78.9881H79.8754V78.5129H79.3999ZM80.1131 78.5129V78.9881H80.5882V78.5129H80.1131ZM80.5882 78.2752V77.7998H80.1131V78.2752L80.5882 78.2752ZM79.9349 77.9632H80.0537V78.29H80.0237V78.4982H80.0537V78.825H79.9349V78.4982H79.9643V78.29H79.9349V77.9632ZM79.563 78.3348V78.4536H79.8898V78.3348H79.563ZM80.0982 78.4536V78.3348H80.425V78.4536H80.0982ZM79.3999 79.2113H79.8753V79.6868H79.3999V79.2113ZM79.3999 79.9245V80.3996H79.8753V79.9245H79.3999ZM80.1131 79.9245V80.3996H80.5882V79.9245H80.1131ZM80.5882 79.6868V79.2113H80.1131V79.6868L80.5882 79.6868ZM79.9349 79.3747H80.0537V79.7015H80.0237V79.9097H80.0537V80.2364H79.9349V79.9097H79.9643V79.7015H79.9349V79.3747ZM79.563 79.7463V79.8651H79.8898V79.7463H79.563ZM80.0982 79.8651V79.7463H80.425V79.8651H80.0982ZM81.287 77.7998H80.8116V78.2753L81.287 78.2753V77.7998ZM80.8116 78.9881V78.513H81.287V78.9881H80.8116ZM81.5247 78.9881V78.513H81.9999V78.9881H81.5247ZM81.9999 77.7998V78.2753L81.5247 78.2753V77.7998H81.9999ZM81.4654 77.9632H81.3466V78.29H81.376V78.4982H81.3466V78.825H81.4654V78.4982H81.4354V78.29H81.4654V77.9632ZM80.9751 78.4536V78.3348H81.3019V78.4536H80.9751ZM81.5096 78.3348V78.4536H81.8364V78.3348H81.5096ZM80.8116 79.2114H81.287V79.6868L80.8116 79.6868V79.2114ZM80.8116 79.9245V80.3997H81.287V79.9245H80.8116ZM81.5247 79.9245V80.3997H81.9999V79.9245H81.5247ZM81.9999 79.6868V79.2114H81.5247V79.6868H81.9999ZM81.3466 79.3748H81.4654V79.7016H81.4354V79.9097H81.4654V80.2365H81.3466V79.9097H81.376V79.7016H81.3466V79.3748ZM80.9751 79.7463V79.8651H81.3019V79.7463H80.9751ZM81.5096 79.8651V79.7463H81.8364V79.8651H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 77.7998H82.9755V78.2752L82.5 78.2752V77.7998ZM82.5 78.5129V78.9881H82.9755V78.5129H82.5ZM83.2132 78.5129V78.9881H83.6883V78.5129H83.2132ZM83.6883 78.2752V77.7998H83.2132V78.2752L83.6883 78.2752ZM83.035 77.9632H83.1538V78.29H83.1238V78.4982H83.1538V78.825H83.035V78.4982H83.0644V78.29H83.035V77.9632ZM82.6631 78.3348V78.4536H82.9899V78.3348H82.6631ZM83.1983 78.4536V78.3348H83.5251V78.4536H83.1983ZM82.5 79.2113H82.9754V79.6868H82.5V79.2113ZM82.5 79.9245V80.3996H82.9754V79.9245H82.5ZM83.2132 79.9245V80.3996H83.6883V79.9245H83.2132ZM83.6883 79.6868V79.2113H83.2132V79.6868L83.6883 79.6868ZM83.035 79.3747H83.1538V79.7015H83.1238V79.9097H83.1538V80.2364H83.035V79.9097H83.0644V79.7015H83.035V79.3747ZM82.6631 79.7463V79.8651H82.9899V79.7463H82.6631ZM83.1983 79.8651V79.7463H83.5251V79.8651H83.1983ZM84.3871 77.7998H83.9117V78.2753L84.3871 78.2753V77.7998ZM83.9117 78.9881V78.513H84.3871V78.9881H83.9117ZM84.6248 78.9881V78.513H85.1V78.9881H84.6248ZM85.1 77.7998V78.2753L84.6248 78.2753V77.7998H85.1ZM84.5655 77.9632H84.4467V78.29H84.4761V78.4982H84.4467V78.825H84.5655V78.4982H84.5355V78.29H84.5655V77.9632ZM84.0752 78.4536V78.3348H84.402V78.4536H84.0752ZM84.6097 78.3348V78.4536H84.9365V78.3348H84.6097ZM83.9117 79.2114H84.3871V79.6868L83.9117 79.6868V79.2114ZM83.9117 79.9245V80.3997H84.3871V79.9245H83.9117ZM84.6248 79.9245V80.3997H85.1V79.9245H84.6248ZM85.1 79.6868V79.2114H84.6248V79.6868H85.1ZM84.4467 79.3748H84.5655V79.7016H84.5355V79.9097H84.5655V80.2365H84.4467V79.9097H84.4761V79.7016H84.4467V79.3748ZM84.0752 79.7463V79.8651H84.402V79.7463H84.0752ZM84.6097 79.8651V79.7463H84.9365V79.8651H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 77.7998H86.0756V78.2752L85.6001 78.2752V77.7998ZM85.6001 78.5129V78.9881H86.0756V78.5129H85.6001ZM86.3133 78.5129V78.9881H86.7884V78.5129H86.3133ZM86.7884 78.2752V77.7998H86.3133V78.2752L86.7884 78.2752ZM86.1351 77.9632H86.2539V78.29H86.2239V78.4982H86.2539V78.825H86.1351V78.4982H86.1645V78.29H86.1351V77.9632ZM85.7632 78.3348V78.4536H86.09V78.3348H85.7632ZM86.2984 78.4536V78.3348H86.6252V78.4536H86.2984ZM85.6001 79.2113H86.0755V79.6868H85.6001V79.2113ZM85.6001 79.9245V80.3996H86.0755V79.9245H85.6001ZM86.3133 79.9245V80.3996H86.7884V79.9245H86.3133ZM86.7884 79.6868V79.2113H86.3133V79.6868L86.7884 79.6868ZM86.1351 79.3747H86.2539V79.7015H86.2239V79.9097H86.2539V80.2364H86.1351V79.9097H86.1645V79.7015H86.1351V79.3747ZM85.7632 79.7463V79.8651H86.09V79.7463H85.7632ZM86.2984 79.8651V79.7463H86.6252V79.8651H86.2984ZM87.4872 77.7998H87.0118V78.2753L87.4872 78.2753V77.7998ZM87.0118 78.9881V78.513H87.4872V78.9881H87.0118ZM87.7249 78.9881V78.513H88.2001V78.9881H87.7249ZM88.2001 77.7998V78.2753L87.7249 78.2753V77.7998H88.2001ZM87.6656 77.9632H87.5468V78.29H87.5762V78.4982H87.5468V78.825H87.6656V78.4982H87.6356V78.29H87.6656V77.9632ZM87.1753 78.4536V78.3348H87.5021V78.4536H87.1753ZM87.7098 78.3348V78.4536H88.0366V78.3348H87.7098ZM87.0118 79.2114H87.4872V79.6868L87.0118 79.6868V79.2114ZM87.0118 79.9245V80.3997H87.4872V79.9245H87.0118ZM87.7249 79.9245V80.3997H88.2001V79.9245H87.7249ZM88.2001 79.6868V79.2114H87.7249V79.6868H88.2001ZM87.5468 79.3748H87.6656V79.7016H87.6356V79.9097H87.6656V80.2365H87.5468V79.9097H87.5762V79.7016H87.5468V79.3748ZM87.1753 79.7463V79.8651H87.5021V79.7463H87.1753ZM87.7098 79.8651V79.7463H88.0366V79.8651H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 80.9004H76.7753V81.3758L76.2998 81.3758V80.9004ZM76.2998 81.6135V82.0887H76.7753V81.6135H76.2998ZM77.013 81.6135V82.0887H77.4881V81.6135H77.013ZM77.4881 81.3758V80.9004H77.013V81.3758L77.4881 81.3758ZM76.8348 81.0638H76.9536V81.3906H76.9236V81.5988H76.9536V81.9255H76.8348V81.5988H76.8642V81.3906H76.8348V81.0638ZM76.4629 81.4353V81.5542H76.7897V81.4353H76.4629ZM76.9981 81.5542V81.4354H77.3249V81.5542H76.9981ZM76.2998 82.3119H76.7753V82.7874H76.2998V82.3119ZM76.2998 83.0251V83.5002H76.7753V83.0251H76.2998ZM77.013 83.0251V83.5002H77.4881V83.0251H77.013ZM77.4881 82.7874V82.3119H77.013V82.7874L77.4881 82.7874ZM76.8348 82.4753H76.9536V82.8021H76.9236V83.0103H76.9536V83.337H76.8348V83.0103H76.8642V82.8021H76.8348V82.4753ZM76.4629 82.8468V82.9657H76.7897V82.8468H76.4629ZM76.9981 82.9657V82.8469H77.3249V82.9657H76.9981ZM78.1869 80.9004H77.7115V81.3759L78.1869 81.3759V80.9004ZM77.7115 82.0887V81.6136H78.1869V82.0887H77.7115ZM78.4246 82.0887V81.6136H78.8998V82.0887H78.4246ZM78.8998 80.9004V81.3759L78.4246 81.3759V80.9004H78.8998ZM78.3653 81.0638H78.2465V81.3906H78.2759V81.5988H78.2465V81.9256H78.3653V81.5988H78.3353V81.3906H78.3653V81.0638ZM77.875 81.5542V81.4354H78.2018V81.5542H77.875ZM78.4095 81.4354V81.5542H78.7363V81.4354H78.4095ZM77.7115 82.312H78.1869V82.7874L77.7115 82.7874V82.312ZM77.7115 83.0251V83.5003H78.1869V83.0251H77.7115ZM78.4246 83.0251V83.5003H78.8998V83.0251H78.4246ZM78.8998 82.7874V82.312H78.4246V82.7874H78.8998ZM78.2465 82.4754H78.3653V82.8021H78.3353V83.0103H78.3653V83.3371H78.2465V83.0103H78.2759V82.8021H78.2465V82.4754ZM77.875 82.8469V82.9657H78.2018V82.8469H77.875ZM78.4095 82.9657V82.8469H78.7363V82.9657H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 80.9004H79.8754V81.3758L79.3999 81.3758V80.9004ZM79.3999 81.6135V82.0887H79.8754V81.6135H79.3999ZM80.1131 81.6135V82.0887H80.5882V81.6135H80.1131ZM80.5882 81.3758V80.9004H80.1131V81.3758L80.5882 81.3758ZM79.9349 81.0638H80.0537V81.3906H80.0237V81.5988H80.0537V81.9255H79.9349V81.5988H79.9643V81.3906H79.9349V81.0638ZM79.563 81.4353V81.5542H79.8898V81.4353H79.563ZM80.0982 81.5542V81.4354H80.425V81.5542H80.0982ZM79.3999 82.3119H79.8753V82.7874H79.3999V82.3119ZM79.3999 83.0251V83.5002H79.8753V83.0251H79.3999ZM80.1131 83.0251V83.5002H80.5882V83.0251H80.1131ZM80.5882 82.7874V82.3119H80.1131V82.7874L80.5882 82.7874ZM79.9349 82.4753H80.0537V82.8021H80.0237V83.0103H80.0537V83.337H79.9349V83.0103H79.9643V82.8021H79.9349V82.4753ZM79.563 82.8468V82.9657H79.8898V82.8468H79.563ZM80.0982 82.9657V82.8469H80.425V82.9657H80.0982ZM81.287 80.9004H80.8116V81.3759L81.287 81.3759V80.9004ZM80.8116 82.0887V81.6136H81.287V82.0887H80.8116ZM81.5247 82.0887V81.6136H81.9999V82.0887H81.5247ZM81.9999 80.9004V81.3759L81.5247 81.3759V80.9004H81.9999ZM81.4654 81.0638H81.3466V81.3906H81.376V81.5988H81.3466V81.9256H81.4654V81.5988H81.4354V81.3906H81.4654V81.0638ZM80.9751 81.5542V81.4354H81.3019V81.5542H80.9751ZM81.5096 81.4354V81.5542H81.8364V81.4354H81.5096ZM80.8116 82.312H81.287V82.7874L80.8116 82.7874V82.312ZM80.8116 83.0251V83.5003H81.287V83.0251H80.8116ZM81.5247 83.0251V83.5003H81.9999V83.0251H81.5247ZM81.9999 82.7874V82.312H81.5247V82.7874H81.9999ZM81.3466 82.4754H81.4654V82.8021H81.4354V83.0103H81.4654V83.3371H81.3466V83.0103H81.376V82.8021H81.3466V82.4754ZM80.9751 82.8469V82.9657H81.3019V82.8469H80.9751ZM81.5096 82.9657V82.8469H81.8364V82.9657H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 80.9004H82.9755V81.3758L82.5 81.3758V80.9004ZM82.5 81.6135V82.0887H82.9755V81.6135H82.5ZM83.2132 81.6135V82.0887H83.6883V81.6135H83.2132ZM83.6883 81.3758V80.9004H83.2132V81.3758L83.6883 81.3758ZM83.035 81.0638H83.1538V81.3906H83.1238V81.5988H83.1538V81.9255H83.035V81.5988H83.0644V81.3906H83.035V81.0638ZM82.6631 81.4353V81.5542H82.9899V81.4353H82.6631ZM83.1983 81.5542V81.4354H83.5251V81.5542H83.1983ZM82.5 82.3119H82.9754V82.7874H82.5V82.3119ZM82.5 83.0251V83.5002H82.9754V83.0251H82.5ZM83.2132 83.0251V83.5002H83.6883V83.0251H83.2132ZM83.6883 82.7874V82.3119H83.2132V82.7874L83.6883 82.7874ZM83.035 82.4753H83.1538V82.8021H83.1238V83.0103H83.1538V83.337H83.035V83.0103H83.0644V82.8021H83.035V82.4753ZM82.6631 82.8468V82.9657H82.9899V82.8468H82.6631ZM83.1983 82.9657V82.8469H83.5251V82.9657H83.1983ZM84.3871 80.9004H83.9117V81.3759L84.3871 81.3759V80.9004ZM83.9117 82.0887V81.6136H84.3871V82.0887H83.9117ZM84.6248 82.0887V81.6136H85.1V82.0887H84.6248ZM85.1 80.9004V81.3759L84.6248 81.3759V80.9004H85.1ZM84.5655 81.0638H84.4467V81.3906H84.4761V81.5988H84.4467V81.9256H84.5655V81.5988H84.5355V81.3906H84.5655V81.0638ZM84.0752 81.5542V81.4354H84.402V81.5542H84.0752ZM84.6097 81.4354V81.5542H84.9365V81.4354H84.6097ZM83.9117 82.312H84.3871V82.7874L83.9117 82.7874V82.312ZM83.9117 83.0251V83.5003H84.3871V83.0251H83.9117ZM84.6248 83.0251V83.5003H85.1V83.0251H84.6248ZM85.1 82.7874V82.312H84.6248V82.7874H85.1ZM84.4467 82.4754H84.5655V82.8021H84.5355V83.0103H84.5655V83.3371H84.4467V83.0103H84.4761V82.8021H84.4467V82.4754ZM84.0752 82.8469V82.9657H84.402V82.8469H84.0752ZM84.6097 82.9657V82.8469H84.9365V82.9657H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 80.9004H86.0756V81.3758L85.6001 81.3758V80.9004ZM85.6001 81.6135V82.0887H86.0756V81.6135H85.6001ZM86.3133 81.6135V82.0887H86.7884V81.6135H86.3133ZM86.7884 81.3758V80.9004H86.3133V81.3758L86.7884 81.3758ZM86.1351 81.0638H86.2539V81.3906H86.2239V81.5988H86.2539V81.9255H86.1351V81.5988H86.1645V81.3906H86.1351V81.0638ZM85.7632 81.4353V81.5542H86.09V81.4353H85.7632ZM86.2984 81.5542V81.4354H86.6252V81.5542H86.2984ZM85.6001 82.3119H86.0755V82.7874H85.6001V82.3119ZM85.6001 83.0251V83.5002H86.0755V83.0251H85.6001ZM86.3133 83.0251V83.5002H86.7884V83.0251H86.3133ZM86.7884 82.7874V82.3119H86.3133V82.7874L86.7884 82.7874ZM86.1351 82.4753H86.2539V82.8021H86.2239V83.0103H86.2539V83.337H86.1351V83.0103H86.1645V82.8021H86.1351V82.4753ZM85.7632 82.8468V82.9657H86.09V82.8468H85.7632ZM86.2984 82.9657V82.8469H86.6252V82.9657H86.2984ZM87.4872 80.9004H87.0118V81.3759L87.4872 81.3759V80.9004ZM87.0118 82.0887V81.6136H87.4872V82.0887H87.0118ZM87.7249 82.0887V81.6136H88.2001V82.0887H87.7249ZM88.2001 80.9004V81.3759L87.7249 81.3759V80.9004H88.2001ZM87.6656 81.0638H87.5468V81.3906H87.5762V81.5988H87.5468V81.9256H87.6656V81.5988H87.6356V81.3906H87.6656V81.0638ZM87.1753 81.5542V81.4354H87.5021V81.5542H87.1753ZM87.7098 81.4354V81.5542H88.0366V81.4354H87.7098ZM87.0118 82.312H87.4872V82.7874L87.0118 82.7874V82.312ZM87.0118 83.0251V83.5003H87.4872V83.0251H87.0118ZM87.7249 83.0251V83.5003H88.2001V83.0251H87.7249ZM88.2001 82.7874V82.312H87.7249V82.7874H88.2001ZM87.5468 82.4754H87.6656V82.8021H87.6356V83.0103H87.6656V83.3371H87.5468V83.0103H87.5762V82.8021H87.5468V82.4754ZM87.1753 82.8469V82.9657H87.5021V82.8469H87.1753ZM87.7098 82.9657V82.8469H88.0366V82.9657H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 84H76.7753V84.4754L76.2998 84.4754V84ZM76.2998 84.7131V85.1883H76.7753V84.7131H76.2998ZM77.013 84.7131V85.1883H77.4881V84.7131H77.013ZM77.4881 84.4754V84H77.013V84.4754L77.4881 84.4754ZM76.8348 84.1634H76.9536V84.4902H76.9236V84.6984H76.9536V85.0251H76.8348V84.6984H76.8642V84.4902H76.8348V84.1634ZM76.4629 84.535V84.6538H76.7897V84.535H76.4629ZM76.9981 84.6538V84.535H77.3249V84.6538H76.9981ZM76.2998 85.4115H76.7753V85.887H76.2998V85.4115ZM76.2998 86.1247V86.5998H76.7753V86.1247H76.2998ZM77.013 86.1247V86.5998H77.4881V86.1247H77.013ZM77.4881 85.887V85.4115H77.013V85.887L77.4881 85.887ZM76.8348 85.5749H76.9536V85.9017H76.9236V86.1099H76.9536V86.4366H76.8348V86.1099H76.8642V85.9017H76.8348V85.5749ZM76.4629 85.9465V86.0653H76.7897V85.9465H76.4629ZM76.9981 86.0653V85.9465H77.3249V86.0653H76.9981ZM78.1869 84H77.7115V84.4755L78.1869 84.4755V84ZM77.7115 85.1883V84.7132H78.1869V85.1883H77.7115ZM78.4246 85.1883V84.7132H78.8998V85.1883H78.4246ZM78.8998 84V84.4755L78.4246 84.4755V84H78.8998ZM78.3653 84.1634H78.2465V84.4902H78.2759V84.6984H78.2465V85.0252H78.3653V84.6984H78.3353V84.4902H78.3653V84.1634ZM77.875 84.6538V84.535H78.2018V84.6538H77.875ZM78.4095 84.535V84.6538H78.7363V84.535H78.4095ZM77.7115 85.4116H78.1869V85.887L77.7115 85.887V85.4116ZM77.7115 86.1247V86.5999H78.1869V86.1247H77.7115ZM78.4246 86.1247V86.5999H78.8998V86.1247H78.4246ZM78.8998 85.887V85.4116H78.4246V85.887H78.8998ZM78.2465 85.575H78.3653V85.9018H78.3353V86.1099H78.3653V86.4367H78.2465V86.1099H78.2759V85.9018H78.2465V85.575ZM77.875 85.9465V86.0653H78.2018V85.9465H77.875ZM78.4095 86.0653V85.9465H78.7363V86.0653H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 84H79.8754V84.4754L79.3999 84.4754V84ZM79.3999 84.7131V85.1883H79.8754V84.7131H79.3999ZM80.1131 84.7131V85.1883H80.5882V84.7131H80.1131ZM80.5882 84.4754V84H80.1131V84.4754L80.5882 84.4754ZM79.9349 84.1634H80.0537V84.4902H80.0237V84.6984H80.0537V85.0251H79.9349V84.6984H79.9643V84.4902H79.9349V84.1634ZM79.563 84.535V84.6538H79.8898V84.535H79.563ZM80.0982 84.6538V84.535H80.425V84.6538H80.0982ZM79.3999 85.4115H79.8753V85.887H79.3999V85.4115ZM79.3999 86.1247V86.5998H79.8753V86.1247H79.3999ZM80.1131 86.1247V86.5998H80.5882V86.1247H80.1131ZM80.5882 85.887V85.4115H80.1131V85.887L80.5882 85.887ZM79.9349 85.5749H80.0537V85.9017H80.0237V86.1099H80.0537V86.4366H79.9349V86.1099H79.9643V85.9017H79.9349V85.5749ZM79.563 85.9465V86.0653H79.8898V85.9465H79.563ZM80.0982 86.0653V85.9465H80.425V86.0653H80.0982ZM81.287 84H80.8116V84.4755L81.287 84.4755V84ZM80.8116 85.1883V84.7132H81.287V85.1883H80.8116ZM81.5247 85.1883V84.7132H81.9999V85.1883H81.5247ZM81.9999 84V84.4755L81.5247 84.4755V84H81.9999ZM81.4654 84.1634H81.3466V84.4902H81.376V84.6984H81.3466V85.0252H81.4654V84.6984H81.4354V84.4902H81.4654V84.1634ZM80.9751 84.6538V84.535H81.3019V84.6538H80.9751ZM81.5096 84.535V84.6538H81.8364V84.535H81.5096ZM80.8116 85.4116H81.287V85.887L80.8116 85.887V85.4116ZM80.8116 86.1247V86.5999H81.287V86.1247H80.8116ZM81.5247 86.1247V86.5999H81.9999V86.1247H81.5247ZM81.9999 85.887V85.4116H81.5247V85.887H81.9999ZM81.3466 85.575H81.4654V85.9018H81.4354V86.1099H81.4654V86.4367H81.3466V86.1099H81.376V85.9018H81.3466V85.575ZM80.9751 85.9465V86.0653H81.3019V85.9465H80.9751ZM81.5096 86.0653V85.9465H81.8364V86.0653H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 84H82.9755V84.4754L82.5 84.4754V84ZM82.5 84.7131V85.1883H82.9755V84.7131H82.5ZM83.2132 84.7131V85.1883H83.6883V84.7131H83.2132ZM83.6883 84.4754V84H83.2132V84.4754L83.6883 84.4754ZM83.035 84.1634H83.1538V84.4902H83.1238V84.6984H83.1538V85.0251H83.035V84.6984H83.0644V84.4902H83.035V84.1634ZM82.6631 84.535V84.6538H82.9899V84.535H82.6631ZM83.1983 84.6538V84.535H83.5251V84.6538H83.1983ZM82.5 85.4115H82.9754V85.887H82.5V85.4115ZM82.5 86.1247V86.5998H82.9754V86.1247H82.5ZM83.2132 86.1247V86.5998H83.6883V86.1247H83.2132ZM83.6883 85.887V85.4115H83.2132V85.887L83.6883 85.887ZM83.035 85.5749H83.1538V85.9017H83.1238V86.1099H83.1538V86.4366H83.035V86.1099H83.0644V85.9017H83.035V85.5749ZM82.6631 85.9465V86.0653H82.9899V85.9465H82.6631ZM83.1983 86.0653V85.9465H83.5251V86.0653H83.1983ZM84.3871 84H83.9117V84.4755L84.3871 84.4755V84ZM83.9117 85.1883V84.7132H84.3871V85.1883H83.9117ZM84.6248 85.1883V84.7132H85.1V85.1883H84.6248ZM85.1 84V84.4755L84.6248 84.4755V84H85.1ZM84.5655 84.1634H84.4467V84.4902H84.4761V84.6984H84.4467V85.0252H84.5655V84.6984H84.5355V84.4902H84.5655V84.1634ZM84.0752 84.6538V84.535H84.402V84.6538H84.0752ZM84.6097 84.535V84.6538H84.9365V84.535H84.6097ZM83.9117 85.4116H84.3871V85.887L83.9117 85.887V85.4116ZM83.9117 86.1247V86.5999H84.3871V86.1247H83.9117ZM84.6248 86.1247V86.5999H85.1V86.1247H84.6248ZM85.1 85.887V85.4116H84.6248V85.887H85.1ZM84.4467 85.575H84.5655V85.9018H84.5355V86.1099H84.5655V86.4367H84.4467V86.1099H84.4761V85.9018H84.4467V85.575ZM84.0752 85.9465V86.0653H84.402V85.9465H84.0752ZM84.6097 86.0653V85.9465H84.9365V86.0653H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 84H86.0756V84.4754L85.6001 84.4754V84ZM85.6001 84.7131V85.1883H86.0756V84.7131H85.6001ZM86.3133 84.7131V85.1883H86.7884V84.7131H86.3133ZM86.7884 84.4754V84H86.3133V84.4754L86.7884 84.4754ZM86.1351 84.1634H86.2539V84.4902H86.2239V84.6984H86.2539V85.0251H86.1351V84.6984H86.1645V84.4902H86.1351V84.1634ZM85.7632 84.535V84.6538H86.09V84.535H85.7632ZM86.2984 84.6538V84.535H86.6252V84.6538H86.2984ZM85.6001 85.4115H86.0755V85.887H85.6001V85.4115ZM85.6001 86.1247V86.5998H86.0755V86.1247H85.6001ZM86.3133 86.1247V86.5998H86.7884V86.1247H86.3133ZM86.7884 85.887V85.4115H86.3133V85.887L86.7884 85.887ZM86.1351 85.5749H86.2539V85.9017H86.2239V86.1099H86.2539V86.4366H86.1351V86.1099H86.1645V85.9017H86.1351V85.5749ZM85.7632 85.9465V86.0653H86.09V85.9465H85.7632ZM86.2984 86.0653V85.9465H86.6252V86.0653H86.2984ZM87.4872 84H87.0118V84.4755L87.4872 84.4755V84ZM87.0118 85.1883V84.7132H87.4872V85.1883H87.0118ZM87.7249 85.1883V84.7132H88.2001V85.1883H87.7249ZM88.2001 84V84.4755L87.7249 84.4755V84H88.2001ZM87.6656 84.1634H87.5468V84.4902H87.5762V84.6984H87.5468V85.0252H87.6656V84.6984H87.6356V84.4902H87.6656V84.1634ZM87.1753 84.6538V84.535H87.5021V84.6538H87.1753ZM87.7098 84.535V84.6538H88.0366V84.535H87.7098ZM87.0118 85.4116H87.4872V85.887L87.0118 85.887V85.4116ZM87.0118 86.1247V86.5999H87.4872V86.1247H87.0118ZM87.7249 86.1247V86.5999H88.2001V86.1247H87.7249ZM88.2001 85.887V85.4116H87.7249V85.887H88.2001ZM87.5468 85.575H87.6656V85.9018H87.6356V86.1099H87.6656V86.4367H87.5468V86.1099H87.5762V85.9018H87.5468V85.575ZM87.1753 85.9465V86.0653H87.5021V85.9465H87.1753ZM87.7098 86.0653V85.9465H88.0366V86.0653H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 87.0996H76.7753V87.575L76.2998 87.575V87.0996ZM76.2998 87.8127V88.2879H76.7753V87.8127H76.2998ZM77.013 87.8127V88.2879H77.4881V87.8127H77.013ZM77.4881 87.575V87.0996H77.013V87.575L77.4881 87.575ZM76.8348 87.263H76.9536V87.5898H76.9236V87.798H76.9536V88.1248H76.8348V87.798H76.8642V87.5898H76.8348V87.263ZM76.4629 87.6346V87.7534H76.7897V87.6346H76.4629ZM76.9981 87.7534V87.6346H77.3249V87.7534H76.9981ZM76.2998 88.5112H76.7753V88.9866H76.2998V88.5112ZM76.2998 89.2243V89.6994H76.7753V89.2243H76.2998ZM77.013 89.2243V89.6994H77.4881V89.2243H77.013ZM77.4881 88.9866V88.5112H77.013V88.9866L77.4881 88.9866ZM76.8348 88.6746H76.9536V89.0013H76.9236V89.2095H76.9536V89.5363H76.8348V89.2095H76.8642V89.0013H76.8348V88.6746ZM76.4629 89.0461V89.1649H76.7897V89.0461H76.4629ZM76.9981 89.1649V89.0461H77.3249V89.1649H76.9981ZM78.1869 87.0996H77.7115V87.5751L78.1869 87.5751V87.0996ZM77.7115 88.2879V87.8128H78.1869V88.2879H77.7115ZM78.4246 88.2879V87.8128H78.8998V88.2879H78.4246ZM78.8998 87.0996V87.5751L78.4246 87.5751V87.0996H78.8998ZM78.3653 87.263H78.2465V87.5898H78.2759V87.798H78.2465V88.1248H78.3653V87.798H78.3353V87.5898H78.3653V87.263ZM77.875 87.7534V87.6346H78.2018V87.7534H77.875ZM78.4095 87.6346V87.7534H78.7363V87.6346H78.4095ZM77.7115 88.5112H78.1869V88.9866L77.7115 88.9866V88.5112ZM77.7115 89.2243V89.6995H78.1869V89.2243H77.7115ZM78.4246 89.2243V89.6995H78.8998V89.2243H78.4246ZM78.8998 88.9866V88.5112H78.4246V88.9866H78.8998ZM78.2465 88.6746H78.3653V89.0014H78.3353V89.2095H78.3653V89.5363H78.2465V89.2095H78.2759V89.0014H78.2465V88.6746ZM77.875 89.0461V89.1649H78.2018V89.0461H77.875ZM78.4095 89.1649V89.0461H78.7363V89.1649H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 87.0996H79.8754V87.575L79.3999 87.575V87.0996ZM79.3999 87.8127V88.2879H79.8754V87.8127H79.3999ZM80.1131 87.8127V88.2879H80.5882V87.8127H80.1131ZM80.5882 87.575V87.0996H80.1131V87.575L80.5882 87.575ZM79.9349 87.263H80.0537V87.5898H80.0237V87.798H80.0537V88.1248H79.9349V87.798H79.9643V87.5898H79.9349V87.263ZM79.563 87.6346V87.7534H79.8898V87.6346H79.563ZM80.0982 87.7534V87.6346H80.425V87.7534H80.0982ZM79.3999 88.5112H79.8753V88.9866H79.3999V88.5112ZM79.3999 89.2243V89.6994H79.8753V89.2243H79.3999ZM80.1131 89.2243V89.6994H80.5882V89.2243H80.1131ZM80.5882 88.9866V88.5112H80.1131V88.9866L80.5882 88.9866ZM79.9349 88.6746H80.0537V89.0013H80.0237V89.2095H80.0537V89.5363H79.9349V89.2095H79.9643V89.0013H79.9349V88.6746ZM79.563 89.0461V89.1649H79.8898V89.0461H79.563ZM80.0982 89.1649V89.0461H80.425V89.1649H80.0982ZM81.287 87.0996H80.8116V87.5751L81.287 87.5751V87.0996ZM80.8116 88.2879V87.8128H81.287V88.2879H80.8116ZM81.5247 88.2879V87.8128H81.9999V88.2879H81.5247ZM81.9999 87.0996V87.5751L81.5247 87.5751V87.0996H81.9999ZM81.4654 87.263H81.3466V87.5898H81.376V87.798H81.3466V88.1248H81.4654V87.798H81.4354V87.5898H81.4654V87.263ZM80.9751 87.7534V87.6346H81.3019V87.7534H80.9751ZM81.5096 87.6346V87.7534H81.8364V87.6346H81.5096ZM80.8116 88.5112H81.287V88.9866L80.8116 88.9866V88.5112ZM80.8116 89.2243V89.6995H81.287V89.2243H80.8116ZM81.5247 89.2243V89.6995H81.9999V89.2243H81.5247ZM81.9999 88.9866V88.5112H81.5247V88.9866H81.9999ZM81.3466 88.6746H81.4654V89.0014H81.4354V89.2095H81.4654V89.5363H81.3466V89.2095H81.376V89.0014H81.3466V88.6746ZM80.9751 89.0461V89.1649H81.3019V89.0461H80.9751ZM81.5096 89.1649V89.0461H81.8364V89.1649H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 87.0996H82.9755V87.575L82.5 87.575V87.0996ZM82.5 87.8127V88.2879H82.9755V87.8127H82.5ZM83.2132 87.8127V88.2879H83.6883V87.8127H83.2132ZM83.6883 87.575V87.0996H83.2132V87.575L83.6883 87.575ZM83.035 87.263H83.1538V87.5898H83.1238V87.798H83.1538V88.1248H83.035V87.798H83.0644V87.5898H83.035V87.263ZM82.6631 87.6346V87.7534H82.9899V87.6346H82.6631ZM83.1983 87.7534V87.6346H83.5251V87.7534H83.1983ZM82.5 88.5112H82.9754V88.9866H82.5V88.5112ZM82.5 89.2243V89.6994H82.9754V89.2243H82.5ZM83.2132 89.2243V89.6994H83.6883V89.2243H83.2132ZM83.6883 88.9866V88.5112H83.2132V88.9866L83.6883 88.9866ZM83.035 88.6746H83.1538V89.0013H83.1238V89.2095H83.1538V89.5363H83.035V89.2095H83.0644V89.0013H83.035V88.6746ZM82.6631 89.0461V89.1649H82.9899V89.0461H82.6631ZM83.1983 89.1649V89.0461H83.5251V89.1649H83.1983ZM84.3871 87.0996H83.9117V87.5751L84.3871 87.5751V87.0996ZM83.9117 88.2879V87.8128H84.3871V88.2879H83.9117ZM84.6248 88.2879V87.8128H85.1V88.2879H84.6248ZM85.1 87.0996V87.5751L84.6248 87.5751V87.0996H85.1ZM84.5655 87.263H84.4467V87.5898H84.4761V87.798H84.4467V88.1248H84.5655V87.798H84.5355V87.5898H84.5655V87.263ZM84.0752 87.7534V87.6346H84.402V87.7534H84.0752ZM84.6097 87.6346V87.7534H84.9365V87.6346H84.6097ZM83.9117 88.5112H84.3871V88.9866L83.9117 88.9866V88.5112ZM83.9117 89.2243V89.6995H84.3871V89.2243H83.9117ZM84.6248 89.2243V89.6995H85.1V89.2243H84.6248ZM85.1 88.9866V88.5112H84.6248V88.9866H85.1ZM84.4467 88.6746H84.5655V89.0014H84.5355V89.2095H84.5655V89.5363H84.4467V89.2095H84.4761V89.0014H84.4467V88.6746ZM84.0752 89.0461V89.1649H84.402V89.0461H84.0752ZM84.6097 89.1649V89.0461H84.9365V89.1649H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 87.0996H86.0756V87.575L85.6001 87.575V87.0996ZM85.6001 87.8127V88.2879H86.0756V87.8127H85.6001ZM86.3133 87.8127V88.2879H86.7884V87.8127H86.3133ZM86.7884 87.575V87.0996H86.3133V87.575L86.7884 87.575ZM86.1351 87.263H86.2539V87.5898H86.2239V87.798H86.2539V88.1248H86.1351V87.798H86.1645V87.5898H86.1351V87.263ZM85.7632 87.6346V87.7534H86.09V87.6346H85.7632ZM86.2984 87.7534V87.6346H86.6252V87.7534H86.2984ZM85.6001 88.5112H86.0755V88.9866H85.6001V88.5112ZM85.6001 89.2243V89.6994H86.0755V89.2243H85.6001ZM86.3133 89.2243V89.6994H86.7884V89.2243H86.3133ZM86.7884 88.9866V88.5112H86.3133V88.9866L86.7884 88.9866ZM86.1351 88.6746H86.2539V89.0013H86.2239V89.2095H86.2539V89.5363H86.1351V89.2095H86.1645V89.0013H86.1351V88.6746ZM85.7632 89.0461V89.1649H86.09V89.0461H85.7632ZM86.2984 89.1649V89.0461H86.6252V89.1649H86.2984ZM87.4872 87.0996H87.0118V87.5751L87.4872 87.5751V87.0996ZM87.0118 88.2879V87.8128H87.4872V88.2879H87.0118ZM87.7249 88.2879V87.8128H88.2001V88.2879H87.7249ZM88.2001 87.0996V87.5751L87.7249 87.5751V87.0996H88.2001ZM87.6656 87.263H87.5468V87.5898H87.5762V87.798H87.5468V88.1248H87.6656V87.798H87.6356V87.5898H87.6656V87.263ZM87.1753 87.7534V87.6346H87.5021V87.7534H87.1753ZM87.7098 87.6346V87.7534H88.0366V87.6346H87.7098ZM87.0118 88.5112H87.4872V88.9866L87.0118 88.9866V88.5112ZM87.0118 89.2243V89.6995H87.4872V89.2243H87.0118ZM87.7249 89.2243V89.6995H88.2001V89.2243H87.7249ZM88.2001 88.9866V88.5112H87.7249V88.9866H88.2001ZM87.5468 88.6746H87.6656V89.0014H87.6356V89.2095H87.6656V89.5363H87.5468V89.2095H87.5762V89.0014H87.5468V88.6746ZM87.1753 89.0461V89.1649H87.5021V89.0461H87.1753ZM87.7098 89.1649V89.0461H88.0366V89.1649H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 77.7998H89.2753V78.2752L88.7998 78.2752V77.7998ZM88.7998 78.5129V78.9881H89.2753V78.5129H88.7998ZM89.513 78.5129V78.9881H89.9881V78.5129H89.513ZM89.9881 78.2752V77.7998H89.513V78.2752L89.9881 78.2752ZM89.3348 77.9632H89.4536V78.29H89.4236V78.4982H89.4536V78.825H89.3348V78.4982H89.3642V78.29H89.3348V77.9632ZM88.9629 78.3348V78.4536H89.2897V78.3348H88.9629ZM89.4981 78.4536V78.3348H89.8249V78.4536H89.4981ZM88.7998 79.2113H89.2753V79.6868H88.7998V79.2113ZM88.7998 79.9245V80.3996H89.2753V79.9245H88.7998ZM89.513 79.9245V80.3996H89.9881V79.9245H89.513ZM89.9881 79.6868V79.2113H89.513V79.6868H89.9881ZM89.3348 79.3747H89.4536V79.7015H89.4236V79.9097H89.4536V80.2364H89.3348V79.9097H89.3642V79.7015H89.3348V79.3747ZM88.9629 79.7463V79.8651H89.2897V79.7463H88.9629ZM89.4981 79.8651V79.7463H89.8249V79.8651H89.4981ZM90.6869 77.7998H90.2115V78.2753L90.6869 78.2753V77.7998ZM90.2115 78.9881V78.513H90.6869V78.9881H90.2115ZM90.9246 78.9881V78.513H91.3998V78.9881H90.9246ZM91.3998 77.7998V78.2753L90.9246 78.2753V77.7998H91.3998ZM90.8653 77.9632H90.7465V78.29H90.7759V78.4982H90.7465V78.825H90.8653V78.4982H90.8353V78.29H90.8653V77.9632ZM90.375 78.4536V78.3348H90.7018V78.4536H90.375ZM90.9095 78.3348V78.4536H91.2363V78.3348H90.9095ZM90.2115 79.2114H90.6869V79.6868H90.2115V79.2114ZM90.2115 79.9245V80.3997H90.6869V79.9245H90.2115ZM90.9246 79.9245V80.3997H91.3998V79.9245H90.9246ZM91.3998 79.6868V79.2114H90.9246V79.6868H91.3998ZM90.7465 79.3748H90.8653V79.7016H90.8353V79.9097H90.8653V80.2365H90.7465V79.9097H90.7759V79.7016H90.7465V79.3748ZM90.375 79.7463V79.8651H90.7018V79.7463H90.375ZM90.9095 79.8651V79.7463H91.2363V79.8651H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 77.7998H92.3754V78.2752L91.8999 78.2752V77.7998ZM91.8999 78.5129V78.9881H92.3754V78.5129H91.8999ZM92.6131 78.5129V78.9881H93.0882V78.5129H92.6131ZM93.0882 78.2752V77.7998H92.6131V78.2752L93.0882 78.2752ZM92.4349 77.9632H92.5537V78.29H92.5237V78.4982H92.5537V78.825H92.4349V78.4982H92.4643V78.29H92.4349V77.9632ZM92.063 78.3348V78.4536H92.3898V78.3348H92.063ZM92.5982 78.4536V78.3348H92.925V78.4536H92.5982ZM91.8999 79.2113H92.3753V79.6868H91.8999V79.2113ZM91.8999 79.9245V80.3996H92.3753V79.9245H91.8999ZM92.6131 79.9245V80.3996H93.0882V79.9245H92.6131ZM93.0882 79.6868V79.2113H92.6131V79.6868L93.0882 79.6868ZM92.4349 79.3747H92.5537V79.7015H92.5237V79.9097H92.5537V80.2364H92.4349V79.9097H92.4643V79.7015H92.4349V79.3747ZM92.063 79.7463V79.8651H92.3898V79.7463H92.063ZM92.5982 79.8651V79.7463H92.925V79.8651H92.5982ZM93.787 77.7998H93.3116V78.2753L93.787 78.2753V77.7998ZM93.3116 78.9881V78.513H93.787V78.9881H93.3116ZM94.0247 78.9881V78.513H94.4999V78.9881H94.0247ZM94.4999 77.7998V78.2753L94.0247 78.2753V77.7998H94.4999ZM93.9654 77.9632H93.8466V78.29H93.876V78.4982H93.8466V78.825H93.9654V78.4982H93.9354V78.29H93.9654V77.9632ZM93.4751 78.4536V78.3348H93.8019V78.4536H93.4751ZM94.0096 78.3348V78.4536H94.3364V78.3348H94.0096ZM93.3116 79.2114H93.787V79.6868L93.3116 79.6868V79.2114ZM93.3116 79.9245V80.3997H93.787V79.9245H93.3116ZM94.0247 79.9245V80.3997H94.4999V79.9245H94.0247ZM94.4999 79.6868V79.2114H94.0247V79.6868H94.4999ZM93.8466 79.3748H93.9654V79.7016H93.9354V79.9097H93.9654V80.2365H93.8466V79.9097H93.876V79.7016H93.8466V79.3748ZM93.4751 79.7463V79.8651H93.8019V79.7463H93.4751ZM94.0096 79.8651V79.7463H94.3364V79.8651H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 77.7998H95.4755V78.2752L95 78.2752V77.7998ZM95 78.5129V78.9881H95.4755V78.5129H95ZM95.7132 78.5129V78.9881H96.1883V78.5129H95.7132ZM96.1883 78.2752V77.7998H95.7132V78.2752L96.1883 78.2752ZM95.535 77.9632H95.6538V78.29H95.6238V78.4982H95.6538V78.825H95.535V78.4982H95.5644V78.29H95.535V77.9632ZM95.1631 78.3348V78.4536H95.4899V78.3348H95.1631ZM95.6983 78.4536V78.3348H96.0251V78.4536H95.6983ZM95 79.2113H95.4754V79.6868H95V79.2113ZM95 79.9245V80.3996H95.4754V79.9245H95ZM95.7132 79.9245V80.3996H96.1883V79.9245H95.7132ZM96.1883 79.6868V79.2113H95.7132V79.6868L96.1883 79.6868ZM95.535 79.3747H95.6538V79.7015H95.6238V79.9097H95.6538V80.2364H95.535V79.9097H95.5644V79.7015H95.535V79.3747ZM95.1631 79.7463V79.8651H95.4899V79.7463H95.1631ZM95.6983 79.8651V79.7463H96.0251V79.8651H95.6983ZM96.8871 77.7998H96.4117V78.2753L96.8871 78.2753V77.7998ZM96.4117 78.9881V78.513H96.8871V78.9881H96.4117ZM97.1248 78.9881V78.513H97.6V78.9881H97.1248ZM97.6 77.7998V78.2753L97.1248 78.2753V77.7998H97.6ZM97.0655 77.9632H96.9467V78.29H96.9761V78.4982H96.9467V78.825H97.0655V78.4982H97.0355V78.29H97.0655V77.9632ZM96.5752 78.4536V78.3348H96.902V78.4536H96.5752ZM97.1097 78.3348V78.4536H97.4365V78.3348H97.1097ZM96.4117 79.2114H96.8871V79.6868L96.4117 79.6868V79.2114ZM96.4117 79.9245V80.3997H96.8871V79.9245H96.4117ZM97.1248 79.9245V80.3997H97.6V79.9245H97.1248ZM97.6 79.6868V79.2114H97.1248V79.6868H97.6ZM96.9467 79.3748H97.0655V79.7016H97.0355V79.9097H97.0655V80.2365H96.9467V79.9097H96.9761V79.7016H96.9467V79.3748ZM96.5752 79.7463V79.8651H96.902V79.7463H96.5752ZM97.1097 79.8651V79.7463H97.4365V79.8651H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 77.7998H98.5756V78.2752L98.1001 78.2752V77.7998ZM98.1001 78.5129V78.9881H98.5756V78.5129H98.1001ZM98.8133 78.5129V78.9881H99.2884V78.5129H98.8133ZM99.2884 78.2752V77.7998H98.8133V78.2752L99.2884 78.2752ZM98.6351 77.9632H98.7539V78.29H98.7239V78.4982H98.7539V78.825H98.6351V78.4982H98.6645V78.29H98.6351V77.9632ZM98.2632 78.3348V78.4536H98.59V78.3348H98.2632ZM98.7984 78.4536V78.3348H99.1252V78.4536H98.7984ZM98.1001 79.2113H98.5755V79.6868H98.1001V79.2113ZM98.1001 79.9245V80.3996H98.5755V79.9245H98.1001ZM98.8133 79.9245V80.3996H99.2884V79.9245H98.8133ZM99.2884 79.6868V79.2113H98.8133V79.6868L99.2884 79.6868ZM98.6351 79.3747H98.7539V79.7015H98.7239V79.9097H98.7539V80.2364H98.6351V79.9097H98.6645V79.7015H98.6351V79.3747ZM98.2632 79.7463V79.8651H98.59V79.7463H98.2632ZM98.7984 79.8651V79.7463H99.1252V79.8651H98.7984ZM99.9872 77.7998H99.5118V78.2753L99.9872 78.2753V77.7998ZM99.5118 78.9881V78.513H99.9872V78.9881H99.5118ZM100.225 78.9881V78.513H100.7V78.9881H100.225ZM100.7 77.7998V78.2753L100.225 78.2753V77.7998H100.7ZM100.166 77.9632H100.047V78.29H100.076V78.4982H100.047V78.825H100.166V78.4982H100.136V78.29H100.166V77.9632ZM99.6753 78.4536V78.3348H100.002V78.4536H99.6753ZM100.21 78.3348V78.4536H100.537V78.3348H100.21ZM99.5118 79.2114H99.9872V79.6868L99.5118 79.6868V79.2114ZM99.5118 79.9245V80.3997H99.9872V79.9245H99.5118ZM100.225 79.9245V80.3997H100.7V79.9245H100.225ZM100.7 79.6868V79.2114H100.225V79.6868H100.7ZM100.047 79.3748H100.166V79.7016H100.136V79.9097H100.166V80.2365H100.047V79.9097H100.076V79.7016H100.047V79.3748ZM99.6753 79.7463V79.8651H100.002V79.7463H99.6753ZM100.21 79.8651V79.7463H100.537V79.8651H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 80.9004H89.2753V81.3758L88.7998 81.3758V80.9004ZM88.7998 81.6135V82.0887H89.2753V81.6135H88.7998ZM89.513 81.6135V82.0887H89.9881V81.6135H89.513ZM89.9881 81.3758V80.9004H89.513V81.3758L89.9881 81.3758ZM89.3348 81.0638H89.4536V81.3906H89.4236V81.5988H89.4536V81.9255H89.3348V81.5988H89.3642V81.3906H89.3348V81.0638ZM88.9629 81.4353V81.5542H89.2897V81.4353H88.9629ZM89.4981 81.5542V81.4354H89.8249V81.5542H89.4981ZM88.7998 82.3119H89.2753V82.7874H88.7998V82.3119ZM88.7998 83.0251V83.5002H89.2753V83.0251H88.7998ZM89.513 83.0251V83.5002H89.9881V83.0251H89.513ZM89.9881 82.7874V82.3119H89.513V82.7874L89.9881 82.7874ZM89.3348 82.4753H89.4536V82.8021H89.4236V83.0103H89.4536V83.337H89.3348V83.0103H89.3642V82.8021H89.3348V82.4753ZM88.9629 82.8468V82.9657H89.2897V82.8468H88.9629ZM89.4981 82.9657V82.8469H89.8249V82.9657H89.4981ZM90.6869 80.9004H90.2115V81.3759L90.6869 81.3759V80.9004ZM90.2115 82.0887V81.6136H90.6869V82.0887H90.2115ZM90.9246 82.0887V81.6136H91.3998V82.0887H90.9246ZM91.3998 80.9004V81.3759L90.9246 81.3759V80.9004H91.3998ZM90.8653 81.0638H90.7465V81.3906H90.7759V81.5988H90.7465V81.9256H90.8653V81.5988H90.8353V81.3906H90.8653V81.0638ZM90.375 81.5542V81.4354H90.7018V81.5542H90.375ZM90.9095 81.4354V81.5542H91.2363V81.4354H90.9095ZM90.2115 82.312H90.6869V82.7874L90.2115 82.7874V82.312ZM90.2115 83.0251V83.5003H90.6869V83.0251H90.2115ZM90.9246 83.0251V83.5003H91.3998V83.0251H90.9246ZM91.3998 82.7874V82.312H90.9246V82.7874H91.3998ZM90.7465 82.4754H90.8653V82.8021H90.8353V83.0103H90.8653V83.3371H90.7465V83.0103H90.7759V82.8021H90.7465V82.4754ZM90.375 82.8469V82.9657H90.7018V82.8469H90.375ZM90.9095 82.9657V82.8469H91.2363V82.9657H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 80.9004H92.3754V81.3758L91.8999 81.3758V80.9004ZM91.8999 81.6135V82.0887H92.3754V81.6135H91.8999ZM92.6131 81.6135V82.0887H93.0882V81.6135H92.6131ZM93.0882 81.3758V80.9004H92.6131V81.3758L93.0882 81.3758ZM92.4349 81.0638H92.5537V81.3906H92.5237V81.5988H92.5537V81.9255H92.4349V81.5988H92.4643V81.3906H92.4349V81.0638ZM92.063 81.4353V81.5542H92.3898V81.4353H92.063ZM92.5982 81.5542V81.4354H92.925V81.5542H92.5982ZM91.8999 82.3119H92.3753V82.7874H91.8999V82.3119ZM91.8999 83.0251V83.5002H92.3753V83.0251H91.8999ZM92.6131 83.0251V83.5002H93.0882V83.0251H92.6131ZM93.0882 82.7874V82.3119H92.6131V82.7874L93.0882 82.7874ZM92.4349 82.4753H92.5537V82.8021H92.5237V83.0103H92.5537V83.337H92.4349V83.0103H92.4643V82.8021H92.4349V82.4753ZM92.063 82.8468V82.9657H92.3898V82.8468H92.063ZM92.5982 82.9657V82.8469H92.925V82.9657H92.5982ZM93.787 80.9004H93.3116V81.3759L93.787 81.3759V80.9004ZM93.3116 82.0887V81.6136H93.787V82.0887H93.3116ZM94.0247 82.0887V81.6136H94.4999V82.0887H94.0247ZM94.4999 80.9004V81.3759L94.0247 81.3759V80.9004H94.4999ZM93.9654 81.0638H93.8466V81.3906H93.876V81.5988H93.8466V81.9256H93.9654V81.5988H93.9354V81.3906H93.9654V81.0638ZM93.4751 81.5542V81.4354H93.8019V81.5542H93.4751ZM94.0096 81.4354V81.5542H94.3364V81.4354H94.0096ZM93.3116 82.312H93.787V82.7874L93.3116 82.7874V82.312ZM93.3116 83.0251V83.5003H93.787V83.0251H93.3116ZM94.0247 83.0251V83.5003H94.4999V83.0251H94.0247ZM94.4999 82.7874V82.312H94.0247V82.7874H94.4999ZM93.8466 82.4754H93.9654V82.8021H93.9354V83.0103H93.9654V83.3371H93.8466V83.0103H93.876V82.8021H93.8466V82.4754ZM93.4751 82.8469V82.9657H93.8019V82.8469H93.4751ZM94.0096 82.9657V82.8469H94.3364V82.9657H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 80.9004H95.4755V81.3758L95 81.3758V80.9004ZM95 81.6135V82.0887H95.4755V81.6135H95ZM95.7132 81.6135V82.0887H96.1883V81.6135H95.7132ZM96.1883 81.3758V80.9004H95.7132V81.3758L96.1883 81.3758ZM95.535 81.0638H95.6538V81.3906H95.6238V81.5988H95.6538V81.9255H95.535V81.5988H95.5644V81.3906H95.535V81.0638ZM95.1631 81.4353V81.5542H95.4899V81.4353H95.1631ZM95.6983 81.5542V81.4354H96.0251V81.5542H95.6983ZM95 82.3119H95.4754V82.7874H95V82.3119ZM95 83.0251V83.5002H95.4754V83.0251H95ZM95.7132 83.0251V83.5002H96.1883V83.0251H95.7132ZM96.1883 82.7874V82.3119H95.7132V82.7874L96.1883 82.7874ZM95.535 82.4753H95.6538V82.8021H95.6238V83.0103H95.6538V83.337H95.535V83.0103H95.5644V82.8021H95.535V82.4753ZM95.1631 82.8468V82.9657H95.4899V82.8468H95.1631ZM95.6983 82.9657V82.8469H96.0251V82.9657H95.6983ZM96.8871 80.9004H96.4117V81.3759L96.8871 81.3759V80.9004ZM96.4117 82.0887V81.6136H96.8871V82.0887H96.4117ZM97.1248 82.0887V81.6136H97.6V82.0887H97.1248ZM97.6 80.9004V81.3759L97.1248 81.3759V80.9004H97.6ZM97.0655 81.0638H96.9467V81.3906H96.9761V81.5988H96.9467V81.9256H97.0655V81.5988H97.0355V81.3906H97.0655V81.0638ZM96.5752 81.5542V81.4354H96.902V81.5542H96.5752ZM97.1097 81.4354V81.5542H97.4365V81.4354H97.1097ZM96.4117 82.312H96.8871V82.7874L96.4117 82.7874V82.312ZM96.4117 83.0251V83.5003H96.8871V83.0251H96.4117ZM97.1248 83.0251V83.5003H97.6V83.0251H97.1248ZM97.6 82.7874V82.312H97.1248V82.7874H97.6ZM96.9467 82.4754H97.0655V82.8021H97.0355V83.0103H97.0655V83.3371H96.9467V83.0103H96.9761V82.8021H96.9467V82.4754ZM96.5752 82.8469V82.9657H96.902V82.8469H96.5752ZM97.1097 82.9657V82.8469H97.4365V82.9657H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 80.9004H98.5756V81.3758L98.1001 81.3758V80.9004ZM98.1001 81.6135V82.0887H98.5756V81.6135H98.1001ZM98.8133 81.6135V82.0887H99.2884V81.6135H98.8133ZM99.2884 81.3758V80.9004H98.8133V81.3758L99.2884 81.3758ZM98.6351 81.0638H98.7539V81.3906H98.7239V81.5988H98.7539V81.9255H98.6351V81.5988H98.6645V81.3906H98.6351V81.0638ZM98.2632 81.4353V81.5542H98.59V81.4353H98.2632ZM98.7984 81.5542V81.4354H99.1252V81.5542H98.7984ZM98.1001 82.3119H98.5755V82.7874H98.1001V82.3119ZM98.1001 83.0251V83.5002H98.5755V83.0251H98.1001ZM98.8133 83.0251V83.5002H99.2884V83.0251H98.8133ZM99.2884 82.7874V82.3119H98.8133V82.7874L99.2884 82.7874ZM98.6351 82.4753H98.7539V82.8021H98.7239V83.0103H98.7539V83.337H98.6351V83.0103H98.6645V82.8021H98.6351V82.4753ZM98.2632 82.8468V82.9657H98.59V82.8468H98.2632ZM98.7984 82.9657V82.8469H99.1252V82.9657H98.7984ZM99.9872 80.9004H99.5118V81.3759L99.9872 81.3759V80.9004ZM99.5118 82.0887V81.6136H99.9872V82.0887H99.5118ZM100.225 82.0887V81.6136H100.7V82.0887H100.225ZM100.7 80.9004V81.3759L100.225 81.3759V80.9004H100.7ZM100.166 81.0638H100.047V81.3906H100.076V81.5988H100.047V81.9256H100.166V81.5988H100.136V81.3906H100.166V81.0638ZM99.6753 81.5542V81.4354H100.002V81.5542H99.6753ZM100.21 81.4354V81.5542H100.537V81.4354H100.21ZM99.5118 82.312H99.9872V82.7874L99.5118 82.7874V82.312ZM99.5118 83.0251V83.5003H99.9872V83.0251H99.5118ZM100.225 83.0251V83.5003H100.7V83.0251H100.225ZM100.7 82.7874V82.312H100.225V82.7874H100.7ZM100.047 82.4754H100.166V82.8021H100.136V83.0103H100.166V83.3371H100.047V83.0103H100.076V82.8021H100.047V82.4754ZM99.6753 82.8469V82.9657H100.002V82.8469H99.6753ZM100.21 82.9657V82.8469H100.537V82.9657H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 84H89.2753V84.4754L88.7998 84.4754V84ZM88.7998 84.7131V85.1883H89.2753V84.7131H88.7998ZM89.513 84.7131V85.1883H89.9881V84.7131H89.513ZM89.9881 84.4754V84H89.513V84.4754L89.9881 84.4754ZM89.3348 84.1634H89.4536V84.4902H89.4236V84.6984H89.4536V85.0251H89.3348V84.6984H89.3642V84.4902H89.3348V84.1634ZM88.9629 84.535V84.6538H89.2897V84.535H88.9629ZM89.4981 84.6538V84.535H89.8249V84.6538H89.4981ZM88.7998 85.4115H89.2753V85.887H88.7998V85.4115ZM88.7998 86.1247V86.5998H89.2753V86.1247H88.7998ZM89.513 86.1247V86.5998H89.9881V86.1247H89.513ZM89.9881 85.887V85.4115H89.513V85.887L89.9881 85.887ZM89.3348 85.5749H89.4536V85.9017H89.4236V86.1099H89.4536V86.4366H89.3348V86.1099H89.3642V85.9017H89.3348V85.5749ZM88.9629 85.9465V86.0653H89.2897V85.9465H88.9629ZM89.4981 86.0653V85.9465H89.8249V86.0653H89.4981ZM90.6869 84H90.2115V84.4755L90.6869 84.4755V84ZM90.2115 85.1883V84.7132H90.6869V85.1883H90.2115ZM90.9246 85.1883V84.7132H91.3998V85.1883H90.9246ZM91.3998 84V84.4755L90.9246 84.4755V84H91.3998ZM90.8653 84.1634H90.7465V84.4902H90.7759V84.6984H90.7465V85.0252H90.8653V84.6984H90.8353V84.4902H90.8653V84.1634ZM90.375 84.6538V84.535H90.7018V84.6538H90.375ZM90.9095 84.535V84.6538H91.2363V84.535H90.9095ZM90.2115 85.4116H90.6869V85.887L90.2115 85.887V85.4116ZM90.2115 86.1247V86.5999H90.6869V86.1247H90.2115ZM90.9246 86.1247V86.5999H91.3998V86.1247H90.9246ZM91.3998 85.887V85.4116H90.9246V85.887H91.3998ZM90.7465 85.575H90.8653V85.9018H90.8353V86.1099H90.8653V86.4367H90.7465V86.1099H90.7759V85.9018H90.7465V85.575ZM90.375 85.9465V86.0653H90.7018V85.9465H90.375ZM90.9095 86.0653V85.9465H91.2363V86.0653H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 84H92.3754V84.4754L91.8999 84.4754V84ZM91.8999 84.7131V85.1883H92.3754V84.7131H91.8999ZM92.6131 84.7131V85.1883H93.0882V84.7131H92.6131ZM93.0882 84.4754V84H92.6131V84.4754L93.0882 84.4754ZM92.4349 84.1634H92.5537V84.4902H92.5237V84.6984H92.5537V85.0251H92.4349V84.6984H92.4643V84.4902H92.4349V84.1634ZM92.063 84.535V84.6538H92.3898V84.535H92.063ZM92.5982 84.6538V84.535H92.925V84.6538H92.5982ZM91.8999 85.4115H92.3753V85.887H91.8999V85.4115ZM91.8999 86.1247V86.5998H92.3753V86.1247H91.8999ZM92.6131 86.1247V86.5998H93.0882V86.1247H92.6131ZM93.0882 85.887V85.4115H92.6131V85.887L93.0882 85.887ZM92.4349 85.5749H92.5537V85.9017H92.5237V86.1099H92.5537V86.4366H92.4349V86.1099H92.4643V85.9017H92.4349V85.5749ZM92.063 85.9465V86.0653H92.3898V85.9465H92.063ZM92.5982 86.0653V85.9465H92.925V86.0653H92.5982ZM93.787 84H93.3116V84.4755L93.787 84.4755V84ZM93.3116 85.1883V84.7132H93.787V85.1883H93.3116ZM94.0247 85.1883V84.7132H94.4999V85.1883H94.0247ZM94.4999 84V84.4755L94.0247 84.4755V84H94.4999ZM93.9654 84.1634H93.8466V84.4902H93.876V84.6984H93.8466V85.0252H93.9654V84.6984H93.9354V84.4902H93.9654V84.1634ZM93.4751 84.6538V84.535H93.8019V84.6538H93.4751ZM94.0096 84.535V84.6538H94.3364V84.535H94.0096ZM93.3116 85.4116H93.787V85.887L93.3116 85.887V85.4116ZM93.3116 86.1247V86.5999H93.787V86.1247H93.3116ZM94.0247 86.1247V86.5999H94.4999V86.1247H94.0247ZM94.4999 85.887V85.4116H94.0247V85.887H94.4999ZM93.8466 85.575H93.9654V85.9018H93.9354V86.1099H93.9654V86.4367H93.8466V86.1099H93.876V85.9018H93.8466V85.575ZM93.4751 85.9465V86.0653H93.8019V85.9465H93.4751ZM94.0096 86.0653V85.9465H94.3364V86.0653H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 84H95.4755V84.4754L95 84.4754V84ZM95 84.7131V85.1883H95.4755V84.7131H95ZM95.7132 84.7131V85.1883H96.1883V84.7131H95.7132ZM96.1883 84.4754V84H95.7132V84.4754L96.1883 84.4754ZM95.535 84.1634H95.6538V84.4902H95.6238V84.6984H95.6538V85.0251H95.535V84.6984H95.5644V84.4902H95.535V84.1634ZM95.1631 84.535V84.6538H95.4899V84.535H95.1631ZM95.6983 84.6538V84.535H96.0251V84.6538H95.6983ZM95 85.4115H95.4754V85.887H95V85.4115ZM95 86.1247V86.5998H95.4754V86.1247H95ZM95.7132 86.1247V86.5998H96.1883V86.1247H95.7132ZM96.1883 85.887V85.4115H95.7132V85.887L96.1883 85.887ZM95.535 85.5749H95.6538V85.9017H95.6238V86.1099H95.6538V86.4366H95.535V86.1099H95.5644V85.9017H95.535V85.5749ZM95.1631 85.9465V86.0653H95.4899V85.9465H95.1631ZM95.6983 86.0653V85.9465H96.0251V86.0653H95.6983ZM96.8871 84H96.4117V84.4755L96.8871 84.4755V84ZM96.4117 85.1883V84.7132H96.8871V85.1883H96.4117ZM97.1248 85.1883V84.7132H97.6V85.1883H97.1248ZM97.6 84V84.4755L97.1248 84.4755V84H97.6ZM97.0655 84.1634H96.9467V84.4902H96.9761V84.6984H96.9467V85.0252H97.0655V84.6984H97.0355V84.4902H97.0655V84.1634ZM96.5752 84.6538V84.535H96.902V84.6538H96.5752ZM97.1097 84.535V84.6538H97.4365V84.535H97.1097ZM96.4117 85.4116H96.8871V85.887L96.4117 85.887V85.4116ZM96.4117 86.1247V86.5999H96.8871V86.1247H96.4117ZM97.1248 86.1247V86.5999H97.6V86.1247H97.1248ZM97.6 85.887V85.4116H97.1248V85.887H97.6ZM96.9467 85.575H97.0655V85.9018H97.0355V86.1099H97.0655V86.4367H96.9467V86.1099H96.9761V85.9018H96.9467V85.575ZM96.5752 85.9465V86.0653H96.902V85.9465H96.5752ZM97.1097 86.0653V85.9465H97.4365V86.0653H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 84H98.5756V84.4754L98.1001 84.4754V84ZM98.1001 84.7131V85.1883H98.5756V84.7131H98.1001ZM98.8133 84.7131V85.1883H99.2884V84.7131H98.8133ZM99.2884 84.4754V84H98.8133V84.4754L99.2884 84.4754ZM98.6351 84.1634H98.7539V84.4902H98.7239V84.6984H98.7539V85.0251H98.6351V84.6984H98.6645V84.4902H98.6351V84.1634ZM98.2632 84.535V84.6538H98.59V84.535H98.2632ZM98.7984 84.6538V84.535H99.1252V84.6538H98.7984ZM98.1001 85.4115H98.5755V85.887H98.1001V85.4115ZM98.1001 86.1247V86.5998H98.5755V86.1247H98.1001ZM98.8133 86.1247V86.5998H99.2884V86.1247H98.8133ZM99.2884 85.887V85.4115H98.8133V85.887L99.2884 85.887ZM98.6351 85.5749H98.7539V85.9017H98.7239V86.1099H98.7539V86.4366H98.6351V86.1099H98.6645V85.9017H98.6351V85.5749ZM98.2632 85.9465V86.0653H98.59V85.9465H98.2632ZM98.7984 86.0653V85.9465H99.1252V86.0653H98.7984ZM99.9872 84H99.5118V84.4755L99.9872 84.4755V84ZM99.5118 85.1883V84.7132H99.9872V85.1883H99.5118ZM100.225 85.1883V84.7132H100.7V85.1883H100.225ZM100.7 84V84.4755L100.225 84.4755V84H100.7ZM100.166 84.1634H100.047V84.4902H100.076V84.6984H100.047V85.0252H100.166V84.6984H100.136V84.4902H100.166V84.1634ZM99.6753 84.6538V84.535H100.002V84.6538H99.6753ZM100.21 84.535V84.6538H100.537V84.535H100.21ZM99.5118 85.4116H99.9872V85.887L99.5118 85.887V85.4116ZM99.5118 86.1247V86.5999H99.9872V86.1247H99.5118ZM100.225 86.1247V86.5999H100.7V86.1247H100.225ZM100.7 85.887V85.4116H100.225V85.887H100.7ZM100.047 85.575H100.166V85.9018H100.136V86.1099H100.166V86.4367H100.047V86.1099H100.076V85.9018H100.047V85.575ZM99.6753 85.9465V86.0653H100.002V85.9465H99.6753ZM100.21 86.0653V85.9465H100.537V86.0653H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 87.0996H89.2753V87.575L88.7998 87.575V87.0996ZM88.7998 87.8127V88.2879H89.2753V87.8127H88.7998ZM89.513 87.8127V88.2879H89.9881V87.8127H89.513ZM89.9881 87.575V87.0996H89.513V87.575L89.9881 87.575ZM89.3348 87.263H89.4536V87.5898H89.4236V87.798H89.4536V88.1248H89.3348V87.798H89.3642V87.5898H89.3348V87.263ZM88.9629 87.6346V87.7534H89.2897V87.6346H88.9629ZM89.4981 87.7534V87.6346H89.8249V87.7534H89.4981ZM88.7998 88.5112H89.2753V88.9866H88.7998V88.5112ZM88.7998 89.2243V89.6994H89.2753V89.2243H88.7998ZM89.513 89.2243V89.6994H89.9881V89.2243H89.513ZM89.9881 88.9866V88.5112H89.513V88.9866L89.9881 88.9866ZM89.3348 88.6746H89.4536V89.0013H89.4236V89.2095H89.4536V89.5363H89.3348V89.2095H89.3642V89.0013H89.3348V88.6746ZM88.9629 89.0461V89.1649H89.2897V89.0461H88.9629ZM89.4981 89.1649V89.0461H89.8249V89.1649H89.4981ZM90.6869 87.0996H90.2115V87.5751L90.6869 87.5751V87.0996ZM90.2115 88.2879V87.8128H90.6869V88.2879H90.2115ZM90.9246 88.2879V87.8128H91.3998V88.2879H90.9246ZM91.3998 87.0996V87.5751L90.9246 87.5751V87.0996H91.3998ZM90.8653 87.263H90.7465V87.5898H90.7759V87.798H90.7465V88.1248H90.8653V87.798H90.8353V87.5898H90.8653V87.263ZM90.375 87.7534V87.6346H90.7018V87.7534H90.375ZM90.9095 87.6346V87.7534H91.2363V87.6346H90.9095ZM90.2115 88.5112H90.6869V88.9866L90.2115 88.9866V88.5112ZM90.2115 89.2243V89.6995H90.6869V89.2243H90.2115ZM90.9246 89.2243V89.6995H91.3998V89.2243H90.9246ZM91.3998 88.9866V88.5112H90.9246V88.9866H91.3998ZM90.7465 88.6746H90.8653V89.0014H90.8353V89.2095H90.8653V89.5363H90.7465V89.2095H90.7759V89.0014H90.7465V88.6746ZM90.375 89.0461V89.1649H90.7018V89.0461H90.375ZM90.9095 89.1649V89.0461H91.2363V89.1649H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 87.0996H92.3754V87.575L91.8999 87.575V87.0996ZM91.8999 87.8127V88.2879H92.3754V87.8127H91.8999ZM92.6131 87.8127V88.2879H93.0882V87.8127H92.6131ZM93.0882 87.575V87.0996H92.6131V87.575L93.0882 87.575ZM92.4349 87.263H92.5537V87.5898H92.5237V87.798H92.5537V88.1248H92.4349V87.798H92.4643V87.5898H92.4349V87.263ZM92.063 87.6346V87.7534H92.3898V87.6346H92.063ZM92.5982 87.7534V87.6346H92.925V87.7534H92.5982ZM91.8999 88.5112H92.3753V88.9866H91.8999V88.5112ZM91.8999 89.2243V89.6994H92.3753V89.2243H91.8999ZM92.6131 89.2243V89.6994H93.0882V89.2243H92.6131ZM93.0882 88.9866V88.5112H92.6131V88.9866L93.0882 88.9866ZM92.4349 88.6746H92.5537V89.0013H92.5237V89.2095H92.5537V89.5363H92.4349V89.2095H92.4643V89.0013H92.4349V88.6746ZM92.063 89.0461V89.1649H92.3898V89.0461H92.063ZM92.5982 89.1649V89.0461H92.925V89.1649H92.5982ZM93.787 87.0996H93.3116V87.5751L93.787 87.5751V87.0996ZM93.3116 88.2879V87.8128H93.787V88.2879H93.3116ZM94.0247 88.2879V87.8128H94.4999V88.2879H94.0247ZM94.4999 87.0996V87.5751L94.0247 87.5751V87.0996H94.4999ZM93.9654 87.263H93.8466V87.5898H93.876V87.798H93.8466V88.1248H93.9654V87.798H93.9354V87.5898H93.9654V87.263ZM93.4751 87.7534V87.6346H93.8019V87.7534H93.4751ZM94.0096 87.6346V87.7534H94.3364V87.6346H94.0096ZM93.3116 88.5112H93.787V88.9866L93.3116 88.9866V88.5112ZM93.3116 89.2243V89.6995H93.787V89.2243H93.3116ZM94.0247 89.2243V89.6995H94.4999V89.2243H94.0247ZM94.4999 88.9866V88.5112H94.0247V88.9866H94.4999ZM93.8466 88.6746H93.9654V89.0014H93.9354V89.2095H93.9654V89.5363H93.8466V89.2095H93.876V89.0014H93.8466V88.6746ZM93.4751 89.0461V89.1649H93.8019V89.0461H93.4751ZM94.0096 89.1649V89.0461H94.3364V89.1649H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 87.0996H95.4755V87.575L95 87.575V87.0996ZM95 87.8127V88.2879H95.4755V87.8127H95ZM95.7132 87.8127V88.2879H96.1883V87.8127H95.7132ZM96.1883 87.575V87.0996H95.7132V87.575L96.1883 87.575ZM95.535 87.263H95.6538V87.5898H95.6238V87.798H95.6538V88.1248H95.535V87.798H95.5644V87.5898H95.535V87.263ZM95.1631 87.6346V87.7534H95.4899V87.6346H95.1631ZM95.6983 87.7534V87.6346H96.0251V87.7534H95.6983ZM95 88.5112H95.4754V88.9866H95V88.5112ZM95 89.2243V89.6994H95.4754V89.2243H95ZM95.7132 89.2243V89.6994H96.1883V89.2243H95.7132ZM96.1883 88.9866V88.5112H95.7132V88.9866L96.1883 88.9866ZM95.535 88.6746H95.6538V89.0013H95.6238V89.2095H95.6538V89.5363H95.535V89.2095H95.5644V89.0013H95.535V88.6746ZM95.1631 89.0461V89.1649H95.4899V89.0461H95.1631ZM95.6983 89.1649V89.0461H96.0251V89.1649H95.6983ZM96.8871 87.0996H96.4117V87.5751L96.8871 87.5751V87.0996ZM96.4117 88.2879V87.8128H96.8871V88.2879H96.4117ZM97.1248 88.2879V87.8128H97.6V88.2879H97.1248ZM97.6 87.0996V87.5751L97.1248 87.5751V87.0996H97.6ZM97.0655 87.263H96.9467V87.5898H96.9761V87.798H96.9467V88.1248H97.0655V87.798H97.0355V87.5898H97.0655V87.263ZM96.5752 87.7534V87.6346H96.902V87.7534H96.5752ZM97.1097 87.6346V87.7534H97.4365V87.6346H97.1097ZM96.4117 88.5112H96.8871V88.9866L96.4117 88.9866V88.5112ZM96.4117 89.2243V89.6995H96.8871V89.2243H96.4117ZM97.1248 89.2243V89.6995H97.6V89.2243H97.1248ZM97.6 88.9866V88.5112H97.1248V88.9866H97.6ZM96.9467 88.6746H97.0655V89.0014H97.0355V89.2095H97.0655V89.5363H96.9467V89.2095H96.9761V89.0014H96.9467V88.6746ZM96.5752 89.0461V89.1649H96.902V89.0461H96.5752ZM97.1097 89.1649V89.0461H97.4365V89.1649H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 87.0996H98.5756V87.575L98.1001 87.575V87.0996ZM98.1001 87.8127V88.2879H98.5756V87.8127H98.1001ZM98.8133 87.8127V88.2879H99.2884V87.8127H98.8133ZM99.2884 87.575V87.0996H98.8133V87.575L99.2884 87.575ZM98.6351 87.263H98.7539V87.5898H98.7239V87.798H98.7539V88.1248H98.6351V87.798H98.6645V87.5898H98.6351V87.263ZM98.2632 87.6346V87.7534H98.59V87.6346H98.2632ZM98.7984 87.7534V87.6346H99.1252V87.7534H98.7984ZM98.1001 88.5112H98.5755V88.9866H98.1001V88.5112ZM98.1001 89.2243V89.6994H98.5755V89.2243H98.1001ZM98.8133 89.2243V89.6994H99.2884V89.2243H98.8133ZM99.2884 88.9866V88.5112H98.8133V88.9866L99.2884 88.9866ZM98.6351 88.6746H98.7539V89.0013H98.7239V89.2095H98.7539V89.5363H98.6351V89.2095H98.6645V89.0013H98.6351V88.6746ZM98.2632 89.0461V89.1649H98.59V89.0461H98.2632ZM98.7984 89.1649V89.0461H99.1252V89.1649H98.7984ZM99.9872 87.0996H99.5118V87.5751L99.9872 87.5751V87.0996ZM99.5118 88.2879V87.8128H99.9872V88.2879H99.5118ZM100.225 88.2879V87.8128H100.7V88.2879H100.225ZM100.7 87.0996V87.5751L100.225 87.5751V87.0996H100.7ZM100.166 87.263H100.047V87.5898H100.076V87.798H100.047V88.1248H100.166V87.798H100.136V87.5898H100.166V87.263ZM99.6753 87.7534V87.6346H100.002V87.7534H99.6753ZM100.21 87.6346V87.7534H100.537V87.6346H100.21ZM99.5118 88.5112H99.9872V88.9866L99.5118 88.9866V88.5112ZM99.5118 89.2243V89.6995H99.9872V89.2243H99.5118ZM100.225 89.2243V89.6995H100.7V89.2243H100.225ZM100.7 88.9866V88.5112H100.225V88.9866H100.7ZM100.047 88.6746H100.166V89.0014H100.136V89.2095H100.166V89.5363H100.047V89.2095H100.076V89.0014H100.047V88.6746ZM99.6753 89.0461V89.1649H100.002V89.0461H99.6753ZM100.21 89.1649V89.0461H100.537V89.1649H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 90.2998H51.7753V90.7752L51.2998 90.7752V90.2998ZM51.2998 91.0129V91.4881H51.7753V91.0129H51.2998ZM52.013 91.0129V91.4881H52.4881V91.0129H52.013ZM52.4881 90.7752V90.2998H52.013V90.7752L52.4881 90.7752ZM51.8348 90.4632H51.9536V90.79H51.9236V90.9982H51.9536V91.325H51.8348V90.9982H51.8642V90.79H51.8348V90.4632ZM51.4629 90.8348V90.9536H51.7897V90.8348H51.4629ZM51.9981 90.9536V90.8348H52.3249V90.9536H51.9981ZM51.2998 91.7113H51.7753V92.1868H51.2998V91.7113ZM51.2998 92.4245V92.8996H51.7753V92.4245H51.2998ZM52.013 92.4245V92.8996H52.4881V92.4245H52.013ZM52.4881 92.1868V91.7113H52.013V92.1868H52.4881ZM51.8348 91.8747H51.9536V92.2015H51.9236V92.4097H51.9536V92.7364H51.8348V92.4097H51.8642V92.2015H51.8348V91.8747ZM51.4629 92.2463V92.3651H51.7897V92.2463H51.4629ZM51.9981 92.3651V92.2463H52.3249V92.3651H51.9981ZM53.1869 90.2998H52.7115V90.7753L53.1869 90.7753V90.2998ZM52.7115 91.4881V91.013H53.1869V91.4881H52.7115ZM53.4246 91.4881V91.013H53.8998V91.4881H53.4246ZM53.8998 90.2998V90.7753L53.4246 90.7753V90.2998H53.8998ZM53.3653 90.4632H53.2465V90.79H53.2759V90.9982H53.2465V91.325H53.3653V90.9982H53.3353V90.79H53.3653V90.4632ZM52.875 90.9536V90.8348H53.2018V90.9536H52.875ZM53.4095 90.8348V90.9536H53.7363V90.8348H53.4095ZM52.7115 91.7114H53.1869V92.1868H52.7115V91.7114ZM52.7115 92.4245V92.8997H53.1869V92.4245H52.7115ZM53.4246 92.4245V92.8997H53.8998V92.4245H53.4246ZM53.8998 92.1868V91.7114H53.4246V92.1868H53.8998ZM53.2465 91.8748H53.3653V92.2016H53.3353V92.4097H53.3653V92.7365H53.2465V92.4097H53.2759V92.2016H53.2465V91.8748ZM52.875 92.2463V92.3651H53.2018V92.2463H52.875ZM53.4095 92.3651V92.2463H53.7363V92.3651H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 90.2998H54.8754V90.7752L54.3999 90.7752V90.2998ZM54.3999 91.0129V91.4881H54.8754V91.0129H54.3999ZM55.1131 91.0129V91.4881H55.5882V91.0129H55.1131ZM55.5882 90.7752V90.2998H55.1131V90.7752L55.5882 90.7752ZM54.9349 90.4632H55.0537V90.79H55.0237V90.9982H55.0537V91.325H54.9349V90.9982H54.9643V90.79H54.9349V90.4632ZM54.563 90.8348V90.9536H54.8898V90.8348H54.563ZM55.0982 90.9536V90.8348H55.425V90.9536H55.0982ZM54.3999 91.7113H54.8753V92.1868H54.3999V91.7113ZM54.3999 92.4245V92.8996H54.8753V92.4245H54.3999ZM55.1131 92.4245V92.8996H55.5882V92.4245H55.1131ZM55.5882 92.1868V91.7113H55.1131V92.1868L55.5882 92.1868ZM54.9349 91.8747H55.0537V92.2015H55.0237V92.4097H55.0537V92.7364H54.9349V92.4097H54.9643V92.2015H54.9349V91.8747ZM54.563 92.2463V92.3651H54.8898V92.2463H54.563ZM55.0982 92.3651V92.2463H55.425V92.3651H55.0982ZM56.287 90.2998H55.8116V90.7753L56.287 90.7753V90.2998ZM55.8116 91.4881V91.013H56.287V91.4881H55.8116ZM56.5247 91.4881V91.013H56.9999V91.4881H56.5247ZM56.9999 90.2998V90.7753L56.5247 90.7753V90.2998H56.9999ZM56.4654 90.4632H56.3466V90.79H56.376V90.9982H56.3466V91.325H56.4654V90.9982H56.4354V90.79H56.4654V90.4632ZM55.9751 90.9536V90.8348H56.3019V90.9536H55.9751ZM56.5096 90.8348V90.9536H56.8364V90.8348H56.5096ZM55.8116 91.7114H56.287V92.1868L55.8116 92.1868V91.7114ZM55.8116 92.4245V92.8997H56.287V92.4245H55.8116ZM56.5247 92.4245V92.8997H56.9999V92.4245H56.5247ZM56.9999 92.1868V91.7114H56.5247V92.1868H56.9999ZM56.3466 91.8748H56.4654V92.2016H56.4354V92.4097H56.4654V92.7365H56.3466V92.4097H56.376V92.2016H56.3466V91.8748ZM55.9751 92.2463V92.3651H56.3019V92.2463H55.9751ZM56.5096 92.3651V92.2463H56.8364V92.3651H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 90.2998H57.9755V90.7752L57.5 90.7752V90.2998ZM57.5 91.0129V91.4881H57.9755V91.0129H57.5ZM58.2132 91.0129V91.4881H58.6883V91.0129H58.2132ZM58.6883 90.7752V90.2998H58.2132V90.7752L58.6883 90.7752ZM58.035 90.4632H58.1538V90.79H58.1238V90.9982H58.1538V91.325H58.035V90.9982H58.0644V90.79H58.035V90.4632ZM57.6631 90.8348V90.9536H57.9899V90.8348H57.6631ZM58.1983 90.9536V90.8348H58.5251V90.9536H58.1983ZM57.5 91.7113H57.9754V92.1868H57.5V91.7113ZM57.5 92.4245V92.8996H57.9754V92.4245H57.5ZM58.2132 92.4245V92.8996H58.6883V92.4245H58.2132ZM58.6883 92.1868V91.7113H58.2132V92.1868L58.6883 92.1868ZM58.035 91.8747H58.1538V92.2015H58.1238V92.4097H58.1538V92.7364H58.035V92.4097H58.0644V92.2015H58.035V91.8747ZM57.6631 92.2463V92.3651H57.9899V92.2463H57.6631ZM58.1983 92.3651V92.2463H58.5251V92.3651H58.1983ZM59.3871 90.2998H58.9117V90.7753L59.3871 90.7753V90.2998ZM58.9117 91.4881V91.013H59.3871V91.4881H58.9117ZM59.6248 91.4881V91.013H60.1V91.4881H59.6248ZM60.1 90.2998V90.7753L59.6248 90.7753V90.2998H60.1ZM59.5655 90.4632H59.4467V90.79H59.4761V90.9982H59.4467V91.325H59.5655V90.9982H59.5355V90.79H59.5655V90.4632ZM59.0752 90.9536V90.8348H59.402V90.9536H59.0752ZM59.6097 90.8348V90.9536H59.9365V90.8348H59.6097ZM58.9117 91.7114H59.3871V92.1868L58.9117 92.1868V91.7114ZM58.9117 92.4245V92.8997H59.3871V92.4245H58.9117ZM59.6248 92.4245V92.8997H60.1V92.4245H59.6248ZM60.1 92.1868V91.7114H59.6248V92.1868H60.1ZM59.4467 91.8748H59.5655V92.2016H59.5355V92.4097H59.5655V92.7365H59.4467V92.4097H59.4761V92.2016H59.4467V91.8748ZM59.0752 92.2463V92.3651H59.402V92.2463H59.0752ZM59.6097 92.3651V92.2463H59.9365V92.3651H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 90.2998H61.0756V90.7752L60.6001 90.7752V90.2998ZM60.6001 91.0129V91.4881H61.0756V91.0129H60.6001ZM61.3133 91.0129V91.4881H61.7884V91.0129H61.3133ZM61.7884 90.7752V90.2998H61.3133V90.7752L61.7884 90.7752ZM61.1351 90.4632H61.2539V90.79H61.2239V90.9982H61.2539V91.325H61.1351V90.9982H61.1645V90.79H61.1351V90.4632ZM60.7632 90.8348V90.9536H61.09V90.8348H60.7632ZM61.2984 90.9536V90.8348H61.6252V90.9536H61.2984ZM60.6001 91.7113H61.0755V92.1868H60.6001V91.7113ZM60.6001 92.4245V92.8996H61.0755V92.4245H60.6001ZM61.3133 92.4245V92.8996H61.7884V92.4245H61.3133ZM61.7884 92.1868V91.7113H61.3133V92.1868L61.7884 92.1868ZM61.1351 91.8747H61.2539V92.2015H61.2239V92.4097H61.2539V92.7364H61.1351V92.4097H61.1645V92.2015H61.1351V91.8747ZM60.7632 92.2463V92.3651H61.09V92.2463H60.7632ZM61.2984 92.3651V92.2463H61.6252V92.3651H61.2984ZM62.4872 90.2998H62.0118V90.7753L62.4872 90.7753V90.2998ZM62.0118 91.4881V91.013H62.4872V91.4881H62.0118ZM62.7249 91.4881V91.013H63.2001V91.4881H62.7249ZM63.2001 90.2998V90.7753L62.7249 90.7753V90.2998H63.2001ZM62.6656 90.4632H62.5468V90.79H62.5762V90.9982H62.5468V91.325H62.6656V90.9982H62.6356V90.79H62.6656V90.4632ZM62.1753 90.9536V90.8348H62.5021V90.9536H62.1753ZM62.7098 90.8348V90.9536H63.0366V90.8348H62.7098ZM62.0118 91.7114H62.4872V92.1868L62.0118 92.1868V91.7114ZM62.0118 92.4245V92.8997H62.4872V92.4245H62.0118ZM62.7249 92.4245V92.8997H63.2001V92.4245H62.7249ZM63.2001 92.1868V91.7114H62.7249V92.1868H63.2001ZM62.5468 91.8748H62.6656V92.2016H62.6356V92.4097H62.6656V92.7365H62.5468V92.4097H62.5762V92.2016H62.5468V91.8748ZM62.1753 92.2463V92.3651H62.5021V92.2463H62.1753ZM62.7098 92.3651V92.2463H63.0366V92.3651H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 93.4004H51.7753V93.8758L51.2998 93.8758V93.4004ZM51.2998 94.1135V94.5887H51.7753V94.1135H51.2998ZM52.013 94.1135V94.5887H52.4881V94.1135H52.013ZM52.4881 93.8758V93.4004H52.013V93.8758L52.4881 93.8758ZM51.8348 93.5638H51.9536V93.8906H51.9236V94.0988H51.9536V94.4255H51.8348V94.0988H51.8642V93.8906H51.8348V93.5638ZM51.4629 93.9353V94.0542H51.7897V93.9353H51.4629ZM51.9981 94.0542V93.9354H52.3249V94.0542H51.9981ZM51.2998 94.8119H51.7753V95.2874H51.2998V94.8119ZM51.2998 95.5251V96.0002H51.7753V95.5251H51.2998ZM52.013 95.5251V96.0002H52.4881V95.5251H52.013ZM52.4881 95.2874V94.8119H52.013V95.2874L52.4881 95.2874ZM51.8348 94.9753H51.9536V95.3021H51.9236V95.5103H51.9536V95.837H51.8348V95.5103H51.8642V95.3021H51.8348V94.9753ZM51.4629 95.3468V95.4657H51.7897V95.3468H51.4629ZM51.9981 95.4657V95.3469H52.3249V95.4657H51.9981ZM53.1869 93.4004H52.7115V93.8759L53.1869 93.8759V93.4004ZM52.7115 94.5887V94.1136H53.1869V94.5887H52.7115ZM53.4246 94.5887V94.1136H53.8998V94.5887H53.4246ZM53.8998 93.4004V93.8759L53.4246 93.8759V93.4004H53.8998ZM53.3653 93.5638H53.2465V93.8906H53.2759V94.0988H53.2465V94.4256H53.3653V94.0988H53.3353V93.8906H53.3653V93.5638ZM52.875 94.0542V93.9354H53.2018V94.0542H52.875ZM53.4095 93.9354V94.0542H53.7363V93.9354H53.4095ZM52.7115 94.812H53.1869V95.2874L52.7115 95.2874V94.812ZM52.7115 95.5251V96.0003H53.1869V95.5251H52.7115ZM53.4246 95.5251V96.0003H53.8998V95.5251H53.4246ZM53.8998 95.2874V94.812H53.4246V95.2874H53.8998ZM53.2465 94.9754H53.3653V95.3021H53.3353V95.5103H53.3653V95.8371H53.2465V95.5103H53.2759V95.3021H53.2465V94.9754ZM52.875 95.3469V95.4657H53.2018V95.3469H52.875ZM53.4095 95.4657V95.3469H53.7363V95.4657H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 93.4004H54.8754V93.8758L54.3999 93.8758V93.4004ZM54.3999 94.1135V94.5887H54.8754V94.1135H54.3999ZM55.1131 94.1135V94.5887H55.5882V94.1135H55.1131ZM55.5882 93.8758V93.4004H55.1131V93.8758L55.5882 93.8758ZM54.9349 93.5638H55.0537V93.8906H55.0237V94.0988H55.0537V94.4255H54.9349V94.0988H54.9643V93.8906H54.9349V93.5638ZM54.563 93.9353V94.0542H54.8898V93.9353H54.563ZM55.0982 94.0542V93.9354H55.425V94.0542H55.0982ZM54.3999 94.8119H54.8753V95.2874H54.3999V94.8119ZM54.3999 95.5251V96.0002H54.8753V95.5251H54.3999ZM55.1131 95.5251V96.0002H55.5882V95.5251H55.1131ZM55.5882 95.2874V94.8119H55.1131V95.2874L55.5882 95.2874ZM54.9349 94.9753H55.0537V95.3021H55.0237V95.5103H55.0537V95.837H54.9349V95.5103H54.9643V95.3021H54.9349V94.9753ZM54.563 95.3468V95.4657H54.8898V95.3468H54.563ZM55.0982 95.4657V95.3469H55.425V95.4657H55.0982ZM56.287 93.4004H55.8116V93.8759L56.287 93.8759V93.4004ZM55.8116 94.5887V94.1136H56.287V94.5887H55.8116ZM56.5247 94.5887V94.1136H56.9999V94.5887H56.5247ZM56.9999 93.4004V93.8759L56.5247 93.8759V93.4004H56.9999ZM56.4654 93.5638H56.3466V93.8906H56.376V94.0988H56.3466V94.4256H56.4654V94.0988H56.4354V93.8906H56.4654V93.5638ZM55.9751 94.0542V93.9354H56.3019V94.0542H55.9751ZM56.5096 93.9354V94.0542H56.8364V93.9354H56.5096ZM55.8116 94.812H56.287V95.2874L55.8116 95.2874V94.812ZM55.8116 95.5251V96.0003H56.287V95.5251H55.8116ZM56.5247 95.5251V96.0003H56.9999V95.5251H56.5247ZM56.9999 95.2874V94.812H56.5247V95.2874H56.9999ZM56.3466 94.9754H56.4654V95.3021H56.4354V95.5103H56.4654V95.8371H56.3466V95.5103H56.376V95.3021H56.3466V94.9754ZM55.9751 95.3469V95.4657H56.3019V95.3469H55.9751ZM56.5096 95.4657V95.3469H56.8364V95.4657H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 93.4004H57.9755V93.8758L57.5 93.8758V93.4004ZM57.5 94.1135V94.5887H57.9755V94.1135H57.5ZM58.2132 94.1135V94.5887H58.6883V94.1135H58.2132ZM58.6883 93.8758V93.4004H58.2132V93.8758L58.6883 93.8758ZM58.035 93.5638H58.1538V93.8906H58.1238V94.0988H58.1538V94.4255H58.035V94.0988H58.0644V93.8906H58.035V93.5638ZM57.6631 93.9353V94.0542H57.9899V93.9353H57.6631ZM58.1983 94.0542V93.9354H58.5251V94.0542H58.1983ZM57.5 94.8119H57.9754V95.2874H57.5V94.8119ZM57.5 95.5251V96.0002H57.9754V95.5251H57.5ZM58.2132 95.5251V96.0002H58.6883V95.5251H58.2132ZM58.6883 95.2874V94.8119H58.2132V95.2874L58.6883 95.2874ZM58.035 94.9753H58.1538V95.3021H58.1238V95.5103H58.1538V95.837H58.035V95.5103H58.0644V95.3021H58.035V94.9753ZM57.6631 95.3468V95.4657H57.9899V95.3468H57.6631ZM58.1983 95.4657V95.3469H58.5251V95.4657H58.1983ZM59.3871 93.4004H58.9117V93.8759L59.3871 93.8759V93.4004ZM58.9117 94.5887V94.1136H59.3871V94.5887H58.9117ZM59.6248 94.5887V94.1136H60.1V94.5887H59.6248ZM60.1 93.4004V93.8759L59.6248 93.8759V93.4004H60.1ZM59.5655 93.5638H59.4467V93.8906H59.4761V94.0988H59.4467V94.4256H59.5655V94.0988H59.5355V93.8906H59.5655V93.5638ZM59.0752 94.0542V93.9354H59.402V94.0542H59.0752ZM59.6097 93.9354V94.0542H59.9365V93.9354H59.6097ZM58.9117 94.812H59.3871V95.2874L58.9117 95.2874V94.812ZM58.9117 95.5251V96.0003H59.3871V95.5251H58.9117ZM59.6248 95.5251V96.0003H60.1V95.5251H59.6248ZM60.1 95.2874V94.812H59.6248V95.2874H60.1ZM59.4467 94.9754H59.5655V95.3021H59.5355V95.5103H59.5655V95.8371H59.4467V95.5103H59.4761V95.3021H59.4467V94.9754ZM59.0752 95.3469V95.4657H59.402V95.3469H59.0752ZM59.6097 95.4657V95.3469H59.9365V95.4657H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 93.4004H61.0756V93.8758L60.6001 93.8758V93.4004ZM60.6001 94.1135V94.5887H61.0756V94.1135H60.6001ZM61.3133 94.1135V94.5887H61.7884V94.1135H61.3133ZM61.7884 93.8758V93.4004H61.3133V93.8758L61.7884 93.8758ZM61.1351 93.5638H61.2539V93.8906H61.2239V94.0988H61.2539V94.4255H61.1351V94.0988H61.1645V93.8906H61.1351V93.5638ZM60.7632 93.9353V94.0542H61.09V93.9353H60.7632ZM61.2984 94.0542V93.9354H61.6252V94.0542H61.2984ZM60.6001 94.8119H61.0755V95.2874H60.6001V94.8119ZM60.6001 95.5251V96.0002H61.0755V95.5251H60.6001ZM61.3133 95.5251V96.0002H61.7884V95.5251H61.3133ZM61.7884 95.2874V94.8119H61.3133V95.2874L61.7884 95.2874ZM61.1351 94.9753H61.2539V95.3021H61.2239V95.5103H61.2539V95.837H61.1351V95.5103H61.1645V95.3021H61.1351V94.9753ZM60.7632 95.3468V95.4657H61.09V95.3468H60.7632ZM61.2984 95.4657V95.3469H61.6252V95.4657H61.2984ZM62.4872 93.4004H62.0118V93.8759L62.4872 93.8759V93.4004ZM62.0118 94.5887V94.1136H62.4872V94.5887H62.0118ZM62.7249 94.5887V94.1136H63.2001V94.5887H62.7249ZM63.2001 93.4004V93.8759L62.7249 93.8759V93.4004H63.2001ZM62.6656 93.5638H62.5468V93.8906H62.5762V94.0988H62.5468V94.4256H62.6656V94.0988H62.6356V93.8906H62.6656V93.5638ZM62.1753 94.0542V93.9354H62.5021V94.0542H62.1753ZM62.7098 93.9354V94.0542H63.0366V93.9354H62.7098ZM62.0118 94.812H62.4872V95.2874L62.0118 95.2874V94.812ZM62.0118 95.5251V96.0003H62.4872V95.5251H62.0118ZM62.7249 95.5251V96.0003H63.2001V95.5251H62.7249ZM63.2001 95.2874V94.812H62.7249V95.2874H63.2001ZM62.5468 94.9754H62.6656V95.3021H62.6356V95.5103H62.6656V95.8371H62.5468V95.5103H62.5762V95.3021H62.5468V94.9754ZM62.1753 95.3469V95.4657H62.5021V95.3469H62.1753ZM62.7098 95.4657V95.3469H63.0366V95.4657H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 96.5H51.7753V96.9754L51.2998 96.9754V96.5ZM51.2998 97.2131V97.6883H51.7753V97.2131H51.2998ZM52.013 97.2131V97.6883H52.4881V97.2131H52.013ZM52.4881 96.9754V96.5H52.013V96.9754L52.4881 96.9754ZM51.8348 96.6634H51.9536V96.9902H51.9236V97.1984H51.9536V97.5251H51.8348V97.1984H51.8642V96.9902H51.8348V96.6634ZM51.4629 97.035V97.1538H51.7897V97.035H51.4629ZM51.9981 97.1538V97.035H52.3249V97.1538H51.9981ZM51.2998 97.9115H51.7753V98.387H51.2998V97.9115ZM51.2998 98.6247V99.0998H51.7753V98.6247H51.2998ZM52.013 98.6247V99.0998H52.4881V98.6247H52.013ZM52.4881 98.387V97.9115H52.013V98.387L52.4881 98.387ZM51.8348 98.0749H51.9536V98.4017H51.9236V98.6099H51.9536V98.9366H51.8348V98.6099H51.8642V98.4017H51.8348V98.0749ZM51.4629 98.4465V98.5653H51.7897V98.4465H51.4629ZM51.9981 98.5653V98.4465H52.3249V98.5653H51.9981ZM53.1869 96.5H52.7115V96.9755L53.1869 96.9755V96.5ZM52.7115 97.6883V97.2132H53.1869V97.6883H52.7115ZM53.4246 97.6883V97.2132H53.8998V97.6883H53.4246ZM53.8998 96.5V96.9755L53.4246 96.9755V96.5H53.8998ZM53.3653 96.6634H53.2465V96.9902H53.2759V97.1984H53.2465V97.5252H53.3653V97.1984H53.3353V96.9902H53.3653V96.6634ZM52.875 97.1538V97.035H53.2018V97.1538H52.875ZM53.4095 97.035V97.1538H53.7363V97.035H53.4095ZM52.7115 97.9116H53.1869V98.387L52.7115 98.387V97.9116ZM52.7115 98.6247V99.0999H53.1869V98.6247H52.7115ZM53.4246 98.6247V99.0999H53.8998V98.6247H53.4246ZM53.8998 98.387V97.9116H53.4246V98.387H53.8998ZM53.2465 98.075H53.3653V98.4018H53.3353V98.6099H53.3653V98.9367H53.2465V98.6099H53.2759V98.4018H53.2465V98.075ZM52.875 98.4465V98.5653H53.2018V98.4465H52.875ZM53.4095 98.5653V98.4465H53.7363V98.5653H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 96.5H54.8754V96.9754L54.3999 96.9754V96.5ZM54.3999 97.2131V97.6883H54.8754V97.2131H54.3999ZM55.1131 97.2131V97.6883H55.5882V97.2131H55.1131ZM55.5882 96.9754V96.5H55.1131V96.9754L55.5882 96.9754ZM54.9349 96.6634H55.0537V96.9902H55.0237V97.1984H55.0537V97.5251H54.9349V97.1984H54.9643V96.9902H54.9349V96.6634ZM54.563 97.035V97.1538H54.8898V97.035H54.563ZM55.0982 97.1538V97.035H55.425V97.1538H55.0982ZM54.3999 97.9115H54.8753V98.387H54.3999V97.9115ZM54.3999 98.6247V99.0998H54.8753V98.6247H54.3999ZM55.1131 98.6247V99.0998H55.5882V98.6247H55.1131ZM55.5882 98.387V97.9115H55.1131V98.387L55.5882 98.387ZM54.9349 98.0749H55.0537V98.4017H55.0237V98.6099H55.0537V98.9366H54.9349V98.6099H54.9643V98.4017H54.9349V98.0749ZM54.563 98.4465V98.5653H54.8898V98.4465H54.563ZM55.0982 98.5653V98.4465H55.425V98.5653H55.0982ZM56.287 96.5H55.8116V96.9755L56.287 96.9755V96.5ZM55.8116 97.6883V97.2132H56.287V97.6883H55.8116ZM56.5247 97.6883V97.2132H56.9999V97.6883H56.5247ZM56.9999 96.5V96.9755L56.5247 96.9755V96.5H56.9999ZM56.4654 96.6634H56.3466V96.9902H56.376V97.1984H56.3466V97.5252H56.4654V97.1984H56.4354V96.9902H56.4654V96.6634ZM55.9751 97.1538V97.035H56.3019V97.1538H55.9751ZM56.5096 97.035V97.1538H56.8364V97.035H56.5096ZM55.8116 97.9116H56.287V98.387L55.8116 98.387V97.9116ZM55.8116 98.6247V99.0999H56.287V98.6247H55.8116ZM56.5247 98.6247V99.0999H56.9999V98.6247H56.5247ZM56.9999 98.387V97.9116H56.5247V98.387H56.9999ZM56.3466 98.075H56.4654V98.4018H56.4354V98.6099H56.4654V98.9367H56.3466V98.6099H56.376V98.4018H56.3466V98.075ZM55.9751 98.4465V98.5653H56.3019V98.4465H55.9751ZM56.5096 98.5653V98.4465H56.8364V98.5653H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 96.5H57.9755V96.9754L57.5 96.9754V96.5ZM57.5 97.2131V97.6883H57.9755V97.2131H57.5ZM58.2132 97.2131V97.6883H58.6883V97.2131H58.2132ZM58.6883 96.9754V96.5H58.2132V96.9754L58.6883 96.9754ZM58.035 96.6634H58.1538V96.9902H58.1238V97.1984H58.1538V97.5251H58.035V97.1984H58.0644V96.9902H58.035V96.6634ZM57.6631 97.035V97.1538H57.9899V97.035H57.6631ZM58.1983 97.1538V97.035H58.5251V97.1538H58.1983ZM57.5 97.9115H57.9754V98.387H57.5V97.9115ZM57.5 98.6247V99.0998H57.9754V98.6247H57.5ZM58.2132 98.6247V99.0998H58.6883V98.6247H58.2132ZM58.6883 98.387V97.9115H58.2132V98.387L58.6883 98.387ZM58.035 98.0749H58.1538V98.4017H58.1238V98.6099H58.1538V98.9366H58.035V98.6099H58.0644V98.4017H58.035V98.0749ZM57.6631 98.4465V98.5653H57.9899V98.4465H57.6631ZM58.1983 98.5653V98.4465H58.5251V98.5653H58.1983ZM59.3871 96.5H58.9117V96.9755L59.3871 96.9755V96.5ZM58.9117 97.6883V97.2132H59.3871V97.6883H58.9117ZM59.6248 97.6883V97.2132H60.1V97.6883H59.6248ZM60.1 96.5V96.9755L59.6248 96.9755V96.5H60.1ZM59.5655 96.6634H59.4467V96.9902H59.4761V97.1984H59.4467V97.5252H59.5655V97.1984H59.5355V96.9902H59.5655V96.6634ZM59.0752 97.1538V97.035H59.402V97.1538H59.0752ZM59.6097 97.035V97.1538H59.9365V97.035H59.6097ZM58.9117 97.9116H59.3871V98.387L58.9117 98.387V97.9116ZM58.9117 98.6247V99.0999H59.3871V98.6247H58.9117ZM59.6248 98.6247V99.0999H60.1V98.6247H59.6248ZM60.1 98.387V97.9116H59.6248V98.387H60.1ZM59.4467 98.075H59.5655V98.4018H59.5355V98.6099H59.5655V98.9367H59.4467V98.6099H59.4761V98.4018H59.4467V98.075ZM59.0752 98.4465V98.5653H59.402V98.4465H59.0752ZM59.6097 98.5653V98.4465H59.9365V98.5653H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 96.5H61.0756V96.9754L60.6001 96.9754V96.5ZM60.6001 97.2131V97.6883H61.0756V97.2131H60.6001ZM61.3133 97.2131V97.6883H61.7884V97.2131H61.3133ZM61.7884 96.9754V96.5H61.3133V96.9754L61.7884 96.9754ZM61.1351 96.6634H61.2539V96.9902H61.2239V97.1984H61.2539V97.5251H61.1351V97.1984H61.1645V96.9902H61.1351V96.6634ZM60.7632 97.035V97.1538H61.09V97.035H60.7632ZM61.2984 97.1538V97.035H61.6252V97.1538H61.2984ZM60.6001 97.9115H61.0755V98.387H60.6001V97.9115ZM60.6001 98.6247V99.0998H61.0755V98.6247H60.6001ZM61.3133 98.6247V99.0998H61.7884V98.6247H61.3133ZM61.7884 98.387V97.9115H61.3133V98.387L61.7884 98.387ZM61.1351 98.0749H61.2539V98.4017H61.2239V98.6099H61.2539V98.9366H61.1351V98.6099H61.1645V98.4017H61.1351V98.0749ZM60.7632 98.4465V98.5653H61.09V98.4465H60.7632ZM61.2984 98.5653V98.4465H61.6252V98.5653H61.2984ZM62.4872 96.5H62.0118V96.9755L62.4872 96.9755V96.5ZM62.0118 97.6883V97.2132H62.4872V97.6883H62.0118ZM62.7249 97.6883V97.2132H63.2001V97.6883H62.7249ZM63.2001 96.5V96.9755L62.7249 96.9755V96.5H63.2001ZM62.6656 96.6634H62.5468V96.9902H62.5762V97.1984H62.5468V97.5252H62.6656V97.1984H62.6356V96.9902H62.6656V96.6634ZM62.1753 97.1538V97.035H62.5021V97.1538H62.1753ZM62.7098 97.035V97.1538H63.0366V97.035H62.7098ZM62.0118 97.9116H62.4872V98.387L62.0118 98.387V97.9116ZM62.0118 98.6247V99.0999H62.4872V98.6247H62.0118ZM62.7249 98.6247V99.0999H63.2001V98.6247H62.7249ZM63.2001 98.387V97.9116H62.7249V98.387H63.2001ZM62.5468 98.075H62.6656V98.4018H62.6356V98.6099H62.6656V98.9367H62.5468V98.6099H62.5762V98.4018H62.5468V98.075ZM62.1753 98.4465V98.5653H62.5021V98.4465H62.1753ZM62.7098 98.5653V98.4465H63.0366V98.5653H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 99.5996H51.7753V100.075L51.2998 100.075V99.5996ZM51.2998 100.313V100.788H51.7753V100.313H51.2998ZM52.013 100.313V100.788H52.4881V100.313H52.013ZM52.4881 100.075V99.5996H52.013V100.075L52.4881 100.075ZM51.8348 99.763H51.9536V100.09H51.9236V100.298H51.9536V100.625H51.8348V100.298H51.8642V100.09H51.8348V99.763ZM51.4629 100.135V100.253H51.7897V100.135H51.4629ZM51.9981 100.253V100.135H52.3249V100.253H51.9981ZM51.2998 101.011H51.7753V101.487H51.2998V101.011ZM51.2998 101.724V102.199H51.7753V101.724H51.2998ZM52.013 101.724V102.199H52.4881V101.724H52.013ZM52.4881 101.487V101.011H52.013V101.487L52.4881 101.487ZM51.8348 101.175H51.9536V101.501H51.9236V101.709H51.9536V102.036H51.8348V101.709H51.8642V101.501H51.8348V101.175ZM51.4629 101.546V101.665H51.7897V101.546H51.4629ZM51.9981 101.665V101.546H52.3249V101.665H51.9981ZM53.1869 99.5996H52.7115V100.075L53.1869 100.075V99.5996ZM52.7115 100.788V100.313H53.1869V100.788H52.7115ZM53.4246 100.788V100.313H53.8998V100.788H53.4246ZM53.8998 99.5996V100.075L53.4246 100.075V99.5996H53.8998ZM53.3653 99.763H53.2465V100.09H53.2759V100.298H53.2465V100.625H53.3653V100.298H53.3353V100.09H53.3653V99.763ZM52.875 100.253V100.135H53.2018V100.253H52.875ZM53.4095 100.135V100.253H53.7363V100.135H53.4095ZM52.7115 101.011H53.1869V101.487L52.7115 101.487V101.011ZM52.7115 101.724V102.199H53.1869V101.724H52.7115ZM53.4246 101.724V102.199H53.8998V101.724H53.4246ZM53.8998 101.487V101.011H53.4246V101.487H53.8998ZM53.2465 101.175H53.3653V101.501H53.3353V101.71H53.3653V102.036H53.2465V101.71H53.2759V101.501H53.2465V101.175ZM52.875 101.546V101.665H53.2018V101.546H52.875ZM53.4095 101.665V101.546H53.7363V101.665H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 99.5996H54.8754V100.075L54.3999 100.075V99.5996ZM54.3999 100.313V100.788H54.8754V100.313H54.3999ZM55.1131 100.313V100.788H55.5882V100.313H55.1131ZM55.5882 100.075V99.5996H55.1131V100.075L55.5882 100.075ZM54.9349 99.763H55.0537V100.09H55.0237V100.298H55.0537V100.625H54.9349V100.298H54.9643V100.09H54.9349V99.763ZM54.563 100.135V100.253H54.8898V100.135H54.563ZM55.0982 100.253V100.135H55.425V100.253H55.0982ZM54.3999 101.011H54.8753V101.487H54.3999V101.011ZM54.3999 101.724V102.199H54.8753V101.724H54.3999ZM55.1131 101.724V102.199H55.5882V101.724H55.1131ZM55.5882 101.487V101.011H55.1131V101.487L55.5882 101.487ZM54.9349 101.175H55.0537V101.501H55.0237V101.709H55.0537V102.036H54.9349V101.709H54.9643V101.501H54.9349V101.175ZM54.563 101.546V101.665H54.8898V101.546H54.563ZM55.0982 101.665V101.546H55.425V101.665H55.0982ZM56.287 99.5996H55.8116V100.075L56.287 100.075V99.5996ZM55.8116 100.788V100.313H56.287V100.788H55.8116ZM56.5247 100.788V100.313H56.9999V100.788H56.5247ZM56.9999 99.5996V100.075L56.5247 100.075V99.5996H56.9999ZM56.4654 99.763H56.3466V100.09H56.376V100.298H56.3466V100.625H56.4654V100.298H56.4354V100.09H56.4654V99.763ZM55.9751 100.253V100.135H56.3019V100.253H55.9751ZM56.5096 100.135V100.253H56.8364V100.135H56.5096ZM55.8116 101.011H56.287V101.487L55.8116 101.487V101.011ZM55.8116 101.724V102.199H56.287V101.724H55.8116ZM56.5247 101.724V102.199H56.9999V101.724H56.5247ZM56.9999 101.487V101.011H56.5247V101.487H56.9999ZM56.3466 101.175H56.4654V101.501H56.4354V101.71H56.4654V102.036H56.3466V101.71H56.376V101.501H56.3466V101.175ZM55.9751 101.546V101.665H56.3019V101.546H55.9751ZM56.5096 101.665V101.546H56.8364V101.665H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 99.5996H57.9755V100.075L57.5 100.075V99.5996ZM57.5 100.313V100.788H57.9755V100.313H57.5ZM58.2132 100.313V100.788H58.6883V100.313H58.2132ZM58.6883 100.075V99.5996H58.2132V100.075L58.6883 100.075ZM58.035 99.763H58.1538V100.09H58.1238V100.298H58.1538V100.625H58.035V100.298H58.0644V100.09H58.035V99.763ZM57.6631 100.135V100.253H57.9899V100.135H57.6631ZM58.1983 100.253V100.135H58.5251V100.253H58.1983ZM57.5 101.011H57.9754V101.487H57.5V101.011ZM57.5 101.724V102.199H57.9754V101.724H57.5ZM58.2132 101.724V102.199H58.6883V101.724H58.2132ZM58.6883 101.487V101.011H58.2132V101.487L58.6883 101.487ZM58.035 101.175H58.1538V101.501H58.1238V101.709H58.1538V102.036H58.035V101.709H58.0644V101.501H58.035V101.175ZM57.6631 101.546V101.665H57.9899V101.546H57.6631ZM58.1983 101.665V101.546H58.5251V101.665H58.1983ZM59.3871 99.5996H58.9117V100.075L59.3871 100.075V99.5996ZM58.9117 100.788V100.313H59.3871V100.788H58.9117ZM59.6248 100.788V100.313H60.1V100.788H59.6248ZM60.1 99.5996V100.075L59.6248 100.075V99.5996H60.1ZM59.5655 99.763H59.4467V100.09H59.4761V100.298H59.4467V100.625H59.5655V100.298H59.5355V100.09H59.5655V99.763ZM59.0752 100.253V100.135H59.402V100.253H59.0752ZM59.6097 100.135V100.253H59.9365V100.135H59.6097ZM58.9117 101.011H59.3871V101.487L58.9117 101.487V101.011ZM58.9117 101.724V102.199H59.3871V101.724H58.9117ZM59.6248 101.724V102.199H60.1V101.724H59.6248ZM60.1 101.487V101.011H59.6248V101.487H60.1ZM59.4467 101.175H59.5655V101.501H59.5355V101.71H59.5655V102.036H59.4467V101.71H59.4761V101.501H59.4467V101.175ZM59.0752 101.546V101.665H59.402V101.546H59.0752ZM59.6097 101.665V101.546H59.9365V101.665H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 99.5996H61.0756V100.075L60.6001 100.075V99.5996ZM60.6001 100.313V100.788H61.0756V100.313H60.6001ZM61.3133 100.313V100.788H61.7884V100.313H61.3133ZM61.7884 100.075V99.5996H61.3133V100.075L61.7884 100.075ZM61.1351 99.763H61.2539V100.09H61.2239V100.298H61.2539V100.625H61.1351V100.298H61.1645V100.09H61.1351V99.763ZM60.7632 100.135V100.253H61.09V100.135H60.7632ZM61.2984 100.253V100.135H61.6252V100.253H61.2984ZM60.6001 101.011H61.0755V101.487H60.6001V101.011ZM60.6001 101.724V102.199H61.0755V101.724H60.6001ZM61.3133 101.724V102.199H61.7884V101.724H61.3133ZM61.7884 101.487V101.011H61.3133V101.487L61.7884 101.487ZM61.1351 101.175H61.2539V101.501H61.2239V101.709H61.2539V102.036H61.1351V101.709H61.1645V101.501H61.1351V101.175ZM60.7632 101.546V101.665H61.09V101.546H60.7632ZM61.2984 101.665V101.546H61.6252V101.665H61.2984ZM62.4872 99.5996H62.0118V100.075L62.4872 100.075V99.5996ZM62.0118 100.788V100.313H62.4872V100.788H62.0118ZM62.7249 100.788V100.313H63.2001V100.788H62.7249ZM63.2001 99.5996V100.075L62.7249 100.075V99.5996H63.2001ZM62.6656 99.763H62.5468V100.09H62.5762V100.298H62.5468V100.625H62.6656V100.298H62.6356V100.09H62.6656V99.763ZM62.1753 100.253V100.135H62.5021V100.253H62.1753ZM62.7098 100.135V100.253H63.0366V100.135H62.7098ZM62.0118 101.011H62.4872V101.487L62.0118 101.487V101.011ZM62.0118 101.724V102.199H62.4872V101.724H62.0118ZM62.7249 101.724V102.199H63.2001V101.724H62.7249ZM63.2001 101.487V101.011H62.7249V101.487H63.2001ZM62.5468 101.175H62.6656V101.501H62.6356V101.71H62.6656V102.036H62.5468V101.71H62.5762V101.501H62.5468V101.175ZM62.1753 101.546V101.665H62.5021V101.546H62.1753ZM62.7098 101.665V101.546H63.0366V101.665H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 90.2998H64.2753V90.7752L63.7998 90.7752V90.2998ZM63.7998 91.0129V91.4881H64.2753V91.0129H63.7998ZM64.513 91.0129V91.4881H64.9881V91.0129H64.513ZM64.9881 90.7752V90.2998H64.513V90.7752L64.9881 90.7752ZM64.3348 90.4632H64.4536V90.79H64.4236V90.9982H64.4536V91.325H64.3348V90.9982H64.3642V90.79H64.3348V90.4632ZM63.9629 90.8348V90.9536H64.2897V90.8348H63.9629ZM64.4981 90.9536V90.8348H64.8249V90.9536H64.4981ZM63.7998 91.7113H64.2753V92.1868H63.7998V91.7113ZM63.7998 92.4245V92.8996H64.2753V92.4245H63.7998ZM64.513 92.4245V92.8996H64.9881V92.4245H64.513ZM64.9881 92.1868V91.7113H64.513V92.1868H64.9881ZM64.3348 91.8747H64.4536V92.2015H64.4236V92.4097H64.4536V92.7364H64.3348V92.4097H64.3642V92.2015H64.3348V91.8747ZM63.9629 92.2463V92.3651H64.2897V92.2463H63.9629ZM64.4981 92.3651V92.2463H64.8249V92.3651H64.4981ZM65.6869 90.2998H65.2115V90.7753L65.6869 90.7753V90.2998ZM65.2115 91.4881V91.013H65.6869V91.4881H65.2115ZM65.9246 91.4881V91.013H66.3998V91.4881H65.9246ZM66.3998 90.2998V90.7753L65.9246 90.7753V90.2998H66.3998ZM65.8653 90.4632H65.7465V90.79H65.7759V90.9982H65.7465V91.325H65.8653V90.9982H65.8353V90.79H65.8653V90.4632ZM65.375 90.9536V90.8348H65.7018V90.9536H65.375ZM65.9095 90.8348V90.9536H66.2363V90.8348H65.9095ZM65.2115 91.7114H65.6869V92.1868H65.2115V91.7114ZM65.2115 92.4245V92.8997H65.6869V92.4245H65.2115ZM65.9246 92.4245V92.8997H66.3998V92.4245H65.9246ZM66.3998 92.1868V91.7114H65.9246V92.1868H66.3998ZM65.7465 91.8748H65.8653V92.2016H65.8353V92.4097H65.8653V92.7365H65.7465V92.4097H65.7759V92.2016H65.7465V91.8748ZM65.375 92.2463V92.3651H65.7018V92.2463H65.375ZM65.9095 92.3651V92.2463H66.2363V92.3651H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 90.2998H67.3754V90.7752L66.8999 90.7752V90.2998ZM66.8999 91.0129V91.4881H67.3754V91.0129H66.8999ZM67.6131 91.0129V91.4881H68.0882V91.0129H67.6131ZM68.0882 90.7752V90.2998H67.6131V90.7752L68.0882 90.7752ZM67.4349 90.4632H67.5537V90.79H67.5237V90.9982H67.5537V91.325H67.4349V90.9982H67.4643V90.79H67.4349V90.4632ZM67.063 90.8348V90.9536H67.3898V90.8348H67.063ZM67.5982 90.9536V90.8348H67.925V90.9536H67.5982ZM66.8999 91.7113H67.3753V92.1868H66.8999V91.7113ZM66.8999 92.4245V92.8996H67.3753V92.4245H66.8999ZM67.6131 92.4245V92.8996H68.0882V92.4245H67.6131ZM68.0882 92.1868V91.7113H67.6131V92.1868L68.0882 92.1868ZM67.4349 91.8747H67.5537V92.2015H67.5237V92.4097H67.5537V92.7364H67.4349V92.4097H67.4643V92.2015H67.4349V91.8747ZM67.063 92.2463V92.3651H67.3898V92.2463H67.063ZM67.5982 92.3651V92.2463H67.925V92.3651H67.5982ZM68.787 90.2998H68.3116V90.7753L68.787 90.7753V90.2998ZM68.3116 91.4881V91.013H68.787V91.4881H68.3116ZM69.0247 91.4881V91.013H69.4999V91.4881H69.0247ZM69.4999 90.2998V90.7753L69.0247 90.7753V90.2998H69.4999ZM68.9654 90.4632H68.8466V90.79H68.876V90.9982H68.8466V91.325H68.9654V90.9982H68.9354V90.79H68.9654V90.4632ZM68.4751 90.9536V90.8348H68.8019V90.9536H68.4751ZM69.0096 90.8348V90.9536H69.3364V90.8348H69.0096ZM68.3116 91.7114H68.787V92.1868L68.3116 92.1868V91.7114ZM68.3116 92.4245V92.8997H68.787V92.4245H68.3116ZM69.0247 92.4245V92.8997H69.4999V92.4245H69.0247ZM69.4999 92.1868V91.7114H69.0247V92.1868H69.4999ZM68.8466 91.8748H68.9654V92.2016H68.9354V92.4097H68.9654V92.7365H68.8466V92.4097H68.876V92.2016H68.8466V91.8748ZM68.4751 92.2463V92.3651H68.8019V92.2463H68.4751ZM69.0096 92.3651V92.2463H69.3364V92.3651H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 90.2998H70.4755V90.7752L70 90.7752V90.2998ZM70 91.0129V91.4881H70.4755V91.0129H70ZM70.7132 91.0129V91.4881H71.1883V91.0129H70.7132ZM71.1883 90.7752V90.2998H70.7132V90.7752L71.1883 90.7752ZM70.535 90.4632H70.6538V90.79H70.6238V90.9982H70.6538V91.325H70.535V90.9982H70.5644V90.79H70.535V90.4632ZM70.1631 90.8348V90.9536H70.4899V90.8348H70.1631ZM70.6983 90.9536V90.8348H71.0251V90.9536H70.6983ZM70 91.7113H70.4754V92.1868H70V91.7113ZM70 92.4245V92.8996H70.4754V92.4245H70ZM70.7132 92.4245V92.8996H71.1883V92.4245H70.7132ZM71.1883 92.1868V91.7113H70.7132V92.1868L71.1883 92.1868ZM70.535 91.8747H70.6538V92.2015H70.6238V92.4097H70.6538V92.7364H70.535V92.4097H70.5644V92.2015H70.535V91.8747ZM70.1631 92.2463V92.3651H70.4899V92.2463H70.1631ZM70.6983 92.3651V92.2463H71.0251V92.3651H70.6983ZM71.8871 90.2998H71.4117V90.7753L71.8871 90.7753V90.2998ZM71.4117 91.4881V91.013H71.8871V91.4881H71.4117ZM72.1248 91.4881V91.013H72.6V91.4881H72.1248ZM72.6 90.2998V90.7753L72.1248 90.7753V90.2998H72.6ZM72.0655 90.4632H71.9467V90.79H71.9761V90.9982H71.9467V91.325H72.0655V90.9982H72.0355V90.79H72.0655V90.4632ZM71.5752 90.9536V90.8348H71.902V90.9536H71.5752ZM72.1097 90.8348V90.9536H72.4365V90.8348H72.1097ZM71.4117 91.7114H71.8871V92.1868L71.4117 92.1868V91.7114ZM71.4117 92.4245V92.8997H71.8871V92.4245H71.4117ZM72.1248 92.4245V92.8997H72.6V92.4245H72.1248ZM72.6 92.1868V91.7114H72.1248V92.1868H72.6ZM71.9467 91.8748H72.0655V92.2016H72.0355V92.4097H72.0655V92.7365H71.9467V92.4097H71.9761V92.2016H71.9467V91.8748ZM71.5752 92.2463V92.3651H71.902V92.2463H71.5752ZM72.1097 92.3651V92.2463H72.4365V92.3651H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 90.2998H73.5756V90.7752L73.1001 90.7752V90.2998ZM73.1001 91.0129V91.4881H73.5756V91.0129H73.1001ZM73.8133 91.0129V91.4881H74.2884V91.0129H73.8133ZM74.2884 90.7752V90.2998H73.8133V90.7752L74.2884 90.7752ZM73.6351 90.4632H73.7539V90.79H73.7239V90.9982H73.7539V91.325H73.6351V90.9982H73.6645V90.79H73.6351V90.4632ZM73.2632 90.8348V90.9536H73.59V90.8348H73.2632ZM73.7984 90.9536V90.8348H74.1252V90.9536H73.7984ZM73.1001 91.7113H73.5755V92.1868H73.1001V91.7113ZM73.1001 92.4245V92.8996H73.5755V92.4245H73.1001ZM73.8133 92.4245V92.8996H74.2884V92.4245H73.8133ZM74.2884 92.1868V91.7113H73.8133V92.1868L74.2884 92.1868ZM73.6351 91.8747H73.7539V92.2015H73.7239V92.4097H73.7539V92.7364H73.6351V92.4097H73.6645V92.2015H73.6351V91.8747ZM73.2632 92.2463V92.3651H73.59V92.2463H73.2632ZM73.7984 92.3651V92.2463H74.1252V92.3651H73.7984ZM74.9872 90.2998H74.5118V90.7753L74.9872 90.7753V90.2998ZM74.5118 91.4881V91.013H74.9872V91.4881H74.5118ZM75.2249 91.4881V91.013H75.7001V91.4881H75.2249ZM75.7001 90.2998V90.7753L75.2249 90.7753V90.2998H75.7001ZM75.1656 90.4632H75.0468V90.79H75.0762V90.9982H75.0468V91.325H75.1656V90.9982H75.1356V90.79H75.1656V90.4632ZM74.6753 90.9536V90.8348H75.0021V90.9536H74.6753ZM75.2098 90.8348V90.9536H75.5366V90.8348H75.2098ZM74.5118 91.7114H74.9872V92.1868L74.5118 92.1868V91.7114ZM74.5118 92.4245V92.8997H74.9872V92.4245H74.5118ZM75.2249 92.4245V92.8997H75.7001V92.4245H75.2249ZM75.7001 92.1868V91.7114H75.2249V92.1868H75.7001ZM75.0468 91.8748H75.1656V92.2016H75.1356V92.4097H75.1656V92.7365H75.0468V92.4097H75.0762V92.2016H75.0468V91.8748ZM74.6753 92.2463V92.3651H75.0021V92.2463H74.6753ZM75.2098 92.3651V92.2463H75.5366V92.3651H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 93.4004H64.2753V93.8758L63.7998 93.8758V93.4004ZM63.7998 94.1135V94.5887H64.2753V94.1135H63.7998ZM64.513 94.1135V94.5887H64.9881V94.1135H64.513ZM64.9881 93.8758V93.4004H64.513V93.8758L64.9881 93.8758ZM64.3348 93.5638H64.4536V93.8906H64.4236V94.0988H64.4536V94.4255H64.3348V94.0988H64.3642V93.8906H64.3348V93.5638ZM63.9629 93.9353V94.0542H64.2897V93.9353H63.9629ZM64.4981 94.0542V93.9354H64.8249V94.0542H64.4981ZM63.7998 94.8119H64.2753V95.2874H63.7998V94.8119ZM63.7998 95.5251V96.0002H64.2753V95.5251H63.7998ZM64.513 95.5251V96.0002H64.9881V95.5251H64.513ZM64.9881 95.2874V94.8119H64.513V95.2874L64.9881 95.2874ZM64.3348 94.9753H64.4536V95.3021H64.4236V95.5103H64.4536V95.837H64.3348V95.5103H64.3642V95.3021H64.3348V94.9753ZM63.9629 95.3468V95.4657H64.2897V95.3468H63.9629ZM64.4981 95.4657V95.3469H64.8249V95.4657H64.4981ZM65.6869 93.4004H65.2115V93.8759L65.6869 93.8759V93.4004ZM65.2115 94.5887V94.1136H65.6869V94.5887H65.2115ZM65.9246 94.5887V94.1136H66.3998V94.5887H65.9246ZM66.3998 93.4004V93.8759L65.9246 93.8759V93.4004H66.3998ZM65.8653 93.5638H65.7465V93.8906H65.7759V94.0988H65.7465V94.4256H65.8653V94.0988H65.8353V93.8906H65.8653V93.5638ZM65.375 94.0542V93.9354H65.7018V94.0542H65.375ZM65.9095 93.9354V94.0542H66.2363V93.9354H65.9095ZM65.2115 94.812H65.6869V95.2874L65.2115 95.2874V94.812ZM65.2115 95.5251V96.0003H65.6869V95.5251H65.2115ZM65.9246 95.5251V96.0003H66.3998V95.5251H65.9246ZM66.3998 95.2874V94.812H65.9246V95.2874H66.3998ZM65.7465 94.9754H65.8653V95.3021H65.8353V95.5103H65.8653V95.8371H65.7465V95.5103H65.7759V95.3021H65.7465V94.9754ZM65.375 95.3469V95.4657H65.7018V95.3469H65.375ZM65.9095 95.4657V95.3469H66.2363V95.4657H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 93.4004H67.3754V93.8758L66.8999 93.8758V93.4004ZM66.8999 94.1135V94.5887H67.3754V94.1135H66.8999ZM67.6131 94.1135V94.5887H68.0882V94.1135H67.6131ZM68.0882 93.8758V93.4004H67.6131V93.8758L68.0882 93.8758ZM67.4349 93.5638H67.5537V93.8906H67.5237V94.0988H67.5537V94.4255H67.4349V94.0988H67.4643V93.8906H67.4349V93.5638ZM67.063 93.9353V94.0542H67.3898V93.9353H67.063ZM67.5982 94.0542V93.9354H67.925V94.0542H67.5982ZM66.8999 94.8119H67.3753V95.2874H66.8999V94.8119ZM66.8999 95.5251V96.0002H67.3753V95.5251H66.8999ZM67.6131 95.5251V96.0002H68.0882V95.5251H67.6131ZM68.0882 95.2874V94.8119H67.6131V95.2874L68.0882 95.2874ZM67.4349 94.9753H67.5537V95.3021H67.5237V95.5103H67.5537V95.837H67.4349V95.5103H67.4643V95.3021H67.4349V94.9753ZM67.063 95.3468V95.4657H67.3898V95.3468H67.063ZM67.5982 95.4657V95.3469H67.925V95.4657H67.5982ZM68.787 93.4004H68.3116V93.8759L68.787 93.8759V93.4004ZM68.3116 94.5887V94.1136H68.787V94.5887H68.3116ZM69.0247 94.5887V94.1136H69.4999V94.5887H69.0247ZM69.4999 93.4004V93.8759L69.0247 93.8759V93.4004H69.4999ZM68.9654 93.5638H68.8466V93.8906H68.876V94.0988H68.8466V94.4256H68.9654V94.0988H68.9354V93.8906H68.9654V93.5638ZM68.4751 94.0542V93.9354H68.8019V94.0542H68.4751ZM69.0096 93.9354V94.0542H69.3364V93.9354H69.0096ZM68.3116 94.812H68.787V95.2874L68.3116 95.2874V94.812ZM68.3116 95.5251V96.0003H68.787V95.5251H68.3116ZM69.0247 95.5251V96.0003H69.4999V95.5251H69.0247ZM69.4999 95.2874V94.812H69.0247V95.2874H69.4999ZM68.8466 94.9754H68.9654V95.3021H68.9354V95.5103H68.9654V95.8371H68.8466V95.5103H68.876V95.3021H68.8466V94.9754ZM68.4751 95.3469V95.4657H68.8019V95.3469H68.4751ZM69.0096 95.4657V95.3469H69.3364V95.4657H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 93.4004H70.4755V93.8758L70 93.8758V93.4004ZM70 94.1135V94.5887H70.4755V94.1135H70ZM70.7132 94.1135V94.5887H71.1883V94.1135H70.7132ZM71.1883 93.8758V93.4004H70.7132V93.8758L71.1883 93.8758ZM70.535 93.5638H70.6538V93.8906H70.6238V94.0988H70.6538V94.4255H70.535V94.0988H70.5644V93.8906H70.535V93.5638ZM70.1631 93.9353V94.0542H70.4899V93.9353H70.1631ZM70.6983 94.0542V93.9354H71.0251V94.0542H70.6983ZM70 94.8119H70.4754V95.2874H70V94.8119ZM70 95.5251V96.0002H70.4754V95.5251H70ZM70.7132 95.5251V96.0002H71.1883V95.5251H70.7132ZM71.1883 95.2874V94.8119H70.7132V95.2874L71.1883 95.2874ZM70.535 94.9753H70.6538V95.3021H70.6238V95.5103H70.6538V95.837H70.535V95.5103H70.5644V95.3021H70.535V94.9753ZM70.1631 95.3468V95.4657H70.4899V95.3468H70.1631ZM70.6983 95.4657V95.3469H71.0251V95.4657H70.6983ZM71.8871 93.4004H71.4117V93.8759L71.8871 93.8759V93.4004ZM71.4117 94.5887V94.1136H71.8871V94.5887H71.4117ZM72.1248 94.5887V94.1136H72.6V94.5887H72.1248ZM72.6 93.4004V93.8759L72.1248 93.8759V93.4004H72.6ZM72.0655 93.5638H71.9467V93.8906H71.9761V94.0988H71.9467V94.4256H72.0655V94.0988H72.0355V93.8906H72.0655V93.5638ZM71.5752 94.0542V93.9354H71.902V94.0542H71.5752ZM72.1097 93.9354V94.0542H72.4365V93.9354H72.1097ZM71.4117 94.812H71.8871V95.2874L71.4117 95.2874V94.812ZM71.4117 95.5251V96.0003H71.8871V95.5251H71.4117ZM72.1248 95.5251V96.0003H72.6V95.5251H72.1248ZM72.6 95.2874V94.812H72.1248V95.2874H72.6ZM71.9467 94.9754H72.0655V95.3021H72.0355V95.5103H72.0655V95.8371H71.9467V95.5103H71.9761V95.3021H71.9467V94.9754ZM71.5752 95.3469V95.4657H71.902V95.3469H71.5752ZM72.1097 95.4657V95.3469H72.4365V95.4657H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 93.4004H73.5756V93.8758L73.1001 93.8758V93.4004ZM73.1001 94.1135V94.5887H73.5756V94.1135H73.1001ZM73.8133 94.1135V94.5887H74.2884V94.1135H73.8133ZM74.2884 93.8758V93.4004H73.8133V93.8758L74.2884 93.8758ZM73.6351 93.5638H73.7539V93.8906H73.7239V94.0988H73.7539V94.4255H73.6351V94.0988H73.6645V93.8906H73.6351V93.5638ZM73.2632 93.9353V94.0542H73.59V93.9353H73.2632ZM73.7984 94.0542V93.9354H74.1252V94.0542H73.7984ZM73.1001 94.8119H73.5755V95.2874H73.1001V94.8119ZM73.1001 95.5251V96.0002H73.5755V95.5251H73.1001ZM73.8133 95.5251V96.0002H74.2884V95.5251H73.8133ZM74.2884 95.2874V94.8119H73.8133V95.2874L74.2884 95.2874ZM73.6351 94.9753H73.7539V95.3021H73.7239V95.5103H73.7539V95.837H73.6351V95.5103H73.6645V95.3021H73.6351V94.9753ZM73.2632 95.3468V95.4657H73.59V95.3468H73.2632ZM73.7984 95.4657V95.3469H74.1252V95.4657H73.7984ZM74.9872 93.4004H74.5118V93.8759L74.9872 93.8759V93.4004ZM74.5118 94.5887V94.1136H74.9872V94.5887H74.5118ZM75.2249 94.5887V94.1136H75.7001V94.5887H75.2249ZM75.7001 93.4004V93.8759L75.2249 93.8759V93.4004H75.7001ZM75.1656 93.5638H75.0468V93.8906H75.0762V94.0988H75.0468V94.4256H75.1656V94.0988H75.1356V93.8906H75.1656V93.5638ZM74.6753 94.0542V93.9354H75.0021V94.0542H74.6753ZM75.2098 93.9354V94.0542H75.5366V93.9354H75.2098ZM74.5118 94.812H74.9872V95.2874L74.5118 95.2874V94.812ZM74.5118 95.5251V96.0003H74.9872V95.5251H74.5118ZM75.2249 95.5251V96.0003H75.7001V95.5251H75.2249ZM75.7001 95.2874V94.812H75.2249V95.2874H75.7001ZM75.0468 94.9754H75.1656V95.3021H75.1356V95.5103H75.1656V95.8371H75.0468V95.5103H75.0762V95.3021H75.0468V94.9754ZM74.6753 95.3469V95.4657H75.0021V95.3469H74.6753ZM75.2098 95.4657V95.3469H75.5366V95.4657H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 96.5H64.2753V96.9754L63.7998 96.9754V96.5ZM63.7998 97.2131V97.6883H64.2753V97.2131H63.7998ZM64.513 97.2131V97.6883H64.9881V97.2131H64.513ZM64.9881 96.9754V96.5H64.513V96.9754L64.9881 96.9754ZM64.3348 96.6634H64.4536V96.9902H64.4236V97.1984H64.4536V97.5251H64.3348V97.1984H64.3642V96.9902H64.3348V96.6634ZM63.9629 97.035V97.1538H64.2897V97.035H63.9629ZM64.4981 97.1538V97.035H64.8249V97.1538H64.4981ZM63.7998 97.9115H64.2753V98.387H63.7998V97.9115ZM63.7998 98.6247V99.0998H64.2753V98.6247H63.7998ZM64.513 98.6247V99.0998H64.9881V98.6247H64.513ZM64.9881 98.387V97.9115H64.513V98.387L64.9881 98.387ZM64.3348 98.0749H64.4536V98.4017H64.4236V98.6099H64.4536V98.9366H64.3348V98.6099H64.3642V98.4017H64.3348V98.0749ZM63.9629 98.4465V98.5653H64.2897V98.4465H63.9629ZM64.4981 98.5653V98.4465H64.8249V98.5653H64.4981ZM65.6869 96.5H65.2115V96.9755L65.6869 96.9755V96.5ZM65.2115 97.6883V97.2132H65.6869V97.6883H65.2115ZM65.9246 97.6883V97.2132H66.3998V97.6883H65.9246ZM66.3998 96.5V96.9755L65.9246 96.9755V96.5H66.3998ZM65.8653 96.6634H65.7465V96.9902H65.7759V97.1984H65.7465V97.5252H65.8653V97.1984H65.8353V96.9902H65.8653V96.6634ZM65.375 97.1538V97.035H65.7018V97.1538H65.375ZM65.9095 97.035V97.1538H66.2363V97.035H65.9095ZM65.2115 97.9116H65.6869V98.387L65.2115 98.387V97.9116ZM65.2115 98.6247V99.0999H65.6869V98.6247H65.2115ZM65.9246 98.6247V99.0999H66.3998V98.6247H65.9246ZM66.3998 98.387V97.9116H65.9246V98.387H66.3998ZM65.7465 98.075H65.8653V98.4018H65.8353V98.6099H65.8653V98.9367H65.7465V98.6099H65.7759V98.4018H65.7465V98.075ZM65.375 98.4465V98.5653H65.7018V98.4465H65.375ZM65.9095 98.5653V98.4465H66.2363V98.5653H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 96.5H67.3754V96.9754L66.8999 96.9754V96.5ZM66.8999 97.2131V97.6883H67.3754V97.2131H66.8999ZM67.6131 97.2131V97.6883H68.0882V97.2131H67.6131ZM68.0882 96.9754V96.5H67.6131V96.9754L68.0882 96.9754ZM67.4349 96.6634H67.5537V96.9902H67.5237V97.1984H67.5537V97.5251H67.4349V97.1984H67.4643V96.9902H67.4349V96.6634ZM67.063 97.035V97.1538H67.3898V97.035H67.063ZM67.5982 97.1538V97.035H67.925V97.1538H67.5982ZM66.8999 97.9115H67.3753V98.387H66.8999V97.9115ZM66.8999 98.6247V99.0998H67.3753V98.6247H66.8999ZM67.6131 98.6247V99.0998H68.0882V98.6247H67.6131ZM68.0882 98.387V97.9115H67.6131V98.387L68.0882 98.387ZM67.4349 98.0749H67.5537V98.4017H67.5237V98.6099H67.5537V98.9366H67.4349V98.6099H67.4643V98.4017H67.4349V98.0749ZM67.063 98.4465V98.5653H67.3898V98.4465H67.063ZM67.5982 98.5653V98.4465H67.925V98.5653H67.5982ZM68.787 96.5H68.3116V96.9755L68.787 96.9755V96.5ZM68.3116 97.6883V97.2132H68.787V97.6883H68.3116ZM69.0247 97.6883V97.2132H69.4999V97.6883H69.0247ZM69.4999 96.5V96.9755L69.0247 96.9755V96.5H69.4999ZM68.9654 96.6634H68.8466V96.9902H68.876V97.1984H68.8466V97.5252H68.9654V97.1984H68.9354V96.9902H68.9654V96.6634ZM68.4751 97.1538V97.035H68.8019V97.1538H68.4751ZM69.0096 97.035V97.1538H69.3364V97.035H69.0096ZM68.3116 97.9116H68.787V98.387L68.3116 98.387V97.9116ZM68.3116 98.6247V99.0999H68.787V98.6247H68.3116ZM69.0247 98.6247V99.0999H69.4999V98.6247H69.0247ZM69.4999 98.387V97.9116H69.0247V98.387H69.4999ZM68.8466 98.075H68.9654V98.4018H68.9354V98.6099H68.9654V98.9367H68.8466V98.6099H68.876V98.4018H68.8466V98.075ZM68.4751 98.4465V98.5653H68.8019V98.4465H68.4751ZM69.0096 98.5653V98.4465H69.3364V98.5653H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 96.5H70.4755V96.9754L70 96.9754V96.5ZM70 97.2131V97.6883H70.4755V97.2131H70ZM70.7132 97.2131V97.6883H71.1883V97.2131H70.7132ZM71.1883 96.9754V96.5H70.7132V96.9754L71.1883 96.9754ZM70.535 96.6634H70.6538V96.9902H70.6238V97.1984H70.6538V97.5251H70.535V97.1984H70.5644V96.9902H70.535V96.6634ZM70.1631 97.035V97.1538H70.4899V97.035H70.1631ZM70.6983 97.1538V97.035H71.0251V97.1538H70.6983ZM70 97.9115H70.4754V98.387H70V97.9115ZM70 98.6247V99.0998H70.4754V98.6247H70ZM70.7132 98.6247V99.0998H71.1883V98.6247H70.7132ZM71.1883 98.387V97.9115H70.7132V98.387L71.1883 98.387ZM70.535 98.0749H70.6538V98.4017H70.6238V98.6099H70.6538V98.9366H70.535V98.6099H70.5644V98.4017H70.535V98.0749ZM70.1631 98.4465V98.5653H70.4899V98.4465H70.1631ZM70.6983 98.5653V98.4465H71.0251V98.5653H70.6983ZM71.8871 96.5H71.4117V96.9755L71.8871 96.9755V96.5ZM71.4117 97.6883V97.2132H71.8871V97.6883H71.4117ZM72.1248 97.6883V97.2132H72.6V97.6883H72.1248ZM72.6 96.5V96.9755L72.1248 96.9755V96.5H72.6ZM72.0655 96.6634H71.9467V96.9902H71.9761V97.1984H71.9467V97.5252H72.0655V97.1984H72.0355V96.9902H72.0655V96.6634ZM71.5752 97.1538V97.035H71.902V97.1538H71.5752ZM72.1097 97.035V97.1538H72.4365V97.035H72.1097ZM71.4117 97.9116H71.8871V98.387L71.4117 98.387V97.9116ZM71.4117 98.6247V99.0999H71.8871V98.6247H71.4117ZM72.1248 98.6247V99.0999H72.6V98.6247H72.1248ZM72.6 98.387V97.9116H72.1248V98.387H72.6ZM71.9467 98.075H72.0655V98.4018H72.0355V98.6099H72.0655V98.9367H71.9467V98.6099H71.9761V98.4018H71.9467V98.075ZM71.5752 98.4465V98.5653H71.902V98.4465H71.5752ZM72.1097 98.5653V98.4465H72.4365V98.5653H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 96.5H73.5756V96.9754L73.1001 96.9754V96.5ZM73.1001 97.2131V97.6883H73.5756V97.2131H73.1001ZM73.8133 97.2131V97.6883H74.2884V97.2131H73.8133ZM74.2884 96.9754V96.5H73.8133V96.9754L74.2884 96.9754ZM73.6351 96.6634H73.7539V96.9902H73.7239V97.1984H73.7539V97.5251H73.6351V97.1984H73.6645V96.9902H73.6351V96.6634ZM73.2632 97.035V97.1538H73.59V97.035H73.2632ZM73.7984 97.1538V97.035H74.1252V97.1538H73.7984ZM73.1001 97.9115H73.5755V98.387H73.1001V97.9115ZM73.1001 98.6247V99.0998H73.5755V98.6247H73.1001ZM73.8133 98.6247V99.0998H74.2884V98.6247H73.8133ZM74.2884 98.387V97.9115H73.8133V98.387L74.2884 98.387ZM73.6351 98.0749H73.7539V98.4017H73.7239V98.6099H73.7539V98.9366H73.6351V98.6099H73.6645V98.4017H73.6351V98.0749ZM73.2632 98.4465V98.5653H73.59V98.4465H73.2632ZM73.7984 98.5653V98.4465H74.1252V98.5653H73.7984ZM74.9872 96.5H74.5118V96.9755L74.9872 96.9755V96.5ZM74.5118 97.6883V97.2132H74.9872V97.6883H74.5118ZM75.2249 97.6883V97.2132H75.7001V97.6883H75.2249ZM75.7001 96.5V96.9755L75.2249 96.9755V96.5H75.7001ZM75.1656 96.6634H75.0468V96.9902H75.0762V97.1984H75.0468V97.5252H75.1656V97.1984H75.1356V96.9902H75.1656V96.6634ZM74.6753 97.1538V97.035H75.0021V97.1538H74.6753ZM75.2098 97.035V97.1538H75.5366V97.035H75.2098ZM74.5118 97.9116H74.9872V98.387L74.5118 98.387V97.9116ZM74.5118 98.6247V99.0999H74.9872V98.6247H74.5118ZM75.2249 98.6247V99.0999H75.7001V98.6247H75.2249ZM75.7001 98.387V97.9116H75.2249V98.387H75.7001ZM75.0468 98.075H75.1656V98.4018H75.1356V98.6099H75.1656V98.9367H75.0468V98.6099H75.0762V98.4018H75.0468V98.075ZM74.6753 98.4465V98.5653H75.0021V98.4465H74.6753ZM75.2098 98.5653V98.4465H75.5366V98.5653H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 99.5996H64.2753V100.075L63.7998 100.075V99.5996ZM63.7998 100.313V100.788H64.2753V100.313H63.7998ZM64.513 100.313V100.788H64.9881V100.313H64.513ZM64.9881 100.075V99.5996H64.513V100.075L64.9881 100.075ZM64.3348 99.763H64.4536V100.09H64.4236V100.298H64.4536V100.625H64.3348V100.298H64.3642V100.09H64.3348V99.763ZM63.9629 100.135V100.253H64.2897V100.135H63.9629ZM64.4981 100.253V100.135H64.8249V100.253H64.4981ZM63.7998 101.011H64.2753V101.487H63.7998V101.011ZM63.7998 101.724V102.199H64.2753V101.724H63.7998ZM64.513 101.724V102.199H64.9881V101.724H64.513ZM64.9881 101.487V101.011H64.513V101.487L64.9881 101.487ZM64.3348 101.175H64.4536V101.501H64.4236V101.709H64.4536V102.036H64.3348V101.709H64.3642V101.501H64.3348V101.175ZM63.9629 101.546V101.665H64.2897V101.546H63.9629ZM64.4981 101.665V101.546H64.8249V101.665H64.4981ZM65.6869 99.5996H65.2115V100.075L65.6869 100.075V99.5996ZM65.2115 100.788V100.313H65.6869V100.788H65.2115ZM65.9246 100.788V100.313H66.3998V100.788H65.9246ZM66.3998 99.5996V100.075L65.9246 100.075V99.5996H66.3998ZM65.8653 99.763H65.7465V100.09H65.7759V100.298H65.7465V100.625H65.8653V100.298H65.8353V100.09H65.8653V99.763ZM65.375 100.253V100.135H65.7018V100.253H65.375ZM65.9095 100.135V100.253H66.2363V100.135H65.9095ZM65.2115 101.011H65.6869V101.487L65.2115 101.487V101.011ZM65.2115 101.724V102.199H65.6869V101.724H65.2115ZM65.9246 101.724V102.199H66.3998V101.724H65.9246ZM66.3998 101.487V101.011H65.9246V101.487H66.3998ZM65.7465 101.175H65.8653V101.501H65.8353V101.71H65.8653V102.036H65.7465V101.71H65.7759V101.501H65.7465V101.175ZM65.375 101.546V101.665H65.7018V101.546H65.375ZM65.9095 101.665V101.546H66.2363V101.665H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 99.5996H67.3754V100.075L66.8999 100.075V99.5996ZM66.8999 100.313V100.788H67.3754V100.313H66.8999ZM67.6131 100.313V100.788H68.0882V100.313H67.6131ZM68.0882 100.075V99.5996H67.6131V100.075L68.0882 100.075ZM67.4349 99.763H67.5537V100.09H67.5237V100.298H67.5537V100.625H67.4349V100.298H67.4643V100.09H67.4349V99.763ZM67.063 100.135V100.253H67.3898V100.135H67.063ZM67.5982 100.253V100.135H67.925V100.253H67.5982ZM66.8999 101.011H67.3753V101.487H66.8999V101.011ZM66.8999 101.724V102.199H67.3753V101.724H66.8999ZM67.6131 101.724V102.199H68.0882V101.724H67.6131ZM68.0882 101.487V101.011H67.6131V101.487L68.0882 101.487ZM67.4349 101.175H67.5537V101.501H67.5237V101.709H67.5537V102.036H67.4349V101.709H67.4643V101.501H67.4349V101.175ZM67.063 101.546V101.665H67.3898V101.546H67.063ZM67.5982 101.665V101.546H67.925V101.665H67.5982ZM68.787 99.5996H68.3116V100.075L68.787 100.075V99.5996ZM68.3116 100.788V100.313H68.787V100.788H68.3116ZM69.0247 100.788V100.313H69.4999V100.788H69.0247ZM69.4999 99.5996V100.075L69.0247 100.075V99.5996H69.4999ZM68.9654 99.763H68.8466V100.09H68.876V100.298H68.8466V100.625H68.9654V100.298H68.9354V100.09H68.9654V99.763ZM68.4751 100.253V100.135H68.8019V100.253H68.4751ZM69.0096 100.135V100.253H69.3364V100.135H69.0096ZM68.3116 101.011H68.787V101.487L68.3116 101.487V101.011ZM68.3116 101.724V102.199H68.787V101.724H68.3116ZM69.0247 101.724V102.199H69.4999V101.724H69.0247ZM69.4999 101.487V101.011H69.0247V101.487H69.4999ZM68.8466 101.175H68.9654V101.501H68.9354V101.71H68.9654V102.036H68.8466V101.71H68.876V101.501H68.8466V101.175ZM68.4751 101.546V101.665H68.8019V101.546H68.4751ZM69.0096 101.665V101.546H69.3364V101.665H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 99.5996H70.4755V100.075L70 100.075V99.5996ZM70 100.313V100.788H70.4755V100.313H70ZM70.7132 100.313V100.788H71.1883V100.313H70.7132ZM71.1883 100.075V99.5996H70.7132V100.075L71.1883 100.075ZM70.535 99.763H70.6538V100.09H70.6238V100.298H70.6538V100.625H70.535V100.298H70.5644V100.09H70.535V99.763ZM70.1631 100.135V100.253H70.4899V100.135H70.1631ZM70.6983 100.253V100.135H71.0251V100.253H70.6983ZM70 101.011H70.4754V101.487H70V101.011ZM70 101.724V102.199H70.4754V101.724H70ZM70.7132 101.724V102.199H71.1883V101.724H70.7132ZM71.1883 101.487V101.011H70.7132V101.487L71.1883 101.487ZM70.535 101.175H70.6538V101.501H70.6238V101.709H70.6538V102.036H70.535V101.709H70.5644V101.501H70.535V101.175ZM70.1631 101.546V101.665H70.4899V101.546H70.1631ZM70.6983 101.665V101.546H71.0251V101.665H70.6983ZM71.8871 99.5996H71.4117V100.075L71.8871 100.075V99.5996ZM71.4117 100.788V100.313H71.8871V100.788H71.4117ZM72.1248 100.788V100.313H72.6V100.788H72.1248ZM72.6 99.5996V100.075L72.1248 100.075V99.5996H72.6ZM72.0655 99.763H71.9467V100.09H71.9761V100.298H71.9467V100.625H72.0655V100.298H72.0355V100.09H72.0655V99.763ZM71.5752 100.253V100.135H71.902V100.253H71.5752ZM72.1097 100.135V100.253H72.4365V100.135H72.1097ZM71.4117 101.011H71.8871V101.487L71.4117 101.487V101.011ZM71.4117 101.724V102.199H71.8871V101.724H71.4117ZM72.1248 101.724V102.199H72.6V101.724H72.1248ZM72.6 101.487V101.011H72.1248V101.487H72.6ZM71.9467 101.175H72.0655V101.501H72.0355V101.71H72.0655V102.036H71.9467V101.71H71.9761V101.501H71.9467V101.175ZM71.5752 101.546V101.665H71.902V101.546H71.5752ZM72.1097 101.665V101.546H72.4365V101.665H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 99.5996H73.5756V100.075L73.1001 100.075V99.5996ZM73.1001 100.313V100.788H73.5756V100.313H73.1001ZM73.8133 100.313V100.788H74.2884V100.313H73.8133ZM74.2884 100.075V99.5996H73.8133V100.075L74.2884 100.075ZM73.6351 99.763H73.7539V100.09H73.7239V100.298H73.7539V100.625H73.6351V100.298H73.6645V100.09H73.6351V99.763ZM73.2632 100.135V100.253H73.59V100.135H73.2632ZM73.7984 100.253V100.135H74.1252V100.253H73.7984ZM73.1001 101.011H73.5755V101.487H73.1001V101.011ZM73.1001 101.724V102.199H73.5755V101.724H73.1001ZM73.8133 101.724V102.199H74.2884V101.724H73.8133ZM74.2884 101.487V101.011H73.8133V101.487L74.2884 101.487ZM73.6351 101.175H73.7539V101.501H73.7239V101.709H73.7539V102.036H73.6351V101.709H73.6645V101.501H73.6351V101.175ZM73.2632 101.546V101.665H73.59V101.546H73.2632ZM73.7984 101.665V101.546H74.1252V101.665H73.7984ZM74.9872 99.5996H74.5118V100.075L74.9872 100.075V99.5996ZM74.5118 100.788V100.313H74.9872V100.788H74.5118ZM75.2249 100.788V100.313H75.7001V100.788H75.2249ZM75.7001 99.5996V100.075L75.2249 100.075V99.5996H75.7001ZM75.1656 99.763H75.0468V100.09H75.0762V100.298H75.0468V100.625H75.1656V100.298H75.1356V100.09H75.1656V99.763ZM74.6753 100.253V100.135H75.0021V100.253H74.6753ZM75.2098 100.135V100.253H75.5366V100.135H75.2098ZM74.5118 101.011H74.9872V101.487L74.5118 101.487V101.011ZM74.5118 101.724V102.199H74.9872V101.724H74.5118ZM75.2249 101.724V102.199H75.7001V101.724H75.2249ZM75.7001 101.487V101.011H75.2249V101.487H75.7001ZM75.0468 101.175H75.1656V101.501H75.1356V101.71H75.1656V102.036H75.0468V101.71H75.0762V101.501H75.0468V101.175ZM74.6753 101.546V101.665H75.0021V101.546H74.6753ZM75.2098 101.665V101.546H75.5366V101.665H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 90.2998H76.7753V90.7752L76.2998 90.7752V90.2998ZM76.2998 91.0129V91.4881H76.7753V91.0129H76.2998ZM77.013 91.0129V91.4881H77.4881V91.0129H77.013ZM77.4881 90.7752V90.2998H77.013V90.7752L77.4881 90.7752ZM76.8348 90.4632H76.9536V90.79H76.9236V90.9982H76.9536V91.325H76.8348V90.9982H76.8642V90.79H76.8348V90.4632ZM76.4629 90.8348V90.9536H76.7897V90.8348H76.4629ZM76.9981 90.9536V90.8348H77.3249V90.9536H76.9981ZM76.2998 91.7113H76.7753V92.1868H76.2998V91.7113ZM76.2998 92.4245V92.8996H76.7753V92.4245H76.2998ZM77.013 92.4245V92.8996H77.4881V92.4245H77.013ZM77.4881 92.1868V91.7113H77.013V92.1868H77.4881ZM76.8348 91.8747H76.9536V92.2015H76.9236V92.4097H76.9536V92.7364H76.8348V92.4097H76.8642V92.2015H76.8348V91.8747ZM76.4629 92.2463V92.3651H76.7897V92.2463H76.4629ZM76.9981 92.3651V92.2463H77.3249V92.3651H76.9981ZM78.1869 90.2998H77.7115V90.7753L78.1869 90.7753V90.2998ZM77.7115 91.4881V91.013H78.1869V91.4881H77.7115ZM78.4246 91.4881V91.013H78.8998V91.4881H78.4246ZM78.8998 90.2998V90.7753L78.4246 90.7753V90.2998H78.8998ZM78.3653 90.4632H78.2465V90.79H78.2759V90.9982H78.2465V91.325H78.3653V90.9982H78.3353V90.79H78.3653V90.4632ZM77.875 90.9536V90.8348H78.2018V90.9536H77.875ZM78.4095 90.8348V90.9536H78.7363V90.8348H78.4095ZM77.7115 91.7114H78.1869V92.1868H77.7115V91.7114ZM77.7115 92.4245V92.8997H78.1869V92.4245H77.7115ZM78.4246 92.4245V92.8997H78.8998V92.4245H78.4246ZM78.8998 92.1868V91.7114H78.4246V92.1868H78.8998ZM78.2465 91.8748H78.3653V92.2016H78.3353V92.4097H78.3653V92.7365H78.2465V92.4097H78.2759V92.2016H78.2465V91.8748ZM77.875 92.2463V92.3651H78.2018V92.2463H77.875ZM78.4095 92.3651V92.2463H78.7363V92.3651H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 90.2998H79.8754V90.7752L79.3999 90.7752V90.2998ZM79.3999 91.0129V91.4881H79.8754V91.0129H79.3999ZM80.1131 91.0129V91.4881H80.5882V91.0129H80.1131ZM80.5882 90.7752V90.2998H80.1131V90.7752L80.5882 90.7752ZM79.9349 90.4632H80.0537V90.79H80.0237V90.9982H80.0537V91.325H79.9349V90.9982H79.9643V90.79H79.9349V90.4632ZM79.563 90.8348V90.9536H79.8898V90.8348H79.563ZM80.0982 90.9536V90.8348H80.425V90.9536H80.0982ZM79.3999 91.7113H79.8753V92.1868H79.3999V91.7113ZM79.3999 92.4245V92.8996H79.8753V92.4245H79.3999ZM80.1131 92.4245V92.8996H80.5882V92.4245H80.1131ZM80.5882 92.1868V91.7113H80.1131V92.1868L80.5882 92.1868ZM79.9349 91.8747H80.0537V92.2015H80.0237V92.4097H80.0537V92.7364H79.9349V92.4097H79.9643V92.2015H79.9349V91.8747ZM79.563 92.2463V92.3651H79.8898V92.2463H79.563ZM80.0982 92.3651V92.2463H80.425V92.3651H80.0982ZM81.287 90.2998H80.8116V90.7753L81.287 90.7753V90.2998ZM80.8116 91.4881V91.013H81.287V91.4881H80.8116ZM81.5247 91.4881V91.013H81.9999V91.4881H81.5247ZM81.9999 90.2998V90.7753L81.5247 90.7753V90.2998H81.9999ZM81.4654 90.4632H81.3466V90.79H81.376V90.9982H81.3466V91.325H81.4654V90.9982H81.4354V90.79H81.4654V90.4632ZM80.9751 90.9536V90.8348H81.3019V90.9536H80.9751ZM81.5096 90.8348V90.9536H81.8364V90.8348H81.5096ZM80.8116 91.7114H81.287V92.1868L80.8116 92.1868V91.7114ZM80.8116 92.4245V92.8997H81.287V92.4245H80.8116ZM81.5247 92.4245V92.8997H81.9999V92.4245H81.5247ZM81.9999 92.1868V91.7114H81.5247V92.1868H81.9999ZM81.3466 91.8748H81.4654V92.2016H81.4354V92.4097H81.4654V92.7365H81.3466V92.4097H81.376V92.2016H81.3466V91.8748ZM80.9751 92.2463V92.3651H81.3019V92.2463H80.9751ZM81.5096 92.3651V92.2463H81.8364V92.3651H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 90.2998H82.9755V90.7752L82.5 90.7752V90.2998ZM82.5 91.0129V91.4881H82.9755V91.0129H82.5ZM83.2132 91.0129V91.4881H83.6883V91.0129H83.2132ZM83.6883 90.7752V90.2998H83.2132V90.7752L83.6883 90.7752ZM83.035 90.4632H83.1538V90.79H83.1238V90.9982H83.1538V91.325H83.035V90.9982H83.0644V90.79H83.035V90.4632ZM82.6631 90.8348V90.9536H82.9899V90.8348H82.6631ZM83.1983 90.9536V90.8348H83.5251V90.9536H83.1983ZM82.5 91.7113H82.9754V92.1868H82.5V91.7113ZM82.5 92.4245V92.8996H82.9754V92.4245H82.5ZM83.2132 92.4245V92.8996H83.6883V92.4245H83.2132ZM83.6883 92.1868V91.7113H83.2132V92.1868L83.6883 92.1868ZM83.035 91.8747H83.1538V92.2015H83.1238V92.4097H83.1538V92.7364H83.035V92.4097H83.0644V92.2015H83.035V91.8747ZM82.6631 92.2463V92.3651H82.9899V92.2463H82.6631ZM83.1983 92.3651V92.2463H83.5251V92.3651H83.1983ZM84.3871 90.2998H83.9117V90.7753L84.3871 90.7753V90.2998ZM83.9117 91.4881V91.013H84.3871V91.4881H83.9117ZM84.6248 91.4881V91.013H85.1V91.4881H84.6248ZM85.1 90.2998V90.7753L84.6248 90.7753V90.2998H85.1ZM84.5655 90.4632H84.4467V90.79H84.4761V90.9982H84.4467V91.325H84.5655V90.9982H84.5355V90.79H84.5655V90.4632ZM84.0752 90.9536V90.8348H84.402V90.9536H84.0752ZM84.6097 90.8348V90.9536H84.9365V90.8348H84.6097ZM83.9117 91.7114H84.3871V92.1868L83.9117 92.1868V91.7114ZM83.9117 92.4245V92.8997H84.3871V92.4245H83.9117ZM84.6248 92.4245V92.8997H85.1V92.4245H84.6248ZM85.1 92.1868V91.7114H84.6248V92.1868H85.1ZM84.4467 91.8748H84.5655V92.2016H84.5355V92.4097H84.5655V92.7365H84.4467V92.4097H84.4761V92.2016H84.4467V91.8748ZM84.0752 92.2463V92.3651H84.402V92.2463H84.0752ZM84.6097 92.3651V92.2463H84.9365V92.3651H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 90.2998H86.0756V90.7752L85.6001 90.7752V90.2998ZM85.6001 91.0129V91.4881H86.0756V91.0129H85.6001ZM86.3133 91.0129V91.4881H86.7884V91.0129H86.3133ZM86.7884 90.7752V90.2998H86.3133V90.7752L86.7884 90.7752ZM86.1351 90.4632H86.2539V90.79H86.2239V90.9982H86.2539V91.325H86.1351V90.9982H86.1645V90.79H86.1351V90.4632ZM85.7632 90.8348V90.9536H86.09V90.8348H85.7632ZM86.2984 90.9536V90.8348H86.6252V90.9536H86.2984ZM85.6001 91.7113H86.0755V92.1868H85.6001V91.7113ZM85.6001 92.4245V92.8996H86.0755V92.4245H85.6001ZM86.3133 92.4245V92.8996H86.7884V92.4245H86.3133ZM86.7884 92.1868V91.7113H86.3133V92.1868L86.7884 92.1868ZM86.1351 91.8747H86.2539V92.2015H86.2239V92.4097H86.2539V92.7364H86.1351V92.4097H86.1645V92.2015H86.1351V91.8747ZM85.7632 92.2463V92.3651H86.09V92.2463H85.7632ZM86.2984 92.3651V92.2463H86.6252V92.3651H86.2984ZM87.4872 90.2998H87.0118V90.7753L87.4872 90.7753V90.2998ZM87.0118 91.4881V91.013H87.4872V91.4881H87.0118ZM87.7249 91.4881V91.013H88.2001V91.4881H87.7249ZM88.2001 90.2998V90.7753L87.7249 90.7753V90.2998H88.2001ZM87.6656 90.4632H87.5468V90.79H87.5762V90.9982H87.5468V91.325H87.6656V90.9982H87.6356V90.79H87.6656V90.4632ZM87.1753 90.9536V90.8348H87.5021V90.9536H87.1753ZM87.7098 90.8348V90.9536H88.0366V90.8348H87.7098ZM87.0118 91.7114H87.4872V92.1868L87.0118 92.1868V91.7114ZM87.0118 92.4245V92.8997H87.4872V92.4245H87.0118ZM87.7249 92.4245V92.8997H88.2001V92.4245H87.7249ZM88.2001 92.1868V91.7114H87.7249V92.1868H88.2001ZM87.5468 91.8748H87.6656V92.2016H87.6356V92.4097H87.6656V92.7365H87.5468V92.4097H87.5762V92.2016H87.5468V91.8748ZM87.1753 92.2463V92.3651H87.5021V92.2463H87.1753ZM87.7098 92.3651V92.2463H88.0366V92.3651H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 93.4004H76.7753V93.8758L76.2998 93.8758V93.4004ZM76.2998 94.1135V94.5887H76.7753V94.1135H76.2998ZM77.013 94.1135V94.5887H77.4881V94.1135H77.013ZM77.4881 93.8758V93.4004H77.013V93.8758L77.4881 93.8758ZM76.8348 93.5638H76.9536V93.8906H76.9236V94.0988H76.9536V94.4255H76.8348V94.0988H76.8642V93.8906H76.8348V93.5638ZM76.4629 93.9353V94.0542H76.7897V93.9353H76.4629ZM76.9981 94.0542V93.9354H77.3249V94.0542H76.9981ZM76.2998 94.8119H76.7753V95.2874H76.2998V94.8119ZM76.2998 95.5251V96.0002H76.7753V95.5251H76.2998ZM77.013 95.5251V96.0002H77.4881V95.5251H77.013ZM77.4881 95.2874V94.8119H77.013V95.2874L77.4881 95.2874ZM76.8348 94.9753H76.9536V95.3021H76.9236V95.5103H76.9536V95.837H76.8348V95.5103H76.8642V95.3021H76.8348V94.9753ZM76.4629 95.3468V95.4657H76.7897V95.3468H76.4629ZM76.9981 95.4657V95.3469H77.3249V95.4657H76.9981ZM78.1869 93.4004H77.7115V93.8759L78.1869 93.8759V93.4004ZM77.7115 94.5887V94.1136H78.1869V94.5887H77.7115ZM78.4246 94.5887V94.1136H78.8998V94.5887H78.4246ZM78.8998 93.4004V93.8759L78.4246 93.8759V93.4004H78.8998ZM78.3653 93.5638H78.2465V93.8906H78.2759V94.0988H78.2465V94.4256H78.3653V94.0988H78.3353V93.8906H78.3653V93.5638ZM77.875 94.0542V93.9354H78.2018V94.0542H77.875ZM78.4095 93.9354V94.0542H78.7363V93.9354H78.4095ZM77.7115 94.812H78.1869V95.2874L77.7115 95.2874V94.812ZM77.7115 95.5251V96.0003H78.1869V95.5251H77.7115ZM78.4246 95.5251V96.0003H78.8998V95.5251H78.4246ZM78.8998 95.2874V94.812H78.4246V95.2874H78.8998ZM78.2465 94.9754H78.3653V95.3021H78.3353V95.5103H78.3653V95.8371H78.2465V95.5103H78.2759V95.3021H78.2465V94.9754ZM77.875 95.3469V95.4657H78.2018V95.3469H77.875ZM78.4095 95.4657V95.3469H78.7363V95.4657H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 93.4004H79.8754V93.8758L79.3999 93.8758V93.4004ZM79.3999 94.1135V94.5887H79.8754V94.1135H79.3999ZM80.1131 94.1135V94.5887H80.5882V94.1135H80.1131ZM80.5882 93.8758V93.4004H80.1131V93.8758L80.5882 93.8758ZM79.9349 93.5638H80.0537V93.8906H80.0237V94.0988H80.0537V94.4255H79.9349V94.0988H79.9643V93.8906H79.9349V93.5638ZM79.563 93.9353V94.0542H79.8898V93.9353H79.563ZM80.0982 94.0542V93.9354H80.425V94.0542H80.0982ZM79.3999 94.8119H79.8753V95.2874H79.3999V94.8119ZM79.3999 95.5251V96.0002H79.8753V95.5251H79.3999ZM80.1131 95.5251V96.0002H80.5882V95.5251H80.1131ZM80.5882 95.2874V94.8119H80.1131V95.2874L80.5882 95.2874ZM79.9349 94.9753H80.0537V95.3021H80.0237V95.5103H80.0537V95.837H79.9349V95.5103H79.9643V95.3021H79.9349V94.9753ZM79.563 95.3468V95.4657H79.8898V95.3468H79.563ZM80.0982 95.4657V95.3469H80.425V95.4657H80.0982ZM81.287 93.4004H80.8116V93.8759L81.287 93.8759V93.4004ZM80.8116 94.5887V94.1136H81.287V94.5887H80.8116ZM81.5247 94.5887V94.1136H81.9999V94.5887H81.5247ZM81.9999 93.4004V93.8759L81.5247 93.8759V93.4004H81.9999ZM81.4654 93.5638H81.3466V93.8906H81.376V94.0988H81.3466V94.4256H81.4654V94.0988H81.4354V93.8906H81.4654V93.5638ZM80.9751 94.0542V93.9354H81.3019V94.0542H80.9751ZM81.5096 93.9354V94.0542H81.8364V93.9354H81.5096ZM80.8116 94.812H81.287V95.2874L80.8116 95.2874V94.812ZM80.8116 95.5251V96.0003H81.287V95.5251H80.8116ZM81.5247 95.5251V96.0003H81.9999V95.5251H81.5247ZM81.9999 95.2874V94.812H81.5247V95.2874H81.9999ZM81.3466 94.9754H81.4654V95.3021H81.4354V95.5103H81.4654V95.8371H81.3466V95.5103H81.376V95.3021H81.3466V94.9754ZM80.9751 95.3469V95.4657H81.3019V95.3469H80.9751ZM81.5096 95.4657V95.3469H81.8364V95.4657H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 93.4004H82.9755V93.8758L82.5 93.8758V93.4004ZM82.5 94.1135V94.5887H82.9755V94.1135H82.5ZM83.2132 94.1135V94.5887H83.6883V94.1135H83.2132ZM83.6883 93.8758V93.4004H83.2132V93.8758L83.6883 93.8758ZM83.035 93.5638H83.1538V93.8906H83.1238V94.0988H83.1538V94.4255H83.035V94.0988H83.0644V93.8906H83.035V93.5638ZM82.6631 93.9353V94.0542H82.9899V93.9353H82.6631ZM83.1983 94.0542V93.9354H83.5251V94.0542H83.1983ZM82.5 94.8119H82.9754V95.2874H82.5V94.8119ZM82.5 95.5251V96.0002H82.9754V95.5251H82.5ZM83.2132 95.5251V96.0002H83.6883V95.5251H83.2132ZM83.6883 95.2874V94.8119H83.2132V95.2874L83.6883 95.2874ZM83.035 94.9753H83.1538V95.3021H83.1238V95.5103H83.1538V95.837H83.035V95.5103H83.0644V95.3021H83.035V94.9753ZM82.6631 95.3468V95.4657H82.9899V95.3468H82.6631ZM83.1983 95.4657V95.3469H83.5251V95.4657H83.1983ZM84.3871 93.4004H83.9117V93.8759L84.3871 93.8759V93.4004ZM83.9117 94.5887V94.1136H84.3871V94.5887H83.9117ZM84.6248 94.5887V94.1136H85.1V94.5887H84.6248ZM85.1 93.4004V93.8759L84.6248 93.8759V93.4004H85.1ZM84.5655 93.5638H84.4467V93.8906H84.4761V94.0988H84.4467V94.4256H84.5655V94.0988H84.5355V93.8906H84.5655V93.5638ZM84.0752 94.0542V93.9354H84.402V94.0542H84.0752ZM84.6097 93.9354V94.0542H84.9365V93.9354H84.6097ZM83.9117 94.812H84.3871V95.2874L83.9117 95.2874V94.812ZM83.9117 95.5251V96.0003H84.3871V95.5251H83.9117ZM84.6248 95.5251V96.0003H85.1V95.5251H84.6248ZM85.1 95.2874V94.812H84.6248V95.2874H85.1ZM84.4467 94.9754H84.5655V95.3021H84.5355V95.5103H84.5655V95.8371H84.4467V95.5103H84.4761V95.3021H84.4467V94.9754ZM84.0752 95.3469V95.4657H84.402V95.3469H84.0752ZM84.6097 95.4657V95.3469H84.9365V95.4657H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 93.4004H86.0756V93.8758L85.6001 93.8758V93.4004ZM85.6001 94.1135V94.5887H86.0756V94.1135H85.6001ZM86.3133 94.1135V94.5887H86.7884V94.1135H86.3133ZM86.7884 93.8758V93.4004H86.3133V93.8758L86.7884 93.8758ZM86.1351 93.5638H86.2539V93.8906H86.2239V94.0988H86.2539V94.4255H86.1351V94.0988H86.1645V93.8906H86.1351V93.5638ZM85.7632 93.9353V94.0542H86.09V93.9353H85.7632ZM86.2984 94.0542V93.9354H86.6252V94.0542H86.2984ZM85.6001 94.8119H86.0755V95.2874H85.6001V94.8119ZM85.6001 95.5251V96.0002H86.0755V95.5251H85.6001ZM86.3133 95.5251V96.0002H86.7884V95.5251H86.3133ZM86.7884 95.2874V94.8119H86.3133V95.2874L86.7884 95.2874ZM86.1351 94.9753H86.2539V95.3021H86.2239V95.5103H86.2539V95.837H86.1351V95.5103H86.1645V95.3021H86.1351V94.9753ZM85.7632 95.3468V95.4657H86.09V95.3468H85.7632ZM86.2984 95.4657V95.3469H86.6252V95.4657H86.2984ZM87.4872 93.4004H87.0118V93.8759L87.4872 93.8759V93.4004ZM87.0118 94.5887V94.1136H87.4872V94.5887H87.0118ZM87.7249 94.5887V94.1136H88.2001V94.5887H87.7249ZM88.2001 93.4004V93.8759L87.7249 93.8759V93.4004H88.2001ZM87.6656 93.5638H87.5468V93.8906H87.5762V94.0988H87.5468V94.4256H87.6656V94.0988H87.6356V93.8906H87.6656V93.5638ZM87.1753 94.0542V93.9354H87.5021V94.0542H87.1753ZM87.7098 93.9354V94.0542H88.0366V93.9354H87.7098ZM87.0118 94.812H87.4872V95.2874L87.0118 95.2874V94.812ZM87.0118 95.5251V96.0003H87.4872V95.5251H87.0118ZM87.7249 95.5251V96.0003H88.2001V95.5251H87.7249ZM88.2001 95.2874V94.812H87.7249V95.2874H88.2001ZM87.5468 94.9754H87.6656V95.3021H87.6356V95.5103H87.6656V95.8371H87.5468V95.5103H87.5762V95.3021H87.5468V94.9754ZM87.1753 95.3469V95.4657H87.5021V95.3469H87.1753ZM87.7098 95.4657V95.3469H88.0366V95.4657H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 96.5H76.7753V96.9754L76.2998 96.9754V96.5ZM76.2998 97.2131V97.6883H76.7753V97.2131H76.2998ZM77.013 97.2131V97.6883H77.4881V97.2131H77.013ZM77.4881 96.9754V96.5H77.013V96.9754L77.4881 96.9754ZM76.8348 96.6634H76.9536V96.9902H76.9236V97.1984H76.9536V97.5251H76.8348V97.1984H76.8642V96.9902H76.8348V96.6634ZM76.4629 97.035V97.1538H76.7897V97.035H76.4629ZM76.9981 97.1538V97.035H77.3249V97.1538H76.9981ZM76.2998 97.9115H76.7753V98.387H76.2998V97.9115ZM76.2998 98.6247V99.0998H76.7753V98.6247H76.2998ZM77.013 98.6247V99.0998H77.4881V98.6247H77.013ZM77.4881 98.387V97.9115H77.013V98.387L77.4881 98.387ZM76.8348 98.0749H76.9536V98.4017H76.9236V98.6099H76.9536V98.9366H76.8348V98.6099H76.8642V98.4017H76.8348V98.0749ZM76.4629 98.4465V98.5653H76.7897V98.4465H76.4629ZM76.9981 98.5653V98.4465H77.3249V98.5653H76.9981ZM78.1869 96.5H77.7115V96.9755L78.1869 96.9755V96.5ZM77.7115 97.6883V97.2132H78.1869V97.6883H77.7115ZM78.4246 97.6883V97.2132H78.8998V97.6883H78.4246ZM78.8998 96.5V96.9755L78.4246 96.9755V96.5H78.8998ZM78.3653 96.6634H78.2465V96.9902H78.2759V97.1984H78.2465V97.5252H78.3653V97.1984H78.3353V96.9902H78.3653V96.6634ZM77.875 97.1538V97.035H78.2018V97.1538H77.875ZM78.4095 97.035V97.1538H78.7363V97.035H78.4095ZM77.7115 97.9116H78.1869V98.387L77.7115 98.387V97.9116ZM77.7115 98.6247V99.0999H78.1869V98.6247H77.7115ZM78.4246 98.6247V99.0999H78.8998V98.6247H78.4246ZM78.8998 98.387V97.9116H78.4246V98.387H78.8998ZM78.2465 98.075H78.3653V98.4018H78.3353V98.6099H78.3653V98.9367H78.2465V98.6099H78.2759V98.4018H78.2465V98.075ZM77.875 98.4465V98.5653H78.2018V98.4465H77.875ZM78.4095 98.5653V98.4465H78.7363V98.5653H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 96.5H79.8754V96.9754L79.3999 96.9754V96.5ZM79.3999 97.2131V97.6883H79.8754V97.2131H79.3999ZM80.1131 97.2131V97.6883H80.5882V97.2131H80.1131ZM80.5882 96.9754V96.5H80.1131V96.9754L80.5882 96.9754ZM79.9349 96.6634H80.0537V96.9902H80.0237V97.1984H80.0537V97.5251H79.9349V97.1984H79.9643V96.9902H79.9349V96.6634ZM79.563 97.035V97.1538H79.8898V97.035H79.563ZM80.0982 97.1538V97.035H80.425V97.1538H80.0982ZM79.3999 97.9115H79.8753V98.387H79.3999V97.9115ZM79.3999 98.6247V99.0998H79.8753V98.6247H79.3999ZM80.1131 98.6247V99.0998H80.5882V98.6247H80.1131ZM80.5882 98.387V97.9115H80.1131V98.387L80.5882 98.387ZM79.9349 98.0749H80.0537V98.4017H80.0237V98.6099H80.0537V98.9366H79.9349V98.6099H79.9643V98.4017H79.9349V98.0749ZM79.563 98.4465V98.5653H79.8898V98.4465H79.563ZM80.0982 98.5653V98.4465H80.425V98.5653H80.0982ZM81.287 96.5H80.8116V96.9755L81.287 96.9755V96.5ZM80.8116 97.6883V97.2132H81.287V97.6883H80.8116ZM81.5247 97.6883V97.2132H81.9999V97.6883H81.5247ZM81.9999 96.5V96.9755L81.5247 96.9755V96.5H81.9999ZM81.4654 96.6634H81.3466V96.9902H81.376V97.1984H81.3466V97.5252H81.4654V97.1984H81.4354V96.9902H81.4654V96.6634ZM80.9751 97.1538V97.035H81.3019V97.1538H80.9751ZM81.5096 97.035V97.1538H81.8364V97.035H81.5096ZM80.8116 97.9116H81.287V98.387L80.8116 98.387V97.9116ZM80.8116 98.6247V99.0999H81.287V98.6247H80.8116ZM81.5247 98.6247V99.0999H81.9999V98.6247H81.5247ZM81.9999 98.387V97.9116H81.5247V98.387H81.9999ZM81.3466 98.075H81.4654V98.4018H81.4354V98.6099H81.4654V98.9367H81.3466V98.6099H81.376V98.4018H81.3466V98.075ZM80.9751 98.4465V98.5653H81.3019V98.4465H80.9751ZM81.5096 98.5653V98.4465H81.8364V98.5653H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 96.5H82.9755V96.9754L82.5 96.9754V96.5ZM82.5 97.2131V97.6883H82.9755V97.2131H82.5ZM83.2132 97.2131V97.6883H83.6883V97.2131H83.2132ZM83.6883 96.9754V96.5H83.2132V96.9754L83.6883 96.9754ZM83.035 96.6634H83.1538V96.9902H83.1238V97.1984H83.1538V97.5251H83.035V97.1984H83.0644V96.9902H83.035V96.6634ZM82.6631 97.035V97.1538H82.9899V97.035H82.6631ZM83.1983 97.1538V97.035H83.5251V97.1538H83.1983ZM82.5 97.9115H82.9754V98.387H82.5V97.9115ZM82.5 98.6247V99.0998H82.9754V98.6247H82.5ZM83.2132 98.6247V99.0998H83.6883V98.6247H83.2132ZM83.6883 98.387V97.9115H83.2132V98.387L83.6883 98.387ZM83.035 98.0749H83.1538V98.4017H83.1238V98.6099H83.1538V98.9366H83.035V98.6099H83.0644V98.4017H83.035V98.0749ZM82.6631 98.4465V98.5653H82.9899V98.4465H82.6631ZM83.1983 98.5653V98.4465H83.5251V98.5653H83.1983ZM84.3871 96.5H83.9117V96.9755L84.3871 96.9755V96.5ZM83.9117 97.6883V97.2132H84.3871V97.6883H83.9117ZM84.6248 97.6883V97.2132H85.1V97.6883H84.6248ZM85.1 96.5V96.9755L84.6248 96.9755V96.5H85.1ZM84.5655 96.6634H84.4467V96.9902H84.4761V97.1984H84.4467V97.5252H84.5655V97.1984H84.5355V96.9902H84.5655V96.6634ZM84.0752 97.1538V97.035H84.402V97.1538H84.0752ZM84.6097 97.035V97.1538H84.9365V97.035H84.6097ZM83.9117 97.9116H84.3871V98.387L83.9117 98.387V97.9116ZM83.9117 98.6247V99.0999H84.3871V98.6247H83.9117ZM84.6248 98.6247V99.0999H85.1V98.6247H84.6248ZM85.1 98.387V97.9116H84.6248V98.387H85.1ZM84.4467 98.075H84.5655V98.4018H84.5355V98.6099H84.5655V98.9367H84.4467V98.6099H84.4761V98.4018H84.4467V98.075ZM84.0752 98.4465V98.5653H84.402V98.4465H84.0752ZM84.6097 98.5653V98.4465H84.9365V98.5653H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 96.5H86.0756V96.9754L85.6001 96.9754V96.5ZM85.6001 97.2131V97.6883H86.0756V97.2131H85.6001ZM86.3133 97.2131V97.6883H86.7884V97.2131H86.3133ZM86.7884 96.9754V96.5H86.3133V96.9754L86.7884 96.9754ZM86.1351 96.6634H86.2539V96.9902H86.2239V97.1984H86.2539V97.5251H86.1351V97.1984H86.1645V96.9902H86.1351V96.6634ZM85.7632 97.035V97.1538H86.09V97.035H85.7632ZM86.2984 97.1538V97.035H86.6252V97.1538H86.2984ZM85.6001 97.9115H86.0755V98.387H85.6001V97.9115ZM85.6001 98.6247V99.0998H86.0755V98.6247H85.6001ZM86.3133 98.6247V99.0998H86.7884V98.6247H86.3133ZM86.7884 98.387V97.9115H86.3133V98.387L86.7884 98.387ZM86.1351 98.0749H86.2539V98.4017H86.2239V98.6099H86.2539V98.9366H86.1351V98.6099H86.1645V98.4017H86.1351V98.0749ZM85.7632 98.4465V98.5653H86.09V98.4465H85.7632ZM86.2984 98.5653V98.4465H86.6252V98.5653H86.2984ZM87.4872 96.5H87.0118V96.9755L87.4872 96.9755V96.5ZM87.0118 97.6883V97.2132H87.4872V97.6883H87.0118ZM87.7249 97.6883V97.2132H88.2001V97.6883H87.7249ZM88.2001 96.5V96.9755L87.7249 96.9755V96.5H88.2001ZM87.6656 96.6634H87.5468V96.9902H87.5762V97.1984H87.5468V97.5252H87.6656V97.1984H87.6356V96.9902H87.6656V96.6634ZM87.1753 97.1538V97.035H87.5021V97.1538H87.1753ZM87.7098 97.035V97.1538H88.0366V97.035H87.7098ZM87.0118 97.9116H87.4872V98.387L87.0118 98.387V97.9116ZM87.0118 98.6247V99.0999H87.4872V98.6247H87.0118ZM87.7249 98.6247V99.0999H88.2001V98.6247H87.7249ZM88.2001 98.387V97.9116H87.7249V98.387H88.2001ZM87.5468 98.075H87.6656V98.4018H87.6356V98.6099H87.6656V98.9367H87.5468V98.6099H87.5762V98.4018H87.5468V98.075ZM87.1753 98.4465V98.5653H87.5021V98.4465H87.1753ZM87.7098 98.5653V98.4465H88.0366V98.5653H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 99.5996H76.7753V100.075L76.2998 100.075V99.5996ZM76.2998 100.313V100.788H76.7753V100.313H76.2998ZM77.013 100.313V100.788H77.4881V100.313H77.013ZM77.4881 100.075V99.5996H77.013V100.075L77.4881 100.075ZM76.8348 99.763H76.9536V100.09H76.9236V100.298H76.9536V100.625H76.8348V100.298H76.8642V100.09H76.8348V99.763ZM76.4629 100.135V100.253H76.7897V100.135H76.4629ZM76.9981 100.253V100.135H77.3249V100.253H76.9981ZM76.2998 101.011H76.7753V101.487H76.2998V101.011ZM76.2998 101.724V102.199H76.7753V101.724H76.2998ZM77.013 101.724V102.199H77.4881V101.724H77.013ZM77.4881 101.487V101.011H77.013V101.487L77.4881 101.487ZM76.8348 101.175H76.9536V101.501H76.9236V101.709H76.9536V102.036H76.8348V101.709H76.8642V101.501H76.8348V101.175ZM76.4629 101.546V101.665H76.7897V101.546H76.4629ZM76.9981 101.665V101.546H77.3249V101.665H76.9981ZM78.1869 99.5996H77.7115V100.075L78.1869 100.075V99.5996ZM77.7115 100.788V100.313H78.1869V100.788H77.7115ZM78.4246 100.788V100.313H78.8998V100.788H78.4246ZM78.8998 99.5996V100.075L78.4246 100.075V99.5996H78.8998ZM78.3653 99.763H78.2465V100.09H78.2759V100.298H78.2465V100.625H78.3653V100.298H78.3353V100.09H78.3653V99.763ZM77.875 100.253V100.135H78.2018V100.253H77.875ZM78.4095 100.135V100.253H78.7363V100.135H78.4095ZM77.7115 101.011H78.1869V101.487L77.7115 101.487V101.011ZM77.7115 101.724V102.199H78.1869V101.724H77.7115ZM78.4246 101.724V102.199H78.8998V101.724H78.4246ZM78.8998 101.487V101.011H78.4246V101.487H78.8998ZM78.2465 101.175H78.3653V101.501H78.3353V101.71H78.3653V102.036H78.2465V101.71H78.2759V101.501H78.2465V101.175ZM77.875 101.546V101.665H78.2018V101.546H77.875ZM78.4095 101.665V101.546H78.7363V101.665H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 99.5996H79.8754V100.075L79.3999 100.075V99.5996ZM79.3999 100.313V100.788H79.8754V100.313H79.3999ZM80.1131 100.313V100.788H80.5882V100.313H80.1131ZM80.5882 100.075V99.5996H80.1131V100.075L80.5882 100.075ZM79.9349 99.763H80.0537V100.09H80.0237V100.298H80.0537V100.625H79.9349V100.298H79.9643V100.09H79.9349V99.763ZM79.563 100.135V100.253H79.8898V100.135H79.563ZM80.0982 100.253V100.135H80.425V100.253H80.0982ZM79.3999 101.011H79.8753V101.487H79.3999V101.011ZM79.3999 101.724V102.199H79.8753V101.724H79.3999ZM80.1131 101.724V102.199H80.5882V101.724H80.1131ZM80.5882 101.487V101.011H80.1131V101.487L80.5882 101.487ZM79.9349 101.175H80.0537V101.501H80.0237V101.709H80.0537V102.036H79.9349V101.709H79.9643V101.501H79.9349V101.175ZM79.563 101.546V101.665H79.8898V101.546H79.563ZM80.0982 101.665V101.546H80.425V101.665H80.0982ZM81.287 99.5996H80.8116V100.075L81.287 100.075V99.5996ZM80.8116 100.788V100.313H81.287V100.788H80.8116ZM81.5247 100.788V100.313H81.9999V100.788H81.5247ZM81.9999 99.5996V100.075L81.5247 100.075V99.5996H81.9999ZM81.4654 99.763H81.3466V100.09H81.376V100.298H81.3466V100.625H81.4654V100.298H81.4354V100.09H81.4654V99.763ZM80.9751 100.253V100.135H81.3019V100.253H80.9751ZM81.5096 100.135V100.253H81.8364V100.135H81.5096ZM80.8116 101.011H81.287V101.487L80.8116 101.487V101.011ZM80.8116 101.724V102.199H81.287V101.724H80.8116ZM81.5247 101.724V102.199H81.9999V101.724H81.5247ZM81.9999 101.487V101.011H81.5247V101.487H81.9999ZM81.3466 101.175H81.4654V101.501H81.4354V101.71H81.4654V102.036H81.3466V101.71H81.376V101.501H81.3466V101.175ZM80.9751 101.546V101.665H81.3019V101.546H80.9751ZM81.5096 101.665V101.546H81.8364V101.665H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 99.5996H82.9755V100.075L82.5 100.075V99.5996ZM82.5 100.313V100.788H82.9755V100.313H82.5ZM83.2132 100.313V100.788H83.6883V100.313H83.2132ZM83.6883 100.075V99.5996H83.2132V100.075L83.6883 100.075ZM83.035 99.763H83.1538V100.09H83.1238V100.298H83.1538V100.625H83.035V100.298H83.0644V100.09H83.035V99.763ZM82.6631 100.135V100.253H82.9899V100.135H82.6631ZM83.1983 100.253V100.135H83.5251V100.253H83.1983ZM82.5 101.011H82.9754V101.487H82.5V101.011ZM82.5 101.724V102.199H82.9754V101.724H82.5ZM83.2132 101.724V102.199H83.6883V101.724H83.2132ZM83.6883 101.487V101.011H83.2132V101.487L83.6883 101.487ZM83.035 101.175H83.1538V101.501H83.1238V101.709H83.1538V102.036H83.035V101.709H83.0644V101.501H83.035V101.175ZM82.6631 101.546V101.665H82.9899V101.546H82.6631ZM83.1983 101.665V101.546H83.5251V101.665H83.1983ZM84.3871 99.5996H83.9117V100.075L84.3871 100.075V99.5996ZM83.9117 100.788V100.313H84.3871V100.788H83.9117ZM84.6248 100.788V100.313H85.1V100.788H84.6248ZM85.1 99.5996V100.075L84.6248 100.075V99.5996H85.1ZM84.5655 99.763H84.4467V100.09H84.4761V100.298H84.4467V100.625H84.5655V100.298H84.5355V100.09H84.5655V99.763ZM84.0752 100.253V100.135H84.402V100.253H84.0752ZM84.6097 100.135V100.253H84.9365V100.135H84.6097ZM83.9117 101.011H84.3871V101.487L83.9117 101.487V101.011ZM83.9117 101.724V102.199H84.3871V101.724H83.9117ZM84.6248 101.724V102.199H85.1V101.724H84.6248ZM85.1 101.487V101.011H84.6248V101.487H85.1ZM84.4467 101.175H84.5655V101.501H84.5355V101.71H84.5655V102.036H84.4467V101.71H84.4761V101.501H84.4467V101.175ZM84.0752 101.546V101.665H84.402V101.546H84.0752ZM84.6097 101.665V101.546H84.9365V101.665H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 99.5996H86.0756V100.075L85.6001 100.075V99.5996ZM85.6001 100.313V100.788H86.0756V100.313H85.6001ZM86.3133 100.313V100.788H86.7884V100.313H86.3133ZM86.7884 100.075V99.5996H86.3133V100.075L86.7884 100.075ZM86.1351 99.763H86.2539V100.09H86.2239V100.298H86.2539V100.625H86.1351V100.298H86.1645V100.09H86.1351V99.763ZM85.7632 100.135V100.253H86.09V100.135H85.7632ZM86.2984 100.253V100.135H86.6252V100.253H86.2984ZM85.6001 101.011H86.0755V101.487H85.6001V101.011ZM85.6001 101.724V102.199H86.0755V101.724H85.6001ZM86.3133 101.724V102.199H86.7884V101.724H86.3133ZM86.7884 101.487V101.011H86.3133V101.487L86.7884 101.487ZM86.1351 101.175H86.2539V101.501H86.2239V101.709H86.2539V102.036H86.1351V101.709H86.1645V101.501H86.1351V101.175ZM85.7632 101.546V101.665H86.09V101.546H85.7632ZM86.2984 101.665V101.546H86.6252V101.665H86.2984ZM87.4872 99.5996H87.0118V100.075L87.4872 100.075V99.5996ZM87.0118 100.788V100.313H87.4872V100.788H87.0118ZM87.7249 100.788V100.313H88.2001V100.788H87.7249ZM88.2001 99.5996V100.075L87.7249 100.075V99.5996H88.2001ZM87.6656 99.763H87.5468V100.09H87.5762V100.298H87.5468V100.625H87.6656V100.298H87.6356V100.09H87.6656V99.763ZM87.1753 100.253V100.135H87.5021V100.253H87.1753ZM87.7098 100.135V100.253H88.0366V100.135H87.7098ZM87.0118 101.011H87.4872V101.487L87.0118 101.487V101.011ZM87.0118 101.724V102.199H87.4872V101.724H87.0118ZM87.7249 101.724V102.199H88.2001V101.724H87.7249ZM88.2001 101.487V101.011H87.7249V101.487H88.2001ZM87.5468 101.175H87.6656V101.501H87.6356V101.71H87.6656V102.036H87.5468V101.71H87.5762V101.501H87.5468V101.175ZM87.1753 101.546V101.665H87.5021V101.546H87.1753ZM87.7098 101.665V101.546H88.0366V101.665H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 90.2998H89.2753V90.7752L88.7998 90.7752V90.2998ZM88.7998 91.0129V91.4881H89.2753V91.0129H88.7998ZM89.513 91.0129V91.4881H89.9881V91.0129H89.513ZM89.9881 90.7752V90.2998H89.513V90.7752L89.9881 90.7752ZM89.3348 90.4632H89.4536V90.79H89.4236V90.9982H89.4536V91.325H89.3348V90.9982H89.3642V90.79H89.3348V90.4632ZM88.9629 90.8348V90.9536H89.2897V90.8348H88.9629ZM89.4981 90.9536V90.8348H89.8249V90.9536H89.4981ZM88.7998 91.7113H89.2753V92.1868H88.7998V91.7113ZM88.7998 92.4245V92.8996H89.2753V92.4245H88.7998ZM89.513 92.4245V92.8996H89.9881V92.4245H89.513ZM89.9881 92.1868V91.7113H89.513V92.1868H89.9881ZM89.3348 91.8747H89.4536V92.2015H89.4236V92.4097H89.4536V92.7364H89.3348V92.4097H89.3642V92.2015H89.3348V91.8747ZM88.9629 92.2463V92.3651H89.2897V92.2463H88.9629ZM89.4981 92.3651V92.2463H89.8249V92.3651H89.4981ZM90.6869 90.2998H90.2115V90.7753L90.6869 90.7753V90.2998ZM90.2115 91.4881V91.013H90.6869V91.4881H90.2115ZM90.9246 91.4881V91.013H91.3998V91.4881H90.9246ZM91.3998 90.2998V90.7753L90.9246 90.7753V90.2998H91.3998ZM90.8653 90.4632H90.7465V90.79H90.7759V90.9982H90.7465V91.325H90.8653V90.9982H90.8353V90.79H90.8653V90.4632ZM90.375 90.9536V90.8348H90.7018V90.9536H90.375ZM90.9095 90.8348V90.9536H91.2363V90.8348H90.9095ZM90.2115 91.7114H90.6869V92.1868H90.2115V91.7114ZM90.2115 92.4245V92.8997H90.6869V92.4245H90.2115ZM90.9246 92.4245V92.8997H91.3998V92.4245H90.9246ZM91.3998 92.1868V91.7114H90.9246V92.1868H91.3998ZM90.7465 91.8748H90.8653V92.2016H90.8353V92.4097H90.8653V92.7365H90.7465V92.4097H90.7759V92.2016H90.7465V91.8748ZM90.375 92.2463V92.3651H90.7018V92.2463H90.375ZM90.9095 92.3651V92.2463H91.2363V92.3651H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 90.2998H92.3754V90.7752L91.8999 90.7752V90.2998ZM91.8999 91.0129V91.4881H92.3754V91.0129H91.8999ZM92.6131 91.0129V91.4881H93.0882V91.0129H92.6131ZM93.0882 90.7752V90.2998H92.6131V90.7752L93.0882 90.7752ZM92.4349 90.4632H92.5537V90.79H92.5237V90.9982H92.5537V91.325H92.4349V90.9982H92.4643V90.79H92.4349V90.4632ZM92.063 90.8348V90.9536H92.3898V90.8348H92.063ZM92.5982 90.9536V90.8348H92.925V90.9536H92.5982ZM91.8999 91.7113H92.3753V92.1868H91.8999V91.7113ZM91.8999 92.4245V92.8996H92.3753V92.4245H91.8999ZM92.6131 92.4245V92.8996H93.0882V92.4245H92.6131ZM93.0882 92.1868V91.7113H92.6131V92.1868L93.0882 92.1868ZM92.4349 91.8747H92.5537V92.2015H92.5237V92.4097H92.5537V92.7364H92.4349V92.4097H92.4643V92.2015H92.4349V91.8747ZM92.063 92.2463V92.3651H92.3898V92.2463H92.063ZM92.5982 92.3651V92.2463H92.925V92.3651H92.5982ZM93.787 90.2998H93.3116V90.7753L93.787 90.7753V90.2998ZM93.3116 91.4881V91.013H93.787V91.4881H93.3116ZM94.0247 91.4881V91.013H94.4999V91.4881H94.0247ZM94.4999 90.2998V90.7753L94.0247 90.7753V90.2998H94.4999ZM93.9654 90.4632H93.8466V90.79H93.876V90.9982H93.8466V91.325H93.9654V90.9982H93.9354V90.79H93.9654V90.4632ZM93.4751 90.9536V90.8348H93.8019V90.9536H93.4751ZM94.0096 90.8348V90.9536H94.3364V90.8348H94.0096ZM93.3116 91.7114H93.787V92.1868L93.3116 92.1868V91.7114ZM93.3116 92.4245V92.8997H93.787V92.4245H93.3116ZM94.0247 92.4245V92.8997H94.4999V92.4245H94.0247ZM94.4999 92.1868V91.7114H94.0247V92.1868H94.4999ZM93.8466 91.8748H93.9654V92.2016H93.9354V92.4097H93.9654V92.7365H93.8466V92.4097H93.876V92.2016H93.8466V91.8748ZM93.4751 92.2463V92.3651H93.8019V92.2463H93.4751ZM94.0096 92.3651V92.2463H94.3364V92.3651H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 90.2998H95.4755V90.7752L95 90.7752V90.2998ZM95 91.0129V91.4881H95.4755V91.0129H95ZM95.7132 91.0129V91.4881H96.1883V91.0129H95.7132ZM96.1883 90.7752V90.2998H95.7132V90.7752L96.1883 90.7752ZM95.535 90.4632H95.6538V90.79H95.6238V90.9982H95.6538V91.325H95.535V90.9982H95.5644V90.79H95.535V90.4632ZM95.1631 90.8348V90.9536H95.4899V90.8348H95.1631ZM95.6983 90.9536V90.8348H96.0251V90.9536H95.6983ZM95 91.7113H95.4754V92.1868H95V91.7113ZM95 92.4245V92.8996H95.4754V92.4245H95ZM95.7132 92.4245V92.8996H96.1883V92.4245H95.7132ZM96.1883 92.1868V91.7113H95.7132V92.1868L96.1883 92.1868ZM95.535 91.8747H95.6538V92.2015H95.6238V92.4097H95.6538V92.7364H95.535V92.4097H95.5644V92.2015H95.535V91.8747ZM95.1631 92.2463V92.3651H95.4899V92.2463H95.1631ZM95.6983 92.3651V92.2463H96.0251V92.3651H95.6983ZM96.8871 90.2998H96.4117V90.7753L96.8871 90.7753V90.2998ZM96.4117 91.4881V91.013H96.8871V91.4881H96.4117ZM97.1248 91.4881V91.013H97.6V91.4881H97.1248ZM97.6 90.2998V90.7753L97.1248 90.7753V90.2998H97.6ZM97.0655 90.4632H96.9467V90.79H96.9761V90.9982H96.9467V91.325H97.0655V90.9982H97.0355V90.79H97.0655V90.4632ZM96.5752 90.9536V90.8348H96.902V90.9536H96.5752ZM97.1097 90.8348V90.9536H97.4365V90.8348H97.1097ZM96.4117 91.7114H96.8871V92.1868L96.4117 92.1868V91.7114ZM96.4117 92.4245V92.8997H96.8871V92.4245H96.4117ZM97.1248 92.4245V92.8997H97.6V92.4245H97.1248ZM97.6 92.1868V91.7114H97.1248V92.1868H97.6ZM96.9467 91.8748H97.0655V92.2016H97.0355V92.4097H97.0655V92.7365H96.9467V92.4097H96.9761V92.2016H96.9467V91.8748ZM96.5752 92.2463V92.3651H96.902V92.2463H96.5752ZM97.1097 92.3651V92.2463H97.4365V92.3651H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 90.2998H98.5756V90.7752L98.1001 90.7752V90.2998ZM98.1001 91.0129V91.4881H98.5756V91.0129H98.1001ZM98.8133 91.0129V91.4881H99.2884V91.0129H98.8133ZM99.2884 90.7752V90.2998H98.8133V90.7752L99.2884 90.7752ZM98.6351 90.4632H98.7539V90.79H98.7239V90.9982H98.7539V91.325H98.6351V90.9982H98.6645V90.79H98.6351V90.4632ZM98.2632 90.8348V90.9536H98.59V90.8348H98.2632ZM98.7984 90.9536V90.8348H99.1252V90.9536H98.7984ZM98.1001 91.7113H98.5755V92.1868H98.1001V91.7113ZM98.1001 92.4245V92.8996H98.5755V92.4245H98.1001ZM98.8133 92.4245V92.8996H99.2884V92.4245H98.8133ZM99.2884 92.1868V91.7113H98.8133V92.1868L99.2884 92.1868ZM98.6351 91.8747H98.7539V92.2015H98.7239V92.4097H98.7539V92.7364H98.6351V92.4097H98.6645V92.2015H98.6351V91.8747ZM98.2632 92.2463V92.3651H98.59V92.2463H98.2632ZM98.7984 92.3651V92.2463H99.1252V92.3651H98.7984ZM99.9872 90.2998H99.5118V90.7753L99.9872 90.7753V90.2998ZM99.5118 91.4881V91.013H99.9872V91.4881H99.5118ZM100.225 91.4881V91.013H100.7V91.4881H100.225ZM100.7 90.2998V90.7753L100.225 90.7753V90.2998H100.7ZM100.166 90.4632H100.047V90.79H100.076V90.9982H100.047V91.325H100.166V90.9982H100.136V90.79H100.166V90.4632ZM99.6753 90.9536V90.8348H100.002V90.9536H99.6753ZM100.21 90.8348V90.9536H100.537V90.8348H100.21ZM99.5118 91.7114H99.9872V92.1868L99.5118 92.1868V91.7114ZM99.5118 92.4245V92.8997H99.9872V92.4245H99.5118ZM100.225 92.4245V92.8997H100.7V92.4245H100.225ZM100.7 92.1868V91.7114H100.225V92.1868H100.7ZM100.047 91.8748H100.166V92.2016H100.136V92.4097H100.166V92.7365H100.047V92.4097H100.076V92.2016H100.047V91.8748ZM99.6753 92.2463V92.3651H100.002V92.2463H99.6753ZM100.21 92.3651V92.2463H100.537V92.3651H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 93.4004H89.2753V93.8758L88.7998 93.8758V93.4004ZM88.7998 94.1135V94.5887H89.2753V94.1135H88.7998ZM89.513 94.1135V94.5887H89.9881V94.1135H89.513ZM89.9881 93.8758V93.4004H89.513V93.8758L89.9881 93.8758ZM89.3348 93.5638H89.4536V93.8906H89.4236V94.0988H89.4536V94.4255H89.3348V94.0988H89.3642V93.8906H89.3348V93.5638ZM88.9629 93.9353V94.0542H89.2897V93.9353H88.9629ZM89.4981 94.0542V93.9354H89.8249V94.0542H89.4981ZM88.7998 94.8119H89.2753V95.2874H88.7998V94.8119ZM88.7998 95.5251V96.0002H89.2753V95.5251H88.7998ZM89.513 95.5251V96.0002H89.9881V95.5251H89.513ZM89.9881 95.2874V94.8119H89.513V95.2874L89.9881 95.2874ZM89.3348 94.9753H89.4536V95.3021H89.4236V95.5103H89.4536V95.837H89.3348V95.5103H89.3642V95.3021H89.3348V94.9753ZM88.9629 95.3468V95.4657H89.2897V95.3468H88.9629ZM89.4981 95.4657V95.3469H89.8249V95.4657H89.4981ZM90.6869 93.4004H90.2115V93.8759L90.6869 93.8759V93.4004ZM90.2115 94.5887V94.1136H90.6869V94.5887H90.2115ZM90.9246 94.5887V94.1136H91.3998V94.5887H90.9246ZM91.3998 93.4004V93.8759L90.9246 93.8759V93.4004H91.3998ZM90.8653 93.5638H90.7465V93.8906H90.7759V94.0988H90.7465V94.4256H90.8653V94.0988H90.8353V93.8906H90.8653V93.5638ZM90.375 94.0542V93.9354H90.7018V94.0542H90.375ZM90.9095 93.9354V94.0542H91.2363V93.9354H90.9095ZM90.2115 94.812H90.6869V95.2874L90.2115 95.2874V94.812ZM90.2115 95.5251V96.0003H90.6869V95.5251H90.2115ZM90.9246 95.5251V96.0003H91.3998V95.5251H90.9246ZM91.3998 95.2874V94.812H90.9246V95.2874H91.3998ZM90.7465 94.9754H90.8653V95.3021H90.8353V95.5103H90.8653V95.8371H90.7465V95.5103H90.7759V95.3021H90.7465V94.9754ZM90.375 95.3469V95.4657H90.7018V95.3469H90.375ZM90.9095 95.4657V95.3469H91.2363V95.4657H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 93.4004H92.3754V93.8758L91.8999 93.8758V93.4004ZM91.8999 94.1135V94.5887H92.3754V94.1135H91.8999ZM92.6131 94.1135V94.5887H93.0882V94.1135H92.6131ZM93.0882 93.8758V93.4004H92.6131V93.8758L93.0882 93.8758ZM92.4349 93.5638H92.5537V93.8906H92.5237V94.0988H92.5537V94.4255H92.4349V94.0988H92.4643V93.8906H92.4349V93.5638ZM92.063 93.9353V94.0542H92.3898V93.9353H92.063ZM92.5982 94.0542V93.9354H92.925V94.0542H92.5982ZM91.8999 94.8119H92.3753V95.2874H91.8999V94.8119ZM91.8999 95.5251V96.0002H92.3753V95.5251H91.8999ZM92.6131 95.5251V96.0002H93.0882V95.5251H92.6131ZM93.0882 95.2874V94.8119H92.6131V95.2874L93.0882 95.2874ZM92.4349 94.9753H92.5537V95.3021H92.5237V95.5103H92.5537V95.837H92.4349V95.5103H92.4643V95.3021H92.4349V94.9753ZM92.063 95.3468V95.4657H92.3898V95.3468H92.063ZM92.5982 95.4657V95.3469H92.925V95.4657H92.5982ZM93.787 93.4004H93.3116V93.8759L93.787 93.8759V93.4004ZM93.3116 94.5887V94.1136H93.787V94.5887H93.3116ZM94.0247 94.5887V94.1136H94.4999V94.5887H94.0247ZM94.4999 93.4004V93.8759L94.0247 93.8759V93.4004H94.4999ZM93.9654 93.5638H93.8466V93.8906H93.876V94.0988H93.8466V94.4256H93.9654V94.0988H93.9354V93.8906H93.9654V93.5638ZM93.4751 94.0542V93.9354H93.8019V94.0542H93.4751ZM94.0096 93.9354V94.0542H94.3364V93.9354H94.0096ZM93.3116 94.812H93.787V95.2874L93.3116 95.2874V94.812ZM93.3116 95.5251V96.0003H93.787V95.5251H93.3116ZM94.0247 95.5251V96.0003H94.4999V95.5251H94.0247ZM94.4999 95.2874V94.812H94.0247V95.2874H94.4999ZM93.8466 94.9754H93.9654V95.3021H93.9354V95.5103H93.9654V95.8371H93.8466V95.5103H93.876V95.3021H93.8466V94.9754ZM93.4751 95.3469V95.4657H93.8019V95.3469H93.4751ZM94.0096 95.4657V95.3469H94.3364V95.4657H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 93.4004H95.4755V93.8758L95 93.8758V93.4004ZM95 94.1135V94.5887H95.4755V94.1135H95ZM95.7132 94.1135V94.5887H96.1883V94.1135H95.7132ZM96.1883 93.8758V93.4004H95.7132V93.8758L96.1883 93.8758ZM95.535 93.5638H95.6538V93.8906H95.6238V94.0988H95.6538V94.4255H95.535V94.0988H95.5644V93.8906H95.535V93.5638ZM95.1631 93.9353V94.0542H95.4899V93.9353H95.1631ZM95.6983 94.0542V93.9354H96.0251V94.0542H95.6983ZM95 94.8119H95.4754V95.2874H95V94.8119ZM95 95.5251V96.0002H95.4754V95.5251H95ZM95.7132 95.5251V96.0002H96.1883V95.5251H95.7132ZM96.1883 95.2874V94.8119H95.7132V95.2874L96.1883 95.2874ZM95.535 94.9753H95.6538V95.3021H95.6238V95.5103H95.6538V95.837H95.535V95.5103H95.5644V95.3021H95.535V94.9753ZM95.1631 95.3468V95.4657H95.4899V95.3468H95.1631ZM95.6983 95.4657V95.3469H96.0251V95.4657H95.6983ZM96.8871 93.4004H96.4117V93.8759L96.8871 93.8759V93.4004ZM96.4117 94.5887V94.1136H96.8871V94.5887H96.4117ZM97.1248 94.5887V94.1136H97.6V94.5887H97.1248ZM97.6 93.4004V93.8759L97.1248 93.8759V93.4004H97.6ZM97.0655 93.5638H96.9467V93.8906H96.9761V94.0988H96.9467V94.4256H97.0655V94.0988H97.0355V93.8906H97.0655V93.5638ZM96.5752 94.0542V93.9354H96.902V94.0542H96.5752ZM97.1097 93.9354V94.0542H97.4365V93.9354H97.1097ZM96.4117 94.812H96.8871V95.2874L96.4117 95.2874V94.812ZM96.4117 95.5251V96.0003H96.8871V95.5251H96.4117ZM97.1248 95.5251V96.0003H97.6V95.5251H97.1248ZM97.6 95.2874V94.812H97.1248V95.2874H97.6ZM96.9467 94.9754H97.0655V95.3021H97.0355V95.5103H97.0655V95.8371H96.9467V95.5103H96.9761V95.3021H96.9467V94.9754ZM96.5752 95.3469V95.4657H96.902V95.3469H96.5752ZM97.1097 95.4657V95.3469H97.4365V95.4657H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 93.4004H98.5756V93.8758L98.1001 93.8758V93.4004ZM98.1001 94.1135V94.5887H98.5756V94.1135H98.1001ZM98.8133 94.1135V94.5887H99.2884V94.1135H98.8133ZM99.2884 93.8758V93.4004H98.8133V93.8758L99.2884 93.8758ZM98.6351 93.5638H98.7539V93.8906H98.7239V94.0988H98.7539V94.4255H98.6351V94.0988H98.6645V93.8906H98.6351V93.5638ZM98.2632 93.9353V94.0542H98.59V93.9353H98.2632ZM98.7984 94.0542V93.9354H99.1252V94.0542H98.7984ZM98.1001 94.8119H98.5755V95.2874H98.1001V94.8119ZM98.1001 95.5251V96.0002H98.5755V95.5251H98.1001ZM98.8133 95.5251V96.0002H99.2884V95.5251H98.8133ZM99.2884 95.2874V94.8119H98.8133V95.2874L99.2884 95.2874ZM98.6351 94.9753H98.7539V95.3021H98.7239V95.5103H98.7539V95.837H98.6351V95.5103H98.6645V95.3021H98.6351V94.9753ZM98.2632 95.3468V95.4657H98.59V95.3468H98.2632ZM98.7984 95.4657V95.3469H99.1252V95.4657H98.7984ZM99.9872 93.4004H99.5118V93.8759L99.9872 93.8759V93.4004ZM99.5118 94.5887V94.1136H99.9872V94.5887H99.5118ZM100.225 94.5887V94.1136H100.7V94.5887H100.225ZM100.7 93.4004V93.8759L100.225 93.8759V93.4004H100.7ZM100.166 93.5638H100.047V93.8906H100.076V94.0988H100.047V94.4256H100.166V94.0988H100.136V93.8906H100.166V93.5638ZM99.6753 94.0542V93.9354H100.002V94.0542H99.6753ZM100.21 93.9354V94.0542H100.537V93.9354H100.21ZM99.5118 94.812H99.9872V95.2874L99.5118 95.2874V94.812ZM99.5118 95.5251V96.0003H99.9872V95.5251H99.5118ZM100.225 95.5251V96.0003H100.7V95.5251H100.225ZM100.7 95.2874V94.812H100.225V95.2874H100.7ZM100.047 94.9754H100.166V95.3021H100.136V95.5103H100.166V95.8371H100.047V95.5103H100.076V95.3021H100.047V94.9754ZM99.6753 95.3469V95.4657H100.002V95.3469H99.6753ZM100.21 95.4657V95.3469H100.537V95.4657H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 96.5H89.2753V96.9754L88.7998 96.9754V96.5ZM88.7998 97.2131V97.6883H89.2753V97.2131H88.7998ZM89.513 97.2131V97.6883H89.9881V97.2131H89.513ZM89.9881 96.9754V96.5H89.513V96.9754L89.9881 96.9754ZM89.3348 96.6634H89.4536V96.9902H89.4236V97.1984H89.4536V97.5251H89.3348V97.1984H89.3642V96.9902H89.3348V96.6634ZM88.9629 97.035V97.1538H89.2897V97.035H88.9629ZM89.4981 97.1538V97.035H89.8249V97.1538H89.4981ZM88.7998 97.9115H89.2753V98.387H88.7998V97.9115ZM88.7998 98.6247V99.0998H89.2753V98.6247H88.7998ZM89.513 98.6247V99.0998H89.9881V98.6247H89.513ZM89.9881 98.387V97.9115H89.513V98.387L89.9881 98.387ZM89.3348 98.0749H89.4536V98.4017H89.4236V98.6099H89.4536V98.9366H89.3348V98.6099H89.3642V98.4017H89.3348V98.0749ZM88.9629 98.4465V98.5653H89.2897V98.4465H88.9629ZM89.4981 98.5653V98.4465H89.8249V98.5653H89.4981ZM90.6869 96.5H90.2115V96.9755L90.6869 96.9755V96.5ZM90.2115 97.6883V97.2132H90.6869V97.6883H90.2115ZM90.9246 97.6883V97.2132H91.3998V97.6883H90.9246ZM91.3998 96.5V96.9755L90.9246 96.9755V96.5H91.3998ZM90.8653 96.6634H90.7465V96.9902H90.7759V97.1984H90.7465V97.5252H90.8653V97.1984H90.8353V96.9902H90.8653V96.6634ZM90.375 97.1538V97.035H90.7018V97.1538H90.375ZM90.9095 97.035V97.1538H91.2363V97.035H90.9095ZM90.2115 97.9116H90.6869V98.387L90.2115 98.387V97.9116ZM90.2115 98.6247V99.0999H90.6869V98.6247H90.2115ZM90.9246 98.6247V99.0999H91.3998V98.6247H90.9246ZM91.3998 98.387V97.9116H90.9246V98.387H91.3998ZM90.7465 98.075H90.8653V98.4018H90.8353V98.6099H90.8653V98.9367H90.7465V98.6099H90.7759V98.4018H90.7465V98.075ZM90.375 98.4465V98.5653H90.7018V98.4465H90.375ZM90.9095 98.5653V98.4465H91.2363V98.5653H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 96.5H92.3754V96.9754L91.8999 96.9754V96.5ZM91.8999 97.2131V97.6883H92.3754V97.2131H91.8999ZM92.6131 97.2131V97.6883H93.0882V97.2131H92.6131ZM93.0882 96.9754V96.5H92.6131V96.9754L93.0882 96.9754ZM92.4349 96.6634H92.5537V96.9902H92.5237V97.1984H92.5537V97.5251H92.4349V97.1984H92.4643V96.9902H92.4349V96.6634ZM92.063 97.035V97.1538H92.3898V97.035H92.063ZM92.5982 97.1538V97.035H92.925V97.1538H92.5982ZM91.8999 97.9115H92.3753V98.387H91.8999V97.9115ZM91.8999 98.6247V99.0998H92.3753V98.6247H91.8999ZM92.6131 98.6247V99.0998H93.0882V98.6247H92.6131ZM93.0882 98.387V97.9115H92.6131V98.387L93.0882 98.387ZM92.4349 98.0749H92.5537V98.4017H92.5237V98.6099H92.5537V98.9366H92.4349V98.6099H92.4643V98.4017H92.4349V98.0749ZM92.063 98.4465V98.5653H92.3898V98.4465H92.063ZM92.5982 98.5653V98.4465H92.925V98.5653H92.5982ZM93.787 96.5H93.3116V96.9755L93.787 96.9755V96.5ZM93.3116 97.6883V97.2132H93.787V97.6883H93.3116ZM94.0247 97.6883V97.2132H94.4999V97.6883H94.0247ZM94.4999 96.5V96.9755L94.0247 96.9755V96.5H94.4999ZM93.9654 96.6634H93.8466V96.9902H93.876V97.1984H93.8466V97.5252H93.9654V97.1984H93.9354V96.9902H93.9654V96.6634ZM93.4751 97.1538V97.035H93.8019V97.1538H93.4751ZM94.0096 97.035V97.1538H94.3364V97.035H94.0096ZM93.3116 97.9116H93.787V98.387L93.3116 98.387V97.9116ZM93.3116 98.6247V99.0999H93.787V98.6247H93.3116ZM94.0247 98.6247V99.0999H94.4999V98.6247H94.0247ZM94.4999 98.387V97.9116H94.0247V98.387H94.4999ZM93.8466 98.075H93.9654V98.4018H93.9354V98.6099H93.9654V98.9367H93.8466V98.6099H93.876V98.4018H93.8466V98.075ZM93.4751 98.4465V98.5653H93.8019V98.4465H93.4751ZM94.0096 98.5653V98.4465H94.3364V98.5653H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 96.5H95.4755V96.9754L95 96.9754V96.5ZM95 97.2131V97.6883H95.4755V97.2131H95ZM95.7132 97.2131V97.6883H96.1883V97.2131H95.7132ZM96.1883 96.9754V96.5H95.7132V96.9754L96.1883 96.9754ZM95.535 96.6634H95.6538V96.9902H95.6238V97.1984H95.6538V97.5251H95.535V97.1984H95.5644V96.9902H95.535V96.6634ZM95.1631 97.035V97.1538H95.4899V97.035H95.1631ZM95.6983 97.1538V97.035H96.0251V97.1538H95.6983ZM95 97.9115H95.4754V98.387H95V97.9115ZM95 98.6247V99.0998H95.4754V98.6247H95ZM95.7132 98.6247V99.0998H96.1883V98.6247H95.7132ZM96.1883 98.387V97.9115H95.7132V98.387L96.1883 98.387ZM95.535 98.0749H95.6538V98.4017H95.6238V98.6099H95.6538V98.9366H95.535V98.6099H95.5644V98.4017H95.535V98.0749ZM95.1631 98.4465V98.5653H95.4899V98.4465H95.1631ZM95.6983 98.5653V98.4465H96.0251V98.5653H95.6983ZM96.8871 96.5H96.4117V96.9755L96.8871 96.9755V96.5ZM96.4117 97.6883V97.2132H96.8871V97.6883H96.4117ZM97.1248 97.6883V97.2132H97.6V97.6883H97.1248ZM97.6 96.5V96.9755L97.1248 96.9755V96.5H97.6ZM97.0655 96.6634H96.9467V96.9902H96.9761V97.1984H96.9467V97.5252H97.0655V97.1984H97.0355V96.9902H97.0655V96.6634ZM96.5752 97.1538V97.035H96.902V97.1538H96.5752ZM97.1097 97.035V97.1538H97.4365V97.035H97.1097ZM96.4117 97.9116H96.8871V98.387L96.4117 98.387V97.9116ZM96.4117 98.6247V99.0999H96.8871V98.6247H96.4117ZM97.1248 98.6247V99.0999H97.6V98.6247H97.1248ZM97.6 98.387V97.9116H97.1248V98.387H97.6ZM96.9467 98.075H97.0655V98.4018H97.0355V98.6099H97.0655V98.9367H96.9467V98.6099H96.9761V98.4018H96.9467V98.075ZM96.5752 98.4465V98.5653H96.902V98.4465H96.5752ZM97.1097 98.5653V98.4465H97.4365V98.5653H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 96.5H98.5756V96.9754L98.1001 96.9754V96.5ZM98.1001 97.2131V97.6883H98.5756V97.2131H98.1001ZM98.8133 97.2131V97.6883H99.2884V97.2131H98.8133ZM99.2884 96.9754V96.5H98.8133V96.9754L99.2884 96.9754ZM98.6351 96.6634H98.7539V96.9902H98.7239V97.1984H98.7539V97.5251H98.6351V97.1984H98.6645V96.9902H98.6351V96.6634ZM98.2632 97.035V97.1538H98.59V97.035H98.2632ZM98.7984 97.1538V97.035H99.1252V97.1538H98.7984ZM98.1001 97.9115H98.5755V98.387H98.1001V97.9115ZM98.1001 98.6247V99.0998H98.5755V98.6247H98.1001ZM98.8133 98.6247V99.0998H99.2884V98.6247H98.8133ZM99.2884 98.387V97.9115H98.8133V98.387L99.2884 98.387ZM98.6351 98.0749H98.7539V98.4017H98.7239V98.6099H98.7539V98.9366H98.6351V98.6099H98.6645V98.4017H98.6351V98.0749ZM98.2632 98.4465V98.5653H98.59V98.4465H98.2632ZM98.7984 98.5653V98.4465H99.1252V98.5653H98.7984ZM99.9872 96.5H99.5118V96.9755L99.9872 96.9755V96.5ZM99.5118 97.6883V97.2132H99.9872V97.6883H99.5118ZM100.225 97.6883V97.2132H100.7V97.6883H100.225ZM100.7 96.5V96.9755L100.225 96.9755V96.5H100.7ZM100.166 96.6634H100.047V96.9902H100.076V97.1984H100.047V97.5252H100.166V97.1984H100.136V96.9902H100.166V96.6634ZM99.6753 97.1538V97.035H100.002V97.1538H99.6753ZM100.21 97.035V97.1538H100.537V97.035H100.21ZM99.5118 97.9116H99.9872V98.387L99.5118 98.387V97.9116ZM99.5118 98.6247V99.0999H99.9872V98.6247H99.5118ZM100.225 98.6247V99.0999H100.7V98.6247H100.225ZM100.7 98.387V97.9116H100.225V98.387H100.7ZM100.047 98.075H100.166V98.4018H100.136V98.6099H100.166V98.9367H100.047V98.6099H100.076V98.4018H100.047V98.075ZM99.6753 98.4465V98.5653H100.002V98.4465H99.6753ZM100.21 98.5653V98.4465H100.537V98.5653H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 99.5996H89.2753V100.075L88.7998 100.075V99.5996ZM88.7998 100.313V100.788H89.2753V100.313H88.7998ZM89.513 100.313V100.788H89.9881V100.313H89.513ZM89.9881 100.075V99.5996H89.513V100.075L89.9881 100.075ZM89.3348 99.763H89.4536V100.09H89.4236V100.298H89.4536V100.625H89.3348V100.298H89.3642V100.09H89.3348V99.763ZM88.9629 100.135V100.253H89.2897V100.135H88.9629ZM89.4981 100.253V100.135H89.8249V100.253H89.4981ZM88.7998 101.011H89.2753V101.487H88.7998V101.011ZM88.7998 101.724V102.199H89.2753V101.724H88.7998ZM89.513 101.724V102.199H89.9881V101.724H89.513ZM89.9881 101.487V101.011H89.513V101.487L89.9881 101.487ZM89.3348 101.175H89.4536V101.501H89.4236V101.709H89.4536V102.036H89.3348V101.709H89.3642V101.501H89.3348V101.175ZM88.9629 101.546V101.665H89.2897V101.546H88.9629ZM89.4981 101.665V101.546H89.8249V101.665H89.4981ZM90.6869 99.5996H90.2115V100.075L90.6869 100.075V99.5996ZM90.2115 100.788V100.313H90.6869V100.788H90.2115ZM90.9246 100.788V100.313H91.3998V100.788H90.9246ZM91.3998 99.5996V100.075L90.9246 100.075V99.5996H91.3998ZM90.8653 99.763H90.7465V100.09H90.7759V100.298H90.7465V100.625H90.8653V100.298H90.8353V100.09H90.8653V99.763ZM90.375 100.253V100.135H90.7018V100.253H90.375ZM90.9095 100.135V100.253H91.2363V100.135H90.9095ZM90.2115 101.011H90.6869V101.487L90.2115 101.487V101.011ZM90.2115 101.724V102.199H90.6869V101.724H90.2115ZM90.9246 101.724V102.199H91.3998V101.724H90.9246ZM91.3998 101.487V101.011H90.9246V101.487H91.3998ZM90.7465 101.175H90.8653V101.501H90.8353V101.71H90.8653V102.036H90.7465V101.71H90.7759V101.501H90.7465V101.175ZM90.375 101.546V101.665H90.7018V101.546H90.375ZM90.9095 101.665V101.546H91.2363V101.665H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 99.5996H92.3754V100.075L91.8999 100.075V99.5996ZM91.8999 100.313V100.788H92.3754V100.313H91.8999ZM92.6131 100.313V100.788H93.0882V100.313H92.6131ZM93.0882 100.075V99.5996H92.6131V100.075L93.0882 100.075ZM92.4349 99.763H92.5537V100.09H92.5237V100.298H92.5537V100.625H92.4349V100.298H92.4643V100.09H92.4349V99.763ZM92.063 100.135V100.253H92.3898V100.135H92.063ZM92.5982 100.253V100.135H92.925V100.253H92.5982ZM91.8999 101.011H92.3753V101.487H91.8999V101.011ZM91.8999 101.724V102.199H92.3753V101.724H91.8999ZM92.6131 101.724V102.199H93.0882V101.724H92.6131ZM93.0882 101.487V101.011H92.6131V101.487L93.0882 101.487ZM92.4349 101.175H92.5537V101.501H92.5237V101.709H92.5537V102.036H92.4349V101.709H92.4643V101.501H92.4349V101.175ZM92.063 101.546V101.665H92.3898V101.546H92.063ZM92.5982 101.665V101.546H92.925V101.665H92.5982ZM93.787 99.5996H93.3116V100.075L93.787 100.075V99.5996ZM93.3116 100.788V100.313H93.787V100.788H93.3116ZM94.0247 100.788V100.313H94.4999V100.788H94.0247ZM94.4999 99.5996V100.075L94.0247 100.075V99.5996H94.4999ZM93.9654 99.763H93.8466V100.09H93.876V100.298H93.8466V100.625H93.9654V100.298H93.9354V100.09H93.9654V99.763ZM93.4751 100.253V100.135H93.8019V100.253H93.4751ZM94.0096 100.135V100.253H94.3364V100.135H94.0096ZM93.3116 101.011H93.787V101.487L93.3116 101.487V101.011ZM93.3116 101.724V102.199H93.787V101.724H93.3116ZM94.0247 101.724V102.199H94.4999V101.724H94.0247ZM94.4999 101.487V101.011H94.0247V101.487H94.4999ZM93.8466 101.175H93.9654V101.501H93.9354V101.71H93.9654V102.036H93.8466V101.71H93.876V101.501H93.8466V101.175ZM93.4751 101.546V101.665H93.8019V101.546H93.4751ZM94.0096 101.665V101.546H94.3364V101.665H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 99.5996H95.4755V100.075L95 100.075V99.5996ZM95 100.313V100.788H95.4755V100.313H95ZM95.7132 100.313V100.788H96.1883V100.313H95.7132ZM96.1883 100.075V99.5996H95.7132V100.075L96.1883 100.075ZM95.535 99.763H95.6538V100.09H95.6238V100.298H95.6538V100.625H95.535V100.298H95.5644V100.09H95.535V99.763ZM95.1631 100.135V100.253H95.4899V100.135H95.1631ZM95.6983 100.253V100.135H96.0251V100.253H95.6983ZM95 101.011H95.4754V101.487H95V101.011ZM95 101.724V102.199H95.4754V101.724H95ZM95.7132 101.724V102.199H96.1883V101.724H95.7132ZM96.1883 101.487V101.011H95.7132V101.487L96.1883 101.487ZM95.535 101.175H95.6538V101.501H95.6238V101.709H95.6538V102.036H95.535V101.709H95.5644V101.501H95.535V101.175ZM95.1631 101.546V101.665H95.4899V101.546H95.1631ZM95.6983 101.665V101.546H96.0251V101.665H95.6983ZM96.8871 99.5996H96.4117V100.075L96.8871 100.075V99.5996ZM96.4117 100.788V100.313H96.8871V100.788H96.4117ZM97.1248 100.788V100.313H97.6V100.788H97.1248ZM97.6 99.5996V100.075L97.1248 100.075V99.5996H97.6ZM97.0655 99.763H96.9467V100.09H96.9761V100.298H96.9467V100.625H97.0655V100.298H97.0355V100.09H97.0655V99.763ZM96.5752 100.253V100.135H96.902V100.253H96.5752ZM97.1097 100.135V100.253H97.4365V100.135H97.1097ZM96.4117 101.011H96.8871V101.487L96.4117 101.487V101.011ZM96.4117 101.724V102.199H96.8871V101.724H96.4117ZM97.1248 101.724V102.199H97.6V101.724H97.1248ZM97.6 101.487V101.011H97.1248V101.487H97.6ZM96.9467 101.175H97.0655V101.501H97.0355V101.71H97.0655V102.036H96.9467V101.71H96.9761V101.501H96.9467V101.175ZM96.5752 101.546V101.665H96.902V101.546H96.5752ZM97.1097 101.665V101.546H97.4365V101.665H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 99.5996H98.5756V100.075L98.1001 100.075V99.5996ZM98.1001 100.313V100.788H98.5756V100.313H98.1001ZM98.8133 100.313V100.788H99.2884V100.313H98.8133ZM99.2884 100.075V99.5996H98.8133V100.075L99.2884 100.075ZM98.6351 99.763H98.7539V100.09H98.7239V100.298H98.7539V100.625H98.6351V100.298H98.6645V100.09H98.6351V99.763ZM98.2632 100.135V100.253H98.59V100.135H98.2632ZM98.7984 100.253V100.135H99.1252V100.253H98.7984ZM98.1001 101.011H98.5755V101.487H98.1001V101.011ZM98.1001 101.724V102.199H98.5755V101.724H98.1001ZM98.8133 101.724V102.199H99.2884V101.724H98.8133ZM99.2884 101.487V101.011H98.8133V101.487L99.2884 101.487ZM98.6351 101.175H98.7539V101.501H98.7239V101.709H98.7539V102.036H98.6351V101.709H98.6645V101.501H98.6351V101.175ZM98.2632 101.546V101.665H98.59V101.546H98.2632ZM98.7984 101.665V101.546H99.1252V101.665H98.7984ZM99.9872 99.5996H99.5118V100.075L99.9872 100.075V99.5996ZM99.5118 100.788V100.313H99.9872V100.788H99.5118ZM100.225 100.788V100.313H100.7V100.788H100.225ZM100.7 99.5996V100.075L100.225 100.075V99.5996H100.7ZM100.166 99.763H100.047V100.09H100.076V100.298H100.047V100.625H100.166V100.298H100.136V100.09H100.166V99.763ZM99.6753 100.253V100.135H100.002V100.253H99.6753ZM100.21 100.135V100.253H100.537V100.135H100.21ZM99.5118 101.011H99.9872V101.487L99.5118 101.487V101.011ZM99.5118 101.724V102.199H99.9872V101.724H99.5118ZM100.225 101.724V102.199H100.7V101.724H100.225ZM100.7 101.487V101.011H100.225V101.487H100.7ZM100.047 101.175H100.166V101.501H100.136V101.71H100.166V102.036H100.047V101.71H100.076V101.501H100.047V101.175ZM99.6753 101.546V101.665H100.002V101.546H99.6753ZM100.21 101.665V101.546H100.537V101.665H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 102.8H51.7753V103.275L51.2998 103.275V102.8ZM51.2998 103.513V103.988H51.7753V103.513H51.2998ZM52.013 103.513V103.988H52.4881V103.513H52.013ZM52.4881 103.275V102.8H52.013V103.275L52.4881 103.275ZM51.8348 102.963H51.9536V103.29H51.9236V103.498H51.9536V103.825H51.8348V103.498H51.8642V103.29H51.8348V102.963ZM51.4629 103.335V103.454H51.7897V103.335H51.4629ZM51.9981 103.454V103.335H52.3249V103.454H51.9981ZM51.2998 104.211H51.7753V104.687H51.2998V104.211ZM51.2998 104.925V105.4H51.7753V104.925H51.2998ZM52.013 104.925V105.4H52.4881V104.925H52.013ZM52.4881 104.687V104.211H52.013V104.687H52.4881ZM51.8348 104.375H51.9536V104.702H51.9236V104.91H51.9536V105.236H51.8348V104.91H51.8642V104.702H51.8348V104.375ZM51.4629 104.746V104.865H51.7897V104.746H51.4629ZM51.9981 104.865V104.746H52.3249V104.865H51.9981ZM53.1869 102.8H52.7115V103.275L53.1869 103.275V102.8ZM52.7115 103.988V103.513H53.1869V103.988H52.7115ZM53.4246 103.988V103.513H53.8998V103.988H53.4246ZM53.8998 102.8V103.275L53.4246 103.275V102.8H53.8998ZM53.3653 102.963H53.2465V103.29H53.2759V103.498H53.2465V103.825H53.3653V103.498H53.3353V103.29H53.3653V102.963ZM52.875 103.454V103.335H53.2018V103.454H52.875ZM53.4095 103.335V103.454H53.7363V103.335H53.4095ZM52.7115 104.211H53.1869V104.687H52.7115V104.211ZM52.7115 104.925V105.4H53.1869V104.925H52.7115ZM53.4246 104.925V105.4H53.8998V104.925H53.4246ZM53.8998 104.687V104.211H53.4246V104.687H53.8998ZM53.2465 104.375H53.3653V104.702H53.3353V104.91H53.3653V105.236H53.2465V104.91H53.2759V104.702H53.2465V104.375ZM52.875 104.746V104.865H53.2018V104.746H52.875ZM53.4095 104.865V104.746H53.7363V104.865H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 102.8H54.8754V103.275L54.3999 103.275V102.8ZM54.3999 103.513V103.988H54.8754V103.513H54.3999ZM55.1131 103.513V103.988H55.5882V103.513H55.1131ZM55.5882 103.275V102.8H55.1131V103.275L55.5882 103.275ZM54.9349 102.963H55.0537V103.29H55.0237V103.498H55.0537V103.825H54.9349V103.498H54.9643V103.29H54.9349V102.963ZM54.563 103.335V103.454H54.8898V103.335H54.563ZM55.0982 103.454V103.335H55.425V103.454H55.0982ZM54.3999 104.211H54.8753V104.687H54.3999V104.211ZM54.3999 104.925V105.4H54.8753V104.925H54.3999ZM55.1131 104.925V105.4H55.5882V104.925H55.1131ZM55.5882 104.687V104.211H55.1131V104.687L55.5882 104.687ZM54.9349 104.375H55.0537V104.702H55.0237V104.91H55.0537V105.236H54.9349V104.91H54.9643V104.702H54.9349V104.375ZM54.563 104.746V104.865H54.8898V104.746H54.563ZM55.0982 104.865V104.746H55.425V104.865H55.0982ZM56.287 102.8H55.8116V103.275L56.287 103.275V102.8ZM55.8116 103.988V103.513H56.287V103.988H55.8116ZM56.5247 103.988V103.513H56.9999V103.988H56.5247ZM56.9999 102.8V103.275L56.5247 103.275V102.8H56.9999ZM56.4654 102.963H56.3466V103.29H56.376V103.498H56.3466V103.825H56.4654V103.498H56.4354V103.29H56.4654V102.963ZM55.9751 103.454V103.335H56.3019V103.454H55.9751ZM56.5096 103.335V103.454H56.8364V103.335H56.5096ZM55.8116 104.211H56.287V104.687L55.8116 104.687V104.211ZM55.8116 104.925V105.4H56.287V104.925H55.8116ZM56.5247 104.925V105.4H56.9999V104.925H56.5247ZM56.9999 104.687V104.211H56.5247V104.687H56.9999ZM56.3466 104.375H56.4654V104.702H56.4354V104.91H56.4654V105.236H56.3466V104.91H56.376V104.702H56.3466V104.375ZM55.9751 104.746V104.865H56.3019V104.746H55.9751ZM56.5096 104.865V104.746H56.8364V104.865H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 102.8H57.9755V103.275L57.5 103.275V102.8ZM57.5 103.513V103.988H57.9755V103.513H57.5ZM58.2132 103.513V103.988H58.6883V103.513H58.2132ZM58.6883 103.275V102.8H58.2132V103.275L58.6883 103.275ZM58.035 102.963H58.1538V103.29H58.1238V103.498H58.1538V103.825H58.035V103.498H58.0644V103.29H58.035V102.963ZM57.6631 103.335V103.454H57.9899V103.335H57.6631ZM58.1983 103.454V103.335H58.5251V103.454H58.1983ZM57.5 104.211H57.9754V104.687H57.5V104.211ZM57.5 104.925V105.4H57.9754V104.925H57.5ZM58.2132 104.925V105.4H58.6883V104.925H58.2132ZM58.6883 104.687V104.211H58.2132V104.687L58.6883 104.687ZM58.035 104.375H58.1538V104.702H58.1238V104.91H58.1538V105.236H58.035V104.91H58.0644V104.702H58.035V104.375ZM57.6631 104.746V104.865H57.9899V104.746H57.6631ZM58.1983 104.865V104.746H58.5251V104.865H58.1983ZM59.3871 102.8H58.9117V103.275L59.3871 103.275V102.8ZM58.9117 103.988V103.513H59.3871V103.988H58.9117ZM59.6248 103.988V103.513H60.1V103.988H59.6248ZM60.1 102.8V103.275L59.6248 103.275V102.8H60.1ZM59.5655 102.963H59.4467V103.29H59.4761V103.498H59.4467V103.825H59.5655V103.498H59.5355V103.29H59.5655V102.963ZM59.0752 103.454V103.335H59.402V103.454H59.0752ZM59.6097 103.335V103.454H59.9365V103.335H59.6097ZM58.9117 104.211H59.3871V104.687L58.9117 104.687V104.211ZM58.9117 104.925V105.4H59.3871V104.925H58.9117ZM59.6248 104.925V105.4H60.1V104.925H59.6248ZM60.1 104.687V104.211H59.6248V104.687H60.1ZM59.4467 104.375H59.5655V104.702H59.5355V104.91H59.5655V105.236H59.4467V104.91H59.4761V104.702H59.4467V104.375ZM59.0752 104.746V104.865H59.402V104.746H59.0752ZM59.6097 104.865V104.746H59.9365V104.865H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 102.8H61.0756V103.275L60.6001 103.275V102.8ZM60.6001 103.513V103.988H61.0756V103.513H60.6001ZM61.3133 103.513V103.988H61.7884V103.513H61.3133ZM61.7884 103.275V102.8H61.3133V103.275L61.7884 103.275ZM61.1351 102.963H61.2539V103.29H61.2239V103.498H61.2539V103.825H61.1351V103.498H61.1645V103.29H61.1351V102.963ZM60.7632 103.335V103.454H61.09V103.335H60.7632ZM61.2984 103.454V103.335H61.6252V103.454H61.2984ZM60.6001 104.211H61.0755V104.687H60.6001V104.211ZM60.6001 104.925V105.4H61.0755V104.925H60.6001ZM61.3133 104.925V105.4H61.7884V104.925H61.3133ZM61.7884 104.687V104.211H61.3133V104.687L61.7884 104.687ZM61.1351 104.375H61.2539V104.702H61.2239V104.91H61.2539V105.236H61.1351V104.91H61.1645V104.702H61.1351V104.375ZM60.7632 104.746V104.865H61.09V104.746H60.7632ZM61.2984 104.865V104.746H61.6252V104.865H61.2984ZM62.4872 102.8H62.0118V103.275L62.4872 103.275V102.8ZM62.0118 103.988V103.513H62.4872V103.988H62.0118ZM62.7249 103.988V103.513H63.2001V103.988H62.7249ZM63.2001 102.8V103.275L62.7249 103.275V102.8H63.2001ZM62.6656 102.963H62.5468V103.29H62.5762V103.498H62.5468V103.825H62.6656V103.498H62.6356V103.29H62.6656V102.963ZM62.1753 103.454V103.335H62.5021V103.454H62.1753ZM62.7098 103.335V103.454H63.0366V103.335H62.7098ZM62.0118 104.211H62.4872V104.687L62.0118 104.687V104.211ZM62.0118 104.925V105.4H62.4872V104.925H62.0118ZM62.7249 104.925V105.4H63.2001V104.925H62.7249ZM63.2001 104.687V104.211H62.7249V104.687H63.2001ZM62.5468 104.375H62.6656V104.702H62.6356V104.91H62.6656V105.236H62.5468V104.91H62.5762V104.702H62.5468V104.375ZM62.1753 104.746V104.865H62.5021V104.746H62.1753ZM62.7098 104.865V104.746H63.0366V104.865H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 105.9H51.7753V106.376L51.2998 106.376V105.9ZM51.2998 106.614V107.089H51.7753V106.614H51.2998ZM52.013 106.614V107.089H52.4881V106.614H52.013ZM52.4881 106.376V105.9H52.013V106.376L52.4881 106.376ZM51.8348 106.064H51.9536V106.391H51.9236V106.599H51.9536V106.926H51.8348V106.599H51.8642V106.391H51.8348V106.064ZM51.4629 106.435V106.554H51.7897V106.435H51.4629ZM51.9981 106.554V106.435H52.3249V106.554H51.9981ZM51.2998 107.312H51.7753V107.787H51.2998V107.312ZM51.2998 108.025V108.5H51.7753V108.025H51.2998ZM52.013 108.025V108.5H52.4881V108.025H52.013ZM52.4881 107.787V107.312H52.013V107.787L52.4881 107.787ZM51.8348 107.475H51.9536V107.802H51.9236V108.01H51.9536V108.337H51.8348V108.01H51.8642V107.802H51.8348V107.475ZM51.4629 107.847V107.966H51.7897V107.847H51.4629ZM51.9981 107.966V107.847H52.3249V107.966H51.9981ZM53.1869 105.9H52.7115V106.376L53.1869 106.376V105.9ZM52.7115 107.089V106.614H53.1869V107.089H52.7115ZM53.4246 107.089V106.614H53.8998V107.089H53.4246ZM53.8998 105.9V106.376L53.4246 106.376V105.9H53.8998ZM53.3653 106.064H53.2465V106.391H53.2759V106.599H53.2465V106.926H53.3653V106.599H53.3353V106.391H53.3653V106.064ZM52.875 106.554V106.435H53.2018V106.554H52.875ZM53.4095 106.435V106.554H53.7363V106.435H53.4095ZM52.7115 107.312H53.1869V107.787L52.7115 107.787V107.312ZM52.7115 108.025V108.5H53.1869V108.025H52.7115ZM53.4246 108.025V108.5H53.8998V108.025H53.4246ZM53.8998 107.787V107.312H53.4246V107.787H53.8998ZM53.2465 107.475H53.3653V107.802H53.3353V108.01H53.3653V108.337H53.2465V108.01H53.2759V107.802H53.2465V107.475ZM52.875 107.847V107.966H53.2018V107.847H52.875ZM53.4095 107.966V107.847H53.7363V107.966H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 105.9H54.8754V106.376L54.3999 106.376V105.9ZM54.3999 106.614V107.089H54.8754V106.614H54.3999ZM55.1131 106.614V107.089H55.5882V106.614H55.1131ZM55.5882 106.376V105.9H55.1131V106.376L55.5882 106.376ZM54.9349 106.064H55.0537V106.391H55.0237V106.599H55.0537V106.926H54.9349V106.599H54.9643V106.391H54.9349V106.064ZM54.563 106.435V106.554H54.8898V106.435H54.563ZM55.0982 106.554V106.435H55.425V106.554H55.0982ZM54.3999 107.312H54.8753V107.787H54.3999V107.312ZM54.3999 108.025V108.5H54.8753V108.025H54.3999ZM55.1131 108.025V108.5H55.5882V108.025H55.1131ZM55.5882 107.787V107.312H55.1131V107.787L55.5882 107.787ZM54.9349 107.475H55.0537V107.802H55.0237V108.01H55.0537V108.337H54.9349V108.01H54.9643V107.802H54.9349V107.475ZM54.563 107.847V107.966H54.8898V107.847H54.563ZM55.0982 107.966V107.847H55.425V107.966H55.0982ZM56.287 105.9H55.8116V106.376L56.287 106.376V105.9ZM55.8116 107.089V106.614H56.287V107.089H55.8116ZM56.5247 107.089V106.614H56.9999V107.089H56.5247ZM56.9999 105.9V106.376L56.5247 106.376V105.9H56.9999ZM56.4654 106.064H56.3466V106.391H56.376V106.599H56.3466V106.926H56.4654V106.599H56.4354V106.391H56.4654V106.064ZM55.9751 106.554V106.435H56.3019V106.554H55.9751ZM56.5096 106.435V106.554H56.8364V106.435H56.5096ZM55.8116 107.312H56.287V107.787L55.8116 107.787V107.312ZM55.8116 108.025V108.5H56.287V108.025H55.8116ZM56.5247 108.025V108.5H56.9999V108.025H56.5247ZM56.9999 107.787V107.312H56.5247V107.787H56.9999ZM56.3466 107.475H56.4654V107.802H56.4354V108.01H56.4654V108.337H56.3466V108.01H56.376V107.802H56.3466V107.475ZM55.9751 107.847V107.966H56.3019V107.847H55.9751ZM56.5096 107.966V107.847H56.8364V107.966H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 105.9H57.9755V106.376L57.5 106.376V105.9ZM57.5 106.614V107.089H57.9755V106.614H57.5ZM58.2132 106.614V107.089H58.6883V106.614H58.2132ZM58.6883 106.376V105.9H58.2132V106.376L58.6883 106.376ZM58.035 106.064H58.1538V106.391H58.1238V106.599H58.1538V106.926H58.035V106.599H58.0644V106.391H58.035V106.064ZM57.6631 106.435V106.554H57.9899V106.435H57.6631ZM58.1983 106.554V106.435H58.5251V106.554H58.1983ZM57.5 107.312H57.9754V107.787H57.5V107.312ZM57.5 108.025V108.5H57.9754V108.025H57.5ZM58.2132 108.025V108.5H58.6883V108.025H58.2132ZM58.6883 107.787V107.312H58.2132V107.787L58.6883 107.787ZM58.035 107.475H58.1538V107.802H58.1238V108.01H58.1538V108.337H58.035V108.01H58.0644V107.802H58.035V107.475ZM57.6631 107.847V107.966H57.9899V107.847H57.6631ZM58.1983 107.966V107.847H58.5251V107.966H58.1983ZM59.3871 105.9H58.9117V106.376L59.3871 106.376V105.9ZM58.9117 107.089V106.614H59.3871V107.089H58.9117ZM59.6248 107.089V106.614H60.1V107.089H59.6248ZM60.1 105.9V106.376L59.6248 106.376V105.9H60.1ZM59.5655 106.064H59.4467V106.391H59.4761V106.599H59.4467V106.926H59.5655V106.599H59.5355V106.391H59.5655V106.064ZM59.0752 106.554V106.435H59.402V106.554H59.0752ZM59.6097 106.435V106.554H59.9365V106.435H59.6097ZM58.9117 107.312H59.3871V107.787L58.9117 107.787V107.312ZM58.9117 108.025V108.5H59.3871V108.025H58.9117ZM59.6248 108.025V108.5H60.1V108.025H59.6248ZM60.1 107.787V107.312H59.6248V107.787H60.1ZM59.4467 107.475H59.5655V107.802H59.5355V108.01H59.5655V108.337H59.4467V108.01H59.4761V107.802H59.4467V107.475ZM59.0752 107.847V107.966H59.402V107.847H59.0752ZM59.6097 107.966V107.847H59.9365V107.966H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 105.9H61.0756V106.376L60.6001 106.376V105.9ZM60.6001 106.614V107.089H61.0756V106.614H60.6001ZM61.3133 106.614V107.089H61.7884V106.614H61.3133ZM61.7884 106.376V105.9H61.3133V106.376L61.7884 106.376ZM61.1351 106.064H61.2539V106.391H61.2239V106.599H61.2539V106.926H61.1351V106.599H61.1645V106.391H61.1351V106.064ZM60.7632 106.435V106.554H61.09V106.435H60.7632ZM61.2984 106.554V106.435H61.6252V106.554H61.2984ZM60.6001 107.312H61.0755V107.787H60.6001V107.312ZM60.6001 108.025V108.5H61.0755V108.025H60.6001ZM61.3133 108.025V108.5H61.7884V108.025H61.3133ZM61.7884 107.787V107.312H61.3133V107.787L61.7884 107.787ZM61.1351 107.475H61.2539V107.802H61.2239V108.01H61.2539V108.337H61.1351V108.01H61.1645V107.802H61.1351V107.475ZM60.7632 107.847V107.966H61.09V107.847H60.7632ZM61.2984 107.966V107.847H61.6252V107.966H61.2984ZM62.4872 105.9H62.0118V106.376L62.4872 106.376V105.9ZM62.0118 107.089V106.614H62.4872V107.089H62.0118ZM62.7249 107.089V106.614H63.2001V107.089H62.7249ZM63.2001 105.9V106.376L62.7249 106.376V105.9H63.2001ZM62.6656 106.064H62.5468V106.391H62.5762V106.599H62.5468V106.926H62.6656V106.599H62.6356V106.391H62.6656V106.064ZM62.1753 106.554V106.435H62.5021V106.554H62.1753ZM62.7098 106.435V106.554H63.0366V106.435H62.7098ZM62.0118 107.312H62.4872V107.787L62.0118 107.787V107.312ZM62.0118 108.025V108.5H62.4872V108.025H62.0118ZM62.7249 108.025V108.5H63.2001V108.025H62.7249ZM63.2001 107.787V107.312H62.7249V107.787H63.2001ZM62.5468 107.475H62.6656V107.802H62.6356V108.01H62.6656V108.337H62.5468V108.01H62.5762V107.802H62.5468V107.475ZM62.1753 107.847V107.966H62.5021V107.847H62.1753ZM62.7098 107.966V107.847H63.0366V107.966H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 109H51.7753V109.475L51.2998 109.475V109ZM51.2998 109.713V110.188H51.7753V109.713H51.2998ZM52.013 109.713V110.188H52.4881V109.713H52.013ZM52.4881 109.475V109H52.013V109.475L52.4881 109.475ZM51.8348 109.163H51.9536V109.49H51.9236V109.698H51.9536V110.025H51.8348V109.698H51.8642V109.49H51.8348V109.163ZM51.4629 109.535V109.654H51.7897V109.535H51.4629ZM51.9981 109.654V109.535H52.3249V109.654H51.9981ZM51.2998 110.412H51.7753V110.887H51.2998V110.412ZM51.2998 111.125V111.6H51.7753V111.125H51.2998ZM52.013 111.125V111.6H52.4881V111.125H52.013ZM52.4881 110.887V110.412H52.013V110.887L52.4881 110.887ZM51.8348 110.575H51.9536V110.902H51.9236V111.11H51.9536V111.437H51.8348V111.11H51.8642V110.902H51.8348V110.575ZM51.4629 110.946V111.065H51.7897V110.946H51.4629ZM51.9981 111.065V110.946H52.3249V111.065H51.9981ZM53.1869 109H52.7115V109.475L53.1869 109.475V109ZM52.7115 110.188V109.713H53.1869V110.188H52.7115ZM53.4246 110.188V109.713H53.8998V110.188H53.4246ZM53.8998 109V109.475L53.4246 109.475V109H53.8998ZM53.3653 109.163H53.2465V109.49H53.2759V109.698H53.2465V110.025H53.3653V109.698H53.3353V109.49H53.3653V109.163ZM52.875 109.654V109.535H53.2018V109.654H52.875ZM53.4095 109.535V109.654H53.7363V109.535H53.4095ZM52.7115 110.412H53.1869V110.887L52.7115 110.887V110.412ZM52.7115 111.125V111.6H53.1869V111.125H52.7115ZM53.4246 111.125V111.6H53.8998V111.125H53.4246ZM53.8998 110.887V110.412H53.4246V110.887H53.8998ZM53.2465 110.575H53.3653V110.902H53.3353V111.11H53.3653V111.437H53.2465V111.11H53.2759V110.902H53.2465V110.575ZM52.875 110.947V111.065H53.2018V110.947H52.875ZM53.4095 111.065V110.947H53.7363V111.065H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 109H54.8754V109.475L54.3999 109.475V109ZM54.3999 109.713V110.188H54.8754V109.713H54.3999ZM55.1131 109.713V110.188H55.5882V109.713H55.1131ZM55.5882 109.475V109H55.1131V109.475L55.5882 109.475ZM54.9349 109.163H55.0537V109.49H55.0237V109.698H55.0537V110.025H54.9349V109.698H54.9643V109.49H54.9349V109.163ZM54.563 109.535V109.654H54.8898V109.535H54.563ZM55.0982 109.654V109.535H55.425V109.654H55.0982ZM54.3999 110.412H54.8753V110.887H54.3999V110.412ZM54.3999 111.125V111.6H54.8753V111.125H54.3999ZM55.1131 111.125V111.6H55.5882V111.125H55.1131ZM55.5882 110.887V110.412H55.1131V110.887L55.5882 110.887ZM54.9349 110.575H55.0537V110.902H55.0237V111.11H55.0537V111.437H54.9349V111.11H54.9643V110.902H54.9349V110.575ZM54.563 110.946V111.065H54.8898V110.946H54.563ZM55.0982 111.065V110.946H55.425V111.065H55.0982ZM56.287 109H55.8116V109.475L56.287 109.475V109ZM55.8116 110.188V109.713H56.287V110.188H55.8116ZM56.5247 110.188V109.713H56.9999V110.188H56.5247ZM56.9999 109V109.475L56.5247 109.475V109H56.9999ZM56.4654 109.163H56.3466V109.49H56.376V109.698H56.3466V110.025H56.4654V109.698H56.4354V109.49H56.4654V109.163ZM55.9751 109.654V109.535H56.3019V109.654H55.9751ZM56.5096 109.535V109.654H56.8364V109.535H56.5096ZM55.8116 110.412H56.287V110.887L55.8116 110.887V110.412ZM55.8116 111.125V111.6H56.287V111.125H55.8116ZM56.5247 111.125V111.6H56.9999V111.125H56.5247ZM56.9999 110.887V110.412H56.5247V110.887H56.9999ZM56.3466 110.575H56.4654V110.902H56.4354V111.11H56.4654V111.437H56.3466V111.11H56.376V110.902H56.3466V110.575ZM55.9751 110.947V111.065H56.3019V110.947H55.9751ZM56.5096 111.065V110.947H56.8364V111.065H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 109H57.9755V109.475L57.5 109.475V109ZM57.5 109.713V110.188H57.9755V109.713H57.5ZM58.2132 109.713V110.188H58.6883V109.713H58.2132ZM58.6883 109.475V109H58.2132V109.475L58.6883 109.475ZM58.035 109.163H58.1538V109.49H58.1238V109.698H58.1538V110.025H58.035V109.698H58.0644V109.49H58.035V109.163ZM57.6631 109.535V109.654H57.9899V109.535H57.6631ZM58.1983 109.654V109.535H58.5251V109.654H58.1983ZM57.5 110.412H57.9754V110.887H57.5V110.412ZM57.5 111.125V111.6H57.9754V111.125H57.5ZM58.2132 111.125V111.6H58.6883V111.125H58.2132ZM58.6883 110.887V110.412H58.2132V110.887L58.6883 110.887ZM58.035 110.575H58.1538V110.902H58.1238V111.11H58.1538V111.437H58.035V111.11H58.0644V110.902H58.035V110.575ZM57.6631 110.946V111.065H57.9899V110.946H57.6631ZM58.1983 111.065V110.946H58.5251V111.065H58.1983ZM59.3871 109H58.9117V109.475L59.3871 109.475V109ZM58.9117 110.188V109.713H59.3871V110.188H58.9117ZM59.6248 110.188V109.713H60.1V110.188H59.6248ZM60.1 109V109.475L59.6248 109.475V109H60.1ZM59.5655 109.163H59.4467V109.49H59.4761V109.698H59.4467V110.025H59.5655V109.698H59.5355V109.49H59.5655V109.163ZM59.0752 109.654V109.535H59.402V109.654H59.0752ZM59.6097 109.535V109.654H59.9365V109.535H59.6097ZM58.9117 110.412H59.3871V110.887L58.9117 110.887V110.412ZM58.9117 111.125V111.6H59.3871V111.125H58.9117ZM59.6248 111.125V111.6H60.1V111.125H59.6248ZM60.1 110.887V110.412H59.6248V110.887H60.1ZM59.4467 110.575H59.5655V110.902H59.5355V111.11H59.5655V111.437H59.4467V111.11H59.4761V110.902H59.4467V110.575ZM59.0752 110.947V111.065H59.402V110.947H59.0752ZM59.6097 111.065V110.947H59.9365V111.065H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 109H61.0756V109.475L60.6001 109.475V109ZM60.6001 109.713V110.188H61.0756V109.713H60.6001ZM61.3133 109.713V110.188H61.7884V109.713H61.3133ZM61.7884 109.475V109H61.3133V109.475L61.7884 109.475ZM61.1351 109.163H61.2539V109.49H61.2239V109.698H61.2539V110.025H61.1351V109.698H61.1645V109.49H61.1351V109.163ZM60.7632 109.535V109.654H61.09V109.535H60.7632ZM61.2984 109.654V109.535H61.6252V109.654H61.2984ZM60.6001 110.412H61.0755V110.887H60.6001V110.412ZM60.6001 111.125V111.6H61.0755V111.125H60.6001ZM61.3133 111.125V111.6H61.7884V111.125H61.3133ZM61.7884 110.887V110.412H61.3133V110.887L61.7884 110.887ZM61.1351 110.575H61.2539V110.902H61.2239V111.11H61.2539V111.437H61.1351V111.11H61.1645V110.902H61.1351V110.575ZM60.7632 110.946V111.065H61.09V110.946H60.7632ZM61.2984 111.065V110.946H61.6252V111.065H61.2984ZM62.4872 109H62.0118V109.475L62.4872 109.475V109ZM62.0118 110.188V109.713H62.4872V110.188H62.0118ZM62.7249 110.188V109.713H63.2001V110.188H62.7249ZM63.2001 109V109.475L62.7249 109.475V109H63.2001ZM62.6656 109.163H62.5468V109.49H62.5762V109.698H62.5468V110.025H62.6656V109.698H62.6356V109.49H62.6656V109.163ZM62.1753 109.654V109.535H62.5021V109.654H62.1753ZM62.7098 109.535V109.654H63.0366V109.535H62.7098ZM62.0118 110.412H62.4872V110.887L62.0118 110.887V110.412ZM62.0118 111.125V111.6H62.4872V111.125H62.0118ZM62.7249 111.125V111.6H63.2001V111.125H62.7249ZM63.2001 110.887V110.412H62.7249V110.887H63.2001ZM62.5468 110.575H62.6656V110.902H62.6356V111.11H62.6656V111.437H62.5468V111.11H62.5762V110.902H62.5468V110.575ZM62.1753 110.947V111.065H62.5021V110.947H62.1753ZM62.7098 111.065V110.947H63.0366V111.065H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.2998 112.1H51.7753V112.575L51.2998 112.575V112.1ZM51.2998 112.813V113.288H51.7753V112.813H51.2998ZM52.013 112.813V113.288H52.4881V112.813H52.013ZM52.4881 112.575V112.1H52.013V112.575L52.4881 112.575ZM51.8348 112.263H51.9536V112.59H51.9236V112.798H51.9536V113.125H51.8348V112.798H51.8642V112.59H51.8348V112.263ZM51.4629 112.635V112.753H51.7897V112.635H51.4629ZM51.9981 112.753V112.635H52.3249V112.753H51.9981ZM51.2998 113.511H51.7753V113.987H51.2998V113.511ZM51.2998 114.224V114.699H51.7753V114.224H51.2998ZM52.013 114.224V114.699H52.4881V114.224H52.013ZM52.4881 113.987V113.511H52.013V113.987L52.4881 113.987ZM51.8348 113.675H51.9536V114.001H51.9236V114.209H51.9536V114.536H51.8348V114.209H51.8642V114.001H51.8348V113.675ZM51.4629 114.046V114.165H51.7897V114.046H51.4629ZM51.9981 114.165V114.046H52.3249V114.165H51.9981ZM53.1869 112.1H52.7115V112.575L53.1869 112.575V112.1ZM52.7115 113.288V112.813H53.1869V113.288H52.7115ZM53.4246 113.288V112.813H53.8998V113.288H53.4246ZM53.8998 112.1V112.575L53.4246 112.575V112.1H53.8998ZM53.3653 112.263H53.2465V112.59H53.2759V112.798H53.2465V113.125H53.3653V112.798H53.3353V112.59H53.3653V112.263ZM52.875 112.753V112.635H53.2018V112.753H52.875ZM53.4095 112.635V112.753H53.7363V112.635H53.4095ZM52.7115 113.511H53.1869V113.987L52.7115 113.987V113.511ZM52.7115 114.224V114.699H53.1869V114.224H52.7115ZM53.4246 114.224V114.699H53.8998V114.224H53.4246ZM53.8998 113.987V113.511H53.4246V113.987H53.8998ZM53.2465 113.675H53.3653V114.001H53.3353V114.21H53.3653V114.536H53.2465V114.21H53.2759V114.001H53.2465V113.675ZM52.875 114.046V114.165H53.2018V114.046H52.875ZM53.4095 114.165V114.046H53.7363V114.165H53.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.3999 112.1H54.8754V112.575L54.3999 112.575V112.1ZM54.3999 112.813V113.288H54.8754V112.813H54.3999ZM55.1131 112.813V113.288H55.5882V112.813H55.1131ZM55.5882 112.575V112.1H55.1131V112.575L55.5882 112.575ZM54.9349 112.263H55.0537V112.59H55.0237V112.798H55.0537V113.125H54.9349V112.798H54.9643V112.59H54.9349V112.263ZM54.563 112.635V112.753H54.8898V112.635H54.563ZM55.0982 112.753V112.635H55.425V112.753H55.0982ZM54.3999 113.511H54.8753V113.987H54.3999V113.511ZM54.3999 114.224V114.699H54.8753V114.224H54.3999ZM55.1131 114.224V114.699H55.5882V114.224H55.1131ZM55.5882 113.987V113.511H55.1131V113.987L55.5882 113.987ZM54.9349 113.675H55.0537V114.001H55.0237V114.209H55.0537V114.536H54.9349V114.209H54.9643V114.001H54.9349V113.675ZM54.563 114.046V114.165H54.8898V114.046H54.563ZM55.0982 114.165V114.046H55.425V114.165H55.0982ZM56.287 112.1H55.8116V112.575L56.287 112.575V112.1ZM55.8116 113.288V112.813H56.287V113.288H55.8116ZM56.5247 113.288V112.813H56.9999V113.288H56.5247ZM56.9999 112.1V112.575L56.5247 112.575V112.1H56.9999ZM56.4654 112.263H56.3466V112.59H56.376V112.798H56.3466V113.125H56.4654V112.798H56.4354V112.59H56.4654V112.263ZM55.9751 112.753V112.635H56.3019V112.753H55.9751ZM56.5096 112.635V112.753H56.8364V112.635H56.5096ZM55.8116 113.511H56.287V113.987L55.8116 113.987V113.511ZM55.8116 114.224V114.699H56.287V114.224H55.8116ZM56.5247 114.224V114.699H56.9999V114.224H56.5247ZM56.9999 113.987V113.511H56.5247V113.987H56.9999ZM56.3466 113.675H56.4654V114.001H56.4354V114.21H56.4654V114.536H56.3466V114.21H56.376V114.001H56.3466V113.675ZM55.9751 114.046V114.165H56.3019V114.046H55.9751ZM56.5096 114.165V114.046H56.8364V114.165H56.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.5 112.1H57.9755V112.575L57.5 112.575V112.1ZM57.5 112.813V113.288H57.9755V112.813H57.5ZM58.2132 112.813V113.288H58.6883V112.813H58.2132ZM58.6883 112.575V112.1H58.2132V112.575L58.6883 112.575ZM58.035 112.263H58.1538V112.59H58.1238V112.798H58.1538V113.125H58.035V112.798H58.0644V112.59H58.035V112.263ZM57.6631 112.635V112.753H57.9899V112.635H57.6631ZM58.1983 112.753V112.635H58.5251V112.753H58.1983ZM57.5 113.511H57.9754V113.987H57.5V113.511ZM57.5 114.224V114.699H57.9754V114.224H57.5ZM58.2132 114.224V114.699H58.6883V114.224H58.2132ZM58.6883 113.987V113.511H58.2132V113.987L58.6883 113.987ZM58.035 113.675H58.1538V114.001H58.1238V114.209H58.1538V114.536H58.035V114.209H58.0644V114.001H58.035V113.675ZM57.6631 114.046V114.165H57.9899V114.046H57.6631ZM58.1983 114.165V114.046H58.5251V114.165H58.1983ZM59.3871 112.1H58.9117V112.575L59.3871 112.575V112.1ZM58.9117 113.288V112.813H59.3871V113.288H58.9117ZM59.6248 113.288V112.813H60.1V113.288H59.6248ZM60.1 112.1V112.575L59.6248 112.575V112.1H60.1ZM59.5655 112.263H59.4467V112.59H59.4761V112.798H59.4467V113.125H59.5655V112.798H59.5355V112.59H59.5655V112.263ZM59.0752 112.753V112.635H59.402V112.753H59.0752ZM59.6097 112.635V112.753H59.9365V112.635H59.6097ZM58.9117 113.511H59.3871V113.987L58.9117 113.987V113.511ZM58.9117 114.224V114.699H59.3871V114.224H58.9117ZM59.6248 114.224V114.699H60.1V114.224H59.6248ZM60.1 113.987V113.511H59.6248V113.987H60.1ZM59.4467 113.675H59.5655V114.001H59.5355V114.21H59.5655V114.536H59.4467V114.21H59.4761V114.001H59.4467V113.675ZM59.0752 114.046V114.165H59.402V114.046H59.0752ZM59.6097 114.165V114.046H59.9365V114.165H59.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.6001 112.1H61.0756V112.575L60.6001 112.575V112.1ZM60.6001 112.813V113.288H61.0756V112.813H60.6001ZM61.3133 112.813V113.288H61.7884V112.813H61.3133ZM61.7884 112.575V112.1H61.3133V112.575L61.7884 112.575ZM61.1351 112.263H61.2539V112.59H61.2239V112.798H61.2539V113.125H61.1351V112.798H61.1645V112.59H61.1351V112.263ZM60.7632 112.635V112.753H61.09V112.635H60.7632ZM61.2984 112.753V112.635H61.6252V112.753H61.2984ZM60.6001 113.511H61.0755V113.987H60.6001V113.511ZM60.6001 114.224V114.699H61.0755V114.224H60.6001ZM61.3133 114.224V114.699H61.7884V114.224H61.3133ZM61.7884 113.987V113.511H61.3133V113.987L61.7884 113.987ZM61.1351 113.675H61.2539V114.001H61.2239V114.209H61.2539V114.536H61.1351V114.209H61.1645V114.001H61.1351V113.675ZM60.7632 114.046V114.165H61.09V114.046H60.7632ZM61.2984 114.165V114.046H61.6252V114.165H61.2984ZM62.4872 112.1H62.0118V112.575L62.4872 112.575V112.1ZM62.0118 113.288V112.813H62.4872V113.288H62.0118ZM62.7249 113.288V112.813H63.2001V113.288H62.7249ZM63.2001 112.1V112.575L62.7249 112.575V112.1H63.2001ZM62.6656 112.263H62.5468V112.59H62.5762V112.798H62.5468V113.125H62.6656V112.798H62.6356V112.59H62.6656V112.263ZM62.1753 112.753V112.635H62.5021V112.753H62.1753ZM62.7098 112.635V112.753H63.0366V112.635H62.7098ZM62.0118 113.511H62.4872V113.987L62.0118 113.987V113.511ZM62.0118 114.224V114.699H62.4872V114.224H62.0118ZM62.7249 114.224V114.699H63.2001V114.224H62.7249ZM63.2001 113.987V113.511H62.7249V113.987H63.2001ZM62.5468 113.675H62.6656V114.001H62.6356V114.21H62.6656V114.536H62.5468V114.21H62.5762V114.001H62.5468V113.675ZM62.1753 114.046V114.165H62.5021V114.046H62.1753ZM62.7098 114.165V114.046H63.0366V114.165H62.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 102.8H64.2753V103.275L63.7998 103.275V102.8ZM63.7998 103.513V103.988H64.2753V103.513H63.7998ZM64.513 103.513V103.988H64.9881V103.513H64.513ZM64.9881 103.275V102.8H64.513V103.275L64.9881 103.275ZM64.3348 102.963H64.4536V103.29H64.4236V103.498H64.4536V103.825H64.3348V103.498H64.3642V103.29H64.3348V102.963ZM63.9629 103.335V103.454H64.2897V103.335H63.9629ZM64.4981 103.454V103.335H64.8249V103.454H64.4981ZM63.7998 104.211H64.2753V104.687H63.7998V104.211ZM63.7998 104.925V105.4H64.2753V104.925H63.7998ZM64.513 104.925V105.4H64.9881V104.925H64.513ZM64.9881 104.687V104.211H64.513V104.687H64.9881ZM64.3348 104.375H64.4536V104.702H64.4236V104.91H64.4536V105.236H64.3348V104.91H64.3642V104.702H64.3348V104.375ZM63.9629 104.746V104.865H64.2897V104.746H63.9629ZM64.4981 104.865V104.746H64.8249V104.865H64.4981ZM65.6869 102.8H65.2115V103.275L65.6869 103.275V102.8ZM65.2115 103.988V103.513H65.6869V103.988H65.2115ZM65.9246 103.988V103.513H66.3998V103.988H65.9246ZM66.3998 102.8V103.275L65.9246 103.275V102.8H66.3998ZM65.8653 102.963H65.7465V103.29H65.7759V103.498H65.7465V103.825H65.8653V103.498H65.8353V103.29H65.8653V102.963ZM65.375 103.454V103.335H65.7018V103.454H65.375ZM65.9095 103.335V103.454H66.2363V103.335H65.9095ZM65.2115 104.211H65.6869V104.687H65.2115V104.211ZM65.2115 104.925V105.4H65.6869V104.925H65.2115ZM65.9246 104.925V105.4H66.3998V104.925H65.9246ZM66.3998 104.687V104.211H65.9246V104.687H66.3998ZM65.7465 104.375H65.8653V104.702H65.8353V104.91H65.8653V105.236H65.7465V104.91H65.7759V104.702H65.7465V104.375ZM65.375 104.746V104.865H65.7018V104.746H65.375ZM65.9095 104.865V104.746H66.2363V104.865H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 102.8H67.3754V103.275L66.8999 103.275V102.8ZM66.8999 103.513V103.988H67.3754V103.513H66.8999ZM67.6131 103.513V103.988H68.0882V103.513H67.6131ZM68.0882 103.275V102.8H67.6131V103.275L68.0882 103.275ZM67.4349 102.963H67.5537V103.29H67.5237V103.498H67.5537V103.825H67.4349V103.498H67.4643V103.29H67.4349V102.963ZM67.063 103.335V103.454H67.3898V103.335H67.063ZM67.5982 103.454V103.335H67.925V103.454H67.5982ZM66.8999 104.211H67.3753V104.687H66.8999V104.211ZM66.8999 104.925V105.4H67.3753V104.925H66.8999ZM67.6131 104.925V105.4H68.0882V104.925H67.6131ZM68.0882 104.687V104.211H67.6131V104.687L68.0882 104.687ZM67.4349 104.375H67.5537V104.702H67.5237V104.91H67.5537V105.236H67.4349V104.91H67.4643V104.702H67.4349V104.375ZM67.063 104.746V104.865H67.3898V104.746H67.063ZM67.5982 104.865V104.746H67.925V104.865H67.5982ZM68.787 102.8H68.3116V103.275L68.787 103.275V102.8ZM68.3116 103.988V103.513H68.787V103.988H68.3116ZM69.0247 103.988V103.513H69.4999V103.988H69.0247ZM69.4999 102.8V103.275L69.0247 103.275V102.8H69.4999ZM68.9654 102.963H68.8466V103.29H68.876V103.498H68.8466V103.825H68.9654V103.498H68.9354V103.29H68.9654V102.963ZM68.4751 103.454V103.335H68.8019V103.454H68.4751ZM69.0096 103.335V103.454H69.3364V103.335H69.0096ZM68.3116 104.211H68.787V104.687L68.3116 104.687V104.211ZM68.3116 104.925V105.4H68.787V104.925H68.3116ZM69.0247 104.925V105.4H69.4999V104.925H69.0247ZM69.4999 104.687V104.211H69.0247V104.687H69.4999ZM68.8466 104.375H68.9654V104.702H68.9354V104.91H68.9654V105.236H68.8466V104.91H68.876V104.702H68.8466V104.375ZM68.4751 104.746V104.865H68.8019V104.746H68.4751ZM69.0096 104.865V104.746H69.3364V104.865H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 102.8H70.4755V103.275L70 103.275V102.8ZM70 103.513V103.988H70.4755V103.513H70ZM70.7132 103.513V103.988H71.1883V103.513H70.7132ZM71.1883 103.275V102.8H70.7132V103.275L71.1883 103.275ZM70.535 102.963H70.6538V103.29H70.6238V103.498H70.6538V103.825H70.535V103.498H70.5644V103.29H70.535V102.963ZM70.1631 103.335V103.454H70.4899V103.335H70.1631ZM70.6983 103.454V103.335H71.0251V103.454H70.6983ZM70 104.211H70.4754V104.687H70V104.211ZM70 104.925V105.4H70.4754V104.925H70ZM70.7132 104.925V105.4H71.1883V104.925H70.7132ZM71.1883 104.687V104.211H70.7132V104.687L71.1883 104.687ZM70.535 104.375H70.6538V104.702H70.6238V104.91H70.6538V105.236H70.535V104.91H70.5644V104.702H70.535V104.375ZM70.1631 104.746V104.865H70.4899V104.746H70.1631ZM70.6983 104.865V104.746H71.0251V104.865H70.6983ZM71.8871 102.8H71.4117V103.275L71.8871 103.275V102.8ZM71.4117 103.988V103.513H71.8871V103.988H71.4117ZM72.1248 103.988V103.513H72.6V103.988H72.1248ZM72.6 102.8V103.275L72.1248 103.275V102.8H72.6ZM72.0655 102.963H71.9467V103.29H71.9761V103.498H71.9467V103.825H72.0655V103.498H72.0355V103.29H72.0655V102.963ZM71.5752 103.454V103.335H71.902V103.454H71.5752ZM72.1097 103.335V103.454H72.4365V103.335H72.1097ZM71.4117 104.211H71.8871V104.687L71.4117 104.687V104.211ZM71.4117 104.925V105.4H71.8871V104.925H71.4117ZM72.1248 104.925V105.4H72.6V104.925H72.1248ZM72.6 104.687V104.211H72.1248V104.687H72.6ZM71.9467 104.375H72.0655V104.702H72.0355V104.91H72.0655V105.236H71.9467V104.91H71.9761V104.702H71.9467V104.375ZM71.5752 104.746V104.865H71.902V104.746H71.5752ZM72.1097 104.865V104.746H72.4365V104.865H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 102.8H73.5756V103.275L73.1001 103.275V102.8ZM73.1001 103.513V103.988H73.5756V103.513H73.1001ZM73.8133 103.513V103.988H74.2884V103.513H73.8133ZM74.2884 103.275V102.8H73.8133V103.275L74.2884 103.275ZM73.6351 102.963H73.7539V103.29H73.7239V103.498H73.7539V103.825H73.6351V103.498H73.6645V103.29H73.6351V102.963ZM73.2632 103.335V103.454H73.59V103.335H73.2632ZM73.7984 103.454V103.335H74.1252V103.454H73.7984ZM73.1001 104.211H73.5755V104.687H73.1001V104.211ZM73.1001 104.925V105.4H73.5755V104.925H73.1001ZM73.8133 104.925V105.4H74.2884V104.925H73.8133ZM74.2884 104.687V104.211H73.8133V104.687L74.2884 104.687ZM73.6351 104.375H73.7539V104.702H73.7239V104.91H73.7539V105.236H73.6351V104.91H73.6645V104.702H73.6351V104.375ZM73.2632 104.746V104.865H73.59V104.746H73.2632ZM73.7984 104.865V104.746H74.1252V104.865H73.7984ZM74.9872 102.8H74.5118V103.275L74.9872 103.275V102.8ZM74.5118 103.988V103.513H74.9872V103.988H74.5118ZM75.2249 103.988V103.513H75.7001V103.988H75.2249ZM75.7001 102.8V103.275L75.2249 103.275V102.8H75.7001ZM75.1656 102.963H75.0468V103.29H75.0762V103.498H75.0468V103.825H75.1656V103.498H75.1356V103.29H75.1656V102.963ZM74.6753 103.454V103.335H75.0021V103.454H74.6753ZM75.2098 103.335V103.454H75.5366V103.335H75.2098ZM74.5118 104.211H74.9872V104.687L74.5118 104.687V104.211ZM74.5118 104.925V105.4H74.9872V104.925H74.5118ZM75.2249 104.925V105.4H75.7001V104.925H75.2249ZM75.7001 104.687V104.211H75.2249V104.687H75.7001ZM75.0468 104.375H75.1656V104.702H75.1356V104.91H75.1656V105.236H75.0468V104.91H75.0762V104.702H75.0468V104.375ZM74.6753 104.746V104.865H75.0021V104.746H74.6753ZM75.2098 104.865V104.746H75.5366V104.865H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 105.9H64.2753V106.376L63.7998 106.376V105.9ZM63.7998 106.614V107.089H64.2753V106.614H63.7998ZM64.513 106.614V107.089H64.9881V106.614H64.513ZM64.9881 106.376V105.9H64.513V106.376L64.9881 106.376ZM64.3348 106.064H64.4536V106.391H64.4236V106.599H64.4536V106.926H64.3348V106.599H64.3642V106.391H64.3348V106.064ZM63.9629 106.435V106.554H64.2897V106.435H63.9629ZM64.4981 106.554V106.435H64.8249V106.554H64.4981ZM63.7998 107.312H64.2753V107.787H63.7998V107.312ZM63.7998 108.025V108.5H64.2753V108.025H63.7998ZM64.513 108.025V108.5H64.9881V108.025H64.513ZM64.9881 107.787V107.312H64.513V107.787L64.9881 107.787ZM64.3348 107.475H64.4536V107.802H64.4236V108.01H64.4536V108.337H64.3348V108.01H64.3642V107.802H64.3348V107.475ZM63.9629 107.847V107.966H64.2897V107.847H63.9629ZM64.4981 107.966V107.847H64.8249V107.966H64.4981ZM65.6869 105.9H65.2115V106.376L65.6869 106.376V105.9ZM65.2115 107.089V106.614H65.6869V107.089H65.2115ZM65.9246 107.089V106.614H66.3998V107.089H65.9246ZM66.3998 105.9V106.376L65.9246 106.376V105.9H66.3998ZM65.8653 106.064H65.7465V106.391H65.7759V106.599H65.7465V106.926H65.8653V106.599H65.8353V106.391H65.8653V106.064ZM65.375 106.554V106.435H65.7018V106.554H65.375ZM65.9095 106.435V106.554H66.2363V106.435H65.9095ZM65.2115 107.312H65.6869V107.787L65.2115 107.787V107.312ZM65.2115 108.025V108.5H65.6869V108.025H65.2115ZM65.9246 108.025V108.5H66.3998V108.025H65.9246ZM66.3998 107.787V107.312H65.9246V107.787H66.3998ZM65.7465 107.475H65.8653V107.802H65.8353V108.01H65.8653V108.337H65.7465V108.01H65.7759V107.802H65.7465V107.475ZM65.375 107.847V107.966H65.7018V107.847H65.375ZM65.9095 107.966V107.847H66.2363V107.966H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 105.9H67.3754V106.376L66.8999 106.376V105.9ZM66.8999 106.614V107.089H67.3754V106.614H66.8999ZM67.6131 106.614V107.089H68.0882V106.614H67.6131ZM68.0882 106.376V105.9H67.6131V106.376L68.0882 106.376ZM67.4349 106.064H67.5537V106.391H67.5237V106.599H67.5537V106.926H67.4349V106.599H67.4643V106.391H67.4349V106.064ZM67.063 106.435V106.554H67.3898V106.435H67.063ZM67.5982 106.554V106.435H67.925V106.554H67.5982ZM66.8999 107.312H67.3753V107.787H66.8999V107.312ZM66.8999 108.025V108.5H67.3753V108.025H66.8999ZM67.6131 108.025V108.5H68.0882V108.025H67.6131ZM68.0882 107.787V107.312H67.6131V107.787L68.0882 107.787ZM67.4349 107.475H67.5537V107.802H67.5237V108.01H67.5537V108.337H67.4349V108.01H67.4643V107.802H67.4349V107.475ZM67.063 107.847V107.966H67.3898V107.847H67.063ZM67.5982 107.966V107.847H67.925V107.966H67.5982ZM68.787 105.9H68.3116V106.376L68.787 106.376V105.9ZM68.3116 107.089V106.614H68.787V107.089H68.3116ZM69.0247 107.089V106.614H69.4999V107.089H69.0247ZM69.4999 105.9V106.376L69.0247 106.376V105.9H69.4999ZM68.9654 106.064H68.8466V106.391H68.876V106.599H68.8466V106.926H68.9654V106.599H68.9354V106.391H68.9654V106.064ZM68.4751 106.554V106.435H68.8019V106.554H68.4751ZM69.0096 106.435V106.554H69.3364V106.435H69.0096ZM68.3116 107.312H68.787V107.787L68.3116 107.787V107.312ZM68.3116 108.025V108.5H68.787V108.025H68.3116ZM69.0247 108.025V108.5H69.4999V108.025H69.0247ZM69.4999 107.787V107.312H69.0247V107.787H69.4999ZM68.8466 107.475H68.9654V107.802H68.9354V108.01H68.9654V108.337H68.8466V108.01H68.876V107.802H68.8466V107.475ZM68.4751 107.847V107.966H68.8019V107.847H68.4751ZM69.0096 107.966V107.847H69.3364V107.966H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 105.9H70.4755V106.376L70 106.376V105.9ZM70 106.614V107.089H70.4755V106.614H70ZM70.7132 106.614V107.089H71.1883V106.614H70.7132ZM71.1883 106.376V105.9H70.7132V106.376L71.1883 106.376ZM70.535 106.064H70.6538V106.391H70.6238V106.599H70.6538V106.926H70.535V106.599H70.5644V106.391H70.535V106.064ZM70.1631 106.435V106.554H70.4899V106.435H70.1631ZM70.6983 106.554V106.435H71.0251V106.554H70.6983ZM70 107.312H70.4754V107.787H70V107.312ZM70 108.025V108.5H70.4754V108.025H70ZM70.7132 108.025V108.5H71.1883V108.025H70.7132ZM71.1883 107.787V107.312H70.7132V107.787L71.1883 107.787ZM70.535 107.475H70.6538V107.802H70.6238V108.01H70.6538V108.337H70.535V108.01H70.5644V107.802H70.535V107.475ZM70.1631 107.847V107.966H70.4899V107.847H70.1631ZM70.6983 107.966V107.847H71.0251V107.966H70.6983ZM71.8871 105.9H71.4117V106.376L71.8871 106.376V105.9ZM71.4117 107.089V106.614H71.8871V107.089H71.4117ZM72.1248 107.089V106.614H72.6V107.089H72.1248ZM72.6 105.9V106.376L72.1248 106.376V105.9H72.6ZM72.0655 106.064H71.9467V106.391H71.9761V106.599H71.9467V106.926H72.0655V106.599H72.0355V106.391H72.0655V106.064ZM71.5752 106.554V106.435H71.902V106.554H71.5752ZM72.1097 106.435V106.554H72.4365V106.435H72.1097ZM71.4117 107.312H71.8871V107.787L71.4117 107.787V107.312ZM71.4117 108.025V108.5H71.8871V108.025H71.4117ZM72.1248 108.025V108.5H72.6V108.025H72.1248ZM72.6 107.787V107.312H72.1248V107.787H72.6ZM71.9467 107.475H72.0655V107.802H72.0355V108.01H72.0655V108.337H71.9467V108.01H71.9761V107.802H71.9467V107.475ZM71.5752 107.847V107.966H71.902V107.847H71.5752ZM72.1097 107.966V107.847H72.4365V107.966H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 105.9H73.5756V106.376L73.1001 106.376V105.9ZM73.1001 106.614V107.089H73.5756V106.614H73.1001ZM73.8133 106.614V107.089H74.2884V106.614H73.8133ZM74.2884 106.376V105.9H73.8133V106.376L74.2884 106.376ZM73.6351 106.064H73.7539V106.391H73.7239V106.599H73.7539V106.926H73.6351V106.599H73.6645V106.391H73.6351V106.064ZM73.2632 106.435V106.554H73.59V106.435H73.2632ZM73.7984 106.554V106.435H74.1252V106.554H73.7984ZM73.1001 107.312H73.5755V107.787H73.1001V107.312ZM73.1001 108.025V108.5H73.5755V108.025H73.1001ZM73.8133 108.025V108.5H74.2884V108.025H73.8133ZM74.2884 107.787V107.312H73.8133V107.787L74.2884 107.787ZM73.6351 107.475H73.7539V107.802H73.7239V108.01H73.7539V108.337H73.6351V108.01H73.6645V107.802H73.6351V107.475ZM73.2632 107.847V107.966H73.59V107.847H73.2632ZM73.7984 107.966V107.847H74.1252V107.966H73.7984ZM74.9872 105.9H74.5118V106.376L74.9872 106.376V105.9ZM74.5118 107.089V106.614H74.9872V107.089H74.5118ZM75.2249 107.089V106.614H75.7001V107.089H75.2249ZM75.7001 105.9V106.376L75.2249 106.376V105.9H75.7001ZM75.1656 106.064H75.0468V106.391H75.0762V106.599H75.0468V106.926H75.1656V106.599H75.1356V106.391H75.1656V106.064ZM74.6753 106.554V106.435H75.0021V106.554H74.6753ZM75.2098 106.435V106.554H75.5366V106.435H75.2098ZM74.5118 107.312H74.9872V107.787L74.5118 107.787V107.312ZM74.5118 108.025V108.5H74.9872V108.025H74.5118ZM75.2249 108.025V108.5H75.7001V108.025H75.2249ZM75.7001 107.787V107.312H75.2249V107.787H75.7001ZM75.0468 107.475H75.1656V107.802H75.1356V108.01H75.1656V108.337H75.0468V108.01H75.0762V107.802H75.0468V107.475ZM74.6753 107.847V107.966H75.0021V107.847H74.6753ZM75.2098 107.966V107.847H75.5366V107.966H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 109H64.2753V109.475L63.7998 109.475V109ZM63.7998 109.713V110.188H64.2753V109.713H63.7998ZM64.513 109.713V110.188H64.9881V109.713H64.513ZM64.9881 109.475V109H64.513V109.475L64.9881 109.475ZM64.3348 109.163H64.4536V109.49H64.4236V109.698H64.4536V110.025H64.3348V109.698H64.3642V109.49H64.3348V109.163ZM63.9629 109.535V109.654H64.2897V109.535H63.9629ZM64.4981 109.654V109.535H64.8249V109.654H64.4981ZM63.7998 110.412H64.2753V110.887H63.7998V110.412ZM63.7998 111.125V111.6H64.2753V111.125H63.7998ZM64.513 111.125V111.6H64.9881V111.125H64.513ZM64.9881 110.887V110.412H64.513V110.887L64.9881 110.887ZM64.3348 110.575H64.4536V110.902H64.4236V111.11H64.4536V111.437H64.3348V111.11H64.3642V110.902H64.3348V110.575ZM63.9629 110.946V111.065H64.2897V110.946H63.9629ZM64.4981 111.065V110.946H64.8249V111.065H64.4981ZM65.6869 109H65.2115V109.475L65.6869 109.475V109ZM65.2115 110.188V109.713H65.6869V110.188H65.2115ZM65.9246 110.188V109.713H66.3998V110.188H65.9246ZM66.3998 109V109.475L65.9246 109.475V109H66.3998ZM65.8653 109.163H65.7465V109.49H65.7759V109.698H65.7465V110.025H65.8653V109.698H65.8353V109.49H65.8653V109.163ZM65.375 109.654V109.535H65.7018V109.654H65.375ZM65.9095 109.535V109.654H66.2363V109.535H65.9095ZM65.2115 110.412H65.6869V110.887L65.2115 110.887V110.412ZM65.2115 111.125V111.6H65.6869V111.125H65.2115ZM65.9246 111.125V111.6H66.3998V111.125H65.9246ZM66.3998 110.887V110.412H65.9246V110.887H66.3998ZM65.7465 110.575H65.8653V110.902H65.8353V111.11H65.8653V111.437H65.7465V111.11H65.7759V110.902H65.7465V110.575ZM65.375 110.947V111.065H65.7018V110.947H65.375ZM65.9095 111.065V110.947H66.2363V111.065H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 109H67.3754V109.475L66.8999 109.475V109ZM66.8999 109.713V110.188H67.3754V109.713H66.8999ZM67.6131 109.713V110.188H68.0882V109.713H67.6131ZM68.0882 109.475V109H67.6131V109.475L68.0882 109.475ZM67.4349 109.163H67.5537V109.49H67.5237V109.698H67.5537V110.025H67.4349V109.698H67.4643V109.49H67.4349V109.163ZM67.063 109.535V109.654H67.3898V109.535H67.063ZM67.5982 109.654V109.535H67.925V109.654H67.5982ZM66.8999 110.412H67.3753V110.887H66.8999V110.412ZM66.8999 111.125V111.6H67.3753V111.125H66.8999ZM67.6131 111.125V111.6H68.0882V111.125H67.6131ZM68.0882 110.887V110.412H67.6131V110.887L68.0882 110.887ZM67.4349 110.575H67.5537V110.902H67.5237V111.11H67.5537V111.437H67.4349V111.11H67.4643V110.902H67.4349V110.575ZM67.063 110.946V111.065H67.3898V110.946H67.063ZM67.5982 111.065V110.946H67.925V111.065H67.5982ZM68.787 109H68.3116V109.475L68.787 109.475V109ZM68.3116 110.188V109.713H68.787V110.188H68.3116ZM69.0247 110.188V109.713H69.4999V110.188H69.0247ZM69.4999 109V109.475L69.0247 109.475V109H69.4999ZM68.9654 109.163H68.8466V109.49H68.876V109.698H68.8466V110.025H68.9654V109.698H68.9354V109.49H68.9654V109.163ZM68.4751 109.654V109.535H68.8019V109.654H68.4751ZM69.0096 109.535V109.654H69.3364V109.535H69.0096ZM68.3116 110.412H68.787V110.887L68.3116 110.887V110.412ZM68.3116 111.125V111.6H68.787V111.125H68.3116ZM69.0247 111.125V111.6H69.4999V111.125H69.0247ZM69.4999 110.887V110.412H69.0247V110.887H69.4999ZM68.8466 110.575H68.9654V110.902H68.9354V111.11H68.9654V111.437H68.8466V111.11H68.876V110.902H68.8466V110.575ZM68.4751 110.947V111.065H68.8019V110.947H68.4751ZM69.0096 111.065V110.947H69.3364V111.065H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 109H70.4755V109.475L70 109.475V109ZM70 109.713V110.188H70.4755V109.713H70ZM70.7132 109.713V110.188H71.1883V109.713H70.7132ZM71.1883 109.475V109H70.7132V109.475L71.1883 109.475ZM70.535 109.163H70.6538V109.49H70.6238V109.698H70.6538V110.025H70.535V109.698H70.5644V109.49H70.535V109.163ZM70.1631 109.535V109.654H70.4899V109.535H70.1631ZM70.6983 109.654V109.535H71.0251V109.654H70.6983ZM70 110.412H70.4754V110.887H70V110.412ZM70 111.125V111.6H70.4754V111.125H70ZM70.7132 111.125V111.6H71.1883V111.125H70.7132ZM71.1883 110.887V110.412H70.7132V110.887L71.1883 110.887ZM70.535 110.575H70.6538V110.902H70.6238V111.11H70.6538V111.437H70.535V111.11H70.5644V110.902H70.535V110.575ZM70.1631 110.946V111.065H70.4899V110.946H70.1631ZM70.6983 111.065V110.946H71.0251V111.065H70.6983ZM71.8871 109H71.4117V109.475L71.8871 109.475V109ZM71.4117 110.188V109.713H71.8871V110.188H71.4117ZM72.1248 110.188V109.713H72.6V110.188H72.1248ZM72.6 109V109.475L72.1248 109.475V109H72.6ZM72.0655 109.163H71.9467V109.49H71.9761V109.698H71.9467V110.025H72.0655V109.698H72.0355V109.49H72.0655V109.163ZM71.5752 109.654V109.535H71.902V109.654H71.5752ZM72.1097 109.535V109.654H72.4365V109.535H72.1097ZM71.4117 110.412H71.8871V110.887L71.4117 110.887V110.412ZM71.4117 111.125V111.6H71.8871V111.125H71.4117ZM72.1248 111.125V111.6H72.6V111.125H72.1248ZM72.6 110.887V110.412H72.1248V110.887H72.6ZM71.9467 110.575H72.0655V110.902H72.0355V111.11H72.0655V111.437H71.9467V111.11H71.9761V110.902H71.9467V110.575ZM71.5752 110.947V111.065H71.902V110.947H71.5752ZM72.1097 111.065V110.947H72.4365V111.065H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 109H73.5756V109.475L73.1001 109.475V109ZM73.1001 109.713V110.188H73.5756V109.713H73.1001ZM73.8133 109.713V110.188H74.2884V109.713H73.8133ZM74.2884 109.475V109H73.8133V109.475L74.2884 109.475ZM73.6351 109.163H73.7539V109.49H73.7239V109.698H73.7539V110.025H73.6351V109.698H73.6645V109.49H73.6351V109.163ZM73.2632 109.535V109.654H73.59V109.535H73.2632ZM73.7984 109.654V109.535H74.1252V109.654H73.7984ZM73.1001 110.412H73.5755V110.887H73.1001V110.412ZM73.1001 111.125V111.6H73.5755V111.125H73.1001ZM73.8133 111.125V111.6H74.2884V111.125H73.8133ZM74.2884 110.887V110.412H73.8133V110.887L74.2884 110.887ZM73.6351 110.575H73.7539V110.902H73.7239V111.11H73.7539V111.437H73.6351V111.11H73.6645V110.902H73.6351V110.575ZM73.2632 110.946V111.065H73.59V110.946H73.2632ZM73.7984 111.065V110.946H74.1252V111.065H73.7984ZM74.9872 109H74.5118V109.475L74.9872 109.475V109ZM74.5118 110.188V109.713H74.9872V110.188H74.5118ZM75.2249 110.188V109.713H75.7001V110.188H75.2249ZM75.7001 109V109.475L75.2249 109.475V109H75.7001ZM75.1656 109.163H75.0468V109.49H75.0762V109.698H75.0468V110.025H75.1656V109.698H75.1356V109.49H75.1656V109.163ZM74.6753 109.654V109.535H75.0021V109.654H74.6753ZM75.2098 109.535V109.654H75.5366V109.535H75.2098ZM74.5118 110.412H74.9872V110.887L74.5118 110.887V110.412ZM74.5118 111.125V111.6H74.9872V111.125H74.5118ZM75.2249 111.125V111.6H75.7001V111.125H75.2249ZM75.7001 110.887V110.412H75.2249V110.887H75.7001ZM75.0468 110.575H75.1656V110.902H75.1356V111.11H75.1656V111.437H75.0468V111.11H75.0762V110.902H75.0468V110.575ZM74.6753 110.947V111.065H75.0021V110.947H74.6753ZM75.2098 111.065V110.947H75.5366V111.065H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7998 112.1H64.2753V112.575L63.7998 112.575V112.1ZM63.7998 112.813V113.288H64.2753V112.813H63.7998ZM64.513 112.813V113.288H64.9881V112.813H64.513ZM64.9881 112.575V112.1H64.513V112.575L64.9881 112.575ZM64.3348 112.263H64.4536V112.59H64.4236V112.798H64.4536V113.125H64.3348V112.798H64.3642V112.59H64.3348V112.263ZM63.9629 112.635V112.753H64.2897V112.635H63.9629ZM64.4981 112.753V112.635H64.8249V112.753H64.4981ZM63.7998 113.511H64.2753V113.987H63.7998V113.511ZM63.7998 114.224V114.699H64.2753V114.224H63.7998ZM64.513 114.224V114.699H64.9881V114.224H64.513ZM64.9881 113.987V113.511H64.513V113.987L64.9881 113.987ZM64.3348 113.675H64.4536V114.001H64.4236V114.209H64.4536V114.536H64.3348V114.209H64.3642V114.001H64.3348V113.675ZM63.9629 114.046V114.165H64.2897V114.046H63.9629ZM64.4981 114.165V114.046H64.8249V114.165H64.4981ZM65.6869 112.1H65.2115V112.575L65.6869 112.575V112.1ZM65.2115 113.288V112.813H65.6869V113.288H65.2115ZM65.9246 113.288V112.813H66.3998V113.288H65.9246ZM66.3998 112.1V112.575L65.9246 112.575V112.1H66.3998ZM65.8653 112.263H65.7465V112.59H65.7759V112.798H65.7465V113.125H65.8653V112.798H65.8353V112.59H65.8653V112.263ZM65.375 112.753V112.635H65.7018V112.753H65.375ZM65.9095 112.635V112.753H66.2363V112.635H65.9095ZM65.2115 113.511H65.6869V113.987L65.2115 113.987V113.511ZM65.2115 114.224V114.699H65.6869V114.224H65.2115ZM65.9246 114.224V114.699H66.3998V114.224H65.9246ZM66.3998 113.987V113.511H65.9246V113.987H66.3998ZM65.7465 113.675H65.8653V114.001H65.8353V114.21H65.8653V114.536H65.7465V114.21H65.7759V114.001H65.7465V113.675ZM65.375 114.046V114.165H65.7018V114.046H65.375ZM65.9095 114.165V114.046H66.2363V114.165H65.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.8999 112.1H67.3754V112.575L66.8999 112.575V112.1ZM66.8999 112.813V113.288H67.3754V112.813H66.8999ZM67.6131 112.813V113.288H68.0882V112.813H67.6131ZM68.0882 112.575V112.1H67.6131V112.575L68.0882 112.575ZM67.4349 112.263H67.5537V112.59H67.5237V112.798H67.5537V113.125H67.4349V112.798H67.4643V112.59H67.4349V112.263ZM67.063 112.635V112.753H67.3898V112.635H67.063ZM67.5982 112.753V112.635H67.925V112.753H67.5982ZM66.8999 113.511H67.3753V113.987H66.8999V113.511ZM66.8999 114.224V114.699H67.3753V114.224H66.8999ZM67.6131 114.224V114.699H68.0882V114.224H67.6131ZM68.0882 113.987V113.511H67.6131V113.987L68.0882 113.987ZM67.4349 113.675H67.5537V114.001H67.5237V114.209H67.5537V114.536H67.4349V114.209H67.4643V114.001H67.4349V113.675ZM67.063 114.046V114.165H67.3898V114.046H67.063ZM67.5982 114.165V114.046H67.925V114.165H67.5982ZM68.787 112.1H68.3116V112.575L68.787 112.575V112.1ZM68.3116 113.288V112.813H68.787V113.288H68.3116ZM69.0247 113.288V112.813H69.4999V113.288H69.0247ZM69.4999 112.1V112.575L69.0247 112.575V112.1H69.4999ZM68.9654 112.263H68.8466V112.59H68.876V112.798H68.8466V113.125H68.9654V112.798H68.9354V112.59H68.9654V112.263ZM68.4751 112.753V112.635H68.8019V112.753H68.4751ZM69.0096 112.635V112.753H69.3364V112.635H69.0096ZM68.3116 113.511H68.787V113.987L68.3116 113.987V113.511ZM68.3116 114.224V114.699H68.787V114.224H68.3116ZM69.0247 114.224V114.699H69.4999V114.224H69.0247ZM69.4999 113.987V113.511H69.0247V113.987H69.4999ZM68.8466 113.675H68.9654V114.001H68.9354V114.21H68.9654V114.536H68.8466V114.21H68.876V114.001H68.8466V113.675ZM68.4751 114.046V114.165H68.8019V114.046H68.4751ZM69.0096 114.165V114.046H69.3364V114.165H69.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70 112.1H70.4755V112.575L70 112.575V112.1ZM70 112.813V113.288H70.4755V112.813H70ZM70.7132 112.813V113.288H71.1883V112.813H70.7132ZM71.1883 112.575V112.1H70.7132V112.575L71.1883 112.575ZM70.535 112.263H70.6538V112.59H70.6238V112.798H70.6538V113.125H70.535V112.798H70.5644V112.59H70.535V112.263ZM70.1631 112.635V112.753H70.4899V112.635H70.1631ZM70.6983 112.753V112.635H71.0251V112.753H70.6983ZM70 113.511H70.4754V113.987H70V113.511ZM70 114.224V114.699H70.4754V114.224H70ZM70.7132 114.224V114.699H71.1883V114.224H70.7132ZM71.1883 113.987V113.511H70.7132V113.987L71.1883 113.987ZM70.535 113.675H70.6538V114.001H70.6238V114.209H70.6538V114.536H70.535V114.209H70.5644V114.001H70.535V113.675ZM70.1631 114.046V114.165H70.4899V114.046H70.1631ZM70.6983 114.165V114.046H71.0251V114.165H70.6983ZM71.8871 112.1H71.4117V112.575L71.8871 112.575V112.1ZM71.4117 113.288V112.813H71.8871V113.288H71.4117ZM72.1248 113.288V112.813H72.6V113.288H72.1248ZM72.6 112.1V112.575L72.1248 112.575V112.1H72.6ZM72.0655 112.263H71.9467V112.59H71.9761V112.798H71.9467V113.125H72.0655V112.798H72.0355V112.59H72.0655V112.263ZM71.5752 112.753V112.635H71.902V112.753H71.5752ZM72.1097 112.635V112.753H72.4365V112.635H72.1097ZM71.4117 113.511H71.8871V113.987L71.4117 113.987V113.511ZM71.4117 114.224V114.699H71.8871V114.224H71.4117ZM72.1248 114.224V114.699H72.6V114.224H72.1248ZM72.6 113.987V113.511H72.1248V113.987H72.6ZM71.9467 113.675H72.0655V114.001H72.0355V114.21H72.0655V114.536H71.9467V114.21H71.9761V114.001H71.9467V113.675ZM71.5752 114.046V114.165H71.902V114.046H71.5752ZM72.1097 114.165V114.046H72.4365V114.165H72.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M73.1001 112.1H73.5756V112.575L73.1001 112.575V112.1ZM73.1001 112.813V113.288H73.5756V112.813H73.1001ZM73.8133 112.813V113.288H74.2884V112.813H73.8133ZM74.2884 112.575V112.1H73.8133V112.575L74.2884 112.575ZM73.6351 112.263H73.7539V112.59H73.7239V112.798H73.7539V113.125H73.6351V112.798H73.6645V112.59H73.6351V112.263ZM73.2632 112.635V112.753H73.59V112.635H73.2632ZM73.7984 112.753V112.635H74.1252V112.753H73.7984ZM73.1001 113.511H73.5755V113.987H73.1001V113.511ZM73.1001 114.224V114.699H73.5755V114.224H73.1001ZM73.8133 114.224V114.699H74.2884V114.224H73.8133ZM74.2884 113.987V113.511H73.8133V113.987L74.2884 113.987ZM73.6351 113.675H73.7539V114.001H73.7239V114.209H73.7539V114.536H73.6351V114.209H73.6645V114.001H73.6351V113.675ZM73.2632 114.046V114.165H73.59V114.046H73.2632ZM73.7984 114.165V114.046H74.1252V114.165H73.7984ZM74.9872 112.1H74.5118V112.575L74.9872 112.575V112.1ZM74.5118 113.288V112.813H74.9872V113.288H74.5118ZM75.2249 113.288V112.813H75.7001V113.288H75.2249ZM75.7001 112.1V112.575L75.2249 112.575V112.1H75.7001ZM75.1656 112.263H75.0468V112.59H75.0762V112.798H75.0468V113.125H75.1656V112.798H75.1356V112.59H75.1656V112.263ZM74.6753 112.753V112.635H75.0021V112.753H74.6753ZM75.2098 112.635V112.753H75.5366V112.635H75.2098ZM74.5118 113.511H74.9872V113.987L74.5118 113.987V113.511ZM74.5118 114.224V114.699H74.9872V114.224H74.5118ZM75.2249 114.224V114.699H75.7001V114.224H75.2249ZM75.7001 113.987V113.511H75.2249V113.987H75.7001ZM75.0468 113.675H75.1656V114.001H75.1356V114.21H75.1656V114.536H75.0468V114.21H75.0762V114.001H75.0468V113.675ZM74.6753 114.046V114.165H75.0021V114.046H74.6753ZM75.2098 114.165V114.046H75.5366V114.165H75.2098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 102.8H76.7753V103.275L76.2998 103.275V102.8ZM76.2998 103.513V103.988H76.7753V103.513H76.2998ZM77.013 103.513V103.988H77.4881V103.513H77.013ZM77.4881 103.275V102.8H77.013V103.275L77.4881 103.275ZM76.8348 102.963H76.9536V103.29H76.9236V103.498H76.9536V103.825H76.8348V103.498H76.8642V103.29H76.8348V102.963ZM76.4629 103.335V103.454H76.7897V103.335H76.4629ZM76.9981 103.454V103.335H77.3249V103.454H76.9981ZM76.2998 104.211H76.7753V104.687H76.2998V104.211ZM76.2998 104.925V105.4H76.7753V104.925H76.2998ZM77.013 104.925V105.4H77.4881V104.925H77.013ZM77.4881 104.687V104.211H77.013V104.687H77.4881ZM76.8348 104.375H76.9536V104.702H76.9236V104.91H76.9536V105.236H76.8348V104.91H76.8642V104.702H76.8348V104.375ZM76.4629 104.746V104.865H76.7897V104.746H76.4629ZM76.9981 104.865V104.746H77.3249V104.865H76.9981ZM78.1869 102.8H77.7115V103.275L78.1869 103.275V102.8ZM77.7115 103.988V103.513H78.1869V103.988H77.7115ZM78.4246 103.988V103.513H78.8998V103.988H78.4246ZM78.8998 102.8V103.275L78.4246 103.275V102.8H78.8998ZM78.3653 102.963H78.2465V103.29H78.2759V103.498H78.2465V103.825H78.3653V103.498H78.3353V103.29H78.3653V102.963ZM77.875 103.454V103.335H78.2018V103.454H77.875ZM78.4095 103.335V103.454H78.7363V103.335H78.4095ZM77.7115 104.211H78.1869V104.687H77.7115V104.211ZM77.7115 104.925V105.4H78.1869V104.925H77.7115ZM78.4246 104.925V105.4H78.8998V104.925H78.4246ZM78.8998 104.687V104.211H78.4246V104.687H78.8998ZM78.2465 104.375H78.3653V104.702H78.3353V104.91H78.3653V105.236H78.2465V104.91H78.2759V104.702H78.2465V104.375ZM77.875 104.746V104.865H78.2018V104.746H77.875ZM78.4095 104.865V104.746H78.7363V104.865H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 102.8H79.8754V103.275L79.3999 103.275V102.8ZM79.3999 103.513V103.988H79.8754V103.513H79.3999ZM80.1131 103.513V103.988H80.5882V103.513H80.1131ZM80.5882 103.275V102.8H80.1131V103.275L80.5882 103.275ZM79.9349 102.963H80.0537V103.29H80.0237V103.498H80.0537V103.825H79.9349V103.498H79.9643V103.29H79.9349V102.963ZM79.563 103.335V103.454H79.8898V103.335H79.563ZM80.0982 103.454V103.335H80.425V103.454H80.0982ZM79.3999 104.211H79.8753V104.687H79.3999V104.211ZM79.3999 104.925V105.4H79.8753V104.925H79.3999ZM80.1131 104.925V105.4H80.5882V104.925H80.1131ZM80.5882 104.687V104.211H80.1131V104.687L80.5882 104.687ZM79.9349 104.375H80.0537V104.702H80.0237V104.91H80.0537V105.236H79.9349V104.91H79.9643V104.702H79.9349V104.375ZM79.563 104.746V104.865H79.8898V104.746H79.563ZM80.0982 104.865V104.746H80.425V104.865H80.0982ZM81.287 102.8H80.8116V103.275L81.287 103.275V102.8ZM80.8116 103.988V103.513H81.287V103.988H80.8116ZM81.5247 103.988V103.513H81.9999V103.988H81.5247ZM81.9999 102.8V103.275L81.5247 103.275V102.8H81.9999ZM81.4654 102.963H81.3466V103.29H81.376V103.498H81.3466V103.825H81.4654V103.498H81.4354V103.29H81.4654V102.963ZM80.9751 103.454V103.335H81.3019V103.454H80.9751ZM81.5096 103.335V103.454H81.8364V103.335H81.5096ZM80.8116 104.211H81.287V104.687L80.8116 104.687V104.211ZM80.8116 104.925V105.4H81.287V104.925H80.8116ZM81.5247 104.925V105.4H81.9999V104.925H81.5247ZM81.9999 104.687V104.211H81.5247V104.687H81.9999ZM81.3466 104.375H81.4654V104.702H81.4354V104.91H81.4654V105.236H81.3466V104.91H81.376V104.702H81.3466V104.375ZM80.9751 104.746V104.865H81.3019V104.746H80.9751ZM81.5096 104.865V104.746H81.8364V104.865H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 102.8H82.9755V103.275L82.5 103.275V102.8ZM82.5 103.513V103.988H82.9755V103.513H82.5ZM83.2132 103.513V103.988H83.6883V103.513H83.2132ZM83.6883 103.275V102.8H83.2132V103.275L83.6883 103.275ZM83.035 102.963H83.1538V103.29H83.1238V103.498H83.1538V103.825H83.035V103.498H83.0644V103.29H83.035V102.963ZM82.6631 103.335V103.454H82.9899V103.335H82.6631ZM83.1983 103.454V103.335H83.5251V103.454H83.1983ZM82.5 104.211H82.9754V104.687H82.5V104.211ZM82.5 104.925V105.4H82.9754V104.925H82.5ZM83.2132 104.925V105.4H83.6883V104.925H83.2132ZM83.6883 104.687V104.211H83.2132V104.687L83.6883 104.687ZM83.035 104.375H83.1538V104.702H83.1238V104.91H83.1538V105.236H83.035V104.91H83.0644V104.702H83.035V104.375ZM82.6631 104.746V104.865H82.9899V104.746H82.6631ZM83.1983 104.865V104.746H83.5251V104.865H83.1983ZM84.3871 102.8H83.9117V103.275L84.3871 103.275V102.8ZM83.9117 103.988V103.513H84.3871V103.988H83.9117ZM84.6248 103.988V103.513H85.1V103.988H84.6248ZM85.1 102.8V103.275L84.6248 103.275V102.8H85.1ZM84.5655 102.963H84.4467V103.29H84.4761V103.498H84.4467V103.825H84.5655V103.498H84.5355V103.29H84.5655V102.963ZM84.0752 103.454V103.335H84.402V103.454H84.0752ZM84.6097 103.335V103.454H84.9365V103.335H84.6097ZM83.9117 104.211H84.3871V104.687L83.9117 104.687V104.211ZM83.9117 104.925V105.4H84.3871V104.925H83.9117ZM84.6248 104.925V105.4H85.1V104.925H84.6248ZM85.1 104.687V104.211H84.6248V104.687H85.1ZM84.4467 104.375H84.5655V104.702H84.5355V104.91H84.5655V105.236H84.4467V104.91H84.4761V104.702H84.4467V104.375ZM84.0752 104.746V104.865H84.402V104.746H84.0752ZM84.6097 104.865V104.746H84.9365V104.865H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 102.8H86.0756V103.275L85.6001 103.275V102.8ZM85.6001 103.513V103.988H86.0756V103.513H85.6001ZM86.3133 103.513V103.988H86.7884V103.513H86.3133ZM86.7884 103.275V102.8H86.3133V103.275L86.7884 103.275ZM86.1351 102.963H86.2539V103.29H86.2239V103.498H86.2539V103.825H86.1351V103.498H86.1645V103.29H86.1351V102.963ZM85.7632 103.335V103.454H86.09V103.335H85.7632ZM86.2984 103.454V103.335H86.6252V103.454H86.2984ZM85.6001 104.211H86.0755V104.687H85.6001V104.211ZM85.6001 104.925V105.4H86.0755V104.925H85.6001ZM86.3133 104.925V105.4H86.7884V104.925H86.3133ZM86.7884 104.687V104.211H86.3133V104.687L86.7884 104.687ZM86.1351 104.375H86.2539V104.702H86.2239V104.91H86.2539V105.236H86.1351V104.91H86.1645V104.702H86.1351V104.375ZM85.7632 104.746V104.865H86.09V104.746H85.7632ZM86.2984 104.865V104.746H86.6252V104.865H86.2984ZM87.4872 102.8H87.0118V103.275L87.4872 103.275V102.8ZM87.0118 103.988V103.513H87.4872V103.988H87.0118ZM87.7249 103.988V103.513H88.2001V103.988H87.7249ZM88.2001 102.8V103.275L87.7249 103.275V102.8H88.2001ZM87.6656 102.963H87.5468V103.29H87.5762V103.498H87.5468V103.825H87.6656V103.498H87.6356V103.29H87.6656V102.963ZM87.1753 103.454V103.335H87.5021V103.454H87.1753ZM87.7098 103.335V103.454H88.0366V103.335H87.7098ZM87.0118 104.211H87.4872V104.687L87.0118 104.687V104.211ZM87.0118 104.925V105.4H87.4872V104.925H87.0118ZM87.7249 104.925V105.4H88.2001V104.925H87.7249ZM88.2001 104.687V104.211H87.7249V104.687H88.2001ZM87.5468 104.375H87.6656V104.702H87.6356V104.91H87.6656V105.236H87.5468V104.91H87.5762V104.702H87.5468V104.375ZM87.1753 104.746V104.865H87.5021V104.746H87.1753ZM87.7098 104.865V104.746H88.0366V104.865H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 105.9H76.7753V106.376L76.2998 106.376V105.9ZM76.2998 106.614V107.089H76.7753V106.614H76.2998ZM77.013 106.614V107.089H77.4881V106.614H77.013ZM77.4881 106.376V105.9H77.013V106.376L77.4881 106.376ZM76.8348 106.064H76.9536V106.391H76.9236V106.599H76.9536V106.926H76.8348V106.599H76.8642V106.391H76.8348V106.064ZM76.4629 106.435V106.554H76.7897V106.435H76.4629ZM76.9981 106.554V106.435H77.3249V106.554H76.9981ZM76.2998 107.312H76.7753V107.787H76.2998V107.312ZM76.2998 108.025V108.5H76.7753V108.025H76.2998ZM77.013 108.025V108.5H77.4881V108.025H77.013ZM77.4881 107.787V107.312H77.013V107.787L77.4881 107.787ZM76.8348 107.475H76.9536V107.802H76.9236V108.01H76.9536V108.337H76.8348V108.01H76.8642V107.802H76.8348V107.475ZM76.4629 107.847V107.966H76.7897V107.847H76.4629ZM76.9981 107.966V107.847H77.3249V107.966H76.9981ZM78.1869 105.9H77.7115V106.376L78.1869 106.376V105.9ZM77.7115 107.089V106.614H78.1869V107.089H77.7115ZM78.4246 107.089V106.614H78.8998V107.089H78.4246ZM78.8998 105.9V106.376L78.4246 106.376V105.9H78.8998ZM78.3653 106.064H78.2465V106.391H78.2759V106.599H78.2465V106.926H78.3653V106.599H78.3353V106.391H78.3653V106.064ZM77.875 106.554V106.435H78.2018V106.554H77.875ZM78.4095 106.435V106.554H78.7363V106.435H78.4095ZM77.7115 107.312H78.1869V107.787L77.7115 107.787V107.312ZM77.7115 108.025V108.5H78.1869V108.025H77.7115ZM78.4246 108.025V108.5H78.8998V108.025H78.4246ZM78.8998 107.787V107.312H78.4246V107.787H78.8998ZM78.2465 107.475H78.3653V107.802H78.3353V108.01H78.3653V108.337H78.2465V108.01H78.2759V107.802H78.2465V107.475ZM77.875 107.847V107.966H78.2018V107.847H77.875ZM78.4095 107.966V107.847H78.7363V107.966H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 105.9H79.8754V106.376L79.3999 106.376V105.9ZM79.3999 106.614V107.089H79.8754V106.614H79.3999ZM80.1131 106.614V107.089H80.5882V106.614H80.1131ZM80.5882 106.376V105.9H80.1131V106.376L80.5882 106.376ZM79.9349 106.064H80.0537V106.391H80.0237V106.599H80.0537V106.926H79.9349V106.599H79.9643V106.391H79.9349V106.064ZM79.563 106.435V106.554H79.8898V106.435H79.563ZM80.0982 106.554V106.435H80.425V106.554H80.0982ZM79.3999 107.312H79.8753V107.787H79.3999V107.312ZM79.3999 108.025V108.5H79.8753V108.025H79.3999ZM80.1131 108.025V108.5H80.5882V108.025H80.1131ZM80.5882 107.787V107.312H80.1131V107.787L80.5882 107.787ZM79.9349 107.475H80.0537V107.802H80.0237V108.01H80.0537V108.337H79.9349V108.01H79.9643V107.802H79.9349V107.475ZM79.563 107.847V107.966H79.8898V107.847H79.563ZM80.0982 107.966V107.847H80.425V107.966H80.0982ZM81.287 105.9H80.8116V106.376L81.287 106.376V105.9ZM80.8116 107.089V106.614H81.287V107.089H80.8116ZM81.5247 107.089V106.614H81.9999V107.089H81.5247ZM81.9999 105.9V106.376L81.5247 106.376V105.9H81.9999ZM81.4654 106.064H81.3466V106.391H81.376V106.599H81.3466V106.926H81.4654V106.599H81.4354V106.391H81.4654V106.064ZM80.9751 106.554V106.435H81.3019V106.554H80.9751ZM81.5096 106.435V106.554H81.8364V106.435H81.5096ZM80.8116 107.312H81.287V107.787L80.8116 107.787V107.312ZM80.8116 108.025V108.5H81.287V108.025H80.8116ZM81.5247 108.025V108.5H81.9999V108.025H81.5247ZM81.9999 107.787V107.312H81.5247V107.787H81.9999ZM81.3466 107.475H81.4654V107.802H81.4354V108.01H81.4654V108.337H81.3466V108.01H81.376V107.802H81.3466V107.475ZM80.9751 107.847V107.966H81.3019V107.847H80.9751ZM81.5096 107.966V107.847H81.8364V107.966H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 105.9H82.9755V106.376L82.5 106.376V105.9ZM82.5 106.614V107.089H82.9755V106.614H82.5ZM83.2132 106.614V107.089H83.6883V106.614H83.2132ZM83.6883 106.376V105.9H83.2132V106.376L83.6883 106.376ZM83.035 106.064H83.1538V106.391H83.1238V106.599H83.1538V106.926H83.035V106.599H83.0644V106.391H83.035V106.064ZM82.6631 106.435V106.554H82.9899V106.435H82.6631ZM83.1983 106.554V106.435H83.5251V106.554H83.1983ZM82.5 107.312H82.9754V107.787H82.5V107.312ZM82.5 108.025V108.5H82.9754V108.025H82.5ZM83.2132 108.025V108.5H83.6883V108.025H83.2132ZM83.6883 107.787V107.312H83.2132V107.787L83.6883 107.787ZM83.035 107.475H83.1538V107.802H83.1238V108.01H83.1538V108.337H83.035V108.01H83.0644V107.802H83.035V107.475ZM82.6631 107.847V107.966H82.9899V107.847H82.6631ZM83.1983 107.966V107.847H83.5251V107.966H83.1983ZM84.3871 105.9H83.9117V106.376L84.3871 106.376V105.9ZM83.9117 107.089V106.614H84.3871V107.089H83.9117ZM84.6248 107.089V106.614H85.1V107.089H84.6248ZM85.1 105.9V106.376L84.6248 106.376V105.9H85.1ZM84.5655 106.064H84.4467V106.391H84.4761V106.599H84.4467V106.926H84.5655V106.599H84.5355V106.391H84.5655V106.064ZM84.0752 106.554V106.435H84.402V106.554H84.0752ZM84.6097 106.435V106.554H84.9365V106.435H84.6097ZM83.9117 107.312H84.3871V107.787L83.9117 107.787V107.312ZM83.9117 108.025V108.5H84.3871V108.025H83.9117ZM84.6248 108.025V108.5H85.1V108.025H84.6248ZM85.1 107.787V107.312H84.6248V107.787H85.1ZM84.4467 107.475H84.5655V107.802H84.5355V108.01H84.5655V108.337H84.4467V108.01H84.4761V107.802H84.4467V107.475ZM84.0752 107.847V107.966H84.402V107.847H84.0752ZM84.6097 107.966V107.847H84.9365V107.966H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 105.9H86.0756V106.376L85.6001 106.376V105.9ZM85.6001 106.614V107.089H86.0756V106.614H85.6001ZM86.3133 106.614V107.089H86.7884V106.614H86.3133ZM86.7884 106.376V105.9H86.3133V106.376L86.7884 106.376ZM86.1351 106.064H86.2539V106.391H86.2239V106.599H86.2539V106.926H86.1351V106.599H86.1645V106.391H86.1351V106.064ZM85.7632 106.435V106.554H86.09V106.435H85.7632ZM86.2984 106.554V106.435H86.6252V106.554H86.2984ZM85.6001 107.312H86.0755V107.787H85.6001V107.312ZM85.6001 108.025V108.5H86.0755V108.025H85.6001ZM86.3133 108.025V108.5H86.7884V108.025H86.3133ZM86.7884 107.787V107.312H86.3133V107.787L86.7884 107.787ZM86.1351 107.475H86.2539V107.802H86.2239V108.01H86.2539V108.337H86.1351V108.01H86.1645V107.802H86.1351V107.475ZM85.7632 107.847V107.966H86.09V107.847H85.7632ZM86.2984 107.966V107.847H86.6252V107.966H86.2984ZM87.4872 105.9H87.0118V106.376L87.4872 106.376V105.9ZM87.0118 107.089V106.614H87.4872V107.089H87.0118ZM87.7249 107.089V106.614H88.2001V107.089H87.7249ZM88.2001 105.9V106.376L87.7249 106.376V105.9H88.2001ZM87.6656 106.064H87.5468V106.391H87.5762V106.599H87.5468V106.926H87.6656V106.599H87.6356V106.391H87.6656V106.064ZM87.1753 106.554V106.435H87.5021V106.554H87.1753ZM87.7098 106.435V106.554H88.0366V106.435H87.7098ZM87.0118 107.312H87.4872V107.787L87.0118 107.787V107.312ZM87.0118 108.025V108.5H87.4872V108.025H87.0118ZM87.7249 108.025V108.5H88.2001V108.025H87.7249ZM88.2001 107.787V107.312H87.7249V107.787H88.2001ZM87.5468 107.475H87.6656V107.802H87.6356V108.01H87.6656V108.337H87.5468V108.01H87.5762V107.802H87.5468V107.475ZM87.1753 107.847V107.966H87.5021V107.847H87.1753ZM87.7098 107.966V107.847H88.0366V107.966H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 109H76.7753V109.475L76.2998 109.475V109ZM76.2998 109.713V110.188H76.7753V109.713H76.2998ZM77.013 109.713V110.188H77.4881V109.713H77.013ZM77.4881 109.475V109H77.013V109.475L77.4881 109.475ZM76.8348 109.163H76.9536V109.49H76.9236V109.698H76.9536V110.025H76.8348V109.698H76.8642V109.49H76.8348V109.163ZM76.4629 109.535V109.654H76.7897V109.535H76.4629ZM76.9981 109.654V109.535H77.3249V109.654H76.9981ZM76.2998 110.412H76.7753V110.887H76.2998V110.412ZM76.2998 111.125V111.6H76.7753V111.125H76.2998ZM77.013 111.125V111.6H77.4881V111.125H77.013ZM77.4881 110.887V110.412H77.013V110.887L77.4881 110.887ZM76.8348 110.575H76.9536V110.902H76.9236V111.11H76.9536V111.437H76.8348V111.11H76.8642V110.902H76.8348V110.575ZM76.4629 110.946V111.065H76.7897V110.946H76.4629ZM76.9981 111.065V110.946H77.3249V111.065H76.9981ZM78.1869 109H77.7115V109.475L78.1869 109.475V109ZM77.7115 110.188V109.713H78.1869V110.188H77.7115ZM78.4246 110.188V109.713H78.8998V110.188H78.4246ZM78.8998 109V109.475L78.4246 109.475V109H78.8998ZM78.3653 109.163H78.2465V109.49H78.2759V109.698H78.2465V110.025H78.3653V109.698H78.3353V109.49H78.3653V109.163ZM77.875 109.654V109.535H78.2018V109.654H77.875ZM78.4095 109.535V109.654H78.7363V109.535H78.4095ZM77.7115 110.412H78.1869V110.887L77.7115 110.887V110.412ZM77.7115 111.125V111.6H78.1869V111.125H77.7115ZM78.4246 111.125V111.6H78.8998V111.125H78.4246ZM78.8998 110.887V110.412H78.4246V110.887H78.8998ZM78.2465 110.575H78.3653V110.902H78.3353V111.11H78.3653V111.437H78.2465V111.11H78.2759V110.902H78.2465V110.575ZM77.875 110.947V111.065H78.2018V110.947H77.875ZM78.4095 111.065V110.947H78.7363V111.065H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 109H79.8754V109.475L79.3999 109.475V109ZM79.3999 109.713V110.188H79.8754V109.713H79.3999ZM80.1131 109.713V110.188H80.5882V109.713H80.1131ZM80.5882 109.475V109H80.1131V109.475L80.5882 109.475ZM79.9349 109.163H80.0537V109.49H80.0237V109.698H80.0537V110.025H79.9349V109.698H79.9643V109.49H79.9349V109.163ZM79.563 109.535V109.654H79.8898V109.535H79.563ZM80.0982 109.654V109.535H80.425V109.654H80.0982ZM79.3999 110.412H79.8753V110.887H79.3999V110.412ZM79.3999 111.125V111.6H79.8753V111.125H79.3999ZM80.1131 111.125V111.6H80.5882V111.125H80.1131ZM80.5882 110.887V110.412H80.1131V110.887L80.5882 110.887ZM79.9349 110.575H80.0537V110.902H80.0237V111.11H80.0537V111.437H79.9349V111.11H79.9643V110.902H79.9349V110.575ZM79.563 110.946V111.065H79.8898V110.946H79.563ZM80.0982 111.065V110.946H80.425V111.065H80.0982ZM81.287 109H80.8116V109.475L81.287 109.475V109ZM80.8116 110.188V109.713H81.287V110.188H80.8116ZM81.5247 110.188V109.713H81.9999V110.188H81.5247ZM81.9999 109V109.475L81.5247 109.475V109H81.9999ZM81.4654 109.163H81.3466V109.49H81.376V109.698H81.3466V110.025H81.4654V109.698H81.4354V109.49H81.4654V109.163ZM80.9751 109.654V109.535H81.3019V109.654H80.9751ZM81.5096 109.535V109.654H81.8364V109.535H81.5096ZM80.8116 110.412H81.287V110.887L80.8116 110.887V110.412ZM80.8116 111.125V111.6H81.287V111.125H80.8116ZM81.5247 111.125V111.6H81.9999V111.125H81.5247ZM81.9999 110.887V110.412H81.5247V110.887H81.9999ZM81.3466 110.575H81.4654V110.902H81.4354V111.11H81.4654V111.437H81.3466V111.11H81.376V110.902H81.3466V110.575ZM80.9751 110.947V111.065H81.3019V110.947H80.9751ZM81.5096 111.065V110.947H81.8364V111.065H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 109H82.9755V109.475L82.5 109.475V109ZM82.5 109.713V110.188H82.9755V109.713H82.5ZM83.2132 109.713V110.188H83.6883V109.713H83.2132ZM83.6883 109.475V109H83.2132V109.475L83.6883 109.475ZM83.035 109.163H83.1538V109.49H83.1238V109.698H83.1538V110.025H83.035V109.698H83.0644V109.49H83.035V109.163ZM82.6631 109.535V109.654H82.9899V109.535H82.6631ZM83.1983 109.654V109.535H83.5251V109.654H83.1983ZM82.5 110.412H82.9754V110.887H82.5V110.412ZM82.5 111.125V111.6H82.9754V111.125H82.5ZM83.2132 111.125V111.6H83.6883V111.125H83.2132ZM83.6883 110.887V110.412H83.2132V110.887L83.6883 110.887ZM83.035 110.575H83.1538V110.902H83.1238V111.11H83.1538V111.437H83.035V111.11H83.0644V110.902H83.035V110.575ZM82.6631 110.946V111.065H82.9899V110.946H82.6631ZM83.1983 111.065V110.946H83.5251V111.065H83.1983ZM84.3871 109H83.9117V109.475L84.3871 109.475V109ZM83.9117 110.188V109.713H84.3871V110.188H83.9117ZM84.6248 110.188V109.713H85.1V110.188H84.6248ZM85.1 109V109.475L84.6248 109.475V109H85.1ZM84.5655 109.163H84.4467V109.49H84.4761V109.698H84.4467V110.025H84.5655V109.698H84.5355V109.49H84.5655V109.163ZM84.0752 109.654V109.535H84.402V109.654H84.0752ZM84.6097 109.535V109.654H84.9365V109.535H84.6097ZM83.9117 110.412H84.3871V110.887L83.9117 110.887V110.412ZM83.9117 111.125V111.6H84.3871V111.125H83.9117ZM84.6248 111.125V111.6H85.1V111.125H84.6248ZM85.1 110.887V110.412H84.6248V110.887H85.1ZM84.4467 110.575H84.5655V110.902H84.5355V111.11H84.5655V111.437H84.4467V111.11H84.4761V110.902H84.4467V110.575ZM84.0752 110.947V111.065H84.402V110.947H84.0752ZM84.6097 111.065V110.947H84.9365V111.065H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 109H86.0756V109.475L85.6001 109.475V109ZM85.6001 109.713V110.188H86.0756V109.713H85.6001ZM86.3133 109.713V110.188H86.7884V109.713H86.3133ZM86.7884 109.475V109H86.3133V109.475L86.7884 109.475ZM86.1351 109.163H86.2539V109.49H86.2239V109.698H86.2539V110.025H86.1351V109.698H86.1645V109.49H86.1351V109.163ZM85.7632 109.535V109.654H86.09V109.535H85.7632ZM86.2984 109.654V109.535H86.6252V109.654H86.2984ZM85.6001 110.412H86.0755V110.887H85.6001V110.412ZM85.6001 111.125V111.6H86.0755V111.125H85.6001ZM86.3133 111.125V111.6H86.7884V111.125H86.3133ZM86.7884 110.887V110.412H86.3133V110.887L86.7884 110.887ZM86.1351 110.575H86.2539V110.902H86.2239V111.11H86.2539V111.437H86.1351V111.11H86.1645V110.902H86.1351V110.575ZM85.7632 110.946V111.065H86.09V110.946H85.7632ZM86.2984 111.065V110.946H86.6252V111.065H86.2984ZM87.4872 109H87.0118V109.475L87.4872 109.475V109ZM87.0118 110.188V109.713H87.4872V110.188H87.0118ZM87.7249 110.188V109.713H88.2001V110.188H87.7249ZM88.2001 109V109.475L87.7249 109.475V109H88.2001ZM87.6656 109.163H87.5468V109.49H87.5762V109.698H87.5468V110.025H87.6656V109.698H87.6356V109.49H87.6656V109.163ZM87.1753 109.654V109.535H87.5021V109.654H87.1753ZM87.7098 109.535V109.654H88.0366V109.535H87.7098ZM87.0118 110.412H87.4872V110.887L87.0118 110.887V110.412ZM87.0118 111.125V111.6H87.4872V111.125H87.0118ZM87.7249 111.125V111.6H88.2001V111.125H87.7249ZM88.2001 110.887V110.412H87.7249V110.887H88.2001ZM87.5468 110.575H87.6656V110.902H87.6356V111.11H87.6656V111.437H87.5468V111.11H87.5762V110.902H87.5468V110.575ZM87.1753 110.947V111.065H87.5021V110.947H87.1753ZM87.7098 111.065V110.947H88.0366V111.065H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.2998 112.1H76.7753V112.575L76.2998 112.575V112.1ZM76.2998 112.813V113.288H76.7753V112.813H76.2998ZM77.013 112.813V113.288H77.4881V112.813H77.013ZM77.4881 112.575V112.1H77.013V112.575L77.4881 112.575ZM76.8348 112.263H76.9536V112.59H76.9236V112.798H76.9536V113.125H76.8348V112.798H76.8642V112.59H76.8348V112.263ZM76.4629 112.635V112.753H76.7897V112.635H76.4629ZM76.9981 112.753V112.635H77.3249V112.753H76.9981ZM76.2998 113.511H76.7753V113.987H76.2998V113.511ZM76.2998 114.224V114.699H76.7753V114.224H76.2998ZM77.013 114.224V114.699H77.4881V114.224H77.013ZM77.4881 113.987V113.511H77.013V113.987L77.4881 113.987ZM76.8348 113.675H76.9536V114.001H76.9236V114.209H76.9536V114.536H76.8348V114.209H76.8642V114.001H76.8348V113.675ZM76.4629 114.046V114.165H76.7897V114.046H76.4629ZM76.9981 114.165V114.046H77.3249V114.165H76.9981ZM78.1869 112.1H77.7115V112.575L78.1869 112.575V112.1ZM77.7115 113.288V112.813H78.1869V113.288H77.7115ZM78.4246 113.288V112.813H78.8998V113.288H78.4246ZM78.8998 112.1V112.575L78.4246 112.575V112.1H78.8998ZM78.3653 112.263H78.2465V112.59H78.2759V112.798H78.2465V113.125H78.3653V112.798H78.3353V112.59H78.3653V112.263ZM77.875 112.753V112.635H78.2018V112.753H77.875ZM78.4095 112.635V112.753H78.7363V112.635H78.4095ZM77.7115 113.511H78.1869V113.987L77.7115 113.987V113.511ZM77.7115 114.224V114.699H78.1869V114.224H77.7115ZM78.4246 114.224V114.699H78.8998V114.224H78.4246ZM78.8998 113.987V113.511H78.4246V113.987H78.8998ZM78.2465 113.675H78.3653V114.001H78.3353V114.21H78.3653V114.536H78.2465V114.21H78.2759V114.001H78.2465V113.675ZM77.875 114.046V114.165H78.2018V114.046H77.875ZM78.4095 114.165V114.046H78.7363V114.165H78.4095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M79.3999 112.1H79.8754V112.575L79.3999 112.575V112.1ZM79.3999 112.813V113.288H79.8754V112.813H79.3999ZM80.1131 112.813V113.288H80.5882V112.813H80.1131ZM80.5882 112.575V112.1H80.1131V112.575L80.5882 112.575ZM79.9349 112.263H80.0537V112.59H80.0237V112.798H80.0537V113.125H79.9349V112.798H79.9643V112.59H79.9349V112.263ZM79.563 112.635V112.753H79.8898V112.635H79.563ZM80.0982 112.753V112.635H80.425V112.753H80.0982ZM79.3999 113.511H79.8753V113.987H79.3999V113.511ZM79.3999 114.224V114.699H79.8753V114.224H79.3999ZM80.1131 114.224V114.699H80.5882V114.224H80.1131ZM80.5882 113.987V113.511H80.1131V113.987L80.5882 113.987ZM79.9349 113.675H80.0537V114.001H80.0237V114.209H80.0537V114.536H79.9349V114.209H79.9643V114.001H79.9349V113.675ZM79.563 114.046V114.165H79.8898V114.046H79.563ZM80.0982 114.165V114.046H80.425V114.165H80.0982ZM81.287 112.1H80.8116V112.575L81.287 112.575V112.1ZM80.8116 113.288V112.813H81.287V113.288H80.8116ZM81.5247 113.288V112.813H81.9999V113.288H81.5247ZM81.9999 112.1V112.575L81.5247 112.575V112.1H81.9999ZM81.4654 112.263H81.3466V112.59H81.376V112.798H81.3466V113.125H81.4654V112.798H81.4354V112.59H81.4654V112.263ZM80.9751 112.753V112.635H81.3019V112.753H80.9751ZM81.5096 112.635V112.753H81.8364V112.635H81.5096ZM80.8116 113.511H81.287V113.987L80.8116 113.987V113.511ZM80.8116 114.224V114.699H81.287V114.224H80.8116ZM81.5247 114.224V114.699H81.9999V114.224H81.5247ZM81.9999 113.987V113.511H81.5247V113.987H81.9999ZM81.3466 113.675H81.4654V114.001H81.4354V114.21H81.4654V114.536H81.3466V114.21H81.376V114.001H81.3466V113.675ZM80.9751 114.046V114.165H81.3019V114.046H80.9751ZM81.5096 114.165V114.046H81.8364V114.165H81.5096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.5 112.1H82.9755V112.575L82.5 112.575V112.1ZM82.5 112.813V113.288H82.9755V112.813H82.5ZM83.2132 112.813V113.288H83.6883V112.813H83.2132ZM83.6883 112.575V112.1H83.2132V112.575L83.6883 112.575ZM83.035 112.263H83.1538V112.59H83.1238V112.798H83.1538V113.125H83.035V112.798H83.0644V112.59H83.035V112.263ZM82.6631 112.635V112.753H82.9899V112.635H82.6631ZM83.1983 112.753V112.635H83.5251V112.753H83.1983ZM82.5 113.511H82.9754V113.987H82.5V113.511ZM82.5 114.224V114.699H82.9754V114.224H82.5ZM83.2132 114.224V114.699H83.6883V114.224H83.2132ZM83.6883 113.987V113.511H83.2132V113.987L83.6883 113.987ZM83.035 113.675H83.1538V114.001H83.1238V114.209H83.1538V114.536H83.035V114.209H83.0644V114.001H83.035V113.675ZM82.6631 114.046V114.165H82.9899V114.046H82.6631ZM83.1983 114.165V114.046H83.5251V114.165H83.1983ZM84.3871 112.1H83.9117V112.575L84.3871 112.575V112.1ZM83.9117 113.288V112.813H84.3871V113.288H83.9117ZM84.6248 113.288V112.813H85.1V113.288H84.6248ZM85.1 112.1V112.575L84.6248 112.575V112.1H85.1ZM84.5655 112.263H84.4467V112.59H84.4761V112.798H84.4467V113.125H84.5655V112.798H84.5355V112.59H84.5655V112.263ZM84.0752 112.753V112.635H84.402V112.753H84.0752ZM84.6097 112.635V112.753H84.9365V112.635H84.6097ZM83.9117 113.511H84.3871V113.987L83.9117 113.987V113.511ZM83.9117 114.224V114.699H84.3871V114.224H83.9117ZM84.6248 114.224V114.699H85.1V114.224H84.6248ZM85.1 113.987V113.511H84.6248V113.987H85.1ZM84.4467 113.675H84.5655V114.001H84.5355V114.21H84.5655V114.536H84.4467V114.21H84.4761V114.001H84.4467V113.675ZM84.0752 114.046V114.165H84.402V114.046H84.0752ZM84.6097 114.165V114.046H84.9365V114.165H84.6097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M85.6001 112.1H86.0756V112.575L85.6001 112.575V112.1ZM85.6001 112.813V113.288H86.0756V112.813H85.6001ZM86.3133 112.813V113.288H86.7884V112.813H86.3133ZM86.7884 112.575V112.1H86.3133V112.575L86.7884 112.575ZM86.1351 112.263H86.2539V112.59H86.2239V112.798H86.2539V113.125H86.1351V112.798H86.1645V112.59H86.1351V112.263ZM85.7632 112.635V112.753H86.09V112.635H85.7632ZM86.2984 112.753V112.635H86.6252V112.753H86.2984ZM85.6001 113.511H86.0755V113.987H85.6001V113.511ZM85.6001 114.224V114.699H86.0755V114.224H85.6001ZM86.3133 114.224V114.699H86.7884V114.224H86.3133ZM86.7884 113.987V113.511H86.3133V113.987L86.7884 113.987ZM86.1351 113.675H86.2539V114.001H86.2239V114.209H86.2539V114.536H86.1351V114.209H86.1645V114.001H86.1351V113.675ZM85.7632 114.046V114.165H86.09V114.046H85.7632ZM86.2984 114.165V114.046H86.6252V114.165H86.2984ZM87.4872 112.1H87.0118V112.575L87.4872 112.575V112.1ZM87.0118 113.288V112.813H87.4872V113.288H87.0118ZM87.7249 113.288V112.813H88.2001V113.288H87.7249ZM88.2001 112.1V112.575L87.7249 112.575V112.1H88.2001ZM87.6656 112.263H87.5468V112.59H87.5762V112.798H87.5468V113.125H87.6656V112.798H87.6356V112.59H87.6656V112.263ZM87.1753 112.753V112.635H87.5021V112.753H87.1753ZM87.7098 112.635V112.753H88.0366V112.635H87.7098ZM87.0118 113.511H87.4872V113.987L87.0118 113.987V113.511ZM87.0118 114.224V114.699H87.4872V114.224H87.0118ZM87.7249 114.224V114.699H88.2001V114.224H87.7249ZM88.2001 113.987V113.511H87.7249V113.987H88.2001ZM87.5468 113.675H87.6656V114.001H87.6356V114.21H87.6656V114.536H87.5468V114.21H87.5762V114.001H87.5468V113.675ZM87.1753 114.046V114.165H87.5021V114.046H87.1753ZM87.7098 114.165V114.046H88.0366V114.165H87.7098Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 102.8H89.2753V103.275L88.7998 103.275V102.8ZM88.7998 103.513V103.988H89.2753V103.513H88.7998ZM89.513 103.513V103.988H89.9881V103.513H89.513ZM89.9881 103.275V102.8H89.513V103.275L89.9881 103.275ZM89.3348 102.963H89.4536V103.29H89.4236V103.498H89.4536V103.825H89.3348V103.498H89.3642V103.29H89.3348V102.963ZM88.9629 103.335V103.454H89.2897V103.335H88.9629ZM89.4981 103.454V103.335H89.8249V103.454H89.4981ZM88.7998 104.211H89.2753V104.687H88.7998V104.211ZM88.7998 104.925V105.4H89.2753V104.925H88.7998ZM89.513 104.925V105.4H89.9881V104.925H89.513ZM89.9881 104.687V104.211H89.513V104.687H89.9881ZM89.3348 104.375H89.4536V104.702H89.4236V104.91H89.4536V105.236H89.3348V104.91H89.3642V104.702H89.3348V104.375ZM88.9629 104.746V104.865H89.2897V104.746H88.9629ZM89.4981 104.865V104.746H89.8249V104.865H89.4981ZM90.6869 102.8H90.2115V103.275L90.6869 103.275V102.8ZM90.2115 103.988V103.513H90.6869V103.988H90.2115ZM90.9246 103.988V103.513H91.3998V103.988H90.9246ZM91.3998 102.8V103.275L90.9246 103.275V102.8H91.3998ZM90.8653 102.963H90.7465V103.29H90.7759V103.498H90.7465V103.825H90.8653V103.498H90.8353V103.29H90.8653V102.963ZM90.375 103.454V103.335H90.7018V103.454H90.375ZM90.9095 103.335V103.454H91.2363V103.335H90.9095ZM90.2115 104.211H90.6869V104.687H90.2115V104.211ZM90.2115 104.925V105.4H90.6869V104.925H90.2115ZM90.9246 104.925V105.4H91.3998V104.925H90.9246ZM91.3998 104.687V104.211H90.9246V104.687H91.3998ZM90.7465 104.375H90.8653V104.702H90.8353V104.91H90.8653V105.236H90.7465V104.91H90.7759V104.702H90.7465V104.375ZM90.375 104.746V104.865H90.7018V104.746H90.375ZM90.9095 104.865V104.746H91.2363V104.865H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 102.8H92.3754V103.275L91.8999 103.275V102.8ZM91.8999 103.513V103.988H92.3754V103.513H91.8999ZM92.6131 103.513V103.988H93.0882V103.513H92.6131ZM93.0882 103.275V102.8H92.6131V103.275L93.0882 103.275ZM92.4349 102.963H92.5537V103.29H92.5237V103.498H92.5537V103.825H92.4349V103.498H92.4643V103.29H92.4349V102.963ZM92.063 103.335V103.454H92.3898V103.335H92.063ZM92.5982 103.454V103.335H92.925V103.454H92.5982ZM91.8999 104.211H92.3753V104.687H91.8999V104.211ZM91.8999 104.925V105.4H92.3753V104.925H91.8999ZM92.6131 104.925V105.4H93.0882V104.925H92.6131ZM93.0882 104.687V104.211H92.6131V104.687L93.0882 104.687ZM92.4349 104.375H92.5537V104.702H92.5237V104.91H92.5537V105.236H92.4349V104.91H92.4643V104.702H92.4349V104.375ZM92.063 104.746V104.865H92.3898V104.746H92.063ZM92.5982 104.865V104.746H92.925V104.865H92.5982ZM93.787 102.8H93.3116V103.275L93.787 103.275V102.8ZM93.3116 103.988V103.513H93.787V103.988H93.3116ZM94.0247 103.988V103.513H94.4999V103.988H94.0247ZM94.4999 102.8V103.275L94.0247 103.275V102.8H94.4999ZM93.9654 102.963H93.8466V103.29H93.876V103.498H93.8466V103.825H93.9654V103.498H93.9354V103.29H93.9654V102.963ZM93.4751 103.454V103.335H93.8019V103.454H93.4751ZM94.0096 103.335V103.454H94.3364V103.335H94.0096ZM93.3116 104.211H93.787V104.687L93.3116 104.687V104.211ZM93.3116 104.925V105.4H93.787V104.925H93.3116ZM94.0247 104.925V105.4H94.4999V104.925H94.0247ZM94.4999 104.687V104.211H94.0247V104.687H94.4999ZM93.8466 104.375H93.9654V104.702H93.9354V104.91H93.9654V105.236H93.8466V104.91H93.876V104.702H93.8466V104.375ZM93.4751 104.746V104.865H93.8019V104.746H93.4751ZM94.0096 104.865V104.746H94.3364V104.865H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 102.8H95.4755V103.275L95 103.275V102.8ZM95 103.513V103.988H95.4755V103.513H95ZM95.7132 103.513V103.988H96.1883V103.513H95.7132ZM96.1883 103.275V102.8H95.7132V103.275L96.1883 103.275ZM95.535 102.963H95.6538V103.29H95.6238V103.498H95.6538V103.825H95.535V103.498H95.5644V103.29H95.535V102.963ZM95.1631 103.335V103.454H95.4899V103.335H95.1631ZM95.6983 103.454V103.335H96.0251V103.454H95.6983ZM95 104.211H95.4754V104.687H95V104.211ZM95 104.925V105.4H95.4754V104.925H95ZM95.7132 104.925V105.4H96.1883V104.925H95.7132ZM96.1883 104.687V104.211H95.7132V104.687L96.1883 104.687ZM95.535 104.375H95.6538V104.702H95.6238V104.91H95.6538V105.236H95.535V104.91H95.5644V104.702H95.535V104.375ZM95.1631 104.746V104.865H95.4899V104.746H95.1631ZM95.6983 104.865V104.746H96.0251V104.865H95.6983ZM96.8871 102.8H96.4117V103.275L96.8871 103.275V102.8ZM96.4117 103.988V103.513H96.8871V103.988H96.4117ZM97.1248 103.988V103.513H97.6V103.988H97.1248ZM97.6 102.8V103.275L97.1248 103.275V102.8H97.6ZM97.0655 102.963H96.9467V103.29H96.9761V103.498H96.9467V103.825H97.0655V103.498H97.0355V103.29H97.0655V102.963ZM96.5752 103.454V103.335H96.902V103.454H96.5752ZM97.1097 103.335V103.454H97.4365V103.335H97.1097ZM96.4117 104.211H96.8871V104.687L96.4117 104.687V104.211ZM96.4117 104.925V105.4H96.8871V104.925H96.4117ZM97.1248 104.925V105.4H97.6V104.925H97.1248ZM97.6 104.687V104.211H97.1248V104.687H97.6ZM96.9467 104.375H97.0655V104.702H97.0355V104.91H97.0655V105.236H96.9467V104.91H96.9761V104.702H96.9467V104.375ZM96.5752 104.746V104.865H96.902V104.746H96.5752ZM97.1097 104.865V104.746H97.4365V104.865H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 102.8H98.5756V103.275L98.1001 103.275V102.8ZM98.1001 103.513V103.988H98.5756V103.513H98.1001ZM98.8133 103.513V103.988H99.2884V103.513H98.8133ZM99.2884 103.275V102.8H98.8133V103.275L99.2884 103.275ZM98.6351 102.963H98.7539V103.29H98.7239V103.498H98.7539V103.825H98.6351V103.498H98.6645V103.29H98.6351V102.963ZM98.2632 103.335V103.454H98.59V103.335H98.2632ZM98.7984 103.454V103.335H99.1252V103.454H98.7984ZM98.1001 104.211H98.5755V104.687H98.1001V104.211ZM98.1001 104.925V105.4H98.5755V104.925H98.1001ZM98.8133 104.925V105.4H99.2884V104.925H98.8133ZM99.2884 104.687V104.211H98.8133V104.687L99.2884 104.687ZM98.6351 104.375H98.7539V104.702H98.7239V104.91H98.7539V105.236H98.6351V104.91H98.6645V104.702H98.6351V104.375ZM98.2632 104.746V104.865H98.59V104.746H98.2632ZM98.7984 104.865V104.746H99.1252V104.865H98.7984ZM99.9872 102.8H99.5118V103.275L99.9872 103.275V102.8ZM99.5118 103.988V103.513H99.9872V103.988H99.5118ZM100.225 103.988V103.513H100.7V103.988H100.225ZM100.7 102.8V103.275L100.225 103.275V102.8H100.7ZM100.166 102.963H100.047V103.29H100.076V103.498H100.047V103.825H100.166V103.498H100.136V103.29H100.166V102.963ZM99.6753 103.454V103.335H100.002V103.454H99.6753ZM100.21 103.335V103.454H100.537V103.335H100.21ZM99.5118 104.211H99.9872V104.687L99.5118 104.687V104.211ZM99.5118 104.925V105.4H99.9872V104.925H99.5118ZM100.225 104.925V105.4H100.7V104.925H100.225ZM100.7 104.687V104.211H100.225V104.687H100.7ZM100.047 104.375H100.166V104.702H100.136V104.91H100.166V105.236H100.047V104.91H100.076V104.702H100.047V104.375ZM99.6753 104.746V104.865H100.002V104.746H99.6753ZM100.21 104.865V104.746H100.537V104.865H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 105.9H89.2753V106.376L88.7998 106.376V105.9ZM88.7998 106.614V107.089H89.2753V106.614H88.7998ZM89.513 106.614V107.089H89.9881V106.614H89.513ZM89.9881 106.376V105.9H89.513V106.376L89.9881 106.376ZM89.3348 106.064H89.4536V106.391H89.4236V106.599H89.4536V106.926H89.3348V106.599H89.3642V106.391H89.3348V106.064ZM88.9629 106.435V106.554H89.2897V106.435H88.9629ZM89.4981 106.554V106.435H89.8249V106.554H89.4981ZM88.7998 107.312H89.2753V107.787H88.7998V107.312ZM88.7998 108.025V108.5H89.2753V108.025H88.7998ZM89.513 108.025V108.5H89.9881V108.025H89.513ZM89.9881 107.787V107.312H89.513V107.787L89.9881 107.787ZM89.3348 107.475H89.4536V107.802H89.4236V108.01H89.4536V108.337H89.3348V108.01H89.3642V107.802H89.3348V107.475ZM88.9629 107.847V107.966H89.2897V107.847H88.9629ZM89.4981 107.966V107.847H89.8249V107.966H89.4981ZM90.6869 105.9H90.2115V106.376L90.6869 106.376V105.9ZM90.2115 107.089V106.614H90.6869V107.089H90.2115ZM90.9246 107.089V106.614H91.3998V107.089H90.9246ZM91.3998 105.9V106.376L90.9246 106.376V105.9H91.3998ZM90.8653 106.064H90.7465V106.391H90.7759V106.599H90.7465V106.926H90.8653V106.599H90.8353V106.391H90.8653V106.064ZM90.375 106.554V106.435H90.7018V106.554H90.375ZM90.9095 106.435V106.554H91.2363V106.435H90.9095ZM90.2115 107.312H90.6869V107.787L90.2115 107.787V107.312ZM90.2115 108.025V108.5H90.6869V108.025H90.2115ZM90.9246 108.025V108.5H91.3998V108.025H90.9246ZM91.3998 107.787V107.312H90.9246V107.787H91.3998ZM90.7465 107.475H90.8653V107.802H90.8353V108.01H90.8653V108.337H90.7465V108.01H90.7759V107.802H90.7465V107.475ZM90.375 107.847V107.966H90.7018V107.847H90.375ZM90.9095 107.966V107.847H91.2363V107.966H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 105.9H92.3754V106.376L91.8999 106.376V105.9ZM91.8999 106.614V107.089H92.3754V106.614H91.8999ZM92.6131 106.614V107.089H93.0882V106.614H92.6131ZM93.0882 106.376V105.9H92.6131V106.376L93.0882 106.376ZM92.4349 106.064H92.5537V106.391H92.5237V106.599H92.5537V106.926H92.4349V106.599H92.4643V106.391H92.4349V106.064ZM92.063 106.435V106.554H92.3898V106.435H92.063ZM92.5982 106.554V106.435H92.925V106.554H92.5982ZM91.8999 107.312H92.3753V107.787H91.8999V107.312ZM91.8999 108.025V108.5H92.3753V108.025H91.8999ZM92.6131 108.025V108.5H93.0882V108.025H92.6131ZM93.0882 107.787V107.312H92.6131V107.787L93.0882 107.787ZM92.4349 107.475H92.5537V107.802H92.5237V108.01H92.5537V108.337H92.4349V108.01H92.4643V107.802H92.4349V107.475ZM92.063 107.847V107.966H92.3898V107.847H92.063ZM92.5982 107.966V107.847H92.925V107.966H92.5982ZM93.787 105.9H93.3116V106.376L93.787 106.376V105.9ZM93.3116 107.089V106.614H93.787V107.089H93.3116ZM94.0247 107.089V106.614H94.4999V107.089H94.0247ZM94.4999 105.9V106.376L94.0247 106.376V105.9H94.4999ZM93.9654 106.064H93.8466V106.391H93.876V106.599H93.8466V106.926H93.9654V106.599H93.9354V106.391H93.9654V106.064ZM93.4751 106.554V106.435H93.8019V106.554H93.4751ZM94.0096 106.435V106.554H94.3364V106.435H94.0096ZM93.3116 107.312H93.787V107.787L93.3116 107.787V107.312ZM93.3116 108.025V108.5H93.787V108.025H93.3116ZM94.0247 108.025V108.5H94.4999V108.025H94.0247ZM94.4999 107.787V107.312H94.0247V107.787H94.4999ZM93.8466 107.475H93.9654V107.802H93.9354V108.01H93.9654V108.337H93.8466V108.01H93.876V107.802H93.8466V107.475ZM93.4751 107.847V107.966H93.8019V107.847H93.4751ZM94.0096 107.966V107.847H94.3364V107.966H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 105.9H95.4755V106.376L95 106.376V105.9ZM95 106.614V107.089H95.4755V106.614H95ZM95.7132 106.614V107.089H96.1883V106.614H95.7132ZM96.1883 106.376V105.9H95.7132V106.376L96.1883 106.376ZM95.535 106.064H95.6538V106.391H95.6238V106.599H95.6538V106.926H95.535V106.599H95.5644V106.391H95.535V106.064ZM95.1631 106.435V106.554H95.4899V106.435H95.1631ZM95.6983 106.554V106.435H96.0251V106.554H95.6983ZM95 107.312H95.4754V107.787H95V107.312ZM95 108.025V108.5H95.4754V108.025H95ZM95.7132 108.025V108.5H96.1883V108.025H95.7132ZM96.1883 107.787V107.312H95.7132V107.787L96.1883 107.787ZM95.535 107.475H95.6538V107.802H95.6238V108.01H95.6538V108.337H95.535V108.01H95.5644V107.802H95.535V107.475ZM95.1631 107.847V107.966H95.4899V107.847H95.1631ZM95.6983 107.966V107.847H96.0251V107.966H95.6983ZM96.8871 105.9H96.4117V106.376L96.8871 106.376V105.9ZM96.4117 107.089V106.614H96.8871V107.089H96.4117ZM97.1248 107.089V106.614H97.6V107.089H97.1248ZM97.6 105.9V106.376L97.1248 106.376V105.9H97.6ZM97.0655 106.064H96.9467V106.391H96.9761V106.599H96.9467V106.926H97.0655V106.599H97.0355V106.391H97.0655V106.064ZM96.5752 106.554V106.435H96.902V106.554H96.5752ZM97.1097 106.435V106.554H97.4365V106.435H97.1097ZM96.4117 107.312H96.8871V107.787L96.4117 107.787V107.312ZM96.4117 108.025V108.5H96.8871V108.025H96.4117ZM97.1248 108.025V108.5H97.6V108.025H97.1248ZM97.6 107.787V107.312H97.1248V107.787H97.6ZM96.9467 107.475H97.0655V107.802H97.0355V108.01H97.0655V108.337H96.9467V108.01H96.9761V107.802H96.9467V107.475ZM96.5752 107.847V107.966H96.902V107.847H96.5752ZM97.1097 107.966V107.847H97.4365V107.966H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 105.9H98.5756V106.376L98.1001 106.376V105.9ZM98.1001 106.614V107.089H98.5756V106.614H98.1001ZM98.8133 106.614V107.089H99.2884V106.614H98.8133ZM99.2884 106.376V105.9H98.8133V106.376L99.2884 106.376ZM98.6351 106.064H98.7539V106.391H98.7239V106.599H98.7539V106.926H98.6351V106.599H98.6645V106.391H98.6351V106.064ZM98.2632 106.435V106.554H98.59V106.435H98.2632ZM98.7984 106.554V106.435H99.1252V106.554H98.7984ZM98.1001 107.312H98.5755V107.787H98.1001V107.312ZM98.1001 108.025V108.5H98.5755V108.025H98.1001ZM98.8133 108.025V108.5H99.2884V108.025H98.8133ZM99.2884 107.787V107.312H98.8133V107.787L99.2884 107.787ZM98.6351 107.475H98.7539V107.802H98.7239V108.01H98.7539V108.337H98.6351V108.01H98.6645V107.802H98.6351V107.475ZM98.2632 107.847V107.966H98.59V107.847H98.2632ZM98.7984 107.966V107.847H99.1252V107.966H98.7984ZM99.9872 105.9H99.5118V106.376L99.9872 106.376V105.9ZM99.5118 107.089V106.614H99.9872V107.089H99.5118ZM100.225 107.089V106.614H100.7V107.089H100.225ZM100.7 105.9V106.376L100.225 106.376V105.9H100.7ZM100.166 106.064H100.047V106.391H100.076V106.599H100.047V106.926H100.166V106.599H100.136V106.391H100.166V106.064ZM99.6753 106.554V106.435H100.002V106.554H99.6753ZM100.21 106.435V106.554H100.537V106.435H100.21ZM99.5118 107.312H99.9872V107.787L99.5118 107.787V107.312ZM99.5118 108.025V108.5H99.9872V108.025H99.5118ZM100.225 108.025V108.5H100.7V108.025H100.225ZM100.7 107.787V107.312H100.225V107.787H100.7ZM100.047 107.475H100.166V107.802H100.136V108.01H100.166V108.337H100.047V108.01H100.076V107.802H100.047V107.475ZM99.6753 107.847V107.966H100.002V107.847H99.6753ZM100.21 107.966V107.847H100.537V107.966H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 109H89.2753V109.475L88.7998 109.475V109ZM88.7998 109.713V110.188H89.2753V109.713H88.7998ZM89.513 109.713V110.188H89.9881V109.713H89.513ZM89.9881 109.475V109H89.513V109.475L89.9881 109.475ZM89.3348 109.163H89.4536V109.49H89.4236V109.698H89.4536V110.025H89.3348V109.698H89.3642V109.49H89.3348V109.163ZM88.9629 109.535V109.654H89.2897V109.535H88.9629ZM89.4981 109.654V109.535H89.8249V109.654H89.4981ZM88.7998 110.412H89.2753V110.887H88.7998V110.412ZM88.7998 111.125V111.6H89.2753V111.125H88.7998ZM89.513 111.125V111.6H89.9881V111.125H89.513ZM89.9881 110.887V110.412H89.513V110.887L89.9881 110.887ZM89.3348 110.575H89.4536V110.902H89.4236V111.11H89.4536V111.437H89.3348V111.11H89.3642V110.902H89.3348V110.575ZM88.9629 110.946V111.065H89.2897V110.946H88.9629ZM89.4981 111.065V110.946H89.8249V111.065H89.4981ZM90.6869 109H90.2115V109.475L90.6869 109.475V109ZM90.2115 110.188V109.713H90.6869V110.188H90.2115ZM90.9246 110.188V109.713H91.3998V110.188H90.9246ZM91.3998 109V109.475L90.9246 109.475V109H91.3998ZM90.8653 109.163H90.7465V109.49H90.7759V109.698H90.7465V110.025H90.8653V109.698H90.8353V109.49H90.8653V109.163ZM90.375 109.654V109.535H90.7018V109.654H90.375ZM90.9095 109.535V109.654H91.2363V109.535H90.9095ZM90.2115 110.412H90.6869V110.887L90.2115 110.887V110.412ZM90.2115 111.125V111.6H90.6869V111.125H90.2115ZM90.9246 111.125V111.6H91.3998V111.125H90.9246ZM91.3998 110.887V110.412H90.9246V110.887H91.3998ZM90.7465 110.575H90.8653V110.902H90.8353V111.11H90.8653V111.437H90.7465V111.11H90.7759V110.902H90.7465V110.575ZM90.375 110.947V111.065H90.7018V110.947H90.375ZM90.9095 111.065V110.947H91.2363V111.065H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 109H92.3754V109.475L91.8999 109.475V109ZM91.8999 109.713V110.188H92.3754V109.713H91.8999ZM92.6131 109.713V110.188H93.0882V109.713H92.6131ZM93.0882 109.475V109H92.6131V109.475L93.0882 109.475ZM92.4349 109.163H92.5537V109.49H92.5237V109.698H92.5537V110.025H92.4349V109.698H92.4643V109.49H92.4349V109.163ZM92.063 109.535V109.654H92.3898V109.535H92.063ZM92.5982 109.654V109.535H92.925V109.654H92.5982ZM91.8999 110.412H92.3753V110.887H91.8999V110.412ZM91.8999 111.125V111.6H92.3753V111.125H91.8999ZM92.6131 111.125V111.6H93.0882V111.125H92.6131ZM93.0882 110.887V110.412H92.6131V110.887L93.0882 110.887ZM92.4349 110.575H92.5537V110.902H92.5237V111.11H92.5537V111.437H92.4349V111.11H92.4643V110.902H92.4349V110.575ZM92.063 110.946V111.065H92.3898V110.946H92.063ZM92.5982 111.065V110.946H92.925V111.065H92.5982ZM93.787 109H93.3116V109.475L93.787 109.475V109ZM93.3116 110.188V109.713H93.787V110.188H93.3116ZM94.0247 110.188V109.713H94.4999V110.188H94.0247ZM94.4999 109V109.475L94.0247 109.475V109H94.4999ZM93.9654 109.163H93.8466V109.49H93.876V109.698H93.8466V110.025H93.9654V109.698H93.9354V109.49H93.9654V109.163ZM93.4751 109.654V109.535H93.8019V109.654H93.4751ZM94.0096 109.535V109.654H94.3364V109.535H94.0096ZM93.3116 110.412H93.787V110.887L93.3116 110.887V110.412ZM93.3116 111.125V111.6H93.787V111.125H93.3116ZM94.0247 111.125V111.6H94.4999V111.125H94.0247ZM94.4999 110.887V110.412H94.0247V110.887H94.4999ZM93.8466 110.575H93.9654V110.902H93.9354V111.11H93.9654V111.437H93.8466V111.11H93.876V110.902H93.8466V110.575ZM93.4751 110.947V111.065H93.8019V110.947H93.4751ZM94.0096 111.065V110.947H94.3364V111.065H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 109H95.4755V109.475L95 109.475V109ZM95 109.713V110.188H95.4755V109.713H95ZM95.7132 109.713V110.188H96.1883V109.713H95.7132ZM96.1883 109.475V109H95.7132V109.475L96.1883 109.475ZM95.535 109.163H95.6538V109.49H95.6238V109.698H95.6538V110.025H95.535V109.698H95.5644V109.49H95.535V109.163ZM95.1631 109.535V109.654H95.4899V109.535H95.1631ZM95.6983 109.654V109.535H96.0251V109.654H95.6983ZM95 110.412H95.4754V110.887H95V110.412ZM95 111.125V111.6H95.4754V111.125H95ZM95.7132 111.125V111.6H96.1883V111.125H95.7132ZM96.1883 110.887V110.412H95.7132V110.887L96.1883 110.887ZM95.535 110.575H95.6538V110.902H95.6238V111.11H95.6538V111.437H95.535V111.11H95.5644V110.902H95.535V110.575ZM95.1631 110.946V111.065H95.4899V110.946H95.1631ZM95.6983 111.065V110.946H96.0251V111.065H95.6983ZM96.8871 109H96.4117V109.475L96.8871 109.475V109ZM96.4117 110.188V109.713H96.8871V110.188H96.4117ZM97.1248 110.188V109.713H97.6V110.188H97.1248ZM97.6 109V109.475L97.1248 109.475V109H97.6ZM97.0655 109.163H96.9467V109.49H96.9761V109.698H96.9467V110.025H97.0655V109.698H97.0355V109.49H97.0655V109.163ZM96.5752 109.654V109.535H96.902V109.654H96.5752ZM97.1097 109.535V109.654H97.4365V109.535H97.1097ZM96.4117 110.412H96.8871V110.887L96.4117 110.887V110.412ZM96.4117 111.125V111.6H96.8871V111.125H96.4117ZM97.1248 111.125V111.6H97.6V111.125H97.1248ZM97.6 110.887V110.412H97.1248V110.887H97.6ZM96.9467 110.575H97.0655V110.902H97.0355V111.11H97.0655V111.437H96.9467V111.11H96.9761V110.902H96.9467V110.575ZM96.5752 110.947V111.065H96.902V110.947H96.5752ZM97.1097 111.065V110.947H97.4365V111.065H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 109H98.5756V109.475L98.1001 109.475V109ZM98.1001 109.713V110.188H98.5756V109.713H98.1001ZM98.8133 109.713V110.188H99.2884V109.713H98.8133ZM99.2884 109.475V109H98.8133V109.475L99.2884 109.475ZM98.6351 109.163H98.7539V109.49H98.7239V109.698H98.7539V110.025H98.6351V109.698H98.6645V109.49H98.6351V109.163ZM98.2632 109.535V109.654H98.59V109.535H98.2632ZM98.7984 109.654V109.535H99.1252V109.654H98.7984ZM98.1001 110.412H98.5755V110.887H98.1001V110.412ZM98.1001 111.125V111.6H98.5755V111.125H98.1001ZM98.8133 111.125V111.6H99.2884V111.125H98.8133ZM99.2884 110.887V110.412H98.8133V110.887L99.2884 110.887ZM98.6351 110.575H98.7539V110.902H98.7239V111.11H98.7539V111.437H98.6351V111.11H98.6645V110.902H98.6351V110.575ZM98.2632 110.946V111.065H98.59V110.946H98.2632ZM98.7984 111.065V110.946H99.1252V111.065H98.7984ZM99.9872 109H99.5118V109.475L99.9872 109.475V109ZM99.5118 110.188V109.713H99.9872V110.188H99.5118ZM100.225 110.188V109.713H100.7V110.188H100.225ZM100.7 109V109.475L100.225 109.475V109H100.7ZM100.166 109.163H100.047V109.49H100.076V109.698H100.047V110.025H100.166V109.698H100.136V109.49H100.166V109.163ZM99.6753 109.654V109.535H100.002V109.654H99.6753ZM100.21 109.535V109.654H100.537V109.535H100.21ZM99.5118 110.412H99.9872V110.887L99.5118 110.887V110.412ZM99.5118 111.125V111.6H99.9872V111.125H99.5118ZM100.225 111.125V111.6H100.7V111.125H100.225ZM100.7 110.887V110.412H100.225V110.887H100.7ZM100.047 110.575H100.166V110.902H100.136V111.11H100.166V111.437H100.047V111.11H100.076V110.902H100.047V110.575ZM99.6753 110.947V111.065H100.002V110.947H99.6753ZM100.21 111.065V110.947H100.537V111.065H100.21Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7998 112.1H89.2753V112.575L88.7998 112.575V112.1ZM88.7998 112.813V113.288H89.2753V112.813H88.7998ZM89.513 112.813V113.288H89.9881V112.813H89.513ZM89.9881 112.575V112.1H89.513V112.575L89.9881 112.575ZM89.3348 112.263H89.4536V112.59H89.4236V112.798H89.4536V113.125H89.3348V112.798H89.3642V112.59H89.3348V112.263ZM88.9629 112.635V112.753H89.2897V112.635H88.9629ZM89.4981 112.753V112.635H89.8249V112.753H89.4981ZM88.7998 113.511H89.2753V113.987H88.7998V113.511ZM88.7998 114.224V114.699H89.2753V114.224H88.7998ZM89.513 114.224V114.699H89.9881V114.224H89.513ZM89.9881 113.987V113.511H89.513V113.987L89.9881 113.987ZM89.3348 113.675H89.4536V114.001H89.4236V114.209H89.4536V114.536H89.3348V114.209H89.3642V114.001H89.3348V113.675ZM88.9629 114.046V114.165H89.2897V114.046H88.9629ZM89.4981 114.165V114.046H89.8249V114.165H89.4981ZM90.6869 112.1H90.2115V112.575L90.6869 112.575V112.1ZM90.2115 113.288V112.813H90.6869V113.288H90.2115ZM90.9246 113.288V112.813H91.3998V113.288H90.9246ZM91.3998 112.1V112.575L90.9246 112.575V112.1H91.3998ZM90.8653 112.263H90.7465V112.59H90.7759V112.798H90.7465V113.125H90.8653V112.798H90.8353V112.59H90.8653V112.263ZM90.375 112.753V112.635H90.7018V112.753H90.375ZM90.9095 112.635V112.753H91.2363V112.635H90.9095ZM90.2115 113.511H90.6869V113.987L90.2115 113.987V113.511ZM90.2115 114.224V114.699H90.6869V114.224H90.2115ZM90.9246 114.224V114.699H91.3998V114.224H90.9246ZM91.3998 113.987V113.511H90.9246V113.987H91.3998ZM90.7465 113.675H90.8653V114.001H90.8353V114.21H90.8653V114.536H90.7465V114.21H90.7759V114.001H90.7465V113.675ZM90.375 114.046V114.165H90.7018V114.046H90.375ZM90.9095 114.165V114.046H91.2363V114.165H90.9095Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.8999 112.1H92.3754V112.575L91.8999 112.575V112.1ZM91.8999 112.813V113.288H92.3754V112.813H91.8999ZM92.6131 112.813V113.288H93.0882V112.813H92.6131ZM93.0882 112.575V112.1H92.6131V112.575L93.0882 112.575ZM92.4349 112.263H92.5537V112.59H92.5237V112.798H92.5537V113.125H92.4349V112.798H92.4643V112.59H92.4349V112.263ZM92.063 112.635V112.753H92.3898V112.635H92.063ZM92.5982 112.753V112.635H92.925V112.753H92.5982ZM91.8999 113.511H92.3753V113.987H91.8999V113.511ZM91.8999 114.224V114.699H92.3753V114.224H91.8999ZM92.6131 114.224V114.699H93.0882V114.224H92.6131ZM93.0882 113.987V113.511H92.6131V113.987L93.0882 113.987ZM92.4349 113.675H92.5537V114.001H92.5237V114.209H92.5537V114.536H92.4349V114.209H92.4643V114.001H92.4349V113.675ZM92.063 114.046V114.165H92.3898V114.046H92.063ZM92.5982 114.165V114.046H92.925V114.165H92.5982ZM93.787 112.1H93.3116V112.575L93.787 112.575V112.1ZM93.3116 113.288V112.813H93.787V113.288H93.3116ZM94.0247 113.288V112.813H94.4999V113.288H94.0247ZM94.4999 112.1V112.575L94.0247 112.575V112.1H94.4999ZM93.9654 112.263H93.8466V112.59H93.876V112.798H93.8466V113.125H93.9654V112.798H93.9354V112.59H93.9654V112.263ZM93.4751 112.753V112.635H93.8019V112.753H93.4751ZM94.0096 112.635V112.753H94.3364V112.635H94.0096ZM93.3116 113.511H93.787V113.987L93.3116 113.987V113.511ZM93.3116 114.224V114.699H93.787V114.224H93.3116ZM94.0247 114.224V114.699H94.4999V114.224H94.0247ZM94.4999 113.987V113.511H94.0247V113.987H94.4999ZM93.8466 113.675H93.9654V114.001H93.9354V114.21H93.9654V114.536H93.8466V114.21H93.876V114.001H93.8466V113.675ZM93.4751 114.046V114.165H93.8019V114.046H93.4751ZM94.0096 114.165V114.046H94.3364V114.165H94.0096Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95 112.1H95.4755V112.575L95 112.575V112.1ZM95 112.813V113.288H95.4755V112.813H95ZM95.7132 112.813V113.288H96.1883V112.813H95.7132ZM96.1883 112.575V112.1H95.7132V112.575L96.1883 112.575ZM95.535 112.263H95.6538V112.59H95.6238V112.798H95.6538V113.125H95.535V112.798H95.5644V112.59H95.535V112.263ZM95.1631 112.635V112.753H95.4899V112.635H95.1631ZM95.6983 112.753V112.635H96.0251V112.753H95.6983ZM95 113.511H95.4754V113.987H95V113.511ZM95 114.224V114.699H95.4754V114.224H95ZM95.7132 114.224V114.699H96.1883V114.224H95.7132ZM96.1883 113.987V113.511H95.7132V113.987L96.1883 113.987ZM95.535 113.675H95.6538V114.001H95.6238V114.209H95.6538V114.536H95.535V114.209H95.5644V114.001H95.535V113.675ZM95.1631 114.046V114.165H95.4899V114.046H95.1631ZM95.6983 114.165V114.046H96.0251V114.165H95.6983ZM96.8871 112.1H96.4117V112.575L96.8871 112.575V112.1ZM96.4117 113.288V112.813H96.8871V113.288H96.4117ZM97.1248 113.288V112.813H97.6V113.288H97.1248ZM97.6 112.1V112.575L97.1248 112.575V112.1H97.6ZM97.0655 112.263H96.9467V112.59H96.9761V112.798H96.9467V113.125H97.0655V112.798H97.0355V112.59H97.0655V112.263ZM96.5752 112.753V112.635H96.902V112.753H96.5752ZM97.1097 112.635V112.753H97.4365V112.635H97.1097ZM96.4117 113.511H96.8871V113.987L96.4117 113.987V113.511ZM96.4117 114.224V114.699H96.8871V114.224H96.4117ZM97.1248 114.224V114.699H97.6V114.224H97.1248ZM97.6 113.987V113.511H97.1248V113.987H97.6ZM96.9467 113.675H97.0655V114.001H97.0355V114.21H97.0655V114.536H96.9467V114.21H96.9761V114.001H96.9467V113.675ZM96.5752 114.046V114.165H96.902V114.046H96.5752ZM97.1097 114.165V114.046H97.4365V114.165H97.1097Z'
          fill='#F2C94C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M98.1001 112.1H98.5756V112.575L98.1001 112.575V112.1ZM98.1001 112.813V113.288H98.5756V112.813H98.1001ZM98.8133 112.813V113.288H99.2884V112.813H98.8133ZM99.2884 112.575V112.1H98.8133V112.575L99.2884 112.575ZM98.6351 112.263H98.7539V112.59H98.7239V112.798H98.7539V113.125H98.6351V112.798H98.6645V112.59H98.6351V112.263ZM98.2632 112.635V112.753H98.59V112.635H98.2632ZM98.7984 112.753V112.635H99.1252V112.753H98.7984ZM98.1001 113.511H98.5755V113.987H98.1001V113.511ZM98.1001 114.224V114.699H98.5755V114.224H98.1001ZM98.8133 114.224V114.699H99.2884V114.224H98.8133ZM99.2884 113.987V113.511H98.8133V113.987L99.2884 113.987ZM98.6351 113.675H98.7539V114.001H98.7239V114.209H98.7539V114.536H98.6351V114.209H98.6645V114.001H98.6351V113.675ZM98.2632 114.046V114.165H98.59V114.046H98.2632ZM98.7984 114.165V114.046H99.1252V114.165H98.7984ZM99.9872 112.1H99.5118V112.575L99.9872 112.575V112.1ZM99.5118 113.288V112.813H99.9872V113.288H99.5118ZM100.225 113.288V112.813H100.7V113.288H100.225ZM100.7 112.1V112.575L100.225 112.575V112.1H100.7ZM100.166 112.263H100.047V112.59H100.076V112.798H100.047V113.125H100.166V112.798H100.136V112.59H100.166V112.263ZM99.6753 112.753V112.635H100.002V112.753H99.6753ZM100.21 112.635V112.753H100.537V112.635H100.21ZM99.5118 113.511H99.9872V113.987L99.5118 113.987V113.511ZM99.5118 114.224V114.699H99.9872V114.224H99.5118ZM100.225 114.224V114.699H100.7V114.224H100.225ZM100.7 113.987V113.511H100.225V113.987H100.7ZM100.047 113.675H100.166V114.001H100.136V114.21H100.166V114.536H100.047V114.21H100.076V114.001H100.047V113.675ZM99.6753 114.046V114.165H100.002V114.046H99.6753ZM100.21 114.165V114.046H100.537V114.165H100.21Z'
          fill='#F2C94C'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default Array
