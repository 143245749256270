import React from 'react'
import { useDispatch } from 'react-redux'

import Channel from 'src/containers/operating/device/beamformers/bbox/DeviceController/Channel'
import BeamSteering from 'src/containers/operating/device/beamformers/bbox/DeviceController/BeamSteering'
import Common from 'src/containers/operating/device/beamformers/bbox/DeviceController/Common'

import SegmentedButton from 'src/components/Button/SegmentedButton'
import CoBrandingBar from 'src/components/CoBrandingBar'

import { changePadControlContent } from 'src/redux/slices/uiControl/beamformers/bbox'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetUIControl from 'src/hooks/useGetUIControl'

const DeviceController = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const { single: s_uiControl } = useGetUIControl(sn)

  const { isSM, isMD, isLG, isXL, is2XL, isMobile, isTablet, isDesktop } =
    useGetScreenSize()

  // padControlContent 跟 deviceData 裡面的 currentControlMode 分開，
  // 因為如果切換到 steering 就直接 設定 theta phi 洗掉 channel
  // UX 上可能會被痛恨(畢竟這樣 steering 的警語在 pad 上就白設計了)
  const padControlContent = s_uiControl?.deviceControl.padControlContent

  // pc 可以視窗化後拖大拖小，而且使用者蠻常用的
  const isPcSM = isDesktop && isSM
  const isPcMid = isDesktop && (isMD || isLG)
  const isPcBig = isDesktop && (isXL || is2XL)

  // ==================================
  //  直立式但沒有 tabs 跟 channel
  // ==================================
  if (isMobile || isPcSM)
    return (
      <div className={lgWidthBox}>
        <CoBrandingBar />

        <Common />

        <BeamSteering />
      </div>
    )

  // ==================================
  // 直立式 有 tabs 跟 channel
  // ==================================
  if (isTablet || isPcMid)
    return (
      <div className={lgWidthBox}>
        <CoBrandingBar />

        <Common />

        <SegmentedButton
          size='md'
          type='solid'
          value={padControlContent}
          onChange={(event, value) =>
            dispatch(changePadControlContent({ sn, value }))
          }>
          <SegmentedButton.Option name='Beam Steering'>
            Beam Steering
          </SegmentedButton.Option>
          <SegmentedButton.Option name='Channel Control'>
            Channel Control
          </SegmentedButton.Option>
        </SegmentedButton>

        {padControlContent === 'Beam Steering' && <BeamSteering />}

        {padControlContent === 'Channel Control' && <Channel />}
      </div>
    )

  // ==================================
  // 橫式 標準版 有 tabs 跟 channel
  // ==================================
  if (isPcBig)
    return (
      <div className='flex gap-x-4'>
        <div className={smWidthBox}>
          <CoBrandingBar />

          <Common />
          <BeamSteering />
        </div>

        <div className={lgWidthBox}>
          <Channel />
        </div>
      </div>
    )
}

export default DeviceController

const smWidthBox = `max-w-[416px] w-full flex flex-col gap-y-6 xl:gap-y-4`
const lgWidthBox = `max-w-[632px] w-full flex flex-col gap-y-6 xl:gap-y-4`
