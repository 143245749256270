import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Button from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

const Notification = memo(({ sn, data = {} }) => {
  const dispatch = useDispatch()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        Func        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const dataKeys = Object.keys(data)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const clickList = {
    isFirmwareUpdate: () => {
      alert('firmwareUpdate')
    },
    isRecaliReminder: () =>
      dispatch(modalActions.showModalOfHomeCaliReminder()),
  }
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      {dataKeys.map(
        (item, index) =>
          data[item] && (
            <Button
              key={sn + item}
              type={item === 'isRecaliReminder' ? 'outlined' : 'primary'}
              size='sm'
              className='text-center'
              onClick={e => {
                // 外層有包 react-router link, 用 preventDefault 才能阻止跳轉
                e.preventDefault()
                clickList[item]()
              }}>
              {textList[item]}
            </Button>
          )
      )}
    </div>
  )
})

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `px-7 pb-7 flex flex-col gap-y-4`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static  Func    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const textList = {
  isFirmwareUpdate: 'New Firmware is available',
  isRecaliReminder: 'Regular calibration reminder',
}

export default Notification

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
Notification.propTypes = { data: PropTypes.object }
