import { put } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { addUiControl as powerDetectorAddUIControl } from 'src/redux/slices/uiControl/powerDetector'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* init(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]

    yield put(powerDetectorAddUIControl({ sn }))

    yield put(setSingleDeviceData({ sn, data: deviceData }))
  } catch (error) {
    devWarLog('[res-handler] init error:', error)
  }
}
