import React from 'react'

import Body from 'src/assets/svg/device/avatar/Ud00ef/Body'
import UDInterface from 'src/assets/svg/device/avatar/Ud00ef/UDInterface'
import LEDLights from 'src/assets/svg/device/avatar/Ud00ef/LEDLights'

const Ud00ef = props => {
  const { channel } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                          relative 
                          w-full h-full 
                          scale-75 group-hover:scale-100 
                          saturate-0 group-hover:saturate-200 
                          duration-1000
                          `
  const bodyClass = `absolute inset-0 m-auto`
  const udInterfaceClass = `absolute inset-0 m-auto`
  const ledLightsClass = `absolute 
                          inset-0 m-auto 
                          grayscale group-hover:grayscale-0 
                          opacity-30 group-hover:opacity-100 
                          duration-1000
                          `
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={containerClass}>
      <Body className={bodyClass} />
      <UDInterface className={udInterfaceClass} channel={channel} />
      <LEDLights className={ledLightsClass} channel={channel} />
    </div>
  )
}
export default Ud00ef
