import React from 'react'
import { useDispatch } from 'react-redux'
import CommonSwitch from 'src/components/Switch/CommonSwitch'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import { cloverCell1024_common_externalControlChange_watcher } from 'src/redux/actions/beamformers/cloverCellArray1024Action'

const ExternalControl = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()

  const sn = current?.sn
  const isExternalControl = current.data.deviceControl.common?.isExternalControl

  const handleClick = () =>
    dispatch(
      cloverCell1024_common_externalControlChange_watcher({
        sn,
      })
    )
  return (
    <div className='flex justify-end'>
      <CommonSwitch
        type='text'
        onText='External Control '
        offText='External Control'
        size='sm'
        on={isExternalControl}
        onClick={handleClick}
      />
    </div>
  )
}

export default ExternalControl
