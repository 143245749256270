import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'
import { useSelector } from 'react-redux'

import Card from 'src/containers/home/Main/Card'

import NoDeviceFound from 'src/containers/home/NoDeviceFound'

const Main = ({ showInfo }) => {
  const deviceData = useSelector(state => state.deviceData)
  const deviceDataLength = Object.entries(deviceData).length

  const noDeviceData = R.pipe(R.keys, R.length, R.equals(0))(deviceData)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // sm:pl-2 是為了補手機被 scrollbar 往左推造成左右不對稱
  // min-h 的兩個 -88px 是扣掉 header 跟外層的 padding-bottom (就不會在單台的時候出現 Scrollbar)
  const flexItems = showInfo ? 'items-start ' : 'items-center '
  const container = `[Home-container]
                    ${maxWidth(deviceDataLength)} 
                    ${flexItems} 
                    relative z-0 
                    w-[calc(100vw_-_120px)] 
                    md:w-[calc(100vw_-_140px)] 
                    lg:w-[calc(100vw_-_160px)] 
                    min-h-[calc(100vh_-_88px_-_88px)] 
                    sm:pl-2
                    mx-auto 
                    flex flex-wrap 
                    justify-center 
                    gap-x-8 gap-y-12 
                    `

  if (noDeviceData) return <NoDeviceFound />

  return (
    <main className={container}>
      {Object.entries(deviceData).map(([sn, value]) => (
        <Card
          {...value.info}
          sn={sn}
          key={'home online card' + sn}
          showInfo={showInfo}
        />
      ))}
    </main>
  )
}

export default Main

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const maxWidth = length =>
  length === 4 ? `max-w-[800px] ` : ` max-w-[1152px] `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

Main.propTypes = { showInfo: PropTypes.bool }
