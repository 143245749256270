import { createSlice, current } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    lookupID: null,
  },
  reducers: {
    setLookupID(state, action) {
      const { lookupID } = action.payload
      return { ...current(state), lookupID }
    },
  },
})

export const { setLookupID } = userSlice.actions

export const user = userSlice.reducer
