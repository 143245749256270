import React from 'react'

import { h6 } from 'src/containers/operating/device/beamformers/style'

import ImitationTable from 'src/containers/operating/device/beamformers/cloverCell/evb/Current/ImitationTable'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const PowerConsumption = props => {
  const sideLabel = ['Voltage (V)', 'Current (I)', 'Power (P)']

  const { current } = useGetCurrentDeviceData()

  const { powerConsumption } = current.data.current

  const vdd1v8Current = powerConsumption?.vdd1v8?.current?.currentValue
  const vdd1v8Voltage = powerConsumption?.vdd1v8?.voltage?.currentValue
  const vdd1v8Power = powerConsumption?.vdd1v8?.power?.currentValue
  const vddPaCurrent = powerConsumption?.vddPa?.current?.currentValue
  const vddPaVoltage = powerConsumption?.vddPa?.voltage?.currentValue
  const vddPaPower = powerConsumption?.vddPa?.power?.currentValue

  const col1 = {
    title: 'VDD_1v8',
    data: [
      { unit: 'mV', value: vdd1v8Voltage },
      { unit: 'mA', value: vdd1v8Current },
      { unit: 'mW', value: vdd1v8Power },
    ],
  }
  const col2 = {
    title: 'VDDPA',
    data: [
      { unit: 'mV', value: vddPaVoltage },
      { unit: 'mA', value: vddPaCurrent },
      { unit: 'mW', value: vddPaPower },
    ],
  }

  return (
    <div>
      <h6 className={h6 + ' text-white mb-4'}>Power Consumption</h6>

      <ImitationTable
        {...{ name: 'power consumption', sideLabel, col1, col2 }}
      />
    </div>
  )
}

PowerConsumption.propTypes = {}

export default PowerConsumption
