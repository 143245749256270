import {
  getDefaultCurrentFreq,
  getLstFreqOptions,
} from '../../configs/spec.mjs'
import * as R from 'ramda'
import Controllers from '../../Controllers/index.mjs'

const getDT = sn => Controllers._getDeviceType(sn)

const lstBoardDataPackage = {
  // gain
  totalGainMin: -5,
  totalGainMax: 20,
  commonCurrentGain: 15,
  commonGainMin: -5,
  commonGainMax: 15,
  // phase
  totalPhaseMin: 0,
  totalPhaseMax: 355,
  commonCurrentPhase: 0,
  commonPhaseMin: 0,
  commonPhaseMax: 355,

  lstChannelData: [
    {
      totalGain: 20,
      elementGain: 5,
      totalPhase: 0,
      elementPhase: 0,
      power: true,
    },
    {
      totalGain: 20,
      elementGain: 5,
      totalPhase: 0,
      elementPhase: 0,
      power: true,
    },
    {
      totalGain: 20,
      elementGain: 5,
      totalPhase: 0,
      elementPhase: 0,
      power: true,
    },
    {
      totalGain: 20,
      elementGain: 5,
      totalPhase: 0,
      elementPhase: 0,
      power: true,
    },
  ],
}

export const cloverCellEvbInitAppendData = sn => ({
  deviceControl: {
    common: {
      rfMode: 'standby', // tx, rx, standby
      currentFreq: getDefaultCurrentFreq[getDT(sn)],
      lstFreqOptions: getLstFreqOptions[getDT(sn)],
      currentControlMode: { tx: 'channel', rx: 'channel' }, // 'steering' || 'channel'
    },
    // ! 新增
    steering: {
      tx: {
        horizon: {
          theta: 0,
          thetaMax: 60, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
        vertical: {
          theta: 0,
          thetaMax: 60, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
      },
      rx: {
        horizon: {
          theta: 0,
          thetaMax: 40, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
        vertical: {
          theta: 0,
          thetaMax: 40, // 沒天線所以要先定義
          phi: 0,
          beamCurrentGain: 0,
          beamGainMin: 0,
          beamGainMax: 15,
        },
      },
    },
    channel: {
      // ! 改為 bbox 模板
      boardCount: 1, // BE only
      channelCount: 4, // BE only
      tx: {
        horizon: {
          // 原本這層有 power 後來拔掉了
          // 但怕之後會有非 data 欄位，所以一樣保留這層
          lstBoardData: [R.clone(lstBoardDataPackage)],
        },
        vertical: {
          lstBoardData: [R.clone(lstBoardDataPackage)],
        },
      },
      rx: {
        horizon: {
          lstBoardData: [R.clone(lstBoardDataPackage)],
        },
        vertical: {
          lstBoardData: [R.clone(lstBoardDataPackage)],
        },
      },
    },
  },
  current: {
    readMethod: 'single', // single, continuous
    readUpdateRate: 1000, // unit: ms
    measureDataLength: 0,
    temperature: {
      currentValue: -40,
      history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
    },
    pdt: {
      horizon: {
        currentValue: [null, null, null, null],
        history: [[], [], [], []], // evb: BE Only , 之後畫圖 FE BE 都會用
      },
      vertical: {
        currentValue: [null, null, null, null],
        history: [[], [], []], // evb: BE Only , 之後畫圖 FE BE 都會用
      },
    },
    powerConsumption: {
      vdd1v8: {
        // ! 加 1v8
        current: {
          currentValue: null,
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
        voltage: {
          currentValue: null,
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
        power: {
          currentValue: null,
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
      },
      vddPa: {
        // ! 改駝峰
        current: {
          currentValue: null,
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
        voltage: {
          currentValue: null,
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
        power: {
          currentValue: null,
          history: [], // evb: BE Only , 之後畫圖 FE BE 都會用
        },
      },
    },
  },
})
