import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from 'src/components/Button/RadioButton'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <RadioButtonGroup
//   className=''
//   title={} // '' 右邊標題文字
//   content={} // '' 右邊內容文字
//   checked={} // 是否被選取
//   group={} // html radio 裡面的 name, 用來判斷是從哪些去單選
//   name={} // onClick 會回傳的值
//   disabled={}
//   onClick={({ name })=>{}}
// />

const RadioButtonGroup = props => {
  const { className, title, content, checked, group, name, disabled, onClick } =
    props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = ({ name }) => !disabled && onClick({ name })
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const containerClass = `
                    ${className}
                    ${containerCommon} 
                    ${containersBorderColor(checked, disabled)} 
                    ${containerBg(disabled)} 
                    ${cursor(disabled)} 
                    hover:bg-white/10 
                    `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div
      className={containerClass}
      onClick={() => !disabled && handleClick({ name })}>
      <div className={circleBoxClass}>
        <RadioButton
          className='w-[18px] h-[18px]'
          size='md' // 'sm', 'md'
          label='' // 後方挾帶的文字
          checked={checked} // 是否被選取
          disabled={disabled}
          {...{ checked, group, name, disabled }}
          onClick={() => handleClick({ name })}
        />
      </div>

      <div className='absolute inset-x-0 mx-auto'>
        <h4 className={titleClass}>{title}</h4>
        <div>{content}</div>
      </div>
    </div>
  )
}
export default RadioButtonGroup

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = `relative w-[296px] h-[67px] rounded-md flex items-center border-1  border-solid p-2 select-none duration-500`
const containersBorderColor = (checked, disabled) =>
  disabled
    ? `border-transparent`
    : checked
    ? `border-teal-5 hover:border-teal-4`
    : `border-light-5 hover:border-light-5`

const containerBg = disabled => (disabled ? `bg-dark-2` : ``)

const circleBoxClass = `w-[66px] pl-6 pr-0`

const titleClass = `text-center text-base font-medium leading-5 text-light-5`

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  content: PropTypes.node,
  checked: PropTypes.bool,
  group: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
