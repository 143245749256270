import { createSlice, createAction } from '@reduxjs/toolkit'

export const deviceDataSlice = createSlice({
  name: 'deviceData',
  initialState: {},
  reducers: {
    clearDevicesData() {
      return []
    },
    setScanDevicesData(state, action) {
      return action.payload
    },
    setSingleDeviceData(state, action) {
      const { sn, data } = action.payload
      state[sn] = data
      return state
    },
  },
})

export const { clearDevicesData, setScanDevicesData, setSingleDeviceData } =
  deviceDataSlice.actions

export const scanWatcher = createAction('deviceData/scanWatcher')

export default deviceDataSlice.reducer
