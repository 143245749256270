const member = {
  showModalOfMemberChangeProfilePhoto: (state, action) => {
    state.normal = {
      type: 'MEMBER',
      props: { status: 'changeProfilePhoto' },
    }
    return state
  },
  showModalOfMemberChangeName: (state, action) => {
    state.normal = {
      type: 'MEMBER',
      props: { status: 'changeName' },
    }
    return state
  },
  showModalOfMemberChangePassword: (state, action) => {
    state.normal = {
      type: 'MEMBER',
      props: { status: 'changePassword' },
    }
    return state
  },
}
export default member
