const middleware = {
  // 顯示 middleware 錯誤
  showModalOfMidNotRunning: (state, action) => {
    state.normal = { type: 'MIDDLEWARE', props: { status: 'notRunning' } }
    return state
  },
  showModalOfMidConnectAwsFailed: (state, action) => {
    state.normal = {
      type: 'MIDDLEWARE',
      props: { status: 'connectAwsFailed' },
    }
    return state
  },
  showModalOfMidGetInfoFailed: (state, action) => {
    state.normal = { type: 'MIDDLEWARE', props: { status: 'getInfoFailed' } }
    return state
  },
  showModalOfDownloadNewMid: (state, action) => {
    state.normal = { type: 'MIDDLEWARE', props: { status: 'downloadNewMid' } }
    return state
  },
}
export default middleware
