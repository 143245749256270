import React from 'react'

import { h6 } from 'src/containers/operating/device/freqConverter/style'

import SegmentedButton from 'src/components/Button/SegmentedButton'

import useGetScreenSize from 'src/hooks/useGetScreenSize'

const RefSource = ({ value, handleChange }) => {
  const { isSM, isMD } = useGetScreenSize()

  return (
    <div className='px-2'>
      <h6 className={h6 + ' text-light-1 mb-2'}>Reference Signal Source</h6>

      <SegmentedButton
        size={isSM || isMD ? 'md' : 'sm'}
        type='outlined'
        value={+value}
        onChange={(event, value) =>
          handleChange({
            label: 'SOURCE_100M',
            newValue: +value,
          })
        }>
        <SegmentedButton.Option name={0}>Internal</SegmentedButton.Option>
        <SegmentedButton.Option name={1}>External</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

export default RefSource
