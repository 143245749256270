import { all, take, fork } from 'redux-saga/effects'

// action
import { udbox_deviceSettings_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import {
  ud0630_deviceSettings_refSignalSource_change_watcher,
  ud0630_deviceSettings_inputSource_change_watcher,
  ud0630_deviceSettings_outputSignalSource_enable_change_watcher,
  ud0630_deviceSettings_outputSignalSource_selection_change_watcher,
  ud0630_deviceSettings_outputLOFrequency_change_watcher,
} from 'src/redux/actions/freqConverter/udboxAction'

// handlers
import { deviceSetting as handleDeviceSetting } from 'src/redux/sagas/handlers/event/freqConverter/udbox/device'
import {
  refSignalSourceChange as handle_ud0630_deviceSettings_refSignalSource_change,
  inputRefSourceChange as handle_ud0630_deviceSettings_inputRefSource_change,
  outputSignalSourceEnableChange as handle_ud0630_deviceSettings_outputSignalSource_enable_change,
  outputSignalSourceSelectionChange as handle_ud0630_deviceSettings_outputSignalSource_selection_change,
  outputLOFrequencyChange as handle_ud0630_deviceSettings_outputLOFrequency_change,
} from 'src/redux/sagas/handlers/event/freqConverter/udbox0630/device'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      UDBox 5G      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* deviceSetting() {
  while (true) {
    const action = yield take(udbox_deviceSettings_watcher.type)
    // const { sn } = action.payload
    devLog('[watcher] deviceSetting', action.payload)
    yield fork(handleDeviceSetting, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     UDBox 0630     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* ud0630deviceSettingsRefSignalSourceChange() {
  while (true) {
    const action = yield take(
      ud0630_deviceSettings_refSignalSource_change_watcher.type
    )
    // const { sn } = action.payload
    devLog(
      '[watcher] ud0630deviceSettingsRefSignalSourceChange',
      action.payload
    )
    yield fork(
      handle_ud0630_deviceSettings_refSignalSource_change,
      action.payload
    )
  }
}
function* ud0630deviceSettingsInputSourceChange() {
  while (true) {
    const action = yield take(
      ud0630_deviceSettings_inputSource_change_watcher.type
    )
    // const { sn } = action.payload
    devLog('[watcher] ud0630deviceSettingsInputSourceChange', action.payload)
    yield fork(
      handle_ud0630_deviceSettings_inputRefSource_change,
      action.payload
    )
  }
}
function* ud0630deviceSettingsOutputSignalSourceEnableChange() {
  while (true) {
    const action = yield take(
      ud0630_deviceSettings_outputSignalSource_enable_change_watcher.type
    )
    // const { sn } = action.payload
    devLog(
      '[watcher] ud0630deviceSettingsOutputSignalSourceEnableChange',
      action.payload
    )
    yield fork(
      handle_ud0630_deviceSettings_outputSignalSource_enable_change,
      action.payload
    )
  }
}
function* ud0630deviceSettingsOutputSignalSourceSelectionChange() {
  while (true) {
    const action = yield take(
      ud0630_deviceSettings_outputSignalSource_selection_change_watcher.type
    )
    // const { sn } = action.payload
    devLog(
      '[watcher] ud0630deviceSettingsOutputSignalSourceSelectionChange',
      action.payload
    )
    yield fork(
      handle_ud0630_deviceSettings_outputSignalSource_selection_change,
      action.payload
    )
  }
}
function* ud0630deviceSettingsOutputLOFrequencyChange() {
  while (true) {
    const action = yield take(
      ud0630_deviceSettings_outputLOFrequency_change_watcher.type
    )
    // const { sn } = action.payload
    devLog(
      '[watcher] ud0630deviceSettingsOutputLOFrequencyChange',
      action.payload
    )
    yield fork(
      handle_ud0630_deviceSettings_outputLOFrequency_change,
      action.payload
    )
  }
}

export default function* udboxWatchers() {
  yield all([
    deviceSetting(),
    ud0630deviceSettingsRefSignalSourceChange(),
    ud0630deviceSettingsInputSourceChange(),
    ud0630deviceSettingsOutputSignalSourceEnableChange(),
    ud0630deviceSettingsOutputSignalSourceSelectionChange(),
    ud0630deviceSettingsOutputLOFrequencyChange(),
  ])
}
