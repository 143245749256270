import { take, call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { setShowLoadingMask } from 'src/redux/slices/uiControl/beamformers/bbox'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import {
  setCameraArgs,
  setMaskDontShowAgain,
} from 'src/redux/slices/uiControl/beamformers/bbox'

import { bbox_steering_socketEmitting_watcher } from 'src/redux/actions/beamformers/bboxAction'

import { devWarLog } from 'src/funcs/tools'

function* _setCurrentControlModeToSteering({ sn }) {
  let { currentData, currentRfMode } = yield call(getCommonArgs, sn)
  currentData.deviceControl.common.currentControlMode[currentRfMode] =
    'steering'

  yield put(setSingleDeviceData({ sn, data: currentData }))
}

export function* steeringSocketEmitting() {
  while (true) {
    const action = yield take(bbox_steering_socketEmitting_watcher.type)
    const { sn } = action.payload

    yield put(
      setShowLoadingMask({
        sn,
        value: { common: false, steering: false, channel: true },
      })
    )
  }
}

export function* steeringChannelModeMaskClose(payloads) {
  try {
    const { sn, dontShowAgain } = payloads

    let { currentData, currentRfMode, lookupID } = yield call(getCommonArgs, sn)

    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield put(
      setMaskDontShowAgain({ key: 'channelMode', value: dontShowAgain })
    )

    const { theta, phi } = currentData.deviceControl.steering[currentRfMode]

    yield call(_setCurrentControlModeToSteering, { sn })

    yield call(__socket_API_sender, beamformersApi.BEAM_STEERING_THETA_PHI, {
      sn,
      lookupID,
      theta: +theta,
      phi: +phi,
    })
  } catch (error) {
    devWarLog('[handler] steeringChannelModeMaskClose error:', error)
  }
}

export function* steeringThetaPhiChange(payloads) {
  try {
    const { sn, theta, phi, isValid } = payloads

    let { currentData, currentRfMode, lookupID } = yield call(getCommonArgs, sn)
    currentData.deviceControl.steering[currentRfMode].theta = theta
    currentData.deviceControl.steering[currentRfMode].phi = phi

    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid) {
      yield call(_setCurrentControlModeToSteering, { sn })

      yield call(__socket_API_sender, beamformersApi.BEAM_STEERING_THETA_PHI, {
        sn,
        lookupID,
        theta: +theta,
        phi: +phi,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* steering3DCameraChange(action) {
  yield put(setCameraArgs(action.payload))
}

export function* steeringGainChange(payloads) {
  try {
    const { sn, value, isValid } = payloads

    let { currentData, currentRfMode, lookupID } = yield call(getCommonArgs, sn)

    currentData.deviceControl.steering[currentRfMode].beamCurrentGain = value

    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (isValid) {
      yield call(_setCurrentControlModeToSteering, { sn })

      yield call(__socket_API_sender, beamformersApi.BEAM_STEERING_GAIN, {
        sn,
        lookupID,
        value: +value,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringGainChange error:', error)
  }
}
