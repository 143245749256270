import React from 'react'

// import CommonSelect from 'src/components/Select/CommonSelect'

// import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const Frequency = props => {
  // const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const { currentFreq } = current.data.deviceControl.common

  // const isMDSize = isSM || isMD

  // 這邊註解是怕哪天 bboard 要 support 換頻率 就不用重寫
  return (
    <>
      <div className='w-full text-sm text-light-4 leading-6 select-none'>
        {currentFreq} GHz
      </div>

      {/* <CommonSelect
      value={currentFreq}
      size={isMDSize ? 'md' : 'sm'}
      currentChildren={currentFreq + ' GHz'}
      selectClassName={`w-full z-10 `}
      disabled={true}
    /> */}
    </>
  )
}

export default Frequency
