import React from 'react'

import GeneralError from 'src/containers/Modal/Emergency/GeneralError'
import GeneralErrorSendMail from 'src/containers/Modal/Emergency/GeneralErrorSendMail'
import GeneralErrorMailSended from 'src/containers/Modal/Emergency/GeneralErrorMailSended'

import DeviceErrorSendMail from 'src/containers/Modal/Emergency/DeviceErrorSendMail'
import DeviceErrorMailSended from 'src/containers/Modal/Emergency/DeviceErrorMailSended'

import LocalMidSocketDisconnect from 'src/containers/Modal/Emergency/LocalMidSocketDisconnect'

const EmergencyModal = props => {
  const { type: modalType, props: modalProps } = props
  const { status: modalStatus } = modalProps

  if (modalType === 'ERROR' && modalStatus === 'generalError')
    return <GeneralError {...modalProps} />

  if (modalType === 'ERROR' && modalStatus === 'generalErrorSendMail')
    return <GeneralErrorSendMail {...modalProps} />

  if (modalType === 'ERROR' && modalStatus === 'generalErrorMailSended')
    return <GeneralErrorMailSended {...modalProps} />

  if (modalType === 'ERROR' && modalStatus === 'deviceErrorSendMail')
    return <DeviceErrorSendMail {...modalProps} />

  if (modalType === 'ERROR' && modalStatus === 'deviceErrorMailSended')
    return <DeviceErrorMailSended {...modalProps} />

  if (modalType === 'ERROR' && modalStatus === 'localMidSocketDisconnect')
    return <LocalMidSocketDisconnect {...modalProps} />
  return <></>
}

export default EmergencyModal
