import React from 'react'
import { useDispatch } from 'react-redux'

import {
  box,
  subtitle,
} from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/style'

import { sg_deviceSettings_inputSource_selection_change_watcher } from 'src/redux/actions/freqConverter/sgAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import CommonButton from 'src/components/Button/CommonButton'

const InputRefSource = () => {
  const dispatch = useDispatch()
  const { isSM, isMD } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const refSource = current.data.settings.device?.refSource
  const currentSelection =
    current.data.settings.device?.inputRefSource?.currentSelection
  const isOutputting = current.data.settings.output.isOutputting

  const isInternal = +refSource === 0
  const isDisabled = isOutputting

  const handleClick = () =>
    dispatch(sg_deviceSettings_inputSource_selection_change_watcher({ sn }))

  if (isInternal) return <></>

  return (
    <div className={box}>
      <h4 className={subtitle}>Input Source</h4>

      <CommonButton
        size={isSM || isMD ? 'md' : 'sm'}
        type='outlined'
        className='w-full'
        disabled={isDisabled}
        onClick={handleClick}>
        {currentSelection}
      </CommonButton>
    </div>
  )
}
export default InputRefSource
