import { createAction } from '@reduxjs/toolkit'

// sg - output settings
export const sg_outputSettingsApply_click_watcher = createAction(
  'deviceControl/sg_outputSettingsApply_click_watcher'
)

// sg - device settings
export const sg_deviceSettings_refSignalSource_change_watcher = createAction(
  'deviceControl/sg_deviceSettings_refSignalSource_change_watcher'
)
export const sg_deviceSettings_outputSource10MHz_change_watcher = createAction(
  'deviceControl/sg_deviceSettings_outputSource10MHz_change_watcher'
)
export const sg_deviceSettings_inputSource_selection_change_watcher =
  createAction(
    'deviceControl/sg_deviceSettings_inputSource_selection_change_watcher'
  )

// sg- modal event
export const sg_turnOffOutputCheckModal_cancelClick_watcher = createAction(
  'modalEvent/sg_turnOffOutputCheckModal_cancelClick_watcher'
)
export const sg_turnOffOutputCheckModal_okClick_watcher = createAction(
  'modalEvent/sg_turnOffOutputCheckModal_okClick_watcher'
)
export const sg_toExternalModal_useExternalClick_watcher = createAction(
  'modalEvent/sg_toExternalModal_useExternalClick_watcher'
)
export const sg_toExternalModal_cancelClick_watcher = createAction(
  'modalEvent/sg_toExternalModal_cancelClick_watcher'
)
