import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import SwitchGroup from 'src/components/Switch/SwitchGroup'
import InputGroup from 'src/components/Input/InputGroup'
import Theta from 'src/components/composite/InputGroupAppendDialog/Theta'
import Phi from 'src/components/composite/InputGroupAppendDialog/Phi'
import Gain from 'src/components/composite/InputGroupAppendDialog/Gain'

import { getDeviceInfo } from 'src/funcs/getDeviceInfo'

import {
  thetaIcon,
  thetaMin,
  thetaStep,
  thetaUnit,
  thetaDecimalScale,
  phiIcon,
  phiMin,
  phiMax,
  phiStep,
  phiUnit,
  phiDecimalScale,
  gainIcon,
  gainStep,
  gainUnit,
  gainDecimalScale,
  getThetaPhiPhaseWarningText,
  getGainWarningText,
} from 'src/constants/beamformers'

const InputControl = ({
  deviceType,
  theta,
  phi,
  beamCurrentGain,
  thetaMax,
  beamGainMin,
  beamGainMax,
  handleInputChange,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const isBBoxLite = getDeviceInfo[deviceType].devName.second === 'lite'

  // ---- warning status
  const [thetaWarning, setThetaWarning] = useState('')
  const [phiWarning, setPhiWarning] = useState('')
  const [gainWarning, setGainWarning] = useState('')
  const [mobileGainWarning, setMobileGainWarning] = useState('')

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ======================
  // warning text
  // ======================
  const thetaWarningText = getThetaPhiPhaseWarningText({
    min: thetaMin,
    max: thetaMax,
    step: thetaStep,
  })

  const phiWarningText = getThetaPhiPhaseWarningText({
    min: phiMin,
    max: phiMax,
    step: phiStep,
  })

  const gainWarningText = getGainWarningText({
    min: beamGainMin,
    max: beamGainMax,
    step: gainStep,
  })

  // ======================
  // Input props
  // ======================
  const thetaInputProps = {
    className: 'w-full ',
    size: 'lg',
    icon: thetaIcon,
    unit: thetaUnit,
    step: thetaStep,
    loop: false,
    validMin: thetaMin,
    validMax: thetaMax,
    decimalScale: thetaDecimalScale,
    value: String(theta),
  }

  const phiInputProps = {
    className: 'w-full ',
    size: 'lg',
    icon: phiIcon,
    unit: phiUnit,
    step: phiStep,
    loop: true,
    validMin: phiMin,
    validMax: phiMax,
    decimalScale: phiDecimalScale,
    value: String(phi),
  }

  const gainInputProps = {
    className: 'w-full',
    size: 'lg',
    icon: gainIcon,
    unit: gainUnit,
    step: gainStep,
    loop: false,
    validMin: beamGainMin,
    validMax: beamGainMax,
    decimalScale: gainDecimalScale,
    value: String(beamCurrentGain),
  }

  // ======================
  // OverwriteElement
  // ======================
  const thetaDialogOverwriteElement = (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...thetaInputProps} />
      <span className={rangeText + ' text-yellow'}>Max {thetaMax}</span>
    </div>
  )
  const phiDialogOverwriteElement = (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...phiInputProps} />
      <span className={rangeText + ' text-yellow'}>Max {phiMax}</span>
    </div>
  )
  const gainDialogOverwriteElement = (
    <div className={itemBox}>
      <InputGroup warning={true} disabled={true} {...gainInputProps} />
      <span className={rangeText + ' text-yellow'}>
        Range {beamGainMin} ~ {beamGainMax}
      </span>
    </div>
  )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='w-full flex justify-start gap-x-4 xl:gap-x-6 '>
        <Theta
          value={String(theta)}
          thetaMax={thetaMax}
          onChange={({ value, isValid }) =>
            handleInputChange({ label: 'theta', value, isValid })
          }
        />

        <div className={itemBox}>
          {isBBoxLite ? (
            <SwitchGroup
              className='w-full '
              size='lg'
              value={+phi}
              icon={phiIcon}
              text1={0}
              text2={180}
              unit='˚'
              onClick={() =>
                handleInputChange({
                  label: 'phi',
                  value: +phi === 0 ? 180 : 0,
                  isValid: true,
                })
              }
            />
          ) : (
            <Phi
              value={String(phi)}
              onChange={({ value, isValid }) =>
                handleInputChange({
                  label: 'phi',
                  value,
                  isValid,
                })
              }
            />
          )}
        </div>

        <Gain
          className='hidden xl:hidden md:block'
          value={String(beamCurrentGain)}
          gainMin={beamGainMin}
          gainMax={beamGainMax}
          onChange={({ value, isValid }) =>
            handleInputChange({ label: 'gain', value, isValid })
          }
        />
      </div>

      {/* 手機版專用 gain */}

      <Gain
        className='block xl:block md:hidden mt-4'
        value={String(beamCurrentGain)}
        gainMin={beamGainMin}
        gainMax={beamGainMax}
        onChange={({ value, isValid }) =>
          handleInputChange({ label: 'gain', value, isValid })
        }
      />
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    flex 
                    xl:flex-col md:flex-row sm:flex-col
                    xl:justify-stretch md:justify-center 
                    gap-x-4 
                    `
const itemBox = `flex flex-col w-full `
const rangeText = `block pl-3 text-xs text-white/50 font-normal mt-1`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleInputChange: PropTypes.func,
}
