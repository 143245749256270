const welcomeDemo = {
  showModalOfWelcomeDemo: (state, action) => {
    state.normal = {
      type: 'WELCOME_DEMO',
      props: { status: '' },
    }

    return state
  },
}
export default welcomeDemo
