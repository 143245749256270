import { put, call, delay } from 'redux-saga/effects'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import localMiddlewareApi from 'src/redux/sagas/services/socketAPI/localMiddleware'

import { modalActions } from 'src/redux/slices/modal'
import { dashboardExportPowerHistoryApi } from 'src/redux/sagas/services/restfulAPI/localMiddlewareApi'
import __export_API_sender from 'src/redux/sagas/services/restfulTools/__export_API_sender'

export function* powerHistoryClear(payloads) {
  try {
    const { sn } = payloads
    const { lookupID } = yield call(getCommonArgs, sn)
    const ack = yield call(
      __socket_API_sender,
      localMiddlewareApi.DASHBOARD_CLEAR_POWER_HISTORY,
      {
        lookupID,
        sn,
      }
    )

    //! 關 modal 寫在 handler 而不是 API response
    //! 是因為 cali save 也需要呼叫 clearPowerHistory 這隻 API
    //! 但那隻不需要清掉 modal，而這邊需要
    //! 因此把該流程做在這邊的 handler
    if (ack === 0) yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog('[handler] powerHistoryClear error:', error)
  }
}

export function* powerHistoryExport(payloads) {
  try {
    const { sn } = payloads
    const { lookupID } = yield call(getCommonArgs, sn)
    const data = { sn, lookupID }

    const { success } = yield call(__export_API_sender, {
      api: dashboardExportPowerHistoryApi,
      data,
    })

    if (success) {
      yield put(modalActions.showModalOfFileExportSuccess())
      yield delay(1000)
      yield put(modalActions.clearNormalModal())
    }
  } catch (error) {
    devWarLog('[handler] powerHistoryExport error:', error)
  }
}
