import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Cable = ({ className, cableLightClassName }) => {
  return (
    <SVGUniqueID>
      <svg
        width='150'
        height='69'
        viewBox='0 0 150 69'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}>
        <path
          d='M143.729 9.35137C146.503 9.59003 148.634 11.9118 148.634 14.6965C148.634 17.5697 146.37 19.9329 143.499 20.0563L126.905 20.7701C126.621 20.7823 126.384 20.5551 126.384 20.2705V8.40417C126.384 8.11112 126.635 7.88089 126.927 7.90601L143.729 9.35137Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M141.634 20.1931C141.634 20.3542 141.556 20.5055 141.425 20.5994L133.711 26.1314C133.626 26.1924 133.524 26.2251 133.42 26.2251L133.42 26.7251L133.42 26.2251L129.133 26.2251C128.857 26.2251 128.633 26.0013 128.633 25.7251L128.633 3.81399C128.633 3.5416 128.852 3.31932 129.124 3.31408L133.42 3.2314C133.528 3.22934 133.633 3.26185 133.72 3.32414L141.424 8.81462C141.556 8.90844 141.634 9.06013 141.634 9.22179L141.634 20.1931Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M137.634 20.1687C137.634 20.3392 137.547 20.498 137.403 20.5899L129.041 25.9401C128.869 26.0501 128.647 26.0445 128.481 25.9261L121.059 20.6359C120.927 20.5421 120.849 20.3904 120.849 20.2287L120.849 9.25741C120.849 9.09627 120.926 8.945 121.057 8.85109L128.483 3.52608C128.649 3.40697 128.871 3.40093 129.043 3.51084L137.402 8.84277C137.546 8.9346 137.634 9.09355 137.634 9.26432L137.634 20.1687Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M117.681 10.5112C119.897 10.6302 121.632 12.4627 121.629 14.6815C121.626 16.9487 119.813 18.7987 117.547 18.847L3.14492 21.284C2.86468 21.29 2.63428 21.0644 2.63428 20.7841V4.8608C2.63428 4.57413 2.87482 4.34615 3.16109 4.36152L117.681 10.5112Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M120.196 8.36519L120.185 8.36391L120.175 8.36306L117.729 8.15922C117.675 8.15472 117.634 8.10962 117.634 8.05549C117.634 6.91488 118.624 6.02678 119.758 6.15042L128.107 7.06078C131.821 7.46576 134.634 10.6024 134.634 14.3385C134.634 18.0631 131.837 21.194 128.136 21.6129L119.575 22.5819C118.54 22.699 117.634 21.8894 117.634 20.8481C117.634 20.8176 117.656 20.7918 117.686 20.7875L120.704 20.3563C122.959 20.0342 124.634 18.1033 124.634 15.8258V13.3925C124.634 10.8374 122.731 8.68211 120.196 8.36519Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          opacity='0.5'
          d='M108.134 14.333L2.13428 12.333'
          stroke='#F2C94C'
          strokeWidth='4'
          strokeLinecap='square'
          className={cableLightClassName}
        />
        <path
          d='M120.113 8.42281C123.224 8.64498 125.634 11.2332 125.634 14.3516C125.634 17.4699 123.224 20.0581 120.113 20.2803L100.588 21.675C99.532 21.7504 98.6338 20.9141 98.6338 19.8555C98.6338 19.8152 98.6634 19.781 98.7034 19.7753L101.667 19.3519L101.596 18.8569L101.667 19.3519C101.735 19.3421 101.802 19.3234 101.865 19.2962L101.669 18.8367L101.866 19.2962L102.642 18.9635C104.457 18.1856 105.634 16.4009 105.634 14.4261C105.634 12.3683 104.357 10.5264 102.431 9.80385L101.349 9.39826C101.28 9.37224 101.207 9.35593 101.133 9.34977L98.7466 9.1509C98.6829 9.14558 98.6338 9.09226 98.6338 9.02825C98.6338 7.87202 99.6148 6.95862 100.768 7.041L120.113 8.42281Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path d='M141.718 9.30606L137.752 9.26909' stroke='white' />
        <path d='M141.617 20.249L137.634 20.3242' stroke='white' />
        <path
          d='M124.414 6.7666L125.907 7.55753C127.948 8.6386 129.4 10.6128 129.847 12.879V12.879C130.035 13.831 130.037 14.8228 129.849 15.7749V15.7749C129.398 18.0586 127.906 20.0001 125.814 21.0225L123.134 22.333'
          stroke='white'
        />
        <path
          d='M143.729 46.8279C146.503 47.0666 148.634 49.3883 148.634 52.173C148.634 55.0463 146.37 57.4094 143.499 57.5329L126.905 58.2466C126.621 58.2588 126.384 58.0317 126.384 57.7471V45.8807C126.384 45.5877 126.635 45.3575 126.927 45.3826L143.729 46.8279Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M141.634 57.6697C141.634 57.8308 141.556 57.9821 141.425 58.076L133.711 63.608C133.626 63.6689 133.524 63.7017 133.42 63.7017L133.42 64.2017L133.42 63.7017L129.133 63.7017C128.857 63.7017 128.633 63.4778 128.633 63.2017L128.633 41.2906C128.633 41.0182 128.852 40.7959 129.124 40.7906L133.42 40.708C133.528 40.7059 133.633 40.7384 133.72 40.8007L141.424 46.2912C141.556 46.385 141.634 46.5367 141.634 46.6983L141.634 57.6697Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M137.634 57.6453C137.634 57.8158 137.547 57.9745 137.403 58.0664L129.041 63.4166C128.869 63.5266 128.647 63.5211 128.481 63.4026L121.059 58.1124C120.927 58.0186 120.849 57.8669 120.849 57.7053L120.849 46.734C120.849 46.5728 120.926 46.4216 121.057 46.3277L128.483 41.0026C128.649 40.8835 128.871 40.8775 129.043 40.9874L137.402 46.3193C137.546 46.4112 137.634 46.5701 137.634 46.7409L137.634 57.6453Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          d='M117.543 48.1328C119.815 48.179 121.632 50.0355 121.629 52.3079C121.626 54.5212 119.895 56.3471 117.685 56.4679L3.16156 62.7254C2.87512 62.741 2.63428 62.513 2.63428 62.2261V46.3028C2.63428 46.0227 2.8644 45.7972 3.14446 45.8029L117.543 48.1328Z'
          fill='#222222'
          stroke='white'
        />
        <path
          d='M120.196 45.8418L120.185 45.8405L120.175 45.8396L117.729 45.6358C117.675 45.6313 117.634 45.5862 117.634 45.532C117.634 44.3914 118.624 43.5033 119.758 43.627L128.107 44.5373C131.821 44.9423 134.634 48.079 134.634 51.815C134.634 55.5396 131.837 58.6705 128.136 59.0894L119.575 60.0584C118.54 60.1755 117.634 59.366 117.634 58.3246C117.634 58.2942 117.656 58.2683 117.686 58.264L120.704 57.8329C122.959 57.5108 124.634 55.5799 124.634 53.3024V50.869C124.634 48.3139 122.731 46.1587 120.196 45.8418Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path
          opacity='0.5'
          d='M114.533 52.0583L2.1029 55.0003'
          stroke='#F2C94C'
          strokeWidth='4'
          strokeLinecap='square'
          className={cableLightClassName}
        />
        <path
          d='M101.196 47.832L101.186 47.8307L101.175 47.8299L98.7636 47.6289C98.6902 47.6228 98.6338 47.5614 98.6338 47.4878C98.6338 46.3176 99.6045 45.381 100.774 45.4227L120.013 46.1098C123.149 46.2218 125.634 48.7968 125.634 51.9351C125.634 54.9932 123.27 57.5314 120.22 57.7493L100.588 59.1516C99.532 59.227 98.6338 58.3907 98.6338 57.3321C98.6338 57.2917 98.6634 57.2575 98.7034 57.2518L101.667 56.8284L101.596 56.3335L101.667 56.8284C101.735 56.8187 101.802 56.8 101.865 56.7728L101.669 56.3132L101.866 56.7728L102.973 56.2983C104.587 55.6064 105.634 54.019 105.634 52.2627C105.634 50.0484 103.985 48.1806 101.788 47.906L101.196 47.832Z'
          fill='#4F4F4F'
          stroke='white'
        />
        <path d='M141.718 46.7826L137.752 46.7457' stroke='white' />
        <path d='M141.617 57.7256L137.634 57.8007' stroke='white' />
        <path
          d='M124.414 44.2432L125.907 45.0341C127.948 46.1152 129.4 48.0894 129.847 50.3555V50.3555C130.035 51.3075 130.037 52.2994 129.849 53.2514V53.2514C129.398 55.5351 127.906 57.4767 125.814 58.4991L123.134 59.8096'
          stroke='white'
        />
      </svg>
    </SVGUniqueID>
  )
}

Cable.propTypes = {}

export default Cable
