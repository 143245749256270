import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'

import {
  h4,
  h6,
  small,
} from 'src/containers/operating/device/powerDetector/style'

import CommonInput from 'src/components/Input/CommonInput'
import CommonSelect from 'src/components/Select/CommonSelect'
import CommonButton from 'src/components/Button/CommonButton'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import { BiEdit, BiChevronDown } from 'react-icons/bi'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import {
  pd_freqChange_watcher,
  pd_readMethodChange_watcher,
  pd_updateRateChange_watcher,
  pd_readClick_watcher,
  pd_averageChange_watcher,
  pd_caliSelect_watcher,
  pd_configCustomClick_watcher,
} from 'src/redux/actions/powerDetector'
import useGetUIControl from 'src/hooks/useGetUIControl'

const ActionPanel = props => {
  // MARK: state
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const { single: s_uiControl } = useGetUIControl(sn)

  const isPowerReading = s_uiControl?.isPowerReading

  const [isExtendAdvanced, setIsExtendAdvanced] = useState(false)
  const { isSM, isMD, isLG } = useGetScreenSize()

  const {
    currentFreq,
    readMethod,
    updateRate,
    average,
    currentCaliID,
    caliData,
  } = current.data.deviceControl.actionPanel

  const componentSize = isSM || isMD || isLG ? 'md' : 'sm'

  const freqRangeTextColor = checkCurrentIsValid(currentFreq)
    ? ' text-white/50'
    : ' text-red'

  // MARK: event

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleFreqChange = result =>
    dispatch(
      pd_freqChange_watcher({
        sn,
        value: result.formattedValue,
        isValid: checkCurrentIsValid(result.formattedValue),
      })
    )

  const handleReadMethodChange = (event, value) =>
    dispatch(pd_readMethodChange_watcher({ sn, value }))

  const handleUpdateRateChange = (event, value) =>
    dispatch(pd_updateRateChange_watcher({ sn, value }))

  const handleReadClick = () => dispatch(pd_readClick_watcher({ sn }))

  const handleAdvanceSettingsClick = () => setIsExtendAdvanced(prev => !prev)

  const handleAverageChange = (event, value) =>
    dispatch(pd_averageChange_watcher({ sn, value }))

  const handleCaliSelect = (event, value) =>
    dispatch(pd_caliSelect_watcher({ sn, id: value }))

  const handleCustomClick = () => dispatch(pd_configCustomClick_watcher({ sn }))

  // MARK: JSX
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={containerClass}>
      <ItemBox>
        <h6 className={h6}>Frequency (GHz)</h6>
        <CommonInput
          inputType='number'
          value={currentFreq}
          size={componentSize}
          disabled={isPowerReading}
          decimalScale={2}
          danger={!checkCurrentIsValid(currentFreq)}
          onChange={handleFreqChange}
        />
        <small className={small + freqRangeTextColor}>
          Value between 0.1~40
        </small>
      </ItemBox>

      <ItemBox>
        <h6 className={h6}>Read Method</h6>
        <SegmentedButton
          type='outlined'
          size={componentSize}
          value={readMethod}
          disabled={isPowerReading}
          onChange={handleReadMethodChange}>
          <SegmentedButton.Option name='single'>Single</SegmentedButton.Option>
          <SegmentedButton.Option name='continuous'>
            Continuous
          </SegmentedButton.Option>
        </SegmentedButton>
      </ItemBox>

      <ItemBox>
        <h6 className={h6}>Update Rate (ms.)</h6>
        <CommonSelect
          size={componentSize}
          value={updateRate}
          disabled={readMethod === 'single' || isPowerReading}
          onChange={handleUpdateRateChange}>
          <CommonSelect.Option name={500}>500</CommonSelect.Option>
          <CommonSelect.Option name={1000}>1000</CommonSelect.Option>
          <CommonSelect.Option name={2000}>2000</CommonSelect.Option>
        </CommonSelect>
      </ItemBox>

      <ItemBox>
        <h6 className={h6}>Read Control</h6>
        <CommonButton
          size={componentSize}
          className='w-full'
          danger={isPowerReading}
          disabled={!checkCurrentIsValid(currentFreq)}
          onClick={handleReadClick}>
          {isPowerReading ? 'STOP' : 'Read'}
        </CommonButton>
      </ItemBox>

      <div className='col-span-2'>
        <div className={advancedClass} onClick={handleAdvanceSettingsClick}>
          <span>Advanced Settings</span>
          <BiChevronDown
            className={`text-2xl duration-500
             ${isExtendAdvanced ? '-rotate-180' : ''}`}
          />
        </div>

        <div className='w-full h-[1px] bg-dark-5 ' />
      </div>

      {isExtendAdvanced && (
        <>
          <ItemBox>
            <h6 className={h6}>Average</h6>
            <CommonSelect
              size={componentSize}
              value={average}
              onChange={handleAverageChange}>
              <CommonSelect.Option name={1}>1</CommonSelect.Option>
              <CommonSelect.Option name={5}>5</CommonSelect.Option>
              <CommonSelect.Option name={10}>10</CommonSelect.Option>
              <CommonSelect.Option name={20}>20</CommonSelect.Option>
            </CommonSelect>
          </ItemBox>

          <ItemBox>
            <div className='flex items-center justify-between'>
              <h6 className={h6}>Calibration Config</h6>
              <CommonButton
                icon={<BiEdit />}
                size={componentSize}
                type='text'
                disabled={isPowerReading}
                className='hidden xl:flex'
                onClick={handleCustomClick}>
                Custom
              </CommonButton>
            </div>

            <CommonSelect
              size={componentSize}
              value={currentCaliID}
              disabled={isPowerReading}
              currentChildren={caliData?.[currentCaliID]?.name || 'Default'}
              onChange={handleCaliSelect}>
              <CommonSelect.Option name='default'>Default</CommonSelect.Option>
              {Object.entries(caliData).map(([id, data]) => (
                <CaliOption key={id} id={id} data={data} />
              ))}
            </CommonSelect>
          </ItemBox>
        </>
      )}
    </div>
  )
}

export default ActionPanel

// MARK: static
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerClass = `functionBlockBackground 
                        w-full
                        pt-4 px-3 pb-8 
                        rounded-md 
                        grid grid-cols-2 
                        gap-y-2 md:gap-y-0 xl:gap-y-2
                        `

const itemBox = `w-full 
                flex flex-col 
                gap-y-1 md:gap-y-2 xl:gap-y-1 
                px-0 md:px-2 xl:px-0 py-2
                `

const advancedClass = `${h4} 
                      w-full
                      flex justify-between items-center 
                      hover:opacity-60 
                      text-white 
                      select-none cursor-pointer 
                      duration-500 
                      mt-6 md:mt-4 xl:mt-6 
                      mb-1 
                      `

// MARK: another components
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const ItemBox = props => (
  <div className={itemBox + ' col-span-2 md:col-span-1 xl:col-span-2'}>
    {props.children}
  </div>
)

// 不放在 saga 是因為這邊畫面很多地方要用到
const checkCurrentIsValid = currentFreq =>
  currentFreq >= 0.1 && currentFreq <= 40

const CaliOption = ({ id, data }) => {
  const isNeedAddEmptyText = R.pipe(
    R.prop('value'),
    R.map(e => e.isDefault),
    R.values,
    R.all(R.equals(true))
  )(data)

  return (
    <CommonSelect.Option name={id}>
      <div className='w-full flex items-center justify-between gap-x-1 pr-3'>
        <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
          {data.name}
        </span>{' '}
        {isNeedAddEmptyText && <em className='text-white/40 italic'>Empty</em>}
      </div>
    </CommonSelect.Option>
  )
}
