import React from 'react'

import BG428 from 'src/assets/img/BG-428.png'
import BG744 from 'src/assets/img/BG-744.png'
import BG1440 from 'src/assets/img/BG-1440.png'
import BG1920 from 'src/assets/img/BG-1920.png'

const Background = () => (
  <picture className={bg}>
    <source media='(min-width: 1441px)' srcSet={BG1920} />
    <source media='(min-width: 745px)' srcSet={BG1440} />
    <source media='(min-width: 429px)' srcSet={BG744} />
    <img
      src={BG428}
      alt='TMXLab-kit background'
      className='w-full h-full object-cover'
    />
  </picture>
)

export default Background

const bg = `fixed w-full h-screen -z-10 `
