import * as R from 'ramda'
import Decimal from 'decimal.js'

import { tryCatch } from 'src/funcs/tools'

export const valueConversionFromUnit = (value, unit) =>
  tryCatch(() => {
    if (value) {
      // base on kHz
      let result = {
        GHz: new Decimal(value).div(1000000).toNumber(),
        MHz: new Decimal(value).div(1000).toNumber(),
        kHz: new Decimal(value).toNumber(),
      }
      return result[unit]
    }

    return value
  })

export const reverseValueUnitTokHz = (value, unit) =>
  tryCatch(() => {
    if (!R.isEmpty(value) && !R.isNil(value) && !isNaN(+value)) {
      let result = {
        GHz: new Decimal(value).mul(1000000).toNumber(),
        MHz: new Decimal(value).mul(1000).toNumber(),
        kHz: new Decimal(value).toNumber(),
      }
      return result[unit]
    }

    return value
  })

export const rfIfIsValid = ({ RF, IF, unit }) =>
  tryCatch(() => {
    const rfMin = valueConversionFromUnit(RF.userMin, unit)
    const rfMax = valueConversionFromUnit(RF.userMax, unit)
    const ifMin = valueConversionFromUnit(IF.userMin, unit)
    const ifMax = valueConversionFromUnit(IF.userMax, unit)

    const validList = [
      +RF.value >= +rfMin,
      +RF.value <= +rfMax,
      +IF.value >= +ifMin,
      +IF.value <= +ifMax,
    ]
    const isValid = validList.every(e => e === true)

    return isValid
  })
