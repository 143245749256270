import { call, put, delay } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import {
  changeIsTemperatureCompensationChanged,
  changeTemperatureCompensationButtonStatus,
} from 'src/redux/slices/uiControl/beamformers/bboard'

import { devWarLog } from 'src/funcs/tools'

export function* readTemperature(response) {
  try {
    const [sn, { rfMode, adc }] = Object.entries(response?.data)[0]

    let currentData = yield call(getCurrentData, sn)

    currentData.deviceControl.step[rfMode].adc.currentValue = adc

    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[res-handler] readTemperature error:', error)
  }
}

export function* setTemperatureCompensation(response) {
  try {
    const { sn } = response?.data

    yield put(
      changeTemperatureCompensationButtonStatus({ sn, status: 'isSuccess' })
    )
    yield delay(1000)
    yield put(changeTemperatureCompensationButtonStatus({ sn, status: '' }))
    yield put(changeIsTemperatureCompensationChanged({ sn, value: false }))
  } catch (error) {
    devWarLog('[res-handler] setTemperatureCompensation error:', error)
  }
}
