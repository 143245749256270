import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiInfoCircle } from 'react-icons/bi'

import {
  bbox_configUnsavedChangesReminderModal_exportOnlyClick_watcher,
  bbox_configUnsavedChangesReminderModal_exportAndSaveClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

const UnsavedChangesReminder = props => {
  const { isSaveLoading } = props
  const dispatch = useDispatch()
  return (
    <Modal>
      <Modal.Icon>
        <BiInfoCircle className='text-white' />
      </Modal.Icon>

      <Modal.Title>Unsaved Changes</Modal.Title>

      <Modal.Description>
        Do you want to save before exporting?
      </Modal.Description>

      <Modal.RowButtonBox>
        <CommonButton
          type='general'
          size='lg'
          disabled={isSaveLoading}
          onClick={() =>
            dispatch(
              bbox_configUnsavedChangesReminderModal_exportOnlyClick_watcher()
            )
          }>
          Export only
        </CommonButton>
        <CommonButton
          type='primary'
          size='lg'
          loading={isSaveLoading}
          onClick={() =>
            dispatch(
              bbox_configUnsavedChangesReminderModal_exportAndSaveClick_watcher()
            )
          }>
          Export & Save
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default UnsavedChangesReminder
