import { createAction } from '@reduxjs/toolkit'

export const cloverCellEvb_common_rfModeChange_watcher = createAction(
  'deviceControl/cloverCellEvb_common_rfModeChange_watcher'
)
export const cloverCellEvb_common_freqSelect_watcher = createAction(
  'deviceControl/cloverCellEvb_common_freqSelect_watcher'
)
export const cloverCellEvb_common_antennaPolarizationChange_watcher =
  createAction(
    'deviceControl/cloverCellEvb_common_antennaPolarizationChange_watcher'
  )
export const cloverCellEvb_common_polarSynthesisChange_watcher = createAction(
  'deviceControl/cloverCellEvb_common_polarSynthesisChange_watcher'
)

export const cloverCellEvb_channel_allPowerSwitch_watcher = createAction(
  'deviceControl/cloverCellEvb_channel_allPowerSwitch_watcher'
)
export const cloverCellEvb_channel_singlePowerSwitch_watcher = createAction(
  'deviceControl/cloverCellEvb_channel_singlePowerSwitch_watcher'
)

export const cloverCellEvb_channel_commonGainSliderChange_watcher =
  createAction(
    'deviceControl/cloverCellEvb_channel_commonGainSliderChange_watcher'
  )
export const cloverCellEvb_channel_commonGainChange_watcher = createAction(
  'deviceControl/cloverCellEvb_channel_commonGainChange_watcher'
)
export const cloverCellEvb_channel_commonPhaseSliderChange_watcher =
  createAction(
    'deviceControl/cloverCellEvb_channel_commonPhaseSliderChange_watcher'
  )
export const cloverCellEvb_channel_commonPhaseChange_watcher = createAction(
  'deviceControl/cloverCellEvb_channel_commonPhaseChange_watcher'
)

export const cloverCellEvb_channel_singleGainChange_watcher = createAction(
  'deviceControl/cloverCellEvb_channel_singleGainChange_watcher'
)
export const cloverCellEvb_channel_singlePhaseChange_watcher = createAction(
  'deviceControl/cloverCellEvb_channel_singlePhaseChange_watcher'
)

export const cloverCellEvb_steering_thetaPhiChange_watcher = createAction(
  'deviceControl/cloverCellEvb_steering_thetaPhiChange_watcher'
)
export const cloverCellEvb_steering_gainChange_watcher = createAction(
  'deviceControl/cloverCellEvb_steering_gainChange_watcher'
)
export const cloverCellEvb_steering_3DCameraChange_watcher = createAction(
  'deviceControl/cloverCellEvb_steering_3DCameraChange_watcher'
)
export const cloverCellEvb_steering_channelModeMaskClose_watcher = createAction(
  'deviceControl/cloverCellEvb_steering_channelModeMaskClose_watcher'
)

export const cloverCellEvb_current_readMethodChange_watcher = createAction(
  'deviceControl/cloverCellEvb_current_readMethodChange_watcher'
)
export const cloverCellEvb_current_readClick_watcher = createAction(
  'deviceControl/cloverCellEvb_current_readClick_watcher'
)
export const cloverCellEvb_current_clearClick_watcher = createAction(
  'deviceControl/cloverCellEvb_current_clearClick_watcher'
)
export const cloverCellEvb_current_exportClick_watcher = createAction(
  'deviceControl/cloverCellEvb_current_exportClick_watcher'
)
