import { put, call, delay } from 'redux-saga/effects'

import { devWarLog } from 'src/funcs/tools'
import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { modalActions } from 'src/redux/slices/modal'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Init        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* deviceSettingRefSourceChange(response) {
  try {
    const [sn, { refSource, ledIndicators }] = Object.entries(response?.data)[0]

    let { currentData } = yield call(getCommonArgs, sn)

    currentData.current.ledIndicators = ledIndicators

    if (refSource === 1) {
      // 如果切到 EXternal be call api 會自動把 Internal output 關掉
      currentData.settings.device.internal.isEnable = false

      yield put(
        modalActions.showModalOfUDModuleExternalCheck({
          sn,
          inputFreq: null,
          isLoading: false,
          isSuccess: true,
        })
      )
      yield delay(1000)
    }

    yield put(setSingleDeviceData({ sn, data: currentData }))

    if (refSource === 1) yield put(modalActions.clearNormalModal())
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}
export function* deviceSettingOutputChange(response) {
  try {
    yield console.log('!!!! deviceSettingOutputChange:', response)
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}
export function* deviceSettingInputChange(response) {
  try {
    yield console.log('!!!! deviceSettingInputChange:', response)
  } catch (error) {
    devWarLog('[handler] init error:', error)
  }
}
