export const theme = (isActive, disabled, danger, warning) => {
  if (disabled) return `bg-dark-3 border-transparent text-white/50`
  if (warning) return `bg-dark-1 border-red text-white`
  if (danger) return `bg-dark-1 border-red text-white`
  if (isActive) return `bg-dark-1 border-teal text-white`
  return `bg-dark-1 border-dark-3 hover:border-dark-5 text-white`
}

export const cursor = disabled =>
  disabled ? 'cursor-not-allowed' : 'cursor-default'
