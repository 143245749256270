import { combineReducers } from '@reduxjs/toolkit'

import deviceData from './deviceData'
import member from './uiControl/facility/member'
import error from './uiControl/facility/error'
import notification from './uiControl/facility/notification'

import header from './uiControl/facility/header'
import { versions } from './uiControl/facility/versions'
import facilityInit from './uiControl/facility/init'
import facilityModal from './modal'

import { user } from './uiControl/facility/user'
import { settings } from './uiControl/facility/settings'
import { homeUiControl, temporaryIpData } from './uiControl/facility/home'
import {
  bboxUIControlForAll,
  bboxUIControlForSingle,
} from './uiControl/beamformers/bbox'
import { bboardUIControlForSingle } from './uiControl/beamformers/bboard'
import {
  cclEvbUIControlForAll,
  cclEvbUIControlForSingle,
} from './uiControl/beamformers/cloverCellEvb'
import { cclCttcUIControlForSingle } from './uiControl/beamformers/cloverCellCttc'
import {
  risUIControlForAll,
  risUIControlForSingle,
} from './uiControl/beamformers/ris'
import { udboxUIControlForSingle } from './uiControl/freqConverter/udbox'
import { sgUIControlForSingle } from './uiControl/freqConverter/sg'
import {
  powerDetectorUIControlForAll,
  powerDetectorUIControlForSingle,
} from './uiControl/powerDetector'

const reducers = combineReducers({
  deviceData,
  member,
  error,

  header,
  notification,

  versions,
  facilityInit,
  facilityModal,

  settings,
  user,
  homeUiControl,
  temporaryIpData,

  bboxUIControlForAll,
  bboxUIControlForSingle,
  bboardUIControlForSingle,
  cclEvbUIControlForAll,
  cclEvbUIControlForSingle,
  cclCttcUIControlForSingle,
  risUIControlForAll,
  risUIControlForSingle,

  udboxUIControlForSingle,
  sgUIControlForSingle,

  powerDetectorUIControlForAll,
  powerDetectorUIControlForSingle,
})

export default reducers
