import { call, put, select, delay } from 'redux-saga/effects'
import * as R from 'ramda'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setShowLoadingMask } from 'src/redux/slices/uiControl/beamformers/bbox'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { modalActions } from 'src/redux/slices/modal'

import { setConfigEditorIsChanged } from 'src/redux/slices/uiControl/beamformers/bbox'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Freq        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* commonFreqSelect(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))

    yield put(setConfigEditorIsChanged({ sn, value: false }))
  } catch (error) {
    devWarLog('[res-handler] commonFreqSelect error:', error)
  }
}
export function* commonFreqAfterImport(response) {
  try {
    const freqListArray = Object.entries(response?.data).map(
      ([sn, freqList]) => ({ sn, freqList })
    )

    const deviceData = yield select(state => state.deviceData)
    let cloneDeviceData = R.clone(deviceData)

    for (let i = 0; i < freqListArray.length; i++) {
      cloneDeviceData[freqListArray[i].sn].deviceControl.common.lstFreqOptions =
        freqListArray[i].freqList

      yield put(
        setSingleDeviceData({
          sn: freqListArray[i].sn,
          data: cloneDeviceData[freqListArray[i].sn],
        })
      )
    }
  } catch (error) {
    devWarLog('[res-handler] commonFreqAfterImport error:', error)
  }
}

// //* ----------------- ------------------ -----------------
// //* ----------------- ------------------ -----------------
// //* -----------------      Antenna       -----------------
// //* ----------------- ------------------ -----------------
// //* ----------------- ------------------ -----------------

export function* commonAntennaSelect(response) {
  try {
    const [sn, { deviceData }] = Object.entries(response?.data)[0]
    yield put(setSingleDeviceData({ sn, data: deviceData }))

    yield put(setConfigEditorIsChanged({ sn, value: false }))

    yield put(
      setShowLoadingMask({
        sn,
        value: { common: false, steering: false, channel: false },
      })
    )
  } catch (error) {
    devWarLog('[res-handler] commonAntennaSelect error:', error)
  }
}

function* _updateLstAntennaDataAndLeaveEditMode({ sn, lstAntennaData }) {
  try {
    let newCurrentData = yield call(getCurrentData, sn)
    newCurrentData.deviceControl.common.lstAntennaData = lstAntennaData

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
    yield put(
      modalActions.setEditSaveIsRequestingForModalOfBBoxCA({
        editDeleteRequesting: false,
      })
    )
    yield put(modalActions.clearChildModalOfBBoxCA())
    yield put(
      modalActions.setSelectIdListForModalOfBBoxCA({ selectIdList: [] })
    )
    yield put(modalActions.setModalOfBBoxCALeaveEdit())
  } catch (error) {
    devWarLog(
      '[res-handler] _updateLstAntennaDataAndLeaveEditMode error:',
      error
    )
  }
}

export function* customAntennaInsert(response) {
  try {
    const [sn, { lstAntennaData, lstImportFailed }] = Object.entries(
      response?.data
    )[0]

    const { editIsCreate } = yield select(
      state => state.facilityModal.normal.props
    )

    // 代表是客製化介面新建資料的 response
    if (editIsCreate)
      yield call(_updateLstAntennaDataAndLeaveEditMode, {
        sn,
        lstAntennaData,
      })

    // 代表是 import 資料的 response
    if (!editIsCreate) {
      if (lstAntennaData) {
        let newCurrentData = yield call(getCurrentData, sn)
        newCurrentData.deviceControl.common.lstAntennaData = lstAntennaData

        yield put(setSingleDeviceData({ sn, data: newCurrentData }))
      }

      if (!lstImportFailed.length) {
        yield put(modalActions.showModalOfFileImportSuccess())
        yield delay(2000)
        yield put(modalActions.clearNormalModal())
      }

      if (lstImportFailed.length)
        yield put(
          modalActions.showModalOfFileImportFailedCheck({
            failedList: lstImportFailed,
          })
        )
    }
  } catch (error) {
    devWarLog('[res-handler] customAntennaInsert error:', error)
  }
}

export function* customAntennaUpdate(response) {
  try {
    const [sn, { lstAntennaData }] = Object.entries(response?.data)[0]

    yield call(_updateLstAntennaDataAndLeaveEditMode, { sn, lstAntennaData })
  } catch (error) {
    devWarLog('[res-handler] customAntennaUpdate error:', error)
  }
}

export function* customAntennaDelete(response) {
  try {
    const [sn, { lstAntennaData }] = Object.entries(response?.data)[0]

    yield put(
      modalActions.setEditDeleteIsRequestingForModalOfBBoxCA({
        editDeleteRequesting: false,
      })
    )
    yield call(_updateLstAntennaDataAndLeaveEditMode, { sn, lstAntennaData })
  } catch (error) {
    devWarLog('[res-handler] customAntennaDelete error:', error)
  }
}

export function* customAntennaExport() {
  // antenna export 在 phase 2 還不用 support
}
