import React, { useState, useRef, forwardRef } from 'react'
import * as R from 'ramda'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import { mergeRefs } from 'react-merge-refs'
import ipRegex from 'ip-regex'

import { RSafe } from 'src/funcs/ramdaFunc'
import {
  fixLeadingZeroForIp,
  regProcess,
  // rangeLimit
  isRangePass,
} from '../func'

import { theme, cursor } from '../style'
import { testID } from 'src/funcs/getEnv'

import { isType } from 'src/funcs/tools'

import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <IpInput
//   {...{ size,
//         value, // '...' <- 空值是這個, 不管怎樣都一定要有三個點
//         disabled,
//         danger,
//         placeholder
//   }}
//   onChange={result => console.log(result.formattedValue)}
//   onFocus={result => console.log(result.formattedValue)}
//   onBlur={result => console.log(result.formattedValue)}
// />

const IpInput = forwardRef(
  (
    {
      className = '',
      value = '...',
      size = 'sm',
      disabled = false,
      warning = false,
      danger = false,
      placeholder: userPlaceholder = '000.000.000.000',
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
    },
    ref
  ) => {
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------       State        -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    // 用來判斷當前浮標在哪用的，藉以處理後續左右鍵切換
    const [selection, setSelection] = useState({ start: '', end: '' })

    const [isActive, setIsActive] = useState([])

    const placeholder = userPlaceholder?.split('.')

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------        Func        -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    // processing 是一個 currying function, 用來處理所有 onChange 的邏輯
    // 這邊多套一層是為了處理 第一格 max 233 其他 255 這件事
    const processedValue = (min, max) => processing(min, max)

    // 把外層傳進來的 value (0.0.0.0) 拆成四個值分別塞進四個 input
    // 這樣才有辦法用 tab 切換
    const getInputValue = index => RSafe(R.split('.'))(value)[index]

    // change 後會判斷是否需 focus 下一層
    // keyDown 用來處理按倒退回到上一層
    // 之所以不在 change 處理回到上一層，是因為如果是該層是空值
    // 點擊倒退將不會觸發 change，所以也不會回到上一層
    // 所以回上層用 keydown 獨立處理
    const ipInput = useRef([])

    const ipMergeRef = index => {
      if (ref)
        return mergeRefs([
          el => (ref.current[index] = el),
          el => (ipInput.current[index] = el),
        ])

      return el => (ipInput.current[index] = el)
    }

    const focusNext = index =>
      ipInput.current[index + 1 >= 3 ? 3 : index + 1]?.focus()
    const focusLast = index =>
      ipInput.current[index - 1 <= 0 ? 0 : index - 1]?.focus()

    const nextFocusAfterChanged = index => value => {
      if (+value >= 100) focusNext(index)
      return value
    }

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------       Event        -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    // 處理 change
    //! 2022/10/7: ivy 說 第四格先不用擋 0 and 255, 也不用擋 127.0.0.1
    const returnValue = index => eventValue =>
      R.pipe(
        processedValue(null, !index ? 223 : 255),
        nextFocusAfterChanged(index),
        returnValueUpdateAndPackage({
          index,
          prevValue: value,
        })
      )(eventValue)

    const handleChange = useBindErrorBoundary((event, index) => {
      const value = event.target.value
      const pastPassValue = ipRegex.v4({ exact: true }).test(value)
      const dottedEnding = value[value.length - 1] === '.'

      // 任何一個 input 的 change event target value 是完整合法的 pastPassValue
      // 代表是複製貼上的，就直接回傳整個 e.target.value
      if (pastPassValue) onChange({ formattedValue: value })

      if (!pastPassValue) {
        // 如果結尾是 點 (.) 就 focus 下一個
        // 不在 keydown 一起處理是因為手機跟 pad keycode 都是一樣的沒辦法判斷
        // 判斷 length !== 1 是為了做到 "沒有值單純按 . 不要跳轉下一個"
        if (dottedEnding && value.length !== 1) focusNext(index)

        // 如果值超過最大值 直接跳轉下一個 判斷 value.length === 3 是為了跟上面那個判斷一起處理
        // 如果沒有判斷 length === 3 上面那個判斷式的 "沒有值單純按 . 不要跳轉下一個" 會掛掉
        if (!isRangePass(value, 0, !index ? 223 : 255) && value.length === 3)
          focusNext(index)

        if (isRangePass(value, 0, !index ? 223 : 255))
          onChange(returnValue(index)(value))
      }
    })

    // 處理 keypress 前後 focus
    // e.preventDefault() 是為了防止 focus 後多跳一格 或多進一格
    const selectionEqualToZero = () => selection.start === 0
    const selectionEqualToValueLength = index =>
      selection.start === getInputValue(index).length
    const selectionIsNotRange = selection.start === selection.end
    const handleKeyDown = (e, index) => {
      // 不能是選取範圍
      // 游標位置一定要是 0
      if (isBackKey(e) && selectionIsNotRange && selectionEqualToZero()) {
        e.preventDefault()
        focusLast(index)
      }

      // 游標位置一定要是 最後
      if (isNextKey(e) && selectionEqualToValueLength(index)) {
        e.preventDefault()
        focusNext(index)
      }
    }

    // 抓取浮標 Select Change
    const handleSelect = e =>
      setSelection({
        start: e.target.selectionStart,
        end: e.target.selectionEnd,
      })

    // 判斷是否 active
    const handleFocus = index => {
      setIsActive(prev => [...prev, index])
      onFocus()
    }
    const handleBlur = index => {
      setIsActive(prev => prev.filter(e => e !== index))
      onBlur()
    }

    // click 後 focus 處理
    const handleClickForContainer = () => ipInput?.current[3]?.focus()
    const handleClickForInputItem = (event, index) => {
      event.stopPropagation()
      ipInput?.current[index]?.focus()
    }
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------        CSS        ------------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    const containerClass = `
                            ${className} 
                            ${containerCommon} 
                            ${theme(
                              isActive.length,
                              disabled,
                              danger,
                              warning
                            )} 
                            ${containerSize[size]} 
                            ${cursor} 
                            `
    const inputClass = `${inputCommon} ${cursor}`

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------        JSX         -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    if (!isType('array')(placeholder))
      return new Error({ message: 'IP placeholder must be an array' })

    // 格式正確才會 render
    // 為了讓 tab 鍵可以在 ip 四個輸入區切換，使用 四個 input 做處理
    return (
      <div
        {...testID('ipContainer')}
        className={containerClass}
        onClick={handleClickForContainer}>
        {inputKeyCreator.map((item, index) => (
          <div className='flex items-center' key={item.key}>
            {!disabled ? (
              <input
                inputMode='decimal'
                {...testID('ipInput' + index)}
                type='text'
                ref={ipMergeRef(index)}
                placeholder={placeholder?.[index]}
                className={inputClass}
                value={getInputValue(index)}
                onChange={e => handleChange(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
                onSelect={e => handleSelect(e)}
                onFocus={() => handleFocus(index)}
                onBlur={() => handleBlur(index)}
                onClick={e => handleClickForInputItem(e, index)}
              />
            ) : (
              // 讓 disabled 時滑鼠匡起來複製可以正常
              <span className={`${inputClass} text-white/50`}>
                {getInputValue(index) || '000'}
              </span>
            )}

            {index !== 3 && <span className='self-end mb-1'>.</span>}
          </div>
        ))}
      </div>
    )
  }
)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = `
                        flex justify-center 
                        border-1 border-solid 
                        rounded-[2px] 
                        px-2 
                        duration-500 
                        `

const inputCommon = `
                    w-8
                    bg-transparent 
                    border-none focus:border-none outline-none
                    text-center 
                    `

const containerSize = {
  sm: 'h-6 text-sm leading-3',
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static Func     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const inputKeyCreator = new Array(4).fill('').map(() => ({ key: nanoid() }))

// 封裝 Input 裡面處理 number 的邏輯
export const processing = (min, max) => value =>
  RSafe(
    R.pipe(
      fixLeadingZeroForIp,
      regProcess(false, 0)
      // rangeLimit(min, max)
    )
  )(value)

// 封裝回外層 onChange 結果的地方，因 input 系列回傳格式都有 formattedValue,
// 所以這邊比照處理，多封一層 formattedValue，避免不一致而出錯
const returnValueUpdateAndPackage =
  ({ index, prevValue }) =>
  processedValue => ({
    formattedValue: RSafe(
      R.pipe(R.split('.'), R.update(index, processedValue), R.join('.'))
    )(prevValue),
  })

const backList = [8, 37] // "backspace" "left"
const nextList = [13, 32, 39] // "enter" "space" "right"
const isBackKey = e => backList.includes(e.keyCode)
const isNextKey = e => nextList.includes(e.keyCode)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
IpInput.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default IpInput
