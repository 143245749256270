import * as R from 'ramda'

import { outputFreqStepMax, outputFreqStepMin } from 'src/constants/sg'

export const isFixedFreqInvalid = ({ value, min, max }) =>
  R.isEmpty(value) || value > max || value < min
export const isDynamicStartEndInvalid = ({ start, end, min, max }) => ({
  isStartInvalid:
    R.isEmpty(start) || +start >= +end || +start > +max || +start < +min,
  isEndInvalid: R.isEmpty(end) || +start >= +end || +end > +max || +end < +min,
})

export const isOutputFreqStepInvalid = ({ value, min, max }) =>
  R.isEmpty(value) || value > max || value < min

export const isSweepTimeStepInvalid = ({ value, min, max }) =>
  R.isEmpty(value) || value > max || value < min
export const isRepeatRoundsInvalid = ({ value, min, max }) =>
  R.isEmpty(value) || value > max || value < min

export const isOutputSettingsInvalid = ({ deviceData }) => {
  const { frequency, outputControls } = deviceData.settings.output

  const isContinuous = outputControls.method === 'continuous'
  const isSweep = outputControls.method === 'sweep'
  const isFixed = frequency.type === 'fixed'
  const isDynamic = frequency.type === 'dynamic'

  const { max: freqMax, min: freqMin, fixed, dynamic } = frequency
  const { start, end, step } = dynamic
  const { sweepTimeStep, repeatRounds } = outputControls
  const {
    currentValue: SweepValue,
    min: sweepMin,
    max: sweepMax,
  } = sweepTimeStep
  const {
    currentValue: repeatValue,
    min: repeatMin,
    max: repeatMax,
  } = repeatRounds

  // Continuous
  // Continuous
  // Continuous
  // Continuous
  // Continuous
  if (isContinuous) {
    const _isFixedInvalid = isFixedFreqInvalid({
      value: fixed,
      min: freqMin,
      max: freqMax,
    })

    return _isFixedInvalid
  }

  // Sweep
  // Sweep
  // Sweep
  // Sweep
  // Sweep
  if (isSweep) {
    const { isStartInvalid, isEndInvalid } = isDynamicStartEndInvalid({
      start,
      end,
      min: freqMin,
      max: freqMax,
    })

    const _isFreqStepInvalid = isOutputFreqStepInvalid({
      value: step,
      min: outputFreqStepMin,
      max: outputFreqStepMax,
    })

    // SweepValue !== null 代表 custom
    // custom 才有輸入框要驗證
    const _isSweepTimeStepInvalid =
      SweepValue !== null &&
      isSweepTimeStepInvalid({
        value: SweepValue,
        min: sweepMin,
        max: sweepMax,
      })

    // repeatValue !== null 代表 custom
    // custom 才有輸入框要驗證
    const _isRepeatRoundsInvalid =
      repeatValue !== null &&
      isRepeatRoundsInvalid({
        value: repeatValue,
        min: repeatMin,
        max: repeatMax,
      })

    // 不管怎樣 sweep 一定要驗證 sweepTimeStep 和 repeatRounds
    // 因為 Fixed 沒有 start, end ,step 所以驗這兩個就好
    if (isFixed) return _isSweepTimeStepInvalid || _isRepeatRoundsInvalid

    // Dynamic 要加驗 start 和 end 和 step
    if (isDynamic)
      return (
        isStartInvalid ||
        isEndInvalid ||
        _isFreqStepInvalid ||
        _isSweepTimeStepInvalid ||
        _isRepeatRoundsInvalid
      )
  }
}
