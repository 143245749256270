import React, { forwardRef } from 'react'

const Step = forwardRef((props, ref) => {
  const { index, className } = props

  const container = `${className} flex items-center gap-x-4 `

  return (
    <div className={container}>
      <div className={circle} ref={ref}>
        {index + 1}
      </div>

      <div>{props.children}</div>
    </div>
  )
})

export default Step

const circle = `
                relative z-20 
                w-8 h-8 
                shrink-0 
                rounded-full 
                pt-[2px] 
                bg-dark-5 
                border-1 border-solid border-white 
                flex items-center justify-center 
                text-base font-normal text-white leading-5 
                `
