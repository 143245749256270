import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

const Cover = props => {
  const { className } = props

  return (
    <SVGUniqueID>
      <svg
        width='164'
        height='173'
        viewBox='0 0 164 173'
        fill='none'
        className={className}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M77.6597 166.707H86.6597V170.879L84.5645 171.707H82.1597H79.7549L77.6597 170.879V166.707Z'
          fill='#434343'
          stroke='white'
        />
        <path
          d='M76.6597 163.707H87.6597V167.92L85.0785 168.78H82.1597H79.2408L76.6597 167.92V163.707Z'
          fill='#434343'
          stroke='white'
        />
        <path d='M85.1597 163.973V168.973' stroke='white' />
        <path d='M79.1597 163.973V168.973' stroke='white' />
        <rect
          x='0.5'
          y='0.707031'
          width='163'
          height='163'
          rx='19.5'
          fill='#222222'
          stroke='white'
        />
        <rect
          x='12.5'
          y='12.707'
          width='139'
          height='139'
          rx='11.5'
          fill='#434343'
          stroke='white'
        />
        <rect
          x='16.5'
          y='16.707'
          width='131'
          height='131'
          rx='9.5'
          fill='#434343'
          stroke='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26 2.20703L28.1213 3.08571L29 5.20703L28.1213 7.32835L26 8.20703L23.8787 7.32835L23 5.20703L23.8787 3.08571L26 2.20703ZM27 5.20703C27 5.75932 26.5523 6.20703 26 6.20703C25.4477 6.20703 25 5.75932 25 5.20703C25 4.65475 25.4477 4.20703 26 4.20703C26.5523 4.20703 27 4.65475 27 5.20703ZM56.1213 3.08571L54 2.20703L51.8787 3.08571L51 5.20703L51.8787 7.32835L54 8.20703L56.1213 7.32835L57 5.20703L56.1213 3.08571ZM54 6.20703C54.5523 6.20703 55 5.75932 55 5.20703C55 4.65475 54.5523 4.20703 54 4.20703C53.4477 4.20703 53 4.65475 53 5.20703C53 5.75932 53.4477 6.20703 54 6.20703ZM84.1213 3.08571L82 2.20703L79.8787 3.08571L79 5.20703L79.8787 7.32835L82 8.20703L84.1213 7.32835L85 5.20703L84.1213 3.08571ZM82 6.20703C82.5523 6.20703 83 5.75932 83 5.20703C83 4.65475 82.5523 4.20703 82 4.20703C81.4477 4.20703 81 4.65475 81 5.20703C81 5.75932 81.4477 6.20703 82 6.20703ZM110 2.20703L112.121 3.08571L113 5.20703L112.121 7.32835L110 8.20703L107.879 7.32835L107 5.20703L107.879 3.08571L110 2.20703ZM111 5.20703C111 5.75932 110.552 6.20703 110 6.20703C109.448 6.20703 109 5.75932 109 5.20703C109 4.65475 109.448 4.20703 110 4.20703C110.552 4.20703 111 4.65475 111 5.20703ZM140.121 3.08571L138 2.20703L135.879 3.08571L135 5.20703L135.879 7.32835L138 8.20703L140.121 7.32835L141 5.20703L140.121 3.08571ZM138 6.20703C138.552 6.20703 139 5.75932 139 5.20703C139 4.65475 138.552 4.20703 138 4.20703C137.448 4.20703 137 4.65475 137 5.20703C137 5.75932 137.448 6.20703 138 6.20703Z'
          fill='#C7C7C7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26 156.207L28.1213 157.086L29 159.207L28.1213 161.328L26 162.207L23.8787 161.328L23 159.207L23.8787 157.086L26 156.207ZM27 159.207C27 159.759 26.5523 160.207 26 160.207C25.4477 160.207 25 159.759 25 159.207C25 158.655 25.4477 158.207 26 158.207C26.5523 158.207 27 158.655 27 159.207ZM56.1213 157.086L54 156.207L51.8787 157.086L51 159.207L51.8787 161.328L54 162.207L56.1213 161.328L57 159.207L56.1213 157.086ZM54 160.207C54.5523 160.207 55 159.759 55 159.207C55 158.655 54.5523 158.207 54 158.207C53.4477 158.207 53 158.655 53 159.207C53 159.759 53.4477 160.207 54 160.207ZM84.1213 157.086L82 156.207L79.8787 157.086L79 159.207L79.8787 161.328L82 162.207L84.1213 161.328L85 159.207L84.1213 157.086ZM82 160.207C82.5523 160.207 83 159.759 83 159.207C83 158.655 82.5523 158.207 82 158.207C81.4477 158.207 81 158.655 81 159.207C81 159.759 81.4477 160.207 82 160.207ZM110 156.207L112.121 157.086L113 159.207L112.121 161.328L110 162.207L107.879 161.328L107 159.207L107.879 157.086L110 156.207ZM111 159.207C111 159.759 110.552 160.207 110 160.207C109.448 160.207 109 159.759 109 159.207C109 158.655 109.448 158.207 110 158.207C110.552 158.207 111 158.655 111 159.207ZM140.121 157.086L138 156.207L135.879 157.086L135 159.207L135.879 161.328L138 162.207L140.121 161.328L141 159.207L140.121 157.086ZM138 160.207C138.552 160.207 139 159.759 139 159.207C139 158.655 138.552 158.207 138 158.207C137.448 158.207 137 158.655 137 159.207C137 159.759 137.448 160.207 138 160.207Z'
          fill='#C7C7C7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M161.121 24.0857L159 23.207L156.879 24.0857L156 26.207L156.879 28.3284L159 29.207L161.121 28.3284L162 26.207L161.121 24.0857ZM159 27.207C159.552 27.207 160 26.7593 160 26.207C160 25.6547 159.552 25.207 159 25.207C158.448 25.207 158 25.6547 158 26.207C158 26.7593 158.448 27.207 159 27.207ZM161.121 80.0857L159 79.207L156.879 80.0857L156 82.207L156.879 84.3284L159 85.207L161.121 84.3284L162 82.207L161.121 80.0857ZM159 83.207C159.552 83.207 160 82.7593 160 82.207C160 81.6547 159.552 81.207 159 81.207C158.448 81.207 158 81.6547 158 82.207C158 82.7593 158.448 83.207 159 83.207ZM159 51.207L161.121 52.0857L162 54.207L161.121 56.3284L159 57.207L156.879 56.3284L156 54.207L156.879 52.0857L159 51.207ZM160 54.207C160 54.7593 159.552 55.207 159 55.207C158.448 55.207 158 54.7593 158 54.207C158 53.6547 158.448 53.207 159 53.207C159.552 53.207 160 53.6547 160 54.207ZM161.121 108.086L159 107.207L156.879 108.086L156 110.207L156.879 112.328L159 113.207L161.121 112.328L162 110.207L161.121 108.086ZM159 111.207C159.552 111.207 160 110.759 160 110.207C160 109.655 159.552 109.207 159 109.207C158.448 109.207 158 109.655 158 110.207C158 110.759 158.448 111.207 159 111.207ZM159 135.207L161.121 136.086L162 138.207L161.121 140.328L159 141.207L156.879 140.328L156 138.207L156.879 136.086L159 135.207ZM160 138.207C160 138.759 159.552 139.207 159 139.207C158.448 139.207 158 138.759 158 138.207C158 137.655 158.448 137.207 159 137.207C159.552 137.207 160 137.655 160 138.207Z'
          fill='#C7C7C7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.12132 24.0857L5 23.207L2.87868 24.0857L2 26.207L2.87868 28.3284L5 29.207L7.12132 28.3284L8 26.207L7.12132 24.0857ZM5 27.207C5.55228 27.207 6 26.7593 6 26.207C6 25.6547 5.55228 25.207 5 25.207C4.44772 25.207 4 25.6547 4 26.207C4 26.7593 4.44772 27.207 5 27.207ZM7.12132 80.0857L5 79.207L2.87868 80.0857L2 82.207L2.87868 84.3284L5 85.207L7.12132 84.3284L8 82.207L7.12132 80.0857ZM5 83.207C5.55228 83.207 6 82.7593 6 82.207C6 81.6547 5.55228 81.207 5 81.207C4.44772 81.207 4 81.6547 4 82.207C4 82.7593 4.44772 83.207 5 83.207ZM5 51.207L7.12132 52.0857L8 54.207L7.12132 56.3284L5 57.207L2.87868 56.3284L2 54.207L2.87868 52.0857L5 51.207ZM6 54.207C6 54.7593 5.55228 55.207 5 55.207C4.44772 55.207 4 54.7593 4 54.207C4 53.6547 4.44772 53.207 5 53.207C5.55228 53.207 6 53.6547 6 54.207ZM7.12132 108.086L5 107.207L2.87868 108.086L2 110.207L2.87868 112.328L5 113.207L7.12132 112.328L8 110.207L7.12132 108.086ZM5 111.207C5.55228 111.207 6 110.759 6 110.207C6 109.655 5.55228 109.207 5 109.207C4.44772 109.207 4 109.655 4 110.207C4 110.759 4.44772 111.207 5 111.207ZM5 135.207L7.12132 136.086L8 138.207L7.12132 140.328L5 141.207L2.87868 140.328L2 138.207L2.87868 136.086L5 135.207ZM6 138.207C6 138.759 5.55228 139.207 5 139.207C4.44772 139.207 4 138.759 4 138.207C4 137.655 4.44772 137.207 5 137.207C5.55228 137.207 6 137.655 6 138.207Z'
          fill='#C7C7C7'
        />
      </svg>
    </SVGUniqueID>
  )
}

export default Cover
