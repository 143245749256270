export const deployMode = process.env.REACT_APP_DEPLOY_MODE
export const devServerTarget = process.env.REACT_APP_DEV_SERVER_TARGET

export const isPROD = deployMode === 'PROD'
export const isOffline = deployMode === 'OFFLINE'
export const isQa = deployMode === 'QA'

export const isProduction = isPROD || isOffline
export const isDeveloping = !isPROD && !isOffline

export const testID = mark => (isDeveloping ? { 'data-testid': mark } : {})
