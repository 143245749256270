import React from 'react'

import Body from 'src/assets/svg/device/avatar/Ud0630/Body'
import LEDLights from 'src/assets/svg/device/avatar/Ud0630/LEDLights'

const Ud0630 = props => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `
                          relative 
                          w-full h-full 
                          flex items-center justify-center 
                          group
                          `
  const bodyClass = `
                    absolute 
                    inset-0 m-auto 
                    scale-[0.7] group-hover:scale-[0.8] 
                    duration-1000
                    `
  const ledLightsClass = `
                          absolute 
                          inset-0 m-auto 
                          scale-[0.7] group-hover:scale-[0.8] 
                          grayscale group-hover:grayscale-0 
                          opacity-30 group-hover:opacity-100 
                          duration-1000
                          `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={containerClass}>
      <Body className={bodyClass} />
      <LEDLights className={ledLightsClass} />
    </div>
  )
}
export default Ud0630
