import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DropdownMenu from 'src/containers/Header/Member/DropdownMenu'

import { BiLogInCircle, BiUser } from 'react-icons/bi'

import { member_startSignInSignUpFlow_watcher } from 'src/redux/actions/facility/member'

const Member = props => {
  const dispatch = useDispatch()

  const memberInfo = useSelector(state => state.member)
  const { className } = props
  const { email, picture, avatarDefaultColor } = memberInfo

  const memberCircle = `${className} ${memberCircleCommon}`

  const handleLoginClick = () =>
    dispatch(member_startSignInSignUpFlow_watcher())

  if (!email)
    return (
      <div className={memberCircle} onClick={handleLoginClick}>
        <BiLogInCircle className='text-white text-2xl' />
      </div>
    )

  if (email && !picture)
    return (
      <DropdownMenu>
        <div
          className={`${memberCircle} ${memberCircleBorder} `}
          style={{ background: avatarDefaultColor }}>
          <BiUser className='text-xl text-black/50' />
        </div>{' '}
      </DropdownMenu>
    )

  if (email && picture)
    return (
      <DropdownMenu>
        <div className={`${memberCircle} ${memberCircleBorder}`}>
          <img src={picture} alt='web-tlk member avatar' />
        </div>
      </DropdownMenu>
    )
}

export default Member

const memberCircleCommon = `
                            w-8 h-8 rounded-full overflow-hidden 
                            flex items-center justify-center 
                            duration-500 uppercase select-none hover:opacity-50
                            cursor-pointer
                            `

const memberCircleBorder = `border-[0.5px] border-solid border-light-4`
