import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { udbox_deviceSettings_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import { BiError } from 'react-icons/bi'

import Stepper from 'src/components/Stepper'

import { h4, h6 } from 'src/containers/Modal/style'
import useGetUIControl from 'src/hooks/useGetUIControl'

const ToExternalError = ({ sn }) => {
  const dispatch = useDispatch()

  const { single: s_uiControl } = useGetUIControl(sn)
  const { isRequesting, isSuccess } = s_uiControl?.ud5g?.refSource

  const handleCancelClick = () =>
    dispatch(
      udbox_deviceSettings_watcher({ sn, label: 'SOURCE_100M', value: 0 })
    )

  const handleUseExternalClick = () =>
    dispatch(
      udbox_deviceSettings_watcher({ sn, label: 'SOURCE_100M', value: 1 })
    )

  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red ' />
      </Modal.Icon>

      <Modal.Title>Failed to use External reference source</Modal.Title>

      <Modal.Description className='text-yellow'>
        Please check the steps below and try again
      </Modal.Description>

      <Stepper name='Use External Reference Source' className='mt-8'>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Connect to an external 100-MHz ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Ensure the source is connected.
          </p>
        </Stepper.Step>
        <Stepper.Step>
          <h4 className={h4 + ' text-white'}>
            Turn on the external 100-MHz ref. source
          </h4>
          <p className={h6 + ' text-light-4 mt-1'}>
            Make sure the source has been transmitted.
          </p>
        </Stepper.Step>
      </Stepper>

      <Modal.RowButtonBox>
        <CommonButton
          size='lg'
          type='outlined'
          disabled={isRequesting}
          onClick={handleCancelClick}>
          Cancel
        </CommonButton>
        <CommonButton
          size='lg'
          type='primary'
          loading={isRequesting}
          success={isSuccess}
          onClick={handleUseExternalClick}>
          Try again
        </CommonButton>
      </Modal.RowButtonBox>
    </Modal>
  )
}

export default ToExternalError
