import { useState, useEffect } from 'react'

const useGetScrollInfo = props => {
  const [pageYOffset, setPageYOffset] = useState(window.pageYOffset)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setPageYOffset(window.pageYOffset)
    })
    return () => {
      window.removeEventListener('scroll', () => {
        setPageYOffset(window.pageYOffset)(window.pageYOffset)
      })
    }
  }, [])

  return { pageYOffset }
}

export default useGetScrollInfo
