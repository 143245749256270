import { put, select } from 'redux-saga/effects'

import { generalErrorWatcher } from 'src/redux/actions/facility/generalError'

export default function* responseToGeneralError(payloads) {
  const { response, api, time } = payloads
  const { code, codeName, message, errorInfo } = response

  const lookupID = yield select(state => state.user.lookupID)

  const versionInfo = errorInfo?.versionInfo
  const deviceType = errorInfo?.deviceType
  const sn = errorInfo?.sn

  yield put(
    generalErrorWatcher({
      time,
      userID: lookupID,
      api,
      code,
      codeName,
      message,
      versionInfo,
      sn,
      deviceType,
    })
  )
}
