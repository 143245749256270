import React from 'react'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import LoItem from 'src/containers/operating/device/freqConverter/general/FreqSettings/LoItem'

import { valueConversionFromUnit } from 'src/funcs/device/udbox'

import { appendComma } from 'src/funcs/tools'

const Lo = ({ handleLOClick }) => {
  const { current } = useGetCurrentDeviceData()
  const { LO, unit } = current.data.settings.freq

  const { currentSelection, LSI, HSI, userMax, userMin } = LO

  const userMinValue = appendComma(+valueConversionFromUnit(userMin, unit))
  const userMaxValue = appendComma(+valueConversionFromUnit(userMax, unit))

  const showSelect = LSI.value || HSI.value

  return (
    <div className={container + ' min-h-[180px]'}>
      <div className='text-base leading-5 font-bold text-light-4'>LO</div>
      <small className={rangeTextClass}>
        Range {userMinValue} - {userMaxValue} {unit}
      </small>

      {!showSelect && (
        <div className='text-base leading-5 font-medium text-light-5 mt-8'>
          Please enter RF and IF value
        </div>
      )}

      {showSelect && (
        <>
          <LoItem
            subtitle='High-Side Injection'
            label='HSI'
            unit={unit}
            value={HSI?.value}
            isSelected={currentSelection === 'HSI'}
            disabled={HSI?.disabled}
            handleClick={handleLOClick}
          />
          <LoItem
            subtitle='Low-Side Injection'
            label='LSI'
            unit={unit}
            value={LSI?.value}
            isSelected={currentSelection === 'LSI'}
            disabled={LSI?.disabled}
            handleClick={handleLOClick}
          />
        </>
      )}
    </div>
  )
}

export default Lo

const container = `w-full bg-dark-1 rounded-[4px] flex flex-col items-center pt-4 pb-6 `
const rangeTextClass = `text-white/50 text-xs mt-2`
