import { take, call, race, put, select, delay } from 'redux-saga/effects'

import {
  getCurrentData,
  getCommonArgs,
} from 'src/redux/sagas/selector/deviceData'

import { modalActions } from 'src/redux/slices/modal'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import {
  deviceResetReminderModal_cancelClick_watcher,
  deviceResetReminderModal_yesClick_watcher,
} from 'src/redux/actions/facility/operating'

import { devWarLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       RfMode       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* commonRfModeSwitch(payloads) {
  try {
    const { sn, value } = payloads
    const { lookupID } = yield call(getCommonArgs, sn)

    let currentData = yield call(getCurrentData, sn)
    currentData.deviceControl.common.rfMode = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    yield call(__socket_API_sender, beamformersApi.COMMON_RF_MODE, {
      sn,
      lookupID,
      value,
    })
  } catch (error) {
    devWarLog('[handler] commonRfModeSwitch error:', error)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        Freq        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export function* commonFreqSelect(payloads) {
  try {
    yield put(modalActions.showModalOfDeviceResetReminder())

    const { cancel, yes } = yield race({
      cancel: take(deviceResetReminderModal_cancelClick_watcher.type),
      yes: take(deviceResetReminderModal_yesClick_watcher.type),
    })

    if (cancel) yield put(modalActions.clearNormalModal())

    if (yes) {
      const { sn, value } = payloads
      const { lookupID } = yield call(getCommonArgs, sn)

      yield put(modalActions.setDeviceResetReminderToLoading())

      const ack = yield call(
        __socket_API_sender,
        beamformersApi.COMMON_FREQUENCY,
        {
          sn,
          lookupID,
          value,
        }
      )

      if (ack === 0) {
        yield put(modalActions.setDeviceResetReminderToSuccess())
        yield delay(1000)
        yield put(modalActions.clearNormalModal())
      }
    }
  } catch (error) {
    devWarLog('[handler] commonFreqSelect error:', error)
  }
}

export function* commonFreqAfterImport(payloads) {
  try {
    const { sn, responseData } = payloads

    let { lookupID } = yield call(getCommonArgs, sn)

    const { lstFailed, lstSuccess, lstSN } = responseData?.data

    // 先確定沒有掛掉
    if (responseData.code !== 0) throw Error()

    const deviceData = yield select(state => state.deviceData)

    // 拿到 init 完的 sn list
    const feInitializedSnList = Object.entries(deviceData)
      .filter(([sn, data]) => data.info.initialization === 'finished')
      .map(([sn]) => sn)

    // 如果 import 的 sn 清單有已經 init 過的
    // 要跟 BE 要新的 freq 清單
    const initializedLstSn = lstSN.filter(sn =>
      feInitializedSnList.includes(sn)
    )

    // 跟 be 要有 import 且 init 過的 device 的 freq 清單
    if (initializedLstSn.length)
      yield call(
        __socket_API_sender,
        beamformersApi.COMMON_FREQUENCY_GET_MULTI_SN,
        {
          lookupID,
          lstSN: initializedLstSn,
        }
      )

    // 沒有放在 response 做是因為第二隻用來同步所有 lstAntenna 的 api 不會知道 import 的結果
    const allSuccess = responseData.code === 0 && lstFailed.length === 0

    const partiallyFailed =
      responseData.code === 0 &&
      lstFailed.length !== 0 &&
      lstSuccess.length !== 0

    const allFailed =
      responseData.code === 0 &&
      lstFailed.length !== 0 &&
      lstSuccess.length === 0

    if (allSuccess) {
      yield put(modalActions.showModalOfFileImportSuccess())
      yield delay(1000)
      yield put(modalActions.clearNormalModal())
      return yield 'allSuccess'
    }

    if (partiallyFailed) {
      yield put(
        modalActions.showModalOfFileImportFailedCheck({ failedList: lstFailed })
      )
      return yield 'partiallyFailed'
    }

    if (allFailed) {
      yield put(
        modalActions.showModalOfFileImportFailedCheck({ failedList: lstFailed })
      )
      return yield 'allFailed'
    }

    // 跟 be 同步所有 Device freq list
  } catch (error) {
    devWarLog('[handler] commonFreqSelect error:', error)
  }
}
