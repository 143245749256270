import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { member_startSignInSignUpFlow_watcher } from 'src/redux/actions/facility/member'

import { BiCheck } from 'react-icons/bi'

const ForgotPasswordSuccess = props => {
  const dispatch = useDispatch()

  const handleClick = () => dispatch(member_startSignInSignUpFlow_watcher())

  useEffect(() => {
    window.addEventListener('keypress', e => {
      if (e.key === 'Enter') dispatch(member_startSignInSignUpFlow_watcher())
    })

    return () => {
      window.removeEventListener('keypress', e => {
        if (e.key === 'Enter') dispatch(member_startSignInSignUpFlow_watcher())
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal>
      <Modal.Icon>
        <BiCheck className='text-white' />
      </Modal.Icon>

      <Modal.Title>Password Changed</Modal.Title>

      <Modal.Description>
        Password changed successfully. Please sign in with your new password.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton size='lg' onClick={handleClick}>
          Back to Sign In
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default ForgotPasswordSuccess
