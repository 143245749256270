export const calibrationTableImportApi = data => ({
  server: 'dbService',
  method: 'POST',
  url: '/api/db/calibration/table/import',
  data,
  timeout: 100000,
  config: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/octet-stream',
    },
  },
  isAutoDemo: true,
})
