import React, { Fragment, memo } from 'react'

import PropTypes from 'prop-types'

import Text from 'src/containers/home/Main/ConnectionInfo/Text'
import IpDisabled from 'src/containers/home/Main/ConnectionInfo/IpDisabled'
import StaticIp from 'src/containers/home/Main/ConnectionInfo/StaticIp'

const ConnectionInfo = memo(({ sn, connectionInfo = {} }) => {
  const dataKeys = Object.keys(connectionInfo)

  return dataKeys.map(key => (
    <Fragment key={sn + key + 'ConnectionInfo'}>
      {componentsList({ sn, key, value: connectionInfo[key] })}
    </Fragment>
  ))
})

const titleList = {
  ip: 'IP Address',
  mac: 'MAC Address',
  staticIP: 'Static IP Address',
}

const componentsList = ({ sn, key, value }) => {
  if (key === 'ip' && value)
    return <IpDisabled title={titleList[key]} value={value} />
  if (key === 'mac' && value)
    return <Text title={titleList[key]} value={value} />
  if (key === 'staticIP' && value)
    return <StaticIp title={titleList[key]} sn={sn} />
}

ConnectionInfo.propTypes = {
  notify: PropTypes.object,
  needDivider: PropTypes.bool,
}

export default ConnectionInfo
