import React, { useState } from 'react'

import { textBox, subtitle } from 'src/containers/home/style'

import IpInput from 'src/components/Input/IpInput'

const IpDisabled = ({ title, value }) => {
  const [, setFakeValue] = useState('...')
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={textBox}>
      <label className={subtitle}>{title}</label>

      <IpInput
        className='mt-2'
        size='sm'
        value={value}
        disabled={true}
        danger={false}
        onChange={result => setFakeValue(result.formattedValue)}
      />
    </div>
  )
}
export default IpDisabled
