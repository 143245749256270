import React from 'react'
import { h2, h3 } from 'src/containers/Modal/style'
import { main } from 'src/containers/Modal/Normal/PowerDetector/CustomCalibration/style'

import noCustomCalibrationConfigImg from 'src/assets/img/no_custom_calibration_config.png'

const NoData = () => (
  <main className={main + ' flex flex-col items-center justify-center'}>
    <div className='w-[240px] h-[160px] mb-4'>
      <img
        src={noCustomCalibrationConfigImg}
        alt='TMXLab-kit power detector - no custom calibration config'
      />
    </div>
    <h2 className={`${h2} text-light-4`}>No custom calibration config</h2>
    <h3 className={`${h3} text-light-4 text-center`}>
      Click the button below to create new one. <br />
      Up to 5 custom calibration config.
    </h3>
  </main>
)

export default NoData
