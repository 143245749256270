import React from 'react'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const Channel = props => {
  const { current } = useGetCurrentDeviceData()

  const i_data = current.data.deviceControl.steering?.incident?.[0]
  const r_data = current.data.deviceControl.steering?.reflector?.[0]

  const thetaI = i_data?.theta
  const thetaR = r_data?.theta
  const phiI = i_data?.phi
  const phiR = r_data?.phi
  const data = current.data.deviceControl.channel
  const countStandard = 32
  const widthStandard = 8
  const heightStandard = 8
  const gapStandard = 1
  const offset = countStandard / data[0].length

  const width = widthStandard * offset
  const height = heightStandard * offset
  const gap = gapStandard * offset

  const _getX = (i, a) => Math.floor(i) * (width + gap)
  const _getY = row_i => Math.floor(row_i * (height + gap))

  return (
    <div>
      <h3 className='text-sm text-light-1 mb-4'>Radiation Pattern</h3>

      <div className='bg-light-4/10 p-4 rounded-md flex flex-col items-center pb-14'>
        <h3 className='text-base text-center text-light-1 font-bold mb-4'>
          INC. θ {thetaI}°, φ {phiI}° | REF. θ {thetaR}°, φ {phiR}°
        </h3>

        <svg className='w-[287px] h-[287px] bg-red/10'>
          {data.map((row_e, row_i) =>
            row_e.map((e, i, a) => (
              <rect
                key={`ris channel rect ${i}`}
                x={_getX(i, a)}
                y={_getY(row_i)}
                width={width}
                height={height}
                fill={
                  +e ? 'hsla(45, 100%, 81%, 0.7)' : 'hsla(45, 100%, 81%, 0.1)'
                }
              />
            ))
          )}
        </svg>
      </div>
    </div>
  )
}

Channel.propTypes = {}

export default Channel
