import { all, fork, take, throttle } from 'redux-saga/effects'

// action

import {
  ris_steering_thetaPhiChange_watcher,
  ris_steering_3DCameraChange_watcher,
  ris_common_afterImport_watcher,
  ris_common_exportClick_watcher,
  ris_steering_distanceChange_watcher,
} from 'src/redux/actions/beamformers/risAction'

// handlers

import {
  steeringThetaPhiChange as handleSteeringThetaPhiChange,
  steeringDistanceChange as handleSteeringDistanceChange,
  steering3DCameraChange as handleSteering3DCameraChange,
} from 'src/redux/sagas/handlers/event/beamformers/ris/steering'
import {
  commonAfterImport as handleCommonAfterImport,
  commonExport as handleCommonExport,
} from 'src/redux/sagas/handlers/event/beamformers/ris/common'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------       Common       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* commonAfterImport() {
  while (true) {
    const action = yield take(ris_common_afterImport_watcher.type)
    devLog('[watcher] commonAfterImport', action.payload)
    yield fork(handleCommonAfterImport, action.payload)
  }
}
function* commonExport() {
  while (true) {
    const action = yield take(ris_common_exportClick_watcher.type)
    devLog('[watcher] commonExport', action.payload)
    yield fork(handleCommonExport, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Beam Steering   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

function* steeringThetaPhiChange() {
  while (true) {
    const action = yield take(ris_steering_thetaPhiChange_watcher.type)
    devLog('[watcher] steeringThetaPhiChange', action.payload)
    yield fork(handleSteeringThetaPhiChange, action.payload)
  }
}
function* steeringDistanceChange() {
  while (true) {
    const action = yield take(ris_steering_distanceChange_watcher.type)
    devLog('[watcher] steeringDistanceChange', action.payload)
    yield fork(handleSteeringDistanceChange, action.payload)
  }
}

function* steering3DCameraChange() {
  yield throttle(
    1000,
    ris_steering_3DCameraChange_watcher,
    handleSteering3DCameraChange
  )
}

export default function* risWatchers() {
  yield all([
    steeringThetaPhiChange(),
    steeringDistanceChange(),
    steering3DCameraChange(),
    commonAfterImport(),
    commonExport(),
  ])
}
