import { createAction } from '@reduxjs/toolkit'

// device control
export const chooseMode_deviceControlClick_watcher = createAction(
  'facility/chooseMode_deviceControlClick_watcher'
)

// facility - demo
export const welcomeDemoModal_okClick_watcher = createAction(
  'modalEvent/welcomeDemoModal_okClick_watcher'
)
export const chooseMode_demoModeClick_watcher = createAction(
  'facility/chooseMode_demoModeClick_watcher'
)
export const initDemoMode_watcher = createAction(
  'facility/initDemoMode_watcher'
)

// redirect to choose mode
export const redirectToChooseMode_watcher = createAction(
  'facility/redirectToChooseMode_watcher'
)

// facility - middleware
export const downloadMid_reConnectClick_watcher = createAction(
  'facility/downloadMid_reConnectClick_watcher'
)
export const downloadNewMidModal_backDoorClick_Watcher = createAction(
  'modalEvent/downloadNewMidModal_backDoorClick_Watcher'
)
