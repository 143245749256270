import React from 'react'
import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { BiError } from 'react-icons/bi'

const LocalMidSocketDisconnect = props => {
  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red' />
      </Modal.Icon>

      <Modal.Title>TLK Middleware disconnected</Modal.Title>

      <Modal.Description>
        Please ensure that TLK Middleware is running properly,
        <br /> and click “Refresh” to try again.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          size='lg'
          type='primary'
          onClick={() => window.location.reload()}>
          Refresh
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default LocalMidSocketDisconnect
