import React from 'react'
import PropTypes from 'prop-types'

import LoadingIcon from './LoadingIcon'

const TextSwitch = props => {
  const {
    on = false,
    className = '',
    disabled,
    loading,
    onText = '',
    offText = '',
    size = 'sm',
    onClick = () => console.log('!!!  TextSwitch not setting onChange !!!'),
  } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = () => {
    if (!disabled) onClick()
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `
                    ${className}
                    ${containerCommon}
                    ${containerSize[size]}
                    ${containerTheme(on, onText)}
                    ${containerReverse(on)}
                    ${containerOpacity(disabled)}
                    ${containerBorderColor(size, on)}
                    ${cursor(disabled)}
                    `
  const rotateBox = `
                    ${rotateBoxCommon}
                    ${rotateBoxRotate(on)}
                    `
  const circle = `
                  ${circleTheme(on, onText)} 
                  ${cursor(disabled)} 
                  ${circleSize[size]} 
                  ${circleCommon}
                  `
  const text = `
                ${textCommon} 
                ${textRotate(on)} 
                ${textSize[size]} 
                ${cursor(disabled)} 
                `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={container} onClick={handleClick}>
      <div className={rotateBox}>
        <div className={circle}>
          <LoadingIcon visible={loading} />
        </div>
        <div className={text}>{on ? onText : offText}</div>
      </div>
    </div>
  )
}
export default TextSwitch

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

const containerCommon = `relative min-w-[34px]  overflow-hidden select-none rounded-full duration-500 group flex items-center border-solid border-1`
const containerSize = {
  sm: 'h-[16px] px-[1px] ',
  md: 'h-[21px] px-1 ',
}
const containerTheme = (on, onText) =>
  on ? (onText ? 'bg-teal-10 ' : 'bg-teal-7 ') : 'bg-dark-5'
const containerReverse = on => (on ? 'flex-row' : 'flex-row-reverse')
const containerOpacity = disabled => (disabled ? 'opacity-30' : 'opacity-100')
const containerBorderColor = (size, on) =>
  size === 'md' && on
    ? 'border-teal-7'
    : size === 'md' && !on
    ? 'border-light-5'
    : 'border-transparent'

const rotateBoxCommon = `flex items-center duration-500`
const rotateBoxRotate = on => (on ? 'rotate-180 ' : 'rotate-0 ')

const circleCommon = `rounded-full duration-1000 flex items-center justify-center shrink-0 `
const circleSize = {
  sm: 'w-3 h-3 ',
  md: 'w-[14px] h-[14px] ',
}
const circleTheme = (on, onText) =>
  on ? (onText ? 'bg-teal-7 ' : 'bg-light-1') : 'bg-light-4'

const textCommon = `
                  block 
                  mx-[3px] 
                  text-white group-hover:text-white/40 font-medium 
                  duration-1000
                  `
const textSize = {
  sm: 'h-3 text-xs leading-[13px] ',
  md: 'h-4 text-sm leading-[18px] ',
}
const textRotate = on => (on ? '-rotate-180' : 'rotate-0')

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
TextSwitch.propTypes = {
  on: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onText: PropTypes.node,
  offText: PropTypes.node,
  onClick: PropTypes.func,
}
