import React from 'react'

import Info from 'src/containers/operating/device/powerDetector/Dashboard/Info'
import Chart from 'src/containers/operating/device/powerDetector/Dashboard/Chart'

const Dashboard = props => {
  return (
    <div className='w-full functionBlockBackground py-6 rounded-md'>
      <Info />
      <Chart />
    </div>
  )
}

export default Dashboard
