import { createSlice, current } from '@reduxjs/toolkit'

const itemData = {
  isTemperatureCompensationChanged: false,
  temperatureCompensationButtonStatus: '', // isRequest, 'isSuccess'
}

export const bboardUIControlForSingleSlice = createSlice({
  name: 'bboardUIControlForSingle',
  initialState: {},
  reducers: {
    addUiControl(state, action) {
      const { sn } = action.payload
      return { ...current(state), [sn]: itemData }
    },
    changeIsTemperatureCompensationChanged(state, action) {
      const { sn, value } = action.payload
      state[sn].isTemperatureCompensationChanged = value
      return state
    },
    changeTemperatureCompensationButtonStatus(state, action) {
      const { sn, status } = action.payload
      state[sn].temperatureCompensationButtonStatus = status
      return state
    },
  },
})

export const {
  addUiControl,
  changeIsTemperatureCompensationChanged,
  changeTemperatureCompensationButtonStatus,
} = bboardUIControlForSingleSlice.actions

export const bboardUIControlForSingle = bboardUIControlForSingleSlice.reducer
