import { createSlice, current } from '@reduxjs/toolkit'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        All         -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 全部 bbox 共用控項，也就是動一台 bbox, 全部 bbox 都受影響
const bboxUIControlForAllInitData = {
  dontShowAgainForSteeringMask: { channelMode: false, instruction: false },

  // customAntennaListSortRule
  // 客製化天線清單的排序，動一次全 bbox 都會共用
  // 且這個值不能隨著 modal 消失而被改變
  // 'Latest to oldest' || 'Oldest to latest'
  customAntennaListSortRule: 'Latest to oldest',
}
export const bboxUIControlForAllSlice = createSlice({
  name: 'bboxUIControlForAll',
  initialState: bboxUIControlForAllInitData,
  reducers: {
    setMaskDontShowAgain(state, action) {
      const { key, value } = action.payload
      return {
        ...current(state),
        dontShowAgainForSteeringMask: {
          ...current(state).dontShowAgainForSteeringMask,
          [key]: value,
        },
      }
    },
    setCustomAntennaSortRule(state, action) {
      const { value } = action.payload
      return {
        ...current(state),
        customAntennaListSortRule: value,
      }
    },
  },
})

export const { setMaskDontShowAgain, setCustomAntennaSortRule } =
  bboxUIControlForAllSlice.actions

export const bboxUIControlForAll = bboxUIControlForAllSlice.reducer

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 個別 bbox 控項
// 內容物：
// { [sn]: itemData }
const itemData = {
  functionTabs: 'Device Controller', //  Device Controller, Beam Config Editor
  deviceControl: {
    padControlContent: 'Channel Control', // Beam Steering , Channel Control
    showFirst3DInstructionMask: true,
    showManual3DInstructionMask: false,
    showLoadingMask: { common: false, steering: false, channel: false },
    deviceDirection: 'left', // top , left
    beam3DCameraArgs: {
      rotation: null,
      position: null,
    },
  },
  beamConfigEditor: {
    rfMode: 'tx',
    isSelectMode: false,
    selectLists: { tx: [], rx: [] },
    isChanged: false,
    saveStatus: '', // '', 'loading', 'success'
    switchMethodStatus: '', // '', 'loading'
  },
}

export const bboxUIControlForSingleSlice = createSlice({
  name: 'bboxUIControlForSingle',
  initialState: {},
  reducers: {
    addUiControl(state, action) {
      const { sn } = action.payload
      return { ...current(state), [sn]: itemData }
    },

    setFunctionTabs(state, action) {
      const { sn, value } = action.payload
      state[sn].functionTabs = value
      return state
    },

    // device control
    changePadControlContent(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.padControlContent = value
      return state
    },

    setShowFirst3DInstructionMask(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.showFirst3DInstructionMask = value
      return state
    },
    setShowManual3DInstructionMask(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.showManual3DInstructionMask = value
      return state
    },

    change3DDirection(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.deviceDirection = value
      return state
    },

    setCameraArgs(state, action) {
      const { sn, rotation, position } = action.payload
      state[sn].deviceControl.beam3DCameraArgs.rotation = rotation
      state[sn].deviceControl.beam3DCameraArgs.position = position
      return state
    },

    setShowLoadingMask(state, action) {
      const { sn, value } = action.payload
      state[sn].deviceControl.showLoadingMask = value
      return state
    },

    // beam config editor
    setConfigEditorRfMode(state, action) {
      const { sn, value } = action.payload
      state[sn].beamConfigEditor.rfMode = value
      return state
    },

    setConfigEditorIsSelectMode(state, action) {
      const { sn, value } = action.payload
      state[sn].beamConfigEditor.isSelectMode = value
      return state
    },

    setConfigEditorSelectList(state, action) {
      const { sn, value } = action.payload
      state[sn].beamConfigEditor.selectLists = value
      return state
    },

    setConfigEditorIsChanged(state, action) {
      const { sn, value } = action.payload
      state[sn].beamConfigEditor.isChanged = value
      return state
    },

    setConfigEditorSaveStatus(state, action) {
      const { sn, value } = action.payload
      state[sn].beamConfigEditor.saveStatus = value
      return state
    },

    setConfigEditorSwitchMethodStatus(state, action) {
      const { sn, value } = action.payload
      state[sn].beamConfigEditor.switchMethodStatus = value
      return state
    },
  },
})

export const {
  addUiControl,
  setFunctionTabs,

  // device control
  changePadControlContent,
  setShowFirst3DInstructionMask,
  setShowManual3DInstructionMask,
  change3DDirection,
  setCameraArgs,
  setShowLoadingMask,

  // beam config editor
  setConfigEditorRfMode,
  setConfigEditorIsSelectMode,
  setConfigEditorSelectList,
  setConfigEditorIsChanged,
  setConfigEditorSaveStatus,
  setConfigEditorSwitchMethodStatus,
} = bboxUIControlForSingleSlice.actions

export const bboxUIControlForSingle = bboxUIControlForSingleSlice.reducer
