import React from 'react'

import Tooltip from 'src/components/Popup/Tooltip'
import CommonSelect from 'src/components/Select/CommonSelect'
import CommonInput from 'src/components/Input/CommonInput'

import { BiInfoCircle } from 'react-icons/bi'

import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { small, h6 } from 'src/containers/operating/device/freqConverter/style'

import { appendComma } from 'src/funcs/tools'

const HarmonicCheck = props => {
  const {
    bandwidth,
    bandwidthList,

    isBandwidthInvalid,
    bandwidthIsOutOfRange,

    bandwidthSmallColor,

    handleBandwidthSelectChange,
    handleBandwidthCustomInputChange,
  } = props
  const { isSM, isMD } = useGetScreenSize()

  return (
    <div className='w-full '>
      <div className={'flex items-center mb-2 ' + label}>
        <h6>Harmonic Check</h6>
        <Tooltip
          className='w-[216px]'
          content='It is used to check the presence of harmonic interference; it is not a device bandwidth limitation.'
          placement='right'
          enableDismiss={true}>
          <BiInfoCircle className='text-base inline ml-1 -mt-[2px] cursor-pointer select-none' />
        </Tooltip>
      </div>

      <CommonSelect
        size={isSM || isMD ? 'md' : 'sm'}
        value={bandwidth.isCustom ? 'custom' : bandwidth.value}
        currentChildren={
          bandwidth.isCustom
            ? 'W / Custom (MHz)'
            : +bandwidth.value === 0
            ? 'RF IF LO only'
            : 'W / ' + appendComma(bandwidth.value) + ' MHz'
        }
        onChange={handleBandwidthSelectChange}>
        <CommonSelect.Option name={0}>RF IF LO only</CommonSelect.Option>

        {bandwidthList.map((e, i) => (
          <CommonSelect.Option
            key={'freq settings bandwidth option ' + i}
            name={e}>
            W / {appendComma(e)} MHz
          </CommonSelect.Option>
        ))}

        <CommonSelect.Option name='custom'>
          W / Custom (MHz)
        </CommonSelect.Option>
      </CommonSelect>

      {bandwidth.isCustom && (
        <>
          <CommonInput
            inputType='number'
            className='mt-1'
            size={isSM || isMD ? 'md' : 'sm'}
            danger={isBandwidthInvalid}
            value={bandwidth.isCustom ? bandwidth.value : ''}
            onChange={handleBandwidthCustomInputChange}
            placeholder='Custom bandwidth'
          />

          <small className={small + bandwidthSmallColor}>
            {bandwidthIsOutOfRange
              ? 'Out of the frequency range'
              : 'Required field'}
          </small>
        </>
      )}
    </div>
  )
}

export default HarmonicCheck

const label = `${h6} text-light-1`
