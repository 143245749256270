import React from 'react'
import PropTypes from 'prop-types'
import LoadingIcon from './LoadingIcon'

const BlockSwitch = props => {
  const {
    on = false,
    className = '',
    loading,
    disabled,
    onClick = () => console.log('!!!  TextSwitch not setting onChange !!!'),
  } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleClick = () => {
    if (!disabled) onClick()
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `
                    ${className}
                    ${containerCommon}
                    ${cursor(disabled)} 
                    `
  const track = `
                    ${trackCommon}
                    ${trackTheme(on)}
                    ${cursor(disabled)} 
                    `
  const circle = `
                  ${circleCommon}
                  ${circleTheme} 
                  ${circleTranslate(on)} 
                  ${cursor(disabled)} 
                  `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className={container} onClick={handleClick}>
      <div className={track}>
        <div className={circle}>
          <LoadingIcon visible={loading} />
        </div>
      </div>
    </div>
  )
}
export default BlockSwitch

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerCommon = ` relative w-6 h-3
                          flex items-center 
                          select-none rounded-full duration-500`

const trackCommon = `w-full h-full rounded-full duration-500`
const trackTheme = on => (on ? 'bg-teal-2' : 'bg-white/20')

const circleCommon = `absolute w-3 h-3 rounded-full duration-1000 top-0 left-0 shadow-black/20 shadow-sm flex items-center justify-center`
const circleTheme = `bg-white`
const circleTranslate = on => (on ? 'translate-x-full' : 'translate-x-0')

const cursor = disabled => (disabled ? 'cursor-not-allowed' : 'cursor-pointer')

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
BlockSwitch.propTypes = {
  on: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
