import React from 'react'

import Box from 'src/assets/svg/device/avatar/CloverCell1024/Box'
import Array from 'src/assets/svg/device/avatar/CloverCell1024/Array'

const CloverCell1024 = props => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const container = `relative w-full h-full overflow-hidden `
  const svgCommon = `absolute 
                    inset-x-0 mx-auto 
                    duration-700 ease-out
                    `

  const box = `
                ${svgCommon} 
                bottom-4 scale-[1] group-hover:scale-[1.15] 
                `

  const array = `
                    ${svgCommon} 
                    bottom-4 scale-[1] group-hover:scale-[1.15] 
                    group-hover:animate-breathBrightness
                    `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <Box className={box} />
      <Array className={array} />
    </div>
  )
}
export default CloverCell1024

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CloverCell1024.propTypes = {}
