import React from 'react'
import { useDispatch } from 'react-redux'

import SegmentedButton from 'src/components/Button/SegmentedButton'
import CommonButton from 'src/components/Button/CommonButton'

import { h6 } from 'src/containers/operating/device/beamformers/style'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  cloverCellEvb_current_readMethodChange_watcher,
  cloverCellEvb_current_readClick_watcher,
} from 'src/redux/actions/beamformers/cloverCellEvbAction'

import useGetUIControl from 'src/hooks/useGetUIControl'

const Read = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const readMethod = current.data.current.readMethod

  const { single: s_uiControl } = useGetUIControl(sn)

  const isOperatingStatusReading = s_uiControl.current.isOperatingStatusReading

  const handleChange = (e, value) =>
    dispatch(cloverCellEvb_current_readMethodChange_watcher({ sn, value }))

  const handleReadClick = () =>
    dispatch(cloverCellEvb_current_readClick_watcher({ sn }))

  return (
    <div className='w-full grid grid-cols-2 gap-x-2'>
      <div className='flex flex-col'>
        <h6 className={subtitle}>Read Method</h6>
        <SegmentedButton
          type='outlined'
          size='sm'
          value={readMethod}
          disabled={isOperatingStatusReading}
          onChange={handleChange}>
          <SegmentedButton.Option name='single'>Single</SegmentedButton.Option>
          <SegmentedButton.Option name='continuous'>
            Continuous
          </SegmentedButton.Option>
        </SegmentedButton>

        {readMethod === 'continuous' && (
          <small className='self-end mt-1 text-white/50'>
            The update rate is 1000 ms.
          </small>
        )}
      </div>

      <div>
        <h6 className={subtitle}>Read Control</h6>
        <CommonButton
          size='sm'
          className='w-full'
          danger={isOperatingStatusReading}
          onClick={handleReadClick}>
          {isOperatingStatusReading ? 'STOP' : 'Read'}
        </CommonButton>
      </div>
    </div>
  )
}

Read.propTypes = {}

export default Read

const subtitle = `${h6} text-light-1 mb-1`
