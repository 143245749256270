import React from 'react'

import Device from 'src/assets/svg/device/avatar/Sg/Device'
import Cable from 'src/assets/svg/device/avatar/Sg/Cable'
import logo from 'src/assets/svg/device/avatar/Sg/logo.png'

const Sg = props => {
  return (
    <div className={containerClass}>
      <Device className={deviceClass} />
      <Cable className={cableCClass} cableLightClassName={cableLightClass} />
      <img src={logo} alt='tmytek logo on sg' className={logoClass} />
    </div>
  )
}
export default Sg

const containerClass = `
                          relative 
                          w-full h-full 
                          flex items-center justify-center 
                          overflow-hidden 
                          group
                          `
const deviceClass = `
                          absolute inset-0 m-auto 
                          translate-y-2
                          scale-[1] group-hover:scale-[0.9]
                          duration-1000 
                          `
const cableCClass = ` 
                          absolute inset-0 m-auto z-10 
                          opacity-0  group-hover:opacity-100 
                          -translate-x-[300px] group-hover:-translate-x-[118px] 
                          translate-y-1 
                          scale-[0.9] 
                          duration-1000
                          `
const logoClass = `
                  absolute 
                  z-20 
                  scale-[0.5] group-hover:scale-[0.45] 
                  translate-y-8 translate-x-5 
                  group-hover:translate-y-[30px]  group-hover:translate-x-[18px] 
                  duration-1000
`

const cableLightClass = `group-hover:animate-breathOpacity`
