import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'
import RadioButton from 'src/components/Button/RadioButton'
import Checkbox from 'src/components/Button/Checkbox'
import CommonInput from 'src/components/Input/CommonInput'

import { modalActions } from 'src/redux/slices/modal'
import { facility_chooseDeviceModal_cancelClick_watcher } from 'src/redux/actions/facility/getQuote'

import { productList, purposeList } from 'src/constants/getQuote'

const ChooseDeviceAndPurpose = props => {
  const dispatch = useDispatch()

  const { interestedProduct, purpose, purposeOthers } = props

  const isOthersInvalid = purpose === 'others' && !purposeOthers
  const isNextButtonDisabled =
    interestedProduct.length === 0 || !purpose || isOthersInvalid

  const handleProductOptionClick = name => {
    if (interestedProduct.includes(name))
      dispatch(
        modalActions.setModalOfGetQuoteContent({
          interestedProduct: interestedProduct.filter(e => e !== name),
        })
      )

    if (!interestedProduct.includes(name))
      dispatch(
        modalActions.setModalOfGetQuoteContent({
          interestedProduct: [...interestedProduct, name],
        })
      )
  }

  const handlePurposeClick = name =>
    dispatch(
      modalActions.setModalOfGetQuoteContent({
        purpose: name,
      })
    )

  const handleOthersChange = event =>
    dispatch(
      modalActions.setModalOfGetQuoteContent({
        purposeOthers: event.formattedValue,
      })
    )

  const handleCancelClick = () =>
    dispatch(facility_chooseDeviceModal_cancelClick_watcher())

  const handleSubmit = async event => {
    event.preventDefault()
    dispatch(modalActions.showModalOfGetQuotePersonalInformation())
  }

  return (
    <Modal>
      <Modal.Title className='mb-6'>Get Quote</Modal.Title>

      <form onSubmit={handleSubmit}>
        <div className='max-h-[400px] overflow-y-scroll scrollbar pr-2 translate-x-2'>
          <Modal.FormLabel>
            Choose the product you are interested in *
          </Modal.FormLabel>
          <div className='grid grid-cols-2 gap-5'>
            {productList.map(e => (
              <ProductOption
                key={`ProductOption ${e.name}`}
                name={e.name}
                category={e.category}
                icon={e.icon}
                selected={interestedProduct.includes(e.name)}
                onClick={handleProductOptionClick}
              />
            ))}
          </div>

          <Modal.Divide />

          <Modal.FormLabel>Your purpose *</Modal.FormLabel>
          <div className='flex flex-col gap-y-3'>
            {purposeList.map(e => (
              <RadioButton
                key={`Your Purpose ${e}`}
                size='md'
                group='purpose'
                name={e.toLowerCase()}
                label={e}
                checked={purpose === e.toLowerCase()}
                onClick={handlePurposeClick}
              />
            ))}

            {purpose === 'others' && (
              <CommonInput
                value={purposeOthers || ''}
                placeholder='Others'
                onChange={handleOthersChange}
              />
            )}
          </div>
        </div>

        <Modal.RowButtonBox>
          <CommonButton size='lg' type='outlined' onClick={handleCancelClick}>
            Cancel
          </CommonButton>
          <CommonButton
            size='lg'
            type='primary'
            buttonType='submit'
            disabled={isNextButtonDisabled}>
            Next
          </CommonButton>
        </Modal.RowButtonBox>
      </form>
    </Modal>
  )
}

export default ChooseDeviceAndPurpose

const ProductOption = ({ name, category, icon, selected, onClick }) => {
  const productOptionContainer = `
                      ${productOptionContainerCommon} 
                      ${selected ? 'border-teal' : 'border-light-4'}
                      `

  const handleClick = () => onClick(name)

  return (
    <div className={productOptionContainer} onClick={handleClick}>
      <div className='flex flex-col items-center gap-y-1 mb-4'>
        {icon}
        <label className={productOptionName}>{name}</label>
        <small className={productOptionCategory}>{category}</small>
      </div>

      <Checkbox status={selected ? 'checked' : 'uncheck'} />
    </div>
  )
}

const productOptionContainerCommon = `
                  flex flex-col items-center justify-center 
                  border-1 border-solid bg-transparent hover:bg-white/10
                  py-5 rounded duration-500 cursor-pointer
                  `

const productOptionName = `text-base text-white`
const productOptionCategory = `bg-white/10 px-1 rounded-sm text-xs text-light-4`
