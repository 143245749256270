import { useState, useEffect } from 'react'
import { isMobileOnly, isTablet, isDesktop } from 'react-device-detect'

// const { isSM, isMD, isLG, isXL, is2XL } = useGetScreenSize()

const useGetScreenSize = () => {
  const breakpoint = { sm: 0, md: 744, lg: 1024, xl: 1280, '2xl': 1440 }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  function handleResize() {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // 手機垂直 水平翻轉
  useEffect(() => {
    window.addEventListener('orientationchange', handleResize)
    handleResize()
    return () => window.removeEventListener('orientationchange', handleResize)
  }, [])

  return {
    isSM: windowWidth < breakpoint.md,
    isMD: windowWidth >= breakpoint.md && windowWidth < breakpoint.lg,
    isLG: windowWidth >= breakpoint.lg && windowWidth < breakpoint.xl,
    isXL: windowWidth >= breakpoint.xl && windowWidth < breakpoint['2xl'],
    is2XL: windowWidth >= breakpoint['2xl'],

    isMobile: isMobileOnly,
    isTablet,
    isDesktop,
  }
}

export default useGetScreenSize
