import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import { BiError } from 'react-icons/bi'

const FailedToSaveForGeneral = props => {
  const dispatch = useDispatch()
  return (
    <Modal>
      <Modal.Icon>
        <BiError className='text-red' />
      </Modal.Icon>

      <Modal.Title>Failed to Save</Modal.Title>

      <Modal.Description>
        Please try again later. If the problem persists, please contact TMYTEK.
      </Modal.Description>

      <Modal.ColumnButtonBox>
        <CommonButton
          type='general'
          size='lg'
          onClick={() => dispatch(modalActions.clearNormalModal())}>
          OK
        </CommonButton>
      </Modal.ColumnButtonBox>
    </Modal>
  )
}

export default FailedToSaveForGeneral
