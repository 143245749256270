import { select, put } from 'redux-saga/effects'
import * as R from 'ramda'

import { devWarLog } from 'src/funcs/tools'

import { modalActions } from 'src/redux/slices/modal'

import { getInitCaliData } from 'src/constants/powerDetector'

export function* getCaliVoltage({ response }) {
  try {
    const [, freqPower] = Object.entries(response?.data)[0]
    const [freq, { power }] = Object.entries(freqPower)[0]

    // 1. 顯示 error mask
    yield put(
      modalActions.set_editIsGetVolError_of_powerDetector_CC({ value: true })
    )

    let targetVoltage
    let oppositeVoltage

    const editTemporaryData = yield select(
      state => state.facilityModal.normal.props.editTemporaryData
    )
    const editGetVoltageList = yield select(
      state => state.facilityModal.normal.props.editGetVoltageList
    )
    let cloneTempData = R.clone(editTemporaryData)

    // 2. 取消 target 的 白字狀態
    const [[id, content]] = Object.entries(cloneTempData)

    if (+content.value[freq].lowPower === +power) {
      targetVoltage = 'lowVolt'
      oppositeVoltage = 'highVolt'
    } else if (+content.value[freq].highPower === +power) {
      targetVoltage = 'highVolt'
      oppositeVoltage = 'lowVolt'
    } else throw Error('getCaliVoltage: BE return power not matched!')

    yield put(
      modalActions.remove_getVoltageList_for_modal_of_powerDetector_CC({
        freq,
        target: targetVoltage,
      })
    )

    // 3. 調整 target or opposite voltage
    const isNeedChangeTargetVoltToEmpty =
      editGetVoltageList[freq].includes(oppositeVoltage)

    const isNeedChangeBothVoltToDefault =
      !editGetVoltageList[freq].includes(oppositeVoltage)

    let newTargetVolt
    let newOppositeVolt = cloneTempData[id].value[freq][oppositeVoltage]

    if (isNeedChangeTargetVoltToEmpty) {
      newTargetVolt = '---'
    } else if (isNeedChangeBothVoltToDefault) {
      newTargetVolt = Object.values(getInitCaliData())[0].value[freq][
        oppositeVoltage
      ]
      newOppositeVolt = Object.values(getInitCaliData())[0].value[freq][
        targetVoltage
      ]
    } else {
      newTargetVolt = Object.values(getInitCaliData())[0].value[freq][
        targetVoltage
      ]
    }

    cloneTempData[id].value[freq][oppositeVoltage] = newOppositeVolt
    cloneTempData[id].value[freq][targetVoltage] = newTargetVolt

    // 4. 將當前 freq 的 isDefault 因為有錯就一直不合法
    cloneTempData[id].value[freq]['isDefault'] = true

    yield put(
      modalActions.set_temporaryData_for_modal_of_powerDetector_CC({
        editTemporaryData: cloneTempData,
      })
    )
  } catch (error) {
    devWarLog('[res-handler] caliSelect error:', error)
  }
}
