import { put, call } from 'redux-saga/effects'

import {
  addSinglePowerPoint as ui_addSinglePowerPoint,
  clearChartData as ui_clearChartData,
  updateMeasureDataLength as ui_updateMeasureDataLength,
} from 'src/redux/slices/uiControl/powerDetector'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* dashboardRenderPoint(response) {
  try {
    const [sn, { power, measureDataLength }] = Object.entries(response?.data)[0]
    const { currentData } = yield call(getCommonArgs, sn)

    currentData.deviceControl.dashboard.currentPowerValue = power
    yield put(setSingleDeviceData({ sn, data: currentData }))

    const invalidPower = -1000
    if (+power !== invalidPower) {
      yield put(ui_addSinglePowerPoint({ sn, power, measureDataLength }))
      yield put(ui_updateMeasureDataLength({ sn, measureDataLength }))
    }
  } catch (error) {
    devWarLog('[res-handler] dashboardRenderPoint error:', error)
  }
}

export function* clearPowerHistory(response) {
  try {
    const { sn } = response?.data
    yield put(ui_clearChartData({ sn }))

    const { currentData } = yield call(getCommonArgs, sn)
    currentData.deviceControl.dashboard.currentPowerValue = null
    currentData.deviceControl.dashboard.measureData = []

    yield put(ui_updateMeasureDataLength({ sn, measureDataLength: 0 }))
    yield put(setSingleDeviceData({ sn, data: currentData }))
  } catch (error) {
    devWarLog('[res-handler] clearPowerHistory error:', error)
  }
}
