import React from 'react'
import ChooseDeviceAndPurpose from 'src/containers/Modal/Normal/GetQuote/ChooseDeviceAndPurpose'
import PersonalInformation from 'src/containers/Modal/Normal/GetQuote/PersonalInformation'
import GetQuoteSuccess from 'src/containers/Modal/Normal/GetQuote/Success'

const GetQuote = modalProps => {
  const { status: modalStatus } = modalProps

  if (modalStatus === 'chooseDeviceAndPurpose')
    return <ChooseDeviceAndPurpose {...modalProps} />

  if (modalStatus === 'personalInformation')
    return <PersonalInformation {...modalProps} />

  if (modalStatus === 'success') return <GetQuoteSuccess {...modalProps} />

  return <></>
}

GetQuote.propTypes = {}

export default GetQuote
