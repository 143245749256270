import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import { errorActions } from 'src/redux/slices/uiControl/facility/error'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import operationalFailureImg from 'src/assets/img/operational_failure.png'

const ErrorBoundaryOfThree = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const { handleRetry } = props

  const handleError = (info, error) => {
    const time = moment().format('YYYY-MM-DD  HH:mm:ss')
    dispatch(
      errorActions.setDeviceError({
        sn,
        errorData: {
          time,
          code: 1,
          codeName: 'FE ERROR',
          message: String(info) + '\n' + String(error.componentStack),
        },
      })
    )
  }

  return (
    <ErrorBoundary
      // fallback={<></>}
      fallback={<CheckHardwareAcceleration />}
      onReset={handleRetry}
      onError={handleError}>
      {props.children}
    </ErrorBoundary>
  )
}

ErrorBoundaryOfThree.propTypes = {}

export default ErrorBoundaryOfThree

const CheckHardwareAcceleration = () => (
  <div className='w-full h-full flex flex-col gap-y-2 justify-center items-center '>
    <img
      src={operationalFailureImg}
      alt='operational Failure Img'
      className='mb-8'
    />

    <div className='w-[336px] text-center text-light-4'>
      <b>Could not load 3D image</b>
      <br /> Please check the following and try again:
      <br />
      1. DNS network settings
      <br />
      2. Browser hardware acceleration is enabled
    </div>
  </div>
)
