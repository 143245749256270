import error from 'src/redux/slices/modal/high/error'
import member from 'src/redux/slices/modal/high/member'
import beamformers from 'src/redux/slices/modal/high/beamformers'

const high = {
  ...error,
  ...member,
  ...beamformers,
}

export default high
