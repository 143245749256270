import { call, put } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { setShowLoadingMask } from 'src/redux/slices/uiControl/beamformers/bbox'

import { devWarLog } from 'src/funcs/tools'

function* _updateChannelBoardData({ sn, rfMode, lstBoardData }) {
  try {
    let newCurrentData = yield call(getCurrentData, sn)

    newCurrentData.deviceControl.channel[rfMode].lstBoardData =
      yield lstBoardData

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
  } catch (error) {
    devWarLog('[res-handler] _updateChannelBoardData error:', error)
  }
}

export function* steeringThetaPhi(response) {
  try {
    const [sn, { channel }] = Object.entries(response?.data)[0]
    yield call(_updateChannelBoardData, {
      sn,
      rfMode: 'tx',
      lstBoardData: channel?.tx.lstBoardData,
    })

    yield call(_updateChannelBoardData, {
      sn,
      rfMode: 'rx',
      lstBoardData: channel?.rx.lstBoardData,
    })

    yield put(
      setShowLoadingMask({
        sn,
        value: { common: false, steering: false, channel: false },
      })
    )
  } catch (error) {
    devWarLog('[res-handler] steeringThetaPhi error:', error)
  }
}

export function* steeringGain(response) {
  try {
    const [sn, { lstBoardData, rfMode }] = Object.entries(response?.data)[0]

    yield call(_updateChannelBoardData, { sn, rfMode, lstBoardData })

    yield put(
      setShowLoadingMask({
        sn,
        value: { common: false, steering: false, channel: false },
      })
    )
  } catch (error) {
    devWarLog('[res-handler] steeringGain error:', error)
  }
}
