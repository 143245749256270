import React from 'react'
import { useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'
import Mask from 'src/components/Mask'
import Carousel from 'src/components/Carousel'

import beamGuideImg1 from 'src/assets/img/beam_guide_1.png'
import beamGuideImg2 from 'src/assets/img/beam_guide_2.png'
import beamGuideImg3 from 'src/assets/img/beam_guide_3.png'

import {
  setMaskDontShowAgain,
  setShowFirst3DInstructionMask,
  setShowManual3DInstructionMask,
} from 'src/redux/slices/uiControl/beamformers/bbox'

const InstructionMask = props => {
  const dispatch = useDispatch()
  const { sn, showManual3DInstructionMask, showFirstInstructionMask } = props

  const handleInstructionOKClick = () => {
    if (showManual3DInstructionMask)
      return dispatch(setShowManual3DInstructionMask({ sn, value: false }))

    return dispatch(setShowFirst3DInstructionMask({ sn, value: false }))
  }

  const handleInstructionDontShowAgainClick = () =>
    dispatch(setMaskDontShowAgain({ key: 'instruction', value: true }))

  return (
    <Mask>
      <Carousel className='h-[380px] xl:h-[420px]'>
        <Carousel.Item>
          <div className={instructionBox}>
            <img src={beamGuideImg1} alt='' />
            <div className={messageContainer}>
              Grab the red ball,
              <br /> drag to control the beam Theta value
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={instructionBox}>
            <img src={beamGuideImg2} alt='' />
            <div className={messageContainer}>
              Grab the green ball,
              <br /> drag to control the beam Phi value
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={instructionBox}>
            <img src={beamGuideImg3} alt='' />
            <div className={messageContainer}>
              3D image can be rotated, zoomed in and out to adjust the angle
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div className='flex flex-col items-center'>
        <CommonButton
          size='md'
          type='general'
          className='my-6'
          onClick={handleInstructionOKClick}>
          OK
        </CommonButton>

        {showFirstInstructionMask && (
          <CommonButton
            size='md'
            type='outlined'
            onClick={handleInstructionDontShowAgainClick}>
            Don't show again
          </CommonButton>
        )}
      </div>
    </Mask>
  )
}

InstructionMask.propTypes = {}

export default InstructionMask

const messageContainer = `max-w-[345px] mx-auto text-white text-center`

const instructionBox = `w-full pt-[64px] flex flex-col items-center cursor-grab`
