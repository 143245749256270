import { call, put, race, take } from 'redux-saga/effects'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'
import { udboxUIControlForSingleAction as uiControlAction } from 'src/redux/slices/uiControl/freqConverter/udbox'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { devWarLog } from 'src/funcs/tools'

import freqConverterApi from 'src/redux/sagas/services/socketAPI/freqConverter'
import { modalActions } from 'src/redux/slices/modal'
import {
  ud0630_turnOffOutputCheckModal_okClick_watcher,
  ud0630_turnOffOutputCheckModal_cancelClick_watcher,
  ud0630_toExternalModal_useExternalClick_watcher,
  ud0630_toExternalModal_cancelClick_watcher,
} from 'src/redux/actions/freqConverter/udboxAction'

export function* refSignalSourceChange(payloads) {
  try {
    const { sn, value } = payloads
    const isToInternal = +value === 0
    const isToExternal = +value === 1
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    if (isToInternal) {
      yield put(setSingleDeviceData({ sn, data: currentData }))

      yield call(__socket_API_sender, freqConverterApi.UD0630_SET_REF_SOURCE, {
        sn,
        lookupID,
        refSource: 0,
        inputRefSource: null,
      })
    }

    if (isToExternal) yield call(inputRefSourceChange, { sn })
  } catch (error) {
    devWarLog('[handler] refSignalSourceChange error:', error)
  }
}

function* _outputRefCheck({ sn }) {
  try {
    let { currentData } = yield call(getCommonArgs, sn)

    const isOutputRefSourceEnable =
      currentData.settings.device.outputRefSource.isEnable

    let result = true

    if (isOutputRefSourceEnable) {
      yield put(
        modalActions.showModalOfUD0630ExternalTurnOffOutputCheck({ sn })
      )
      const { cancel } = yield race({
        ok: take(ud0630_turnOffOutputCheckModal_okClick_watcher.type),
        cancel: take(ud0630_turnOffOutputCheckModal_cancelClick_watcher.type),
      })

      if (cancel) {
        yield put(modalActions.clearNormalModal())
        result = false
      }
    }

    return result
  } catch (error) {
    devWarLog('[handler] outputRefCheck error:', error)
  }
}
function* _useExternalCheck({ sn }) {
  try {
    yield put(modalActions.showModalOfUDBox0630ToExternalCheck({ sn }))

    const { useExternal, cancel } = yield race({
      useExternal: take(ud0630_toExternalModal_useExternalClick_watcher.type),
      cancel: take(ud0630_toExternalModal_cancelClick_watcher.type),
    })

    if (cancel) yield put(modalActions.clearNormalModal())
    if (useExternal) {
      const { lookupID } = yield call(getCommonArgs, sn)
      const inputRefSource = useExternal.payload.inputRefSource

      yield put(
        uiControlAction.setUD0630Requesting({
          sn,
          key: 'refSource',
        })
      )
      yield call(__socket_API_sender, freqConverterApi.UD0630_SET_REF_SOURCE, {
        sn,
        lookupID,
        refSource: 1,
        inputRefSource,
      })
    }
  } catch (error) {
    devWarLog('[handler] _useExternalCheck error:', error)
  }
}

export function* inputRefSourceChange(payloads) {
  try {
    const { sn } = payloads
    const outputRefCheckPass = yield call(_outputRefCheck, { sn })
    if (outputRefCheckPass) yield call(_useExternalCheck, { sn })
  } catch (error) {
    devWarLog('[handler] inputRefSourceChange error:', error)
  }
}

export function* outputSignalSourceEnableChange(payloads) {
  try {
    const { sn, value: isEnable } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)

    const currentSelection = isEnable
      ? currentData.settings.device.outputRefSource.currentSelection
      : null

    yield put(
      uiControlAction.setUD0630Requesting({
        sn,
        key: 'outputSignalSourceEnable',
      })
    )
    yield call(__socket_API_sender, freqConverterApi.UD0630_OUTPUT_REF_SOURCE, {
      sn,
      lookupID,
      isEnable,
      currentSelection,
    })
  } catch (error) {
    devWarLog('[handler] outputSignalSourceEnableChange error:', error)
  }
}

export function* outputSignalSourceSelectionChange(payloads) {
  try {
    const { sn, value: currentSelection } = payloads
    let { lookupID } = yield call(getCommonArgs, sn)

    yield call(__socket_API_sender, freqConverterApi.UD0630_OUTPUT_REF_SOURCE, {
      sn,
      lookupID,
      isEnable: true,
      currentSelection,
    })
  } catch (error) {
    devWarLog('[handler] outputSignalSourceSelectionChange error:', error)
  }
}

export function* outputLOFrequencyChange(payloads) {
  try {
    const { sn, value: isOutputLoEnable } = payloads
    let { lookupID } = yield call(getCommonArgs, sn)

    yield put(
      uiControlAction.setUD0630Requesting({
        sn,
        key: 'outputLOFrequency',
      })
    )
    yield call(
      __socket_API_sender,
      freqConverterApi.UD0630_OUTPUT_LO_FREQUENCY,
      {
        sn,
        lookupID,
        isOutputLoEnable,
      }
    )
  } catch (error) {
    devWarLog('[handler] outputLOFrequencyChange error:', error)
  }
}
