import React, { useState } from 'react'
import InputGroup from 'src/components/Input/InputGroup'

const InputGroupPkg = ({ inputGroupProps, isInvalid, hint }) => {
  const [inputFocus, setInputFocus] = useState(false)

  const showHint = isInvalid || inputFocus
  const hintTextColor = isInvalid ? `text-red` : `text-light-5`
  return (
    <>
      <InputGroup
        {...inputGroupProps}
        inputType='number'
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
      />
      {showHint && <small className={`text-xs ${hintTextColor}`}>{hint}</small>}
    </>
  )
}

InputGroupPkg.propTypes = {}

export default InputGroupPkg
