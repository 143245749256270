import React from 'react'
// import { useDispatch } from 'react-redux'

// import CommonSelect from 'src/components/Select/CommonSelect'

import { h6 } from 'src/containers/operating/device/beamformers/style'

// import { cloverCell1024_common_freqSelect_watcher } from 'src/redux/actions/beamformers/cloverCellArray1024Action'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const CentralFrequency = props => {
  // const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  // const sn = current?.sn
  const currentFreq = current.data.deviceControl.common.currentFreq
  // const lstFreqOptions = current.data.deviceControl.common.lstFreqOptions

  //! 5/16 slack Ivy 指示 改成單一頻率，不用下拉選單
  //! 但怕後續要加回來，所以用註解的方式
  // const handleChange = (event, value) =>
  //   dispatch(cloverCell1024_common_freqSelect_watcher({ sn, value }))

  return (
    <div>
      <h6 className={subtitle}>Central Frequency</h6>
      <p className='text-sm leading-6 text-light-4'>{currentFreq} GHz</p>

      {
        //! 5/16 slack Ivy 指示 改成單一頻率，不用下拉選單 但怕後續要加回來，所以用註解的方式
        /* <CommonSelect
        size='sm'
        value={currentFreq}
        currentChildren={<>{currentFreq} GHz</>}
        onChange={handleChange}
        disabled={true}>
        {lstFreqOptions.map(e => (
          <CommonSelect.Option name={e} key={'clover cell freq ' + e}>
            {e} GHz
          </CommonSelect.Option>
        ))}
      </CommonSelect> */
      }
    </div>
  )
}

CentralFrequency.propTypes = {}

export default CentralFrequency

const subtitle = `${h6} text-light-1 mb-1`
