import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Dropdown from 'src/components/Popup/Dropdown'

import { BiCog, BiLogOutCircle } from 'react-icons/bi'
// import { HiOutlineUserGroup } from 'react-icons/hi'
import { member_signOut_watcher } from 'src/redux/actions/facility/member'

const DropdownMenu = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <Dropdown
      dismiss={true}
      placement='bottom'
      render={({ close }) => (
        <div className='bg-dark-4 w-[375px] md:w-[220px] flex flex-col gap-y-2 py-3'>
          <div
            className={dropdownItem}
            onClick={() => {
              close()
              navigate(`/settings`)
            }}>
            <BiCog />
            Settings
          </div>
          {/* <div
            className={dropdownItem}
            onClick={() => {
              close()
              navigate(`/team`)
            }}>
            <HiOutlineUserGroup />
            Team
          </div> */}

          <div className={divider} />

          <div
            className={dropdownItem}
            onClick={() => {
              close()
              dispatch(member_signOut_watcher())
            }}>
            <BiLogOutCircle />
            Log out
          </div>
        </div>
      )}>
      {props.children}
    </Dropdown>
  )
}

export default DropdownMenu

const dropdownItem = `
                      flex items-center gap-x-2
                      px-3 py-2 
                      hover:bg-teal 
                      text-white hover:text-black 
                      text-sm 
                      font-normal hover:font-bold
                      duration-500 
                      cursor-pointer
                      `

const divider = `h-[1px] w-full bg-dark-5`
