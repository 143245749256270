import moment from 'moment'
import * as R from 'ramda'
import { nanoid } from 'nanoid'

import { bboxInitAppendData } from '../configs/initAppendData/bbox.mjs'
import { bboardInitAppendData } from '../configs/initAppendData/bboard.mjs'
import { cloverCellEvbInitAppendData } from '../configs/initAppendData/cloverCellEvb.mjs'
import { cloverCell1024InitAppendData } from '../configs/initAppendData/cloverCell1024.mjs'
import { cloverCellCTTCInitAppendData } from '../configs/initAppendData/cloverCellCTTC.mjs'
import { risInitAppendData } from '../configs/initAppendData/ris.mjs'
import {
  mockChannel_1,
  mockChannel_2,
  mockChannel_3,
  mockChannel_4,
} from '../constants/ris.mjs'

const beamformersFunctions = {
  //* ------------------ ------------------- ------------------
  //* ------------------ ------------------- ------------------
  //* ------------------     Beamformers     -----------------
  //* ------------------ ------------------- ------------------
  //* ------------------ ------------------- ------------------
  _isBBox(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'bbox')
    } catch (error) {
      console.warn(error)
    }
  },
  _isBBoard(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'bboard')
    } catch (error) {
      console.warn(error)
    }
  },

  _isCloverCellEvb(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'clover_cell_evb')
    } catch (error) {
      console.warn(error)
    }
  },
  _isCloverCell1024(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'clover_cell_array_1024')
    } catch (error) {
      console.warn(error)
    }
  },
  _isCloverCellCTTC(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'clover_cell_cttc')
    } catch (error) {
      console.warn(error)
    }
  },
  _isRis(sn) {
    try {
      const deviceType = this._getDeviceType(sn)
      return this._isIncludesCategory(deviceType, 'ris')
    } catch (error) {
      console.warn(error)
    }
  },

  _getCaliTable(sn) {
    try {
      return R.clone(this.caliTables[sn])
    } catch (error) {
      console.warn(error)
    }
  },

  _getRfMode(sn) {
    try {
      return R.clone(this._getCurrentData(sn)?.deviceControl?.common.rfMode)
    } catch (error) {
      console.warn(error)
    }
  },

  _getListAntennaData(sn) {
    try {
      return R.clone(
        this._getCurrentData(sn)?.deviceControl?.common.lstAntennaData
      )
    } catch (error) {
      console.warn(error)
    }
  },

  _setCurrentControlMode({ sn, currentControlMode }) {
    let currentData = this._getCurrentData(sn)
    const rfMode = currentData.deviceControl.common.rfMode

    currentData.deviceControl.common.currentControlMode[rfMode] =
      currentControlMode

    this._updateSingleDeviceData(sn, currentData)
  },

  _bboxSyncChToSteering({ sn, rfMode }) {
    try {
      let currentData = this._getCurrentData(sn)

      const beamCurrentGain =
        currentData.deviceControl.steering[rfMode].beamCurrentGain

      currentData.deviceControl.channel[rfMode].lstBoardData =
        currentData.deviceControl.channel[rfMode].lstBoardData.map(bData => {
          const elementGainMax = bData.totalGainMax - bData.commonGainMax
          const totalGainMin = bData.totalGainMin

          const gainAppendOffset = beamCurrentGain - totalGainMin

          const elementGain =
            gainAppendOffset >= elementGainMax
              ? elementGainMax
              : gainAppendOffset

          const newCommonCurrentGain = beamCurrentGain - elementGain

          const newLstChannelData = bData.lstChannelData.map(cData => ({
            ...cData,
            totalGain: beamCurrentGain,
            elementGain,
          }))

          return {
            ...bData,
            commonCurrentGain: newCommonCurrentGain,
            lstChannelData: newLstChannelData,
          }
        })

      this._updateSingleDeviceData(sn, currentData)
    } catch (error) {
      console.warn(error)
    } finally {
      let currentData = this._getCurrentData(sn)

      return {
        tx: currentData.deviceControl.channel['tx'].lstBoardData,
        rx: currentData.deviceControl.channel['rx'].lstBoardData,
      }
    }
  },

  _bboxReset(sn) {
    try {
      let newBBoxData = this._getCurrentData(sn)

      newBBoxData.deviceControl.common.currentControlMode.tx = 'channel'
      newBBoxData.deviceControl.common.currentControlMode.rx = 'channel'

      // 將 steering 設為 預設值 (theta & phi = 0 , gain 最大)
      newBBoxData.deviceControl.steering.tx.theta = 0
      newBBoxData.deviceControl.steering.tx.phi = 0
      newBBoxData.deviceControl.steering.tx.beamCurrentGain =
        newBBoxData.deviceControl.steering.tx.beamGainMax

      newBBoxData.deviceControl.steering.rx.theta = 0
      newBBoxData.deviceControl.steering.rx.phi = 0
      newBBoxData.deviceControl.steering.rx.beamCurrentGain =
        newBBoxData.deviceControl.steering.rx.beamGainMax

      // 將 channel 設為 預設值 (gain 最大, phase 0)
      newBBoxData.deviceControl.channel.rx.lstBoardData =
        newBBoxData.deviceControl.channel.rx.lstBoardData.map(bData => ({
          ...bData,
          commonCurrentGain: bData.commonGainMax,
          lstChannelData: bData.lstChannelData.map(cData => ({
            power: true,
            totalGain: bData.totalGainMax,
            elementGain: bData.totalGainMax - bData.commonGainMax,
            phase: 0,
          })),
        }))
      newBBoxData.deviceControl.channel.tx.lstBoardData =
        newBBoxData.deviceControl.channel.tx.lstBoardData.map(bData => ({
          ...bData,
          commonCurrentGain: bData.commonGainMax,
          lstChannelData: bData.lstChannelData.map(cData => ({
            power: true,
            totalGain: bData.totalGainMax,
            elementGain: bData.totalGainMax - bData.commonGainMax,
            phase: 0,
          })),
        }))

      this._updateSingleDeviceData(sn, newBBoxData)
    } catch (error) {
      console.warn(error)
    }
  },

  _risGetMockChannel(sn) {
    if (this.risMockCHIndex?.[sn] === undefined) this.risMockCHIndex[sn] = 0

    const mockChannelsList = [
      mockChannel_1,
      mockChannel_2,
      mockChannel_3,
      mockChannel_4,
    ]

    const newSnMockCHIndex =
      this.risMockCHIndex?.[sn] === mockChannelsList.length - 1
        ? 0
        : this.risMockCHIndex?.[sn] + 1

    this.risMockCHIndex[sn] = newSnMockCHIndex

    return mockChannelsList[newSnMockCHIndex]
  },

  //* -----------------      All - Init        -----------------
  //* -----------------      All - Init        -----------------
  //* -----------------      All - Init        -----------------
  //* -----------------      All - Init        -----------------
  //* -----------------      All - Init        -----------------
  _beamformersInit(sn) {
    try {
      const currentData = this._getCurrentData(sn)

      const isBBox = this._isBBox(sn)
      const isBBoard = this._isBBoard(sn)
      const isCloverCellEvb = this._isCloverCellEvb(sn)
      const isCloverCell1024 = this._isCloverCell1024(sn)
      const isCloverCellCTTC = this._isCloverCellCTTC(sn)
      const isRis = this._isRis(sn)

      let appendData

      if (isBBox) appendData = bboxInitAppendData(sn)
      if (isBBoard) appendData = bboardInitAppendData(sn)
      if (isCloverCellEvb) appendData = cloverCellEvbInitAppendData(sn)
      if (isCloverCell1024) appendData = cloverCell1024InitAppendData(sn)
      if (isRis) appendData = risInitAppendData(sn)

      if (isCloverCellCTTC) {
        const lastSn = sn[sn.length - 1]
        appendData = cloverCellCTTCInitAppendData(sn, +lastSn)
      }

      const newDeviceData = { ...currentData, ...appendData }

      this.caliTables = {
        ...this.caliTables,
        [sn]: appendData.deviceControl.common.lstFreqOptions,
      }

      //! 開發中可以替換狀態
      newDeviceData.info.initialization = 'finished'
      // newDeviceData.info.initialization = 'failed'
      // newDeviceData.info.initialization = 'no-table'

      this._updateSingleDeviceData(sn, newDeviceData)

      return { [sn]: { deviceData: newDeviceData } }
    } catch (error) {
      console.warn(error)
    }
  },

  //* -----------------       All - Common        -----------------
  //* -----------------       All - Common        -----------------
  //* -----------------       All - Common        -----------------
  //* -----------------       All - Common        -----------------
  //* -----------------       All - Common        -----------------
  's_common/rfMode'({ payloads }) {
    try {
      const { sn, value: rfMode } = payloads

      let currentData = this._getCurrentData(sn)
      currentData.deviceControl.common.rfMode = rfMode
      this._updateSingleDeviceData(sn, currentData)

      // 只有 bboard 才需要回傳 adc
      if (this._isBBoard(sn)) {
        const adc = this['s_step/adc']({ payloads: { sn } })[sn]?.adc
        return { [sn]: { rfMode, adc } }
      }

      if (!this._isBBoard(sn)) return { [sn]: { rfMode } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_common/frequency'({ payloads }) {
    try {
      const { sn, value: newFreq } = payloads
      this._bboxReset(sn)

      let currentData = this._getCurrentData(sn)

      currentData.deviceControl.common.currentFreq = newFreq
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { deviceData: currentData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_common/frequency/get/multiSN'({ payloads }) {
    try {
      const { lstSN } = payloads
      let allData = this._getAllData()

      let allSnList = Object.entries(allData).map(([sn, data]) => ({
        sn,
      }))

      // 要回傳 fe 的
      let newFreqObj = allSnList
        .filter(item => lstSN.includes(item.sn))
        .reduce(
          (prev, { sn, freqList }) => ({
            ...prev,
            [sn]: this._getCaliTable(sn),
          }),
          {}
        )

      // 回傳那些也要更新 be
      Object.entries(newFreqObj).forEach(([sn, freqList]) => {
        let currentData = this._getCurrentData(sn)
        currentData.deviceControl.common.lstFreqOptions = freqList
        this._updateSingleDeviceData(sn, currentData)
      })

      return newFreqObj
    } catch (error) {
      console.warn(error)
    }
  },

  's_aakit/select'({ payloads }) {
    try {
      const { sn, value: selectName } = payloads
      this._bboxReset(sn)

      let currentData = this._getCurrentData(sn)

      const { name: selectAntennaName } =
        currentData.deviceControl.common.lstAntennaData.find(
          e => e.name === selectName
        )

      currentData.deviceControl.common.currentAntenna = selectAntennaName || ''
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { deviceData: currentData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_aakit/insert'({ payloads }) {
    try {
      const { sn, lstValidData, lstImportFailed } = payloads
      let currentData = this._getCurrentData(sn)
      const lstAntennaData = currentData.deviceControl.common.lstAntennaData

      const appendIdAndTimestampInsertData = lstValidData.map(e => ({
        ...e,
        id: nanoid(),
        name:
          e.type === 'customized'
            ? e.name + `_${moment().format('YYYYMMDDkkmmssSSZZ')}`
            : e.name,
      }))

      const newLstAntennaData = [
        ...lstAntennaData,
        ...appendIdAndTimestampInsertData,
      ]

      currentData.deviceControl.common.lstAntennaData = newLstAntennaData
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { lstImportFailed, lstAntennaData: newLstAntennaData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_aakit/update'({ payloads }) {
    try {
      const { sn, data: updateAntennaData } = payloads
      let currentData = this._getCurrentData(sn)
      let prevLstAntennaData = currentData.deviceControl.common.lstAntennaData

      const newLstAntennaData = prevLstAntennaData.map(e =>
        e.id === updateAntennaData.id ? updateAntennaData : e
      )

      currentData.deviceControl.common.lstAntennaData = newLstAntennaData
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { lstAntennaData: newLstAntennaData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_aakit/delete'({ payloads }) {
    try {
      const { sn, data: deleteIdList } = payloads
      let currentData = this._getCurrentData(sn)
      const listAntennaData = currentData.deviceControl.common.lstAntennaData
      const newLstAntennaData = listAntennaData.filter(
        e => !deleteIdList.includes(e.id)
      )

      currentData.deviceControl.common.lstAntennaData = newLstAntennaData
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { lstAntennaData: newLstAntennaData } }
    } catch (error) {
      console.warn(error)
    }
  },

  //* -----------------       BBox - Channel        -----------------
  //* -----------------       BBox - Channel        -----------------
  //* -----------------       BBox - Channel        -----------------
  //* -----------------       BBox - Channel        -----------------
  //* -----------------       BBox - Channel        -----------------
  's_channel/power/all'({ payloads }) {
    try {
      const { sn, value } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

      currentData.deviceControl.channel[currentRfMode].lstBoardData =
        currentData.deviceControl.channel[currentRfMode].lstBoardData.map(
          e => ({
            ...e,
            lstChannelData: e.lstChannelData.map(ee => ({
              ...ee,
              power: value,
            })),
          })
        )

      this._updateSingleDeviceData(sn, currentData)

      // FE 直接改值了
      return null
    } catch (error) {
      console.warn(error)
    } finally {
      const { sn } = payloads
      const currentRfMode = this._getRfMode(sn)
      return { rfMode: currentRfMode }
    }
  },

  's_channel/power'({ payloads }) {
    try {
      const { sn, boardIndex, channelIndex, value: power } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

      currentData.deviceControl.channel[currentRfMode].lstBoardData[
        boardIndex
      ].lstChannelData[channelIndex].power = power

      this._updateSingleDeviceData(sn, currentData)

      // FE 直接改值了
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_channel/commonGain'({ payloads }) {
    try {
      const { sn, boardIndex, commonGain: newCommonGain } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

      let boardData =
        currentData.deviceControl.channel[currentRfMode].lstBoardData[
          boardIndex
        ]

      const oldCommonCurrentGain = boardData.commonCurrentGain

      const lstOldBoardTotalGains = boardData.lstChannelData.map(
        chData => chData.totalGain
      )

      const lstOldBoardElementGains = lstOldBoardTotalGains.map(
        totalGain => +totalGain - +oldCommonCurrentGain
      )

      boardData.lstChannelData = lstOldBoardElementGains.map(
        (elementGain, index) => ({
          ...boardData.lstChannelData[index],
          totalGain: +elementGain + +newCommonGain,
          elementGain: elementGain,
        })
      )

      boardData.commonCurrentGain = newCommonGain

      this._updateSingleDeviceData(sn, currentData)

      // FE 改值了
      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_channel/gain'({ payloads }) {
    try {
      const { sn, channelIndex, boardIndex, value: newElementGain } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

      let boardData =
        currentData.deviceControl.channel[currentRfMode].lstBoardData[
          boardIndex
        ]

      const commonGain = boardData.commonCurrentGain

      boardData.lstChannelData[channelIndex].totalGain =
        +commonGain + +newElementGain

      boardData.lstChannelData[channelIndex].elementGain = +newElementGain

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_channel/phase'({ payloads }) {
    try {
      const { sn, channelIndex, boardIndex, value: newPhase } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

      currentData.deviceControl.channel[currentRfMode].lstBoardData[
        boardIndex
      ].lstChannelData[channelIndex].phase = newPhase

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  //* -----------------       BBox - Steering        -----------------
  //* -----------------       BBox - Steering        -----------------
  //* -----------------       BBox - Steering        -----------------
  //* -----------------       BBox - Steering        -----------------
  //* -----------------       BBox - Steering        -----------------
  's_beam/steering/thetaPhi'({ payloads }) {
    try {
      const { sn, theta, phi } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'steering' })

      currentData.deviceControl.steering[currentRfMode].theta = theta
      currentData.deviceControl.steering[currentRfMode].phi = phi

      // 模擬 ch 的假 phase
      // beam steering 模式下 power 要全開，所以 power: true
      currentData.deviceControl.channel[currentRfMode].lstBoardData =
        currentData.deviceControl.channel[currentRfMode].lstBoardData.map(
          (bData, bIndex) => {
            const newLstChannelData = bData.lstChannelData.map(
              (cData, cIndex) => {
                let newPhase =
                  bIndex * (phi * 0.3) + cIndex * theta >= 355
                    ? 355
                    : bIndex * (phi * 0.3) + cIndex * theta

                newPhase = Math.round(newPhase / 5) * 5

                return { ...cData, phase: newPhase, power: true }
              }
            )

            return {
              ...bData,
              lstChannelData: newLstChannelData,
            }
          }
        )

      this._updateSingleDeviceData(sn, currentData)
    } catch (error) {
      console.warn(error)
    } finally {
      const { sn } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      const rfMode = currentRfMode
      const channel = currentData.deviceControl.channel

      return { [sn]: { rfMode, channel } }
    }
  },

  's_beam/steering/gain'({ payloads }) {
    try {
      const { sn, value: newGain } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'steering' })

      currentData.deviceControl.steering[currentRfMode].beamCurrentGain =
        newGain

      // beam steering 模式下 power 要全開，所以 power: true
      currentData.deviceControl.channel[currentRfMode].lstBoardData =
        currentData.deviceControl.channel[currentRfMode].lstBoardData.map(
          bData => {
            const elementGainMax = bData.totalGainMax - bData.commonGainMax
            const totalGainMin = bData.totalGainMin

            const gainAppendOffset = newGain - totalGainMin

            const elementGain =
              gainAppendOffset >= elementGainMax
                ? elementGainMax
                : gainAppendOffset

            const newCommonCurrentGain = newGain - elementGain

            const newLstChannelData = bData.lstChannelData.map(cData => ({
              ...cData,
              totalGain: newGain,
              elementGain,
              power: true,
            }))

            return {
              ...bData,
              commonCurrentGain: newCommonCurrentGain,
              lstChannelData: newLstChannelData,
            }
          }
        )

      this._updateSingleDeviceData(sn, currentData)
    } catch (error) {
      console.warn(error)
    } finally {
      const { sn } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)
      const rfMode = currentRfMode
      const lstBoardData =
        currentData.deviceControl.channel[currentRfMode].lstBoardData

      return { [sn]: { rfMode, lstBoardData } }
    }
  },

  //* ----------------- BBox - Beam Config Editor  -----------------
  //* ----------------- BBox - Beam Config Editor  -----------------
  //* ----------------- BBox - Beam Config Editor  -----------------
  //* ----------------- BBox - Beam Config Editor  -----------------
  //* ----------------- BBox - Beam Config Editor  -----------------
  'r_/api/beamformers/spi/save/config'({ req }) {
    try {
      const { sn, beamConfigName, tableData, sort } = req
      let currentData = this._getCurrentData(sn)

      currentData.beamConfigEditor.currentConfig.beamConfigName = beamConfigName
      currentData.beamConfigEditor.currentConfig.sort = sort
      currentData.beamConfigEditor.currentConfig.tableData = tableData
      currentData.beamConfigEditor.currentConfig.bindAntennaID =
        currentData.deviceControl.common.currentAntenna

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  'r_/api/beamformers/spi/import/config'({ req }) {
    try {
      const { sn, jsonFileData } = req.body

      // 測試 server 時用, 有給 sn 就 return 正確的 response
      if (sn) {
        return {
          beamConfigID: '', //
          name: '', // 遠端才會用到
          bindAntennaID: jsonFileData.AAKIT_NAME,
          numberOfConfigs: 64, // config 數量
          sort: 'asc', // asc or desc
          tableData: {
            tx: jsonFileData.TX_beam_list,
            rx: jsonFileData.RX_beam_list,
          },
        }
      }

      return
    } catch (error) {
      console.warn(error)
    }
  },

  's_spi/switch/method'({ payloads }) {
    // const { sn, controlMethod } = payloads
    return null
  },

  //* -----------------        BBoard        -----------------
  //* -----------------        BBoard        -----------------
  //* -----------------        BBoard        -----------------
  //* -----------------        BBoard        -----------------
  //* -----------------        BBoard        -----------------

  's_step/power/all'({ payloads }) {
    try {
      const { sn, value } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      currentData.deviceControl.step[currentRfMode].power.channelValue =
        currentData.deviceControl.step[currentRfMode].power.channelValue.fill(
          value
        )

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_step/power'({ payloads }) {
    try {
      const { sn, channelIndex, value } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      currentData.deviceControl.step[currentRfMode].power.channelValue[
        channelIndex
      ] = value

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_step/attenuation/common'({ payloads }) {
    try {
      const { sn, value } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      currentData.deviceControl.step[currentRfMode].attenuation.comValue = value

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_step/attenuation'({ payloads }) {
    try {
      const { sn, channelIndex, value } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      currentData.deviceControl.step[currentRfMode].attenuation.channelValue[
        channelIndex
      ] = value

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_step/phase'({ payloads }) {
    try {
      const { sn, channelIndex, value } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      currentData.deviceControl.step[currentRfMode].phase.channelValue[
        channelIndex
      ] = value

      this._updateSingleDeviceData(sn, currentData)

      return null
    } catch (error) {
      console.warn(error)
    }
  },

  's_step/adc'({ payloads }) {
    try {
      const { sn } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      let adc = Math.round(Math.random() * 100)
      currentData.deviceControl.step[currentRfMode].adc.currentValue = adc

      this._updateSingleDeviceData(sn, currentData)

      const rfMode = currentRfMode
      return {
        [sn]: { rfMode, adc },
      }
    } catch (error) {
      console.warn(error)
    }
  },

  's_step/compensation/CQ/setup'({ payloads }) {
    try {
      const { sn, trC, trQ } = payloads
      let currentData = this._getCurrentData(sn)
      const currentRfMode = this._getRfMode(sn)

      currentData.deviceControl.step[currentRfMode].compensation.trC.valueMax =
        trC
      currentData.deviceControl.step[currentRfMode].compensation.trQ.valueMax =
        trQ

      this._updateSingleDeviceData(sn, currentData)

      return { sn }
    } catch (error) {
      console.warn(error)
    }
  },

  //* -----------------        Clover Cell - EVB 2x2        -----------------
  //* -----------------        Clover Cell - EVB 2x2        -----------------
  //* -----------------        Clover Cell - EVB 2x2        -----------------
  //* -----------------        Clover Cell - EVB 2x2        -----------------
  //* -----------------        Clover Cell - EVB 2x2        -----------------
  's_clovercell/evb/channel/power/all'({ payloads }) {
    const { sn, hvMode, value } = payloads
    let currentData = this._getCurrentData(sn)
    const currentRfMode = this._getRfMode(sn)

    this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

    currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData =
      currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData.map(
        e => ({
          ...e,
          lstChannelData: e.lstChannelData.map(ee => ({ ...ee, power: value })),
        })
      )

    this._updateSingleDeviceData(sn, currentData)

    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/evb/channel/power'({ payloads }) {
    const { sn, boardIndex, channelIndex, hvMode, value } = payloads
    let currentData = this._getCurrentData(sn)
    const currentRfMode = this._getRfMode(sn)

    this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

    currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[
      boardIndex
    ].lstChannelData[channelIndex].power = value

    this._updateSingleDeviceData(sn, currentData)

    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/evb/channel/commonGain'({ payloads }) {
    const { sn, boardIndex, hvMode, lstElementGains, commonGain } = payloads

    let currentData = this._getCurrentData(sn)
    const currentRfMode = this._getRfMode(sn)

    this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

    currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[
      boardIndex
    ].commonCurrentGain = commonGain

    lstElementGains.forEach((e, i) => {
      currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[
        boardIndex
      ].lstChannelData[i].elementGain = e
    })

    this._updateSingleDeviceData(sn, currentData)

    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/evb/channel/gain'({ payloads }) {
    const { sn, boardIndex, channelIndex, hvMode, value } = payloads

    let currentData = this._getCurrentData(sn)
    const currentRfMode = this._getRfMode(sn)

    this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

    currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[
      boardIndex
    ].lstChannelData[channelIndex].totalGain = value

    this._updateSingleDeviceData(sn, currentData)
    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/evb/channel/phase'({ payloads }) {
    const { sn, boardIndex, channelIndex, hvMode, value } = payloads
    let currentData = this._getCurrentData(sn)
    const currentRfMode = this._getRfMode(sn)

    this._setCurrentControlMode({ sn, currentControlMode: 'channel' })

    // polarization 還沒開放 commonPhase 永遠都是 0
    // 所以這邊 totalPhase 跟 elementPhase 一樣
    currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[
      boardIndex
    ].lstChannelData[channelIndex].totalPhase = value

    currentData.deviceControl.channel[currentRfMode][hvMode].lstBoardData[
      boardIndex
    ].lstChannelData[channelIndex].elementPhase = value

    this._updateSingleDeviceData(sn, currentData)
    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/evb/beam/steering/thetaPhi'({ payloads }) {
    try {
      const { sn, hvMode, theta, phi } = payloads
      let currentData = this._getCurrentData(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'steering' })

      const rfMode = currentData.deviceControl.common.rfMode
      currentData.deviceControl.steering[rfMode][hvMode].theta = theta
      currentData.deviceControl.steering[rfMode][hvMode].phi = phi

      currentData.deviceControl.channel[rfMode][hvMode].lstBoardData =
        currentData.deviceControl.channel[rfMode][hvMode].lstBoardData.map(
          e => ({
            ...e,
            lstChannelData: e.lstChannelData.map(ee => ({
              ...ee,
              power: true,
            })),
          })
        )

      const lstBoardData =
        currentData.deviceControl.channel[rfMode][hvMode].lstBoardData
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { rfMode, hvMode, lstBoardData } }
    } catch (error) {
      console.warn(error)
    }
  },

  's_clovercell/evb/beam/steering/gain'({ payloads }) {
    try {
      const { sn, hvMode, value } = payloads
      let currentData = this._getCurrentData(sn)

      this._setCurrentControlMode({ sn, currentControlMode: 'steering' })

      const rfMode = currentData.deviceControl.common.rfMode
      currentData.deviceControl.steering[rfMode][hvMode].beamCurrentGain = value

      currentData.deviceControl.channel[rfMode][hvMode].lstBoardData =
        currentData.deviceControl.channel[rfMode][hvMode].lstBoardData.map(
          e => ({
            ...e,
            lstChannelData: e.lstChannelData.map(ee => ({
              ...ee,
              power: true,
            })),
          })
        )

      const lstBoardData =
        currentData.deviceControl.channel[rfMode][hvMode].lstBoardData
      this._updateSingleDeviceData(sn, currentData)

      return { [sn]: { rfMode, hvMode, lstBoardData } }
    } catch (error) {
      console.warn(error)
    }
  },

  //* -----------------        Clover Cell - Array 1024        -----------------
  //* -----------------        Clover Cell - Array 1024        -----------------
  //* -----------------        Clover Cell - Array 1024        -----------------
  //* -----------------        Clover Cell - Array 1024        -----------------
  //* -----------------        Clover Cell - Array 1024        -----------------
  's_clovercell/array1024/common/signalGeneratorFrequency'({ payloads }) {
    // const { sn, value } = payloads
    // 前端沒接，不用回傳
    return null
  },
  's_clovercell/array1024/spi/switch/method'({ payloads }) {
    // const { sn, value } = payloads
    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/array1024/channel/power/all'({ payloads }) {
    // const { sn, rfMode, hvMode, value } = payloads
    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/array1024/beam/steering/thetaPhi'({ payloads }) {
    // const { sn, hvMode, theta, phi } = payloads
    // 前端沒接，不用回傳
    return null
  },

  's_clovercell/array1024/beam/steering/gain'({ payloads }) {
    // const { sn, hvMode, value } = payloads
    // 前端沒接，不用回傳
    return null
  },

  //* -----------------        RIS        -----------------
  //* -----------------        RIS        -----------------
  //* -----------------        RIS        -----------------
  //* -----------------        RIS        -----------------
  //* -----------------        RIS        -----------------
  's_ris/beam/steering/thetaPhi'({ payloads }) {
    const { sn, incident, reflector } = payloads

    let currentData = this._getCurrentData(sn)

    currentData.deviceControl.steering.incident = incident
    currentData.deviceControl.steering.reflector = reflector
    const newChannel = this._risGetMockChannel(sn)
    currentData.deviceControl.channel = newChannel
    this._updateSingleDeviceData(sn, currentData)

    return { [sn]: { channel: newChannel } }
  },
  's_ris/beam/steering/distance'({ payloads }) {
    const { sn, index, distance } = payloads

    let currentData = this._getCurrentData(sn)

    currentData.deviceControl.steering.incident[index].distance = distance
    const newChannel = this._risGetMockChannel(sn)
    currentData.deviceControl.channel = newChannel
    this._updateSingleDeviceData(sn, currentData)

    return { [sn]: { channel: newChannel } }
  },

  //* -----------------        Clover Cell - CTTC        -----------------
  //* -----------------        Clover Cell - CTTC        -----------------
  //* -----------------        Clover Cell - CTTC        -----------------
  //* -----------------        Clover Cell - CTTC        -----------------
  //* -----------------        Clover Cell - CTTC        -----------------
  's_clovercell/cttc/common/frequency'({ payloads }) {
    const { sn, value } = payloads

    let currentData = this._getCurrentData(sn)
    currentData.deviceControl.common.currentFreq = value

    this._updateSingleDeviceData(sn, currentData)

    return { [sn]: { deviceData: currentData } }
  },
  's_clovercell/cttc/common/set/refSource'({ payloads }) {
    const { sn, value } = payloads

    let currentData = this._getCurrentData(sn)
    currentData.deviceControl.common.refSource = value
    this._updateSingleDeviceData(sn, currentData)

    return { [sn]: { value } }
  },
  's_clovercell/cttc/steering/set/controlPattern'({ payloads }) {
    const { sn, value } = payloads

    let currentData = this._getCurrentData(sn)
    currentData.deviceControl.steering.controlPattern = value
    this._updateSingleDeviceData(sn, currentData)

    return { [sn]: { deviceData: currentData } }
  },
  's_clovercell/cttc/steering/set/beamSynthesis'({ payloads }) {
    const { sn } = payloads

    let currentData = this._getCurrentData(sn)

    this._updateSingleDeviceData(sn, currentData)

    return null
  },
  's_clovercell/cttc/steering/set/rfMode'({ payloads }) {
    const { sn } = payloads

    let currentData = this._getCurrentData(sn)

    this._updateSingleDeviceData(sn, currentData)

    return null
  },
  's_clovercell/cttc/steering/set/power'({ payloads }) {
    const { sn } = payloads

    let currentData = this._getCurrentData(sn)

    this._updateSingleDeviceData(sn, currentData)

    return null
  },
  's_clovercell/cttc/steering/set/thetaPhiGain'({ payloads }) {
    const { sn } = payloads

    let currentData = this._getCurrentData(sn)

    this._updateSingleDeviceData(sn, currentData)

    return null
  },
}

export default beamformersFunctions
