import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FloatingPortal, FloatingOverlay } from '@floating-ui/react'

import { lottie_scanAnimateModal_complete_watcher } from 'src/redux/actions/facility/home'

import TMXLABFullAnimation from 'src/assets/json/TMXLABFullAnimation.json'

import LottieWeb from 'src/components/Lottie'

import useGetCurrentMode from 'src/hooks/useGetCurrentMode'

const TMXLABFullAnimationModal = modalProps => {
  const dispatch = useDispatch()
  const preferredUsername = useSelector(state => state.member.preferredUsername)
  const { status: modalStatus } = modalProps

  const { isDemoMode } = useGetCurrentMode()

  const handleAnimateComplete = () =>
    dispatch(lottie_scanAnimateModal_complete_watcher())

  const overlayClass = `
    fixed 
    inset-0 
    bg-black/80 
    z-10 
    flex flex-col 
    items-center justify-center 
    duration-[5000ms] 
    backdrop-blur-sm 
    ${modalStatus === 'loading' ? 'opacity-100' : 'opacity-0'}
    `

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={overlayClass}>
        <LottieWeb
          className='block max-w-[800px]'
          play={true}
          loop={true}
          animationData={TMXLABFullAnimation}
          segments={modalStatus === 'loading' ? loadingSegment : successSegment}
          forceSegments={false}
          onLoopComplete={handleAnimateComplete}
        />

        {!isDemoMode && preferredUsername && (
          <h6 className={helloText}>Hello, {preferredUsername}</h6>
        )}
      </FloatingOverlay>
    </FloatingPortal>
  )
}

const loadingSegment = [0, 60]
const successSegment = [60, 120]

TMXLABFullAnimationModal.propTypes = {}

export default TMXLABFullAnimationModal

const helloText = `w-5/6 block  break-words text-2xl text-white  text-center -mt-20 md:-mt-[120px]`
