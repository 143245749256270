const operating = {
  showModalOfDeviceResetReminder: (state, action) => {
    state.normal = {
      type: 'OPERATING',
      props: {
        status: 'deviceResetReminder',
        isLoading: false,
        isSuccess: false,
      },
    }
    return state
  },
  setDeviceResetReminderToLoading: (state, action) => {
    state.normal = {
      type: 'OPERATING',
      props: {
        status: 'deviceResetReminder',
        isLoading: true,
        isSuccess: false,
      },
    }
    return state
  },
  setDeviceResetReminderToSuccess: (state, action) => {
    state.normal = {
      type: 'OPERATING',
      props: {
        status: 'deviceResetReminder',
        isLoading: false,
        isSuccess: true,
      },
    }
    return state
  },
}
export default operating
