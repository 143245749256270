import React, { useState } from 'react'

import InputControl from 'src/components/composite/beamformers/BeamSteeringModule/ForCloverCell/InputControl'
import Beam3DButtons from 'src/components/composite/beamformers/BeamSteeringModule/ForCloverCell/Beam3DButtons'

import Beam3DControlForCloverCell from 'src/components/composite/beamformers/Beam3DControl/ForCloverCell'

import ErrorBoundaryOfThree from 'src/containers/ErrorBoundary/Three'

const BeamSteeringModule = props => {
  const {
    sn,
    isDontRender3D,
    deviceType,
    dragHVMode,
    handleDragHVModeChange,
    // value
    thetaH,
    thetaV,
    phiH,
    phiV,
    beamCurrentGainH,
    beamCurrentGainV,
    thetaMaxH,
    thetaMaxV,
    beamGainMinH,
    beamGainMinV,
    beamGainMaxH,
    beamGainMaxV,
    isOffH,
    isOffV,
    isGainDisabled,

    // 3d control event
    handleDrag,
    handleDragEnd,
    beam3DCameraArgs,
    handleCameraChange,

    // 3d button event
    deviceDirection,
    handleDirectionChange,
    handleHelpButtonClick,
    handleBoresightClick,

    // input event
    handleInputChange,
    handleInputFocus,
  } = props

  // 按 reset 鏡頭的按鈕會設成 true, 100ms 後自動設回 false
  const [isCameraReset, setIsCameraReset] = useState(false)
  const boresightDisabled =
    +thetaH === 0 && +thetaV === 0 && +phiH === 0 && +phiV === 0

  const handleCameraReset = value => setIsCameraReset(value)
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='relative w-full h-[400px]'>
        <ErrorBoundaryOfThree>
          {!isDontRender3D && (
            <Beam3DControlForCloverCell
              {...{
                sn,
                deviceType,
                dragHVMode,
                handleDragHVModeChange,
                thetaH,
                thetaV,
                phiH,
                phiV,
                thetaMaxH,
                thetaMaxV,
                isOffH,
                isOffV,
                handleDrag,
                handleDragEnd,
                beam3DCameraArgs,
                handleCameraChange,
                isCameraReset,
                handleCameraReset,
                deviceDirection,
              }}
            />
          )}
          <Beam3DButtons
            {...{
              deviceDirection,
              handleDirectionChange,
              handleCameraReset,
              handleHelpButtonClick,
              handleBoresightClick,
              boresightDisabled,
            }}
          />
        </ErrorBoundaryOfThree>
      </div>

      <InputControl
        {...{
          deviceType,
          dragHVMode,
          thetaH,
          thetaV,
          phiH,
          phiV,
          beamCurrentGainH,
          beamCurrentGainV,
          thetaMaxH,
          thetaMaxV,
          beamGainMinH,
          beamGainMinV,
          beamGainMaxH,
          beamGainMaxV,
          isOffH,
          isOffV,
          isGainDisabled,
          handleInputChange,
          handleInputFocus,
        }}
      />
    </div>
  )
}

export default BeamSteeringModule

const container = `bg-light-4/10 
                    flex flex-col 
                    gap-y-4 
                    rounded-lg 
                    px-5 md:px-5 xl:px-4
                    pb-10
                    `
