import React from 'react'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import Head from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/Head'
import SpacingAndAngle from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/SpacingAndAngle'
import OffsetInputGroups from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/EditMode/OffsetInputGroups'

import CommonButton from 'src/components/Button/CommonButton'

import {
  main,
  footer,
} from 'src/containers/Modal/Normal/beamformers/bbox/DeviceController/CustomAntenna/style'
import { h3, h6 } from 'src/containers/Modal/style'

import {
  bbox_customAntennaModal_deleteClick_watcher,
  bbox_customAntennaModal_saveClick_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import {
  getAntennaName,
  antennaNameIsInvalid,
  spacingIsInvalid,
  thetaMaxIsInvalid,
  offsetIsInvalid,
} from 'src/funcs/device/bbox'

import { offsetMin, offsetMax } from 'src/constants/beamformers'

const EditMode = props => {
  const {
    sn,
    editIsCreate,
    editSaveIsRequesting,

    editTemporaryData,
  } = props

  return (
    <>
      <Head {...{ editIsCreate, editTemporaryData }} />

      <div className={'w-full px-[84px]'}>
        <main className={main + ' overflow-y-scroll scrollbar'}>
          <SpacingAndAngle editTemporaryData={editTemporaryData} />

          <div className='h-[1px] w-full bg-dark-5 my-4' />

          <Offset {...{ sn, editTemporaryData }} />
        </main>
      </div>

      <Footer
        name={editTemporaryData.name}
        {...{ editIsCreate, editSaveIsRequesting }}
      />
    </>
  )
}

export default EditMode

const Offset = ({ sn, editTemporaryData }) => {
  return (
    <div className='w-full flex flex-col px-2 pb-4'>
      <h3 className={h3 + ' mb-1 text-light-1'}>
        Phase offset of each channel
      </h3>
      <h6 className={h6 + ' text-light-4'}>
        The value can be between 0° - 355°. Only multiples of 5 are allowed.
      </h6>

      <h3 className='my-6 text-light-1'>TX mode</h3>
      <OffsetInputGroups mode='txOffset' {...{ sn, editTemporaryData }} />

      <div className='dashedDivide w-full h-[1px] my-6' />

      <h3 className='mb-6 text-light-1'>RX mode</h3>
      <OffsetInputGroups mode='rxOffset' {...{ sn, editTemporaryData }} />
    </div>
  )
}

const Footer = ({ editIsCreate, editSaveIsRequesting }) => {
  const dispatch = useDispatch()

  const editTemporaryData = useSelector(
    state => state.facilityModal.normal.props.editTemporaryData
  )

  const {
    id = '',
    name,
    spacingX,
    spacingY,
    thetaMax,
    rxOffset,
    txOffset,
  } = editTemporaryData

  const nameInvalid = antennaNameIsInvalid(getAntennaName(name))
  const spacingXInvalid = spacingIsInvalid(spacingX)
  const spacingYInvalid = spacingIsInvalid(spacingY)
  const thetaMaxInvalid = thetaMaxIsInvalid(thetaMax)

  const offsetVerify = offsetArray =>
    R.pipe(
      R.filter(e => offsetIsInvalid(e, offsetMin, offsetMax)),
      R.length,
      R.equals(0),
      R.not
    )(offsetArray)
  const rxOffsetInvalid = offsetVerify(rxOffset)
  const txOffsetInvalid = offsetVerify(txOffset)

  const saveIsDisabled =
    nameInvalid ||
    spacingXInvalid ||
    spacingYInvalid ||
    thetaMaxInvalid ||
    rxOffsetInvalid ||
    txOffsetInvalid

  const handleDeleteClick = () =>
    dispatch(bbox_customAntennaModal_deleteClick_watcher([id]))
  const handleSaveClick = () =>
    dispatch(bbox_customAntennaModal_saveClick_watcher())

  if (editIsCreate)
    return (
      <footer className={footer + ' flex justify-end'}>
        <CommonButton
          type='primary'
          size='lg'
          loading={editSaveIsRequesting}
          onClick={handleSaveClick}
          disabled={saveIsDisabled}>
          Save
        </CommonButton>
      </footer>
    )

  return (
    <footer className={footer + ' flex justify-between gap-x-8'}>
      <CommonButton
        type='outlined'
        size='lg'
        danger
        className='px-4'
        disabled={saveIsDisabled}
        onClick={handleDeleteClick}>
        Delete
      </CommonButton>

      <CommonButton
        type='primary'
        size='lg'
        disabled={saveIsDisabled}
        loading={editSaveIsRequesting}
        onClick={handleSaveClick}>
        Save
      </CommonButton>
    </footer>
  )
}
