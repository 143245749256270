import { all, take, fork } from 'redux-saga/effects'

// action

import {
  sg_outputSettingsApply_click_watcher,
  sg_deviceSettings_refSignalSource_change_watcher,
  sg_deviceSettings_outputSource10MHz_change_watcher,
  sg_deviceSettings_inputSource_selection_change_watcher,
} from 'src/redux/actions/freqConverter/sgAction'

// handlers
import { sgOutputSettingsApply as handle_sgOutputSettingsApply } from 'src/redux/sagas/handlers/event/freqConverter/sg/outputSettings'
import {
  sgDeviceSettingsRefSourceChange as handle_sgDeviceSettingsRefSourceChange,
  sgDeviceSettingsOutputSource10MHzChange as handle_sgDeviceSettingsOutputSource10MHzChange,
  sgDeviceSettingsInputSourceChange as handle_sgDeviceSettingsInputSourceChange,
} from 'src/redux/sagas/handlers/event/freqConverter/sg/deviceSettings'

import { devLog } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Output Settings  -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* sgOutputSettingsApply() {
  while (true) {
    const action = yield take(sg_outputSettingsApply_click_watcher.type)
    devLog('[watcher] sgOutputSettingsApply', action.payload)
    yield fork(handle_sgOutputSettingsApply, action.payload)
  }
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Device Settings  -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
function* sgDeviceSettingsRefSourceChange() {
  while (true) {
    const action = yield take(
      sg_deviceSettings_refSignalSource_change_watcher.type
    )
    devLog('[watcher] sgDeviceSettingsRefSourceChange', action.payload)
    yield fork(handle_sgDeviceSettingsRefSourceChange, action.payload)
  }
}

function* sgDeviceSettingsOutputSource10MHzChange() {
  while (true) {
    const action = yield take(
      sg_deviceSettings_outputSource10MHz_change_watcher.type
    )
    devLog('[watcher] sgDeviceSettingsOutputSource10MHzChange', action.payload)
    yield fork(handle_sgDeviceSettingsOutputSource10MHzChange, action.payload)
  }
}

function* sgDeviceSettingsInputSourceChange() {
  while (true) {
    const action = yield take(
      sg_deviceSettings_inputSource_selection_change_watcher.type
    )
    devLog('[watcher] sgDeviceSettingsInputSourceChange', action.payload)
    yield fork(handle_sgDeviceSettingsInputSourceChange, action.payload)
  }
}

export default function* sgWatchers() {
  yield all([
    sgOutputSettingsApply(),
    sgDeviceSettingsRefSourceChange(),
    sgDeviceSettingsOutputSource10MHzChange(),
    sgDeviceSettingsInputSourceChange(),
  ])
}
