import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'src/components/Popup/Modal'
import CommonInput from 'src/components/Input/CommonInput'
import CommonButton from 'src/components/Button/CommonButton'

import { modalActions } from 'src/redux/slices/modal'

import {
  member_modalClose_watcher,
  member_signUpVerificationModal_submit_watcher,
  member_signUpVerificationModal_resend_watcher,
} from 'src/redux/actions/facility/member'

const SignUpVerification = props => {
  const dispatch = useDispatch()

  const { email, verificationCode, errorMessage, resendDelay } = props

  const handleVerificationCodeChange = event =>
    dispatch(
      modalActions.setMemberSignUpVerificationValue({
        verificationCode: event.formattedValue,
      })
    )

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(member_signUpVerificationModal_submit_watcher())
  }
  const handleResendClick = () =>
    dispatch(member_signUpVerificationModal_resend_watcher())
  const handleCloseIconClick = () => dispatch(member_modalClose_watcher())

  return (
    <Modal>
      <Modal.CloseIcon onClick={handleCloseIconClick} />

      <Modal.Title>Enter verification code</Modal.Title>

      <Modal.Description>
        We sent an email with a 6-digit verification code to <b>{email}</b>.
        <br />
        Enter it below to confirm your email.
      </Modal.Description>

      <div className='flex flex-col'>
        <form onSubmit={handleSubmit} className='flex flex-col'>
          {errorMessage && <div className='text-red mt-8'>{errorMessage}</div>}

          <Modal.FormLabel htmlFor='verificationCode' className='mt-8'>
            Verification code
          </Modal.FormLabel>
          <CommonInput
            autoFocus
            id='verificationCode'
            size='lg'
            onChange={handleVerificationCodeChange}
            value={verificationCode}
            placeholder='code'
          />

          <Modal.ColumnButtonBox>
            <CommonButton buttonType='submit' size='lg'>
              Verify
            </CommonButton>

            <CommonButton
              size='lg'
              type='outlined'
              disabled={resendDelay !== 0}
              onClick={handleResendClick}>
              Resend Code {resendDelay !== 0 && ` ${resendDelay}s`}
            </CommonButton>
          </Modal.ColumnButtonBox>
        </form>
      </div>
    </Modal>
  )
}

export default SignUpVerification
