import React from 'react'

import CommonSwitch from 'src/components/Switch/CommonSwitch'

import {
  h6,
  vDivider,
  rowBox,
} from 'src/containers/operating/device/freqConverter/style'

const SwitchControl = props => {
  const { title, option1, option2, handleChange } = props

  const dividerClass = option2 ? vDivider : vDivider + ' opacity-0'

  const option1Subtitle = option1?.subtitle
  const option1Label = option1?.label
  const option1Value = option1?.value

  const option2Subtitle = option2?.subtitle
  const option2Label = option2?.label
  const option2Value = option2?.value
  const option2Disabled = option2?.disabled

  return (
    <div className='px-2'>
      <h6 className={h6 + ' text-light-1 mb-1'}>{title}</h6>

      <div className='flex items-center'>
        <div className={rowBox}>
          <h6 className={h6 + ' text-light-4'}>{option1Subtitle}</h6>
          <CommonSwitch
            type='text'
            onText='ON'
            offText='OFF'
            on={+option1Value === 1}
            onClick={() =>
              handleChange({
                label: option1Label,
                newValue: +option1Value === 0 ? 1 : 0,
              })
            }
          />
        </div>

        <div className={dividerClass} />

        {option2 ? (
          <div className={rowBox}>
            <h6 className={h6 + ' text-light-4'}>{option2Subtitle}</h6>
            <CommonSwitch
              type='text'
              onText='ON'
              offText='OFF'
              on={+option2Value === 1}
              disabled={option2Disabled}
              onClick={() =>
                handleChange({
                  label: option2Label,
                  newValue: +option2Value === 0 ? 1 : 0,
                })
              }
            />
          </div>
        ) : (
          <div className={rowBox} />
        )}
      </div>
    </div>
  )
}

export default SwitchControl
