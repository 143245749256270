import React, { useRef } from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import { h6, small } from 'src/containers/operating/device/freqConverter/style'

import CommonInput from 'src/components/Input/CommonInput'
import SegmentedButton from 'src/components/Button/SegmentedButton'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { isSweepTimeStepInvalid } from 'src/funcs/device/sg'

const SweepTimeStep = props => {
  const dispatch = useDispatch()
  const { isXL, is2XL } = useGetScreenSize()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const customInputRef = useRef(null)

  const isOutputting = current.data.settings.output.isOutputting
  const { currentValue, min, max } =
    current.data?.settings?.output.outputControls.sweepTimeStep

  const segmentButtonValue = currentValue === null ? 'default' : 'custom'

  const currentValueInvalid = isSweepTimeStepInvalid({
    value: currentValue,
    min,
    max,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleSegmentButtonChange = (event, value) => {
    let currentDeviceData = R.clone(current.data)

    if (value === 'default')
      currentDeviceData.settings.output.outputControls.sweepTimeStep.currentValue =
        null

    if (value === 'custom') {
      currentDeviceData.settings.output.outputControls.sweepTimeStep.currentValue =
        ''
      customInputRef?.current?.focus()
    }

    dispatch(setSingleDeviceData({ sn, data: currentDeviceData }))
  }

  const handleCustomInputChange = result => {
    let currentDeviceData = R.clone(current.data)

    currentDeviceData.settings.output.outputControls.sweepTimeStep.currentValue =
      result.formattedValue

    dispatch(setSingleDeviceData({ sn, data: currentDeviceData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const smallTextColor = currentValueInvalid ? ' text-red' : ' text-white/50'
  const customBoxClass =
    segmentButtonValue === 'custom'
      ? 'h-[52px] duration-500'
      : 'h-0 overflow-hidden'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div>
      <h6 className={h6 + ' text-light-1 mb-1'}>Sweep Time Step (ms)</h6>

      <div>
        <SegmentedButton
          size={isXL || is2XL ? 'sm' : 'md'}
          type='outlined'
          value={segmentButtonValue}
          disabled={isOutputting}
          onChange={handleSegmentButtonChange}>
          <SegmentedButton.Option name='default'>
            Default
          </SegmentedButton.Option>
          <SegmentedButton.Option name='custom'>Custom</SegmentedButton.Option>
        </SegmentedButton>

        <div className={customBoxClass}>
          <CommonInput
            ref={customInputRef}
            inputType='number'
            size={isXL || is2XL ? 'sm' : 'md'}
            danger={currentValueInvalid}
            className='mt-1'
            value={currentValue || ''}
            disabled={isOutputting}
            onChange={handleCustomInputChange}
          />
          <small className={small + smallTextColor + ' mt-1'}>
            Range {min} ~ {max} ms
          </small>
        </div>
      </div>
    </div>
  )
}

export default SweepTimeStep
