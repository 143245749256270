import React from 'react'
import { useDispatch } from 'react-redux'

import SegmentedButton from 'src/components/Button/SegmentedButton'

import { h6 } from 'src/containers/operating/device/beamformers/style'

import { cloverCell1024_common_rfModeChange_watcher } from 'src/redux/actions/beamformers/cloverCellArray1024Action'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const RfMode = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const rfMode = current?.data.deviceControl.common.rfMode
  const isExternalControl = current.data.deviceControl.common?.isExternalControl

  const handleChange = (e, value) =>
    dispatch(cloverCell1024_common_rfModeChange_watcher({ sn, value }))

  return (
    <div>
      <h6 className={subtitle}>Current Mode</h6>
      <SegmentedButton
        type='outlined'
        size='sm'
        value={rfMode}
        disabled={isExternalControl}
        onChange={handleChange}>
        <SegmentedButton.Option name='standby'>Standby</SegmentedButton.Option>
        <SegmentedButton.Option name='tx'>TX</SegmentedButton.Option>
        <SegmentedButton.Option name='rx'>RX</SegmentedButton.Option>
      </SegmentedButton>
    </div>
  )
}

RfMode.propTypes = {}

export default RfMode

const subtitle = `${h6} text-light-1 mb-1`
