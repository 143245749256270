import React from 'react'
import PropTypes from 'prop-types'
import { Canvas } from '@react-three/fiber'

import Environment from './Environment'
import Device from './Device'
import Coordinates from './Coordinates'
import Beam from './Beam'
import ArrowBeam from './ArrowBeam'
import BallAndDisk from './BallAndDisk'
import { cameraPosition } from 'src/constants/beamformers'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Beam3DControlForCloverCell
//   {...{
//     sn, // str
//     deviceType, // str
//     beam3DCameraArgs, // { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 }, }
//     deviceDirection, // 'top', 'left'
//     isCameraReset,// bool
//     isDragging, // bool
//     enableZoom, // bool
//     handleCameraChange, // func
//     handleCameraReset, // func
//   }}
// />

const Beam3DControlCore = ({
  sn = '',
  deviceType = 0,
  beam3DCameraArgs = {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
  },
  deviceDirection = 'top', // top, left
  defaultCameraPosition = cameraPosition.mid,
  isCameraReset = false,
  isDragging = false,
  isEnableZoom,
  handleCameraChange = () => {},
  handleCameraReset = () => {},
  ...props
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <Canvas
      linear
      camera={{
        position: defaultCameraPosition,
        near: 0.1,
        far: 50,
      }}>
      <Coordinates {...{ deviceDirection }} />

      <Device {...{ deviceType }} />

      <Environment
        {...{
          sn,
          defaultCameraPosition,
          deviceDirection,
          isCameraReset,
          isDragging,
          beam3DCameraArgs,
          isEnableZoom,
          handleCameraReset,
          handleCameraChange,
        }}
      />

      {props.children}
    </Canvas>
  )
}

export default Beam3DControlCore

Beam3DControlCore.BallAndDisk = BallAndDisk
Beam3DControlCore.Beam = Beam
Beam3DControlCore.ArrowBeam = ArrowBeam

Beam3DControlCore.propTypes = {
  sn: PropTypes.string,
  deviceType: PropTypes.number,
  deviceDirection: PropTypes.oneOf(['top', 'left']),
  isCameraReset: PropTypes.bool,
  isDragging: PropTypes.bool,
  handleDragHVModeChange: PropTypes.func,
  handleCameraChange: PropTypes.func,
  handleCameraReset: PropTypes.func,
}
