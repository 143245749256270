import React from 'react'

import Description from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/Description'

import Modal from 'src/components/Popup/Modal'
import CreateItem from 'src/containers/operating/device/beamformers/bbox/BeamConfigEditor/BeamList/CreateItem'

import noDataImg from 'src/assets/img/no_data.png'

import { mainHeight } from 'src/containers/Modal/Normal/beamformers/bbox/BeamConfigEditor/EditBeam/Main'

const NoData = ({ handleCreateClick }) => (
  <div
    className={`${mainHeight} w-[640px] flex flex-col items-center py-4 pr-4 translate-x-2 gap-y-6 scrollbar`}>
    <Description value='' disabled={true} />

    <CreateItem handleCreateClick={handleCreateClick} />

    <div className='h-full flex flex-col items-center mt-10 pb-10 gap-y-2'>
      <img src={noDataImg} alt='no data' />

      <Modal.Description>
        Choose to edit the beam in Beam steering control or Channel control.
      </Modal.Description>
    </div>
  </div>
)

export default NoData
