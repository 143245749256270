import React, { useState, cloneElement } from 'react'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'

import { TabsContext } from 'src/components/Tabs/context'

import CommonItem from 'src/components/Tabs/Item/CommonItem'
import FunctionalItem from 'src/components/Tabs/Item/FunctionalItem'

import { tryCatch, isType } from 'src/funcs/tools'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//  <CommonTabs
//    value={}
//    disabled={}
//    onChange={(e, v) => {}}>
//    <CommonTabs.Item disabled={} name=''></CommonTabs.Item>
//    <CommonTabs.Item disabled={} name=''></CommonTabs.Item>
//  </CommonTabs>

const CommonTabs = props => {
  const {
    value = '',
    className,
    disabled = false,
    onChange = () => {},
    children,
  } = props

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const [activeIndex, setActiveIndex] = useState('')

  const contextProps = {
    disabled,
    activeName: value,
    activeIndex,
    handleChange,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  function handleChange(event, value, index) {
    if (!disabled) {
      tryCatch(() => {
        setActiveIndex(index)
        onChange(event, value)
      })
    }
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `${className} flex items-end gap-x-2 duration-500 select-none `

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <nav className={containerClass}>
      <TabsContext.Provider value={contextProps}>
        <ChildrenWithIndex children={children} />
      </TabsContext.Provider>
    </nav>
  )
}

CommonTabs.Item = CommonItem
CommonTabs.FunctionalItem = FunctionalItem

export default CommonTabs

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// 把傳進來的 children 多包一層 index
// 拿來給 Option 做 change 判斷
const ChildrenWithIndex = ({ children }) =>
  isType('array')(children)
    ? children.map((child, index) =>
        cloneElement(child, {
          index,
          key: nanoid(),
        })
      )
    : children

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
CommonTabs.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
